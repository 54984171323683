import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import CallbackMessage from "../../components/CallbackMessage";
import OverlayLoading from "../../components/OverlayLoading";
import ButtonComponent from "../../components/ButtonComponent";
import loginService from "../../services/loginService";
import "./styles.scss";
import { InputNativo } from "../../components/InputNativo";

function RedefinirSenha() {
  const history = useHistory();
  const { search } = useLocation();
  const query = search.split("=");
  const token = query[1].replace("&email", "");
  const email = query[2];
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loadingForgot, setLoadingForgot] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorsList, setErrorsList] = useState([]);
  const [errors, setErrors] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState("");

  function handleClose(event, reason) {
    setShowMessage(false);
    if (errorsList.length === 0) {
      history.push("/");
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setErrorsList([]);
    let l = [];
    if (password === "") {
      l.push('O campo "Nova senha" é obrigatório.');
    }
    if (confirmPassword === "") {
      l.push('O campo "Confirmar nova senha" é obrigatório.');
    }

    if (password.length < 6) {
      fieldsErrors.push("A senha deve ter pelo menos 6 caracteres");
    }
  
    if (!/[A-Z]/.test(password)) {
      fieldsErrors.push("A senha deve conter pelo menos uma letra maiúscula");
    }
  
    if (!/\d/.test(password)) {
      fieldsErrors.push("A senha deve conter pelo menos um número");
    }
  
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      fieldsErrors.push("A senha deve conter pelo menos um caractere especial");
    }

    if (l.length > 0) {
      setErrorsList(l);
      setShowMessage(true);
      return;
    } else if (password !== confirmPassword) {
      setErrorsList(["As senhas não são iguais."]);
      setShowMessage(true);
      return;
    }
    setErrors(null);
    setLoadingForgot(true);
    const response = await loginService.atualizaSenha(token, email, password);
    if (response.status !== 200) {
      if (response.data.error) {
        setErrorsList(Object.values(response.data.error));
        setErrors(response.data.error);
      } else if (
        response.data.status &&
        response.data.status === "passwords.token"
      ) {
        setErrorsList(["O link de acesso para redefinir senha expirou."]);
      } else {
        setErrorsList(["Ocorreu um erro."]);
      }
    }
    setShowMessage(true);
    setLoadingForgot(false);
  }

  const validatePassword = (value) => {
    let message = "";
  
    if (value.length < 6) {
      message = "A senha deve ter pelo menos 6 caracteres.";
    } else if (!/[A-Z]/.test(value)) {
      message = "A senha deve conter pelo menos uma letra maiúscula.";
    } else if (!/\d/.test(value)) {
      message = "A senha deve conter pelo menos um número.";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      message = "A senha deve conter pelo menos um caractere especial.";
    }
  
    setPasswordMessage(message);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  return (
    <div className="login-container">
      <div className="login-background" />
      <div className="login-form-container">
        <div className="login-img-logo" />
        {loadingForgot && <OverlayLoading />}
        <p className="login-description-page">Redefinir Senha</p>
        <form className="login-form" onSubmit={handleSubmit}>
          <InputNativo
            label="Nova senha"
            type="password"
            placeholder="nova senha"
            onChange={handlePasswordChange}
            error={errors?.nova_senha}
          />
          {passwordMessage && <div className="password-message">{passwordMessage}</div>}
          <InputNativo
            label="Confirmar nova senha"
            type="password"
            placeholder="confirmar nova senha"
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
          <div className="redefinir-submit-button">
            <ButtonComponent
              type="submit"
              className="btn-success zero-margin"
              text="Salvar"
            />
          </div>
          <CallbackMessage
            open={showMessage}
            type={`${errorsList.length === 0 ? "success" : "error"}`}
            message={`${
              errorsList.length === 0
                ? "A senha foi redefinida com sucesso."
                : "Erro!"
            }`}
            errorList={errorsList}
            handleClose={handleClose}
            duration={errorsList.length === 0 ? 2000 : 6000}
          />
        </form>
      </div>
    </div>
  );
}

export default RedefinirSenha;
