import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Input from "../../../components/Input";
import InputFile from "../../../components/InputFile";
import Button from "../../../components/ButtonComponent";
import Select from "../../../components/SelectComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

import { base64ToFile } from "../../../utils/functions";
import validateFields from '../../../utils/validateFields';

import empresasService from "../../../services/empresasService";
import { documentacaoLegalService } from "../../../services/documentacaoLegalService";

import "./styles.css";

function EditarDocumentacaoLegal() {
  const history = useHistory();
  const paramsQuery = useParams();

  let { id: idParams } = paramsQuery;

  const [id, setId] = useState(idParams);

  const { selectEmpresa } = empresasService;
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState(null);
  const [company, setCompany] = useState(null);
  const [file, setFile] = useState(null);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackType, setCallbackType] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await loadEmpresas();
      await loadFields();
      setLoading(false);
    })();
  }, []);

  let params = {
    page: null,
    orderBy: null,
    orderByType: null,
    perPage: 9999,
    searchTerm: null,
  };

  async function loadEmpresas() {
    const response = await selectEmpresa();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const data = response.data;

    if (data && data.length !== 0) {
      setEmpresas(data);
    }
  }

  async function loadFields() {
    const responseSelect = await documentacaoLegalService.selectCategorias();
    if (responseSelect.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const response = await documentacaoLegalService.consultaDocumentacao(id);
    if(response.status === 400){
      setCallbackType("error");
      setCallbackMessage(response.data.msg || (response.data.message || "Erro!"));
      setCallbackErrorList([response.data.error] || ["Erro ao consultar produto!"]);
      setLoading(false);
      setCallbackShown(true);
      setId(null);
      return;
    }
    let data = response.data;
    setCategorias(responseSelect.data);
    setTitle(data.titulo);
    setCategory(data.id_categoria_documento_legal);
    setCompany(data.id_empresa);
    if (data.arquivo !== null) setFile(data.arquivo);
  }

  

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      setCallbackShown(false);
    }
    if (reason === 'timeout') {
      setCallbackShown(false);
    }
    if (callbackType === 'success') {
      history.push("/documentacao-legal");
    }
  }

  async function handleUserSubmit() {
    setLoading(true);
    let hasErrors = false;

    const fieldsToValidate = [
      {
        label: "titulo",
        value: title,
      },
      {
        label: "categoria",
        value: category,
      },
      {
        label: "empresa",
        value: company,
      },
      {
        label: "anexo",
        value: file,
      }
    ]

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      hasErrors = true;
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(fieldsErrors);
      setCallbackShown(true);
    }
    
    if (hasErrors) {
      setLoading(false);
      return;
    }
    
    documentacaoLegalService
      .atualizaDocumentoLegal(id, title, category, company, file)
      .then((response) => {
        if (response.status !== 200) {
          if (response.data.error)
            setCallbackErrorList(Object.values(response.data.error));
          else setCallbackErrorList(["Ocorreu um erro"]);
          
          setCallbackType("error")
          setCallbackMessage("Erro!");
        } else {
          setCallbackType("success");
          setCallbackMessage("Atualizado!");
          setCallbackDuration(1500);

        }
        setLoading(false);
        setCallbackShown(true);
      });
  }

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <form className="cadastrar-documento-legal-form">
            <div className="cadastrar-documento-legal-titulo-input">
              <Input
                nome="titulo"
                label="Título"
                tipo="text"
                value={title}
                required={true}
                handleInputChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="cadastrar-documento-legal-categoria-input">
              <Select
                required
                styleType="form"
                label="Categoria"
                list={categorias}
                initialValue={
                  categorias.find((item) => item.id === category)
                    ? categorias.find((item) => item.id === category).nome
                    : ""
                }
                callback={(e) => setCategory(e)}
              />
            </div>
            <div className="cadastrar-documento-legal-empresa-input">
              <Select
                styleType="form"
                label="Empresa"
                required
                initialValue={
                  empresas.find((item) => item.id === company)
                    ? empresas.find((item) => item.id === company).nome
                    : ""
                }
                list={empresas}
                callback={(e) => setCompany(e)}
              />
            </div>
            {file && (
              <div className="cadastrar-documento-legal-file-input">
                <div className="label">Anexo *</div>
                <div className="cadastro-certidao-anexo-botoes">
                  <Button
                    className="primary-outline text-button"
                    type="button"
                    text="Visualizar arquivo"
                    icone="download"
                    iconeColor="#812990"
                    iconeSize="18"
                    onClick={() => base64ToFile(file, "anexo")}
                  />
                  <Button
                    className="primary-outline icon-button"
                    type="button"
                    icone="trash"
                    iconeColor="#812990"
                    iconeSize="16"
                    onClick={() => setFile(null)}
                  />
                </div>
              </div>
            )}
            {file === null && (
              <div className="cadastrar-documento-legal-file-input">
                <InputFile title="Anexo" onChange={(file) => setFile(file)} />
              </div>
            )}
            <div className="required-text">
              <span>* Campos obrigatórios</span>
            </div>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            type="button"
            text="Salvar"
            className="btn-success"
            onClick={handleUserSubmit}
          />
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        message={callbackMessage}
        errorList={callbackErrorList}
        handleClose={handleClose}
        duration={callbackDuration}
      />
    </>
  );
}

export default EditarDocumentacaoLegal;
