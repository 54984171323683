import React, { useState, useEffect, useCallback } from "react";

import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import TableComponent from "../../../../components/TableComponent";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import OverlayLoading from "../../../../components/OverlayLoading";
import CallbackMessage from "../../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../../components/ModalLoginExpirado";
import ModalNovoValeTransporte from "../../../../components/ModalNovoValeTransporte";
import ButtonComponent from "../../../../components/ButtonComponent";
import valeTransporteServices from "../../../../services/valeTransporteServices";

import "./styles.scss";

function ListaValeTranporte() {
  const [listRequest, setListRequest] = useState([]);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [openCadastraValeTransporte, setOpenCadastraValeTransporte] = useState(
    false
  );
  const [valeId, setValeId] = useState("");
  const { id } = useParams();
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Descrição", dataRef: "descricao" },
      { columnName: "Quantidade por dia", dataRef: "qtd_por_dia" },
    ],
    options: {
      edit: true,
      delete: true
    },
    display: {
      search: false,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null
  });

  const updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const response = await valeTransporteServices.getValeTransporte(
      id,
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      response.data.data = response.data.data.map((item, index) => {
        item.id = item.id_vale_transporte;
        return item;
      });
      setListRequest(response.data);
    }
    setLoading(false);
  }, [id,
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  useEffect(() => {
    const newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  const onPageChange = newPageObj => {
    const newPage = newPageObj.page;
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onOrderBy = newOrderBy => {
    const orderBy = newOrderBy.orderBy;
    const orderByType = newOrderBy.orderByType;
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onPerPage = newPerPage => {
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onSearchTerm = value => {
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onEdit = (id) => {
    setValeId(id);
    setOpenCadastraValeTransporte(true);
  };

  const handleRemoveDialog = (id) => {
    setValeId(id);
    setConfirmRemoveDialog(true);
  };

  const handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  
  const handleClose = (event, reason) => {
    setShowMessage(false);
    if (errors.length === 0) {
      updateTable();
    }
  };
  
  const onDelete = async () => {
    await valeTransporteServices
      .removeValeTransporte(valeId)
      .then(async (response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          return;
        }
        if (response.status === 200) {
          setShowMessage(true);
          updateTable();
        } else {
          if (response.data.error)
            setErrors(Object.values(response.data.error));
          else setErrors(["Ocorreu um erro."]);
          setShowMessage(true);
        }
      });
    handleRemoveCancel();
  };
  return (
    <>
      {loading && <OverlayLoading />}
      <Grid container className="vale-transporte-container">
        <Grid container className="vale-transporte-wrapper">
          <Grid item>
            <h3 className="title-vale-transporte">Vale-transporte</h3>
          </Grid>
          <Grid item>
            <ButtonComponent
              className="btn-primary"
              text="+ Novo Vale-transporte"
              onClick={() => {
                setValeId();
                setOpenCadastraValeTransporte(true);
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <TableComponent
            idName="id"
            dataList={listRequest !== undefined ? listRequest?.data : []}
            tableConfig={defaultConfigTable}
            callbackCurrentPage={onPageChange}
            callbackOrderBy={onOrderBy}
            callbackPerPage={onPerPage}
            callbackSearchTerm={onSearchTerm}
            cbEdit={onEdit}
            cbDelete={handleRemoveDialog}
          />
        </Grid>
      </Grid>
      {openCadastraValeTransporte && (
        <ModalNovoValeTransporte
          idValeTransporte={valeId}
          idFuncionario={id}
          onClose={async () => {
            setOpenCadastraValeTransporte(false);
            await updateTable();
          }}
          open={openCadastraValeTransporte}
        />
      )}
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />
      <CallbackMessage
        duration={4000}
        open={showMessage}
        errorList={errors}
        message={
          errors.length === 0 ? "Vale-transporte excluído com sucesso." : "Erro"
        }
        type={errors.length === 0 ? "success" : "error"}
        handleClose={handleClose}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default ListaValeTranporte;
