import Axios from "./Axios";

export const orcamentoServices = {
  getDre: async ({ idEmpresa, ano }) => {
    const result = await Axios.get(`/lista-grupo-categoria-orcamento`, {
      params: {
        id_empresa: idEmpresa,
        ano,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  atualizaValorCategoria: async ({ id_categoria, ano, valor }) => {
    const response = await Axios.post(
      `/atualiza-orcamento-categoria/${id_categoria}`,
      {
        valor,
        ano,
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
};
