import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Grid, useMediaQuery } from "@material-ui/core";

import Select from "../../components/SelectComponent";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import DatePicker from "../../components/DatePicker";
import OverlayLoading from "../../components/OverlayLoading";
import TableComponent from "../../components/TableComponent";
import CallbackMessage from "../../components/CallbackMessage";
import Button from "../../components/ButtonComponent";

import empresasService from "../../services/empresasService";
import faturasService from "../../services/faturasService";
import selectServices from "../../services/selectServices";
import planosServices from "../../services/planosServices";

import { formatDate } from "../../utils/dates";
import { base64ToFile, numberToCurrency } from "../../utils/functions";

import DadosComplementaresDialog from "./components/DadosComplementaresDialog";

import styles from "./styles.module.scss";
import "./styles.scss";

function ListaRelatorioAssinantes() {
  const history = useHistory();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const { selectStatusFatura, selecionaFormaPagamentoPorOrigem } = selectServices;
  const { selecionaPlano } = planosServices;
  const { exportarRelatorio, exportarRelatorioPdf } = faturasService;

  const [loading, setLoading] = useState(false);
  const [empresa, setEmpresa] = useState(null);
  const [status, setStatus] = useState(null);
  const [plano, setPlano] = useState(null);
  const [formaPagamento, setFormaPagamento] = useState(null);
  const [statusCliente, setStatusCliente] = useState(null);
  const [dataContratacaoInicio, setDataContratacaoInicio] = useState("");
  const [dataContratacaoFim, setDataContratacaoFim] = useState("");
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [dataPagamentoInicio, setDataPagamentoInicio] = useState("");
  const [dataPagamentoFim, setDataPagamentoFim] = useState("");
  const [dataEmissaoInicio, setDataEmissaoInicio] = useState("");
  const [dataEmissaoFim, setDataEmissaoFim] = useState("");
  const [listEmpresas, setListEmpresas] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [listPlanos, setListPlanos] = useState([]);
  const [listFormaPagamento, setListFormaPagamento] = useState([]);
  const [listRequest, setListRequest] = useState([]);
  const [dadosComplementares, setDadosComplementares] = useState({});
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [openDadosComplDialog, setOpenDadosComplDialog] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [callbackMessage, setCallbackMessage] = useState("");

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_empresa" },
      { columnName: "Empresa", dataRef: "empresa" },
      { columnName: "Plano", dataRef: "plano" },
      { columnName: "Valor", dataRef: "valor" },
      { columnName: "Id da Fatura", dataRef: "id_fatura" },
      { columnName: "Vencimento", dataRef: "data_vencimento" },
      { columnName: "Data de Pagamento", dataRef: "data_pagamento" },
      { columnName: "Forma de Pagamento", dataRef: "forma_pagamento" },
      { columnName: "NSU", dataRef: "nsu" },
    ],
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-xls",
        },
        {
          icon: "export",
          label: "Exportar PDF",
          action: "exportar-pdf",
        },
      ],
    },
    options: {
      searchFile: true,
      info: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    status: null,
    empresa: null,
    data_inicio: null,
    data_fim: null,
    status_cliente: null,
    plano: null,
    forma_pagamento: null,
    data_contratacao_inicio: null,
    data_contratacao_fim: null,
    data_pagamento_inicio: null,
    data_pagamento_fim: null,
    data_emissao_inicio: null,
    data_emissao_fim: null,
  });

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const response = await faturasService.faturaAssinantes(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.status,
      defaultConfigTable.empresa,
      defaultConfigTable.data_inicio === '' ? null : defaultConfigTable.data_inicio,
      defaultConfigTable.data_fim === '' ? null : defaultConfigTable.data_fim,
      defaultConfigTable.status_cliente,
      defaultConfigTable.plano,
      defaultConfigTable.forma_pagamento,
      defaultConfigTable.data_contratacao_inicio === '' ? null : defaultConfigTable.data_contratacao_inicio,
      defaultConfigTable.data_contratacao_fim === '' ? null : defaultConfigTable.data_contratacao_fim,
      defaultConfigTable.data_pagamento_inicio === '' ? null : defaultConfigTable.data_pagamento_inicio,
      defaultConfigTable.data_pagamento_fim === '' ? null : defaultConfigTable.data_pagamento_fim,
      defaultConfigTable.data_emissao_inicio === '' ? null : defaultConfigTable.data_emissao_inicio,
      defaultConfigTable.data_emissao_fim === '' ? null : defaultConfigTable.data_emissao_fim,
    );

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    let responseData = {};

    if (response.data.hasOwnProperty("data")) {
      responseData = response.data;
    } else {
      responseData = response;
    }

    let dataList = responseData.data.map((item, index) => {
      return {
        ...item,
        id: item.id_fatura || item.id_empresa,
        data_criacao: formatDate(item.data_criacao?.substring(0, 10)),
        data_vencimento: formatDate(item.data_vencimento),
        data_emissao: formatDate(item.data_emissao),
        data_pagamento: formatDate(item.data_pagamento?.substring(0, 10)),
        data_assinatura: formatDate(
          item.data_assinatura_contrato?.substring(0, 10)
        ),
        valor: numberToCurrency(item.valor),
      };
    });

    const newResult = {
      ...responseData,
      data: dataList,
    };

    setListRequest(newResult);
    setLoading(false);
  }, [
    defaultConfigTable,
  ]);

  const loadPlanos = useCallback(async () => {
    const response = await selecionaPlano();

    if (response.status === 200) setListPlanos(response.data);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  }, []);

  const loadFormaPagamento = useCallback(async () => {
    const origemFatura = 3;
    const response = await selecionaFormaPagamentoPorOrigem(origemFatura);

    if (response.status === 200) setListFormaPagamento(response.data);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  }, []);

  const loadEmpresas = useCallback(async () => {
    const response = await empresasService.selectEmpresa();

    if (response.status === 200) setListEmpresas(response.data);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  }, []);

  const loadStatus = useCallback(async () => {
    const response = await selectStatusFatura();

    if (response.status === 200) setListStatus(response.data);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  }, [selectStatusFatura]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchFile = async (id) => {
    if (id) {
      const itemFatura = await listRequest?.data?.find(
        (item) => item?.id_fatura === Number(id)
      );

      const { id_fatura, id_boleto } = itemFatura;

      history.push(`/fatura-boleto/${id_fatura}/${id_boleto}`);
    }
  };

  let onTableMoreAction = async (action) => {

    setLoading(true);

    if (action.action === "exportar-pdf") {
      const response = await exportarRelatorioPdf(
        status,
        defaultConfigTable.orderBy,
        defaultConfigTable.orderByType,
        empresa,
        dataInicio === "" ? null : dataInicio,
        dataFim === "" ? null : dataFim,
        defaultConfigTable.searchTerm,
        dataPagamentoInicio === "" ? null : dataPagamentoInicio,
        dataPagamentoFim === "" ? null : dataPagamentoFim,
        dataEmissaoInicio === "" ? null : dataEmissaoInicio,
        dataEmissaoFim === "" ? null : dataEmissaoFim,
        dataContratacaoInicio === "" ? null : dataContratacaoInicio,
        dataContratacaoFim === "" ? null : dataContratacaoFim
      );

      if (response.status === 200) {
        base64ToFile(
          response.data.arquivo,
          "cbhub_relatorio_assinantes",
          "pdf"
        );
      } else if (response.status === 500) {
        setCallbackErrorList([
          "Erro interno no servidor. Por favor, contate o suporte",
        ]);
        setCallbackShown(true);
      } else if (response.status === 400) {
        setCallbackErrorList(
          Object.values(response?.data?.error).flat(Infinity)
        );
        setCallbackShown(true);
      } else {
        setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
        setCallbackShown(true);
      }
    }

    if (action.action === "exportar-xls") {
      const response = await exportarRelatorio(
        status,
        defaultConfigTable.orderBy,
        defaultConfigTable.orderByType,
        empresa,
        dataInicio === "" ? null : dataInicio,
        dataFim === "" ? null : dataFim,
        defaultConfigTable.searchTerm,
        dataPagamentoInicio,
        dataPagamentoFim,
        dataEmissaoInicio,
        dataEmissaoFim,
        dataContratacaoInicio === "" ? null : dataContratacaoInicio,
        dataContratacaoFim === "" ? null : dataContratacaoFim,
        "xls"
      );
      if (response.status === 200) {
        base64ToFile(
          response.data.arquivo,
          "cbhub_relatorio_assinantes",
          "xls"
        );
      } else if (response.status === 500) {
        setCallbackErrorList([
          "Erro interno no servidor. Por favor, contate o suporte",
        ]);
        setCallbackShown(true);
      } else {
        setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
        setCallbackShown(true);
      }
    }

    setLoading(false);
  };

  let onInfoClick = async (id) => {
    if (id) {
      const itemFatura = await listRequest.data?.find((item) => {
        if (item.id_fatura) {
          if (item.id_fatura === Number(id)) {
            return item;
          }
        }

        if (item.id_empresa === Number(id)) {
          return item;
        }
      });

      const formattedData = {
        empresa: itemFatura.empresa,
        cnpj: itemFatura.cnpj,
        nome_contato: itemFatura.nome_contato,
        data_vencimento: itemFatura.data_vencimento,
        telefone: itemFatura.telefone,
        email: itemFatura.email,
        plano: itemFatura.plano,
        valor: itemFatura.valor,
        data_emissao: itemFatura.data_emissao,
        data_pagamento: itemFatura.data_pagamento,
        data_criacao: itemFatura.data_criacao,
        data_assinatura: itemFatura.data_assinatura,
        id_fatura: itemFatura.id_fatura,
        status_cliente: itemFatura.status_cliente,
      };
      setDadosComplementares(formattedData);
      setOpenDadosComplDialog(true);
    }
  };

  let handleCloseDadosComplDialog = () => {
    setOpenDadosComplDialog(false);
    setDadosComplementares({});
  };

  function listaStatus(list = []) {
    return list.map((item) => {
      if (item.status_pagamento === "PAGO") {
        return (
          <div className="card-table-success" key={item.id_empresa}>
            PAGO
          </div>
        );
      } else if (item.status_pagamento === "EM ATRASO") {
        return (
          <div className="card-table-danger" key={item.id_empresa}>
            EM ATRASO
          </div>
        );
      } else if (item.status_pagamento === "EM ABERTO") {
        return (
          <div className="card-table-warning" key={item.id_empresa}>
            EM ABERTO
          </div>
        );
      } else {
        return (
          <div className="card-table-free" key={item.id_empresa}>
            PLANO GRATUITO
          </div>
        );
      }
    });
  }

  function listaStatusCliente(list = []) {
    return list.map((item) => {
      switch (item.status_cliente) {
        case "BLOQUEADO":
          return (
            <div className="card-table-danger" key={item.id_empresa}>
              BLOQUEADO
            </div>
          );
        case "ATIVO":
          return (
            <div className="card-table-success" key={item.id_empresa}>
              ATIVO
            </div>
          );
        case "INATIVO":
          return (
            <div className="card-table-disabled" key={item.id_empresa}>
              INATIVO
            </div>
          );
        default:
          return "";
      }
    });
  }

  function verificaTamanhoTabela() {
    let box = document.querySelector(".table-relatorio-assinantes");
    let table = document.querySelector(".MuiTable-root");
    if (isMobile) {
      table.style.transform = "unset";
      table.style.transformOrigin = "unset";
      return;
    }
    let widthBox = box?.offsetWidth;
    let widthTable = table?.offsetWidth;
    if (!widthBox || !widthTable) {
      return;
    }
    let comparacaoOffset = (widthTable * 100) / widthBox;
    let scale = comparacaoOffset - 95;
    if (scale > 0 && scale < 10) {
      table.style.transform = `scale(${(100 - scale) / 100})`;
      table.style.transformOrigin = "0 0";
    } else if (scale >= 10) {
      table.style.transform = `scale(${(100 - scale + 2) / 100})`;
      table.style.transformOrigin = "0 0";
    }
  }

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await Promise.all([
        loadStatus(),
        loadEmpresas(),
        loadPlanos(),
        loadFormaPagamento(),
      ]);
      setLoading(false);
      verificaTamanhoTabela();
    })();
  }, [
    // updateTable,
    loadEmpresas,
    loadStatus,
    loadPlanos,
    loadFormaPagamento,
    isMobile,
  ]);

  useEffect(() => {
    updateTable()
  }, [updateTable])

  useEffect(() => {
    window.addEventListener('resize', verificaTamanhoTabela());
  })

  const handleClose = (event, reason) => {
    if (reason === "timeout") {
      setCallbackShown(false);
    }

    if (reason === "clickaway") {
      setCallbackShown(false);
    }
  };

  const handleLimparFiltros = useCallback(() => {
    setEmpresa(null)
    setStatus(null)
    setStatusCliente(null)
    setPlano(null)
    setFormaPagamento(null)
    setDataContratacaoInicio('')
    setDataContratacaoFim('')
    setDataInicio('')
    setDataFim('')
    setDataPagamentoInicio('')
    setDataPagamentoFim('')
    setDataEmissaoInicio('')
    setDataEmissaoFim('')
    setDefaultConfigTable({
      ...defaultConfigTable,
      status: null,
      empresa: null,
      data_inicio: null,
      data_fim: null,
      status_cliente: null,
      plano: null,
      forma_pagamento: null,
      data_contratacao_inicio: null,
      data_contratacao_fim: null,
      data_pagamento_inicio: null,
      data_pagamento_fim: null,
      data_emissao_inicio: null,
      data_emissao_fim: null,
    })
  }, [updateTable])

  const listStatusCliente = useMemo(
    () => [
      {
        id: "BLOQUEADO",
        nome: "BLOQUEADO",
      },
      {
        id: "ATIVO",
        nome: "ATIVO",
      },
      {
        id: "INATIVO",
        nome: "INATIVO",
      },
    ],
    []
  );

  function filterComponent() {
    return (
      <div className="session-container chat dre-filter filter-container relatorio-assinantes">
        <Grid container spacing={2} className="form-table">
          <Grid container spacing={2} xs={12} md={12} sm={12}>
            <Grid xs={12} md={4} item>
              <Select
                styleType="form"
                label="Empresa"
                initialValue={
                  listEmpresas.find((item) => item.id === empresa)
                    ? listEmpresas.find((item) => item.id === empresa).nome
                    : ""
                }
                title="Selecione uma empresa"
                list={listEmpresas}
                callback={async e => {
                  setDefaultConfigTable({
                    ...defaultConfigTable,
                    empresa: e,
                    currentPage: 1
                  })
                  setEmpresa(e);
                }}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <Select
                styleType="form"
                label="Status da fatura"
                initialValue={
                  listStatus.find((item) => item.id === status)
                    ? listStatus.find((item) => item.id === status).nome
                    : ""
                }
                list={listStatus}
                callback={e => {
                  setDefaultConfigTable({
                    ...defaultConfigTable,
                    status: e,
                    currentPage: 1
                  })
                  setStatus(e);
                }}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <Select
                styleType="form"
                label="Status do cliente"
                initialValue={
                  listStatusCliente.find((item) => item.id === statusCliente)
                    ? listStatusCliente.find(
                        (item) => item.id === statusCliente
                      )
                    : null
                }
                list={listStatusCliente}
                callback={e => {
                  setDefaultConfigTable({
                    ...defaultConfigTable,
                    status_cliente: e,
                    currentPage: 1
                  })
                  setStatusCliente(e);
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            xs={12}
            md={12}
            sm={12}
            classes={{ root: styles.marginTop }}
          >
            <Grid xs={12} md={4} item>
              <DatePicker
                label="Vencimento De"
                noMask
                handleChange={
                  (value) => {
                    setDefaultConfigTable({
                      ...defaultConfigTable,
                      data_inicio: value,
                      currentPage: 1
                    })
                    setDataInicio(value)
                  }
                }
                initDate={dataInicio}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <DatePicker
                label="Vencimento Até"
                noMask
                handleChange={
                  (value) => {
                    setDefaultConfigTable({
                      ...defaultConfigTable,
                      data_fim: value,
                      currentPage: 1
                    })
                    setDataFim(value)
                  }
                }
                initDate={dataFim}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <Select
                styleType="form"
                label="Plano da fatura"
                initialValue={
                  listPlanos.find((item) => item.id === plano)
                    ? listPlanos.find((item) => item.id === plano).nome
                    : ""
                }
                list={listPlanos}
                callback={e => {
                  setDefaultConfigTable({
                    ...defaultConfigTable,
                    plano: empresa,
                    currentPage: 1
                  })
                  setPlano(e);
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            xs={12}
            md={12}
            sm={12}
            classes={{ root: styles.marginTop }}
          >
            <Grid item xs={12} md={4}>
              <DatePicker
                label="Contratação De"
                noMask
                handleChange={
                  (value) => {
                    setDefaultConfigTable({
                      ...defaultConfigTable,
                      data_contratacao_inicio: value,
                      currentPage: 1
                    })
                    setDataContratacaoInicio(value)
                  }
                }
                initDate={dataContratacaoInicio}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                label="Contratação Até"
                noMask
                handleChange={
                  (value) => {
                    setDefaultConfigTable({
                      ...defaultConfigTable,
                      data_contratacao_fim: value,
                      currentPage: 1
                    })
                    setDataContratacaoFim(value)
                  }
                }
                initDate={dataContratacaoFim}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                styleType="form"
                label="Forma de Pagamento"
                initialValue={
                  listFormaPagamento.find((item) => item.id === formaPagamento)
                    ? listFormaPagamento.find(
                        (item) => item.id === formaPagamento
                      ).nome
                    : ""
                }
                list={listFormaPagamento}
                callback={e => {
                  setDefaultConfigTable({
                    ...defaultConfigTable,
                    forma_pagamento: e,
                    currentPage: 1
                  })
                  setFormaPagamento(e);
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            xs={12}
            md={12}
            sm={12}
            classes={{ root: styles.marginTop }}
          >
            <Grid item xs={6} md={6} sm={6}>
              <DatePicker
                label="Pagamento De"
                noMask
                handleChange={
                  (value) => {
                    setDefaultConfigTable({
                      ...defaultConfigTable,
                      data_pagamento_inicio: value,
                      currentPage: 1
                    })
                    setDataPagamentoInicio(value)
                  }
                }
                initDate={dataPagamentoInicio}
              />
            </Grid>
            <Grid item xs={6} md={6} sm={6}>
              <DatePicker
                label="Pagamento Até"
                noMask
                handleChange={
                  (value) => {
                    setDefaultConfigTable({
                      ...defaultConfigTable,
                      data_pagamento_fim: value,
                      currentPage: 1
                    })
                    setDataPagamentoFim(value)
                  }
                }
                initDate={dataPagamentoFim}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            xs={12}
            md={12}
            sm={12}
            classes={{ root: styles.marginTop }}
          >
            <Grid item xs={6} md={6} sm={6}>
              <DatePicker
                label="Emissão De"
                noMask
                handleChange={
                  (value) => {
                    setDefaultConfigTable({
                      ...defaultConfigTable,
                      data_emissao_inicio: value,
                      currentPage: 1
                    })
                    setDataEmissaoInicio(value)
                  }
                }
                initDate={dataEmissaoInicio}
              />
            </Grid>
            <Grid item xs={6} md={6} sm={6}>
              <DatePicker
                label="Emissão Até"
                noMask
                handleChange={
                  (value) => {
                    setDefaultConfigTable({
                      ...defaultConfigTable,
                      data_emissao_fim: value,
                      currentPage: 1
                    })
                    setDataEmissaoFim(value)
                  }
                }
                initDate={dataEmissaoFim}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            xs={12}
            md={12}
            sm={12}
            classes={{ root: styles.marginTop }}
          >
            <Grid item xs={12}>
              <Button
                text="Limpar Filtros"
                onClick={handleLimparFiltros}
                className="default-outline clean-filters-button"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <>
      {loading && <OverlayLoading />}
      <div className="table-no-header table-relatorio-assinantes">
        <TableComponent
          idName="id"
          dataList={listRequest !== undefined ? listRequest.data : []}
          tableConfig={defaultConfigTable}
          callbackCurrentPage={onPageChange}
          callbackOrderBy={onOrderBy}
          callbackPerPage={onPerPage}
          callbackSearchTerm={onSearchTerm}
          filterComponent={filterComponent()}
          cbTableMoreAction={onTableMoreAction}
          cbLookFile={onSearchFile}
          cbInfo={onInfoClick}
          customColumns={[
            {
              columnHead: <span>Status da Fatura</span>,
              columnData: listaStatus(listRequest?.data),
            },
            {
              columnHead: <span>Status do Cliente</span>,
              columnData: listaStatusCliente(listRequest?.data),
            },
          ]}
        />
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
      <CallbackMessage
        open={callbackShown}
        errorList={callbackErrorList}
        type={callbackErrorList.length === 0 ? "success" : "error"}
        message={callbackMessage}
        duration={callbackDuration}
        handleClose={handleClose}
      />
      <DadosComplementaresDialog
        open={openDadosComplDialog}
        onClose={handleCloseDadosComplDialog}
        objDadosComplementares={dadosComplementares}
      />
    </>
  );
}

export default ListaRelatorioAssinantes;
