import React, { useState, useEffect, useCallback } from "react";

import TableComponent from "../../../../components/TableComponent";
import OverlayLoading from "../../../../components/OverlayLoading";


import "./styles.css";

function ServicosContrato({listRequest, setListRequest, isLoading}) {

  const [checkedPermissoesList, setCheckedPermissoesList] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_configuracao_plano" },
      { columnName: "SERVIÇOS", dataRef: "configuracao" },
      { columnName: "Opções", dataRef: "permissao" },
    ],
    display: {
      search: false,
      itemsPerPage: false,
      totalResults: false,
      pagination: false,
      selfContainer: true,
      checkBoxOptions: false,
    },
    currentPage: 1,
    pagination: false,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: 999,
    searchTerm: null,
  });

console.log(listRequest);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;

    const checkedItems = listRequest?.data.map( a => a.checked);
    setCheckedPermissoesList(checkedItems)
  }, [listRequest, defaultConfigTable]);

  let onCheck = useCallback((checkBoxObj) => {
    console.log(checkBoxObj);
    (async () => {
      let { id, checked } = JSON.parse(checkBoxObj);
      console.log(id, checked);
      let {data} = listRequest;
      console.log(data);

      let newData = data.map( a => {
        if(a.id_configuracao_plano === id){
          return {...a, checked: checked ? 1 : 0}
        }
        return a;
      })

      console.log(newData);
      setListRequest({
        data: newData
      })
      
    })();
  }, [listRequest.data]);

  return (
    <>
      <TableComponent
        idName="id_configuracao_plano"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        checkList={checkedPermissoesList}
        tableConfig={defaultConfigTable}
        cbCheckBox={onCheck}
      />
      {isLoading && <OverlayLoading />}
    </>
  );
}

export default ServicosContrato;
