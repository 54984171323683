import React, { useState, useEffect, memo } from 'react';
import Select from 'react-select';
import { BsChevronDown } from "react-icons/bs";

import classes from './styles.module.scss';

const DropdownIndicator = ({ innerProps }) => <BsChevronDown style={{ marginRight: 8 }} {...innerProps} />

const SelectComponent = ({
  styleType = "table",
  title = "",
  list = [],
  label = "",
  search = true,
  initialValue = null,
  required = false,
  callback = () => { },
  loading = false,
  selectUnderline = false,
  isDisabled=false
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const [inputValue, setInputValue] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleSelectOption = (value, action) => {
    setSelectedOption(value)
    callback(value.value);
  };

  const handleMenuOpen = () => {
    setInputValue("");
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  useEffect(() => {
    let mounted = true;

    if (initialValue && typeof initialValue === 'string') {
      if (mounted)
        setSelectedOption({ ...selectedOption, label: initialValue })

      return;
    }

    if (initialValue && typeof initialValue === 'object') {
      if (mounted)
        setSelectedOption({ value: initialValue.id, label: initialValue.nome });

      return;
    }

    if (initialValue && typeof initialValue === 'number') {
      if (mounted)
        setSelectedOption({ value: initialValue.id, ...selectedOption });

      return;
    }

    setSelectedOption(initialValue);
    
    return () => mounted = false;
  }, [initialValue])

  useEffect(() => {
    let mounted = true;
    if (list?.length > 0 && Array.isArray(list)) {
      if (list !== undefined) {
        let tempList = list?.map((item) => ({
          value: item.id,
          label: item.nome,
          disabled: item.desabilitar === 1,
        }));

        if (mounted) setOptions(tempList);
      }
    }else{
      setOptions([]);
    }

    return () => mounted = false;
  }, [list]);

  return (
    <>
      {label !== "" && (
        <div className={classes.labelContainer}>
          <label
            className={classes.selectLabel}
          >
            {label}
            <span className={classes.requiredItem}>
              {required && "*"}
            </span>
          </label>
        </div>
      )}
      <Select
        classNamePrefix="react-select"
        className={
          selectUnderline ? classes.selectUnderline :
            styleType === "table" ? classes.tableSelectInput : classes.formSelectInput
        }
        inputValue={inputValue}
        onInputChange={(value) => setInputValue(value)}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        isLoading={loading}
        options={options}
        placeholder={options.length !== 0 ? title : "Seleção Vazia"}
        value={menuIsOpen ? {...selectedOption, label: ""} : selectedOption}
        onChange={handleSelectOption}
        components={{ DropdownIndicator }}
        isSearchable={styleType === 'table' ? false : search}
        noOptionsMessage={() => <span>Seleção Vazia</span>}
        isOptionDisabled={(option) => option.disabled}
        isDisabled={isDisabled}
        styles={{
          placeholder: styles => ({
            ...styles, fontSize: '14px', fontStyle: 'italic',
            color: '#6c6c6c'
          }),
          indicatorSeparator: styles => ({ ...styles, display: 'none' }),
          control: (styles) => ({
            ...styles,
            border: '1px solid #C8C8C8 !important',
          }),
          menu: styles => ({ ...styles, zIndex: 999999999 }),
          menuList: styles => ({ ...styles, backgroundColor: '#fff', border: '1px solid #66afe9' }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isFocused || isSelected ? '#d4d4d4' : '#ffffff',
            color: isFocused || isSelected ? '#424242' : '#424242',
            ':active': {
              ...styles[':active'],
              backgroundColor: '#d4d4d4',
            },
          })
        }}
      />
    </>
  );
};

export default memo(SelectComponent);