import { useState } from "react";

export default function useCompanyStoraged() {
  const [empresasSelecionadas, setEmpresasSelecionadas] = useState(
    JSON.parse(sessionStorage.getItem("CB@empresasSelecionadas"))
  );

  function getEmpresaPagina(pagina) {
    if (empresasSelecionadas?.hasOwnProperty(pagina)) {
      return empresasSelecionadas[pagina];
    }
    setEmpresaPagina(pagina, null);
    return null;
  }

  function setEmpresaPagina(pagina, idEmpresa) {
    const newEmpresasSelecionadas = { [pagina]: idEmpresa };
    setEmpresasSelecionadas(newEmpresasSelecionadas);
    sessionStorage.setItem(
      "CB@empresasSelecionadas",
      JSON.stringify(newEmpresasSelecionadas)
    );
  }

  return { getEmpresaPagina, setEmpresaPagina };
}
