import React, { useState, useEffect, useCallback } from "react";

import "./styles.scss";
import categoriaServices from "../../services/categoriasServices";
import { Search } from "../../components/TableComponent";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import OverlayLoading from "../../components/OverlayLoading";
import CallbackMessage from "../../components/CallbackMessage";
import exportFileService from "../../services/exportFile";
import { GrupoComponent } from "./components/grupo";

function Categorias() {
  const { exportFile } = exportFileService;

  const [listRequest, setListRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);

  const tableOptions = {
    noSearch: true,
    filter: false,
    more: true,
    moreOptions: [
      {
        icon: "export",
        label: "Exportar XLS",
        action: "exportar-xls",
      },
      {
        icon: "export",
        label: "Exportar PDF",
        action: "exportar-pdf",
      },
    ],
  };

  const exportToPDF = {
    url: "exportar-categorias-padrao-pdf",
    fileName: "cbhub_relatorio_categorias_padrao",
    fileType: "pdf",
  };

  const exportToXLS = {
    url: "exportar-categorias-padrao-excel",
    fileName: "cbhub_relatorio_categorias_padrao",
    fileType: "xlsx",
  };

  const fetchCategory = useCallback(async () => {
    setLoading(true);
    await categoriaServices.getCategoriaPadrao().then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) setListRequest(response.data);
      setLoading(false);
    });
  }, [setListRequest, setLoading]);

  let onTableMoreAction = async ({ action }) => {
    console.log(action);

    setLoading(true);
    let params = {};
    if (action === "exportar-xls") {
      params = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: {},
      };
    } else if (action === "exportar-pdf") {
      params = {
        url: exportToPDF.url,
        fileName: exportToPDF.fileName,
        fileType: exportToPDF.fileType,
        params: {},
      };
    }
    try {
      const response = await exportFile(params);
      console.log(response);

      if (response?.status === 200 || response?.status === 201) {
        setCallbackErrorList([]);
        setCallbackShown(true);
        setCallbackMessage("Sucesso!");
        setCallbackType("success");
      } else if (response?.status === 500) {
        setCallbackErrorList([
          "Erro interno no servidor. Por favor, contate o suporte",
        ]);
        setCallbackShown(true);
        setCallbackType("error");
      } else if (response?.status === 400) {
        console.log(response);
        if (
          typeof response?.data?.error === "string" &&
          response?.data?.error?.includes("Too Many Requests")
        ) {
          setLoading(false);
          setCallbackErrorList([
            "Ocorreu um excesso de consultas. Espere 1 minuto e tente novamente.",
          ]);
          setCallbackDuration(4000);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackShown(true);
        } else if (typeof response?.data?.error === "string") {
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackDuration(4000);
          setCallbackErrorList([response?.data?.error]);
        } else if (typeof response?.data?.error === "object") {
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackDuration(4000);
          setCallbackMessage("Erro!");
          setCallbackErrorList(
            Object.values(response?.data?.error).flat(Infinity)
          );
        }
      } else {
        setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
        setCallbackShown(true);
        setCallbackDuration(4000);
        setCallbackMessage("Erro!");
        setCallbackType("error");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

    setLoading(false);
  };

  function handleClose() {
    setCallbackShown(false);
    setCallbackType("");
  }

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <>
      <div className="session-container session-categoria-padrao-responsive">
        <Search
          callback={() => {}}
          tableOptions={tableOptions}
          callbackMenu={onTableMoreAction}
        />
        <div className="space-vertical-xl" />
        {!loading && !listRequest.isEmpty ? (
          listRequest?.length > 0 ? (
            <div className="session-container pagina-subcategorias">
              {listRequest.map((item, index) => (
                <>
                  <GrupoComponent
                    item={item}
                    index={index}
                    fetchCategory={fetchCategory}
                  />
                  <div className="space-vertical" />
                </>
              ))}
            </div>
          ) : null
        ) : (
          <OverlayLoading />
        )}
      </div>

      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={() => handleClose()}
      />

      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default Categorias;
