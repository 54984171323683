import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Grid, FormControlLabel, Checkbox, withStyles, Dialog, DialogContent, useMediaQuery } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { format, parseISO } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

import Select from '../../components/SelectComponent'
import Input from '../../components/Input'
import CallbackMessage from '../../components/CallbackMessage'
import OverlayLoading from '../../components/OverlayLoading'
import Boleto from '../../views/FaturaBoleto/Boleto2'

import Logo from "../../assets/img/logo-2.svg"
import LogoBlue from "../../assets/img/logo001.svg"
import copyIcon from "../../assets/img/copy-icon.svg"

import { BiBarcodeReader } from 'react-icons/bi';
import { AiOutlineCreditCard } from 'react-icons/ai';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import buscaCepServices from '../../services/buscaCepService'
import checkoutServices from '../../services/checkoutServices'
import loginServices from '../../services/loginService'
import boletoServices from '../../services/boletoService'

import { onlyNumbers, boletoMask, cpfCnpjMask, cepMask } from '../../utils/strings'
import { numberToCurrency } from '../../utils/functions'
import { formatDate } from "../../utils/dates";

import checkCircle from '../../assets/img/circle_check.svg'
import closeCircle from '../../assets/img/circle_close.svg'
import cbLogo from '../../assets/img/cb_logo.svg'

import validateFields from '../../utils/validateFields'

import styles from './styles.module.scss'

export default function CheckoutContratacao() {
  const CustomCheckbox = withStyles({
    root: {
      color: "#c9cdd4",
      width: 0,
      borderRadius: 4,
      "&$checked": {
        color: "#EF4066"
      }
    },
    checked: {}
  })(props => <Checkbox color="default" {...props} />)
  
  const { getCep } = buscaCepServices
  const { pagamentoBoleto, pagamentoCartao } = checkoutServices
  const { checkLogin } = loginServices
  const { consultaConfiguracoes, consultaBoleto } = boletoServices
  
  const isMobile = useMediaQuery('(max-width: 768px)')
  
  const [plano, setPlano] = useState(null)
  
  const [nome, setNome] = useState('')
  const [cpf, setCpf] = useState('')
  const [cep, setCep] = useState('')
  const [endereco, setEndereco] = useState('')
  const [bairro, setBairro] = useState('')
  const [numero, setNumero] = useState('')
  const [complemento, setComplemento] = useState('')
  const [estado, setEstado] = useState('')
  const [cidade, setCidade] = useState('')
  
  const [checkedDebit, setCheckedDebit] = useState(false)
  const [checkedCredit, setCheckedCredit] = useState(false)

  const [cardNumber, setCardNumber] = useState("")
  const [cardName, setCardName] = useState("")
  const [cardValidity, setCardValidity] = useState("")
  const [cardSecurityCode, setCardSecurityCode] = useState("")

  const [showInvoicePaymentSection, setShowInvoicePaymentSection] = useState(false)
  const [digitableLine, setDigitableLine] = useState('')
  const [expirationDate, setExpirationDate] = useState('')
  
  const [callbackShown, setCallbackShown] = useState(false)
  const [callbackDuration, setCallbackDuration] = useState(6000)
  const [callbackErrorList, setCallbackErrorList] = useState([])
  const [callbackMessage, setCallbackMessage] = useState('')
  const [cardPayment, setCardPayment] = useState(false);
  const [barcodePayment, setBarcodePayment] = useState(false);
  
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showFailureModal, setShowFailureModal] = useState(false)

  const [loading, setLoading] = useState(false)

  const [boleto, setBoleto] = useState({
    linhaDigitavel: "",
    dataVencimento: "",
    beneficiario: "",
    agenciaCodigo: "",
    dataDocumento: "",
    numeroDocumento: "",
    especieDoc: "",
    aceite: "",
    dataProcessamento: "",
    nossoNumero: "",
    usoDoBanco: "",
    carteira: "",
    especie: "",
    quantidade: "",
    valor: "",
    valorDocumento: "",
    instrucao: "",
    desconto: "",
    juros: "",
    valorCobrado: "",
    nomePagador: "",
    cpfCnpjPagador: "",
    enderecoPagador: "",
    codBarras: "",
    cpfCnpjBeneficiario: "",
    enderecoBeneficiario: "",
    valorPago: ""
  });
  const [boletoConfig, setBoletoConfig] = useState({})

  const [parcels, setParcels] = useState('1')
  
  useEffect(() => {
    (async () => {
      
        const responseBoletoConfig = await consultaConfiguracoes(response.data?.empresa_plataforma)

        if (responseBoletoConfig?.status === 200) {
          setBoletoConfig(responseBoletoConfig.data[0])
        }
    }
    )()
  }, [checkLogin, consultaConfiguracoes])
  
  const handleChange = event => {
    if (event.target.name === "debit") {
      setCheckedDebit(event.target.checked)
      if (event.target.checked === true) {
        setCheckedCredit(false)
      }
    }

    if (event.target.name === "credit") {
      setCheckedCredit(event.target.checked)
      if (event.target.checked === true) {
        setCheckedDebit(false)
      }
    }
  }

  const handleChangeParcels = e => {
    if (e.target.name === '1-parcel') {
      setParcels('1')
    }

    if (e.target.name === '2-parcels') {
      setParcels('2')
    }

    if (e.target.name === '3-parcels') {
      setParcels('3')
    }
  }

  const handleCardPayment = () => {
    setCardPayment(prev => !prev);
    setBarcodePayment(false);
  }

  const handleBarcodePayment = () => {
    setBarcodePayment(prev => !prev);
    setCardPayment(false);
  }

  const handleCardNumberChange = useCallback(event => {
    setCardNumber(event.target.value)
  }, [])

  const onCopy = useCallback(() => {
    setCallbackMessage('Copiado!')
    setCallbackDuration(1200)
    setCallbackShown(true)
  }, [])
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      setCallbackShown(false)
    }

    if (reason === 'timeout') {
      setCallbackShown(false)
    }
  }

  const onInputChange = useCallback((setterFc, value) => {
    return setterFc(value)
  }, [])

  const onCepChange = useCallback(async (value) => {
    const rawCep = onlyNumbers(value.toString())
    setCep(rawCep)
    
    if (rawCep.length === 8) {
      setLoading(true)
      const response = await getCep(value)

      if (response.status === 200) {
        const { data } = response
        
        setEndereco(data.logradouro)
        setBairro(data.bairro)
        setComplemento(data.complemento)
        setEstado(data.uf)
        setCidade(data.localidade)
      }
      
      setLoading(false)
    }
    
  }, [getCep])
  
  const onCloseSuccessDialog = () => {
    setShowSuccessModal(false)
  }

  const onCloseFailureDialog = () => {
    setShowFailureModal(false)
  }
  
  
  const mockPlanos = useMemo(() => [
    {
      id: 1,
      nome: 'Trabalhador - R$ 99,00',
      valor: '99.00'
    },
    {
      id: 2,
      nome: 'Micro Empreendedor Individual - R$ 99,00',
      valor: '99.00'
    },
    {
      id: 3,
      nome: 'Renda extra - R$ 375,00',
      valor: '375.00'
    },
    {
      id: 4,
      nome: 'Investidor Ocasional - nível 1 - R$ 750,00',
      valor: '750.00'
    },
  ], [])
  
  const finalPrice = useMemo(() => {
    let selectedPlan = mockPlanos.find(item => item.id === plano)

    let hasSelected = typeof selectedPlan !== 'undefined'

    if (hasSelected) {
      return selectedPlan.valor
    }
    return 0
  }, [plano, mockPlanos])
  
  const handleInvoicePayment = useCallback(async () => {
    setLoading(true)

    console.log("boletoConfig :: ", boletoConfig)
    
    const fieldsToValidate = [
      {
        label: 'plano',
        value: plano,
      },
      {
        label: 'nome',
        value: nome,
      },
      {
        label: 'cpf',
        value: cpf,
      },
      {
        label: 'cep',
        value: cep,
      },
      {
        label: 'endereco',
        value: endereco,
      },
      {
        label: 'bairro',
        value: bairro,
      },
      {
        label: 'numero',
        value: numero,
      },
      {
        label: 'estado',
        value: estado,
      },
      {
        label: 'cidade',
        value: cidade,
      },
    ]
    
    const fieldsErrors = validateFields(fieldsToValidate)

    if (fieldsErrors.length !== 0) {
      setCallbackMessage('Erro')
      setCallbackErrorList(fieldsErrors)
      setCallbackShown(true)
      setLoading(false)
      return
    }
    
    const response = await pagamentoBoleto(cpf, cidade, cep,
      nome, estado, endereco, bairro, finalPrice)

    if (response.status === 200) {
      setDigitableLine(response.data?.linha_digitavel)
      setExpirationDate(format(parseISO(response.data?.data_vencimento), 'dd/MM/yyyy' ,{
        locale: ptBR
      }))
      
      const responseBoleto = await consultaBoleto(response.data?.boleto_id)

      if (responseBoleto.status === 200) {
        const { data } = responseBoleto

        const data_criacao = data.data_criacao.substring(0, 10);

        //data criacao em data do documento e data do processamento

        setBoleto({
          linhaDigitavel: boletoMask(data.linha_digitavel),
          dataVencimento: formatDate(data.data_vencimento),
          beneficiario: data.Beneficiario,
          agenciaCodigo: `${data.AgenciaCodigo}/${
            boletoConfig?.beneficiario_codigo || ""
          }`,
          dataDocumento: formatDate(data_criacao),
          numeroDocumento: data.numero_titulo_beneficiario,
          especieDoc: data.Especie,
          aceite: data.codigo_aceite,
          dataProcessamento: formatDate(data_criacao),
          nossoNumero: data.numero_titulo_cliente,
          usoDoBanco: "",
          carteira: data.numero_carteira,
          especie: "R$",
          quantidade: data.Quantidade,
          valor: numberToCurrency(data.valor_original).substring(
            3,
            numberToCurrency(data.valor_original).length
          ),
          valorDocumento: numberToCurrency(data.valor_original).substring(
            3,
            numberToCurrency(data.valor_original).length
          ),
          instrucao: data.intrucoes,
          desconto: numberToCurrency(data.valor_desconto).substring(
            3,
            numberToCurrency(data.valor_desconto).length
          ),
          juros: numberToCurrency(data.valor_juros).substring(
            3,
            numberToCurrency(data.valor_juros).length
          ),
          valorCobrado: numberToCurrency(
            Number(data.valor_original) -
              Number(data.valor_desconto) +
              Number(data.valor_juros)
          ).substring(
            3,
            numberToCurrency(
              Number(data.valor_original) -
                Number(data.valor_desconto) +
                Number(data.valor_juros)
            ).length
          ),
          nomePagador: data.pagador_nome,
          cpfCnpjPagador: cpfCnpjMask(response.data?.pagador_numero_registro),
          enderecoPagador: `${data.pagador_cidade} ${
            data.pagador_bairro && data.pagador_bairro !== ""
              ? "- " + data.pagador_bairro
              : ""
          }
          ${" "}${
            data.pagador_endereco && data.pagador_endereco !== ""
              ? "- " + data.pagador_endereco
              : ""
          }
          ${" "}${
            data.pagador_cep && cepMask(data.pagador_cep) !== false
              ? "- " + cepMask(data.pagador_cep)
              : ""
          }`,
          codBarras: data.codigo_barra_numerico,
          cpfCnpjBeneficiario: cpfCnpjMask(boletoConfig?.beneficiario_cnpj),
          enderecoBeneficiario: [
            `${boletoConfig?.logradouro}`,
            `${boletoConfig?.bairro} - ${boletoConfig?.municipio}/${boletoConfig?.uf} - CEP: ${
              boletoConfig?.cep && cepMask(boletoConfig?.cep) !== false
                ? cepMask(boletoConfig?.cep)
                : ""
            }`,
          ],
          valorPago: numberToCurrency(data.valor_pago)
        });
      }
    }
      
    setShowInvoicePaymentSection(true)

    setLoading(false)
  }, [cpf, cidade, cep, consultaBoleto, pagamentoBoleto,
    nome, estado, endereco, bairro, finalPrice, numero, plano, boletoConfig])
  
  
  const handleCreditCardPayment = useCallback(async () => {
    setLoading(true)
    
    const fieldsToValidate = [
      {
        label: 'plano',
        value: plano,
      },
      {
        label: 'nome',
        value: nome,
      },
      {
        label: 'cpf',
        value: cpf,
      },
      {
        label: 'tipodocartao',
        value: checkedCredit || checkedDebit,
      },
      {
        label: 'numerodocartao',
        value: cardNumber,
      },
      {
        label: 'nomeimpressonocartao',
        value: cardName,
      },
      {
        label: 'validade',
        value: cardValidity,
      },
      {
        label: 'codigodeseguranca',
        value: cardSecurityCode,
      },
    ]

    const fieldsErrors = validateFields(fieldsToValidate)

    if (fieldsErrors.length !== 0) {
      setCallbackMessage('Erro')
      setCallbackErrorList(fieldsErrors)
      setCallbackShown(true)
      setLoading(false)
      return
    }

    const response = await pagamentoCartao(cpf, nome, finalPrice, checkedDebit ?
      'DebitCard' : checkedCredit ? 'CreditCard' : null, cardNumber, cardName.toUpperCase(), 
      cardValidity, cardSecurityCode, 'http://www.braspag.com.br', checkedCredit ? parcels : null)

    if (response.status === 200) {
      setShowSuccessModal(true)
    }

    if (response.status !== 200) {
      setCallbackMessage('Erro')
      setCallbackShown(true);
      setShowFailureModal(true);
      if (response.response.data.error) {
        setCallbackErrorList(Object.values(response.response.data.error));
      } else {
        setCallbackErrorList(["Erro ao efetuar o pagamento."]);
      }
    }
    
    setLoading(false)
  }, [cpf, nome, finalPrice, plano, cardName, pagamentoCartao,
    checkedDebit, checkedCredit, cardNumber, cardValidity, cardSecurityCode])
  
    
  return (
    <main className={styles.pageWrapper}>
      <h1 className={styles.pageTitle}>Checkout de contratação</h1>
      <section className={styles.contentContainer}>

        <div className={styles.panel}>

          <div className={styles.panelContentContainer}>
            <div className={styles.logoWrapper}>
              <img  width={120} src={Logo} alt="" />
              <img width={120} src={cbLogo} alt='Contábil Bandeirantes' />
            </div>

            <div className={styles.infoWrapper}>
              <h2>Escolha seu Plano</h2>
            </div>
          </div>
        </div>


        <div className={styles.formContentWrapper}> 
        <div className={styles.planSelectWrapper}>
          <Select search={false} list={mockPlanos} styleType="form" callback={(id) => onInputChange(setPlano, id)} />
        </div>

        <div className={styles.initialForm}>
          <p>Dados Pessoais</p>

            <form>
            <fieldset>
              <Input value={nome} label="Nome" required handleInputChange={({ target }) => onInputChange(setNome, target.value)} />
            </fieldset>
            <fieldset>
              <Input value={cpf} label="CPF" tipo="cpf" required handleInputChange={({ target }) => onInputChange(setCpf, target.value)} />
            </fieldset>
            </form>

            </div>
            
            <h2>Método de Pagamento</h2>
            
            <div className={styles.paymentOptionsContainer} >
              <button onClick={handleCardPayment} className={cardPayment && styles.activeButtonPayment} ><AiOutlineCreditCard size={25} /> Cartão Crédito / Débito</button>
              <button onClick={handleBarcodePayment} className={barcodePayment && styles.activeButtonPayment}><BiBarcodeReader size={25}/> Boleto Bancário</button> 
            </div>

            {cardPayment && (
                    <div className={styles.payment}>
                      <h1 className={styles.paymentTitle}>
                      <AiOutlineCreditCard size={25} /> Dados do Cartão
                      </h1>
                     <div className={styles.paymentInfo}>
                       <Grid
                         container
                         className={styles.creditCardChoice}
                         justify="center"
                       >
                         <Grid
                           item
                           container
                           xs={12}
                           sm={12}
                           md={12}
                           justify="center"
                         >
                           <Grid className={styles.gridItemCard} item xs={6} md={6} sm={6}>
                             <div
                               className={`${styles.flexCenter} ${styles.debitCard}`}
                             >
                               <FormControlLabel
                               label='Cartão de Débito'
                               labelPlacement='start'
                                classes={{ label: styles.checkboxLabel, root: styles.resetMargin }}
                                control={
                                   <CustomCheckbox
                                     checked={checkedDebit}
                                     onChange={handleChange}
                                     icon={<RadioButtonUncheckedIcon />}
                                     checkedIcon={<RadioButtonCheckedIcon />}
                                     name="debit"
                                   />
                                 }
                               />
                            </div>
                          </Grid>
                          <Grid className={styles.gridItemCard} item xs={6} md={6} sm={6}> 
                            <div
                              className={`${styles.flexCenter} ${styles.creditCard}`}
                            >
                               <FormControlLabel
                                label='Cartão de Crédito'
                                labelPlacement='start'
                                classes={{ label: styles.checkboxLabel, root: styles.resetMargin }}
                                control={
                                   <CustomCheckbox
                                     checked={checkedCredit}
                                     onChange={handleChange}
                                     icon={<RadioButtonUncheckedIcon />}
                                     checkedIcon={<RadioButtonCheckedIcon />}
                                     name="credit"
                                  />
                                }
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      {checkedCredit && (
                        <Grid
                        container
                        className={styles.creditCardChoice}
                        justify="center"
                       >
                         <Grid className={styles.gridItem} item>
                            <div
                              className={`${styles.flexCenter} ${styles.debitCard}`}
                            >
                              <FormControlLabel
                              label='À vista'
                              labelPlacement='start'
                              classes={{ label: styles.checkboxLabel, root: styles.resetMargin }}
                              control={
                                  <CustomCheckbox
                                    checked={parcels === '1'}
                                    onChange={handleChangeParcels}
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<RadioButtonCheckedIcon />}
                                    name="1-parcel"
                                  />
                                }
                              />
                            </div>
                          </Grid>

                          <Grid className={styles.gridItem} item>
                            <div
                              className={`${styles.flexCenter} ${styles.debitCard}`}
                            >
                              <FormControlLabel
                                label='2 Parcelas'
                                labelPlacement='start'
                                classes={{ label: styles.checkboxLabel, root: styles.resetMargin }}
                                control={
                                  <CustomCheckbox
                                    checked={parcels === '2'}
                                    onChange={handleChangeParcels}
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<RadioButtonCheckedIcon />}
                                    name="2-parcels"
                                  />
                                }
                              />
                            </div>
                          </Grid>

                          <Grid className={styles.gridItem} item>
                            <div
                              className={`${styles.flexCenter} ${styles.debitCard}`}
                            >
                              <FormControlLabel
                              label='3 Parcelas'
                              labelPlacement='start'
                              classes={{ label: styles.checkboxLabel, root: styles.resetMargin }}
                                control={
                                  <CustomCheckbox
                                    checked={parcels === '3'}
                                    onChange={handleChangeParcels}
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<RadioButtonCheckedIcon />}
                                    name="3-parcels"
                                  />
                                }
                              />
                            </div>
                          </Grid>
                       </Grid>
                      )}
                       <form className={styles.paymentForm}>
                         <div className={`${styles.flexColumn}`}>
                           <Input
                             label="Número do cartão"
                             required
                             tipo="creditCard"
                             handleInputChange={handleCardNumberChange}
                           />
                         </div>
                         <div className={`${styles.flexColumn}`}>
                           <Input
                             label="Nome impresso no cartão"
                             required
                             handleInputChange={event =>
                               setCardName(event.target.value)
                             }
                           />
                         </div>

                         <Grid
                            container
                            spacing={3}
                            justify="space-around"
                            className={styles.gridFormCard}
                          >
                            <Grid item xs={6}>
                         <div className={`${styles.flexColumn}`}>
                           <Input
                             label="Validade"
                             tipo="creditCardValidity"
                             required
                             title="__/__"
                             handleInputChange={event =>
                               setCardValidity(event.target.value)
                             }
                           />
                         </div>
                         </Grid>
                         <Grid item xs={6}>
                         <div className={`${styles.flexColumn}`}>
                           <Input
                             label="Código de segurança"
                             tipo="cvv"
                             required
                             title="___"
                             handleInputChange={event =>
                               setCardSecurityCode(event.target.value)
                             }
                           />
                         </div>
                         </Grid>
                         </Grid>
                         
                       </form>
                       <div className={styles.buttonArea}>
                         <button
                           onClick={handleCreditCardPayment}
                           className="btn-secondary"
                         >Pagar</button>
                       </div>
                     </div>
                  </div>
            )}

            {barcodePayment && (
              <>
              {showInvoicePaymentSection ? (
                <>
                  <div className={styles.invoicePaymentContainer}>
                    <div className={styles.copyToClipboardSection}>
                      <p>Copiar linha digitável</p>
                      <div className={styles.digitableLine}><p>{digitableLine}</p></div>
                      <CopyToClipboard text={digitableLine} onCopy={onCopy}>
                        <div className={styles.buttonWrapper}>
                        <button> <img src={copyIcon} alt="Icone copiar"/> Copiar</button>
                        </div>
                      </CopyToClipboard>
                    </div>
          
                    {isMobile ? (
                      <div className={styles.invoiceInfoSection}>
                        <div className={styles.invoiceInfoWrapper}>
                          <div className={styles.invoiceInfoSectionFieldset}>
                            <p>Data de vencimento:</p>
                            <span>{expirationDate}</span>
                          </div>
        
                          <div className={styles.invoiceInfoSectionFieldset}>
                            <p>Valor:</p>
                            <span>{numberToCurrency(finalPrice)}</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.invoiceContainer}>
                        <Boleto boleto={boleto} />
                      </div>
                    )}
                  </div>
                  <div className={styles.buttonArea}>
                      <button
                        onClick={() => window.location = 'https://oferta.contabilhub.com.br/obrigado'}
                        className="btn-secondary"
                    >Concluir </button>
                  </div>
                </>
              ) : (
                <div className={styles.payment}>
                      <h1 className={styles.paymentTitle}>
                      <BiBarcodeReader size={25} /> Dados do Boleto
                      </h1>
                     <div className={styles.paymentInfo}> 
                       <form className={styles.paymentForm}>
                         <div className={`${styles.flexColumn}`}>
                          <Input label="CEP" tipo="cep" title="_____-___" required handleInputChange={({ target }) => onCepChange(target.value)} />
                           
                         </div>

                         <div className={`${styles.flexColumn}`}>
                          <Input value={endereco} label="Endereço" required handleInputChange={({ target }) => onInputChange(setEndereco, target.value)} />
                         </div>

                         <Grid
                            container
                            spacing={3}
                            justify="space-around"
                            className={styles.gridFormCard}
                          >
                            <Grid item xs={6}>
                         <div className={`${styles.flexColumn}`}>
                         <Input value={bairro} label="Bairro" required handleInputChange={({ target }) => onInputChange(setBairro, target.value)} />

                         </div>
                         </Grid>
                         <Grid item xs={6}>
                         <div className={`${styles.flexColumn}`}>
                         <Input value={numero} label="Número" required handleInputChange={({ target }) => onInputChange(setNumero, target.value)} />

                         </div>
                         </Grid>
                         </Grid>
                         <div className={`${styles.flexColumn}`}>
                         <Input value={complemento} label="Complemento" handleInputChange={({ target }) => onInputChange(setComplemento, target.value)} />

                         </div>

                         <Grid
                            container
                            spacing={3}
                            justify="space-around"
                            className={styles.gridFormCard}
                          >
                            <Grid item xs={6}>
                         <div className={`${styles.flexColumn}`}>
                         <Input value={cidade} label="Cidade" required handleInputChange={({ target }) => onInputChange(setCidade, target.value)} />

                         </div>
                         </Grid>
                         <Grid item xs={6}>
                         <div className={`${styles.flexColumn}`}>
                         <Input value={estado} label="Estado" required handleInputChange={({ target }) => onInputChange(setEstado, target.value)} />

                         </div>
                         </Grid>
                         </Grid>
                         
                       </form>
                       <div className={styles.buttonArea}>
                         <button
                           onClick={handleInvoicePayment}
                           className="btn-secondary"
                         >Gerar Boleto</button>
                       </div>
                     </div>
                  </div>
              )}          
              </>  
            )}
        </div>


        <div className={styles.footerInfoWrapper}>
          <img width={180} src={LogoBlue} alt="" />
              <p>CB AFONSO SERVIÇOS E TECNOLOGIA LTDA </p>
              <p>CNPJ: 30.170.362/0001-91</p>
              <p>comercial@contabilhub.com.br</p>
        </div>
        
      </section>
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackErrorList.length !== 0 ? 'error' : 'success'}
      />
       <Dialog open={showFailureModal} onClose={onCloseFailureDialog}>
        <DialogContent className={styles.successDialog}>
          <img src={closeCircle} width={145} height={145} alt="Check" />
          <p>Pagamento recusado</p>
          <span>Infelizmente seu pagamento não foi aceito, verifique com a instituição bancária ou cadastre um novo cartão e tente novamente.</span>
        
          <div className={styles.buttonArea}>
            <button
              onClick={onCloseFailureDialog}
            >Fechar</button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showSuccessModal} onClose={onCloseSuccessDialog}>
        <DialogContent className={styles.successDialog}>
          <img src={checkCircle} width={145} height={145} alt="Check" />
          <p>Pagamento realizado com sucesso!</p>
          <span>Em breve um dos nossos especialistas iniciará o processo da declaração do seu Imposto de Renda e entrará em contato com você.</span>
        
          <div className={styles.buttonArea}>
            <a href="https://oferta.contabilhub.com.br/obrigado">
            <button
              onClick={onCloseSuccessDialog}
            >Fechar</button>
            </a>
          </div>
        </DialogContent>
      </Dialog>
      {loading && <OverlayLoading />}
    </main>
  )
}