import Axios from "./Axios";

const configuracoesPlataformaService = {
  getConfig: async () => {
    const result = await Axios.get("/lista-configuracao-plataforma", {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  addConfig: async (
    integracaoNfse,
    integracaoBoleto,
    integracaoTransferencia
  ) => {
    const response = await Axios.post("/atualiza-configuracao-plataforma", {
      integracao_nfse: integracaoNfse,
      integracao_boleto: integracaoBoleto,
      integracao_transferencia: integracaoTransferencia,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  getEmailConfig: async () => {
    const result = await Axios.get("/consulta-configuracao-email-automatico")
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  addEmailConfig: async ({
    faturasVencerBloqueadoInativo,
    faturasVencerAssunto,
    faturasVencerMensagem,
    faturasVencidasBloqueadoInativo,
    faturasVencidasMensagem,
    faturasVencidasAssunto,
    contasPagarBloqueadoInativo,
    contasPagarMensagem,
    contasPagarAssunto,
    obrigacoesMensagem,
    obrigacoesBloqueadoInativo,
    obrigacoesAssunto,
  }) => {
    const response = await Axios.post("/cadastra-configuracao-email-automatico", {
      fatura_a_vencer_ativo: faturasVencerBloqueadoInativo,
      fatura_a_vencer_assunto: faturasVencerAssunto,
      fatura_a_vencer_mensagem: faturasVencerMensagem,

      fatura_vencida_ativo: faturasVencidasBloqueadoInativo,
      fatura_vencida_mensagem: faturasVencidasMensagem,
      fatura_vencida_assunto: faturasVencidasAssunto,
      
      contas_a_pagar_ativo: contasPagarBloqueadoInativo,
      contas_a_pagar_mensagem: contasPagarMensagem,
      contas_a_pagar_assunto: contasPagarAssunto,
      
      obrigacao_mensagem: obrigacoesMensagem,
      obrigacao_ativo: obrigacoesBloqueadoInativo,
      obrigacao_assunto: obrigacoesAssunto,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};

export default configuracoesPlataformaService;
