import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
} from "@material-ui/core";
import styles from "../../../../styles.module.scss";
import styles2 from "./styles.module.scss";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import InputTime from "../../../../../../../components/InputTime";

export function HorariosSemanaMobile({
  dados,
  handleChangeDadosHorariosSemana,
  locationState
}) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    console.log(panel);
    console.log(isExpanded);
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className="label">Preencha os horários da semana</div>
      </Grid>
      <Grid item xs={12}>
        <Accordion
          expanded={expanded === "segunda"}
          onChange={handleChange("segunda")}
          className={styles.accordionStyles}
        >
          <AccordionSummary>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              xs={12}
              className={`${styles.accordionSummary} ${styles2.accordionSummaryDiaSemana}`}
            >
              <Grid item className="font-title">
                <span className={`text-pleft`}>
                  <input
                    type="checkbox"
                    disabled={locationState?.details}
                    name="checkSegunda"
                    checked={dados?.horariosSemana?.segunda}
                    onChange={(event) =>
                      handleChangeDadosHorariosSemana(
                        "segunda",
                        event.target.checked,
                        true
                      )
                    }
                  />
                  Segunda-Feira
                </span>
              </Grid>
              <Grid item>
                {expanded === "segunda" ? (
                  <ExpandLess className="font-darkBlue" />
                ) : (
                  <ExpandMore className="font-orange" />
                )}
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails
            className={styles.accordionDetails}
            style={{ background: "white" }}
          >
            <div className={styles2.containerDiasSemana}>
              <div className={`${styles2.itemDiaSemana} label`}>
                Entrada
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.entradaSegunda}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "entradaSegunda",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles2.itemDiaSemana} label`}>
                Início intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.inicioIntervaloSegunda}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "inicioIntervaloSegunda",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles2.itemDiaSemana} label`}>
                Fim intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.fimIntervaloSegunda}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "fimIntervaloSegunda",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles2.itemDiaSemana} label`}>
                Saída
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.saidaSegunda}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "saidaSegunda",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Accordion
          expanded={expanded === "terca"}
          onChange={handleChange("terca")}
          className={styles.accordionStyles}
        >
          <AccordionSummary>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              xs={12}
              className={`${styles.accordionSummary} ${styles2.accordionSummaryDiaSemana}`}
            >
              <Grid item className="font-title">
                <span className="text-pleft">
                  <input
                    type="checkbox"
                    disabled={locationState?.details}
                    name="checkTerca"
                    checked={dados?.horariosSemana?.terca}
                    onChange={(event) =>
                      handleChangeDadosHorariosSemana(
                        "terca",
                        event.target.checked,
                        true
                      )
                    }
                  />
                  Terça-Feira
                </span>
              </Grid>
              <Grid item>
                {expanded === "terca" ? (
                  <ExpandLess className="font-darkBlue" />
                ) : (
                  <ExpandMore className="font-orange" />
                )}
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails
            className={styles.accordionDetails}
            style={{ background: "white" }}
          >
            <div className={styles2.containerDiasSemana}>
              <div className={`${styles.itemDiaSemana} label`}>
                Entrada
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.entradaTerca}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "entradaTerca",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Início intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.inicioIntervaloTerca}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "inicioIntervaloTerca",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Fim intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.fimIntervaloTerca}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "fimIntervaloTerca",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Saída
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.saidaTerca}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "saidaTerca",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Accordion
          expanded={expanded === "quarta"}
          onChange={handleChange("quarta")}
          className={styles.accordionStyles}
        >
          <AccordionSummary>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              xs={12}
              className={`${styles.accordionSummary} ${styles2.accordionSummaryDiaSemana}`}
            >
              <Grid item className="font-title">
                <span className="text-pleft">
                  <input
                    type="checkbox"
                    disabled={locationState?.details}
                    name="checkQuarta"
                    checked={dados?.horariosSemana?.quarta}
                    onChange={(event) =>
                      handleChangeDadosHorariosSemana(
                        "quarta",
                        event.target.checked,
                        true
                      )
                    }
                  />
                  Quarta-Feira
                </span>
              </Grid>
              <Grid item>
                {expanded === "quarta" ? (
                  <ExpandLess className="font-darkBlue" />
                ) : (
                  <ExpandMore className="font-orange" />
                )}
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails
            className={styles.accordionDetails}
            style={{ background: "white" }}
          >
            <div className={styles2.containerDiasSemana}>
              <div className={`${styles.itemDiaSemana} label`}>
                Entrada
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.entradaQuarta}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "entradaQuarta",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Início intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.inicioIntervaloQuarta}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "inicioIntervaloQuarta",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Fim intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.fimIntervaloQuarta}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "fimIntervaloQuarta",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Saída
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.saidaQuarta}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "saidaQuarta",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Accordion
          expanded={expanded === "quinta"}
          onChange={handleChange("quinta")}
          className={styles.accordionStyles}
        >
          <AccordionSummary>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              xs={12}
              className={`${styles.accordionSummary} ${styles2.accordionSummaryDiaSemana}`}
            >
              <Grid item className="font-title">
                <span className="text-pleft">
                  <input
                    type="checkbox"
                    disabled={locationState?.details}
                    name="checkQuinta"
                    checked={dados?.horariosSemana?.quinta}
                    onChange={(event) =>
                      handleChangeDadosHorariosSemana(
                        "quinta",
                        event.target.checked,
                        true
                      )
                    }
                  />
                  Quinta-Feira
                </span>
              </Grid>
              <Grid item>
                {expanded === "quinta" ? (
                  <ExpandLess className="font-darkBlue" />
                ) : (
                  <ExpandMore className="font-orange" />
                )}
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails
            className={styles.accordionDetails}
            style={{ background: "white" }}
          >
            <div className={styles2.containerDiasSemana}>
              <div className={`${styles.itemDiaSemana} label`}>
                Entrada
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.entradaQuinta}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "entradaQuinta",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Início intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.inicioIntervaloQuinta}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "inicioIntervaloQuinta",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Fim intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.fimIntervaloQuinta}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "fimIntervaloQuinta",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Saída
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.saidaQuinta}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "saidaQuinta",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Accordion
          expanded={expanded === "sexta"}
          onChange={handleChange("sexta")}
          className={styles.accordionStyles}
        >
          <AccordionSummary>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              xs={12}
              className={`${styles.accordionSummary} ${styles2.accordionSummaryDiaSemana}`}
            >
              <Grid item className="font-title">
                <span className="text-pleft">
                  <input
                    type="checkbox"
                    disabled={locationState?.details}
                    name="checkSexta"
                    checked={dados?.horariosSemana?.sexta}
                    onChange={(event) =>
                      handleChangeDadosHorariosSemana(
                        "sexta",
                        event.target.checked,
                        true
                      )
                    }
                  />
                  Sexta-Feira
                </span>
              </Grid>
              <Grid item>
                {expanded === "sexta" ? (
                  <ExpandLess className="font-darkBlue" />
                ) : (
                  <ExpandMore className="font-orange" />
                )}
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails
            className={styles.accordionDetails}
            style={{ background: "white" }}
          >
            <div className={styles2.containerDiasSemana}>
              <div className={`${styles.itemDiaSemana} label`}>
                Entrada
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.entradaSexta}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "entradaSexta",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Início intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.inicioIntervaloSexta}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "inicioIntervaloSexta",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Fim intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.fimIntervaloSexta}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "fimIntervaloSexta",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Saída
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.saidaSexta}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "saidaSexta",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Accordion
          expanded={expanded === "sabado"}
          onChange={handleChange("sabado")}
          className={styles.accordionStyles}
        >
          <AccordionSummary>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              xs={12}
              className={`${styles.accordionSummary} ${styles2.accordionSummaryDiaSemana}`}
            >
              <Grid item className="font-title">
                <span className="text-pleft">
                  <input
                    type="checkbox"
                    disabled={locationState?.details}
                    name="checkSabado"
                    checked={dados?.horariosSemana?.sabado}
                    onChange={(event) =>
                      handleChangeDadosHorariosSemana(
                        "sabado",
                        event.target.checked,
                        true
                      )
                    }
                  />
                  Sábado
                </span>
              </Grid>
              <Grid item>
                {expanded === "sabado" ? (
                  <ExpandLess className="font-darkBlue" />
                ) : (
                  <ExpandMore className="font-orange" />
                )}
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails
            className={styles.accordionDetails}
            style={{ background: "white" }}
          >
            <div className={styles2.containerDiasSemana}>
              <div className={`${styles.itemDiaSemana} label`}>
                Entrada
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.entradaSabado}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "entradaSabado",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Início intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.inicioIntervaloSabado}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "inicioIntervaloSabado",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Fim intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.fimIntervaloSabado}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "fimIntervaloSabado",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Saída
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.saidaSabado}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "saidaSabado",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Accordion
          expanded={expanded === "domingo"}
          onChange={handleChange("domingo")}
          className={styles.accordionStyles}
        >
          <AccordionSummary>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              xs={12}
              className={`${styles.accordionSummary} ${styles2.accordionSummaryDiaSemana}`}
            >
              <Grid item className="font-title">
                <span className="text-pleft">
                  <input
                    type="checkbox"
                    disabled={locationState?.details}
                    name="checkDomingo"
                    checked={dados?.horariosSemana?.domingo}
                    onChange={(event) =>
                      handleChangeDadosHorariosSemana(
                        "domingo",
                        event.target.checked,
                        true
                      )
                    }
                  />
                  Domingo
                </span>
              </Grid>
              <Grid item>
                {expanded === "domingo" ? (
                  <ExpandLess className="font-darkBlue" />
                ) : (
                  <ExpandMore className="font-orange" />
                )}
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails
            className={styles.accordionDetails}
            style={{ background: "white" }}
          >
            <div className={styles2.containerDiasSemana}>
              <div className={`${styles.itemDiaSemana} label`}>
                Entrada
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.entradaDomingo}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "entradaDomingo",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Início intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.inicioIntervaloDomingo}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "inicioIntervaloDomingo",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Fim intervalo
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.fimIntervaloDomingo}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "fimIntervaloDomingo",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className={`${styles.itemDiaSemana} label`}>
                Saída
                <InputTime
                  tipo="hhmm"
                  icon="time"
                  value={dados?.horariosSemana?.saidaDomingo}
                  disabled={locationState?.details}
                  handleInputChange={(event) =>
                    handleChangeDadosHorariosSemana(
                      "saidaDomingo",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
