import Axios from "./Axios";

const recebimentosService = {
  listarRecebimentos: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null,
    id_cliente = null,
    data_inicial = null,
    data_final = null,
    status = null
  ) => {
    const response = await Axios.get("/lista-recebimento", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
        id_cliente,
        data_inicial,
        data_final,
        status,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  consultaRecebimento: async (id) => {
    const response = await Axios.get(`/consulta-recebimento/${id}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cadastraRecebimento: async (
    id_empresa,
    arquivo,
    descricao,
    id_categoria,
    data_vencimento,
    valor,
    id_cliente,
    id_centro_custo,
    parcelas,
    emite_fatura,
    id_nota_fiscal,
    data_recebimento,
    id_conta_bancaria,
    valor_recebimento,
    conta_paga,
    numero_nota,
    data_emissao,
    codigo_de_barras
  ) => {
    const response = await Axios.post("cadastra-recebimento", {
      id_empresa,
      arquivo,
      descricao,
      id_categoria,
      data_vencimento,
      valor,
      id_cliente,
      id_centro_custo,
      parcelas,
      emite_fatura,
      id_nota_fiscal,
      data_recebimento,
      id_conta_bancaria,
      valor_recebimento,
      conta_paga,
      numero_nota,
      data_emissao,
      codigo_de_barras,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  atualizaRecebimento: async ({
    idRecebimento,
    idEmpresa,
    dataVencimento,
    idFornecedor,
    idCategoria,
    descricao,
    valor,
    dataRecebimento,
    dataEmissao,
    codigoBarras,
  }) => {
    const response = await Axios.post(`edita-pagamento/${idRecebimento}`, {
      id_empresa: idEmpresa,
      data_referencia: dataVencimento,
      id_fornecedor: idFornecedor,
      id_categoria: idCategoria,
      valor: valor,
      descricao,
      data_pagamento: dataRecebimento,
      data_emissao: dataEmissao,
      codigo_de_barras: codigoBarras,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cadastraRecebimentoExtrato: async (
    id_empresa,
    arquivo,
    descricao,
    id_categoria,
    data_vencimento,
    valor,
    id_cliente,
    id_centro_custo,
    id_conta_bancaria
  ) => {
    const response = await Axios.post("cadastra-recebimento-extrato", {
      id_empresa: id_empresa,
      arquivo: arquivo,
      descricao: descricao,
      id_categoria: id_categoria,
      data_vencimento: data_vencimento,
      valor: valor,
      id_cliente: id_cliente,
      id_centro_custo: id_centro_custo,
      id_conta_bancaria: id_conta_bancaria,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selecionaCategoriaRecebimento: async (id) => {
    const response = await Axios.get("/seleciona-categoria-recebimento", {
      params: {
        id_empresa: id,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  registrarRecebimento: async (
    idRecebimento,
    data_recebimento,
    valor_recebimento,
    id_conta_bancaria,
    multa_juros,
    abatimento_desconto
  ) => {
    const response = await Axios.post(
      `/registra-recebimento/${idRecebimento}`,
      {
        data_recebimento,
        valor_recebimento,
        id_conta_bancaria,
        multa_juros,
        abatimento_desconto,
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cancelarRecebimento: async (idRecebimento) => {
    const response = await Axios.post(`/cancela-recebimento/${idRecebimento}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  removerRecebimento: async (idRecebimento) => {
    const response = await Axios.get(`/remove-recebimento/${idRecebimento}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  exportarRelatorio: async (
    order_by = null,
    order_by_type = null,
    search_term = null,
    id_empresa = null,
    id_cliente = null,
    data_inicial = null,
    data_final = null,
    status = null
  ) => {
    const response = await Axios.get("/exportar-recebimentos", {
      params: {
        order_by,
        order_by_type,
        search_term,
        id_empresa,
        id_cliente,
        data_inicial,
        data_final,
        status,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selecionaStatusRecebimento: async () => {
    const response = await Axios.get("/seleciona-status-recebimento")
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  importarRecebimento: async ({ arquivo, id_empresa }) => {
    const response = await Axios.post(`/importar-recebimentos`, {
      arquivo,
      id_empresa,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  estornarRecebimento: async (id_lancamento) => {
    const response = await Axios.post(`/estorna-recebimento/${id_lancamento}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};

export default recebimentosService;
