import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ButtonComponent from "../ButtonComponent";
import InputComponent from '../Input';
import DatePicker from '../DatePicker';

import "./styles.scss";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import CallbackMessage from "../CallbackMessage";
import InputTime from "../InputTime";
import pdvService from "../../services/pdvService";
import validateFields from "../../utils/validateFields";

function ModalCaixa({
  infoCaixa = null,
  open = false,
  onClose = () => { }
}) {

  console.log(infoCaixa);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(4000);
  const { editaCaixa } = pdvService;

  const [valor, setValor] = useState('');
  const [horaAbertura, setHoraAbertura] = useState('');
  const [dataFechamento, setDataFechamento] = useState(null);
  const [horaFechamento, setHoraFechamento] = useState(null);
  const [dataAbertura, setDataAbertura] = useState(null);
  // const [dataAbertura, setDataAbertura] = useState(null);

  async function handleSubmit() {
    setLoading(true);
    let hasErrors = false;
    const fieldsToValidate = [
      {
        label: "data_abertura",
        value: dataAbertura,
      },
      {
        label: "hora_abertura",
        value: horaAbertura,
      },
      {
        label: "data_fechamento",
        value: dataFechamento,
      },
      {
        label: "hora_fechamento",
        value: horaFechamento,
      },
      {
        label: "valor",
        value: dataAbertura,
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      hasErrors = true;
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(fieldsErrors);
      setCallbackShown(true);
      setLoading(false);
    }

    if (hasErrors) return;
    const dataAberturaTemp = `${dataAbertura} ${horaAbertura}`;
    console.log(dataAberturaTemp);
    const dataFechamentoTemp = `${dataFechamento} ${horaFechamento}`;
    console.log(dataFechamentoTemp);
    let response = await editaCaixa({
      id_caixa: infoCaixa?.id_caixa, data_abertura: dataAberturaTemp,
      data_fechamento: dataFechamentoTemp, valor_inicial: Number(valor), numero_caixa: infoCaixa?.numero_caixa
    });
    console.log(response);
    if (response?.status === 200 || response?.status === 201) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType('success');
      setCallbackMessage('Caixa editado com sucesso!');
      setCallbackDuration(1000);
      return;
    }
    if (response?.status === 400) {
      console.log('here');
      setCallbackErrorList(['Ocorreu um erro ao editar o caixa']);
      setLoading(false);
      setCallbackShown(true);
      setCallbackType('error');
      setCallbackMessage('Erro!');
      return;
    }
    if (response?.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    } else {
      if (response?.data?.error) {
        setCallbackErrorList(Object.values(response?.data?.error));
        return;
      } else {
        console.log('here');
        setCallbackErrorList(['Ocorreu um erro ao editar o caixa']);
        setLoading(false);
        setCallbackShown(true);
        setCallbackType('error');
        setCallbackMessage('Erro!');
        return
      }
    }

  }

  function handleClose(event, reason) {
    console.log(callbackErrorList);
    setCallbackShown(false);
    if (callbackErrorList.length === 0) {
      handleCloseModal(true);
    }
  }

  const handleCloseModal = async (success = false) => {
    onClose(success);
  }

  useEffect(() => {
    if (!infoCaixa?.data_criacao) return
    const time = new Date(infoCaixa?.data_criacao).toLocaleTimeString('en',
      { timeStyle: 'short', hour12: false, timeZone: 'UTC' })
    const horaFechamento = new Date(infoCaixa?.data_fechamento);
    let horaFechamentoTemp = horaFechamento.getHours() >= 10 ? horaFechamento.getHours() : `0${horaFechamento.getHours()}`
    let minutosFechamentoTemp = horaFechamento.getMinutes() >= 10 ? horaFechamento.getMinutes() : `0${horaFechamento.getMinutes()}`
    const timeFechamento = `${horaFechamentoTemp}:${minutosFechamentoTemp}`;
    console.log(time);
    console.log(timeFechamento);
    console.log(infoCaixa?.data_fechamento_inicial);
    console.log(infoCaixa?.data_criacao_inicial);
    setHoraAbertura(time)
    setHoraFechamento(timeFechamento)
    setValor(infoCaixa?.valor_inicial);
    setDataAbertura(infoCaixa?.data_criacao_inicial);
    setDataFechamento(infoCaixa?.data_fechamento_inicial ? infoCaixa?.data_fechamento_inicial?.split(' ')[0] : null);
    setCallbackErrorList([]);
  }, [infoCaixa])

  return (
    <Dialog open={open} onClose={() => handleCloseModal()}>
      {loading && <OverlayLoading />}
      <div className="modal-title-area">
        <DialogTitle>
          <span className="modal-title">
            Edição do Caixa
          </span>
        </DialogTitle>
        <IconButton
          onClick={() => handleCloseModal()}
          size="medium"
          style={{ marginRight: 16, color: "#EF4066", padding: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="modal-content">
        <Grid container justify="space-between" spacing={2}>
          <Grid item xs sm md={4}>
            <DatePicker
              label="Data de abertura *"
              required
              value={dataAbertura}
              initDate={infoCaixa?.data_criacao_inicial ? infoCaixa?.data_criacao_inicial : null}
              handleChange={(date) => {
                setDataAbertura(date);
              }}
            ></DatePicker>
          </Grid>
          <Grid item xs sm md={2}>
            <InputTime
              tipo="hhmm"
              required
              label="Hora"
              value={horaAbertura}
              handleInputChange={(event) => setHoraAbertura(event.target.value)}
            />
          </Grid>
          <Grid item xs sm md={4}>
            <DatePicker
              label="Data de fechamento *"
              required
              value={dataFechamento}
              initDate={infoCaixa?.data_fechamento_inicial ? infoCaixa?.data_fechamento_inicial?.split(' ')[0] : null}
              handleChange={(date) => {
                setDataFechamento(date);
              }}
            ></DatePicker>
          </Grid>
          <Grid item xs sm md={2}>
            <InputTime
              tipo="hhmm"
              required
              label="Hora"
              value={horaFechamento}
              handleInputChange={(event) => setHoraFechamento(event.target.value)}
            />
          </Grid>
          <Grid item xs sm md={6}>
            <InputComponent
              tipo="dinheiro"
              required
              label="Valor"
              value={valor}
              handleInputChange={(event) => setValor(event.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <ButtonComponent
              text="Salvar"
              className="btn-primary button-flex-end"
              onClick={() => handleSubmit()}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {callbackShown && (
        <CallbackMessage
          open={callbackShown}
          type={callbackType}
          handleClose={handleClose}
          message={callbackMessage}
          duration={callbackDuration}
          errorList={callbackErrorList}
        />
      )}
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}

export default ModalCaixa;
