import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { documentacaoLegalService } from "../../services/documentacaoLegalService";

import OverlayLoading from "../../components/OverlayLoading";
import TableComponent from "../../components/TableComponent";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import ConfirmDialog from "../../components/ConfirmDialog";
import CallbackMessage from "../../components/CallbackMessage";

import { Grid } from "@material-ui/core";
import Select from "../../components/SelectComponent";
import Button from "../../components/ButtonComponent";

import { base64ToFile } from "../../utils/functions";
import { useIsMount } from "../../hooks/useIsMount";
import useCompany from "../../hooks/useCompany";

function DocumentoLegalLista() {
  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("documentacao_legal");
  const isMount = useIsMount();

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [listRequest, setListRequest] = useState([]);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Data Cadastro", dataRef: "data_hora" },
      { columnName: "Empresa", dataRef: "razao_social" },
      { columnName: "Título", dataRef: "titulo" },
      { columnName: "Categoria", dataRef: "nome" },
    ],
    options: {
      edit: true,
      delete: true,
      download: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    tableOptions: {
      filter: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    id_empresa: null,
  });

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onEdit = (id) => {
    history.push(`editar-documento-legal/${id}`);
  };

  let handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  let handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  let onDelete = async () => {
    setLoading(true);
    const response = await documentacaoLegalService.deleteDocumentacao(
      deleteId
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    console.log(response);

    if (response.status === 200) {
      setShowMessage(true);
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
    } else {
      setShowMessage(true);
      if (response.data.error) setErrors(Object.values(response.data.error));
      else setErrors(["Ocorreu um erro"]);
    }
    setLoading(false);
    handleRemoveCancel();
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setShowMessage(false);
    }
    if (reason === "timeout") {
      setShowMessage(false);
    }
  }

  let onDownload = async (id) => {
    await documentacaoLegalService.consultaDocumentacao(id).then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200 && response.data.arquivo) {
        base64ToFile(response.data.arquivo, "anexo");
      }
    });
  };

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await documentacaoLegalService.getDocumentacao(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.id_empresa
    );
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.status === 200) {
      let list = [];
      let l = [];
      if (result.data.hasOwnProperty("data")) list = result.data.data;
      else list = result.data;

      list.forEach((item) => {
        l.push({
          id: item.id_documento_legal,
          data_hora: new Date(item.data_hora).toLocaleDateString("pt-br"),
          razao_social: item.razao_social,
          titulo: item.titulo,
          nome: item.nome,
          arquivo: item.arquivo,
        });
      });

      if (result.data.hasOwnProperty("data")) {
        result.data.data = l;
        console.log(result.data);
        setListRequest(result.data);
      } else {
        result.data = l;
        setListRequest(result);
      }
    }

    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
  ]);

  const handleSelectEmpresa = async (id) => {
    console.log(id);
    setEmpresaSelecionada(id);
  };

  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      return setDefaultConfigTable({
        ...defaultConfigTable,
        currentPage: 1,
        id_empresa: empresaStorage,
      });
    }
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_empresa: empresaSelecionada,
    });
    setEmpresaPagina("documentacao_legal", empresaSelecionada);
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
    });
    setEmpresaPagina("documentacao_legal", null);
  };

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                      ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={handleSelectEmpresa}
            />
          </Grid>
          <Grid className="marginLeftAuto">
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("documentacao_legal");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onEdit}
        cbDelete={handleRemoveDialog}
        cbDownload={onDownload}
        filterComponent={filterComponent()}
      />
      {showMessage && (
        <CallbackMessage
          open={showMessage}
          type={`${errors.length === 0 ? "success" : "error"}`}
          message={`${
            errors.length === 0
              ? "Documento Legal excluído com sucesso!"
              : "Erro!"
          }`}
          errorList={errors}
          handleClose={handleClose}
          duration={errors.length === 0 ? 2000 : 6000}
        />
      )}
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default DocumentoLegalLista;
