import Axios from "./Axios";

const ofxService = {
  importarOfx: async (idConta, file) => {
    const result = await Axios.post("importar-ofx", {
      ofx: file,
      id_conta_bancaria: idConta,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};

export default ofxService;
