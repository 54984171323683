import { Dialog, DialogTitle, Grid } from "@material-ui/core";
import React, { useState } from "react";
import validateFields from "../../../utils/validateFields";
import ButtonComponent from "../../ButtonComponent";
import CallbackMessage from "../../CallbackMessage";
import InputComponent from "../../Input";
import ModalLoginExpirado from "../../ModalLoginExpirado";
import OverlayLoading from "../../OverlayLoading";
import Switch from "../../Switch";
import "./styles.scss";


function ModalCadastroPDV({
  open,
  onClose,
  onSave,
}) {
  const [dadoCliente, setDadoCliente] = useState("");
  const [cadastrarCliente, setCadastrarCliente] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  async function handleSubmit() {
    setErrors([]);

    let fields = [];

    if(cadastrarCliente) {
      fields.push(
        {
          label: "dado-cliente",
          value: dadoCliente,
        },
      );
    } 
    
    const fieldsValidate = validateFields(fields);

    if (fieldsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(fieldsValidate);
      return;
    }

    if(cadastrarCliente) {
      onSave(dadoCliente);
      setDadoCliente("");
      setCadastrarCliente(false);
    } else {
      onSave();
      setDadoCliente("");
      setCadastrarCliente(false);
    }

    setLoading(false);
  }

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }


  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => {}} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>CADASTRO CPF/CNPJ</span>
          </Grid>
          {/* <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid> */}
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
              <Grid container spacing={1} item xs={12} md={12} className="empresa-nfe">
                <Grid item className="container-input-switch">
                  Deseja cadastrar CPF / CNPJ do cliente?
                  
                  <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                    Não
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={cadastrarCliente}
                      onClick={() => setCadastrarCliente(!cadastrarCliente)}
                    />
                  </Grid>
                  <Grid item >
                    Sim
                  </Grid>
                </Grid>
                </Grid>

                {cadastrarCliente && (
                  <>
                <Grid item xs={12} sm={12} md={12}>
                <InputComponent
                  nome="dado-cliente"
                  label="Informe o CPF/CNPJ do cliente"
                  tipo="text"
                  value={dadoCliente}
                  handleInputChange={(event) => {
                    setDadoCliente(event.target.value);
                  }}
                />
                </Grid>
                <Grid item xs={12}>
                <span className="required-text">* Campos obrigatórios</span>
                </Grid>
                </>
                )}

              <Grid container justify="flex-end" item>
                <ButtonComponent
                  text="Salvar"
                  onClick={handleSubmit}
                  className="btn-success zero-margin"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalCadastroPDV;
