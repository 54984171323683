import React, { useEffect } from "react";
import { DialogTitle, Dialog, DialogContent, Grid } from "@material-ui/core";
import ButtonComponent from "../ButtonComponent";
import { useHistory } from "react-router-dom";

export default function ModalAcessoExpirado({ open }) {
  const history = useHistory();

  useEffect(() => {
    window.localStorage.removeItem("acesso_negado");
  }, []);

  return (
    <Dialog open={open}>
      <div className="modal-title-area">
        <DialogTitle>
          <span className="modal-title">ACESSO NEGADO</span>
        </DialogTitle>
      </div>
      <DialogContent className="modal-content">
        <Grid container direction="column" spacing={4} className="modal-items">
          <Grid item xs={12}>
            Você não possui permissão para acessar essa página.
          </Grid>
          <Grid item xs container justify="flex-end">
            <ButtonComponent
              className="btn-success margin-zero"
              text="Voltar"
              onClick={() => {
                window.sessionStorage.removeItem("acesso_negado");
                history.goBack();
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
