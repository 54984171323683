import Axios from "./Axios";

const cartoesPontoService = {
  getCartoesPonto: async ({
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null,
    status = null,
  }) => {
    const result = await Axios.get("/lista-dp-cartao-ponto", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
        status,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  cadastrarCartaoPonto: async ({
    dados,
    idEmpresa,
    dataInicio,
    dataFim,
    idFuncionario,
  }) => {
    const response = await Axios.post(
      "/cadastra-dp-cartao-ponto",
      {
        id_empresa: idEmpresa,
        data_inicio: dataInicio,
        data_fim: dataFim,
        id_funcionario: idFuncionario,
        dados: dados,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  editarCartaoPonto: async ({
    id,
    dados,
    idEmpresa,
    dataInicio,
    dataFim,
    idFuncionario,
  }) => {
    const response = await Axios.post(
      `/edita-cartao-ponto/${id}`,
      {
        id_empresa: idEmpresa,
        data_inicio: dataInicio,
        data_fim: dataFim,
        id_funcionario: idFuncionario,
        dados: dados,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  carregarDocumentoCartaoPonto: async (formData) => {
    const response = await Axios.post(
      "/carregar-dp-documento-cartao-ponto",
      formData
    )
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  consultaCartaoPonto: async (id) => {
    const response = await Axios.get(`/consulta-cartao-ponto/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  confirmarFechamento: async (id) => {
    const response = await Axios.post(`/dp-fecha-cartao-ponto/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  confirmarFechamentoPeriodo: async ({ dataInicio, dataFim }) => {
    const response = await Axios.post(`/dp-fecha-cartao-ponto`, {
      data_inicio: dataInicio,
      data_fim: dataFim,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  getHistoricoCartaoPonto: async ({
    id = null,
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
  }) => {
    const result = await Axios.get(`dp-historico-fecha-cartao-ponto/${id}`, {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};
export default cartoesPontoService;
