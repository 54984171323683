import React from "react";
import { Tooltip, withStyles } from "@material-ui/core";
import classes from "./styles.module.scss";
import "./styles.scss";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    color: "rgba(0, 0, 0, 0.87)",
    padding: 15,
    background: "#F9F9F9",
    borderRadius: 4,
    filter: "drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25))",
  },
}))(Tooltip);

export function TooltipComponent({ label = "", children }) {
  return (
    <div className={classes.suffixLabel}>
      <HtmlTooltip arrow title={label} placement="top-start">
        {children}
      </HtmlTooltip>
    </div>
  );
}
