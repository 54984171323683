import { Grid } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Button from "../../../components/ButtonComponent";
import CallbackMessage from "../../../components/CallbackMessage";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import OverlayLoading from "../../../components/OverlayLoading";
import Select from "../../../components/SelectComponent";
import TableComponent from "../../../components/TableResponsiveComponent";
import empresasServices from "../../../services/empresasService";
import preAdmissaoService from "../../../services/dpPreAdmissaoService";
import exportData from "../../../services/exportData";
import { ModalUploadDocumento } from "../../../components/ModalUploadDocumento";
import { ModalListaDocumento } from "../../../components/ModalListaDocumento";
import { toDateDD_MM_YYYY } from "../../../utils/dates";
import agendamentoFeriasService from "../../../services/dpAgendamentoFeriasService";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import useCompany from "../../../hooks/useCompany";
import { useIsMount } from "../../../hooks/useIsMount";

const exportToPDF = {
  url: "exportar-relatorio-dp-agendamento-ferias-pdf",
  fileName: "cbhub_relatorio_agendamento_ferias",
  fileType: "pdf",
};

const exportToXLS = {
  url: "exportar-relatorio-dp-agendamento-ferias-xls",
  fileName: "cbhub_relatorio_agendamento_ferias",
  fileType: "xlsx",
};

const statusList = [
  { id: 0, nome: "Aguardando" },
  { id: 1, nome: "Agendado" },
];

function filterComponent({
  empresaSelecionada,
  setEmpresaSelecionada,
  setStatusSelecionado,
  statusSelecionado,
  handleApplyFilters,
  handleCleanFilters,
  listEmpresas,
}) {
  return (
    <div className="session-container filter-component-container chat">
      <Grid container spacing={2} alignItems="center" className="form-table">
        <Grid item xs={12}>
          <Select
            styleType="form"
            label="Empresa"
            required
            initialValue={
              empresaSelecionada !== undefined
                ? listEmpresas.find((item) => item.id === empresaSelecionada)
                    ?.nome
                : ""
            }
            title="Selecione uma empresa"
            list={listEmpresas}
            callback={(id) => setEmpresaSelecionada(id)}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            styleType="form"
            label="Status"
            required
            initialValue={
              statusSelecionado !== undefined
                ? statusList.find((item) => item.id === statusSelecionado)?.nome
                : ""
            }
            title="Selecione um status"
            list={statusList}
            callback={(id) => setStatusSelecionado(id)}
          />
        </Grid>
        <Grid className="marginLeftAuto">
          <div className="filter-button-area align-rigth">
            <Button
              onClick={handleApplyFilters}
              text="Filtrar"
              className="btn-primary"
            />
            <Button
              onClick={handleCleanFilters}
              text="Limpar Filtros"
              className="default-outline clean-filters-button"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export function ListaAgendamentoFerias() {
  const history = useHistory();
  const { isAdmin } = useContext(AuthContext);

  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("agendamento_ferias");
  const isMount = useIsMount();

  const { getAgendamentos, confirmarAgendamento, excluirAgendamento } = agendamentoFeriasService;

  const { uploadArquivo } = preAdmissaoService;

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [loading, setLoading] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(4000);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_agendamento_ferias_funcionario" },
      { columnName: "Empresa", dataRef: "nome_empresa" },
      { columnName: "CPF do  Funcionário", dataRef: "cpf" },
      { columnName: "Nome", dataRef: "nome" },
      { columnName: "Data cadastro", dataRef: "created_at" },
      { columnName: "Início das férias", dataRef: "data_inicio" },
      {
        columnName: "Fim das férias",
        dataRef: "data_fim",
      },
      { columnName: "Abono", dataRef: "abono" },
      {
        columnName: "Adiantamento",
        dataRef: "adiantar_decimo_terceiro_status",
      },
      { columnName: "Status", dataRef: "status" },
    ],
    options: {
      schedule: isAdmin,
      details: true,
      trash: isAdmin,
      more: [
        { icon: "plus-circle", label: "Anexar documentos", action: "anexar" },
        { icon: "download", label: "Documentos anexados", action: "anexados" },
      ],
    },
    text: {
      schedule: "Confirmar agendamento",
      details: "Visualizar agendamento",
      download_dp: "Documentos anexados",
      upload_dp: "Anexar documentos",
      trash: "Excluir agendamento"
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      statusLabels: true,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-xls",
        },
        {
          icon: "export",
          label: "Exportar PDF",
          action: "exportar-pdf",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: "id_agendamento_ferias_funcionario",
    orderByType: "desc",
    perPage: null,
    searchTerm: null,
    id_empresa: null,
    status: null,
  });

  const [listRequest, setListRequest] = useState([]);

  const [statusSelecionado, setStatusSelecionado] = useState(null);

  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [confirmSolicitacaoDialog, setConfirmSolicitacaoDialog] =
    useState(false);
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [showModalListaUploads, setShowModalListaUploads] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await getAgendamentos({
      page: defaultConfigTable.currentPage,
      orderBy: defaultConfigTable.orderBy,
      orderByType: defaultConfigTable.orderByType,
      perPage: defaultConfigTable.perPage,
      searchTerm: defaultConfigTable.searchTerm,
      id_empresa: defaultConfigTable.id_empresa,
      status: defaultConfigTable.status,
    });
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    } else if (result.status === 200 || result.status === 201) {
      const resultData = result.data;

      if (resultData.hasOwnProperty("data")) {
        const { data } = resultData;
        const newData = data.map((item) => {
          return {
            ...item,
            status: item.status_name,
            data_inicio: toDateDD_MM_YYYY(item.data_inicio),
            data_fim: toDateDD_MM_YYYY(item.data_fim),
          };
        });
        const newResult = {
          ...resultData,
          data: newData,
        };
        setListRequest(newResult);
      } else {
        const newData = resultData.map((item) => {
          return {
            ...item,
            status: item.status_name,
            data_inicio: toDateDD_MM_YYYY(item.data_inicio),
            data_fim: toDateDD_MM_YYYY(item.data_fim),
          };
        });
        const newResult = {
          ...resultData,
          data: newData,
        };
        setListRequest(newResult);
      }
    }

    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
    defaultConfigTable.status,
  ]);

  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      return setDefaultConfigTable({
        ...defaultConfigTable,
        currentPage: 1,
        id_empresa: empresaStorage,
      });
    }
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_empresa: empresaSelecionada,
      status: statusSelecionado,
    });
    setEmpresaPagina("agendamento_ferias", empresaSelecionada);
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setStatusSelecionado(null);
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
      status: null,
    });
    setEmpresaPagina("agendamento_ferias", null);
  };

  const onTableMoreAction = async ({ action }) => {
    console.log(action);

    let params = {};
    if (action === "exportar-xls") {
      params = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: {
          search_term: defaultConfigTable.searchTerm,
          id_empresa: defaultConfigTable.id_empresa,
          status: defaultConfigTable.status,
        },
      };
    } else if (action === "exportar-pdf") {
      params = {
        url: exportToPDF.url,
        fileName: exportToPDF.fileName,
        fileType: exportToPDF.fileType,
        params: {
          search_term: defaultConfigTable.searchTerm,
          id_empresa: defaultConfigTable.id_empresa,
          status: defaultConfigTable.status,
        },
      };
    }

    setLoading(true);
    const response = await exportData(params);
    console.log(response);

    if (response.status === 500) {
      setCallbackErrorList([
        "Erro interno no servidor. Por favor, contate o suporte",
      ]);
      setCallbackShown(true);
      setCallbackType("error");
    } else if (response.status !== 201 && response.status !== 200) {
      setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
      setCallbackShown(true);
      setCallbackType("error");
    }

    setLoading(false);
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  const handleModalUploadCancel = () => {
    setCurrentId(null);
    setShowModalUpload(false);
  };

  const onUpload = async (files = []) => {
    setLoading(true);

    const idFuncionario = listRequest.data?.find(
      (item) =>
        Number(item.id_agendamento_ferias_funcionario) === Number(currentId)
    )?.id_funcionario;

    const formData = new FormData();
    formData.append("id_agendamento_ferias", currentId);
    formData.append("id_funcionario", idFuncionario);
    formData.append("tipo_doc", "agendamento_ferias");
    files?.forEach((item, index) => {
      formData.append(`documentos[${index}][nome]`, item.nome);
      formData.append(`documentos[${index}][arquivo]`, item.arquivo);
    });
    const response = await uploadArquivo(formData);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setLoading(false);
      handleModalUploadCancel();
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        response?.data?.mensagem || "Upload realizado com sucesso!"
      );
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
      return;
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage(response?.data?.msg || "Erro!");
      console.log(response?.data);
      if (response?.data?.error && Array.isArray(response?.data?.error)) {
        let errorTemp = [];
        response?.data?.error?.map((item) => {
          errorTemp.push(...Object.values(item));
        });
        setCallbackErrorList(errorTemp);
      } else if (typeof response.data.error === "string") {
        setCallbackErrorList([response.data.error]);
      } else if (
        response?.data?.error &&
        !Array.isArray(response?.data?.error)
      ) {
        setCallbackErrorList(Object.values(response.data.error));
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
      handleModalUploadCancel();
    }
    handleModalUploadCancel();
  };

  const onOpenUpload = (id) => {
    console.log(id);
    setCurrentId(id);
    setShowModalUpload(true);
  };

  const handleModalListaUploadsCancel = () => {
    setCurrentId(null);
    setShowModalListaUploads(false);
  };

  const onOpenDownloadDp = (id) => {
    const idFuncionario = listRequest.data?.find(
      (item) => Number(item.id_agendamento_ferias_funcionario) === Number(id)
    )?.id_funcionario;
    setCurrentId(idFuncionario);
    setShowModalListaUploads(true);
  };

  const onSchedule = (id) => {
    setCurrentId(id);
    setConfirmSolicitacaoDialog(true);
  };

  const handleRemoveDialog = (id) => {
    setCurrentId(id);
    setConfirmRemoveDialog(true);
  };

  const handleRemoveCancel = () => {
    setCurrentId(null);
    setConfirmRemoveDialog(false);
  };

  const handleAgendamentoCancel = () => {
    setCurrentId(null);
    setConfirmSolicitacaoDialog(false);
  };

  const confirmaAgendamento = async () => {
    setLoading(true);
    const response = await confirmarAgendamento(currentId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 201) {
      setLoading(false);
      handleAgendamentoCancel();
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        response?.data?.mensagem ||
          "Confirmação do agendamento de férias realizada com sucesso!"
      );
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
      return;
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage(response?.data?.msg || "Erro!");
      if (response?.data?.error && Array.isArray(response?.data?.error)) {
        let errorTemp = [];
        response?.data?.error?.map((item) => {
          errorTemp.push(...Object.values(item));
        });
        setCallbackErrorList(errorTemp);
      } else if (typeof response.data.error === "string") {
        setCallbackErrorList([response.data.error]);
      } else if (
        response?.data?.error &&
        !Array.isArray(response?.data?.error)
      ) {
        setCallbackErrorList(Object.values(response.data.error));
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
      handleAgendamentoCancel();
    }
    handleAgendamentoCancel();
  };

  const onDelete = async () => {
    setLoading(true);
    const response = await excluirAgendamento(currentId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setLoading(false);
      handleRemoveCancel();
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
          response?.data?.mensagem ||
          "Agendamento de férias excluído com sucesso!"
      );
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
          newDefaultConfigTable.currentPage > 1 &&
          listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
            newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
      return;
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage(response?.data?.msg || "Erro!");
      if (response?.data?.error && Array.isArray(response?.data?.error)) {
        let errorTemp = [];
        response?.data?.error?.map((item) => {
          errorTemp.push(...Object.values(item));
        });
        setCallbackErrorList(errorTemp);
      } else if (typeof response.data.error === "string") {
        setCallbackErrorList([response.data.error]);
      } else if (
          response?.data?.error &&
          !Array.isArray(response?.data?.error)
      ) {
        setCallbackErrorList(Object.values(response.data.error));
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
      handleRemoveCancel();
    }
    handleRemoveCancel();
  };

  const onDetails = (id) => {
    history.push(`/consultar-agendamento/${id}`);
  };

  let onMoreAction = async (action) => {
    switch (action.action) {
      case "anexar":
        onOpenUpload(action.id);
        break;
      case "anexados":
        onOpenDownloadDp(action.id);
        break;
      default:
        break;
    }
  };

  const onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onOrderBy = (newOrderBy) => {
    let orderBy =
      newOrderBy.orderBy === "adiantar_decimo_terceiro_status"
        ? "adiantar_decimo_terceiro"
        : newOrderBy.orderBy === "status"
        ? "id_status_agendamento_ferias"
        : newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("agendamento_ferias");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}

      <TableComponent
        idName="id_agendamento_ferias_funcionario"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbTableMoreAction={onTableMoreAction}
        cbDetails={onDetails}
        cbSchedule={onSchedule}
        cbMoreAction={onMoreAction}
        cbDelete={handleRemoveDialog}
        filterComponent={filterComponent({
          empresaSelecionada,
          setEmpresaSelecionada,
          handleApplyFilters,
          handleCleanFilters,
          setStatusSelecionado,
          statusSelecionado,
          listEmpresas,
        })}
        pageId="lista-agendamento-ferias"
      />

      <ConfirmDialog
        open={confirmSolicitacaoDialog}
        onCancel={handleAgendamentoCancel}
        onAccept={confirmaAgendamento}
        btnClass="btn-success"
        cancelClass="default-outline"
        acceptLabel={"Confirmar"}
        description="Você tem certeza que deseja confirmar o agendamento de férias?"
        title="CONFIRMAR AGENDAMENTO DE FÉRIAS"
      />

      <ConfirmDialog
          open={confirmRemoveDialog}
          onCancel={handleRemoveCancel}
          onAccept={onDelete}
          btnClass="btn-success"
          cancelClass="default-outline"
          acceptLabel={"Confirmar"}
          description="Você tem certeza que deseja excluir o agendamento de férias?"
          title="CONFIRMAR EXCLUSÃO"
      />

      <ModalUploadDocumento
        open={showModalUpload}
        onCancel={handleModalUploadCancel}
        onAccept={onUpload}
        btnClass="btn-success"
        cancelClass="default-outline"
        acceptLabel={"Salvar"}
        description=""
        title="ANEXAR DOCUMENTOS"
      />

      <ModalListaDocumento
        id={currentId}
        open={showModalListaUploads}
        description=""
        title="DOCUMENTOS ANEXADOS"
        onClose={handleModalListaUploadsCancel}
        tipo="agendamento_ferias"
      />

      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={handleClose}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
