import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ClearCacheProvider } from 'react-clear-cache';

import { PDVProvider } from './contexts/PDVContext';

import App from './App.js';

ReactDOM.render(
  <ClearCacheProvider auto>
    <Router>
      <PDVProvider>
        <App />
      </PDVProvider>
    </Router>
  </ClearCacheProvider>,
  document.getElementById('root')
);
