import React, { useState } from "react";
import { Tooltip, withStyles } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import InputMask from "react-input-mask";
import classes from "./styles.module.scss";
import OverlayLoading from "../OverlayLoading";
import MoneyMask from "react-number-format";
import { onlyNumbers, percentageMask } from "../../utils/strings";
import "./styles.scss";
import Icon from "../Icon";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    color: "rgba(0, 0, 0, 0.87)",
    padding: 15,
    background: "#F9F9F9",
    borderRadius: 4,
    filter: "drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25))",
    fontSize: 14,
    fontFamily: "Lato Regular",
  },
}))(Tooltip);

function Input({
  title = "",
  label = "",
  tipo = "",
  type = "text",
  nome = "",
  error = false,
  underline = false,
  onInputChange = () => {},
  required = false,
  handleInputChange = () => {},
  loading = false,
  icon,
  labelSuffix = null,
  handleMouseLeave = () => {},
  children,
  ...rest
}) {
  const [valueSelect, setValueInput] = useState("");

  function handleOnInputWrap(event) {
    const text = event.target.value;
    setValueInput(text);
    onInputChange(valueSelect);
  }

  const suffixInput = () => {
    let icon;

    if (labelSuffix.icon === "info")
      icon = <InfoOutlined className={classes.suffixIconInfo} />;
    else if (labelSuffix.icon === "export")
      icon = (
        <span className={classes.suffixIconShare}>
          <Icon name="export" color="#812990" size="16" />
        </span>
      );

    return (
      <div className={classes.suffixLabel}>
        <HtmlTooltip
          arrow
          title={labelSuffix.title}
          onClick={() => labelSuffix.onClick()}
          placement="top-start"
          className={classes.tooltipStyle}
        >
          {icon}
        </HtmlTooltip>
      </div>
    );
  };

  const headerInput = () => (
    <React.Fragment>
      <div className={classes.inputLabel}>
        {label}
        {required && " *"}
        {labelSuffix && suffixInput()}
      </div>
    </React.Fragment>
  );

  const mascara = (tipo) => {
    switch (tipo) {
      case "cpf":
        return "999.999.999-99";
      case "cnpj":
        return "99.999.999/9999-99";
      case "data":
        return "99/99/9999";
      case "celular":
        return "(99) 99999-9999";
      case "telefone":
        return "(99) 9999-9999";
      case "cep":
        return "99999-999";
      case "pis":
        return "999.99999.99-9";
      case "cnae":
        return "9999-9/99";
      case "creditCard":
        return "9999 9999 9999 9999";
      case "creditCardValidity":
        return "99/9999";
      case "cvv":
        return "9999";
      case "ncm":
        return "9999.99.99";
      case "cest":
        return "99.999.99";
      case "rg":
        return "99.999.999-99";
      case "ctps":
        return "999.99999.99-99";
      case "rne":
        return "A999999-9";
      case "mes-ano":
        return "99/9999";
      case "boleto":
        return "99999.99999 99999.999999 99999.999999 9 999999999999999";
      case "boleto-concessionaria":
        return "99999999999 9 99999999999 9 99999999999 9 99999999999 9";
      default:
        break;
    }
  };

  const handlePercentage = (event) => {
    event.target.value = percentageMask(event.target.value);
    let length = event.target.value.length;
    event.target.setSelectionRange(length - 1, length - 1);
    event.value = event.target.value.substring(0, length - 1);
  };

  return (
    <React.Fragment>
      {headerInput()}
      <div
        className={underline ? classes.inputUnderline : classes.inputContainer}
      >
        {loading && <OverlayLoading size={20} />}
        {tipo !== "dinheiro" && tipo !== "decimal" ? (
          <InputMask
            className={
              error
                ? classes.error
                : tipo === "percentage"
                ? classes.inputNumber
                : classes.input
            }
            onChange={(event) => {
              if (tipo === "numero")
                event.target.value = onlyNumbers(event.target.value);

              if (tipo === "percentage") {
                handlePercentage(event);
              }
              handleOnInputWrap(event);
              handleInputChange(event);
            }}
            maskChar={
              tipo === "creditCard" || tipo === "cvv" || tipo === "boleto"
                ? null
                : "_"
            }
            type={type}
            placeholder={title}
            mask={mascara(tipo)}
            name={nome}
            value={valueSelect}
            {...rest}
          />
        ) : (
          <MoneyMask
            className={error ? classes.error : classes.inputMoney}
            onValueChange={(event, info) => {
              setValueInput(event);
              if (tipo === "decimal") {
                let value = event.value;
                event.target = {};
                event.target.value = value;
              }
              handleInputChange(event, info);
            }}
            fixedDecimalScale={tipo === "dinheiro"}
            isNumericString
            decimalScale={tipo === "dinheiro" ? 2 : 3}
            type={type}
            prefix={tipo === "dinheiro" ? "R$ " : ""}
            thousandSeparator="."
            decimalSeparator=","
            name={nome}
            onMouseLeave={handleMouseLeave}
            {...rest}
          />
        )}
        {icon && (
          <div
            className={icon === "arrow-down" ? classes.iconArrow : classes.icon}
          >
            <Icon name={icon} color="#EF4066" size={20} />
          </div>
        )}
        {children}
      </div>
    </React.Fragment>
  );
}

export default Input;
