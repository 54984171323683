import React, { useState, useEffect } from "react";
import { Grid, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import Icon from "../Icon";

import classes from "./styles.module.css";

function InputFile({
  onChange = () => { },
  title = "",
  fileName = "",
  fileFormat = "",
  labelButton = "Escolher Arquivo",
  cbFileObj = () => { },
  labelSuffix = null,
  hasFile = false,
  id = "",
  disabled=false
}) {
  const [valueFile, setValueFile] = useState("");

  useEffect(() => {  
    setValueFile(fileName);
  }, [ fileName ]);

  const suffixInput = () => {

    let icon;

    if (labelSuffix.icon === "info")
      icon = <InfoOutlined className={classes.suffixIconInfo} />;

    else if (labelSuffix.icon === "export")
      icon =
        <span className={classes.suffixIconShare}>
          <Icon
            name="export"
            color="#812990"
            size="16"
          />
        </span>;

    return (
      <div className={classes.suffixLabel}>
        <Tooltip
          arrow
          title={labelSuffix.title}
          onClick={() => labelSuffix.onClick()}
        >
          {icon}
        </Tooltip>
      </div>
    );
  }


  const handleImageChange = event => {
    let file = event.target.files[0];

    if (!file) return;

    cbFileObj(file);

    let reader = new FileReader();
    setValueFile(file);
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      onChange(reader.result, file.name, file);
    };
  };

  return (
    <>
      <div className={classes.label}>
        {title}
        {labelSuffix && (
          suffixInput()
        )}
      </div>
      <Grid
        item
        container
        justifyContent="space-between"
        className={classes.input_text}
      >
        <Grid item xs={12} sm={7} className={classes.containerFileName}>
          <div className={classes.text}>
            {valueFile === ""
              ? (hasFile ? hasFile : "Nenhum arquivo selecionado")
              : valueFile.name || valueFile}
          </div>
        </Grid>
        <Grid item xs={12} sm={5} className={classes.input_button}>
          <label className={classes.label} htmlFor={`input-file ${id}`}>
            {labelButton}
          </label>
          <input
            id={`input-file ${id}`}
            className={classes.input_file}
            type="file"
            accept={fileFormat}
            onChange={handleImageChange}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default InputFile;
