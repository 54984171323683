import React, { useState, useCallback, useEffect, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'

import CallbackMessage from '../../components/CallbackMessage'

import Clicksign from '../../services/clicksign'
import clickSignUrl from '../../utils/clicksign'

import styles from './styles.module.scss'
import './styles.module.scss'
import './styles.scss'

export default function AssinaContrato() {
  const params = useParams()
  
  const [callbackShown, setCallbackShown] = useState(false)
  const [callbackErrorList, setCallbackErrorList] = useState([])
  const [callbackMessage, setCallbackMessage] = useState('')
  const [callbackDuration, setCallbackDuration] = useState(3000)
  
  let widget
  
  let run = (idAssinatura) => {

    if (widget) {
      widget.unmount()
    }

    widget = new Clicksign(idAssinatura)

    widget.endpoint = clickSignUrl.clicksignUrl
    widget.origin = window.location.origin
    widget.mount("container")

    widget.on("loaded", function (ev) {
    })
    widget.on("signed", async function (ev) {
      setCallbackDuration(3000);
      setCallbackMessage("Sucesso");
      setCallbackShown(true);
      if (typeof window.Contrato !== "undefined") { 
        window.Contrato.postMessage("Assinado");
      }
    })
    widget.on("resized", function (height) {
      document.getElementById("container").style.height = height + "px"
    })
  }
  
  useEffect(() => {
    if (params?.id_assinatura_clicksign) {

      run(params?.id_assinatura_clicksign)
    }
  }, [])
  
  const handleClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      setCallbackShown(false)
    }

    if (reason === 'timeout') {
      setCallbackShown(false)
    }
  }, [])
  
  const nps = document.getElementById("yv-nps-frame")
  const chatWidget = document.getElementById('bot')
  
  useLayoutEffect(() => { 
    if(nps) nps.remove()
    if (chatWidget) chatWidget.remove()
  }, [nps, chatWidget])

  return (
    <>
      <div id="container" style={{ height: '100vh' }} className={styles.contractWrapper}></div>
      <CallbackMessage
        open={callbackShown}
        errorList={callbackErrorList}
        type={callbackErrorList.length !== 0 ? 'error' : 'success'}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={handleClose}
      />
    </>
  )
}