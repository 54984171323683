import { Dialog, DialogTitle, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import produtosServices from '../../services/produtosServices';
import validateFields from "../../utils/validateFields";
import ButtonComponent from "../ButtonComponent";
import CallbackMessage from "../CallbackMessage";
import InputComponent from "../Input";
import ModalLoginExpirado from "../ModalLoginExpirado";
import OverlayLoading from "../OverlayLoading";
import "./styles.scss";


function ModalEstoque({
  open,
  onClose,
  idProduto,
  onSave,
}) {
  const { consultaEstoque, definirEstoque } = produtosServices;

  const [estoqueAtual, setEstoqueAtual] = useState(null);
  const [estoqueMaximo, setEstoqueMaximo] = useState(null);
  const [estoqueMinimo, setEstoqueMinimo] = useState(null);


  useEffect(() => { 
    if(idProduto && open) {
      loadEstoque();
    }
  }, [idProduto, open])

  const loadEstoque = async () => {
    setLoading(true);
    const response = await consultaEstoque(idProduto);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }

    if (response.status === 400) {
      setErrors(Object.values(response?.data?.error).flat(Infinity));
      setShowMessage(true);
    }

    if (response.status === 500) {
      setErrors(['Erro interno no servidor, contate o suporte.']);
      setShowMessage(true);
    }

    if(response.status === 200 || response.status === 201) {
      setEstoqueAtual(response?.data?.estoque);
      setEstoqueMinimo(response?.data?.estoque_minimo);
      setEstoqueMaximo(response?.data?.estoque_maximo);
    }
    
    setLoading(false);
  };



  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  async function handleSubmit() {
    setErrors([]);

    let fields = [
      {
        label: "estoquemax",
        value: estoqueMaximo,
      },
      {
        label: "estoquemin",
        value: estoqueMinimo,
      },
    ];
    
    const fieldsValidate = validateFields(fields);

    if (fieldsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(fieldsValidate);
      return;
    }

    const response = await definirEstoque(idProduto, estoqueMinimo, estoqueMaximo);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }

    if (response.status === 400) {
      setErrors(Object.values(response?.data?.error).flat(Infinity));
      setShowMessage(true);
    }

    if (response.status === 500) {
      setErrors(['Erro interno no servidor, contate o suporte.']);
      setShowMessage(true);
    }
    

    if(response.status === 200 || response.status === 201) {
      setShowMessage(true);
      setSuccessMessage("Estoque atualizado com sucesso!");

      setTimeout(() => {
        handleCloseModal();
      }, 6000)
    }


    setLoading(false);
  }

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }

  const handleCloseModal = () => {
    setShowMessage(false);
    setEstoqueAtual(null);
    setEstoqueMaximo(null);
    setEstoqueMinimo(null);
    onClose();
  }


  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleCloseModal} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>ESTOQUE DO PRODUTO</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                <InputComponent
                  nome="estoqueatual"
                  label="Estoque Atual"
                  tipo="text"
                  value={estoqueAtual}
                  disabled
                />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <InputComponent
                  nome="estoquemax"
                  label="Estoque Máximo"
                  tipo="text"
                  required
                  value={estoqueMaximo}
                  handleInputChange={(event) => {
                    setEstoqueMaximo(event.target.value);
                  }}
                />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <InputComponent
                  nome="estoquemin"
                  label="Estoque Mínimo"
                  tipo="text"
                  required
                  value={estoqueMinimo}
                  handleInputChange={(event) => {
                    setEstoqueMinimo(event.target.value);
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <span className="required-text">* Campos obrigatórios</span>
                </Grid>

              <Grid container justify="flex-end" item>
                <ButtonComponent
                  text="Salvar"
                  onClick={handleSubmit}
                  className="btn-success zero-margin"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalEstoque;
