import Axios from "./Axios";

const certidoesService = {
  listarCertidoes: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null
  ) => {
    const response = await Axios.get("/lista-certidao", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cadastraCertidao: async (
    id_empresa,
    arquivo,
    id_abrangencia,
    id_orgao_emissor,
    id_status,
    id_tipo_certidao,
    data_emissao,
    data_vencimento
  ) => {
    const response = await Axios.post("/cadastra-certidao", {
      id_empresa: id_empresa,
      arquivo: arquivo,
      id_abrangencia: id_abrangencia,
      id_orgao_emissor: id_orgao_emissor,
      id_status: id_status,
      id_tipo_certidao: id_tipo_certidao,
      data_emissao: data_emissao,
      data_vencimento: data_vencimento,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selecionaCertidao: async () => {
    const response = await Axios.get("/seleciona-certidao")
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selecionaOrgaoEmissor: async () => {
    const response = await Axios.get("/seleciona-orgao-emissor-certidao")
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selecionaAbrangencia: async () => {
    const response = await Axios.get("/seleciona-abrangencia-certidao")
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selecionaStatus: async () => {
    const response = await Axios.get("/seleciona-status-certidao")
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  consultaCertidao: async (id) => {
    const response = await Axios.get(`/consulta-certidao/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  atualizaCertidao: async (
    idCertidao,
    id_empresa,
    arquivo,
    id_abrangencia,
    id_orgao_emissor,
    id_status,
    id_tipo_certidao,
    data_emissao,
    data_vencimento
  ) => {
    const response = await Axios.post(`/atualiza-certidao/${idCertidao}`, {
      id_empresa,
      arquivo,
      id_abrangencia,
      id_orgao_emissor,
      id_status,
      id_tipo_certidao,
      data_emissao,
      data_vencimento,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  removeCertidao: async (id) => {
    const response = await Axios.get(`/remove-certidao/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};

export default certidoesService;
