const generateAmountArray = () => {
  let array = [{ id: 0, nome: 'Ilimitado' }]
  for (let i = 1; i <= 100; i++) {
    array.push({ id: i, nome: String(i) });
  }

  console.log(array);
  return array;
}

export default generateAmountArray;