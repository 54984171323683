import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Select from "../../components/SelectComponent";
import Button from "../../components/ButtonComponent";
import OverlayLoading from "../../components/OverlayLoading";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import CallbackMessage from "../../components/CallbackMessage";

import configuracoesPlataformaService from "../../services/configuracoesPlataformaService";

import "./styles.css";
import { Grid } from "@material-ui/core";

function ConfiguracoesPlataforma() {
  const history = useHistory();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const { getConfig, addConfig } = configuracoesPlataformaService;

  const [emissorNfe, setEmissorNfe] = useState();
  const [emissorBoleto, setEmissorBoleto] = useState();
  const [emissorTransferencia, setEmissorTransferencia] = useState();
  const [nfeValue, setNfeValue] = useState("");
  const [boletoValue, setBoletoValue] = useState("");
  const [transferenciaValue, setTransferenciaValue] = useState("");

  const [loading, setLoading] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackErrors, setCallbackErrorList] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [duration, setCallbackDuration] = useState(6000);

  const nfseOptions = [
    {
      id: 1,
      emissor: "Plugnotas",
      nome: "1 - Plugnotas",
    },
    {
      id: 2,
      emissor: "invoiSys",
      nome: "2 - invoiSys",
    },
  ];

  const boletoOptions = [
    {
      id: 1,
      emissor: "Plugnotas",
      nome: "1 - Plugnotas",
    },
    {
      id: 2,
      emissor: "Banco do Brasil",
      nome: "2 - Banco do Brasil",
    },
  ];

  const transferenciaOptions = [
    {
      id: 1,
      emissor: "Plugnotas",
      nome: "1 - Plugnotas",
    },
    {
      id: 2,
      emissor: "Banco do Brasil",
      nome: "2 - Banco do Brasil",
    },
  ];

  useEffect(() => {
    (async function loadConfig() {
      setLoading(true);
      const response = await getConfig();
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      const { data } = response;

      if (data) {
        const initialNfeValue = nfseOptions.filter(
          (item) => item.id === data.integracao_nfse
        );

        const initialBoletoValue = boletoOptions.filter(
          (item) => item.id === data.integracao_boleto
        );

        const initialTransferenciaValue = transferenciaOptions.filter(
          (item) => item.id === data.integracao_transferencia
        );

        setNfeValue(initialNfeValue[0].nome);
        setBoletoValue(initialBoletoValue[0].nome);
        setTransferenciaValue(initialTransferenciaValue[0].nome);

        setEmissorBoleto(data?.integracao_boleto);
        setEmissorNfe(data?.integracao_nfse);
        setEmissorTransferencia(data?.integracao_transferencia);
        setLoading(false);
      }
    })();
  }, []);

  function handleEmissorNfeChange(event) {
    setEmissorNfe(event);
  }

  function handleEmissorBoletoChange(event) {
    setEmissorBoleto(event);
  }

  function handleEmissorTransferenciaChange(event) {
    setEmissorTransferencia(event);
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/home");
    }
  }

  async function handleErrors(errors) {
    console.log(errors);
    const callbackErrors = errors.map((error) => {
      switch (error) {
        case "integracao_nfse":
          return "Nenhum emissor de nota fiscal selecionado.";

        case "integracao_boleto":
          return "Nenhuma emissor de boletos selecionado.";

        case "integracao_transferencia":
          return "Nenhuma emissor de transferencias bancárias selecionado.";

        default:
          break;
      }
    });

    setCallbackShown(true);
    setCallbackType("error");
    setCallbackMessage("Erro!");
    setCallbackErrorList(callbackErrors);
    setLoading(false);
    return;
  }

  async function handleSubmit() {
    setLoading(true);
    const response = await addConfig(
      emissorNfe,
      emissorBoleto,
      emissorTransferencia
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status === 200) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Atualizado!");
      console.log(response);
      setLoading(false);
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      return;
    }
  }

  return loading ? (
    <OverlayLoading />
  ) : (
    <>
      <div className="zero-m-bottom session-container user-register-container">
        <span className="session-container-header">
          <form className="platform-config-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  list={nfseOptions}
                  initialValue={nfeValue}
                  styleType="form"
                  search={false}
                  nome="emissorNfe"
                  label="Emissor da Nota Fiscal (NFS-e)"
                  title="Selecione um parceiro para emissão das notas fiscais"
                  required={true}
                  callback={(event) => handleEmissorNfeChange(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  list={boletoOptions}
                  initialValue={boletoValue}
                  styleType="form"
                  search={false}
                  nome="emissorBoletos"
                  label="Emissor de boletos"
                  title="Selecione um parceiro para emissão dos boletos"
                  required={true}
                  callback={(event) => handleEmissorBoletoChange(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  list={transferenciaOptions}
                  initialValue={transferenciaValue}
                  styleType="form"
                  search={false}
                  nome="emissorTransferencias"
                  label="Emissor transferências bancárias"
                  title="Selecione um parceiro para realizar as trasnferências bancárias"
                  required={true}
                  callback={(event) => handleEmissorTransferenciaChange(event)}
                />
              </Grid>
              <Grid item sx={12} className="required-text">
                <span>* Campos obrigatórios</span>
              </Grid>

            </Grid>
            {callbackShown && (
              <CallbackMessage
                type={callbackType}
                open={callbackShown}
                message={callbackMessage}
                errorList={callbackErrors}
                handleClose={handleClose}
                duration={duration}
              />
            )}
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            type="button"
            text="Salvar"
            className="btn-success"
            onClick={handleSubmit}
          />
        </span>
      </div>

      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default ConfiguracoesPlataforma;
