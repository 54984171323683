import React, { useEffect, useState } from "react";
import { PluggyConnect } from "react-pluggy-connect";
import contasBancariasService from "../../services/contasBancariasService";
import CallbackMessage from "../CallbackMessage";
import { Grid } from "@material-ui/core";
import "./styles.scss";
import OverlayLoading from "../OverlayLoading";

export const WidgetPluggy = ({
  idEmpresa,
  setIdIntegracaoRealizada,
  onClose,
  idConta = null,
  idIntegracao = null,
}) => {
  const { getPluggyAccessToken } = contasBancariasService;

  const [loading, setLoading] = useState(true);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [token, setToken] = useState();

  function handleClose(event, reason) {
    setCallbackShown(false);
  }

  function onSuccess({ item }) {
    setIdIntegracaoRealizada(item.id);
  }

  function onError({ data }) {
    const { code } = data.item;
    if (code === "USER_AUTHORIZATION_PENDING") {
      setIdIntegracaoRealizada(data.item.id);
    }
  }

  async function getToken() {
    try {
      if (!idEmpresa) return;
      setLoading(true);

      const response = await getPluggyAccessToken(idEmpresa, idConta);
      setLoading(false);

      if (response.status === 200) {
        return setToken(response.data.accessToken);
      }
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(6000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getToken();
    return () => {
      setToken(null);
    };
  }, [idEmpresa]);

  if (!idEmpresa) {
    return null;
  }

  if (loading) {
    return <OverlayLoading />;
  }

  if (!token) {
    return null;
  }

  return (
    <Grid item xs={12} className="container-pluggy">
      <PluggyConnect
        connectToken={token}
        onSuccess={onSuccess}
        onError={onError}
        includeSandbox={process.env.REACT_APP_ENV !== "production"}
        connectorTypes={["BUSINESS_BANK", "PERSONAL_BANK"]}
        onClose={onClose}
        updateItem={idIntegracao || undefined}
      />

      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />
    </Grid>
  );
};
