import React, { useCallback, useEffect, useState } from "react";
import { FormControlLabel, Grid, useMediaQuery } from "@material-ui/core";
import styles from "./styles.module.scss";
import DatePicker from "../../../../../components/DatePicker";
import Input from "../../../../../components/Input";
import SelectComponent from "../../../../../components/SelectComponent";
import selectService from "../../../../../services/selectServices";
import SwitchComponent from "../../../../../components/Switch";
import { HorariosSemana } from "./componentes/horariosSemana";
import { HorariosSemanaMobile } from "./componentes/horariosSemanaMobile";
import Icon from "../../../../../components/Icon";
import { InfoOutlined } from "@material-ui/icons";
import { InfoComponent } from "../../../../../components/InfoComponent";

const resultadoExameOcupacionalOpcoes = [
  {
    id: 1,
    nome: "Apto",
  },
  {
    id: 0,
    nome: "Inapto",
  },
];

export function DadosContratuais({
  locationState,
  dados,
  setDados,
  idEmpresa,
  setIdEmpresa,
  listEmpresa,
}) {
  const isMobile = useMediaQuery("(max-width: 1100px)");

  const {
    selecionaTipoAdmissao,
    selecionaVinculoTrabalhista,
    selecionaCategoriaFuncionario,
    selecionaFormaPagamento,
  } = selectService;
  const [listTipoAdmissao, setListTipoAdmissao] = useState([]);
  const [listVinculoTrabalhista, setListVinculoTrabalhista] = useState([]);
  const [listCategoriaFuncionario, setListCategoriaFuncionario] = useState([]);
  const [listFormaPagamento, setListFormaPagamento] = useState([]);

  const loadTipoAdmissao = useCallback(async () => {
    await selecionaTipoAdmissao().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListTipoAdmissao(response.data);
    });
  }, []);

  const loadVinculoTrabalhista = useCallback(async () => {
    await selecionaVinculoTrabalhista().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListVinculoTrabalhista(response.data);
    });
  }, []);

  const loadCategoriaFuncionario = useCallback(async () => {
    await selecionaCategoriaFuncionario().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListCategoriaFuncionario(response.data);
    });
  }, []);

  const loadFormaPagamento = useCallback(async () => {
    await selecionaFormaPagamento().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListFormaPagamento(response.data);
    });
  }, []);

  function handleChangeDados(field, value) {
    let novosDados = { ...dados };
    novosDados[field] = value;
    setDados(novosDados);
  }

  function handleChangeDadosHorariosSemana(field, value, boolean = false) {
    let dadosHorariosSemana = { ...dados?.horariosSemana };
    if (
      !boolean &&
      value.length === 3 &&
      (!dadosHorariosSemana[field] || dadosHorariosSemana[field]?.length < 3)
    ) {
      value = `${value}00`;
    }
    dadosHorariosSemana[field] = value;
    handleChangeDados("horariosSemana", dadosHorariosSemana);
  }

  useEffect(() => {
    loadTipoAdmissao();
    loadVinculoTrabalhista();
    loadCategoriaFuncionario();
    loadFormaPagamento();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectComponent
          styleType="form"
          label="Empresa"
          title="Selecione uma opção"
          list={listEmpresa}
          initialValue={
            listEmpresa.find((item) => item.id === idEmpresa)
              ? listEmpresa.find((item) => item.id === idEmpresa).nome
              : ""
          }
          required
          callback={(id) => setIdEmpresa(id)}
          isDisabled={locationState?.details}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="Nome do Funcionário"
          name="nomeFuncionario"
          value={dados?.nomeFuncionario}
          required
          handleInputChange={async (e) => {
            handleChangeDados("nomeFuncionario", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          label="Data de Admissão*"
          name="dataAdmissao"
          initDate={dados?.dataAdmissao ? dados?.dataAdmissao : ""}
          handleChange={(date) => {
            handleChangeDados("dataAdmissao", date);
          }}
          value={dados?.dataAdmissao}
          required
          readOnly={locationState?.details}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="Quantidade de dias do contrato de experiência"
          name="diasExperiencia"
          value={dados?.diasExperiencia}
          required
          handleInputChange={async (e) => {
            handleChangeDados("diasExperiencia", e.target.value);
          }}
          disabled={locationState?.details}
        />
        <InfoComponent
          text="Exemplo para preenchimento: 45+45 , 90, 30, 30+60"
          icon={<InfoOutlined fontSize="small" className="info-icon" />}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Resultado do exame ocupacional"
          title="Selecione uma opção"
          list={resultadoExameOcupacionalOpcoes}
          initialValue={
            resultadoExameOcupacionalOpcoes.find(
              (item) => item.id === dados?.resultadoAtestadoOcupacional
            )
              ? resultadoExameOcupacionalOpcoes.find(
                  (item) => item.id === dados?.resultadoAtestadoOcupacional
                ).nome
              : ""
          }
          required
          callback={(id) =>
            handleChangeDados("resultadoAtestadoOcupacional", id)
          }
          isDisabled={locationState?.details}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          label="Data do exame admissional"
          name="dataExameAdmissional"
          initDate={
            dados?.dataExameAdmissional ? dados?.dataExameAdmissional : ""
          }
          handleChange={(date) => {
            handleChangeDados("dataExameAdmissional", date);
          }}
          value={dados?.dataExameAdmissional}
          readOnly={locationState?.details}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Tipo de Admissão"
          title="Selecione uma opção"
          list={listTipoAdmissao}
          initialValue={
            listTipoAdmissao.find((item) => item.id === dados?.tipoAdmissao)
              ? listTipoAdmissao.find((item) => item.id === dados?.tipoAdmissao)
                  .nome
              : ""
          }
          required
          callback={(id) => handleChangeDados("tipoAdmissao", id)}
          isDisabled={locationState?.details}
        />
      </Grid>
      <Grid item className="container-input-switch">
        Optante por adiantamento salarial?
        <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
          Não
        </Grid>
        <Grid item style={{ marginLeft: 12 }}>
          <FormControlLabel
            className={styles.controlLabel}
            style={{ marginBottom: 0 }}
            control={
              <SwitchComponent
                checked={dados?.optantePorAdiantamento === 1}
                onClick={(event) => {
                  handleChangeDados(
                    "optantePorAdiantamento",
                    event.target.checked ? 1 : 0
                  );
                }}
                name="optantePorAdiantamento"
                disabled={locationState?.details}
              />
            }
          />
        </Grid>
        <Grid item style={{ marginLeft: -8 }}>
          Sim
        </Grid>
      </Grid>
      <Grid item className={isMobile ? styles.containerInputSwitch : ""}>
        {isMobile && (
          <div>
            Admissão durante percepção/requerimento do Seguro Desemprego ou
            decorrida de ação fiscal?
          </div>
        )}
        <Grid item className="container-input-switch">
          {!isMobile && (
            <>
              Admissão durante percepção/requerimento do Seguro Desemprego ou
              decorrida de ação fiscal?
            </>
          )}
          <Grid item style={{ marginRight: 12 }}>
            Não
          </Grid>
          <Grid item style={{ marginLeft: 12 }}>
            <FormControlLabel
              className={styles.controlLabel}
              style={{ marginBottom: 0 }}
              control={
                <SwitchComponent
                  checked={dados?.admissaoDuranteSD === 1}
                  onClick={(event) => {
                    handleChangeDados(
                      "admissaoDuranteSD",
                      event.target.checked ? 1 : 0
                    );
                  }}
                  name="admissaoDuranteSD"
                  disabled={locationState?.details}
                />
              }
            />
          </Grid>
          <Grid item style={{ marginLeft: -8 }}>
            Sim
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Vínculo Trabalhista"
          title="Selecione uma opção"
          list={listVinculoTrabalhista}
          initialValue={
            listVinculoTrabalhista.find(
              (item) => item.id === dados?.vinculoTrabalista
            )
              ? listVinculoTrabalhista.find(
                  (item) => item.id === dados?.vinculoTrabalista
                ).nome
              : ""
          }
          required
          callback={(id) => handleChangeDados("vinculoTrabalista", id)}
          isDisabled={locationState?.details}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Categoria"
          title="Selecione uma opção"
          list={listCategoriaFuncionario}
          initialValue={
            listCategoriaFuncionario.find(
              (item) => item.id === dados?.categoria
            )
              ? listCategoriaFuncionario.find(
                  (item) => item.id === dados?.categoria
                ).nome
              : ""
          }
          required
          callback={(id) => handleChangeDados("categoria", id)}
          isDisabled={locationState?.details}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="Departamento"
          name="departamento"
          value={dados?.departamento}
          required
          handleInputChange={async (e) => {
            handleChangeDados("departamento", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="Função"
          name="funcao"
          value={dados?.funcao}
          required
          handleInputChange={async (e) => {
            handleChangeDados("funcao", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="Salário Base"
          name="salarioBase"
          tipo="dinheiro"
          placeholder="R$"
          value={dados?.salarioBase}
          required
          handleInputChange={async (e) => {
            handleChangeDados("salarioBase", e.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Forma de pagamento"
          title="Selecione uma opção"
          list={listFormaPagamento}
          initialValue={
            listFormaPagamento.find((item) => item.id === dados?.formaPagamento)
              ? listFormaPagamento.find(
                  (item) => item.id === dados?.formaPagamento
                ).nome
              : ""
          }
          required
          callback={(id) => handleChangeDados("formaPagamento", id)}
          isDisabled={locationState?.details}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          label="Término do contrato"
          name="terminoContrato"
          initDate={dados?.terminoContrato ? dados?.terminoContrato : ""}
          handleChange={(date) => {
            handleChangeDados("terminoContrato", date);
          }}
          value={dados?.terminoContrato}
          readOnly={locationState?.details}
        />
      </Grid>
      {isMobile ? (
        <HorariosSemanaMobile
          dados={dados}
          handleChangeDadosHorariosSemana={handleChangeDadosHorariosSemana}
          locationState={locationState}
        />
      ) : (
        <HorariosSemana
          dados={dados}
          handleChangeDadosHorariosSemana={handleChangeDadosHorariosSemana}
          locationState={locationState}
        />
      )}
      <Grid item xs={12}>
        <span className="required-text">* Campos obrigatórios</span>
      </Grid>
    </Grid>
  );
}
