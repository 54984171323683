import Axios from "./Axios";

export default {
  consultaListaImovel: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    empresaUsuario = window.localStorage.getItem("empresa_usuario")
  ) => {
    const result = await Axios.get("/lista-imovel", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa: empresaUsuario
      }
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  consultaDetalhesImovel: async (id) => {  
    const result = await Axios.get(`/consulta-imovel/${id}`, {
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};
