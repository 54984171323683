import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ButtonComponent from "../ButtonComponent";
import SelectComponent from "../SelectComponent";
import Textarea from "../Textarea";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import CallbackMessage from "../CallbackMessage";
import validateFields from "../../utils/validateFields";
import selectServices from '../../services/selectServices';
import empresasService from '../../services/empresasService';

import "./styles.scss";

function ModalStatus({
  open,
  onClose,
  idEmpresa,
  cbTable,
  dataModal
}) {
  const [status, setStatus] = useState(null);
  const [observacoes, setObservacoes] = useState("");

  const [listStatus, setListStatus] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);


  useEffect(() => {
    setLoading(true);
    loadStatus();
    setLoading(false);
  }, []);

  async function handleSubmit() {
    setErrors([]);

    let fields = [
      {
        label: "status",
        value: status,
      },
    ];


    const fieldsValidate = validateFields(fields);

    if (fieldsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(fieldsValidate);
      return;
    }

    setLoading(true);

    const response = await empresasService.alterarStatusEmpresa(
      idEmpresa,
      status,
      observacoes,
    );

    if (response.status === 401) {
       setOpenLoginExpirado(true);
       setLoading(false);
       return;
     }
     if (response.status !== 201 && response.status !== 200) {
       if (response.data.error) {
         setErrors(Object.values(response.data.error));
         return console.log('errors:', response.data.error)
       } else setErrors(["Ocorreu um erro"]);
     } else if (response.status === 200 || response.status === 201) {
        setShowMessage(true);
        setSuccessMessage("Status atualizado com sucesso!");
     }
     setLoading(false);
     handleClose();
  }

  const loadStatus = async () => {
    const response = await selectServices.selectStatusEmpresa();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const data = response.data;

    setListStatus(data);
  }

  const loadFields = useCallback(async () => {
    if (idEmpresa !== null) {
      const response = await empresasService.consultaEmpresa(idEmpresa);

      if (response?.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }

      if (response?.status !== 201 && response?.status !== 200) {
       if (response?.data?.error) {
         setErrors(Object.values(response?.data?.error));
       } else setErrors(["Ocorreu um erro"]);
        setShowMessage(true);
      } else {
        const { data } = response;

        data?.status ? setStatus(data?.status) : setStatus(null);
        data?.observacao ? setObservacoes(data?.observacao) : setObservacoes("");

      }
      setLoading(false);
    }
  }, [idEmpresa])

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
      cbTable();
    }
  }

  const handleCloseModal = async () => {
    setLoading(false);
    onClose();
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadFields();
      setLoading(false);
    })();
  }, [loadFields]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleCloseModal} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>Informações extras</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className="select-container">
                <SelectComponent
                  list={listStatus}
                  label="Status do cliente"
                  title="Selecione uma opção"
                  required
                  initialValue={
                    listStatus.find(
                      (item) => item.id === status
                    )
                      ? listStatus.find(
                        (item) => item.id === status
                      ).nome
                      : ""
                  }
                  callback={(id) => setStatus(id)}
                />
              </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Textarea
                    label='Observações'
                    value={observacoes}
                    handleInputChange={event => setObservacoes(event.target.value)}
                  />
              </Grid>
              <Grid item xs={12}>
                <span className="required-text">* Campos obrigatórios</span>
              </Grid>
              <Grid container justify="flex-end" item>
                <ButtonComponent
                  text="Salvar"
                  onClick={handleSubmit}
                  className="btn-success zero-margin"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalStatus;
