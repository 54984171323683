// Utilização: Passe como parâmetro um array de objetos,
// sendo esses objetos os campos a serem validados, e seus
// respectivos valores.
// Caso esteja faltando algum campo que precisa ser validado,
// basta adicionar um novo 'case' no switch.

// Os campos "Estado", "Cidade" e "ISS Retido", especialmente,
// têm validações diferentes para os casos em que forem selects
// e para quando forem inputs de texto.

const validateFields = (fields = [{}]) => {
  const errors = fields.map((field) => {
    const { label } = field;
    const value =
      field.value === "" || field.value === null || field.value === undefined;

    switch (true) {
      case label === "cnpj" && value:
        return 'O campo "CNPJ" é obrigatório.';

      case label === "razaosocial" && value:
        return 'O campo "Razão Social" é obrigatório.';

      case label === "nomefantasia" && value:
        return 'O campo "Nome Fantasia" é obrigatório.';

      case label === "cnaeprincipal" && value:
        return 'O campo "CNAE Principal" é obrigatório.';

      case label === "naturezajuridica" && value:
        return 'O campo "Natureza juridica" é obrigatório.';

      case label === "inscricaoestadual" && value:
        return 'O campo "Inscrição Estadual" é obrigatório.';

      case label === "inscricaomunicipal" && value:
        return 'O campo "Inscrição Municipal" é obrigatório.';

      case label === "cep" && value:
        return 'O campo "CEP" é obrigatório.';

      case label === "endereco" && value:
        return 'O campo "Endereço" é obrigatório.';

      case label === "bairro" && value:
        return 'O campo "Bairro" é obrigatório.';

      case label === "numero" && value:
        return 'O campo "Número" é obrigatório.';

      case label === "estado" && value:
        return 'O campo "Estado" é obrigatório.';

      case label === "cidade" && value:
        return 'O campo "Cidade" é obrigatório.';

      case label === "nome" && value:
        return 'O campo "Nome" é obrigatório.';

      case label === "cpf" && value:
        return 'O campo "CPF" é obrigatório.';

      case label === "pis" && value:
        return 'O campo "PIS" é obrigatório.';

      case label === "cofins" && value:
        return 'O campo "COFINS" é obrigatório.';

      case label === "csll" && value:
        return 'O campo "CSLL" é obrigatório.';

      case label === "irpj" && value:
        return 'O campo "IRPJ" é obrigatório.';

      case label === "inss" && value:
        return 'O campo "INSS" é obrigatório.';

      case label === "qualificacao" && value:
        return 'O campo "Qualificação" é obrigatório.';

      case label === "empresa" && value:
        return "Nenhuma empresa selecionada.";

      case label === "opcaopelosimples" && value:
        return 'O campo "Opção pelo simples" é obrigatório.';

      case label === "numerodaultimanf" && value:
        return 'O campo "Número da última NF" é obrigatório.';

      case label === "certificado" && value:
        return "Nenhum certificado anexado.";

      case label === "senha" && value:
        return 'O campo "Senha" é obrigatório.';

      case label === "nomedogrupo" && value:
        return 'o campo "Nome do grupo" é obrigatório.';

      case label === "grupo" && value:
        return "Nenhum grupo selecionado.";

      case label === "tipodecategoria" && value:
        return "Nenhum tipo de categoria selecionado.";

      case label === "nomedacategoria" && value:
        return 'O campo "Nome da categoria" é obrigatório.';

      case label === "nomedocentrodecusto" && value:
        return 'O campo "Nome do centro de custo" é obrigatório.';

      case label === "banco" && value:
        return "Nenhum banco selecionado.";

      case label === "indicadordeie" && value:
        return "Nenhum indicador de IE selecionado.";

      case label === "email" && value:
        return 'O campo "E-mail" é obrigatório.';

      case label === "login" && value:
        return 'O campo "Login" é obrigatório.';

      case label === "confirmarsenha" && value:
        return 'O campo "Confirmar senha" é obrigatório.';

      case label === "prestadordeservico" && value:
        return "Nenhum prestador de serviço selecionado.";

      case label === "datadeemissao" && value:
        return 'O campo "Data de Emissão" é obrigatório.';

      case label === "cpfcnpjourazaosocial" && value:
        return "Nenhum tomador de serviço selecionado";

      case label === "selectestado" && value:
        return "Nenhum estado selecionado.";

      case label === "selectcidade" && value:
        return "Nenhuma cidade selecionada.";

      case label === "codigocodigomunicipaledescricaodoservico" && value:
        return "Nenhum serviço selecionado.";

      case label === "selectissretido" && value:
        return 'O campo "ISS Retido?" é obrigatório.';

      case label === "valor" && value:
        return 'O campo "Valor" é obrigatório.';

      case label === "descricao" && value:
        return 'O campo "Descrição" é obrigatório.';

      case label === "fornecedor" && value:
        return "Nenhum fornecedor selecionado.";

      case label === "cliente" && value:
        return "Nenhum cliente selecionado.";

      case label === "categoria" && value:
        return "Nenhuma categoria selecionada.";

      case label === "referencia" && value:
        return 'O campo "Referência" é obrigatório.';

      case label === "vencimentoem" && value:
        return 'O campo "Vencimento em" é obrigatório.';

      case label === "titulododocumento" && value:
        return 'O campo "Título do Documento" é obrigatório.';

      case label === "tipodecertidao" && value:
        return "Nenhum tipo de certidão selecionado.";

      case label === "orgaoemissor" && value:
        return "Nenhum órgão emissor selecionado.";

      case label === "abrangencia" && value:
        return "Nenhuma abrangência selecionada.";

      case label === "statuscertidao" && value:
        return "Nenhum status selecionado.";

      case label === "dataemissao" && value:
        return 'O campo "Data emissão" é obrigatório.';

      case label === "datavencimento" && value:
        return 'O campo "Data vencimento" é obrigatório.';

      case label === "anexo" && value:
        return "Nenhum arquivo selecionado.";

      case label === "titulo" && value:
        return 'O campo "Título" é obrigatório.';

      case label === "datanascimento" && value:
        return 'O campo "Data Nascimento" é obrigatório.';

      case label === "centrocusto" && value:
        return "Nenhum centro de custo selecionado.";

      case label === "datadepagamento" && value:
        return 'O campo "Data de Pagamento" é obrigatório.';

      case label === "dataderecebimento" && value:
        return 'O campo "Data de Recebimento" é obrigatório.';

      case label === "contaselect" && value:
        return "Nenhuma conta selecionada.";

      case label === "numerodolotedanf" && value:
        return 'O campo "Número do lote da NF" é obrigatório.';

      case label === "seriedanf" && value:
        return 'O campo "Série da NF" é obrigatório.';

      case label === "status" && value:
        return "Nenhum status selecionado.";

      case label === "obrigacao" && value:
        return "Nenhuma obrigação selecionada.";

      case label === "vencimentoaposemissao" && value:
        return 'O campo "Vencimento após emissão" é obrigatório.';

      case label === "numerodocartao" && value:
        return 'O campo "Número do cartão" é obrigatório.';

      case label === "nomeimpressonocartao" && value:
        return 'O campo "Nome impresso no cartão" é obrigatório.';

      case label === "validade" && value:
        return 'O campo "Validade" é obrigatório.';

      case label === "codigodeseguranca" && value:
        return 'O campo "Código de segurança" é obrigatório.';

      case label === "tipodocartao" && value:
        return "Nenhuma opção de cartão selecionada.";

      case label === "servico" && value:
        return "Nenhuma opção de serviço selecionado.";

      case label === "quantidade" && value:
        return 'O campo "Quantidade" é obrigatório.';

      case label === "observacoes" && value:
        return 'O campo "Observações" é obrigatório.';
      case label === "rua" && value:
        return 'O campo "Rua" é obrigatório.';

      case label === "selectpais" && value:
        return "Nenhum país selecionado.";

      case label === "telefone" && value:
        return 'O campo "Telefone" é obrigatório.';

      case label === "tipoidentidade" && value:
        return "Nenhum tipo de identidade selecionado.";

      case label === "identidade" && value:
        return 'O campo "Identidade" é obrigatório.';

      case label === "orgaoidentidade" && value:
        return 'O campo "Órgão Emissor da Identidade" é obrigatório.';

      case label === "emissaoidentidade" && value:
        return 'O campo "Emissão da Identidade" é obrigatório.';

      case label === "ufidentidade" && value:
        return "Nenhuma UF de identidade selecionado.";

      case label === "selectctps" && value:
        return "Nenhuma CTPS selecionada.";

      case label === "seriectps" && value:
        return 'O campo "Série da CTPS" é obrigatório.';

      case label === "ufctps" && value:
        return "Nenhuma UF de CTPS selecionado.";

      case label === "emissaoctps" && value:
        return 'O campo "Emissão da CTPS" é obrigatório.';

      case label === "datadenascimento" && value:
        return 'O campo "Data de nascimento" é obrigatório.';

      case label === "sexo" && value:
        return 'O campo "Sexo" é obrigatório.';

      case label === "estadocivil" && value:
        return 'O campo "Estado civil" é obrigatório.';

      case label === "cor" && value:
        return 'O campo "Cor" é obrigatório.';

      case label === "escolaridade" && value:
        return 'O campo "Escolaridade" é obrigatório.';

      case label === "paisdenascimento" && value:
        return "Nenhum país de nascimento selecionado.";

      case label === "nacionalidade" && value:
        return "Nenhuma nacionalidade selecionada.";

      case label === "estadodenascimento" && value:
        return "Nenhum estado selecionado.";

      case label === "municipiodenascimento" && value:
        return "Nenhum município selecionado.";

      case label === "parentesco" && value:
        return 'O campo "Parentesco" é obrigatório.';

      case label === "iniciodadependencia" && value:
        return 'O campo "Início da dependência" é obrigatório.';

      case label === "codigo" && value:
        return 'O campo "Código" é obrigatório.';

      case label === "tipodoproduto" && value:
        return "Nenhum tipo de produto selecionado.";

      case label === "tipoespecifico" && value:
        return "Nenhum campo específico selecionado.";

      case label === "categoriapadrao" && value:
        return "Nenhuma categoria selecionada.";

      case label === "descricaodoprodutoouservico" && value:
        return 'O campo "Descrição do Produto ou Serviço" é obrigatório.';

      case label === "ncm" && value:
        return 'O campo "NCM" é obrigatório.';

      case label === "unidadedemedidacomercial" && value:
        return "Nenhuma unidade de medida comercial selecionada.";

      case label === "unidadedemedidatributaria" && value:
        return "Nenhuma unidade de medida tributária selecionada.";

      case label === "origemdomaterial" && value:
        return "Origem do material não foi selecionada.";

      case label === "origem" && value:
        return 'O campo "Origem" é obrigatório';

      case label === "cst" && value:
        return 'O campo "CST" é obrigatório';

      case label === "modalidade_determinacao_bcicms" && value:
        return 'O campo "Modalidade determinação BCI" é obrigatório';

      case label === "valor_base_calculo" && value:
        return 'O campo "Valor base de cálculo" é obrigatório';

      case label === "aliquota_imposto" && value:
        return 'O campo "Alíquota do imposto" é obrigatório';

      case label === "valor_icms" && value:
        return 'O campo "Valor do ICMS" é obrigatório';

      case label === "modalidade_determinacao_bcicmsst" && value:
        return 'O campo "Modalidade determinação BC ICMS ST" é obrigatório';

      case label === "valor_base_calculo_st" && value:
        return 'O campo "Valor da base de cálculo ST" é obrigatório';

      case label === "aliquota_imposto_icmsst" && value:
        return 'O campo "Alíquota imposto ICMS ST" é obrigatório';

      case label === "valor_icmsst" && value:
        return 'O campo "Valor ICMS ST" é obrigatório';

      case label === "percentual_reducao_bc" && value:
        return 'O campo "Redução de BC (%)" é obrigatório';

      case label === "valor_bc_icms_st_retido" && value:
        return 'O campo "Valor BC ICMS ST retido" é obrigatório';

      case label === "valor_icms_st_retido" && value:
        return 'O campo "Valor do ICMS ST retido" é obrigatório';

      case label === "valor_bc_icms_st" && value:
        return 'O campo "Valor da BC ICMS ST" é obrigatório';

      case label === "vBC" && value:
        return 'O campo "Valor da BC ICMS" é obrigatório';

      case label === "pICMSST" && value:
        return 'O campo "Alíquota do imposto do ICMS ST %" é obrigatório';

      case label === "vICMSST" && value:
        return 'O campo "Valor do ICMS ST" é obrigatório';

      case label === "vCredICMSSN" && value:
        return 'O campo "Valor crédito do ICMS que pode ser aproveitado" é obrigatório';

      case label === "tipodeicms" && value:
        return "Nenhum tipo de ICMS selecionado.";

      case label === "aliquota_aplicavel_calculo_credito" && value:
        return 'O campo "Alíquota aplicável do cálculo do crédito" é obrigatório.';

      case label === "valor_credito_icmssn" && value:
        return 'O campo "Valor crédito ICMS SN" é obrigatório.';

      case label === "csosn" && value:
        return 'O campo "CSOSN" é obrigatório.';

      case label === "valor_bcicmsst" && value:
        return 'O campo "Valor da base de cálculo ICMS ST" é obrigatório.';

      case label === "vBCSTRet" && value:
        return 'O campo "Valor BC ICMS ST Retido" é obrigatório.';

      case label === "vICMSSTRet" && value:
        return 'O campo "Valor do ICMS ST Retido" é obrigatório.';

      case label === "nomeservico" && value:
        return 'O campo "Nome do serviço" é obrigatório.';

      case label === "valorservico" && value:
        return 'O campo "Valor do serviço" é obrigatório.';

      case label === "tiposervico" && value:
        return "Nenhum tipo de serviço selecionado.";

      case label === "nomedoplano" && value:
        return 'O campo "Nome do plano" é obrigatório.';

      case label === "valortabela" && value:
        return 'O campo "Valor Tabela" é obrigatório.';

      case label === "valorplano" && value:
        return 'O campo "Valor Plano" é obrigatório.';

      case label === "periodo" && value:
        return "Nenhum período selecionado.";

      case label === "quantidadeselect" && value:
        return "Nenhuma quantidade selecionada.";

      case label === "perfilservico" && value:
        return "Nenhum perfil do serviço selecionado.";

      case label === "perfilservico" && value:
        return "Nenhum perfil do serviço selecionado.";

      case label === "tipoempresa" && value:
        return "Nenhum tipo de empresa selecionado.";

      case label === "areaatuacao" && value:
        return "Nenhuma área de atuação selecionada.";

      case label === "comprovanteendereco" && value:
        return "Nenhum comprovante de endereço selecionado.";

      case label === "rgcpf" && value:
        return "Nenhum RG e CPF ou CNH selecionado.";

      case label === "declaracao" && value:
        return 'O campo "Número da declaração" é obrigatório.';

      case label === "comprovanteresponsavel" && value:
        return "Nenhum comprovante de residência dos responsáveis selecionado.";

      case label === "tipodeempresa" && value:
        return 'O campo "Tipo de empresa" é obrigatório.';

      case label === "tiporegimetributario" && value:
        return 'O campo "Regime tributário" é obrigatório.';

      case label === "datainicio" && value:
        return "Selecione uma data inicial.";

      case label === "datafim" && value:
        return "Selecione uma data final.";

      case label === "tituloamigavel" && value:
        return 'O campo "Título amigável" é obrigatório.';

      case label === "formadepagamento" && value:
        return 'O campo "Forma de pagamento" é obrigatório.';

      case label === "meiodepagamento" && value:
        return 'O campo "Meio de pagamento" é obrigatório';

      case label === "plano" && value:
        return "Nenhum plano selecionado.";

      case label === "titulodoimovel" && value:
        return 'O campo "Título do Imóvel" é obrigatório.';

      case label === "descricaodoimovel" && value:
        return 'O campo "Descrição do Imóvel" é obrigatório.';

      case label === "nomdedarua" && value:
        return 'O campo "Nome da Rua" é obrigatório.';

      case label === "latitude" && value:
        return 'O campo "Latitude" é obrigatório.';

      case label === "longitude" && value:
        return 'O campo "Longitude" é obrigatório.';

      case label === "nomedarua" && value:
        return 'O campo "Nome da Rua" é obrigatório.';
      case label === "tipodeconta" && value:
        return 'O campo "Tipo da conta" é obrigatório.';
      case label === "agencia" && value:
        return 'O campo "Agência" é obrigatório.';
      case label === "conta" && value:
        return 'O campo "Conta" é obrigatório.';
      case label === "digito" && value:
        return 'O campo "Digito" é obrigatório.';

      case label === "selecionacredor" && value:
        return "Nenhum Credor selecionado";

      case label === "selecionapagador" && value:
        return "Nenhum Pagador selecionado";

      case label === "selecionaimovel" && value:
        return "Nenhum Imóvel selecionado";

      case label === "tipoimovel" && value:
        return "Nenhum tipo de imóvel selecionado";

      case label === "contraproposta" && value:
        return 'O campo "Contra Proposta" é obrigatório.';

      case label === "profissao" && value:
        return "Nenhuma profissão selecionada.";

      case label === "documentoTipo" && value:
        return "Nenhum tipo de documento selecionado.";

      case label === "orgaoExpedidor" && value:
        return 'O campo "Expedido Pelo" é obrigatório..';

      case label === "numeroDocumento" && value:
        return 'O campo "Número do Documento" é obrigatório.';

      case label === "proprietariodoimovel" && value:
        return 'O campo "Proprietário do Imóvel" é obrigatório.';
      case label === "nomesocial" && value:
        return 'O campo "Como gostaria de ser chamado" é obrigatório.';
      case label === "preproduto" && value:
        return 'O campo "Pré-cadastro de produto" é obrigatório.';
      case label === "situacaotributaria" && value:
        return 'O campo "Situação Tributária" é obrigatório.';
      case label === "cfop" && value:
        return 'O campo "CFOP" é obrigatório.';
      case label === "naturezaoperacao" && value:
        return 'O campo "Natureza da operação" é obrigatório.';
      case label === "finalidade" && value:
        return 'O campo "Finalidade" é obrigatório.';
      case label === "indicador" && value:
        return 'O campo "Indicador" é obrigatório.';
      case label === "tipofrete" && value:
        return 'O campo "Tipo de frete" é obrigatório.';
      case label === "motivo" && value:
        return 'O campo "Motivo do cancelamento" é obrigatório.';
      case label === "dado-cliente" && value:
        return 'O campo "CPF/CNPJ do cliente" é obrigatório.';
      case label === "nsu" && value:
        return 'O campo "Nsu" é obrigatório.';
      case label === "bandeira" && value:
        return 'O campo "Informe a bandeira" é obrigatório.';
      case label === "codigopdv" && value:
        return 'O campo "PDV" é obrigatório.';
      case label === "valorabertura" && value:
        return 'O campo "Valor de Abertura" é obrigatório.';
      case label === "cpfcnpj" && value:
        return 'O campo "CPF/CNPJ" é obrigatório';
      case label === "datainativacao" && value:
        return 'O campo "Data de Inativação" é obrigatório';
      case label === "estoquemin" && value:
        return 'O campo "Estoque Mínimo" é obrigatório';
      case label === "estoquemax" && value:
        return 'O campo "Estoque Máximo" é obrigatório';
      case label === "ano" && value:
        return 'O campo "Ano" é obrigatório';
      case label === "rePassword" && value:
        return 'O campo "Confirmar Senha" é obrigatório';
      case label === "password" && value:
        return 'O campo "Senha" é obrigatório';
      case label === "codigo_vista" && value:
        return 'O campo "Código Vista" é obrigatório';
      case label === "emissorboleto" && value:
        return 'O campo "Emissor de Boleto" é obrigatório';
      case label === "emissornfse" && value:
        return 'O campo "Emissor de Nota Fiscal Eletrônica de Serviço | NFSE" é obrigatório';
      case label === "emissornotaproduto" && value:
        return 'O campo "Emissor de Nota Fiscal Eletrônica de Produto | NFC-e | DANFE |NF-e | Cupom Fiscal" é obrigatório';
      case label === "processamentofolha" && value:
        return 'O campo "Processamento de folha de pagamento para funcionário com registro CLT" é obrigatório';
      case label === "prolaboresocio" && value:
        return 'O campo "Pró-labore de sócios" é obrigatório';
      case label === "faturamentomedio" && value:
        return 'O campo "Faturamento médio" é obrigatório';
      case label === "rg" && value:
        return 'O campo "RG" é obrigatório';
      case label === "quantidade_entrada_estoque" && value:
        return 'O campo "Quantidade de entrada no estoque" é obrigatório';
      case label === "unidade_medida_produto" && value:
        return 'O campo "Unidade de Medida do produto" é obrigatório';
      case label === "data_abertura" && value:
        return 'O campo "Data de abertura" é obrigatório';
      case label === "hora_abertura" && value:
        return 'O campo "Hora de abertura" é obrigatório';
      case label === "data_fechamento" && value:
        return 'O campo "Data de fechamento" é obrigatório';
      case label === "hora_fechamento" && value:
        return 'O campo "Hora de fechamento" é obrigatório';
      case label === "inscricao_suframa" && value:
        return 'O campo "Inscrição Suframa" é obrigatório';
      case label === "dia_vencimento_plano" && value:
        return 'O campo "Dia de vencimento do plano" é obrigatório';
      case label === "emailpara" && value:
        return 'O campo "Para" é obrigatório';
      case label === "emailcco" && value:
        return 'O campo "Cco" é obrigatório';
      case label === "emailassunto" && value:
        return 'O campo "Assunto" é obrigatório';
      case label === "emailmensagem" && value:
        return 'O campo "Mensagem" é obrigatório';
      case label === "prefeitura" && value:
        return 'O campo "Prefeitura" é obrigatório';

      case label === "nomeFuncionario" && value:
        return 'O campo "Nome do Funcionário" é obrigatório';
      case label === "dataAdmissao" && value:
        return 'O campo "Data de Admissão" é obrigatório';
      case label === "diasExperiencia" && value:
        return 'O campo "Quantidade de dias do contrato de experiência" é obrigatório';
      case label === "departamento" && value:
        return 'O campo "Departamento" é obrigatório';
      case label === "salarioBase" && value:
        return 'O campo "Salário Base" é obrigatório';
      case label === "proventosDescontos" && value:
        return 'O campo "Proventos e Descontos" é obrigatório';
      case label === "resultadoAtestadoOcupacional" && value:
        return 'O campo "Resultado do atestado ocupacional" é obrigatório';
      case label === "tipoAdmissao" && value:
        return 'O campo "Tipo de Admissão" é obrigatório';
      case label === "vinculoTrabalista" && value:
        return 'O campo "Vínculo Trabalhista" é obrigatório';
      case label === "categoria_dp" && value:
        return 'O campo "Categoria" é obrigatório';
      case label === "funcao" && value:
        return 'O campo "Função" é obrigatório';
      case label === "formaPagamento" && value:
        return 'O campo "Forma de pagamento" é obrigatório';
      case label === "quantidadePassagens" && value:
        return 'O campo "Quantidade de passagens por dia" é obrigatório';
      case label === "numeroBilhete" && value:
        return 'O campo "Número do Bilhete" é obrigatório';
      case label === "paisDp" && value:
        return 'O campo "País" é obrigatório';
      case label === "nisNit" && value:
        return 'O campo "NIS/PIS/PASED ou NIT" é obrigatório';
      case label === "tipoIdentidadeDp" && value:
        return 'O campo "Tipo Identidade" é obrigatório';
      case label === "numeroDoc" && value:
        return 'O campo "Número do Documento (RG, RIC ou RNE)" é obrigatório';
      case label === "emissorDoc" && value:
        return 'O campo "Órgão Emissor do Documento" é obrigatório';
      case label === "emissaoDoc" && value:
        return 'O campo "Emissão do Documento" é obrigatório';
      case label === "ufDoc" && value:
        return 'O campo "UF do Documento" é obrigatório';
      case label === "ctps" && value:
        return 'O campo "CTPS" é obrigatório';
      case label === "ufCtpsDp" && value:
        return 'O campo "UF da CTPS" é obrigatório';
      case label === "inicioDependencia" && value:
        return 'O campo "Início da Dependência" é obrigatório';
      case label === "paisNascimento" && value:
        return 'O campo "País de Nascimento" é obrigatório';
      case label === "nomeMae" && value:
        return 'O campo "Nome da mãe" é obrigatório';
      case label === "nacionalidadeDp" && value:
        return 'O campo "Nacionalidade" é obrigatório';
      case label === "estadoNascimento" && value:
        return 'O campo "Estado de Nascimento" é obrigatório';
      case label === "municipioNascimento" && value:
        return 'O campo "Município de Nascimento" é obrigatório';
      case label === "empresaDp" && value:
        return 'O campo "Empresa" é obrigatório';
      case label === "funcionario" && value:
        return 'O campo "Funcionário" é obrigatório';
      case label === "documentoDp" && value:
        return 'O campo "Documento" é obrigatório';
      case label === "emissorDocumentoDp" && value:
        return 'O campo "Emissor" é obrigatório';
      case label === "data" && value:
        return 'O campo "periodo_ferias_de" é obrigatório';
      case label === "data" && value:
        return 'O campo "Período de férias de" é obrigatório';
      case label === "periodo_ferias_ate" && value:
        return 'O campo "Período de férias até" é obrigatório';
      case label === "data_pagamento" && value:
        return 'O campo "Data dos pagamentos" é obrigatório';
      case label === "data_adiantamento" && value:
        return 'O campo "Adiantamentos" é obrigatório';
      case label === "periodo_de" && value:
        return 'O campo "Período de" é obrigatório';
      case label === "periodo_ate" && value:
        return 'O campo "Período até" é obrigatório';
      case label === "id_tipo_movimento_folha" && value:
        return 'O campo "Tipo de movimento" é obrigatório';
      case label === "id_evento_folha" && value:
        return 'O campo "Evento" é obrigatório';
      case label === "tipo" && value:
        return 'O campo "Tipo" é obrigatório';
      case label === "diasParaRenovacaoSenha" && value:
        return 'O campo "Renovação de senha" é obrigatório';
      case label === "periodo_de_emissao_de" && value:
        return 'O campo "Período de emissão de" é obrigatório';
      case label === "periodo_de_emissao_ate" && value:
        return 'O campo "Período de emissão até" é obrigatório';

      default:
        break;
    }
    return undefined;
  });
  return errors.filter((error) => error !== undefined);
};

export default validateFields;
