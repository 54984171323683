import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import Input from "../../../components/Input";
import Button from "../../../components/ButtonComponent";
import Select from "../../../components/SelectComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

import empresasService from "../../../services/empresasService";
import categoriasService from "../../../services/categoriasServices";

import "./styles.scss";
import { Grid } from "@material-ui/core";
import InputCheck from "../../../components/InputCheckLeft";
import selectService from "../../../services/selectServices";

const tiposCategoria = [
  {
    id: 1,
    nome: "ENTRADA",
  },
  {
    id: 2,
    nome: "SAÍDA",
  },
];

function CadastroSubategorias() {
  const history = useHistory();
  const params = useParams();
  const { companyId, categoryId, subCategoryId } = params;

  const { selectEmpresa } = empresasService;
  const { cadastraCategoria, consultarCategoria, atualizaCategoria } =
    categoriasService;
  const { selecionaCategoriaEmpresa } = selectService;

  const [listEmpresas, setListEmpresas] = useState([]);
  const [listCategorias, setListCategorias] = useState([]);

  const [idEmpresa, setIdEmpresa] = useState(null);
  const [idCategoria, setIdCategoria] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [nome, setNome] = useState("");
  const [conta, setConta] = useState(null);
  const [ativo, setAtivo] = useState(0);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [loading, setLoading] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
      setShowMessage(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
      setShowMessage(false);
    }
    if (callbackType === "success") {
      history.push("/subcategorias");
    }
  }

  async function handleSubmit() {
    if (!validateFields()) return;

    setLoading(true);

    try {
      let response = {};

      if (subCategoryId) {
        response = await atualizaCategoria({
          id: subCategoryId,
          idEmpresa,
          idCategoria,
          nome,
          status: ativo,
          idTipoCategoria: tipo,
          contaIntegracao: conta,
        });
      } else {
        response = await cadastraCategoria({
          idEmpresa,
          idCategoria,
          nome,
          status: ativo,
          idTipoCategoria: tipo,
          contaIntegracao: conta,
        });
      }

      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status !== 201 && response.status !== 200) {
        setLoading(false);
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackDuration(3000);
        setCallbackMessage("Erro!");
        if (response.data.error)
          setCallbackErrorList(Object.values(response.data.error));
        else setCallbackErrorList(["Ocorreu um erro"]);
        return;
      }
      if (response.status === 201 || response.status === 200) {
        setCallbackShown(true);
        setCallbackType("success");
        setCallbackDuration(2000);
        setCallbackMessage(
          response?.data?.message || "Subcategoria cadastrada com sucesso."
        );
      }
      setLoading(false);
    } catch (error) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Ocorreu um erro"]);
      setLoading(false);
    }
  }

  function verifyField(field, callback) {
    if (field === "" || field === null || field === undefined) {
      callback();
    }
  }

  function validateFields() {
    let list = [];
    verifyField(idEmpresa, () =>
      list.push('O campo "Empresa" deve ser selecionada.')
    );
    verifyField(idCategoria, () =>
      list.push('O campo "Categoria" deve ser selecionado.')
    );
    verifyField(tipo, () =>
      list.push('O campo "Tipo de Subcategoria" deve ser selecionado.')
    );
    verifyField(nome, () =>
      list.push('O campo "Nome da Subcategoria" é obrigatório.')
    );
    setErrors(list);
    if (list.length > 0) setShowMessage(true);
    return list.length === 0;
  }

  const handleEmpresaSelecionada = (id) => {
    setIdEmpresa(id);
    loadCategorias(id);
  };

  const loadEmpresas = useCallback(async () => {
    try {
      setLoading(true);
      const response = await selectEmpresa();
      if (response.status === 200) {
        setListEmpresas(response.data);
        setIdEmpresa(Number(companyId));
        setIdCategoria(Number(categoryId));
        loadCategorias(Number(companyId));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      setListEmpresas([]);
    }
  }, [selectEmpresa, companyId, categoryId]);

  const loadCategorias = useCallback(
    async (empresaSelecionada) => {
      try {
        setLoading(true);
        const response = await selecionaCategoriaEmpresa(empresaSelecionada);
        if (response.status === 200) {
          setListCategorias(response.data);
        }
        setLoading(false);
      } catch (error) {
        setListCategorias([]);
        setLoading(false);
      }
    },
    [selecionaCategoriaEmpresa, categoryId]
  );

  const loadSubcategoria = useCallback(async () => {
    try {
      const response = await consultarCategoria(subCategoryId);
      if (response.status === 200) {
        console.log({ data: response.data });
        const sub = response.data;
        setAtivo(sub?.status || 0);
        setNome(sub?.nome || null);
        setConta(sub?.conta_integracao || null);
        setTipo(sub?.id_tipo_categoria || null);
      }
    } catch (error) {
      console.log({ error });
    }
  }, [consultarCategoria, subCategoryId]);

  useEffect(() => {
    (async function () {
      console.log({ subCategoryId });
      loadEmpresas();
      if (subCategoryId) {
        loadSubcategoria(subCategoryId);
      }
    })();
  }, []);

  return (
    <>
      <div className="session-container pagina-cadastro-subcategoria">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <CallbackMessage
            type={callbackType}
            open={callbackShown}
            duration={callbackDuration}
            errorList={callbackErrorList}
            handleClose={handleClose}
            message={callbackMessage}
          />
          <form className="category-register-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  styleType="form"
                  label="Empresa"
                  required
                  initialValue={
                    idEmpresa
                      ? listEmpresas.find((item) => item.id === idEmpresa)?.nome
                      : ""
                  }
                  title="Selecione uma empresa"
                  list={listEmpresas}
                  callback={handleEmpresaSelecionada}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  styleType="form"
                  label="Categoria"
                  required
                  initialValue={
                    idCategoria
                      ? listCategorias.find((item) => item.id === idCategoria)
                          ?.nome
                      : ""
                  }
                  title="Selecione uma categoria"
                  list={listCategorias}
                  callback={(id) => setIdCategoria(id)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  nome="nome"
                  label="Nome da subcategoria"
                  tipo="text"
                  required={true}
                  handleInputChange={(event) => setNome(event.target.value)}
                  value={nome}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  styleType="form"
                  label="Tipo"
                  required
                  initialValue={
                    tipo
                      ? tiposCategoria.find((item) => item.id === tipo)?.nome
                      : ""
                  }
                  title="Selecione"
                  list={tiposCategoria}
                  callback={(id) => setTipo(id)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  nome="conta"
                  label="Conta contábil"
                  type="number"
                  handleInputChange={(event) => setConta(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <InputCheck
                  id="ativo"
                  title="Ativo"
                  nome="ativo"
                  onInputChange={(value) => setAtivo(value ? 1 : 0)}
                  checkedValue={ativo}
                />
              </Grid>
              <Grid item xs={12}>
                <span className="required-text ">* Campos obrigatórios</span>
              </Grid>
            </Grid>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            text="Salvar"
            className="btn-success"
            onClick={handleSubmit}
          />
        </span>
      </div>
      {showMessage && (
        <CallbackMessage
          open={showMessage}
          type={`${errors.length === 0 ? "success" : "error"}`}
          message={`${
            errors.length === 0 ? "Categoria cadastrada com sucesso!" : "Erro!"
          }`}
          errorList={errors}
          handleClose={handleClose}
          duration={errors.length === 0 ? 2000 : 6000}
        />
      )}
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastroSubategorias;
