import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import ButtonComponent from "../../components/ButtonComponent";
import CallbackMessage from "../../components/CallbackMessage";
import DatePicker from "../../components/DatePicker";
import Input from "../../components/Input";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import OverlayLoading from "../../components/OverlayLoading";
import Select from "../../components/SelectComponent";
import Textarea from "../../components/Textarea";
import TableServices from "./components/TableServices";

import servicosServices from "../../services/servicosService";
import solicitacaoServicoServices from "../../services/solicitacaoServicoServices";

import validateFields from "../../utils/validateFields";

import Switch from "../../components/Switch";
import "./styles.scss";
import useCompany from "../../hooks/useCompany";

function SolicitacaoServico() {
  const {
    selecionaStatusSolicitacao,
    selecionaServicoPlano,
    solicitarServico,
    atualizarSolicitacaoServico,
    consultaSolicitacao,
  } = solicitacaoServicoServices;
  const { consultaServico } = servicosServices;

  const { id } = useParams();
  const history = useHistory();

  const {
    companyList: listEmpresa,
    selectedCompany: idEmpresa,
    setSelectedCompany: setIdEmpresa,
    getEmpresaPagina,
  } = useCompany("solicitacoes_servicos");

  const perfilAcesso = Number(window.localStorage.getItem("perfil_acesso"));
  const canView = perfilAcesso === 1 || perfilAcesso === 2;

  const [idServico, setIdServico] = useState(null);
  const [idStatus, setIdStatus] = useState(1);
  const [valorIndividual, setValorIndividual] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [descricao, setDescricao] = useState("");
  const [editStatusSolicitacao, setEditStatusSolicitacao] = useState("");
  const [indexSelected, setIndexSelected] = useState(null);
  const [dataVencimento, setDataVencimento] = useState(null);

  const [isEdit, setIsEdit] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [loading, setLoading] = useState(false);
  const [listServices, setListServices] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [autorizaParcelamento, setAutorizaParcelamento] = useState(false);
  const [quantidadeParcelas, setQuantidadeParcelas] = useState(1);

  const [dataServiceList, setDataServiceList] = useState([]);
  const loadSolicitacao = async (statusList = [], servicosList = []) => {
    if (!id) return;
    const response = await consultaSolicitacao(id);

    if (response.status === 200) {
      const { data } = response;
      setEditStatusSolicitacao(
        statusList.find((status) => status.nome === data.status_solicitacao)
          ? statusList.find((status) => status.nome === data.status_solicitacao)
              .id
          : ""
      );
      const solicitacoes = data.itens.map((item) => {
        return {
          valorUnit: item.valor_servico,
          quantidade: item.quantidade,
          observacoes: item.observacao,
          idSolicitacaoItem: item.id_solicitacao_servico_item,
          id: item.id_servico,
          idStatus:
            statusList.find((status) => status.nome === item.status_servico) !==
            undefined
              ? statusList.find((status) => status.nome === item.status_servico)
                  .id
              : "",
        };
      });
      setIdEmpresa(response.data.id_empresa);
      setDataServiceList(solicitacoes);
      console.log("solicitacoes :: ", solicitacoes);
    } else if (response.status === 401) {
      setLoading(false);
      setOpenLoginExpirado(true);
    }
  };

  const loadSelects = async () => {
    const values = await Promise.all([
      selecionaStatusSolicitacao(),
      selecionaServicoPlano(),
    ]);

    if (values[0].status === 200) {
      setListStatus(values[0].data);
    }

    if (values[1].status === 200) {
      setListServices(values[1].data);
    }

    await loadSolicitacao(values[0].data, values[1].data);
  };

  const handleEdit = (id) => {
    setIsEdit(true);

    const item = dataServiceList.find((item) => item.idSolicitacaoItem === id);
    setQuantidade(item.quantidade);
    setValorIndividual(item.valorUnit);
    setObservacoes(item.observacoes);
    setIdServico(item.idSolicitacaoItem);
    setIdStatus(item.idStatus);
    setIndexSelected(
      dataServiceList.findIndex((item) => item.idSolicitacaoItem === id)
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }

    if (reason === "timeout") {
      setCallbackShown(false);
    }

    if (callbackType === "success") {
      history.goBack();
    }
  };

  const handleSubmitFormService = async () => {
    let serviceObj = {
      idSolicitacaoItem: idServico,
      idStatus: 1,
      valorUnit: valorIndividual,
      descricao,
      quantidade,
      observacoes,
    };

    const servicesFields = [
      {
        label: "empresa",
        value: idEmpresa,
      },
      {
        label: "servico",
        value: idServico,
      },
      {
        label: "status",
        value: idStatus,
      },
      {
        label: "quantidade",
        value: quantidade,
      },
    ];

    const servicesFieldsErrors = validateFields(servicesFields);

    if (servicesFieldsErrors.length !== 0) {
      setCallbackType("error");
      setCallbackShown(true);
      setCallbackMessage("Erro!");
      setCallbackErrorList(servicesFieldsErrors);
      return;
    }

    setQuantidade("");
    setValorIndividual("");
    setObservacoes("");
    setIdServico(null);

    let newServicesArray = [...dataServiceList, serviceObj];

    let hasService = dataServiceList.find(
      (item) => item.idSolicitacaoItem === serviceObj.idSolicitacaoItem
    );

    if (hasService !== undefined) {
      newServicesArray = dataServiceList
        .map((item) => {
          if (item.idSolicitacaoItem === hasService.idSolicitacaoItem) {
            return {
              ...item,
              quantidade: !isEdit
                ? Number(serviceObj.quantidade) + Number(item.quantidade)
                : serviceObj.quantidade,
              observacoes: serviceObj.observacoes,
              descricao: serviceObj.descricao,
              valor_servico: serviceObj.valorUnit,
            };
          }
          return item;
        })
        .filter((item) => item !== undefined);
    }

    setDataServiceList(newServicesArray);
    setIsEdit(false);
  };

  const handleSelectEmpresa = (id) => {
    setIdEmpresa(id);
  };

  const handleSelectService = async (id) => {
    setLoading(true);

    const response = await consultaServico(id);
    setIdServico(id);

    if (response.status === 200) {
      const { data } = response;

      setDescricao(data.nome);
      setValorIndividual(data.valor);
    }

    if (response.status !== 200) {
      setCallbackMessage("Erro");
      setCallbackErrorList(Object.values(response.data.error).flat(Infinity));
      setCallbackType("error");
      setCallbackShown(true);
      setIdServico(null);
    }

    setLoading(false);
  };

  const handleRemovedService = (id) => {
    console.log("id :: ", id);
    setDataServiceList(
      dataServiceList.filter((item) => item.idSolicitacaoItem !== id)
    );
    setIndexSelected(
      dataServiceList.findIndex((item) => item.idSolicitacaoItem === id)
    );
  };

  const handleCadastrarServicos = useCallback(
    async (services) => {
      console.log(autorizaParcelamento);
      // const response = await solicitarServico(idEmpresa, services, autorizaParcelamento ? 1 : 0, autorizaParcelamento ? quantidadeParcelas : null);
      const response = await solicitarServico(
        idEmpresa,
        services,
        autorizaParcelamento ? 1 : 0,
        autorizaParcelamento ? quantidadeParcelas : null,
        dataVencimento
      );

      if (response.status === 200 || response.status === 201) {
        setCallbackType("success");
        setCallbackMessage("Cadastrado com sucesso!");
        setCallbackDuration(2000);
        setCallbackShown(true);
      } else if (response.status === 500) {
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList([
          "Erro interno no servidor. Por favor, contate o suporte.",
        ]);
        setCallbackShown(true);
      } else if (response.status === 401) {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      } else {
        console.log(response);
        console.log(response?.data?.error);
        if (response?.data?.error) {
          let error = [];
          Object.keys(response?.data?.error).map((a) => {
            if (typeof response?.data?.error[a] === "object") {
              Object.keys(response?.data?.error[a]).map((b) => {
                error.push(response?.data?.error[a][b]);
              });
            } else {
              error.push(Object.values(response?.data?.error).flat(Infinity));
            }
          });
          setCallbackErrorList(error);
        } else {
          setCallbackErrorList([
            "Houve um erro ao tentar contratar o(s) serviço(s).",
          ]);
        }
        setCallbackMessage("Erro!");
        setCallbackType("error");

        setCallbackShown(true);
        setCallbackDuration(4000);
      }
    },
    [
      idEmpresa,
      solicitarServico,
      autorizaParcelamento,
      quantidadeParcelas,
      dataVencimento,
    ]
  );

  const handleAtualizarServicos = useCallback(
    async (services) => {
      const response = await atualizarSolicitacaoServico(
        id,
        editStatusSolicitacao,
        services,
        autorizaParcelamento ? 1 : 0,
        autorizaParcelamento ? quantidadeParcelas : null,
        dataVencimento
      );

      if (response.status === 200 || response.status === 201) {
        setCallbackMessage("Atualizado com sucesso!");
        setCallbackDuration(2000);
        setCallbackShown(true);
      } else if (response.status === 500) {
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList([
          "Erro interno no servidor. Por favor, contate o suporte.",
        ]);
        setCallbackShown(true);
      } else if (response.status === 401) {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      } else {
        if (response?.data?.error) {
          let error = [];
          Object.keys(response?.data?.error).map((a) => {
            if (typeof response?.data?.error[a] === "object") {
              Object.keys(response?.data?.error[a]).map((b) => {
                error.push(response?.data?.error[a][b]);
              });
            } else {
              error.push(Object.values(response?.data?.error).flat(Infinity));
            }
          });
          setCallbackErrorList(error);
        } else {
          setCallbackErrorList([
            "Houve um erro ao tentar atualizar o(s) serviço(s).",
          ]);
        }
        setCallbackMessage("Erro!");
        setCallbackType("error");
        setCallbackShown(true);
        setCallbackDuration(4000);
      }
    },
    [
      atualizarSolicitacaoServico,
      id,
      editStatusSolicitacao,
      autorizaParcelamento,
      quantidadeParcelas,
      dataVencimento,
    ]
  );

  const handleSubmitServices = useCallback(async () => {
    setLoading(true);
    if (id) {
      const itens = dataServiceList.map((item) => ({
        id_servico: item.id,
        valor_servico: item.valorUnit,
        quantidade: item.quantidade,
        observacao: item.observacoes,
        status_servico: item.idStatus,
        id_solicitacao_servico_item: item.idSolicitacaoItem,
      }));
      await handleAtualizarServicos(itens);
    } else {
      const services = dataServiceList.map((item) => ({
        id_servico: item.idSolicitacaoItem,
        valor_servico: item.valorUnit,
        quantidade: item.quantidade,
        observacao: item.observacoes,
        status_servico: item.idStatus,
      }));
      await handleCadastrarServicos(services);
    }

    setLoading(false);
  }, [dataServiceList, handleAtualizarServicos, handleCadastrarServicos, id]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadSelects();
      setLoading(false);
    })();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("solicitacoes_servicos");
    if (empresaStorage) {
      handleSelectEmpresa(empresaStorage);
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <div className="session-container solicitacao-servico-container">
        <Grid container justifyContent="center" className="containerService">
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              {id ? (
                <>
                  <div className="label">Empresa *</div>
                  <div className="text-background">
                    {listEmpresa.find((item) => item.id === idEmpresa)
                      ? listEmpresa.find((item) => item.id === idEmpresa).nome
                      : ""}
                  </div>
                </>
              ) : (
                <Select
                  styleType="form"
                  required
                  label="Empresa"
                  list={listEmpresa}
                  initialValue={
                    listEmpresa.find((item) => item.id === idEmpresa)
                      ? listEmpresa.find((item) => item.id === idEmpresa)
                      : ""
                  }
                  callback={(id) => {
                    handleSelectEmpresa(id);
                  }}
                />
              )}
            </Grid>
            <Grid item md={4} xs={12}>
              <Select
                styleType="form"
                required
                label="Serviço"
                list={listServices}
                initialValue={
                  listServices.find((item) => item.id === idServico) !==
                  undefined
                    ? listServices.find((item) => item.id === idServico)
                    : null
                }
                callback={(id) => {
                  handleSelectService(id);
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <>
                <div className="label">Status *</div>
                <div className="solicitacaoServico__statusInputContainer">
                  <input type="text" readOnly value="Aguardando" />
                </div>
              </>
            </Grid>
            <Grid item md={4} xs={12}>
              <>
                <DatePicker
                  initDate={dataVencimento}
                  label="Data de vencimento *"
                  noMask
                  handleChange={(value) => setDataVencimento(value)}
                />
              </>
            </Grid>
            <Grid item className="gridXs4">
              <Input
                tipo="dinheiro"
                nome="valorIndividual"
                label="Valor individual"
                value={valorIndividual}
                disabled={
                  Number(perfilAcesso) !== 1 && Number(perfilAcesso) !== 2
                }
                handleInputChange={(e) => {
                  setValorIndividual(e.value);
                }}
              />
            </Grid>
            <Grid item className="gridXs4">
              <Input
                tipo="numero"
                nome="quantidade"
                required
                label="Quantidade"
                value={quantidade}
                handleInputChange={(e) => setQuantidade(e.target.value)}
              />
            </Grid>
            <Grid item className="gridXs4">
              <Input
                readOnly
                tipo="dinheiro"
                nome="valorTotal"
                label="Valor total"
                value={Number(valorIndividual) * quantidade}
              />
            </Grid>
            {canView && (
              <>
                <Grid item className="container-input-switch" xs={12}>
                  Autoriza parcelamento?
                  <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                    Não
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={autorizaParcelamento}
                      onClick={() =>
                        setAutorizaParcelamento(!autorizaParcelamento)
                      }
                    />
                  </Grid>
                  <Grid item className="colorWarning">
                    Sim
                  </Grid>
                </Grid>
                {autorizaParcelamento && (
                  <Grid item className="gridXs4" xs={12}>
                    <Input
                      nome="quantidadeParcelas"
                      label="Quantidade de parcelas"
                      value={quantidadeParcelas}
                      handleInputChange={(e) =>
                        setQuantidadeParcelas(e.target.value)
                      }
                    />
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12}>
              <Textarea
                label="Observações"
                value={observacoes}
                handleInputChange={(event) =>
                  setObservacoes(event.target.value)
                }
              />
            </Grid>
            <Grid container justifyContent="flex-end" item>
              <ButtonComponent
                text={id ? "Atualizar" : "+ Adicionar"}
                onClick={handleSubmitFormService}
                className="success-outline btnAddService"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      {dataServiceList.length !== 0 && (
        <div className="session-container containerServiceList">
          <Grid
            container
            justifyContent="center"
            className="containerService serviceTable"
          >
            <Grid container item xs={12} spacing={2}>
              <Grid container justifyContent="flex-start" item>
                <span className="titleServices">Serviços solicitados</span>
              </Grid>
              <TableServices
                serviceList={dataServiceList}
                onDelete={handleRemovedService}
                edit={id}
                onEdit={handleEdit}
              />
              <div className="solicitacao-servico-contratar-button-area">
                <ButtonComponent
                  text="Contratar"
                  className="btn-success"
                  onClick={handleSubmitServices}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        handleClose={handleClose}
        message={callbackMessage}
        duration={callbackDuration}
        errorList={callbackErrorList}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default SolicitacaoServico;
