import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import InputComponent from "../../../components/Input";
import SelectComponent from "../../../components/SelectComponent";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import empresasService from "../../../services/empresasService";
import FileInput from "../../../components/InputFile";
import ButtonComponent from "../../../components/ButtonComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import DatePicker from "../../../components/DatePicker";
import Textarea from "../../../components/Textarea";

import obrigacoesServices from "../../../services/obrigacoesService";

import validateFields from "../../../utils/validateFields";

import "./styles.scss";
import SmallScreen from "../../../utils/smallScreen";
import useCompany from "../../../hooks/useCompany";

function CadastroObrigacoes() {
  const isSmallScreen = SmallScreen();

  const {
    companyList: listEmpresas,
    selectedCompany: idEmpresa,
    setSelectedCompany: setIdEmpresa,
    getEmpresaPagina,
  } = useCompany("obrigacoes");

  const [file, setFile] = useState(null);
  const [referencia, setReferencia] = useState("");
  const [fileName, setFileName] = useState(null);
  const [observacoes, setObservacoes] = useState(null);
  const [selectStatus, setSelectStatus] = useState([]);
  const [selectObrigacao, setSelectObrigacao] = useState([]);
  const [obrigacaoId, setObrigacaoId] = useState("");
  const [vencimento, setVencimento] = useState("");
  const [statusId, setStatusId] = useState("");
  const [valor, setValor] = useState("");

  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState("");
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const history = useHistory();

  let loadSelects = async () => {
    let responseStatus = await obrigacoesServices.selectStatus();
    let responseObrigacoes = await obrigacoesServices.selectObrigacao();
    if (responseStatus.status === 401 || responseObrigacoes.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setSelectObrigacao(responseObrigacoes.data);
    setSelectStatus(responseStatus.data);
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/obrigacoes-financeiras");
    }
  }

  async function handleSubmit() {
    console.log("submit");
    setLoading(true);
    let hasErrors = false;

    const fieldsToValidate = [
      {
        label: "referencia",
        value: referencia,
      },
      {
        label: "vencimentoem",
        value: vencimento,
      },
      {
        label: "status",
        value: statusId,
      },
      {
        label: "obrigacao",
        value: obrigacaoId,
      },
      {
        label: "valor",
        value: valor,
      },
      {
        label: "empresa",
        value: idEmpresa,
      },
      {
        label: "anexo",
        value: file,
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      hasErrors = true;
      setLoading(false);
      setCallbackType("error");
      setCallbackErrorList(fieldsErrors);
      setCallbackMessage("Erro!");
      setCallbackShown(true);
    }

    if (hasErrors) return;

    const result = await obrigacoesServices.cadastraObrigacao(
      referencia,
      obrigacaoId,
      vencimento,
      statusId,
      idEmpresa,
      file,
      valor,
      observacoes,
      fileName
    );
    console.log(result);
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.status !== 200) {
      setLoading(false);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackShown(true);
      console.log(typeof result.data.error);
      if (typeof result.data.error === "string") {
        if (result.data.msg && typeof result.data.msg === "string") {
          setCallbackErrorList([result.data.msg]);
        } else {
          setCallbackErrorList([result.data.error]);
        }
      } else {
        setCallbackErrorList(Object.values(result.data.error));
      }
      return;
    }
    setLoading(false);
    setCallbackShown(true);
    setCallbackMessage("Obrigação cadastrada com sucesso.");
    setCallbackType("success");
    setCallbackDuration(2000);
  }

  function handleSelecionaObrigacao(id) {
    const textoPadrao =
      selectObrigacao?.find((item) => item.id === id)?.texto_padrao || "";
    setObservacoes(textoPadrao);
    setObrigacaoId(id);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadSelects();
      const empresaStorage = getEmpresaPagina("obrigacoes");
      if (empresaStorage) {
        setIdEmpresa(empresaStorage);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <form className="session-container-form">
        <div className="session-container">
          <span className="session-container-header">
            {loading && <OverlayLoading />}
            <div className={`${isSmallScreen ? "" : "p-horizontal"}`}>
              <Grid item container direction="column" spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <InputComponent
                      required
                      label="Referência"
                      value={referencia}
                      handleInputChange={(event) =>
                        setReferencia(event.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DatePicker
                      label="Vencimento em *"
                      noMask
                      handleChange={(event) => setVencimento(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SelectComponent
                      styleType="form"
                      label="Status"
                      required
                      title="Selecione uma opção"
                      list={selectStatus}
                      callback={(id) => setStatusId(id)}
                      search={false}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <SelectComponent
                      styleType="form"
                      label="Obrigação"
                      required
                      title="Selecione uma obrigação"
                      list={selectObrigacao}
                      callback={(id) => handleSelecionaObrigacao(id)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputComponent
                      required
                      tipo="dinheiro"
                      title="R$"
                      label="Valor"
                      value={valor}
                      handleInputChange={(event) => setValor(event.value)}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <SelectComponent
                    styleType="form"
                    label="Empresa"
                    title="Selecione uma empresa"
                    list={listEmpresas}
                    initialValue={
                      listEmpresas?.find((item) => item.id === idEmpresa)
                        ?.nome || null
                    }
                    required
                    callback={(id) => setIdEmpresa(id)}
                  />
                </Grid>
                <Grid item>
                  <Textarea
                    label="Observações"
                    minRows={5}
                    value={observacoes}
                    onChange={(e) => setObservacoes(e.target.value)}
                    maxLength={300}
                  />
                </Grid>
                <Grid item>
                  <FileInput
                    title="Anexo *"
                    onChange={(value, valueFileName) => {
                      setFile(value);
                      setFileName(valueFileName);
                    }}
                  />
                </Grid>
                <Grid item>
                  <span className="required-text">* Campos obrigatórios</span>
                </Grid>
              </Grid>
              <div style={{ height: 40 }} />
            </div>
          </span>
        </div>
      </form>
      <div style={{ height: 40 }} />
      <Grid item container justifyContent="flex-end">
        <ButtonComponent
          text="Salvar"
          className="btn-success zero-margin"
          onClick={handleSubmit}
        />
      </Grid>
      {callbackShown && (
        <CallbackMessage
          open={callbackShown}
          type={callbackType}
          handleClose={handleClose}
          message={callbackMessage}
          duration={callbackDuration}
          errorList={callbackErrorList}
        />
      )}
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastroObrigacoes;
