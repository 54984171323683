import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import { Grid } from "@material-ui/core";
import Button from "../../../components/ButtonComponent";
import "./styles.scss";

import AutocompleteInput from "../../../components/AutocompleteInput";
import { formaPagamentoServices } from "../../../services/formaPagamentoServices";
import selectService from "../../../services/selectServices";
import TableComponent from "../../../components/TableResponsiveComponent";
import ConfirmDialog from "../../../components/ConfirmDialog";

function filterComponent({
  listaOrigem,
  initOrigem,
  handleSelectedValues,
  handleApplyFilters,
  handleCleanFilters,
}) {
  return (
    <div className="session-container filter-component-container chat">
      <Grid container spacing={2} alignItems="center" className="form-table">
        <Grid item xs={12}>
          <AutocompleteInput
            label="Origem"
            data={listaOrigem}
            initValue={initOrigem}
            placeholder="Selecione uma origem"
            handleSelectedValues={(values) => handleSelectedValues(values)}
            key={(option) => option.id}
            getOptionLabel={(option) => option.nome}
            getOptionSelected={(option, value) => option.id === value.id}
            required
            noOptionsText="Nenhuma opção disponível"
            page="formas-pagamento"
          />
        </Grid>
        <Grid className="ml-auto">
          <div className="filter-button-area align-rigth">
            <Button
              onClick={handleApplyFilters}
              text="Filtrar"
              className="btn-primary"
            />
            <Button
              onClick={handleCleanFilters}
              text="Limpar Filtros"
              className="default-outline clean-filters-button"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export const ListaFormasPagamento = () => {
  const history = useHistory();

  const { listaFormasPagamento, removeFormaPagamento } = formaPagamentoServices;
  const { selecionaOrigemFomaPagamento } = selectService;
  const [loading, setLoading] = useState(false);
  const [origem, setOrigem] = useState([]);
  const [idDelete, setIdDelete] = useState(null);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [listaOrigem, setListaOrigem] = useState([]);
  const [initOrigem, setInitOrigem] = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleClose = (event, reason) => {
    console.log(reason);
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  };

  const [listRequest, setListRequest] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_forma_pagamento" },
      { columnName: "Descrição", dataRef: "descricao" },
      { columnName: "Origem", dataRef: "multi_label" },
      { columnName: "Status", dataRef: "status" },
    ],
    options: {
      edit: true,
      delete: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      statusLabels: true,
      multiLabels: true,
      filter: false,
    },
    tableOptions: {
      filter: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: "id_forma_pagamento",
    orderByType: "asc",
    perPage: 10,
    searchTerm: null,
    origem: null,
  });

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await listaFormasPagamento({
      page: defaultConfigTable.currentPage,
      orderBy: defaultConfigTable.orderBy,
      orderByType: defaultConfigTable.orderByType,
      perPage: defaultConfigTable.perPage,
      searchTerm: defaultConfigTable.searchTerm,
      origem: defaultConfigTable.origem,
    });
    if (result.status === 200) {
      const { data } = result;
      console.log({ data });
      const newData = data.data.data.map((item) => {
        return {
          ...item,
          status: item.ativo ? "Ativo" : "Inativo",
          multi_label: item.origem
            ? item.origem.map((itemOrigem) => itemOrigem.descricao)
            : [],
        };
      });
      const newResult = {
        ...data.data,
        data: newData,
      };
      setListRequest(newResult);
    } else {
      setCallbackErrorList([
        "Ocorreu um erro ao listar as formas de pagamento.",
      ]);
      setCallbackShown(true);
      setCallbackType("error");
    }
    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.origem,
  ]);

  const loadOrigens = useCallback(async () => {
    const response = await selecionaOrigemFomaPagamento();
    if (response.status === 200) {
      setListaOrigem(response.data);
    }
  }, [selecionaOrigemFomaPagamento]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onOrderBy = (newOrderBy) => {
    if (newOrderBy.orderBy === "multi_label") {
      return;
    }
    let orderBy =
      newOrderBy.orderBy === "status" ? "ativo" : newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  function handleSelectedValues(values) {
    console.log({ values });
    const origensIds = values.map((item) => {
      return item.id;
    });

    setOrigem(origensIds);
    setInitOrigem(values);
  }

  const onEdit = (id) => {
    history.push(`/editar-forma-pagamento/${id}`);
  };

  const handleApplyFilters = () => {
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      origem: origem,
    });
  };

  const handleCleanFilters = async () => {
    setInitOrigem([]);
    handleSelectedValues([]);
    setDefaultConfigTable({
      ...defaultConfigTable,
      origem: null,
    });
  };

  const onClickDelete = (id) => {
    setIdDelete(id);
    setOpenConfirmDelete(true);
  };

  const onDelete = async () => {
    try {
      setLoading(true);
      const response = await removeFormaPagamento(idDelete);

      setOpenConfirmDelete(false);
      if (response.status === 200 || response.status === 201) {
        setCallbackType("success");
        setCallbackDuration(3000);
        await updateTable();
        setLoading(false);
        setCallbackShown(true);
        setCallbackErrorList([]);
        setCallbackMessage("Forma de pagamento excluída com sucesso");
      } else {
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackDuration(6000);

        if (response.status === 401) {
          setOpenLoginExpirado(false);
          setLoading(false);
          return;
        }
        if (response.data.error) {
          setCallbackErrorList(Object.values(response.data.error));
        } else {
          console.log({ msg: response.data.msg });
          if (response?.data?.msg) {
            setCallbackErrorList([response?.data?.msg]);
          } else {
            setCallbackErrorList(["Ocorreu um erro ao excluir"]);
          }
        }
        setCallbackShown(true);
        setLoading(false);
      }
    } catch (error) {
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Ocorreu um erro ao excluir"]);
      setCallbackShown(true);
      setLoading(false);
    }
  };

  const onCancelDelete = () => {
    setOpenConfirmDelete(false);
  };

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    loadOrigens();
  }, []);

  return (
    <div className="container-tabela-responsiva">
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id_forma_pagamento"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        filterComponent={filterComponent({
          listaOrigem,
          initOrigem,
          handleSelectedValues,
          handleApplyFilters,
          handleCleanFilters,
        })}
        cbEdit={onEdit}
        cbDelete={onClickDelete}
      />

      <ConfirmDialog
        open={openConfirmDelete}
        title="CONFIRMAR EXCLUSÃO"
        description="Você tem certeza que deseja excluir a forma de pagamento?"
        cancelLabel="Voltar"
        acceptLabel="Excluir"
        onAccept={onDelete}
        onCancel={onCancelDelete}
        onClose={onCancelDelete}
      />
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />
    </div>
  );
};
