import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import TableComponent from "../../../components/TableComponent";
import ConfirmDialog from "../../../components/ConfirmDialog";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

import gerentesService from "../../../services/gerentesService";

function ListaGerentes() {
  const history = useHistory();
  const [listRequest, setListRequest] = useState([]);
  //eslint-disable-next-line
  const [errors, setErrors] = useState([]);
  //eslint-disable-next-line
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);

  const { consultaGerentes, removeGerente } = gerentesService;

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_gerente" },
      { columnName: "CNPJ/CPF", dataRef: "cnpj_cpf" },
      { columnName: "Razão Social/Nome", dataRef: "nome_razao_social" }
    ],
    options: {
      edit: true,
      delete: true
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null
  });

  const updateTable = useCallback(async () => {
    setLoading(true);
    const response = await consultaGerentes(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm
    );
    setLoading(false);
    if (response.data.hasOwnProperty("data")) {
      setListRequest(response.data);
    } else {
      setListRequest(response);
    }
  }, [
    consultaGerentes,
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm
  ]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  useEffect(() => {
    const newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  const onPageChange = newPageObj => {
    const newPage = newPageObj.page;
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onOrderBy = newOrderBy => {
    const orderBy = newOrderBy.orderBy;
    const orderByType = newOrderBy.orderByType;
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onPerPage = newPerPage => {
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onSearchTerm = value => {
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onEdit = id => {
    history.push(`/editar-gerente/${id}`);
  };

  const handleRemoveDialog = id => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  const handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  const onDelete = async () => {
    const response = await removeGerente(deleteId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status !== 200) {
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackShown(true);
      if (response.data.error) {
        setCallbackErrorList(Object.values(response.data.error));
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
      setLoading(false);
      handleRemoveCancel();

      return;
    }
    if (response.status === 200) {
      setCallbackType("success");
      setCallbackMessage("Gerente removido com sucesso!");
      setCallbackDuration(3000);
      setCallbackShown(true);
      setLoading(false);
      handleRemoveCancel();

      await updateTable();
      return;
    }
    handleRemoveCancel();
  };

  function handleClose(event, reason) {
    if (reason === 'clickaway')
      setShowMessage(false);
    if (reason === 'timeout')
      setShowMessage(false);
    if (errors.length === 0) {
      updateTable();
    }
  }

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id_gerente"
        dataList={listRequest !== undefined ? listRequest.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onEdit}
        cbDelete={handleRemoveDialog}
      />
      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={handleClose}
      />

      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default ListaGerentes;