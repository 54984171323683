import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import InputComponent from "../../../components/Input";
import SwitchComponent from "../../../components/Switch";
import DatePicker from "../../../components/DatePicker";
import Parcelamentos from "../../../components/Parcelamentos";
import SelectComponent from "../../../components/SelectComponent";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import centroCustosService from "../../../services/centroCustosService";
import recebimentosServices from "../../../services/recebimentosService";
import clientesServices from "../../../services/clientesService";
import FileInput from "../../../components/InputFile";
import ButtonComponent from "../../../components/ButtonComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import SmallScreen from "../../../utils/smallScreen";
import "./styles.scss";
import useCompany from "../../../hooks/useCompany";
import { onlyUnsignedNumbers } from "../../../utils/strings";
// import { unformatDate } from "../../../utils/dates";

function CadastroRecebimento() {
  const history = useHistory();
  const isSmallScreen = SmallScreen();

  const {
    companyList: listEmpresas,
    selectedCompany: empresaId,
    setSelectedCompany: setEmpresaId,
    getEmpresaPagina,
  } = useCompany("recebimentos");

  const [file, setFile] = useState(null);
  const [vencimento, setVencimento] = useState("");
  const [emissao, setEmissao] = useState("");
  const [valor, setValor] = useState("");
  const [numeroNota, setNumeroNota] = useState("");
  const [centroId, setCentroId] = useState("");
  const [codigoBarras, setCodigoBarras] = useState(null);
  const [listCentroCusto, setListCentroCusto] = useState([]);
  const [clienteId, setClienteId] = useState("");
  const [categoriaId, setCategoriaId] = useState("");
  const [descricao, setDescricao] = useState("");
  const [listClientes, setListClientes] = useState([]);
  const [listCategorias, setListCategorias] = useState([]);
  const [parcelamentos, setParcelamentos] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showParcela, setShowParcela] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  async function loadCentroCustos(id) {
    setLoading(true);
    let response = await centroCustosService.selecionaCentroCusto(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) setListCentroCusto(response.data);
    setLoading(false);
  }

  async function loadClientes(id) {
    let response = await clientesServices.selecionaCliente(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) setListClientes(response.data);
  }

  async function loadCategorias(id) {
    let response = await recebimentosServices.selecionaCategoriaRecebimento(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) setListCategorias(response.data);
  }

  function validateFields() {
    let list = [];
    if (empresaId === "") list.push('O campo "Empresa" deve ser selecionada.');
    if (vencimento === "")
      list.push('O campo "Vencimento" deve ser preenchido.');
    if (valor === "") list.push('O campo "Valor" deve ser preenchido.');
    if (clienteId === "") list.push('O campo "Cliente" deve ser selecionado.');
    if (categoriaId === "")
      list.push('O campo "Categoria" deve ser selecionado.');
    if (descricao === "") list.push('O campo "Descrição" deve ser preenchido.');

    if (list.length > 0) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(4000);
      setCallbackErrorList(list);
    }

    return list.length === 0;
  }

  function handleClose(event, reason) {
    setCallbackShown(false);
    if (callbackErrorList.length === 0) {
      history.push("/recebimentos-cadastrados");
    }
    setCallbackErrorList([]);
  }

  async function handleSubmit(emiteFatura = 0) {
    if (!validateFields()) return;
    let p = parcelamentos.map((item, index) => {
      item.numero = parcelamentos.length;
      item.valor = Number(item.valor);
      return item;
    });
    window.scrollTo(0, 0);
    setLoading(true);
    await recebimentosServices
      .cadastraRecebimento(
        empresaId,
        file,
        descricao,
        categoriaId,
        vencimento,
        Number(valor),
        clienteId,
        centroId,
        p,
        emiteFatura,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        numeroNota,
        emissao || null,
        codigoBarras
      )
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("success");
          setCallbackMessage("Recebimento salvo com sucesso!");
          setCallbackDuration(3000);
          return;
        }
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        } else {
          if (response.data.error)
            setCallbackErrorList(Object.values(response.data.error));
          else setCallbackMessage(["Ocorreu um erro"]);
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
        }
      });
  }

  async function handleChangeEmpresa(id) {
    setEmpresaId(id);
    setLoading(true);
    await loadCentroCustos(id);
    await loadCategorias(id);
    await loadClientes(id);
    setLoading(false);
  }

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("recebimentos");
    if (empresaStorage) {
      handleChangeEmpresa(empresaStorage);
    }
  }, []);

  return (
    <>
      <div className="session-container">
        <span className="session-container-header">
          <form className="session-container-form">
            {loading && <OverlayLoading />}
            <div className={`${isSmallScreen ? "" : "p-horizontal"}`}>
              <Grid item container direction="column" spacing={2}>
                <Grid item>
                  <SelectComponent
                    styleType="form"
                    label="Empresa"
                    title="Selecione uma empresa"
                    list={listEmpresas}
                    required
                    initialValue={
                      listEmpresas?.find((item) => item.id === empresaId)
                        ?.nome || null
                    }
                    callback={(id) => {
                      handleChangeEmpresa(id);
                    }}
                  />
                </Grid>
                <Grid item>
                  <SelectComponent
                    styleType="form"
                    label="Cliente"
                    required
                    title="Selecione um cliente"
                    list={listClientes}
                    callback={(id) => setClienteId(id)}
                  />
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <SelectComponent
                      styleType="form"
                      label="Categoria"
                      required
                      title="Selecione uma categoria"
                      list={listCategorias}
                      callback={(id) => setCategoriaId(id)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectComponent
                      styleType="form"
                      label="Centro de Custo"
                      title="Selecione uma opção"
                      list={listCentroCusto}
                      callback={(id) => setCentroId(id)}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Data de Emissão"
                      initDate={""}
                      value={emissao}
                      handleChange={(date) => {
                        setEmissao(date);
                      }}
                    ></DatePicker>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Data de Vencimento"
                      required
                      value={vencimento}
                      handleChange={(date) => {
                        setVencimento(date);
                      }}
                    ></DatePicker>
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputComponent
                      tipo="dinheiro"
                      required
                      label="Valor"
                      value={valor}
                      handleInputChange={(event) => setValor(event.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputComponent
                      label="Número da nota"
                      value={numeroNota}
                      handleInputChange={(event) =>
                        setNumeroNota(event.target.value)
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <InputComponent
                      label="Código de barras"
                      value={codigoBarras}
                      tipo={
                        codigoBarras?.length > 47
                          ? "boleto-concessionaria"
                          : "boleto"
                      }
                      handleInputChange={(event) => {
                        setCodigoBarras(
                          onlyUnsignedNumbers(event.target.value)
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <span className="label">Descrição *</span>
                  <textarea
                    className="large-input textarea-100"
                    value={descricao}
                    onChange={(event) => setDescricao(event.target.value)}
                  />
                </Grid>
                <Grid item>
                  <FileInput
                    title="Anexo"
                    onChange={(value) => setFile(value)}
                  />
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: 60 }}
                >
                  <Grid item>
                    <SwitchComponent
                      checked={showParcela}
                      onClick={() => {
                        setShowParcela(!showParcela);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <span className="title">Adicionar parcelas</span>
                  </Grid>
                </Grid>
                {showParcela && (
                  <Grid item>
                    <Parcelamentos
                      qtdTotalParcelas={2}
                      valorTotal={valor}
                      handleChange={(list) => {
                        setParcelamentos(list);
                      }}
                    />
                  </Grid>
                )}
                <Grid item>
                  <span className="required-text">* Campos obrigatórios</span>
                </Grid>
              </Grid>
              <div style={{ height: 40 }} />
            </div>
            {callbackShown && (
              <CallbackMessage
                open={callbackShown}
                type={callbackType}
                handleClose={handleClose}
                message={callbackMessage}
                duration={callbackDuration}
                errorList={callbackErrorList}
              />
            )}
          </form>
        </span>
      </div>
      <div style={{ height: 30 }} />
      <Grid item container justifyContent="flex-end" spacing={4}>
       <Grid item>
          <ButtonComponent
            text="Salvar"
            className="btn-success"
            onClick={() => handleSubmit(0)}
          />
        </Grid>
      </Grid>
      <div style={{ height: 30 }} />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastroRecebimento;
