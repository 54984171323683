import React from "react";
import { 
  DialogTitle, 
  Dialog, 
  DialogContent, 
  Grid, 
  DialogActions, 
  IconButton 
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";


import ButtonComponent from "../../../../components/ButtonComponent";

export default function ModalNovaEmpresa({ open, onClose = () => {} }) {

  const handleNewPlan = () => {
    window.open('https://www.contabilhub.com.br/planos/', '_blank')
    return onClose()
  }

   return (
    <Dialog open={open} onClose={onClose}>
      <div className="modal-title-area">
        <DialogTitle>
          <span className="titleConfirmDialog">NOVA EMPRESA</span>
        </DialogTitle>
        <IconButton onClick={onClose} size="medium">
          <CloseIcon className="iconConfirm" />
        </IconButton>
      </div>
      <DialogContent className="modal-content">
        <Grid container direction="column" spacing={4} className="modal-items">
          <Grid item xs={12}>
          Para realizar esta ação você precisa contratar um novo plano. <br />
          Deseja contratar um novo plano?
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonComponent
          className="btn-success margin-zero btnSize"
          text="Sim"
          onClick={handleNewPlan}
        />
        <ButtonComponent
          className="btn-secondary margin-zero btnSize"
          text="Não"
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  )
}
