import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { Grid } from "@material-ui/core";

import TableComponent from "../../../components/TableComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import CallbackMessage from "../../../components/CallbackMessage";
import SelectComponent from "../../../components/SelectComponent";
import DatePicker from "../../../components/DatePicker";
import Button from "../../../components/ButtonComponent";

import servicosService from "../../../services/servicosService";
import empresasService from "../../../services/empresasService";
import selectServices from "../../../services/selectServices";

import { numberToCurrency } from "../../../utils/functions";
import {
  formatDate,
  toDateYYYY_MM_DD,
  unformatDate,
} from "../../../utils/dates";
import { useIsMount } from "../../../hooks/useIsMount";
import useCompany from "../../../hooks/useCompany";

export default function ListaServicos() {
  const history = useHistory();
  const { selecionaStatusServico, listaServico } = servicosService;

  const {
    companyList: listEmpresas,
    selectedCompany: empresa,
    setSelectedCompany: setEmpresa,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("servicos");
  const isMount = useIsMount();

  const [loading, setLoading] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const [errors, setErrors] = useState([]);

  const [listStatus, setListStatus] = useState([]);

  const [status, setStatus] = useState(null);

  const [listRequest, setListRequest] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_servico_empresa" },
      { columnName: "Razão Social", dataRef: "razao_social" },
      { columnName: "Nome do Serviço", dataRef: "nome" },
      { columnName: "Tipo", dataRef: "tipo_servico" },
      { columnName: "Status", dataRef: "status" },
      { columnName: "Valor", dataRef: "valor" },
    ],
    options: {
      edit: true,
      delete: true,
      copy: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      filter: true,
      isOpenedFilter: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  const loadStatus = useCallback(async () => {
    const response = await selecionaStatusServico();
    if (response.status === 401) {
      setLoading(false);
      setOpenLoginExpirado(true);
      return;
    } else if (response.status === 200) {
      setListStatus(response.data);
    }
  }, []);

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);

    const response = await listaServico(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      status,
      empresa
    );

    if (response.data.hasOwnProperty("data")) {
      const { data } = response;
      const formattedData = {
        ...response.data,
        data: data.data.map((item) => ({
          ...item,
          valor: numberToCurrency(item.valor),
        })),
      };
      setListRequest(formattedData);
    } else {
      const formattedData = response.data.map((item) => ({
        ...item,
        valor: numberToCurrency(item.valor),
      }));
      response.data = formattedData;
      setListRequest(response);
    }

    console.log(
      "SolicitacaoServicoLista :: updateTable :: response :: ",
      response
    );

    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    listaServico,
    empresa,
    status,
  ]);

  let onPageChange = useCallback(
    (newPageObj) => {
      let newPage = newPageObj.page;
      let newDefaultConfigTable = { ...defaultConfigTable };
      newDefaultConfigTable.currentPage = newPage;
      setDefaultConfigTable(newDefaultConfigTable);
    },
    [defaultConfigTable, updateTable]
  );

  let onOrderBy = useCallback(
    (newOrderBy) => {
      let orderBy = newOrderBy.orderBy;
      let orderByType = newOrderBy.orderByType;
      let newDefaultConfigTable = { ...defaultConfigTable };
      newDefaultConfigTable.orderBy = orderBy;
      newDefaultConfigTable.orderByType = orderByType;
      setDefaultConfigTable(newDefaultConfigTable);
    },
    [defaultConfigTable, updateTable]
  );

  let onPerPage = useCallback(
    (newPerPage) => {
      let newDefaultConfigTable = { ...defaultConfigTable };
      newDefaultConfigTable.perPage = newPerPage;
      newDefaultConfigTable.currentPage = 1;
      setDefaultConfigTable(newDefaultConfigTable);
    },
    [defaultConfigTable, updateTable]
  );

  let onSearchTerm = useCallback(
    (value) => {
      let newDefaultConfigTable = { ...defaultConfigTable };
      newDefaultConfigTable.searchTerm = value ? value : null;
      newDefaultConfigTable.currentPage = 1;
      setDefaultConfigTable(newDefaultConfigTable);
    },
    [defaultConfigTable, updateTable]
  );

  let onClickFile = (id) => {
    history.push(`/teste-visualizar-solicitacao/${id}`);
  };

  let onEdit = (id) => {
    console.log("id::", id);

    history.push(`/editar-servico/${id}`);
  };

  let onCopy = (id) => {
    history.push(`/cadastrar-servico`, {
      id,
      isCopy: true,
    });
  };

  let onClickDelete = (id) => {
    setIdDelete(id);
    setOpenConfirmDelete(true);
  };

  let onCancelDelete = () => {
    setOpenConfirmDelete(false);
  };

  let onDelete = async () => {
    setLoading(true);
    const response = await servicosService.inativaServico(idDelete);
    if (response.status === 200 || response.status === 201) {
      setOpenConfirmDelete(false);
      await updateTable();
      setLoading(false);
      setShowMessage(true);
      setErrors([]);
    } else {
      if (response.status === 401) {
        setOpenLoginExpirado(false);
        setLoading(false);
        return;
      }
      if (response.data.error) setErrors(Object.values(response.data.error));
      else setErrors(["Ocorreu um erro"]);
      setShowMessage(true);
      setLoading(false);
    }
  };

  function handleClose() {
    setShowMessage(false);
  }

  const handleApplyFilters = useCallback(
    ({ company, status, empresaStorage = null }) => {
      if (empresaStorage) {
        setEmpresa(empresaStorage);
        let newDefaultConfigTable = { ...defaultConfigTable };
        setDefaultConfigTable({
          ...newDefaultConfigTable,
          currentPage: 1,
        });
        return;
      }
      setEmpresa(company);
      setStatus(status);
      let newDefaultConfigTable = { ...defaultConfigTable };
      setDefaultConfigTable({
        ...newDefaultConfigTable,
        currentPage: 1,
      });
      setEmpresaPagina("servicos", company);
    },
    []
  );

  const handleCleanFilters = useCallback(() => {
    setStatus(null);
    setEmpresa(null);
    setEmpresaPagina("servicos", null);
  }, []);

  const FilterComponentCustom = ({
    onApplyFilters = () => {},
    onCleanFilters = () => {},
    initCompany = null,
    initStatus = null,
  }) => {
    const [company, setCompany] = useState(null);
    const [status, setStatus] = useState(null);

    const handleApplyFilters = () => {
      onApplyFilters({ company, status });
    };

    const handleCleanFilters = () => {
      onCleanFilters();
      setCompany(null);
      setStatus(null);
    };

    useEffect(() => {
      if (initCompany) setCompany(initCompany);
      if (initStatus) setStatus(initStatus);
    }, [initCompany, initStatus]);

    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12} sm={8}>
            <SelectComponent
              styleType="form"
              label="Empresa"
              initialValue={
                company !== null
                  ? listEmpresas.find((item) => item.id == company)?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={(id) => setCompany(id)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectComponent
              styleType="form"
              label="Status"
              initialValue={
                status !== null
                  ? listStatus.find((item) => item.id == status)?.nome
                  : ""
              }
              title="Selecione um status"
              list={listStatus}
              callback={(id) => setStatus(id)}
            />
          </Grid>

          <div className="filter-button-area">
            <Button
              onClick={handleApplyFilters}
              text="Aplicar"
              className="btn-primary"
            />
            <Button
              onClick={handleCleanFilters}
              text="Limpar"
              className="btn-primary"
            />
          </div>
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    setLoading(true);
    loadStatus();
    setLoading(false);
  }, []);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("servicos");
    if (empresaStorage) {
      handleApplyFilters({ empresaStorage });
    } else {
      updateTable();
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id_servico_empresa"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        cbEdit={(id) => onEdit(id)}
        cbCopy={onCopy}
        cbDelete={onClickDelete}
        callbackSearchTerm={onSearchTerm}
        cbLookFile={onClickFile}
        filterComponentCustom={
          <FilterComponentCustom
            onApplyFilters={handleApplyFilters}
            onCleanFilters={handleCleanFilters}
            initCompany={empresa}
            initStatus={status}
          />
        }
      />
      <ConfirmDialog
        open={openConfirmDelete}
        title="CONFIRMAR CANCELAMENTO"
        description="Você tem certeza que deseja realizar o cancelamento?"
        cancelLabel="Voltar"
        acceptLabel="Cancelar"
        onAccept={onDelete}
        onCancel={onCancelDelete}
        onClose={onCancelDelete}
      />
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${
          errors.length === 0 ? "Serviço cancelado com sucesso!" : "Erro!"
        }`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 3000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
