import Axios from "./Axios";

const ObrigacoesService = {
  tablesHomeWithParams: async (status, params) => {
    const paramsTemp = { status: status };

    // if (params.order !== 0) {
    //   paramsTemp["columnName"] = params.columnName;
    //   paramsTemp["order"] = params.order;
    // }

    // if (params.page !== 1) {
    //   paramsTemp["page"] = params.page;
    // }

    // if (params.seach !== "") {
    //   paramsTemp["seach"] = params.seach;
    // }

    const result = await Axios.get("/lista-obrigacao-home", {
      params: {
        page: params.page,
        order_by: params.orderBy,
        order_by_type: params.orderByType,
        per_page: params.perPage,
        status: status,
        //  search_term: searchTerm,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  tablesHome: async (status) => {
    const result = await Axios.get("/lista-obrigacao-home", {
      params: {
        status: status,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  getObrigacoesLista: async (
    page,
    orderBy,
    orderByType,
    perPage,
    searchTerm,
    id_empresa,
    data_inicial,
    data_final
  ) => {
    const result = await Axios.get("/lista-obrigacao", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
        data_inicial,
        data_final
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },

  editObrigacao: async (
    referencia,
    id_categoria_obrigacao,
    data_vencimento,
    id_status,
    id_empresa,
    arquivo,
    valor,
    id,
    observacoes, 
    nome_arquivo
  ) => {
    const result = await Axios.post(`atualiza-obrigacao/${id}`, {
      referencia: referencia,
      id_categoria_obrigacao: id_categoria_obrigacao,
      data_vencimento: data_vencimento,
      id_status: id_status,
      id_empresa: id_empresa,
      arquivo: arquivo,
      valor: valor,
      observacoes, 
      nome_arquivo
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  selectObrigacao: async () => {
    const result = Axios.get("/seleciona-obrigacao")
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  selectStatus: async () => {
    const result = Axios.get("/seleciona-status-obrigacao")
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  cadastraObrigacao: async (
    referencia,
    id_categoria_obrigacao,
    data_vencimento,
    id_status,
    id_empresa,
    arquivo,
    valor,
    observacoes, 
    nome_arquivo
  ) => {
    const result = Axios.post("/cadastra-obrigacao", {
      referencia: referencia,
      id_categoria_obrigacao: id_categoria_obrigacao,
      data_vencimento: data_vencimento,
      id_status: id_status,
      id_empresa: id_empresa,
      arquivo: arquivo,
      valor: valor,
      observacoes,
      nome_arquivo
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  consultaObrigacao: async (id) => {
    const result = Axios.get(`/consulta-obrigacao/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  removerObrigacao: async (id) => {
    const response = await Axios.get(`/remove-obrigacao/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  notificacaoDownloadObrigacao: async (id) => {
    const response = await Axios.post(`/download-anexo-obrigacao/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};

export default ObrigacoesService;
