import React, { useEffect, useState, useCallback } from 'react'
import { Grid, Dialog, DialogTitle, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import InputComponent from '../Input'
import DatePicker from '../DatePicker'
import ButtonComponent from '../ButtonComponent'
import SelectComponent from '../SelectComponent'
import OverlayLoading from '../OverlayLoading'
import ModalLoginExpirado from '../ModalLoginExpirado'
import CallbackMessage from '../CallbackMessage'
import validateFields from '../../utils/validateFields'
import selectServices from '../../services/selectServices'
import faturasService from '../../services/faturasService'

import './styles.scss'
import Textarea from '../Textarea'
import contasBancariasService from '../../services/contasBancariasService'

export function ModalRegistrarBaixa({
  open,
  onClose,
  idFatura,
  showInfo,
  cbTable
}) {
  const { selecionaConta } = contasBancariasService

  const [dataPagamento, setDataPagamento] = useState('')
  const [valor, setValor] = useState(null)
  const [conta, setConta] = useState(null)
  const [acrescimoMultaJuros, setAcrescimoMultaJuros] = useState(null)
  const [concessaoAbatimentosDescontos, setConcessaoAbatimentosDescontos] =
    useState(null)
  const [formaPagamento, setFormaPagamento] = useState(null)
  const [descricao, setDescricao] = useState(null)

  const [listConta, setListConta] = useState([])
  const [listFormaPagamento, setListFormaPagamento] = useState([])

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false)

  useEffect(() => {
    setLoading(true)
    loadStatus()
    loadFormaPagamento()
    setLoading(false)
  }, [])

  async function handleSubmit() {
    setErrors([])

    let fields = []

    fields.push(
      {
        label: 'valor',
        value: valor
      },
      {
        label: 'datadepagamento',
        value: dataPagamento
      },
      {
        label: 'formadepagamento',
        value: formaPagamento
      }
    )

    const fieldsValidate = validateFields(fields)

    if (fieldsValidate.length !== 0) {
      setShowMessage(true)
      setErrors(fieldsValidate)
      return
    }

    setLoading(true)

    const response = await faturasService.baixaFatura(idFatura, {
      data_pagamento_baixa: dataPagamento,
      valor_baixa: valor,
      conta_baixa: conta,
      acrescimos_baixa: acrescimoMultaJuros,
      descontos_baixa: concessaoAbatimentosDescontos,
      forma_pagamento: formaPagamento,
      obs_baixa: descricao
    })

    console.log('response', response)

    if (response.status === 401) {
      setOpenLoginExpirado(true)
      setLoading(false)
      return
    }
    if (response.status !== 201 && response.status !== 200) {
      if (response.data.error) {
        setLoading(false)
        setShowMessage(true)
        setErrors(Object.values(response.data.error))
        return console.log('errors:', response.data.error)
      } else setErrors(['Ocorreu um erro'])
    } else if (response.status === 200 || response.status === 201) {
      setErrors([])
      setSuccessMessage(
        response?.data?.msg
          ? response?.data?.msg
          : 'Fatura baixada com sucesso!'
      )
    }

    setShowMessage(true)
    setLoading(false)
    cbTable()
  }

  const loadStatus = async () => {
    const response = await selectServices.selectStatusFatura()
    if (response.status === 401) {
      setOpenLoginExpirado(true)
      setLoading(false)
      return
    }
    const data = response.data

    setListConta(data)
  }

  const loadFormaPagamento = async () => {
    const origemFatura = 3;
    const response = await selectServices.selecionaFormaPagamentoPorOrigem(origemFatura)
    if (response.status === 401) {
      setOpenLoginExpirado(true)
      setLoading(false)
      return
    }
    const data = response.data

    setListFormaPagamento(data)
  }

  const resetAllFields = () => {
    setShowMessage(false)
    setValor(null)
    setDataPagamento(null)
    setConta(null)
    setAcrescimoMultaJuros(null)
    setConcessaoAbatimentosDescontos(null)
    setFormaPagamento(null)
    setDescricao('')
  }

  const loadContas = useCallback(async () => {
    if (open && idFatura) {
      const response = await faturasService.consultaFatura(idFatura)
      if (response.status === 200) {
        const { data } = response
        if (showInfo) {
          setValor(data?.valor_baixa ? data.valor_baixa : null)
          setDataPagamento(
            data?.data_pagamento_baixa ? data.data_pagamento_baixa : null
          )
          setConta(data?.conta_baixa ? data.conta_baixa : null)
          setAcrescimoMultaJuros(
            data?.acrescimos_baixa ? data.acrescimos_baixa : null
          )
          setConcessaoAbatimentosDescontos(
            data?.descontos_baixa ? data.descontos_baixa : null
          )
          setFormaPagamento(
            data?.forma_pagamento_baixa ? data.forma_pagamento_baixa : null
          )
          setDescricao(data?.obs_baixa ? data.obs_baixa : null)
        } else {
          resetAllFields()
        }
        const idEmpresa = data.id_empresa
        if (idEmpresa !== null) {
          const response = await selecionaConta(idEmpresa)
          console.log(response)

          if (response.status === 401) {
            setOpenLoginExpirado(true)
            setLoading(false)
            return
          }

          if (response.status !== 201 && response.status !== 200) {
            if (response.data.error) {
              setLoading(false)
              setShowMessage(true)
              setErrors(Object.values(response.data.error))
            } else setErrors(['Ocorreu um erro'])
            setShowMessage(true)
          } else {
            const { data } = response
            console.log(data)
            setListConta(data)
          }
          setLoading(false)
        } else {
          setListConta([])
        }
      } else {
        setListConta([])
      }
    } else {
      setListConta([])
    }
  }, [idFatura, showInfo, open])

  const handleClose = () => {
    resetAllFields()
    if (errors.length === 0) {
      onClose()
      cbTable()
    }
  }

  const handleCloseModal = async () => {
    resetAllFields()
    setLoading(false)
    onClose()
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await Promise.all([loadFormaPagamento(), loadContas()])
      setLoading(false)
    })()
  }, [idFatura, open, showInfo])

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='md'
      onClose={handleCloseModal}
      dialog
    >
      {loading && <OverlayLoading />}
      <DialogTitle className='modal-container'>
        <Grid
          item
          container
          justify='space-between'
          className='modal-title-container modal-title'
        >
          <Grid item>
            <span>
              {showInfo
                ? 'INFORMAÇÕES DA BAIXA DA FATURA'
                : 'REGISTRAR BAIXA DA FATURA'}
            </span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size='medium'
              style={{ marginRight: 16, color: '#053b59', padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className='content'>
        <form className='session-container-form'>
          <Grid container direction='column' spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <DatePicker
                  label='Data de Pagamento*'
                  initDate={dataPagamento}
                  readOnly={showInfo}
                  handleChange={(date) => {
                    setDataPagamento(date)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  tipo='dinheiro'
                  disabled={showInfo}
                  label='Valor*'
                  placeholder='R$'
                  value={valor ? valor : null}
                  handleInputChange={(event) => setValor(event.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div className='select-container'>
                  <SelectComponent
                    list={listConta}
                    isDisabled={showInfo}
                    label='Conta'
                    title='Selecione uma conta'
                    initialValue={
                      listConta.find((item) => item.id === conta)
                        ? listConta.find((item) => item.id === conta).nome
                        : ''
                    }
                    callback={(id) => setConta(id)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  disabled={showInfo}
                  tipo='dinheiro'
                  label='Acréscimo Multas/ Juros'
                  placeholder='R$'
                  value={acrescimoMultaJuros}
                  handleInputChange={(event) =>
                    setAcrescimoMultaJuros(event.value)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  tipo='dinheiro'
                  label='Concessão de Abatimentos/Descontos'
                  disabled={showInfo}
                  placeholder='R$'
                  value={concessaoAbatimentosDescontos}
                  handleInputChange={(event) =>
                    setConcessaoAbatimentosDescontos(event.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div className='select-container'>
                  <SelectComponent
                    list={listFormaPagamento}
                    label='Forma de pagamento*'
                    isDisabled={showInfo}
                    title='Selecione uma opção'
                    initialValue={
                      listFormaPagamento.find(
                        (item) => item.id === formaPagamento
                      )
                        ? listFormaPagamento.find(
                            (item) => item.id === formaPagamento
                          ).nome
                        : ''
                    }
                    callback={(id) => setFormaPagamento(id)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Textarea
                  label='Descrição'
                  disabled={showInfo}
                  value={descricao}
                  handleInputChange={(event) =>
                    setDescricao(event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <span className='required-text'>* Campos obrigatórios</span>
              </Grid>
              <Grid container justify='flex-end' item>
                {showInfo ? (
                  <ButtonComponent
                    text='Voltar'
                    onClick={handleCloseModal}
                    className='btn-success zero-margin'
                  />
                ) : (
                  <ButtonComponent
                    text='Salvar'
                    onClick={handleSubmit}
                    className='btn-success zero-margin'
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? 'success' : 'error'}`}
        message={`${errors.length === 0 ? successMessage : 'Erro!'}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  )
}
