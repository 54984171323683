import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import Input from "../../../components/Input";
import InputFile from "../../../components/InputFile";
import ModalLoginExpirado from "../../../components/InputFile";
import Button from "../../../components/ButtonComponent";
import Select from "../../../components/SelectComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";

import { documentacaoLegalService } from "../../../services/documentacaoLegalService";

import "./styles.css";
import useCompany from "../../../hooks/useCompany";

function CadastroDocumentacaoLegal() {
  const history = useHistory();
  const { selectCategorias, cadastrarDocumentoLegal } =
    documentacaoLegalService;

  const {
    companyList: empresas,
    selectedCompany: company,
    setSelectedCompany: setCompany,
    getEmpresaPagina,
  } = useCompany("documentacao_legal");

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState(null);
  const [file, setFile] = useState(null);

  const [loading, setLoading] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const loadCategorias = useCallback(async () => {
    const response = await selectCategorias();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setCategorias(response.data);
    console.log("loadCategorias :: ", response);
  }, [selectCategorias]);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/documentacao-legal");
    }
  }

  async function handleSubmit() {
    if (!validateFields()) return;
    setLoading(true);
    const response = await cadastrarDocumentoLegal(
      title,
      category,
      company,
      file
    );
    setLoading(false);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status !== 200) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      setCallbackDuration(2000);
      return;
    } else {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage("Documentação legal cadastrada com sucesso.");
      setCallbackDuration(2000);
      return;
    }
  }

  function verifyField(field, callback) {
    if (field === "" || field === null || field === undefined) {
      callback();
    }
  }

  function validateFields() {
    let list = [];
    verifyField(title, () => list.push('O campo "Título" é obrigatório.'));
    verifyField(category, () =>
      list.push('O campo "Categoria" deve ser selecionado.')
    );
    verifyField(company, () =>
      list.push('O campo "Empresa" deve ser selecionado.')
    );
    verifyField(file, () => list.push('O campo "Anexo" é obrigatório.'));
    setCallbackErrorList(list);
    if (list.length > 0) {
      setCallbackType("error");
      setCallbackShown(true);
      setCallbackDuration(2000);
    }
    return list.length === 0;
  }

  useEffect(() => {
    (async function () {
      setLoading(true);
      await loadCategorias();
      setLoading(false);
    })();
  }, [loadCategorias]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("documentacao_legal");
    if (empresaStorage) {
      setCompany(empresaStorage);
    }
  }, []);

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <CallbackMessage
            open={callbackShown}
            duration={callbackDuration}
            errorList={callbackErrorList}
            handleClose={handleClose}
            message={callbackMessage}
            type={callbackType}
          />
          <form className="cadastrar-documento-legal-form">
            <div className="cadastrar-documento-legal-titulo-input">
              <Input
                nome="titulo"
                label="Título"
                tipo="text"
                required={true}
                handleInputChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="cadastrar-documento-legal-categoria-input">
              <Select
                styleType="form"
                label="Categoria"
                required
                title="Selecione uma categoria"
                list={categorias}
                callback={(e) => setCategory(e)}
              />
            </div>
            <div className="cadastrar-documento-legal-empresa-input">
              <Select
                styleType="form"
                label="Empresa"
                required
                title="Selecione uma empresa"
                list={empresas}
                initialValue={
                  empresas?.find((item) => item.id === company)?.nome || null
                }
                callback={(e) => setCompany(e)}
              />
            </div>
            <div className="cadastrar-documento-legal-file-input">
              <InputFile title="Anexo *" onChange={(file) => setFile(file)} />
            </div>
            <div className="required-text">
              <span>* Campos obrigatórios</span>
            </div>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            text="Salvar"
            className="btn-success"
            onClick={handleSubmit}
          />
        </span>
      </div>
      {openLoginExpirado && <ModalLoginExpirado open={openLoginExpirado} />}
    </>
  );
}

export default CadastroDocumentacaoLegal;
