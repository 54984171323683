import Axios from './Axios';

const dreService = {
  getDre: async (id_empresa, ano) => {
    const response = await Axios.get('/lista-dre', {
      params: {
        id_empresa,
        ano,
      },
    })
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  getDreCentroCusto: async (id_empresa, id_centro_custo, ano) => {
    const response = await Axios.get('/lista-dre', {
      params: {
        id_empresa,
        id_centro_custo,
        ano,
      },
    })
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  exportarRelatorio: async (
    id_empresa = null,
    id_centro_custo = null,
    ano = null,
  ) => {
    const response = await Axios.get('/exportar-relatorio-dre', {
      params: {
        id_empresa,
        id_centro_custo,
        ano
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  enviarEmailRelatorio: async ({
    assunto = null,
    mensagem = null,
    emails = [],
    cco = [],
    id_empresa = null,
    ano = null,
  }) => {
    const response = await Axios.get('/exportar-relatorio-dre-email',{
      params: {
        id_empresa,
        ano,
        assunto,
        mensagem,
        emails,
        cco,
      }
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  }
};

export default dreService;
