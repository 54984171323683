import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Input from '../../components/Input'
import Button from '../../components/ButtonComponent'
import Select from '../../components/SelectComponent'
import OverlayLoading from '../../components/OverlayLoading'
import CallbackMessage from '../../components/CallbackMessage'
import ModalLoginExpirado from '../../components/ModalLoginExpirado'
import DatePicker from '../../components/DatePicker'
import validateFields from '../../utils/validateFields'
import AutocompleteInput from '../../components/AutocompleteInput'

import { Grid } from '@material-ui/core'

import empresasService from '../../services/empresasService'
import planosServices from '../../services/planosServices'
import faturasService from '../../services/faturasService'

import classes from '../../components/Input/styles.module.scss'

import './styles.scss'
import { ConvertMoney } from '../../utils/strings'

function CadastroFatura() {
  const history = useHistory()
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false)
  const { selectEmpresa } = empresasService
  const { consultaPlanoCustomizado, selecionaPlano } = planosServices

  const { state } = useLocation()

  const [planos, setPlanos] = useState([])

  const [plano, setPlano] = useState({
    id: null,
    descricao: null
  })
  const [valorPlano, setValorPlano] = useState(null)
  const [descricao, setDescricao] = useState('')
  const [dataVencimento, setDataVencimento] = useState('')
  const [dataEmissao, setDataEmissao] = useState()
  const [competencia, setCompetencia] = useState('')

  const [idCliente, setIdCliente] = useState('')
  const [idEmpresa, setIdEmpresa] = useState('')

  const [callbackShown, setCallbackShown] = useState(false)
  const [callbackType, setCallbackType] = useState('')
  const [callbackMessage, setCallbackMessage] = useState('')
  const [callbackErrorList, setCallbackErrorList] = useState([])
  const [callbackDuration, setCallbackDuration] = useState(6000)

  const [loading, setLoading] = useState(false)

  const [listEmpresas, setListEmpresas] = useState([])

  const [selectedCompanys, setSelectedCompanys] = useState([])

  const loadEmpresas = async () => {
    setLoading(true)

    const response = await selectEmpresa()

    if (response?.status === 401) {
      setOpenLoginExpirado(true)
      setLoading(false)
      return
    }

    const data = response.data

    setListEmpresas(data)
    setLoading(false)
  }

  const loadPlanos = async () => {
    setLoading(true)
    const response = await selecionaPlano()
    if (response?.status === 401) {
      setOpenLoginExpirado(true)
      setLoading(false)
      return
    }
    const data = response.data
    setPlanos(data)
    setLoading(false)
  }

  const loadFatura = async () => {
    setLoading(true)
    const response = await faturasService.consultaFatura(state.idFatura)
    if (response?.status === 401) {
      setOpenLoginExpirado(true)
      setLoading(false)
      return
    }

    if (response.status === 200) {
      const data = response.data

      const planoSelecionado = planos?.find(
        (item) => item.id === data?.id_plano
      )
      setIdCliente(data?.id_cliente)
      setIdEmpresa(data?.id_empresa)
      setPlano({
        id: data?.id_plano,
        descricao: planoSelecionado?.nome
      })
      setDescricao(data?.item_fatura[data.item_fatura?.length - 1].item)
      setCompetencia(data?.competencia)
      setDataVencimento(data?.data_vencimento)
      setDataEmissao(data?.data_emissao)
      setLoading(false)
    }
  }

  const loadPlanoCliente = async () => {
    setLoading(true)
    const response = await consultaPlanoCustomizado(state.id_cliente)
    if (response?.status === 401) {
      setOpenLoginExpirado(true)
      setLoading(false)
      return
    }

    if (response.status === 200) {
      const data = response.data
      const planoSelecionado = planos?.find(
        (item) => item.id === data?.id_plano
      )
      setPlano({
        id: planoSelecionado?.id,
        descricao: planoSelecionado?.nome
      })
      setValorPlano(
        Number(
          data.valor_customizado ? data.valor_customizado : data?.valor_total
        )
      )
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    loadPlanos()

    if (state?.isBatch) {
      loadEmpresas()
    }

    if (state.isCopy) {
      // setLoading(true)
      loadFatura()
      // setLoading(false)
    }

    // setLoading(false)
  }, [state?.isCopy, state?.isBatch])

  useEffect(() => {
    if (state?.id_cliente && planos.length > 0) {
      loadPlanoCliente()
    }
  }, [planos, state])

  function handleClose(event, reason) {
    setCallbackShown(false)
    if (callbackType === 'success') {
      history.push('/cadastro-conta')
    }
  }

  const handleSelectedCompanys = (values) => {
    const selectedItems = values?.map((item) => {
      return item.id
    })

    setSelectedCompanys(selectedItems)
  }

  async function handleSubmit() {
    setLoading(true)

    let fieldsToValidate = [
      {
        label: 'plano',
        value: plano?.id
      },
      {
        label: 'descricao',
        value: descricao
      },
      {
        label: 'datavencimento',
        value: dataVencimento
      },
      {
        label: 'dataemissao',
        value: dataEmissao
      },
      {
        label: 'valorplano',
        value: valorPlano
      }
    ]

    if (state?.isBatch) {
      fieldsToValidate.push({
        label: 'empresa',
        value: selectedCompanys.length !== 0 ? selectedCompanys : ''
      })
    }

    let fieldErrors = validateFields(fieldsToValidate)

    if (fieldErrors.length !== 0) {
      setCallbackShown(true)
      setCallbackType('error')
      setCallbackMessage('Erro!')
      setCallbackErrorList(fieldErrors)
      setCallbackDuration(3000)
      setLoading(false)
      return
    }

    let response

    if (state?.isBatch) {
      let id_empresa = parseInt(
        window.localStorage.getItem('empresa_usuario'),
        10
      )

      response = await faturasService.cadastraFatura(
        id_empresa,
        state?.isBatch && state?.id_cliente,
        plano?.id,
        descricao !== '' ? descricao : null,
        dataVencimento !== '' ? dataVencimento : null,
        dataEmissao !== '' ? dataEmissao : null,
        competencia !== '' ? competencia : null,
        selectedCompanys.length !== 0 ? selectedCompanys : null
      )
    } else if (state.isCopy) {
      response = await faturasService.cadastraFatura(
        idEmpresa,
        idCliente,
        plano.id,
        descricao !== '' ? descricao : null,
        dataVencimento !== '' ? dataVencimento : null,
        dataEmissao !== '' ? dataEmissao : null,
        competencia !== '' ? competencia : null
      )
    } else {
      let id_empresa = parseInt(
        window.localStorage.getItem('empresa_usuario'),
        10
      )

      response = await faturasService.cadastraFatura(
        id_empresa,
        state.isNew && state.id_cliente,
        plano.id,
        descricao !== '' ? descricao : null,
        dataVencimento !== '' ? dataVencimento : null,
        dataEmissao !== '' ? dataEmissao : null,
        competencia !== '' ? competencia : null
      )
    }

    if (response.status === 401) {
      setOpenLoginExpirado(true)
      setLoading(false)
      return
    }
    if (response.status !== 201 && response.status !== 200) {
      setLoading(false)
      setCallbackShown(true)
      setCallbackType('error')
      setCallbackDuration(6000)
      setCallbackMessage('Erro!')
      if (response.data.error && typeof response?.data?.error === 'string') {
        setCallbackErrorList([response?.data?.error])
      } else if (typeof response?.data?.error[0]?.message === 'string') {
        setCallbackErrorList([response?.data?.error[0]?.message])
      } else if (typeof response?.data?.error === 'object') {
        setCallbackErrorList(
          Object.values(response?.data?.error).flat(Infinity)
        )
      } else {
        setCallbackErrorList(['Ocorreu um erro'])
      }
      return
    } else {
      setLoading(false)
      setCallbackShown(true)
      setCallbackType('success')
      setCallbackDuration(2000)
      setCallbackMessage('Fatura criada com sucesso.')
      setTimeout(() => {
        history.push(`/faturas/${state.id_cliente || idCliente}`)
      }, [2000])
    }
  }

  return (
    <>
      {loading && <OverlayLoading />}
      {!loading && (
        <>
          <div className='session-container user-register-container'>
            <div className='form-container'>
              <form className='session-container-form'>
                <Grid item container spacing={2}>
                  {state?.isBatch && (
                    <Grid item xs={12} sm={12} md={12}>
                      <AutocompleteInput
                        id='tags-outlined-2'
                        label='Empresas'
                        data={listEmpresas}
                        handleSelectedValues={(values) =>
                          handleSelectedCompanys(values)
                        }
                        key={(option) => option.id}
                        required
                        getOptionLabel={(option) => option.nome}
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.inputLabel}>Plano</div>
                    <div className='disabled-field cadastra-fatura'>
                      {plano.descricao}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.inputLabel}>Valor do Plano</div>
                    <div className='disabled-field cadastra-fatura'>
                      {ConvertMoney(valorPlano)}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Input
                      nome='descricao'
                      label='Descrição'
                      required
                      value={descricao}
                      tipo='text'
                      handleInputChange={(event) => {
                        setDescricao(event.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Input
                      nome='competencia'
                      label='Competência (mês/ano)'
                      tipo='mes-ano'
                      value={competencia}
                      title='__/____'
                      handleInputChange={(event) => {
                        setCompetencia(event.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <DatePicker
                      label='Data de emissão *'
                      initDate={dataEmissao}
                      required
                      handleChange={(date) => {
                        setDataEmissao(date)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <DatePicker
                      label='Data de vencimento *'
                      initDate={dataVencimento}
                      handleChange={(date) => {
                        setDataVencimento(date)
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <span className='required-text'>* Campos obrigatórios</span>
                </Grid>
              </form>
            </div>
          </div>
          <div className='button-area'>
            <Button
              type='button'
              text='Gerar Fatura'
              className='btn-success'
              onClick={handleSubmit}
            />
          </div>
        </>
      )}
      <ModalLoginExpirado open={openLoginExpirado} />
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />
    </>
  )
}

export default CadastroFatura
