import React, { useState, useEffect } from "react";
import { Grid, useMediaQuery } from "@material-ui/core";

import { Search } from "../../components/TableComponent";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import OverlayLoading from "../../components/OverlayLoading";

import dreService from "../../services/dreService";
import empresasService from "../../services/empresasService";
import centroCustosService from "../../services/centroCustosService";

import "./styles.scss";
import CallbackMessage from "../../components/CallbackMessage";
import validateFields from "../../utils/validateFields";
import exportData from "../../services/exportData";
import { ModalEnviarEmail } from "../../components/ModalEnviarEmail";
import { Filter } from "../../components/FilterComponent";
import { EmptyDre } from "./componentes";
import { AccordionDre } from "./componentes/accordion";
import { AccordionDreMobile } from "./componentes/accordionMobile";
import useCompany from "../../hooks/useCompany";

const tableOptions = {
  noSearch: true,
  filter: true,
  more: true,
  moreOptions: [
    {
      icon: "export",
      label: "Exportar XLS",
      action: "exportar-xls",
    },
    {
      icon: "export",
      label: "Exportar PDF",
      action: "exportar-pdf",
    },
    {
      icon: "email",
      label: "Enviar e-mail",
      action: "enviar-e-mail",
    },
  ],
};

const exportToPDF = {
  url: "exportar-relatorio-dre-pdf",
  fileName: "cbhub_relatorio_dre",
  fileType: "pdf",
};

const exportToXLS = {
  url: "exportar-relatorio-dre",
  fileName: "cbhub_relatorio_dre",
  fileType: "xlsx",
};

const meses = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];

function filterComponent({
  handleSelectEmpresa,
  listEmpresas,
  empresaId,
  setCentroCustoId,
  listCentroCusto,
  centroCustoId,
  setAno,
  listAnos,
  ano,
}) {
  return (
    <Filter.Root>
      <Filter.Select
        callback={handleSelectEmpresa}
        label="Empresa"
        options={listEmpresas}
        value={empresaId}
        xs={12}
      />
      <Filter.Select
        xs={12}
        md={6}
        callback={(id) => {
          setCentroCustoId(id);
        }}
        required={false}
        label="Centro de custos"
        title="Selecione um centro de custo"
        options={listCentroCusto}
        value={centroCustoId}
      />
      <Filter.Select
        xs={12}
        md={6}
        callback={(id) => {
          setAno(id);
        }}
        label="Ano"
        title="Selecione o ano"
        options={listAnos}
        value={ano}
      />
    </Filter.Root>
  );
}

function HeaderTable({
  handleSelectEmpresa,
  listEmpresas,
  empresaId,
  setCentroCustoId,
  listCentroCusto,
  centroCustoId,
  setAno,
  listAnos,
  ano,
  tableOptions,
  onTableMoreAction,
}) {
  return (
    <Search
      callback={() => {}}
      tableOptions={tableOptions}
      filterComponent={filterComponent({
        handleSelectEmpresa,
        listEmpresas,
        empresaId,
        setCentroCustoId,
        listCentroCusto,
        centroCustoId,
        setAno,
        listAnos,
        ano,
      })}
      callbackMenu={onTableMoreAction}
    />
  );
}

function DRE() {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const {
    companyList: listEmpresas,
    selectedCompany: empresaId,
    setSelectedCompany: setEmpresaId,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("dre");

  const [loading, setLoading] = useState(false);
  const [listCentroCusto, setListCentroCusto] = useState([]);
  const [centroCustoId, setCentroCustoId] = useState("");
  const [ano, setAno] = useState("");
  const [listRequest, setListRequest] = useState();
  const [listAnos, setListAnos] = useState(Array(20).fill(0));
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);

  const [openModalEmail, setOpenModalEmail] = useState(false);

  async function loadCentroCusto(id) {
    const response = await centroCustosService.selecionaCentroCusto(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) setListCentroCusto(response.data);
    if (response.data.length === 0) {
      setCentroCustoId("");
    }
  }

  const updateTable = async () => {
    window.scrollTo(0, 0);
    setLoading(true);

    if (centroCustoId !== "") {
      await dreService
        .getDreCentroCusto(empresaId, centroCustoId, ano)
        .then((response) => {
          if (response.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          }
          if (response.status === 200) {
            setListRequest(response.data);
          }
        });
    } else {
      await dreService.getDre(empresaId, ano).then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        if (response.status === 200) {
          setListRequest(response.data);
        }
      });
    }

    setLoading(false);
  };

  let onTableMoreAction = async ({ action }) => {
    let fields = [
      {
        label: "empresa",
        value: empresaId,
      },
      {
        label: "ano",
        value: ano,
      },
    ];

    const validateFieldsErrors = validateFields(fields);

    if (validateFieldsErrors.length !== 0) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackDuration(3000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(validateFieldsErrors);
      setLoading(false);
      return;
    }

    let params = {};
    if (action === "enviar-e-mail") {
      setOpenModalEmail(true);
      return;
    } else if (action === "exportar-xls") {
      params = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: {
          id_empresa: empresaId,
          id_centro_custo:
            centroCustoId && centroCustoId !== "" ? centroCustoId : null,
          ano: ano,
        },
      };
    } else if (action === "exportar-pdf") {
      params = {
        url: exportToPDF.url,
        fileName: exportToPDF.fileName,
        fileType: exportToPDF.fileType,
        params: {
          id_empresa: empresaId,
          id_centro_custo:
            centroCustoId && centroCustoId !== "" ? centroCustoId : null,
          ano: ano,
        },
      };
    }

    setLoading(true);
    const response = await exportData(params);

    if (response.status === 500) {
      setCallbackErrorList([
        "Erro interno no servidor. Por favor, contate o suporte",
      ]);
      setCallbackShown(true);
      setCallbackType("error");
    } else if (response.status !== 201 && response.status !== 200) {
      setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
      setCallbackShown(true);
      setCallbackType("error");
    }

    setLoading(false);
  };

  function handleClose() {
    setCallbackShown(false);
    setCallbackType("");
  }

  async function handleSendEmail(data = false) {
    if (!data) {
      setOpenModalEmail(false);
      return;
    }
    setLoading(true);
    const cco = data.cco?.split(";")?.map((item) => {
      return item?.trim();
    });
    const emails = data.para?.split(";")?.map((item) => {
      return item?.trim();
    });
    const response = await dreService.enviarEmailRelatorio({
      id_empresa: empresaId,
      ano,
      assunto: data.assunto,
      cco,
      emails,
      mensagem: data.mensagem,
    });
    if (response?.status === 200) {
      setCallbackErrorList([]);
      setCallbackMessage([
        response?.data?.msg ? response?.data?.msg : "Relátorio enviado",
      ]);
      setCallbackShown(true);
      setCallbackType("success");
      setOpenModalEmail(false);
    } else if (response.status === 400) {
      setCallbackErrorList(Object.values(response?.data?.error).flat(Infinity));
      setCallbackDuration(3000);
      setCallbackMessage("Erro");
      setCallbackShown(true);
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Ocorreu um erro"]);
    }

    setLoading(false);
  }

  async function handleSelectEmpresa(id) {
    setEmpresaId(id);
    await loadCentroCusto(id);
  }

  useEffect(() => {
    (async function () {
      setLoading(true);
      const year = new Date().getFullYear();

      setListAnos(
        listAnos.map((item, index) => ({
          id: year - index,
          nome: `${year - index}`,
        }))
      );
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (
      (empresaId !== "" && ano !== "") ||
      (centroCustoId !== "" && empresaId !== "" && ano !== "")
    ) {
      updateTable();
    } else if (empresaId) {
      setEmpresaPagina("dre", empresaId);
    }
  }, [empresaId, ano, centroCustoId]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("dre");
    if (empresaStorage) {
      handleSelectEmpresa(empresaStorage);
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <div className="table-no-header relatorio-dre">
        <HeaderTable
          handleSelectEmpresa={handleSelectEmpresa}
          listEmpresas={listEmpresas}
          empresaId={empresaId}
          setCentroCustoId={setCentroCustoId}
          listCentroCusto={listCentroCusto}
          centroCustoId={centroCustoId}
          setAno={setAno}
          listAnos={listAnos}
          ano={ano}
          tableOptions={tableOptions}
          onTableMoreAction={onTableMoreAction}
        />
        {listRequest ? (
          <div className="session-container">
            <span className="session-container-header">
              <Grid
                container
                direction="column"
                className="container-dre container-relatorio"
                style={{
                  paddingRight: 24,
                }}
              >
                {!isMobile && (
                  <>
                    <Grid item className="container-row-dre">
                      <Grid item className="name-column-categoria-dre">
                        Categoria
                      </Grid>
                      {meses.map((item, index) => (
                        <Grid item key={index} className="name-column">
                          {item}
                        </Grid>
                      ))}
                      <Grid item className="name-column total-column">
                        Total
                      </Grid>
                    </Grid>
                    <div style={{ height: 40 }} />
                  </>
                )}
                <Grid item xs sm md>
                  {isMobile ? (
                    <AccordionDreMobile dados={listRequest} />
                  ) : (
                    <AccordionDre dados={listRequest} />
                  )}
                </Grid>
              </Grid>
            </span>
          </div>
        ) : (
          <EmptyDre />
        )}
        <CallbackMessage
          type={callbackType}
          open={callbackShown}
          errorList={callbackErrorList}
          duration={callbackDuration}
          message={callbackMessage}
          handleClose={() => handleClose()}
        />
        <ModalEnviarEmail
          open={openModalEmail}
          onClose={handleSendEmail}
          setCallbackErrorList={setCallbackErrorList}
          callbackErrorList={callbackErrorList}
          callbackShown={callbackShown}
          setCallbackShown={setCallbackShown}
        />
        <ModalLoginExpirado open={openLoginExpirado} />
      </div>
    </>
  );
}

export default DRE;
