import React, { useMemo, useState } from "react";
import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from "@material-ui/icons";
import classes from "./styles.module.scss";
import OverlayLoading from "../OverlayLoading";
import Icon from "../Icon";
import Cleave from "cleave.js/react";

function InputTime({
  title = "",
  label = "",
  tipo = "",
  type = "text",
  nome = "",
  error = false,
  underline = false,
  onInputChange = () => {},
  required = false,
  handleInputChange = () => { },
  loading = false,
  icon,
  labelSuffix = null,
  handleMouseLeave = () => { },
  customClassName,
  ...rest
}) {
  const [valueSelect, setValueInput] = useState("");

  function handleOnInputWrap(event) {
    const text = event.target.value;
    setValueInput(text);
    onInputChange(valueSelect);
  }

  const suffixInput = () => {

    let icon;

    if (labelSuffix.icon === "info")
    icon = <InfoOutlined className={classes.suffixIconInfo} />;

    else if(labelSuffix.icon === "export") 
      icon = 
        <span className={classes.suffixIconShare}>
          <Icon
            name="export"
            color="#812990"
            size="16"
          />
        </span>;   
               
    return (
      <div className={classes.suffixLabel}>
        <Tooltip
          arrow
          title={labelSuffix.title}
          onClick={() => labelSuffix.onClick()}
        >
          {icon}  
        </Tooltip>
      </div>
    );
  }

  const headerInput = () => (
    <React.Fragment>
      <div className={classes.inputLabel}>
        {label}
        {required && " *"}
        {labelSuffix && (
          suffixInput()
        )}
      </div>
    </React.Fragment>
  );
 
  const cleaveTimeOptions = useMemo(() => ({
    time: true,
    timePattern: ['h', 'm']
  }), [])

  return (
    <React.Fragment>
      {headerInput()}
      <div
        className={underline ? classes.inputUnderline : classes.inputContainer}
      >
        {loading && <OverlayLoading size={20} />}
        <Cleave options={cleaveTimeOptions}
          onChange={(event) => {
            // return console.log(event.target.value)
            handleOnInputWrap(event);
            handleInputChange(event);
          }} className={`${customClassName} ${error ? classes.error : 
            tipo === "percentage"
             ? classes.inputNumber 
             : classes.input
            }`}
            {...rest}
            />
        {icon && (
          <div
            className={icon === "arrow-down" ? classes.iconArrow : classes.icon}
          >
            <Icon name={icon} color="#EF4066" size={20} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default InputTime;