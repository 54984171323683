import { FilterAutocomplete } from "./FilterAutocomplete";
import { FilterButton } from "./FilterButton";
import { FilterButtons } from "./FilterButtons";
import { FilterCheckbox } from "./FilterCheckbox";
import { FilterDate } from "./FilterDate";
import { FilterRoot } from "./FilterRoot";
import { FilterSelect } from "./FilterSelect";

export const Filter = {
  Root: FilterRoot,
  Select: FilterSelect,
  Checkbox: FilterCheckbox,
  Autocomplete: FilterAutocomplete,
  Buttons: FilterButtons,
  Button: FilterButton,
  Date: FilterDate,
};
