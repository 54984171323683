import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { isNumber } from "lodash";

import SelectList from "../../../../components/SelectList";
import Select from "../../../../components/SelectComponent";
import Input from "../../../../components/Input";
import Button from "../../../../components/ButtonComponent";
import OverlayLoading from "../../../../components/OverlayLoading";
import ModalLoginExpirado from "../../../../components/ModalLoginExpirado";
import CallbackMessage from "../../../../components/CallbackMessage";

import empresasService from "../../../../services/empresasService";
import selectServices from "../../../../services/selectServices";
import produtosServices from "../../../../services/produtosServices";
import notasFiscaisServices from "../../../../services/notasFiscaisService";
import enumsServices from "../../../../services/enumsServices";

import {
  numberToCurrency,
  currencyToNumber,
} from "../../../../utils/functions";
import { cpfCnpjMask } from "../../../../utils/strings";
import validateFields from "../../../../utils/validateFields";

import IconNfce from "../../../../assets/img/nfc-e.svg";
import "./styles.scss";
import useCompany from "../../../../hooks/useCompany";

function EmitirNfce() {
  const history = useHistory();

  const {
    companyList: listEmpresa,
    selectedCompany: empresa,
    setSelectedCompany: setEmpresa,
    getEmpresaPagina,
  } = useCompany("nfce");

  const { selecionaProduto } = produtosServices;
  const { consultaEmpresa } = empresasService;
  const { cadastraNotaProduto } = notasFiscaisServices;
  const {
    finalidadeEmissao: getFinalidadeEmissao,
    indicadorPresenca,
    localDestinoOperacao,
    modalidadeFrete: getModalidadeFrete,
    naturezaOperacao: getNaturezaOperacao,
    tipoImpressaoDanfe: getTipoImpressaoDanfe,
    tipoNF,
  } = enumsServices;

  const [danfeCompanyInfo, setDanfeCompanyInfo] = useState(null);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [loading, setLoading] = useState();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [openCadastro, setOpenCadastro] = useState(false);
  const [openPagamentoValores, setOpenPagamentoValores] = useState(false);
  const [produto, setProduto] = useState();
  const [somaQuantidade, setSomaQuantidade] = useState(0.0);
  const [somaDesconto, setSomaDesconto] = useState(0.0);
  const [somaTotal, setSomaTotal] = useState(0.0);
  const [somaTotalPagar, setSomaTotalPagar] = useState(0.0);
  const [formProduto, setFormProduto] = useState([]);

  const [listDestinoOperacao, setListDestinoOperacao] = useState([]);
  const [listFinalidadeEmissao, setListFinalidadeEmissao] = useState([]);
  const [listIndicadorVendaPresencial, setListIndicadorVendaPresencial] =
    useState([]);
  const [listModalidadeFrete, setListModalidadeFrete] = useState([]);
  const [listNaturezaOperacao, setListNaturezaOperacao] = useState([]);
  const [listNumeroNf, setListNumeroNf] = useState([]);
  const [listSerie, setListSerie] = useState([]);
  const [listTipoDaOperacao, setListTipoDaOperacao] = useState([]);
  const [listTipoImpressaoDanfe, setListTipoImpressaoDanfe] = useState([]);

  const [listFormaPagamento, setListFormaPagamento] = useState([]);
  const [listMeioPagamento, setListMeioPagamento] = useState([]);
  const [listProduto, setListProduto] = useState([]);
  const objPagamento = {
    valor: null,
    formaPagamento: null,
    meioPagamento: null,
  };

  const [formPagamento, setFormPagamento] = useState([objPagamento]);

  const [naturezaOperacao, setNaturezaOperacao] = useState(null);
  const [tipoOperacao, setTipoOperacao] = useState(null);
  const [destinoOperacao, setDestinoOperacao] = useState(null);
  const [tipoImpressaoDanfe, setTipoImpressaoDanfe] = useState(null);
  const [finalidadeEmissao, setFinalidadeEmissao] = useState(null);
  const [indicadorVendaPresencial, setIndicadorVendaPresencial] =
    useState(null);
  const [modalidadeFrete, setModalidadeFrete] = useState(null);

  const dataNFCE = history.location.state?.nfcsCopiada;

  const loadEnumDestinoOperacao = useCallback(async () => {
    const responseDestinoOperacao = await localDestinoOperacao();

    if (responseDestinoOperacao.status === 200) {
      setListDestinoOperacao(responseDestinoOperacao.data);
    }
  }, [localDestinoOperacao, setListDestinoOperacao]);

  const loadEnumFinalidadeEmissao = useCallback(async () => {
    const responseFinalidadeEmissao = await getFinalidadeEmissao();

    if (responseFinalidadeEmissao.status === 200) {
      setListFinalidadeEmissao(responseFinalidadeEmissao.data);
    }
  }, [getFinalidadeEmissao, setListFinalidadeEmissao]);

  const loadEnumIndicadorVendaPresencial = useCallback(async () => {
    const responseIndicadorPresenca = await indicadorPresenca();

    if (responseIndicadorPresenca.status === 200) {
      setListIndicadorVendaPresencial(responseIndicadorPresenca.data);
    }
  }, [indicadorPresenca, setListIndicadorVendaPresencial]);

  const loadEnumModalidadeFrete = useCallback(async () => {
    const responseModalidadeFrete = await getModalidadeFrete();

    if (responseModalidadeFrete.status === 200) {
      setListModalidadeFrete(responseModalidadeFrete.data);
    }
  }, [getModalidadeFrete, setListModalidadeFrete]);

  const loadEnumNaturezaOperacao = useCallback(async () => {
    const responseNaturezaOperacao = await getNaturezaOperacao();

    if (responseNaturezaOperacao.status === 200) {
      setListNaturezaOperacao(responseNaturezaOperacao.data);
    }
  }, [getNaturezaOperacao, setListNaturezaOperacao]);

  const loadEnumTipoImpressaoDanfe = useCallback(async () => {
    const responseTipoImpressaoDanfe = await getTipoImpressaoDanfe();

    if (responseTipoImpressaoDanfe.status === 200) {
      setListTipoImpressaoDanfe(responseTipoImpressaoDanfe.data);
    }
  }, [getTipoImpressaoDanfe, setListTipoImpressaoDanfe]);

  const loadEnumTipoOperacao = useCallback(async () => {
    const responseTipoOperacao = await tipoNF();

    if (responseTipoOperacao.status === 200) {
      setListTipoDaOperacao(responseTipoOperacao.data);
    }
  }, [tipoNF, setListTipoDaOperacao]);

  const loadProdutos = useCallback(
    async (id) => {
      const response = await selecionaProduto(id);

      if (response.status === 200) {
        const { data } = response;

        if (data.length === 0) {
          setCallbackMessage("Erro!");
          setCallbackErrorList([
            "Nenhum produto encontrado para a empresa selecionada.",
          ]);
          setCallbackShown(true);
          return;
        }

        const productsList = data.map((item) => ({
          image:
            "https://www.orientalheritageagra.com/wp-content/uploads/2019/03/product-png-image-59158.png",
          description: item.descricao,
          code: item.codigo_produto,
          type: item.tipo_produto,
          value: numberToCurrency(item.valor_unitario_comercial),
          valorTributario: item.valor_unitario_tributario,
          id: item.id_produto,
          estoqueMinimo:
            !item.estoque_minimo && item.estoque_minimo !== 0
              ? false
              : item.estoque_minimo,
          estoque: item.estoque,
          ean: item?.ean,
        }));

        setListProduto(productsList);
        return productsList;
      }
    },
    [selecionaProduto]
  );

  const loadListFormaPagamento = useCallback(async () => {
    const origemNF = 1;
    const response = await selectServices.selecionaFormaPagamentoPorOrigem(
      origemNF
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListFormaPagamento(response.data);
  }, []);

  const loadDanfeCompanyInfo = useCallback(
    async (id) => {
      const response = await consultaEmpresa(id);

      if (response.status === 200) {
        const { data } = response;

        setDanfeCompanyInfo({
          cpfCnpj: cpfCnpjMask(data.cnpj),
          razaoSocial: data.razao_social,
          endereco: data.endereco,
          numero: data.numero,
          bairro: data.bairro,
          cidade: data.cidade,
          uf: data.uf,
        });
      }
    },
    [consultaEmpresa]
  );

  const handleChangeEmpresa = useCallback(
    async (id) => {
      setLoading(true);
      setEmpresa(id);
      await loadProdutos(id);
      await loadDanfeCompanyInfo(id);
      setLoading(false);
    },
    [loadDanfeCompanyInfo, loadProdutos]
  );

  const loadListMeioPagamento = useCallback(async () => {
    const response = await selectServices.selectMeioPagamento();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListMeioPagamento(response.data);
  }, []);

  const handleAdicionarProduto = (produtoSelected, fields) => {
    setOpenCadastro(false);
    console.log(produtoSelected, fields);
    setFormProduto([...formProduto, { ...produtoSelected, ...fields }]);
  };

  const handleSubmit = async () => {
    setLoading(true);

    let errors = [];

    let fieldsToValidate = [
      {
        label: "empresa",
        value: empresa,
      },
    ];

    let fieldsErrors = validateFields(fieldsToValidate);

    fieldsErrors.map((item) => errors.push(item));

    if (formProduto.length === 0) {
      errors.push("Nenhum produto selecionado.");
    }

    if (
      formPagamento.length === 1 &&
      (formPagamento[0].formaPagamento === null ||
        formPagamento[0].meioPagamento === null ||
        formPagamento[0].valor === null)
    ) {
      errors.push("Dados de pagamento não adicionados.");
    }

    if (errors.length !== 0) {
      setCallbackErrorList(errors);
      setCallbackMessage("Erro!");
      setCallbackShown(true);
      setLoading(false);
      return;
    }

    const products = formProduto.map((item) => ({
      id_produto: item.id,
      quantidade: Number(item.quantidade),
      valor: item.valorUnitario,
    }));

    const pagamentos = [...formPagamento];
    pagamentos.pop();

    const payments = pagamentos.map((item) => ({
      id_forma_pagamento: item.meioPagamento,
      valor_pago: Number(item.valor),
    }));

    const totalPaid = payments.reduce((acumulattor, actualValue) => {
      return acumulattor + actualValue.valor_pago;
    }, 0);

    const valorTroco =
      totalPaid > somaTotalPagar ? totalPaid - somaTotalPagar : 0;

    let response = await cadastraNotaProduto(
      empresa,
      naturezaOperacao,
      tipoOperacao,
      destinoOperacao,
      tipoImpressaoDanfe,
      finalidadeEmissao,
      indicadorVendaPresencial,
      modalidadeFrete,
      valorTroco,
      somaTotalPagar,
      products,
      payments
    );

    if (response.hasOwnProperty("response")) {
      response = response.response;
    }

    if (response.status === 200 || response.status === 201) {
      setCallbackMessage(response.data?.msg);
      setCallbackShown(true);
      setCallbackDuration(2000);
      setTimeout(() => history.push("/emissao/nfc"), 250);
    }

    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }

    if (response.status === 400) {
      setCallbackErrorList(Object.values(response?.data?.error).flat(Infinity));
      setCallbackMessage("Erro");
      setCallbackShown(true);
    }

    if (response.status === 500) {
      setCallbackErrorList(["Erro interno no servidor, contate o suporte."]);
      setCallbackMessage("Erro!");
      setCallbackShown(true);
    }
    setLoading(false);
  };

  function calculaSoma() {
    let quantidade = 0.0;
    let desconto = 0.0;
    let total = 0.0;

    for (let i = 0; i < formProduto.length; i++) {
      quantidade += Number(formProduto[i].quantidade || 0.0);
      total += Number(formProduto[i].valorTotal || 0.0);
      desconto += Number(formProduto[i].valorDesconto || 0.0);
    }

    setSomaQuantidade(quantidade);
    setSomaDesconto(desconto);
    setSomaTotal(total + desconto);
    setSomaTotalPagar(total);
  }

  function NotaNfce() {
    calculaSoma();

    return (
      <Grid
        container
        direction="column"
        className="nota-nfce-container"
        spacing={1}
      >
        <Grid item container>
          <Grid item xs={6} sm={8} md={8}>
            <pre className="nota-nfce-info">
              {danfeCompanyInfo && (
                <>
                  {`CPF/CPNJ: ${danfeCompanyInfo.cpfCnpj} ${danfeCompanyInfo.razaoSocial}
                  ${danfeCompanyInfo.endereco}, ${danfeCompanyInfo.numero}, ${danfeCompanyInfo.bairro}, ${danfeCompanyInfo.cidade} - ${danfeCompanyInfo.uf}\n
                  Documento Auxiliar da Nota Fiscal de Consumidor Eletrônica`}
                </>
              )}
            </pre>
          </Grid>
          <Grid item xs={6} sm={4} md={4} className="nota-nfce-icon-wrapper">
            <img
              className="nota-nfce-icon"
              src={IconNfce}
              alt="icone-nota-nfce"
            />
          </Grid>
        </Grid>
        <Grid item>
          <table className="nota-nfce-table">
            <thead>
              <tr>
                <th className="column-head-id">ID</th>
                <th className="column-numero">Código</th>
                <th className="column-descricao">Descrição</th>
                <th className="column-numero">Qtde</th>
                <th className="column-th-valor">Vl Unit</th>
                <th className="column-th-valor">Desc</th>
                <th className="column-th-valor">Vl Trib</th>
                <th className="column-th-valor">Valor (R$)</th>
              </tr>
            </thead>
            {formProduto.length > 0 && (
              <tbody>
                {formProduto.map((item, index) => (
                  <tr key={index}>
                    <td className="column-id">
                      <div>
                        <Button
                          className="action-outline"
                          text="-"
                          onClick={() => {
                            let list = [...formProduto];
                            list.splice(index, 1);
                            setFormProduto(list);
                            calculaSoma();
                          }}
                        />
                        <span>{item.id}</span>
                      </div>
                    </td>
                    <td className="column-numero">{item.code}</td>
                    <td className="column-descricao">{item.description}</td>
                    <td className="column-quantidade">{item.quantidade}</td>
                    <td className="column-td-valor">
                      {numberToCurrency(item.valorUnitario)}
                    </td>
                    <td className="column-td-valor">
                      {numberToCurrency(item.valorDesconto)}
                    </td>
                    <td className="column-td-valor">
                      {numberToCurrency(item.valorTributo)}
                    </td>
                    <td className="column-td-valor">
                      {numberToCurrency(item.valorTotal)}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {formProduto.length === 0 && (
            <div className="table-sem-dados"> Nenhum produto adicionado</div>
          )}
        </Grid>
        <Grid item container spacing={1} justify="flex-end">
          <Grid item xs={12} sm={4} md={1} className="nota-nfce-total">
            TOTAL
          </Grid>
          <Grid item xs={12} sm={4} md={2} className="nota-nfce-total">
            {numberToCurrency(somaTotal)}
          </Grid>
        </Grid>
        <Grid
          item
          container
          className="nota-nfce-info-total-container"
          justify="flex-end"
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            className="nota-nfce-info-total-label"
          >
            Quantidade Total de Itens:
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={3}
            className="nota-nfce-info-total-valor"
          >
            {somaQuantidade}
          </Grid>
        </Grid>
        <Grid
          item
          container
          className="nota-nfce-info-total-container"
          justify="flex-end"
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            className="nota-nfce-info-total-label"
          >
            Valor total:
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={3}
            className="nota-nfce-info-total-valor"
          >
            {numberToCurrency(somaTotal)}
          </Grid>
        </Grid>
        <Grid
          item
          container
          className="nota-nfce-info-total-container"
          justify="flex-end"
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            className="nota-nfce-info-total-label"
          >
            Desconto:
          </Grid>
          <Grid item xs={12} sm={2} md={3} className="nota-nfce-info-desconto">
            {numberToCurrency(somaDesconto)}
          </Grid>
        </Grid>
        <Grid
          item
          container
          className="nota-nfce-info-total-container"
          justify="flex-end"
        >
          <Grid itemxs={12} sm={4} md={4} className="nota-nfce-info-soma-label">
            Valor Total a Pagar:
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={3}
            className="nota-nfce-info-soma-valor"
          >
            {numberToCurrency(somaTotalPagar)}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function ProdutoCadastro() {
    let produtoSelected = listProduto.find((item) => item.id === produto);
    const [callbackAddProdutoShown, setCallbackAddProdutoShown] =
      useState(false);
    const [callbackAddProdutoMessage, setCallbackAddProdutoMessage] =
      useState("");
    const [callbackAddProdutoErrorList, setCallbackAddProdutoErrorList] =
      useState([]);

    useEffect(() => {
      if (
        isNumber(produtoSelected?.estoque) &&
        isNumber(produtoSelected?.estoqueMinimo) &&
        produtoSelected?.estoque <= produtoSelected?.estoqueMinimo
      ) {
        setCallbackAddProdutoMessage("Atenção!");
        setCallbackAddProdutoErrorList(["Estoque mínimo do produto atingido!"]);
        setCallbackAddProdutoShown(true);
      }
    }, [produtoSelected, produto]);

    const [quantidade, setQuantidade] = useState(1);
    const [valorDesconto, setValorDesconto] = useState(0);

    let valorTributo = produtoSelected.valorTributario;
    let valorUnitario = currencyToNumber(produtoSelected.value);

    const valorTotal = useMemo(() => {
      let total = 0;

      total = valorUnitario * quantidade - valorDesconto;

      return total;
    }, [valorUnitario, quantidade, valorDesconto]);

    const handleCloseAddProduto = (event, reason) => {
      if (reason === "timeout") {
        setCallbackAddProdutoShown(false);
      }
    };

    return (
      <>
        <Grid item container direction="column" spacing={2}>
          <Grid
            item
            container
            direction="column"
            className="emitir-nfce-cadastro-container"
            spacing={2}
          >
            <Grid item className="emitir-nfce-form-title">
              {`Item: #${formProduto.length + 1}`}
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                <Input
                  readOnly
                  title={produtoSelected.description}
                  label="Descrição do Produto ou Serviço"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input readOnly title={produtoSelected.code} label="Código" />
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <div>
                    <div className="label">Imagem</div>
                    <img
                      src={produtoSelected.image}
                      alt="imagem-produto"
                      className="emitir-nfce-cadastro-imagem"
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  xs={12}
                  sm={12}
                  md={6}
                  spacing={1}
                >
                  <Grid item container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      className="emitir-nfce-field-quantidade"
                    >
                      <Input
                        label="Quantidade"
                        tipo="decimal"
                        value={quantidade}
                        handleInputChange={(event) =>
                          setQuantidade(event.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Input
                        label="Valor unitário (R$)"
                        tipo="dinheiro"
                        readOnly
                        value={valorUnitario}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="flex-end">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      className="emitir-nfce-field-desconto"
                    >
                      <Input
                        label="Desconto"
                        tipo="dinheiro"
                        handleInputChange={(event) =>
                          setValorDesconto(event.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="flex-end">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      className="emitir-nfce-field-total"
                    >
                      <Input
                        label="Total"
                        tipo="dinheiro"
                        readOnly
                        value={valorTotal}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justify="flex-end">
            <Button
              text="+ Adicionar"
              className="success-outline zero-margin"
              onClick={() =>
                handleAdicionarProduto(produtoSelected, {
                  valorDesconto: valorDesconto,
                  valorTotal: valorTotal,
                  valorTributo: valorTributo,
                  valorUnitario: valorUnitario,
                  quantidade: quantidade,
                })
              }
            />
          </Grid>
        </Grid>
        <CallbackMessage
          key={1}
          open={callbackAddProdutoShown}
          type={callbackAddProdutoErrorList.length !== 0 ? "error" : "success"}
          duration={6000}
          errorList={callbackAddProdutoErrorList}
          message={callbackAddProdutoMessage}
          handleClose={handleCloseAddProduto}
        />
      </>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }

    if (reason === "timeout") {
      setCallbackShown(false);
    }
  };

  const [pagCadastroCallbackShown, setPagCadastroCallbackShown] =
    useState(false);
  const [pagCadastroCallbackMessage, setPagCadastroCallbackMessage] =
    useState("");
  const [pagCadastroCallbackErrorList, setPagCadastroCallbackErrorList] =
    useState([]);
  const [pagCadastroCallbackDuration] = useState(6000);

  const pagCadastroHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      setPagCadastroCallbackShown(false);
    }

    if (reason === "timeout") {
      setPagCadastroCallbackShown(false);
    }

    if (pagCadastroCallbackErrorList.length === 0) {
      history.goBack();
    }
  };

  const handleAdicionarPagamento = () => {
    let list = [...formPagamento];
    let lastField = list[list.length - 1];

    const fieldsToValidate = [
      {
        label: "formadepagamento",
        value: lastField.formaPagamento,
      },
      {
        label: "meiodepagamento",
        value: lastField.meioPagamento,
      },
      {
        label: "valor",
        value: lastField.valor,
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      setPagCadastroCallbackErrorList(fieldsErrors);
      setPagCadastroCallbackMessage("Erro!");
      setPagCadastroCallbackShown(true);
      return;
    } else {
      if (!openPagamentoValores) setOpenPagamentoValores(true);
      setFormPagamento([...formPagamento, objPagamento]);
    }
  };

  const loadInitialPayments = (pagamentos) => {
    if (pagamentos && pagamentos?.length > 0) {
      const newPayments = [];
      pagamentos.map((item) => {
        const fieldsToValidate = [
          {
            label: "formadepagamento",
            value: item?.id_forma_pagamento,
          },
          {
            label: "meiodepagamento",
            value: item?.meio_pagamento,
          },
          {
            label: "valor",
            value: item?.valor_pago,
          },
        ];

        const fieldsErrors = validateFields(fieldsToValidate);

        if (fieldsErrors.length === 0) {
          newPayments.push({
            valor: item?.valor_pago,
            formaPagamento: item?.id_forma_pagamento,
            meioPagamento: item?.meio_pagamento,
          });
        }
      });
      setFormPagamento([...newPayments, objPagamento]);
      if (newPayments.length > 0) {
        setOpenPagamentoValores(true);
      }
    }
  };

  const loadInitialProdutos = async (idEmpresa, produtos) => {
    const listaProdutos = await loadProdutos(idEmpresa);
    if (produtos && produtos?.length > 0) {
      const newProducts = [];
      produtos.map((item) => {
        const productAtual = listaProdutos?.find(
          (a) => a.id === item?.id_produto
        );
        if (productAtual) {
          newProducts.push({
            code: productAtual?.code,
            description: productAtual?.description,
            id: item?.id_produto,
            image: productAtual?.image,
            quantidade: item?.quantidade ? item?.quantidade : 0,
            type: productAtual?.type,
            valorDesconto: 0,
            valorTotal: item?.quantidade * productAtual?.valorTributario,
            valorTributario: productAtual?.valorTributario,
            valorTributo: productAtual?.valorTributario,
            valorUnitario: productAtual?.valorTributario,
            value: productAtual?.value,
          });
        }
      });
      setFormProduto(newProducts);
    }
  };

  useEffect(() => {
    console.log(dataNFCE);
    if (dataNFCE) {
      handleChangeEmpresa(dataNFCE?.id_empresa);
      loadInitialPayments(dataNFCE?.pagamentos);
      loadInitialProdutos(dataNFCE?.id_empresa, dataNFCE?.itens);
    } else {
      const empresaStorage = getEmpresaPagina("nfce");
      if (empresaStorage) {
        handleChangeEmpresa(empresaStorage);
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([
        loadListFormaPagamento(),
        loadListMeioPagamento(),
        loadEnumDestinoOperacao(),
        loadEnumFinalidadeEmissao(),
        loadEnumIndicadorVendaPresencial(),
        loadEnumModalidadeFrete(),
        loadEnumNaturezaOperacao(),
        loadEnumTipoImpressaoDanfe(),
        loadEnumTipoOperacao(),
      ]);
      setLoading(false);
    })();
  }, [
    loadListFormaPagamento,
    loadListMeioPagamento,
    loadEnumDestinoOperacao,
    loadEnumFinalidadeEmissao,
    loadEnumIndicadorVendaPresencial,
    loadEnumModalidadeFrete,
    loadEnumNaturezaOperacao,
    loadEnumTipoImpressaoDanfe,
    loadEnumTipoOperacao,
  ]);

  return (
    <>
      <div className="session-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <form className="page-nfce emitir-nfce-form-container">
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Select
                  styleType="form"
                  label="Empresa"
                  required
                  initialValue={listEmpresa?.find(
                    (item) => item?.id === empresa
                  )}
                  list={listEmpresa}
                  title="Selecione"
                  callback={handleChangeEmpresa}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className="emitir-nfce-form-title">Adicionar produtos</div>
                <SelectList
                  label="Localize um produto e serviço"
                  required
                  list={listProduto}
                  title="Selecione"
                  callback={(id) => {
                    setProduto(id);
                    setOpenCadastro(true);
                  }}
                  hint="Produtos"
                />
              </Grid>
              {openCadastro && (
                <Grid item xs={12} sm={12} md={12}>
                  <ProdutoCadastro />
                </Grid>
              )}
            </Grid>
          </form>
        </span>
      </div>
      <div className="session-container">
        <span className="session-container-header">
          <form className="page-nfce emitir-nfce-form-container">
            <div className="emitir-nfce-section-title">Pré-Danfe</div>
            <NotaNfce />
          </form>
        </span>
      </div>
      <div className="session-container">
        <span className="session-container-header">
          <form className="page-nfce emitir-nfce-form-container">
            <div className="emitir-nfce-pagamento-container">
              <div className="emitir-nfce-form-title">Dados de pagamento</div>
              <Grid container direction="column" spacing={3}>
                <Grid item container alignItems="flex-end" spacing={1}>
                  <Grid item container spacing={1} xs={11} sm={11} md={11}>
                    <Grid item xs={12} sm={12} md={4}>
                      <Select
                        key={0}
                        label="Forma de pagamento"
                        styleType="form"
                        required
                        list={listFormaPagamento}
                        callback={(id) => {
                          let list = formPagamento;
                          list[list.length - 1].formaPagamento = id;
                          setFormPagamento(list);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Select
                        key={1}
                        label="Meio de pagamento"
                        styleType="form"
                        required
                        list={listMeioPagamento}
                        callback={(id) => {
                          let list = formPagamento;
                          list[list.length - 1].meioPagamento = id;
                          setFormPagamento(list);
                        }}
                      />
                    </Grid>
                    <Grid item xs={11} sm={12} md={4}>
                      <Input
                        label="Valor"
                        required
                        tipo="dinheiro"
                        handleInputChange={(event) => {
                          let list = formPagamento;
                          list[list.length - 1].valor = event.value;
                          setFormPagamento(list);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <Button
                      className="action-outline emitir-nfce-btn-adicionar"
                      text="+"
                      onClick={handleAdicionarPagamento}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {openPagamentoValores && (
                <div className="emitir-nfce-pagamento">
                  <Grid item container direction="column" spacing={1}>
                    <Grid container item spacing={2} xs={11} sm={11} md={11}>
                      <Grid
                        item
                        className="emitir-nfce-pagamento-label"
                        xs
                        sm
                        md
                      >
                        Forma de pagamento
                      </Grid>
                      <Grid
                        item
                        className="emitir-nfce-pagamento-label"
                        xs
                        sm
                        md
                      >
                        Meio de pagamento
                      </Grid>
                      <Grid
                        item
                        className="emitir-nfce-pagamento-label"
                        xs
                        sm
                        md
                      >
                        Valor
                      </Grid>
                    </Grid>
                    {formPagamento.map((item, index) => (
                      <>
                        {formPagamento[index].formaPagamento !== null &&
                          formPagamento[index].meioPagamento !== null &&
                          formPagamento[index].valor !== null && (
                            <Grid
                              item
                              direction="column"
                              xs={12}
                              sm={12}
                              md={12}
                              key={index}
                              className="emitir-nfce-pagamento-row"
                            >
                              <Grid
                                item
                                className="emitir-nfce-pagamento-divider"
                                xs={12}
                                sm={12}
                                md={11}
                              />
                              <Grid
                                container
                                item
                                spacing={2}
                                xs={12}
                                sm={12}
                                md={11}
                              >
                                <Grid
                                  item
                                  className="emitir-nfce-pagamento-text"
                                  xs
                                  sm
                                  md
                                >
                                  {
                                    listFormaPagamento.find(
                                      (item) =>
                                        item.id ==
                                        Number(
                                          formPagamento[index].formaPagamento
                                        )
                                    )?.nome
                                  }
                                </Grid>
                                <Grid
                                  item
                                  className="emitir-nfce-pagamento-text"
                                  xs
                                  sm
                                  md
                                >
                                  {
                                    listMeioPagamento.find(
                                      (item) =>
                                        item.id ==
                                        Number(
                                          formPagamento[index].meioPagamento
                                        )
                                    )?.nome
                                  }
                                </Grid>
                                <Grid
                                  item
                                  className="emitir-nfce-pagamento-valor"
                                  xs
                                  sm
                                  md
                                >
                                  {numberToCurrency(
                                    Number(formPagamento[index].valor)
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                      </>
                    ))}
                  </Grid>
                </div>
              )}
              {/* <div className="nfce-config-info-container">
                <Grid container direction="column" spacing={3}>
                  <Grid item container spacing={1} xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Select
                        key={2}
                        label="Destino da Operação"
                        styleType="form"
                        required
                        list={listDestinoOperacao}
                        callback={(id) => setDestinoOperacao(id)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Select
                        key={3}
                        label="Finalidade da Emissão"
                        styleType="form"
                        required
                        list={listFinalidadeEmissao}
                        callback={(id) => setFinalidadeEmissao(id)}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container spacing={1} xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Select
                        key={4}
                        label="Indicador Venda Presencial"
                        styleType="form"
                        required
                        list={listIndicadorVendaPresencial}
                        callback={(id) => setIndicadorVendaPresencial(id)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Select
                        key={5}
                        label="Modalidade Frete"
                        styleType="form"
                        required
                        list={listModalidadeFrete}
                        callback={(id) => setModalidadeFrete(id)}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container spacing={1} xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Select
                        key={6}
                        label="Natureza da Operação"
                        styleType="form"
                        required
                        list={listNaturezaOperacao}
                        callback={(id) => setNaturezaOperacao(id)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Select
                        key={7}
                        label="Tipo Impressão Danfe"
                        styleType="form"
                        required
                        list={listTipoImpressaoDanfe}
                        callback={(id) => setTipoImpressaoDanfe(id)}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container spacing={1} xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Select
                        key={0}
                        label="Tipo da Operação"
                        styleType="form"
                        required
                        list={listTipoDaOperacao}
                        callback={(id) => setTipoOperacao(id)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div> */}
              <div className="emitir-nfce-submit">
                <Button
                  className="btn-primary zero-margin"
                  text="GERAR NOTA FISCAL"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </form>
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
      <CallbackMessage
        key={1}
        open={callbackShown}
        type={callbackErrorList.length !== 0 ? "error" : "success"}
        duration={callbackDuration}
        errorList={callbackErrorList}
        message={callbackMessage}
        handleClose={handleClose}
      />
    </>
  );
}

export default EmitirNfce;
