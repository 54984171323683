import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Input from '../../../components/Input';
import Textarea from '../../../components/Textarea';
import TableServices from '../components/TableServices';
import OverlayLoading from '../../../components/OverlayLoading';

import solicitacaoServicoServices from '../../../services/solicitacaoServicoServices';

import './styles.scss';
import CallbackMessage from '../../../components/CallbackMessage';

function SolicitacaoServico() {
  const { id: idSolicitacao } = useParams();
  const history = useHistory();

  const { consultaSolicitacao } = solicitacaoServicoServices;

  const [servicosSolicitados, setServicosSolicitados] = useState([]);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState('');
  const [callbackMessage, setCallbackMessage] = useState('');
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  // eslint-disable-next-line
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      setCallbackShown(false);
    }

    if (reason === 'timeout') {
      setCallbackShown(false);
    }
  }

  const loadSolicitacao = async () => {
    setLoading(true);
    const response = await consultaSolicitacao(idSolicitacao);

    if (response.status !== 200) {
      setCallbackMessage('Erro!');
      setCallbackErrorList(['Não foi possível exibir a solicitação.'])
      history.push('/teste-lista-solicitacao-servico')
    }

    console.log('consultaSolicitacao :: ', response);

    if (response.status === 200) {
      const servicesArray = response.data.itens;

      const newServices = servicesArray.map(item => ({
        id: item.id_servico,
        quantidade: item.quantidade,
        descricao: item.nome_servico,
        valorUnit: item.valor_servico,
        observacao: item.observacao,
      }))
      setServicosSolicitados(newServices);
    }

    setLoading(false);
  }

  useEffect(() => {
    (async () => {
      await loadSolicitacao();
    })()
  }, [idSolicitacao]);

  return (
    <>
      {loading && <OverlayLoading />}
      <div className='session-container containerServiceList'>
        <Grid
          container
          justify='center'
          className='containerService serviceTable'
        >
          <Grid container item xs={12} spacing={2}>
            <Grid continer justify='flex-start' item>
              <span className='titleServices'>Serviços solicitados</span>
            </Grid>
            <TableServices
              serviceList={servicosSolicitados}
              noDelete
            />
          </Grid>
        </Grid>
      </div>
      <CallbackMessage
        open={callbackShown}
        handleClose={handleClose}
        duration={callbackDuration}
        errorList={callbackErrorList}
        message={callbackMessage}
        type={callbackErrorList.length !== 0 ? 'error' : 'success'}
      />
    </>
  );
}

export default SolicitacaoServico;
