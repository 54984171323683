import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import CallbackMessage from "../CallbackMessage";
import notasFiscaisService from '../../services/notasFiscaisService';
import ShowMore from '../ShowMore';
import { formatDate } from '../../utils/dates'
import { numberToCurrency } from '../../utils/functions';


import "./styles.scss";

function ModalDetalhesNotaFiscal({
  open,
  onClose,
  idNota,
}) {

  const { consultaNfe } = notasFiscaisService;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [dataEmitente, setDataEmitente] = useState([]);
  const [dataDestinatario, setDataDestinatario] = useState([]);
  const [dataValores, setDataValores] = useState([]);
  const [dadosAdicionais, setDadosAdicionais] = useState([]);
  const [data, setData] = useState([]);
  const [dataItens, setDataItens] = useState([]);
  

  const loadFields = useCallback(async () => {
    if (idNota) {
      setLoading(true);

      const response = await consultaNfe(idNota);

      let newData = response.data[0];

      if(response.status === 200 || response.status === 201) {
        setDataEmitente([
          {
            title: "Razão Social:",
            description: newData?.nome_emitente
          },
          {
            title: "CNPJ:",
            description: newData?.cpf_cnpj_emitente
          },
          {
            title: "Cidade:",
            description: newData?.cidade_emitente
          },
          {
            title: "Estado:",
            description: newData?.uf_emitente
          },
        ])
        setDataDestinatario([
          {
            title: "Razão Social:",
            description: newData?.nome_destinatario
          },
          {
            title: "CNPJ:",
            description: newData?.cpf_cnpj_destinatario
          },
          {
            title: "Cidade:",
            description: newData?.cidade_destinatario
          },
          {
            title: "Estado:",
            description: newData?.uf_destinatario
          },
        ])
        setDataValores([
          {
            title: "Valor Troco:",
            description: numberToCurrency(newData?.valor_troco)
          },
          {
            title: "Valor Desconto:",
            description: numberToCurrency(newData?.valor_desconto)
          },
          {
            title: "Valor Total:",
            description: numberToCurrency(newData?.valor_total)
          },
        ]);
        setData([
          {
            title: "Data de Emissão:",
            description: formatDate(newData?.data_hora_emissao?.substring(0, 10))
          },
          {
            title: "Chave:",
            description: newData?.chave
          },
          {
            title: "Número:",
            description: newData?.numero
          },
          {
            title: "Série:",
            description: newData?.serie
          },
          {
            title: "Status SEFAZ:",
            description: newData?.descricao_status_sefaz
          },
          {
            title: "Código Status SEFAZ:",
            description: newData?.codigo_status_sefaz
          },
        ])
        setDadosAdicionais([
          {
            title: "Outras informações",
            description: newData?.outras_informacoes
          }
        ])

        let newItens = [];

        let newItensMap = newData?.itens?.map((item, index) => {
          let indexNumber = index + 1;
          let nomeItem = item?.descricao && item.descricao !== "" ? item.descricao : `#--------- ITEM ${indexNumber} ---------#`;

          newItens.push(
            {
            title: nomeItem,
            description: ""
            },
            {
              title: "CFOP:",
              description: item?.cfop
            },
            {
              title: "EAN:",
              description: item?.ean
            },
            {
              title: "EAN Tributável:",
              description: item?.ean
            },
            {
              title: "Quantidade:",
              description: item?.quantidade
            },
            {
              title: "Valor total:",
              description: numberToCurrency(item?.total_item)
            },
            {
              title: "Valor unitário:",
              description: numberToCurrency(item?.valor_unitario_tributario)
            },
          );
        });

        setDataItens(newItens);

        setLoading(false);
      }
    }
  }, [idNota])

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }

  const handleCloseModal = async () => {
    setLoading(false);
    onClose();
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadFields();
      setLoading(false);
    })();
  }, [loadFields]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleCloseModal} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>DETALHES - DADOS DA NOTA FISCAL</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} className="modal-items-nota">
                <ShowMore title="INFORMAÇÕES" listInfo={data} /> 
              </Grid>

             <Grid item xs={12} className="modal-items-nota">
                <ShowMore title="DADOS DO EMITENTE" listInfo={dataEmitente} /> 
              </Grid>

              <Grid item xs={12} className="modal-items-nota">
                <ShowMore title="DADOS DO DESTINATÁRIO" listInfo={dataDestinatario} /> 
              </Grid>

              <Grid item xs={12} className="modal-items-nota">
                <ShowMore title="ITENS" listInfo={dataItens} /> 
              </Grid>

              <Grid item xs={12} className="modal-items-nota">
                <ShowMore title="VALORES TOTAIS" listInfo={dataValores} /> 
              </Grid>
              <Grid item xs={12} className="modal-items-nota">
                <ShowMore title="DADOS ADICIONAIS" listInfo={dadosAdicionais} /> 
              </Grid>



            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalDetalhesNotaFiscal;
