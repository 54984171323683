import Axios from "./Axios";

const permissoesService = {
  listarPermissoes: async (
    idUsuario,
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null
  ) => {
    const response = await Axios.get(`/lista-permissao/${idUsuario}`, {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cadastraPermissao: async (userId, idPermissao = []) => {
    const response = await Axios.post("/cadastra-permissao", {
      id_usuario: userId,
      id_permissao: idPermissao,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  removePermissao: async (userId, idPermissao = []) => {
    const response = await Axios.post("/remove-permissao", {
      id_usuario: userId,
      id_permissao: idPermissao,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};

export default permissoesService;
