import Axios from "./Axios";

const corretoresService = {
  consultaCorretores: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null
  ) => {
    const response = await Axios.get("/lista-corretor", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm
      }
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ ...response }) => {
        return response;
      });

    return response;
  },
  consultaCorretor: async id => {
    const response = await Axios.get(`/consulta-corretor/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ ...response }) => {
        return response;
      });

    return response;
  },
  cadastraCorretor: async form => {
    const response = await Axios.post("/cadastra-corretor", form)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ ...response }) => {
        return response;
      });
    return response;
  },
  atualizaCorretor: async (id, form) => {
    const response = await Axios.post(`/atualiza-corretor/${id}`, form)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ ...response }) => {
        return response;
      });
    return response;
  },
  selecionaCorretor: async () => {
    const response = await Axios.get(`/seleciona-corretor`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ ...response }) => {
        return response;
      });
    return response;
  },
  removeCorretor: async id => {
    const response = await Axios.get(`/remove-corretor/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ ...response }) => {
        return response;
      });
    return response;
  },
  selecionaTimeCorretor: async () => {
    const response = await Axios.get(`/seleciona-time-corretor`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ ...response }) => {
        return response;
      });
    return response;
  },
  atualizaGerenteTime: async (idCorretor, form) => {
    const response = await Axios.post(
      `/atualiza-gerente-time-corretor/${idCorretor}`,
      form
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ ...response }) => {
        return response;
      });
    return response;
  },
  atualizaTurno: async (id_corretor, turnos) => {
    const response = await Axios.post(
      `/definir-turno-corretor/${id_corretor}`,
      {
        id_corretor,
        turnos
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ ...response }) => {
        return response;
      });
      
    return response;
  },
  listaCorretoresTurno: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    dataInicial,
    dataFinal
  ) => {
    const response = await Axios.get("/lista-corretor", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        data_inicial: dataInicial,
        data_final: dataFinal
      }
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ ...response }) => {
        return response;
      });

    return response;
  },
  consultaTurnoCorretor: async (id, mes, ano) => {
    const response = await Axios.get(`/consulta-turno-corretor/${id}`, {
      params: {
        mes, ano
      }
    })
      .then(({ ...response }) => response)
      .catch(({ ...response}) => response)

    return response;
  }
};

export default corretoresService;
