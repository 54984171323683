import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import "./styles.scss";
import CallbackMessage from "../CallbackMessage";
import TableComponent from "../TableResponsiveComponent";
import OverlayLoading from "../OverlayLoading";
import cartoesPontoService from "../../services/dpCartoesPonto";
import { toDateDD_MM_YYYY } from "../../utils/dates";

export const ModalHistoricoCartaoPonto = ({ open, title, onClose, id }) => {
  const { getHistoricoCartaoPonto } = cartoesPontoService;
  console.log({ open });
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");

  const [listRequest, setListRequest] = useState([]);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Data", dataRef: "data" },
      { columnName: "Hora", dataRef: "hora" },
      { columnName: "Id do usuário", dataRef: "id_usuario" },
      { columnName: "Nome do usuário", dataRef: "nome_usuario" },
    ],
    options: null,
    display: {
      search: false,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: "id",
    orderByType: "desc",
    perPage: null,
  });

  const updateTable = useCallback(async () => {
    if (!id || !open) {
      return;
    }

    try {
      setLoading(true);
      const result = await getHistoricoCartaoPonto({
        id,
        page: defaultConfigTable.currentPage,
        orderBy: defaultConfigTable.orderBy,
        orderByType: defaultConfigTable.orderByType,
        perPage: defaultConfigTable.perPage,
      });
      if (result.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      const resultData = result.data;

      if (resultData.hasOwnProperty("data")) {
        const { data } = resultData;
        console.log(data);
        const newData = data.map((item) => {
          return {
            ...item,
            data: toDateDD_MM_YYYY(item.data),
          };
        });
        const newResult = {
          ...resultData,
          data: newData,
        };
        setListRequest(newResult);
      } else {
        const newData = resultData.map((item) => {
          return {
            ...item,
            data: toDateDD_MM_YYYY(item.data),
          };
        });
        const newResult = {
          ...resultData,
          data: newData,
        };
        setListRequest(newResult);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [
    id,
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
  ]);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  const onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    updateTable();
  }, [updateTable, id]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      className="paper dialog-modal-historico"
    >
      {loading && <OverlayLoading />}
      <div className="modal-title-area">
        <DialogTitle>
          <span className="titleConfirmDialog">{title}</span>
        </DialogTitle>
        <IconButton onClick={onClose} size="medium">
          <CloseIcon className="iconConfirm" />
        </IconButton>
      </div>
      <DialogContent className="custom-dialog-content">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableComponent
              idName="id"
              dataList={listRequest !== undefined ? listRequest?.data : []}
              tableConfig={defaultConfigTable}
              callbackCurrentPage={onPageChange}
              callbackOrderBy={onOrderBy}
              callbackPerPage={onPerPage}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={4000}
        message={callbackMessage}
        handleClose={handleClose}
      />
    </Dialog>
  );
};
