import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InputComponent from "../../Input";
import ButtonComponent from "../../ButtonComponent";
import OverlayLoading from "../../OverlayLoading";
import ModalLoginExpirado from "../../ModalLoginExpirado";
import CallbackMessage from "../../CallbackMessage";
import Switch from "../../Switch";
import validateFields from "../../../utils/validateFields";
import perfilAcessoService from "../../../services/perfilAcessoService";


import "./styles.scss";

function ModalEditarPerfil({
  open,
  onClose,
  cbTable,
  idEdit,
}) {
  const { atualizaPerfilAcesso, consultaPerfilAcesso } = perfilAcessoService;
  const [nome, setNome] = useState(null);
  const [padrao, setPadrao] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const handleSubmit = async () => {
    setErrors([]);

    let fields = [];

    fields.push(
      {
        label: "nome",
        value: nome,
      },
    );

    const fieldsValidate = validateFields(fields);

    if (fieldsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(fieldsValidate);
      return;
    }

    setLoading(true);

    const response = await atualizaPerfilAcesso(idEdit, nome, padrao ? 1 : 0);

    if (response.status === 401) {
       setOpenLoginExpirado(true);
       setLoading(false);
       return;
     }
     if (response.status !== 201 && response.status !== 200) {
       if (response.data.error) {
        setLoading(false);
        setShowMessage(true);
         setErrors(Object.values(response.data.error));
         return console.log('errors:', response.data.error)
       } else setErrors(["Ocorreu um erro"]);
     } else if (response.status === 200 || response.status === 201) {
       setLoading(false);
       setErrors([]);
       setSuccessMessage("Perfil de acesso atualizado com sucesso!");
      }
      
      setShowMessage(true);
      setLoading(false);
      cbTable();
  }

  const loadFields = useCallback(async () => {
    if (idEdit) {
      const response = await consultaPerfilAcesso(idEdit);

      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }

      if (response.status !== 201 && response.status !== 200) {
       if (response.data.error) {
          setLoading(false);
          setShowMessage(true);
          setErrors(Object.values(response.data.error));
       } else setErrors(["Ocorreu um erro"]);
        setShowMessage(true);
      } else {
        const { data } = response;
        setPadrao(data?.padrao === 1 ? true : false);
        setNome(data?.nome);
      }
      setLoading(false);
    }
  }, [idEdit])

  useEffect(() => {
    setLoading(true)
    loadFields()
    setLoading(false)
  }, [loadFields]);

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
      cbTable();
    }
  }

  const handleCloseModal = async () => {
    setLoading(false);
    onClose();
    setNome(null);
    setPadrao(false);
  }

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleCloseModal} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>EDITAR PERFIL</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
          
              <Grid item xs={12} sm={12} md={12}>
                <InputComponent
                  nome="nome"
                  label="Nome do perfil"
                  tipo="text"
                  required
                  value={nome}
                  handleInputChange={(event) => {
                    setNome(event.target.value);
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={10}>
                <div className="switch-container-modal">
                 <Grid item>
                    <Switch
                      checked={padrao}
                      onClick={() => {
                        setPadrao(prev => !prev);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    Tornar Padrão
                  </Grid>
                  </div>
              </Grid> */}
              <Grid item xs={12}>
                <span className="required-text">* Campos obrigatórios</span>
              </Grid>
              <Grid container justify="flex-end" item spacing={2}>
              <Grid item>
               <ButtonComponent
                  text="Cancelar"
                  onClick={onClose}
                  className="primary-outline zero-margin btn-modal-outline"
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  text="Salvar"
                  onClick={handleSubmit}
                  className="btn-success zero-margin"
                />
              </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalEditarPerfil;
