const addChildrenAttribute = (array) => {
  array.forEach((element) => {
    element.children = [];
  });
};
const countArrays = (array) => {
  let numberArrays = 0;
  array.forEach((element) => {
    let splitResult = element.order.split(".");
    if (numberArrays < splitResult.length) {
      numberArrays = splitResult.length;
    }
  });
  return numberArrays;
};
const createMenu = (countArrays, array) => {
  let initialObj = {};
  let menuObj = [];

  //Cria as arrays
  for (let i = 1; i <= countArrays; i++) {
    initialObj[`array${i}`] = [];
  }

  //-------------------------------------
  //Copula as arrays
  for (let i = 1; i <= countArrays; i++) {
    let splitArray = separatesArray(array, i);
    splitArray.forEach((element) => {
      initialObj[`array${i}`].push(element);
    });
  }
  //-------------------------------------
  //Criando o ObjMenu
  if (countArrays > 1) {
    for (let i = countArrays; i > 1; i--) {
      if (countArrays > 1) {
        let parentArray = i - 1;
        let parentArrayAux = initialObj[`array${parentArray}`];
        if (i === countArrays) {
        let childArray = i;
        menuObj = joinParentArrayWithChildArray(
          parentArrayAux,
          initialObj[`array${childArray}`]
          );
        } else if (i < countArrays) {
          if (i === 2) {
            parentArrayAux = parentArrayAux.sort((a, b) =>{
              if (Number(a.order) > Number(b.order)) {
                return 1;
              }
              if (Number(a.order) < Number(b.order)) {
                return -1;
              }
              return 0;
            })
          }
        menuObj = joinParentArrayWithChildArray(
            parentArrayAux,
            menuObj
          );
        }
      }
    }
  } else if (countArrays === 1) {
    for (let i = countArrays; i >= 1; i--) {
      if (countArrays === 1) {
        initialObj[`array${i}`].forEach((el) => {
          menuObj.push(el);
        });
      }
    }
  }
  //-------------------------------------
  return menuObj;
};
const joinParentArrayWithChildArray = (firstArray, secondArray) => {
  firstArray.forEach((firstElement) => {
    secondArray.forEach((secondElement) => {
      if (firstElement.id === secondElement.idParent) {
        firstElement.children.push(secondElement);
        arraySort(firstElement.children);
      }
    });
  });
  return firstArray;
};
const arraySort = (array) => {
  array.sort(function (a, b) {
    if (a.order > b.order) {
      return 1;
    }
    if (a.order < b.order) {
      return -1;
    }
    return 0;
  });
};
const separatesArray = (array, numberArrays) => {
  let result = [];
  array.forEach((element) => {
    let level = element.order.split(".");
    if (numberArrays === level.length) {
      result.push(element);
    }
  });
  return result;
};

const generateMenu = (arrayMenu) => {
  //Adiciona em todos os itens o atributo children
  addChildrenAttribute(arrayMenu);

  //Retorna a quantidade de itens do menu
  const numberArrays = countArrays(arrayMenu);

  //retorna o array final do menu
  const resultMenu = createMenu(numberArrays, arrayMenu);

  return resultMenu;
};

export default generateMenu;
