import { Grid } from "@material-ui/core";
import React from "react";
import InputTime from "../../../../../../../components/InputTime";
import styles from "../../styles.module.scss";

export function HorariosSemana({
  dados,
  handleChangeDadosHorariosSemana,
  locationState,
}) {
  return (
    <Grid item xs={12}>
      <div className="label">Preencha os horários da semana</div>
      <div className={styles.containerDiasSemana}>
        <div className={`${styles.itemDiaSemana} ${styles.labelDiaSemana}`}>
          <input
            type="checkbox"
            disabled={locationState?.details}
            name="checkSegunda"
            checked={dados?.horariosSemana?.segunda}
            onChange={(event) =>
              handleChangeDadosHorariosSemana(
                "segunda",
                event.target.checked,
                true
              )
            }
          />
          Segunda-Feira
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Entrada
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.entradaSegunda}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "entradaSegunda",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Início intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.inicioIntervaloSegunda}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "inicioIntervaloSegunda",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Fim intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.fimIntervaloSegunda}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "fimIntervaloSegunda",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Saída
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.saidaSegunda}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "saidaSegunda",
                event.target.value
              )
            }
          />
        </div>

        <div className={`${styles.itemDiaSemana} ${styles.labelDiaSemana}`}>
          <input
            type="checkbox"
            disabled={locationState?.details}
            checked={dados?.horariosSemana?.terca}
            name="checkTerca"
            onChange={(event) =>
              handleChangeDadosHorariosSemana(
                "terca",
                event.target.checked,
                true
              )
            }
          />
          Terça-Feira
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Entrada
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.entradaTerca}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "entradaTerca",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Início intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.inicioIntervaloTerca}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "inicioIntervaloTerca",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Fim intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.fimIntervaloTerca}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "fimIntervaloTerca",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Saída
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.saidaTerca}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana("saidaTerca", event.target.value)
            }
          />
        </div>

        <div className={`${styles.itemDiaSemana} ${styles.labelDiaSemana}`}>
          <input
            type="checkbox"
            disabled={locationState?.details}
            checked={dados?.horariosSemana?.quarta}
            name="checkQuarta"
            onChange={(event) =>
              handleChangeDadosHorariosSemana(
                "quarta",
                event.target.checked,
                true
              )
            }
          />
          Quarta-Feira
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Entrada
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.entradaQuarta}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "entradaQuarta",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Início intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.inicioIntervaloQuarta}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "inicioIntervaloQuarta",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Fim intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.fimIntervaloQuarta}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "fimIntervaloQuarta",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Saída
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.saidaQuarta}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana("saidaQuarta", event.target.value)
            }
          />
        </div>

        <div className={`${styles.itemDiaSemana} ${styles.labelDiaSemana}`}>
          <input
            type="checkbox"
            disabled={locationState?.details}
            checked={dados?.horariosSemana?.quinta}
            name="checkQuinta"
            onChange={(event) =>
              handleChangeDadosHorariosSemana(
                "quinta",
                event.target.checked,
                true
              )
            }
          />
          Quinta-Feira
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Entrada
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.entradaQuinta}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "entradaQuinta",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Início intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.inicioIntervaloQuinta}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "inicioIntervaloQuinta",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Fim intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.fimIntervaloQuinta}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "fimIntervaloQuinta",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Saída
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.saidaQuinta}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana("saidaQuinta", event.target.value)
            }
          />
        </div>

        <div className={`${styles.itemDiaSemana} ${styles.labelDiaSemana}`}>
          <input
            type="checkbox"
            disabled={locationState?.details}
            checked={dados?.horariosSemana?.sexta}
            name="checkSexta"
            onChange={(event) =>
              handleChangeDadosHorariosSemana(
                "sexta",
                event.target.checked,
                true
              )
            }
          />
          Sexta-Feira
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Entrada
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.entradaSexta}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "entradaSexta",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Início intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.inicioIntervaloSexta}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "inicioIntervaloSexta",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Fim intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.fimIntervaloSexta}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "fimIntervaloSexta",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Saída
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.saidaSexta}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana("saidaSexta", event.target.value)
            }
          />
        </div>

        <div className={`${styles.itemDiaSemana} ${styles.labelDiaSemana}`}>
          <input
            type="checkbox"
            disabled={locationState?.details}
            checked={dados?.horariosSemana?.sabado}
            name="checkSabado"
            onChange={(event) =>
              handleChangeDadosHorariosSemana(
                "sabado",
                event.target.checked,
                true
              )
            }
          />
          Sábado
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Entrada
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.entradaSabado}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "entradaSabado",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Início intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.inicioIntervaloSabado}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "inicioIntervaloSabado",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Fim intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.fimIntervaloSabado}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "fimIntervaloSabado",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Saída
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.saidaSabado}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana("saidaSabado", event.target.value)
            }
          />
        </div>

        <div className={`${styles.itemDiaSemana} ${styles.labelDiaSemana}`}>
          <input
            type="checkbox"
            disabled={locationState?.details}
            checked={dados?.horariosSemana?.domingo}
            name="checkDomingo"
            onChange={(event) =>
              handleChangeDadosHorariosSemana(
                "domingo",
                event.target.checked,
                true
              )
            }
          />
          Domingo
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Entrada
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.entradaDomingo}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "entradaDomingo",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Início intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.inicioIntervaloDomingo}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "inicioIntervaloDomingo",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Fim intervalo
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.fimIntervaloDomingo}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "fimIntervaloDomingo",
                event.target.value
              )
            }
          />
        </div>
        <div className={`${styles.itemDiaSemana} label`}>
          Saída
          <InputTime
            tipo="hhmm"
            value={dados?.horariosSemana?.saidaDomingo}
            disabled={locationState?.details}
            handleInputChange={(event) =>
              handleChangeDadosHorariosSemana(
                "saidaDomingo",
                event.target.value
              )
            }
          />
        </div>
      </div>
    </Grid>
  );
}
