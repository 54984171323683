import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { onlyNumbers } from "../../../utils/strings.js";
import validateFields from "../../../utils/validateFields";

import SelectComponent from "../../../components/SelectComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import Input from "../../../components/Input";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import OverlayLoading from "../../../components/OverlayLoading";
import Stepper from "../../../components/Stepper";

import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";

import { Grid, Divider, Switch, withStyles } from "@material-ui/core";

import buscaCnpjService from "../../../services/buscaCnpjService";
import buscaCepService from "../../../services/buscaCepService";
import empresasService from "../../../services/empresasService";
import corretoresService from "../../../services/corretoresService";

import colors from "../../../assets/styles/_colors.scss";

import "./styles.scss";

function CadastroCorretores() {
  const history = useHistory();
  const [listEmpresas, setListEmpresas] = useState([]);
  const [idEmpresaSelected, setIdEmpresaSelected] = useState();
  const [idEmpresaSelectedRS, setIdEmpresaSelectedRS] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [loadingCnpj, setLoadingCnpj] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const params = useParams();
  const { id } = params;
  const IntId = parseInt(id, 10);
  const isEdit = Number.isInteger(IntId);

  const { selectEmpresa } = empresasService;
  const {
    cadastraCorretor,
    consultaCorretor,
    atualizaCorretor
  } = corretoresService;

  const [activeStep, setActiveStep] = useState(0);
  const [isPJ, setIsPJ] = useState(true);

  // INICIO CAMPOS PARA O SERVICE
  const [cnpj, setCnpj] = useState("");
  const [cpf, setCpf] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numEnd, setNumEnd] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nome, setNome] = useState("");
  const [nome_fantasia, setNome_fantasia] = useState("");
  const [site, setSite] = useState("");

  // contato
  const objContato = {
    nome: "",
    email: "",
    telefone: "",
    celular: ""
  };
  const [formContato, setFormContato] = useState([objContato]);
  const handleDuplicateContato = () =>
    setFormContato([...formContato, objContato]);
  const handleRemoveContato = index => {
    formContato.splice(index, 1);
    setFormContato([...formContato]);
  };
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const CustomSwitch = withStyles({
    switchBase: {
      color: colors.colorPrimary,
      "&$checked": {
        color: colors.colorPrimary
      },
      "&$checked + $track": {
        backgroundColor: colors.colorPrimary
      }
    },
    checked: {},
    track: {}
  })(Switch);

  const updateListEmpresas = useCallback(async () => {
    const response = await selectEmpresa();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListEmpresas(response.data);
  }, [selectEmpresa]);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await updateListEmpresas();
      setLoading(false);
    })();
  }, [updateListEmpresas]);

  const getCorretorRequest = useCallback(async () => {
    setLoading(true);
    const response = await consultaCorretor(id);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    const { data } = response;

    if (data) {
      if (data.cnpj_cpf.length === 14) {
        setCnpj(data.cnpj_cpf);
        setRazaoSocial(data.nome_razao_social);
        setCpf("");
        setIsPJ(true);
      }

      if (data.cnpj_cpf.length === 11) {
        setCpf(data.cnpj_cpf);
        setNome(data.nome_razao_social);

        setCnpj("");
        setIsPJ(false);
      }

      setIdEmpresaSelected(data.id_empresa);
      const empresaGetRazaoSocial = await empresasService.consultaEmpresa(
        data.id_empresa
      );

      if (empresaGetRazaoSocial && empresaGetRazaoSocial.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      setIdEmpresaSelectedRS(empresaGetRazaoSocial.data.razao_social);

      setBairro(data.bairro);
      setCep(data.cep);
      setEndereco(data.endereco);
      setNumEnd(data.numero);
      setCidade(data.cidade);
      setEstado(data.uf);

      setComplemento(data.complemento);
      setNome_fantasia(data.nome_fantasia);
      setSite(data.site);
      setFormContato(data.contato, ...formContato);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (isEdit) {
      (async function () {
        setLoading(true);
        await getCorretorRequest();
        setLoading(false);
      })();
    }
  }, [isEdit, getCorretorRequest]);

  async function handleCnpjChange(_cnpj) {
    _cnpj = onlyNumbers(_cnpj);
    setCnpj(_cnpj);
    if (_cnpj.length === 14) {
      setCallbackShown(false);
      setCallbackErrorList([]);
      setLoadingCnpj(true);
      const response = await buscaCnpjService.getCnpjReceita(_cnpj);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoadingCnpj(false);
        return;
      }
      const data = response.data;
      if (response.status === 201) {
        if (data.status !== "ERROR") {
          setRazaoSocial(data.nome);
          setCep(onlyNumbers(data.cep));
          setCidade(data.municipio);
          setEndereco(data.logradouro);
          setBairro(data.bairro);
          setNumEnd(data.numero);
          setEstado(data.uf);
          setComplemento(data.complemento);
          setNome_fantasia(data.fantasia);
        } else {  
          setCallbackErrorList(["CNPJ inválido"]);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setLoadingCnpj(false);
          setRazaoSocial("");
          setCep("");
          setCidade("");
          setEndereco("");
          setBairro("");
          setNumEnd("");
          setEstado("");
          setComplemento("");
          setNome_fantasia("");
          return;
        }
      } else if (response.status === 400 && 
        response.data.error.includes("Too Many Requests")){  
        setLoadingCnpj(false);
        setCallbackErrorList(["Ocorreu um excesso de consultas. Espere 1 minuto e tente novamente."]);
        setCallbackDuration(4000);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackShown(true);
      }
      setLoadingCnpj(false);
    }
  }
  function handleCpfChange(_cpf) {
    _cpf = onlyNumbers(_cpf);
    setCpf(_cpf);
  }

  async function handleCepChange(cep) {
    cep = onlyNumbers(cep);
    setCep(cep);

    if (cep.length === 8) {
      setLoadingCep(true);
      const response = await buscaCepService.getCep(cep);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoadingCep(false);
        return;
      }
      const data = response.data;

      if (data) {
        setCidade(data.localidade);
        setEstado(data.uf);
        setEndereco(data.logradouro);
        setBairro(data.bairro);
        setNumEnd("");
      }
      setLoadingCep(false);
    }
  }
  const handleNextStep = () => {
    let hasErrors = false;
    if (!isPJ) {
      if (activeStep === 0) {
        const stepZeroFields = [
          {
            label: "empresa",
            value: idEmpresaSelected
          },
          {
            label: "cpf",
            value: cpf
          },
          {
            label: "nome",
            value: nome
          }
        ];
        const stepZeroErrors = validateFields(stepZeroFields);

        if (stepZeroErrors.length !== 0) {
          hasErrors = true;
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackErrorList(stepZeroErrors);
          return;
        }
      }
    }
    if (isPJ) {
      if (activeStep === 0) {
        const stepZeroFields = [
          {
            label: "empresa",
            value: idEmpresaSelected
          },
          {
            label: "cnpj",
            value: cnpj
          },
          {
            label: "razaosocial",
            value: razaoSocial
          }
        ];
        const stepZeroErrors = validateFields(stepZeroFields);

        if (stepZeroErrors.length !== 0) {
          hasErrors = true;
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackErrorList(stepZeroErrors);
          return;
        }
      }
    }

    if (hasErrors) return;

    setActiveStep(activeStep + 1);
  };
  const handleBack = () => setActiveStep(activeStep - 1);

  const atualFormData = {
    id_empresa: idEmpresaSelected,
    escolhapfpj: isPJ ? "pj" : "pf",
    corretor_cnpj: cnpj,
    corretor_cpf: cpf,
    corretor_razao_social: razaoSocial,
    corretor_nome: nome,
    corretor_nome_fantasia: nome_fantasia,

    corretor_rua: endereco,
    corretor_numero: numEnd,
    corretor_complemento: complemento,
    corretor_bairro: bairro,
    corretor_cep: cep,
    corretor_cidade: cidade,
    corretor_estado: estado,

    corretor_site: site,

    contato: formContato
  };

  const handleFormSubmit = async event => {
    event.preventDefault();

    let hasErrors = false;
    if (activeStep === 2) {
      const stepTwoFields = [
        {
          label: "cep",
          value: cep
        },
        {
          label: "endereco",
          value: endereco
        },
        {
          label: "bairro",
          value: bairro
        },
        {
          label: "numero",
          value: numEnd
        },
        {
          label: "estado",
          value: estado
        },
        {
          label: "cidade",
          value: cidade
        }
      ];
      const stepTwoErrors = validateFields(stepTwoFields);

      if (stepTwoErrors.length !== 0) {
        hasErrors = true;
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(stepTwoErrors);
        return;
      }
    }

    if (hasErrors) return;

    let success = false;
    let status = 0;
    let error = "";

    setLoading(true);
    if (isEdit) {
      const response = await atualizaCorretor(id, atualFormData);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      success = response.success;
      status = response.status;
      if (response.data.error) error = Object.values(response.data.error);
    } else {
      const response = await cadastraCorretor(atualFormData);
      success = response.data.success;
      status = response.status;
      setLoading(false);
      if (success || status === 201) {
        setCallbackType("success");
        setCallbackShown(true);
        setCallbackMessage("Corretor adicionado com sucesso!");
        setCallbackDuration(3000);
      } else if (response.response.data.error) {
        error = Object.values(response.response.data.error)
      };
      if (error) {
        setCallbackType("error");
        setCallbackShown(true);
        setCallbackErrorList(error);
        setCallbackDuration(3000);
        setCallbackMessage("Erro!");
      }
    }
    setLoading(false);

    if (success || status === 201) {
      setCallbackType("success");
      setCallbackShown(true);
      setCallbackMessage("Corretor salvo com sucesso!");
      setCallbackDuration(3000);
    }

    if (error) {
      setCallbackType("error");
      setCallbackShown(true);
      setCallbackErrorList(error);
      setCallbackMessage("Erro!");
    }
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/corretores-cadastrados");
    }
  }

  return (
    <>
      <Grid container className="default-forms-container">
        <Grid item xs={12} md={2}>
          <Stepper
            activeStep={activeStep}
            arraySteps={[
              "DADOS DE IDENTIFICAÇÃO",
              "DADOS DE CONTATO",
              "ENDEREÇO"
            ]}
            useActiveStep={true}
          />
        </Grid>

        <Grid item xs={10}>
          <Grid container spacing={3} justify="center">
            <Grid item xs={8} style={{ marginTop: 45 }}>
              <form onSubmit={handleFormSubmit} noValidate>
                {/* STEP0 PESSOA JURIDICA E PESSOA FISICA */}
                <Grid container spacing={2}>
                  {/* swicht pj-pf */}
                  {activeStep === 0 && (
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center"
                      }}
                    >
                      <Grid item>
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          justify="center"
                          spacing={1}
                          style={{
                            backgroundColor: "#ECECEC",
                            width: 115,
                            height: 34,
                            padding: 0,
                            borderRadius: 5,
                            marginBottom: 10,
                            alignContent: "center",
                            color: colors.colorPrimary
                          }}
                        >
                          <Grid item>PF</Grid>
                          <div>
                            <CustomSwitch
                              size="small"
                              checked={isPJ}
                              onChange={() => setIsPJ(!isPJ)}
                            />
                          </div>
                          <Grid item>PJ</Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs="auto"
                        style={{
                          alignContent: "center",
                          marginTop: 3
                        }}
                      >
                        <span className="text-tipo-corretores-for">
                          {isPJ ? "PESSOA JURÍDICA" : "PESSOA FÍSICA"}
                        </span>
                      </Grid>
                    </Grid>
                  )}

                  {/* EMPRESA */}
                  {activeStep === 0 && (
                    <Grid item xs={12}>
                      <SelectComponent
                        styleType="form"
                        label="Empresa"
                        title="Selecione uma empresa"
                        list={listEmpresas}
                        initialValue={idEmpresaSelectedRS}
                        required
                        callback={id => setIdEmpresaSelected(id)}
                      />
                    </Grid>
                  )}

                  {/* PJ */}
                  {activeStep === 0 && isPJ && (
                    <>
                      <Grid item xs={12}>
                        <Input
                          label="CNPJ"
                          tipo="cnpj"
                          required
                          nome="cnpj"
                          value={cnpj}
                          loading={loadingCnpj}
                          handleInputChange={e => {
                            handleCnpjChange(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          label="Razão Social"
                          nome="razaoSocial"
                          value={razaoSocial}
                          required
                          handleInputChange={e => {
                            setRazaoSocial(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          label="Nome Fantasia"
                          nome="nome_fantasia"
                          value={nome_fantasia}
                          handleInputChange={e => {
                            setNome_fantasia(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          label="Site"
                          nome="site"
                          value={site}
                          handleInputChange={e => {
                            setSite(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <span className="required-text">
                          * Campos obrigatórios
                        </span>
                      </Grid>
                    </>
                  )}

                  {/* PF */}
                  {activeStep === 0 && !isPJ && (
                    <>
                      <Grid item xs={12}>
                        <Input
                          label="CPF"
                          nome="cpf"
                          tipo="cpf"
                          required
                          value={cpf}
                          handleInputChange={e => {
                            handleCpfChange(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          label="Nome"
                          nome="nome"
                          value={nome}
                          required
                          handleInputChange={e => {
                            setNome(e.target.value);
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>

                {/* STEP 1 CONTATO */}
                {activeStep === 1 && (
                  <>
                    {formContato.map((c, index) => (
                      <React.Fragment key={index}>
                        <Grid container spacing={2}>
                          {index > 0 && (
                            <Grid item xs={12} style={{ marginTop: 20 }}>
                              <Divider />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Input
                              label="Nome Contato"
                              nome="nomec"
                              value={c.nome}
                              handleInputChange={e => {
                                let list = [...formContato];
                                list[index].nome = e.target.value;
                                setFormContato(list);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              label="E-mail"
                              tipo="email"
                              nome="emailc"
                              value={c.email}
                              handleInputChange={e => {
                                let list = [...formContato];
                                list[index].email = e.target.value;
                                setFormContato(list);
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Input
                              label="Telefone"
                              tipo="telefone"
                              nome="telefonec"
                              value={c.telefone}
                              handleInputChange={e => {
                                let list = [...formContato];
                                list[index].telefone = onlyNumbers(
                                  e.target.value
                                );
                                setFormContato(list);
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Input
                              label="Celular"
                              tipo="celular"
                              nome="celularc"
                              value={c.celular}
                              handleInputChange={e => {
                                let list = [...formContato];
                                list[index].celular = onlyNumbers(
                                  e.target.value
                                );
                                setFormContato(list);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <div className="directBtn left">
                              {index > 0 && (
                                <>
                                  <ButtonComponent
                                    icon={AiOutlineUserDelete}
                                    text="Remover"
                                    onClick={() => handleRemoveContato(index)}
                                    className="secondary-outline"
                                  />
                                </>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}
                    <div className="directBtn">
                      <ButtonComponent
                        icon={AiOutlineUserAdd}
                        text="Adicionar"
                        onClick={() => handleDuplicateContato()}
                        className="success-outline"
                      />
                    </div>
                  </>
                )}

                {/* STEP 2 ENDEREÇO */}
                {activeStep === 2 && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Input
                          label="CEP"
                          tipo="cep"
                          required
                          loading={loadingCep}
                          nome="cep"
                          value={cep}
                          handleInputChange={e => {
                            handleCepChange(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Endereço"
                          nome="endereco"
                          value={endereco}
                          required
                          handleInputChange={e => {
                            setEndereco(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Bairro"
                          nome="bairro"
                          value={bairro}
                          required
                          handleInputChange={e => {
                            setBairro(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Número"
                          tipo="numero"
                          nome="numEnd"
                          value={numEnd}
                          required
                          handleInputChange={e => {
                            setNumEnd(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Complemento"
                          nome="complemento"
                          value={complemento}
                          handleInputChange={e => {
                            setComplemento(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Estado"
                          nome="estado"
                          value={estado}
                          required
                          handleInputChange={e => {
                            setEstado(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Cidade"
                          nome="cidade"
                          value={cidade}
                          required
                          handleInputChange={e => {
                            setCidade(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <span className="required-text">
                          * Campos obrigatórios
                        </span>
                      </Grid>
                    </Grid>
                  </>
                )}

                {/* NAVIGATION BUTTONS */}
                <Grid
                  container
                  justify="flex-end"
                  style={{
                    marginTop: 40,
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  {activeStep > 0 && (
                    <>
                      <Grid item style={{ marginRight: 10 }}>
                        <ButtonComponent
                          text="Voltar"
                          className="btn-secondary"
                          onClick={handleBack}
                        />
                      </Grid>
                    </>
                  )}
                  {activeStep <= 1 && (
                    <>
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary"
                          text="Avançar"
                          onClick={handleNextStep}
                        />
                      </Grid>
                    </>
                  )}
                  {activeStep >= 2 && (
                    <>
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary"
                          text="Finalizar"
                          type="submit"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
        {loading && <OverlayLoading />}
        {callbackShown && (
          <CallbackMessage
            open={callbackShown}
            type={callbackType}
            handleClose={handleClose}
            message={callbackMessage}
            duration={callbackDuration}
            errorList={callbackErrorList}
          />
        )}
      </Grid>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastroCorretores;
