import Axios from "./Axios";

const preAdmissaoService = {
  getPreAdmissoes: async ({
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null,
    efetivado = null,
  }) => {
    const result = await Axios.get("/listagem-pre-admissao", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
        efetivado,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  cadastrarPreAdmissaoEmpresa: async ({
    nome,
    idEmpresa,
    dataAdmissao,
    diasContratoExperiencia,
    aptoTrabalhar,
    dataExameAdmissional,
    idTipoAdmissao,
    optantePorAdiantamento,
    admissaoSeguroAcaoFiscal,
    idVinculoTrabalhista,
    idCategoriaFuncionario,
    idDepartamentoFuncionario,
    funcao,
    salarioBase,
    idFormaPagamentoSalario,
    dataTerminoContrato,
    horario,
    vaValor,
    vaIdFormaPagamento,
    vaDescontado,
    vaTipoDesconto,
    vaValorDescontado,
    vrValor,
    vrIdFormaPagamento,
    vrDescontado,
    vrTipoDesconto,
    vrValorDescontado,
    assMedicaOperadora,
    assMedicaPlano,
    assMedicaValor,
    assMedicaDescontado,
    assMedicaTipoDesconto,
    assMedicaValorDescontado,
    assMedicaDependentes,
    assMedicaDependenteInclusoNoPlano,
    assMedicaDependenteDescontado,
    assMedicaDependenteTipoDesconto,
    assOdontoOperadora,
    assOdontoPlano,
    assOdontoValor,
    assOdontoDescontado,
    assOdontoTipoDesconto,
    assOdontoValorDescontado,
    assOdontoDependentes,
    assOdontoDependenteInclusoNoPlano,
    assOdontoDependenteDescontado,
    assOdontoDependenteTipoDesconto,
    assOdontoDependenteValorDescontado,
    seguroVidaDescricao,
    seguroVidaSeguradora,
    seguroVidaValor,
    seguroVidaDescontado,
    seguroVidaTipoDesconto,
    seguroVidaValorDescontado,
    bolsaAuxilioInstituicao,
    bolsaAuxilioCurso,
    bolsaAuxilioValor,
    bolsaAuxilioDescontado,
    bolsaAuxilioTipoDesconto,
    bolsaAuxilioValorDescontado,
    outrosDescricao,
    outrosValor,
    outrosDescontado,
    outrosTipoDesconto,
    outrosValorDescontado,
    vt,
  }) => {
    const response = await Axios.post("/cadastro-dp-pre-admissao", {
      id_empresa: idEmpresa,
      nome: nome,
      data_admissao: dataAdmissao,
      dias_contrato_experiencia: diasContratoExperiencia,
      apto_trabalhar: aptoTrabalhar,
      data_exame_admissional: dataExameAdmissional,
      id_tipo_admissao: idTipoAdmissao,
      optante_por_adiantamento: optantePorAdiantamento,
      admissao_seguro_acao_fiscal: admissaoSeguroAcaoFiscal,
      id_vinculo_trabalhista: idVinculoTrabalhista,
      id_categoria_funcionario: idCategoriaFuncionario,
      id_departamento_funcionario: idDepartamentoFuncionario,
      funcao: funcao,
      salario_base: salarioBase,
      id_forma_pagamento_salario: idFormaPagamentoSalario,
      data_termino_contrato: dataTerminoContrato,
      horario: horario,
      va_valor: vaValor,
      va_id_forma_pagamento: vaIdFormaPagamento,
      va_descontado: vaDescontado,
      va_tipo_desconto: vaTipoDesconto,
      va_valor_descontado: vaValorDescontado,
      vr_valor: vrValor,
      vr_id_forma_pagamento: vrIdFormaPagamento,
      vr_descontado: vrDescontado,
      vr_tipo_desconto: vrTipoDesconto,
      vr_valor_descontado: vrValorDescontado,
      ass_medica_operadora: assMedicaOperadora,
      ass_medica_plano: assMedicaPlano,
      ass_medica_valor: assMedicaValor,
      ass_medica_descontado: assMedicaDescontado,
      ass_medica_tipo_desconto: assMedicaTipoDesconto,
      ass_medica_valor_descontado: assMedicaValorDescontado,
      ass_medica_dependentes: assMedicaDependentes,
      ass_medica_dependente_incluso_no_plano: assMedicaDependenteInclusoNoPlano,
      ass_medica_dependente_descontado: assMedicaDependenteDescontado,
      ass_medica_dependente_tipo_desconto: assMedicaDependenteTipoDesconto,
      ass_odonto_operadora: assOdontoOperadora,
      ass_odonto_plano: assOdontoPlano,
      ass_odonto_valor: assOdontoValor,
      ass_odonto_descontado: assOdontoDescontado,
      ass_odonto_tipo_desconto: assOdontoTipoDesconto,
      ass_odonto_valor_descontado: assOdontoValorDescontado,
      ass_odonto_dependentes: assOdontoDependentes,
      ass_odonto_dependente_incluso_no_plano: assOdontoDependenteInclusoNoPlano,
      ass_odonto_dependente_descontado: assOdontoDependenteDescontado,
      ass_odonto_dependente_tipo_desconto: assOdontoDependenteTipoDesconto,
      ass_odonto_dependente_valor_descontado:
        assOdontoDependenteValorDescontado,
      seguro_vida_descricao: seguroVidaDescricao,
      seguro_vida_seguradora: seguroVidaSeguradora,
      seguro_vida_valor: seguroVidaValor,
      seguro_vida_descontado: seguroVidaDescontado,
      seguro_vida_tipo_desconto: seguroVidaTipoDesconto,
      seguro_vida_valor_descontado: seguroVidaValorDescontado,
      bolsa_auxilio_instituicao: bolsaAuxilioInstituicao,
      bolsa_auxilio_curso: bolsaAuxilioCurso,
      bolsa_auxilio_valor: bolsaAuxilioValor,
      bolsa_auxilio_descontado: bolsaAuxilioDescontado,
      bolsa_auxilio_tipo_desconto: bolsaAuxilioTipoDesconto,
      bolsa_auxilio_valor_descontado: bolsaAuxilioValorDescontado,
      outros_descricao: outrosDescricao,
      outros_valor: outrosValor,
      outros_descontado: outrosDescontado,
      outros_tipo_desconto: outrosTipoDesconto,
      outros_valor_descontado: outrosValorDescontado,
      vt: vt,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  cadastrarPreAdmissaoFuncionario: async (formData) => {
    const response = await Axios.post(
      "/cadastra-dp-pre-admissao-funcionario",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  editarPreAdmissao: async (id, formData) => {
    const response = await Axios.post(`/edita-pre-admissao/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  verificaHashPreAdmissao: async (hash) => {
    const response = await Axios.post("/verifica-pre-admissao", {
      hash,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  consultaPreAdmissao: async (id) => {
    const response = await Axios.get(`/consulta-pre-admissao/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  removePreAdmissao: async (id) => {
    const response = await Axios.post(`/remove-admissao/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  confirmarContratacaoPreAdmissao: async (id) => {
    const response = await Axios.post(`/funcionario-contratado/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  uploadArquivo: async (formData) => {
    const response = await Axios.post(`/anexar-documentos-dp`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  getDocumentosDp: async ({
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    id_funcionario = null,
    tipo = null,
    id_evento_folha = null
  }) => {
    const result = await Axios.get("/lista-dp-documentos-anexados", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        id_funcionario,
        tipo,
        id_evento_folha
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  downloadDocumentosDp: async (id) => {
    const result = await Axios.get(`/download-documentos-dp/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};
export default preAdmissaoService;
