import React from 'react'

import { Grid } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

export default function HubCountDashboard() {
  const location = useLocation()
  const hubcountUrl = location?.search?.replace('?', '')

  return (
      <Grid
        container
        style={{
          height: '100%',
          position: 'relative'
        }}p
      >
        <Grid
          item
          xs={12}
        >
          {hubcountUrl && (
            <div
              style={{
                display: 'flex',
                height: '100%'
              }}
            >
              <iframe
                title='hubcount'
                src={hubcountUrl}
                width='100%'
                height='100%'
                style={{
                  display: 'flex',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  border: 'none'
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
  )
}
