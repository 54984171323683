import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from 'react-router-dom';

import CardPlano from "./components/CardPlano";
import OverlayLoading from '../../components/OverlayLoading';
import CallbackMessage from '../../components/CallbackMessage';

import planosServices from '../../services/planosServices';

import "./styles.scss";

function EscolhePlanos() {
  const history = useHistory() 

  const { assinaPlano, selecionaPlanoServico } = planosServices;

  const [loading, setLoading] = useState(false);

  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [listaPlano, setListaPlano] = useState([]);

  const updateListaPlano = useCallback(async () => {
    setLoading(true);
    const { data, status } = await selecionaPlanoServico();
    if (status === 200) {
      setListaPlano(data);
    } else if (status === 500) {
      setCallbackDuration(3000);
      setCallbackErrorList(["Erro interno no servidor. Por favor, contate o suporte."]);
      setCallbackMessage('Erro!');
      setCallbackShown(true);
    } else if (status === 400) {
      let errors = [];
      setCallbackErrorList(errors.concat(Object.values(data?.error)));
      setCallbackMessage('Erro!')
      setCallbackShown(true);
    } else {
      setCallbackMessage('Erro!');
      setCallbackErrorList(["Houve um erro ao selecionar o plano."])
      setCallbackShown(true);
    }
    setLoading(false);

  }, [selecionaPlanoServico]);

  useEffect(() => {
    updateListaPlano();
  }, [updateListaPlano]);

  const handleClose = (event, reason) => {
    if(callbackErrorList.length === 0) history.push("/home");

    if (reason === 'clickaway') {
      setCallbackShown(false);
    }

    if (reason === 'timeout') {
      setCallbackShown(false);
    }
  }

  const handleClick = async idPlano => {
    setLoading(true);

    const response = await assinaPlano(idPlano);

    if (response.status === 200 || response.status === 201) {
      if(response.data.msg === "Definido para o plano gratuíto.") {
        setCallbackDuration(3000);
        setCallbackErrorList([]);
        setCallbackMessage("Definido para o plano gratuito.")
        setCallbackShown(true);        
       }

      else history.push(`/fatura-boleto/${response.data.fatura_id}/${response.data.boleto_id}`, {
        goToHome: true,
      })
    } else if (response.status === 500) {
      setCallbackDuration(3000);
      setCallbackErrorList(["Erro interno no servidor. Por favor, contate o suporte."]);
      setCallbackMessage('Erro!');
      setCallbackShown(true);
    } else if (response.status === 400) {
      let errors = [];
      setCallbackErrorList(errors.concat(Object.values(response?.data?.error)));
      setCallbackMessage('Erro!')
      setCallbackShown(true);
    } else {
      setCallbackMessage('Erro!');
      setCallbackErrorList(["Houve um erro ao selecionar o plano."])
      setCallbackShown(true);
    }

    setLoading(false);
  };

  return (
    <>
      <div className="escolhe-planos-container">
          {!loading && (
          <div className="escolhe-planos-header">
            <h1>Escolha o melhor plano para você</h1>
            <div className="escolhe-planos-content">
              <Grid
                container
                spacing={2}
                style={{ paddingLeft: 4 }}
                // alignItems="center"
              >
                {listaPlano.map((plano, index) => (
                  <React.Fragment key={index}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      // style={{ alignItems: "center" }}
                    >
                      <CardPlano
                        key={plano.id_plano}
                        data={plano}
                        cbButton={handleClick}
                      />
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </div>
          </div>
          )}
      </div>
      {loading && <OverlayLoading />}
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        type={callbackErrorList.length !== 0 ? 'error' : 'success'}
        message={callbackMessage}
      />
    </>
  );
}

export default EscolhePlanos;
