import { useRef, useReducer } from 'react'

function usePersistedReducer(reducer, defaultState, storageKey) {
  const isLoading = useRef(true)
  if (isLoading.current) {
    try {
      const persisted = JSON.parse(localStorage.getItem(storageKey))
      defaultState = persisted
    } catch (e) {
      console.warn(
        `Failed to load state '${storageKey}' from localStorage; using default state instead`
      )
    }
    isLoading.current = false
  }

  const [state, dispatch] = useReducer(reducer, defaultState)

  try {
    localStorage.setItem(storageKey, JSON.stringify(state))
  } catch (e) {
    console.warn(`Failed to store updated state '${storageKey}'`)
  }

  return [state, dispatch]
}

export default usePersistedReducer
