import { useCallback, useReducer } from 'react';
import useLocallyPersistedReducer from '../hooks/useLocallyPersistedReducer';

export const initialState = {
  caixa: {
    pass: false,
  },
  identificador: {
    pass: false,
  },
  produtos: {
    pass: false,
  },
  predanfe: {
    pass: false,
  },
  danfe: {
    pass: false,
  },
};

const usePDV = () => {
  const PDVReducer = (state, action) => {
    switch (action.type) {
      case '@SET':
        const { field, value } = action.payload;

        // if (oldFieldValues) {
        //   console.log(
        //     'oldFieldValuesoldFieldValuesoldFieldValues',
        //     oldFieldValues
        //   );
        // }

        return {
          ...state,
          [field]: { ...value },
        };
      case '@RESET_BOX_OPEN':
        const { caixa, ...oldState } = state;

        let newState = {
          ...initialState,
          caixa: caixa,
        };

        return { ...state, ...newState };
      case '@RESET':
        return { ...state, ...initialState };
      default:
        return state;
    }
  };

  const [state, dispatch] = useLocallyPersistedReducer(
    PDVReducer,
    initialState,
    'contabilhub@formpdv'
  );

  const onFinish = useCallback(() => {
    dispatch({ type: '@RESET' });
  }, [dispatch]);

  const onChange = useCallback(
    (field, value) => {
      dispatch({ type: '@SET', payload: { field, value } });
    },
    [dispatch]
  );

  const onResetWithBoxOpen = useCallback(() => {
    dispatch({ type: '@RESET_BOX_OPEN' });
  }, [dispatch]);

  const onChangeInput = useCallback(
    (event) => {
      dispatch({
        type: 'set',
        payload: { field: event.target.name, value: event.target.value },
      });
    },
    [dispatch]
  );

  return {
    state,
    onChange,
    onChangeInput,
    onFinish,
    onResetWithBoxOpen,
  };
};

export default usePDV;
