import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from 'react-router-dom';

import TableComponent from "../../components/TableComponent";
import OverlayLoading from "../../components/OverlayLoading";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";

import { numberToCurrency } from "../../utils/functions";

import imoveisService from "../../services/imoveisService";

function ListagemImoveis() {
  const history = useHistory();
  const [listRequest, setListRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const { consultaListaImovel } = imoveisService;

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Id do Imóvel", dataRef: "id_imovel" },
      { columnName: "Referência", dataRef: "referencia" },
      { columnName: "Cidade", dataRef: "cidade" },
      { columnName: "Bairro", dataRef: "bairro" },
      { columnName: "Valor locação", dataRef: "valor_locacao" },
      { columnName: "Valor venda", dataRef: "valor_venda" },
      { columnName: "Classificação", dataRef: "classificacao" }
    ],
    options: {
      detailTitle: true,
      searchFile: true
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null
  });

  const updateTable = useCallback(async () => {
    setLoading(true);
    const response = await consultaListaImovel(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm
    );
    setLoading(false);
    const formattedData = {
      ...response.data,
      data: response.data.data ? response.data.data.map(item => ({
        ...item,
        id_imovel: `#${item.id_imovel}`,
        valor_condominio: numberToCurrency(item.valor_condominio),
        valor_locacao: numberToCurrency(item.valor_locacao),
        valor_venda: numberToCurrency(item.valor_venda),
        valor_iptu: numberToCurrency(item.valor_iptu)
      })) : response.data.map(item => ({
        ...item,
        id_imovel: `#${item.id_imovel}`,
        valor_condominio: numberToCurrency(item.valor_condominio),
        valor_locacao: numberToCurrency(item.valor_locacao),
        valor_venda: numberToCurrency(item.valor_venda),
        valor_iptu: numberToCurrency(item.valor_iptu)
      }))
    };
    setListRequest(formattedData);
  }, [defaultConfigTable, consultaListaImovel]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  useEffect(() => {
    const newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  const onPageChange = newPageObj => {
    const newPage = newPageObj.page;
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onOrderBy = newOrderBy => {
    const orderBy = newOrderBy.orderBy;
    const orderByType = newOrderBy.orderByType;
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onPerPage = newPerPage => {
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onSearchTerm = value => {
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const handleLookFileClick = id => {
    history.push(`/detalhes-imovel/${id.replace('#', '')}`)
  };

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id_imovel"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbLookFile={handleLookFileClick}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default ListagemImoveis;
