import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import TableComponent from "../../../components/TableComponent";
import ConfirmDialog from "../../../components/ConfirmDialog";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

import clientesService from "../../../services/clientesService";
import { Grid } from "@material-ui/core";
import Select from "../../../components/SelectComponent";
import Button from "../../../components/ButtonComponent";
import classes from "./styles.module.scss";
import exportData from "../../../services/exportData";
import useCompany from "../../../hooks/useCompany";
import { useIsMount } from "../../../hooks/useIsMount";

function ClientesLista() {
  const history = useHistory();

  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("clientes");
  const isMount = useIsMount();

  const [listRequest, setListRequest] = useState([]);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_cliente" },
      { columnName: "CNPJ/CPF", dataRef: "cnpj_cpf" },
      { columnName: "Razão Social/Nome", dataRef: "nome_razao_social" },
    ],
    options: {
      edit: true,
      delete: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      filter: false,
      export: false,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-excel",
        },
        {
          icon: "export",
          label: "Exportar PDF",
          action: "exportar-pdf",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    id_empresa: null,
  });

  const exportToPDF = {
    url: "exportar-cliente-pdf",
    fileName: "clientes",
    fileType: "pdf",
  };

  const exportToXLS = {
    url: "exportar-cliente-excel",
    fileName: "clientes",
    fileType: "xlsx",
  };

  let updateTable = useCallback(async () => {
    setLoading(true);
    const result = await clientesService.getClientes(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.id_empresa
    );
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.status === 200) {
      if (!result.data.hasOwnProperty("data")) {
        let list = result.data;
        result.data.data = list;
      }
      setListRequest(result.data);
    }
    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
  ]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onEdit = (id) => {
    history.push(`/editar-cliente/${id}`);
  };

  let handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  let handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  let onDelete = async () => {
    setLoading(true);
    await clientesService.deleteCliente(deleteId).then((response) => {
      if (response.status === 200) {
        let newDefaultConfigTable = { ...defaultConfigTable };
        if (
          newDefaultConfigTable.currentPage > 1 &&
          listRequest?.data?.length === 1
        ) {
          newDefaultConfigTable.currentPage =
            newDefaultConfigTable.currentPage - 1;
          setDefaultConfigTable(newDefaultConfigTable);
        } else {
          updateTable();
        }
      }
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      } else if (response.status !== 200) {
        if (response.data.error) setErrors(Object.values(response.data.error));
        else setErrors(["Ocorreu um erro"]);
      }
      setShowMessage(true);
    });
    setLoading(false);
    handleRemoveCancel();
  };

  function handleClose(event, reason) {
    setShowMessage(false);
  }

  const handleSelectStatus = async (id) => {
    console.log(id);
    setEmpresaSelecionada(id);
  };

  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      return setDefaultConfigTable({
        ...defaultConfigTable,
        currentPage: 1,
        id_empresa: empresaStorage,
      });
    }
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_empresa: empresaSelecionada,
    });
    setEmpresaPagina("clientes", empresaSelecionada);
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
    });
    setEmpresaPagina("clientes", null);
  };

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                      ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={handleSelectStatus}
            />
          </Grid>
          <Grid className={classes.marginLeftAuto}>
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  const onTableMoreAction = useCallback(
    async ({ action }) => {
      console.log(action);
      // return;
      let aux = {};
      if (action === "exportar-excel") {
        aux = {
          url: exportToXLS.url,
          fileName: exportToXLS.fileName,
          fileType: exportToXLS.fileType,
          params: {
            ...exportToXLS.params,
            order_by: defaultConfigTable.orderBy,
            order_by_type: defaultConfigTable.orderByType,
            id_empresa: defaultConfigTable.id_empresa,
          },
        };
      } else if (action === "exportar-pdf") {
        aux = {
          url: exportToPDF.url,
          fileName: exportToPDF.fileName,
          fileType: exportToPDF.fileType,
          params: {
            ...exportToPDF.params,
            order_by: defaultConfigTable.orderBy,
            order_by_type: defaultConfigTable.orderByType,
            id_empresa: defaultConfigTable.id_empresa,
          },
        };
      }
      const response = await exportData(aux);
    },
    [
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.id_empresa,
    ]
  );

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("clientes");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id_cliente"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onEdit}
        cbDelete={handleRemoveDialog}
        filterComponent={filterComponent()}
        cbTableMoreAction={onTableMoreAction}
        // exportPDF={exportToPDF}
        // exportXLS={exportToXLS}
      />
      <CallbackMessage
        duration={4000}
        open={showMessage}
        errorList={errors}
        message={errors.length === 0 ? "Cliente excluído com sucesso." : "Erro"}
        type={errors.length === 0 ? "success" : "error"}
        handleClose={handleClose}
      />
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default ClientesLista;
