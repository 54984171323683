import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import ButtonComponent from "../../../components/ButtonComponent";

import CallbackMessage from "../../../components/CallbackMessage";
import OverlayLoading from "../../../components/OverlayLoading";

import { FormControlLabel, Grid, useMediaQuery } from "@material-ui/core";

import SelectComponent from "../../../components/SelectComponent";
import selectService from "../../../services/selectServices";
import DatePicker from "../../../components/DatePicker";
import Input from "../../../components/Input";
import preDemissaoService from "../../../services/dpPreDemissaoService";
import validateFields from "../../../utils/validateFields";
import styles from "./styles.module.scss";
import InputTime from "../../../components/InputTime";
import Icon from "../../../components/Icon";
import SwitchComponent from "../../../components/Switch";
import useCompany from "../../../hooks/useCompany";
import { useIsMount } from "../../../hooks/useIsMount";

export function CadastroPreDemissao() {
  const isMobile = useMediaQuery("(max-width: 1100px)");

  const {
    companyList: listEmpresa,
    selectedCompany: idEmpresa,
    setSelectedCompany: setIdEmpresa,
    getEmpresaPagina,
  } = useCompany("pre_demissao");
  const isMount = useIsMount();

  const history = useHistory();
  const locationState = history.location.state;
  console.log({ locationState });
  const { cadastrarPreDemissao, consultaPreDemissao, editarPreDemissao } =
    preDemissaoService;
  const {
    selecionaFuncionariosDp,
    selecionaDocumentoPreDemissaoDp,
    selecionaEmissorPreDemissaoDp,
    selecionaMotivoPreDemissaoDp,
    selecionaEventoFolhaDp,
  } = selectService;

  const [listFuncionarios, setListFuncionarios] = useState([]);
  const [listTipoDocumento, setListTipoDocumento] = useState([]);
  const [listEmissorDocumento, setListEmissorDocumento] = useState([]);
  const [listMotivo, setListMotivo] = useState([]);
  const [listEvento, setListEvento] = useState([]);

  const [loading, setLoading] = useState(false);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const params = useParams();
  const { id } = params;

  const [idFuncionario, setIdFuncionario] = useState(null);
  const [idTipoDocumento, setIdTipoDocumento] = useState(null);
  const [idEmissorDocumento, setIdEmissorDocumento] = useState(null);
  const [idMotivo, setIdMotivo] = useState(null);
  const [isPrevia, setIsPrevia] = useState(0);
  const [eventos, setEventos] = useState([
    {
      id_evento_folha: null,
      quantidade: null,
      mascara: "",
    },
  ]);
  const [quantidade, setQuantidade] = useState(null);

  const [data, setData] = useState(null);
  // FIM CAMPOS PARA O SERVICE

  const [isLoadingFuncionarios, setIsLoadingFuncionarios] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const loadFuncionarios = async (empresa) => {
    setIsLoadingFuncionarios(true);
    await selecionaFuncionariosDp(empresa)
      .then((response) => {
        setIsLoadingFuncionarios(false);
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        if (response.status === 200) setListFuncionarios(response.data);
      })
      .catch(() => {
        setIsLoadingFuncionarios(false);
      });
  };

  const loadDocumentos = async () => {
    await selecionaDocumentoPreDemissaoDp().then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) setListTipoDocumento(response.data);
    });
  };

  const loadEmissor = async () => {
    await selecionaEmissorPreDemissaoDp().then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      console.log(response);
      if (response.status === 200) setListEmissorDocumento(response.data);
    });
  };

  const loadMotivo = async () => {
    await selecionaMotivoPreDemissaoDp().then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) setListMotivo(response.data);
    });
  };

  const loadEventos = async () => {
    await selecionaEventoFolhaDp().then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) setListEvento(response.data);
    });
  };

  const handleChangeEmpresa = (id) => {
    loadFuncionarios(id);
  };

  function handleClose(event, reason) {
    console.log("there");
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/pre-demissao");
    }
  }

  async function handleSubmit() {
    const fields = [
      {
        label: "empresaDp",
        value: idEmpresa,
      },
      {
        label: "funcionario",
        value: idFuncionario,
      },
      {
        label: "data",
        value: data,
      },
    ];

    if (!isPrevia) {
      fields.push(
        {
          label: "documentoDp",
          value: idTipoDocumento,
        },
        {
          label: "emissorDocumentoDp",
          value: idTipoDocumento,
        }
      );
    }

    const fieldsErros = validateFields(fields);

    if (!isPrevia) {
      if (eventos.filter((item) => !item.deleted)?.length === 0) {
        fieldsErros.push("Adicione no mínimo 1 evento");
      } else {
        let erroIdEvento = false;
        let erroIdQuantidade = false;

        eventos
          .filter((item) => !item.deleted)
          ?.map((item) => {
            if (!item.id_evento_folha) {
              erroIdEvento = true;
            }
            if (!item.quantidade) {
              erroIdQuantidade = true;
            }
          });

        if (erroIdEvento) {
          fieldsErros.push("Preencha o campo Evento");
        }

        if (erroIdQuantidade) {
          fieldsErros.push("Preencha o campo Quantidade");
        }
      }
    }

    if (fieldsErros.length !== 0) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(fieldsErros);
      return;
    }
    setLoading(true);
    let response = null;
    if (id) {
      response = await editarPreDemissao({
        previa: isPrevia,
        idPreDemissao: id,
        empresaDp: idEmpresa,
        funcionario: idFuncionario,
        documentoDp: idTipoDocumento,
        emissorDocumentoDp: idTipoDocumento,
        data,
        motivo: idMotivo,
        eventos: isPrevia ? null : eventos,
      });
    } else {
      response = await cadastrarPreDemissao({
        previa: isPrevia,
        empresaDp: idEmpresa,
        funcionario: idFuncionario,
        documentoDp: idTipoDocumento,
        emissorDocumentoDp: idTipoDocumento,
        data,
        motivo: idMotivo,
        eventos: isPrevia ? null : eventos,
      });
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 201 || response.status === 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage(
        response?.data?.message ||
          `Pré-demissão ${id ? "atualizada" : "cadastrada"} com sucesso!`
      );
      return;
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage(response?.data?.msg || "Erro!");

      if (response?.data?.error && Array.isArray(response?.data?.error)) {
        let errorTemp = [];
        response?.data?.error?.map((item) => {
          errorTemp.push(...Object.values(item));
        });
        setCallbackErrorList(errorTemp);
      } else if (typeof response.data.error === "string") {
        setCallbackErrorList([response.data.error]);
      } else if (
        response?.data?.error &&
        !Array.isArray(response?.data?.error)
      ) {
        setCallbackErrorList(Object.values(response.data.error));
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
    }
  }

  const loadPreDemissao = async () => {
    setLoading(true);
    try {
      const response = await consultaPreDemissao(id);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      console.log(response);
      if (response.status === 201 && response?.data?.pre_demissao) {
        console.log("there");
        setIdEmpresa(response.data.pre_demissao.id_empresa);
        setIdFuncionario(response.data.pre_demissao.id_funcionario);
        setIdTipoDocumento(response.data.pre_demissao.id_documento);
        setIdEmissorDocumento(response.data.pre_demissao.id_emissor);
        setData(response.data.pre_demissao.data);
        setIdMotivo(response.data.pre_demissao.id_motivo);
        setIsPrevia(response.data.pre_demissao.previa ? 1 : 0);
        if (response.data.pre_demissao.eventos?.length > 0) {
          updatePreDemissaoEventosMascara(response.data.pre_demissao.eventos);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangeEventos = ({ index, field, value }) => {
    let novosEventos = [...eventos];
    novosEventos = novosEventos.map((item, i) => {
      if (i === index) {
        let mascara = item.mascara;
        if (field === "id_evento_folha") {
          let novaMascara = listEvento.find((item) => item.id === value)?.tipo;
          console.log({ novaMascara });
          if (novaMascara === "valor") {
            mascara = "dinheiro";
          } else if (!novaMascara) {
            mascara = "texto";
          } else {
            mascara = novaMascara;
          }
        }
        return {
          ...item,
          mascara,
          updated: 1,
          [field]: value,
        };
      } else {
        return item;
      }
    });
    setEventos(novosEventos);
  };

  const addEvento = () => {
    let novosEventos = [...eventos];
    novosEventos.push({
      id_evento_folha: null,
      quantidade: null,
      mascara: "",
    });
    setEventos(novosEventos);
  };

  const removeEvento = (index) => {
    let novosEventos = [];
    eventos.forEach((item, i) => {
      if (i === index) {
        if (item.id) {
          novosEventos.push({
            ...item,
            deleted: 1,
          });
        }
      } else {
        novosEventos.push(item);
      }
    });

    setEventos(novosEventos);
  };

  const updatePreDemissaoEventosMascara = (preDemissaoEventos) => {
    try {
      let novosEventos = [...preDemissaoEventos];
      novosEventos = novosEventos.map((item, i) => {
        console.log(item);
        let novaMascara = listEvento.find(
          (itemList) => itemList.id === item.id_evento_folha
        )?.tipo;
        let mascara = novaMascara;
        if (novaMascara === "valor") {
          mascara = "dinheiro";
        } else if (!novaMascara) {
          mascara = "texto";
        }

        return {
          ...item,
          mascara,
        };
      });
      setEventos(novosEventos);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    loadDocumentos();
    loadEmissor();
    loadMotivo();
    loadEventos();
  }, []);

  useEffect(() => {
    if (id && listEvento.length) {
      loadPreDemissao();
    } else if (locationState?.funcionarioSelecionado) {
      setIdFuncionario(locationState.funcionarioSelecionado.idFuncionario);
      setIdEmpresa(locationState.funcionarioSelecionado.idEmpresa);
      loadFuncionarios(locationState.funcionarioSelecionado.idEmpresa);
    }
  }, [id, listEvento]);

  useEffect(() => {
    if (!isMount) {
      if (!id) {
        const empresaStorage = getEmpresaPagina("pre_demissao");
        if (empresaStorage) {
          handleChangeEmpresa(empresaStorage);
        }
      }
    }
    if (idEmpresa) {
      handleChangeEmpresa(idEmpresa);
    }
  }, [idEmpresa]);

  return (
    <div>
      <div className={`session-container ${styles.sessionContainer}`}>
        <span className="session-container-header">
          <form className="session-container-form">
            {loading && <OverlayLoading />}
            <div className={`${isMobile ? "" : "p-horizontal"}`}>
              <Grid container spacing={2}>
                <Grid item xs={12} className="container-input-switch">
                  Prévia da demissão
                  <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                    Não
                  </Grid>
                  <Grid item style={{ marginLeft: 12 }}>
                    <FormControlLabel
                      className={styles.controlLabel}
                      style={{ marginBottom: 0 }}
                      control={
                        <SwitchComponent
                          checked={isPrevia}
                          onClick={() => setIsPrevia(isPrevia ? 0 : 1)}
                          name="optantePorAdiantamento"
                          disabled={locationState?.details}
                        />
                      }
                    />
                  </Grid>
                  <Grid item style={{ marginLeft: -8 }}>
                    Sim
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <SelectComponent
                    styleType="form"
                    label="Empresa"
                    title="Selecione uma opção"
                    list={listEmpresa}
                    initialValue={
                      listEmpresa.find((item) => item.id === idEmpresa)
                        ? listEmpresa.find((item) => item.id === idEmpresa).nome
                        : ""
                    }
                    required
                    callback={(id) => setIdEmpresa(id)}
                    isDisabled={locationState?.details}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Funcionário"
                    title="Selecione uma opção"
                    list={listFuncionarios}
                    loading={isLoadingFuncionarios}
                    initialValue={
                      listFuncionarios.find((item) => item.id === idFuncionario)
                        ? listFuncionarios.find(
                            (item) => item.id === idFuncionario
                          ).nome
                        : ""
                    }
                    required
                    callback={(id) => setIdFuncionario(id)}
                    isDisabled={locationState?.details}
                  />
                </Grid>
                {!isPrevia && (
                  <>
                    <Grid item xs={12} md={6}>
                      <SelectComponent
                        styleType="form"
                        label="Documento"
                        title="Selecione uma opção"
                        list={listTipoDocumento}
                        initialValue={
                          listTipoDocumento.find(
                            (item) => item.id === idTipoDocumento
                          )
                            ? listTipoDocumento.find(
                                (item) => item.id === idTipoDocumento
                              ).nome
                            : ""
                        }
                        required
                        callback={(id) => setIdTipoDocumento(id)}
                        isDisabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SelectComponent
                        styleType="form"
                        label="Emissor"
                        title="Selecione uma opção"
                        list={listEmissorDocumento}
                        initialValue={
                          listEmissorDocumento.find(
                            (item) => item.id === idEmissorDocumento
                          )
                            ? listEmissorDocumento.find(
                                (item) => item.id === idEmissorDocumento
                              ).nome
                            : ""
                        }
                        required
                        callback={(id) => setIdEmissorDocumento(id)}
                        isDisabled={locationState?.details}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Data*"
                    name="data"
                    initDate={data ? data : ""}
                    handleChange={(date) => setData(date)}
                    value={data}
                    required
                    readOnly={locationState?.details}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <SelectComponent
                    styleType="form"
                    label="Motivo"
                    title="Selecione uma opção"
                    list={listMotivo}
                    initialValue={
                      listMotivo.find((item) => item.id === idMotivo)
                        ? listMotivo.find((item) => item.id === idMotivo).nome
                        : ""
                    }
                    callback={(id) => setIdMotivo(id)}
                    isDisabled={locationState?.details}
                  />
                </Grid>

                {!isPrevia &&
                  eventos.map((evento, index) => {
                    return !evento.deleted ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className={`${styles.mtAuto} ${styles.containerEvento}`}
                        >
                          <SelectComponent
                            styleType="form"
                            label="Evento"
                            title="Selecione um evento"
                            list={listEvento}
                            initialValue={
                              listEvento.find(
                                (item) => item.id === evento.id_evento_folha
                              )
                                ? listEvento.find(
                                    (item) => item.id === evento.id_evento_folha
                                  ).nome
                                : ""
                            }
                            required
                            callback={(id) => {
                              handleChangeEventos({
                                index,
                                field: "id_evento_folha",
                                value: id,
                              });
                            }}
                            isDisabled={locationState?.details}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className={`${styles.positionRelative} ${styles.containerEvento}`}
                        >
                          {evento.mascara === "hora" ? (
                            <div
                              className={`${styles.containerInputTime} label`}
                            >
                              Quantidade *
                              <InputTime
                                tipo="hhmm"
                                value={evento.quantidade}
                                disabled={locationState?.details}
                                handleInputChange={(e) =>
                                  handleChangeEventos({
                                    index,
                                    field: "quantidade",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                          ) : (
                            <Input
                              label="Quantidade"
                              name="quantidade"
                              tipo={evento.mascara}
                              value={evento.quantidade}
                              required
                              handleInputChange={async (e) => {
                                if (evento.mascara === "dinheiro") {
                                  handleChangeEventos({
                                    index,
                                    field: "quantidade",
                                    value: e.value,
                                  });
                                } else {
                                  handleChangeEventos({
                                    index,
                                    field: "quantidade",
                                    value: e.target.value,
                                  });
                                }
                              }}
                              disabled={locationState?.details}
                            />
                          )}
                          {eventos.length - 1 === index ? (
                            <div
                              className={styles.containerIcon}
                              onClick={addEvento}
                            >
                              <Icon name="add-square" size={25} />
                            </div>
                          ) : (
                            <div
                              className={`${styles.containerIcon} ${styles.remover}`}
                              onClick={() => removeEvento(index)}
                            >
                              <Icon name="remover" size={20} />
                            </div>
                          )}
                        </Grid>
                      </>
                    ) : null;
                  })}
              </Grid>
            </div>
          </form>
        </span>
      </div>
      <Grid item container justifyContent="flex-end" spacing={4}>
        <Grid item {...(isMobile ? { xs: 12 } : {})}>
          <ButtonComponent
            text="Salvar"
            className={`btn-success w-full ${styles.btnSalvar}`}
            onClick={() => handleSubmit()}
          />
        </Grid>
      </Grid>
      {loading && <OverlayLoading />}
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        handleClose={handleClose}
        message={callbackMessage}
        duration={callbackDuration}
        errorList={callbackErrorList}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </div>
  );
}
