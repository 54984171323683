import React, { useState, useEffect, useCallback } from "react";

import { useHistory } from "react-router-dom";
import moment from "moment/moment";

import TableComponent from "../../components/TableResponsiveComponent";
import ConfirmDialog from "../../components/ConfirmDialog";
import ModalContas from "../../components/ModalContas";
import OverlayLoading from "../../components/OverlayLoading";
import ModalRegistrarRecebePaga from "../../components/ModalRegistraRecebePaga";
import CallbackMessage from "../../components/CallbackMessage";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import DatePicker from "../../components/DatePicker";
import { base64ToFile } from "../../utils/functions";

import recebimentosService from "../../services/recebimentosService.js";
import contasBancariasService from "../../services/contasBancariasService";

import { formatDate } from "../../utils/dates";
import { numberToCurrency } from "../../utils/functions";

import { Grid } from "@material-ui/core";
import Select from "../../components/SelectComponent";
import Button from "../../components/ButtonComponent";
import notasFiscaisService from "../../services/notasFiscaisService";
import clientesService from "../../services/clientesService";

import "./styles.css";
import { ModalImportarXlsx } from "../../components/ModalImportarXlsx";
import arquivoModeloRecebimento from "../../assets/downloads/documento_padrao_importar_recebimento.xlsx";
import { useIsMount } from "../../hooks/useIsMount";
import useCompany from "../../hooks/useCompany";

function RecebimentosCadastrados() {
  const history = useHistory();

  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("recebimentos");
  const isMount = useIsMount();

  const { selecionaConta } = contasBancariasService;

  const {
    listarRecebimentos,
    consultaRecebimento,
    registrarRecebimento,
    cancelarRecebimento,
    removerRecebimento,
    exportarRelatorio,
    importarRecebimento,
    estornarRecebimento,
  } = recebimentosService;

  const { consultaPDFInvoisys } = notasFiscaisService;

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const recebimento = "Recebimento";
  const [modalRegistrarData, setModalRegistrarData] = useState({
    id: null,
    data: null,
    valor: null,
    conta: "",
    contaValue: "",
    multaJuros: null,
    abatimentosDescontos: null,
  });

  const [loading, setLoading] = useState(false);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [confirmEstornoDialog, setConfirmEstornoDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [estornoId, setEstornoId] = useState(null);

  const [openModalRecebimento, setOpenModalRecebimento] = useState(false);
  const [openModalImportar, setOpenModalImportar] = useState(false);

  const [registrarCancelar, setRegistrarCancelar] = useState("");
  const [dataConsultaRecebimento, setDataConsultaRecebimento] = useState();
  const [openModalRegistrar, setOpenModalRegistrar] = useState(false);

  const [listContas, setListContas] = useState([]);

  const [listRequest, setListRequest] = useState([]);
  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);
  const [callbackShownModal, setCallbackShownModal] = useState(false);
  const [callbackErrorListModal, setCallbackErrorListModal] = useState([]);

  const [listClientes, setListClientes] = useState([]);
  const [listStatus, setListStatus] = useState([]);

  const [initialDate, setInitialDate] = useState(
    moment().subtract(12, "month").format("YYYY-MM-DD")
  );
  const [finalDate, setFinalDate] = useState(moment().format("YYYY-MM-DD"));
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [statusSelecionado, setStatusSelecionado] = useState(null);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_lancamento" },
      { columnName: "Empresa", dataRef: "razao_social" },
      { columnName: "Data emissão", dataRef: "data_emissao" },
      { columnName: "Data vencimento", dataRef: "data_referencia" },
      { columnName: "Valor", dataRef: "valor" },
      { columnName: "Cliente", dataRef: "nome_cliente" },
      { columnName: "Conta", dataRef: "conta_corrente" },
      { columnName: "Data recebimento", dataRef: "data_pagamento" },
      { columnName: "Multa/ Juros", dataRef: "multa_juros" },
      { columnName: "Abatimentos/ Descontos", dataRef: "abatimento_desconto" },
      { columnName: "Valor recebimento", dataRef: "valor_pagamento" },
    ],
    options: {
      money: true,
      estorno: true,
      more: [
        { icon: "edit", label: "Editar", action: "editar" },
        { icon: "download", label: "Baixar arquivo", action: "baixar-arquivo" },
        { icon: "delete", label: "Excluir", action: "excluir" },
      ],
    },
    text: {
      more: "Editar, baixar arquivo ou excluir",
      money: "Registrar recebimento",
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-xls",
        },
        {
          icon: "download",
          label: "Importar XLS",
          action: "importar-xls",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: "data_referencia",
    orderByType: "desc",
    perPage: null,
    searchTerm: null,
    id_empresa: null,
    id_cliente: null,
    status: null,
    data_inicial: moment().subtract(12, "month").format("YYYY-MM-DD"),
    data_final: moment().format("YYYY-MM-DD"),
  });

  const updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await listarRecebimentos(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.id_empresa,
      defaultConfigTable.id_cliente,
      defaultConfigTable.data_inicial,
      defaultConfigTable.data_final,
      defaultConfigTable.status
    );
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    const resultData = result.data;

    if (resultData.hasOwnProperty("data")) {
      const { data } = resultData;
      const newData = data.map((item) => {
        return {
          ...item,
          id: item.id_lancamento,
          id2: item.id_empresa,
          valor: numberToCurrency(item.valor),
          valor_pagamento: numberToCurrency(item.valor_pagamento),
          multa_juros: numberToCurrency(item.multa_juros),
          abatimento_desconto: numberToCurrency(item.abatimento_desconto),
          data_referencia: formatDate(item.data_referencia),
          data_emissao: formatDate(item.data_emissao),
          data_pagamento: formatDate(item.data_pagamento),
        };
      });
      const newResult = {
        ...resultData,
        data: newData,
      };
      setListRequest(newResult);
    } else {
      const newData = resultData.map((item) => {
        return {
          ...item,
          id: item.id_lancamento,
          id2: item.id_empresa,
          valor: numberToCurrency(item.valor),
          valor_pagamento: numberToCurrency(item.valor_pagamento),
          data_referencia: formatDate(item.data_referencia),
          multa_juros: numberToCurrency(item.multa_juros),
          abatimento_desconto: numberToCurrency(item.abatimento_desconto),
          data_emissao: formatDate(item.data_emissao),
          data_pagamento: formatDate(item.data_pagamento),
        };
      });
      const newResult = {
        ...resultData,
        data: newData,
      };
      setListRequest(newResult);
    }

    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
    defaultConfigTable.id_cliente,
    defaultConfigTable.data_inicial,
    defaultConfigTable.data_final,
    defaultConfigTable.status,
    listarRecebimentos,
  ]);

  const getClientsList = useCallback(async (idEmpresa) => {
    const response = await clientesService.selecionaCliente(idEmpresa);
    if (response.status === 200) {
      setListClientes(response.data);
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
  }, []);

  const onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  const handleEstornoDialog = (id) => {
    setEstornoId(id);
    setConfirmEstornoDialog(true);
  };

  const handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  const handleEstornoCancel = () => {
    setEstornoId(null);
    setConfirmEstornoDialog(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const response = await removerRecebimento(deleteId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setCallbackMessage("Removido com sucesso!");
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackShown(true);
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
    setLoading(false);
    handleRemoveCancel();
  };

  const onEstorno = async () => {
    console.log({ estornoId });
    setLoading(true);
    const response = await estornarRecebimento(estornoId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setCallbackMessage(response.data.message || "Estornado com sucesso!");
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackShown(true);

      updateTable();
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
    setLoading(false);
    handleEstornoCancel();
  };

  const onClip = async (id) => {
    setLoading(true);
    const row = listRequest?.data?.find((item) => item.id === Number(id));
    const idNotaFiscal = row?.id_nota_fiscal;
    if (idNotaFiscal) {
      const response = await consultaPDFInvoisys(Number(idNotaFiscal));
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }

      const file = response.data.pdf;

      if (!file) {
        setCallbackMessage("Erro!");
        setCallbackType("error");
        setCallbackDuration(6000);
        setCallbackErrorList([
          "Arquivo não encontrado, ou não enviado pelo usuário.",
        ]);
        setCallbackShown(true);
      } else {
        base64ToFile(
          "data:application/pdf;base64," + response.data.pdf,
          "nota_fiscal"
        );
        setLoading(false);
      }
    } else {
      const response = await consultaRecebimento(id);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }

      const file = response.data.arquivo;

      if (!file) {
        setCallbackMessage("Erro!");
        setCallbackType("error");
        setCallbackDuration(6000);
        setCallbackErrorList([
          "Arquivo não encontrado, ou não enviado pelo usuário.",
        ]);
        setCallbackShown(true);
      } else {
        const downloadFile = base64ToFile(file, "anexo");
        setLoading(false);
        return downloadFile;
      }

      setLoading(false);
    }
  };

  const onMoney = async (responseObj) => {
    const responseData = JSON.parse(responseObj);
    setLoading(true);
    const response = await selecionaConta(responseData.id2);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListContas(response.data);

    const paymentData = await consultaRecebimento(responseData.id);
    if (paymentData.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const { data } = paymentData;

    setModalRegistrarData({
      id: responseData.id,
      data: data.data_pagamento,
      valor: data.valor_pagamento,
      conta: data.conta_corrente,
      contaValue: data.nome,
      multaJuros: data.multa_juros,
      abatimentosDescontos: data.abatimento_desconto,
    });

    if (data.data_pagamento && data.valor_pagamento) {
      setRegistrarCancelar("cancelar");
    } else {
      setRegistrarCancelar("registrar");
    }

    setLoading(false);

    setOpenModalRegistrar(true);
  };

  const handleVerFatura = (faturaId, boletoId) => {
    history.push(`/fatura-boleto/${faturaId}/${boletoId}`);
  };

  const resetDataConsultaRecebimento = {
    nomeEmpresa: "",
    nomeCliente: "",
    dataPrev: "",
    categoria: "",
    valorPrev: "",
    conta: "",
    descricao: "",
    dataRealizada: "",
    valorRealizado: "",
    faturaId: null,
    boletoId: null,
    codigoBarras: null,
  };

  const handleOpenModalRecebimento = async (id) => {
    setLoading(true);
    const result = await consultaRecebimento(id);

    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const data = result.data;
    setLoading(false);
    if (data) {
      setDataConsultaRecebimento({
        idRecebimento: id,
        conta: data.nome,
        dataRealizada: data.data_pagamento,
        dataPrev: data.data_referencia,
        dataEmissaoPrev: data.data_emissao,
        categoria: data.categoria,
        nomeEmpresa: data.razao_social,
        nomeCliente: data.nome_cliente,
        valorPrev: data.valor,
        valorRealizado: data.valor_pagamento,
        faturaId: data.fatura_id,
        boletoId: data.boleto_id,
        descricao: data.descricao,
        codigoBarras: data.codigo_de_barras,
      });
      setOpenModalRecebimento(true);
    }
  };

  const closeModalRecebimento = (success = false) => {
    setOpenModalRecebimento(false);
    setDataConsultaRecebimento(resetDataConsultaRecebimento);
    if (success) {
      updateTable();
    }
  };

  async function handleCloseModal(res) {
    setLoading(true);
    if (res === undefined) {
      setOpenModalRegistrar(false);
      setLoading(false);
      return;
    }
    if (registrarCancelar === "cancelar") {
      const response = await cancelarRecebimento(modalRegistrarData.id);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        setCallbackMessage("Recebimento cancelado com sucesso!");
        setCallbackType("success");
        setCallbackDuration(3000);
        setCallbackShown(true);
      }
    }
    if (registrarCancelar === "registrar") {
      const response = await registrarRecebimento(
        modalRegistrarData.id,
        res.data,
        res.valor,
        res.conta,
        res.multaJuros,
        res.abatimentosDescontos
      );
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
    }
    updateTable();
    setOpenModalRegistrar(false);
    setLoading(false);
  }

  const onTableMoreAction = async (action) => {
    console.log(action);

    if (action?.action === "exportar-xls") {
      setLoading(true);

      const response = await exportarRelatorio(
        defaultConfigTable.orderBy,
        defaultConfigTable.orderByType,
        defaultConfigTable.searchTerm,
        defaultConfigTable.id_empresa,
        defaultConfigTable.id_cliente,
        defaultConfigTable.data_inicial,
        defaultConfigTable.data_final,
        defaultConfigTable.status
      );
      if (response.status === 200) {
        base64ToFile(
          response.data.arquivo,
          "cbhub_relatorio_recebimentos",
          "xlsx"
        );
      } else if (response.status === 500) {
        setCallbackErrorList([
          "Erro interno no servidor. Por favor, contate o suporte",
        ]);
        setCallbackShown(true);
      } else {
        setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
        setCallbackShown(true);
      }
      setLoading(false);
    } else if (action?.action === "importar-xls") {
      setOpenModalImportar(true);
    }
  };

  const handleSelectEmpresa = async (id) => {
    setEmpresaSelecionada(id);
    getClientsList(id);
  };

  const handleSelectClient = async (id) => {
    setClienteSelecionado(id);
  };

  const handleSelectStatus = async (id) => {
    setStatusSelecionado(id);
  };

  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      return setDefaultConfigTable({
        ...defaultConfigTable,
        currentPage: 1,
        id_empresa: empresaStorage,
      });
    }
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_empresa: empresaSelecionada,
      id_cliente: clienteSelecionado,
      status: statusSelecionado,
      data_inicial: initialDate,
      data_final: finalDate,
      status: statusSelecionado,
    });
    setEmpresaPagina("recebimentos", empresaSelecionada);
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setClienteSelecionado(null);
    setStatusSelecionado(null);
    setInitialDate(moment().subtract(12, "months").format("YYYY-MM-DD"));
    setFinalDate(moment().format("YYYY-MM-DD"));
    setListClientes([]);
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
      id_cliente: null,
      data_inicial: moment().subtract(12, "months").format("YYYY-MM-DD"),
      data_final: moment().format("YYYY-MM-DD"),
      status: null,
    });
    setEmpresaPagina("recebimentos", null);
  };

  const onImport = async ({ arquivo, idEmpresa }) => {
    console.log({ arquivo, idEmpresa });
    let errorList = [];
    if (!idEmpresa) {
      errorList.push("É necessário informar a empresa");
    }
    if (!arquivo) {
      errorList.push("É necessário selecionar um arquivo");
    }
    if (errorList.length > 0) {
      setCallbackErrorListModal(errorList);
      setCallbackShownModal(true);
      return;
    }

    setLoading(true);
    const response = await importarRecebimento({
      arquivo,
      id_empresa: idEmpresa,
    });
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setOpenModalImportar(false);
      setCallbackMessage(response.data?.msg || "Importado com sucesso!");
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackShown(true);
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
    } else {
      setCallbackShownModal(true);
      if (response.data.error)
        setCallbackErrorListModal(Object.values(response.data.error));
      else setCallbackErrorListModal(["Ocorreu um erro ao importar"]);
    }
    setLoading(false);
    handleRemoveCancel();
  };

  const getStatusList = async () => {
    const response = await recebimentosService.selecionaStatusRecebimento();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setListStatus(
        Object.entries(response.data).map((key) => ({
          id: key[0],
          nome: key[1],
        }))
      );
    }
  };

  const handleInitialDateChange = (date) => {
    setInitialDate(date);
    setDefaultConfigTable({
      ...defaultConfigTable,
      data_inicial: date,
    });
  };

  const handleFinalDateChange = (date) => {
    setFinalDate(date);
    setDefaultConfigTable({
      ...defaultConfigTable,
      data_final: date,
    });
  };

  const onMoreAction = async (action) => {
    console.log(action);

    switch (action.action) {
      case "editar":
        handleOpenModalRecebimento(action.id);
        break;
      case "baixar-arquivo":
        onClip(action.id);
        break;
      case "excluir":
        handleRemoveDialog(action.id);
        break;
      default:
        break;
    }
  };

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                      ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={handleSelectEmpresa}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DatePicker
              label="Vencimento De *"
              handleChange={(value) => handleInitialDateChange(value)}
              initDate={moment(initialDate).format("YYYY-MM-DD")}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DatePicker
              label="Vencimento Até *"
              handleChange={(value) => handleFinalDateChange(value)}
              initDate={moment(finalDate).format("YYYY-MM-DD")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              styleType="form"
              label="Cliente"
              // required
              initialValue={
                clienteSelecionado !== undefined
                  ? listClientes.find((item) => item.id === clienteSelecionado)
                      ?.nome
                  : ""
              }
              title="Selecione um cliente"
              list={listClientes}
              callback={handleSelectClient}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              styleType="form"
              label="Status do agendamento"
              // required
              initialValue={
                statusSelecionado !== undefined
                  ? listStatus.find((item) => item.id === statusSelecionado)
                      ?.nome
                  : ""
              }
              title="Selecione um status"
              list={listStatus}
              callback={handleSelectStatus}
            />
          </Grid>

          <Grid className="marginLeftAuto">
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);
  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    getStatusList();
  }, []);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("recebimentos");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbMoreAction={onMoreAction}
        cbMoney={onMoney}
        cbTableMoreAction={onTableMoreAction}
        cbEstorno={handleEstornoDialog}
        filterComponent={filterComponent()}
        pageId="lista-recebimentos"
      />
      {openModalRecebimento && (
        <ModalContas
          tipoRecebimentoPagamento={recebimento}
          cbVerFatura={handleVerFatura}
          dataModal={dataConsultaRecebimento}
          open={openModalRecebimento}
          onClose={closeModalRecebimento}
        />
      )}
      {openModalRegistrar && (
        <ModalRegistrarRecebePaga
          open={openModalRegistrar}
          onClose={(res) => handleCloseModal(res)}
          listaContas={listContas}
          tipoRecebimentoPagamento={recebimento}
          tipoRegistrarCancelar={registrarCancelar}
          data={modalRegistrarData.data}
          conta={modalRegistrarData.conta}
          valor={modalRegistrarData.valor}
          initConta={modalRegistrarData.contaValue}
          multaJuros={modalRegistrarData.multaJuros}
          abatimentosDescontos={modalRegistrarData.abatimentosDescontos}
        />
      )}
      <ModalImportarXlsx
        open={openModalImportar}
        onClose={() => setOpenModalImportar(false)}
        title="IMPORTAR RECEBIMENTOS EM LOTE"
        acceptLabel="Importar"
        onAccept={onImport}
        callbackErrorListModal={callbackErrorListModal}
        callbackShownModal={callbackShownModal}
        setCallbackShown={setCallbackShownModal}
        arquivoPadrao={arquivoModeloRecebimento}
      />
      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={() => setCallbackShown(false)}
      />
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />

      <ConfirmDialog
        open={confirmEstornoDialog}
        onCancel={handleEstornoCancel}
        onAccept={onEstorno}
        description="Você tem certeza que deseja realizar o estorno?"
        title="CONFIRMAR ESTORNO"
        acceptLabel="Confirmar"
      />

      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default RecebimentosCadastrados;
