import React, { useState, useEffect, useCallback } from "react";

import OverlayLoading from "../../../components/OverlayLoading";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

import "./styles.scss";
import { versaoService } from "../../../services/versaoService";
import { Grid, useMediaQuery } from "@material-ui/core";
import ButtonComponent from "../../../components/ButtonComponent";
import { base64ToFile } from "../../../utils/functions";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

const porPagina = 6;

const LoadingPdf = () => {
  return (
    <div className="loadingPdf"></div>
  )
}

export function ListaVersoes() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(false);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [listVersoes, setListVersoes] = useState([]);
  const [listVersoesCompleta, setListVersoesCompleta] = useState([]);
  const { listaVersoes, consultaVersao } = versaoService;

  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    console.log({ numPages });
    setNumPages(numPages);
  }
  const buscaVersoes = useCallback(async () => {
    try {
      setLoading(true);
      const result = await listaVersoes();
      if (result.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (result?.status === 200) {
        if (result.data?.versoes?.length > 0) {
          const versao = result.data?.versoes[0];
          const topicos = versao?.descricao_topicos || [];
          setSelectedTopics(topicos);
          const splitNomeVersao = result.data?.versoes?.map((item) => {
            const nomeSplit = item.nome.split(".");
            const mes = `${nomeSplit[0]}.${nomeSplit[1]}`;
            const identificador = nomeSplit[2];
            return {
              ...item,
              mes,
              identificador,
            };
          });
          console.log({ splitNomeVersao });

          const versoesAgrupadas = splitNomeVersao.reduce((groups, item) => {
            const { mes } = item;
            if (!groups[mes]) {
              groups[mes] = [];
            }
            groups[mes].push(item);
            return groups;
          }, {});

          console.log({ versoesAgrupadas });

          const itensPaginados = {};
          Object.keys(versoesAgrupadas).forEach((item, index) => {
            if (index < paginaAtual * porPagina) {
              itensPaginados[item] = versoesAgrupadas[item];
            }
          });

          setListVersoesCompleta(versoesAgrupadas);
          setListVersoes(itensPaginados);
          setSelectedVersion(versao);
        } else {
          setSelectedVersion(null);
          setListVersoes([]);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  async function onSelect(versao) {
    console.log({ versao });
    if (versao.id_versao === selectedVersion.id_versao) return;

    setLoading(true);
    const response = await consultaVersao(versao.id_versao);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200 && response?.data?.versao) {
      console.log(response.data);
      const versaoSelecionada = response.data.versao;
      setSelectedVersion(versaoSelecionada);
      setSelectedTopics(versaoSelecionada.descricao_topicos);
    }
    setLoading(false);
  }

  function onDownload() {
    console.log({ selectedVersion });
    base64ToFile(selectedVersion.arquivo, selectedVersion.nome, "pdf");
  }

  function onViewMore() {
    console.log("here");
    setPaginaAtual(paginaAtual + 1);
    const itensPaginados = {};
    Object.keys(listVersoesCompleta).forEach((item, index) => {
      if (index < (paginaAtual + 1) * porPagina) {
        itensPaginados[item] = listVersoesCompleta[item];
      }
    });
    console.log({ itensPaginados });
    setListVersoes(itensPaginados);
  }

  useEffect(() => {
    buscaVersoes();
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <div className="session-container containerPageNovidades">
        <Grid
          container
          className="containerVersoes"
          direction={isMobile ? "row-reverse" : "row"}
        >
          <Grid item md={8} xs={12}>
            {selectedVersion && (
              <div className="containerPdf">
                <Document
                  file={selectedVersion?.arquivo}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={LoadingPdf}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              </div>
              // <iframe src={selectedVersion?.arquivo} title="Pdf Versão" />
            )}
          </Grid>
          <Grid
            container
            item
            md={4}
            xs={12}
            direction="column"
            className="columnVersoes"
          >
            {selectedTopics?.length ? (
              <div className="cardVersao">
                {selectedTopics &&
                  selectedTopics.map((item, index) => (
                    <div key={`item_${index}`}>{item}</div>
                  ))}
              </div>
            ) : null}
            {Object.keys(listVersoes).length ? (
              <div className="cardVersao">
                {Object.keys(listVersoes).map((mesVersao) => {
                  const versoes = listVersoes[mesVersao];
                  console.log({ versoes });
                  return (
                    <Grid container item key={`item_${versoes[0].id_versao}`}>
                      <Grid item className="mesVersao" xs={6}>
                        {mesVersao}
                      </Grid>
                      <Grid
                        item
                        container
                        xs={6}
                        className="containerNomeVersao"
                      >
                        {versoes.map((item, indexVersao) => (
                          <div
                            className="nomeVersao"
                            onClick={() => onSelect(item)}
                            key={indexVersao.toString()}
                          >
                            {item.nome}
                          </div>
                        ))}
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item>
                  <div className="verAnteriores" onClick={onViewMore}>
                    Ver Anteriores
                  </div>
                </Grid>
              </div>
            ) : null}
            {selectedVersion && (
              <div className="botaoDownload">
                <ButtonComponent
                  text="Download PDF"
                  icone="download"
                  className="btn-success"
                  onClick={onDownload}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
