import React, { useState } from 'react';
import ButtonComponent from '../../components/ButtonComponent';
import ConfirmDialog from '../../components/ConfirmDialog';
import './styles.css';

function ViewConfirmDialog() {
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(true);

  function handleOpenConfirmDialog() {
    setConfirmRemoveDialog(true);
  }

  function handleRemoveCancel() {
    setConfirmRemoveDialog(false);
  }

  function handleRemoveAccept() {
    window.alert('REMOVER');
  }

  return (
    <>
      <div className="session-container">
        <span className="session-container-header">
          <div className="teste-confirmDialog-container">
            <ButtonComponent
              text="Abrir confirmar exclusão"
              className="flat-default"
              onClick={handleOpenConfirmDialog}
            />
          </div>
        </span>
      </div>
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
      />
    </>
  );
}

export default ViewConfirmDialog;
