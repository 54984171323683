import React, { useState } from "react";
import InputFile from "../../components/InputFile";
import Anexos from "../../components/Anexos";
import "./styles.css";

function ViewInputFile() {
  const [valueInput, setValueInput] = useState("");
  return (
    <div className="container">
      {/* <InputFile
        onChange={(value) => {
          setValueInput(value);
        }}
        title="Certificado"
      /> */}
      <Anexos />
    </div>
  );
}

export default ViewInputFile;
