import React from "react";
import PropTypes from "prop-types";
import { makeStyles, CircularProgress } from "@material-ui/core";

const styles = () => ({
  root: ({ transparency }) => ({
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 999999999,
    backgroundColor: `rgba(255, 255, 255, ${transparency})`,
  }),
  progress: ({ size }) => ({
    color: "#F58466",
    position: "fixed",
    top: "50%",
    left: "50%",
    marginTop: (size / 2) * -1,
    marginLeft: (size / 2) * -1,
  }),
});

const useStyles = makeStyles(styles);

const OverlayLoading = ({ size, transparency }) => {
  const classes = useStyles({ size, transparency });
  return (
    <div className={classes.root}>
      <CircularProgress className={classes.progress} size={size} />
    </div>
  );
};

OverlayLoading.propTypes = {
  size: PropTypes.number,
  transparency: PropTypes.number,
};

OverlayLoading.defaultProps = {
  transparency: 0.5,
  size: 60,
};

export default OverlayLoading;
