import React, { useState, useEffect, useRef } from "react";
import { removerAcentos } from "../../utils/removerAcentos";
import classes from "./styles.module.scss";
import Icon from "../Icon";

function getRandomString() {
  var randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  var result = "";
  for (var i = 0;i < 10;i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
}

function SelectList({
  label = "",
  required = false,
  initialValue = "",
  hint = "",
  callback = () => { },
  list = [], // mandar objeto com image, description, code, type, value e id
  pdvProducts,
}) {
  const [valueSelect, setValueSelect] = useState("");
  const [selectAnimate, setSelectAnimate] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredList, setFilteredList] = useState(list);
  const [id] = useState(getRandomString());
  const [isSelected, setIsSelected] = useState(false);
  const searchFocus = useRef(null);

  let debounceCloseTable = null;

  useEffect(() => {
    if (selectAnimate) searchFocus.current.focus();
  }, [selectAnimate]);

  useEffect(() => {
    if (list.length === 0) setValueSelect("");

    let debounceSearchList = null;

    function handleSearchList(value) {
      clearTimeout(debounceSearchList);

      debounceSearchList = setTimeout(function () {
        if (value === "") {
          setFilteredList(list);
        } else {
          let regex = new RegExp(removerAcentos(value), "gi");
          let temp = [];

          temp = list.filter(element =>
            regex.test(removerAcentos(element.description))
            || regex.test(removerAcentos(element.code))
            || regex.test(removerAcentos(element.ean))
          );

          setFilteredList(temp);
        }
      }, 300);
    }

    handleSearchList(searchInput);
  }, [searchInput, list]);

  useEffect(() => {
    if (initialValue !== "") {
      setValueSelect({ description: initialValue });
    }
  }, [initialValue]);

  function handleCloseTable(inputTable = false) {
    clearTimeout(debounceCloseTable);

    debounceCloseTable = setTimeout(function () {
      if (!inputTable && document.querySelector(`#${id}`)) {
        setSelectAnimate(false);
        document.querySelector(`#${id}`).scrollTo(0, 0);
      }
    }, 150);
  }

  function handleBlur() {
    if (isSelected) {
      setIsSelected(false);
      return;
    }
  }

  const headerSelect = () => (
    <React.Fragment>
      {label !== "" && (
        <label className={classes.formSelectLabel}>
          {label}
          <span className={classes.formRequiredItem}>{required && "*"}</span>
        </label>
      )}
      <span
        className={`${classes.formSelectHint} ${valueSelect !== "" ? classes.formSelectHintHide : ""
          } ${selectAnimate ? classes.formSelectAnimate : ""}`}
      >
        {!selectAnimate && (list.length === 0 || list === null ? "Seleção vazia" : hint)}
      </span>
    </React.Fragment>
  );

  const itemCard = (item, index) => {
    console.log('item???????', item);
    return (
      <div
        key={index}
        className={`${classes.formCardItem} ${classes.formSelectTableListItem}`}
        onMouseDown={() => {
          setIsSelected(true);
          setValueSelect(item);
          callback(item.id);
          handleCloseTable();
        }}
      >
        <img src={item.image} alt="item-list-image" />
        <div className={classes.formCardTitleWrapper}>
          <div>{item.description}</div>
          <div className={classes.formCardSubtitle}>{`Código ${item.code}`}</div>
          <div className={classes.formCardSubtitle}>{`Ean ${item.ean}`}</div>
        </div>
        <div className={classes.formCardDescription}>{item.type}</div>
        <div className={classes.formCardValue}>{pdvProducts ? item?.valorTributario : item.value}</div>
      </div>
    );
  };

  const tableSelect = () => (
    <React.Fragment>
      <div
        className={`
        ${classes.formSelectTableContainer}
        ${selectAnimate && classes.formSelectTableContainerShow}`}
      >
        <form onSubmit={handleCallback} className="here">
          <div className={classes.inputWrapper}>
            <Icon name="search" />
            <input
              ref={searchFocus}
              className={classes.formSelectInput}
              onMouseDown={() => {
                setIsSelected(true);
                handleCloseTable(true);
              }}
              onBlur={() => {
                handleCloseTable(false);
              }}
              onChange={event => {
                setIsSelected(true);
                setSearchInput(event.target.value);
              }}
              disabled={list.length === 0 || list === null ? true : false}
            />
          </div>
        </form>
        <ul className={classes.formSelectTableList} id={id}>
          {filteredList.map((item, index) => itemCard(item, index))}
        </ul>
      </div>
    </React.Fragment>
  );

  const handleCallback = (e) => {
    e.preventDefault();
    const item = filteredList?.find( (a, i) => i === 0)
    setIsSelected(true);
    setValueSelect(item);
    callback(item?.id);
    handleCloseTable();
  }

  return (
    <React.Fragment>
      <div className={classes.formSelectContainer} onBlur={handleBlur}>
        {headerSelect()}
        <div className={`${classes.formSelectInputContainer}`}>
          <div className={classes.inputWrapper}>
            <Icon name="search" />
            <input
              className={`${classes.formSelectInput}`}
              readOnly
              onClick={() => {
                setSelectAnimate(!selectAnimate);
              }}
              value={valueSelect ? valueSelect.description : valueSelect}
              disabled={list.length === 0 || list === null ? true : false}
            />
          </div>
        </div>
        {tableSelect()}
      </div>
    </React.Fragment>
  );
}

export default SelectList;
