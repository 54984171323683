import React from "react";
import SelectComponent from "../../components/SelectComponent";
import "./styles.css";

function ViewSelect() {
  const mock = [
    { id: 1, nome: "Teste 1" },
    { id: 2, nome: "Teste 2" },
    { id: 3, nome: "Teste 3" },
    { id: 4, nome: "Teste 4" },
    { id: 5, nome: "Teste 5" },
    { id: 6, nome: "Teste 6" },
    { id: 7, nome: "Teste 7" },
    { id: 8, nome: "Teste 8" },
    { id: 9, nome: "Teste 9" },
    { id: 10, nome: "Teste 10" },
    { id: 11, nome: "Teste 11" },
  ];

  function getSelectOption(id) {
    console.log("getSelectOption", id);
  }

  return (
    <React.Fragment>
      <div className="session-container">
        <span className="session-container-header">
          <form className="session-container-form">
            <fieldset>
              <SelectComponent
                label="Empresa"
                title="Selecione uma empresa"
                list={mock}
                required
                callback={getSelectOption}
              />
            </fieldset>
            <fieldset>
              <label>Teste</label>
              <input placeholder="Digite aqui" style={{ width: "100%" }} />
            </fieldset>
            <fieldset>
              <SelectComponent
                label="Fornecedores"
                title="Selecione um fornecedor"
                list={mock}
                required
                callback={getSelectOption}
              />
            </fieldset>
            <fieldset>
              <label>Teste</label>
              <input placeholder="Digite aqui" style={{ width: "100%" }} />
            </fieldset>
            <fieldset>
              <SelectComponent
                label="Cliente"
                title="Selecione um cliente"
                list={mock}
                required
                callback={getSelectOption}
              />
            </fieldset>
            <fieldset>
              <label>Teste</label>
              <input placeholder="Digite aqui" style={{ width: "100%" }} />
            </fieldset>
            <fieldset>
              <SelectComponent
                label="Funcionarios"
                title="Selecione um setor"
                list={mock}
                required
                callback={getSelectOption}
              />
            </fieldset>
            <fieldset>
              <label>Teste</label>
              <input placeholder="Digite aqui" style={{ width: "100%" }} />
            </fieldset>
            <fieldset>
              <SelectComponent
                label="Usuarios"
                title="Selecione um usuario"
                list={mock}
                required
                callback={getSelectOption}
              />
            </fieldset>
            <fieldset>
              <label>Teste</label>
              <input placeholder="Digite aqui" style={{ width: "100%" }} />
            </fieldset>
            <fieldset>
              <SelectComponent
                label="Contas a pagar"
                title="Selecione uma conta"
                list={mock}
                required
                callback={getSelectOption}
              />
            </fieldset>
            <fieldset>
              <label>Teste</label>
              <input placeholder="Digite aqui" style={{ width: "100%" }} />
            </fieldset>
            <fieldset>
              <SelectComponent
                label="Certificados"
                title="Selecione um certificado"
                list={mock}
                required
                callback={getSelectOption}
              />
            </fieldset>
          </form>
        </span>
      </div>
    </React.Fragment>
  );
}

export default ViewSelect;
