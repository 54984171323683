import { Dialog, DialogTitle, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import pdvService from '../../../services/pdvService';
import { numberToCurrency } from '../../../utils/functions';
import ButtonComponent from "../../ButtonComponent";
import CallbackMessage from "../../CallbackMessage";
import ModalLoginExpirado from "../../ModalLoginExpirado";
import OverlayLoading from "../../OverlayLoading";
import { dateToDateDD_MM_YYYYandHours } from '../../../utils/dates';
import "./styles.scss";


function ModalDetalhesVenda({
  open,
  onClose,
  onSave,
  idVenda,
}) {
  const { consultaPedido } = pdvService;

  const [dadosPedido, setDadosPedido] = useState({});

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  useEffect(() => {
    if(idVenda && open) {
      loadPedido();
    }
  }, [idVenda, open])

  const loadPedido = async () => {
    setLoading(true);
    const response = await consultaPedido(idVenda);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }

    if (response.status === 400) {
      setErrors(Object.values(response?.data?.error).flat(Infinity));
      setShowMessage(true);
    }

    if (response.status === 500) {
      setErrors(['Erro interno no servidor, contate o suporte.']);
      setShowMessage(true);
    }

    if(response.status === 200 || response.status === 201) {
        setDadosPedido(response?.data);
    }

    setLoading(false);
  };

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }

  const handleCloseModal = () => {
    setShowMessage(false);
    setDadosPedido({});
    onClose();
  }


  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleCloseModal} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>DETALHES DO PEDIDO</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={3}>
              <div className="top-info-details modal-detalhes-venda">
                <div className="wrapper-detail">
              <Grid item xs={12} sm={12} md={6} className="modal-items-detail">
                  <div>
                  <label>ID</label>
                  <span>{dadosPedido?.id_pedido}</span>
                  </div>
               </Grid>
               </div>
                <div className="wrapper-detail">
               <Grid item xs={12} sm={12} md={6} className="modal-items-detail">
                  <div>
                  <label>Quantidade de Itens</label>
                  <span>{dadosPedido?.quantidade_itens}</span>
                  </div>
               </Grid>
               </div>
               <div className="wrapper-detail mt-15">
               <Grid item xs={12} sm={12} md={6} className="modal-items-detail">
                  <div>
                  <label>Valor</label>
                  <span>{numberToCurrency(dadosPedido?.valor_total)}</span>
                  </div>
               </Grid>
               </div>
               <div className="wrapper-detail mt-15">
               <Grid item xs={12} sm={12} md={6} className="modal-items-detail">
                  <div>
                  <label>Número da Nota</label>
                  <span>{dadosPedido?.numero_nota}</span>
                  </div>
               </Grid>
               </div>
               <div className="wrapper-detail mt-15">
               <Grid item xs={12} sm={12} md={6} className="modal-items-detail">
                  <div>
                  <label>Fechamento do pedido</label>
                  <span>{dateToDateDD_MM_YYYYandHours(dadosPedido?.data_finalizacao_pedido)}</span>
                  </div>
               </Grid>
               </div>
               <div className="wrapper-detail">
               
               </div>
               <div className="wrapper-detail mt-15">
                <Grid item xs={12} className="modal-items-detail">
                    <div>
                    <label>Observação</label>
                    <span>{dadosPedido?.observacao}</span>
                    </div>
                </Grid>
               </div>
               </div>

              <Grid item className="container-table-details modal-detalhes-venda">
               <Grid item xs={12} sm={12} md={12}>
              <table className="nota-nfce-table">
                <thead>
                  <tr>
                    <th className="column-head-id">ID</th>
                    <th className="column-numero">Código</th>
                    <th className="column-descricao">Descrição</th>
                    <th className="column-numero">Qtde</th>
                    <th className="column-th-valor">Vl Unit</th>
                    <th className="column-th-valor">Desc</th>
                    <th className="column-th-valor">Vl Trib</th>
                    <th className="column-th-valor">Valor (R$)</th>
                  </tr>
                </thead>
                {dadosPedido?.itens?.length > 0
                  && (
                    <tbody>
                      {dadosPedido?.itens?.map((item, index) => (
                        <tr key={index}>
                          <td className="column-id">
                            <div>
                              {item.id}
                            </div>
                          </td>
                          <td className="column-numero">{item?.codigo}</td>
                          <td className="column-descricao">{item?.descricao}</td>
                          <td className="column-quantidade">{item?.quantidade}</td>
                          <td className="column-td-valor">{numberToCurrency(item.valor_unitario)}</td>
                          <td className="column-td-valor">{numberToCurrency(item.valor_desconto)}</td>
                          <td className="column-td-valor">{numberToCurrency(item.valor_total_tributos)}</td>
                          <td className="column-td-valor">{numberToCurrency(item.valor_total)}</td>
                        </tr>
                      ))}
                    </tbody>
                  )}
              </table>
              {dadosPedido?.itens?.length === 0 &&
                <div className="table-sem-dados"> Nenhum produto adicionado</div>}
            </Grid>
            <Grid item container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={4} md={1} className="nota-nfce-total">
              TOTAL
              </Grid>
              <Grid item xs={12} sm={4} md={2} className="nota-nfce-total">
                {numberToCurrency(dadosPedido?.valor_total)}
              </Grid>
            </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} className="modal-items-detail">
                  <div>
                  <label>Pagamento</label>
                  <span></span>
                  </div>
            </Grid>
             
                <Grid item xs={12} sm={12} md={12} className="info-itens-container pagamentos-container">
                  <div className="info-top-payment">
                  <h3>Valor Pago</h3>
                  <span>{numberToCurrency(dadosPedido?.total_pagamentos)}</span>
                  </div>
                  <div className="info-item-payment item">
                  <h3>Dinheiro</h3>
                  <span>{numberToCurrency(dadosPedido?.pagamento_dinheiro)}</span>
                  </div>
                  <div className="info-item-payment item">
                  <h3>Cartão</h3>
                  <span>{numberToCurrency(dadosPedido?.pagamento_cartao)}</span>
                  </div>
                  { dadosPedido?.parcelas_cartao &&
                    <div className="info-item-payment item">
                      <h3>Quantidade de parcelas</h3>
                      <span>{dadosPedido?.parcelas_cartao}</span>
                    </div>
                  }
                </Grid>

                <Grid item container spacing={1} justify="flex-end">
                <Grid item xs={12} sm={4} md={1} className="nota-nfce-total">
                  TOTAL
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className="nota-nfce-total">
                    {numberToCurrency(dadosPedido?.total_pagamentos)}
                  </Grid>
                </Grid>

              <Grid container justify="flex-end" item>
                <ButtonComponent
                  text="Voltar"
                  onClick={handleCloseModal}
                  className="btn-success zero-margin"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalDetalhesVenda;
