import React, { useState, useEffect, useCallback } from "react";

import TableComponent from "../../components/TableResponsiveComponent";
import ConfirmDialog from "../../components/ConfirmDialog";
import OverlayLoading from "../../components/OverlayLoading";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import ModalContas from "../../components/ModalContas";
import ModalRegistrarRecebePaga from "../../components/ModalRegistraRecebePaga";
import CallbackMessage from "../../components/CallbackMessage";
import DatePicker from "../../components/DatePicker";

import pagamentosService from "../../services/pagamentosService";
import contasBancariasService from "../../services/contasBancariasService";

import { formatDate } from "../../utils/dates";
import { numberToCurrency } from "../../utils/functions";
import { base64ToFile } from "../../utils/functions";

import { Grid } from "@material-ui/core";
import Select from "../../components/SelectComponent";
import Button from "../../components/ButtonComponent";

import "./styles.css";
import notasFiscaisService from "../../services/notasFiscaisService";
import moment from "moment/moment";
import { ModalImportarXlsx } from "../../components/ModalImportarXlsx";
import arquivoModeloPagamento from "../../assets/downloads/documento_padrao_importar_pagamento.xlsx";
import { useIsMount } from "../../hooks/useIsMount";
import useCompany from "../../hooks/useCompany";

function PagamentosCadastrados() {
  const { selecionaConta } = contasBancariasService;
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const {
    listarPagamentos,
    removerPagamento,
    consultaPagamento,
    registrarPagamento,
    cancelarPagamento,
    exportarRelatorio,
    importarPagamento,
    estornarPagamento,
  } = pagamentosService;

  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("pagamentos");
  const isMount = useIsMount();

  const pagamento = "Pagamento";
  const [modalRegistrarData, setModalRegistrarData] = useState({
    id: null,
    data: null,
    valor: null,
    conta: "",
    contaValue: "",
  });

  const [openModalImportar, setOpenModalImportar] = useState(false);

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(4000);
  const [callbackShownModal, setCallbackShownModal] = useState(false);
  const [callbackErrorListModal, setCallbackErrorListModal] = useState([]);

  const [confirmEstornoDialog, setConfirmEstornoDialog] = useState(false);
  const [estornoId, setEstornoId] = useState(null);

  const { downloadPDFNotaEntrada } = notasFiscaisService;

  const [registrarCancelar, setRegistrarCancelar] = useState("");
  const [openModalPagamento, setOpenModalPagamento] = useState(false);
  const [dataConsultaPagamento, setDataConsultaPagamento] = useState();
  const [openModalRegistrar, setOpenModalRegistrar] = useState(false);

  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [listContas, setListContas] = useState([]);

  const [listFornecedor, setListFornecedor] = useState([]);

  const [initialDate, setInitialDate] = useState(
    moment().subtract(12, "month").format("YYYY-MM-DD")
  );
  const [finalDate, setFinalDate] = useState(moment().format("YYYY-MM-DD"));
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);
  const [statusSelecionado, setStatusSelecionado] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listRequest, setListRequest] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_lancamento" },
      { columnName: "Empresa", dataRef: "razao_social" },
      { columnName: "Data emissão", dataRef: "data_emissao" },
      { columnName: "Data vencimento", dataRef: "data_referencia" },
      { columnName: "Valor", dataRef: "valor" },
      { columnName: "Fornecedor", dataRef: "nome_fornecedor" },
      { columnName: "Conta", dataRef: "conta_corrente" },
      { columnName: "Data pagamento", dataRef: "data_pagamento" },
      { columnName: "Multa/ Juros", dataRef: "multa_juros" },
      { columnName: "Abatimentos/ Descontos", dataRef: "abatimento_desconto" },
      { columnName: "Valor pagamento", dataRef: "valor_pagamento" },
    ],
    options: {
      money: true,
      estorno: true,
      more: [
        { icon: "edit", label: "Editar", action: "editar" },
        { icon: "download", label: "Baixar arquivo", action: "baixar-arquivo" },
        { icon: "delete", label: "Excluir", action: "excluir" },
      ],
    },
    text: {
      more: "Editar, baixar arquivo ou excluir",
      money: "Registrar pagamento",
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-xls",
        },
        {
          icon: "download",
          label: "Importar XLS",
          action: "importar-xls",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: "data_referencia",
    orderByType: "desc",
    perPage: null,
    searchTerm: null,
    id_empresa: null,
    id_fornecedor: null,
    data_inicial: moment().subtract(12, "month").format("YYYY-MM-DD"),
    data_final: moment().format("YYYY-MM-DD"),
    status: null,
  });

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await listarPagamentos(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.id_empresa,
      defaultConfigTable.id_fornecedor,
      defaultConfigTable.data_inicial,
      defaultConfigTable.data_final,
      defaultConfigTable.status
    );
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const resultData = result.data;
    if (result?.status === 400) {
      if (typeof resultData?.error === "object") {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(Object.values(resultData?.error).flat(Infinity));
      } else if (typeof resultData?.data?.error === "string") {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList([resultData?.error]);
      }
      return setLoading(false);
    }
    if (resultData.hasOwnProperty("data")) {
      const { data } = resultData;
      const newData = data.map((item) => {
        return {
          ...item,
          id: item.id_lancamento,
          id2: item.id_empresa,
          valor: numberToCurrency(item.valor),
          valor_pagamento: numberToCurrency(item.valor_pagamento),
          data_referencia: formatDate(item.data_referencia),
          multa_juros: numberToCurrency(item.multa_juros),
          abatimento_desconto: numberToCurrency(item.abatimento_desconto),
          data_emissao: formatDate(item.data_emissao),
          data_pagamento: formatDate(item.data_pagamento),
        };
      });
      const newResult = {
        ...resultData,
        data: newData,
      };
      setListRequest(newResult);
    } else {
      const newData = resultData.map((item) => {
        return {
          ...item,
          id: item.id_lancamento,
          id2: item.id_empresa,
          valor: numberToCurrency(item.valor),
          valor_pagamento: numberToCurrency(item.valor_pagamento),
          data_referencia: formatDate(item.data_referencia),
          multa_juros: numberToCurrency(item.multa_juros),
          abatimento_desconto: numberToCurrency(item.abatimento_desconto),
          data_emissao: formatDate(item.data_emissao),
          data_pagamento: formatDate(item.data_pagamento),
        };
      });
      const newResult = {
        ...resultData,
        data: newData,
      };
      setListRequest(newResult);
    }

    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
    defaultConfigTable.id_fornecedor,
    defaultConfigTable.data_inicial,
    defaultConfigTable.data_final,
    defaultConfigTable.status,
    listarPagamentos,
  ]);

  const getStatusList = async () => {
    const response = await pagamentosService.selecionaStatus();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      return;
    }
    if (response.status === 200) {
      const { data } = response;
      setStatusList(
        Object.keys(data).map((key) => ({ id: key, nome: data[key] }))
      );
    }
  };

  const getFornecedorList = useCallback(async (idEmpresa) => {
    const response = await pagamentosService.selecionaFornecedor(idEmpresa);
    if (response.status === 200) {
      setListFornecedor(response.data);
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
  }, []);

  const onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  const handleEstornoDialog = (id) => {
    setEstornoId(id);
    setConfirmEstornoDialog(true);
  };

  const handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  const handleEstornoCancel = () => {
    setEstornoId(null);
    setConfirmEstornoDialog(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const response = await removerPagamento(deleteId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status === 200) {
      setLoading(false);
      handleRemoveCancel();
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage("Pagamento excluído com sucesso!");
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
      return;
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      handleRemoveCancel();
    }
    handleRemoveCancel();
  };

  const onEstorno = async () => {
    console.log({ estornoId });
    setLoading(true);
    const response = await estornarPagamento(estornoId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setCallbackMessage(response.data.message || "Estornado com sucesso!");
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackShown(true);

      updateTable();
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
    setLoading(false);
    handleEstornoCancel();
  };

  const onMoney = async (responseObj) => {
    // let modalData = {};

    const responseData = JSON.parse(responseObj);
    setLoading(true);
    const response = await selecionaConta(responseData.id2);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListContas(response.data);

    const paymentData = await consultaPagamento(responseData.id);
    if (paymentData.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const { data } = paymentData;

    setModalRegistrarData({
      id: responseData.id,
      data: data.data_pagamento,
      valor: data.valor_pagamento,
      conta: data.conta_corrente,
      contaValue: data.nome,
      multaJuros: data.multa_juros,
      abatimentosDescontos: data.abatimento_desconto,
    });

    if (data.data_pagamento && data.valor_pagamento) {
      setRegistrarCancelar("cancelar");
    } else {
      setRegistrarCancelar("registrar");
    }

    setLoading(false);

    setOpenModalRegistrar(true);
  };

  const onClip = async (id) => {
    const idNotaFiscalProdutoEntrada = listRequest?.data?.find(
      (a) => a.id_lancamento == id
    )?.id_nota_fiscal_produto_entrada;

    if (idNotaFiscalProdutoEntrada) {
      const response = await downloadPDFNotaEntrada(idNotaFiscalProdutoEntrada);

      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }

      if (response.status === 400) {
        let errorList = response?.data?.error
          ? typeof response.data.error === "object"
            ? Object.values(response.data.error).flat(Infinity)
            : response?.data?.error?.length > 0
            ? response?.data?.error
            : [response.data?.msg]
          : [response.data?.msg];
        let errorMsg =
          typeof response?.data?.error === "object"
            ? response?.data?.msg
            : "Erro!";
        setCallbackType("error");
        setCallbackMessage(errorMsg);
        setCallbackErrorList(errorList);
        setCallbackDuration(3000);
        setCallbackShown(true);
        setLoading(false);
        return;
      }

      if (response.status === 500) {
        setCallbackType("error");
        setCallbackErrorList(["Erro interno no servidor. Contate o suporte."]);
        setCallbackMessage("Erro!");
        setCallbackShown(true);
        setCallbackDuration(3000);
        setLoading(false);
        return;
      }

      if (response.status === 200 || response.status === 201) {
        base64ToFile(
          "data:application/pdf;base64," + response.data.pdf,
          "nota_fiscal"
        );
      }
    } else {
      const response = await consultaPagamento(id);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      const file = response.data.arquivo;

      if (!file) {
        setCallbackMessage("Erro!");
        setCallbackType("error");
        setCallbackDuration(6000);
        setCallbackErrorList([
          "Arquivo não encontrado, ou não enviado pelo usuário.",
        ]);
        setCallbackShown(true);
      } else {
        const downloadFile = base64ToFile(file, "anexo");
        setLoading(false);
        return downloadFile;
      }
    }

    setLoading(false);
  };

  const resetDataConsultaPagamento = {
    nomeEmpresa: "",
    nomeFornecedor: "",
    dataPrev: "",
    categoria: "",
    valorPrev: "",
    conta: "",
    dataRealizada: "",
    valorRealizado: "",
    codigoBarras: null,
  };

  const handleOpenModalPagamento = async (id) => {
    setLoading(true);
    const result = await consultaPagamento(id);
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const data = result.data;
    setLoading(false);

    if (data) {
      setDataConsultaPagamento({
        idPagamento: id,
        conta: data.nome,
        dataRealizada: data.data_pagamento,
        dataPrev: data.data_referencia,
        dataEmissaoPrev: data.data_emissao,
        categoria: data.categoria,
        nomeEmpresa: data.razao_social,
        nomeFornecedor: data.nome_fornecedor,
        valorPrev: data.valor,
        valorRealizado: data.valor_pagamento,
        descricao: data.descricao,
        codigoBarras: data.codigo_de_barras,
      });
      setOpenModalPagamento(true);
    }
  };

  const closeModalPagamento = (success = false) => {
    setDataConsultaPagamento(resetDataConsultaPagamento);
    setOpenModalPagamento(false);
    if (success) {
      updateTable();
    }
  };

  async function handleCloseModal(res) {
    setLoading(true);
    if (res === undefined) {
      setOpenModalRegistrar(false);
      setLoading(false);
      return;
    }
    if (registrarCancelar === "cancelar") {
      const response = await cancelarPagamento(modalRegistrarData.id);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        setCallbackMessage("Pagamento removido com sucesso!");
        setCallbackType("success");
        setCallbackDuration(3000);
        setCallbackShown(true);
      }
    }
    if (registrarCancelar === "registrar") {
      const response = await registrarPagamento(
        modalRegistrarData.id,
        res.data,
        res.valor,
        res.conta,
        res.multaJuros,
        res.abatimentosDescontos
      );
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
    }
    updateTable();
    setOpenModalRegistrar(false);
    setLoading(false);
  }

  const onTableMoreAction = async (action) => {
    if (action?.action === "exportar-xls") {
      setLoading(true);

      const response = await exportarRelatorio(
        defaultConfigTable.orderBy,
        defaultConfigTable.orderByType,
        defaultConfigTable.searchTerm,
        defaultConfigTable.id_empresa,
        defaultConfigTable.id_fornecedor,
        defaultConfigTable.data_inicial,
        defaultConfigTable.data_final,
        defaultConfigTable.status
      );
      if (response.status === 200) {
        base64ToFile(
          response.data.arquivo,
          "cbhub_relatorio_pagamentos",
          "xlsx"
        );
      } else if (response.status === 500) {
        setCallbackErrorList([
          "Erro interno no servidor. Por favor, contate o suporte",
        ]);
        setCallbackShown(true);
      } else {
        setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
        setCallbackShown(true);
      }

      setLoading(false);
    } else if (action?.action === "importar-xls") {
      setOpenModalImportar(true);
    }
  };

  const handleSelectEmpresa = async (id) => {
    setEmpresaSelecionada(id);
    getFornecedorList(id);
  };

  const handleSelectFornecedor = async (id) => {
    setFornecedorSelecionado(id);
  };

  const handleSelectStatus = async (id) => {
    setStatusSelecionado(id);
  };

  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      return setDefaultConfigTable({
        ...defaultConfigTable,
        currentPage: 1,
        id_empresa: empresaStorage,
      });
    }
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_empresa: empresaSelecionada,
      id_fornecedor: fornecedorSelecionado,
      status: statusSelecionado,
      start_date: initialDate,
      end_date: finalDate,
    });
    setEmpresaPagina("pagamentos", empresaSelecionada);
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setFornecedorSelecionado(null);
    setStatusSelecionado(null);
    setInitialDate(moment().subtract(12, "months").format("YYYY-MM-DD"));
    setFinalDate(moment().format("YYYY-MM-DD"));
    setListFornecedor([]);
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
      id_fornecedor: null,
      status: null,
      start_date: "",
      end_date: "",
    });
    setEmpresaPagina("pagamentos", null);
  };

  const handleInitialDateChange = (value) => {
    setInitialDate(value);
    setDefaultConfigTable({
      ...defaultConfigTable,
      data_inicial: value,
    });
  };

  const handleFinalDateChange = (value) => {
    setFinalDate(value);
    setDefaultConfigTable({
      ...defaultConfigTable,
      data_final: value,
    });
  };

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                      ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={handleSelectEmpresa}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DatePicker
              label="Vencimento De *"
              handleChange={(value) => handleInitialDateChange(value)}
              initDate={initialDate}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DatePicker
              label="Vencimento Até *"
              initDate={finalDate}
              handleChange={(value) => handleFinalDateChange(value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              styleType="form"
              label="Fornecedor"
              // required
              initialValue={
                fornecedorSelecionado !== undefined
                  ? listFornecedor.find(
                      (item) => item.id === fornecedorSelecionado
                    )?.nome
                  : ""
              }
              title="Selecione um fornecedor"
              list={listFornecedor}
              callback={handleSelectFornecedor}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              styleType="form"
              label="Status do agendamento"
              // required
              initialValue={
                statusSelecionado !== undefined
                  ? statusList.find((item) => item.id === statusSelecionado)
                      ?.nome
                  : ""
              }
              title="Selecione um status"
              list={statusList}
              callback={handleSelectStatus}
            />
          </Grid>
          <Grid className="marginLeftAuto">
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  const onImport = async ({ arquivo, idEmpresa }) => {
    console.log({ arquivo, idEmpresa });
    let errorList = [];
    if (!idEmpresa) {
      errorList.push("É necessário informar a empresa");
    }
    if (!arquivo) {
      errorList.push("É necessário selecionar um arquivo");
    }
    if (errorList.length > 0) {
      setCallbackErrorListModal(errorList);
      setCallbackShownModal(true);
      return;
    }

    setLoading(true);
    const response = await importarPagamento({
      arquivo,
      id_empresa: idEmpresa,
    });
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setOpenModalImportar(false);
      setCallbackMessage(response.data?.msg || "Importado com sucesso!");
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackShown(true);
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
    } else {
      setCallbackShownModal(true);
      if (response.data.error)
        setCallbackErrorListModal(Object.values(response.data.error));
      else setCallbackErrorListModal(["Ocorreu um erro ao importar"]);
    }
    setLoading(false);
    handleRemoveCancel();
  };

  const onMoreAction = async (action) => {
    console.log(action);

    switch (action.action) {
      case "editar":
        handleOpenModalPagamento(action.id);
        break;
      case "baixar-arquivo":
        onClip(action.id);
        break;
      case "excluir":
        handleRemoveDialog(action.id);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    getStatusList();
  }, []);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("pagamentos");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}

      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbMoney={onMoney}
        cbEstorno={handleEstornoDialog}
        cbTableMoreAction={onTableMoreAction}
        cbMoreAction={onMoreAction}
        filterComponent={filterComponent()}
        pageId="lista-pagamentos"
      />
      {openModalPagamento && (
        <ModalContas
          tipoRecebimentoPagamento={pagamento}
          dataModal={dataConsultaPagamento}
          open={openModalPagamento}
          onClose={closeModalPagamento}
        />
      )}
      {openModalRegistrar && (
        <ModalRegistrarRecebePaga
          open={openModalRegistrar}
          onClose={(res) => handleCloseModal(res)}
          listaContas={listContas}
          tipoRecebimentoPagamento={pagamento}
          tipoRegistrarCancelar={registrarCancelar}
          data={modalRegistrarData.data}
          conta={modalRegistrarData.conta}
          valor={modalRegistrarData.valor}
          initConta={modalRegistrarData.contaValue}
          multaJuros={modalRegistrarData.multaJuros}
          abatimentosDescontos={modalRegistrarData.abatimentosDescontos}
        />
      )}

      <ModalImportarXlsx
        open={openModalImportar}
        onClose={() => setOpenModalImportar(false)}
        title="IMPORTAR PAGAMENTOS EM LOTE"
        acceptLabel="Importar"
        onAccept={onImport}
        callbackErrorListModal={callbackErrorListModal}
        callbackShownModal={callbackShownModal}
        setCallbackShown={setCallbackShownModal}
        arquivoPadrao={arquivoModeloPagamento}
      />
      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={() => setCallbackShown(false)}
      />
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />

      <ConfirmDialog
        open={confirmEstornoDialog}
        onCancel={handleEstornoCancel}
        onAccept={onEstorno}
        description="Você tem certeza que deseja realizar o estorno?"
        title="CONFIRMAR ESTORNO"
        acceptLabel="Confirmar"
      />

      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default PagamentosCadastrados;
