import React from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import "./styles.scss";

const DadosComplementaresDialog = ({
  open,
  onClose,
  objDadosComplementares: data
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className="complementares-dialog-header">
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <span className="titleConfirmDialog">DADOS COMPLEMENTARES</span>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose} size="medium">
              <CloseIcon className="iconConfirm" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="complementares-dialog-content">
        <Grid item xs={12}>
            <Grid item xs={12}>
              <span className="complementares-dialog-title">
                Status do Cliente
              </span>
            </Grid>
            <Grid item xs={4}>
              {data?.status_cliente === "BLOQUEADO" ? (
                <div className="card-table-danger">BLOQUEADO</div>
                ) : (
                <div className="card-table-success">ATIVO</div>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <span className="complementares-dialog-title">Empresa</span>
            </Grid>
            <Grid item xs={12}>
              <span className="complementares-dialog-text">
                {data?.empresa}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <span className="complementares-dialog-title">Data de Cadastro</span>
            </Grid>
            <Grid item xs={12}>
              <span className="complementares-dialog-text">{data?.data_criacao}</span>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <span className="complementares-dialog-title">CNPJ</span>
            </Grid>
            <Grid item xs={12}>
              <span className="complementares-dialog-text">{data?.cnpj}</span>
            </Grid>
          </Grid>
         
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <span className="complementares-dialog-title">E-mail</span>
            </Grid>
            <Grid item xs={12}>
              <span className="complementares-dialog-text">{data?.email}</span>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} >
            <Grid item xs={12}>
              <span className="complementares-dialog-title">
                Nome do Contato
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className="complementares-dialog-text">
                {data?.nome_contato}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} >
            <Grid item xs={12}>
              <span className="complementares-dialog-title">
                Telefone
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className="complementares-dialog-text">
                {data?.telefone}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <span className="complementares-dialog-title">Plano</span>
            </Grid>
            <Grid item xs={12}>
              <span className="complementares-dialog-text">{data?.plano}</span>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <span className="complementares-dialog-title">Valor</span>
            </Grid>
            <Grid item xs={12}>
              <span className="complementares-dialog-text">{data?.valor}</span>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <span className="complementares-dialog-title">Id da Fatura</span>
            </Grid>
            <Grid item xs={12}>
              <span className="complementares-dialog-text">
                {data?.id_fatura}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <span className="complementares-dialog-title">
                Data de Emissão
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className="complementares-dialog-text">
                {data?.data_emissao}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <span className="complementares-dialog-title">
                Data de Vencimento
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className="complementares-dialog-text">
                {data?.data_vencimento}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <span className="complementares-dialog-title">
                Data de assinatura do contrato
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className="complementares-dialog-text">
                {data?.data_assinatura}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <span className="complementares-dialog-title">
                Data de Pagamento
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className="complementares-dialog-text">
                {data?.data_pagamento}
              </span>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DadosComplementaresDialog;
