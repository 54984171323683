import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ButtonComponent from "../ButtonComponent";
import SelectComponent from "../SelectComponent";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import CallbackMessage from "../CallbackMessage";
import validateFields from "../../utils/validateFields";
import empresasService from "../../services/empresasService";

import "./styles.scss";
import Input from "../Input";

const listDatasPagamento = [
  { id: 1, nome: "Dia 30 de todo mês" },
  { id: 2, nome: "5º dia útil de todo mês" },
];

const listDatasAdiantamento = [
  { id: 1, nome: "Dia 15" },
  { id: 2, nome: "Dia 20" },
];

export function ModalFolha({ open, onClose, idEmpresa, cbTable }) {
  const [dadosFolha, setDadosFolha] = useState({
    sindicato: null,
    cnpj: null,
    dataPagamento: null,
    adiantamento: null,
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  async function handleSubmit() {
    setErrors([]);

    let fields = [
      {
        label: "data_pagamento",
        value: dadosFolha.dataPagamento,
      },
      {
        label: "data_adiantamento",
        value: dadosFolha.adiantamento,
      },
    ];

    const fieldsValidate = validateFields(fields);

    if (fieldsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(fieldsValidate);
      return;
    }

    setLoading(true);

    const response = await empresasService.editarDadosFolha({
      idEmpresa,
      dataPagamento: dadosFolha.dataPagamento,
      adiantamento: dadosFolha.adiantamento,
      sindicato: dadosFolha.sindicato,
      cnpj: dadosFolha.cnpj,
    });

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    console.log({ response });
    if (response.status !== 201 && response.status !== 200) {
      if (response.data.error) {
        setErrors(Object.values(response?.data?.error));
        return console.log("errors:", response?.data?.error);
      } else setErrors(["Ocorreu um erro"]);
    } else if (response.status === 200 || response.status === 201) {
      console.log("here");
      setShowMessage(true);
      setSuccessMessage(
        response.data.message || "Dados da folha atualizados com sucesso!"
      );
    }
    setLoading(false);
  }

  const handleClose = () => {
    // setShowMessage(false);
    console.log("now");
    setShowMessage(false);
    if (errors.length === 0) {
      setDadosFolha({
        sindicato: null,
        cnpj: null,
        dataPagamento: null,
        adiantamento: null,
      });
      onClose();
      cbTable();
    }
  };

  const handleCloseModal = async () => {
    setLoading(false);
    setDadosFolha({
      sindicato: null,
      cnpj: null,
      dataPagamento: null,
      adiantamento: null,
    });
    onClose();
  };

  const handleChangeData = (id) => {
    const newDadosFolha = { ...dadosFolha };
    newDadosFolha.dataPagamento = id;
    newDadosFolha.adiantamento = id;
    setDadosFolha(newDadosFolha);
  };

  const loadDadosFolha = useCallback(async () => {
    const response = await empresasService.bucarDadosFolha(idEmpresa);
    console.log({ response });
    if (response.status === 200) {
      // setListEmpresas(response.data);
      setDadosFolha({
        adiantamento: response.data?.folha?.adiantamento || null,
        cnpj: response.data?.folha?.cnpj || null,
        dataPagamento: response.data?.folha?.data_pagamento || null,
        sindicato: response.data?.folha?.sindicato || null,
      });
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [idEmpresa]);

  useEffect(() => {
    if (open && idEmpresa) {
      loadDadosFolha();
    }
  }, [open, idEmpresa]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={handleCloseModal}
      dialog
    >
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justifyContent="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>Dados da Folha</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Input
                  label="Sindicato"
                  value={dadosFolha.sindicato}
                  handleInputChange={(event) =>
                    setDadosFolha({
                      ...dadosFolha,
                      sindicato: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="CNPJ"
                  tipo="cnpj"
                  value={dadosFolha.cnpj}
                  handleInputChange={(event) =>
                    setDadosFolha({ ...dadosFolha, cnpj: event.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className="select-container">
                  <SelectComponent
                    list={listDatasPagamento}
                    label="Data dos pagamentos"
                    title="Selecione uma opção"
                    required
                    initialValue={
                      listDatasPagamento.find(
                        (item) => item.id === dadosFolha.dataPagamento
                      )
                        ? listDatasPagamento.find(
                            (item) => item.id === dadosFolha.dataPagamento
                          ).nome
                        : ""
                    }
                    callback={(id) => handleChangeData(id)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className="select-container">
                  <SelectComponent
                    list={listDatasAdiantamento}
                    label="Adiantamentos"
                    title="Selecione uma opção"
                    required
                    initialValue={
                      listDatasAdiantamento.find(
                        (item) => item.id === dadosFolha.adiantamento
                      )
                        ? listDatasAdiantamento.find(
                            (item) => item.id === dadosFolha.adiantamento
                          ).nome
                        : ""
                    }
                    isDisabled={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <span className="required-text">* Campos obrigatórios</span>
              </Grid>
              <Grid container justifyContent="flex-end" item>
                <ButtonComponent
                  text="Cancelar"
                  onClick={handleCloseModal}
                  className="btn-secondary-strong btnSize default-outline btn-modal-folha"
                />
                <ButtonComponent
                  text="Salvar"
                  onClick={handleSubmit}
                  className="btn-success btnSize btn-modal-folha"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
