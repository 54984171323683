import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { format } from 'date-fns'

import TableComponent from "../../../components/TableComponent";
import ConfirmDialog from "../../../components/ConfirmDialog";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

import gestaoLeadsService from "../../../services/gestaoLeadsService";

import { base64ToFile } from '../../../utils/functions'

function ListaGestaoLeads() {
  const history = useHistory();
  const [listRequest, setListRequest] = useState([]);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { getListaLeads, removeLead, exportarLeads } = gestaoLeadsService;

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Data", dataRef: "data_hora" },
      { columnName: "Nome do Lead", dataRef: "nome_lead" },
      { columnName: "Portal de origem", dataRef: "portal_origem" },
      { columnName: "Código do Imóvel", dataRef: "codigo_imovel" },
      { columnName: 'Classificação', dataRef: 'classificacao'},
      { columnName: "Corretor Responsável", dataRef: "corretor" },
      { columnName: "Status", dataRef: "status" }
    ],
    options: {
      edit: true,
      delete: true,
    },
    display: {
      statusLabels: true,
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true
    }, 
    tableOptions: {
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar Leads",
          action: "exportar-leads",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null
  });

  const updateTable = useCallback(async () => {
    setLoading(true);
    
    const response = await getListaLeads(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm
      );

      if (response.data.hasOwnProperty("data")) {
        setListRequest(response.data);
      } else {
        setListRequest(response);
      }
      
    setLoading(false);

    
  }, [defaultConfigTable.currentPage,
  defaultConfigTable.orderBy,
  defaultConfigTable.orderByType,
  defaultConfigTable.perPage,
  defaultConfigTable.searchTerm,
    getListaLeads]);

  useEffect(() => {
    updateTable();
  }, []);

  useEffect(() => {
    const newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  const onPageChange = newPageObj => {
    const newPage = newPageObj.page;
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onOrderBy = newOrderBy => {
    const orderBy = newOrderBy.orderBy;
    const orderByType = newOrderBy.orderByType;
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onPerPage = newPerPage => {
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onSearchTerm = value => {
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onEdit = id => {
    console.log(`Edit: ${id}`);
    history.push(`/atualiza-lead/${id}`);
  };

  const handleRemoveDialog = id => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  const handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  const onDelete = async () => {
    console.log(deleteId);
    const response = await removeLead(deleteId);
    console.log(response);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status !== 200) {
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackShown(true);
      if (response.data.error) {
        setCallbackErrorList(Object.values(response.data.error));
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
      setLoading(false);
      handleRemoveCancel();
    }
    if (response.status === 200) {
      setCallbackType("success");
      setCallbackMessage("Lead removido com sucesso!");
      setCallbackDuration(3000);
      setCallbackShown(true);
      setLoading(false);
      handleRemoveCancel();
      await updateTable();
      return;
    }
    handleRemoveCancel();
  };

  const handleTableMoreAction = useCallback(async (option) => {
    if (option.action === 'exportar-leads') {
      setLoading(true)
      
      const response = await exportarLeads()

      setLoading(false)

      if (response.status === 200) {
        let today = new Date();
        
        base64ToFile(`data:text/csv;base64,${response.data?.arquivo}`, `cbhub-leads-${format(today, 't')}`, 'csv')
      }
    }
  }, [])
  
  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id_lead_zap"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onEdit}
        cbDelete={handleRemoveDialog}
        cbTableMoreAction={handleTableMoreAction}
      />
      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={() => setCallbackShown(false)}
      />
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default ListaGestaoLeads;
