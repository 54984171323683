import React, { useState } from "react";
import { useEffect } from "react";
import classes from "./styles.module.scss";

function InputCheck({
  id,
  tipo = "checkbox",
  title = "",
  nome = "",
  onInputChange = () => { },
  required = false,
  checkedValue,
  ...rest
}) {
  const [valueSelect, setValueInput] = useState(false);

  useEffect(() => {
    setValueInput(checkedValue);
  }, [checkedValue]);

  function handleOnInputWrap(event) {
    const isChecked = event.target.checked;
    onInputChange(event);
    setValueInput(isChecked);
  }

  return (
    <React.Fragment>
      <div className={classes.inputContainer}>
        <div className={classes.inputContainer}>
          <input
            id={id}
            type={tipo}
            name={nome}
            checked={valueSelect}
            onChange={(event) => handleOnInputWrap(event)}
            autocomplete="off"
            {...rest}
          />
          <label htmlFor={tipo === "radio" ? id : nome}>{title}</label>
        </div>
      </div>
    </React.Fragment>
  );
}

export default InputCheck;
