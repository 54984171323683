import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InputComponent from "../Input";
import ButtonComponent from "../ButtonComponent";
import SelectComponent from "../SelectComponent";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import CallbackMessage from "../CallbackMessage";
import validateFields from "../../utils/validateFields";
import solicitacaoServicoServices from "../../services/solicitacaoServicoServices";
import planosServices from "../../services/planosServices";

import "./styles.scss";
import SwitchComponent from "../Switch";

const classeStatus = {
  confirmado: "status-confirmado",
  processando: "status-processando",
  recusado: "status-recusado",
};

function ModalDefinirPlano({ open, onClose, idEmpresa, dataModal, cbTable }) {
  const [plano, setPlano] = useState("");
  const [valorPlano, setValorPlano] = useState("");
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listServices, setListServices] = useState([]);
  const [listPlans, setListPlans] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [dataPlan, setDataPlan] = useState({});
  const [habilitarDda, setHabilitarDda] = useState(false);
  const [valorInicialHabilitarDda, setValorInicialHabilitarDda] =
    useState(false);
  const [statusDda, setStatusDda] = useState(null);

  const objectService = {
    id_servico_empresa: "",
    quantidade: "",
    valor_customizado: "",
  };

  const [formService, setFormService] = useState([objectService]);
  const handleDuplicateService = () =>
    setFormService([...formService, objectService]);

  function handleRemoveService(index) {
    formService.splice(index, 1);
    setFormService([...formService]);
  }

  const updateListServices = async () => {
    const response = await solicitacaoServicoServices.selecionaServicoPlano();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const data = response.data;

    console.log("listservices", data);

    setListServices(data);
  };

  const updateListPlans = async () => {
    const response = await planosServices.selecionaPlano();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const data = response.data;

    setListPlans(data);
  };

  useEffect(() => {
    setLoading(true);
    updateListServices();
    updateListPlans();
    setLoading(false);
  }, []);

  async function handleSubmit() {
    setErrors([]);
    let errorsValidate = [];
    if (formService.length === 1) {
      if (formService[0]?.id_servico_empresa !== "") {
        const serviceFields = [
          {
            label: "quantidade",
            value: formService[0].quantidade,
          },
          {
            label: "valorservico",
            value: formService[0].valor_customizado,
          },
        ];
        let serviceErrors = validateFields(serviceFields);

        if (serviceErrors.length !== 0) {
          serviceErrors.map((message) => errorsValidate.push(message));
        }
      } else if (formService[0]?.quantidade !== "") {
        const serviceFields = [
          {
            label: "servico",
            value: formService[0].id_servico_empresa,
          },
          {
            label: "valorservico",
            value: formService[0].valor_customizado,
          },
        ];
        const serviceErrors = validateFields(serviceFields);

        if (serviceErrors.length !== 0) {
          serviceErrors.map((message) => errorsValidate.push(message));
        }
      } else if (formService[0]?.valor_customizado !== "") {
        const serviceFields = [
          {
            label: "quantidade",
            value: formService[0].quantidade,
          },
          {
            label: "servico",
            value: formService[0].id_servico_empresa,
          },
        ];
        const serviceErrors = validateFields(serviceFields);

        if (serviceErrors.length !== 0) {
          serviceErrors.map((message) => errorsValidate.push(message));
        }
      }
    }

    if (formService.length > 1) {
      formService.forEach((service) => {
        const serviceFields = [
          {
            label: "servico",
            value: service.id_servico_empresa,
          },
          {
            label: "quantidade",
            value: service.quantidade,
          },
          {
            label: "valorservico",
            value: service.valor_customizado,
          },
        ];
        const serviceErrors = validateFields(serviceFields);

        if (serviceErrors.length !== 0) {
          serviceErrors.map((message) => errorsValidate.push(message));
        }
      });
    }

    const fields = [
      {
        label: "plano",
        value: plano,
      },
    ];

    const fieldsValidate = validateFields(fields);

    console.log("errors::::", fieldsValidate);

    fieldsValidate.map((message) => errorsValidate.push(message));

    if (errorsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(errorsValidate);
      return;
    }

    setLoading(true);

    const response = await planosServices.criarPlanoCustomizado(
      idEmpresa,
      plano,
      valorPlano !== null || undefined || "" ? valorPlano : undefined,
      formService[0]?.id_servico_empresa !== "" ? formService : [],
      habilitarDda
    );

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status !== 201 && response.status !== 200) {
      if (response.data?.error) {
        setShowMessage(true);
        if (typeof response.data.error === "string") {
          setErrors([response.data.error]);
        } else {
          setErrors(Object.values(response.data.error));
        }
        setLoading(false);
        return;
      } else setErrors(["Ocorreu um erro"]);
    } else if (response.status === 200 || response.status === 201) {
      setSuccessMessage("Plano definido com sucesso!");
    }

    setLoading(false);
    setShowMessage(true);
    cbTable();
  }

  const loadFields = useCallback(async () => {
    if (idEmpresa !== null) {
      await planosServices
        .consultaPlanoCustomizado(idEmpresa)
        .then((response) => {
          if (response.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          }
          if (response.status !== 201 && response.status !== 200) {
            if (response.data.error) {
              setErrors(Object.values(response.data.error));
            } else setErrors(["Ocorreu um erro"]);
            setShowMessage(true);
          } else {
            const { data } = response;

            setDataPlan(data);

            setHabilitarDda(data?.habilitar_dda ? 1 : 0);
            setValorInicialHabilitarDda(data?.habilitar_dda ? 1 : 0);
            setStatusDda(data?.status_dda || null);

            if (data.length !== 0) {
              setPlano(data.id_plano);
              data?.servicos?.length && setFormService(data.servicos);
              setValorPlano(data.valor_customizado);
            } else {
              setPlano("");
              setFormService([objectService]);
              setValorPlano("");
            }
          }
          setLoading(false);
        });
    }
  }, [idEmpresa]);

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  };

  const reload = () => window.location.reload();

  const handleCloseModal = async () => {
    setLoading(true);
    if (dataPlan.length !== 0) {
      setPlano(dataPlan.id_plano);
      if (dataPlan.servicos !== undefined) {
        setFormService(dataPlan.servicos)
      };
      setValorPlano(dataPlan.valor_customizado);
    } else {
      setPlano("");
      setFormService([objectService]);
      setValorPlano("");
    }
    setLoading(false);

    onClose();
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadFields();
      setLoading(false);
    })();
  }, [loadFields, idEmpresa]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleCloseModal}
      dialog
      className="auto-height"
    >
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justifyContent="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>Definir Plano</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
              <Grid
                xs={12}
                md={5}
                item
                container
                spacing={1}
                alignItems="center"
              >
              </Grid>
            </Grid>
            {valorInicialHabilitarDda === 1 && habilitarDda === 0 ? (
              <Grid item xs={12} sm={12} md={8}>
                <Grid item className="aviso-inativar-dda">
                  A ação de desabilitar resultará na exclusão do DDA para a
                  empresa.
                </Grid>
              </Grid>
            ) : null}
            {valorInicialHabilitarDda ? (
              <Grid item xs={12} sm={12} md={8}>
                <Grid item className="label">
                  Status
                </Grid>
                <Grid item className="container-status-dda">
                  <span className={classeStatus[statusDda?.toLowerCase()]}>
                    {statusDda}
                  </span>
                </Grid>
              </Grid>
            ) : null}
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                <div className="select-container">
                  <SelectComponent
                    list={listPlans}
                    label="Selecione o plano"
                    title="Selecione um plano"
                    initialValue={
                      listPlans.find((item) => item.id === plano)
                        ? listPlans.find((item) => item.id === plano).nome
                        : ""
                    }
                    callback={(id) => setPlano(id)}
                    required
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  value={valorPlano? valorPlano : ""}
                  tipo="dinheiro"
                  label="Valor"
                  handleInputChange={(event) => setValorPlano(event.value)}
                />
              </Grid>
            </Grid>
            <div className="info">
              <span>
                Deixe o campo valor em branco caso queira manter o valor do
                plano original
              </span>
            </div>
            <div className="title-services">
              <span>Serviços extras:</span>
            </div>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {formService.map((service, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="select-container">
                        <SelectComponent
                          list={listServices}
                          label="Serviço"
                          title="Selecione um serviço"
                          initialValue={
                            listServices?.find(
                              (item) => item.id == service.id_servico_empresa
                            )
                              ? listServices?.find(
                                  (item) =>
                                    item.id == service.id_servico_empresa
                                ).nome
                              : ""
                          }
                          callback={(id) => {
                            let list = [...formService];
                            list[index].id_servico_empresa = id;
                            setFormService(list);
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <InputComponent
                        value={service.quantidade}
                        tipo="numero"
                        label="Quantidade"
                        handleInputChange={(event) => {
                          let list = [...formService];
                          list[index].quantidade = event.target.value;
                          setFormService(list);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputComponent
                        value={service.valor_customizado}
                        tipo="dinheiro"
                        label="Valor"
                        handleInputChange={(event) => {
                          let list = [...formService];
                          list[index].valor_customizado = event.value;
                          setFormService(list);
                        }}
                      />
                    </Grid>
                    <div className="btnRemove">
                      {index >= 0 && (
                        <ButtonComponent
                          text="- Remover"
                          className="secondary-outline btnAddRemove"
                          onClick={() => handleRemoveService(index)}
                        />
                      )}
                    </div>
                  </React.Fragment>
                ))}
              </Grid>
              <div className="directBtn">
                <ButtonComponent
                  text="+ Adicionar"
                  onClick={() => handleDuplicateService()}
                  className="success-outline btnAddEmpresa"
                />
              </div>
              <Grid item>
                <span className="required-text">* Campos obrigatórios</span>
              </Grid>
              <Grid container justifyContent="flex-end" item>
                <ButtonComponent
                  text="Salvar"
                  onClick={handleSubmit}
                  className="btn-success zero-margin"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalDefinirPlano;
