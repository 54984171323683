import React, { createContext } from 'react';
import useNavbar from '../hooks/useNavbar';

const NavbarContext = createContext();

function NavbarProvider({ children }) {
  const { listNotification, setListNotification, 
    fetchNotification, readNotification, chaveSelected, 
    setChaveSelected, showNavbar, setShowNavbar } = useNavbar();
  
  return (
    <NavbarContext.Provider value={{ 
      listNotification, setListNotification,
      fetchNotification, readNotification,
      chaveSelected, setChaveSelected,
      showNavbar, setShowNavbar
     }}>
      {children}
    </NavbarContext.Provider>
  );
}

export { NavbarContext, NavbarProvider };
