import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import ButtonComponent from "../../../components/ButtonComponent";

import CallbackMessage from "../../../components/CallbackMessage";
import OverlayLoading from "../../../components/OverlayLoading";

import { FormControlLabel, Grid, useMediaQuery } from "@material-ui/core";

import empresasService from "../../../services/empresasService";
import SelectComponent from "../../../components/SelectComponent";
import selectService from "../../../services/selectServices";
import DatePicker from "../../../components/DatePicker";
import Input from "../../../components/Input";
import validateFields from "../../../utils/validateFields";
import styles from "./styles.module.scss";
import SwitchComponent from "../../../components/Switch";
import agendamentoFeriasService from "../../../services/dpAgendamentoFeriasService";
import useCompany from "../../../hooks/useCompany";

export function CadastroAgendamentoFerias() {
  const isMobile = useMediaQuery("(max-width: 1100px)");

  const {
    companyList: listEmpresa,
    selectedCompany: idEmpresa,
    setSelectedCompany: setIdEmpresa,
    getEmpresaPagina,
  } = useCompany("agendamento_ferias");

  const history = useHistory();
  const locationState = history.location.state;

  const { selectEmpresa } = empresasService;
  const { selecionaFuncionariosDp } = selectService;
  const {
    cadastrarAgendamentoFerias,
    consultaAgendamentoFerias,
    listaFeriados,
  } = agendamentoFeriasService;

  const [listFuncionarios, setListFuncionarios] = useState([]);

  const [loading, setLoading] = useState(false);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const params = useParams();
  const { id } = params;

  const [idFuncionario, setIdFuncionario] = useState(null);

  const [periodoFeriasDe, setPeriodoFeriasDe] = useState(null);
  const [periodoFeriasAte, setPeriodoFeriasAte] = useState(null);
  const [pagarAdiantamento, setPagarAdiantamento] = useState(null);
  const [anotacoes, setAnotacoes] = useState(null);
  const [pagarAbono, setPagarAbono] = useState(null);
  const [periodoFeriasDeInvalido, setPeriodoFeriasDeInvalido] = useState(false);
  const [diasAbonoFerias, setDiasAbonoFerias] = useState(null);

  // FIM CAMPOS PARA O SERVICE

  const [isLoadingFuncionarios, setIsLoadingFuncionarios] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const loadFeriados = async (ano, date) => {
    await listaFeriados(ano).then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      console.log(response);
      console.log(date);
      if (response.status === 200) {
        console.log(response.data);
        let hasError = false;
        if (response?.data?.length > 0) {
          response.data.forEach((item) => {
            const itemDate = new Date(item.date);

            // Subtrai 1 dia da data2
            const itemDate1 = new Date(itemDate);
            itemDate1.setDate(itemDate1.getDate() - 1);
            const itemDateString = itemDate.toISOString().split("T")[0];
            const itemDate1String = itemDate1.toISOString().split("T")[0];
            const dateString = date.toISOString().split("T")[0];

            if (
              dateString === itemDateString ||
              dateString === itemDate1String
            ) {
              console.log({
                itemDateString,
                itemDate1String,
                dateString,
              });
              hasError = true;
            }
          });
        }
        if (hasError) {
          setPeriodoFeriasDeInvalido(true);
        }
      }
    });
  };

  const loadFuncionarios = async (empresa) => {
    setIsLoadingFuncionarios(true);
    await selecionaFuncionariosDp(empresa)
      .then((response) => {
        setIsLoadingFuncionarios(false);
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        if (response.status === 200) setListFuncionarios(response.data);
      })
      .catch(() => {
        setIsLoadingFuncionarios(false);
      });
  };

  const handleChangeEmpresa = (id) => {
    setIdEmpresa(id);
    loadFuncionarios(id);
  };

  function handleClose(event, reason) {
    console.log("there");
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/agendamento-de-ferias");
    }
  }

  async function handleSubmit() {
    const fields = [
      {
        label: "empresaDp",
        value: idEmpresa,
      },
      {
        label: "funcionario",
        value: idFuncionario,
      },
      {
        label: "periodo_ferias_de",
        value: periodoFeriasDe,
      },
      {
        label: "periodo_ferias_ate",
        value: periodoFeriasAte,
      },
    ];
    const fieldsErros = validateFields(fields);
    if (periodoFeriasDeInvalido) {
      fieldsErros.push("Data de início de férias inválido");
    }
    if (fieldsErros.length !== 0) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(fieldsErros);
      return;
    }

    setLoading(true);
    const response = await cadastrarAgendamentoFerias({
      empresaDp: idEmpresa,
      funcionario: idFuncionario,
      periodoDe: periodoFeriasDe,
      periodoAte: periodoFeriasAte,
      pagarAdiantamento: pagarAdiantamento ? 1 : 0,
      anotacoes,
      pagarAbono: pagarAbono ? 1 : 0,
      diasAbono: pagarAbono ? diasAbonoFerias?.abono : 0,
      diasFerias: diasAbonoFerias?.ferias,
    });

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 201 || response.status === 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage(
        response?.data?.message ||
          `Agendamento de férias cadastrado com sucesso!`
      );
      return;
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage(response?.data?.msg || "Erro!");

      if (response?.data?.error && Array.isArray(response?.data?.error)) {
        let errorTemp = [];
        response?.data?.error?.map((item) => {
          errorTemp.push(...Object.values(item));
        });
        setCallbackErrorList(errorTemp);
      } else if (typeof response.data.error === "string") {
        setCallbackErrorList([response.data.error]);
      } else if (
        response?.data?.error &&
        !Array.isArray(response?.data?.error)
      ) {
        setCallbackErrorList(Object.values(response.data.error));
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
    }
  }

  const loadAgendamento = async () => {
    setLoading(true);
    try {
      const response = await consultaAgendamentoFerias(id);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      console.log(response);
      if (response.status === 201 && response?.data) {
        console.log("there");
        handleChangeEmpresa(response.data.agendamento.id_empresa);
        setIdFuncionario(response.data.agendamento.id_funcionario);
        setPeriodoFeriasDe(response.data.agendamento.data_inicio);
        setPeriodoFeriasAte(response.data.agendamento.data_fim);
        setPagarAdiantamento(
          response.data.agendamento.adiantar_decimo_terceiro
        );
        setAnotacoes(response.data.agendamento.anotacoes);
        setPagarAbono();
        setPeriodoFeriasDeInvalido(false);
        setDiasAbonoFerias({
          abono: response.data.agendamento.dias_abono,
          ferias: response.data.agendamento.dias_ferias,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangeDateDe = (date) => {
    let dateObj = new Date(`${date} 12:00:00`);

    let dayOfWeek = dateObj.getUTCDay();
    console.log(dayOfWeek); // Output: Friday

    if ([0, 5, 6].includes(dayOfWeek)) {
      setPeriodoFeriasDeInvalido(true);
    } else {
      setPeriodoFeriasDeInvalido(false);
    }
    loadFeriados(dateObj.getFullYear(), dateObj);
    setPeriodoFeriasDe(date);
  };

  useEffect(() => {
    if (id) {
      loadAgendamento();
    } else {
      const empresaStorage = getEmpresaPagina("agendamento_ferias");
      if (empresaStorage) {
        handleChangeEmpresa(empresaStorage);
      }
    }
  }, []);

  useEffect(() => {
    if (periodoFeriasDe && periodoFeriasAte) {
      let dateDe = new Date(periodoFeriasDe);
      let dateAte = new Date(periodoFeriasAte);
      let diffTime = Math.abs(dateAte - dateDe);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
      if (diffDays < 30) {
        setDiasAbonoFerias({
          abono: 30 - diffDays,
          ferias: diffDays,
        });
      } else {
        setDiasAbonoFerias(null);
      }
    }
  }, [periodoFeriasDe, periodoFeriasAte]);

  return (
    <div className={styles.mtDp}>
      <div className={`session-container ${styles.sessionContainer}`}>
        <span className="session-container-header">
          <form className="session-container-form">
            {loading && <OverlayLoading />}
            <div className={`${isMobile ? "" : "p-horizontal"}`}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Empresa"
                    title="Selecione uma opção"
                    list={listEmpresa}
                    initialValue={
                      listEmpresa.find((item) => item.id === idEmpresa)
                        ? listEmpresa.find((item) => item.id === idEmpresa).nome
                        : ""
                    }
                    required
                    callback={(id) => handleChangeEmpresa(id)}
                    isDisabled={locationState?.details || id}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Funcionário"
                    title="Selecione uma opção"
                    list={listFuncionarios}
                    loading={isLoadingFuncionarios}
                    initialValue={
                      listFuncionarios.find((item) => item.id === idFuncionario)
                        ? listFuncionarios.find(
                            (item) => item.id === idFuncionario
                          ).nome
                        : ""
                    }
                    required
                    callback={(id) => setIdFuncionario(id)}
                    isDisabled={locationState?.details || id}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Período de Férias de:*"
                    name="periodoFeriasDe"
                    initDate={periodoFeriasDe ? periodoFeriasDe : ""}
                    handleChange={(date) => handleChangeDateDe(date)}
                    value={periodoFeriasDe}
                    required
                    readOnly={locationState?.details || id}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Período de Férias até:*"
                    name="periodoFeriasat"
                    initDate={periodoFeriasAte ? periodoFeriasAte : ""}
                    handleChange={(date) => setPeriodoFeriasAte(date)}
                    value={periodoFeriasAte}
                    required
                    readOnly={locationState?.details || id}
                  />
                </Grid>
                {periodoFeriasDeInvalido && (
                  <Grid>
                    <div className={styles.warning}>
                      As férias não podem ter início na sexta, sábado, domingo,
                      véspera de feriado e feriado
                    </div>
                  </Grid>
                )}

                {diasAbonoFerias && (
                  <>
                    <Grid item className="container-input-switch" xs={12}>
                      Período de férias inferior a 30 dias! Deseja que os dias
                      restantes sejam levados como Abono?
                      <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                        Não
                      </Grid>
                      <Grid item style={{ marginLeft: 12 }}>
                        <FormControlLabel
                          className={styles.controlLabel}
                          style={{ marginBottom: 0 }}
                          control={
                            <SwitchComponent
                              checked={pagarAbono === 1}
                              onClick={(event) => {
                                setPagarAbono(event.target.checked ? 1 : 0);
                              }}
                              name="pagarAdiantamento"
                              disabled={locationState?.details || id}
                            />
                          }
                        />
                      </Grid>
                      <Grid item style={{ marginLeft: -8 }}>
                        Sim
                      </Grid>
                    </Grid>

                    {pagarAbono && (
                      <Grid item xs={12}>
                        <div className={styles.customLabel}>
                          <div className="label">Dias de abono</div>
                          <label>{diasAbonoFerias?.abono || null}</label>
                        </div>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <div className={styles.customLabel}>
                        <div className="label">Dias de férias</div>
                        <label>{diasAbonoFerias?.ferias || null}</label>
                      </div>
                    </Grid>
                  </>
                )}

                <Grid item className="container-input-switch" xs={12}>
                  Pagar adiamento de 13º
                  <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                    Não
                  </Grid>
                  <Grid item style={{ marginLeft: 12 }}>
                    <FormControlLabel
                      className={styles.controlLabel}
                      style={{ marginBottom: 0 }}
                      control={
                        <SwitchComponent
                          checked={pagarAdiantamento === 1}
                          onClick={(event) => {
                            setPagarAdiantamento(event.target.checked ? 1 : 0);
                          }}
                          name="pagarAdiantamento"
                          disabled={locationState?.details || id}
                        />
                      }
                    />
                  </Grid>
                  <Grid item style={{ marginLeft: -8 }}>
                    Sim
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Input
                    label="Anotações"
                    name="anotacoes"
                    value={anotacoes}
                    required
                    handleInputChange={async (e) => {
                      setAnotacoes(e.target.value);
                    }}
                    disabled={locationState?.details || id}
                  />
                </Grid>
              </Grid>
            </div>
          </form>
        </span>
      </div>
      {!locationState?.details && !id && (
        <Grid item container justifyContent="flex-end" spacing={4}>
          <Grid item {...(isMobile ? { xs: 12 } : {})}>
            <ButtonComponent
              text="Salvar"
              className={`btn-success w-full ${styles.btnSalvar}`}
              onClick={() => handleSubmit()}
            />
          </Grid>
        </Grid>
      )}
      {loading && <OverlayLoading />}
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        handleClose={handleClose}
        message={callbackMessage}
        duration={callbackDuration}
        errorList={callbackErrorList}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </div>
  );
}
