import Axios from "./Axios";

const gestaoLeadsService = {
  getListaLeads: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null
  ) => {
    const response = await Axios.get("/lista-lead", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm
      }
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  consultaLead: async id => {
    const response = await Axios.get(`/consulta-lead/${id}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cadastraInteracao: async (idLead, texto, assunto) => {
    const response = await Axios.post(`/cadastra-interacao/${idLead}`, {
      texto,
      assunto,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  atualizaLead: async (id, form) => {
    const response = await Axios.post(`/atualiza-lead/${id}`, form)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  removeLead: async id => {
    const response = await Axios.get(`/remove-lead/${id}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  exportarLeads: async () => {
    const response = await Axios.get('/exporta-lead')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
};

export default gestaoLeadsService;
