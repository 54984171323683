import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Input from '../../../components/Input';
import Button from '../../../components/ButtonComponent';
import Select from '../../../components/SelectComponent';
import OverlayLoading from '../../../components/OverlayLoading';
import CallbackMessage from '../../../components/CallbackMessage';
import ModalLoginExpirado from '../../../components/ModalLoginExpirado';

import categoriasService from '../../../services/categoriasServices';
import './styles.css';

function EditarCategoria() {
  const history = useHistory();
  const params = useParams();

  const {
    consultarCategoria,
    atualizaCategoria,
    getCategorias
  } = categoriasService;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [categoriasGrupo, setCategoriasGrupo] = useState([]);

  const [empresa, setEmpresa] = useState('');
  const [grupo, setGrupo] = useState('');
  const [tipo, setTipo] = useState('');
  const [nome, setNome] = useState('');
  const [conta, setConta] = useState('');

  const [grupoId, setGrupoId] = useState();
  const [tipoId, setTipoId] = useState();
  const [empresaId, setEmpresaId] = useState();

  const categoriasTipos = [
    { id: 1, nome: 'ENTRADA' },
    { id: 2, nome: 'SAÍDA' }
  ];

  async function loadGruposCategorias() {
    const { id } = params;
    const categoriaInicial = await consultarCategoria(id);
    
    if (categoriaInicial && categoriaInicial.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    const gruposCategoriasList = await getCategorias(categoriaInicial?.data?.id_empresa);

    console.log('categoriaInicial :: ', categoriaInicial);
    console.log('gruposCategoriasList :: ', gruposCategoriasList);

    const { data: gruposCategoriasListData } = gruposCategoriasList;
    const { data: categoriaInicialData } = categoriaInicial;

    const initialData = await gruposCategoriasListData.find(
      item => item.id_empresa === categoriaInicialData.id_empresa
    );

    const gruposList = initialData.grupos.map(item => {
      return {
        id: item.id_grupo,
        nome: item.nome_grupo
      };
    });

    const initialGrupoValue = gruposList.find(
      item => item.id === categoriaInicialData.id_grupo
    );

    console.log('gruposList :: ', gruposList);
    console.log('categoriaInicialData :: ', categoriaInicialData);

    console.log('initialGrupoValue :: ', initialGrupoValue);

    setGrupoId(initialGrupoValue.id);
    setGrupo(initialGrupoValue.nome);
    setTipo(categoriaInicialData.tipo);
    setTipoId(categoriaInicialData.id_tipo_categoria);
    setEmpresa(initialData.razao_social);
    setEmpresaId(initialData.id_empresa);
    setConta(categoriaInicialData.conta_integracao);
    setNome(categoriaInicialData.nome);
    setCategoriasGrupo(gruposList);
  }

  function handleClose(event, reason) {
    setShowMessage(false);
    if (errors.length === 0) {
      history.push('/categorias');
    }
  }

  function verifyField(field, callback) {
    if (field === '' || field === null || field === [] || field === undefined) {
      callback();
    }
  }

  function validateFields() {
    let list = [];
    verifyField(empresa, () =>
      list.push('A Empresa não foi selecionada e é obrigatória.')
    );
    verifyField(grupo, () =>
      list.push('O Grupo não foi selecionado e é obrigatório.')
    );
    verifyField(tipoId, () =>
      list.push('O Tipo de categoria não foi selecionado e é obrigatório')
    );
    verifyField(nome, () => list.push('O Nome da categoria é obrigatório.'));
    setErrors(list);
    if (list.length > 0) setShowMessage(true);
    return list.length === 0;
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      loadGruposCategorias();
      setLoading(false);
    })();
  }, []);

  async function handleSubmit() {
    if (!validateFields()) return;

    const { id } = params;
    const response = await atualizaCategoria(
      id,
      empresaId,
      grupoId,
      nome,
      tipoId,
      conta
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    } else if (response.status !== 200 && response.status !== 201) {
      if (response.data.error) setErrors(Object.values(response.data.error));
      else setErrors('Ocorreu um erro');
    }
    setShowMessage(true);
  }

  return (
    <>
      <div className='session-container user-register-container'>
        <span className='session-container-header'>
          {loading && <OverlayLoading />}
          <form className='category-register-form' onSubmit={handleSubmit}>
            <div className='category-register-form-empresa-input'>
              <Input readOnly label='Empresa' value={empresa} required />
            </div>
            <div className='category-register-form-grupo-input'>
              <Select
                required
                styleType='form'
                label='Grupo'
                list={categoriasGrupo}
                initialValue={grupo}
                callback={element => setGrupoId(element)}
              />
            </div>
            <div className='category-register-form-tipo-input'>
              <Select
                required
                styleType='form'
                search={false}
                label='Tipo de categoria'
                list={categoriasTipos}
                initialValue={tipo}
                callback={element => setTipoId(element)}
              />
            </div>
            <div className='category-register-form-nome-input'>
              <Input
                required
                nome='nome'
                label='Nome da categoria'
                tipo='text'
                value={nome}
                handleInputChange={event => setNome(event.target.value)}
              />
            </div>
            <div className='category-register-form-conta-input'>
              <Input
                nome='conta'
                label='Conta contábil'
                type='text'
                value={conta}
                handleInputChange={event => setConta(event.target.value)}
              />
            </div>
            <div>
              <span className='required-text'>* Campos obrigatórios</span>
            </div>
            {showMessage && (
              <CallbackMessage
                open={showMessage}
                type={`${errors.length === 0 ? 'success' : 'error'}`}
                message={`${
                  errors.length === 0
                    ? 'Categoria editada com sucesso!'
                    : 'Erro!'
                }`}
                errorList={errors}
                handleClose={handleClose}
                duration={errors.length === 0 ? 2000 : 6000}
              />
            )}
          </form>
        </span>
      </div>
      <div className='button-area'>
        <span>
          <Button
            type='button'
            text='Salvar'
            className='btn-success'
            onClick={handleSubmit}
          />
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default React.memo(EditarCategoria);
