import React from 'react';
import Stepper from '../../components/Stepper';
import './styles.css';

const teste1 = () => (
  <>
    <form>
      <input placeholder="teste1"></input>
      <input placeholder="teste1"></input>
      <input placeholder="teste1"></input>
      <input placeholder="teste1"></input>
    </form>
  </>
);
const teste2 = () => (
  <>
    <form>
      <input placeholder="teste2"></input>
      <input placeholder="teste2"></input>
      <input placeholder="teste2"></input>
      <input placeholder="teste2"></input>
    </form>
  </>
);
const teste3 = () => (
  <>
    <form>
      <input placeholder="teste3"></input>
      <input placeholder="teste3"></input>
      <input placeholder="teste3"></input>
      <input placeholder="teste3"></input>
    </form>
  </>
);

function ViewStepper() {
  return (
    <div className="session-container">
      <span className="session-container-header">
        <Stepper
          arraySteps={['teste1', 'teste2', 'teste3']}
          arrayContent={[teste1, teste2, teste3]}
        />
      </span>
    </div>
  );
}

export default ViewStepper;
