import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import Select from "../../components/SelectComponent";
import Button from "../../components/ButtonComponent";
import OverlayLoading from "../../components/OverlayLoading";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import CallbackMessage from "../../components/CallbackMessage";

import configuracoesPlataformaService from "../../services/configuracoesPlataformaService";

import "./styles.css";
import styles from "./styles.module.scss";

import { Grid } from "@material-ui/core";
import SwitchComponent from "../../components/Switch";
import Textarea from "../../components/Textarea";
import Input from "../../components/Input";
import JoditEditor from "jodit-react";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  minHeight: 150,
  toolbar: false,
  showPlaceholder: false
}

export function ConfiguracoesEmailAutomatico() {
  const history = useHistory();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const { getEmailConfig, addEmailConfig } = configuracoesPlataformaService;
  const editorFaturasVencer = useRef(null)
  const editorFaturasVencidas = useRef(null)
  const editorContasPagar = useRef(null)
  const editorObrigacoes = useRef(null)

  const [faturasVencerBloqueadoInativo, setFaturasVencerBloqueadoInativo] = useState(false);
  const [faturasVencidasBloqueadoInativo, setFaturasVencidasBloqueadoInativo] = useState(false);
  const [contasPagarBloqueadoInativo, setContasPagarBloqueadoInativo] = useState(false);
  const [obrigacoesBloqueadoInativo, setObrigacoesBloqueadoInativo] = useState(false);

  const [faturasVencerAssunto, setFaturasVencerAssunto] = useState(null);
  const [faturasVencidasAssunto, setFaturasVencidasAssunto] = useState(null);
  const [contasPagarAssunto, setContasPagarAssunto] = useState(null);
  const [obrigacoesAssunto, setObrigacoesAssunto] = useState(null);

  const [faturasVencerMensagem, setFaturasVencerMensagem] = useState(null);
  const [faturasVencidasMensagem, setFaturasVencidasMensagem] = useState(null);
  const [contasPagarMensagem, setContasPagarMensagem] = useState(null);
  const [obrigacoesMensagem, setObrigacoesMensagem] = useState(null);

  const [loading, setLoading] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackErrors, setCallbackErrorList] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [duration, setCallbackDuration] = useState(6000);

  useEffect(() => {
    (async function loadConfig() {
      setLoading(true);
      const response = await getEmailConfig();
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      const { data } = response;
      if (data?.data) {
        setFaturasVencerBloqueadoInativo(data?.data?.fatura_a_vencer_ativo)
        setFaturasVencidasBloqueadoInativo(data?.data?.fatura_vencida_ativo)
        setContasPagarBloqueadoInativo(data?.data?.contas_a_pagar_ativo)
        setObrigacoesBloqueadoInativo(data?.data?.obrigacao_ativo)
        setFaturasVencerAssunto(data?.data?.fatura_a_vencer_assunto)
        setFaturasVencidasAssunto(data?.data?.fatura_vencida_assunto)
        setContasPagarAssunto(data?.data?.contas_a_pagar_assunto)
        setObrigacoesAssunto(data?.data?.obrigacao_assunto)
        setFaturasVencerMensagem(data?.data?.fatura_a_vencer_mensagem)
        setFaturasVencidasMensagem(data?.data?.fatura_vencida_mensagem)
        setContasPagarMensagem(data?.data?.contas_a_pagar_mensagem)
        setObrigacoesMensagem(data?.data?.obrigacao_mensagem)
      }
      setLoading(false);
      
    })();
  }, []);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/home");
    }
  }

  function handleAddVariable(variable, type) {

    switch (type) {
      case 'faturasVencer':
        setFaturasVencerMensagem( prev => `${prev} ${variable}`)
        break;

      case 'farurasVencidas':
        setFaturasVencidasMensagem( prev => `${prev} ${variable}`)
        break;

      case 'contasPagar':
        setContasPagarMensagem( prev => `${prev} ${variable}`)
        break;

      case 'obrigacoes':
        setObrigacoesMensagem( prev => `${prev} ${variable}`)
        break;
    
      default:
        break;
    }
  }

  async function handleSubmit() {

    // return;
    setLoading(true);
    const response = await addEmailConfig({
      faturasVencerBloqueadoInativo: faturasVencerBloqueadoInativo ? 1 : 0,
      faturasVencerAssunto,
      faturasVencerMensagem,
      faturasVencidasBloqueadoInativo: faturasVencidasBloqueadoInativo ? 1 : 0,
      faturasVencidasMensagem,
      faturasVencidasAssunto,
      contasPagarBloqueadoInativo: contasPagarBloqueadoInativo ? 1 : 0,
      contasPagarMensagem,
      contasPagarAssunto,
      obrigacoesMensagem,
      obrigacoesBloqueadoInativo: obrigacoesBloqueadoInativo ? 1 : 0,
      obrigacoesAssunto,
    });
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status === 200) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Atualizado!");
      setLoading(false);
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      return;
    }
  }

  return <>
    <div className="zero-m-bottom session-container user-register-container configuracao-email-automatico">
      <span className="session-container-header">
        <form className={styles.form}>
          <div className={styles.pageSection}>
            <div className={styles.subSection}>
              <Grid container justifyContent="space-between">
                <Grid xs={12} md={7} item className={styles.headerTitle}>
                  E-mails Automáticos de Notificações de faturas a vencer
                </Grid>
                <Grid xs={12} md={5} item container spacing={1} alignItems="center" justifyContent="flex-end">
                  <Grid item className="label">
                    Bloqueado/Inativo?
                  </Grid>
                  <Grid item className={styles.dependenteFontDecline}>
                    Não
                  </Grid>
                  <Grid item>
                    <SwitchComponent
                      checked={!faturasVencerBloqueadoInativo}
                      onClick={() => setFaturasVencerBloqueadoInativo(!faturasVencerBloqueadoInativo)}
                    />
                  </Grid>
                  <Grid item className={styles.dependenteFontAccept}>
                    Sim
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    label="Assunto padrão"
                    required
                    value={faturasVencerAssunto}
                    handleInputChange={(event) =>
                      setFaturasVencerAssunto(event.target.value)
                    }
                  />
                </Grid>
                <Grid item container spacing={1} xs={12}>
                  <Grid item xs={12}>
                  <label className={styles.inputLabel}>Mensagem *</label>
                  <JoditEditor
                    ref={editorFaturasVencer}
                    value={faturasVencerMensagem}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setFaturasVencerMensagem(newContent)} // preferred to use only this option to update the content for performance reasons
                  />
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid xs={12} className={styles.helperText}>Variáveis pré-definidas que você pode adicionar ao conteúdo do e-mail:</Grid>
                    <Grid xs={12} container className={styles.helperText}>
                      <span onClick={() => handleAddVariable('[nome_empresa]', 'faturasVencer')}>[nome_empresa]</span>
                      <span onClick={() => handleAddVariable('[nome_cliente]', 'faturasVencer')}>[nome_cliente]</span>
                      <span onClick={() => handleAddVariable('[vencimento_fatura]', 'faturasVencer')}>[vencimento_fatura]</span>
                      <span onClick={() => handleAddVariable('[id_fatura]', 'faturasVencer')}>[id_fatura]</span>
                      <span onClick={() => handleAddVariable('[competencia]', 'faturasVencer')}>[competencia]</span>
                      <span onClick={() => handleAddVariable('[valor_fatura]', 'faturasVencer')}>[valor_fatura]</span>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </div>
          </div>
          <div className={styles.pageSection}>
            <div className={styles.subSection}>
              <Grid container justifyContent="space-between">
                <Grid xs={12} md={7} item className={styles.headerTitle}>
                E-mails Automáticos de Notificações de faturas vencidas
                </Grid>
                <Grid xs={12} md={5} item container spacing={1} alignItems="center" justifyContent="flex-end">
                  <Grid item className="label">
                    Bloqueado/Inativo?
                  </Grid>
                  <Grid item className={styles.dependenteFontDecline}>
                    Não
                  </Grid>
                  <Grid item>
                    <SwitchComponent
                      checked={!faturasVencidasBloqueadoInativo}
                      onClick={() => setFaturasVencidasBloqueadoInativo(!faturasVencidasBloqueadoInativo)}
                    />
                  </Grid>
                  <Grid item className={styles.dependenteFontAccept}>
                    Sim
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    label="Assunto padrão"
                    required
                    value={faturasVencidasAssunto}
                    handleInputChange={(event) =>
                      setFaturasVencidasAssunto(event.target.value)
                    }
                  />
                </Grid>
                <Grid item container spacing={1} xs={12}>
                  <Grid item xs={12}>
                  <label className={styles.inputLabel}>Mensagem *</label>
                  <JoditEditor
                    css={{
                      border: '1px solid #c8c8c8 !important'
                    }}
                    ref={editorFaturasVencidas}
                    value={faturasVencidasMensagem}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setFaturasVencidasMensagem(newContent)} // preferred to use only this option to update the content for performance reasons
                  />
                  </Grid>
                  <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Grid className={styles.helperText}>Variáveis pré-definidas que você pode adicionar ao conteúdo do e-mail:</Grid>
                    <Grid container className={styles.helperText}>
                      <span onClick={() => handleAddVariable('[nome_empresa]', 'farurasVencidas')}>[nome_empresa]</span>
                      <span onClick={() => handleAddVariable('[nome_cliente]', 'farurasVencidas')}>[nome_cliente]</span>
                      <span onClick={() => handleAddVariable('[vencimento_fatura]', 'farurasVencidas')}>[vencimento_fatura]</span>
                      <span onClick={() => handleAddVariable('[id_fatura]', 'farurasVencidas')}>[id_fatura]</span>
                      <span onClick={() => handleAddVariable('[competencia]', 'farurasVencidas')}>[competencia]</span>
                      <span onClick={() => handleAddVariable('[valor_fatura]', 'farurasVencidas')}>[valor_fatura]</span>
                    </Grid>
                  </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </div>
          </div>
          <div className={styles.pageSection}>
            <div className={styles.subSection}>
              <Grid container justifyContent="space-between">
                <Grid xs={12} md={7} item className={styles.headerTitle}>
                E-mails Automáticos de Notificações de contas a pagar
                </Grid>
                <Grid xs={12} md={5} item container spacing={1} alignItems="center" justifyContent="flex-end">
                  <Grid item className="label">
                    Bloqueado/Inativo?
                  </Grid>
                  <Grid item className={styles.dependenteFontDecline}>
                    Não
                  </Grid>
                  <Grid item>
                    <SwitchComponent
                      checked={!contasPagarBloqueadoInativo}
                      onClick={() => setContasPagarBloqueadoInativo(!contasPagarBloqueadoInativo)}
                    />
                  </Grid>
                  <Grid item className={styles.dependenteFontAccept}>
                    Sim
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    label="Assunto padrão"
                    required
                    value={contasPagarAssunto}
                    handleInputChange={(event) =>
                      setContasPagarAssunto(event.target.value)
                    }
                  />
                </Grid>
                <Grid item container spacing={1} xs={12}>
                  <Grid item xs={12}>
                  <label className={styles.inputLabel}>Mensagem *</label>
                  <JoditEditor
                    css={{
                      border: '1px solid #c8c8c8 !important'
                    }}
                    ref={editorContasPagar}
                    value={contasPagarMensagem}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setContasPagarMensagem(newContent)} // preferred to use only this option to update the content for performance reasons
                  />
                  </Grid>
                  <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Grid className={styles.helperText}>Variáveis pré-definidas que você pode adicionar ao conteúdo do e-mail:</Grid>
                    <Grid container className={styles.helperText}>
                      <span onClick={() => handleAddVariable('[conta_a_pagar]', 'contasPagar')}>[conta_a_pagar]</span>
                      <span onClick={() => handleAddVariable('[razao_social]', 'contasPagar')}>[razao_social]</span>
                      <span onClick={() => handleAddVariable('[data_vencimento]', 'contasPagar')}>[data_vencimento]</span>
                    </Grid>
                  </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </div>
          </div>
          <div className={styles.pageSection}>
            <div className={styles.subSection}>
              <Grid container justifyContent="space-between">
                <Grid xs={12} md={7} item className={styles.headerTitle}>
                E-mails Automáticos de Notificações de Obrigações
                </Grid>
                <Grid xs={12} md={5} item container spacing={1} alignItems="center" justifyContent="flex-end">
                  <Grid item className="label">
                    Bloqueado/Inativo?
                  </Grid>
                  <Grid item className={styles.dependenteFontDecline}>
                    Não
                  </Grid>
                  <Grid item>
                    <SwitchComponent
                      checked={!obrigacoesBloqueadoInativo}
                      onClick={() => setObrigacoesBloqueadoInativo(!obrigacoesBloqueadoInativo)}
                    />
                  </Grid>
                  <Grid item className={styles.dependenteFontAccept}>
                    Sim
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    label="Assunto padrão"
                    required
                    value={obrigacoesAssunto}
                    handleInputChange={(event) =>
                      setObrigacoesAssunto(event.target.value)
                    }
                  />
                </Grid>
                <Grid item container spacing={1} xs={12}>
                  <Grid item xs={12}>
                  <label className={styles.inputLabel}>Mensagem *</label>
                  <JoditEditor
                    css={{
                      border: '1px solid #c8c8c8 !important'
                    }}
                    ref={editorObrigacoes}
                    value={obrigacoesMensagem}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setObrigacoesMensagem(newContent)} // preferred to use only this option to update the content for performance reasons
                  />
                  </Grid>
                  <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Grid className={styles.helperText}>Variáveis pré-definidas que você pode adicionar ao conteúdo do e-mail:</Grid>
                    <Grid container className={styles.helperText}>
                      <span onClick={() => handleAddVariable('[obrigacao]', 'obrigacoes')}>[obrigacao]</span>
                      <span onClick={() => handleAddVariable('[razao_social]', 'obrigacoes')}>[razao_social]</span>
                      <span onClick={() => handleAddVariable('[referencia_obrigacao]', 'obrigacoes')}>[referencia_obrigacao]</span>
                      <span onClick={() => handleAddVariable('[data_vencimento]', 'obrigacoes')}>[data_vencimento]</span>
                      <span onClick={() => handleAddVariable('[observacoes]', 'obrigacoes')}>[observacoes]</span>
                    </Grid>
                  </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </div>
          </div>
        </form>
      </span>
    </div>
    <div className="button-area">
      <span>
        <Button
          type="button"
          text="Salvar"
          className="btn-success"
          onClick={handleSubmit}
        />
      </span>
    </div>
    {callbackShown && (
      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        message={callbackMessage}
        errorList={callbackErrors}
        handleClose={handleClose}
        duration={duration}
      />
    )}
    {loading && <OverlayLoading />}
    <ModalLoginExpirado open={openLoginExpirado} />
  </>
}
