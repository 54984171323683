import styled from 'styled-components';
import { DialogContent } from '@material-ui/core';

export const TitleArea = styled.div`
  background-color: #ffff !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  overflow-x: hidden;
`;

export const Title = styled.span`
  font-size: 16px;
  color: #ef4066;
`;
export const ColsTable = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding-top: ${props => props.noPadding ? '0px !important' : '40px !important'};
  background-color: #f8f8f8 !important;
`;

export const ColsCell = styled.div`
  display: table-cell;
  padding: 6px;
  width: 100%;

  &:first-of-type {
    padding-left: 30px;
  }

  &:last-of-type {
    padding-right: 30px;
  }
`;

export const SubmitButton = styled.div`
  float: right;
  margin-right: 30px;
  margin-top: 200px;
`;

export const CustomContent = styled(DialogContent)`
  overflow-y: auto !important;
  height: 350px !important;
`;
