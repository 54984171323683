import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import Input from "../../components/Input";
import Button from "../../components/ButtonComponent";
import Select from "../../components/SelectComponent";
import OverlayLoading from "../../components/OverlayLoading";
import CallbackMessage from "../../components/CallbackMessage";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";

import contasBancariasService from "../../services/contasBancariasService.js";

import "./styles.scss";
import { FormControlLabel, Grid } from "@material-ui/core";
import SwitchComponent from "../../components/Switch";
import Icon from "../../components/Icon";
import { WidgetPluggy } from "../../components/WidgetPluggy";
import useCompany from "../../hooks/useCompany";

function CadastroContas() {
  const history = useHistory();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const {
    addContaBancaria,
    getBancos,
    consultaContaBancaria,
    atualizaContaBancaria,
  } = contasBancariasService;

  const routeParams = useParams();
  const { idConta } = routeParams;

  const {
    companyList: empresas,
    selectedCompany: empresa,
    setSelectedCompany: setEmpresa,
    getEmpresaPagina,
  } = useCompany("contas_bancarias");

  const [bancos, setBancos] = useState([]);

  const [banco, setBanco] = useState(null);
  const [agencia, setAgencia] = useState(null);
  const [contaCorrente, setContaCorrente] = useState(null);
  const [digito, setDigito] = useState(null);
  const [saldo, setSaldo] = useState(null);
  const [contaContabil, setContaContabil] = useState(null);
  const [nomeConta, setNomeConta] = useState(null);
  const [usarIntegracao, setUsarIntegracao] = useState(false);
  const [widgetOpen, setWidgetOpen] = useState(true);
  const idIntegracaoRealizada = useRef();

  const [loading, setLoading] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  async function loadBancos() {
    const response = await getBancos();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const { data } = response;

    if (data && data.length !== 0) {
      setBancos(data);
    }
  }

  function handleClose(event, reason) {
    setCallbackShown(false);
    if (callbackType === "success") {
      history.push("/cadastro-conta");
    }
  }

  function handleSelectBanco(id) {
    setBanco(id);
    const nomeBanco = bancos.find((item) => item.id === id);
    setContaContabil(nomeBanco.conta_contabil);
    setNomeConta(nomeBanco.nome);
  }

  async function handleAccountSubmit() {
    if (!validateFields()) return;
    let response = null;

    setLoading(true);
    if (idConta) {
      response = await atualizaContaBancaria({
        id: idConta,
        idEmpresa: empresa,
        idBanco: banco,
        agencia: agencia,
        contaCorrente: contaCorrente,
        digito: digito,
        contaContabil: contaContabil,
        saldoInicial: Number(saldo),
        nomeConta: nomeConta,
        usarIntegracao: usarIntegracao ? 1 : 0,
        idIntegracao: idIntegracaoRealizada.current,
      });
    } else {
      response = await addContaBancaria({
        idEmpresa: empresa,
        idBanco: banco,
        agencia: agencia,
        contaCorrente: contaCorrente,
        digito: digito,
        contaContabil: contaContabil,
        saldoInicial: Number(saldo),
        nomeConta: nomeConta,
        usarIntegracao: usarIntegracao ? 1 : 0,
        idIntegracao: idIntegracaoRealizada.current,
      });
    }
    setLoading(false);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status !== 201 && response.status !== 200) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(6000);
      setCallbackMessage("Erro!");
      if (response?.data?.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      return;
    } else {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage(response.data.message || "Conta atualizada!");
    }
  }

  function verifyField(field, callback) {
    if (!field) {
      callback();
    }
  }

  function validateFields() {
    let list = [];
    verifyField(empresa, () => list.push("A Empresa deve ser selecionada."));
    if (usarIntegracao) {
      verifyField(idIntegracaoRealizada.current, () =>
        list.push(
          "Finalize a integração ou desmarque a opção 'Deseja obter automaticamente os seus extratos bancários?'"
        )
      );
    } else {
      verifyField(banco, () => list.push("O Banco deve ser selecionado."));
    }

    setCallbackErrorList(list);
    if (list.length > 0) {
      setCallbackType("error");
      setCallbackShown(true);
    }
    return list.length === 0;
  }

  function handleChangeUsarIntegracao() {
    if (!usarIntegracao && !empresa) {
      setCallbackType("error");
      setCallbackDuration(4000);
      setCallbackErrorList(["Selecione uma empresa para continuar"]);
      setCallbackShown(true);
    }

    setUsarIntegracao((prev) => !prev);
    setWidgetOpen(true);
  }

  const onClose = useCallback(() => {
    setWidgetOpen(false);
    if (!idIntegracaoRealizada.current) {
      setUsarIntegracao(false);
    }
  }, [idIntegracaoRealizada.current, setUsarIntegracao, idConta]);

  async function loadConta() {
    // const { id } = routeParams;
    const response = await consultaContaBancaria(idConta);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    } else if (response.status === 400) {
      setCallbackType("error");
      setCallbackMessage(response.data.msg || response.data.message || "Erro!");
      setCallbackErrorList(
        [response.data.error] || ["Erro ao consultar conta!"]
      );
      setLoading(false);
      setCallbackShown(true);
      setId(null);
      return;
    }
    const { data } = response;

    const bancosArray = await getBancos();
    if (bancosArray && bancosArray.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const { data: bancosData } = bancosArray;
    if (bancosData && bancosData.length !== 0) {
      setBancos(bancosData);

      const initialBanco = bancosData.find((item) => item.id === data.id_banco);

      setNomeConta(initialBanco?.nome || null);
      setBanco(initialBanco?.id || null);
    }

    setEmpresa(data.id_empresa);
    setAgencia(data.agencia || null);
    setContaCorrente(data.conta_corrente || null);
    setContaContabil(data.conta_contabil || null);
    setDigito(data.digito || null);
    setSaldo(data.saldo_inicial || null);
    setUsarIntegracao(data.integracao_pluggy_ativa || null);
    idIntegracaoRealizada.current = data.id_integracao_pluggy;
  }

  useEffect(() => {
    (async function () {
      setLoading(true);
      if (idConta) {
        await loadConta();
      } else {
        loadBancos();
      }
      setLoading(false);
    })();
  }, [idConta]);

  useEffect(() => {
    if (!idConta) {
      const empresaStorage = getEmpresaPagina("contas_bancarias");
      if (empresaStorage) {
        setEmpresa(empresaStorage);
      }
    }
  }, []);

  return (
    <>
      <div className="session-container user-register-container cadastrar-conta">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <form
            className={`cadastro-contas-form ${
              usarIntegracao && widgetOpen && empresa ? "mostrar-widget" : ""
            }`}
          >
            <Grid container spacing={2}>
              <Grid
                container
                xs={usarIntegracao && widgetOpen && empresa ? 8 : 12}
                item
                spacing={2}
                className="container-form"
              >
                <Grid item xs={12}>
                  <Select
                    styleType="form"
                    nome="empresa"
                    title="Selecione uma empresa"
                    label="Empresa"
                    tipo="text"
                    required="true"
                    list={empresas}
                    initialValue={
                      empresas?.find((item) => item.id === empresa)?.nome || ""
                    }
                    callback={(element) => setEmpresa(element)}
                  />
                </Grid>
                <Grid item className="container-input-switch">
                  <Grid
                    item
                    style={{
                      marginRight: 12,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icon name="info-circle" />
                    Deseja obter automaticamente os seus extratos bancários?
                  </Grid>
                  <Grid item style={{ marginLeft: 12 }}>
                    <FormControlLabel
                      style={{ marginBottom: 0 }}
                      control={
                        <SwitchComponent
                          checked={!!usarIntegracao}
                          onClick={() => {
                            handleChangeUsarIntegracao();
                          }}
                          name="usarIntegracao"
                        />
                      }
                    />
                  </Grid>
                </Grid>
                {!usarIntegracao ? (
                  <Grid item xs={12}>
                    <Select
                      styleType="form"
                      nome="banco"
                      label="Banco"
                      title="Selecione uma instituição bancária"
                      tipo="text"
                      list={bancos}
                      required="true"
                      callback={(id) => handleSelectBanco(id)}
                      initialValue={nomeConta}
                    />
                  </Grid>
                ) : null}

                {!usarIntegracao ? (
                  <Grid item xs={12} md={4}>
                    <Input
                      nome="agencia"
                      label="Agência"
                      tipo="text"
                      value={agencia}
                      handleInputChange={(event) => {
                        setAgencia(event.target.value);
                      }}
                    />
                  </Grid>
                ) : null}

                {!usarIntegracao ? (
                  <Grid item xs={12} md={4}>
                    <Input
                      nome="contaCorrente"
                      label="Conta Corrente"
                      tipo="text"
                      type="text"
                      value={contaCorrente}
                      handleInputChange={(event) =>
                        setContaCorrente(event.target.value)
                      }
                    />
                  </Grid>
                ) : null}

                {!usarIntegracao ? (
                  <Grid item xs={12} md={4}>
                    <Input
                      nome="digito"
                      label="Dígito"
                      tipo="text"
                      type="text"
                      value={digito}
                      handleInputChange={(event) =>
                        setDigito(event.target.value)
                      }
                    />
                  </Grid>
                ) : null}
                <Grid
                  container
                  item
                  xs={12}
                  className="container-campos-obrigatorios"
                  spacing={2}
                >
                  <Grid item xs={12} md={6}>
                    <Input
                      nome="saldoInicial"
                      label="Saldo Inicial"
                      title="R$"
                      tipo="dinheiro"
                      value={saldo}
                      handleInputChange={(event) => setSaldo(event.value)}
                      disabled={!!idConta}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      nome="contaContabil"
                      label="Conta Contábil"
                      tipo="text"
                      type="text"
                      handleInputChange={(event) =>
                        setContaContabil(event.target.value)
                      }
                      value={contaContabil}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <span className="required-text">* Campos obrigatórios</span>
                </Grid>
              </Grid>
              {usarIntegracao && empresa && widgetOpen ? (
                <Grid container item xs={4}>
                  <Grid item xs={12}>
                    <WidgetPluggy
                      idEmpresa={empresa}
                      onClose={onClose}
                      setIdIntegracaoRealizada={(value) => {
                        idIntegracaoRealizada.current = value;
                      }}
                      idConta={idConta}
                      idIntegracao={
                        idIntegracaoRealizada.current && idConta
                          ? idIntegracaoRealizada.current
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            type="button"
            text="Salvar"
            className="btn-success"
            onClick={handleAccountSubmit}
          />
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />

      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />
    </>
  );
}

export default CadastroContas;
