import React, { useState, useEffect } from "react";
import { Grid, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import Icon from "../Icon";

import classes from "./styles.module.css";
import { TooltipComponent } from "../Tooltip";
import { element } from "prop-types";

function InputFile({
  onChange = () => {},
  title = "",
  fileName = "",
  fileFormat = "",
  labelButton = "Escolher Arquivo",
  cbFileObj = () => {},
  labelSuffix = null,
  hasFile = false,
  id = "",
  disabled = false,
}) {
  const [valueFile, setValueFile] = useState("");
  const [mensagemTooltip, setMensagemTooltip] = useState("");

  useEffect(() => {
    setValueFile(fileName);
  }, [fileName]);

  const suffixInput = () => {
    let icon;

    if (labelSuffix.icon === "info")
      icon = <InfoOutlined className={classes.suffixIconInfo} />;
    else if (labelSuffix.icon === "export")
      icon = (
        <span className={classes.suffixIconShare}>
          <Icon name="export" color="#812990" size="16" />
        </span>
      );

    return (
      <div className={classes.suffixLabel}>
        <Tooltip
          arrow
          title={labelSuffix.title}
          onClick={() => labelSuffix.onClick()}
        >
          {icon}
        </Tooltip>
      </div>
    );
  };

  const handleImageChange = (event) => {
    let files = event.target.files;

    if (!files) return;
    let formattedFiles = [];
    let mensagem = "";
    if (files.length) {
      console.log({ here: files.length });
      mensagem = `${files?.length} ${
        files?.length > 1
          ? "novos arquivos adicionados:\n"
          : "novo arquivo adicionado:\n"
      } `;
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        if (index > 0) {
          mensagem += ", ";
        }
        mensagem += `${element.name}`;
        formattedFiles.push({
          nome: element.name,
          arquivo: element,
        });
      }
      console.log({ mensagem });
      setMensagemTooltip(mensagem);
    }

    cbFileObj(files);
    setValueFile(
      `${files?.length} ${
        files?.length > 1
          ? "novos arquivos adicionados"
          : "novo arquivo adicionado"
      }`
    );
    onChange(formattedFiles);
  };

  return (
    <>
      <div className={classes.label}>
        {title}
        {labelSuffix && suffixInput()}
      </div>
      <Grid
        item
        container
        justifyContent="space-between"
        className={classes.input_text}
      >
        <TooltipComponent label={mensagemTooltip}>
          <Grid item container justifyContent="space-between">
            <Grid item xs={12} sm={7} className={classes.containerFileName}>
              <div className={classes.text}>
                {valueFile === ""
                  ? hasFile
                    ? hasFile
                    : "Nenhum arquivo selecionado"
                  : valueFile.name || valueFile}
              </div>
            </Grid>
            <Grid item xs={12} sm={5} className={classes.input_button}>
              <label className={classes.label} htmlFor={`input-file ${id}`}>
                {labelButton}
              </label>
              <input
                id={`input-file ${id}`}
                className={classes.input_file}
                type="file"
                accept={fileFormat}
                onChange={handleImageChange}
                disabled={disabled}
                multiple={true}
              />
            </Grid>
          </Grid>
        </TooltipComponent>
      </Grid>
    </>
  );
}

export default InputFile;
