import React, { useState } from "react";
import { Accordion } from "../../../../components/AccordionComponent";
import { Grid } from "@material-ui/core";
import { formataNumero, numberToCurrency } from "../../../../utils/functions";
import TableComponent from "../../../../components/TableComponent";

export function AccordionDre({
  dados,
  columns = [],
  configuracaoTabela,
  tipoAtivo,
}) {
  const activeOpen = tipoAtivo === "produto";

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    if (!activeOpen) {
      return;
    }
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {dados.map((item, index) => {
        const values = columns.map((itemCol) => {
          return item[itemCol.id];
        });
        const dadosProdutos =
          tipoAtivo === "produto"
            ? item?.itens
              ? item.itens.map((itemProd, itemIndex) => ({
                  ...itemProd,
                  ordem: itemIndex + 1,
                  codigo: null,
                  ncm: itemProd?.produto?.ncm || null,
                  valor_unitario_tributario: formataNumero(
                    itemProd.valor_unitario_tributario
                  ),
                  valor_desconto: formataNumero(itemProd.desconto),
                  valor_total_bruto: formataNumero(itemProd.total_item),
                  bc_icms: formataNumero(itemProd.valor_base_calculo),
                  bc_icmsst: formataNumero(itemProd.valor_bcicmsst),
                  valor_icms: formataNumero(itemProd.valor_icms),
                  valor_icmsst: formataNumero(itemProd.valor_icmsst),
                  valor_ipi: formataNumero(itemProd.valor_ipi),
                  aliquota_icms: formataNumero(itemProd.valor_aliquota),
                  aliquota_ipi: formataNumero(itemProd.aliquota_ipi),
                }))
              : []
            : [];

        return (
          <div key={`summary_${index}`}>
            <Accordion.Root
              handleChange={handleChange}
              index={index}
              expanded={expanded}
            >
              <Accordion.Header
                expanded={expanded}
                index={index}
                item={item}
                columns={values}
                numbers={false}
                marginRight={32}
                widthFirstColumn={190}
                customDescriptionClass=""
                activeOpen={activeOpen}
              />

              {activeOpen ? (
                <Accordion.Body>
                  <Grid
                    container
                    direction="column"
                    className="container-tabela-responsiva"
                  >
                    <TableComponent
                      idName="id_item"
                      dataList={dadosProdutos}
                      tableConfig={configuracaoTabela}
                      enableOrder={false}
                    />
                  </Grid>
                </Accordion.Body>
              ) : null}
            </Accordion.Root>
            <div className="space-vertical" />
          </div>
        );
      })}
    </>
  );
}
