import Axios from './Axios';

const faturasService = {
  getFaturas: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    clienteId = null
  ) => {
    const response = await Axios.get('/lista-fatura', {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        cliente_id: clienteId,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  consultaFatura: async (idFatura) => {
    const response = await Axios.get(`/consulta-fatura/${idFatura}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  cadastraFatura: async (
    id_empresa,
    id_cliente,
    id_plano,
    descricao,
    data_vencimento,
    data_emissao,
    competencia,
    clientes
  ) => {
    const response = await Axios.post('/cadastra-fatura', {
      id_empresa,
      id_cliente,
      id_plano,
      descricao,
      data_vencimento,
      data_emissao,
      competencia,
      clientes,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  alterarFatura: async (
    id_fatura,
    id_plano,
    id_empresa,
    id_cliente,
    // id_plano,
    // descricao,
    data_pagamento,
    nsu,
    forma_pagamento,
    status_pagamento,
    competencia,
    emite_nota
  ) => {
    const response = await Axios.post(`/altera-fatura/${id_fatura}`, {
      // descricao,
      id_plano,
      id_empresa,
      id_cliente,
      data_pagamento,
      nsu,
      forma_pagamento,
      status_pagamento,
      competencia,
      emite_nota,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  baixaFatura: async (id_fatura, params) => {
    const response = await Axios.post(`/baixar-fatura/${id_fatura}`, {
      ...params
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  apagarFatura: async (id_fatura) => {
    const response = await Axios.get(`/remove-fatura/${id_fatura}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  pagamentoBrasPag: async (
    id_fatura,
    tipo,
    numero_cartao,
    data_expiracao,
    codigo_seguranca,
    url_retorno,
    nome_cartao,
    parcelas
  ) => {
    const response = await Axios.post('/pagamento-bras-pag', {
      id_fatura,
      tipo,
      numero_cartao,
      data_expiracao,
      codigo_seguranca,
      url_retorno,
      nome_cartao,
      parcelas
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  faturaAssinantes: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    statusRelatorio = null,
    empresa = null,
    dataInicio = null,
    dataFim = null,
    status_cliente = null,
    plano = null,
    forma_pagamento = null,
    data_contratacao_inicio = null,
    data_contratacao_fim = null,
    data_pagamento_inicio = null,
    data_pagamento_fim = null,
    data_emissao_inicio = null,
    data_emissao_fim = null
  ) => {
    const response = await Axios.get('/lista-assinantes', {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        status: statusRelatorio,
        empresa,
        data_inicio: dataInicio,
        data_fim: dataFim,
        status_cliente,
        plano,
        forma_pagamento,
        data_contratacao_inicio,
        data_contratacao_fim,
        data_pagamento_inicio,
        data_pagamento_fim,
        data_emissao_inicio,
        data_emissao_fim,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  exportarRelatorio: async (
    status = null,
    order_by = null,
    order_by_type = null,
    empresa = null,
    data_inicio = null,
    data_fim = null,
    search_term = null,
    data_pagamento_inicio = null,
    data_pagamento_fim = null,
    data_emissao_inicio = null,
    data_emissao_fim = null,
    data_contratacao_inicio = null,
    data_contratacao_fim = null,
    tipo_exportacao,
  ) => {
    const response = await Axios.get('/exporta-relatorio-assinantes', {
      params: {
        status,
        order_by,
        order_by_type,
        empresa,
        data_inicio,
        data_fim,
        search_term,
        tipo_exportacao,
        data_pagamento_inicio,
        data_pagamento_fim,
        data_emissao_inicio,
        data_emissao_fim,
        data_contratacao_inicio,
        data_contratacao_fim
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  exportarRelatorioPdf: async (
    status = null,
    order_by = null,
    order_by_type = null,
    empresa = null,
    data_inicio = null,
    data_fim = null,
    search_term = null,
    data_pagamento_inicio = null,
    data_pagamento_fim = null,
    data_emissao_inicio = null,
    data_emissao_fim = null,
    data_contratacao_inicio = null,
    data_contratacao_fim = null
  ) => {
    const response = await Axios.get('/exporta-relatorio-assinantes-pdf', {
      params: {
        status,
        order_by,
        order_by_type,
        empresa,
        data_inicio,
        data_fim,
        search_term,
        data_pagamento_inicio,
        data_pagamento_fim,
        data_emissao_inicio,
        data_emissao_fim,
        data_contratacao_inicio,
        data_contratacao_fim
      },
    })
      .then(({ ...response }) => response)
      .catch(({response}) => response);

    return response;
  },
  estornarFatura: async (
    id_lancamento,
  ) => {
    const response = await Axios.post(
      `/estorna-fatura/${id_lancamento}`
    )
      .then(({ ...response }) => {
        return response
      })
      .catch(({ response }) => {
        return response
      })

    return response
  }
};

export default faturasService;
