import Axios from './Axios';

const fornecedoresService = {
  getFornecedores: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    de = null,
    ate = null,
    id_empresa = null
  ) => {
    const result = await Axios.get('/lista-fornecedor', {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        date_de: de,
        date_ate: ate,
        id_empresa,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  selecionaFornecedores: async (idEmpresa) => {
    const result = Axios.get(`seleciona-fornecedor/${idEmpresa}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};

export default fornecedoresService;
