import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import TableComponent from "../../components/TableComponent";
import Select from "../../components/SelectComponent";
import DatePicker from "../../components/DatePicker";
import OverlayLoading from "../../components/OverlayLoading";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import moment from "moment";
import { formatDate } from "../../utils/dates";
import { numberToCurrency } from "../../utils/functions";

import contasReceberService from "../../services/contasReceberService";
import empresasService from "../../services/empresasService";

import "./styles.css";
import CallbackMessage from "../../components/CallbackMessage";
import validateFields from "../../utils/validateFields";
import exportData from "../../services/exportData";
import { ModalEnviarEmail } from "../../components/ModalEnviarEmail";
import useCompany from "../../hooks/useCompany";

function ContasReceber() {
  const {
    companyList: listEmpresas,
    selectedCompany: empresaId,
    setSelectedCompany: setEmpresaId,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("contas_receber");

  const [loading, setLoading] = useState(false);
  const [dataInicio, setDataInicio] = useState(moment().format("YYYY-MM-DD"));
  const [dataFim, setDataFim] = useState(
    moment().add(1, "month").format("YYYY-MM-DD")
  );
  const [listRequest, setListRequest] = useState([]);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);

  const [openModalEmail, setOpenModalEmail] = useState(false);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_lancamento" },
      { columnName: "Data Vencimento", dataRef: "data_vencimento" },
      { columnName: "Cliente", dataRef: "nome_cliente" },
      { columnName: "Descrição", dataRef: "descricao" },
      { columnName: "Categoria", dataRef: "nome_categoria" },
      { columnName: "Valor", dataRef: "valor" },
    ],
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-xls",
        },
        {
          icon: "export",
          label: "Exportar PDF",
          action: "exportar-pdf",
        },
        {
          icon: "email",
          label: "Enviar e-mail",
          action: "enviar-e-mail",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  const exportToPDF = {
    url: "exportar-contas-a-receber-pdf",
    fileName: "cbhub_relatorio_contas_receber",
    fileType: "pdf",
  };

  const exportToXLS = {
    url: "exportar-contas-a-receber",
    fileName: "cbhub_relatorio_contas_receber",
    fileType: "xlsx",
  };

  let updateTable = async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await contasReceberService.getContas(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      dataInicio,
      dataFim,
      empresaId
    );
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    console.log(result);
    if (result.status === 200) {
      let list = [];
      let l = [];
      if (result.data.hasOwnProperty("data")) list = result.data.data;
      else list = result.data;

      list.forEach((item) => {
        item.data_pagamento = formatDate(item.data_pagamento);
        item.data_vencimento = formatDate(item.data_vencimento);
        item.valor = numberToCurrency(item.valor);
        l.push(item);
      });

      if (result.data.hasOwnProperty("data")) {
        result.data.data = l;
        setListRequest(result.data);
      } else {
        result.data = l;
        setListRequest(result);
      }
    }

    setLoading(false);
  };

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onTableMoreAction = async ({ action }) => {
    let fields = [
      {
        label: "empresa",
        value: empresaId,
      },
      {
        label: "datainicio",
        value: dataInicio,
      },
      {
        label: "datafim",
        value: dataFim,
      },
    ];

    const validateFieldsErrors = validateFields(fields);

    if (validateFieldsErrors.length !== 0) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackDuration(3000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(validateFieldsErrors);
      setLoading(false);
      return;
    }
    console.log(action);
    if (action === "enviar-e-mail") {
      console.log("enviar-e-mail");
      setOpenModalEmail(true);
      return;
    }
    let params = {};
    if (action === "exportar-xls") {
      params = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: {
          data_inicial: dataInicio,
          data_final: dataFim,
          id_empresa: empresaId,
        },
      };
    } else if (action === "exportar-pdf") {
      params = {
        url: exportToPDF.url,
        fileName: exportToPDF.fileName,
        fileType: exportToPDF.fileType,
        params: {
          data_inicial: dataInicio,
          data_final: dataFim,
          id_empresa: empresaId,
        },
      };
    }

    setLoading(true);
    const response = await exportData(params);
    console.log(response);

    if (response.status === 500) {
      setCallbackErrorList([
        "Erro interno no servidor. Por favor, contate o suporte",
      ]);
      setCallbackShown(true);
      setCallbackType("error");
    } else if (response.status !== 201 && response.status !== 200) {
      setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
      setCallbackShown(true);
      setCallbackType("error");
    }

    setLoading(false);
  };

  function handleClose() {
    setCallbackShown(false);
    setCallbackType("");
  }

  async function handleSendEmail(data = false) {
    console.log(data);
    if (!data) {
      setOpenModalEmail(false);
      return;
    }
    setLoading(true);
    const cco = data.cco?.split(";")?.map((item) => {
      return item?.trim();
    });
    const emails = data.para?.split(";")?.map((item) => {
      return item?.trim();
    });
    const response = await contasReceberService.enviarEmailRelatorio({
      id_empresa: empresaId,
      data_inicial: dataInicio,
      data_final: dataFim,
      assunto: data.assunto,
      cco,
      emails,
      mensagem: data.mensagem,
    });

    if (response?.status === 200) {
      setCallbackErrorList([]);
      setCallbackMessage([
        response?.data?.msg ? response?.data?.msg : "Relátorio enviado",
      ]);
      setCallbackShown(true);
      setCallbackType("success");
      setOpenModalEmail(false);
    }

    setLoading(false);
    console.log(response);
  }

  function filterComponent() {
    return (
      <div className="session-container container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              title="Selecione uma empresa"
              list={listEmpresas}
              initialValue={
                listEmpresas?.find((item) => item.id === empresaId)?.nome ||
                null
              }
              callback={(e) => {
                setEmpresaId(e);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="De *"
              handleChange={(value) => setDataInicio(value)}
              initDate={dataInicio}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="Para *"
              handleChange={(value) => setDataFim(value)}
              initDate={dataFim}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  useEffect(() => {
    if (empresaId) {
      updateTable();
      setEmpresaPagina("contas_receber", empresaId);
    }
  }, [empresaId, dataInicio, dataFim]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("contas_receber");
    if (empresaStorage) {
      setEmpresaId(empresaStorage);
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <div className="table-no-header container-tabela-responsiva">
        <TableComponent
          idName="id"
          dataList={listRequest !== undefined ? listRequest.data : []}
          tableConfig={defaultConfigTable}
          callbackCurrentPage={onPageChange}
          callbackOrderBy={onOrderBy}
          callbackPerPage={onPerPage}
          callbackSearchTerm={onSearchTerm}
          filterComponent={filterComponent()}
          cbTableMoreAction={onTableMoreAction}
        />

        <CallbackMessage
          type={callbackType}
          open={callbackShown}
          errorList={callbackErrorList}
          duration={callbackDuration}
          message={callbackMessage}
          handleClose={() => handleClose()}
        />

        <ModalEnviarEmail
          open={openModalEmail}
          onClose={handleSendEmail}
          setCallbackErrorList={setCallbackErrorList}
          callbackErrorList={callbackErrorList}
          callbackShown={callbackShown}
          setCallbackShown={setCallbackShown}
        />
        <ModalLoginExpirado open={openLoginExpirado} />
      </div>
    </>
  );
}

export default ContasReceber;
