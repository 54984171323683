import Axios from "./Axios";

const blackFridayServices = {
  cadastra: async (objCadastro) => {
    const response = await Axios.post("/cadastra-black-friday", {  
      ...objCadastro
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  verificaCadastro: async (id) => {  
    const response = await Axios.get(`/verifica-cadastro-empresa-completo/${id}`, {  
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  cadastraComplemento: async (id, objCadastro) => {  
    const response = await Axios.post(`/cadastra-empresa-complemento/${id}`, {  
      ...objCadastro
    })
    .then(({ ...response }) => response)
    .catch(({ response }) => response);

  return response;
  }
};

export default blackFridayServices;