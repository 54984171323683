import React, { useEffect, useState, useCallback } from "react";

import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import { Grid } from "@material-ui/core";
import "./styles.scss";

import SelectComponent from "../../../components/SelectComponent";
import TableComponent, {
  Search,
} from "../../../components/TableResponsiveComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { ModalImportarXlsx } from "../../../components/ModalImportarXlsx";
import arquivoModeloCategorias from "../../../assets/downloads/documento_padrao_importar_categoria.xlsx";
import categoriaEmpresaService from "../../../services/categoriaEmpresaService";
import useCompany from "../../../hooks/useCompany";

const tableOptions = {
  noSearch: true,
  filter: true,
  more: true,
  moreOptions: [
    {
      icon: "download",
      label: "Importar XLS",
      action: "importar-xls",
    },
  ],
};

function filterComponent({
  empresaSelecionada,
  listEmpresas,
  handleApplyFilters,
  handleCleanFilters,
  handleSelectEmpresa,
}) {
  return (
    <div className="session-container filter-component-container grupo-empresa chat">
      <Grid container spacing={2} alignItems="center" className="form-table">
        <Grid item xs={12}>
          <SelectComponent
            styleType="form"
            label="Empresa"
            required
            initialValue={
              empresaSelecionada
                ? listEmpresas.find((item) => item.id === empresaSelecionada)
                    ?.nome
                : ""
            }
            title="Selecione uma empresa"
            list={listEmpresas}
            callback={handleSelectEmpresa}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="filter-button-area align-rigth">
            <ButtonComponent
              onClick={handleApplyFilters}
              text="Filtrar"
              className="btn-primary"
            />
            <ButtonComponent
              onClick={handleCleanFilters}
              text="Limpar Filtros"
              className="default-outline clean-filters-button"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export const ListaCategorias = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { listCategorias, atualizarStatusCategoria, importarCategoria } =
    categoriaEmpresaService;
  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("categorias");

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [callbackShownModal, setCallbackShownModal] = useState(false);
  const [callbackErrorListModal, setCallbackErrorListModal] = useState([]);

  const [itemAcao, setItemAcao] = useState(null);
  const [confirmStatusDialog, setConfirmStatusDialog] = useState(false);
  const [openModalImportar, setOpenModalImportar] = useState(false);

  const handleClose = (event, reason) => {
    console.log(reason);
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  };

  const [listRequest, setListRequest] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_categoria" },
      { columnName: "Empresa", dataRef: "razao_social" },
      { columnName: "Código", dataRef: "codigo" },
      { columnName: "Grupo", dataRef: "nome_grupo" },
      { columnName: "Categoria", dataRef: "nome" },
      { columnName: "status_categoria", dataRef: "switch_value", hide: true },
    ],
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      statusLabels: true,
      filter: false,
    },
    options: {
      edit: true,
      switch: true,
    },
    text: {
      edit: "Editar",
      switch: "Ativar/Inativar",
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    id_empresa: null,
  });

  const updateTable = useCallback(async () => {
    if (!defaultConfigTable.id_empresa) {
      return;
    }
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await listCategorias({
      page: defaultConfigTable.currentPage,
      orderBy: defaultConfigTable.orderBy,
      orderByType: defaultConfigTable.orderByType,
      perPage: defaultConfigTable.perPage,
      searchTerm: defaultConfigTable.searchTerm,
      idEmpresa: defaultConfigTable.id_empresa,
    });

    if (result.status === 200) {
      if (result?.data?.hasOwnProperty("data")) {
        const { data } = result;

        const newData = data.data.map((item) => ({
          ...item,
          switch_value: item.status,
          status: item.status_label,
        }));

        setListRequest({
          ...data,
          data: newData,
        });
      } else {
        const newData = result.data.map((item) => ({
          ...item,
          switch_value: item.status,
          status: item.status_label,
        }));
        const newResult = {
          ...result,
          data: newData,
        };

        setListRequest(newResult);
      }
    } else {
      setCallbackErrorList(["Ocorreu um erro ao listar as categorias."]);
      setCallbackShown(true);
      setCallbackType("error");
    }
    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
  ]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      setDefaultConfigTable({
        ...defaultConfigTable,
        currentPage: 1,
        id_empresa: empresaStorage,
      });
    } else {
      setDefaultConfigTable({
        ...defaultConfigTable,
        currentPage: 1,
        id_empresa: empresaSelecionada,
      });

      setEmpresaPagina("categorias", empresaSelecionada);
    }
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setListRequest([]);
    setEmpresaPagina("categorias", null);
  };

  const handleSelectEmpresa = async (id) => {
    console.log(id);
    setEmpresaSelecionada(id);
  };

  const onTableMoreAction = async ({ action }) => {
    console.log(action);
    if (action === "importar-xls") {
      setOpenModalImportar(true);
    }
  };

  const onEdit = (id) => {
    history.push(`/editar-categoria-empresa/${id}`);
  };

  function handleAtualizaStatusDialog(id) {
    const categoriaSelecionado = listRequest.data.find(
      (item) => item.id_categoria === Number(id)
    );

    setItemAcao(categoriaSelecionado);
    setConfirmStatusDialog(true);
  }

  function handleAtualizaStatusCancel() {
    setItemAcao(null);
    setConfirmStatusDialog(false);
  }

  async function handleAtualizaStatus() {
    console.log({ itemAcao });
    const response = await atualizarStatusCategoria(itemAcao.id_categoria);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      return;
    }
    if (response.status === 201) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        response?.data?.message || "Status da categoria atualizada!"
      );
      setCallbackDuration(2000);
      setTimeout(() => {
        updateTable();
      }, 1500);
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
    handleAtualizaStatusCancel();
  }

  const onImport = async ({ arquivo, idEmpresa }) => {
    console.log({ arquivo, idEmpresa });
    let errorList = [];
    if (!idEmpresa) {
      errorList.push("É necessário informar a empresa");
    }
    if (!arquivo) {
      errorList.push("É necessário selecionar um arquivo");
    }
    if (errorList.length > 0) {
      setCallbackErrorListModal(errorList);
      setCallbackShownModal(true);
      return;
    }

    setLoading(true);
    const response = await importarCategoria({
      arquivo,
      id_empresa: idEmpresa,
    });
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setOpenModalImportar(false);
      setCallbackMessage(response.data?.msg || "Importado com sucesso!");
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackShown(true);
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
    } else {
      setCallbackShownModal(true);
      if (response.data.error)
        setCallbackErrorListModal(Object.values(response.data.error));
      else setCallbackErrorListModal(["Ocorreu um erro ao importar"]);
    }
    setLoading(false);
  };

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("categorias");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    }
  }, []);

  return (
    <div className="container-tabela-responsiva">
      {loading && <OverlayLoading />}

      <Search
        callback={() => {}}
        tableOptions={tableOptions}
        filterComponent={filterComponent({
          empresaSelecionada,
          handleApplyFilters,
          handleCleanFilters,
          handleSelectEmpresa,
          listEmpresas,
        })}
        callbackMenu={onTableMoreAction}
      />
      {defaultConfigTable.id_empresa ? (
        <TableComponent
          idName="id_categoria"
          dataList={listRequest !== undefined ? listRequest?.data : []}
          tableConfig={defaultConfigTable}
          callbackCurrentPage={onPageChange}
          cbEdit={onEdit}
          callbackOrderBy={onOrderBy}
          callbackPerPage={onPerPage}
          callbackSearchTerm={onSearchTerm}
          cbSwitch={(id) => handleAtualizaStatusDialog(id)}
        />
      ) : null}

      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />

      <ConfirmDialog
        open={confirmStatusDialog}
        onCancel={handleAtualizaStatusCancel}
        onAccept={handleAtualizaStatus}
        acceptLabel={itemAcao?.switch_value ? "Desativar" : "Ativar"}
        title="CONFIRMAR ALTERAÇÃO DE STATUS"
        description={`Tem certeza que deseja ${
          itemAcao?.switch_value ? "desativar" : "ativar"
        } essa categoria?`}
      />

      <ModalImportarXlsx
        open={openModalImportar}
        onClose={() => setOpenModalImportar(false)}
        title="IMPORTAR CATEGORIAS"
        acceptLabel="Importar"
        onAccept={onImport}
        callbackErrorListModal={callbackErrorListModal}
        callbackShownModal={callbackShownModal}
        setCallbackShown={setCallbackShownModal}
        arquivoPadrao={arquivoModeloCategorias}
      />
    </div>
  );
};
