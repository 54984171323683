import axios from "./Axios";
import { base64ToFile } from '../utils/functions';

const exportData = async (data) => {
  try {
    const response = await axios.get(`${data.url}`, {
      params: {
        ...data.params
      }
    })
    base64ToFile(response.data.arquivo, data.fileName, data.fileType)

    return response
  } catch (error) {
    console.error(error)
    return error
  }
};

export default exportData;