import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Input from "../../../../components/Input";
import ModalLoginExpirado from "../../../../components/InputFile";
import ButtonComponent from "../../../../components/ButtonComponent";
import SelectComponent from "../../../../components/SelectComponent";
import OverlayLoading from "../../../../components/OverlayLoading";
import CallbackMessage from "../../../../components/CallbackMessage";
import { Grid } from "@material-ui/core";
import "./styles.scss";
import contasBancariasService from "../../../../services/contasBancariasService";
import selectServices from "../../../../services/selectServices";
import preAdmissaoService from "../../../../services/preAdmissaoService";
import verifyFields from "../../../../utils/validateFields";


function CadastroDadosBancarios() {
  const history = useHistory();
  const params = useParams();
  const { idFuncionario } = params;
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errors, setErrors] = useState([]);
  const [listTipoConta, setListTipoConta] = useState([]);
  const [listBanco, setListBanco] = useState([]);
  const [tipoConta, setTipoConta] = useState();
  const [banco, setBanco] = useState();
  const [agencia, setAgencia] = useState();
  const [conta, setConta] = useState();
  const [digito, setDigito] = useState();

  function handleClose() {
    setShowMessage(false);
    if (errors.length === 0) {
      history.push("/pre-admissao");
    }
  }

  const loadPreAdmissaoDadosBancarios = async () => {
    if(!idFuncionario) {
      return;
    }

    if (idFuncionario) {
      const response = await preAdmissaoService.consultaPreAdmissao(idFuncionario);
    
      console.log("response:::", response);

      if (response.status === 200) {        
        const data = response.data;
        
        data?.agencia !== null && setAgencia(data?.agencia);
        data?.conta !== null && setConta(data?.conta);
        data?.id_banco !== null && setBanco(data?.id_banco);
        data?.id_tipo_conta_banco !== null && setTipoConta(data?.id_tipo_conta_banco);
        data?.digito !== null && setDigito(data?.digito);
      } else {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      }
    }
  };

  async function loadListTipoBanco() {
    await selectServices.selecionaTipoConta().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      } else setListTipoConta(response.data);
    });
  }

  async function loadBancos() {
    await contasBancariasService.getBancos().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      } else setListBanco(response.data);
    });
  }

  function validateFields() {
    setErrors([]);

    const fields = [
      {
        label: "banco",
        value: banco,
      },
      {
        label: "tipodeconta",
        value: tipoConta,
      },
      {
        label: "agencia",
        value: agencia,
      },
      {
        label: "conta",
        value: conta,
      },
      {
        label: "digito",
        value: digito,
      },
    ];
    const fieldsErrors = verifyFields(fields);
    if (fieldsErrors.length > 0) {
      setErrors(fieldsErrors);
      setShowMessage(true);
    }
    return fieldsErrors.length === 0;
  }

  async function handleSubmit() {
    if (!validateFields()) return;
    setLoading(true);
    await preAdmissaoService
      .cadastraDadosBancarios(
        idFuncionario,
        tipoConta,
        banco,
        agencia,
        conta,
        digito
      )
      .then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }

        if (response.status !== 201 && response.status !== 200) {
          if (response.data.error)
            setErrors(Object.values(response.data.error));
          else setErrors(["Ocorreu um erro"]);
        }
        setLoading(false);
        setShowMessage(true);
      });
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadBancos();
      await loadListTipoBanco();
      await loadPreAdmissaoDadosBancarios();
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <Grid
            container
            direction="column"
            className="form-cadastro-endereco"
            spacing={2}
          >
            <Grid item>
              <div className="title-cadastro-endereco">Dados Bancários</div>
            </Grid>
            <Grid item container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={12} md={6}>
                <SelectComponent
                  styleType="form"
                  label="Tipo da conta *"
                  title="Selecione uma opção"
                  list={listTipoConta}
                  initialValue={
                    listTipoConta.find((item) => item.id === tipoConta)
                      ? listTipoConta.find((item) => item.id === tipoConta).nome
                      : ""
                  }
                  callback={(id) => setTipoConta(id)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <SelectComponent
                  styleType="form"
                  label="Banco *"
                  title="Selecione uma instituição bancária"
                  initialValue={
                    listBanco.find((item) => item.id === banco)
                      ? listBanco.find((item) => item.id === banco).nome
                      : ""
                  }
                  list={listBanco}
                  callback={(id) => setBanco(id)}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Agência *"
                  tipo="numero"
                  value={agencia}
                  handleInputChange={(e) => {
                    setAgencia(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Conta Corrente *"
                  tipo="numero"
                  value={conta}
                  handleInputChange={(e) => {
                    setConta(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Dígito *"
                  tipo="numero"
                  value={digito}
                  handleInputChange={(e) => {
                    setDigito(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <span className="required-text">* Campos obrigatórios</span>
            </Grid>
          </Grid>
        </span>
      </div>
      <Grid item container justify="flex-end">
        <ButtonComponent
          text="Salvar"
          className="btn-success zero-margin"
          onClick={handleSubmit}
        />
      </Grid>
      {openLoginExpirado && <ModalLoginExpirado open={openLoginExpirado} />}
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0
            ? "Dados bancários cadastrados com sucesso!"
            : "Erro!"
          }`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
    </>
  );
}
export default CadastroDadosBancarios;
