import { AccordionSummary, FormControlLabel, Grid } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React from "react";
import Icon from "../../../../../../components/Icon";

export const AccordionHeader = ({ expanded, name }) => {
  return (
    <AccordionSummary>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item container xs className="accordion-title">
          <Grid item>
            {expanded === "panel1" ? (
              <ExpandMore className="font-orange accordion-arrow" />
            ) : (
              <ExpandLess className="font-darkBlue accordion-arrow" />
            )}
          </Grid>
          <Grid item className="font-title">
            {name}
          </Grid>
        </Grid>
      </Grid>
    </AccordionSummary>
  );
};
