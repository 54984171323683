import Axios from "./Axios";

const centroCustosService = {
  getCentroCustos: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null
  ) => {
    const result = await Axios.get("/lista-centro-custo", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa: id_empresa
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  consultaCentroCustos: async (id) => {
    const response = await Axios.get(`/consulta-centro-custo/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  addCentroCusto: async (idEmpresa, nome) => {
    const add = await Axios.post("/cadastra-centro-custo", {
      id_empresa: idEmpresa,
      nome,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return add;
  },
  atualizaCentroCusto: async (id, idEmpresa, nome) => {
    const response = await Axios.post(`/atualiza-centro-custo/${id}`, {
      id_empresa: idEmpresa,
      nome,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  removeCentroCusto: async (id) => {
    const response = await Axios.get(`/remove-centro-custo/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  selecionaCentroCusto: async (id) => {
    const response = await Axios.get(`/seleciona-centro-custo?id_empresa=${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
};

export default centroCustosService;
