import { useEffect, useState, useCallback, useContext } from "react";
import empresasService from "../services/empresasService";
import { CompanyContext } from "../contexts/CompanyContext";

export default function useCompany(page) {
  const { selectEmpresa } = empresasService;
  const { getEmpresaPagina, setEmpresaPagina } = useContext(CompanyContext);

  const [companyList, setCompanylist] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const loadCompanies = useCallback(async () => {
    const response = await selectEmpresa();
    if (response.status === 200) {
      setCompanylist(response.data);
    }
  }, [selectEmpresa]);

  const loadSelectedCompany = () => {
    const company = getEmpresaPagina(page) || null;
    if (company) {
      setSelectedCompany(company);
    } else if (companyList?.length === 1) {
      setSelectedCompany(companyList[0].id);
      setEmpresaPagina(page, companyList[0].id);
    }
  };

  useEffect(() => {
    loadCompanies();
  }, []);

  useEffect(() => {
    if (companyList?.length > 0) {
      loadSelectedCompany();
    }
  }, [companyList]);

  return {
    companyList,
    selectedCompany,
    setSelectedCompany,
    getEmpresaPagina,
    setEmpresaPagina,
  };
}
