import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import Table from "../../../components/TableComponent";
import RemoveDialog from "../../../components/ConfirmDialog";
import ModalEstoque from "../../../components/ModalEstoque";
import { Grid } from "@material-ui/core";
import Select from "../../../components/SelectComponent";
import Button from "../../../components/ButtonComponent";
import "./styles.css";
import empresasServices from "../../../services/empresasService";
import codigoServicosService from "../../../services/codigosServicoService";

import { base64ToFile, numberToCurrency } from "../../../utils/functions";
import DtDatePicker from "../../../components/DatePicker";
import AutocompleteInput from "../../../components/AutocompleteInput";
import prefeiturasService from "../../../services/prefeiturasService";
import { dateToDateDD_MM_YYYYandHours } from "../../../utils/dates";

const ListaCodigosServicosPrefeitura = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);
  
  const [dataInicio, setDataInicio] = useState(null)
  const [dataFim, setDataFim] = useState(null)

  const [empresaSelecionada, setEmpresaSelecionada] = useState([]);
  const [empresaSelecionadaClear, setEmpresaSelecionadaClear] = useState([]);
  const [listPrefeituras, setListPrefeituras] = useState([]);
  const { listaServicos } = codigoServicosService;
  const { selecionaPrefeitura } = prefeiturasService;

  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [productId, setProductId] = useState(null);
  const [openModalInventory, setOpenModalInventory] = useState(false);

  const handleClose = (event, reason) => {
    console.log(reason);
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  };

  const [listRequest, setListRequest] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id" },
      { columnName: "Prefeitura", dataRef: "prefeitura" },
      { columnName: "Data/hora", dataRef: "data_hora" },
      { columnName: "Usuário", dataRef: "usuario" },
      { columnName: "Arquivo", dataRef: "arquivo" },
      { columnName: "Status", dataRef: "status" },
    ],
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      statusLabels: true,
      filter: false,
    },
    tableOptions: {
      filter: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    id_prefeitura: null,
  });

  let updateTable = useCallback(async () => {
    
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await listaServicos({
      page: defaultConfigTable.currentPage,
      orderBy: defaultConfigTable.orderBy,
      orderByType: defaultConfigTable.orderByType,
      perPage: defaultConfigTable.perPage,
      searchTerm: defaultConfigTable.searchTerm,
      data_final: dataFim,
      data_inicial: dataInicio,
      id_prefeitura: defaultConfigTable.id_prefeitura,
    }
    );

    if (result.status === 200) {
      if (result?.data?.hasOwnProperty("data")) {
        const { data } = result;
  
        const newData = data.data.map((item) => {
          return {
            ...item,
            data_hora: dateToDateDD_MM_YYYYandHours(item.data_hora)
          };
        });
  
        const newResult = {
          ...data,
          data: newData,
        };
        setListRequest(newResult);
      } else {
        const newData = result.data.map((item) => {
          return {
            ...item,
            data_hora: dateToDateDD_MM_YYYYandHours(item.data_hora)
          };
        });
  
        const newResult = {
          ...result,
          data: newData,
        };
  
        setListRequest(newResult);
      }
    }else{
      setCallbackErrorList(["Ocorreu um erro ao listar os serviços."]);
      setCallbackShown(true);
      setCallbackType("error");
    }
    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_prefeitura,
    dataInicio,
    dataFim
  ]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    loadEmpresas();
  }, []);

  const loadEmpresas = useCallback(async () => {
    const response = await selecionaPrefeitura();
    if (response.status === 200) {
      const data = response?.data?.map( item => ({
        ...item,
        id: item.id_cidade
      }))
      console.log(data);
      setListPrefeituras(data);
    }
  }, [selecionaPrefeitura]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const handleSelectEmpresa = async (values) => {
    const empresasIds = values.map((item) => {
      return item.id;
    });
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_prefeitura: empresasIds,
    });
  };

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <AutocompleteInput
              label="Prefeitura"
              data={listPrefeituras}
              key={(option) => option.id}
              getOptionLabel={(option) => option.nome}
              getOptionSelected={(option, value) => option.id === value.id}
              required
              handleSelectedValues={(values) => handleSelectEmpresa(values)}
            />
          </Grid>

          <Grid item xs={6}>
            <DtDatePicker
              label='Periodo de upload de:'
              initDate=''
              handleChange={(value) => {
                setDataInicio(value)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <DtDatePicker
              label='Periodo de upload até:'
              initDate=''
              handleChange={(value) => {
                setDataFim(value)
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className="container-tabela-responsiva">
      {loading && <OverlayLoading />}
      <Table
        idName="id_servico_prefeitura"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        filterComponent={filterComponent()}
      />
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />
    </div>
  );
};

export default ListaCodigosServicosPrefeitura;
