import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CallbackMessage from "../../../../components/CallbackMessage";
import { Accordion, AccordionDetails } from "@material-ui/core";
import TableComponent from "../../../../components/TableResponsiveComponent";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import ModalLoginExpirado from "../../../../components/ModalLoginExpirado";
import { AccordionHeader } from "./components/AccordionHeader";
import categoriasServices from "../../../../services/categoriasServices";
import categoriaEmpresaService from "../../../../services/categoriaEmpresaService";

export const AccordionCategory = ({
  groupName,
  categoryId,
  companyId,
  categories,
  groupStatus,
  updateCategories = () => {},
}) => {
  const { atualizarStatusSubcategoria } = categoriasServices;
  const { atualizarStatusCategoria } = categoriaEmpresaService
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [
    confirmAtualizaStatusCategoriaDialog,
    setConfirmAtualizaStatusCategoriaDialog,
  ] = useState(false);
  const [confirmStatusGrupoDialog, setConfirmStatusGrupoDialog] =
    useState(false);

  const [possuiCategoriaComLancamento, setPossuiCategoriaComLancamento] =
    useState(false);
  const [atualizaStatusSubcategoria, setAtualizaStatusCategoria] =
    useState(null);
  const [removeGrupoId, setRemoveGrupoId] = useState(null);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const defaultConfigTable = {
    columnArray: [
      { columnName: "Subcategoria", dataRef: "nome" },
      { columnName: "status_categoria", dataRef: "switch_value", hide: true },
    ],
    options: {
      edit: true,
      switch: true,
    },
    display: {
      statusLabels: true,
      search: false,
      itemsPerPage: false,
      totalResults: false,
      pagination: false,
    },
    currentPage: 1,
    pagination: false,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function handleNewCategoryNavigation(companyId, categoryId) {
    history.push(`/cadastro-subcategoria/${companyId}/${categoryId}`);
  }

  function handleEditGroupNavigation(categoryId) {
    console.log({ categoryId });
    history.push(`/editar-categoria-empresa/${categoryId}`, {
      from: "subcategorias",
    });
  }

  async function handleAtualizaStatusCategoria() {
    const response = await atualizarStatusCategoria(removeGrupoId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      return;
    }

    if (response.status === 200) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        response?.data?.message || "Status da categoria atualizada com sucesso!"
      );
      setCallbackDuration(2000);
      setTimeout(() => {
        updateCategories();
      }, 1500);
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
    handleAtualizaStatusCancel();
  }

  function handleClose() {
    setCallbackShown(false);
  }

  function handleEditarCategoria(id) {
    console.log({ id });
    history.push(`/editar-subcategoria/${companyId}/${categoryId}/${id}`);
  }

  function handleAtualizaStatusCategoriaDialog(id) {
    const categoriaSelecionada = categories.find(
      (item) => item.id_categoria === Number(id)
    );
    setConfirmAtualizaStatusCategoriaDialog(true);
    setAtualizaStatusCategoria(categoriaSelecionada);
  }

  function handleAtualizaStatusCancel() {
    setConfirmAtualizaStatusCategoriaDialog(false);
    setConfirmStatusGrupoDialog(false);
    setAtualizaStatusCategoria(null);
  }

  function handleAtualizaStatusCategoriaGrupoDialog(id) {
    setRemoveGrupoId(id);
    setConfirmStatusGrupoDialog(true);
  }

  async function handleAtualizaStatusSubcategoria() {
    await atualizarStatusSubcategoria(atualizaStatusSubcategoria.id).then(
      (response) => {
        console.log(response);
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          return;
        }
        setCallbackErrorList([]);
        if (response.status === 201) {
          setCallbackType("success");
          setCallbackMessage(
            response?.data?.message ||
              "Status da subcategoria atualizado com sucesso!"
          );
          setCallbackDuration(4000);
          setCallbackShown(true);
          setTimeout(() => {
            updateCategories();
          }, 1500);
        } else {
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          if (response.data.error)
            setCallbackErrorList(Object.values(response.data.error));
          else
            setCallbackErrorList([
              "Houve um erro ao tentar atualizar o status da subcategoria. Por favor, tente novamente.",
            ]);
        }
      }
    );
    handleAtualizaStatusCancel();
  }

  function listTipo(categories) {
    return categories.map((item) =>
      item.tipo === "ENTRADA" ? (
        <div className="card-entrada">ENTRADA</div>
      ) : (
        <div className="card-saida">SAIDA</div>
      )
    );
  }

  useEffect(() => {
    if (categories.length > 0) {
      let possuiLancamentos = false;
      categories.forEach((element) => {
        if (element.possui_lancamentos && groupStatus) {
          possuiLancamentos = true;
        }
      });
      console.log({ possuiLancamentos });
      setPossuiCategoriaComLancamento(possuiLancamentos);
    } else {
      setPossuiCategoriaComLancamento(false);
    }
  }, [categories, groupStatus]);

  return (
    <>
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
        key={`${categoryId}_callback`}
      />
      <Accordion
        key={categoryId}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className="subcategoria-accordion"
      >
        <AccordionHeader
          companyId={companyId}
          expanded={expanded}
          categoryId={categoryId}
          groupStatus={groupStatus}
          handleAtualizaStatusCategoriaGrupoDialog={
            handleAtualizaStatusCategoriaGrupoDialog
          }
          handleEditGroupNavigation={handleEditGroupNavigation}
          handleNewCategoryNavigation={handleNewCategoryNavigation}
          name={groupName}
        />

        <AccordionDetails className="accordion-details-categoria">
          <TableComponent
            idName="id"
            dataList={categories}
            tableConfig={defaultConfigTable}
            cbEdit={(id) => handleEditarCategoria(id)}
            cbSwitch={(id) => handleAtualizaStatusCategoriaDialog(id)}
            enableOrder={false}
            customColumns={[
              {
                columnHead: <span>Tipo</span>,
                columnData: listTipo(categories),
              },
            ]}
          />
        </AccordionDetails>
      </Accordion>
      <ConfirmDialog
        open={confirmAtualizaStatusCategoriaDialog}
        onCancel={handleAtualizaStatusCancel}
        onAccept={handleAtualizaStatusSubcategoria}
        acceptLabel={
          atualizaStatusSubcategoria?.status ? "Desativar" : "Ativar"
        }
        title="CONFIRMAR ALTERAÇÃO DE STATUS"
        description={`Tem certeza que deseja ${
          atualizaStatusSubcategoria?.status ? "desativar" : "ativar"
        } esta subcategoria?${
          atualizaStatusSubcategoria?.status &&
          atualizaStatusSubcategoria?.possui_lancamentos
            ? `\r\n\r\nExistem registros relacionados a esta subcategoria!`
            : ""
        }`}
      />
      <ConfirmDialog
        open={confirmStatusGrupoDialog}
        onCancel={handleAtualizaStatusCancel}
        onAccept={handleAtualizaStatusCategoria}
        acceptLabel={groupStatus ? "Desativar" : "Ativar"}
        title="CONFIRMAR ALTERAÇÃO DE STATUS"
        description={`Tem certeza que deseja ${
          groupStatus ? "desativar" : "ativar"
        } esta categoria e todas as suas subcategorias?${
          possuiCategoriaComLancamento
            ? `\r\n\r\nEsta categoria possui subcategoria(s) com lançamentos registrados!`
            : ""
        }`}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
};
