import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Checkbox, Grid } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Input from "../../../components/Input";
import InputFile from "../../../components/InputFile";
import ModalLoginExpirado from "../../../components/InputFile";
import Button from "../../../components/ButtonComponent";
import SelectComponent from "../../../components/SelectComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import DatePicker from "../../../components/DatePicker";
import Textarea from '../../../components/Textarea';

import empresasService from "../../../services/empresasService";
import selectServices from '../../../services/selectServices';
import viacepServices from '../../../services/viacepServices';
import preAdmissaoService from '../../../services/preAdmissaoService';

import validateFields from '../../../utils/validateFields';

import User from '../../../assets/img/user.svg';

import classes from './styles.module.css';
import "./styles.scss";

function CadastroPreAdmissao() {
  const CustomCheckbox = withStyles({
    root: {
      color: "#E8EBF1",
      width: 0,
      marginLeft: 12,
      borderRadius: 4,
      "&$checked": {
        color: "#812990 !important",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const {
    selecionaCor,
    selecionaEscolaridade,
    selecionaEstadoCivil,
    selecionaNacionalidade,
    selecionaPais,
    selecionaSexo,
    selecionaTipoDeficiencia,
  } = selectServices;

  const { selecionaCidade, selecionaEstado } = viacepServices;
  const { cadastrarAdmissao, consultaPreAdmissao, atualizarAdmissao } = preAdmissaoService;

  const history = useHistory();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const { selectEmpresa } = empresasService;

  const { id: idPreAdmissao } = useParams();

  const [loadingMunicipio, setLoadingMunicipio] = useState(false);

  const [empresas, setEmpresas] = useState([]);
  // const [categorias, setCategorias] = useState([]);
  const [listaSexo, setListaSexo] = useState([]);
  const [listaEstadoCivil, setListaEstadoCivil] = useState([]);
  const [listaCor, setListaCor] = useState([]);
  const [listaEscolaridade, setListaEscolaridade] = useState([]);
  const [listaPais, setListaPais] = useState([]);
  const [listaNacionalidade, setListaNacionalidade] = useState([]);
  const [listaEstado, setListaEstado] = useState([]);
  const [listaMunicipio, setListaMunicipio] = useState([]);
  const [listaTipoDeficiencia, setListaTipoDeficiencia] = useState([]);

  const [company, setCompany] = useState(null);
  const [name, setName] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState();
  const [dataNascimento, setDataNascimento] = useState("");
  const [sexo, setSexo] = useState(null);
  const [estadoCivil, setEstadoCivil] = useState(null);
  const [cor, setCor] = useState(null);
  const [escolaridade, setEscolaridade] = useState(null);
  const [motherName, setMotherName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [paisNascimento, setPaisNascimento] = useState(null);
  const [nacionalidade, setNacionalidade] = useState(null);
  const [estadoNascimento, setEstadoNascimento] = useState(null);
  const [municipio, setMunicipio] = useState(null);
  const [checked, setChecked] = useState(false);
  const [descricao, setDescricao] = useState("");
  const [deficiencia, setDeficiencia] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);

  const [loadedImage, setLoadedImage] = useState();

  const [loading, setLoading] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const preview = useMemo(() => {
    return thumbnail ? URL.createObjectURL(thumbnail) : null;
  }, [thumbnail]);

  const loadPreAdmissao = async () => {
    if(!idPreAdmissao) {
      return;
    }


    if (idPreAdmissao) {
      const response = await consultaPreAdmissao(idPreAdmissao);

      if (response.status === 200) {        
        const data = response.data;

        console.log('preadmissao::::', data);

        setCompany(data.id_empresa);
        setCor(data.id_cor);
        setDataNascimento(data.data_nascimento);
        setChecked(data.possui_deficiencia === 0 ? false : true);
        setDescricao(data.observacao);
        setDeficiencia(data.possui_deficiencia === 0 ? null : data.id_tipo_deficiencia);
        setEscolaridade(data.id_grau_instrucao);
        setNacionalidade(data.id_nacionalidade);
        setPaisNascimento(data.id_pais_nascimento);
        setName(data.nome);
        setSexo(data.id_sexo);
        setFatherName(data.nome_pai);
        setMotherName(data.nome_mae);
        setEstadoCivil(data.id_estado_civil);
        setEstadoNascimento(data.id_uf_nascimento);
        setMunicipio(data.id_municipio_nascimento);
        setFile(data.foto);
        setLoadedImage(data.foto);
        setFileName(data.foto_nome);

        handleEstadoChange(data.id_uf_nascimento);
      } else {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      }
    }
  };

  const loadSelects = useCallback(async () => {
    const responseEmpresas = await selectEmpresa();
    const responseCor = await selecionaCor();
    const responseEscolaridade = await selecionaEscolaridade();
    const responseEstadoCivil = await selecionaEstadoCivil();
    const responseNacionalidade = await selecionaNacionalidade();
    const responsePais = await selecionaPais();
    const responseSexo = await selecionaSexo();
    const responseTipoDeficiencia = await selecionaTipoDeficiencia();
    const responseEstado = await selecionaEstado();

    if (
      responseEmpresas.status === 401 ||
      responseCor.status === 401 ||
      responseEscolaridade.status === 401 ||
      responseEstadoCivil.status === 401 ||
      responseNacionalidade.status === 401 ||
      responsePais.status === 401 ||
      responseSexo.status === 401 ||
      responseTipoDeficiencia.status === 401 ||
      responseEstado.status === 401
    ) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    setEmpresas(responseEmpresas.data);
    setListaCor(responseCor.data);
    setListaEscolaridade(responseEscolaridade.data);
    setListaEstadoCivil(responseEstadoCivil.data);
    setListaNacionalidade(responseNacionalidade.data);
    setListaPais(responsePais.data);
    setListaSexo(responseSexo.data);
    setListaTipoDeficiencia(responseTipoDeficiencia.data);
    setListaEstado(responseEstado.data);

  }, [selecionaCor,
    selecionaEscolaridade,
    selecionaEstado,
    selecionaEstadoCivil,
    selecionaNacionalidade,
    selecionaPais,
    selecionaSexo,
    selecionaTipoDeficiencia,
    selectEmpresa]);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await loadSelects();
      await loadPreAdmissao();
      setLoading(false);
    })();
  }, [loadSelects]);

  function handleClose(event, reason) {

    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/pre-admissao");
    }
  }

  const handleFileChange = (file) => {
    setFile(file);
    console.log('file selected:::::', file);
  }

  const handlePreview = (fileObj) => {
    setThumbnail(fileObj);
  }

  async function handleSubmit() {
    setLoading(true);

    let hasErrors = false;
    let fieldsToVerify = [
      {
        label: 'empresa',
        value: company,
      },
      {
        label: 'nome',
        value: name,
      },
      {
        label: 'sexo',
        value: sexo,
      },
      {
        label: 'estadocivil',
        value: estadoCivil,
      },
      {
        label: 'cor',
        value: cor,
      },
      {
        label: 'escolaridade',
        value: escolaridade,
      },
      {
        label: 'paisdenascimento',
        value: paisNascimento,
      },
      {
        label: 'nacionalidade',
        value: nacionalidade,
      },
      {
        label: 'estadodenascimento',
        value: estadoNascimento,
      },
      {
        label: 'municipiodenascimento',
        value: municipio,
      },
      {
        label: 'descricao',
        value: descricao,
      },
    ]

    if (checked) {
      fieldsToVerify = [...fieldsToVerify, { label: 'deficiencia', value: deficiencia }]
    }

    let fieldsErrors = validateFields(fieldsToVerify);

    if (fieldsErrors.length !== 0) {
      hasErrors = true;
      setLoading(false);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(fieldsErrors);
      setCallbackShown(true);
      return;
    }

    if (hasErrors) return;

    if(idPreAdmissao) {
      const idConvert = Number.parseInt(idPreAdmissao, 10);

      const response = await atualizarAdmissao(
        company,
        name,
        dataNascimento,
        sexo,
        estadoCivil,
        cor,
        escolaridade,
        paisNascimento,
        paisNascimento,
        estadoNascimento,
        municipio,
        descricao,
        nacionalidade,
        file,
        motherName,
        fatherName,
        checked,
        deficiencia,
        idConvert,
      );

      setLoading(false);
  
      if (response?.status === 401) {
        setOpenLoginExpirado(true);
        return;
      }
      if (response?.status !== 200) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        if (response?.data?.error){ 
          setCallbackErrorList(Object.values(response.data.error));
        } else setCallbackErrorList(["Ocorreu um erro, escolha um imagem com tamanho menor."]);
        setCallbackDuration(2000);
        return;
      } else {
        setCallbackShown(true);
        setCallbackType("success");
        setCallbackMessage("Pre-admissão atualizada com sucesso.");
        setCallbackDuration(2000);
        return;
      }
    }

    const response = await cadastrarAdmissao(
      company,
      name,
      dataNascimento,
      sexo,
      estadoCivil,
      cor,
      escolaridade,
      paisNascimento,
      paisNascimento,
      estadoNascimento,
      municipio,
      descricao,
      nacionalidade,
      file,
      motherName,
      fatherName,
      checked,
      deficiencia,
      null,
    );

    setLoading(false);

    if (response?.status === 401) {
      setOpenLoginExpirado(true);
      return;
    }
    if (response?.status !== 200) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response?.data?.error){ 
        setCallbackErrorList(Object.values(response.data.error));
      } else setCallbackErrorList(["Ocorreu um erro, escolha um imagem com tamanho menor."]);
      setCallbackDuration(2000);
      return;
    } else {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage("Pre-admissão cadastrada com sucesso.");
      setCallbackDuration(2000);
      return;
    }
  }

  const handleEstadoChange = async (id) => {
    setEstadoNascimento(id);

    if(!idPreAdmissao) {
      setMunicipio(null);
    }
    setLoadingMunicipio(true);
    const responseMunicipio = await selecionaCidade(id);
    if (responseMunicipio.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    setListaMunicipio(responseMunicipio.data);
    setLoadingMunicipio(false);
  }

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <CallbackMessage
            open={callbackShown}
            duration={callbackDuration}
            errorList={callbackErrorList}
            handleClose={handleClose}
            message={callbackMessage}
            type={callbackType}
          />
          <form className="cadastrar-preAdmissao-form">
            <section className={classes.fieldsContainer}>
              <fieldset className={classes.empresaSelectContainer}>
                <SelectComponent
                  styleType="form"
                  label="Empresa"
                  required
                  initialValue={company !== null ? empresas.find(item => item.id === company)?.nome : ""}
                  title="Selecione uma empresa"
                  list={empresas}
                  callback={id => setCompany(id)}
                />
              </fieldset>

                <Grid item container spacing={2} className={classes.inputsContainer}>

                 <Grid item xs={12} sm={12} md={9}>
                    <Input
                      required
                      nome="nome"
                      label="Nome"
                      tipo="text"
                      type="text"
                      value={name}
                      handleInputChange={event => setName(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={9}>
                    <InputFile
                      title="Foto"
                      fileName={fileName ? fileName : ""}
                      labelButton="Escolher Imagem"
                      onChange={(file) => handleFileChange(file)}
                      cbFileObj={(file) => handlePreview(file)}
                    />
                  </Grid>

                <Grid item xs={12} sm={12} md={3}>
                <div className={classes.userPicContainerGrid}>
                  <label
                    id="thumbnail"
                    style={{ backgroundImage: `url(${preview || loadedImage})` }}
                    className="circle-image"
                  >
                    <img alt="" className={classes.userMock} src={thumbnail || loadedImage ? null : User} />
                  </label>
                  </div>
                </Grid>
                
                </Grid>
              
              <fieldset className={classes.gridLineOne}>
                <div className={classes.birthDateInputContainer}>
                  <DatePicker
                    initDate={dataNascimento !== "" ? dataNascimento : null}
                    noMask
                    label="Data de nascimento *"
                    handleChange={value => setDataNascimento(value)}
                  />
                </div>
                <div className={classes.genderInputContainer}>
                  <SelectComponent
                    styleType="form"
                    label="Sexo"
                    initialValue={sexo !== null ? listaSexo.find(item => item.id === sexo)?.nome : ""}
                    required
                    title="Selecione uma opção"
                    list={listaSexo}
                    callback={id => setSexo(id)}
                  />
                </div>
                <div className={classes.userStatusInputContainer}>
                  <SelectComponent
                    styleType="form"
                    label="Estado civil"
                    initialValue={estadoCivil !== null ? listaEstadoCivil.find(item => item.id === estadoCivil)?.nome : ""}
                    required
                    title="Selecione uma opção"
                    list={listaEstadoCivil}
                    callback={id => setEstadoCivil(id)}
                  />
                </div>
              </fieldset>

              <fieldset className={classes.gridLineTwo}>
                <div className={classes.colorInputContainer}>
                  <SelectComponent
                    styleType="form"
                    search={false}
                    initialValue={cor !== null ? listaCor.find(item => item.id === cor)?.nome : ""}
                    label="Cor"
                    required
                    list={listaCor}
                    title="Selecione uma opção"
                    callback={id => setCor(id)}
                  />
                </div>
                <div className={classes.schoolingInputContainer}>
                  <SelectComponent
                    styleType="form"
                    initialValue={escolaridade !== null ? listaEscolaridade.find(item => item.id === escolaridade)?.nome : ""}
                    search={false}
                    label="Escolaridade"
                    required
                    list={listaEscolaridade}
                    title="Selecione uma opção"
                    callback={id => setEscolaridade(id)}
                  />
                </div>
              </fieldset>

              <fieldset className={classes.gridTwoFr}>
                <div className={classes.motherNameInputContainer}>
                  <Input
                    label="Nome da mãe"
                    nome="mae"
                    tipo="text"
                    value={motherName}
                    type="text"
                    handleInputChange={event => setMotherName(event.target.value)}
                  />
                </div>
                <div className={classes.fatherNameInputContainer}>
                  <Input
                    label="Nome do pai"
                    nome="pai"
                    value={fatherName}
                    tipo="text"
                    type="text"
                    handleInputChange={event => setFatherName(event.target.value)}
                  />
                </div>
              </fieldset>

              <fieldset className={classes.gridTwoFr}>
                <div className={classes.bornCountryInputContainer}>
                  <SelectComponent
                    label="País de nascimento"
                    styleType="form"
                    required
                    initialValue={paisNascimento !== null ? listaPais.find(item => item.id === paisNascimento)?.nome : ""}
                    list={listaPais}
                    callback={(id) => setPaisNascimento(id)}
                  />
                </div>
                <div className={classes.nationalityInputContainer}>
                  <SelectComponent
                    label="Nacionalidade"
                    styleType="form"
                    initialValue={nacionalidade !== null ? listaNacionalidade.find(item => item.id === nacionalidade)?.nome : ""}
                    required
                    list={listaNacionalidade}
                    callback={(id) => setNacionalidade(id)}
                  />
                </div>
              </fieldset>

              <fieldset className={classes.gridTwoFr}>
                <div className={classes.bornStateInputContainer}>
                  <SelectComponent
                    label="Estado de nascimento"
                    styleType="form"
                    required
                    initialValue={estadoNascimento !== null ? listaEstado.find(item => item.id === estadoNascimento)?.nome : ""}
                    list={listaEstado}
                    callback={(id) => handleEstadoChange(id)}
                  />
                </div>
                <div className={classes.countyInputContainer}>
                  <SelectComponent
                    label="Município de nascimento"
                    styleType="form"
                    required
                    initialValue={municipio !== null ? listaMunicipio.find(item => item.id === municipio)?.nome : ""}
                    loading={loadingMunicipio}
                    list={listaMunicipio}
                    callback={(id) => setMunicipio(id)}
                  />
                </div>
              </fieldset>

              <div className={`preAdmissaoCheckboxContainer ${classes.checkboxInputContainer}`}>
                <FormControlLabel
                  className="preAdmissaoCustomCheck"
                  label="Possui deficiência?"
                  labelPlacement="start"
                  control={
                    <CustomCheckbox
                      checked={checked}
                      onChange={() => setChecked((prev) => !prev)}
                      name="possuiDeficiencia"
                    />
                  }
                />
              </div>

              <div className={classes.checkboxSelectContainer}>
                <SelectComponent
                  label="Se sim, qual?"
                  initialValue={deficiencia !== null ? listaTipoDeficiencia.find(item => item.id === deficiencia)?.nome : ""}
                  list={listaTipoDeficiencia}
                  title="Selecione uma opção"
                  styleType="form"
                  callback={id => setDeficiencia(id)}
                />
              </div>


              <div className={classes.descriptionInputContainer}>
                <Textarea required value={descricao} label="Descrição" handleInputChange={(event) => setDescricao(event.target.value)} />
              </div>
              <div className={classes.requiredTextContainer}>
                <span className="required-text">
                  * Campos obrigatórios
                </span>
              </div>
            </section>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            text="Salvar"
            className="btn-success"
            onClick={handleSubmit}
          />
        </span>
      </div>
      {openLoginExpirado && <ModalLoginExpirado open={openLoginExpirado} />}
    </>
  );
}

export default CadastroPreAdmissao;

