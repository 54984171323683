import React from "react";
import Bar from "../Bar";
import Sidebar from "../Sidebar";
import "./styles.scss";

function Header({
  secondaryContainer,
  arrayMenu,
  vertical = false,
  objHeaderConfig,
  showToolbar
}) {


  return (
    <div>
      {!vertical ? (
        <>
          <Bar arrayMenu={arrayMenu} />
        </>
      ) : (
        <>
          <Bar
            secondaryContainer={secondaryContainer}
            arrayMenu={[]}
            objHeaderConfig={objHeaderConfig}
            showLogo={true}
            showToolbar={showToolbar}
          />
          <Sidebar arrayMenu={arrayMenu}></Sidebar>           
        </>
      )}
    </div>
  );
}

export default Header;