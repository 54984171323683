import React, { useEffect, useState } from "react";
import NavbarItem from "../NavbarItem";
import NavbarFooter from "../NavbarFooter";
import generateMenuService from "../../services/generateMenuService";
import getUnique from '../../utils/getUniqueArray';
import "./styles.css";

function Navbar({ arrayMenu, vertical }) {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (arrayMenu.length !== 0) {
      const uniqueItemsMenu = getUnique(arrayMenu, 'id');
      setMenu(generateMenuService(uniqueItemsMenu));

    }

  }, [arrayMenu]);

  return (
    <>
      <nav className={`navbar ${vertical ? "vertical" : "horizontal"}`}>
        {menu.map((element, index) => {
          return (
            <React.Fragment key={element.id}>
            <NavbarItem item={element} vertical={vertical} />
          
          </React.Fragment>
        )})}         
      </nav>
     
      <NavbarFooter />
    </>
  );
}

export default Navbar;
