import React, { useState, useEffect, useCallback, useRef } from "react";
import { debounce } from "throttle-debounce";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import { ClickAwayListener } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from '../ButtonComponent';

import SelectField from "../SelectComponent";
import Icon from "../Icon";

import { numberToCurrency } from '../../utils/functions';

import "./styles.scss";

const defaultConfigTable = {
  columnArray: [],
  pagination: true,
  totalPages: 1,
  dataListTotal: 0,
  perPage: 10,
};

function MoreOptions({
  options,
  lineId,
  callback,
  handleMouseDown,
  displayHeader = false
}) {
  let onClick = e => {
    let data;
    if (displayHeader) {
      data = {
        action: e.target.dataset.action
      };
    } else
      data = {
        id: lineId,
        action: e.target.dataset.action
      };
    callback(data);
  };

  return (
    <>
      {displayHeader && (
        <div className="modal-options-header">
          <ul className="modal-options-list-header">
            {options.map((option, index) => (
              <React.Fragment key={index}>
                <li
                  className="modal-options-list-item"
                  data-action={option.action}
                  onMouseDown={e => {
                    handleMouseDown();
                    onClick(e);
                  }}
                >
                  <span className="modal-options-list-item-icon">
                    <Icon name={option.icon} color="#0a2346" />
                  </span>
                  <span className="modal-options-list-item-label">
                    {option.label}
                  </span>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
      {!displayHeader && (
        <div className="modal-options">
          <ul className="modal-options-list">
            {options.map((option, index) => (
              <React.Fragment key={index}>
                <li
                  className="modal-options-list-item"
                  data-action={option.action}
                  onMouseDown={e => {
                    handleMouseDown();
                    onClick(e);
                  }}
                >
                  <span className="modal-options-list-item-icon">
                    <Icon name={option.icon} />
                  </span>
                  <span className="modal-options-list-item-label">
                    {option.label}
                  </span>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}


function ListComponent({
  idName,
  dataList,
  checkList = [],
  tableConfig = defaultConfigTable,
  filterComponentCustom,
  titleColumnOne = "",
  titleColumnTwo = "",
  onClickFilter = () => { },
  filterOpen = false,
  callbackOrderBy,
  selectedProducts,
  callbackCurrentPage,
  callbackPerPage,
  cbDelete,
  cbApprove,
  cbMoreAction,
  cbNewProduct,
  cbSearchExisting,
  cbConfirmImport,
  cbConfirmDisable,
}) {
  const [listData, setListData] = useState(dataList);
  const [filteredDataList, setFilteredDataList] = useState([]);
  const [objFilteredAttr, setObjFilteredAttr] = useState([]);
  const [page, setPage] = React.useState(1);
  const [moreOptions, setMoreOptions] = useState(false);
  const [moreActions, setMoreActions] = useState(false);
  const [moreDetails, setMoreDetails] = useState(false);
  const [moreOptionsId, setMoreOptionsId] = useState(null);
  const [listCheckBox, setListCheckBox] = useState([]);
  const [listCheckedId, setListCheckedId] = useState([]);
  const [products, setProducts] = useState([]);
  const [listIndexCurrency, setListIndexCurrency] = useState([]);
  const [isOpenFilter, setIsOpenFilter] = useState(tableConfig.display.filterOnly ? true : false);
  const [orderBy, setorderBy] = useState({
    field: '',
    type: ''
  });
  const searchTermRef = useRef(null);

  const mobileScreen = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (checkList.length !== 0) setListCheckBox(checkList);
    return;
  }, [checkList]);

  useEffect(() => { 
    if(selectedProducts.length !== 0) {
      let ids = selectedProducts.map(item => item.id_item);

      const filteredArray = selectedProducts.filter(({id_item}, index) => !ids.includes(id_item, index + 1))
      
      setProducts(filteredArray);
    }
  }, [selectedProducts])

  useEffect(() => {
    setListData(dataList);
    if (tableConfig.display && tableConfig.display.startCheckBox) {
      if (dataList) setListCheckBox(new Array(dataList.length).fill(false));
    }
  }, [dataList, tableConfig.display]);

  useEffect(() => {
    setorderBy({field: tableConfig.orderBy, type: tableConfig.orderByType});

    if (listData) {

      let array = listData.map(element => {
        let tempObj = {};
        if (element.id) tempObj["id"] = element.id;
        if (element.id2) tempObj["id2"] = element.id2;

        let { id, ...restData } = element;

        if(restData) tempObj["data"] = restData;

        return tempObj;
      });
      setFilteredDataList(array);
    }
  }, [listData, objFilteredAttr]);

  document.documentElement.onclick = function (event) {
    if (event.target !== moreOptions || event.target !== moreActions || event.target !== moreDetails) {
      setMoreOptions(false);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    callbackCurrentPage({ page: value });
  };

  const perPageSelect = [
    { id: 10, nome: "10 por página" },
    { id: 25, nome: "25 por página" },
    { id: 50, nome: "50 por página" },
    { id: 100, nome: "100 por página" }
  ];
  
  let perPageSelectCb = value => {
    callbackPerPage(value);
  };

  let cbImportItem = (idProduto, idNotaEntradaItem, quantidade, valorCusto) => {
    cbConfirmImport(idProduto, idNotaEntradaItem, quantidade, valorCusto);
  };

  let cbDisableItem = (id) => {
    cbConfirmDisable(id);
  };

  let onMoreOptionsCb = action => {
    cbMoreAction(action);
    setMoreOptions(false);
    setMoreDetails(false);
    setMoreActions(false);
  };


  const FilterList = () => {
    return (
      <div className="container-filter-list">
        <Icon name="filter" />
      </div>
    )
  }

  const handleToggleFilter = useCallback(() => {
    setIsOpenFilter(!isOpenFilter);
  }, [tableConfig.display.filter, isOpenFilter])


  return (
    <ClickAwayListener onClickAway={() => setMoreOptions(false)}>
      <Paper elevation={0} onClick={() => setMoreOptions(false)}>
        <div className={`config-display ${tableConfig.display.filterOnly && "filter-container"}`}>
          <div onClick={handleToggleFilter} className={tableConfig.display.filterOnly ? "filter-display-only" : ""}>
            {tableConfig.display.filter && (
              <FilterList />
            )}
          </div>
        </div>
        {isOpenFilter && filterComponentCustom}
        <TableContainer
          className={
            tableConfig.display.selfContainer
              ? "MuiTableContainer-selfContainer"
              : ""
          }
        >
          {tableConfig.display.customTableTitle && (
            <h1 className="custom-table-title">
              {tableConfig.display.customTableTitle}
            </h1>
          )}
          <Table aria-label="Table">
            <TableHead>
              <TableRow>
                <div className="title-content">
                  <div className="title-box">
                    <h1>{titleColumnOne}</h1>
                  </div>
                  <span></span>
                  <div className="title-box">
                    <h1>{titleColumnTwo}</h1>
                  </div>
                </div>
              </TableRow>

              <TableRow>
                {tableConfig.display && tableConfig.display.startCheckBox && (
                  <TableCell />
                )}
                {filteredDataList?.length !== 0 && (
                    <React.Fragment>
                {filteredDataList?.map((elementData, index) => {
                  const cadastrado = products.find(item => item?.id_item === elementData[idName] && item?.cadastrado);
                  return (
                    <div className={`list-item-container ${tableConfig.options && elementData?.data?.importado === 1 ? 'margin-import-card' : ''}`}>
                      <div className="second-card-content">
                      {tableConfig.options && elementData?.data?.importado === 0 && (
                          <div className="wrapper-fake">
                            </div>
                      )}
                      <div className="card-component first-card">
                        {tableConfig?.options?.remove ? (
                          <Button 
                            text="-" 
                            onClick={() => cbDisableItem(elementData[idName])} 
                            className="secondary-outline btnAddRemove"
                          />
                        ) : null}

                        {tableConfig?.display?.listProducts ? (
                          <>
                          <div className="grid-one">
                            <span className="text-info">Nome: <p>{elementData?.data?.descricao}</p></span>
                            <span className="text-info">Código: <p>{elementData?.data?.codigo}</p></span>
                            <span className="text-info">NCM: <p>{elementData?.data?.ncm}</p></span>
                          </div>
                          <div className="grid-two">
                            <span className="text-info">Quantidade: <p>{elementData?.data?.quantidade_tributavel}</p></span>
                            <span className="text-info">Valor do produto: <p>{numberToCurrency(elementData?.data?.valor_unitario_tributario)}</p></span>
                            <span className="text-info">Valor total: <p>{numberToCurrency(elementData?.data?.quantidade_tributavel * elementData?.data?.valor_unitario_tributario)}</p></span>
                          </div>
                          </>
                        ) : null}
                        
                      </div>
                      </div>
                      
                      <Button 
                        text="Confirmar" 
                        icone="arrow-track"
                        iconeSize={20}
                        disabled={elementData?.data?.importado === 0  ? cadastrado ? true : false : true}
                        onClick={() =>{
                          const produtoAtual = products.find(item => item?.id_item == elementData[idName]);
                          cbImportItem(
                            produtoAtual?.data?.id_produto, 
                            elementData[idName], 
                            produtoAtual?.data?.quantidade,
                            produtoAtual?.data?.valor_de_custo,
                            )}
                        }  
                        className={`${elementData?.data?.importado === 0 ? cadastrado ? "disabled" : "default-outline clean-filters-button" : "disabled"}`}
                        />

                      <div className="second-card-content">
                      {tableConfig.options && elementData?.data?.importado === 0 && (
                          <div className={`${products.find(item => item.id_item === elementData[idName] && item.suggested) ? "suggested-wrapper" : "options-wrapper"}`}>
                            {tableConfig.options &&
                              tableConfig.options.more &&
                              tableConfig.options.more.length > 0 ? (
                              <>
                              {products.find(item => item.id_item === elementData[idName] && item.suggested) && (
                                <div className="suggested-info">
                                  <Icon
                                  name="star"
                                  size={15}
                                  color="#812990"
                                  />
                                <span>Sugestão</span>
                                </div>
                              )}

                              <div
                                className="icon-wrapper icon-menu"
                                onClick={e => {
                                  e.stopPropagation();
                                  setMoreOptionsId(elementData[idName]);
                                  setMoreOptions(prev => !prev);
                                }}
                              >
                                <Icon
                                  name="hamburger"
                                  size={15}
                                  color="#0a2346"
                                  className="icon"
                                />
                                {moreOptionsId === elementData[idName] &&
                                  moreOptions ? (
                                  <MoreOptions
                                    handleMouseDown={() => setMoreOptions(true)}
                                    key={elementData[idName]}
                                    options={tableConfig.options.more}
                                    callback={onMoreOptionsCb}
                                    lineId={moreOptionsId}
                                  />
                                ) : null}
                              </div>
                              </>
                            ) : null}
                          
                          </div>
                      )}
                      
                      <div className={`card-component ${products.find(item => item.id_item === elementData[idName]) ? "second-card" : "flex-card"}`}>
                        {products?.filter(x => x.id_item === elementData[idName]).map(item => (
                           <>
                           {tableConfig?.display?.listProducts && (
                             <>
                             <div className="grid-one" key={item.id_produto}>
                               <span className="text-info">Nome: <p>{item?.data?.descricao}</p></span>
                               <span className="text-info">Código: <p>{item?.data?.codigo_produto}</p></span>
                               <span className="text-info">Tipo de material: <p>{item?.data?.tipo_produto}</p></span>
                             </div>
                             <div className="grid-two" key={item.id_produto}>
                               <span className="text-info">EAN: <p>{item?.data?.ean}</p></span>
                               <span className="text-info">NCM: <p>{item?.data?.ncm}</p></span>
                             </div>
                             </>
                           )}
                           </>
                          ))}
                        {!products.find(item => item.id_item === elementData[idName]) && (
                            <>
                              {tableConfig?.options?.newProduct ? (
                                <Button 
                                  text="Novo produto" 
                                  icone="plus"
                                  iconeSize={20} 
                                  onClick={() => cbNewProduct(elementData[idName])} 
                                  className="simple-outline plus-button"
                                />
                                ) : null}
                              {tableConfig?.options?.searchExisting ? (
                                  <Button 
                                  text="Buscar existente"
                                  icone="search" 
                                  iconeSize={20} 
                                  onClick={() => cbSearchExisting(elementData[idName])} 
                                  className="simple-outline"
                                />
                                ) : null}   
                              </>
                        )}
                          
                      </div>
                      </div>
                    </div>
                )})}
                </React.Fragment>
                )}

              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDataList?.length === 0 && (
                  <React.Fragment>
                    <TableRow className="table-row">
                      <TableCell colSpan={objFilteredAttr.length}>
                        Nenhum item encontrado
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )} 
            </TableBody>
          </Table>
          {filteredDataList?.length > 0 && (
              <div className="table-footer">
                {tableConfig.display.itemsPerPage && !mobileScreen ? (
                  <div className="table-perpage-wrapper">
                    <SelectField
                      initialValue={
                        typeof perPageSelect.find(item => item.id === tableConfig?.perPage) !== "undefined" ?
                          perPageSelect.find(item => item.id === tableConfig?.perPage) :
                          perPageSelect[0]
                      }
                      list={perPageSelect}
                      callback={perPageSelectCb}
                    />
                  </div>
                ) : null}
                {tableConfig.display.totalResults && !mobileScreen ? (
                  <div className="table-total-data-lis-wrapper">
                    <span className="table-total-data-list">
                      {`Total: ${tableConfig.dataListTotal
                        ? tableConfig.dataListTotal
                        : filteredDataList?.length
                        } resultados`}
                    </span>
                  </div>
                ) : null}
                {tableConfig.display.pagination ? (
                  <div className="table-configuration">
                    <Pagination
                      count={tableConfig.totalPages}
                      variant="outlined"
                      shape="rounded"
                      size="large"
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                ) : null}
              </div>
            )}
        </TableContainer>
      </Paper>
    </ClickAwayListener>
  );
}

export default ListComponent;