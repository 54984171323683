import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import SelectComponent from '../../../components/SelectComponent';
import ButtonComponent from '../../../components/ButtonComponent';
import Input from '../../../components/Input';
import OverlayLoading from '../../../components/OverlayLoading';
import CallbackMessage from '../../../components/CallbackMessage';
import ModalLoginExpirado from '../../../components/ModalLoginExpirado';

import empresasService from '../../../services/empresasService';
import centroCustoService from '../../../services/centroCustosService';
import centroCustosService from '../../../services/centroCustosService';

import './styles.css';

function EditarCentroCusto() {
  const history = useHistory();
  const routeParams = useParams();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [listEmpresas, setListEmpresas] = useState();
  const [idEmpresaSelected, setIdEmpresaSelected] = useState();
  const [valueInput, setValueInput] = useState('');
  const [initialSelectValue, setInitialSelectValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState('');
  const [callbackErrors, setCallbackErrors] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState('');
  const [duration, setDuration] = useState(6000);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await getCentroCusto();
      await updateListEmpresas();
      setLoading(false);
    })();
  }, []);

  async function getCentroCusto() {
    const { id } = routeParams;

    const response = await centroCustosService.consultaCentroCustos(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const { data } = response;

    setValueInput(data.nome);
    setIdEmpresaSelected(data.id_empresa);

    const empresaSelectedLabel = await empresasService.consultaEmpresa(
      data.id_empresa
    );
    if (empresaSelectedLabel && empresaSelectedLabel.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const { data: empresaData } = empresaSelectedLabel;

    setInitialSelectValue(empresaData.razao_social);
  }

  let updateListEmpresas = async () => {
    let params = {
      page: null,
      orderBy: null,
      orderByType: null,
      perPage: 9999,
      searchTerm: null
    };
    const response = await empresasService.getEmpresas(
      params.page,
      params.orderBy,
      params.orderByType,
      params.perPage,
      params.searchTerm
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const data = response.data;
    setListEmpresas(
      data.data.map(empresa => ({
        id: empresa.id_empresa,
        nome: empresa.razao_social
      }))
    );
  };

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      setCallbackShown(false);
    }
    if (reason === 'timeout') {
      setCallbackShown(false);
    }
    if (callbackType === 'success') {
      history.push('/centro-custo');
    }
  }

  async function handleClick() {
    const { id } = routeParams;
    if (idEmpresaSelected === undefined && valueInput === '') {
      setCallbackShown(true);
      setCallbackType('error');
      setCallbackErrors([
        'Nenhuma empresa selecionada.',
        "O campo 'Nome do centro de custo' é obrigatório."
      ]);
      setCallbackMessage('Erro!');
    } else if (idEmpresaSelected === undefined) {
      setCallbackShown(true);
      setCallbackType('success');
      setCallbackErrors(['Nenhuma empresa selecionada.']);
      setCallbackMessage('Erro!');
    } else if (valueInput === '') {
      setCallbackShown(true);
      setCallbackType('error');
      setCallbackErrors(["O campo 'Nome do centro de custo' é obrigatório."]);
      setCallbackMessage('Erro!');
    } else {
      const response = await centroCustoService.atualizaCentroCusto(
        id,
        idEmpresaSelected,
        valueInput
      );
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      } else if (response.status === 200 || response.status === 201) {
        setCallbackShown(true);
        setCallbackType('success');
        setCallbackMessage('Dados atualizados com sucesso!');
        setDuration(2000);
      } else {
        setCallbackShown(true);
        setCallbackType('error');
        setCallbackMessage(Object.values(response.data.error));
        setDuration(6000);
      }
    }
  }

  return (
    <>
      <div className='session-container'>
        <span className='session-container-header'>
          {loading && <OverlayLoading />}
          {callbackShown && (
            <CallbackMessage
              type={callbackType}
              open={callbackShown}
              message={callbackMessage}
              errorList={callbackErrors}
              handleClose={handleClose}
              duration={duration}
            />
          )}
          <form className='session-container-form'>
            <div className='input-area'>
              <fieldset>
                <SelectComponent
                  styleType='form'
                  label='Empresa'
                  title='Selecione uma empresa'
                  list={listEmpresas}
                  initialValue={initialSelectValue}
                  required
                  callback={id => setIdEmpresaSelected(id)}
                />
              </fieldset>
              <fieldset className='input-children'>
                <Input
                  required
                  title=''
                  label='Nome do centro de custo'
                  tipo='nome'
                  value={valueInput}
                  handleInputChange={event => setValueInput(event.target.value)}
                />
              </fieldset>
              <div className='required-text'>
                <span>* Campos obrigatórios</span>
              </div>
            </div>
          </form>
        </span>
      </div>
      <div className='button-area'>
        <span>
          <ButtonComponent
            text='Salvar'
            className='btn-success'
            onClick={handleClick}
          />
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default EditarCentroCusto;
