import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import OverlayLoading from "../../components/OverlayLoading";
import ConfirmDialog from "../../components/ConfirmDialog";
import CallbackMessage from "../../components/CallbackMessage";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";

import obrigacoesService from "../../services/obrigacoesService";

import { toDateDD_MM_YYYY } from "../../utils/dates";
import { base64ToFile } from "../../utils/functions";

import { Grid } from "@material-ui/core";
import Select from "../../components/SelectComponent";
import Button from "../../components/ButtonComponent";
import exportData from "../../services/exportData";
import DatePicker from "../../components/DatePicker";
import TableComponent from "../../components/TableResponsiveComponent";
import { useIsMount } from "../../hooks/useIsMount";
import useCompany from "../../hooks/useCompany";

const exportToXLS = {
  url: "exportar-obrigacoes-xls",
  fileName: "obrigações_financeiras",
  fileType: "xlsx",
};

function ObrigacoesLista() {
  const history = useHistory();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const {
    getObrigacoesLista,
    removerObrigacao,
    consultaObrigacao,
    notificacaoDownloadObrigacao,
  } = obrigacoesService;

  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("obrigacoes");
  const isMount = useIsMount();

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);

  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [listRequest, setListRequest] = useState([]);
  const currencyFormatter = new Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
  });

  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);

  const perfilAcesso = window.localStorage.getItem("perfil_acesso");
  const isContratante =
    Number(perfilAcesso) !== 1 && Number(perfilAcesso) !== 2;

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_lancamento" },
      { columnName: "Empresa", dataRef: "razao_social" },
      { columnName: "Obrigação", dataRef: "obrigacao" },
      { columnName: "Data cadastro", dataRef: "data_cadastro" },
      { columnName: "Referência", dataRef: "referencia" },
      { columnName: "Vencimento", dataRef: "data_vencimento" },
      { columnName: "Valor", dataRef: "valor" },
      { columnName: "Status", dataRef: "status" },
    ],
    options: {
      edit2: true,
      delete2: !isContratante,
      file2: true,
    },
    text: {
      edit: "Editar",
      delete: "Excluir",
      file: "Download do documento",
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-excel",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: "id_obrigacao",
    orderByType: "desc",
    perPage: null,
    searchTerm: null,
    id_empresa: null,
    data_inicial: null,
    data_final: null,
  });

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    let list = [];
    const result = await getObrigacoesLista(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.id_empresa,
      defaultConfigTable.data_inicial,
      defaultConfigTable.data_final
    );
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.status === 200) {
      let list = [];
      let l = [];
      if (result.data.hasOwnProperty("data")) list = result.data.data;
      else list = result.data;

      list.forEach((item) => {
        item.valor = currencyFormatter.format(item.valor);
        item.data_vencimento = toDateDD_MM_YYYY(item.data_vencimento);
        item.data_cadastro = toDateDD_MM_YYYY(item.data_cadastro);
        item.id = item.id_obrigacao;
        l.push(item);
      });
      if (result.data.hasOwnProperty("data")) {
        result.data.data = l;
        setListRequest(result.data);
      } else {
        result.data = l;
        setListRequest(result);
      }
    }

    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
    defaultConfigTable.data_inicial,
    defaultConfigTable.data_final,
    getObrigacoesLista,
  ]);

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onEdit = (id) => {
    history.push(`/editar-obrigacoes/${id}`);
  };

  let handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  let handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  let onDelete = async () => {
    setLoading(true);
    const response = await removerObrigacao(deleteId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status === 200 || response.status === 201) {
      setCallbackMessage("Obrigação excluída com sucesso!");
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackShown(true);
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
    } else {
      setCallbackMessage("Erro!");
      setCallbackType("error");
      setCallbackDuration(6000);
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      setCallbackShown(true);
    }
    setLoading(false);
    handleRemoveCancel();
  };

  let onDownload = async (id) => {
    setLoading(true);
    const response = await consultaObrigacao(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const file = response.data.arquivo;
    if (!file) {
      setCallbackMessage("Erro!");
      setCallbackType("error");
      setCallbackDuration(6000);
      setCallbackErrorList([
        "Arquivo não encontrado, ou não enviado pelo usuário.",
      ]);
      setCallbackShown(true);
    } else {
      const downloadFile = base64ToFile(file, "anexo");
      setLoading(false);

      try {
        notificacaoDownloadObrigacao(id);
      } catch (error) {}
      return downloadFile;
    }

    setLoading(false);
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  const handleSelectEmpresa = async (id) => {
    setEmpresaSelecionada(id);
  };

  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      return setDefaultConfigTable({
        ...defaultConfigTable,
        currentPage: 1,
        id_empresa: empresaStorage,
      });
    }
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_empresa: empresaSelecionada,
      data_inicial: dataInicial,
      data_final: dataFinal,
    });
    setEmpresaPagina("obrigacoes", empresaSelecionada);
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setDataInicial("");
    setDataFinal("");
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
      data_inicial: null,
      data_final: null,
      currentPage: 1,
    });
    setEmpresaPagina("obrigacoes", null);
  };

  const onTableMoreAction = useCallback(
    async ({ action }) => {
      let aux = {};
      if (action === "exportar-excel") {
        aux = {
          url: exportToXLS.url,
          fileName: exportToXLS.fileName,
          fileType: exportToXLS.fileType,
          params: {
            ...exportToXLS.params,
            order_by: defaultConfigTable.orderBy,
            order_by_type: defaultConfigTable.orderByType,
            id_empresa: defaultConfigTable.id_empresa,
            search_term: defaultConfigTable.searchTerm,
            data_inicial: defaultConfigTable.data_inicial,
            data_final: defaultConfigTable.data_final,
          },
        };
      }
      setLoading(true);
      await exportData(aux);
      setLoading(false);
    },
    [
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.id_empresa,
      defaultConfigTable.searchTerm,
      defaultConfigTable.data_inicial,
      defaultConfigTable.data_final,
    ]
  );

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                      ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={handleSelectEmpresa}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <DatePicker
              label="De"
              initDate={dataInicial || ""}
              handleChange={(value) => setDataInicial(value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <DatePicker
              label="Até"
              initDate={dataFinal || ""}
              handleChange={(value) => setDataFinal(value)}
            />
          </Grid>
          <Grid className="marginLeftAuto">
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("obrigacoes");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  return (
    <div className="container-tabela-responsiva">
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onEdit}
        cbDelete={handleRemoveDialog}
        cbDownload={onDownload}
        filterComponent={filterComponent()}
        cbTableMoreAction={onTableMoreAction}
      />
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        duration={callbackDuration}
        errorList={callbackErrorList}
        message={callbackMessage}
        handleClose={handleClose}
      />
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </div>
  );
}

export default ObrigacoesLista;
