import Axios from "./Axios";
import { base64ToFile } from '../utils/functions';

const exportFile = {
  exportFile: async (data) => {
      const response = await Axios.get(`${data.url}`, {
        params: {
          ...data.params
        }
      })
      .then(({ ...response }) => {
        base64ToFile(response.data.arquivo, data.fileName, data.fileType)
        return response;
      })
      .catch(({response}) => {
        return response
      });
      
      return response
}
};

export default exportFile;