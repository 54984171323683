import React, { useState, useEffect } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import InputComponent from "../../Input";
import ButtonComponent from "../../ButtonComponent";
import SelectComponent from "../../SelectComponent";
import CallbackMessage from "../../CallbackMessage";
import validateFields from "../../../utils/validateFields";
import "../styles.scss";

function ModalIcms90({ open, onClose, data = null, callback = () => {}, listBci = [], listBcimsst,listCstCsosn = []  }) {
  
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackErrorList, setCallbackErrorList] = useState([]);

  const [field, setField] = useState({
    origem: null,
    cst: null,
    modalidade_determinacao_bcicms: null,
    valor_base_calculo: null,
    aliquota_imposto: null,
    valor_icms: null,
    modalidade_determinacao_bcicmsst: null,
    percentual_margem_valor_adicionado_icmsst: null,
    percentual_reducao_bcicmsst: null,
    valor_base_calculo_st: null,
    aliquota_imposto_icmsst: null,
    valor_icmsst: null,
    valor_base_calculo_fundo_combate_pobreza: null,
    percentual_fundo_combate_pobreza: null,
    valor_fundo_combate_pobreza: null,
    valor_base_calculo_fundo_combate_pobreza_st: null,
    percentual_fundo_combate_pobreza_st: null,
    valor_fundo_combate_pobreza_st: null,
    percentual_reducao_bc: null
  });

  const handleClose = (event, reason) => {
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
  };

  const handleSubmit = () => {
    console.log(field);

    const fieldsToValidate = [
      {
        label: "origem",
        value: field.origem,
      },
      {
        label: "cst",
        value: field.cst,
      },
      {
        label: "modalidade_determinacao_bcicms",
        value: field.modalidade_determinacao_bcicms,
      },
      {
        label: "valor_base_calculo",
        value: field.valor_base_calculo,
      },
      {
        label: "aliquota_imposto",
        value: field.aliquota_imposto,
      },
      {
        label: "valor_icms",
        value: field.valor_icms,
      },
      {
        label: "modalidade_determinacao_bcicmsst",
        value: field.modalidade_determinacao_bcicmsst,
      },
      {
        label: "valor_base_calculo_st",
        value: field.valor_base_calculo_st,
      },
      {
        label: "aliquota_imposto_icmsst",
        value: field.aliquota_imposto_icmsst,
      },
      {
        label: "valor_icmsst",
        value: field.valor_icmsst,
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      setCallbackErrorList(fieldsErrors);
      setCallbackShown(true);
      return;
    }
    callback(field)
  }

  useEffect(() => {
    let mounted = true;

    if (data) {
      if (mounted) {
        setField({
          origem: data.origem,
          cst: data.cst,
          modalidade_determinacao_bcicms: data.modalidade_determinacao_bcicms,
          valor_base_calculo: data.valor_base_calculo,
          aliquota_imposto: data.aliquota_imposto,
          valor_icms: data.valor_icms,
          modalidade_determinacao_bcicmsst: data.modalidade_determinacao_bcicmsst,
          percentual_margem_valor_adicionado_icmsst: data.percentual_margem_valor_adicionado_icmsst,
          percentual_reducao_bcicmsst: data.percentual_reducao_bcicmsst,
          valor_base_calculo_st: data.valor_base_calculo_st,
          aliquota_imposto_icmsst: data.aliquota_imposto_icmsst,
          valor_icmsst: data.valor_icmsst,
          valor_base_calculo_fundo_combate_pobreza: data.valor_base_calculo_fundo_combate_pobreza,
          percentual_fundo_combate_pobreza: data.percentual_fundo_combate_pobreza,
          valor_fundo_combate_pobreza: data.valor_fundo_combate_pobreza,
          valor_base_calculo_fundo_combate_pobreza_st: data.valor_base_calculo_fundo_combate_pobreza_st,
          percentual_fundo_combate_pobreza_st: data.percentual_fundo_combate_pobreza_st,
          valor_fundo_combate_pobreza_st: data.valor_fundo_combate_pobreza_st,
          percentual_reducao_bc: data.valor_fundo_combate_pobreza_st
        })
      }
    }
    
    return () => mounted = false;
  }, [data])
  
  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose} >
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>ICMS - ICMS90</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={1}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Origem"
                  tipo="numero"
                  value={field.origem}
                  handleInputChange={event => setField({ ...field, origem: event.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <SelectComponent
                  required
                  styleType="form"
                  label="CST"
                  initialValue={listCstCsosn.find((item) => item.id === Number(field.cst) ? listCstCsosn.find((item) => item.id === Number(field.cst)) : null)}
                  callback={event => setField({ ...field, cst: event })}
                  list={listCstCsosn}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
               <SelectComponent
                  required
                  label="Modalidade determinação BC ICMS"
                  initialValue={field.modalidade_determinacao_bcicms ? listBci.find(item => item.id === field.modalidade_determinacao_bcicms) : ''}
                  callback={id => setField({ ...field, modalidade_determinacao_bcicms: id })}
                  styleType="form"
                  list={listBci}                  
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  tipo="dinheiro"
                  title="R$"
                  label="Valor da base de cálculo"
                  value={field.valor_base_calculo}
                  handleInputChange={event => setField({ ...field, valor_base_calculo: event.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Alíquota do imposto (%)"
                  tipo="percentage"
                  value={field.aliquota_imposto && field.aliquota_imposto+"%"}
                  handleInputChange={event => setField({ ...field, aliquota_imposto: event.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Valor do ICMS"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_icms}
                  handleInputChange={event => setField({ ...field, valor_icms: event.value })}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Redução BC (%)"
                  tipo="percentage"
                  value={field.percentual_reducao_bc && field.percentual_reducao_bc+"%"}
                  handleInputChange={event => setField({ ...field, percentual_reducao_bc: event.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
              <SelectComponent
                  required
                  label="Modalidade determinação BC ICMS ST"
                  initialValue={field.modalidade_determinacao_bcicmsst !== undefined ? listBcimsst.find(item => item.id === field.modalidade_determinacao_bcicmsst) : ''}
                  callback={id => setField({ ...field, modalidade_determinacao_bcicmsst: id })}
                  styleType="form"
                  list={listBcimsst}                  
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Margem de valor adicionado ICMS ST (%)"
                  tipo="percentage"
                  value={field.percentual_margem_valor_adicionado_icmsst && field.percentual_margem_valor_adicionado_icmsst +"%"}
                  handleInputChange={event => setField({ ...field, percentual_margem_valor_adicionado_icmsst: event.value})}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Redução BC ICMS ST (%)"
                  tipo="percentage"
                  value={field.percentual_reducao_bcicmsst && field.percentual_reducao_bcicmsst+"%"}
                  handleInputChange={event => setField({ ...field, percentual_reducao_bcicmsst: event.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Valor da base de cálculo ICMS ST"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_base_calculo_st}
                  handleInputChange={event => setField({ ...field, valor_base_calculo_st: event.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Alíquota do imposto ICMS ST (%)"
                  tipo="percentage"
                  value={field.aliquota_imposto_icmsst && field.aliquota_imposto_icmsst+"%"}
                  handleInputChange={event => setField({ ...field, aliquota_imposto_icmsst: event.value})}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Valor do ICMS ST"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_icmsst}
                  handleInputChange={event => setField({ ...field, valor_icmsst: event.value})}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Base de cálculo fundo de combate à pobreza"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_base_calculo_fundo_combate_pobreza}
                  handleInputChange={event => setField({ ...field, valor_base_calculo_fundo_combate_pobreza: event.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Fundo de combate à pobreza (%)"
                  tipo="percentage"
                  value={field.percentual_fundo_combate_pobreza && field.percentual_fundo_combate_pobreza+"%"}
                  handleInputChange={event => setField({ ...field, percentual_fundo_combate_pobreza: event.value })}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4} className="container-row-height">
                <InputComponent
                  label="Valor de fundo de combate à pobreza"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_fundo_combate_pobreza}
                  handleInputChange={event => setField({ ...field, valor_fundo_combate_pobreza: event.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="container-row-height">
                <InputComponent
                  label="Base de cálculo fundo de combate à pobreza por ST"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_base_calculo_fundo_combate_pobreza_st}
                  handleInputChange={event => setField({ ...field, valor_base_calculo_fundo_combate_pobreza_st: event.value})}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="container-row-height">
                <InputComponent
                  label="Fundo de combate à pobreza por ST (%)"
                  tipo="percentage"
                  value={field.percentual_fundo_combate_pobreza_st && field.percentual_fundo_combate_pobreza_st+"%"}
                  handleInputChange={event => setField({ ...field, percentual_fundo_combate_pobreza_st: event.value })}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Valor de fundo de combate à pobreza por ST"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_fundo_combate_pobreza_st}
                  handleInputChange={event => setField({ ...field, valor_fundo_combate_pobreza_st: event.value })}
                />
              </Grid>
            </Grid>           
            <Grid container justify="flex-end" item>
              <ButtonComponent
                text="Salvar"
                onClick={() => handleSubmit()}
                className="btn-success zero-margin"
              />
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={callbackShown}
        errorList={callbackErrorList}
        duration={4000}
        message={'Erro!'}
        type={'error'}
        handleClose={handleClose}
      />
    </Dialog>
  );
}

export default ModalIcms90;
