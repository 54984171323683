import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import "./styles.scss";
import obrigacoesService from "../../../services/obrigacoesService";
import InputComponent from "../../../components/Input";
import SelectComponent from "../../../components/SelectComponent";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import empresasService from "../../../services/empresasService";
import FileInput from "../../../components/InputFile";
import DatePicker from "../../../components/DatePicker";
import ButtonComponent from "../../../components/ButtonComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import { base64ToFile } from "../../../utils/functions";
import SmallScreen from "../../../utils/smallScreen";
import Textarea from "../../../components/Textarea";

function EditarObrigacoes() {
  const isSmallScreen = SmallScreen();
  const [file, setFile] = useState(null);
  const [referencia, setReferencia] = useState("");
  const [fileName, setFileName] = useState(null);
  const [observacoes, setObservacoes] = useState(null);
  const [idEmpresa, setIdEmpresa] = useState("");
  const [listEmpresas, setListEmpresas] = useState();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [obrigacaoId, setObrigacaoId] = useState("");
  const [vencimento, setVencimento] = useState("");
  const [statusId, setStatusId] = useState([]);
  const [valor, setValor] = useState("");
  const [selectStatus, setSelectStatus] = useState([]);
  const [selectObrigacao, setSelectObrigacao] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errors, setErrors] = useState([]);
  const history = useHistory();
  const params = useParams();

  const perfilAcesso = window.localStorage.getItem("perfil_acesso");
  const isContratante = Number(perfilAcesso) === 3;

  let { id: idParams } = params;

  const [id, setId] = useState(idParams);

  const currencyFormatter = new Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
  });

  let updateListEmpresas = async () => {
    await empresasService.selectEmpresa().then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) setListEmpresas(response.data);
    });
  };

  let loadSelects = async () => {
    let responseStatus = await obrigacoesService.selectStatus();
    let responseObrigacoes = await obrigacoesService.selectObrigacao();
    if (responseObrigacoes.status === 401 || responseStatus === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setSelectObrigacao(responseObrigacoes.data);
    setSelectStatus(responseStatus.data);
  };

  const loadFields = useCallback(async () => {
    await obrigacoesService.consultaObrigacao(id).then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      } else if (response.status === 400) {
        setErrors([response.data.error] || ["Erro ao consultar obrigação!"]);
        setLoading(false);
        setShowMessage(true);
        setId(null);
        return;
      }
      let data = response.data;
      setReferencia(data.referencia);
      setObrigacaoId(data.id_categoria_obrigacao);
      setStatusId(data.id_status);
      setIdEmpresa(data.id_empresa);
      setFile(data.arquivo);
      setValor(data.valor);
      setVencimento(data.data_vencimento);
      setObservacoes(data.observacoes);
    });
  }, [currencyFormatter, id]);

  function validateFields() {
    let list = [];
    if (referencia === "") list.push("O campo Referência deve ser preenchido.");
    if (vencimento === "") list.push("O campo Vencimento deve ser preenchido.");
    if (obrigacaoId === "")
      list.push("O campo Obrigação deve ser selecionado.");
    if (valor === "") list.push("O campo Valor deve ser preenchido.");

    if (idEmpresa === "") list.push("A Empresa deve ser selecionada.");

    if (file === "") list.push("O campo Anexo é obrigatório.");

    if (list.length > 0) setShowMessage(true);

    setErrors(list);

    return list.length === 0;
  }

  function handleClose(event, reason) {
    setShowMessage(false);
    if (errors.length === 0) {
      history.push("/obrigacoes-financeiras");
    }
  }

  let handleSubmit = async () => {
    if (!validateFields()) return;
    const response = await obrigacoesService.editObrigacao(
      referencia,
      obrigacaoId,
      vencimento,
      statusId,
      idEmpresa,
      file,
      valor,
      id,
      observacoes,
      fileName
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status !== 200)
      if (response.data.error) setErrors(Object.values(response.data.error));
      else setErrors("Ocorreu um erro");
    setShowMessage(true);
  };

  const notificacaoDownload = useCallback(() => {
    if (!id) return;

    try {
      obrigacoesService.notificacaoDownloadObrigacao(id);
    } catch (error) {}
  }, [id]);

  useEffect(() => {
    setLoading(true);

    updateListEmpresas().then(async (_) => {
      await loadFields();
      await loadSelects();
      setLoading(false);
    });
  }, []);

  return (
    <>
      <form className="session-container-form">
        <div className="session-container">
          <span className="session-container-header">
            {loading && <OverlayLoading />}
            <div className={`${isSmallScreen ? "" : "p-horizontal"}`}>
              <Grid item container direction="column" spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <InputComponent
                      required
                      label="Referência"
                      readOnly={isContratante}
                      isDisabled={isContratante}
                      value={referencia}
                      handleInputChange={(event) =>
                        setReferencia(event.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DatePicker
                      label="Vencimento em *"
                      initDate={vencimento}
                      readOnly={isContratante}
                      isDisabled={isContratante}
                      handleChange={(date) => setVencimento(date)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SelectComponent
                      styleType="form"
                      label="Status"
                      title="Selecione uma opção"
                      list={selectStatus}
                      initialValue={
                        selectStatus.find((item) => item.id === statusId)
                          ? selectStatus.find((item) => item.id === statusId)
                              .nome
                          : ""
                      }
                      callback={(id) => setStatusId(id)}
                      search={false}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <SelectComponent
                      styleType="form"
                      label="Obrigação"
                      required
                      readOnly={isContratante}
                      isDisabled={isContratante}
                      title="Selecione uma obrigação"
                      list={selectObrigacao}
                      initialValue={
                        selectObrigacao.find((item) => item.id === obrigacaoId)
                          ? selectObrigacao.find(
                              (item) => item.id === obrigacaoId
                            ).nome
                          : ""
                      }
                      callback={(id) => setObrigacaoId(id)}
                      search={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputComponent
                      required
                      tipo="dinheiro"
                      readOnly={isContratante}
                      isDisabled={isContratante}
                      label="Valor"
                      value={valor}
                      handleInputChange={(event) => setValor(event.value)}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <SelectComponent
                    styleType="form"
                    label="Empresa"
                    title="Selecione uma empresa"
                    readOnly={isContratante}
                    isDisabled={isContratante}
                    list={listEmpresas}
                    initialValue={
                      listEmpresas &&
                      listEmpresas.find((item) => item.id === idEmpresa)
                        ? listEmpresas.find((item) => item.id === idEmpresa)
                            .nome
                        : ""
                    }
                    required
                    callback={(id) => setIdEmpresa(id)}
                  />
                </Grid>
                <Grid item>
                  <Textarea
                    label="Observações"
                    minRows={5}
                    value={observacoes}
                    onChange={(e) => setObservacoes(e.target.value)}
                    maxLength={300}
                  />
                </Grid>
                <Grid item>
                  {file === "" ? (
                    <Grid item>
                      <FileInput
                        title="Anexo *"
                        onChange={(value, valueFileName) => {
                          setFile(value);
                          setFileName(valueFileName);
                        }}
                      />
                    </Grid>
                  ) : (
                    <>
                      <div className="label">
                        <span>Anexo *</span>
                      </div>
                      <Grid item container>
                        <Grid item>
                          <ButtonComponent
                            className="primary-outline text-button"
                            text="Visualizar arquivo"
                            icone="download"
                            iconeColor="#812990"
                            iconeSize="18"
                            onClick={() => {
                              base64ToFile(file, "anexo");
                              notificacaoDownload();
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <ButtonComponent
                            className="primary-outline icon-button"
                            icone="trash"
                            iconeColor="#812990"
                            iconeSize="16"
                            disabled={isContratante}
                            onClick={() => {
                              setFile("");
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item>
                  <span className="required-text">* Campos obrigatórios</span>
                </Grid>
              </Grid>
              {showMessage && (
                <CallbackMessage
                  open={showMessage}
                  type={`${errors.length === 0 ? "success" : "error"}`}
                  message={`${
                    errors.length === 0
                      ? "Obrigação atualizada com sucesso!"
                      : "Erro!"
                  }`}
                  errorList={errors}
                  handleClose={handleClose}
                  duration={errors.length === 0 ? 2000 : 6000}
                />
              )}
            </div>
          </span>
        </div>
      </form>
      <div style={{ height: 40 }} />
      <Grid item container justifyContent="flex-end">
        <ButtonComponent
          text="Salvar"
          className="btn-success zero-margin"
          onClick={handleSubmit}
        />
      </Grid>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default EditarObrigacoes;
