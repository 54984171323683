import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import CallbackMessage from '../../../components/CallbackMessage';
import ConfirmDialog from '../../../components/ConfirmDialog';
import ListComponent from '../../../components/ListComponent';
import ModalCadastroProduto from '../../../components/ModalCadastroProduto';
import ModalBuscarProduto from '../../../components/ModalBuscarProduto';
import ModalLoginExpirado from '../../../components/ModalLoginExpirado';
import OverlayLoading from '../../../components/OverlayLoading';

import notasFiscaisService from '../../../services/notasFiscaisService';
import viacepServices from '../../../services/viacepServices';


import { formatDate } from '../../../utils/dates';
import { numberToCurrency } from '../../../utils/functions';

import './styles.scss';


export default function ConfirmacaoProduto() {
  const { id: idNotaFiscal } = useParams();
  const location = useLocation();
  const filters = location?.search?.replace('?', '')
  useEffect(() => {
    if (filters) {
      localStorage.setItem('contabilhub@confirmacao_produto', filters)
    }
  }, [filters])
  console.log('filters', filters)

  const { 
    consultaNotaEntrada,
    confirmacaoNotaEntrada,
    listaNotaEntradaItens,
    importarProdutoNotaEntrada,
    desabilitaItemNotaEntrada
  } = notasFiscaisService;
  const { selecionaEstado } = viacepServices;
  const perfilAcesso = window.localStorage.getItem('perfil_acesso');

  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmDisable, setOpenConfirmDisable] = useState(false);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [showModalCadastroProduto, setShowModalCadastroProduto] = useState(false);
  const [showModalBuscarProduto, setShowModalBuscarProduto] = useState(false);

  const [idItemNota, setIdItemNota] = useState(null);
  const [idDisable, setIdDisable] = useState(null);


  const [idProduct, setIdProduct] = useState(null);
  const [idNotaEntradaItem, setIdNotaEntradaItem] = useState(null);
  const [quantidade, setQuantidade] = useState(null); 
  const [valorCustoUnitario, setValorCustoUnitario] = useState(null); 

  const [idCompany, setIdCompany] = useState(null);

  const [itemsProduct, setItemsProduct] = useState([]);

  const [dataNota, setDataNota] = useState({});
  const [valorCustoProdutoAtivo, setValorCustoProdutoAtivo] = useState(0);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);
  
  const [listRequest, setListRequest] = useState([]);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    options: {
      newProduct: true,
      searchExisting: true,
      remove: true,
      more: [
        {
          icon: "edit",
          label: "Novo produto",
          action: "novo-produto"
        },
        {
          icon: "search",
          label: "Buscar existente",
          action: "buscar-existente"
        },
        {
          icon: "search",
          label: "Ver produto sugerido",
          action: "produto-sugerido"
        },
      ],
    },
    display: {
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      listProducts: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  let onMoreAction = async action => {
    console.log(action);

    switch (action.action) {
      case "novo-produto": {
        setLoading(true);
        setShowModalCadastroProduto(true);
        setLoading(false);
        break;
      }
      case "buscar-existente": {
        setLoading(true);
        setShowModalBuscarProduto(true);
        setLoading(false);
        break;
      }
      case "produto-sugerido": {
        setLoading(true);
        let findSuggestItem = listRequest.data?.find(item => item.id === action?.id)
          
        if(findSuggestItem?.sugerido === null) {
          setCallbackShown(true);
          setCallbackType('error');
          setCallbackDuration(3000);
          setCallbackMessage('Erro!');
          setCallbackErrorList(['Esse item não possuí um produto sugerido pois nunca foi vinculado anteriormente!']);
          setLoading(false);
        } else {

          let newItem = {
            id_item: action.id,
            suggested: true,
            data: findSuggestItem?.sugerido,
          }
      
          setItemsProduct([...itemsProduct, newItem]); 

          setCallbackShown(true);
          setCallbackType('success');
          setCallbackDuration(3000);
          setCallbackMessage('Produto sugerido com sucesso.');
          setLoading(false);
        }

        setLoading(false);
        break;
      }
      default:
        break;
    }
  };

  const handleOpenModalCadastro = useCallback((idItemNota) => {
    const produtoAtivo = listRequest?.data?.find( a => idItemNota === a.id)
    setValorCustoProdutoAtivo(produtoAtivo?.valor_unitario_tributario)
    setIdCompany(dataNota?.destinatario_id_empresa);
    setShowModalCadastroProduto(true);

    setIdItemNota(idItemNota);
  }, [idCompany, setIdCompany, listRequest])

  let handleOpenModalBuscar = (idItemNota) => {
    const produtoAtivo = listRequest?.data?.find( a => idItemNota === a.id)
    setValorCustoProdutoAtivo(produtoAtivo?.valor_unitario_tributario)
    setShowModalBuscarProduto(true);  
    setIdItemNota(idItemNota);
  }

  const loadNotaFiscal = useCallback(async () => {
    const response = await consultaNotaEntrada(idNotaFiscal);

    if (response.status === 200) {
      const data = response.data;

      setIdCompany(data?.destinatario_id_empresa);


      let newData = {
        ...data,
        data_hora_emissao: formatDate(data?.data_hora_emissao.substring(0, 10)),
        valor_total: numberToCurrency(data?.valor_total),
      }

      setDataNota(newData);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  }, [idNotaFiscal, consultaNotaEntrada]);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await loadNotaFiscal();
      setLoading(false);
    })();
  }, [loadNotaFiscal]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);

    if(itemsProduct.length !== 0) {
      setItemsProduct([]);
    }

    const response = await listaNotaEntradaItens(
      idNotaFiscal,
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
    );

    if (response.data) {
      if (response.data.hasOwnProperty("data")) {
        const { data } = response.data;

        data.map(item => {
          if(item?.dados_produto?.length === 0) {
            return;
          } else {
              let newItem = {
                id_item: item?.id_nota_fiscal_produto_entrada_item,
                data: item.dados_produto,
              }

              setItemsProduct(prevState => [...prevState, newItem]); 
        }})

        const newData = data.map(item => ({
          ...item,
          id: item.id_nota_fiscal_produto_entrada_item,
        }))

        const newResult = {
          ...response.data,
          data: newData,
        }

        setListRequest(newResult);
      } else {
        const { data } = response;

        const newData = data.map(item => ({
          ...item,
          id: item.id_nota_fiscal_produto_entrada_item,
        }))

        const newResult = {
          ...response,
          data: newData,
        }

        setListRequest(newResult);
      }
    }
    setLoading(false);
  }, [defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    listaNotaEntradaItens,
    idNotaFiscal,
  ]);

  useEffect(() => {
    updateTable();
  }, [updateTable])

  let onPageChange = useCallback((newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onOrderBy = useCallback((newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onPerPage = useCallback((newPerPage) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onSearchTerm = useCallback((value) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let handleConfirmImport = useCallback(async () => {
    setLoading(true);

    if(!idProduct) {
      setCallbackType('error');
      setCallbackDuration(2000);
      setCallbackMessage('Erro!');
      setCallbackErrorList(['Você precisa selecionar um produto para importar o item.']);
      setCallbackShown(true);
      setOpenConfirm(false);
      setLoading(false);
      return;
    }

    const response = await importarProdutoNotaEntrada(idProduct, idNotaEntradaItem, quantidade, valorCustoUnitario);

    if (response.status === 200) {
      setCallbackType('success');
      setCallbackDuration(2000);
      setCallbackMessage('Produto importado com sucesso!');
      setCallbackShown(true);
      updateTable();
    }

    if (response.status === 500) {
      setCallbackType('error');
      setCallbackDuration(2000);
      setCallbackMessage('Erro!');
      setCallbackErrorList(['Erro interno do servidor. Por favor, contate o suporte.']);
      setCallbackShown(true);
    }

    if (response.status !== 200 && response.status !== 500) {
      const data = response.data;
      setCallbackShown(true);
      setCallbackType('error');
      setCallbackMessage('Erro!');
      setCallbackErrorList(data?.msg);
    }

    setOpenConfirm(false);
    setLoading(false);
  }, [idProduct, idNotaEntradaItem, quantidade, valorCustoUnitario])


  let handleConfirmDisable = useCallback(async () => {
    setLoading(true);

    const response = await desabilitaItemNotaEntrada(idDisable);

    if (response.status === 200) {
      setCallbackType('success');
      setCallbackDuration(2000);
      setCallbackMessage('Item desabilitado com sucesso!');
      setCallbackShown(true);
      updateTable();
    }

    if (response.status === 500) {
      setCallbackType('error');
      setCallbackDuration(2000);
      setCallbackMessage('Erro!');
      setCallbackErrorList(['Erro interno do servidor. Por favor, contate o suporte.']);
      setCallbackShown(true);
    }

    if (response.status !== 200 && response.status !== 500) {
      const data = response.data;
      setCallbackShown(true);
      setCallbackType('error');
      setCallbackMessage('Erro!');
      setCallbackErrorList(data?.msg);
    }

    setOpenConfirmDisable(false);
    setLoading(false);
  }, [idDisable])

  const handleOpenConfirm = (idProduto, idNotaEntradaItem, quantidade, custoUnitarioProduto) => {
    setOpenConfirm(true);
    setIdProduct(idProduto);
    setIdNotaEntradaItem(idNotaEntradaItem);
    setQuantidade(quantidade);
    setValorCustoUnitario(custoUnitarioProduto)
  }

  const handleOpenConfirmDisable = (id) => {
    setOpenConfirmDisable(true);
    setIdDisable(id);
  }

  const cbOnConfirmModal = useCallback((product, quantidade, valorCusto) => {
    if(product) {
      setShowModalBuscarProduto(false);
      // product.quantidade = quantidade;
      // product.valor_de_custo = valorCusto;
      console.log(product); 
      let newItem = {
        id_item: idItemNota,
        data: {
          ...product,
          quantidade,
          valor_de_custo: valorCusto 
        },
      }
  
      setItemsProduct([...itemsProduct, newItem]); 
    }
  }, [idItemNota, itemsProduct])

  const cbOnSuccess = useCallback((product) => {
    if(product) {
      setShowModalCadastroProduto(false);

      let newItem = {
        id_item: idItemNota,
        data: product,
        cadastrado: true
      }
  
      setItemsProduct([...itemsProduct, newItem]); 
    }
  }, [idItemNota, itemsProduct])

  function handleClose() {
    setCallbackShown(false);
  }

  function handleCloseModal() {
    setShowModalCadastroProduto(false);
    setShowModalBuscarProduto(false);
  }

  return (
    <>
      {loading && <OverlayLoading />}

      <div className="session-container">
        <div className="container-info-header">
          <div className="box-data">
            <h1>Dados da Nota Fiscal</h1>
            <div className="content-data">
              <div>
                <span className="text-data-notafiscal">Data de emissão: <p>{dataNota.data_hora_emissao}</p></span>
                <span className="text-data-notafiscal">Emitente: <p>{dataNota.emitente_razao_social}</p></span>
                <span className="text-data-notafiscal">UF: <p>{dataNota.uf}</p></span>
              
              </div>
              <div>
                <span className="text-data-notafiscal">Número: <p>{dataNota.numero}</p></span>
                <span className="text-data-notafiscal">Série: <p>{dataNota.serie}</p></span>
                <span className="text-data-notafiscal">Total: <p>{dataNota.valor_total}</p></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <ListComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        titleColumnOne="Produtos da NF-e importada"
        titleColumnTwo="Produtos cadastrados no Contábil Hub"
        tableConfig={defaultConfigTable}
        selectedProducts={itemsProduct}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbMoreAction={onMoreAction}
        cbNewProduct={handleOpenModalCadastro}
        cbSearchExisting={handleOpenModalBuscar}
        cbConfirmImport={handleOpenConfirm}
        cbConfirmDisable={handleOpenConfirmDisable}
      />

      <ModalCadastroProduto 
        open={showModalCadastroProduto}
        onClose={handleCloseModal}
        cbSuccess={cbOnSuccess}
        idCompany={idCompany}
        valorCusto={valorCustoProdutoAtivo}
      />
      <ModalBuscarProduto 
        open={showModalBuscarProduto}
        onClose={handleCloseModal}
        onConfirm={cbOnConfirmModal}
        valorCusto={valorCustoProdutoAtivo}
      />
      <ConfirmDialog
        open={openConfirm}
        title="CONFIRMAR IMPORTAÇÃO DE ITEM"
        description="Você tem certeza que quer importar esse item?"
        cancelLabel="Cancelar"
        acceptLabel="Confirmar"
        onCancel={() => setOpenConfirm(false)}
        onClose={() => setOpenConfirm(false)}
        onAccept={handleConfirmImport}
      />
      <ConfirmDialog
        open={openConfirmDisable}
        title="DESABILITAR ITEM"
        description="Você tem certeza que quer desabilitar esse item?"
        cancelLabel="Cancelar"
        acceptLabel="Confirmar"
        onCancel={() => setOpenConfirmDisable(false)}
        onClose={() => setOpenConfirmDisable(false)}
        onAccept={handleConfirmDisable}
      />
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        handleClose={handleClose}
        message={callbackMessage}
        duration={callbackDuration}
        errorList={callbackErrorList}
      />
       <ModalLoginExpirado open={openLoginExpirado} />
       
    </>
  )
}