import Axios from "./Axios";

const valeTransporteServices = {
  cadastraVale: async (
    id_funcionario,
    descricao,
    qtd_por_dia,
    valor,
    id_vale_transporte
  ) => {
    const result = await Axios.post("/cadastra-vale-transporte", {
      id_funcionario,
      descricao,
      qtd_por_dia,
      valor,
      id_vale_transporte,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  consultaVale: async (id) => {
    const result = await Axios.get(`/consulta-vale-transporte/${id}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },

  getValeTransporte: async (
    id,
    page = null,
    order_by = null,
    order_by_type = null,
    per_page = null
  ) => {
    const response = await Axios.get(`/lista-vale-transporte/${id}`, {
      params: {
        order_by,
        order_by_type,
        page,
        per_page,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);
    return response;
  },
  removeValeTransporte: async (id) => {
    const result = await Axios.get(`/remove-vale-transporte/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};

export default valeTransporteServices;
