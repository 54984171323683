import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import validateFields from "../../../../utils/validateFields";
import ModalLoginExpirado from "../../../../components/ModalLoginExpirado";
import ButtonComponent from "../../../../components/ButtonComponent";

import CallbackMessage from "../../../../components/CallbackMessage";
import OverlayLoading from "../../../../components/OverlayLoading";
import Stepper from "../../../../components/Stepper";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  useMediaQuery,
} from "@material-ui/core";

import empresasService from "../../../../services/empresasService";

import { Aviso } from "../componentes/aviso";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import styles from "../styles.module.scss";
import { DadosPessoais } from "../componentes/dadosPessoais";
import { EnderecoContato } from "../componentes/enderecoContato";
import { Documentos } from "../componentes/documentos";
import { Dependentes } from "../componentes/dependentes";
import preAdmissaoService from "../../../../services/dpPreAdmissaoService";
import { onlyUnsignedNumbers, validarEmail } from "../../../../utils/strings";

export function CadastroPreAdmissaoColaborador() {
  const isMobile = useMediaQuery("(max-width: 1100px)");

  const history = useHistory();
  const { cadastrarPreAdmissaoFuncionario, verificaHashPreAdmissao } =
    preAdmissaoService;
  const locationState = history.location.state;
  const [loading, setLoading] = useState(false);
  const [mostrarAviso, setMostrarAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState(null);
  const [hashVerificada, setHashVerificada] = useState(false);
  const [hashValida, setHashValida] = useState(false);
  const [cadastroPreenchido, setCadastroPreenchido] = useState(false);
  const [idEmpresa, setIdempresa] = useState(null);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const params = useParams();
  const { id, hash } = params;
  console.log(hash);
  const IntId = parseInt(id, 10);

  const [activeStep, setActiveStep] = useState(0);

  const [availableSteps, setAvailableSteps] = useState([
    "DADOS PESSOAIS",
    "ENDEREÇO E CONTATO",
    "DOCUMENTOS",
    "DEPENDENTES",
  ]);

  const [preAdmissaoDadosPessoais, setPreAdmissaoDadosPessoais] = useState({
    // DADOS PESSOAIS
    imagem: null,
    imagemPreview: null,
    dataNascimento: null,
    estadoCivil: null,
    sexo: null,
    cor: null,
    escolaridade: null,
    nomeMae: null,
    nomePai: null,
    paisNascimento: null,
    nacionalidade: null,
    estadoNascimento: null,
    municipioNascimento: null,
    possuiDeficiencia: null,
    deficiencia: null,
    observacoes: null,
  });

  const [preAdmissaoEnderecoContato, setPreAdmissaoEnderecoContato] = useState({
    // ENDEREÇO E CONTATO
    cep: null,
    rua: null,
    numero: null,
    bairro: null,
    cidade: null,
    estado: null,
    pais: null,
    email: null,
    telefone: null,
    complemento: null,
    celular: null,
  });

  const [preAdmissaoDocumentos, setPreAdmissaoDocumentos] = useState({
    // DOCUMENTOS
    cpf: null,
    nisNit: null,
    tipoIdentidade: null,
    numeroDoc: null,
    emissorDoc: null,
    emissaoDoc: null,
    ufDoc: null,
    ctps: null,
    serieCtps: null,
    ufCtps: null,
    emissaoCtps: null,
    emissaoNisNit: null,
    tituloEleitor: null,
    zonaEleitoral: null,
    secaoEleitoral: null,
    cnh: null,
    emissaoCnh: null,
    validadeCnh: null,
    categoriaCnh: null,
    ufCnh: null,
    emissaoPrimeiraCnh: null,
    tipoConta: null,
    banco: null,
    bancoOutros: null,
    agencia: null,
    conta: null,
    digitoConta: null,
    documentos: [],
  });

  const [preAdmissaoDependentes, setPreAdmissaoDependentes] = useState({
    // ENDEREÇO E CONTATO
    possuiDependentes: null,
    dependentes: [
      {
        nome: null,
        cpf: null,
        dataNascimento: null,
        inicioDependencia: null,
        paisNascimento: null,
        nacionalidade: null,
        telefone: null,
        cep: null,
        rua: null,
        numero: null,
        bairro: null,
        cidade: null,
        estado: null,
        pais: null,
        parentesco: null,
        dataEntradaPais: null,
        possuiDeficiencia: null,
        inclusoPlanoSaude: null,
        inclusoPlanoOdontologico: null,
        deficiencia: null,
        mesmoEndereco: null,
        complemento: null,
        declaraDependente: null,
        salarioFamilia: null,
        certidaoNascimento: null,
        cadernetaVacinacao: null,
      },
    ],
  });

  // FIM CAMPOS PARA O SERVICE

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const handleNextStep = () => {
    if (locationState?.details) {
      return setActiveStep(activeStep + 1);
    }
    if (activeStep === 0) {
      const stepZeroFields = [
        {
          label: "datanascimento",
          value: preAdmissaoDadosPessoais.dataNascimento,
        },
        {
          label: "estadocivil",
          value: preAdmissaoDadosPessoais.estadoCivil,
        },
        {
          label: "sexo",
          value: preAdmissaoDadosPessoais.sexo,
        },
        {
          label: "cor",
          value: preAdmissaoDadosPessoais.cor,
        },
        {
          label: "escolaridade",
          value: preAdmissaoDadosPessoais.escolaridade,
        },
        {
          label: "nomeMae",
          value: preAdmissaoDadosPessoais.nomeMae,
        },
        {
          label: "paisNascimento",
          value: preAdmissaoDadosPessoais.paisNascimento,
        },
        {
          label: "nacionalidadeDp",
          value: preAdmissaoDadosPessoais.nacionalidade,
        },
        {
          label: "estadoNascimento",
          value: preAdmissaoDadosPessoais.estadoNascimento,
        },
        {
          label: "municipioNascimento",
          value: preAdmissaoDadosPessoais.municipioNascimento,
        },
      ];
      const stepZeroErrors = validateFields(stepZeroFields);

      if (stepZeroErrors.length !== 0) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(stepZeroErrors);
        return;
      }
    }

    if (activeStep === 1) {
      const step1Fields = [
        {
          label: "cep",
          value: preAdmissaoEnderecoContato.cep,
        },
        {
          label: "rua",
          value: preAdmissaoEnderecoContato.rua,
        },
        {
          label: "numero",
          value: preAdmissaoEnderecoContato.numero,
        },
        {
          label: "bairro",
          value: preAdmissaoEnderecoContato.bairro,
        },
        {
          label: "cidade",
          value: preAdmissaoEnderecoContato.cidade,
        },
        {
          label: "estado",
          value: preAdmissaoEnderecoContato.estado,
        },
        {
          label: "paisDp",
          value: preAdmissaoEnderecoContato.pais,
        },
        {
          label: "telefone",
          value: preAdmissaoEnderecoContato.telefone,
        },
      ];
      const step1Errors = validateFields(step1Fields);

      if(!validarEmail(preAdmissaoEnderecoContato.email)){
        step1Errors.push('Por favor, digite um endereço de email válido')
      }
      console.log({step1Errors});
      if (step1Errors.length !== 0) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(step1Errors);
        return;
      }
    }

    if (activeStep === 2) {
      const step2Fields = [
        {
          label: "cpf",
          value: preAdmissaoDocumentos.cpf,
        },
        {
          label: "nisNit",
          value: preAdmissaoDocumentos.nisNit,
        },
        {
          label: "tipoIdentidadeDp",
          value: preAdmissaoDocumentos.tipoIdentidade,
        },
        {
          label: "numeroDoc",
          value: preAdmissaoDocumentos.numeroDoc,
        },
        {
          label: "emissorDoc",
          value: preAdmissaoDocumentos.emissorDoc,
        },
        {
          label: "emissaoDoc",
          value: preAdmissaoDocumentos.emissaoDoc,
        },
        {
          label: "ufDoc",
          value: preAdmissaoDocumentos.ufDoc,
        },
        {
          label: "ctps",
          value: preAdmissaoDocumentos.ctps,
        },
        {
          label: "seriectps",
          value: preAdmissaoDocumentos.serieCtps,
        },
        {
          label: "ufCtpsDp",
          value: preAdmissaoDocumentos.ufCtps,
        },
        {
          label: "emissaoctps",
          value: preAdmissaoDocumentos.emissaoCtps,
        },
      ];
      const step2Errors = validateFields(step2Fields);
      console.log({ documentos: preAdmissaoDocumentos.documentos });
      if (preAdmissaoDocumentos.documentos.length === 0) {
        step2Errors.push(
          'Você precisa adicionar no mínimo 1 documento no campo "Anexar documentos"'
        );
      }
      if (step2Errors.length !== 0) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(step2Errors);
        return;
      }
    }

    setActiveStep(activeStep + 1);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      if (activeStep === 3) {
        let hasError = false;

        if (preAdmissaoDependentes?.possuiDependentes === null) {
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackErrorList([
            'O Campo "Possui dependentes?" é obrigatório.',
          ]);
          return;
        }

        if (preAdmissaoDependentes?.possuiDependentes) {
          preAdmissaoDependentes?.dependentes.forEach((dependente) => {
            let dependentesFields = [
              {
                label: "nome",
                value: dependente.nome,
              },
              {
                label: "cpf",
                value: dependente.cpf,
              },
              {
                label: "datanascimento",
                value: dependente.dataNascimento,
              },
              {
                label: "inicioDependencia",
                value: dependente.inicioDependencia,
              },
              {
                label: "paisNascimento",
                value: dependente.paisNascimento,
              },
              {
                label: "nacionalidadeDp",
                value: dependente.nacionalidade,
              },
              {
                label: "telefone",
                value: dependente.telefone,
              },
            ];

            if (!dependente.mesmoEndereco) {
              dependentesFields = [
                ...dependentesFields,
                {
                  label: "cep",
                  value: dependente.cep,
                },
                {
                  label: "rua",
                  value: dependente.rua,
                },
                {
                  label: "numero",
                  value: dependente.numero,
                },
                {
                  label: "bairro",
                  value: dependente.bairro,
                },
                {
                  label: "cidade",
                  value: dependente.cidade,
                },
                {
                  label: "estado",
                  value: dependente.estado,
                },
                {
                  label: "paisDp",
                  value: dependente.pais,
                },
              ];
            }

            const dependentesErrors = validateFields(dependentesFields);

            if (dependentesErrors.length !== 0) {
              hasError = true;
              setCallbackShown(true);
              setCallbackType("error");
              setCallbackMessage("Erro!");
              setCallbackErrorList(dependentesErrors);
              return;
            }
          });
          if (hasError) return;
        }
      }

      setLoading(true);

      const formData = new FormData();

      formData.append("hash", hash);
      formData.append("id_empresa", idEmpresa);
      if (preAdmissaoDadosPessoais.imagem != null)
        formData.append("imagem", preAdmissaoDadosPessoais.imagem);
      if (preAdmissaoDadosPessoais.dataNascimento != null)
        formData.append(
          "data_nascimento",
          preAdmissaoDadosPessoais.dataNascimento
        );
      if (preAdmissaoDadosPessoais.estadoCivil != null)
        formData.append(
          "id_estado_civil",
          preAdmissaoDadosPessoais.estadoCivil
        );
      if (preAdmissaoDadosPessoais.sexo != null)
        formData.append("id_sexo", preAdmissaoDadosPessoais.sexo);
      if (preAdmissaoDadosPessoais.cor != null)
        formData.append("id_cor", preAdmissaoDadosPessoais.cor);
      if (preAdmissaoDadosPessoais.escolaridade != null)
        formData.append(
          "id_grau_instrucao",
          preAdmissaoDadosPessoais.escolaridade
        );
      if (preAdmissaoDadosPessoais.nomeMae != null)
        formData.append("nome_mae", preAdmissaoDadosPessoais.nomeMae);
      if (preAdmissaoDadosPessoais.nomePai != null)
        formData.append("nome_pai", preAdmissaoDadosPessoais.nomePai);
      if (preAdmissaoDadosPessoais.paisNascimento != null)
        formData.append(
          "id_pais_nascimento",
          preAdmissaoDadosPessoais.paisNascimento
        );
      if (preAdmissaoDadosPessoais.nacionalidade != null)
        formData.append(
          "id_nacionalidade",
          preAdmissaoDadosPessoais.nacionalidade
        );
      if (preAdmissaoDadosPessoais.estadoNascimento != null)
        formData.append(
          "id_uf_nascimento",
          preAdmissaoDadosPessoais.estadoNascimento
        );
      if (preAdmissaoDadosPessoais.municipioNascimento != null)
        formData.append(
          "id_municipio_nascimento",
          preAdmissaoDadosPessoais.municipioNascimento
        );
      if (preAdmissaoDadosPessoais.possuiDeficiencia != null)
        formData.append(
          "possui_deficiencia",
          preAdmissaoDadosPessoais.possuiDeficiencia ? "1" : "0"
        );
      if (preAdmissaoDadosPessoais.deficiencia != null)
        formData.append(
          "id_tipo_deficiencia",
          preAdmissaoDadosPessoais.deficiencia
        );
      if (preAdmissaoDadosPessoais.observacoes != null)
        formData.append("observacao", preAdmissaoDadosPessoais.observacoes);
      if (preAdmissaoEnderecoContato.cep != null)
        formData.append("cep", preAdmissaoEnderecoContato.cep);
      if (preAdmissaoEnderecoContato.rua != null)
        formData.append("endereco", preAdmissaoEnderecoContato.rua);
      if (preAdmissaoEnderecoContato.numero != null)
        formData.append("numero", preAdmissaoEnderecoContato.numero);
      if (preAdmissaoEnderecoContato.complemento != null)
        formData.append("complemento", preAdmissaoEnderecoContato.complemento);
      if (preAdmissaoEnderecoContato.bairro != null)
        formData.append("bairro", preAdmissaoEnderecoContato.bairro);
      if (preAdmissaoEnderecoContato.cidade != null)
        formData.append("id_cidade", preAdmissaoEnderecoContato.cidade);
      if (preAdmissaoEnderecoContato.estado != null)
        formData.append("id_uf", preAdmissaoEnderecoContato.estado);
      if (preAdmissaoEnderecoContato.pais != null)
        formData.append("id_pais", preAdmissaoEnderecoContato.pais);
      if (preAdmissaoEnderecoContato.email != null)
        formData.append("email", preAdmissaoEnderecoContato.email);
      if (preAdmissaoEnderecoContato.telefone != null)
        formData.append("telefone", preAdmissaoEnderecoContato.telefone);
      if (preAdmissaoEnderecoContato.celular != null)
        formData.append("celular", preAdmissaoEnderecoContato.celular);
      if (preAdmissaoDocumentos.cpf != null)
        formData.append("cpf", onlyUnsignedNumbers(preAdmissaoDocumentos.cpf));
      if (preAdmissaoDocumentos.nisNit != null)
        formData.append("pis", preAdmissaoDocumentos.nisNit);
      if (preAdmissaoDocumentos.emissaoNisNit != null)
        formData.append(
          "data_emissao_pis",
          preAdmissaoDocumentos.emissaoNisNit
        );
      if (preAdmissaoDocumentos.tipoIdentidade != null)
        formData.append(
          "id_tipo_identidade",
          preAdmissaoDocumentos.tipoIdentidade
        );
      if (preAdmissaoDocumentos.numeroDoc != null)
        formData.append("identidade", preAdmissaoDocumentos.numeroDoc);
      if (preAdmissaoDocumentos.emissorDoc != null)
        formData.append(
          "orgao_emissor_identidade",
          preAdmissaoDocumentos.emissorDoc
        );
      if (preAdmissaoDocumentos.emissaoDoc != null)
        formData.append(
          "data_emissao_identidade",
          preAdmissaoDocumentos.emissaoDoc
        );
      if (preAdmissaoDocumentos.ufDoc != null)
        formData.append("id_uf_identidade", preAdmissaoDocumentos.ufDoc);
      if (preAdmissaoDocumentos.ctps != null)
        formData.append("nro_ctps", preAdmissaoDocumentos.ctps);
      if (preAdmissaoDocumentos.serieCtps != null)
        formData.append("serie_ctps", preAdmissaoDocumentos.serieCtps);
      if (preAdmissaoDocumentos.ufCtps != null)
        formData.append("id_uf_ctps", preAdmissaoDocumentos.ufCtps);
      if (preAdmissaoDocumentos.emissaoCtps != null)
        formData.append("data_emissao_ctps", preAdmissaoDocumentos.emissaoCtps);
      if (preAdmissaoDocumentos.tituloEleitor != null)
        formData.append("titulo_eleitor", preAdmissaoDocumentos.tituloEleitor);
      if (preAdmissaoDocumentos.zonaEleitoral != null)
        formData.append("zona_eleitoral", preAdmissaoDocumentos.zonaEleitoral);
      if (preAdmissaoDocumentos.secaoEleitoral != null)
        formData.append(
          "secao_eleitoral",
          preAdmissaoDocumentos.secaoEleitoral
        );
      if (preAdmissaoDocumentos.cnh != null)
        formData.append("carteira_habilitacao", preAdmissaoDocumentos.cnh);
      if (preAdmissaoDocumentos.emissaoCnh != null)
        formData.append("data_emissao_cnh", preAdmissaoDocumentos.emissaoCnh);
      if (preAdmissaoDocumentos.validadeCnh != null)
        formData.append("data_validade_cnh", preAdmissaoDocumentos.validadeCnh);
      if (preAdmissaoDocumentos.categoriaCnh != null)
        formData.append("id_categoria_cnh", preAdmissaoDocumentos.categoriaCnh);
      if (preAdmissaoDocumentos.ufCnh != null)
        formData.append("id_uf_cnh", preAdmissaoDocumentos.ufCnh);
      if (preAdmissaoDocumentos.emissaoPrimeiraCnh != null)
        formData.append(
          "data_primeira_cnh",
          preAdmissaoDocumentos.emissaoPrimeiraCnh
        );
      if (preAdmissaoDocumentos.tipoConta != null)
        formData.append("id_tipo_conta_banco", preAdmissaoDocumentos.tipoConta);
      if (preAdmissaoDocumentos.banco != null)
        formData.append("id_banco", preAdmissaoDocumentos.banco);
      if (preAdmissaoDocumentos.bancoOutros != null)
        formData.append("banco_nome", preAdmissaoDocumentos.bancoOutros);
      if (preAdmissaoDocumentos.agencia != null)
        formData.append("agencia", preAdmissaoDocumentos.agencia);
      if (preAdmissaoDocumentos.conta != null)
        formData.append("conta", preAdmissaoDocumentos.conta);
      if (preAdmissaoDocumentos.digitoConta != null)
        formData.append("digito", preAdmissaoDocumentos.digitoConta);

      preAdmissaoDocumentos.documentos?.forEach((item, index) => {
        formData.append(`documentos[${index}][nome]`, item.nome);
        formData.append(`documentos[${index}][arquivo]`, item.arquivo);
      });

      if (preAdmissaoDependentes.possuiDependentes != null)
        formData.append("dependente", preAdmissaoDependentes.possuiDependentes);
      if (preAdmissaoDependentes.possuiDependentes) {
        preAdmissaoDependentes.dependentes?.forEach((item, index) => {
          formData.append(`dependentes[${index}][nome]`, item.nome);
          formData.append(
            `dependentes[${index}][cpf]`,
            onlyUnsignedNumbers(item.cpf)
          );
          formData.append(
            `dependentes[${index}][id_parentesco]`,
            item.parentesco
          );
          formData.append(
            `dependentes[${index}][data_nascimento]`,
            item.dataNascimento
          );
          formData.append(
            `dependentes[${index}][data_inicio_dependencia]`,
            item.inicioDependencia
          );
          formData.append(
            `dependentes[${index}][id_pais_nascimento]`,
            item.paisNascimento
          );
          formData.append(
            `dependentes[${index}][id_nacionalidade]`,
            item.nacionalidade
          );
          formData.append(
            `dependentes[${index}][data_entrada_pais]`,
            item.dataEntradaPais
          );
          formData.append(`dependentes[${index}][telefone]`, item.telefone);
          formData.append(
            `dependentes[${index}][possui_deficiencia]`,
            item.possuiDeficiencia ? 1 : 0
          );
          formData.append(
            `dependentes[${index}][incluso_no_plano_de_saude]`,
            item.inclusoPlanoSaude ? 1 : 0
          );
          formData.append(
            `dependentes[${index}][incluso_no_plano_odontologico]`,
            item.inclusoPlanoOdontologico ? 1 : 0
          );
          formData.append(
            `dependentes[${index}][id_tipo_deficiencia]`,
            item.deficiencia
          );
          formData.append(
            `dependentes[${index}][mesmo_endereco_funcionario]`,
            item.mesmoEndereco ? 1 : 0
          );
          if (!item.mesmoEndereco) {
            formData.append(`dependentes[${index}][cep]`, item.cep);
            formData.append(`dependentes[${index}][rua]`, item.rua);
            formData.append(`dependentes[${index}][numero]`, item.numero);
            formData.append(
              `dependentes[${index}][complemento]`,
              item.complemento
            );
            formData.append(`dependentes[${index}][bairro]`, item.bairro);
            formData.append(`dependentes[${index}][id_cidade]`, item.cidade);
            formData.append(`dependentes[${index}][id_estado]`, item.estado);
            formData.append(`dependentes[${index}][id_pais]`, item.pais);
          }
          formData.append(
            `dependentes[${index}][declara_no_irpf]`,
            item.declaraDependente ? 1 : 0
          );
          formData.append(
            `dependentes[${index}][possui_salario_familia]`,
            item.salarioFamilia ? 1 : 0
          );
          if (item.certidaoNascimento)
            formData.append(
              `dependentes[${index}][certidao_nascimento]`,
              item.certidaoNascimento
            );
          if (item.cadernetaVacinacao)
            formData.append(
              `dependentes[${index}][caderneta_vacinacao]`,
              item.cadernetaVacinacao
            );
        });
      }

      const response = await cadastrarPreAdmissaoFuncionario(formData);
      console.log(response);
      setLoading(false);
      if (response?.data?.success) {
        setTipoAviso("preenchimento-finalizado-funcionario");
        setMostrarAviso(true);
        setCallbackShown(true);
        setCallbackType("success");
        setCallbackMessage(response?.data?.message || "Informações enviadas!");
        setCallbackDuration(2000);
      } else if (typeof response?.data?.error === "string") {
        setLoading(false);
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage(response?.data?.msg || "Erro!");
        setCallbackDuration(4000);
        setCallbackErrorList([response?.data?.error]);
      } else if (typeof response?.data?.error === "object") {
        setLoading(false);
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackDuration(4000);
        setCallbackMessage(response?.data?.msg || "Erro!");
        setCallbackErrorList(
          Object.values(response?.data?.error).flat(Infinity)
        );
      } else {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(["Erro ao cadastrar pré-admissão"]);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  const handleClickStep = (newStep) => {
    if (locationState?.activeStepNavigation) {
      setActiveStep(newStep);
    }
  };

  async function verificarHash() {
    try {
      const response = await verificaHashPreAdmissao(hash);
      if (response?.status === 201 && response?.data?.success) {
        setIdempresa(response?.data?.empresa);
        if (response?.data?.hash) {
          setHashValida(true);
        }
        if (response?.data?.preenchido) {
          setCadastroPreenchido(true);
          setTipoAviso("ja-preenchido");
        }
        setHashVerificada(true);
      } else {
        setHashValida(false);
        setHashVerificada(true);
        setTipoAviso("link-invalido");
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      setHashVerificada(true);
    }
  }

  useEffect(() => {
    verificarHash();
  }, []);

  if (!hashVerificada) {
    return <OverlayLoading />;
  }

  if (hashVerificada && !hashValida) {
    return (
      <Grid container className={isMobile ? "" : "default-forms-container"}>
        <Aviso locationState={locationState} tipo={tipoAviso} />
      </Grid>
    );
  }

  if (hashVerificada && cadastroPreenchido) {
    return (
      <Grid container className={isMobile ? "" : "default-forms-container"}>
        <Aviso locationState={locationState} tipo={tipoAviso} />
      </Grid>
    );
  }

  return (
    <>
      {loading && <OverlayLoading />}
      <Grid container className={isMobile ? "" : "default-forms-container"}>
        {mostrarAviso ? (
          <Aviso locationState={locationState} tipo={tipoAviso} />
        ) : (
          <>
            {!isMobile && (
              <Grid item xs={2}>
                <Stepper
                  useActiveStep
                  activeStep={activeStep}
                  arraySteps={availableSteps}
                  handleClickStep={handleClickStep}
                  activeStepNavigation={locationState?.activeStepNavigation}
                />
              </Grid>
            )}
            <Grid item xs={isMobile ? 12 : 10}>
              <Grid
                container
                spacing={2}
                justifyContent={isMobile ? "flex-start" : "center"}
              >
                <Grid
                  item
                  xs={isMobile ? 12 : 8}
                  className={isMobile ? styles.containerFormMobile : ""}
                  style={isMobile ? {} : { marginTop: 20 }}
                >
                  <form onSubmit={handleFormSubmit}>
                    {isMobile && (
                      <>
                        <Accordion
                          expanded={activeStep === 0}
                          className={styles.accordionStyles}
                        >
                          <AccordionSummary>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              xs={12}
                              className={styles.accordionSummary}
                            >
                              <Grid item className="font-title">
                                <span className="text-pleft">
                                  1. DADOS PESSOAIS
                                </span>
                              </Grid>
                              <Grid item>
                                {activeStep === 0 ? (
                                  <ExpandLess className="font-darkBlue" />
                                ) : (
                                  <ExpandMore className="font-orange" />
                                )}
                              </Grid>
                            </Grid>
                          </AccordionSummary>

                          <AccordionDetails
                            className={styles.accordionDetails}
                            style={{ background: "white" }}
                          >
                            <DadosPessoais
                              locationState={locationState}
                              dados={preAdmissaoDadosPessoais}
                              setDados={setPreAdmissaoDadosPessoais}
                              setLoading={setLoading}
                            />
                            <Grid
                              container
                              xs={12}
                              style={{ marginTop: 10 }}
                              justifyContent="flex-end"
                            >
                              <ButtonComponent
                                className={`btn-success ${styles.btnSteps} w-full`}
                                text="Avançar"
                                onClick={handleNextStep}
                              />
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={activeStep === 1}
                          className={styles.accordionStyles}
                        >
                          <AccordionSummary>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              xs={12}
                              className={styles.accordionSummary}
                            >
                              <Grid item className="font-title">
                                <span className="text-pleft">
                                  2. ENDEREÇO E CONTATO
                                </span>
                              </Grid>
                              <Grid item>
                                {activeStep === 1 ? (
                                  <ExpandLess className="font-darkBlue" />
                                ) : (
                                  <ExpandMore className="font-orange" />
                                )}
                              </Grid>
                            </Grid>
                          </AccordionSummary>

                          <AccordionDetails
                            className={styles.accordionDetails}
                            style={{ background: "white" }}
                          >
                            <EnderecoContato
                              locationState={locationState}
                              dados={preAdmissaoEnderecoContato}
                              setDados={setPreAdmissaoEnderecoContato}
                              setLoading={setLoading}
                            />
                            <Grid
                              container
                              spacing={2}
                              xs={12}
                              style={{ marginTop: 10 }}
                              justifyContent="flex-end"
                            >
                              <Grid item xs={6}>
                                <ButtonComponent
                                  text="Voltar"
                                  className={`btn-secondary ${styles.btnOutline} ${styles.btnSteps} w-full`}
                                  onClick={handleBack}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <ButtonComponent
                                  className={`btn-success ${styles.btnSteps} w-full`}
                                  text="Avançar"
                                  onClick={handleNextStep}
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={activeStep === 2}
                          className={styles.accordionStyles}
                        >
                          <AccordionSummary>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              xs={12}
                              className={styles.accordionSummary}
                            >
                              <Grid item className="font-title">
                                <span className="text-pleft">
                                  3. DOCUMENTOS
                                </span>
                              </Grid>
                              <Grid item>
                                {activeStep === 2 ? (
                                  <ExpandLess className="font-darkBlue" />
                                ) : (
                                  <ExpandMore className="font-orange" />
                                )}
                              </Grid>
                            </Grid>
                          </AccordionSummary>

                          <AccordionDetails
                            className={styles.accordionDetails}
                            style={{ background: "white" }}
                          >
                            <Documentos
                              locationState={locationState}
                              dados={preAdmissaoDocumentos}
                              setDados={setPreAdmissaoDocumentos}
                              setLoading={setLoading}
                            />
                            <Grid
                              container
                              spacing={2}
                              xs={12}
                              style={{ marginTop: 10 }}
                              justifyContent="flex-end"
                            >
                              <Grid item xs={6}>
                                <ButtonComponent
                                  text="Voltar"
                                  className={`btn-secondary ${styles.btnOutline} ${styles.btnSteps} w-full`}
                                  onClick={handleBack}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <ButtonComponent
                                  className={`btn-success ${styles.btnSteps} w-full`}
                                  text="Avançar"
                                  onClick={handleNextStep}
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={activeStep === 3}
                          className={styles.accordionStyles}
                        >
                          <AccordionSummary>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              xs={12}
                              className={styles.accordionSummary}
                            >
                              <Grid item className="font-title">
                                <span className="text-pleft">
                                  4. DEPENDENTES
                                </span>
                              </Grid>
                              <Grid item>
                                {activeStep === 3 ? (
                                  <ExpandLess className="font-darkBlue" />
                                ) : (
                                  <ExpandMore className="font-orange" />
                                )}
                              </Grid>
                            </Grid>
                          </AccordionSummary>

                          <AccordionDetails
                            className={styles.accordionDetails}
                            style={{ background: "white" }}
                          >
                            <Dependentes
                              locationState={locationState}
                              dados={preAdmissaoDependentes}
                              setDados={setPreAdmissaoDependentes}
                              setLoading={setLoading}
                            />
                            <Grid
                              container
                              spacing={2}
                              xs={12}
                              style={{ marginTop: 10 }}
                              justifyContent="flex-end"
                            >
                              <Grid item xs={6}>
                                <ButtonComponent
                                  text="Voltar"
                                  className={`btn-secondary ${styles.btnOutline} ${styles.btnSteps} w-full`}
                                  onClick={handleBack}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <ButtonComponent
                                  className={`btn-success ${styles.btnSteps} w-full`}
                                  text="Enviar"
                                  type="submit"
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )}
                    {!isMobile && (
                      <>
                        {activeStep === 0 && (
                          <DadosPessoais
                            locationState={locationState}
                            dados={preAdmissaoDadosPessoais}
                            setDados={setPreAdmissaoDadosPessoais}
                            setLoading={setLoading}
                          />
                        )}
                        {activeStep === 1 && (
                          <EnderecoContato
                            locationState={locationState}
                            dados={preAdmissaoEnderecoContato}
                            setDados={setPreAdmissaoEnderecoContato}
                            setLoading={setLoading}
                          />
                        )}
                        {activeStep === 2 && (
                          <Documentos
                            locationState={locationState}
                            dados={preAdmissaoDocumentos}
                            setDados={setPreAdmissaoDocumentos}
                            setLoading={setLoading}
                          />
                        )}
                        {activeStep === 3 && (
                          <Dependentes
                            locationState={locationState}
                            dados={preAdmissaoDependentes}
                            setDados={setPreAdmissaoDependentes}
                            setLoading={setLoading}
                          />
                        )}
                        <Grid
                          container
                          justifyContent="flex-end"
                          style={{
                            marginTop: 40,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          {activeStep > 0 ? (
                            <>
                              <Grid
                                item
                                xs={3}
                                style={{ marginRight: 10 }}
                                className={
                                  locationState?.details && activeStep === 3
                                    ? "align-button-right"
                                    : ""
                                }
                              >
                                <ButtonComponent
                                  text="Voltar"
                                  className="btn-secondary btnSteps"
                                  onClick={handleBack}
                                />
                              </Grid>
                            </>
                          ) : null}
                          {activeStep < 3 ? (
                            <>
                              <Grid
                                item
                                {...(locationState?.edit ? { xs: 3 } : {})}
                                style={{ width: 120 }}
                              >
                                <ButtonComponent
                                  className="btn-success btnSteps"
                                  text="Avançar"
                                  onClick={handleNextStep}
                                />
                              </Grid>
                            </>
                          ) : null}

                          {activeStep === 3 ? (
                            <>
                              <Grid item style={{ width: 120 }}>
                                <ButtonComponent
                                  className="btn-info btnSteps"
                                  text="Salvar"
                                  type="submit"
                                />
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </>
                    )}
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {loading && <OverlayLoading />}
        {callbackShown && (
          <CallbackMessage
            open={callbackShown}
            type={callbackType}
            handleClose={handleClose}
            message={callbackMessage}
            duration={callbackDuration}
            errorList={callbackErrorList}
          />
        )}
      </Grid>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
