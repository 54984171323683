import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Dialog, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InputComponent from '../Input';
import ButtonComponent from '../ButtonComponent';
import OverlayLoading from '../OverlayLoading';
import ModalLoginExpirado from '../ModalLoginExpirado';
import CallbackMessage from '../CallbackMessage';
import validateFields from '../../utils/validateFields';
import valeTransporteServices from '../../services/valeTransporteServices';
import './styles.scss';

function ModalNovoValeTransporte({
  open,
  onClose,
  idFuncionario,
  idValeTransporte,
}) {
  const [valor, setValor] = useState();
  const [descricao, setDescricao] = useState();
  const [quantidade, setQuantidade] = useState();
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const loadFields = useCallback(async () => {
    await valeTransporteServices
      .consultaVale(idValeTransporte)
      .then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }

        if (response.status !== 201 && response.status !== 200) {
          if (response.data.error)
            setErrors(Object.values(response.data.error));
          else setErrors(['Ocorreu um erro']);
          setShowMessage(true);
        } else {
          setDescricao(response.data.descricao);
          setQuantidade(response.data.qtd_por_dia);
          setValor(response.data.valor);
        }
        setLoading(false);
      });
  }, [idValeTransporte]);

  async function handleSubmit() {
    setErrors([]);

    const fields = [
      {
        label: 'descricao',
        value: descricao,
      },
      {
        label: 'valor',
        value: valor,
      },
      {
        label: 'quantidade',
        value: quantidade,
      },
    ];

    const errorsValidate = validateFields(fields);
    if (errorsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(errorsValidate);
      return;
    }
    setLoading(true);
    await valeTransporteServices
      .cadastraVale(
        idFuncionario,
        descricao,
        quantidade,
        valor,
        idValeTransporte
      )
      .then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        if (response.status !== 201 && response.status !== 200) {
          if (response.data.error)
            setErrors(Object.values(response.data.error));
          else setErrors(['Ocorreu um erro']);
        } else if (response.status === 200 && !idValeTransporte)
          setSuccessMessage('Vale transporte cadastrado com sucesso!');
        else if (response.status === 200 && idValeTransporte)
          setSuccessMessage('Vale transporte editado com sucesso!');
        setLoading(false);
        setShowMessage(true);
      });
  }
  function handleClose() {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }

  useEffect(() => {
    if (idValeTransporte) {
      (async () => {
        setLoading(true);
        await loadFields();
        setLoading(false);
      })();
    }
  }, [idValeTransporte, loadFields]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>NOVO VALE-TRANSPORTE</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{ marginRight: 16, color: '#053b59', padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <InputComponent
                value={descricao}
                label="Descrição"
                required
                handleInputChange={(event) => setDescricao(event.target.value)}
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  value={valor}
                  tipo="dinheiro"
                  label="Valor *"
                  handleInputChange={(event) => setValor(event.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  value={quantidade}
                  tipo="numero"
                  label="Quantidade *"
                  handleInputChange={(event) =>
                    setQuantidade(event.target.value)
                  }
                />
              </Grid>
              <Grid item>
                <span className="required-text">* Obrigatório</span>
              </Grid>
              <Grid container justify="flex-end" item>
                <ButtonComponent
                  text="Salvar"
                  onClick={handleSubmit}
                  className="btn-success zero-margin"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? 'success' : 'error'}`}
        message={`${errors.length === 0 ? successMessage : 'Erro!'}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalNovoValeTransporte;
