import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { FiChevronDown, FiUser } from "react-icons/fi";
import { FaRegBuilding, FaSignOutAlt, FaUserEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import usuarioService from "../../services/usuariosService";
import NavbarNotification from "../NavbarNotification";
import { NavbarContext } from "../../contexts/NavbarContext";

import "./styles.scss";
import { startYvnps } from "../../utils/yvnps";
import { AuthContext } from "../../contexts/AuthContext";

function UserNavbar({ setShowNavbar, isSmallScreen }) {
  const history = useHistory();
  const { setListNotification } = useContext(NavbarContext);
  const { avatar } = useContext(AuthContext);
  const statusUso = window.localStorage.getItem("status_uso");

  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showBadge, setShowBadge] = useState(false);

  const list = [
    // {
    //   title: "Meu perfil", link: "", icon: <FiUser />
    // },
    // {
    //   title: "Obrigações",
    //   link: "/obrigacoes-financeiras",
    //   icon: <FaFileInvoiceDollar />,
    // },
  ];
  const [name, setName] = useState("");

  function logout() {
    window.localStorage.clear();
    setListNotification([]);
    return history.replace("/");
  }

  function onClickEditProfile() {
    if (isSmallScreen) {
      setShowNavbar(false);
    }
  }

  document.documentElement.onclick = function (event) {
    if (event.target !== showProfileMenu) {
      setShowProfileMenu(false);
    }
  };

  useEffect(() => {
    if (history.location.pathname === "/contrato" && statusUso === "0") {
      return setShowBadge(false);
    }

    setShowBadge(true);
  }, [history.location.pathname, statusUso]);

  useEffect(() => {
    async function loadUser() {
      await usuarioService.consultaUsuarioLogado().then((response) => {
        if (response.status === 200) {
          setName(response.data.name);

          window.localStorage.setItem(
            "user",
            JSON.stringify({
              email: response.data.email,
              name: response.data.name,
            })
          );

          startYvnps();
        }
      });
    }
    loadUser();
    return () => setName("");
  }, []);

  return (
    <>
      <div className="header-sidebar-user">
        <div
          className="header-sidebar-user-container"
          onClick={() => setShowProfileMenu(!showProfileMenu)}
        >
          <span className="header-sidebar-user-wrap">
            <div
              className={`header-sidebar-user-icon ${
                avatar ? "avatar-usuario-container" : ""
              }`}
            >
              {avatar ? (
                <img
                  src={avatar}
                  alt="Avatar usuário"
                  className="avatar-usuario"
                />
              ) : (
                <FiUser size="1.4rem" />
              )}
            </div>
            <p className="header-sidebar-user-name">{name}</p>
          </span>
          <span className="header-sidebar-user-collapse-icon-container">
            <FiChevronDown
              size=".6rem"
              className={`header-sidebar-user-collapse-icon ${
                !showProfileMenu
                  ? "header-sidebar-user-options-close"
                  : "header-sidebar-user-options-open"
              }`}
            />
          </span>
        </div>
        <div
          className={`header-sidebar-user-options ${
            !showProfileMenu ? "hide" : ""
          }`}
        >
          <ul>
            {list.map((element, index) => (
              <li key={index}>
                {element.icon ? element.icon : <FaRegBuilding />}
                {element.link ? (
                  <Link
                    to={element.link}
                    className="header-sidebar-user-other-options"
                  >
                    <p>{element.title}</p>
                  </Link>
                ) : (
                  <p>{element.title}</p>
                )}
              </li>
            ))}
            <li
              className="header-sidebar-user-options-profile"
              onClick={onClickEditProfile}
            >
              <Link to="/editar-perfil">
                <FaUserEdit />
                <p>Editar perfil</p>
              </Link>
            </li>
            <li className="header-sidebar-user-options-logout" onClick={logout}>
              <FaSignOutAlt />
              <p>Sair</p>
            </li>
          </ul>
        </div>
      </div>
      <NavbarNotification showBadge={showBadge} />
    </>
  );
}

export default UserNavbar;
