import { Grid } from "@material-ui/core";
import InputCheck from "../../InputCheckLeft";

export function FilterCheckbox({ text, id, onChange, value, ...rest }) {
  return (
    <Grid item {...rest}>
      <InputCheck
        id={id}
        title={text}
        nome="checkbox"
        onInputChange={(value) => onChange(value)}
        checkedValue={value}
      />
    </Grid>
  );
}
