import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InputComponent from "../Input";
import SelectComponent from "../SelectComponent";
import ButtonComponent from "../ButtonComponent";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import Switch from "../Switch";
import servicosServices from "../../services/servicosService";
import "./styles.scss";

function ModalIssqn({ open, onClose, idEmpresa, data = null, callback = () => {} }) {
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  
  const [listServico, setListServico] = useState([]);

  const [field, setField] = useState({
    valor_base_calculo: null,
    valor_aliquota: null,
    valor_issqn: null,
    valor_deducao_para_reducao_base_calculo: null,
    valor_outras_retencoes: null,
    valor_desconto_incondicionado: null,
    valor_desconto_condicionado: null,
    valor_retencao_iss: null,
    indicador_exigibilidade_iss: 0,
    codigo_servico_prestado_dentro_municipio: null,
    codigo_pais_servico_prestado: null,
    n_proc_jud_adm_susp_exigibilidade: null,
    indicador_incentivo_fiscal: 0,
    item_lista_servicos: null
  });

  useEffect(() => {
    let mounted = true;

    if (data) {
      if (mounted) {
        setField({
          valor_base_calculo: data?.valor_base_calculo,
          valor_aliquota: data?.valor_aliquota,
          valor_issqn: data?.valor_issqn,
          valor_deducao_para_reducao_base_calculo: data?.valor_deducao_para_reducao_base_calculo,
          valor_outras_retencoes: data?.valor_outras_retencoes,
          valor_desconto_incondicionado: data?.valor_desconto_incondicionado,
          valor_desconto_condicionado: data?.valor_desconto_condicionado,
          valor_retencao_iss: data?.valor_retencao_iss,
          indicador_exigibilidade_iss: data?.indicador_exigibilidade_iss,
          codigo_servico_prestado_dentro_municipio: data?.codigo_servico_prestado_dentro_municipio,
          codigo_pais_servico_prestado: data?.codigo_pais_servico_prestado,
          n_proc_jud_adm_susp_exigibilidade: data?.n_proc_jud_adm_susp_exigibilidade,
          indicador_incentivo_fiscal: data?.indicador_incentivo_fiscal,
          item_lista_servicos: data?.item_lista_servicos
        })
      }
    }
    
    return () => mounted = false;
  }, [data])
  
  const loadServicos = useCallback(async () => {
    const response = await servicosServices.selecionaServico(idEmpresa);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      return;
    } else if (response.status === 200) setListServico(response.data);
  }, [setListServico, idEmpresa]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadServicos();
      setLoading(false);
    })();
  }, [setLoading, loadServicos]);

  return ( 
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>ISSQN</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content" style={{ paddingTop: 6 }}>
        <form className="session-container-form">
          <Grid container direction="column" spacing={1}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Valor base de cálculo"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_base_calculo}
                  handleInputChange={event => {                  
                    setField({...field, valor_base_calculo: event.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Valor alíquota"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_aliquota}
                  handleInputChange={event => {                    
                    setField({...field, valor_aliquota: event.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Valor ISSQN"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_issqn}
                  handleInputChange={event => {                   
                    setField({ ...field, valor_issqn: event.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <SelectComponent
                  styleType="form"
                  label="Item da lista de serviços"
                  list={listServico}
                  initialValue={field.item_lista_servicos !== undefined 
                    ? listServico.find(item => item.id === Number(field.item_lista_servicos))?.nome 
                    : ""
                  }
                  callback={event => {                 
                    setField({ ...field, item_lista_servicos: event });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  tipo="dinheiro"
                  label="Dedução redução da base de cálculo"
                  value={field.valor_deducao_para_reducao_base_calculo}
                  handleInputChange={event => {
                    setField({ ...field, valor_deducao_para_reducao_base_calculo: event.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Valor de outras retenções"
                  tipo="dinheiro"
                  value={field.valor_outras_retencoes}
                  title="R$"
                  handleInputChange={event => {
                    setField({ ...field, valor_outras_retencoes: event.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>              
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Valor desconto incondicionado"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_desconto_incondicionado}
                  handleInputChange={event => {
                    setField({ ...field, valor_desconto_incondicionado: event.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Valor desconto condicionado"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_desconto_condicionado}
                  handleInputChange={event => {
                    setField({ ...field, valor_desconto_condicionado: event.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Valor retenção ISS"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_retencao_iss}
                  handleInputChange={event => {
                    setField({ ...field, valor_retencao_iss: event.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>           
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Código serviço prestado no município"
                  value={field.codigo_servico_prestado_dentro_municipio}
                  handleInputChange={event => {
                     setField({ ...field, codigo_servico_prestado_dentro_municipio: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Código pais serviço foi prestado"
                  value={field.codigo_pais_servico_prestado}
                  handleInputChange={event => {
                    setField({ ...field, codigo_pais_servico_prestado: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Prod Jud Adm Susp exigibilidade"
                  value={field.n_proc_jud_adm_susp_exigibilidade}
                  handleInputChange={event => {
                    setField({ ...field, n_proc_jud_adm_susp_exigibilidade: event.target.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="flex-end">         
              <Grid item xs={12} sm={12} md={4}>
                <div className="label label-center">
                  Indicador incentivo fiscal
                </div>
                <div className="switch-row">
                  <div className={`${field.indicador_incentivo_fiscal 
                    ? "switch-label-inactive" : "switch-label-active"
                  }`}>
                    Não
                  </div>
                  <Switch
                    checked={Boolean(field.indicador_incentivo_fiscal)}
                    onClick={() => {  
                      let value = 1;
                      if(field.indicador_incentivo_fiscal === 1)
                        value = 0;
                      setField({... field, indicador_incentivo_fiscal: value});
                    }}
                  />
                  <div className={`${field.indicador_incentivo_fiscal 
                    ? "switch-label-active" : "switch-label-inactive"
                  }`}>
                    Sim
                  </div>
                </div>
             </Grid>
             <Grid item xs={12} sm={12} md={4}>
                <div className="label label-center">
                Indicador exigibilidade ISS
                </div>
                <div className="switch-row">
                  <div className={`${field.indicador_exigibilidade_iss 
                    ? "switch-label-inactive" : "switch-label-active"
                  }`}>
                    Não
                  </div>
                  <Switch
                    checked={Boolean(field.indicador_exigibilidade_iss)}
                    onClick={() => {  
                      let value = 1;
                      if(field.indicador_exigibilidade_iss === 1)
                        value = 0;
                      setField({... field, indicador_exigibilidade_iss: value});
                    }}
                  />
                  <div className={`${field.indicador_exigibilidade_iss 
                    ? "switch-label-active" : "switch-label-inactive"
                  }`}>
                    Sim
                  </div>
                </div>
              </Grid>
            </Grid>          
            <Grid container justify="flex-end" item>
              <ButtonComponent
                text="Salvar"
                onClick={() => callback(field)}
                className="btn-success zero-margin"
              />
            </Grid>
          </Grid>
        </form>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}

export default ModalIssqn;
