import Axios from "./Axios";

export default {
  listaPlanos: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null
  ) => {
    const response = await Axios.get("/lista-plano", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  cadastraPlano: async (
    nome_plano,
    valor_plano,
    valor_tabela,
    quantidades_meses,
    status,
    plano_servico,
    exibir_selecao_plano
  ) => {
    const response = await Axios.post("/cadastra-plano", {
      nome_plano,
      valor_plano,
      valor_tabela,
      quantidades_meses,
      status,
      plano_servico,
      exibir_selecao_plano,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  atualizaPlano: async (
    idPlano,
    nome_plano,
    valor_plano,
    valor_tabela,
    quantidades_meses,
    status,
    plano_servico,
    plano_servico_cancelar,
    exibir_selecao_plano
  ) => {
    const response = await Axios.post(`/atualiza-plano/${idPlano}`, {
      nome_plano,
      valor_plano,
      valor_tabela,
      quantidades_meses,
      status,
      plano_servico,
      plano_servico_cancelar,
      exibir_selecao_plano,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  consultaPlano: async (idPlano) => {
    const response = await Axios.get(`/consulta-plano/${idPlano}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  consultaPlanoCustomizado: async (idEmpresa) => {
    const response = await Axios.get(`/busca-plano-customizado/${idEmpresa}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  criarPlanoCustomizado: async (
    id_empresa,
    id_plano,
    valor_customizado,
    servicos,
    habilitar_dda
  ) => {
    const response = await Axios.post(`/definir-plano-customizado`, {
      id_empresa,
      id_plano,
      valor_customizado,
      servicos,
      habilitar_dda,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaPlano: async () => {
    let url = "seleciona-plano";

    let codigo_promocional = window.localStorage.getItem("codigo_promocional");

    if (codigo_promocional != null) {
      url += "?codigo_promocional=" + codigo_promocional;
    }

    const response = await Axios.get(url)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaServico: async () => {
    const response = await Axios.get("/lista-servico-plano")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaPlanoServico: async () => {
    const response = await Axios.get("/seleciona-plano-servico")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaQuantidade: async () => {
    const response = await Axios.get("/seleciona-quantidade-servico-plano")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  removePlano: async (id) => {
    const response = await Axios.get(`/inativa-plano/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  assinaPlano: async (id, anual) => {
    var url = `/assina-plano/${id}?anual=`;
    if (anual) url += 1;
    else url += 0;

    console.log(url);
    const response = await Axios.get(url)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaServicosPrestados: async () => {
    const response = await Axios.get("/seleciona-servicos-prestados")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
};
