import React, { useState } from 'react';
import ButtonComponent from '../../components/ButtonComponent';
import PagamentoRecebimentoExtrato from '../../components/PagamentoRecebimentoExtrato';
import './styles.css';

function ViewModalContas() {
  const [openModalPagamentos, setOpenModalPagamentos] = useState(false);
  const [openModalRecebimentos, setOpenModalRecebimentos] = useState(false);

  function handleOpenModalPagamentos() {
    setOpenModalPagamentos(true);
  }
  function handleOpenModalRecebimentos() {
    setOpenModalRecebimentos(true);
  }
  function handleClose() {
    setOpenModalPagamentos(false);
    setOpenModalRecebimentos(false);
  }

  function cbForm(form) {
    console.log(form);
  }
  return (
    <>
      <div className="session-container">
        <div className="session-container-header">
          <div className="teste-modal-container">
            <ButtonComponent
              text="Abrir Modal de PAGAMENTOS"
              className="btn-primary"
              onClick={handleOpenModalPagamentos}
            />
            <ButtonComponent
              text="Abrir Modal de RECEBIMENTOS"
              className="btn-primary"
              onClick={handleOpenModalRecebimentos}
            />
          </div>

          <div>
            <PagamentoRecebimentoExtrato
              isPagamento
              open={openModalPagamentos}
              onClose={handleClose}
              callback={form => cbForm(form)}
            />
            <PagamentoRecebimentoExtrato
              isRecebimento
              open={openModalRecebimentos}
              onClose={handleClose}
              callback={form => cbForm(form)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewModalContas;
