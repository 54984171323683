import { Grid } from "@material-ui/core";

export function FilterButtons({ children, className }) {
  return (
    <Grid className={className} xs={12}>
      <div className="filter-button-area align-rigth">
        {children}
      </div>
    </Grid>
  );
}
