import Axios from './Axios';

export default {
  listaIntegracoes: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null,
    data_de = null,
    data_ate = null,
  ) => {
    const response = await Axios.get('/lista-integracao-questor', {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
        data_de,
        data_ate,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  listaIntegracoesDisponiveis: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null
  ) => {
    const response = await Axios.get('/seleciona-intergracoes-questor', {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  listaLogIntegracao: async (
    idIntegracao = null
  ) => {
    const response = await Axios.get(`/lista-integracao-questor-log/${idIntegracao}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  excluirIntegracao: async (
    idIntegracao = null
  ) => {
    const response = await Axios.get(`/cancelar-intergracoes-questor/${idIntegracao}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  cadastrarIntegracao: async ({
    idEmpresa,
    idsIntegracoes,
    dataInicio,
    dataFim
  }) => {
    const response = await Axios.post(`/cadastrar-intergracoes-questor`, {
      id_empresa: idEmpresa,
      ids_integracoes: idsIntegracoes,
      data_inicio: dataInicio,
      data_fim: dataFim
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
};
