import React, { useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import SelectComponent from "../../../../../components/SelectComponent";
import ButtonComponent from "../../../../../components/ButtonComponent";
import OverlayLoading from "../../../../../components/OverlayLoading";
import CallbackMessage from "../../../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../../../components/ModalLoginExpirado";

import gerentesService from "../../../../../services/gerentesService";
import corretoresService from "../../../../../services/corretoresService";

import colors from "../../../../../assets/styles/_colors.scss";

import "./styles.scss";

function DefinirGerenteTimeModal({
  open,
  onClose = () => {},
  idCorretor,
  currentIdGerente,
  currentIdTimeCorretor
}) {
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [idGerente, setIdGerente] = useState("");
  const [idTime, setIdTime] = useState("");

  const [listGerente, setListGerente] = useState([]);
  const [listTime, setListTime] = useState([]);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const { selecionaGerente } = gerentesService;
  const { selecionaTimeCorretor, atualizaGerenteTime } = corretoresService;

  useEffect(() => {
    getListGerente();
    getListTime();
  }, []);

  async function getListTime() {
    const { data, status } = await selecionaTimeCorretor();
    if (status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListTime(data);
  }

  async function getListGerente() {
    const { data, status } = await selecionaGerente();
    if (status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListGerente(data);
  }

  const handleSubmit = async () => {
    setLoading(true);
    const formGerenteTime = {
      id_gerente: idGerente ? idGerente : currentIdGerente,
      id_time_corretor: idTime ? idTime : currentIdTimeCorretor
    };

    const response = await atualizaGerenteTime(idCorretor, formGerenteTime);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status === 200) {
      setCallbackType("success");
      setCallbackShown(true);
      setCallbackMessage("Salvo com sucesso!");
      setCallbackDuration(3000);
      setLoading(false);
      setTimeout(() => {
        onClose();
      }, 1000);
    }

    if (response.status !== 200 && response.status !== 401) {
      let responseErrors = Object.values(response?.data?.error) 

      setCallbackType("error");
      setCallbackShown(true);
      setCallbackErrorList(responseErrors.flat(Infinity));
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      setLoading(false);
    }
  }

  return (
    <Dialog
      open={open} 
      fullWidth 
      maxWidth="md" 
      onClose={onClose}
    >
      <DialogTitle style={{ padding: 0 }}>
        {loading && <OverlayLoading />}
        <Grid
          item
          container
          justify="space-between"
          className="gerente-time-container-title"
        >
          <Grid item>
            <span className="title-definicao">DEFINIÇÃO DE GERENTE E TIME</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{
                marginRight: 16,
                color: colors.colorPrimary,
                padding: 0
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SelectComponent
              title="Escolha uma opção"
              selectUnderline
              list={listGerente}
              label="Gerente"
              styleType="form"
              callback={id => setIdGerente(id)}
              initialValue={
                listGerente.find(item => item.id === currentIdGerente)
                  ? listGerente.find(item => item.id === currentIdGerente)
                  : {}
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SelectComponent
              title="Escolha uma opção"
              selectUnderline
              list={listTime}
              label="Time"
              styleType="form"
              callback={id => setIdTime(id)}
              initialValue={
                listTime.find(item => item.id === currentIdTimeCorretor)
                  ? listTime.find(item => item.id === currentIdTimeCorretor)
                  : {}
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingRight: 24 }}>
        <Grid container justify="flex-end" item>
          <ButtonComponent
            text="Salvar"
            onClick={handleSubmit}
            className="btn-primary zero-margin btn-definirGerente"
          />
        </Grid>
      </DialogActions>
      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={() => setCallbackShown(false)}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}

export default DefinirGerenteTimeModal;
