import React, { useState, useEffect, useCallback } from "react";
import InputFile from "../../components/InputFile";
import ButtonComponent from "../../components/ButtonComponent";
import ModalBuscarConciliacao from "../../components/ModalBuscarConciliacao";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import ModalNovaTransacao from "../../components/ModalNovaTransacao";
import CallbackMessage from "../../components/CallbackMessage";
import ConfirmDialog from "../../components/ConfirmDialog";
import Icon from "../../components/Icon";
import { useParams } from "react-router-dom";
import OverlayLoading from "../../components/OverlayLoading";
import { Grid } from "@material-ui/core";
import { StarBorder } from "@material-ui/icons";
import ofxService from "../../services/ofxService";
import conciliacaoService from "../../services/conciliacaoService";
import pagamentosService from "../../services/pagamentosService";
import recebimentosService from "../../services/recebimentosService";
import { formatDate } from "../../utils/dates";
import { numberToCurrency } from "../../utils/functions";
import Pagination from "@material-ui/lab/Pagination";
import SelectField from "../../components/SelectComponent";
import extratosService from "../../services/extratosService";

import "./styles.scss";
import DatePicker from "../../components/DatePicker";
import { debounce } from "throttle-debounce";
import moment from "moment";
import { WidgetPluggy } from "../../components/WidgetPluggy";

function FilterComponent({ dataInicio, dataFim, setDataInicio, setDataFim }) {
  return (
    <div className="session-container filter-component-container filter-conciliacao">
      <Grid
        container
        spacing={2}
        alignItems="center"
        className="form-table"
        xs={8}
      >
        <Grid item xs={6}>
          <DatePicker
            label="De *"
            initDate={dataInicio}
            handleChange={debounce(!dataInicio ? 0 : 750, (value) =>
              setDataInicio(value)
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="Até *"
            initDate={dataFim}
            handleChange={debounce(!dataFim ? 0 : 750, (value) =>
              setDataFim(value)
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}

function CardBuscarContabil({
  type,
  idLancamento,
  setShowModalTransacao,
  setShowModalBuscar,
  transacao,
}) {
  return (
    <div className="card-buscar">
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
        className="card-extrato-contabil card-buscar"
      >
        <Grid item>
          <ButtonComponent
            icone="add"
            iconeSize="16"
            className="info-outline-small"
            text="Nova transação"
            onClick={() => {
              setShowModalTransacao({
                open: true,
                type: type === 1 ? "recebimento" : "pagamento",
                idLancamento: idLancamento,
                transacao,
              });
            }}
          />
        </Grid>
        <Grid item>
          <ButtonComponent
            icone="search"
            iconeSize="16"
            className="info-outline-small"
            text="Buscar lançamentos"
            onClick={() => {
              setShowModalBuscar({
                open: true,
                type: type === 1 ? "recebimento" : "pagamento",
                idLancamento: idLancamento,
                transacao,
              });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

function CardExtratoContabil({
  idLancamento,
  data = "",
  descricao = "",
  valor = "",
  type = "",
  nomeCategoria = "",
  nomeFornecedor = "",
  nomeCliente = "",
  centroCusto = "",
  setShowModalTransacao,
  setShowModalBuscar,
  transacao,
}) {
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  document.documentElement.onclick = function (event) {
    if (event.target !== showMoreOptions) {
      setShowMoreOptions(false);
    }
  };
  return (
    <Grid item container direction="column" className="header-card-contabil">
      <Grid item container justifyContent="space-between">
        <Grid
          item
          container
          alignItems="center"
          className="font-primary"
          xs={6}
        >
          <StarBorder style={{ fontSize: 15, padding: 4 }} />
          Sugestão
        </Grid>
        <Grid
          item
          className="icon-wrapper"
          onClick={() => {
            setShowMoreOptions(true);
          }}
        >
          <Icon name="hamburger" size={18} color="#053B59" className="icon" />
          {showMoreOptions && (
            <Grid item>
              <div className="modal-options">
                <ul className="modal-options-list">
                  <li
                    className="modal-options-list-item"
                    onMouseDown={() => {
                      setShowModalTransacao({
                        open: true,
                        type: type === 1 ? "recebimento" : "pagamento",
                        idLancamento: idLancamento,
                        transacao,
                      });
                    }}
                  >
                    <span className="modal-options-list-item-icon">
                      <Icon name="edit" color="#0a2346" />
                    </span>
                    <span className="modal-options-list-item-label">
                      Nova transação
                    </span>
                  </li>
                  <li
                    className="modal-options-list-item"
                    onMouseDown={() =>
                      setShowModalBuscar({
                        open: true,
                        type: type === 1 ? "recebimento" : "pagamento",
                        idLancamento: idLancamento,
                        transacao,
                      })
                    }
                  >
                    <span className="modal-options-list-item-icon">
                      <Icon name="search" color="#0a2346" />
                    </span>
                    <span className="modal-options-list-item-label">
                      Buscar agendamento
                    </span>
                  </li>
                  <li
                    className="modal-options-list-item"
                    onMouseDown={(e) => {}}
                  >
                    <span className="modal-options-list-item-icon">
                      <Icon name="trash" color="#0a2346" />
                    </span>
                    <span className="modal-options-list-item-label">
                      Excluir conciliação
                    </span>
                  </li>
                </ul>
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item container>
        <Grid
          item
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          className="card-extrato-contabil"
        >
          <Grid item>{data}</Grid>
          <Grid item xs={7}>
            {descricao}
          </Grid>
          <Grid item className={type === 1 ? "font-verde" : "font-vermelho"}>
            {valor}
          </Grid>
          <Grid item container direction="column" spacing={1}>
            {type === 1 ? (
              <Grid item>Cliente: {nomeCliente}</Grid>
            ) : (
              <Grid item>Fornecedor: {nomeFornecedor}</Grid>
            )}
            <Grid item container justifyContent="space-between">
              <Grid item>Categoria: {nomeCategoria}</Grid>
              <Grid item>Centro de custos: {centroCusto}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function CardExtratoOfx({
  data = "",
  descricao = "",
  valor = "",
  type = "",
  id = "",
  setDeleteId,
  setDeleteType,
  setOpenDelete,
}) {
  return (
    <div className="card-extrato">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="grid-extrato"
        spacing={1}
      >
        <Grid item>
          <ButtonComponent
            className="action-outline"
            text="-"
            onClick={() => {
              setDeleteId(id);
              setDeleteType(type);
              setOpenDelete(true);
            }}
          />
        </Grid>
        <Grid item>{data}</Grid>
        <Grid item xs={6}>
          {descricao}
        </Grid>
        <Grid item className={type === 1 ? "font-verde" : "font-vermelho"}>
          {valor}
        </Grid>
      </Grid>
    </div>
  );
}

function CardBanco({
  conta,
  valorPendente,
  atualizarIntegracao,
  setAtualizarIntegracao,
}) {
  return (
    <Grid container>
      <Grid
        xs={12}
        md={atualizarIntegracao ? 8 : 12}
        container
        direction="column"
        className="card-banco"
        spacing={1}
      >
        <Grid item xs sm md className="font-nome-banco">
          {conta.nome_conta}
        </Grid>
        <Grid item container xs sm md spacing={2}>
          <Grid item md={4}>
            Agência: {conta.agencia}
          </Grid>
          <Grid item md={4}>
            Conta contábil: {conta.conta_contabil}
          </Grid>
        </Grid>
        <Grid item container xs sm md spacing={2}>
          <Grid item md={4}>
            Conta corrente: {conta.conta_corrente}
          </Grid>
          <Grid item md={5}>
            Valor pendente para conciliar: {valorPendente}
          </Grid>
          <Grid item md={3} className="font-saldo-banco">
            Saldo: {numberToCurrency(conta.saldo)}
          </Grid>
        </Grid>
      </Grid>
      {atualizarIntegracao ? (
        <Grid xs={12} md={4}>
          <WidgetPluggy
            idEmpresa={conta.id_empresa}
            onClose={() => {}}
            setIdIntegracaoRealizada={(value) => {
              setAtualizarIntegracao(false);
            }}
            idConta={conta.id_conta_bancaria}
            idIntegracao={conta.id_integracao_pluggy}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

function Conciliacao() {
  const params = useParams();
  const { idConta, idEmpresa } = params;

  const [fileOfx, setFileOfx] = useState(null);
  const [listConciliacao, setListConciliacao] = useState([]);
  const [showModalBuscar, setShowModalBuscar] = useState({ open: false });
  const [showModalTransacao, setShowModalTransacao] = useState({ open: false });
  const [loading, setLoading] = useState(false);
  const [qtdPendente, setQtdPendente] = useState("");
  const [valorPendente, setValorPendente] = useState(0);
  const [conta, setConta] = useState({});
  const [page, setPage] = useState(null);
  const [perPage, setPerPage] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [deleteType, setDeleteType] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [usarIntegracao, setUsarIntegracao] = useState(null);
  const [atualizarIntegracao, setAtualizarIntegracao] = useState(null);

  const [dataInicio, setDataInicio] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [dataFim, setDataFim] = useState(moment().format("YYYY-MM-DD"));

  const perPageSelect = [
    { id: 10, nome: "10 por página" },
    { id: 25, nome: "25 por página" },
    { id: 50, nome: "50 por página" },
    { id: 100, nome: "100 por página" },
  ];

  const loadConciliacoes = useCallback(
    async (reloadQuantidade = false) => {
      setLoading(true);
      let list = [];
      await conciliacaoService
        .getListaConciliacao(idConta, page, perPage, dataInicio, dataFim)
        .then((response) => {
          if (response.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          }
          if (response.status === 200) {
            setTotalPages(response.data.last_page);
            setPage(response.data.current_page);
            response.data.data.forEach((item, index) => {
              item.data_referencia_original =
                item.data_referencia?.split(" ")[0];
              item.data_referencia = formatDate(
                item.data_referencia ? item.data_referencia?.split(" ")[0] : ""
              );
              if (item.id_tipo_categoria === 1) {
                item.valorOriginal = Number(item.valor);
                item.valor = numberToCurrency(item.valor);
              } else if (item.id_tipo_categoria === 2) {
                item.valorOriginal = Number(item.valor);
                let currency = numberToCurrency(item.valor);
                currency = currency.split(/(\s+)/);
                item.valor = currency[0] + " -" + currency[2];
              }
              if (item.id_sugestao) {
                if (item.data_referencia_sugestao)
                  item.data_referencia_sugestao = formatDate(
                    item.data_referencia_sugestao
                  );
                if (item.id_tipo_categoria === 1)
                  item.valor_sugestao = numberToCurrency(item.valor_sugestao);
                else if (item.id_tipo_categoria === 2) {
                  let currency = numberToCurrency(item.valor_sugestao).split(
                    /(\s+)/
                  );
                  item.valor_sugestao = currency[0] + " -" + currency[2];
                }
              }
              list.push(item);
            });
          }
        });
      if (reloadQuantidade) {
        await conciliacaoService.valorPendente(idConta).then((response) => {
          if (response.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          }
          if (response.status === 200) {
            setValorPendente(numberToCurrency(response.data.valor_pendente));
            setQtdPendente(response.data.quantidade_pendente);
          }
        });
      }
      setLoading(false);
      setListConciliacao(list);
    },
    [idConta, page, perPage, dataInicio, dataFim]
  );

  const loadConta = useCallback(async () => {
    setLoading(true);
    extratosService.getConsultaConta(idConta).then((response) => {
      if (response.status === 200) {
        console.log({ data: response.data });
        setConta(response.data);
        setUsarIntegracao(response?.data?.integracao_pluggy_ativa || 0);
        setAtualizarIntegracao(response?.data?.atualizar_integracao);
      }
      setLoading(false);
    });
  }, [idConta]);

  function handleClose(event, reason) {
    setErrors([]);
    setShowMessage(false);
  }

  async function handleDeleteLancamento() {
    setLoading(true);
    let response = [];

    if (usarIntegracao) {
      response = await conciliacaoService.removerTransacaoPluggy(deleteId);
    } else {
      if (deleteType === 1) {
        response = await recebimentosService.removerRecebimento(deleteId);
      } else {
        response = await pagamentosService.removerPagamento(deleteId);
      }
    }

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setSuccessMessage("Lançamento removido com sucesso.");
      setErrors([]);
      setShowMessage(true);
    } else {
      if (response.data.error) setErrors(Object.values(response.data.error));
      else setErrors(["Ocorreu um erro"]);
      setShowMessage(true);
    }
    await loadConciliacoes(true);
    setLoading(false);
    handleCancelRemove();
  }

  function handleCancelRemove() {
    setOpenDelete(false);
  }

  async function onClickImportar() {
    setLoading(true);
    let file = String(fileOfx).split(",")[1];
    await ofxService.importarOfx(idConta, file).then(async (response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        setSuccessMessage("Importação realizada com sucesso.");
        setErrors([]);
        setShowMessage(true);
        await loadConciliacoes(true);
      } else {
        setErrors(["Ocorreu um erro"]);
        setShowMessage(true);
        setLoading(false);
      }
    });
  }

  async function onClickConciliar(idLancamento) {
    setLoading(true);
    await conciliacaoService.conciliar(idLancamento).then(async (response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        await loadConciliacoes(true);
      } else setLoading(false);
    });
  }

  async function handleChangePage(event, value) {
    setPage(value);
    window.scrollTo(0, 500);
  }

  async function perPageSelectCb(value) {
    setPerPage(value);
  }

  useEffect(() => {
    (async function loadValues() {
      await loadConta();
      loadConciliacoes(true);
    })();
  }, [loadConta]);

  useEffect(() => {
    async function init() {
      await loadConciliacoes();
    }
    init();
  }, [perPage, page, dataInicio, dataFim, loadConciliacoes]);

  return (
    <>
      {loading && <OverlayLoading />}
      <div className="session-container pagina-conciliacao">
        <span
          className={`session-container-header container-conciliacao ${
            atualizarIntegracao ? "atualizar-integracao" : ""
          }`}
        >
          <Grid container direction="column">
            <Grid item>
              <CardBanco
                conta={conta}
                valorPendente={valorPendente}
                atualizarIntegracao={usarIntegracao && atualizarIntegracao}
                setAtualizarIntegracao={setAtualizarIntegracao}
              />
            </Grid>
            {usarIntegracao === 0 ? (
              <>
                <Grid item>
                  <div className="text text-importar">
                    Importar novo arquivo para conciliação
                  </div>
                </Grid>
                <Grid item container spacing={1} alignItems="flex-end">
                  <Grid item xs={10}>
                    {!showModalTransacao.open && (
                      <InputFile
                        title="Arquivo .ofx"
                        fileFormat=".ofx"
                        onChange={(value) => setFileOfx(value)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2} container justifyContent="flex-end">
                    <ButtonComponent
                      onClick={onClickImportar}
                      text="Importar"
                      className="info-outline"
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
        </span>
      </div>
      {!usarIntegracao || (usarIntegracao && !atualizarIntegracao) ? (
        <div className="session-container extrato-container">
          <Grid container direction="column" alignItems="center">
            <FilterComponent
              dataInicio={dataInicio}
              dataFim={dataFim}
              setDataInicio={setDataInicio}
              setDataFim={setDataFim}
            />
            <Grid
              item
              xs={8}
              className="ofx-header text"
              container
              justifyContent="space-between"
            >
              <Grid item>Itens para conciliar: {qtdPendente}</Grid>
              <Grid item>Valor total para conciliar: {valorPendente}</Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="space-between">
              <Grid item xs={5}>
                <div className="text ofx-column-title ">
                  Lançamentos importados do seu extrato
                </div>
                <div className="divider" />
              </Grid>
              <Grid item xs={5}>
                <div className="text ofx-column-title ">
                  Lançamentos cadastrados na plataforma
                </div>
                <div className="divider" />
              </Grid>
            </Grid>
            {listConciliacao.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item container xs={12} justifyContent="space-between">
                  <Grid item xs={5}>
                    <CardExtratoOfx
                      data={item.data_referencia}
                      descricao={item.descricao}
                      valor={item.valor}
                      type={item.id_tipo_categoria}
                      id={item.id_lancamento}
                      setDeleteId={setDeleteId}
                      setDeleteType={setDeleteType}
                      setOpenDelete={setOpenDelete}
                    />
                  </Grid>
                  {item.id_sugestao && (
                    <Grid
                      item
                      container
                      xs={1}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ButtonComponent
                        icone="arrow-track"
                        iconeSize="16"
                        className="primary-outline btn-conciliar"
                        text="Conciliar"
                        onClick={() => onClickConciliar(item.id_lancamento)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={5}>
                    {item.id_sugestao ? (
                      <CardExtratoContabil
                        idLancamento={item.id_lancamento}
                        data={item.data_referencia_sugestao}
                        descricao={item.descricao_sugestao}
                        valor={item.valor_sugestao}
                        type={item.id_tipo_categoria_sugestao}
                        nomeCategoria={item.nome_categoria_sugestao}
                        nomeFornecedor={item.nome_fornecedor_sugestao}
                        nomeCliente={item.nome_cliente_sugestao}
                        centroCusto={item.nome_centro_custo_sugestao}
                        setShowModalBuscar={setShowModalBuscar}
                        setShowModalTransacao={setShowModalTransacao}
                        transacao={item}
                      />
                    ) : (
                      <CardBuscarContabil
                        type={item.id_tipo_categoria}
                        idLancamento={item.id_lancamento}
                        setShowModalBuscar={setShowModalBuscar}
                        setShowModalTransacao={setShowModalTransacao}
                        transacao={item}
                      />
                    )}
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
            <Grid
              item
              container
              justifyContent="space-between"
              className="conciliacao-pagination"
            >
              <Grid item>
                <div className="table-perpage-wrapper">
                  <SelectField
                    initialValue={perPageSelect[0].nome}
                    list={perPageSelect}
                    callback={perPageSelectCb}
                  />
                </div>
              </Grid>
              <Grid item>
                <div className="table-configuration">
                  <Pagination
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={page}
                    onChange={handleChangePage}
                  />
                </div>
              </Grid>
            </Grid>
            {showModalBuscar.open && (
              <ModalBuscarConciliacao
                type={showModalBuscar.type}
                open={showModalBuscar.open}
                idLancamento={showModalBuscar.idLancamento}
                transacao={showModalBuscar.transacao}
                idEmpresa={idEmpresa}
                onClose={() => setShowModalBuscar({ open: false })}
                onSubmit={() => loadConciliacoes(true)}
                usarIntegracao={usarIntegracao}
              />
            )}
            {showModalTransacao.open && (
              <ModalNovaTransacao
                idEmpresa={idEmpresa}
                type={showModalTransacao.type}
                open={showModalTransacao.open}
                transacao={showModalTransacao.transacao}
                idLancamento={showModalTransacao.idLancamento}
                onClose={() => setShowModalTransacao({ open: false })}
                onSubmit={() => loadConciliacoes(true)}
                usarIntegracao={usarIntegracao}
                idConta={idConta}
              />
            )}
          </Grid>
        </div>
      ) : null}
      <ConfirmDialog
        open={openDelete}
        onCancel={handleCancelRemove}
        onAccept={handleDeleteLancamento}
      />
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default Conciliacao;
