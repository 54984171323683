import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";

import TableComponent from "../../../components/TableComponent";
import ConfirmDialog from "../../../components/ConfirmDialog";
import OverlayLoading from "../../../components/OverlayLoading";
import fornecedoresService from "../../../services/fornecedoresService";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import DatePicker from "../../../components/DatePicker";
import Button from "../../../components/ButtonComponent";
import Select from "../../../components/SelectComponent";
import exportData from "../../../services/exportData";

import cadastroFornecedorService from "../../../services/cadastroFornecedorService";
import { useHistory } from "react-router-dom";

import "./styles.css";
import CallbackMessage from "../../../components/CallbackMessage";
import useCompany from "../../../hooks/useCompany";
import { useIsMount } from "../../../hooks/useIsMount";

function FornecedoresLista() {
  const history = useHistory();
  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("fornecedores");
  const isMount = useIsMount();

  const [loading, setLoading] = useState(false);

  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [listRequest, setListRequest] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [paramsURL, setParamsURL] = useState(null);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [exportToPDF, setExportToPDF] = useState({
    url: "exportar-fornecedor-pdf",
    fileName: "fornecedores",
    fileType: "pdf",
    params: null,
  });
  const [exportToXLS, setExportToXLS] = useState({
    url: "exportar-fornecedor-excel",
    fileName: "fornecedores",
    fileType: "xlsx",
    params: null,
  });
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_fornecedor" },
      { columnName: "CNPJ/CPF", dataRef: "cnpj_cpf" },
      { columnName: "Razão Social/Nome", dataRef: "nome_razao_social" },
    ],
    options: {
      edit: true,
      delete: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      filter: false,
      export: false,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-excel",
        },
        {
          icon: "export",
          label: "Exportar PDF",
          action: "exportar-pdf",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    id_empresa: null,
  });

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await fornecedoresService.getFornecedores(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      dateFrom,
      dateTo,
      defaultConfigTable.id_empresa
    );
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.data.hasOwnProperty("data")) {
      setListRequest(result.data);
    } else {
      setListRequest(result);
    }
    if (result) {
      setLoading(false);
    }
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    dateFrom,
    dateTo,
    defaultConfigTable.id_empresa,
  ]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onEdit = (id) => {
    history.push(`/edita-fornecedor/${id}`);
  };

  let handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  let handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  async function onDelete() {
    setLoading(true);
    const response = await cadastroFornecedorService.removeFornecedor(deleteId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200 || response.status === 201) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        response?.data?.msg
          ? response.data.msg
          : "Fornecedor excluído com sucesso!"
      );
      setCallbackDuration(2000);
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response?.data?.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
    setLoading(false);
    handleRemoveCancel();
  }

  let onMoreAction = (action) => {};

  const handleSelectStatus = async (id) => {
    console.log(id);
    setEmpresaSelecionada(id);
  };

  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      return setDefaultConfigTable({
        ...defaultConfigTable,
        currentPage: 1,
        id_empresa: empresaStorage,
      });
    }
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_empresa: empresaSelecionada,
    });
    setEmpresaPagina("fornecedores", empresaSelecionada);
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
    });
    setEmpresaPagina("fornecedores", null);
  };

  function handleClose() {
    setCallbackShown(false);
  }

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                      ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={handleSelectStatus}
            />
          </Grid>
          <Grid className="marginLeftAuto">
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  const onTableMoreAction = useCallback(
    async ({ action }) => {
      console.log(action);
      // return;
      let aux = {};
      if (action === "exportar-excel") {
        aux = {
          url: exportToXLS.url,
          fileName: exportToXLS.fileName,
          fileType: exportToXLS.fileType,
          params: {
            ...exportToXLS.params,
            order_by: defaultConfigTable.orderBy,
            order_by_type: defaultConfigTable.orderByType,
            id_empresa: defaultConfigTable.id_empresa,
          },
        };
      } else if (action === "exportar-pdf") {
        aux = {
          url: exportToPDF.url,
          fileName: exportToPDF.fileName,
          fileType: exportToPDF.fileType,
          params: {
            ...exportToPDF.params,
            order_by: defaultConfigTable.orderBy,
            order_by_type: defaultConfigTable.orderByType,
            id_empresa: defaultConfigTable.id_empresa,
          },
        };
      }
      const response = await exportData(aux);
    },
    [
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.id_empresa,
    ]
  );

  useEffect(() => {
    setExportToPDF({ ...exportToPDF, params: paramsURL });
    setExportToXLS({ ...exportToXLS, params: paramsURL });
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("fornecedores");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id_fornecedor"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onEdit}
        cbDelete={handleRemoveDialog}
        cbMoreAction={onMoreAction}
        filterComponent={filterComponent()}
        cbTableMoreAction={onTableMoreAction}
      />
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default FornecedoresLista;
