import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Input from "../../../../../components/Input";
import buscaCepService from "../../../../../services/buscaCepService";
import DatePicker from "../../../../../components/DatePicker";
import SelectComponent from "../../../../../components/SelectComponent";
import styles from "./styles.module.scss";
import selectService from "../../../../../services/selectServices";
import viacepServices from "../../../../../services/viacepServices";
import contasBancariasService from "../../../../../services/contasBancariasService";
import InputFile from "../../../../../components/InputMultiFiles";
import ButtonComponent from "../../../../../components/ButtonComponent";
import { ModalListaDocumento } from "../../../../../components/ModalListaDocumento";

export function Documentos({
  locationState,
  dados,
  setDados,
  setLoading,
  edit = false,
}) {
  const { getCep } = buscaCepService;
  const { selecionaEstado } = viacepServices;
  const { getBancos } = contasBancariasService;
  const {
    selecionaTipoIdentidade,
    selecionaTipoCnh,
    selecionaTipoContaBancariaDp,
    selecionaOrgaoEmissorDp,
  } = selectService;
  console.log({ dados });
  const [listEstado, setListEstado] = useState([]);
  const [listTipoIdentidade, setListTipoIdentidade] = useState([]);
  const [listCategoriaCnh, setListCategoriaCnh] = useState([]);
  const [listTipoContaBancaria, setListTipoContaBancaria] = useState([]);
  const [listBancos, setListBancos] = useState([]);
  const [listEmissorDocumento, setListEmissorDocumento] = useState([]);
  const [showModalListaUploads, setShowModalListaUploads] = useState(false);

  const handleModalListaUploadsCancel = () => {
    setShowModalListaUploads(false);
  };

  function handleChangeDados(field, value) {
    console.log(value);
    console.log(value.length);
    if (field === "cep" && value.length === 8) {
      handleChangeCep(value);
    }
    let novosDados = { ...dados };
    novosDados[field] = value;
    console.log(novosDados);
    setDados(novosDados);
  }

  async function handleChangeCep(cep) {
    try {
      setLoading(true);
      const response = await getCep(cep);
      setLoading(false);
      if (response.status === 401) {
        return;
      }
      const data = response.data;
      console.log(data);
      if (!data.hasOwnProperty("erro")) {
        let novosDados = { ...dados };
        novosDados = {
          ...novosDados,
          rua: data?.logradouro,
          complemento: data?.complemento,
          bairro: data?.bairro,
          cidade: data?.localidade,
          estado: data?.uf,
        };
        setDados(novosDados);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const loadTipoIdentidade = useCallback(async () => {
    await selecionaTipoIdentidade().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListTipoIdentidade(response.data);
    });
  }, []);

  const loadEstado = useCallback(async () => {
    await selecionaEstado().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListEstado(response.data);
    });
  }, []);

  const loadCategoriaCnh = useCallback(async () => {
    await selecionaTipoCnh().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListCategoriaCnh(response.data);
    });
  }, []);

  const loadOrgaoEmissor = useCallback(async () => {
    await selecionaOrgaoEmissorDp().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        if (
          typeof response.data === "object" &&
          Object.keys(response.data)?.length > 0
        ) {
          const arrayFormatted = Object.keys(response.data).map((key) => ({
            id: Number(key),
            nome: response.data[key],
          }));
          setListEmissorDocumento(arrayFormatted);
        } else {
          setListEmissorDocumento([]);
        }
      }
    });
  }, []);

  const loadTipoContaBancaria = useCallback(async () => {
    await selecionaTipoContaBancariaDp().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        if (
          typeof response.data === "object" &&
          Object.keys(response.data)?.length > 0
        ) {
          const arrayFormatted = Object.keys(response.data).map((key) => ({
            id: Number(key),
            nome: response.data[key],
          }));
          setListTipoContaBancaria(arrayFormatted);
        } else {
          setListTipoContaBancaria([]);
        }
      }
    });
  }, []);

  const loadBancos = useCallback(async () => {
    await getBancos().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListBancos(response.data);
    });
  }, []);

  useEffect(() => {
    loadTipoIdentidade();
    loadEstado();
    loadCategoriaCnh();
    loadTipoContaBancaria();
    loadBancos();
    loadOrgaoEmissor();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Input
          label="CPF"
          name="cpf"
          tipo="cpf"
          required
          value={dados?.cpf}
          handleInputChange={async (e) => {
            handleChangeDados("cpf", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="NIS/PIS/PASEP ou NIT"
          name="nisNit"
          required
          value={dados?.nisNit}
          handleInputChange={async (e) => {
            handleChangeDados("nisNit", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DatePicker
          label="Emissão do NIS/PIS/PASEP ou NIT"
          name="emissaoNisNit"
          initDate={dados?.emissaoNisNit ? dados?.emissaoNisNit : ""}
          handleChange={(date) => {
            handleChangeDados("emissaoNisNit", date);
          }}
          value={dados?.emissaoNisNit}
          required
          readOnly={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Tipo Identidade"
          required
          title="Selecione uma opção"
          list={listTipoIdentidade}
          initialValue={
            listTipoIdentidade?.find(
              (item) => item.id === dados?.tipoIdentidade
            )
              ? listTipoIdentidade?.find(
                  (item) => item.id === dados?.tipoIdentidade
                ).nome
              : ""
          }
          callback={(id) => handleChangeDados("tipoIdentidade", id)}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="Número do Documento (RG, RIC OU RNE)"
          name="numeroDoc"
          required
          value={dados?.numeroDoc}
          handleInputChange={async (e) => {
            handleChangeDados("numeroDoc", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Órgão emissor do documento"
          required
          title="Selecione uma opção"
          list={listEmissorDocumento}
          initialValue={
            listEmissorDocumento?.find(
              (item) => Number(item.id) === Number(dados?.emissorDoc)
            )
              ? listEmissorDocumento?.find(
                  (item) => Number(item.id) === Number(dados?.emissorDoc)
                ).nome
              : ""
          }
          callback={(id) => {
            handleChangeDados("emissorDoc", id);
          }}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DatePicker
          label="Emissão do documento*"
          name="emissaoDoc"
          initDate={dados?.emissaoDoc ? dados?.emissaoDoc : ""}
          handleChange={(date) => {
            handleChangeDados("emissaoDoc", date);
          }}
          value={dados?.emissaoDoc}
          readOnly={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="UF do documento"
          required
          title="Selecione uma opção"
          list={listEstado}
          initialValue={
            listEstado?.find((item) => item.id === dados?.ufDoc)
              ? listEstado?.find((item) => item.id === dados?.ufDoc).nome
              : ""
          }
          callback={(id) => {
            handleChangeDados("ufDoc", id);
          }}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="CTPS"
          name="ctps"
          required
          value={dados?.ctps}
          handleInputChange={async (e) => {
            handleChangeDados("ctps", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="Série de CTPS"
          name="serieCtps"
          required
          value={dados?.serieCtps}
          handleInputChange={async (e) => {
            handleChangeDados("serieCtps", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="UF da CTPS"
          required
          title="Selecione uma opção"
          list={listEstado}
          initialValue={
            listEstado?.find((item) => item.id === dados?.ufCtps)
              ? listEstado?.find((item) => item.id === dados?.ufCtps).nome
              : ""
          }
          callback={(id) => {
            handleChangeDados("ufCtps", id);
          }}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DatePicker
          label="Emissão da CTPS *"
          name="emissaoCtps"
          required
          initDate={dados?.emissaoCtps ? dados?.emissaoCtps : ""}
          handleChange={(date) => {
            handleChangeDados("emissaoCtps", date);
          }}
          value={dados?.emissaoCtps}
          readOnly={locationState?.details}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          label="Título de Eleitor"
          name="tituloEleitor"
          value={dados?.tituloEleitor}
          handleInputChange={async (e) => {
            handleChangeDados("tituloEleitor", e.target.value);
          }}
          disabled={locationState?.details}
          maxLength={12}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="Zona Eleitoral"
          name="zonaEleitoral"
          value={dados?.zonaEleitoral}
          handleInputChange={async (e) => {
            handleChangeDados("zonaEleitoral", e.target.value);
          }}
          disabled={locationState?.details}
          maxLength={3}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="Seção Eleitoral"
          name="secaoEleitoral"
          value={dados?.secaoEleitoral}
          handleInputChange={async (e) => {
            handleChangeDados("secaoEleitoral", e.target.value);
          }}
          disabled={locationState?.details}
          maxLength={4}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="Carteira de Habilitação"
          name="cnh"
          value={dados?.cnh}
          handleInputChange={async (e) => {
            handleChangeDados("cnh", e.target.value);
          }}
          disabled={locationState?.details}
          maxLength={15}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DatePicker
          label="Data de Emissão da CNH"
          name="emissaoCnh"
          required
          initDate={dados?.emissaoCnh ? dados?.emissaoCnh : ""}
          handleChange={(date) => {
            handleChangeDados("emissaoCnh", date);
          }}
          value={dados?.emissaoCnh}
          readOnly={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DatePicker
          label="Validade da CNH"
          name="validadeCnh"
          required
          initDate={dados?.validadeCnh ? dados?.validadeCnh : ""}
          handleChange={(date) => {
            handleChangeDados("validadeCnh", date);
          }}
          value={dados?.validadeCnh}
          readOnly={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Categoria da CNH"
          title="Selecione uma opção"
          list={listCategoriaCnh}
          initialValue={
            listCategoriaCnh?.find((item) => item.id === dados?.categoriaCnh)
              ? listCategoriaCnh?.find(
                  (item) => item.id === dados?.categoriaCnh
                ).nome
              : ""
          }
          callback={(id) => {
            handleChangeDados("categoriaCnh", id);
          }}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="UF da CNH"
          title="Selecione uma opção"
          list={listEstado}
          initialValue={
            listEstado?.find((item) => item.id === dados?.ufCnh)
              ? listEstado?.find((item) => item.id === dados?.ufCnh).nome
              : ""
          }
          callback={(id) => {
            handleChangeDados("ufCnh", id);
          }}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DatePicker
          label="Data da primeira emissão da CNH"
          name="emissaoPrimeiraCnh"
          initDate={dados?.emissaoPrimeiraCnh ? dados?.emissaoPrimeiraCnh : ""}
          handleChange={(date) => {
            handleChangeDados("emissaoPrimeiraCnh", date);
          }}
          value={dados?.emissaoPrimeiraCnh}
          readOnly={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Tipo da conta"
          title="Selecione uma opção"
          list={listTipoContaBancaria}
          initialValue={
            listTipoContaBancaria?.find(
              (item) => Number(item.id) === Number(dados?.tipoConta)
            )
              ? listTipoContaBancaria?.find(
                  (item) => Number(item.id) === Number(dados?.tipoConta)
                ).nome
              : ""
          }
          callback={(id) => {
            handleChangeDados("tipoConta", id);
          }}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Banco"
          title="Selecione uma opção"
          list={listBancos}
          initialValue={
            listBancos?.find((item) => item.id === dados?.banco)
              ? listBancos?.find((item) => item.id === dados?.banco).nome
              : ""
          }
          callback={(id) => {
            handleChangeDados("banco", id);
          }}
          isDisabled={locationState?.details}
        />
      </Grid>

      {dados?.banco === 211 && (
        <Grid item xs={12} md={6}>
          <Input
            label="Nome do banco"
            name="bancoOutros"
            value={dados?.bancoOutros}
            handleInputChange={async (e) => {
              handleChangeDados("bancoOutros", e.target.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <Input
          label="Agência"
          name="agencia"
          value={dados?.agencia}
          handleInputChange={async (e) => {
            handleChangeDados("agencia", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item container xs={12} md={6} spacing={2}>
        <Grid item xs={8}>
          <Input
            label="Conta"
            name="conta"
            value={dados?.conta}
            handleInputChange={async (e) => {
              handleChangeDados("conta", e.target.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={1} className={styles.separadoDigito}>
          -
        </Grid>
        <Grid item xs={3} className="margin-top-auto">
          <Input
            label=""
            name="digitoConta"
            value={dados?.digitoConta}
            handleInputChange={async (e) => {
              handleChangeDados("digitoConta", e.target.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InputFile
          fileFormat=".pdf,.doc,.docx,.xls,.xlsx,.jpeg,.jpg,.png"
          hasFile={
            dados?.documentos?.length
              ? `${dados?.documentos?.length} ${
                  dados?.documentos?.length > 1
                    ? "arquivos adicionados"
                    : "arquivo adicionado"
                }`
              : false
          }
          title="Anexar documentos*"
          onChange={(files) => handleChangeDados("documentos", files)}
        />
        {edit && (
          <div className={styles.containerButtonArquivos}>
            <ButtonComponent
              icone="paper-clip"
              className="btn-info"
              text="Arquivos Anexados"
              onClick={() => setShowModalListaUploads(true)}
            />
          </div>
        )}
      </Grid>

      <Grid item xs={12}>
        <span className="required-text">* Campos obrigatórios</span>
      </Grid>
      {edit && (
        <ModalListaDocumento
          id={edit}
          open={showModalListaUploads}
          description=""
          title="DOCUMENTOS ANEXADOS"
          onClose={handleModalListaUploadsCancel}
          tipo="pre_admissao"
        />
      )}
    </Grid>
  );
}
