import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InputComponent from "../../components/Input";
import SwitchComponent from "../../components/Switch";
import DatePicker from "../../components/DatePicker";
import Parcelamentos from "../../components/Parcelamentos";
import SelectComponent from "../../components/SelectComponent";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import empresasService from "../../services/empresasService";
import centroCustosService from "../../services/centroCustosService";
import recebimentosServices from "../../services/recebimentosService";
import clientesServices from "../../services/clientesService";
import FileInput from "../../components/InputFile";
import ButtonComponent from "../../components/ButtonComponent";
import OverlayLoading from "../../components/OverlayLoading";
import CallbackMessage from "../../components/CallbackMessage";

import "./styles.scss";
import contasBancariasService from "../../services/contasBancariasService";
import fornecedoresService from "../../services/fornecedoresService";

import { currencyToNumber } from "../../utils/functions";
import { onlyUnsignedNumbers } from "../../utils/strings";

function ModalCadastroRecebimento({ open, onClose, cbSuccess, notaData }) {
  const [showMessage, setShowMessage] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  const [file, setFile] = useState(null);
  const [vencimento, setVencimento] = useState("");
  const [emissao, setEmissao] = useState("");
  const [valor, setValor] = useState("");
  const [codigoBarras, setCodigoBarras] = useState(null);
  const [centroId, setCentroId] = useState("");
  const [empresaId, setEmpresaId] = useState("");
  const [listEmpresas, setListEmpresas] = useState([]);
  const [listCentroCusto, setListCentroCusto] = useState([]);
  const [clienteId, setClienteId] = useState("");
  const [categoriaId, setCategoriaId] = useState("");
  const [descricao, setDescricao] = useState("");
  const [listClientes, setListClientes] = useState([]);
  const [listCategorias, setListCategorias] = useState([]);
  const [parcelamentos, setParcelamentos] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showParcela, setShowParcela] = useState(false);
  const [showContaPaga, setShowContaPaga] = useState(false);

  const [dataContaPaga, setDataContaPaga] = useState(null);
  const [valorContaPaga, setValorContaPaga] = useState(null);
  const [contaContaPaga, setContaContaPaga] = useState(null);
  const [listContas, setListContas] = useState([]);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(1500);

  const [nota, setNota] = useState({});

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const { selecionaConta } = contasBancariasService;
  const { selecionaFornecedores } = fornecedoresService;
  const { selecionaCliente } = clientesServices;

  let loadEmpresas = async () => {
    await empresasService.selectEmpresa().then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) setListEmpresas(response.data);
    });
  };

  async function loadContasBancarias(id) {
    setLoading(true);
    const response = await selecionaConta(id);
    console.log(response);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) setListContas(response.data);
    setLoading(false);
  }

  async function loadCentroCustos(id) {
    setLoading(true);
    let response = await centroCustosService.selecionaCentroCusto(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) setListCentroCusto(response.data);
    setLoading(false);
  }

  // async function loadFornecedores(id) {
  //   let response = await selecionaFornecedores(id);
  //   if (response.status === 401) {
  //     setOpenLoginExpirado(true);
  //     setLoading(false);
  //     return;
  //   }
  //   if (response.status === 200) setListFornecedor(response.data);
  // }

  async function loadClientes(id) {
    let response = await selecionaCliente(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) setListClientes(response.data);
  }

  async function loadCategorias(id) {
    let response = await recebimentosServices.selecionaCategoriaRecebimento(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) setListCategorias(response.data);
  }

  function validateFields() {
    let list = [];
    if (empresaId === "") list.push('O campo "Empresa" deve ser selecionada.');
    if (vencimento === "")
      list.push('O campo "Vencimento" deve ser preenchido.');
    if (valor === "") list.push('O campo "Valor" deve ser preenchido.');
    if (clienteId === "") list.push('O campo "Cliente" deve ser selecionado.');
    if (categoriaId === "")
      list.push('O campo "Categoria" deve ser selecionado.');
    if (descricao === "") list.push('O campo "Descrição" deve ser preenchido.');
    if (showContaPaga && !dataContaPaga)
      list.push('O campo "Data de Recebimento" deve ser preenchido.');
    if (showContaPaga && !valorContaPaga)
      list.push('O campo "Valor" deve ser preenchido.');
    if (showContaPaga && !contaContaPaga)
      list.push('O campo "Conta" deve ser preenchido.');

    if (list.length > 0) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(4000);
      setCallbackErrorList(list);
    }

    return list.length === 0;
  }

  function handleClose(event, reason) {
    setCallbackShown(false);
    if (callbackErrorList.length === 0) {
      handleCloseModal();
    }
    setCallbackErrorList([]);
  }

  async function handleSubmit(emiteFatura = 0) {
    // return;
    if (!validateFields()) return;
    let p = parcelamentos.map((item, index) => {
      item.numero = parcelamentos.length;
      item.valor = Number(item.valor);
      return item;
    });
    window.scrollTo(0, 0);
    setLoading(true);
    await recebimentosServices
      .cadastraRecebimento(
        empresaId,
        file,
        descricao,
        categoriaId,
        vencimento,
        Number(valor),
        clienteId,
        centroId,
        p,
        emiteFatura,
        notaData?.id_nota_fiscal,
        showContaPaga ? dataContaPaga : undefined,
        showContaPaga ? contaContaPaga : undefined,
        showContaPaga ? valorContaPaga : undefined,
        showContaPaga ? 1 : 0,
        undefined,
        emissao,
        codigoBarras
      )
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("success");
          setCallbackMessage("Recebimento salvo com sucesso!");
          setCallbackDuration(3000);
          return;
        }
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        } else {
          if (response.data.error)
            setCallbackErrorList(Object.values(response.data.error));
          else setCallbackMessage(["Ocorreu um erro"]);
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
        }
      });
  }

  useEffect(() => {
    setLoading(true);
    loadEmpresas().then((_) => {
      setLoading(false);
    });

    setEmpresaId(notaData.id_empresa);
    loadCentroCustos(notaData.id_empresa);
    loadCategorias(notaData.id_empresa);
    loadContasBancarias(notaData.id_empresa);

    console.log(notaData);
    setNota(notaData);
    setValor(currencyToNumber(String(notaData.valor_total_nota)));

    async function getClientes() {
      await loadClientes(notaData.id_empresa);
      setClienteId(notaData.id_cliente);
    }

    getClientes();
  }, [notaData]);

  const handleCloseModal = async () => {
    clearFields();
    onClose();
  };

  const clearFields = () => {
    setEmpresaId(null);
    setNota({});
    setValor("");
    setVencimento("");
    setEmissao("");
    setCentroId("");
    setClienteId("");
    setCategoriaId("");
    setDescricao("");
    setShowContaPaga(false);
    setDataContaPaga(null);
    setValorContaPaga(null);
    setContaContaPaga(null);
    setShowParcela(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      className="modal-cadastra-recebimento auto-height"
      onClose={handleCloseModal}
      dialog
    >
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justifyContent="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>CADASTRAR RECEBIMENTO</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <div className="content">
        <form className="session-container-form">
          {loading && <OverlayLoading />}
          <div className="p-horizontal">
            <Grid item container direction="column" spacing={2}>
              <Grid item>
                <SelectComponent
                  styleType="form"
                  label="Empresa"
                  title="Selecione uma empresa"
                  list={listEmpresas}
                  initialValue={
                    listEmpresas.find((item) => item.id === empresaId)
                      ? listEmpresas.find((item) => item.id === empresaId).nome
                      : ""
                  }
                  required
                  callback={async (id) => {
                    setEmpresaId(id);
                    setLoading(true);
                    await loadCentroCustos(id);
                    await loadCategorias(id);
                    // await loadFornecedores(id);
                    await loadClientes(id);
                    await loadContasBancarias(id);
                    setLoading(false);
                  }}
                />
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs sm md={6}>
                  <DatePicker
                    label="Data de Emissão"
                    value={emissao}
                    initDate={""}
                    handleChange={(date) => {
                      setEmissao(date);
                    }}
                  ></DatePicker>
                </Grid>
                <Grid item xs sm md={6}>
                  <DatePicker
                    label="Data de Vencimento"
                    required
                    value={vencimento}
                    handleChange={(date) => {
                      setVencimento(date);
                    }}
                  ></DatePicker>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs sm md={6}>
                  <InputComponent
                    tipo="dinheiro"
                    required
                    label="Valor"
                    value={valor}
                    handleInputChange={(event) => setValor(event.value)}
                  />
                </Grid>
                <Grid item xs sm md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Centro de Custo"
                    title="Selecione uma opção"
                    list={listCentroCusto}
                    callback={(id) => setCentroId(id)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputComponent
                  label="Código de barras"
                  value={codigoBarras}
                  tipo={
                    codigoBarras?.length > 47
                      ? "boleto-concessionaria"
                      : "boleto"
                  }
                  handleInputChange={(event) => {
                    setCodigoBarras(onlyUnsignedNumbers(event.target.value));
                  }}
                />
              </Grid>
              <Grid item>
                <SelectComponent
                  styleType="form"
                  label="Cliente"
                  required
                  title="Selecione um cliente"
                  initialValue={
                    listClientes.find((item) => item.id === clienteId)
                      ? listClientes.find((item) => item.id === clienteId).nome
                      : ""
                  }
                  list={listClientes}
                  callback={(id) => setClienteId(id)}
                />
              </Grid>
              <Grid item>
                <SelectComponent
                  styleType="form"
                  label="Categoria"
                  required
                  title="Selecione uma categoria"
                  list={listCategorias}
                  callback={(id) => setCategoriaId(id)}
                />
              </Grid>
              <Grid item>
                <span className="label">Descrição *</span>
                <textarea
                  className="large-input"
                  value={descricao}
                  onChange={(event) => setDescricao(event.target.value)}
                />
              </Grid>

              <Grid item container alignItems="center" style={{ height: 60 }}>
                <Grid item>
                  <SwitchComponent
                    checked={showParcela}
                    onClick={() => setShowParcela(!showParcela)}
                  />
                </Grid>

                <Grid item>
                  <span className="title">Adicionar parcelas</span>
                </Grid>
              </Grid>
              {showParcela && (
                <Grid item>
                  <Parcelamentos
                    qtdTotalParcelas={2}
                    valorTotal={valor}
                    dividirEntreParcelas={true}
                    handleChange={(list) => {
                      setParcelamentos(list);
                    }}
                  />
                </Grid>
              )}
              <Grid item container alignItems="center" style={{ height: 60 }}>
                <Grid item>
                  <SwitchComponent
                    checked={showContaPaga}
                    onClick={() => setShowContaPaga(!showContaPaga)}
                  />
                </Grid>

                <Grid item>
                  <span className="title">A conta já foi paga?</span>
                </Grid>
              </Grid>
              {showContaPaga && (
                <Grid item>
                  <div className="parcelContainer">
                    <div className="parcelContainerInputsContainer">
                      <div>
                        <DatePicker
                          label="Data de Recebimento *"
                          required
                          initDate={dataContaPaga}
                          handleChange={(date) => {
                            setDataContaPaga(date);
                          }}
                        ></DatePicker>
                      </div>
                      <div>
                        <InputComponent
                          label="Valor"
                          required
                          tipo="dinheiro"
                          value={valorContaPaga ? valorContaPaga : 0}
                          handleInputChange={(e) => {
                            console.log(e);
                            console.log(e.value);
                            setValorContaPaga(e.value);
                          }}
                        />
                      </div>
                      <div>
                        <SelectComponent
                          styleType="form"
                          label="Conta"
                          title=""
                          list={listContas}
                          required
                          callback={async (id) => {
                            setContaContaPaga(id);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              )}
              <Grid item>
                <span className="required-text">* Campos obrigatórios</span>
              </Grid>
            </Grid>
            <div style={{ height: 40 }} />
          </div>
          {callbackShown && (
            <CallbackMessage
              open={callbackShown}
              type={callbackType}
              handleClose={handleClose}
              message={callbackMessage}
              duration={callbackDuration}
              errorList={callbackErrorList}
            />
          )}
        </form>
        <Grid item container justifyContent="flex-end" spacing={4}>
          {/* <Grid item>
          <ButtonComponent
            className="btn-primary"
            text="Salvar e Gerar Fatura"
            onClick={() => handleSubmit(1)}
          />
        </Grid> */}
          <Grid item>
            <ButtonComponent
              text="Salvar"
              className="btn-primary"
              onClick={() => handleSubmit(0)}
            />
          </Grid>
        </Grid>
        <ModalLoginExpirado open={openLoginExpirado} />
      </div>

      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 1000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalCadastroRecebimento;
