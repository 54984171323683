import React, { useState } from "react";
import { AccordionCategory } from "../category";
import { AccordionHeader } from "./components/AccordionHeader";
import { Accordion, AccordionDetails } from "@material-ui/core";

export const GrupoComponent = ({ item, index, fetchCategory }) => {
  console.log({ item });
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      key={item.id_grupo}
      expanded={expanded === item.id_grupo}
      onChange={handleChange(item.id_grupo)}
      style={{
        background: "#f8f8fb",
        boxShadow: "none",
      }}
    >
      <AccordionHeader
        expanded={expanded}
        expandedId={item.id_grupo}
        name={item.nome}
      />

      <AccordionDetails style={{ background: "white", display: 'block' }}>
        {item.categorias.map((newItem, newIndex) => {
          console.log({ newItem });
          const { subcategorias } = newItem;

          const newCategories = subcategorias.map((itemCategoria) => {
            return {
              ...itemCategoria,
              id: itemCategoria.id_categoria,
              switch_value: itemCategoria.status,
            };
          });
          console.log({ item });
          return (
            <>
              <AccordionCategory
                key={newIndex}
                groupName={newItem.nome}
                categories={newCategories}
                categoryId={newItem.id_categoria}
                companyId={item.id_empresa}
                groupStatus={newItem.status}
                updateCategories={fetchCategory}
              />
            </>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
