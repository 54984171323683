import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { useParams, useHistory, useLocation } from "react-router-dom";
import SelectComponent from "../../../components/SelectComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import Input from "../../../components/Input";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import verifyFields from "../../../utils/validateFields";
import { Grid } from "@material-ui/core";
import servicosService from "../../../services/servicosService";
import selectServices from "../../../services/selectServices";
import OverlayLoading from "../../../components/OverlayLoading";
import Switch from "../../../components/Switch";
import useCompany from "../../../hooks/useCompany";

function ServicoCadastro() {
  const history = useHistory();
  const { id_servico } = useParams();
  const { state } = useLocation();
  const {
    companyList: listEmpresas,
    selectedCompany: company,
    setSelectedCompany: setCompany,
    getEmpresaPagina,
  } = useCompany("servicos");

  const [nome, setNome] = useState();
  const [valor, setValor] = useState();
  const [categoriaPadrao, setCategoriaPadrao] = useState(null);
  const [tipoServico, setTipoServico] = useState();
  const [codigoServico, setCodigoServico] = useState();
  const [codigoMunicipal, setCodigoMunicipal] = useState();
  const [codigoMunicipalDescricao, setCodigoMunicipalDescricao] = useState();
  const [iss, setIss] = useState();
  const [tipoTributacao, setTipoTributacao] = useState();
  const [status, setStatus] = useState();
  const [listServico, setListServico] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [listCategoriaPadrao, setListCategoriaPadrao] = useState([]);
  const [listTributacao, setListTributacao] = useState([]);
  const [listCodigosServico, setListCodigosServico] = useState([]);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [destaque, setDestaque] = useState(false);
  const [reterIrrf, setReterIrrf] = useState(false);
  const [reterInss, setReterInss] = useState(false);
  const [reterCofins, setReterCofins] = useState(false);
  const [reterPis, setReterPis] = useState(false);
  const [reterCsll, setReterCsll] = useState(false);
  const [reterIss, setReterIss] = useState(false);
  const [ISSRetido, setISSRetido] = useState();

  const [tituloAmigavel, setTituloAmigavel] = useState("");

  const issOptions = [
    {
      id: 1,
      nome: "SIM",
    },
    {
      id: 0,
      nome: "NÃO",
    },
  ];

  const loadServico = useCallback(
    async (statusList) => {
      if (id_servico) {
        const response = await servicosService.consultaServico(id_servico);

        if (response.status === 401) {
          setLoading(false);
          setOpenLoginExpirado(true);
          return;
        } else if (response.status === 200) {
          const { data } = response;
          console.log(data);
          setNome(data.nome);
          setValor(data.valor);
          setTipoServico(data.id_tipo_servico);
          setTituloAmigavel(data.titulo_amigavel);
          setDestaque(data.destaque === 1 ? true : false);
          setReterIrrf(data.reter_irrf === 1 ? true : false);
          setReterInss(data.reter_inss === 1 ? true : false);
          setReterCofins(data.reter_cofins === 1 ? true : false);
          setReterPis(data.reter_pis === 1 ? true : false);
          setReterCsll(data.reter_csll === 1 ? true : false);
          setReterIss(data.reter_iss === 1 ? true : false);
          setCompany(data.id_empresa);
          loadListCodigos(
            data.id_empresa,
            data.cod_servico,
            data.cod_servico_municipio
          );
          setISSRetido(data.iss_retido);
          setStatus(
            statusList.find((item) => item.nome === data.status)
              ? statusList.find((item) => item.nome === data.status).id
              : ""
          );
          setCategoriaPadrao(data?.id_categoria_padrao);
          data.cod_servico !== null && setCodigoServico(data.cod_servico);
          data.cod_servico_municipio !== null &&
            setCodigoMunicipal(data.cod_servico_municipio);
          data.cod_tipo_tributacao_servico !== null &&
            setTipoTributacao(data.cod_tipo_tributacao_servico);
          data.iss > 0 && setIss(`${data.iss}%`.replace(".", ","));
        }
      }

      if (state) {
        const { id: idCopy, isCopy } = state;

        const IntId = parseInt(idCopy, 10);

        if (IntId && isCopy) {
          const response = await servicosService.consultaServico(IntId);

          if (response.status === 401) {
            setLoading(false);
            setOpenLoginExpirado(true);
            return;
          } else if (response.status === 200) {
            const { data } = response;

            setNome(data.nome);
            setValor(data.valor);
            setTipoServico(data.id_tipo_servico);
            setTituloAmigavel(data.titulo_amigavel);
            setDestaque(data.destaque === 1 ? true : false);
            setReterIrrf(data.reter_irrf === 1 ? true : false);
            setReterInss(data.reter_inss === 1 ? true : false);
            setReterCofins(data.reter_cofins === 1 ? true : false);
            setReterPis(data.reter_pis === 1 ? true : false);
            setReterCsll(data.reter_csll === 1 ? true : false);
            setReterIss(data.reter_iss === 1 ? true : false);
            setCompany(data.id_empresa);
            loadListCodigos(
              data.id_empresa,
              data.cod_servico,
              data.cod_servico_municipio
            );
            setStatus(
              statusList.find((item) => item.nome === data.status)
                ? statusList.find((item) => item.nome === data.status).id
                : ""
            );
            setCategoriaPadrao(data?.id_categoria_padrao);
            data.cod_servico !== null && setCodigoServico(data.cod_servico);
            data.cod_servico_municipio !== null &&
              setCodigoMunicipal(data.cod_servico_municipio);
            data.cod_tipo_tributacao_servico !== null &&
              setTipoTributacao(data.cod_tipo_tributacao_servico);
            data.iss > 0 && setIss(`${data.iss}%`.replace(".", ","));
            data.iss_retido && setISSRetido(data.iss_retido);
          }
        }
      }
    },
    [id_servico]
  );

  const loadCategoriaPadrao = useCallback(async () => {
    const response = await selectServices.selecionaCategoriaPadrao();
    if (response.status === 200) {
      const formattedData = response?.data?.map((item) => ({
        ...item,
        id: item?.id_categoria_padrao,
      }));
      setListCategoriaPadrao(formattedData);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, []);

  const loadListTributacao = useCallback(async () => {
    const response = await servicosService.selecionaTipoTributacao();
    if (response.status === 401) {
      setLoading(false);
      setOpenLoginExpirado(true);
      return;
    } else if (response.status === 200) {
      setListTributacao(response.data);
    }
  }, [setListTributacao]);

  const loadListStatus = useCallback(async () => {
    const response = await selectServices.selecionaStatusPlano();
    if (response.status === 401) {
      setLoading(false);
      setOpenLoginExpirado(true);
      return;
    } else if (response.status === 200) {
      setListStatus(response.data);
      await loadServico(response.data);
    }
  }, [loadServico]);

  const loadListServico = useCallback(async () => {
    const response = await selectServices.selectTipoServico();
    if (response.status === 401) {
      setLoading(false);
      setOpenLoginExpirado(true);
      return;
    } else if (response.status === 200) setListServico(response.data);
  }, []);

  const loadListCodigos = useCallback(
    async (idEmpresa, codServico = false, codMunicipio = false) => {
      const response = await selectServices.selecionaCodigosServico(idEmpresa);
      if (response.status === 401) {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      } else if (response.status === 200) {
        const data = response?.data?.map((item) => ({
          ...item,
          nome: item.codigo_servico,
        }));
        setListCodigosServico(data);
        if (codServico) {
          handleChangeCodigoServicoSelect(null, codServico, codMunicipio, data);
        }
      }
    },
    []
  );

  function validateFields() {
    const fields = [
      {
        label: "empresa",
        value: company,
      },
      {
        label: "nomeservico",
        value: nome,
      },
      {
        label: "tituloamigavel",
        value: tituloAmigavel,
      },
      {
        label: "valorservico",
        value: valor,
      },
      {
        label: "categoriapadrao",
        value: categoriaPadrao,
      },
      {
        label: "tiposervico",
        value: tipoServico,
      },
      {
        label: "status",
        value: status,
      },
      {
        label: "selectissretido",
        value: ISSRetido,
      },
    ];
    let list = verifyFields(fields);
    if (list.length > 0) {
      setShowMessage(true);
    }
    setErrors(list);

    return list.length === 0;
  }

  function handleClose() {
    setShowMessage(false);
    if (errors.length === 0) {
      history.push("/servicos");
    }
  }

  async function handleSubmit() {
    if (!validateFields()) return;

    setLoading(true);

    let response;

    if (id_servico)
      response = await servicosService.atualizaServico(
        id_servico,
        nome,
        valor,
        tipoServico,
        status,
        company,
        tituloAmigavel,
        destaque ? 1 : 0,
        codigoServico,
        codigoMunicipal,
        iss !== undefined
          ? iss.substring(0, iss.length - 1).replace(",", ".")
          : "",
        tipoTributacao,
        categoriaPadrao,
        ISSRetido,
        reterIrrf ? 1 : 0,
        reterInss ? 1 : 0,
        reterCofins ? 1 : 0,
        reterPis ? 1 : 0,
        reterCsll ? 1 : 0,
        reterIss ? 1 : 0
      );
    else
      response = await servicosService.cadastraServico(
        nome,
        valor,
        tipoServico,
        status,
        company,
        tituloAmigavel,
        destaque ? 1 : 0,
        codigoServico,
        codigoMunicipal,
        iss !== undefined
          ? iss.substring(0, iss.length - 1).replace(",", ".")
          : "",
        tipoTributacao,
        categoriaPadrao,
        ISSRetido,
        reterIrrf ? 1 : 0,
        reterInss ? 1 : 0,
        reterCofins ? 1 : 0,
        reterPis ? 1 : 0,
        reterCsll ? 1 : 0,
        reterIss ? 1 : 0
      );

    if (response.status === 401) {
      setLoading(false);
      setOpenLoginExpirado(true);
      return;
    } else if (response.status === 200 || response.status === 201) {
      setErrors([]);
      if (id_servico) setSuccessMessage("Serviço atualizado com sucesso!");
      else setSuccessMessage("Serviço cadastrado com sucesso!");
      setShowMessage(true);
    } else {
      setShowMessage(true);
      if (response.data.error) setErrors(Object.values(response.data.error));
      else setErrors(["Ocorreu um erro"]);
    }
    setLoading(false);
  }

  function handleChangeEmpresa(id) {
    setCompany(id);
    loadListCodigos(id);
  }

  function handleChangeCodigoServicoSelect(
    id,
    codServico,
    codMunicipio,
    lista
  ) {
    let servico = {};
    const listaAtual = lista ? lista : listCodigosServico;
    if (id) {
      servico = listaAtual?.find((item) => item.id === id);
    } else if (codServico) {
      servico = listaAtual?.find((item) => item.codigo_servico === codServico);
    }
    if (codMunicipio) {
      setCodigoMunicipal(codMunicipio);
      setCodigoMunicipalDescricao(
        `${codMunicipio}${servico?.descricao ? " - " + servico.descricao : ""}`
      );
    } else {
      setCodigoMunicipal(servico.codigo_municipal);
      setCodigoMunicipalDescricao(
        `${servico?.codigo_municipal} - ${servico?.descricao}`
      );
    }
    setCodigoServico(
      servico?.codigo_servico ? servico?.codigo_servico : codServico
    );
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([
        loadListServico(),
        loadListStatus(),
        loadListTributacao(),
        loadCategoriaPadrao(),
      ]);
      setLoading(false);
    })();
  }, [loadListServico, loadListStatus, loadCategoriaPadrao]);

  useEffect(() => {
    if (!id_servico) {
      const empresaStorage = getEmpresaPagina("servicos");
      if (empresaStorage) {
        handleChangeEmpresa(empresaStorage);
      }
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <div className="session-container cadastro-servicos-container">
        <Grid container direction="column" spacing={2}>
          <Grid item container xs={12} sm={12} md spacing={2}>
            <Grid item xs={12} sm={12}>
              <SelectComponent
                styleType="form"
                label="Empresa"
                required
                initialValue={
                  company !== null
                    ? listEmpresas.find((item) => item.id == company)?.nome
                    : ""
                }
                title="Selecione uma empresa"
                list={listEmpresas}
                callback={(id) => handleChangeEmpresa(id)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                required
                handleInputChange={(event) => {
                  setNome(event.target.value);
                }}
                value={nome}
                label="Nome do serviço"
              />
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                <Input
                  required
                  handleInputChange={(event) => {
                    setTituloAmigavel(event.target.value);
                  }}
                  value={tituloAmigavel}
                  tipo="text"
                  label="Título amigável"
                  labelSuffix={{
                    icon: "info",
                    title:
                      "Esse título irá aparecer na tela de escolha de plano.",
                  }}
                />
              </Grid>
              <Grid xs={12} sm={12} md={4} className="servico-destaque">
                <div>Destaque ?</div>
                <div className="servico-switchDestaque">
                  <div className="servico-switchOption">Não</div>
                  <div>
                    <Switch
                      checked={destaque}
                      onClick={() => setDestaque(!destaque)}
                    />
                  </div>
                  <div>Sim</div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <SelectComponent
                required
                callback={(id) => {
                  setTipoServico(id);
                }}
                styleType="form"
                list={listServico}
                label="Tipo de serviço"
                initialValue={
                  listServico.find((item) => item.id === tipoServico)
                    ? listServico.find((item) => item.id === tipoServico).nome
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <SelectComponent
                required
                callback={(id) => {
                  setStatus(id);
                }}
                styleType="form"
                list={listStatus}
                label="Status"
                initialValue={
                  listStatus.find((item) => item.id === status)
                    ? listStatus.find((item) => item.id === status).nome
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Input
                required
                handleInputChange={(event) => {
                  setValor(event.value);
                }}
                value={valor}
                tipo="dinheiro"
                label="Valor do serviço"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <SelectComponent
                styleType="form"
                label="Categoria"
                title="Selecione uma opção"
                required
                initialValue={
                  categoriaPadrao !== undefined
                    ? listCategoriaPadrao.find(
                        (item) => item.id === categoriaPadrao
                      )?.nome
                    : ""
                }
                list={listCategoriaPadrao}
                callback={(id) => {
                  setCategoriaPadrao(id);
                }}
              />
            </Grid>
          </Grid>
          <Grid item container className="required-text">
            * Campos obrigatórios
          </Grid>
        </Grid>
      </div>
      <div className="session-container cadastro-servicos-container">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <div className="perfilTributario-title">
              Informações tributárias
            </div>
          </Grid>
          <Grid item container xs={12} sm={12} md spacing={2}></Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              {listCodigosServico?.length > 0 ? (
                <SelectComponent
                  styleType="form"
                  label="Código Serviço"
                  title="Selecione"
                  initialValue={
                    listCodigosServico.find(
                      (item) => item.codigo_servico == codigoServico
                    )
                      ? listCodigosServico.find(
                          (item) => item.codigo_servico == codigoServico
                        ).nome
                      : ""
                  }
                  list={listCodigosServico}
                  search={false}
                  callback={(id) => handleChangeCodigoServicoSelect(id)}
                />
              ) : (
                <Input
                  handleInputChange={(event) => {
                    let value = event.target.value.replace(/[a-zç]/gi, "");
                    setCodigoServico(value);
                  }}
                  value={codigoServico}
                  label="Código Serviço"
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Input
                handleInputChange={(event) => {
                  setCodigoMunicipal(event.target.value);
                  if (listCodigosServico?.length > 0) {
                    setCodigoMunicipalDescricao(event.target.value);
                  }
                }}
                value={
                  listCodigosServico?.length > 0
                    ? codigoMunicipalDescricao
                    : codigoMunicipal
                }
                label="Código Municipal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Input
                handleInputChange={(event) => {
                  setIss(event.target.value);
                }}
                value={iss}
                tipo="percentage"
                placeholder="%"
                label="Alíquota ISS (%)"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              className="container-select-fit-content"
            >
              <SelectComponent
                styleType="form"
                label="ISS Retido?"
                required
                title="Selecione"
                initialValue={
                  issOptions.find((item) => item.id == ISSRetido)
                    ? issOptions.find((item) => item.id == ISSRetido).nome
                    : ""
                }
                list={issOptions}
                search={false}
                callback={(id) => setISSRetido(id)}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SelectComponent
                styleType="form"
                label="Tipo de tributação do serviço"
                initialValue={
                  listTributacao.find((item) => item.id == tipoTributacao)
                    ? listTributacao.find((item) => item.id == tipoTributacao)
                        .nome
                    : ""
                }
                title="Selecione uma tributação"
                list={listTributacao}
                callback={(id) => setTipoTributacao(id)}
              />
            </Grid>

            <Grid xs={12} sm={6} md={4} className="servico-destaque">
              <div className="container-item-retencao">
                <div>Reter IRRF?</div>
                <div className="servico-switchDestaque">
                  <div className="servico-switchOption">Não</div>
                  <div>
                    <Switch
                      checked={reterIrrf}
                      onClick={() => setReterIrrf(!reterIrrf)}
                    />
                  </div>
                  <div>Sim</div>
                </div>
              </div>
            </Grid>

            <Grid xs={12} sm={6} md={4} className="servico-destaque">
              <div className="container-item-retencao">
                <div>Reter INSS?</div>
                <div className="servico-switchDestaque">
                  <div className="servico-switchOption">Não</div>
                  <div>
                    <Switch
                      checked={reterInss}
                      onClick={() => setReterInss(!reterInss)}
                    />
                  </div>
                  <div>Sim</div>
                </div>
              </div>
            </Grid>

            <Grid xs={12} sm={6} md={4} className="servico-destaque">
              <div className="container-item-retencao">
                <div>Reter Cofins?</div>
                <div className="servico-switchDestaque">
                  <div className="servico-switchOption">Não</div>
                  <div>
                    <Switch
                      checked={reterCofins}
                      onClick={() => setReterCofins(!reterCofins)}
                    />
                  </div>
                  <div>Sim</div>
                </div>
              </div>
            </Grid>

            <Grid xs={12} sm={6} md={4} className="servico-destaque">
              <div className="container-item-retencao">
                <div>Reter PIS?</div>
                <div className="servico-switchDestaque">
                  <div className="servico-switchOption">Não</div>
                  <div>
                    <Switch
                      checked={reterPis}
                      onClick={() => setReterPis(!reterPis)}
                    />
                  </div>
                  <div>Sim</div>
                </div>
              </div>
            </Grid>

            <Grid xs={12} sm={6} md={4} className="servico-destaque">
              <div className="container-item-retencao">
                <div>Reter CSLL?</div>
                <div className="servico-switchDestaque">
                  <div className="servico-switchOption">Não</div>
                  <div>
                    <Switch
                      checked={reterCsll}
                      onClick={() => setReterCsll(!reterCsll)}
                    />
                  </div>
                  <div>Sim</div>
                </div>
              </div>
            </Grid>

            <Grid xs={12} sm={6} md={4} className="servico-destaque">
              <div className="container-item-retencao">
                <div>Reter ISS?</div>
                <div className="servico-switchDestaque">
                  <div className="servico-switchOption">Não</div>
                  <div>
                    <Switch
                      checked={reterIss}
                      onClick={() => setReterIss(!reterIss)}
                    />
                  </div>
                  <div>Sim</div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid container direction="row-reverse">
        <Grid item>
          <ButtonComponent
            text="Salvar"
            className="btn-success"
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default ServicoCadastro;
