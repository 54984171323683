import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import ButtonComponent from '../ButtonComponent'
import Navbar from '../Navbar'

import SmallScreen from '../../utils/smallScreen'
import cliente from '../../config/cliente'

import barLogo from '../../assets/img/logo001.svg'
import './styles.scss'

function Bar({ arrayMenu, objHeaderConfig, secondaryContainer, showToolbar = true }) {
  const history = useHistory()
  const userStatus = window.localStorage.getItem('status_uso')

  const CLIENTE = cliente.cliente
  const isSmallScreen = SmallScreen()

  const [defaultLogo, setDefaultLogo] = useState(null)
  const [buttonVisible, setButtonVisible] = useState(true)

  useEffect(() => {
    setButtonVisible(false)
    setTimeout(() => {
      setButtonVisible(true)
    }, 100)
  }, [objHeaderConfig])

  function Title() {
    if (objHeaderConfig?.title === 'contabilhub') {
      return (
        <div
          className={`header-bar-container-title-page ${
            isSmallScreen ? 'header-bar-container-align' : ''
          }`}
        >
          <h2 className='header-bar-title-page'>
            Seja bem vindo à{' '}
            <span className='header-bar-title-page-special-text'>
              contábilhub
            </span>
          </h2>
        </div>
      )
    }

    if (objHeaderConfig?.title === '36z') {
      return (
        <div
          className={`header-bar-container-title-page ${
            isSmallScreen ? 'header-bar-container-align' : ''
          }`}
        >
          <h2 className='header-bar-title-page'>
            Seja bem vindo à{' '}
            <span className='header-bar-title-page-special-text'>36Z</span>
          </h2>
        </div>
      )
    }

    return (
      <div
        className={`header-bar-container-title-page ${
          (isSmallScreen && showToolbar)
            ? 'header-bar-container-align responsive-grid-contabil'
            : ''
        }`}
      >
        {objHeaderConfig?.title !== 'Home' &&
          objHeaderConfig?.title !== 'cbhub' && (
            <>
              {objHeaderConfig?.ceu ? (
                objHeaderConfig?.title === 'ceu' ? (
                  <h2 className='header-bar-title-page-ceu'>
                    Seja bem vindo à{' '}
                    <span className='header-bar-title-page-special-text'>
                      Céu
                    </span>
                  </h2>
                ) : (
                  <h2 className='header-bar-title-page-ceu'>
                    {objHeaderConfig?.title}
                  </h2>
                )
              ) : (
                <h2 className='header-bar-title-page' dangerouslySetInnerHTML={{__html: objHeaderConfig?.title}} />
              )}
            </>
          )}
        {objHeaderConfig?.title === 'Home' && (
          <>
            <h2 className='header-bar-title-page'>
              Seja bem vindo(a){' '}
              <span className='header-bar-title-page-special-text' />
            </h2>
          </>
        )}
        {objHeaderConfig?.title === 'cbhub' && (
          <>
            <h2 className='header-bar-title-page'>
              Seja bem vindo à{' '}
              <span className='header-bar-title-page-special-text'>
                contábilhub
              </span>
            </h2>
          </>
        )}
      </div>
    )
  }

  return (
    <header
      className={`${showToolbar && 'responsive-grid-contabil-container'} ${
        objHeaderConfig && secondaryContainer && 'header header-secondary'
      } ${objHeaderConfig && !secondaryContainer && 'header'} ${
        (!objHeaderConfig || !showToolbar) && 'no-header'
      }`}
    >
      {!isSmallScreen ? (
        <Link
          to={userStatus === '0' ? '/contrato' : '/home'}
          className='header-logo-link'
        >
          <h1 className='header-logo-bg'>
            <div
              className='header-logo'
              style={{
                backgroundImage:
                  CLIENTE === '36z' ? `url(${defaultLogo})` : `url(${barLogo})`
              }}
            />
          </h1>
        </Link>
      ) : (
        <Title />
      )}
      {showToolbar && (
        <div
          className={`header-bar ${
            objHeaderConfig && arrayMenu.length === 0 ? 'with-button' : ''
          } ${objHeaderConfig ? '' : 'no-header'}`}
        >
          {buttonVisible && (
            <>
              {arrayMenu.length === 0 && objHeaderConfig && (
                <>
                  {!isSmallScreen ? <Title /> : <div />}
                  {objHeaderConfig?.btnText && (
                    <ButtonComponent
                      className={`responsive-grid-contabil-button ${
                        !objHeaderConfig?.btnClass
                          ? ''
                          : objHeaderConfig?.btnClass
                      }`}
                      onClick={() =>
                        history.push(
                          !objHeaderConfig?.url ? '' : objHeaderConfig?.url
                        )
                      }
                      text={
                        !objHeaderConfig?.btnText
                          ? ''
                          : objHeaderConfig?.btnText
                      }
                    />
                  )}
                </>
              )}
            </>
          )}

          {arrayMenu.length !== 0 && (
            <>
              <Navbar arrayMenu={arrayMenu} />
            </>
          )}
        </div>
      )}
    </header>
  )
}

export default Bar
