import { Dialog, DialogTitle, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import pdvService from '../../../services/pdvService';
import { toDateDD_MM_YYYYandHours } from '../../../utils/dates';
import validateFields from "../../../utils/validateFields";
import ButtonComponent from "../../ButtonComponent";
import CallbackMessage from "../../CallbackMessage";
import InputComponent from "../../Input";
import CheckBox from "../../InputCheck";
import ModalLoginExpirado from "../../ModalLoginExpirado";
import OverlayLoading from "../../OverlayLoading";
import "./styles.scss";

import { numberToCurrency } from '../../../utils/functions';

function ModalFecharCaixa({
  open,
  onClose,
  onSave,
  idCaixa,
}) {
  const { consultaCaixa, fecharCaixa } = pdvService;

  const [registrar, setRegistrar] = useState(false);
  const [dataFechamento, setDataFechamento] = useState(null);
  const [motivo, setMotivo] = useState(null);
  const [valorSangria, setValorSangria] = useState(null);

  const [dadosFechamento, setDadosFechamento] = useState({});

  const user = JSON.parse(window.localStorage.getItem("user"));

  useEffect(() => { 
    if(dataFechamento === null) {
      setDataFechamento(toDateDD_MM_YYYYandHours(new Date()));
    }
  }, [dataFechamento])


  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  useEffect(() => {
    if(idCaixa && open) {
      loadCaixa();
    }
  }, [idCaixa, open])

  const loadCaixa = async () => {
    setLoading(true);
    const response = await consultaCaixa(idCaixa);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }

    if (response.status === 400) {
      setErrors(Object.values(response?.data?.error).flat(Infinity));
      setShowMessage(true);
    }

    if (response.status === 500) {
      setErrors(['Erro interno no servidor, contate o suporte.']);
      setShowMessage(true);
    }

    if(response.status === 200 || response.status === 201) {
      setDadosFechamento(response?.data);
    }

    setLoading(false);
  };

  async function handleSubmit() {
    setLoading(true);
    setErrors([]);

    const response = await fecharCaixa(idCaixa);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }

    if (response.status === 400) {
      setErrors(Object.values(response?.data?.error).flat(Infinity));
      setShowMessage(true);
    }

    if (response.status === 500) {
      setErrors(['Erro interno no servidor, contate o suporte.']);
      setShowMessage(true);
    }
    
    if(response.status === 200 || response.status === 201) {
      setShowMessage(true);
      setSuccessMessage(response?.data?.message);

      setTimeout(() => {
        onClose();
        onSave('fechamento');
      }, 2000)
    }

    setLoading(false);
  }

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>FECHAMENTO  DE CAIXA</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleClose}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={6} className="modal-items">
                  <div>
                  <label>Fechamento</label>
                  <span>{dataFechamento}</span>
                  </div>
               </Grid>
               <Grid item xs={12} sm={12} md={6} className="modal-items">
                  <div>
                  <label>Usuário</label>
                  <span>{user?.name}</span>
                  </div>
               </Grid>
                <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  nome="motivo"
                  label="Dinheiro"
                  tipo="dinheiro"
                  value={dadosFechamento?.total_dinheiro}
                  disabled
                />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  nome="motivo"
                  label="Cheque"
                  tipo="dinheiro"
                  value={dadosFechamento?.total_cheque}
                  disabled

                />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  nome="motivo"
                  label="Cartão de Crédito"
                  tipo="dinheiro"
                  value={dadosFechamento?.total_cartao_credito}
                  disabled

                />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  nome="motivo"
                  label="PIX"
                  tipo="dinheiro"
                  value={dadosFechamento?.total_pix}
                  disabled
                />
                </Grid>

                <Grid item xs={12} sm={12} md={12} className="info-itens-container">
                  <div className="info-item-caixa">
                  <h3>Abertura de Caixa</h3>
                  <span>{numberToCurrency(dadosFechamento?.valor_inicial)}</span>
                  </div>
                  <div className="info-item-caixa">
                  <h3>Sangria</h3>
                  <span>{numberToCurrency(dadosFechamento?.total_sangria)}</span>
                  </div>
                  <div className="info-item-caixa">
                  <h3>Suprimento</h3>
                  <span>{numberToCurrency(dadosFechamento?.total_suprimento)}</span>
                  </div>
                  <div className="info-item-caixa">
                  <h3>Entrada</h3>
                  <span>{numberToCurrency(dadosFechamento?.total_entrada)}</span>
                  </div>
                  <div className="info-item-caixa">
                  <h3>Saída</h3>
                  <span>{numberToCurrency(dadosFechamento?.total_saida)}</span>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <div className="total-item-caixa">
                  <h3>Total</h3>
                  <span>{numberToCurrency(dadosFechamento?.valor_final)}</span>
                  </div>
                </Grid>

              <Grid container justify="flex-end" item>
                <ButtonComponent
                  text="Salvar"
                  onClick={handleSubmit}
                  className="btn-success zero-margin"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalFecharCaixa;
