import React, { useState, useEffect, useCallback } from "react";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";

import OverlayLoading from "../../components/OverlayLoading";
import CallbackMessage from "../../components/CallbackMessage";

import categoriasServices from "../../services/categoriasServices";

import "./styles.scss";
import classes from "./styles.module.scss";
import exportFileService from "../../services/exportFile";
import { Search } from "../../components/TableResponsiveComponent";
import { Filter } from "../../components/FilterComponent";
import { GrupoComponent } from "./components/grupo";
import { Grid } from "@material-ui/core";
import { ModalImportarXlsx } from "../../components/ModalImportarXlsx";
import arquivoModeloSubcategorias from "../../assets/downloads/documento_padrao_importar_subcategoria.xlsx";
import useCompany from "../../hooks/useCompany";

function Subcategorias() {
  const { exportFile } = exportFileService;
  const { getCategorias, importarSubcategoria } = categoriasServices;
  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("subcategorias");

  const [listRequest, setListRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [openModalImportar, setOpenModalImportar] = useState(false);
  const [callbackShownModal, setCallbackShownModal] = useState(false);
  const [callbackErrorListModal, setCallbackErrorListModal] = useState([]);

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);

  const tableOptions = {
    noSearch: true,
    filter: true,
    more: true,
    moreOptions: [
      {
        icon: "export",
        label: "Exportar XLS",
        action: "exportar-xls",
      },
      {
        icon: "export",
        label: "Exportar PDF",
        action: "exportar-pdf",
      },
      {
        icon: "download",
        label: "Importar XLS",
        action: "importar-xls",
      },
    ],
  };

  const fetchCategory = useCallback(
    async (empresaStorage = null) => {
      if (!empresaSelecionada && !empresaStorage) {
        return;
      }
      setLoading(true);
      await getCategorias(empresaStorage || empresaSelecionada).then(
        (response) => {
          if (response.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          }
          if (response.status === 200) setListRequest(response.data);
          setLoading(false);
        }
      );
    },
    [empresaSelecionada]
  );

  const handleSelectEmpresa = async (id) => {
    console.log(id);
    setEmpresaSelecionada(id);
  };

  const handleApplyFilters = (empresaStorage = null) => {
    fetchCategory(empresaStorage);
    console.log({ empresaStorage });
    if (!empresaStorage) {
      setEmpresaPagina("subcategorias", empresaSelecionada);
    }
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setListRequest([]);
    setEmpresaPagina("subcategorias", null);
  };

  const exportToPDF = {
    url: "exportar-categorias-pdf",
    fileName: "cbhub_relatorio_categorias",
    fileType: "pdf",
  };

  const exportToXLS = {
    url: "exportar-categorias-excel",
    fileName: "cbhub_relatorio_categorias",
    fileType: "xlsx",
  };

  let onTableMoreAction = async ({ action }) => {
    console.log(action);

    if (action === "importar-xls") {
      return setOpenModalImportar(true);
    }

    if (!empresaSelecionada) {
      setCallbackErrorList(["Selecione uma empresa antes de exportar"]);
      setCallbackDuration(4000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackShown(true);
      return;
    }
    setLoading(true);
    let params = {};
    if (action === "exportar-xls") {
      params = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: {
          id_empresa: empresaSelecionada,
        },
      };
    } else if (action === "exportar-pdf") {
      params = {
        url: exportToPDF.url,
        fileName: exportToPDF.fileName,
        fileType: exportToPDF.fileType,
        params: {
          id_empresa: empresaSelecionada,
        },
      };
    }

    try {
      const response = await exportFile(params);
      console.log(response);

      if (response?.status === 200 || response?.status === 201) {
        setCallbackErrorList([]);
        setCallbackShown(true);
        setCallbackMessage("Sucesso!");
        setCallbackType("success");
      } else if (response?.status === 500) {
        setCallbackErrorList([
          "Erro interno no servidor. Por favor, contate o suporte",
        ]);
        setCallbackShown(true);
        setCallbackType("error");
      } else if (response?.status === 400) {
        console.log(response);
        if (
          typeof response?.data?.error === "string" &&
          response?.data?.error?.includes("Too Many Requests")
        ) {
          setLoading(false);
          setCallbackErrorList([
            "Ocorreu um excesso de consultas. Espere 1 minuto e tente novamente.",
          ]);
          setCallbackDuration(4000);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackShown(true);
        } else if (typeof response?.data?.error === "string") {
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackDuration(4000);
          setCallbackErrorList([response?.data?.error]);
        } else if (typeof response?.data?.error === "object") {
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackDuration(4000);
          setCallbackMessage("Erro!");
          setCallbackErrorList(
            Object.values(response?.data?.error).flat(Infinity)
          );
        }
      } else {
        setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
        setCallbackShown(true);
        setCallbackType("error");
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  function handleClose() {
    setCallbackShown(false);
    setCallbackType("");
  }

  const onImport = async ({ arquivo, idEmpresa }) => {
    console.log({ arquivo, idEmpresa });
    let errorList = [];
    if (!idEmpresa) {
      errorList.push("É necessário informar a empresa");
    }
    if (!arquivo) {
      errorList.push("É necessário selecionar um arquivo");
    }
    if (errorList.length > 0) {
      setCallbackErrorListModal(errorList);
      setCallbackShownModal(true);
      return;
    }

    setLoading(true);
    const response = await importarSubcategoria({
      arquivo,
      id_empresa: idEmpresa,
    });
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setOpenModalImportar(false);
      setCallbackMessage(response.data?.msg || "Importado com sucesso!");
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackShown(true);
      fetchCategory();
    } else {
      setCallbackShownModal(true);
      if (response.data.error)
        setCallbackErrorListModal(Object.values(response.data.error));
      else setCallbackErrorListModal(["Ocorreu um erro ao importar"]);
    }
    setLoading(false);
  };

  function filterComponent() {
    return (
      <Filter.Root>
        <Filter.Select
          callback={handleSelectEmpresa}
          label="Empresa"
          options={listEmpresas}
          value={empresaSelecionada}
          xs={12}
        />
        <Filter.Buttons className={classes.marginLeftAuto}>
          <Filter.Button
            text="Filtrar"
            className="btn-primary"
            onClick={handleApplyFilters}
          />
          <Filter.Button
            text="Limpar Filtros"
            className="default-outline clean-filters-button"
            onClick={handleCleanFilters}
          />
        </Filter.Buttons>
      </Filter.Root>
    );
  }

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("subcategorias");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    }
  }, []);

  return (
    <>
      <Search
        callback={() => {}}
        tableOptions={tableOptions}
        filterComponent={filterComponent()}
        callbackMenu={onTableMoreAction}
      />
      {!loading && !listRequest.isEmpty ? (
        listRequest?.length > 0 ? (
          <div className="session-container pagina-subcategorias">
            <Grid item>
              <div className="space-large-vertical" />
            </Grid>
            {listRequest.map((item, index) => (
              <>
                <GrupoComponent
                  item={item}
                  index={index}
                  fetchCategory={fetchCategory}
                />
                <div className="space-vertical" />
              </>
            ))}
          </div>
        ) : null
      ) : (
        <OverlayLoading />
      )}

      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={() => handleClose()}
      />

      <ModalImportarXlsx
        open={openModalImportar}
        onClose={() => setOpenModalImportar(false)}
        title="IMPORTAR SUBCATEGORIAS"
        acceptLabel="Importar"
        onAccept={onImport}
        callbackErrorListModal={callbackErrorListModal}
        callbackShownModal={callbackShownModal}
        setCallbackShown={setCallbackShownModal}
        arquivoPadrao={arquivoModeloSubcategorias}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default Subcategorias;
