import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import "./styles.scss";
import CallbackMessage from "../CallbackMessage";
import TableComponent from "../TableResponsiveComponent";
import preAdmissaoService from "../../services/dpPreAdmissaoService";
import OverlayLoading from "../OverlayLoading";
import { base64ToFile } from "../../utils/functions";

export const ModalListaDocumento = ({
  open,
  title,
  description,
  onClose,
  id,
  tipo,
  idEventoFolha = null
}) => {
  const { getDocumentosDp, downloadDocumentosDp } = preAdmissaoService;
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");

  const [listRequest, setListRequest] = useState([]);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id" },
      { columnName: "Nome do documento", dataRef: "nome_doc" },
    ],
    options: {
      file2: true,
    },
    display: {
      search: false,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      optionsColumnName: "Baixar",
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: "id",
    orderByType: "desc",
    perPage: null,
  });

  const updateTable = useCallback(async () => {
    console.log({ id, open });
    if (!id || !open) {
      return;
    }

    setLoading(true);
    const result = await getDocumentosDp({
      page: defaultConfigTable.currentPage,
      orderBy: defaultConfigTable.orderBy,
      orderByType: defaultConfigTable.orderByType,
      perPage: defaultConfigTable.perPage,
      searchTerm: defaultConfigTable.searchTerm,
      tipo: tipo,
      id_funcionario: id,
      id_evento_folha: idEventoFolha
    });
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const resultData = result.data;

    if (resultData.hasOwnProperty("data")) {
      const { data } = resultData;
      console.log(data);
      const newData = data.map((item) => {
        return {
          ...item,
        };
      });
      const newResult = {
        ...resultData,
        data: newData,
      };
      setListRequest(newResult);
    } else {
      const newData = resultData.map((item) => {
        return {
          ...item,
        };
      });
      const newResult = {
        ...resultData,
        data: newData,
      };
      setListRequest(newResult);
    }

    setLoading(false);
  }, [
    id,
    open,
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
  ]);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  const onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onDownload = async (idDoc) => {
    console.log(idDoc);

    setLoading(true);
    const response = await downloadDocumentosDp(idDoc);
    const name = listRequest?.data?.find((item) => item.id == idDoc)?.nome_doc
      ? listRequest?.data?.find((item) => item.id == idDoc)?.nome_doc
      : "cbhub_anexo_dp";
    if (response.status === 201) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        response?.data?.mensagem || "Download realizado com sucesso!"
      );
    }
    base64ToFile(
      response.data.arquivo,
      name,
      response.data.arquivo.startsWith(
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
      )
        ? "xlsx"
        : response.data.arquivo.startsWith(
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;"
          )
        ? "docx"
        : ""
    );

    console.log(response);

    if (response.status === 500) {
      setCallbackType("error");
      setCallbackErrorList([
        "Erro interno no servidor. Por favor, contate o suporte",
      ]);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
    } else if (response.status !== 201 && response.status !== 200) {
      setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
    }

    setLoading(false);
  };

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    updateTable();
  }, [updateTable, id]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" className="paper">
      {loading && <OverlayLoading />}
      <div className="modal-title-area">
        <DialogTitle>
          <span className="titleConfirmDialog">{title}</span>
        </DialogTitle>
        <IconButton onClick={onClose} size="medium">
          <CloseIcon className="iconConfirm" />
        </IconButton>
      </div>
      <DialogContent className="custom-dialog-content">
        {description && <pre className="confirmDescription">{description}</pre>}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableComponent
              idName="id"
              dataList={listRequest !== undefined ? listRequest?.data : []}
              tableConfig={defaultConfigTable}
              callbackCurrentPage={onPageChange}
              callbackOrderBy={onOrderBy}
              callbackPerPage={onPerPage}
              cbDownload={onDownload}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={4000}
        message={callbackMessage}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

ModalListaDocumento.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

ModalListaDocumento.defaultProps = {
  open: false,
  description: "Você tem certeza que deseja realizar a exclusão?",
  title: "CONFIRMAR EXCLUSÃO",
};
