import React, { useCallback, useEffect, useState } from "react";
import { FormControlLabel, Grid, useMediaQuery } from "@material-ui/core";
import styles from "./styles.module.scss";
import Input from "../../../../../components/Input";
import SelectComponent from "../../../../../components/SelectComponent";
import selectService from "../../../../../services/selectServices";
import InputFile from "../../../../../components/InputFile";
import DatePicker from "../../../../../components/DatePicker";
import SwitchComponent from "../../../../../components/Switch";
import viacepServices from "../../../../../services/viacepServices";
import ImagePreview from "../../../../../assets/img/user.svg";

const simNaoOpcoes = [
  {
    id: 1,
    nome: "Sim",
  },
  {
    id: 0,
    nome: "Não",
  },
];

export function DadosPessoais({ locationState, dados, setDados, setLoading }) {
  const isMobile = useMediaQuery("(max-width: 1100px)");

  const {
    selecionaEstadoCivil,
    selecionaSexo,
    selecionaCor,
    selecionaEscolaridade,
    selecionaPais,
    selecionaNacionalidade,
    selecionaTipoDeficiencia,
  } = selectService;

  const { selecionaEstado, selecionaCidade } = viacepServices;
  const [cidadesLoading, setCidadesLoading] = useState(false);

  const [listEstadoCivil, setListEstadoCivil] = useState([]);
  const [listSexo, setListSexo] = useState([]);
  const [listCor, setListCor] = useState([]);
  const [listEscolaridade, setListEscolaridade] = useState([]);
  const [listPais, setListPais] = useState([]);
  const [listNacionalidade, setListNacionalidade] = useState([]);
  const [listEstado, setListEstado] = useState([]);
  const [listCidade, setListCidade] = useState([]);
  const [listTipoDeficiencia, setListTipoDeficiencia] = useState([]);

  const loadEstadoCivil = useCallback(async () => {
    await selecionaEstadoCivil().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListEstadoCivil(response.data);
    });
  }, []);

  const loadSexo = useCallback(async () => {
    await selecionaSexo().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListSexo(response.data);
    });
  }, []);

  const loadCor = useCallback(async () => {
    await selecionaCor().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListCor(response.data);
    });
  }, []);

  const loadEscolaridade = useCallback(async () => {
    await selecionaEscolaridade().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListEscolaridade(response.data);
    });
  }, []);

  const loadPais = useCallback(async () => {
    await selecionaPais().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListPais(response.data);
    });
  }, []);

  const loadNacionalidade = useCallback(async () => {
    await selecionaNacionalidade().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListNacionalidade(response.data);
    });
  }, []);

  const loadEstado = useCallback(async () => {
    await selecionaEstado().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListEstado(response.data);
    });
  }, []);

  const loadTipoDeficiencia = useCallback(async () => {
    await selecionaTipoDeficiencia().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListTipoDeficiencia(response.data);
    });
  }, []);

  const loadCidades = useCallback(async (id) => {
    setCidadesLoading(true);
    const response = await selecionaCidade(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return [];
    }
    setListCidade(response.data);
    setCidadesLoading(false);
  }, []);

  function handleChangeDados(field, value, base64 = false) {
    let novosDados = { ...dados };
    novosDados[field] = value;
    if (field === "estadoNascimento") {
      novosDados["municipioNascimento"] = null;
    }
    if (field === "imagem") {
      novosDados["imagemPreview"] = base64;
    }
    console.log(novosDados);
    setDados(novosDados);
  }

  useEffect(() => {
    loadEstadoCivil();
    loadSexo();
    loadCor();
    loadEscolaridade();
    loadPais();
    loadNacionalidade();
    loadEstado();
    loadTipoDeficiencia();
  }, []);

  useEffect(() => {
    if (dados.estadoNascimento) {
      loadCidades(dados.estadoNascimento);
    }
  }, [dados.estadoNascimento]);

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} direction="row-reverse" spacing={2}>
        <Grid
          item
          container
          xs={12}
          md={3}
          className={styles.containerImagemPreview}
          justifyContent="center"
          alignItems="flex-end"
        >
          <div className={styles.imagemPreview}>
            <img
              src={dados?.imagemPreview || ImagePreview}
              alt="Imagem funcionário"
            />
          </div>
        </Grid>
        <Grid item container spacing={2} xs={12} md={9} className={styles.containerInputImagem}>
          <Grid item xs={12} className={styles.containerPL0}>
            <InputFile
              hasFile={dados.hasImagem ? "Imagem selecionada" : false}
              title="Imagem"
              disabled={locationState?.details}
              onChange={(base64, __, file) =>
                handleChangeDados("imagem", file, base64)
              }
            />
          </Grid>
          <Grid item xs={12} md={12} className={styles.containerPL0}>
            <DatePicker
              label="Data de Nascimento*"
              name="dataNascimento"
              initDate={dados?.dataNascimento ? dados?.dataNascimento : ""}
              handleChange={(date) => {
                handleChangeDados("dataNascimento", date);
              }}
              value={dados?.dataNascimento}
              required
              readOnly={locationState?.details}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Estado Civil"
          required
          title="Selecione uma opção"
          list={listEstadoCivil}
          initialValue={
            listEstadoCivil.find((item) => item.id === dados?.estadoCivil)
              ? listEstadoCivil.find((item) => item.id === dados?.estadoCivil)
                  .nome
              : ""
          }
          callback={(id) => handleChangeDados("estadoCivil", id)}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Sexo"
          required
          title="Selecione uma opção"
          list={listSexo}
          initialValue={
            listSexo.find((item) => item.id === dados?.sexo)
              ? listSexo.find((item) => item.id === dados?.sexo).nome
              : ""
          }
          callback={(id) => handleChangeDados("sexo", id)}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Cor"
          required
          title="Selecione uma opção"
          list={listCor}
          initialValue={
            listCor.find((item) => item.id === dados?.cor)
              ? listCor.find((item) => item.id === dados?.cor).nome
              : ""
          }
          callback={(id) => handleChangeDados("cor", id)}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Escolaridade"
          required
          title="Selecione uma opção"
          list={listEscolaridade}
          initialValue={
            listEscolaridade.find((item) => item.id === dados?.escolaridade)
              ? listEscolaridade.find((item) => item.id === dados?.escolaridade)
                  .nome
              : ""
          }
          callback={(id) => handleChangeDados("escolaridade", id)}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          label="Nome do pai"
          name="nomePai"
          value={dados?.nomePai}
          handleInputChange={async (e) => {
            handleChangeDados("nomePai", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          label="Nome da mãe"
          name="nomeMae"
          required
          value={dados?.nomeMae}
          handleInputChange={async (e) => {
            handleChangeDados("nomeMae", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="País de nascimento"
          required
          title="Selecione uma opção"
          list={listPais}
          initialValue={
            listPais.find((item) => item.id === dados?.paisNascimento)
              ? listPais.find((item) => item.id === dados?.paisNascimento).nome
              : ""
          }
          callback={(id) => handleChangeDados("paisNascimento", id)}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Nacionalidade"
          required
          title="Selecione uma opção"
          list={listNacionalidade}
          initialValue={
            listNacionalidade.find((item) => item.id === dados?.nacionalidade)
              ? listNacionalidade.find(
                  (item) => item.id === dados?.nacionalidade
                ).nome
              : ""
          }
          callback={(id) => handleChangeDados("nacionalidade", id)}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Estado de nascimento"
          required
          title="Selecione uma opção"
          list={listEstado}
          initialValue={
            listEstado.find((item) => item.id === dados?.estadoNascimento)
              ? listEstado.find((item) => item.id === dados?.estadoNascimento)
                  .nome
              : ""
          }
          callback={(id) => {
            handleChangeDados("estadoNascimento", id);
          }}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Município de nascimento"
          required
          title="Selecione uma opção"
          list={listCidade}
          loading={cidadesLoading}
          initialValue={
            listCidade.find((item) => item.id === dados?.municipioNascimento)
              ? listCidade.find(
                  (item) => item.id === dados?.municipioNascimento
                ).nome
              : ""
          }
          callback={(id) => handleChangeDados("municipioNascimento", id)}
          isDisabled={locationState?.details}
        />
      </Grid>

      <Grid item className="container-input-switch" xs={12}>
        Possui deficiência?
        <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
          Não
        </Grid>
        <Grid item style={{ marginLeft: 12 }}>
          <FormControlLabel
            className={styles.controlLabel}
            style={{ marginBottom: 0 }}
            control={
              <SwitchComponent
                checked={dados?.possuiDeficiencia}
                onClick={(event) => {
                  console.log(dados?.possuiDeficiencia);
                  handleChangeDados(
                    "possuiDeficiencia",
                    !dados.possuiDeficiencia
                  );
                }}
                name="possuiDeficiencia"
                disabled={locationState?.details}
              />
            }
          />
        </Grid>
        <Grid item style={{ marginLeft: -8 }}>
          Sim
        </Grid>
      </Grid>

      {dados?.possuiDeficiencia && (
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Qual deficiência?"
            title="Selecione uma opção"
            list={listTipoDeficiencia}
            initialValue={
              listTipoDeficiencia.find((item) => item.id === dados?.deficiencia)
                ? listTipoDeficiencia.find(
                    (item) => item.id === dados?.deficiencia
                  ).nome
                : ""
            }
            callback={(id) => handleChangeDados("deficiencia", id)}
            isDisabled={locationState?.details}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Input
          label="Observações"
          name="observacoes"
          value={dados?.observacoes}
          handleInputChange={async (e) => {
            handleChangeDados("observacoes", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12}>
        <span className="required-text">* Campos obrigatórios</span>
      </Grid>
    </Grid>
  );
}
