import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import Table from "../../../components/TableComponent";
import RemoveDialog from "../../../components/ConfirmDialog";
import { Grid } from "@material-ui/core";
import Select from "../../../components/SelectComponent";
import Button from "../../../components/ButtonComponent";
import "./styles.css";
import empresasServices from "../../../services/empresasService";
import produtosService from "../../../services/produtosServices";
import integracoesService from "../../../services/integracoesService";

import { base64ToFile, numberToCurrency } from "../../../utils/functions";
import DatePicker from "../../../components/DatePicker";
import ModalHistoricoIntegracao from "../../../components/ModalHistoricoIntegracao";
import { opcoesStatusIntegracao } from "../../../enums/opcoesStatusIntegracao"
import { toDateDD_MM_YYYY } from "../../../utils/dates";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

const ListaIntegracao = () => {

  const [loading, setLoading] = useState(false);

  const { listaIntegracoes, excluirIntegracao } = integracoesService;
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [empresaSelecionada, setEmpresaSelecionada] = useState("");
  const [listEmpresas, setListEmpresas] = useState([]);
  const { selectEmpresa } = empresasServices;

  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [historicoIntegracaoId, setHistoricoIntegracaoId] = useState(null);
  const [historicoIntegracaoNomeEmpresa, setHistoricoIntegracaoNomeEmpresa] = useState('');
  const [openModalHistory, setOpenModalHistory] = useState(false);

  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const handleClose = (event, reason) => {
    console.log(reason);
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  };

  const [listRequest, setListRequest] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id" },
      { columnName: "Empresa", dataRef: "razao_social" },
      { columnName: "Data de integração", dataRef: "data_inicio" },
      { columnName: "Status", dataRef: "status" },
    ],
    options: {
      delete: true,
      searchFile: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      statusLabels: true,
      filter: false,
      alignLeft: true
    },
    tableOptions: {
      filter: true,
      more: false,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    id_empresa: null,
    data_inicio: null,
    data_fim: null,
  });

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await listaIntegracoes(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.id_empresa,
      defaultConfigTable.data_inicio,
      defaultConfigTable.data_fim,
    );
    if (result?.status === 200) {
      if (result?.data.hasOwnProperty("data")) {
        const { data } = result;

        const newData = data.data.map((item, i) => {
          return {
            ...item,
            data_inicio: toDateDD_MM_YYYY(item.data_inicio),
            status: opcoesStatusIntegracao.find(a => a.id === item.status) ? opcoesStatusIntegracao.find(a => a.id === item.status).nome : 'Erro',
          };
        });

        const newResult = {
          ...data,
          data: newData,
        };
        setListRequest(newResult);
        setLoading(false);
      } else {
        if (result?.data?.length) {
          const newResult = result?.data?.map((item) => {
            return {
              ...item,
              data_inicio: toDateDD_MM_YYYY(item.data_inicio),
              status: opcoesStatusIntegracao.find(a => a.id === item.status) ? opcoesStatusIntegracao.find(a => a.id === item.status).nome : 'Erro',
            };
          });
  
          setListRequest(newResult);
        } else {
          setListRequest([]);
        }
        setLoading(false);
      }
    } else if (result?.status === 500) {
      setLoading(false);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackDuration(2000);
      setCallbackErrorList(["Houve um erro interno no servidor."]);
      setCallbackShown(true);
      return;
    }

    if (result?.status === 401) {
      setLoading(false);
      setOpenLoginExpirado(true);
      return;
    }

    if (result?.status === 400 || result?.result?.status) {
      setLoading(false);
      setCallbackMessage("Erro!");
      setCallbackDuration(5000);
      setCallbackType("error");
      setCallbackShown(true);
      setCallbackErrorList(
        result?.data?.error ?
          Object.values(
            result?.data?.error || result?.result?.data?.error
          ).flat(Infinity)
          :
          (result?.data?.msg
            ?
            [result?.data?.msg] :
            ["Ocorreu algum erro inesperado, tente novamente."]
          )
      );
      return;
    }
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
    defaultConfigTable.data_inicio,
    defaultConfigTable.data_fim,
  ]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    loadEmpresas();
  }, []);

  const loadEmpresas = useCallback(async () => {
    const response = await selectEmpresa();
    if (response.status === 200) {
      setListEmpresas(response.data);
    } else if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }

  }, [selectEmpresa]);


  const onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  const handleRemoveCancel = () => {
    setDeleteId(null);
    setConfirmRemoveDialog(false);
  };

  const onDelete = async () => {
    
    setLoading(true);
    const response = await excluirIntegracao(deleteId);
    if (response.status === 200) {
      setLoading(false);
      setCallbackErrorList([]);
      setCallbackMessage(response?.data?.msg ? response.data.msg : "Removido com sucesso!");
      setCallbackDuration(2000);
      setCallbackType("success");
      setCallbackShown(true);
      handleRemoveCancel();
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (newDefaultConfigTable.currentPage > 1 && listRequest?.data?.length === 1) {
        newDefaultConfigTable.currentPage = newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      }else{
        updateTable();
      }
      return;
    }

    if (response.status === 500) {
      setLoading(false);
      setCallbackMessage("Erro!");
      setCallbackDuration(2000);
      setCallbackType("error");
      setCallbackErrorList(["Houve um erro interno no servidor."]);
      handleRemoveCancel();
      setCallbackShown(true);
      return;
    }

    if (response.status === 401) {
      setLoading(false);
      setOpenLoginExpirado(true);
      handleRemoveCancel();
      return;
    }

    if (response?.status === 400 || response?.response?.status) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackMessage("Erro!");
      setCallbackDuration(5000);
      setCallbackType("error");
      setCallbackErrorList(
        response?.data?.error ?
          Object.values(
            response?.data?.error || response?.response?.data?.error
          ).flat(Infinity)
          :
          (response?.data?.msg
            ?
            [response?.data?.msg] :
            ["Ocorreu algum erro inesperado, tente novamente."]
          )
      );
      handleRemoveCancel();
      return;
    }

    setLoading(false);
    handleRemoveCancel();
  };

  let onClickFile = (id) => {
    console.log(id);
    console.log(listRequest);
    let nomeEmpresa = listRequest?.data?.find(item => Number(item.id) === Number(id))
    nomeEmpresa = nomeEmpresa ? nomeEmpresa.razao_social : ''
    console.log(nomeEmpresa);
    setHistoricoIntegracaoId(id);
    setHistoricoIntegracaoNomeEmpresa(nomeEmpresa);
    setOpenModalHistory(true);
  };

  const handleCloseModalHistorico = () => {
    setHistoricoIntegracaoId(null);
    setOpenModalHistory(false);
  };

  const handleSelectEmpresa = async (id) => {
    console.log(id);
    setEmpresaSelecionada(id);
  };

  const onTableMoreAction = async action => {
    if (!defaultConfigTable?.id_empresa) {
      setCallbackMessage("Erro!");
      setCallbackType("error");
      setCallbackErrorList(['É necessário selecionar uma empresa antes de exportar.']);
      setCallbackShown(true);
      return;
    }
    setLoading(true);
    const response = await produtosService.exportarRelatorio(
      defaultConfigTable?.id_empresa ? defaultConfigTable?.id_empresa : null
    );
    setLoading(false);
    if (response.status === 200) {
      base64ToFile(response.data.arquivo, 'cbhub_relatorio_produtos', 'xlsx');
    } else if (response.status === 500 || response?.response?.status === 500) {
      setCallbackErrorList(['Erro interno no servidor. Por favor, contate o suporte']);
      setCallbackType("error")
      setCallbackShown(true);
    } else if (response?.status === 400 || response?.response?.status === 400) {
      setLoading(false);
      setCallbackMessage("Erro!");
      setCallbackDuration(5000);
      setCallbackType("error");
      setCallbackShown(true);

      setCallbackErrorList(
        response?.data?.error ?
          Object.values(
            response?.data?.error || response?.response?.data?.error
          ).flat(Infinity)
          :
          (response?.data?.msg
            ?
            [response?.data?.msg] :
            ["Ocorreu algum erro inesperado, tente novamente."]
          )
      );
      return;
    } else if (response?.status === 401 || response?.response?.status === 401) {
      setLoading(false);
      setOpenLoginExpirado(true);
      return;
    } else {
      setCallbackErrorList(['Ocorreu um erro ao exportar o relatório.']);
      setCallbackType("error")
      setCallbackShown(true);
    }

    setLoading(false);

  };

  const handleApplyFilters = () => {
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_empresa: empresaSelecionada,
      data_inicio: dataInicio,
      data_fim: dataFim,
    });
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_empresa: null,
      data_inicio: null,
      data_fim: null,
    });
  };

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat integracao-questor">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                    ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={handleSelectEmpresa}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="De *"
              handleChange={(value) => setDataInicio(value)}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="Até *"
              handleChange={(value) => setDataFim(value)}
            />
          </Grid>
          <Grid className="marginLeftAuto">
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className="container-lista-integracoes">
      {loading && <OverlayLoading />}
      <Table
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbDelete={handleRemoveDialog}
        cbLookFile={onClickFile}
        filterComponent={filterComponent()}
        cbTableMoreAction={onTableMoreAction}
      />
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />
      <RemoveDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
        title="CONFIRMAR CANCELAMENTO"
        description="Você tem certeza que deseja realizar o cancelamento?"
        acceptLabel="Salvar"
        cancelLabel="Voltar"
        cancelClass="default-outline"
      />
      <ModalHistoricoIntegracao
        open={openModalHistory}
        onClose={handleCloseModalHistorico}
        idIntegracao={historicoIntegracaoId}
        nomeEmpresa={historicoIntegracaoNomeEmpresa}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </div>
  );
};

export default ListaIntegracao;
