import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CallbackMessage from "../../../../components/CallbackMessage";
import { Accordion, AccordionDetails, Grid } from "@material-ui/core";
import TableComponent from "../../../../components/TableResponsiveComponent";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import ModalLoginExpirado from "../../../../components/ModalLoginExpirado";
import { AccordionHeader } from "./components/AccordionHeader";
import categoriasServices from "../../../../services/categoriasServices";
import categoriaEmpresaService from "../../../../services/categoriaEmpresaService";
import Icon from "../../../../components/Icon";

function ListConta(categories, groupId) {
  const [showMessage, setShowMessage] = useState(false);
  const [errorsList, setErrorsList] = useState([]);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  let list = [];
  categories.forEach((item) => {
    list.push(item.conta_contabil);
  });

  return list.map((item, index) => {
    return (
      <Grid item container>
        <Grid item>
          <input
            className="input-conta"
            defaultValue={list[index]}
            onChange={(event) => {
              list[index] = event.target.value;
            }}
          />
        </Grid>
        <Grid item>
          <div
            className="icon-wrapper"
            onClick={async () => {
              let categoria = categories[index];

              const response = await categoriasServices.atualizaCategoriaPadrao(
                categoria.id_categoria_padrao,
                list[index]
              );
              if (response.status === 401) {
                setOpenLoginExpirado(true);
                return;
              }
              if (response.status !== 200)
                if (response.data.error)
                  setErrorsList(Object.values(response.data.error));
                else setErrorsList(["Ocorreu um erro"]);
              setShowMessage(true);
            }}
          >
            <Icon name="save" size={17} color="#EF4066" />
          </div>
        </Grid>
        <CallbackMessage
          duration={4000}
          open={showMessage}
          errorList={errorsList}
          message={
            errorsList.length === 0 ? "Categoria editada com sucesso" : "Erro"
          }
          type={errorsList.length === 0 ? "success" : "error"}
          handleClose={() => setShowMessage(false)}
        />
        <ModalLoginExpirado open={openLoginExpirado} />
      </Grid>
    );
  });
}

export const AccordionCategory = ({
  groupName,
  categoryId,
  companyId,
  categories,
  groupStatus,
  groupId,
  updateCategories = () => {},
}) => {
  const { atualizarStatusSubcategoria } = categoriasServices;
  const { atualizarStatusCategoria } = categoriaEmpresaService;
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [
    confirmAtualizaStatusCategoriaDialog,
    setConfirmAtualizaStatusCategoriaDialog,
  ] = useState(false);
  const [confirmStatusGrupoDialog, setConfirmStatusGrupoDialog] =
    useState(false);

  const [possuiCategoriaComLancamento, setPossuiCategoriaComLancamento] =
    useState(false);
  const [atualizaStatusSubcategoria, setAtualizaStatusCategoria] =
    useState(null);
  const [removeGrupoId, setRemoveGrupoId] = useState(null);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const defaultConfigTable = {
    columnArray: [
      { columnName: "Categoria", dataRef: "nome" },
      { columnName: "Classificação", dataRef: "classificacao" },
      { columnName: "Tipo", dataRef: "tipo" },
    ],

    display: {
      statusLabels: true,
      search: false,
      itemsPerPage: false,
      totalResults: false,
      pagination: false,
    },
    currentPage: 1,
    pagination: false,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function handleAtualizaStatusCategoria() {
    const response = await atualizarStatusCategoria(removeGrupoId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      return;
    }

    if (response.status === 200) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        response?.data?.message || "Status da categoria atualizada com sucesso!"
      );
      setCallbackDuration(2000);
      setTimeout(() => {
        updateCategories();
      }, 1500);
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
    handleAtualizaStatusCancel();
  }

  function handleClose() {
    setCallbackShown(false);
  }

  function handleAtualizaStatusCancel() {
    setConfirmAtualizaStatusCategoriaDialog(false);
    setConfirmStatusGrupoDialog(false);
    setAtualizaStatusCategoria(null);
  }

  async function handleAtualizaStatusSubcategoria() {
    await atualizarStatusSubcategoria(atualizaStatusSubcategoria.id).then(
      (response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          return;
        }
        setCallbackErrorList([]);
        if (response.status === 201) {
          setCallbackType("success");
          setCallbackMessage(
            response?.data?.message ||
              "Status da subcategoria atualizado com sucesso!"
          );
          setCallbackDuration(4000);
          setCallbackShown(true);
          setTimeout(() => {
            updateCategories();
          }, 1500);
        } else {
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          if (response.data.error)
            setCallbackErrorList(Object.values(response.data.error));
          else
            setCallbackErrorList([
              "Houve um erro ao tentar atualizar o status da subcategoria. Por favor, tente novamente.",
            ]);
        }
      }
    );
    handleAtualizaStatusCancel();
  }

  useEffect(() => {
    if (categories.length > 0) {
      let possuiLancamentos = false;
      categories.forEach((element) => {
        if (element.possui_lancamentos && groupStatus) {
          possuiLancamentos = true;
        }
      });
      setPossuiCategoriaComLancamento(possuiLancamentos);
    } else {
      setPossuiCategoriaComLancamento(false);
    }
  }, [categories, groupStatus]);

  return (
    <>
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
        key={`${categoryId}_callback`}
      />
      <Accordion
        key={categoryId}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className="subcategoria-accordion"
      >
        <AccordionHeader expanded={expanded} name={groupName} />

        <AccordionDetails className="accordion-details-categoria">
          <TableComponent
            idName="id_categorias"
            dataList={categories}
            tableConfig={defaultConfigTable}
            enableOrder={false}
            customColumns={[
              {
                columnHead: <span>Conta Contábil</span>,
                columnData: ListConta(categories, groupId),
              },
            ]}
          />
        </AccordionDetails>
      </Accordion>
      <ConfirmDialog
        open={confirmAtualizaStatusCategoriaDialog}
        onCancel={handleAtualizaStatusCancel}
        onAccept={handleAtualizaStatusSubcategoria}
        acceptLabel={
          atualizaStatusSubcategoria?.status ? "Desativar" : "Ativar"
        }
        title="CONFIRMAR ALTERAÇÃO DE STATUS"
        description={`Tem certeza que deseja ${
          atualizaStatusSubcategoria?.status ? "desativar" : "ativar"
        } esta subcategoria?${
          atualizaStatusSubcategoria?.status &&
          atualizaStatusSubcategoria?.possui_lancamentos
            ? `\r\n\r\nExistem registros relacionados a esta subcategoria!`
            : ""
        }`}
      />
      <ConfirmDialog
        open={confirmStatusGrupoDialog}
        onCancel={handleAtualizaStatusCancel}
        onAccept={handleAtualizaStatusCategoria}
        acceptLabel={groupStatus ? "Desativar" : "Ativar"}
        title="CONFIRMAR ALTERAÇÃO DE STATUS"
        description={`Tem certeza que deseja ${
          groupStatus ? "desativar" : "ativar"
        } esta categoria e todas as suas subcategorias?${
          possuiCategoriaComLancamento
            ? `\r\n\r\nEsta categoria possui subcategoria(s) com lançamentos registrados!`
            : ""
        }`}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
};
