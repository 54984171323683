import { Dialog, DialogTitle, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import pdvService from '../../../services/pdvService';
import { toDateDD_MM_YYYYandHours, dateToDateDD_MM_YYYYandHours } from '../../../utils/dates';
import validateFields from "../../../utils/validateFields";
import ButtonComponent from "../../ButtonComponent";
import CallbackMessage from "../../CallbackMessage";
import InputComponent from "../../Input";
import ModalLoginExpirado from "../../ModalLoginExpirado";
import OverlayLoading from "../../OverlayLoading";
import "./styles.scss";



function ModalAbrirCaixa({
  open,
  onClose,
  onSave,
}) {
  const history = useHistory();
  const [dadoCliente, setDadoCliente] = useState("");
  const [cadastrarCliente, setCadastrarCliente] = useState(false);

  const { abrirCaixa } = pdvService;

  const user = JSON.parse(window.localStorage.getItem("user"));

  const [dataAbertura, setDataAbertura] = useState(null);
  // const [dataAbertura, setDataAbertura] = useState(null);
  const [codigoPdv, setCodigoPdv] = useState(null);
  const [valorAbertura, setValorAbertura] = useState(null);

  useEffect(() => { 
    if(dataAbertura === null) {
      setDataAbertura(toDateDD_MM_YYYYandHours(new Date()));
    }
  }, [])


  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  async function handleSubmit() {
    setErrors([]);

    let fields = [
      {
        label: "codigopdv",
        value: codigoPdv,
      },
      {
        label: "valorabertura",
        value: valorAbertura,
      },
    ];
    
    const fieldsValidate = validateFields(fields);

    if (fieldsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(fieldsValidate);
      return;
    }

    const companyId = parseInt(window.localStorage.getItem("empresa_usuario"), 10);
    
    const response = await abrirCaixa(companyId, valorAbertura, codigoPdv);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }

    if (response.status === 400) {
      setErrors(Object.values(response?.data?.error).flat(Infinity));
      setShowMessage(true);
    }

    if (response.status === 500) {
      setErrors(['Erro interno no servidor, contate o suporte.']);
      setShowMessage(true);
    }
    

    if(response.status === 200 || response.status === 201) {
      setSuccessMessage(response.data?.message);
      setShowMessage(true)

      let data = {
        codigoPdv: codigoPdv,
        valorAbertura: valorAbertura,
        idCaixa: response?.data?.dados_caixa?.id_caixa,
        dataAbertura: dateToDateDD_MM_YYYYandHours(response?.data?.dados_caixa?.data_abertura),
      }

      onSave(data);
      onClose();
    }


    setLoading(false);
  }

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }

  const handleBack = () => {
    history.push('/home');
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => {}} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>ABERTURA DE CAIXA</span>
          </Grid>
          {/* <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid> */}
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
            <Grid item xs={12} sm={12} md={6} className="modal-items">
                <label>Abertura</label>
                <span>{dataAbertura}</span>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="modal-items">
                <label>Usuário</label>
                <span>{user?.name}</span>
              </Grid>
        
                <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  nome="codigopdv"
                  label="PDV"
                  title="Digite o PDV"
                  tipo="text"
                  value={codigoPdv}
                  handleInputChange={(event) => {
                    setCodigoPdv(event.target.value);
                  }}
                  required
                />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  nome="valor"
                  label="Valor de Abertura"
                  title="R$"
                  tipo="dinheiro"
                  value={valorAbertura}
                  handleInputChange={(event) => {
                    setValorAbertura(event.value);
                  }}
                  required
                />
                </Grid>

                <Grid item xs={12}>
                <span className="required-text">* Campos obrigatórios</span>
                </Grid>

                <Grid container justify="flex-end" item spacing={2}>
                  <Grid item>
                    <ButtonComponent
                      text="Voltar"
                      onClick={handleBack}
                      className="primary-outline zero-margin btn-modal-outline"
                    />
                  </Grid>
                  <Grid item>
                      <ButtonComponent
                        text="Salvar"
                        onClick={handleSubmit}
                        className="btn-success zero-margin"
                      />
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalAbrirCaixa;
