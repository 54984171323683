import React, { useState, useEffect, useCallback, useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import loadPermissoes from "../contexts/RoutesContext";
import { NavbarContext } from "../contexts/NavbarContext";
import ModalAcessoNegado from "../components/ModalAcessoNegado";
import { AuthContext } from "../contexts/AuthContext";

const RouteWrapper = ({ path = null, render, isPrivate = true, ...rest }) => {
  const { authenticated } = useContext(AuthContext);
  const userStatus = window.localStorage.getItem("status_uso");
  const empresaUsuario = window.localStorage.getItem("empresa_usuario");
  const [hasAccess, setHasAccess] = useState(true);
  const permissoes = loadPermissoes();
  const { search } = useLocation();
  const { fetchNotification } = useContext(NavbarContext);

  const load = useCallback(() => {
    let route = permissoes.find((item) => item.path === path);
    if (route && route !== undefined && !route?.accessGranted) {
      setHasAccess(false);
    }

    if (
      path === "/home" ||
      path === "/contrato" ||
      path.startsWith("/fatura")
    ) {
      setHasAccess(true);
    }
  }, [permissoes, path]);

  const loadNotification = useCallback(async () => {
    await fetchNotification();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted) load();

    return () => (mounted = false);
  }, [path, load]);

  useEffect(() => {
    if (authenticated) loadNotification();
  }, [path, loadNotification]);
  if (path === "/contrato" && userStatus === "1") {
    return <Redirect to="/home" />;
  }

  if (
    path.startsWith("/teste-") ||
    path.startsWith("/atualiza-senha") ||
    path.includes("black-friday")
  ) {
    return <Route path={path} render={render} {...rest} />;
  }

  if (authenticated && search.startsWith("?pag=")) {
    if (search.startsWith("?pag=faturas")) {
      return <Redirect to={`/faturas/${empresaUsuario}`} />;
    } else {
      return <Redirect to={"/" + search.split("pag=")[1]} />;
    }
  }
  if (
    authenticated &&
    userStatus === "0" &&
    !path.startsWith("/fatura") &&
    !path.startsWith("/contrato")
  ) {
    return <Redirect to="/contrato" />;
  }
  if (authenticated && !hasAccess) {
    return <ModalAcessoNegado open />;
  }

  if (!authenticated && isPrivate) {
    return <Redirect to="/" />;
  }

  return <Route path={path} render={render} {...rest} />;
};

export default RouteWrapper;
