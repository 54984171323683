import Axios from "./Axios";

const pagamentosService = {
  listarPagamentos: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null,
    id_fornecedor = null,
    data_inicial = null,
    data_final = null,
    status = null
  ) => {
    const response = await Axios.get("/lista-pagamento", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
        id_fornecedor,
        data_inicial,
        data_final,
        status,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  removerPagamento: async (id) => {
    const response = await Axios.get(`/remove-pagamento/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  consultaPagamento: async (id) => {
    const response = await Axios.get(`/consulta-pagamento/${id}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selecionaCategoriaPagamento: async (id) => {
    const response = await Axios.get(
      `/seleciona-categoria-pagamento?id_empresa=${id}`,
      {}
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cadastraPagamento: async (
    id_empresa,
    arquivo,
    descricao,
    id_categoria,
    data_vencimento,
    valor,
    id_fornecedor,
    parcelas,
    id_nota_fiscal_produto_entrada,
    data_pagamento,
    id_conta_bancaria,
    valor_pagamento,
    conta_paga,
    numero_nota,
    data_emissao,
    codigo_de_barras
  ) => {
    const response = await Axios.post("cadastra-pagamento", {
      id_empresa: id_empresa,
      arquivo: arquivo,
      descricao: descricao,
      id_categoria: id_categoria,
      data_vencimento: data_vencimento,
      valor: valor,
      id_fornecedor: id_fornecedor,
      parcelas: parcelas,
      id_nota_fiscal_produto_entrada,
      data_pagamento,
      id_conta_bancaria,
      valor_pagamento,
      conta_paga,
      numero_nota,
      data_emissao,
      codigo_de_barras,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  atualizaPagamento: async ({
    idPagamento,
    idEmpresa,
    dataVencimento,
    idFornecedor,
    idCategoria,
    descricao,
    valor,
    dataRecebimento,
    dataEmissao,
    codigoBarras,
  }) => {
    const response = await Axios.post(`edita-pagamento/${idPagamento}`, {
      id_empresa: idEmpresa,
      data_referencia: dataVencimento,
      id_fornecedor: idFornecedor,
      id_categoria: idCategoria,
      valor: valor,
      descricao,
      data_pagamento: dataRecebimento,
      data_emissao: dataEmissao,
      codigo_de_barras: codigoBarras,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cadastraPagamentoExtrato: async (
    id_empresa,
    arquivo,
    descricao,
    id_categoria,
    data_vencimento,
    valor,
    id_fornecedor,
    id_centro_custo,
    id_conta_bancaria
  ) => {
    const response = await Axios.post("cadastra-pagamento-extrato", {
      id_empresa: id_empresa,
      arquivo: arquivo,
      descricao: descricao,
      id_categoria: id_categoria,
      data_vencimento: data_vencimento,
      valor: valor,
      id_fornecedor: id_fornecedor,
      id_centro_custo: id_centro_custo,
      id_conta_bancaria: id_conta_bancaria,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  registrarPagamento: async (
    idPagamento,
    data_pagamento,
    valor_pagamento,
    id_conta_bancaria,
    multa_juros,
    abatimento_desconto
  ) => {
    const response = await Axios.post(`/registra-pagamento/${idPagamento}`, {
      data_pagamento,
      valor_pagamento,
      id_conta_bancaria,
      multa_juros,
      abatimento_desconto,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cancelarPagamento: async (idPagamento) => {
    const response = await Axios.post(`/cancela-pagamento/${idPagamento}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  exportarRelatorio: async (
    order_by = null,
    order_by_type = null,
    search_term = null,
    id_empresa = null,
    id_fornecedor = null,
    data_inicial = null,
    data_final = null,
    status = null
  ) => {
    const response = await Axios.get("/exportar-pagamentos", {
      params: {
        order_by,
        order_by_type,
        search_term,
        id_empresa,
        id_fornecedor,
        data_inicial,
        data_final,
        status,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selecionaFornecedor: async (id) => {
    const response = await Axios.get(`/seleciona-fornecedor/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selecionaStatus: async () => {
    const response = await Axios.get(`/seleciona-status-recebimento`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  importarPagamento: async ({ arquivo, id_empresa }) => {
    const response = await Axios.post(`/importar-contas-a-pagar`, {
      arquivo,
      id_empresa,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  estornarPagamento: async (id_lancamento) => {
    const response = await Axios.post(`/estorna-pagamento/${id_lancamento}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};

export default pagamentosService;
