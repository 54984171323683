import React, { useState, useEffect } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import InputComponent from "../../Input";
import ButtonComponent from "../../ButtonComponent";
import SelectComponent from "../../SelectComponent";
import CallbackMessage from "../../CallbackMessage";
import validateFields from "../../../utils/validateFields";

function ModalIcmssn102({ open, onClose, data = null, callback = () => {}, listCstCsosn = [] }) {

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackErrorList, setCallbackErrorList] = useState([]);

  const [field, setField] = useState({
    origem: null,
    csosn: null,
  });

  const handleClose = (event, reason) => {
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
  };

  const handleSubmit = () => {
    console.log(field);

    const fieldsToValidate = [
      {
        label: "origem",
        value: field.origem,
      },
      {
        label: "csosn",
        value: field.csosn,
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      setCallbackErrorList(fieldsErrors);
      setCallbackShown(true);
      return;
    }
    callback(field)
  }

  useEffect(() => {
    let mounted = true;

    if (data) {
      if (mounted) {
        setField({
          origem: data.origem,
          csosn: data.csosn,
        })
      }
    }
    
    return () => mounted = false;
  }, [data])

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose} 
      className="modal-icms">
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>ICMSSN - ICMSSN102</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <div className="session-container-form icms-modal-expand">
          <Grid container direction="column" spacing={1}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  required
                  label="Origem"
                  tipo="numero"
                  value={field.origem}
                  handleInputChange={event => setField({ ...field, origem: event.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
              <SelectComponent
                  required
                  styleType="form"
                  label="CSOSN"
                  initialValue={listCstCsosn.find((item) => item.id === Number(field.csosn) ? listCstCsosn.find((item) => item.id === Number(field.csosn)) : null)}
                  callback={event => setField({ ...field, csosn: event })}
                  list={listCstCsosn}
                />
              </Grid>
            </Grid>            
          </Grid>
          <Grid container justify="flex-end" item>
              <ButtonComponent
                text="Salvar"
                onClick={() => handleSubmit()}
                className="btn-success zero-margin"
              />
            </Grid>
        </div>
      </div>
      <CallbackMessage
        open={callbackShown}
        errorList={callbackErrorList}
        duration={4000}
        message={'Erro!'}
        type={'error'}
        handleClose={handleClose}
      />
    </Dialog>
  );
}

export default ModalIcmssn102;
