import React, { createContext } from "react";
import useCompanyStoraged from "../hooks/useCompanyStoraged";

const CompanyContext = createContext();

function CompanyProvider({ children }) {
  const { getEmpresaPagina, setEmpresaPagina } = useCompanyStoraged();

  return (
    <CompanyContext.Provider value={{ getEmpresaPagina, setEmpresaPagina }}>
      {children}
    </CompanyContext.Provider>
  );
}

export { CompanyContext, CompanyProvider };
