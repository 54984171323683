import React from "react";
import "./styles.css";

function Loader({
  width = "4",
  heigth = "4",
  thickness = "6",
  color = "#FFFFFF",
}) {
  return (
    <div className="login-loader-div">
      <div
        className="login-loader"
        style={{
          width: `${width}px`,
          height: `${heigth}px`,
          border: `${thickness}px solid rgba(0, 0, 0, 0.1)`,
          borderLeftColor: color,
        }}
      />
    </div>
  );
}

export default Loader;
