import React, { useState } from "react";
import { DialogTitle, Dialog, DialogContent, Grid } from "@material-ui/core";
import Button from "../ButtonComponent";
import Input from "../Input";
import { FiX } from "react-icons/fi";
import Icon from "../Icon";

import "./style.scss";
import CallbackMessage from "../CallbackMessage";
import validateFields from "../../utils/validateFields";
import usuariosService from "../../services/usuariosService";

export default function ModalAlterarSenha({
  show,
  toggle,
  idUsuarioChangePassword,
  setLoading,
}) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  function handlePasswordVisibility(type = "password") {
    if (type === "password") {
      setPasswordVisible(!passwordVisible);
    } else if (type === "confirm") {
      setPasswordConfirmVisible(!passwordConfirmVisible);
    }
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") setShowMessage(false);

    if (reason === "timeout") setShowMessage(false);

    setShowMessage(false);
  };

  function closeModal() {
    toggle(false);
  }

  const submitChangePassword = async () => {
    setLoading(true);

    const fieldsToValidate = [
      {
        label: "password",
        value: password,
      },
      {
        label: "rePassword",
        value: rePassword,
      },
    ];
    const fieldsErrors = validateFields(fieldsToValidate);

    if (password !== rePassword) {
      fieldsErrors.push("As senhas não são iguais");
    }

    if (password.length < 6) {
      fieldsErrors.push("A senha deve ter pelo menos 6 caracteres");
    }
  
    if (!/[A-Z]/.test(password)) {
      fieldsErrors.push("A senha deve conter pelo menos uma letra maiúscula");
    }
  
    if (!/\d/.test(password)) {
      fieldsErrors.push("A senha deve conter pelo menos um número");
    }
  
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      fieldsErrors.push("A senha deve conter pelo menos um caractere especial");
    }

    if (fieldsErrors.length !== 0) {
      setErrors(fieldsErrors);
      setShowMessage(true);
      setLoading(false);
      return;
    }
    const response = await usuariosService.alterarSenha(
      idUsuarioChangePassword,
      password
    );

    if (response.status === 201 || response.status === 200) {
      toggle(false);
      setErrors([]);
      setSuccessMessage("Senha alterada");
      setShowMessage(true);
      setLoading(false);
    } else if (response.status === 500) {
      setErrors(["Erro interno no servidor. Por favor, contate o suporte"]);
      setShowMessage(true);
      setLoading(false);
      return;
    } else if (response?.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    } else {
      setLoading(false);
      setShowMessage(true);
      setErrors(Object.values(response?.data?.error));
      return;
    }
  };

  const validatePassword = (value) => {
    let message = "";
  
    if (value.length < 6) {
      message = "A senha deve ter pelo menos 6 caracteres.";
    } else if (!/[A-Z]/.test(value)) {
      message = "A senha deve conter pelo menos uma letra maiúscula.";
    } else if (!/\d/.test(value)) {
      message = "A senha deve conter pelo menos um número.";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      message = "A senha deve conter pelo menos um caractere especial.";
    }
  
    setPasswordMessage(message);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  return (
    <>
      <Dialog open={show} className="modalAlteraSenha">
        <div className="modal-title-area">
          <DialogTitle>
            <span className="modal-title">ALTERAR SENHA</span>
          </DialogTitle>
          <FiX
            size={24}
            color="#EF4066"
            className="closeModal"
            onClick={() => {
              closeModal();
            }}
          />
        </div>
        <DialogContent className="modal-content">
          <div className="container-formulario">
            <div className="password-input password-input-button">
              <Input
                nome="password"
                label="Senha"
                tipo="text"
                type={passwordVisible ? "text" : "password"}
                required={true}
                handleInputChange={handlePasswordChange}
                value={password}
                error={passwordMessage !== ""}
                message={passwordMessage}
              >
                <button
                  onClick={() => handlePasswordVisibility()}
                  className="password-icon"
                  type="button"
                >
                  {passwordVisible ? (
                    <Icon name="open-eye" />
                  ) : (
                    <Icon name="closed-eye" />
                  )}
                </button>
              </Input>
              {passwordMessage && <div className="password-message">{passwordMessage}</div>}
            </div>
            <div className="confirm-password-input">
              <Input
                nome="confirmPassword"
                label="Confirmar senha"
                tipo="text"
                type={passwordConfirmVisible ? "text" : "password"}
                required={true}
                handleInputChange={(event) => setRePassword(event.target.value)}
              />
              <button
                onClick={() => handlePasswordVisibility("confirm")}
                className="password-icon"
                type="button"
              >
                {passwordConfirmVisible ? (
                  <Icon name="open-eye" />
                ) : (
                  <Icon name="closed-eye" />
                )}
              </button>
            </div>

            <Button
              tipo="button"
              text="Salvar"
              className="btn-success zero-margin"
              onClick={submitChangePassword}
            />
          </div>

          <CallbackMessage
            open={showMessage}
            type={`${errors.length === 0 ? "success" : "error"}`}
            message={`${errors.length === 0 ? successMessage : "Erro!"}`}
            errorList={errors}
            handleClose={handleClose}
            duration={errors.length === 0 ? 2000 : 6000}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
