import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import JoditEditor from "jodit-react";
import ReactTooltip from "react-tooltip";
import InputComponent from "../Input";
import TextArea from "../Textarea";
import ButtonComponent from "../ButtonComponent";
import ModalLoginExpirado from "../ModalLoginExpirado";
import CallbackMessage from "../CallbackMessage";
import validateFields from "../../utils/validateFields";

import styles from "./styles.module.scss";
import "./styles.scss";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  minHeight: 150,
  toolbar: false,
  showPlaceholder: false,
};

export function ModalEnviarEmail({
  open,
  onClose,
  callbackShown = false,
  setCallbackShown = () => {},
  callbackErrorList = [],
  setCallbackErrorList = () => {},
}) {
  const [emailData, setEmailData] = useState({
    para: null,
    cco: null,
    assunto: null,
    mensagem: null,
  });
  const editorAssunto = useRef(null);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  // const [callbackShown, setCallbackShown] = useState(false);
  // const [callbackErrorList, setCallbackErrorList] = useState("");

  async function handleSubmit() {
    let hasErrors = false;
    console.log(emailData);
    const fieldsToValidate = [
      {
        label: "emailpara",
        value: emailData?.para,
      },
      {
        label: "emailassunto",
        value: emailData?.assunto,
      },
      {
        label: "emailmensagem",
        value: emailData?.mensagem?.replace(/(<([^>]+)>)/gi, ""),
      },
    ];
    console.log(emailData?.mensagem?.replace(/(<([^>]+)>)/gi, ""));
    console.log(fieldsToValidate);
    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      hasErrors = true;
      setCallbackErrorList(fieldsErrors);
      setCallbackShown(true);
    } else {
      setCallbackErrorList([]);
      setCallbackShown(false);
    }

    if (hasErrors) return;

    onClose(emailData);
  }

  const handleCloseModal = async () => {
    setEmailData({
      para: null,
      cco: null,
      assunto: null,
      mensagem: null,
    });
    onClose();
  };

  const handleClose = () => {
    console.log("there");
    setCallbackShown(false);
  };

  useEffect(() => {
    if (!open) {
      setEmailData({
        para: null,
        cco: null,
        assunto: null,
        mensagem: null,
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={handleCloseModal}
      dialog
      className="modal-enviar-email"
    >
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>Envio de Relatórios</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className="content">
          <form className="session-container-form">
            <Grid container direction="column" spacing={2}>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <ReactTooltip id="show-label" type="error">
                    <span>Concluído com erros</span>
                  </ReactTooltip>
                  <InputComponent
                    nome="para"
                    label="Para"
                    type="email"
                    labelSuffix={{
                      icon: "info",
                      title:
                        "Para adicionar mais de um e-mail, utilize ; para a separação",
                    }}
                    value={emailData.para}
                    required
                    handleInputChange={(event) => {
                      console.log({
                        ...emailData,
                        para: event?.target?.value,
                      });
                      setEmailData({
                        ...emailData,
                        para: event?.target?.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <InputComponent
                    nome="cco"
                    label="CCo"
                    type="email"
                    labelSuffix={{
                      icon: "info",
                      title:
                        "Para adicionar mais de um e-mail, utilize ; para a separação",
                    }}
                    value={emailData.cco}
                    handleInputChange={(event) => {
                      console.log(event?.target?.value);
                      setEmailData({
                        ...emailData,
                        cco: event?.target?.value || null,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <InputComponent
                    nome="assunto"
                    label="Assunto"
                    required
                    value={emailData.assunto}
                    handleInputChange={(event) => {
                      setEmailData({
                        ...emailData,
                        assunto: event?.target?.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label className={styles.inputLabel}>Mensagem *</label>
                  <JoditEditor
                    ref={editorAssunto}
                    value={emailData.mensagem}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => {
                      setEmailData({
                        ...emailData,
                        mensagem: newContent,
                      });
                    }} // preferred to use only this option to update the content for performance reasons
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <span className="required-text">* Campos obrigatórios</span>
                </Grid> */}
                <Grid container justifyContent="flex-end" item>
                  <Grid xs={12} container md={4} justifyContent="flex-end" item>
                    <ButtonComponent
                      text="Cancelar"
                      className="btn-secondary-strong default-outline btnSize btn-modal-envia-email"
                      onClick={handleCloseModal}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} container>
                    <ButtonComponent
                      text="Salvar"
                      onClick={handleSubmit}
                      className="btn-success zero-margin btnSize btn-modal-envia-email"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </DialogContent>
      <CallbackMessage
        open={callbackShown}
        type={callbackErrorList?.length > 0 ? "error" : "success"}
        message={callbackErrorList?.length > 0 ? "Erro" : "Sucesso"}
        errorList={callbackErrorList}
        handleClose={handleClose}
        duration={
          callbackErrorList?.length > 0
            ? callbackErrorList?.length * 3000
            : 3000
        }
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
