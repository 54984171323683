import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import SelectComponent from "../../../components/SelectComponent";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import ButtonComponent from "../../../components/ButtonComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";

import validateFields from "../../../utils/validateFields";

import "./styles.css";
import SmallScreen from "../../../utils/smallScreen";
import Input from "../../../components/Input";
import InputCheck from "../../../components/InputCheckLeft";
import gruposCategoriaService from "../../../services/gruposCategoriaService";
import { useParams } from "react-router-dom";
import useCompany from "../../../hooks/useCompany";

export function CadastroGrupoCategoria() {
  const isSmallScreen = SmallScreen();
  const history = useHistory();
  const params = useParams();
  const { id } = params;

  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
  } = useCompany("grupos");

  const {
    cadastrarGrupo,
    consultaGrupo,
    atualizarGrupo,
    consultaSugestaoCodigoGrupo,
  } = gruposCategoriaService;

  const [nome, setNome] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [ativo, setAtivo] = useState(0);

  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState("");
  const [callbackDuration, setCallbackDuration] = useState(6000);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/grupos");
    }
  }

  async function handleSubmit() {
    console.log("submit");
    setLoading(true);
    let hasErrors = false;

    const fieldsToValidate = [
      {
        label: "empresa",
        value: empresaSelecionada,
      },
      {
        label: "nomedogrupo",
        value: nome,
      },
      {
        label: "codigo",
        value: codigo,
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      hasErrors = true;
      setLoading(false);
      setCallbackType("error");
      setCallbackErrorList(fieldsErrors);
      setCallbackMessage("Erro!");
      setCallbackShown(true);
    }

    if (hasErrors) return;

    let result = null;
    if (id) {
      result = await atualizarGrupo({
        id,
        codigo,
        idEmpresa: empresaSelecionada,
        nome,
        status: ativo,
      });
    } else {
      result = await cadastrarGrupo({
        codigo,
        idEmpresa: empresaSelecionada,
        nome,
        status: ativo,
      });
    }
    console.log(result);
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.status !== 200 && result.status !== 201) {
      setLoading(false);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackShown(true);
      console.log(typeof result.data.error);
      if (typeof result.data.error === "string") {
        if (result.data.msg && typeof result.data.msg === "string") {
          setCallbackErrorList([result.data.msg]);
        } else {
          setCallbackErrorList([result.data.error]);
        }
      } else {
        setCallbackErrorList(Object.values(result.data.error));
      }
      return;
    }
    setLoading(false);
    setCallbackShown(true);
    setCallbackMessage(
      result?.data?.message ? result.data.message : "Erro ao cadastrar grupo"
    );
    setCallbackType("success");
    setCallbackDuration(2000);
  }

  const loadGrupo = useCallback(async () => {
    try {
      const response = await consultaGrupo(id);
      if (response.status === 200) {
        console.log({ data: response.data });
        const grupo = response.data;
        setAtivo(grupo?.status || 0);
        setNome(grupo?.nome || null);
        setCodigo(grupo?.codigo ? grupo.codigo.toString() : null);
        setEmpresaSelecionada(grupo?.id_empresa || null);
      }
    } catch (error) {
      console.log({ error });
    }
  }, [id]);

  const loadSugestaoCodigoGrupo = useCallback(
    async (idEmpresa) => {
      try {
        const response = await consultaSugestaoCodigoGrupo(idEmpresa);
        if (response.status === 200) {
          setCodigo(
            response?.data?.codigo ? response.data.codigo.toString() : null
          );
        }
      } catch (error) {
        console.log({ error });
      }
    },
    [id]
  );

  const handleSelectEmpresa = (idEmpresa) => {
    setEmpresaSelecionada(idEmpresa);
    if (!id) {
      loadSugestaoCodigoGrupo(idEmpresa);
    }
  };

  useEffect(() => {
    if (id) {
      loadGrupo();
    } else {
      const empresaStorage = getEmpresaPagina("grupos");
      if (empresaStorage) {
        handleSelectEmpresa(empresaStorage);
      }
    }
  }, []);

  return (
    <>
      <form className="session-container-form">
        <div className="session-container">
          <span className="session-container-header">
            {loading && <OverlayLoading />}
            <div className={`${isSmallScreen ? "" : "p-horizontal"}`}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SelectComponent
                    styleType="form"
                    label="Empresa"
                    required
                    initialValue={
                      empresaSelecionada
                        ? listEmpresas.find(
                            (item) => item.id === empresaSelecionada
                          )?.nome
                        : ""
                    }
                    title="Selecione uma empresa"
                    list={listEmpresas}
                    callback={(id) => handleSelectEmpresa(id)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    required
                    label="Nome do grupo"
                    value={nome}
                    handleInputChange={(event) => setNome(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    required
                    label="Código"
                    value={codigo}
                    handleInputChange={(event) => setCodigo(event.target.value)}
                    disabled={!!id}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputCheck
                    id="ativo"
                    title="Ativo"
                    nome="ativo"
                    onInputChange={(value) => setAtivo(value ? 1 : 0)}
                    checkedValue={ativo}
                  />
                </Grid>
                <Grid item>
                  <span className="required-text">* Campos obrigatórios</span>
                </Grid>
              </Grid>
              <div style={{ height: 40 }} />
            </div>
          </span>
        </div>
      </form>
      <div style={{ height: 40 }} />
      <Grid item container justifyContent="flex-end">
        <ButtonComponent
          text="Salvar"
          className="btn-success zero-margin"
          onClick={handleSubmit}
        />
      </Grid>
      {callbackShown && (
        <CallbackMessage
          open={callbackShown}
          type={callbackType}
          handleClose={handleClose}
          message={callbackMessage}
          duration={callbackDuration}
          errorList={callbackErrorList}
        />
      )}
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
