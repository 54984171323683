import Axios from "./Axios";

const selectService = {
  getCNAE: async () => {
    const result = await Axios.get("/seleciona-cnae")
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  getNatureza: async () => {
    const result = await Axios.get("/seleciona-natureza-juridica")
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  selecionaCor: async () => {
    const response = await Axios.get("/seleciona-cor")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaEstadoCivil: async () => {
    const response = await Axios.get("/seleciona-estadocivil")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaSexo: async () => {
    const response = await Axios.get("/seleciona-sexo")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaEscolaridade: async () => {
    const response = await Axios.get("/seleciona-grauinstrucao")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaPais: async () => {
    const response = await Axios.get("/seleciona-pais")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaNacionalidade: async () => {
    const response = await Axios.get("/seleciona-nacionalidade")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaTipoIdentidade: async () => {
    const response = await Axios.get("/seleciona-tipoidentidade")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaTipoCnh: async () => {
    const response = await Axios.get("/seleciona-categoriacnh")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaTipoConta: async () => {
    const response = await Axios.get("/seleciona-tipocontabanco")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaTipoDeficiencia: async () => {
    const response = await Axios.get("/seleciona-tipodeficiencia")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaParentesco: async () => {
    const response = await Axios.get("/seleciona-parentesco")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaAtestadoOcupacional: async () => {
    const response = await Axios.get("/seleciona-resultadoatestadoocupacional")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaDepartamento: async () => {
    const response = await Axios.get("/seleciona-departamento-funcionario")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaTipoHorario: async () => {
    const response = await Axios.get("/seleciona-tipo-horario")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaTipoAdmissao: async () => {
    const response = await Axios.get("/seleciona-tipoadmissao")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaVinculoTrabalhista: async () => {
    const response = await Axios.get("/seleciona-vinculotrabalhista")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaCategoriaFuncionario: async () => {
    const response = await Axios.get("/seleciona-categoriafuncionario")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaFuncaoFuncionario: async (searchTerm) => {
    const response = await Axios.get("/seleciona-funcaofuncionario", {
      params: {
        search_term: searchTerm,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaFormaPagamento: async () => {
    const response = await Axios.get("/seleciona-formapagamentosalario")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaFormaPagamentoStatus: async () => {
    const response = await Axios.get("/seleciona-forma-pagamento")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaIcms: async () => {
    const response = Axios.get("/seleciona-icms-produto")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaStatusPlano: async () => {
    const response = await Axios.get("/seleciona-status-plano")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaPeriodoPlano: async () => {
    const response = await Axios.get("/seleciona-periodo-plano")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaServico: async () => {
    const response = await Axios.get("/seleciona-servico")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaDeterminacaoBci: async () => {
    const response = await Axios.get("/seleciona-determinacao-bci-produto")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaTipoEmpresa: async () => {
    const response = await Axios.get("/seleciona-tipo-empresa")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaGrupoAtuacao: async () => {
    const response = await Axios.get("/seleciona-grupo-atuacao")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selectFormaPagamento: async () => {
    const response = await Axios.get(`/enum-indicador-forma-pagamento`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selectMeioPagamento: async () => {
    const response = await Axios.get(`/enum-meio-pagamento`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selectStatusEmpresa: async () => {
    const response = await Axios.get("/seleciona-status-empresa")
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selectStatusFatura: async () => {
    const response = await Axios.get("/seleciona-status-fatura")
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selectTipoServico: async () => {
    const response = await Axios.get("/seleciona-tipo-servico")
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selectCstCsosn: async (id) => {
    const response = await Axios.get(`/seleciona-cst-csosn/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selecionaNcm: async () => {
    const response = await Axios.get("/seleciona-ncm")
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selecionaCest: async (ncm) => {
    const response = await Axios.get(`/seleciona-cest/${ncm}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selecionaCategoriaPadrao: async () => {
    const response = await Axios.get("/lista-categoria-padrao-todas")
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selecionaTransportadora: async (idEmpresa) => {
    const response = await Axios.get(`/seleciona-transportadora/${idEmpresa}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selecionaMeses: async () => {
    const response = await Axios.get(`/seleciona-mes`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selecionaFormaPagamentoDp: async () => {
    const response = await Axios.get("/seleciona-dp-tipo-forma-pagamento")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaTipoDescontoDp: async () => {
    const response = await Axios.get("/seleciona-dp-tipo-desconto")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaCodigosServico: async (id_empresa) => {
    const response = await Axios.get(`/select-servicos-prefeitura`, {
      params: {
        id_empresa,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaAnos: async () => {
    const response = await Axios.get(`/seleciona-ano`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selecionaTipoContaBancariaDp: async () => {
    const response = await Axios.get("/seleciona-dp-tipo-conta-bancaria")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaOrgaoEmissorDp: async () => {
    const response = await Axios.get("/seleciona-dp-orgao-emissor")
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaFuncionariosDp: async (idEmpresa) => {
    const response = await Axios.get(`/seleciona-dp-funcionario/${idEmpresa}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaDocumentoPreDemissaoDp: async () => {
    const response = await Axios.get(`/seleciona-dp-pre-demissao-documento`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaEmissorPreDemissaoDp: async () => {
    const response = await Axios.get(`/seleciona-dp-pre-demissao-emissor`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaMotivoPreDemissaoDp: async () => {
    const response = await Axios.get(`/seleciona-dp-pre-demissao-motivo`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaEventoFolhaDp: async () => {
    const response = await Axios.get(`/seleciona-dp-eventos`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaTipoMovimentoDp: async () => {
    const response = await Axios.get(`/seleciona-dp-tipo-movimento`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaAnoOrcamento: async () => {
    const response = await Axios.get(`/seleciona-ano-orcamento`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaOrigemFomaPagamento: async () => {
    const response = await Axios.get(`/seleciona-origem-forma-pagamento`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaFormaPagamentoPorOrigem: async (idOrigem) => {
    const response = await Axios.get(`/seleciona-forma-pagamento/${idOrigem}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaGrupoCategoria: async (idEmpresa) => {
    const response = await Axios.get(`/seleciona-grupo-empresa/${idEmpresa}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaCategoriaEmpresa: async (idEmpresa) => {
    const response = await Axios.get(
      `/seleciona-categoria-empresa/${idEmpresa}`
    )
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaCategoria: async (id_empresa) => {
    const response = await Axios.get(`/seleciona-categoria/${id_empresa}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaGrupoCategoria: async (idEmpresa) => {
    const response = await Axios.get(`/seleciona-grupo-empresa/${idEmpresa}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaCategoriaEmpresa: async (idEmpresa) => {
    const response = await Axios.get(
      `/seleciona-categoria-empresa/${idEmpresa}`
    )
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaGrupoCategoria: async (idEmpresa) => {
    const response = await Axios.get(`/seleciona-grupo-empresa/${idEmpresa}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);
    return response;
  },
  selecionaCategoriaEmpresa: async (idEmpresa) => {
    const response = await Axios.get(
      `/seleciona-categoria-empresa/${idEmpresa}`
    )
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaStatusPreDemissao: async () => {
    const response = await Axios.get(
      `/lista-status-pre-demissao`
    )
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
};

export default selectService;
