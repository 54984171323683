/* eslint-disable no-loop-func */
import React, { useState, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import SelectComponent from "../../../components/SelectComponent";
import Switch from "../../../components/Switch";
import ButtonComponent from "../../../components/ButtonComponent";
import FileInput from "../../../components/InputFile";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import Input from "../../../components/Input";
import Icon from "../../../components/Icon";

import validateFields from "../../../utils/validateFields";

import servicosService from "../../../services/servicosService";
import perfilTributarioService from "../../../services/perfilTributarioService";
import empresasService from "../../../services/empresasService";

import "./styles.scss";
import { InfoComponent } from "../../../components/InfoComponent";

function PerfilTributario() {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const perfilAcesso = window.localStorage.getItem("perfil_acesso");

  const [empresa, setEmpresa] = useState("");
  const [opcaoSimples, setOpcaoSimples] = useState(null);
  const [regimeTributario, setRegimeTributario] = useState("");
  const [regimeEspecial, setRegimeEspecial] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [usuarioPrefeitura, setUsuarioPrefeitura] = useState("");
  const [senhaPrefeitura, setSenhaPrefeitura] = useState("");

  const [pis, setPis] = useState("");
  const [cofins, setCofins] = useState("");
  const [csll, setCsll] = useState("");
  const [irpj, setIrpj] = useState("");
  const [inss, setInss] = useState("");

  const [ultimaNf, setUltimaNf] = useState(null);
  const [loteNf, setLoteNf] = useState(null);
  const [serieNf, setSerieNf] = useState(null);
  const [logo, setLogo] = useState("");

  const [chaveAutorizacao, setChaveAutorizacao] = useState(null);

  const [ultimaNfce, setUltimaNfce] = useState(null);
  const [serieNfce, setSerieNfce] = useState(null);
  const [idTokenNfce, setIdTokenNfce] = useState(null);
  const [idTokenNfceHomologa, setIdTokenNfceHomologa] = useState(null);
  const [tokenCscNfce, setTokenCscNfce] = useState(null);
  const [tokenCscNfceHomologa, setTokenCscNfceHomologa] = useState(null);

  const [loading, setLoading] = useState(false);
  const [regimeOptions, setRegimeOptions] = useState([]);
  const [tributoEspecialOptions, setTributoEspecialOptions] = useState([]);
  const [
    qualificacaoPessoaJuridicaOptions,
    setQualificacaoPessoaJuridicaOptions,
  ] = useState([]);

  const [tributoOptions, setTributoOptions] = useState([]);

  const [cadastraPerfil, setCadastraPerfil] = useState(false);

  const [utilizaNfe, setUtilizaNfe] = useState(false);
  const [grupoTransporteReboque, setGrupoTransporteReboque] = useState(false);
  const [grupoVolumeCargaLacres, setGrupoVolumeCargaLacres] = useState(false);
  const [retencaoIcms, setRetencaoIcms] = useState(false);
  const [utilizaNfse, setUtilizaNfse] = useState(false);
  const [utilizaNfce, setUtilizaNfce] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [showOpcaoPeloSimples, setShowOpcaoPeloSimples] = useState(false);
  const [showTributos, setShowTributos] = useState(false);
  const [showQualificacoesPessoaJuridica, setShowQualificacoesPessoaJuridica] =
    useState(false);
  const [qualificacaoPessoaJuridica, setQualificacaoPessoaJuridica] =
    useState(null);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const simpleOptions = [
    {
      id: 1,
      nome: "Sim",
    },
    {
      id: 2,
      nome: "Não",
    },
    {
      id: 3,
      nome: "ME",
    },
    {
      id: 4,
      nome: "EPP",
    },
    {
      id: 5,
      nome: "Demais",
    },
  ];

  const optionsShowOpcaoPeloSimples = [1, 3, 4];

  function onlyNumbers(event) {
    return event.target.value.match(/^[0-9]*\.?[0-9]*$/);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    let hasErrors = false;

    let fieldsToValidate = [
      {
        label: "pis",
        value: pis,
      },
      {
        label: "cofins",
        value: cofins,
      },
      {
        label: "csll",
        value: csll,
      },
      {
        label: "irpj",
        value: irpj,
      },
      {
        label: "inss",
        value: inss,
      },
    ];

    if ([1, 3, 4].includes(regimeTributario)) {
      fieldsToValidate.push({
        label: "opcaopelosimples",
        value: opcaoSimples,
      });
    }

    if (
      ultimaNfce === "" ||
      ultimaNfce === null ||
      serieNfce === "" ||
      serieNfce === null
    ) {
      if (utilizaNfse) {
        fieldsToValidate.push(
          {
            label: "numerodaultimanf",
            value: ultimaNf,
          },
          {
            label: "numerodolotedanf",
            value: loteNf,
          },
          {
            label: "seriedanf",
            value: serieNf,
          }
        );
      }
    }

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      setLoading(false);
      hasErrors = true;
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(fieldsErrors);
    }

    if (hasErrors) return;

    let responsePerfil;

    if (!cadastraPerfil)
      responsePerfil = await perfilTributarioService.atualizaPerfilTributario(
        id,
        opcaoSimples,
        regimeTributario,
        regimeEspecial,
        usuarioPrefeitura,
        senhaPrefeitura,
        pis
          .substring(0, pis.length - 1)
          .replace(",", ".")
          .replace("%", ""),
        cofins
          .substring(0, cofins.length - 1)
          .replace(",", ".")
          .replace("%", ""),
        csll
          .substring(0, csll.length - 1)
          .replace(",", ".")
          .replace("%", ""),
        irpj
          .substring(0, irpj.length - 1)
          .replace(",", ".")
          .replace("%", ""),
        inss
          .substring(0, inss.length - 1)
          .replace(",", ".")
          .replace("%", ""),
        ultimaNf,
        loteNf,
        serieNf,
        logo,
        ultimaNfce,
        tokenCscNfce,
        tokenCscNfceHomologa,
        serieNfce,
        idTokenNfce,
        idTokenNfceHomologa,
        chaveAutorizacao,
        utilizaNfe ? 1 : 0,
        utilizaNfse ? 1 : 0,
        utilizaNfce ? 1 : 0,
        grupoTransporteReboque ? 1 : 0,
        grupoVolumeCargaLacres ? 1 : 0,
        retencaoIcms ? 1 : 0,
        qualificacaoPessoaJuridica
      );
    else
      responsePerfil = await perfilTributarioService.sendPerfilTributario(
        id,
        opcaoSimples,
        regimeTributario,
        regimeEspecial,
        usuarioPrefeitura,
        senhaPrefeitura,
        pis
          .substring(0, pis.length - 1)
          .replace(",", ".")
          .replace("%", ""),
        cofins
          .substring(0, cofins.length - 1)
          .replace(",", ".")
          .replace("%", ""),
        csll
          .substring(0, csll.length - 1)
          .replace(",", ".")
          .replace("%", ""),
        irpj
          .substring(0, irpj.length - 1)
          .replace(",", ".")
          .replace("%", ""),
        inss
          .substring(0, inss.length - 1)
          .replace(",", ".")
          .replace("%", ""),
        ultimaNf,
        loteNf,
        serieNf,
        logo,
        ultimaNfce,
        tokenCscNfce,
        tokenCscNfceHomologa,
        serieNfce,
        idTokenNfce,
        idTokenNfceHomologa,
        chaveAutorizacao,
        utilizaNfe ? 1 : 0,
        utilizaNfse ? 1 : 0,
        utilizaNfce ? 1 : 0,
        grupoTransporteReboque ? 1 : 0,
        grupoVolumeCargaLacres ? 1 : 0,
        retencaoIcms ? 1 : 0,
        qualificacaoPessoaJuridica
      );
    if (responsePerfil.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (responsePerfil.status !== 200 && responsePerfil.status !== 201) {
      setCallbackShown(true);
      setLoading(false);
      setCallbackType("error");
      setCallbackMessage("Erro!");

      if (responsePerfil.data.error) {
        let error = [];
        Object.keys(responsePerfil.data?.error).map((a) => {
          if (typeof responsePerfil.data?.error[a] === "object") {
            Object.keys(responsePerfil.data?.error[a]).map((b) => {
              error.push(responsePerfil.data?.error[a][b]);
            });
          } else {
            error.push(
              Object.values(responsePerfil?.data?.error).flat(Infinity)
            );
          }
        });
        setCallbackErrorList(error);
      } else {
        setCallbackErrorList(["Ocorreu um erro."]);
      }
      return;
    }

    setLoading(false);
    setCallbackType("success");
    setCallbackMessage("Perfil tributário atualizado com sucesso");
    setCallbackDuration(2000);
    setCallbackShown(true);
    window.scrollTo(0, 0);
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/empresas");
    }
  }

  const loadPerfil = useCallback(async () => {
    const response = await perfilTributarioService.consultaPerfil(id);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status === 200) {
      let { data } = response;

      setEmpresa(data.cnpj + " - " + data.razao_social);
      setInss(`${data.inss}%`.replace(".", ","));
      setIrpj(`${data.irpj}%`.replace(".", ","));
      setLoteNf(data.numero_lote_nf);
      setOpcaoSimples(data.cod_optante_simples);
      setRegimeEspecial(data.cod_regime_tributario_especial);
      setUsuarioPrefeitura(data.usuario_prefeitura);
      setSenhaPrefeitura(data.senha_prefeitura);
      setPis(`${data.pis}%`.replace(".", ","));
      setCofins(`${data.cofins}%`.replace(".", ","));
      setCsll(`${data.csll}%`.replace(".", ","));
      setUltimaNf(data.numero_ultima_nf);
      setQualificacaoPessoaJuridica(
        data.id_qualificacao ? data.id_qualificacao : null
      );
      setSerieNf(data.serie_nf);
      setUltimaNfce(data.numero_ultima_nfce);
      setSerieNfce(data.serie_nfce);
      setTokenCscNfce(data.token_csc_nfce);
      setTokenCscNfceHomologa(data.token_csc_hml_nfce);
      setIdTokenNfce(data.id_token_nfce);
      setIdTokenNfceHomologa(data.id_token_hml_nfce);
      setChaveAutorizacao(data?.chave_autorizacao_nfse);
      setUtilizaNfe(data.modulo_nfe === 1);
      setUtilizaNfse(data.modulo_nfse === 1);
      setUtilizaNfce(data.modulo_nfce === 1);
      setGrupoTransporteReboque(data.grupo_transporte_reboque === 1);
      setGrupoVolumeCargaLacres(data.grupo_volume_carga_lacres === 1);
      setRetencaoIcms(data.retencao_icms_transporte === 1);
      setShowTributos(true);
      loadDependenciasRegimeTributario(data.cod_regime_tributario);
    } else if (response.status === 400) {
      const newEmpresa = await empresasService.consultaEmpresa(id);
      if (newEmpresa.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }

      setEmpresa(newEmpresa.data.cnpj + " - " + newEmpresa.data.razao_social);
      setCadastraPerfil(true);
    }
  }, [setEmpresa, id]);

  const initialValues = useCallback(async () => {
    setLoading(true);
    const tribEspecial = await perfilTributarioService.getTributarioEspecial();
    const regime = await perfilTributarioService.getTributario();
    const tipoTributacao = await servicosService.selecionaTipoTributacao();
    if (
      tribEspecial.status === 401 ||
      regime.status === 401 ||
      tipoTributacao.status === 401
    ) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (tribEspecial.status === 200) {
      setTributoEspecialOptions(tribEspecial.data);
    }
    if (regime.status === 200) {
      setRegimeOptions(regime.data);
    }
    if (tipoTributacao.status === 200) {
      setTributoOptions(tipoTributacao.data);
    }
    setLoading(false);
  }, [setRegimeOptions, setTributoEspecialOptions, setTributoOptions]);

  const getQualificacaoPessoaJuridica = useCallback(
    async (idRegimeTributario) => {
      setLoading(true);
      const response = await perfilTributarioService.getConfiguracaoRegime(
        idRegimeTributario
      );
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }

      setLoading(false);
      if (response.status === 200) {
        setQualificacaoPessoaJuridicaOptions(response?.data?.qualificacoes);
        return response?.data;
      } else {
        setQualificacaoPessoaJuridicaOptions([]);
      }

      return false;
    },
    [setQualificacaoPessoaJuridicaOptions]
  );

  const loadDependenciasRegimeTributario = async (event) => {
    setRegimeTributario(event);
    if (optionsShowOpcaoPeloSimples.find((opcao) => event === opcao)) {
      setShowOpcaoPeloSimples(true);
    } else {
      setShowOpcaoPeloSimples(false);
    }
    if (event === 3) {
      const data = await getQualificacaoPessoaJuridica(event);
      if (data?.qualificacoes?.length > 0) {
        setShowQualificacoesPessoaJuridica(true);
      } else {
        setShowQualificacoesPessoaJuridica(false);
      }
    }
  };

  const handleChangeRegimeTributario = async (event) => {
    setRegimeTributario(event);
    if (optionsShowOpcaoPeloSimples.find((opcao) => event === opcao)) {
      setShowOpcaoPeloSimples(true);
    } else {
      setShowOpcaoPeloSimples(false);
    }
    const data = await getQualificacaoPessoaJuridica(event);
    if (data?.qualificacoes?.length > 0) {
      setShowQualificacoesPessoaJuridica(true);
    } else {
      setShowQualificacoesPessoaJuridica(false);
    }
    const impostos = data?.impostos ? data?.impostos : false;
    setPis(impostos ? `${impostos.pis}%`.replace(".", ",") : "0%");
    setCofins(impostos ? `${impostos.cofins}%`.replace(".", ",") : "0%");
    setCsll(impostos ? `${impostos.csll}%`.replace(".", ",") : "0%");
    setIrpj(impostos ? `${impostos.irrf}%`.replace(".", ",") : "0%");
    setInss(impostos ? `${impostos.inss}%`.replace(".", ",") : "0%");

    if (event === 1) {
      setOpcaoSimples(3);
    } else if (event === 3 || event === 4) {
      setOpcaoSimples(5);
    } else {
      setOpcaoSimples(null);
    }
    setShowTributos(true);
  };
  useEffect(() => {
    loadPerfil();
    initialValues();
  }, [initialValues, loadPerfil]);

  function handleSenhaChange(event) {
    const { value } = event.target;
    setSenhaPrefeitura(value);
  }

  // const handleDuplicate = () => {
  //   setItemServico([...itemServico, objItemServico]);
  // };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {loading && <OverlayLoading />}
        <div className="session-container user-register-container">
          <div className="session-container-header">
            <div className="p-horizontal">
              <Grid container direction="column" alignItems="stretch">
                <Grid item>
                  <span className="label">Empresa *</span>
                </Grid>
                <Grid item>
                  <div type="text" className="text-background">
                    {empresa}
                  </div>
                </Grid>

                <div className="space-vertical" />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <SelectComponent
                      list={regimeOptions}
                      initialValue={
                        regimeOptions.find(
                          (item) => item.id === regimeTributario
                        )
                          ? regimeOptions.find(
                              (item) => item.id === regimeTributario
                            ).nome
                          : ""
                      }
                      styleType="form"
                      search={false}
                      label="Regime Tributário"
                      title="Selecione uma opção"
                      required
                      callback={(event) => {
                        handleChangeRegimeTributario(event);
                      }}
                    />
                  </Grid>
                  {showOpcaoPeloSimples && (
                    <Grid item xs={12} sm={12} md={6}>
                      <SelectComponent
                        list={simpleOptions}
                        initialValue={
                          simpleOptions.find((item) => item.id === opcaoSimples)
                            ? simpleOptions.find(
                                (item) => item.id === opcaoSimples
                              ).nome
                            : ""
                        }
                        styleType="form"
                        search={false}
                        label="Opção pelo simples"
                        title="Selecione uma opção"
                        required={true}
                        callback={(event) => {
                          setOpcaoSimples(event);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
                <div className="space-vertical" />
                {showQualificacoesPessoaJuridica && (
                  <Grid item>
                    <SelectComponent
                      list={qualificacaoPessoaJuridicaOptions}
                      styleType="form"
                      initialValue={
                        qualificacaoPessoaJuridicaOptions.find(
                          (item) => item.id === qualificacaoPessoaJuridica
                        )
                          ? qualificacaoPessoaJuridicaOptions.find(
                              (item) => item.id === qualificacaoPessoaJuridica
                            ).nome
                          : ""
                      }
                      search={false}
                      label="Qualificações de Pessoa Jurídica"
                      title="Selecione uma opção"
                      required={false}
                      callback={(event) => {
                        setQualificacaoPessoaJuridica(event);
                      }}
                    />
                  </Grid>
                )}

                <div className="space-vertical" />
                <Grid item>
                  <SelectComponent
                    list={tributoEspecialOptions}
                    styleType="form"
                    initialValue={
                      tributoEspecialOptions.find(
                        (item) => item.id === regimeEspecial
                      )
                        ? tributoEspecialOptions.find(
                            (item) => item.id === regimeEspecial
                          ).nome
                        : ""
                    }
                    search={false}
                    label="Regime especial de tributação"
                    title="Selecione uma opção"
                    required={false}
                    callback={(event) => {
                      setRegimeEspecial(event);
                    }}
                  />
                </Grid>
              </Grid>

              <div className="space-vertical" />

              <Grid item container spacing={3}>
                <Grid item md={6}>
                  <Input
                    label="Usuário da prefeitura"
                    tipo="text"
                    value={usuarioPrefeitura}
                    handleInputChange={(event) =>
                      setUsuarioPrefeitura(event.target.value)
                    }
                  />
                </Grid>

                <Grid item md={6}>
                  <div className="certificado-register-form-senha-input">
                    <div className="password-input">
                      <Input
                        value={senhaPrefeitura}
                        label="Senha prefeitura"
                        tipo="text"
                        title={senhaPrefeitura !== "" ? null : "******"}
                        type={isPasswordVisible ? "text" : "password"}
                        handleInputChange={(event) => handleSenhaChange(event)}
                      />
                      <button
                        onClick={() => setIsPasswordVisible((prev) => !prev)}
                        className="gov-password-icon"
                        type="button"
                      >
                        <div className="gov-password-icon-container">
                          {!isPasswordVisible ? (
                            <Icon name="closed-eye" />
                          ) : (
                            <Icon name="open-eye" />
                          )}
                        </div>
                      </button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        {showTributos && (
          <>
            <div className="space-vertical" />
            <div className="session-container user-register-container">
              <span className="session-container-header p-horizontal">
                <Grid container direction="column" alignItems="stretch">
                  <Grid item>
                    <div className="perfilTributario-title">Tributos</div>
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item md={4}>
                      <Input
                        label="PIS (%)"
                        required
                        title="%"
                        tipo="percentage"
                        value={pis}
                        readOnly={
                          perfilAcesso !== "1" && perfilAcesso !== "2"
                            ? true
                            : false
                        }
                        handleInputChange={(event) =>
                          setPis(event.target.value)
                        }
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Input
                        label="COFINS (%)"
                        required
                        title="%"
                        tipo="percentage"
                        value={cofins}
                        readOnly={
                          perfilAcesso !== "1" && perfilAcesso !== "2"
                            ? true
                            : false
                        }
                        handleInputChange={(event) =>
                          setCofins(event.target.value)
                        }
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Input
                        label="CSLL (%)"
                        required
                        title="%"
                        tipo="percentage"
                        value={csll}
                        readOnly={
                          perfilAcesso !== "1" && perfilAcesso !== "2"
                            ? true
                            : false
                        }
                        handleInputChange={(event) => {
                          setCsll(event.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <div className="space-vertical" />
                  <Grid item container spacing={3}>
                    <Grid item md={4}>
                      <Input
                        label="IRPJ (%)"
                        required
                        placeholder="%"
                        tipo="percentage"
                        value={irpj}
                        readOnly={
                          perfilAcesso !== "1" && perfilAcesso !== "2"
                            ? true
                            : false
                        }
                        handleInputChange={(event) =>
                          setIrpj(event.target.value)
                        }
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Input
                        label="INSS (%)"
                        required
                        placeholder="%"
                        tipo="percentage"
                        value={inss}
                        handleInputChange={(event) =>
                          setInss(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </span>
            </div>
          </>
        )}

        <div className="space-vertical" />

        <div className="session-container user-register-container">
          <span className="session-container-header p-horizontal">
            <Grid container direction="column" alignItems="stretch" spacing={2}>
              <Grid item>
                <div className="perfilTributario-titleSubtitle">NF-e</div>
                <div className="perfilTributario-subTitle">
                  Nota fiscal eletrônica
                </div>
              </Grid>
              <Grid
                container
                spacing={1}
                item
                xs={12}
                md={6}
                className="empresa-nfe"
              >
                <Grid item className="container-input-switch">
                  Empresa utiliza NF-e?
                  <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                    Não
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={utilizaNfe}
                      onClick={() => setUtilizaNfe(!utilizaNfe)}
                    />
                  </Grid>
                  <Grid item>Sim</Grid>
                </Grid>
                <Grid item className="container-input-switch">
                  Adicionar Grupo de Transporte e Reboque na Emissão?
                  <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                    Não
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={grupoTransporteReboque}
                      onClick={() =>
                        setGrupoTransporteReboque(!grupoTransporteReboque)
                      }
                    />
                  </Grid>
                  <Grid item>Sim</Grid>
                </Grid>
                <Grid item className="container-input-switch">
                  Adicionar Grupo de Volume da Carga e Lacres na Emissão?
                  <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                    Não
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={grupoVolumeCargaLacres}
                      onClick={() =>
                        setGrupoVolumeCargaLacres(!grupoVolumeCargaLacres)
                      }
                    />
                  </Grid>
                  <Grid item>Sim</Grid>
                </Grid>
                <Grid item className="container-input-switch">
                  Adicionar Retenção de ICMS Transporte na Emissão?
                  <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                    Não
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={retencaoIcms}
                      onClick={() => setRetencaoIcms(!retencaoIcms)}
                    />
                  </Grid>
                  <Grid item>Sim</Grid>
                </Grid>
              </Grid>
              <Grid item container spacing={3}>
                <InfoComponent
                  text="A emissão é realizada através do portal da invoiSys"
                  icon={<InfoOutlined fontSize="small" className="info-icon" />}
                />
              </Grid>
            </Grid>
          </span>
        </div>
        <div className="space-vertical" />

        <div className="space-vertical" />
        <div className="session-container user-register-container">
          <span className="session-container-header p-horizontal">
            <Grid container direction="column" alignItems="stretch" spacing={2}>
              <Grid item>
                <div className="perfilTributario-titleSubtitle">NFS-e</div>
                <div className="perfilTributario-subTitle">
                  Nota fiscal de serviço
                </div>
              </Grid>
              <Grid
                container
                spacing={1}
                item
                xs={12}
                md={6}
                className="empresa-nfe"
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <Grid item className="container-input-switch">
                  Empresa utiliza NFS-e?
                  <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                    Não
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={utilizaNfse}
                      onClick={() => setUtilizaNfse(!utilizaNfse)}
                    />
                  </Grid>
                  <Grid item>Sim</Grid>
                </Grid>
              </Grid>
              {utilizaNfse && (
                <>
                  <Grid item container spacing={3}>
                    <Grid item md={4}>
                      <span className="label">Número da última NF *</span>
                      <input
                        type="text"
                        className="text-border"
                        value={ultimaNf}
                        onChange={(event) => {
                          if (onlyNumbers(event))
                            setUltimaNf(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <span className="label">Número do lote da NF *</span>
                      <input
                        type="text"
                        className="text-border"
                        value={loteNf}
                        onChange={(event) => {
                          if (onlyNumbers(event)) setLoteNf(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <span className="label">Série da NF *</span>
                      <input
                        type="text"
                        className="text-border"
                        value={serieNf}
                        onChange={(event) => setSerieNf(event.target.value)}
                      />
                    </Grid>
                    <Grid item>
                      <span className="font-hint nf-hint">
                        * Caso já tenha emitido
                      </span>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Input
                      label="Chave de Autenticação"
                      tipo="text"
                      value={chaveAutorizacao}
                      handleInputChange={(event) =>
                        setChaveAutorizacao(event.target.value)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <FileInput
                      title="Logo"
                      onChange={(value) => {
                        setLogo(value);
                      }}
                    />
                  </Grid>
                  <InfoComponent
                    text="Imagem de até 100kb nos formatos .png ou .jpg"
                    icon={
                      <InfoOutlined fontSize="small" className="info-icon" />
                    }
                  />
                  <Grid item>
                    <div className="required-text">
                      <span>* Campos obrigatórios</span>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </span>
        </div>
        <div className="space-vertical" />
        <div className="session-container user-register-container">
          <span className="session-container-header p-horizontal">
            <Grid container direction="column" alignItems="stretch" spacing={2}>
              <Grid item>
                <div className="perfilTributario-titleSubtitle">NFC-e</div>
                <div className="perfilTributario-subTitle">
                  Nota fiscal de produto
                </div>
              </Grid>
              <Grid
                container
                spacing={1}
                item
                xs={12}
                md={6}
                className="empresa-nfe"
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <Grid item className="container-input-switch">
                  Empresa utiliza NFC-e?
                  <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                    Não
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={utilizaNfce}
                      onClick={() => setUtilizaNfce(!utilizaNfce)}
                    />
                  </Grid>
                  <Grid item>Sim</Grid>
                </Grid>
              </Grid>
              {utilizaNfce && (
                <>
                  <Grid item container spacing={3}>
                    <Grid item md={6}>
                      <span className="label">Número da última NFC-e</span>
                      <input
                        type="text"
                        className="text-border"
                        value={ultimaNfce}
                        onChange={(event) => {
                          if (onlyNumbers(event))
                            setUltimaNfce(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <span className="label">Série da NFC-e</span>
                      <input
                        type="text"
                        className="text-border"
                        value={serieNfce}
                        onChange={(event) => setSerieNfce(event.target.value)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <span className="label">ID Token NFC-e</span>
                      <input
                        type="text"
                        className="text-border"
                        value={idTokenNfce}
                        onChange={(event) => {
                          if (onlyNumbers(event))
                            setIdTokenNfce(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <span className="label">Token CSC NFC-e</span>
                      <input
                        type="text"
                        className="text-border"
                        value={tokenCscNfce}
                        onChange={(event) =>
                          setTokenCscNfce(event.target.value)
                        }
                      />
                    </Grid>
                    <Grid item md={6}>
                      <span className="label">
                        ID Token NFC-e - Homologação
                      </span>
                      <input
                        type="text"
                        className="text-border"
                        value={idTokenNfceHomologa}
                        onChange={(event) => {
                          if (onlyNumbers(event))
                            setIdTokenNfceHomologa(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <span className="label">
                        Token CSC NFC-e - Homologação
                      </span>
                      <input
                        type="text"
                        className="text-border"
                        value={tokenCscNfceHomologa}
                        onChange={(event) =>
                          setTokenCscNfceHomologa(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <div className="required-text">
                      <span>* Caso já tenha emitido</span>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </span>
        </div>
        <div className="space-vertical" />
        <Grid item container justify="flex-end">
          <ButtonComponent
            type="submit"
            text="Aplicar Configurações"
            className="btn-success zero-margin"
          />
        </Grid>
        <div className="space-vertical" />
      </form>
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        message={callbackMessage}
        errorList={callbackErrorList}
        handleClose={handleClose}
        duration={callbackDuration}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default PerfilTributario;
