import Axios from "./Axios";

const usuariosService = {
  getUsuarios: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null,
    status = null,
    perfil_acesso = null
  ) => {
    const result = await Axios.get("/lista-usuario", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
        status,
        perfil_acesso,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  addUsuario: async (name, email, cpf, empresas, perfil_acesso, active) => {
    const add = await Axios.post("/cadastra-usuario", {
      name,
      email,
      cpf,
      empresas,
      perfil_acesso,
      active,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return add;
  },
  consultaUsuario: async (id) => {
    const response = await Axios.get(`/consulta-usuario/${id}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  consultaUsuarioLogado: async () => {
    const response = await Axios.get(`/consulta-usuario`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  consultaUsuarioLogadoEdicao: async () => {
    const response = await Axios.get(`/consulta-usuario-logado`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  atualizaUsuario: async (
    id,
    name,
    email,
    cpf,
    empresas,
    perfil_acesso,
    active,
    permissoes = null
  ) => {
    const response = await Axios.post(`/atualiza-usuario/${id}`, {
      name,
      email,
      cpf,
      empresas,
      perfil_acesso,
      active,
      permissoes,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  atualizaUsuarioLogado: async ({
    name,
    email,
    cpf,
    diasParaRenovacaoSenha,
    password,
    imagem,
  }) => {
    const response = await Axios.post(`/atualiza-usuario-logado`, {
      name,
      email,
      cpf,
      password,
      imagem,
      dias_para_renovacao_senha: diasParaRenovacaoSenha,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  removeUsuario: async (id) => {
    const response = await Axios.get(`/remover-usuario/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  limiteUsuario: async (id) => {
    const response = await Axios.get(`/controle-quantidade-usuario/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  consultaPermissoes: async (perfil_acesso) => {
    const response = await Axios.get("/consulta-permissoes-usuario", {
      perfil_acesso,
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  alterarSenha: async (id_usuario, password, token = null) => {
    const headers = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await Axios.post(
      `/altera-senha-usuario`,
      {
        id_usuario,
        password,
      },
      {
        headers,
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  solicitaReativacao: async (id_usuario) => {
    const response = await Axios.post(
      `/solicitar-ativacao-usuario/${id_usuario}`
    )
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selecionaPeriodoParaRenovacaoSenha: async (perfil_acesso) => {
    const response = await Axios.get(
      "/seleciona-dias-para-renovacao-de-senha",
      {
        perfil_acesso,
      }
    )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
};

export default usuariosService;
