import React from "react";
import ButtonComponent from "../../components/ButtonComponent";
import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import "./styles.css";

function ViewButton() {
  function testeOnClick() {
    console.log("Teste concluido");
  }
  return (
    <>
      <div className="session-container">
        <span className="session-container-header">
          <div className="teste-button-container">
            <ButtonComponent
              text="Login"
              className="flat-default"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Login"
              className="flat-default-strong"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Login"
              className="default-outline"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Login"
              className="default-outline-strong"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="+ Novo Cliente"
              className="btn-primary"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="+ Novo Cliente"
              className="btn-dark"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Salvar e Gerar Faturas"
              className="btn-primary"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Salvar e Gerar Faturas"
              className="btn-dark"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Salvar"
              className="btn-success"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Salvar"
              className="btn-success-strong"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Excluir"
              className="btn-danger"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Excluir"
              className="btn-danger-strong"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Avançar"
              className="btn-info"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Avançar"
              className="btn-info-strong"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Voltar"
              className="btn-secondary"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Voltar"
              className="btn-secondary-strong"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Voltar"
              className="secondary-outline"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="Voltar"
              className="secondary-outline-strong"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="+ Emitir Nota Fiscal"
              className="btn-primary"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="+ Emitir Nota Fiscal"
              className="btn-dark"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="+ Adicionar"
              className="success-outline"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="+ Adicionar"
              className="success-outline-strong"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="- Remover"
              className="secondary-outline"
              onClick={testeOnClick}
            />
            <ButtonComponent
              text="- Remover"
              className="secondary-outline-strong"
              onClick={testeOnClick}
            />
            <ButtonComponent
              icon={AiOutlineUserAdd}
              text="Adicionar Contato"
              className="btn-success"
              onClick={testeOnClick}
            />
            <ButtonComponent
              icon={AiOutlineUserAdd}
              text="Adicionar Contato"
              className="btn-success-strong"
              onClick={testeOnClick}
            />
            <ButtonComponent
              icon={AiOutlineUserAdd}
              text="Adicionar Contato"
              className="success-outline"
              onClick={testeOnClick}
            />
            <ButtonComponent
              icon={AiOutlineUserAdd}
              text="Adicionar Contato"
              className="success-outline-strong"
              onClick={testeOnClick}
            />
            <ButtonComponent
              icon={AiOutlineUserDelete}
              text="Remover Contato"
              className="secondary-outline"
              onClick={testeOnClick}
            />
            <ButtonComponent
              icon={AiOutlineUserDelete}
              text="Remover Contato"
              className="secondary-outline-strong"
              onClick={testeOnClick}
            />
          </div>
          <div className="teste-button-container teste">
            <div className="filter-input-container">
              <label htmlFor="search-input" className="filter-input-icon">
                <FaSearch />
              </label>
              <input
                placeholder="Pesquisar"
                onInput={(event) => console.log(event.target.value)}
                id="search-input"
                className="filter-input"
              />
            </div>
            <div className="filter-input-container input-state-force">
              <label htmlFor="search-input2" className="filter-input-icon">
                <FaSearch />
              </label>
              <input
                placeholder="Texto da pesquisa"
                onInput={(event) => console.log(event.target.value)}
                id="search-input2"
                className="filter-input"
              />
            </div>
            <div>
              <ButtonComponent
                text="+"
                className="btn-simple"
                onClick={testeOnClick}
              />
              <ButtonComponent
                text="-"
                className="simple-outline"
                onClick={testeOnClick}
              />
            </div>
          </div>
        </span>
      </div>
    </>
  );
}

export default ViewButton;
