import { Grid } from "@material-ui/core";
import React from "react";
import ImagemNovidades from "../../assets/img/novidades.svg";
import "./styles.scss";
import { useHistory } from "react-router-dom";

export function CardNovidadesHome() {
  const history = useHistory();
  return (
    <div className="containerCardNovidades">
      <Grid container className="cardNovidades">
        <Grid item className="containerTitulo" xs={12}>
          <h4>Explore as Novidades da contábilhub!</h4>
        </Grid>
        <Grid item container>
          <Grid item xs={5} className="containerImagem">
            <img src={ImagemNovidades} alt="Imagem novidades" />
          </Grid>
          <Grid item container xs={7} className="containerTexto">
            <Grid item className="descricao" xs={12}>
              <span>
                Descubra as melhorias e recursos incríveis da nossa versão
                atualizada.
              </span>
            </Grid>
            <Grid item xs={12} className="containerBotao">
              <button type="button" onClick={() => history.push("/novidades")}>
                Acesse Aqui
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
