import React, { useEffect, useState, memo, useCallback } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory, useParams } from "react-router-dom";
import { onlyNumbers, onlyUnsignedNumbers, isValidCnpj } from "../../../utils/strings.js";
import validateFields from "../../../utils/validateFields";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import SelectComponent from "../../../components/SelectComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import Input from "../../../components/Input";
import Switch from "../../../components/Switch";
import DatePicker from "../../../components/DatePicker";

import CallbackMessage from "../../../components/CallbackMessage";
import OverlayLoading from "../../../components/OverlayLoading";
import Stepper from "../../../components/Stepper";
import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";
import { Grid, Divider } from "@material-ui/core";

import empresasService from "../../../services/empresasService";
import buscaCnpjService from "../../../services/buscaCnpjService";
import buscaCepService from "../../../services/buscaCepService";

import selectServices from "../../../services/selectServices";
import planosServices from "../../../services/planosServices";

import styles from "./styles.module.scss";

import "./styles.scss";
import ServicosContrato from "./ServicosContrato/index.jsx";
import { servicosPlano } from "../../../enums/servicosPlanos.js";

const CustomCheckbox = withStyles({
  root: {
    color: "#812990",
    marginTop: 10,
    width: 0,
    "&$checked": {
      color: "#812990",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function PreCadastroEmpresas() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const params = useParams();
  const { id } = params;
  const IntId = parseInt(id, 10);
  const isEdit = Number.isInteger(IntId);

  const perfilAcesso = window.localStorage.getItem("perfil_acesso");

  const isEmpresaPlataforma = perfilAcesso && (perfilAcesso === "1" || perfilAcesso === "2");

  const [loadingInputCnpj, setLoadingInputCnpj] = useState(false);
  const [loadingInputCep, setLoadingInputCep] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [listCNAE, setListCNAE] = useState([]);
  const [listNatureza, setListNatureza] = useState([]);
  const [listQualificacao, setListQualificacao] = useState([]);
  const [planList, setPlanList] = useState([]);

  const [listRequest, setListRequest] = useState({
    data: [],
  });

  // CAMPOS CONTATO
  const [dadosContato, setDadosContato] = useState({
    razaoSocial: "",
    nome: "",
    cpf: "",
    dataNascimento: "",
    email: "",
    cep: "",
    uf: "",
    cidade: "",
    bairro: "",
    endereco: "",
    numero: "",
    complemento: "",
    cnpj: ""
  });

  // CAMPOS PLANO
  const [dadosPlano, setDadosPlano] = useState({
    plano: "",
    valorPlano: "",
    emitiFaturaHoje: 0,
    dataVencimento: "",
  });

  // CAMPOS CONTRATO
  const [servicosPrestadosInputs, setServicosPrestadosInputs] = useState(null);

  // const [nome, setNome] = useState("");
  // const [cpf, setCpf] = useState("");
  // const [endereco, setEndereco] = useState("");
  // const [email, setEmail] = useState("");
  const [cnae, setCnae] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cep, setCep] = useState("");
  const [numEnd, setNumEnd] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [site, setSite] = useState("");
  const [inscricao_municipal, setInscricao_municipal] = useState("");
  const [inscricao_estadual, setInscricao_estadual] = useState("");
  const [utilizaNfe, setUtilizaNfe] = useState(false);

  const [senhaPortalGov, setSenhaPortalGov] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [naturezaJuridica, setNaturezaJuridica] = useState("");
  const { selecionaPlano, selecionaServicosPrestados } = planosServices;

  //Contato
  const objContato = {
    nome_social: "",
    nome: "",
    email: "",
    telefone: "",
    celular: "",
    departamento: "",
  };
  const [formContato, setFormContato] = useState([objContato]);
  const handleDuplicateContato = () =>
    setFormContato([...formContato, objContato]);
  const handleRemoveContato = (index) => {
    formContato.splice(index, 1);
    setFormContato([...formContato]);
  };

  //CNAESecundario
  const objCnae = {
    id: "",
  };
  const [formCnae, setformCnae] = useState([]);
  const handleDuplicateCnae = () => setformCnae([...formCnae, objCnae]);
  function handleRemoveCnae(index) {
    formCnae.splice(index, 1);
    setformCnae([...formCnae]);
  }

  // Socios
  const ObjSocio = {
    nome: "",
    data_nascimento: "",
    cpf: "",
    pis: "",
    email: "",
    cod_qualificacao: null,
    pro_labore: false,
    utiliza_endereco_empresa: 0,
    cep: "",
    endereco: "",
    bairro: "",
    numero: "",
    complemento: "",
    uf: "",
    cidade: "",
  };

  const [formSocio, setFormSocio] = useState([ObjSocio]);
  const handleDuplicateSocio = () => setFormSocio([...formSocio, ObjSocio]);
  const handleRemoveSocio = (index) => {
    formSocio.splice(index, 1);
    setFormSocio([...formSocio]);
  };

  // FIM CAMPOS PARA O SERVICE

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  useEffect(() => {
    if (!isEmpresaPlataforma) {
      return history.push('/empresas');
    }
    setLoading(true);
    updateListNatureza();
    updateListCNAE();
    updateListQualificacao();
    getPlanList();
    setLoading(false);
  }, []);

  useEffect(() => {
    loadServicosContrato();
  }, []);

  const loadServicosContrato = useCallback(async () => {
    const response = await selecionaServicosPrestados();

    if (response.status === 200) {
      let servicosPlanoCheckboxTemp = response?.data?.filter(a => a.type === 'checkbox').map((a) => {
        return { ...a, id_configuracao_plano: a.id, configuracao: a.label, checked: 0 };
      });
      let servicosPlanoInputsTemp = response?.data?.filter(a => a.type === 'input').map((a) => {
        return { ...a, id_configuracao_plano: a.id, value: '' };
      });
      setListRequest({
        data: servicosPlanoCheckboxTemp,
      });
      setServicosPrestadosInputs(servicosPlanoInputsTemp);
      // setPlanList(response.data);
    }
  }, [servicosPlano]);

  const getPlanList = useCallback(async () => {
    const response = await selecionaPlano();

    if (response.status === 200) {
      setPlanList(response.data);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (isEdit) {
        setLoading(true);
        const response = await empresasService.consultaEmpresa(id);
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }

        const { data } = response;
        if (data) {
          setDadosContato((prev) => ({
            ...prev,
            nome: data.nome,
            razaoSocial: data.razao_social,
            email: data.email,
            endereco: data.endereco,
            cpf: data.cpf,
            dataNascimento: data.data_nascimento,
            complemento: data.complemento,
            numero: data.numero,
            cep: data.cep,
            cnpj: data.cnpj,
          }));
          setCnae(data.id_cnae);
          setNaturezaJuridica(data.id_natureza_juridica);
          setRazaoSocial(data.razao_social);
          setBairro(data.bairro);
          setCep(data.cep);
          // setEndereco(data.endereco);
          setNumEnd(data.numero);
          setCidade(data.cidade);
          setEstado(data.uf);
          setComplemento(data.complemento);
          // setNome(data.nome);
          setSite(data.site);
          setInscricao_municipal(data.inscricao_municipal);
          setInscricao_estadual(data.inscricao_estadual);
          setSenhaPortalGov(data.senha_govbr);
          setUtilizaNfe(data.modulo_nfe === 1);

          // setCod_indicador_ie(data.cod_indicador_ie);

          const sociosData = data.socio_empresas.map((socio) => {
            return {
              ...socio,
              pro_labore: socio.pro_labore === 1 ? true : false,
            };
          });

          setFormSocio(sociosData);

          setFormContato(data.contato_empresas);

          let cnae2 = data.cnae_secundario_empresas.map((c) => ({ id: c }));
          setformCnae(cnae2);
          setLoading(false);
        }
      }
    })();
  }, [isEdit, id]);

  async function updateListCNAE() {
    const response = await selectServices.getCNAE();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const data = response.data;

    setListCNAE(data);
  }
  const updateListNatureza = async () => {
    const response = await selectServices.getNatureza();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListNatureza(response.data);
  };

  const updateListQualificacao = async () => {
    const response = await empresasService.selecionaQualificacao();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    setListQualificacao(response.data);
  };

  const handleNextStep = () => {
    if (activeStep === 0) {
      const stepZeroFields = [
        {
          label: "nome",
          value: dadosContato.nome,
        },
        {
          label: "cpf",
          value: dadosContato.cpf,
        },
        {
          label: "datadenascimento",
          value: dadosContato.dataNascimento,
        },
        {
          label: "email",
          value: dadosContato.email,
        },
        {
          label: "cep",
          value: dadosContato.cep,
        },
        {
          label: "endereco",
          value: dadosContato.endereco,
        },
        {
          label: "bairro",
          value: dadosContato.bairro,
        },
        {
          label: "numero",
          value: dadosContato.numero,
        },
        {
          label: "estado",
          value: dadosContato.uf,
        },
        {
          label: "cidade",
          value: dadosContato.cidade,
        },

      ];

      const stepZeroErrors = validateFields(stepZeroFields);

      if (dadosContato.cnpj && !isValidCnpj(dadosContato.cnpj)) {
        stepZeroErrors.push("Informe um CNPJ válido ou deixe o campo vazio")
      }

      if (stepZeroErrors.length !== 0) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(stepZeroErrors);
        return;
      }
    }

    if (activeStep === 1) {
      const stepOneFields = [
        {
          label: "plano",
          value: dadosPlano.plano,
        },
        {
          label: "datavencimento",
          value: dadosPlano.dataVencimento,
        },
      ];
      const stepOneErrors = validateFields(stepOneFields);

      if (stepOneErrors.length !== 0) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(stepOneErrors);
        return;
      }
    }

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  function handleChangeDadosContato(value, field) {
    setDadosContato((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  async function handleCepChange(cep) {
    cep = onlyUnsignedNumbers(cep);
    console.log(cep);
    setDadosContato(prev => ({
      ...prev,
      cep
    }));

    if (cep.length === 8) {
      setLoadingInputCep(true);
      const response = await buscaCepService.getCep(cep);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoadingInputCep(false);
        return;
      }
      const data = response.data;
      if (data) {
        setDadosContato(prev => ({
          ...prev,
          cidade: data.localidade,
          uf: data.uf,
          endereco: data.logradouro,
          bairro: data.bairro,
          numero: ""
        }))
      }
      setLoadingInputCep(false);
    }
  }

  function handleChangeDadosPlano(value, field) {
    setDadosPlano((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  function handleChangeServicosInput(value, field) {
    console.log(value);
    console.log(field);
    console.log(servicosPrestadosInputs);

    const newServicosPrestadosInputs = servicosPrestadosInputs?.map( item => {
      if (item.id === field) {
        return {
          ...item,
          value
        }
      }
      return item;
    })
    setServicosPrestadosInputs(newServicosPrestadosInputs);
  }

  async function handleCepSocioChange(cep, index) {
    let list = [...formSocio];

    list[index].cep = cep;

    let newCepConsult = onlyNumbers(cep);

    if (newCepConsult.length === 8) {
      setLoadingInputCep(true);
      const response = await buscaCepService.getCep(newCepConsult);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoadingInputCep(false);
        return;
      }
      const data = response.data;
      if (data) {
        list[index].cidade = data.localidade;
        list[index].uf = data.uf;
        list[index].endereco = data.logradouro;
        list[index].bairro = data.bairro;
      }
      setLoadingInputCep(false);
    }

    setFormSocio(list);
  }

  function handleLinkEstadual() {
    let win = window.open("http://www.sintegra.gov.br/", "_blank");
    win.focus();
  }

  function handleLinkMunicipal() {
    let win = window.open(
      "https://ccm.prefeitura.sp.gov.br/login/contribuinte?tipo=F",
      "_blank"
    );
    win.focus();
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let success = false;
    let status = 0;
    let error = "";

    const stepZeroErrors = [];
    servicosPrestadosInputs.map( item => {
      if (item.required && (!item.value || item.value === '')) {
        stepZeroErrors.push(`O campo ${item.label} é obrigatório`)
      }
    })
    // const stepZeroErrors = validateFields(stepZeroFields);

    if (stepZeroErrors.length !== 0) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(stepZeroErrors);
      return;
    }
    setLoading(true);

    const response = await empresasService.preCadastroEmpresa(getAtualFormData);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    success = response.success;
    status = response.status;
    if (response.data.error) {
      if (typeof response.data.error === 'string') {
        error = [response.data.error];
      } else if(Array.isArray(response.data.error) && typeof response.data.error[0] === 'string'){
        error = response.data.error;
      } else if(Array.isArray(response.data.error) && typeof response.data.error[0] === 'object'){
        error = Object.values(response?.data?.error[0]).flat(Infinity)
      } else if(typeof response.data.error === 'object'){
        error = Object.values(response?.data?.error).flat(Infinity)
      } else {
        error = ['Não foi possível realizar o pré-cadastro.']
      }
    }
    setLoading(false);

    if (success || status === 201 || response?.data?.sucess) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage("Pré-cadastro salvo com sucesso!");
      setCallbackDuration(2000);
    }

    if (error) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(error);
    }
  };

  const getServicosPlano = () => {
    if (listRequest?.data?.length > 0) {
      let newData = [];
      const result = listRequest?.data?.map(a => {
        if (a.checked) {
          newData.push({
            id_configuracao_plano: a.id_configuracao_plano,
            valor: a.checked.toString(),
          });
        }
      });
      servicosPrestadosInputs && servicosPrestadosInputs.map( item => {
        newData.push({ id_configuracao_plano: item.id_configuracao_plano, valor: item.value })
      })
      return newData;
    } else {
      return null;
    }
  };

  const getAtualFormData = {
    razao_social: dadosContato.razaoSocial,
    nome: dadosContato.nome,
    cpf: dadosContato.cpf,
    cnpj: dadosContato.cnpj,
    data_nascimento: dadosContato.dataNascimento,
    cep: dadosContato.cep,
    endereco: dadosContato.endereco,
    bairro: dadosContato.bairro,
    numero: dadosContato.numero,
    complemento: dadosContato.complemento,
    uf: dadosContato.uf,
    cidade: dadosContato.cidade,
    email: dadosContato.email,
    id_plano: dadosPlano.plano,
    emite_fatura: dadosPlano.emitiFaturaHoje,
    valor: dadosPlano.valorPlano,
    data_vencimento: dadosPlano.dataVencimento,
    servicos_contrato: getServicosPlano(),
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/empresas");
    }
  }
  // if (error) return <div>Error loading : {error.message}</div>
  // if (loading) return <OverlayLoading />;
  // if (!data.length) return <div>No data!!!</div>;

  const handleEstadoChange = (event) => {
    const { value, maxLength } = event.target;
    const estado = value.slice(0, maxLength);
    setEstado(estado.toUpperCase());
  };

  async function handleCnpjChange(_cnpj) {

    handleChangeDadosContato(
      _cnpj,
      "cnpj"
    );
      console.log(_cnpj);
      console.log(_cnpj.length);
    if (_cnpj.length === 14) {
      setCallbackShown(false);
      setLoadingInputCnpj(true);
      setCallbackErrorList([]);
      const response = await buscaCnpjService.getCnpjReceita(_cnpj);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoadingInputCnpj(false);
        return;
      }
      const data = response.data;
      console.log(data);
      setCallbackErrorList([]);
      if(response.status === 201) {
        if (data.status && data.status === "ERROR") {
          setCallbackErrorList(["CNPJ inválido"]);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setLoadingInputCnpj(false);
          setDadosContato( prev => ({
            razaoSocial: "",
            nome: "",
            cpf: "",
            dataNascimento: "",
            email: "",
            cep: "",
            uf: "",
            cidade: "",
            bairro: "",
            endereco: "",
            numero: "",
            complemento: "",
            cnpj: ""
          }))
          return;
        }
        if (data) {
          if (data.situacao === "ATIVA") {
            
            let dadosContratoTemp = {...dadosContato};
            dadosContratoTemp = {
              ...dadosContratoTemp,
              razaoSocial: data.nome,
              cep: onlyNumbers(data.cep),
              uf: data.uf,
              cidade: data.municipio,
              bairro: data.bairro,
              endereco: data.logradouro,
              numero: data.numero,
              complemento: data.complemento,
              cnpj: _cnpj
            }
            
            if (data.qsa.length > 0) {
              dadosContratoTemp = {
                ...dadosContratoTemp,
                nome: data.qsa[0]?.nome,
                cpf: "",
                dataNascimento: "",
                email: ""
              }
            }else{
              dadosContratoTemp = {
                ...dadosContratoTemp,
                nome: "",
                cpf: "",
                dataNascimento: "",
                email: ""
              }
            }
            setDadosContato(dadosContratoTemp)
          }
        }
      } else if (response.status === 400 &&
        response.data.error.includes("Too Many Requests")) {
          setLoadingInputCnpj(false);
          setCallbackErrorList(["Ocorreu um excesso de consultas. Espere 1 minuto e tente novamente."]);
          setCallbackDuration(4000);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackShown(true);
      }
      setLoadingInputCnpj(false);
    }
  }

  return (
    <>
      {loading && <OverlayLoading />}
      <Grid container className="default-forms-container tela-pre-cadastro">
        <Grid item xs={2}>
          <Stepper
            useActiveStep
            activeStep={activeStep}
            arraySteps={["Contato", "Plano", "Contrato"]}
          />
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={2} justify="center" className="container-form-pre-cadastro">
            <Grid item style={{ marginTop: 20 }}>
              <form
                onSubmit={handleFormSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* step 0 Contato  */}
                {activeStep === 0 && (
                  <Grid container spacing={2} xs={8}>
                    <Grid item xs={12}>
                      <Input
                        label="CNPJ"
                        tipo="cnpj"
                        name="cnpj"
                        value={dadosContato.cnpj}
                        loading={loadingInputCnpj}
                        handleInputChange={async (e) => {
                          await handleCnpjChange(onlyUnsignedNumbers(e.target.value));
                        }}
                        // handleInputChange={(e) => {
                        //   handleChangeDadosContato(
                        //     onlyUnsignedNumbers(e.target.value),
                        //     "cnpj"
                        //   );
                        // }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Razão Social"
                        name="razaoSocial"
                        value={dadosContato.razaoSocial}
                        handleInputChange={(e) => {
                          handleChangeDadosContato(
                            e.target.value,
                            "razaoSocial"
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Nome Sócio"
                        name="nome"
                        required
                        value={dadosContato.nome}
                        handleInputChange={(e) => {
                            handleChangeDadosContato(e.target.value?.replace(/[^a-zA-ZÀ-ÿ ]/g, ''), "nome");
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="CPF"
                        tipo="cpf"
                        required
                        value={dadosContato.cpf}
                        handleInputChange={(e) => {
                          e.target.value = onlyNumbers(e.target.value);
                          handleChangeDadosContato(e.target.value, "cpf");
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DatePicker
                        initDate={
                          dadosContato.dataNascimento
                            ? dadosContato.dataNascimento
                            : ''
                        }
                        noMask
                        label="Data de nascimento *"
                        handleChange={(value) =>
                          handleChangeDadosContato(value, "dataNascimento")
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="E-mail"
                        name="email"
                        required
                        value={dadosContato.email}
                        handleInputChange={(e) => {
                          handleChangeDadosContato(e.target.value, "email");
                        }}
                      />
                    </Grid>
                    <Grid container spacing={2} className="grid-container-endereco">
                      <Grid item xs={6}>
                        <Input
                          label="CEP"
                          tipo="cep"
                          required
                          name="cep"
                          loading={loadingInputCep}
                          value={dadosContato.cep}
                          handleInputChange={(e) => {
                            handleCepChange(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="grid-container-endereco">
                      <Grid item xs={6}>
                        <Input
                          label="Endereço"
                          name="endereco"
                          required
                          value={dadosContato.endereco}
                          handleInputChange={(e) => {
                            handleChangeDadosContato(
                              e.target.value,
                              "endereco"
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Bairro"
                          name="bairro"
                          required
                          value={dadosContato.bairro}
                          handleInputChange={(e) => {
                            handleChangeDadosContato(e.target.value, "bairro");
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Número"
                          name="numero"
                          value={dadosContato.numero}
                          required
                          handleInputChange={(e) => {
                            handleChangeDadosContato(e.target.value, 'numero');
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Complemento"
                          name="complemento"
                          value={dadosContato.complemento}
                          handleInputChange={(e) => {
                            handleChangeDadosContato(e.target.value, 'complemento');
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Estado"
                          name="estado"
                          required
                          value={dadosContato.uf}
                          handleInputChange={(e) => {
                            handleChangeDadosContato(e.target.value, "uf");
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Cidade"
                          name="cidade"
                          required
                          value={dadosContato.cidade}
                          handleInputChange={(e) => {
                            handleChangeDadosContato(e.target.value, "cidade");
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <span className="required-text">
                          * Campos obrigatórios
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {/* step 1 Plano */}
                {activeStep === 1 && (
                  <Grid container spacing={2} xs={12}>
                    <Grid item xs={6}>
                      <SelectComponent
                        styleType="form"
                        label="Plano"
                        title=""
                        name="plano"
                        list={planList}
                        initialValue={
                          planList.find((item) => item.id === dadosPlano.plano)
                            ? planList.find(
                              (item) => item.id === dadosPlano.plano
                            ).nome
                            : ""
                        }
                        required
                        callback={(id) => {
                          handleChangeDadosPlano(id, "plano");
                        }}
                      />
                      {/* <Input
                        label="Plano"
                        required
                        name="plano"
                        value={dadosPlano.plano}
                        handleInputChange={(e) => {
                          handleChangeDadosPlano(e.target.value, 'plano');
                        }}
                      /> */}
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Valor Plano"
                        name="valorPlano"
                        tipo="dinheiro"
                        value={dadosPlano.valorPlano}
                        handleInputChange={(e) => {
                          handleChangeDadosPlano(e.value, "valorPlano");
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        initDate={
                          dadosPlano.dataVencimento !== ""
                            ? dadosPlano.dataVencimento
                            : null
                        }
                        noMask
                        required
                        label="Data de Vencimento*"
                        handleChange={(value) =>
                          handleChangeDadosPlano(value, "dataVencimento")
                        }
                        customClass="labelFatura"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <span className="required-text">
                        * Campos obrigatórios
                      </span>
                    </Grid>
                  </Grid>
                )}
                {/* step 2 Contrato */}
                {activeStep === 2 && (
                  <>
                    <Grid container spacing={2} xs={10}>
                      <ServicosContrato
                        listRequest={listRequest}
                        setListRequest={setListRequest}
                        isLoading={loading}
                      />
                      {
                        servicosPrestadosInputs && servicosPrestadosInputs?.map(item => (
                          <Grid item xs={6}>
                            <Input
                              label={item?.label}
                              name={item?.name}
                              required={item?.required}
                              tipo={item?.mask ? item?.mask : ''}
                              value={item.value}
                              handleInputChange={(e) => {
                                handleChangeServicosInput(
                                  item?.mask === 'dinheiro' ? e.value : e.target.value,
                                  item?.id
                                );
                              }}
                            />
                          </Grid>
                        ))
                      }

                      <Grid item xs={12}>
                        <span className="required-text">
                          * Campos obrigatórios
                        </span>
                      </Grid>
                    </Grid>
                  </>
                )}

                {/* NAVIGATION BUTTONS */}
                <Grid
                  container
                  xs={8}
                  justify="flex-end"
                  style={{
                    marginTop: 40,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {activeStep > 0 && (
                    <>
                      <Grid item xs={3} style={{ marginRight: 10 }}>
                        <ButtonComponent
                          text="Voltar"
                          className="btn-secondary btnSteps"
                          onClick={handleBack}
                        />
                      </Grid>
                    </>
                  )}
                  {activeStep <= 1 && (
                    <>
                      <Grid item style={{ width: 120 }}>
                        <ButtonComponent
                          className="btn-success btnSteps"
                          text="Avançar"
                          onClick={handleNextStep}
                        />
                      </Grid>
                    </>
                  )}
                  {activeStep >= 2 && (
                    <>
                      <Grid item style={{ width: 120 }}>
                        <ButtonComponent
                          className="btn-info btnSteps"
                          text="Salvar"
                          type="submit"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
        {callbackShown && (
          <CallbackMessage
            open={callbackShown}
            type={callbackType}
            handleClose={handleClose}
            message={callbackMessage}
            duration={callbackDuration}
            errorList={callbackErrorList}
          />
        )}
      </Grid>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default memo(PreCadastroEmpresas);
