import Axios from './Axios'

const checkoutServices = {
  pagamentoBoleto: async (
    identificador_cliente,
    cidade_cliente,
    cep_cliente,
    nome_razao_social_cliente,
    uf_cliente,
    endereco_cliente,
    bairro_cliente,
    valor,
    ) => {
      const response = await Axios.post('/pagamento-boleto', {
        identificador_cliente,
        cidade_cliente,
        cep_cliente,
        nome_razao_social_cliente,
        uf_cliente,
        endereco_cliente,
        bairro_cliente,
        valor,
      })
        .then(({ ...response }) => response)
        .catch(({ ...response }) => response)

      return response
    },
    pagamentoCartao: async (
      identificador_cliente,
      nome_razao_social_cliente,
      valor,
      tipo,
      numero_cartao,
      nome_cartao,
      data_expiracao,
      codigo_seguranca,
      url_retorno,
      parcelas,
    ) => {
      const response = await Axios.post('/pagamento-cartao', {
        identificador_cliente,
        nome_razao_social_cliente,
        valor,
        tipo,
        numero_cartao,
        nome_cartao,
        data_expiracao,
        codigo_seguranca,
        url_retorno,
        parcelas,
      })
        .then(({ ...response }) => response)
        .catch(({ ...response }) => response)

      return response
    }
}

export default checkoutServices