import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import ButtonComponent from "../../../components/ButtonComponent";

import CallbackMessage from "../../../components/CallbackMessage";
import OverlayLoading from "../../../components/OverlayLoading";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  useMediaQuery,
} from "@material-ui/core";

import SelectComponent from "../../../components/SelectComponent";
import selectService from "../../../services/selectServices";
import DatePicker from "../../../components/DatePicker";
import validateFields from "../../../utils/validateFields";
import styles from "./styles.module.scss";
import "./styles.scss";
import InputFile from "../../../components/InputFile";
import InputTime from "../../../components/InputTime";
import cartoesPontoService from "../../../services/dpCartoesPonto";
import { toDateDD_MM_YYYY } from "../../../utils/dates";
import { ExpandLess, ExpandMore, InfoOutlined } from "@material-ui/icons";
import modeloPadraoCartaoPonto from "../../../assets/downloads/modelo_padrao_de_cartao_ponto.xlsx";
import Icon from "../../../components/Icon";
import useCompany from "../../../hooks/useCompany";
import { InfoComponent } from "../../../components/InfoComponent";

export function CadastroCartaoPonto() {
  const isMobile = useMediaQuery("(max-width: 1100px)");

  const {
    companyList: listEmpresa,
    selectedCompany: idEmpresa,
    setSelectedCompany: setIdEmpresa,
    getEmpresaPagina,
  } = useCompany("ponto_eletronico");

  const history = useHistory();
  const locationState = history.location.state;

  const { selecionaFuncionariosDp } = selectService;
  const {
    cadastrarCartaoPonto,
    carregarDocumentoCartaoPonto,
    consultaCartaoPonto,
    editarCartaoPonto,
  } = cartoesPontoService;

  const [listFuncionarios, setListFuncionarios] = useState([]);

  const [loading, setLoading] = useState(false);
  const [showPeriodInputs, setShowPeriodInputs] = useState(false);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const params = useParams();
  const { id } = params;

  const [idFuncionario, setIdFuncionario] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);

  const [anexo, setAnexo] = useState(null);
  const [periodoDe, setPeriodoDe] = useState(null);
  const [periodoAte, setPeriodoAte] = useState(null);

  // FIM CAMPOS PARA O SERVICE

  const [isLoadingFuncionarios, setIsLoadingFuncionarios] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const loadFuncionarios = async (empresa) => {
    setIsLoadingFuncionarios(true);
    await selecionaFuncionariosDp(empresa)
      .then((response) => {
        setIsLoadingFuncionarios(false);
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        if (response.status === 200) setListFuncionarios(response.data);
      })
      .catch(() => {
        setIsLoadingFuncionarios(false);
      });
  };

  const handleChangeEmpresa = (id) => {
    setIdEmpresa(id);
    loadFuncionarios(id);
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/cartoes-ponto");
    }
  }

  async function handleChangePeriod({ field, value, index }) {
    const newSelectedDays = selectedDays.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [field]: value,
        };
      }
      return item;
    });
    setSelectedDays(newSelectedDays);
  }

  async function handleCheckPeriod() {
    try {
      const fields = [
        {
          label: "empresaDp",
          value: idEmpresa,
        },
        {
          label: "funcionario",
          value: idFuncionario,
        },
        {
          label: "periodo_de",
          value: periodoDe,
        },
        {
          label: "periodo_ate",
          value: periodoAte,
        },
      ];

      const fieldsErros = validateFields(fields);

      if (fieldsErros.length !== 0) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(fieldsErros);
        return;
      }

      setLoading(true);

      if (anexo) {
        const formData = new FormData();

        formData.append("id_empresa", idEmpresa);
        formData.append("data_inicio", periodoDe);
        formData.append("data_fim", periodoAte);
        formData.append("id_funcionario", idFuncionario);
        formData.append("arquivo", anexo);

        const response = await carregarDocumentoCartaoPonto(formData);
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        if (response.status === 200) {
          const data = response.data;
          if (data?.length > 0) {
            const dias = [];

            data.forEach((element) => {
              const date = element.dia?.split("/");
              if (date.length !== 3) {
                return;
              }
              const dia = new Date(`${date[2]}-${date[1]}-${date[0]} 12:00:00`);
              let descricao = dia.toLocaleString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                weekday: "long",
              });

              let diaFormatado = dia.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });

              descricao = descricao.replace("-feira", "");
              descricao =
                descricao.charAt(0).toUpperCase() + descricao.slice(1);
              const [diaSemana, diaMes] = descricao.split(", ");
              descricao = `${diaMes} - ${diaSemana}`;
              dias.push({
                descricao,
                dia: diaFormatado,
                entrada: element.entrada || null,
                inicioIntervalo: element.inicio_intervalo || null,
                fimIntervalo: element.fim_intervalo || null,
                saida: element.saida || null,
                justificativa: null,
              });
            });
            setSelectedDays(dias);
            setShowPeriodInputs(true);
          }
          setLoading(false);
          return;
        } else {
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage(response?.data?.msg || "Erro!");

          if (response?.data?.error && Array.isArray(response?.data?.error)) {
            let errorTemp = [];
            response?.data?.error?.map((item) => {
              errorTemp.push(...Object.values(item));
            });
            setCallbackErrorList(errorTemp);
          } else if (typeof response.data.error === "string") {
            setCallbackErrorList([response.data.error]);
          } else if (
            response?.data?.error &&
            !Array.isArray(response?.data?.error)
          ) {
            setCallbackErrorList(Object.values(response.data.error));
          } else {
            setCallbackErrorList(["Ocorreu um erro"]);
          }
        }
      } else {
        setLoading(false);
        const periodoAteDate = new Date(`${periodoAte} 12:00:00`);
        const periodoDeDate = new Date(`${periodoDe}  12:00:00`);

        const diffEmMilissegundos = periodoAteDate - periodoDeDate;
        const diffEmDias = Math.round(diffEmMilissegundos / 86400000);

        const dias = [];
        for (let i = 0; i <= diffEmDias; i++) {
          const dia = new Date(periodoDeDate.getTime() + i * 86400000);
          let descricao = dia.toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            weekday: "long",
          });
          descricao = descricao.replace("-feira", "");
          descricao = descricao.charAt(0).toUpperCase() + descricao.slice(1);

          let diaFormatado = dia.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          const id = dia.toISOString().slice(0, 10);

          const [diaSemana, diaMes] = descricao.split(", ");
          descricao = `${diaMes} - ${diaSemana}`;
          if (selectedDays.find((item) => item.idDia === id)) {
            dias.push(selectedDays.find((item) => item.idDia === id));
          } else {
            dias.push({
              idDia: id,
              descricao,
              dia: diaFormatado,
              entrada: null,
              inicioIntervalo: null,
              fimIntervalo: null,
              saida: null,
              justificativa: null,
            });
          }
        }
        setSelectedDays(dias);
        setShowPeriodInputs(true);
      }
    } catch (error) {
      console.log({ error });
      setLoading(false);
    }
  }

  async function handleSubmit() {
    // return;
    setLoading(true);

    const dados = selectedDays.map((item) => ({
      id: item.id,
      dia: item.dia,
      entrada: item.entrada,
      inicio_intervalo: item.inicioIntervalo,
      fim_intervalo: item.fimIntervalo,
      saida: item.saida,
      justificativa: item.justificativa,
    }));
    let response = null;
    if (id) {
      response = await editarCartaoPonto({
        id,
        dados,
        idEmpresa,
        dataInicio: periodoDe,
        dataFim: periodoAte,
        idFuncionario,
      });
    } else {
      response = await cadastrarCartaoPonto({
        dados,
        idEmpresa,
        dataInicio: periodoDe,
        dataFim: periodoAte,
        idFuncionario,
      });
    }

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 201 || response.status === 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage(
        response?.data?.message ||
          `Cartão de ponto ${id ? "atualizado" : "cadastrado"} com sucesso!`
      );
      return;
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage(response?.data?.msg || "Erro!");

      if (response?.data?.error && Array.isArray(response?.data?.error)) {
        let errorTemp = [];
        response?.data?.error?.map((item) => {
          errorTemp.push(...Object.values(item));
        });
        setCallbackErrorList(errorTemp);
      } else if (typeof response.data.error === "string") {
        setCallbackErrorList([response.data.error]);
      } else if (
        response?.data?.error &&
        !Array.isArray(response?.data?.error)
      ) {
        setCallbackErrorList(Object.values(response.data.error));
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
    }
  }

  const loadCartaoPonto = async () => {
    setLoading(true);
    try {
      const response = await consultaCartaoPonto(id);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 201 && response?.data?.cartao) {
        const dadosCartao = response.data.cartao;
        handleChangeEmpresa(dadosCartao.id_empresa);
        setIdFuncionario(dadosCartao.id_funcionario);

        setPeriodoDe(dadosCartao.data_inicio);
        setPeriodoAte(dadosCartao.data_fim);
        let dias = dadosCartao?.dados_cartao;
        if (dias?.length > 0) {
          dias = dias.map((item, index) => {
            const dia = new Date(`${item.dia} 12:00:00`);
            let descricao = dia.toLocaleString("pt-BR", {
              day: "2-digit",
              month: "2-digit",
              weekday: "long",
            });

            let diaFormatado = dia.toLocaleDateString("pt-BR", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
            const [diaSemana, diaMes] = descricao.split(", ");
            descricao = `${diaMes} - ${diaSemana}`;

            return {
              id: item.id,
              idDia: item.dia,
              descricao,
              dia: diaFormatado,
              entrada: item.entrada,
              inicioIntervalo: item.inicio_intervalo,
              fimIntervalo: item.fim_intervalo,
              saida: item.saida,
              justificativa: item.justificativa || null,
            };
          });
          setShowPeriodInputs(true);
          setSelectedDays(dias);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      loadCartaoPonto();
    } else {
      const empresaStorage = getEmpresaPagina("ponto_eletronico");
      if (empresaStorage) {
        handleChangeEmpresa(empresaStorage);
      }
    }
  }, []);

  return (
    <div className={styles.mtDp}>
      <div className={`session-container session-container-height-auto`}>
        <span className="session-container-header">
          <form className="session-container-form">
            {loading && <OverlayLoading />}
            <div className={`${isMobile ? "" : "p-horizontal"}`}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Empresa"
                    title="Selecione uma opção"
                    list={listEmpresa}
                    initialValue={
                      listEmpresa.find((item) => item.id === idEmpresa)
                        ? listEmpresa.find((item) => item.id === idEmpresa).nome
                        : ""
                    }
                    required
                    callback={(id) => handleChangeEmpresa(id)}
                    isDisabled={locationState?.details}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Funcionário"
                    title="Selecione uma opção"
                    list={listFuncionarios}
                    loading={isLoadingFuncionarios}
                    initialValue={
                      listFuncionarios.find((item) => item.id === idFuncionario)
                        ? listFuncionarios.find(
                            (item) => item.id === idFuncionario
                          ).nome
                        : ""
                    }
                    required
                    callback={(id) => setIdFuncionario(id)}
                    isDisabled={locationState?.details}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Período de:*"
                    name="periodoDe"
                    initDate={periodoDe ? periodoDe : ""}
                    handleChange={(date) => setPeriodoDe(date)}
                    value={periodoDe}
                    required
                    readOnly={locationState?.details}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Período até:*"
                    name="periodoAte"
                    initDate={periodoAte ? periodoAte : ""}
                    handleChange={(date) => setPeriodoAte(date)}
                    value={periodoAte}
                    required
                    readOnly={locationState?.details}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputFile
                    title="Anexo"
                    onChange={(base64, name, file) => {
                      setAnexo(file);
                    }}
                  />
                  <div className="warning-text">
                    <InfoComponent
                      text={
                        <a href={modeloPadraoCartaoPonto} download>
                          Para fazer o download do arquivo padrão para
                          preenchimento <span>clique aqui</span>
                        </a>
                      }
                      icon={
                        <InfoOutlined fontSize="small" className="info-icon" />
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </form>
        </span>
      </div>
      {(id || showPeriodInputs) && (
        <Grid item container justifyContent="flex-end" spacing={4}>
          <Grid item {...(isMobile ? { xs: 12 } : {})}>
            <ButtonComponent
              text={"Avançar"}
              className={`btn-success w-full ${styles.btnSalvar} ${styles.btnAvancarEdicao}`}
              onClick={() => handleCheckPeriod()}
            />
          </Grid>
        </Grid>
      )}
      {showPeriodInputs && (
        <div
          className={`session-container session-container-height-auto session-periods`}
        >
          <span className="session-container-header">
            <form className="session-container-form">
              {loading && <OverlayLoading />}
              <div class="container-periods">
                <div className="periods-title">
                  Período de {toDateDD_MM_YYYY(periodoDe)} a{" "}
                  {toDateDD_MM_YYYY(periodoAte)}
                </div>
                {selectedDays.map((period, index) => {
                  return isMobile ? (
                    <Accordion
                      expanded={expanded === index}
                      onChange={handleChange(index)}
                      className={styles.accordionStyles}
                    >
                      <AccordionSummary>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          xs={12}
                          className={`${styles.accordionSummary} ${styles.accordionSummaryDia}`}
                        >
                          <Grid item className="font-title">
                            <div className="label h-100">
                              <div className="data-content">
                                {period.descricao}
                              </div>
                            </div>
                          </Grid>
                          <Grid item>
                            {expanded === index ? (
                              <ExpandLess className="font-darkBlue" />
                            ) : (
                              <ExpandMore className="font-orange" />
                            )}
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails
                        className={styles.accordionDetails}
                        style={{ background: "white" }}
                      >
                        <div className="">
                          <div class="item">
                            <InputTime
                              label="Entrada"
                              tipo="hhmm"
                              value={period.entrada || ""}
                              disabled={locationState?.details}
                              handleInputChange={(event) =>
                                handleChangePeriod({
                                  field: "entrada",
                                  value: event.target.value,
                                  index,
                                })
                              }
                            />
                          </div>
                          <div class="item">
                            <InputTime
                              tipo="hhmm"
                              label="Início Intervalo"
                              value={period.inicioIntervalo || ""}
                              disabled={locationState?.details}
                              handleInputChange={(event) =>
                                handleChangePeriod({
                                  field: "inicioIntervalo",
                                  value: event.target.value,
                                  index,
                                })
                              }
                            />
                          </div>
                          <div class="item">
                            <InputTime
                              tipo="hhmm"
                              label="Fim Intervalo"
                              value={period.fimIntervalo || ""}
                              disabled={locationState?.details}
                              handleInputChange={(event) =>
                                handleChangePeriod({
                                  field: "fimIntervalo",
                                  value: event.target.value,
                                  index,
                                })
                              }
                            />
                          </div>
                          <div class="item">
                            <InputTime
                              tipo="hhmm"
                              label="Saída"
                              value={period.saida || ""}
                              disabled={locationState?.details}
                              handleInputChange={(event) =>
                                handleChangePeriod({
                                  field: "saida",
                                  value: event.target.value,
                                  index,
                                })
                              }
                            />
                          </div>
                          <div class="item">
                            <InputFile
                              id={index}
                              title="Justificativa da ausência"
                              hasFile={
                                period.justificativa && id
                                  ? "Justificativa adicionada!"
                                  : false
                              }
                              onChange={(base64, name, file) => {
                                handleChangePeriod({
                                  field: "justificativa",
                                  value: base64,
                                  index,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <div class="period">
                      <div class="item h-100">
                        <div className="label h-100">
                          Data
                          <div className="data-content">{period.descricao}</div>
                        </div>
                      </div>
                      <div class="item">
                        <InputTime
                          label="Entrada"
                          tipo="hhmm"
                          value={period.entrada || ""}
                          disabled={locationState?.details}
                          handleInputChange={(event) =>
                            handleChangePeriod({
                              field: "entrada",
                              value: event.target.value,
                              index,
                            })
                          }
                        />
                      </div>
                      <div class="item">
                        <InputTime
                          tipo="hhmm"
                          label="Início Intervalo"
                          value={period.inicioIntervalo || ""}
                          disabled={locationState?.details}
                          handleInputChange={(event) =>
                            handleChangePeriod({
                              field: "inicioIntervalo",
                              value: event.target.value,
                              index,
                            })
                          }
                        />
                      </div>
                      <div class="item">
                        <InputTime
                          tipo="hhmm"
                          label="Fim Intervalo"
                          value={period.fimIntervalo || ""}
                          disabled={locationState?.details}
                          handleInputChange={(event) =>
                            handleChangePeriod({
                              field: "fimIntervalo",
                              value: event.target.value,
                              index,
                            })
                          }
                        />
                      </div>
                      <div class="item">
                        <InputTime
                          tipo="hhmm"
                          label="Saída"
                          value={period.saida || ""}
                          disabled={locationState?.details}
                          handleInputChange={(event) =>
                            handleChangePeriod({
                              field: "saida",
                              value: event.target.value,
                              index,
                            })
                          }
                        />
                      </div>
                      <div class="item">
                        <InputFile
                          id={index}
                          title="Justificativa da ausência"
                          hasFile={
                            period.justificativa && id
                              ? "Justificativa adicionada!"
                              : false
                          }
                          onChange={(base64, name, file) => {
                            handleChangePeriod({
                              field: "justificativa",
                              value: base64,
                              index,
                            });
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </form>
          </span>
        </div>
      )}
      {!locationState?.details && (
        <Grid item container justifyContent="flex-end" spacing={4}>
          <Grid item {...(isMobile ? { xs: 12 } : {})}>
            <ButtonComponent
              text={showPeriodInputs ? "Salvar" : "Avançar"}
              className={`btn-success w-full ${styles.btnSalvar}`}
              onClick={() =>
                showPeriodInputs ? handleSubmit() : handleCheckPeriod()
              }
            />
          </Grid>
        </Grid>
      )}
      {loading && <OverlayLoading />}
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        handleClose={handleClose}
        message={callbackMessage}
        duration={callbackDuration}
        errorList={callbackErrorList}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </div>
  );
}
