import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";

import validateFields from "../../utils/validateFields";

import TableComponent from "../../components/TableComponent";
import OverlayLoading from "../../components/OverlayLoading";
import Select from "../../components/SelectComponent";
import Input from "../../components/Input";
import DatePicker from "../../components/DatePicker";
import Button from "../../components/ButtonComponent";
import ConfirmDialog from "../../components/ConfirmDialog";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import CallbackMessage from "../../components/CallbackMessage";
import ModalDetalhesNota from "../../components/ModalDetalhesNota";
import ModalItensNota from "../../components/ModalItensNota";
import ModalAgendarPagamento from "../../components/ModalAgendarPagamento";

import solicitacaoServicoServices from "../../services/solicitacaoServicoServices";
import notasFiscaisService from "../../services/notasFiscaisService";
import viacepServices from "../../services/viacepServices";

import { base64ToFile } from "../../utils/functions";
import { formatDate, toDateYYYY_MM_DD, unformatDate } from "../../utils/dates";

import "./styles.scss";
import useCompany from "../../hooks/useCompany";

export default function NotasDeEntrada() {
  const history = useHistory();
  const { listaSolicitacoes } = solicitacaoServicoServices;
  const {
    listaNotaEntrada,
    downloadPDFNotaEntrada,
    downloadXMLNotaEntrada,
    selecionaStatusNotaEntrada,
    cienciaNotaEntrada,
    confirmacaoNotaEntrada,
    desconhecimentoNotaEntrada,
    naoRealizacaoNotaEntrada,
  } = notasFiscaisService;
  const {
    companyList: listEmpresas,
    selectedCompany: empresa,
    setSelectedCompany: setEmpresa,
    setEmpresaPagina,
  } = useCompany("entrada_nfe");

  const { selecionaEstado } = viacepServices;

  const [loading, setLoading] = useState(false);
  const [idNotaEntrada, setIdNotaEntrada] = useState(null);
  const [openConfirmCiencia, setOpenConfirmCiencia] = useState(false);
  const [openConfirmDesconhecimento, setOpenConfirmDesconhecimento] =
    useState(false);
  const [openConfirmNaoRealizada, setOpenConfirmNaoRealizada] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [showModalDetalhesNota, setShowModalDetalhesNota] = useState(false);
  const [showModalItensNota, setShowModalItensNota] = useState(false);
  // TODO Mudar modal agendamento para false
  const [showModalAgendarPagamento, setShowModalAgendarPagamento] =
    useState(false);

  const [dateDe, setDateDe] = useState(null);
  const [dateAte, setDateAte] = useState(null);
  const [emitente, setEmitente] = useState(null);
  const [chaveAcesso, setChaveAcesso] = useState(null);
  const [numeroInicial, setNumeroInicial] = useState(null);
  const [numeroFinal, setNumeroFinal] = useState(null);
  const [status, setStatus] = useState(null);
  const [serieInicial, setSerieInicial] = useState(null);
  const [serieFinal, setSerieFinal] = useState(null);
  const [estado, setEstado] = useState(null);

  const [listStatus, setListStatus] = useState([]);
  const [listEstados, setListEstados] = useState([]);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [notaData, setNotaData] = useState({});

  const [listRequest, setListRequest] = useState([]);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Data emissão", dataRef: "data_emissao" },
      { columnName: "Emitente", dataRef: "emitente_razao_social" },
      { columnName: "UF", dataRef: "uf" },
      { columnName: "Número", dataRef: "numero" },
      { columnName: "Série", dataRef: "serie" },
      { columnName: "Total", dataRef: "valor_total" },
      // { columnName: "Status", dataRef: "status" },
    ],
    options: {
      moreDetails: [
        {
          icon: "find-file",
          label: "Dados da Nota Fiscal",
          action: "dados-nota-fiscal",
        },
        { icon: "clipboard", label: "Itens", action: "itens" },
      ],
      moreActions: [
        {
          icon: "check",
          label: "Ciência",
          action: "ciencia",
        },
        { icon: "check-square", label: "Confirmação", action: "confirmacao" },
        {
          icon: "unknow-file",
          label: "Desconhecimento",
          action: "desconhecimento",
        },
        { icon: "block", label: "Não realizada", action: "nao-realizada" },
      ],
      more: [
        {
          icon: "nf-e",
          label: "Download XML",
          action: "download-xml",
        },
        {
          icon: "nf-e",
          label: "Download PDF",
          action: "download-pdf",
        },
        {
          icon: "file-download",
          label: "Importar produto",
          action: "importar-produto",
        },
        {
          icon: "shopping-cart-check",
          label: "Agendar pagamento",
          action: "agendar-pagamento",
        },
      ],
    },
    display: {
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      filter: true,
      filterOnly: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  const filtersOnLocalStorage = localStorage.getItem(
    "contabilhub@confirmacao_produto"
  );
  const filtersOnLocalStorageParsed = filtersOnLocalStorage
    ? decodeURI(filtersOnLocalStorage)
        .split("&")
        .reduce((acc, item) => {
          const [key, value] = item.split("=");
          acc[key] = value;
          return acc;
        }, {})
    : null;

  let onMoreAction = async (action) => {
    switch (action.action) {
      case "dados-nota-fiscal": {
        setLoading(true);

        setShowModalDetalhesNota(true);
        setIdNotaEntrada(action.id);

        setLoading(false);
        break;
      }
      case "itens": {
        setLoading(true);

        setShowModalItensNota(true);
        setIdNotaEntrada(action.id);

        setLoading(false);
        break;
      }
      case "ciencia": {
        setLoading(true);

        setOpenConfirmCiencia(true);
        setIdNotaEntrada(action.id);

        setLoading(false);
        break;
      }
      case "confirmacao": {
        setLoading(true);

        setIdNotaEntrada(action.id);
        setOpenConfirm(true);

        setLoading(false);
        break;
      }
      case "desconhecimento": {
        setLoading(true);

        setIdNotaEntrada(action.id);
        setOpenConfirmDesconhecimento(true);

        setLoading(false);
        break;
      }
      case "nao-realizada": {
        setLoading(true);

        setIdNotaEntrada(action.id);
        setOpenConfirmNaoRealizada(true);

        setLoading(false);
        break;
      }
      case "download-xml": {
        setLoading(true);

        setIdNotaEntrada(action.id);
        onDownloadFile("xml");

        setLoading(false);
        break;
      }
      case "download-pdf": {
        setLoading(true);

        setIdNotaEntrada(action.id);
        onDownloadFile("pdf");

        setLoading(false);
        break;
      }
      case "importar-produto": {
        setLoading(true);

        const filters = {
          empresa,
          currentPage: defaultConfigTable.currentPage,
          orderBy: defaultConfigTable.orderBy,
          orderByType: defaultConfigTable.orderByType,
          perPage: defaultConfigTable.perPage,
          searchTerm: defaultConfigTable.searchTerm,
          dateDe,
          dateAte,
          emitente,
          chaveAcesso,
          numeroInicial,
          numeroFinal,
          status,
          serieInicial,
          serieFinal,
          estado,
        };
        const removeEmptyProperties = (obj) => {
          return Object.fromEntries(
            Object.entries(obj).filter(([, v]) => {
              if (v !== null || v !== "" || v !== undefined) {
                return v;
              }
            })
          );
        };
        const serialize = (obj) => {
          const str = [];
          for (let p in obj)
            if (obj.hasOwnProperty(p)) {
              str.push(
                encodeURIComponent(p) + "=" + encodeURIComponent(obj[p])
              );
            }
          return str.join("&");
        };

        const filtersClean = serialize(removeEmptyProperties(filters));

        history.push(`/confirmacao-produto-nota/${action.id}?${filtersClean}`);

        setLoading(false);
        break;
      }
      case "agendar-pagamento": {
        setLoading(true);

        const nota = listRequest.data.find((nota) => nota.id === action.id);
        // console.log(nota)
        setNotaData(nota);

        // TODO
        setShowModalAgendarPagamento(true);
        setIdNotaEntrada(action.id);
        // history.push(`/confirmacao-produto-nota/${action.id}`);

        setLoading(false);
        break;
      }
      default:
        break;
    }
  };

  const loadEstados = useCallback(async () => {
    const response = await selecionaEstado();

    if (response.status === 200) setListEstados(response.data);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  }, []);

  const loadStatus = useCallback(async () => {
    const response = await selecionaStatusNotaEntrada();

    if (response.status === 200) setListStatus(response.data);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  }, []);

  let updateTable = useCallback(
    async (params) => {
      window.scrollTo(0, 0);
      setLoading(true);

      const paramsToSend = {
        empresa: params?.empresa || empresa,
        currentPage: params?.currentPage || defaultConfigTable.currentPage,
        orderBy: params?.orderBy || defaultConfigTable.orderBy,
        orderByType: params?.orderByType || defaultConfigTable.orderByType,
        perPage: params?.perPage || defaultConfigTable.perPage,
        searchTerm: params?.searchTerm || defaultConfigTable.searchTerm,
        dateDe: params?.dateDe || dateDe,
        dateAte: params?.dateAte || dateAte,
        emitente: params?.emitente || emitente,
        chaveAcesso: params?.chaveAcesso || chaveAcesso,
        numeroInicial: params?.numeroInicial || numeroInicial,
        numeroFinal: params?.numeroFinal || numeroFinal,
        status: params?.status || status,
        serieInicial: params?.serieInicial || serieInicial,
        serieFinal: params?.serieFinal || serieFinal,
        estado: params?.estado || estado,
      };

      const response = await listaNotaEntrada(
        paramsToSend.empresa,
        paramsToSend.currentPage,
        paramsToSend.orderBy,
        paramsToSend.orderByType,
        paramsToSend.perPage,
        paramsToSend.searchTerm,
        paramsToSend.dateDe,
        paramsToSend.dateAte,
        paramsToSend.emitente,
        paramsToSend.chaveAcesso,
        paramsToSend.numeroInicial,
        paramsToSend.numeroFinal,
        paramsToSend.status,
        paramsToSend.serieInicial,
        paramsToSend.serieFinal,
        paramsToSend.estado
      );

      if (response.data) {
        if (response.data.hasOwnProperty("data")) {
          // response.data.data.map(item => console.log('Status: ', item.status));

          const { data } = response.data;

          const newData = data.map((item) => ({
            ...item,
            data_emissao: formatDate(item.data_hora_emissao.substring(0, 10)),
            id: item.id_nota_fiscal_produto_entrada,
            // valor_total: numberToCurrency(item.valor_total)
          }));

          const newResult = {
            ...response.data,
            data: newData,
          };

          setListRequest(newResult);
        } else {
          const { data } = response;

          const newData = data.map((item) => ({
            ...item,
            data_emissao: formatDate(item.data_hora_emissao.substring(0, 10)),
            id: item.id_nota_fiscal_produto_entrada,
            // valor_total: numberToCurrency(item.valor_total)
          }));

          const newResult = {
            ...response,
            data: newData,
          };

          setListRequest(newResult);
        }
      }
      setLoading(false);
    },
    [
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      listaSolicitacoes,
      dateAte,
      dateDe,
      empresa,
      emitente,
      chaveAcesso,
      numeroInicial,
      numeroFinal,
      serieInicial,
      serieFinal,
      estado,
      status,
    ]
  );

  let onPageChange = useCallback(
    (newPageObj) => {
      let newPage = newPageObj.page;
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.currentPage = newPage;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable(newDefaultConfigTable);
    },
    [defaultConfigTable, updateTable]
  );

  let onOrderBy = useCallback(
    (newOrderBy) => {
      let orderBy = newOrderBy.orderBy;
      let orderByType = newOrderBy.orderByType;
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.orderBy = orderBy;
      newDefaultConfigTable.orderByType = orderByType;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable(newDefaultConfigTable);
    },
    [defaultConfigTable, updateTable]
  );

  let onPerPage = useCallback(
    (newPerPage) => {
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.perPage = newPerPage;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable(newDefaultConfigTable);
    },
    [defaultConfigTable, updateTable]
  );

  let onSearchTerm = useCallback(
    (value) => {
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.searchTerm = value ? value : null;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable(newDefaultConfigTable);
    },
    [defaultConfigTable, updateTable]
  );

  let handleCienciaNota = async () => {
    setLoading(true);

    const response = await cienciaNotaEntrada(idNotaEntrada);

    if (response.status === 200) {
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Ciência na nota com sucesso!");
      setCallbackShown(true);
      updateTable();
    }

    if (response.status === 500) {
      setCallbackType("error");
      setCallbackDuration(2000);
      setCallbackMessage("Erro!");
      setCallbackErrorList([
        "Erro interno do servidor. Por favor, contate o suporte.",
      ]);
      setCallbackShown(true);
    }

    if (response.status !== 200 && response.status !== 500) {
      const { data } = response.response;

      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(data?.error);
    }

    setOpenConfirmCiencia(false);
    setLoading(false);
  };

  let handleConfirmNota = async () => {
    setLoading(true);

    const response = await confirmacaoNotaEntrada(idNotaEntrada);

    if (response.status === 200) {
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Confirmação na nota com sucesso!");
      setCallbackShown(true);
      updateTable();
    }

    if (response.status === 500) {
      setCallbackType("error");
      setCallbackDuration(2000);
      setCallbackMessage("Erro!");
      setCallbackErrorList([
        "Erro interno do servidor. Por favor, contate o suporte.",
      ]);
      setCallbackShown(true);
    }

    if (response.status !== 200 && response.status !== 500) {
      const { data } = response.response;

      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(data?.error);
    }

    setOpenConfirm(false);
    setLoading(false);
  };

  let handleDesconhecimentoNota = async () => {
    setLoading(true);

    const response = await desconhecimentoNotaEntrada(idNotaEntrada);

    if (response.status === 200) {
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Confirmação na nota com sucesso!");
      setCallbackShown(true);
      updateTable();
    }

    if (response.status === 500) {
      setCallbackType("error");
      setCallbackDuration(2000);
      setCallbackMessage("Erro!");
      setCallbackErrorList([
        "Erro interno do servidor. Por favor, contate o suporte.",
      ]);
      setCallbackShown(true);
    }

    if (response.status !== 200 && response.status !== 500) {
      const { data } = response.response;

      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(data?.error);
    }

    setOpenConfirmDesconhecimento(false);
    setLoading(false);
  };

  let handleNaoRealizacaoNota = async () => {
    setLoading(true);

    const response = await naoRealizacaoNotaEntrada(idNotaEntrada);

    if (response.status === 200) {
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Confirmação na nota com sucesso!");
      setCallbackShown(true);
      updateTable();
    }

    if (response.status === 500) {
      setCallbackType("error");
      setCallbackDuration(2000);
      setCallbackMessage("Erro!");
      setCallbackErrorList([
        "Erro interno do servidor. Por favor, contate o suporte.",
      ]);
      setCallbackShown(true);
    }

    if (response.status !== 200 && response.status !== 500) {
      const { data } = response.response;

      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(data?.error);
    }

    setOpenConfirmNaoRealizada(false);
    setLoading(false);
  };

  function handleClose() {
    setCallbackShown(false);
  }

  function handleCloseModal() {
    setShowModalDetalhesNota(false);
    setShowModalItensNota(false);
    setShowModalAgendarPagamento(false);
  }

  function listaStatus(list = []) {
    return list.map((item) =>
      item.status.toUpperCase() === "CANCELADO" ? (
        <div className="card-table-danger" key={item.id_empresa}>
          Cancelado
        </div>
      ) : (
        <div className="card-table-success" key={item.id_empresa}>
          Autorizado
        </div>
      )
    );
  }

  const handleApplyFilters = useCallback(() => {
    setLoading(true);

    let fields = [
      {
        label: "empresa",
        value: empresa,
      },
    ];

    const validateFieldsErrors = validateFields(fields);

    if (validateFieldsErrors.length !== 0) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackDuration(3000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(validateFieldsErrors);
      return;
    }

    setLoading(false);
    updateTable();
    // setParamsURL({ id_empresa: 150, data_de: dateFrom, data_ate: dateTo });
    setEmpresaPagina("entrada_nfe", empresa);
  }, [updateTable]);

  const onDownloadFile = async (type) => {
    setLoading(true);

    let response;

    if (type === "xml") {
      response = await downloadXMLNotaEntrada(idNotaEntrada);
    } else if (type === "pdf") {
      response = await downloadPDFNotaEntrada(idNotaEntrada);
    }

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status === 400) {
      let errorList = response?.data?.error
        ? typeof response.data.error === "object"
          ? Object.values(response.data.error).flat(Infinity)
          : response?.data?.error?.length > 0
          ? response?.data?.error
          : [response.data?.msg]
        : [response.data?.msg];
      let errorMsg =
        typeof response?.data?.error === "object"
          ? response?.data?.msg
          : "Erro!";
      setCallbackType("error");
      setCallbackMessage(errorMsg);
      setCallbackErrorList(errorList);
      setCallbackDuration(3000);
      setCallbackShown(true);
      setLoading(false);
      return;
    }

    if (response.status === 500) {
      setCallbackType("error");
      setCallbackErrorList(["Erro interno no servidor. Contate o suporte."]);
      setCallbackMessage("Erro!");
      setCallbackShown(true);
      setCallbackDuration(3000);
      setLoading(false);
      return;
    }

    if (response.status === 200 || response.status === 201) {
      if (type === "xml") {
        base64ToFile(
          "data:application/xml;base64," + response.data.xml,
          "nota_fiscal"
        );
      } else if (type === "pdf") {
        base64ToFile(
          "data:application/pdf;base64," + response.data.pdf,
          "nota_fiscal"
        );
      }
    }
    setLoading(false);
  };

  const handleCleanFilters = useCallback(() => {
    setLoading(true);

    setDateDe(null);
    setDateAte(null);
    setChaveAcesso(null);
    setEmpresa(null);
    setEmitente(null);
    setEstado(null);
    setNumeroFinal(null);
    setNumeroInicial(null);
    setSerieFinal(null);
    setSerieInicial(null);

    setLoading(false);
    setEmpresaPagina("entrada_nfe", null);
  }, []);

  function filterComponent() {
    return (
      <div className="session-container filter-component-container custom-filter-container">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12} sm={12} md={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                listEmpresas.find((item) => item.id === empresa)
                  ? listEmpresas.find((item) => item.id === empresa).nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={async (e) => {
                setEmpresa(e);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <DatePicker
              label="Emissão de"
              noMask
              initDate={dateDe}
              handleChange={(date) => setDateDe(date)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <DatePicker
              noMask
              label="Emissão até"
              initDate={dateAte}
              handleChange={(date) => setDateAte(date)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Input
              nome="emitente"
              label="Emitente"
              tipo="text"
              title="Insira a Razão Social ou o CNPJ do emitente"
              value={emitente}
              handleInputChange={(event) => {
                setEmitente(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Input
              nome="chave"
              label="Chave de Acesso"
              tipo="text"
              value={chaveAcesso}
              handleInputChange={(event) => {
                setChaveAcesso(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Input
              nome="numeroinicial"
              label="Número inicial"
              tipo="text"
              value={numeroInicial}
              handleInputChange={(event) => {
                setNumeroInicial(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Input
              nome="numerofinal"
              label="Número final"
              tipo="text"
              value={numeroFinal}
              handleInputChange={(event) => {
                setNumeroFinal(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Select
              styleType="form"
              label="Status"
              initialValue={
                listStatus.find((item) => item.id === status)
                  ? listStatus.find((item) => item.id === status).nome
                  : ""
              }
              title="Selecione uma opção"
              list={listStatus}
              callback={(e) => {
                setStatus(e);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Input
              nome="serieinicial"
              label="Série inicial"
              tipo="text"
              value={serieInicial}
              handleInputChange={(event) => {
                setSerieInicial(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Input
              nome="seriefinal"
              label="Série final"
              tipo="text"
              value={serieFinal}
              handleInputChange={(event) => {
                setSerieFinal(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Select
              styleType="form"
              label="UF"
              initialValue={
                listEstados.find((item) => item.id === estado)
                  ? listEstados.find((item) => item.id === estado).nome
                  : ""
              }
              title="Selecione uma opção"
              list={listEstados}
              callback={(id) => {
                let selectedEstado = listEstados.find((item) => item.id === id);
                setEstado(selectedEstado.uf);
              }}
            />
          </Grid>

          <div className="filter-button-area">
            <Button
              onClick={handleApplyFilters}
              text="Filtrar"
              className="btn-primary"
            />
            <Button
              onClick={handleCleanFilters}
              text="Limpar Filtros"
              className="default-outline clean-filters-button"
            />
          </div>
        </Grid>
      </div>
    );
  }

  useEffect(() => {
    (async function () {
      setLoading(true);
      await loadEstados();
      await loadStatus();
      setLoading(false);
    })();

    return () => {
      localStorage.removeItem("contabilhub@confirmacao_produto");
    };
  }, []);

  useEffect(() => {
    if (!dateDe) {
      let year = new Date().getFullYear();
      let initDateYear = new Date(year, 0, 1);
      const dateInitBeforeSetFormat = toDateYYYY_MM_DD(initDateYear);
      setDateDe(dateInitBeforeSetFormat);
    }

    if (!dateAte) {
      const dateInitSet = new Date();
      const dateInitSetFormat = toDateYYYY_MM_DD(dateInitSet);
      setDateAte(dateInitSetFormat);
    }
  }, [dateDe, dateAte]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    if (filtersOnLocalStorage) {
      setEmpresa(Number(filtersOnLocalStorageParsed.empresa || 0));
      setDateDe(filtersOnLocalStorageParsed.dateDe || null);
      setDateAte(filtersOnLocalStorageParsed.dateAte || null);
      setEmitente(filtersOnLocalStorageParsed.emitente || null);
      setChaveAcesso(filtersOnLocalStorageParsed.chaveAcesso || null);
      setNumeroInicial(filtersOnLocalStorageParsed.numeroInicial || null);
      setNumeroFinal(filtersOnLocalStorageParsed.numeroFinal || null);
      setStatus(filtersOnLocalStorageParsed.status || null);
      setSerieInicial(filtersOnLocalStorageParsed.serieInicial || null);
      setSerieFinal(filtersOnLocalStorageParsed.serieFinal || null);
      setEstado(filtersOnLocalStorageParsed.estado || null);

      const newDefaultConfigTable = { ...defaultConfigTable };
      newDefaultConfigTable.currentPage =
        Number(filtersOnLocalStorageParsed?.page) || 1;
      newDefaultConfigTable.perPage =
        Number(filtersOnLocalStorageParsed?.perPage) || 10;
      newDefaultConfigTable.orderBy = filtersOnLocalStorageParsed?.orderBy;
      filtersOnLocalStorageParsed?.orderByType;
      newDefaultConfigTable.searchTerm =
        filtersOnLocalStorageParsed?.searchTerm;

      setDefaultConfigTable(newDefaultConfigTable);
      updateTable({
        empresa: Number(filtersOnLocalStorageParsed.empresa || 0),
        currentPage: Number(filtersOnLocalStorageParsed?.page) || 1,
        orderBy: filtersOnLocalStorageParsed?.orderBy,
        orderByType: filtersOnLocalStorageParsed?.orderByType,
        perPage: Number(filtersOnLocalStorageParsed?.perPage) || 10,
        searchTerm: filtersOnLocalStorageParsed?.searchTerm,
        dateDe: filtersOnLocalStorageParsed?.dateDe || null,
        dateAte: filtersOnLocalStorageParsed?.dateAte || null,
        emitente: filtersOnLocalStorageParsed?.emitente || null,
        chaveAcesso: filtersOnLocalStorageParsed?.chaveAcesso || null,
        numeroInicial: filtersOnLocalStorageParsed?.numeroInicial || null,
        numeroFinal: filtersOnLocalStorageParsed?.numeroFinal || null,
        status: Number(filtersOnLocalStorageParsed.status) || null,
        serieInicial: filtersOnLocalStorageParsed?.serieInicial || null,
        serieFinal: filtersOnLocalStorageParsed?.serieFinal || null,
        estado: filtersOnLocalStorageParsed?.estado || null,
      });
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbMoreAction={onMoreAction}
        filterComponentCustom={filterComponent()}
        customColumns={[
          {
            columnHead: <span>Status</span>,
            columnData: listaStatus(listRequest?.data),
          },
        ]}
      />

      <ModalItensNota
        idNota={showModalItensNota ? idNotaEntrada : null}
        open={showModalItensNota}
        onClose={handleCloseModal}
      />

      <ModalDetalhesNota
        idNota={showModalDetalhesNota ? idNotaEntrada : null}
        open={showModalDetalhesNota}
        onClose={handleCloseModal}
      />

      <ModalAgendarPagamento
        // idNota={showModalAgendarPagamento ? idNotaEntrada : null}
        open={showModalAgendarPagamento}
        onClose={handleCloseModal}
        // empresas={listEmpresas}
        notaData={notaData}
      />
      <ConfirmDialog
        open={openConfirmNaoRealizada}
        title="MANIFESTAR NÃO REALIZAÇÃO DE NOTA"
        description="Você tem certeza que deseja manifestar não realização a essa nota?"
        cancelLabel="Cancelar"
        acceptLabel="Confirmar"
        onCancel={() => setOpenConfirmNaoRealizada(false)}
        onClose={() => setOpenConfirmNaoRealizada(false)}
        onAccept={handleNaoRealizacaoNota}
      />
      <ConfirmDialog
        open={openConfirmDesconhecimento}
        title="MANIFESTAR DESCONHECIMENTO DE NOTA"
        description="Você tem certeza que deseja manifestar desconhecimento a essa nota?"
        cancelLabel="Cancelar"
        acceptLabel="Confirmar"
        onCancel={() => setOpenConfirmDesconhecimento(false)}
        onClose={() => setOpenConfirmDesconhecimento(false)}
        onAccept={handleDesconhecimentoNota}
      />
      <ConfirmDialog
        open={openConfirm}
        title="MANIFESTAR CONFIRMAÇÃO DE NOTA"
        description="Você tem certeza que deseja manifestar confirmação a essa nota?"
        cancelLabel="Cancelar"
        acceptLabel="Confirmar"
        onCancel={() => setOpenConfirm(false)}
        onClose={() => setOpenConfirm(false)}
        onAccept={handleConfirmNota}
      />
      <ConfirmDialog
        open={openConfirmCiencia}
        title="MANIFESTAR CIÊNCIA DE NOTA"
        description="Você tem certeza que deseja manifestar ciência a essa nota?"
        cancelLabel="Cancelar"
        acceptLabel="Confirmar"
        onCancel={() => setOpenConfirmCiencia(false)}
        onClose={() => setOpenConfirmCiencia(false)}
        onAccept={handleCienciaNota}
      />
      {/* <ConfirmDialog
       open={openConfirmDelete}
       title="CONFIRMAR CANCELAMENTO"
       description="Você tem certeza que deseja realizar o cancelamento da solicitação e da fatura?"
       cancelLabel="Voltar"
       acceptLabel="Cancelar"
       onAccept={onDelete}
       onCancel={onCancelDelete}
       onClose={onCancelDelete}
      /> */}
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        handleClose={handleClose}
        message={callbackMessage}
        duration={callbackDuration}
        errorList={callbackErrorList}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
