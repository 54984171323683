import React, { useState, useCallback, useContext, useEffect } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { NavbarContext } from "../../../contexts/NavbarContext";

import ButtonComponent from "../../ButtonComponent";
import SelectComponent from "../../SelectComponent";
import Switch from "../../Switch";
import OverlayLoading from "../../OverlayLoading";
import CallbackMessage from "../../CallbackMessage";
import blackFridayServices from "../../../services/blackFridayServices";
import Input from '../../Input';
import Icon from '../../Icon';
import DatePicker from "../../DatePicker";


import selectServices from "../../../services/selectServices";
import perfilTributarioService from "../../../services/perfilTributarioService";

import validateFields from '../../../utils/validateFields';

import "./styles.scss";

function ModalMigrar({ open, onClose, data, companyId }) {
  const [, setIsEdit] = useState(false);

  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const id = companyId || window.localStorage.getItem("empresa_usuario");
  const { readNotification } = useContext(NavbarContext);
  const [tipoEmpresa, setTipoEmpresa] = useState();
  const [regime, setRegime] = useState();
  const [possuiFuncionarios, setPossuiFuncionarios] = useState(false);
  const [listTipo, setListTipo] = useState([]);
  const [listRegime, setListRegime] = useState([]);
  const [senhaGovBr, setSenhaGovBr] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [codigoSimples, setCodigoSimples] = useState("");
  const [nascResponsavel, setNascResponsavel] = useState(null);

  const handleGovBrPassVisibility = () => {
    setIsPasswordVisible(prev => !prev);
  };
  
  useEffect(() => {
    let mounted = true;

    if (data) {
      if (mounted) {

        console.log('data::::', data);
        setIsEdit(true);
        setTipoEmpresa(data.id_tipo_empresa);
        setRegime(data.regime_tributario);
        setPossuiFuncionarios(data.possui_funcionarios === 1 ? true : false);
        setSenhaGovBr(data.senha_govbr);
        setCodigoSimples(data?.codigo_simples);
        setNascResponsavel(data?.data_nascimento)
      }
    }

    return () => mounted = false;
  }, [data])

  async function handleSubmit() {
    setLoading(true);

    const fieldsToValidate = [
      {
        label: 'tipodeempresa',
        value: tipoEmpresa,
      },
      {
        label: 'tiporegimetributario',
        value: regime,
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      setErrors(fieldsErrors);
      setShowMessage(true);

      setLoading(false);
      return;
    }

    const field = {
      id_tipo_empresa: tipoEmpresa,
      regime_tributario: regime,
      possui_funcionarios: possuiFuncionarios ? 1 : 0,
      tipo_cadastro: 2,
      senha_govbr: senhaGovBr,
      codigo_simples: codigoSimples,
      data_nascimento: nascResponsavel,
    }

    const response = await blackFridayServices.cadastraComplemento(id, field);
    if (response.status === 200 || response.status === 201) {
      open = false;
      setLoading(false);
      setShowMessage(true);
      setErrors([]);
    } else {
      if (response.data.error)
        setErrors(Object.values(response.data.error));
      else setErrors(["Ocorreu um erro"]);
      setShowMessage(true);
      setLoading(false);
    }
  }

  const loadListRegime = useCallback(async () => {
    const response = await perfilTributarioService.getTributario();
    if (response.status === 200) setListRegime(response.data);
  }, []);

  const loadListTipo = useCallback(async () => {
    const response = await selectServices.selecionaTipoEmpresa();
    if (response.status === 200) setListTipo(response.data);
  }, []);

  function handleClose() {
    setShowMessage(false);
    if (errors.length === 0) {
      readNotification();
      onClose();
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadListRegime();
      await loadListTipo();
      setLoading(false);
    })();
  }, [loadListRegime, loadListTipo])

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>CADASTRO COMPLEMENTAR</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <h1 className="cadastro-first-title">1. Sobre a empresa</h1>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <SelectComponent
                  styleType="form"
                  label="Qual tipo de empresa?"
                  required
                  list={listTipo}
                  initialValue={listTipo.find(item => item.id === tipoEmpresa) ? listTipo.find(item => item.id === tipoEmpresa).nome : ''}
                  callback={(id) => {
                    setTipoEmpresa(id);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <SelectComponent
                  styleType="form"
                  label="Qual o regime tributário da sua empresa? *"
                  list={listRegime}
                  initialValue={listRegime.find(item => item.id === regime) ? listRegime.find(item => item.id === regime).nome : ''}
                  callback={(id) => {
                    setRegime(id);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              
              <Grid item xs={12} sm={12} md={6}>
                <Input
                  label="Senha portal Gov.br"
                  value={senhaGovBr}
                  tooltipType="infoButton"
                  labelSuffix={{
                    icon: "export",
                    title: "Portal Gov.br",
                    onClick: () => {
                      window.open('https://sso.acesso.gov.br/login?client_id=contas.acesso.gov.br&authorization_id=176f186eaf', '_blank');
                    }
                  }}
                  type={isPasswordVisible ? "text" : "password"}
                  handleInputChange={event => setSenhaGovBr(event.target.value)}
                />
                <button
                onClick={handleGovBrPassVisibility}
                className="gov-password-icon"
                type="button"
              >
                <div className="gov-password-icon-container">
                  {isPasswordVisible ? (
                    <Icon name="open-eye" />
                  ) : (
                      <Icon name="closed-eye" />
                    )}
                </div>
              </button>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Input
                  label="Código do Simples"
                  value={codigoSimples}
                  tooltipType="infoButton"
                  type="text"
                  handleInputChange={event => setCodigoSimples(event.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
              <h1 className="cadastro-form-title">2. Responsável pela empresa</h1>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
                <DatePicker
                  initDate={nascResponsavel}
                  readOnly={!data?.id_socio}
                  label="Data de nascimento"
                  noMask
                  handleChange={(value) => setNascResponsavel(value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div className="label">
                  Possui funcionários?
                </div>
                <Grid item container spacing={2} alignItems="center" className="cadastro-switch-area">
                  <Grid item className={
                    `${possuiFuncionarios
                      ? "cadastro-font-inactive"
                      : "cadastro-font-active"}`
                  }
                  >
                    Não
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={possuiFuncionarios}
                      onClick={() => {
                        setPossuiFuncionarios(prev => !prev);
                      }}
                    />
                  </Grid>
                  <Grid item className={`
                      cadastro-switch-accept
                      ${possuiFuncionarios
                      ? "cadastro-font-active"
                      : "cadastro-font-inactive"}`}
                  >
                    Sim
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <span className="required-text">
                * Campos obrigatórios
              </span>
            </Grid>
            <Grid item container justify="flex-end">
              <ButtonComponent text="Salvar" onClick={handleSubmit} className="btn-success zero-margin" />
            </Grid>
          </Grid>
        </form>
        <CallbackMessage
          open={showMessage}
          type={`${errors.length === 0 ? "success" : "error"}`}
          message={`${errors.length === 0
            ? "Cadastro complementado com sucesso!"
            : "Erro!"
            }`}
          errorList={errors}
          handleClose={handleClose}
          duration={errors.length === 0 ? 4000 : 6000}
        />
      </div>
    </Dialog>
  );
}

export default ModalMigrar;