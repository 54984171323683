import React, { useState, useEffect, useCallback } from "react";
import { Grid, useMediaQuery } from "@material-ui/core";

import { Search } from "../../components/TableComponent";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import OverlayLoading from "../../components/OverlayLoading";

import dreService from "../../services/dreService";
import selectServices from "../../services/selectServices";

import "./styles.scss";
import CallbackMessage from "../../components/CallbackMessage";
import validateFields from "../../utils/validateFields";
import exportData from "../../services/exportData";
import { ModalEnviarEmail } from "../../components/ModalEnviarEmail";
import fluxoCaixaService from "../../services/fluxoCaixaService";
import { Filter } from "../../components/FilterComponent";
import { EmptyDre } from "../DRE/componentes";
import { AccordionDre } from "./componentes/accordion";
import { AccordionDreMobile } from "./componentes/accordionMobile";
import useCompany from "../../hooks/useCompany";

const tableOptions = {
  noSearch: true,
  filter: true,
  more: true,
  moreOptions: [
    {
      icon: "export",
      label: "Exportar XLS",
      action: "exportar-xls",
    },
    {
      icon: "export",
      label: "Exportar PDF",
      action: "exportar-pdf",
    },
    {
      icon: "email",
      label: "Enviar e-mail",
      action: "enviar-e-mail",
    },
  ],
};

const exportToPDF = {
  url: "exportar-relatorio-fluxo-caixa-pdf",
  fileName: "cbhub_relatorio_fluxo_caixa",
  fileType: "pdf",
};

const exportToXLS = {
  url: "exportar-relatorio-fluxo-caixa-xls",
  fileName: "cbhub_relatorio_fluxo_caixa",
  fileType: "xlsx",
};

function filterComponent({
  listEmpresas,
  listMeses,
  listAnos,
  setEmpresaId,
  setMesId,
  setAno,
  setShowAllCategories,
  mesId,
  empresaId,
  ano,
  showAllCategories,
  handleApplyFilters,
  handleCleanFilters,
}) {
  return (
    <Filter.Root>
      <Filter.Select
        callback={(id) => {
          setEmpresaId(id);
        }}
        label="Empresa"
        options={listEmpresas}
        value={empresaId}
        xs={12}
        initialValue={
          listEmpresas?.find((item) => item.id === empresaId)?.nome || null
        }
      />
      <Filter.Select
        xs={12}
        md={6}
        callback={(id) => {
          setMesId(id);
        }}
        label="Mês"
        options={listMeses}
        title="Selecione o mês"
        value={mesId}
        required={false}
      />
      <Filter.Select
        xs={12}
        md={6}
        callback={(id) => {
          setAno(id);
        }}
        label="Ano"
        title="Selecione o ano"
        options={listAnos}
        value={ano}
      />
      <Filter.Checkbox
        text="Retornar as categorias que não possuem contas cadastradas"
        id="showAllCategories"
        onChange={(value) => setShowAllCategories(value ? 1 : 0)}
        value={showAllCategories}
        xs={12}
      />
      <Filter.Buttons>
        <Filter.Button
          text="Filtrar"
          onClick={handleApplyFilters}
          className="btn-primary w-full fluxo-caixa"
        />

        <Filter.Button
          text="Limpar Filtros"
          onClick={handleCleanFilters}
          className="default-outline clean-filters-button"
        />
      </Filter.Buttons>
    </Filter.Root>
  );
}

function HeaderTable({ onTableMoreAction, tableOptions, ...rest }) {
  return (
    <Search
      callback={() => {}}
      tableOptions={tableOptions}
      filterComponent={filterComponent({ ...rest })}
      callbackMenu={onTableMoreAction}
    />
  );
}

function FluxoCaixa() {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const {
    companyList: listEmpresas,
    selectedCompany: empresaId,
    setSelectedCompany: setEmpresaId,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("fluxo_caixa");

  const [loading, setLoading] = useState(false);
  const [listMeses, setListMeses] = useState([]);
  const [centroCustoId, setCentroCustoId] = useState("");
  const [mesId, setMesId] = useState(null);
  const [showAllCategories, setShowAllCategories] = useState(0);
  const [daysOfMonth, setDaysOfMonth] = useState([]);
  const [ano, setAno] = useState(null);
  const [filterOptions, setFilterOptions] = useState({
    id_empresa: null,
    mes: null,
    ano: null,
    all: 0,
  });
  const [listRequest, setListRequest] = useState();
  const [listAnos, setListAnos] = useState([]);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);

  const [openModalEmail, setOpenModalEmail] = useState(false);

  async function loadMeses() {
    const response = await selectServices.selecionaMeses();
    if (response.status === 200) {
      const data = Object.keys(response.data).map((key) => ({
        id: key,
        nome: response.data[key],
      }));
      setListMeses(data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  }

  async function loadAnos() {
    const response = await selectServices.selecionaAnos();

    if (response.status === 200) {
      const data = Object.keys(response.data).map((key) => ({
        id: response.data[key],
        nome: response.data[key].toString(),
      }));
      setListAnos(data.reverse());
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  }

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);

    const response = await fluxoCaixaService.getFluxoCaixa({
      ...filterOptions,
    });
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    // setListRequest(mockResponse)
    if (response.status === 200) {
      setListRequest(response.data);
    } else {
    }
    setLoading(false);
  }, [
    dreService,
    filterOptions,
    setListRequest,
    setLoading,
    setOpenLoginExpirado,
  ]);

  let onTableMoreAction = async ({ action }) => {
    let fields = [
      {
        label: "empresa",
        value: filterOptions.id_empresa,
      },
      {
        label: "ano",
        value: filterOptions.ano,
      },
    ];

    const validateFieldsErrors = validateFields(fields);

    if (validateFieldsErrors.length !== 0) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackDuration(3000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(validateFieldsErrors);
      setLoading(false);
      return;
    }

    let params = {};
    if (action === "enviar-e-mail") {
      setOpenModalEmail(true);
      return;
    } else if (action === "exportar-xls") {
      params = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: { ...filterOptions },
      };
    } else if (action === "exportar-pdf") {
      params = {
        url: exportToPDF.url,
        fileName: exportToPDF.fileName,
        fileType: exportToPDF.fileType,
        params: { ...filterOptions },
      };
    }

    setLoading(true);
    const response = await exportData(params);

    if (response.status === 500) {
      setCallbackErrorList([
        "Erro interno no servidor. Por favor, contate o suporte",
      ]);
      setCallbackShown(true);
      setCallbackType("error");
    } else if (response.status !== 201 && response.status !== 200) {
      setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
      setCallbackShown(true);
      setCallbackType("error");
    }

    setLoading(false);
  };

  function handleClose() {
    setCallbackShown(false);
    setCallbackType("");
  }

  async function handleSendEmail(data = false) {
    if (!data) {
      setOpenModalEmail(false);
      return;
    }
    setLoading(true);
    const cco = data.cco?.split(";")?.map((item) => {
      return item?.trim();
    });
    const emails = data.para?.split(";")?.map((item) => {
      return item?.trim();
    });
    const response = await fluxoCaixaService.enviarEmailRelatorio({
      ...filterOptions,
      assunto: data.assunto,
      cco,
      emails,
      mensagem: data.mensagem,
    });
    if (response?.status === 200) {
      setCallbackErrorList([]);
      setCallbackMessage([
        response?.data?.msg ? response?.data?.msg : "Relátorio enviado",
      ]);
      setCallbackShown(true);
      setCallbackType("success");
      setOpenModalEmail(false);
    } else if (response.status === 400) {
      setCallbackErrorList(Object.values(response?.data?.error).flat(Infinity));
      setCallbackDuration(3000);
      setCallbackMessage("Erro");
      setCallbackShown(true);
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Ocorreu um erro"]);
    }

    setLoading(false);
  }

  const handleCleanFilters = async () => {
    setEmpresaId(null);
    setAno(null);
    setMesId(null);
    setShowAllCategories(0);
    setListRequest(null);
    setEmpresaPagina("fluxo_caixa", null);
  };

  const handleApplyFilters = () => {
    let fields = [
      {
        label: "empresa",
        value: empresaId,
      },
      {
        label: "ano",
        value: ano,
      },
    ];
    setEmpresaPagina("fluxo_caixa", empresaId);

    const validateFieldsErrors = validateFields(fields);

    if (validateFieldsErrors.length !== 0) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackDuration(3000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(validateFieldsErrors);
      setLoading(false);
      return;
    }
    setFilterOptions({
      id_empresa: empresaId,
      mes: mesId,
      ano: ano,
      all: showAllCategories,
    });
  };

  useEffect(() => {
    (async function () {
      setLoading(true);
      loadMeses();
      loadAnos();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (
      (empresaId !== "" && ano !== "") ||
      (centroCustoId !== "" && empresaId !== "" && ano !== "")
    ) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    if (mesId && ano) {
      function getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
      }

      const amountOfDays = getDaysInMonth(ano, mesId);
      setDaysOfMonth(
        Array(amountOfDays)
          .fill(0)
          .map((item, index) => ({ id: index + 1, nome: index + 1 }))
      );
    }
  }, [mesId, ano]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("fluxo_caixa");
    if (empresaStorage) {
      setEmpresaId(empresaStorage);
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <div className="table-no-header relatorio-fluxo-caixa">
        <HeaderTable
          listEmpresas={listEmpresas}
          listMeses={listMeses}
          listAnos={listAnos}
          setEmpresaId={setEmpresaId}
          setMesId={setMesId}
          setAno={setAno}
          setShowAllCategories={setShowAllCategories}
          mesId={mesId}
          empresaId={empresaId}
          ano={ano}
          showAllCategories={showAllCategories}
          handleApplyFilters={handleApplyFilters}
          handleCleanFilters={handleCleanFilters}
          onTableMoreAction={onTableMoreAction}
          tableOptions={tableOptions}
        />
        {listRequest ? (
          <div className="session-container">
            <span className="session-container-header">
              <Grid
                container
                direction="column"
                className="container-fluxo-caixa container-relatorio"
              >
                {!isMobile && (
                  <>
                    <Grid
                      item
                      spacing={3}
                      className="container-row-fluxo-caixa"
                    >
                      <Grid
                        item
                        className="name-categoria header title-categoria"
                      >
                        Categoria
                      </Grid>
                      <Grid item className="name-tipo-categoria">
                        Tipo de categoria
                      </Grid>
                      {filterOptions.mes
                        ? daysOfMonth.map((item, index) => (
                            <Grid item key={index} className="name-column">
                              {Intl.NumberFormat("pt-BR", {
                                minimumIntegerDigits: 2,
                                maximumFractionDigits: 0,
                              }).format(item.nome)}
                            </Grid>
                          ))
                        : listMeses.map((item, index) => (
                            <Grid item key={index} className="name-column">
                              {item?.nome?.substring(0, 3)}
                            </Grid>
                          ))}
                      <Grid
                        item
                        className="name-column total-column header-total"
                      >
                        Total
                      </Grid>
                    </Grid>
                    <div style={{ height: 40 }} />
                  </>
                )}

                <Grid item xs sm md>
                  {isMobile ? (
                    <AccordionDreMobile
                      columns={filterOptions.mes ? daysOfMonth : listMeses}
                      dados={listRequest}
                    />
                  ) : (
                    <AccordionDre
                      columns={filterOptions.mes ? daysOfMonth : listMeses}
                      dados={listRequest}
                    />
                  )}
                </Grid>
              </Grid>
            </span>
          </div>
        ) : (
          <EmptyDre />
        )}
        <CallbackMessage
          type={callbackType}
          open={callbackShown}
          errorList={callbackErrorList}
          duration={callbackDuration}
          message={callbackMessage}
          handleClose={() => handleClose()}
        />
        <ModalEnviarEmail
          open={openModalEmail}
          onClose={handleSendEmail}
          setCallbackErrorList={setCallbackErrorList}
          callbackErrorList={callbackErrorList}
          callbackShown={callbackShown}
          setCallbackShown={setCallbackShown}
        />
        <ModalLoginExpirado open={openLoginExpirado} />
      </div>
    </>
  );
}

export default FluxoCaixa;
