import React, { useState, useCallback, useEffect, useMemo, memo } from "react";
import clsx from "clsx";
import {
  format,
  startOfWeek,
  endOfWeek,
  isSameDay,
  startOfDay,
  endOfDay,
  isWithinInterval,
  isValid,
  eachDayOfInterval
} from "date-fns";
import { DatePicker } from "@material-ui/pickers";
import { ptBR } from "date-fns/locale";

import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import { Search } from "../../components/TableComponent";
import Select from "../../components/SelectComponent";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import OverlayLoading from "../../components/OverlayLoading";
import Icon from "../../components/Icon";

import corretoresService from "../../services/corretoresService";

import "./styles.scss";
import styles from "./styles.js";

const useStyles = makeStyles(styles);

const Header = memo(({ handleSearchChange = () => {}, tableOptions }) => {
  return (
    <Search
      callback={handleSearchChange}
      tableOptions={tableOptions}
      customFilterTitle="Corretores e Turnos"
    />
  );
});

function ListaCorretoresTurnos() {
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const classes = useStyles();
  const [sortState, setSortState] = useState(1);
  const [page, setPage] = useState(1);
  const perPageSelect = [
    { id: 10, nome: "10 por página" },
    { id: 25, nome: "25 por página" },
    { id: 50, nome: "50 por página" },
    { id: 100, nome: "100 por página" }
  ];
  const [listRequest, setListRequest] = useState([]);
  const [loading, setLoading] = useState(false);

  const currentDate = new Date();

  const [selectedDate, setSelectedDate] = useState(currentDate);

  const start = startOfWeek(selectedDate, { locale: ptBR, weekStartsOn: 1 });
  const end = endOfWeek(selectedDate, { locale: ptBR, weekStartsOn: 1 });

  const initialInterval = eachDayOfInterval({ start: start, end: end });
  const [intervalDate, setIntervalDate] = useState(initialInterval);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    data_inicial: startOfDay(start),
    data_final: endOfDay(end),
  });

  const tableOptions = useMemo(() => ({
    filter: false,
    noSearch: false
  }), []);

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await corretoresService.listaCorretoresTurno(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      format(defaultConfigTable.data_inicial, "yyyy-MM-dd"),
      format(defaultConfigTable.data_final, "yyyy-MM-dd"),
    );
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.data.hasOwnProperty("data")) {
      setListRequest(result.data);
    } else {
      setListRequest(result);
    }
    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.data_inicial,
    defaultConfigTable.data_final
  ]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  let handleSearchChange = useCallback(value => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : "";
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, []);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  let onOrderBy = () => {
    let orderByType = null;
    if (sortState === 1) {
      setSortState(2);
      orderByType = "asc";
    } else {
      setSortState(1);
      orderByType = "desc";
    }
    let orderBy = "nome_razao_social";
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const CustomTable = () => {
    const getTurno = turno => {
      if (turno === "D") {
        return <div className="rectangleDay">D</div>;
      }
      if (turno === "M") {
        return (
          <div className="rectangleManha">
            <div>M</div>
            <div />
          </div>
        );
      }
      if (turno === "T") {
        return (
          <div className="rectangleTarde">
            <div />
            <div>T</div>
          </div>
        );
      }
      return;
    };

    const handleChangePage = (event, value) => {
      setPage(value);
      let newPage = value;
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.currentPage = newPage;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    };

    const perPageSelectCb = value => {
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.perPage = value;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    };

    const capitalize = str => {
      if (typeof str !== "string") {
        return "";
      }
      return str.charAt(0).toUpperCase() + str.substr(1);
    };

    return (
      <TableContainer className="MuiTableContainer-selfContainer">
        <MonthAndWeek />
        {loading && <OverlayLoading />}
        <Table aria-label="Table">
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="table-head-item-container" onClick={onOrderBy}>
                  <div className="table-head-item-title">Razão Social/Nome</div>
                  <div className="order-icons">
                    <div
                      className={`arrowDown ${
                        sortState === 1 && "arrowDown-active"
                      }`}
                    ></div>
                    <div
                      className={`arrowUp ${
                        sortState === 2 && "arrowUp-active"
                      }`}
                    ></div>
                  </div>
                </div>
              </TableCell>
              {intervalDate.map(interval => {
                return (
                  <TableCell key={interval}>
                    <div className="tableHeaderWeeks">
                      <span className="tableHeadWeekDayNumber">
                        {format(interval, "d")}
                      </span>
                      <span className="tableHeadWeekDay">
                        {format(interval, "EEEE", { locale: ptBR }) === "sábado"
                          ? capitalize(
                              format(interval, "EEEE", { locale: ptBR })
                            )
                          : format(interval, "EEEE", { locale: ptBR }) ===
                            "domingo"
                          ? capitalize(
                              format(interval, "EEEE", { locale: ptBR })
                            )
                          : capitalize(
                              format(interval, "EEEE", {
                                locale: ptBR
                              })
                            )}
                      </span>
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {listRequest.data &&
              listRequest.data.map(({ nome_razao_social, turnos }, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{nome_razao_social}</TableCell>
                    {intervalDate.map((interval, index) => {
                      const sameDay = turnos.find(item => {
                        const formattedDate = item.data_hora.substring(0, 10);
                        return isSameDay(
                          new Date(
                            format(interval, "yyyy-MM-dd").replace("-", ",")
                          ),
                          new Date(formattedDate.replace("-", ","))
                        );
                      });
                      if (sameDay && sameDay !== undefined) {
                        return (
                          <TableCell key={index}>
                            {getTurno(sameDay.turno)}
                          </TableCell>
                        );
                      }
                      return <TableCell key={index}></TableCell>;
                    })}
                  </TableRow>
                ))}
            {listRequest.data && listRequest.data.length === 0 && (
              <TableRow className="table-row">
                <TableCell colSpan={8}>Nenhum item encontrado</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="table-footer">
          <div className="table-perpage-wrapper">
            <Select
              initialValue={
                defaultConfigTable.perPage
                  ? `${defaultConfigTable.perPage} por página`
                  : perPageSelect[0].nome
              }
              list={perPageSelect}
              callback={perPageSelectCb}
            />
          </div>
          <div className="table-total-data-lis-wrapper">
            <span className="table-total-data-list">
              {`Total: ${listRequest.total || "0"} resultados`}
            </span>
          </div>
          <div className="table-configuration">
            <Pagination
              count={defaultConfigTable.totalPages} // last_page
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </div>
        </div>
      </TableContainer>
    );
  };

  const MonthAndWeek = () => {
    const handleWeekChange = date => {
      setSelectedDate(startOfWeek(date, { locale: ptBR, weekStartsOn: 1 }));
      const newStart = startOfWeek(date, { locale: ptBR, weekStartsOn: 1 });
      const newEnd = endOfWeek(date, { locale: ptBR, weekStartsOn: 1 });
      setIntervalDate(
        eachDayOfInterval({
          start: newStart,
          end: newEnd
        })
      );
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.data_inicial = startOfDay(newStart);
      newDefaultConfigTable.data_final = endOfDay(newEnd);
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    };

    const formatWeekSelectLabel = (date, invalidLabel) => {
      let dateClone = date;

      return dateClone && isValid(dateClone)
        ? "Selecione outra semana"
        : invalidLabel;
    };

    const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
      let dateClone = date;
      let selectedDateClone = selectedDate;

      const start = startOfWeek(selectedDateClone, { weekStartsOn: 1 });
      const end = endOfWeek(selectedDateClone, { weekStartsOn: 1 });

      const dayIsBetween = isWithinInterval(dateClone, { start, end });
      const isFirstDay = isSameDay(dateClone, start);
      const isLastDay = isSameDay(dateClone, end);

      const wrapperClassName = clsx({
        [classes.highlight]: dayIsBetween,
        [classes.firstHighlight]: isFirstDay,
        [classes.endHighlight]: isLastDay
      });

      const dayClassName = clsx(classes.day, {
        [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
        [classes.highlightNonCurrentMonthDay]:
          !dayInCurrentMonth && dayIsBetween
      });

      return (
        <div className={wrapperClassName}>
          <IconButton className={dayClassName}>
            <span> {format(dateClone, "d")} </span>
          </IconButton>
        </div>
      );
    };
    return (
      <div className="monthWrapper">
        <div>
          <span className="titleMonth">
            {format(selectedDate, "MMMM yyyy", { locale: ptBR }).toUpperCase()}
            <hr style={{ width: "200px" }} />
          </span>
        </div>
        <div>
          <span className="titleWeek">
            {`Semana de ${format(start, "d", { locale: ptBR })} ao ${format(end, "d", { locale: ptBR })}`}
          </span>
        </div>
        <div className="datePickerWrapper">
          <DatePicker
            cancelLabel="Cancelar"
            value={selectedDate}
            onChange={handleWeekChange}
            renderDay={renderWrappedWeekDay}
            labelFunc={formatWeekSelectLabel}
          />
          <div className="iconWrapper"> 
            <Icon name="calendar-outline" size={25} color="#000000" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="table-no-header">
      <Header handleSearchChange={handleSearchChange} tableOptions={tableOptions} />
      <CustomTable />
      <ModalLoginExpirado open={openLoginExpirado} />
    </div>
  );
}

export default ListaCorretoresTurnos;
