import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import validateFields from "../../../../utils/validateFields";
import ModalLoginExpirado from "../../../../components/ModalLoginExpirado";
import ButtonComponent from "../../../../components/ButtonComponent";

import CallbackMessage from "../../../../components/CallbackMessage";
import OverlayLoading from "../../../../components/OverlayLoading";
import Stepper from "../../../../components/Stepper";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  useMediaQuery,
} from "@material-ui/core";

import { DadosContratuais } from "../componentes/dadosContratuais/index.jsx";
import { Beneficios } from "../componentes/beneficios/index.jsx";
import { Aviso } from "../componentes/aviso";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import styles from "../styles.module.scss";
import { BeneficiosMobile } from "../componentes/beneficiosMobile";
import preAdmissaoService from "../../../../services/dpPreAdmissaoService";
import useCompany from "../../../../hooks/useCompany";

export function CadastroPreAdmissao() {
  const isMobile = useMediaQuery("(max-width: 1100px)");

  const {
    companyList: listEmpresas,
    selectedCompany: idEmpresa,
    setSelectedCompany: setIdEmpresa,
    getEmpresaPagina,
  } = useCompany("pre_admissao");

  const history = useHistory();
  const locationState = history.location.state;
  const { cadastrarPreAdmissaoEmpresa } = preAdmissaoService;
  const [loading, setLoading] = useState(false);
  const [mostrarAviso, setMostrarAviso] = useState(false);
  const [linkPreenchimentoFuncionario, setLinkPreenchimentoFuncionario] =
    useState(null);
  const [tipoAviso, setTipoAviso] = useState(null);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const params = useParams();

  const [activeStep, setActiveStep] = useState(0);

  const [availableSteps, setAvailableSteps] = useState([
    "DADOS CONTRATUAIS",
    "BENEFÍCIOS",
  ]);

  const [preAdmissaoDadosContratuais, setPreAdmissaoDadosContratuais] =
    useState({
      // DADOS CONTRATUAIS
      nomeFuncionario: null,
      dataAdmissao: null,
      diasExperiencia: null,
      dataExameAdmissional: null,
      optantePorAdiantamento: null,
      admissaoDuranteSD: null,
      departamento: null,
      salarioBase: null,
      terminoContrato: null,
      resultadoAtestadoOcupacional: null,
      tipoAdmissao: null,
      vinculoTrabalista: null,
      categoria: null,
      funcao: null,
      formaPagamento: null,
      horariosSemana: {
        segunda: null,
        entradaSegunda: null,
        inicioIntervaloSegunda: null,
        fimIntervaloSegunda: null,
        saidaSegunda: null,
        terca: null,
        entradaTerca: null,
        inicioIntervaloTerca: null,
        fimIntervaloTerca: null,
        saidaTerca: null,
        quarta: null,
        entradaQuarta: null,
        inicioIntervaloQuarta: null,
        fimIntervaloQuarta: null,
        saidaQuarta: null,
        quinta: null,
        entradaQuinta: null,
        inicioIntervaloQuinta: null,
        fimIntervaloQuinta: null,
        saidaQuinta: null,
        sexta: null,
        entradaSexta: null,
        inicioIntervaloSexta: null,
        fimIntervaloSexta: null,
        saidaSexta: null,
        sabado: null,
        entradaSabado: null,
        inicioIntervaloSabado: null,
        fimIntervaloSabado: null,
        saidaSabado: null,
        domingo: null,
        entradaDomingo: null,
        inicioIntervaloDomingo: null,
        fimIntervaloDomingo: null,
        saidaDomingo: null,
      },
    });

  const [preAdmissaoBeneficios, setPreAdmissaoBeneficios] = useState({
    valeTransporte: [
      {
        descricao: null,
        quantidadePassagens: null,
        valor: null,
        numeroBilhete: null,
      },
    ],
    valeAlimentacao: {
      valor: null,
      formaPagamento: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
    },
    valeRefeicao: {
      valor: null,
      formaPagamento: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
    },
    assistenciaMedica: {
      operadora: null,
      plano: null,
      valor: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
      dependentes: null,
      inclusoNoPlano: null,
      tipoDescontoDependentes: null,
      valorDescontadoDependentes: null,
    },
    assistenciaOdontologica: {
      operadora: null,
      plano: null,
      valor: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
      dependentes: null,
      inclusoNoPlano: null,
      tipoDescontoDependentes: null,
      valorDescontadoDependentes: null,
    },
    seguroVida: {
      descricao: null,
      seguradora: null,
      valor: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
    },
    bolsaAuxilio: {
      instituicao: null,
      curso: null,
      valor: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
    },
    outros: {
      descricao: null,
      valor: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
    },
  });

  // FIM CAMPOS PARA O SERVICE

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const onCopy = useCallback(() => {
    console.log("there");
    setCallbackMessage("Link copiado!");
    setCallbackType("success");
    setCallbackDuration(2000);
    setCallbackShown(true);
  }, []);

  const handleNextStep = () => {
    console.log("preAdmissaoDadosContratuais -> ", preAdmissaoDadosContratuais);

    if (locationState?.details) {
      return setActiveStep(activeStep + 1);
    }
    if (activeStep === 0) {
      const stepZeroFields = [
        {
          label: "empresaDp",
          value: idEmpresa,
        },
        {
          label: "nomeFuncionario",
          value: preAdmissaoDadosContratuais.nomeFuncionario,
        },
        {
          label: "dataAdmissao",
          value: preAdmissaoDadosContratuais.dataAdmissao,
        },
        {
          label: "diasExperiencia",
          value: preAdmissaoDadosContratuais.diasExperiencia,
        },
        {
          label: "departamento",
          value: preAdmissaoDadosContratuais.departamento,
        },
        {
          label: "salarioBase",
          value: preAdmissaoDadosContratuais.salarioBase,
        },
        {
          label: "resultadoAtestadoOcupacional",
          value: preAdmissaoDadosContratuais.resultadoAtestadoOcupacional,
        },
        {
          label: "tipoAdmissao",
          value: preAdmissaoDadosContratuais.tipoAdmissao,
        },
        {
          label: "vinculoTrabalista",
          value: preAdmissaoDadosContratuais.vinculoTrabalista,
        },
        { label: "categoria_dp", value: preAdmissaoDadosContratuais.categoria },
        { label: "funcao", value: preAdmissaoDadosContratuais.funcao },
        {
          label: "formaPagamento",
          value: preAdmissaoDadosContratuais.formaPagamento,
        },
      ];
      const stepZeroErrors = validateFields(stepZeroFields);
      if (stepZeroErrors.length !== 0) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(stepZeroErrors);
        return;
      }
    }
    setActiveStep(activeStep + 1);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (activeStep === 1) {
      let hasError = false;

      preAdmissaoBeneficios?.valeTransporte.forEach((vale) => {
        let beneficiosFields = [
          {
            label: "descricao",
            value: vale.descricao,
          },
          {
            label: "quantidadePassagens",
            value: vale.quantidadePassagens,
          },
          {
            label: "valor",
            value: vale.valor,
          },
          {
            label: "numeroBilhete",
            value: vale.numeroBilhete,
          },
        ];
        console.log(beneficiosFields);
        const beneficiosErros = validateFields(beneficiosFields);
        console.log(beneficiosErros);
        if (beneficiosErros.length !== 0) {
          hasError = true;
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackErrorList(beneficiosErros);
          return;
        }
      });
      if (hasError) return;
    }

    const valeTransporte = preAdmissaoBeneficios.valeTransporte?.map(
      (item) => ({
        descricao: item.descricao,
        valor: item.valor,
        numero_bilhete: item.numeroBilhete,
        qtd_passagens_dia: item.quantidadePassagens,
      })
    );

    const horariosSemana = preAdmissaoDadosContratuais.horariosSemana;

    let horarios = new Array();

    horarios[0] = {
      trabalha: horariosSemana.segunda ? 1 : 0,
      entrada: horariosSemana.entradaSegunda,
      inicio_intervalo: horariosSemana.inicioIntervaloSegunda,
      final_intervalo: horariosSemana.fimIntervaloSegunda,
      saida: horariosSemana.saidaSegunda,
    };
    horarios[1] = {
      trabalha: horariosSemana.terca ? 1 : 0,
      entrada: horariosSemana.entradaTerca,
      inicio_intervalo: horariosSemana.inicioIntervaloTerca,
      final_intervalo: horariosSemana.fimIntervaloTerca,
      saida: horariosSemana.saidaTerca,
    };
    horarios[2] = {
      trabalha: horariosSemana.quarta ? 1 : 0,
      entrada: horariosSemana.entradaQuarta,
      inicio_intervalo: horariosSemana.inicioIntervaloQuarta,
      final_intervalo: horariosSemana.fimIntervaloQuarta,
      saida: horariosSemana.saidaQuarta,
    };
    horarios[3] = {
      trabalha: horariosSemana.quinta ? 1 : 0,
      entrada: horariosSemana.entradaQuinta,
      inicio_intervalo: horariosSemana.inicioIntervaloQuinta,
      final_intervalo: horariosSemana.fimIntervaloQuinta,
      saida: horariosSemana.saidaQuinta,
    };
    horarios[4] = {
      trabalha: horariosSemana.sexta ? 1 : 0,
      entrada: horariosSemana.entradaSexta,
      inicio_intervalo: horariosSemana.inicioIntervaloSexta,
      final_intervalo: horariosSemana.fimIntervaloSexta,
      saida: horariosSemana.saidaSexta,
    };
    horarios[5] = {
      trabalha: horariosSemana.sabado ? 1 : 0,
      entrada: horariosSemana.entradaSabado,
      inicio_intervalo: horariosSemana.inicioIntervaloSabado,
      final_intervalo: horariosSemana.fimIntervaloSabado,
      saida: horariosSemana.saidaSabado,
    };
    horarios[6] = {
      trabalha: horariosSemana.domingo ? 1 : 0,
      entrada: horariosSemana.entradaDomingo,
      inicio_intervalo: horariosSemana.inicioIntervaloDomingo,
      final_intervalo: horariosSemana.fimIntervaloDomingo,
      saida: horariosSemana.saidaDomingo,
    };

    setLoading(true);

    const response = await cadastrarPreAdmissaoEmpresa({
      idEmpresa,
      nome: preAdmissaoDadosContratuais.nomeFuncionario,
      dataAdmissao: preAdmissaoDadosContratuais.dataAdmissao,
      diasContratoExperiencia: preAdmissaoDadosContratuais.diasExperiencia,
      aptoTrabalhar: preAdmissaoDadosContratuais.resultadoAtestadoOcupacional
        ? 1
        : 0,
      dataExameAdmissional: preAdmissaoDadosContratuais.dataExameAdmissional,
      idTipoAdmissao: preAdmissaoDadosContratuais.tipoAdmissao,
      optantePorAdiantamento: preAdmissaoDadosContratuais.optantePorAdiantamento
        ? 1
        : 0,
      admissaoSeguroAcaoFiscal: preAdmissaoDadosContratuais.admissaoDuranteSD
        ? 1
        : 0,
      idVinculoTrabalhista: preAdmissaoDadosContratuais.vinculoTrabalista,
      idCategoriaFuncionario: preAdmissaoDadosContratuais.categoria,
      idDepartamentoFuncionario: preAdmissaoDadosContratuais.departamento,
      funcao: preAdmissaoDadosContratuais.funcao,
      salarioBase: preAdmissaoDadosContratuais.salarioBase,
      idFormaPagamentoSalario: preAdmissaoDadosContratuais.formaPagamento,
      dataTerminoContrato: preAdmissaoDadosContratuais.terminoContrato,
      horario: horarios,
      vaValor: preAdmissaoBeneficios.valeAlimentacao.valor,
      vaIdFormaPagamento: preAdmissaoBeneficios.valeAlimentacao.formaPagamento,
      vaDescontado: preAdmissaoBeneficios.valeAlimentacao.descontado ? 1 : 0,
      vaTipoDesconto: preAdmissaoBeneficios.valeAlimentacao.tipoDesconto,
      vaValorDescontado: preAdmissaoBeneficios.valeAlimentacao.valorDescontado,
      vrValor: preAdmissaoBeneficios.valeRefeicao.valor,
      vrIdFormaPagamento: preAdmissaoBeneficios.valeRefeicao.formaPagamento,
      vrDescontado: preAdmissaoBeneficios.valeRefeicao.descontado ? 1 : 0,
      vrTipoDesconto: preAdmissaoBeneficios.valeRefeicao.tipoDesconto,
      vrValorDescontado: preAdmissaoBeneficios.valeRefeicao.valorDescontado,
      assMedicaOperadora: preAdmissaoBeneficios.assistenciaMedica.operadora,
      assMedicaPlano: preAdmissaoBeneficios.assistenciaMedica.plano,
      assMedicaValor: preAdmissaoBeneficios.assistenciaMedica.valor,
      assMedicaDescontado: preAdmissaoBeneficios.assistenciaMedica.descontado
        ? 1
        : 0,
      assMedicaTipoDesconto:
        preAdmissaoBeneficios.assistenciaMedica.tipoDesconto,
      assMedicaValorDescontado:
        preAdmissaoBeneficios.assistenciaMedica.valorDescontado,
      assMedicaDependentes: preAdmissaoBeneficios.assistenciaMedica.dependentes
        ? 1
        : 0,
      assMedicaDependenteInclusoNoPlano: preAdmissaoBeneficios.assistenciaMedica
        .inclusoNoPlano
        ? 1
        : 0,
      assMedicaDependenteDescontado:
        preAdmissaoBeneficios.assistenciaMedica.valorDescontadoDependentes,
      assMedicaDependenteTipoDesconto:
        preAdmissaoBeneficios.assistenciaMedica.tipoDescontoDependentes,
      assOdontoOperadora:
        preAdmissaoBeneficios.assistenciaOdontologica.operadora,
      assOdontoPlano: preAdmissaoBeneficios.assistenciaOdontologica.plano,
      assOdontoValor: preAdmissaoBeneficios.assistenciaOdontologica.valor,
      assOdontoDescontado: preAdmissaoBeneficios.assistenciaOdontologica
        .descontado
        ? 1
        : 0,
      assOdontoTipoDesconto:
        preAdmissaoBeneficios.assistenciaOdontologica.tipoDesconto,
      assOdontoValorDescontado:
        preAdmissaoBeneficios.assistenciaOdontologica.valorDescontado,
      assOdontoDependentes: preAdmissaoBeneficios.assistenciaOdontologica
        .dependentes
        ? 1
        : 0,
      assOdontoDependenteInclusoNoPlano: preAdmissaoBeneficios
        .assistenciaOdontologica.inclusoNoPlano
        ? 1
        : 0,
      assOdontoDependenteTipoDesconto:
        preAdmissaoBeneficios.assistenciaOdontologica.tipoDescontoDependentes,
      assOdontoDependenteValorDescontado:
        preAdmissaoBeneficios.assistenciaOdontologica
          .valorDescontadoDependentes,
      seguroVidaDescricao: preAdmissaoBeneficios.seguroVida.descricao,
      seguroVidaSeguradora: preAdmissaoBeneficios.seguroVida.seguradora,
      seguroVidaValor: preAdmissaoBeneficios.seguroVida.valor,
      seguroVidaDescontado: preAdmissaoBeneficios.seguroVida.descontado ? 1 : 0,
      seguroVidaTipoDesconto: preAdmissaoBeneficios.seguroVida.tipoDesconto,
      seguroVidaValorDescontado:
        preAdmissaoBeneficios.seguroVida.valorDescontado,
      bolsaAuxilioInstituicao: preAdmissaoBeneficios.bolsaAuxilio.instituicao,
      bolsaAuxilioCurso: preAdmissaoBeneficios.bolsaAuxilio.curso,
      bolsaAuxilioValor: preAdmissaoBeneficios.bolsaAuxilio.valor,
      bolsaAuxilioDescontado: preAdmissaoBeneficios.bolsaAuxilio.descontado
        ? 1
        : 0,
      bolsaAuxilioTipoDesconto: preAdmissaoBeneficios.bolsaAuxilio.tipoDesconto,
      bolsaAuxilioValorDescontado:
        preAdmissaoBeneficios.bolsaAuxilio.valorDescontado,
      outrosDescricao: preAdmissaoBeneficios.outros.descricao,
      outrosValor: preAdmissaoBeneficios.outros.valor,
      outrosDescontado: preAdmissaoBeneficios.outros.descontado ? 1 : 0,
      outrosTipoDesconto: preAdmissaoBeneficios.outros.tipoDesconto,
      outrosValorDescontado: preAdmissaoBeneficios.outros.valorDescontado,
      vt: valeTransporte,
    });
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    console.log("cadastraEmpresa :: ", response);

    setLoading(false);

    if (response?.data?.success && response?.data?.link) {
      setLinkPreenchimentoFuncionario(response?.data?.link);
      setTipoAviso("preenchimento-finalizado");
      setMostrarAviso(true);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        response?.data?.message || "Pré-admissão salva com sucesso!"
      );
      setCallbackDuration(2000);
    } else if (typeof response?.data?.error === "string") {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage(response?.data?.msg || "Erro!");
      setCallbackDuration(4000);
      setCallbackErrorList([response?.data?.error]);
    } else if (typeof response?.data?.error === "object") {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(4000);
      setCallbackMessage(response?.data?.msg || "Erro!");
      setCallbackErrorList(Object.values(response?.data?.error).flat(Infinity));
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Erro ao cadastrar pré-admissão"]);
    }
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  const handleClickStep = (newStep) => {
    if (locationState?.activeStepNavigation) {
      setActiveStep(newStep);
    }
  };

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("pre_admissao");
    if (empresaStorage) {
      setIdEmpresa(empresaStorage);
    }
  }, []);

  return (
    <>
      <Grid container className={isMobile ? "" : "default-forms-container"}>
        {mostrarAviso ? (
          <Aviso
            locationState={locationState}
            link={linkPreenchimentoFuncionario}
            tipo={tipoAviso}
            onCopy={onCopy}
          />
        ) : (
          <>
            {!isMobile && (
              <Grid item xs={2}>
                <Stepper
                  useActiveStep
                  activeStep={activeStep}
                  arraySteps={availableSteps}
                  handleClickStep={handleClickStep}
                  activeStepNavigation={locationState?.activeStepNavigation}
                />
              </Grid>
            )}
            <Grid item xs={isMobile ? 12 : 10}>
              <Grid
                container
                spacing={2}
                justifyContent={isMobile ? "flex-start" : "center"}
              >
                <Grid
                  item
                  xs={isMobile ? 12 : 8}
                  className={isMobile ? styles.containerFormMobile : ""}
                  style={isMobile ? {} : { marginTop: 20 }}
                >
                  <form onSubmit={handleFormSubmit}>
                    {isMobile && (
                      <>
                        <Accordion
                          expanded={activeStep === 0}
                          className={styles.accordionStyles}
                        >
                          <AccordionSummary>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              xs={12}
                              className={styles.accordionSummary}
                            >
                              <Grid item className="font-title">
                                <span className="text-pleft">
                                  1. DADOS CONTRATUAIS
                                </span>
                              </Grid>
                              <Grid item>
                                {activeStep === 0 ? (
                                  <ExpandLess className="font-darkBlue" />
                                ) : (
                                  <ExpandMore className="font-orange" />
                                )}
                              </Grid>
                            </Grid>
                          </AccordionSummary>

                          <AccordionDetails
                            className={styles.accordionDetails}
                            style={{ background: "white" }}
                          >
                            <DadosContratuais
                              idEmpresa={idEmpresa}
                              setIdEmpresa={setIdEmpresa}
                              locationState={locationState}
                              dados={preAdmissaoDadosContratuais}
                              setDados={setPreAdmissaoDadosContratuais}
                              listEmpresa={listEmpresas}
                            />
                            <Grid
                              container
                              xs={12}
                              style={{ marginTop: 10 }}
                              justifyContent="flex-end"
                            >
                              <ButtonComponent
                                className={`btn-success ${styles.btnSteps} w-full`}
                                text="Avançar"
                                onClick={handleNextStep}
                              />
                            </Grid>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          expanded={activeStep === 1}
                          className={`${styles.accordionStyles} no-boder-top`}
                        >
                          <AccordionSummary>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              xs={12}
                              className={styles.accordionSummary}
                            >
                              <Grid item className="font-title">
                                <span className="text-pleft">
                                  2. BENEFÍCIOS
                                </span>
                              </Grid>
                              <Grid item>
                                {activeStep === 1 ? (
                                  <ExpandLess className="font-darkBlue" />
                                ) : (
                                  <ExpandMore className="font-orange" />
                                )}
                              </Grid>
                            </Grid>
                          </AccordionSummary>

                          <AccordionDetails
                            className={styles.accordionDetails}
                            style={{ background: "white" }}
                          >
                            <BeneficiosMobile
                              locationState={locationState}
                              dados={preAdmissaoBeneficios}
                              setDados={setPreAdmissaoBeneficios}
                            />
                            <Grid
                              container
                              spacing={2}
                              xs={12}
                              style={{ marginTop: 10 }}
                              justifyContent="flex-end"
                            >
                              <Grid item xs={6}>
                                <ButtonComponent
                                  text="Voltar"
                                  className={`btn-secondary ${styles.btnOutline} ${styles.btnSteps} w-full`}
                                  onClick={handleBack}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <ButtonComponent
                                  className={`btn-info ${styles.btnSteps} w-full`}
                                  text="Salvar"
                                  type="submit"
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )}
                    {!isMobile && (
                      <>
                        {activeStep === 0 && (
                          <DadosContratuais
                            idEmpresa={idEmpresa}
                            setIdEmpresa={setIdEmpresa}
                            locationState={locationState}
                            dados={preAdmissaoDadosContratuais}
                            setDados={setPreAdmissaoDadosContratuais}
                            listEmpresa={listEmpresas}
                          />
                        )}
                        {activeStep === 1 && (
                          <Beneficios
                            locationState={locationState}
                            dados={preAdmissaoBeneficios}
                            setDados={setPreAdmissaoBeneficios}
                          />
                        )}
                        <Grid
                          container
                          justifyContent="flex-end"
                          style={{
                            marginTop: 40,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          {activeStep > 0 && (
                            <>
                              <Grid
                                item
                                xs={3}
                                style={{ marginRight: 10 }}
                                className={
                                  locationState?.details && activeStep === 3
                                    ? "align-button-right"
                                    : ""
                                }
                              >
                                <ButtonComponent
                                  text="Voltar"
                                  className="btn-secondary btnSteps"
                                  onClick={handleBack}
                                />
                              </Grid>
                            </>
                          )}
                          {activeStep === 0 && (
                            <>
                              <Grid
                                item
                                {...(locationState?.edit ? { xs: 3 } : {})}
                                style={{ width: 120 }}
                              >
                                <ButtonComponent
                                  className="btn-success btnSteps"
                                  text="Avançar"
                                  onClick={handleNextStep}
                                />
                              </Grid>
                            </>
                          )}

                          {activeStep === 1 && (
                            <>
                              <Grid item style={{ width: 120 }}>
                                <ButtonComponent
                                  className="btn-info btnSteps"
                                  text="Salvar"
                                  type="submit"
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </>
                    )}
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {loading && <OverlayLoading />}
        {callbackShown && (
          <CallbackMessage
            open={callbackShown}
            type={callbackType}
            handleClose={handleClose}
            message={callbackMessage}
            duration={callbackDuration}
            errorList={callbackErrorList}
          />
        )}
      </Grid>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
