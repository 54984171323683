import Axios from "./Axios";

const usoService = {
  verificaUso: async () => {
    const response = await Axios.get("/verifica-uso")
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  consultaUsoLogin: async () => {
    const response = await Axios.get(`/consulta-uso-login`)
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  consultaUso: async () => {
    const userId = window.localStorage.getItem("id_usuario");

    const response = await Axios.get(`/consulta-uso/${userId}`)
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
};

export default usoService;
