import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import OverlayLoading from "../OverlayLoading";
import { numberToCurrency } from "../../utils/functions";
import { Grid, useMediaQuery } from "@material-ui/core";
import { Chart as ChartJS } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./styles.scss";
ChartJS.register(ChartDataLabels);
ChartJS.defaults.color = "#000";

const labels = ["Orçado", "Realizado"];

const options = (isMobile) => ({
  responsive: isMobile,
  maintainAspectRatio: false,
  legend: {
    labels: {
      color: "blue", // Change the color of the legend labels here
    },
  },
  layout: {
    padding: {
      top: 60,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
    },
    y: {
      ticks: {
        display: false,
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },
  plugins: {
    datalabels: {
      display: true,
      anchor: function (context) {
        var index = context.dataIndex;
        var value = context.dataset.data[index];
        var otherValue = context.dataset.data.find((_, i) => i !== index);

        if (value === 0 || otherValue * 0.05 > value) {
          return "end";
        }
        return "center";
      },
      color: function (context) {
        var index = context.dataIndex;
        var value = context.dataset.data[index];
        var otherValue = context.dataset.data.find((_, i) => i !== index);

        if (value === 0 || otherValue * 0.05 > value) {
          return "#000";
        }
        return "#fff";
      },
      align: function (context) {
        var index = context.dataIndex;
        var value = context.dataset.data[index];
        var otherValue = context.dataset.data.find((_, i) => i !== index);

        if (value === 0 || otherValue * 0.05 > value) {
          return 'end';
        }
        return 'center';
      },
      labels: {
        title: {
          font: {
            weight: "bold",
            size: 10,
          },
        },
      },
      formatter: function (value, context) {
        console.log({ value });
        return parseFloat(value).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    legend: {
      position: "bottom",
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          var label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += numberToCurrency(context.parsed.y);
          }
          return label;
        },
      },
    },
  },
});

function BarItem({ dados }) {
  const mobileScreen = useMediaQuery("(max-width: 1100px)");
  return (
    <Bar
      data={{
        labels: labels,
        datasets: [
          {
            label: "",
            data: dados,
            backgroundColor: ["#6C6C6C", "#EF4066"],
            borderWidth: 1,
            borderRadius: 8,
            barThickness: 120,
          },
        ],
      }}
      height={400}
      options={options(mobileScreen)}
    />
  );
}
function GraficoBar({ loading = false, dados }) {
  const [saldoRecebimento, setSaldoRecebimento] = useState(null);
  const [saldoPagamento, setSaldoPagamento] = useState(null);

  useEffect(() => {
    if (dados?.recebimento) {
      const realizado = dados.recebimento?.realizado || 0;
      const orcado = dados.recebimento?.orcado || 0;

      const saldo = realizado - orcado;
      setSaldoRecebimento(saldo);
    }
    if (dados?.pagamento) {
      const realizado = dados.pagamento?.realizado || 0;
      const orcado = dados.pagamento?.orcado || 0;

      const saldo = orcado - realizado;
      setSaldoPagamento(saldo);
    }
  }, [dados]);

  return (
    <>
      {loading && <OverlayLoading />}
      <Grid container spacing={2} className="container-grafico-home">
        <Grid container item xs={12} md={6}>
          <Grid item xs={12}>
            <div className="grafico-container-titulo">
              <span className="grafico-subtitulo">Recebimentos</span>
              {saldoRecebimento || saldoRecebimento === 0 ? (
                <span
                  className="grafico-titulo"
                  style={{ color: saldoRecebimento < 0 ? "#B00" : "#219653" }}
                >
                  {Intl.NumberFormat("pt-br", {
                    style: "currency",
                    currency: "brl",
                  }).format(saldoRecebimento)}
                </span>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12}>
            <BarItem
              dados={[dados.recebimento.orcado, dados.recebimento.realizado]}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={6}>
          <Grid item xs={12}>
            <div className="grafico-container-titulo">
              <span className="grafico-subtitulo">Pagamentos</span>
              {saldoPagamento || saldoPagamento === 0 ? (
                <span
                  className="grafico-titulo"
                  style={{ color: saldoPagamento < 0 ? "#B00" : "#219653" }}
                >
                  {Intl.NumberFormat("pt-br", {
                    style: "currency",
                    currency: "brl",
                  }).format(saldoPagamento)}
                </span>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12}>
            <BarItem
              dados={[dados.pagamento.orcado, dados.pagamento.realizado]}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default GraficoBar;
