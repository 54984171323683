import React, { useEffect, useState } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InputComponent from "../Input";
import Textarea from "../Textarea";
import DatePicker from "../DatePicker";
import SelectComponent from "../SelectComponent";
import ButtonComponent from "../ButtonComponent";
import OverlayLoading from "../OverlayLoading";
import InputFile from "../InputFile";
import CallbackMessage from "../CallbackMessage";
import validateFields from "../../utils/validateFields";
import recebimentosServices from "../../services/recebimentosService";
import pagamentosService from "../../services/pagamentosService";
import centroCustosService from "../../services/centroCustosService";
import fornedoresService from "../../services/fornecedoresService";
import clientesService from "../../services/clientesService";
import conciliacaoService from "../../services/conciliacaoService";
import ModalLoginExpirado from "../ModalLoginExpirado";
import "./styles.scss";
import SwitchComponent from "../Switch";

function ModalNovaTransacao({
  open,
  onClose = () => {},
  type = "",
  idLancamento = "",
  idEmpresa = "",
  onSubmit = () => {},
  idConta,
  usarIntegracao,
  transacao,
}) {
  const [lancamentoRecorrente, setLancamentoRecorrente] = useState(0);
  const [dataVencimento, setDataVencimento] = useState(
    transacao?.data_referencia_original || ""
  );
  const [valor, setValor] = useState(transacao.valorOriginal);
  const [centroCustoId, setCentroCustoId] = useState("");
  const [clienteId, setClienteId] = useState("");
  const [fornecedorId, setFornecedorId] = useState("");
  const [categoriaId, setCategoriaId] = useState("");
  const [descricao, setDescricao] = useState(transacao.descricao);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState([]);
  const [listCentroCusto, setListCentroCusto] = useState([]);
  const [listFornecedores, setListFornecedores] = useState([]);
  const [listClientes, setListClientes] = useState([]);
  const [listCategorias, setListCategorias] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  async function handleSubmit() {
    const fields = [
      {
        label: "datavencimento",
        value: dataVencimento,
      },
      {
        label: "valor",
        value: valor,
      },
      {
        label: type === "pagamento" ? "fornecedor" : "cliente",
        value: type === "pagamento" ? fornecedorId : clienteId,
      },
      {
        label: "categoria",
        value: categoriaId,
      },
      {
        label: "descricao",
        value: descricao,
      },
    ];

    const errorsValidate = validateFields(fields);
    if (errorsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(errorsValidate);
      return;
    }
    setLoading(true);
    if (type === "pagamento") {
      await conciliacaoService
        .conciliarNovoPagamento(
          idEmpresa,
          file,
          descricao,
          categoriaId,
          dataVencimento,
          Number(valor),
          fornecedorId,
          centroCustoId,
          idLancamento,
          usarIntegracao,
          lancamentoRecorrente,
          idConta
        )
        .then(async (response) => {
          if (response.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          } else if (response.status !== 200 && response.status !== 201)
            if (response.data.error)
              setErrors(Object.values(response.data.error));
            else setErrors(["Ocorreu um erro"]);

          setLoading(false);
          setShowMessage(true);
          if (response.status === 200) onSubmit();
        });
    } else {
      await conciliacaoService
        .conciliarNovoRecebimento(
          idEmpresa,
          file,
          descricao,
          categoriaId,
          dataVencimento,
          Number(valor),
          clienteId,
          centroCustoId,
          idLancamento,
          usarIntegracao,
          lancamentoRecorrente,
          idConta
        )
        .then(async (response) => {
          if (response.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          } else if (response.status !== 200 && response.status !== 201)
            setErrors(["Ocorreu um erro ao realizar transação"]);

          setLoading(false);
          setShowMessage(true);
          if (response.status === 200) onSubmit();
        });
    }
  }

  function handleClose() {
    if (errors.length === 0) {
      onClose();
    }
    setShowMessage(false);
    setErrors([]);
  }

  async function loadCentroCusto() {
    await centroCustosService
      .selecionaCentroCusto(idEmpresa)
      .then((response) => {
        if (response.status === 200) setListCentroCusto(response.data);
        else if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
      });
  }

  async function loadCategorias() {
    if (type === "pagamento") {
      await pagamentosService
        .selecionaCategoriaPagamento(idEmpresa)
        .then((response) => {
          if (response.status === 200) setListCategorias(response.data);
          else if (response.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          }
        });
    } else {
      await recebimentosServices
        .selecionaCategoriaRecebimento(idEmpresa)
        .then((response) => {
          if (response.status === 200) setListCategorias(response.data);
          else if (response.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          }
        });
    }
  }

  async function loadFornecedores() {
    await fornedoresService
      .selecionaFornecedores(idEmpresa)
      .then((response) => {
        if (response.status === 200) setListFornecedores(response.data);
        else if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
      });
  }

  async function loadClientes() {
    await clientesService.selecionaCliente(idEmpresa).then((response) => {
      if (response.status === 200) setListClientes(response.data);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
    });
  }

  useEffect(() => {
    async function init() {
      setLoading(true);
      await loadCentroCusto();
      await loadCategorias();
      if (type === "pagamento") await loadFornecedores();
      else await loadClientes();
      setLoading(false);
    }
    init();
  }, [type]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle className="modal-container">
        {loading && <OverlayLoading />}
        <Grid
          item
          container
          justifyContent="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>{`NOVA TRANSAÇÃO DE ${
              type === "recebimento" ? "ENTRADA" : "SAÍDA"
            }`}</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content-transacao">
        <form className="session-container-form">
          <Grid container direction="column" spacing={1}>
            <Grid item container spacing={1}>
              <Grid xs={12} item className="container-input-switch page-nfe">
                <span className="modal-title lancamento-recorrente-titulo">
                  Lançamento recorrente?
                </span>
                <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                  Não
                </Grid>
                <Grid item>
                  <SwitchComponent
                    checked={lancamentoRecorrente}
                    onClick={() =>
                      setLancamentoRecorrente((prev) => (prev ? 0 : 1))
                    }
                  />
                </Grid>
                <Grid item>Sim</Grid>
              </Grid>
              <Grid item xs>
                <DatePicker
                  label={
                    type === "recebimento"
                      ? "Data de entrada *"
                      : "Data de saída *"
                  }
                  initDate={dataVencimento}
                  handleChange={(value) => {
                    setDataVencimento(value);
                  }}
                />
              </Grid>
              <Grid item xs>
                <InputComponent
                  tipo="dinheiro"
                  label="Valor *"
                  value={valor}
                  handleInputChange={(event) => setValor(event.value)}
                />
              </Grid>
              <Grid item xs>
                <SelectComponent
                  list={listCentroCusto}
                  label="Centro de custo"
                  styleType="form"
                  callback={(value) => setCentroCustoId(value)}
                  title="Selecione um centro de custo"
                />
              </Grid>
            </Grid>
            {type === "pagamento" ? (
              <Grid item>
                <SelectComponent
                  list={listFornecedores}
                  label="Fornecedor *"
                  styleType="form"
                  callback={(value) => setFornecedorId(value)}
                  title="Selecione um fornecedor"
                />
              </Grid>
            ) : (
              <Grid item>
                <SelectComponent
                  list={listClientes}
                  label="Cliente *"
                  styleType="form"
                  callback={(value) => setClienteId(value)}
                  title="Selecione um cliente"
                />
              </Grid>
            )}
            <Grid item>
              <SelectComponent
                list={listCategorias}
                label="Categoria *"
                styleType="form"
                callback={(value) => setCategoriaId(value)}
                title="Selecione uma categoria"
              />
            </Grid>
            <Grid item>
              <Textarea
                label="Descrição *"
                value={descricao}
                handleInputChange={(event) => setDescricao(event.target.value)}
              />
            </Grid>
            <Grid item>
              <InputFile title="Anexo" onChange={(value) => setFile(value)} />
            </Grid>
            <Grid container justifyContent="flex-end" item>
              <ButtonComponent
                text="Salvar e conciliar"
                onClick={handleSubmit}
                className="btn-success zero-margin"
              />
            </Grid>
          </Grid>
          <CallbackMessage
            open={showMessage}
            type={`${errors.length === 0 ? "success" : "error"}`}
            message={`${
              errors.length === 0 ? "Transação realizada com sucesso!" : "Erro!"
            }`}
            errorList={errors}
            handleClose={handleClose}
            duration={errors.length === 0 ? 2000 : 6000}
          />
        </form>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}

export default ModalNovaTransacao;
