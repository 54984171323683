import React, { createContext,useContext } from 'react';

import usePDV from '../hooks/usePDV';

const PDVContext = createContext();

function PDVProvider({ children }) {
  const { state, onChange, onFinish, onResetWithBoxOpen } = usePDV();
  
  return (
    <PDVContext.Provider value={{ state, onChange, onFinish, onResetWithBoxOpen }}>
      {children}
    </PDVContext.Provider>
  );
}

const usePDVContext = () => useContext(PDVContext);

export { PDVContext, PDVProvider, usePDVContext };
