import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InputComponent from "../Input";
import ButtonComponent from "../ButtonComponent";
import SelectComponent from "../SelectComponent";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import CallbackMessage from "../CallbackMessage";
import validateFields from "../../utils/validateFields";
import selectServices from '../../services/selectServices';
import produtosServices from '../../services/produtosServices';
import classes from "../../components/Input/styles.module.scss";


import "./styles.scss";
import { ConvertMoney } from "../../utils/strings";

function ModalCadastroProduto({
  open,
  onClose,
  cbSuccess,
  idCompany,
  valorCusto=0
}) {
  const { cadastraProduto, selectCfop, consultaProduto, selecionaProduto, selectMedidaComercial } = produtosServices;

  const { selectCstCsosn } = selectServices;

  const [codigo, setCodigo] = useState(null);
  const [descricao, setDescricao] = useState(null);
  const [situacao, setSituacao] = useState(null);
  const [cfop, setCfop] = useState(null);
  const [valor, setValor] = useState(null);
  const [quantidade, setQuantidade] = useState(null);
  const [produto, setProduto] = useState(null);
  const [unidadeMedidaComercial, setUnidadeMedidaComercial] = useState(null);
  const [unidadeMedidaComercialDescricao, setUnidadeMedidaComercialDescricao] = useState(null);
  const [listMedidaComercial, setListMedidaComercial] = useState([]);
  const [valorCustoTotal, setValorCustoTotal] = useState(0);
  const [valorCustoUnitario, setValorCustoUnitario] = useState(0);

  const [listCfop, setListCfop] = useState([]);
  const [listCsosn, setListCsosn] = useState([]);
  const [listProducts, setListProducts] = useState([]);

  const [dataProduct, setDataProduct] = useState({});

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const loadProducts = useCallback(async () => {
    if (idCompany) {
      const response = await selecionaProduto(idCompany);

      if (response.status === 200) {
        const data = response.data;

        let newData = data.map(item => ({
          id: item.id_produto,
          nome: item.descricao,
        }));

        setListProducts(newData)
      }
      if (response.status === 401) {
        setOpenLoginExpirado(true);
      }
    }
  }, [idCompany]);

  const loadCfop = useCallback(async () => {
    const response = await selectCfop();
    if (response.status === 200) {
      console.log('response', response);
      setListCfop(response.data)
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selectCfop])

  const loadCsosn = useCallback(async (id) => {
    const response = await selectCstCsosn(id);
    if (response.status === 200) {
      setListCsosn(response.data)
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selectCstCsosn])


  const loadUnidadeComercial = useCallback(async () => {
    const response = await selectMedidaComercial();
    if (response.status === 200) {
      setListMedidaComercial(response.data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selectMedidaComercial]);

  useEffect(() => {
    setLoading(true);
    loadCfop();
    loadProducts();
    loadUnidadeComercial();
    setLoading(false);
  }, [loadProducts]);

  useEffect( () => {
    setValorCustoTotal(quantidade > 0 ? valorCusto/quantidade : 0)
  }, [quantidade, valorCusto])

  const handleSubmit = useCallback(async () => {
    setErrors([]);

    let fields = [
      {
        label: "preproduto",
        value: produto,
      },
      {
        label: "codigo",
        value: codigo,
      },
      {
        label: "descricaodoprodutoouservico",
        value: descricao,
      },
      {
        label: "situacaotributaria",
        value: situacao,
      },
      {
        label: "cfop",
        value: cfop,
      },
      {
        label: "quantidade_entrada_estoque",
        value: quantidade,
      },
      {
        label: "unidade_medida_produto",
        value: unidadeMedidaComercial,
      },
      {
        label: "valor",
        value: valor,
      },
    ];

    const fieldsValidate = validateFields(fields);

    if (fieldsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(fieldsValidate);
      return;
    }

    setLoading(true);

    const response = await cadastraProduto(
      dataProduct?.id_empresa,
      codigo,
      dataProduct?.tipo_produto,
      dataProduct?.tipo_especifico_produto,
      descricao,
      dataProduct?.ncm,
      valorCustoTotal,
      unidadeMedidaComercial,
      unidadeMedidaComercial,
      dataProduct?.origem_material,
      dataProduct?.descricao_ncm,
      dataProduct?.cest,
      dataProduct?.ean,
      dataProduct?.ean_tributado,
      dataProduct?.codigo_beneficio_fiscal,
      valor,
      valor,
      dataProduct?.peso_liquido,
      dataProduct?.peso_bruto,
      dataProduct?.percentual_imposto,
      dataProduct?.extensao_ipi,
      dataProduct?.valor_total_tributos,
      cfop,
      dataProduct?.bloqueado_inativo,
      dataProduct?.imagem_produto,
      dataProduct?.impostos?.issqn,
      dataProduct?.impostos?.id_icms,
      null,
      null,
      dataProduct?.id_categoria_padrao,
      dataProduct?.impostos?.valor_base_calculo_pis,
      dataProduct?.impostos?.aliquota_pis,
      dataProduct?.impostos?.cst_pis,
      dataProduct?.impostos?.valor_base_calculo_cofins,
      dataProduct?.impostos?.aliquota_cofins,
      dataProduct?.impostos?.cst_cofins,
      situacao,
      quantidade,
      unidadeMedidaComercialDescricao,
      unidadeMedidaComercialDescricao
    );

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status !== 201 && response.status !== 200) {
      if (response.data.error) {
        setLoading(false);
        setShowMessage(true);
        setErrors(Object.values(response.data.error));
        return console.log('errors:', response.data.error)
      } else setErrors(["Ocorreu um erro"]);
    } else if (response.status === 200 || response.status === 201) {
      setErrors([]);
      setSuccessMessage("Novo produto cadastrado com sucesso!");
      onClose();

      let newDataProduct = {
        id_produto: response?.data?.id,
        tipo_material: response?.data?.tipo_material,
        descricao: response?.data?.dados_cru?.description,
        ean: response?.data?.dados_cru?.ean,
        ncm: response?.data?.dados_cru?.ncm,
        codigo_produto: response?.data?.dados_cru?.codigo,
      }

      cbSuccess(newDataProduct)
      setCfop(null);
      setCodigo(null);
      setDescricao(null);
      setSituacao(null);
      setValor(null);
      setDataProduct({});
      setValorCustoUnitario(0);
      setQuantidade(null);
      setUnidadeMedidaComercial(null)
      setUnidadeMedidaComercialDescricao(null)
    }

    setShowMessage(true);
    setLoading(false);
  }, [dataProduct, codigo, descricao, cfop, situacao, quantidade, valorCusto, unidadeMedidaComercial]);

  const handleChangePreCadastro = useCallback(async (id) => {
    setLoading(true);
    setProduto(id);
    const response = await consultaProduto(id, 1);

    if (response.status === 200) {
      const data = response.data;

      await loadCsosn(data?.impostos.id_icms);

      setCodigo(data?.codigo_produto);
      setCfop(data?.cfop)
      setDescricao(data?.descricao);
      setSituacao(data?.impostos?.csosn);
      setValor(data?.valor_unitario_comercial);

      setDataProduct(data);
    } else {
      setShowMessage(true);
      setErrors(['Ocorreu algum erro ao tentar selecionar esse produto, tente novamente.']);
    }

    setLoading(false);
  }, [consultaProduto, loadCsosn])

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }

  const handleCloseModal = async () => {
    setLoading(false);
    setCfop(null);
    setCodigo(null);
    setDescricao(null);
    setSituacao(null);
    setValor(null);
    setDataProduct({});
    setValorCustoTotal(0);
    setQuantidade(null);
    setUnidadeMedidaComercial(null)
    setUnidadeMedidaComercialDescricao(null)
    onClose();
  }

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleCloseModal} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>CADASTRO DE PRODUTO - SIMPLIFICADO</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <div className="select-container">
                  <SelectComponent
                    list={listProducts}
                    label="Pré-cadastro de produto"
                    title="Selecione uma opção"
                    required
                    callback={handleChangePreCadastro}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  nome="codigo"
                  label="Código"
                  required
                  tipo="text"
                  value={codigo}
                  handleInputChange={(event) => {
                    setCodigo(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <InputComponent
                  nome="descricao"
                  label="Descrição do Produto ou Serviço"
                  required
                  tipo="text"
                  value={descricao}
                  handleInputChange={(event) => {
                    setDescricao(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className="select-container">
                  <SelectComponent
                    list={listCsosn}
                    label="Situação tributária - CSOSN"
                    title="Selecione uma opção"
                    required
                    initialValue={
                      listCsosn.find(
                        (item) => item.id === situacao
                      )
                        ? listCsosn.find(
                          (item) => item.id === situacao
                        ).nome
                        : ""
                    }
                    callback={(id) => setSituacao(id)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="select-container">
                  <SelectComponent
                    list={listCfop}
                    label="CFOP de entrada"
                    title="Selecione uma opção"
                    required
                    initialValue={
                      listCfop.find(
                        (item) => item.id === cfop
                      )
                        ? listCfop.find(
                          (item) => item.id === cfop
                        ).nome
                        : ""
                    }
                    callback={(id) => setCfop(id)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  nome="valor"
                  label="Quantidade de entrada no estoque"
                  required
                  tipo="decimal"
                  value={quantidade}
                  handleInputChange={(event) => {
                    setQuantidade(event.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <SelectComponent
                  list={listMedidaComercial}
                  styleType="form"
                  label="Unidade de Medida do produto"
                  title="Selecione uma opção"
                  initialValue={
                    unidadeMedidaComercial !== undefined
                      ? listMedidaComercial.find(
                        (item) => item.id === unidadeMedidaComercial
                      )?.nome
                      : ""
                  }
                  required
                  callback={(id) => {
                    setUnidadeMedidaComercial(id)
                    const idAux = listMedidaComercial?.find( item => item.id === id)?.nome;
                    setUnidadeMedidaComercialDescricao(idAux)
                  }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  nome="valor"
                  label="Valor de venda"
                  required
                  tipo="dinheiro"
                  value={valor}
                  handleInputChange={(event) => {
                    setValor(event.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
              <div className={classes.inputLabel}>
                Valor de custo *
              </div>
                <div className="disabled-field">{ConvertMoney(!isNaN(valorCustoTotal) ? valorCustoTotal : 0)}</div>
              </Grid>
              <Grid item xs={12}>
                <span className="required-text">* Campos obrigatórios</span>
              </Grid>
              <Grid container justify="flex-end" item>
                <ButtonComponent
                  text="Salvar e confirmar"
                  onClick={handleSubmit}
                  className="btn-success zero-margin"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalCadastroProduto;
