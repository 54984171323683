import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import OverlayLoading from "../../components/OverlayLoading";
import CallbackMessage from "../../components/CallbackMessage";
import ConfirmDialog from "../../components/ConfirmDialog";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import Button from "../../components/ButtonComponent";
import AutocompleteInput from "../../components/AutocompleteInput";

import faturasService from "../../services/faturasService";
import boletoService from "../../services/boletoService";
import notasFiscaisService from "../../services/notasFiscaisService";

import ModalAlterarStatus from "../../components/ModalAlterarStatus";

import loadPermissoes from "../../contexts/RoutesContext";

import { formatDate } from "../../utils/dates";
import { numberToCurrency, base64ToFile } from "../../utils/functions";

import styles from "./styles.module.scss";
import { ModalRegistrarBaixa } from "../../components/ModalRegistrarBaixa";
import TableComponent from "../../components/TableResponsiveComponent";

// import "./styles.css";

const faturaOptions = [
  { id: "batch", name: "Lote" },
  { id: "only", name: "Única" },
];

function Faturas() {
  const history = useHistory();
  const params = useParams();

  const { id: idEmpresa } = params;

  const { getFaturas, baixaFatura, apagarFatura, estornarFatura } =
    faturasService;
  const { atualizaBoleto } = boletoService;
  const { consultaPDFInvoisys } = notasFiscaisService;
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [showInfoBaixa, setShowInfoBaixa] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [callbackType, setCallbackType] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);

  const [confirmBaixaDialog, setConfirmBaixaDialog] = useState(false);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [confirmEstornoDialog, setConfirmEstornoDialog] = useState(false);

  const [showModalAlterarStatus, setShowModalAlterarStatus] = useState(false);
  const [idFatura, setIdFatura] = useState(null);
  const [estornoId, setEstornoId] = useState(null);

  const [visible, setVisible] = useState(false);

  const perfilAcesso = window.localStorage.getItem("perfil_acesso");

  const isEmpresaPlataforma =
    perfilAcesso && (perfilAcesso === "1" || perfilAcesso === "2");

  const [listRequest, setListRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "fatura_id" },
      { columnName: "Competência", dataRef: "competencia" },
      { columnName: "Nota fiscal", dataRef: "numero_documento" },
      { columnName: "Classificação", dataRef: "classificacao" },
      { columnName: "Valor", dataRef: "valor_total" },
      { columnName: "Status Pagamento", dataRef: "status_pagamento" },
    ],
    options: {
      nfe2: true,
      search2: true,
      update: isEmpresaPlataforma ? false : true,
      more: isEmpresaPlataforma
        ? [
            {
              icon: "download-thin-blue",
              label: "Dar Baixa",
              labelOpcional: "Informações da Baixa",
              action: "baixa",
            },
            {
              icon: "estorno",
              label: "Estorno do Baixa",
              action: "estorno",
            },
            {
              icon: "copy-icon",
              label: "Copiar/Duplicar",
              action: "copiar",
            },
            {
              icon: "arrows",
              label: "Alterar Status",
              action: "alterar-status",
            },
            {
              icon: "update-icon",
              label: "Atualizar Boleto",
              action: "atualizar-boleto",
            },
          ]
        : false,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: "fatura_id",
    orderByType: "desc",
    perPage: null,
    searchTerm: null,
  });

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const response = await getFaturas(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      idEmpresa
    );

    console.log("listaBoletos :: ", response);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const responseData = response.data;

    if (responseData.hasOwnProperty("data")) {
      const { data } = responseData;
      const newData = data.map((item) => {
        return {
          ...item,
          data_vencimento: formatDate(item.data_vencimento),
          valor_total: numberToCurrency(item.valor_total),
          classificacao: item.classificacao ? item.classificacao : "-",
        };
      });
      const newResponse = {
        ...responseData,
        data: newData,
      };
      setListRequest(newResponse);
    } else {
      const newData = responseData.map((item) => {
        return {
          ...item,
          data_vencimento: formatDate(item.data_vencimento),
          valor_total: numberToCurrency(item.valor_total),
          classificacao: item.classificacao ? item.classificacao : "-",
        };
      });
      const newResponse = {
        ...responseData,
        data: newData,
      };
      setListRequest(newResponse);
    }

    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    getFaturas,
    idEmpresa,
  ]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onMoreAction = async (action) => {
    console.log(action);

    switch (action.action) {
      case "baixa": {
        setLoading(true);
        const fatura = listRequest.data.find(
          (fatura) => fatura.fatura_id === Number(action.id)
        );
        setShowInfoBaixa(fatura?.status_pagamento === "BAIXADA");
        setIdFatura(action.id);
        setConfirmBaixaDialog(true);

        setLoading(false);
        break;
      }
      case "apagar-fatura": {
        setLoading(true);

        setIdFatura(action.id);
        setConfirmRemoveDialog(true);

        setLoading(false);
        break;
      }
      case "copiar": {
        history.push("/nova-fatura", {
          isCopy: true,
          idFatura: action.id,
          id_cliente: idEmpresa,
        });
        break;
      }
      case "alterar-status": {
        setLoading(true);

        setIdFatura(action.id);
        setShowModalAlterarStatus(true);

        setLoading(false);
        break;
      }
      case "atualizar-boleto": {
        const fatura = listRequest.data.find(
          (fatura) => fatura.fatura_id === Number(action.id)
        );
        onUpdate(fatura.boleto_id);

        break;
      }
      case "estorno": {
        handleEstornoDialog(action.id);

        break;
      }
      case "estorno": {
        handleEstornoDialog(action.id);

        break;
      }
      default:
        break;
    }
  };

  const handleEstornoDialog = (id) => {
    setEstornoId(id);
    setConfirmEstornoDialog(true);
  };

  let handleRemoveCancel = () => {
    setConfirmBaixaDialog(false);
    setConfirmRemoveDialog(false);
  };

  const onSearch = async (id) => {
    const { fatura_id, boleto_id } = id;

    history.push(
      `/fatura-boleto/${fatura_id}${(boleto_id && `/${boleto_id}`) || ""}`
    );
  };

  let onFile = async (id) => {
    setLoading(true);

    const fatura = listRequest.data.find(
      (fatura) => fatura.fatura_id === Number(id)
    );

    const response = await consultaPDFInvoisys(Number(fatura.id_nota_fiscal));

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    console.log("response", response);

    if (response.status === 400) {
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList([response?.data?.msg]);
      setCallbackDuration(3000);
      setCallbackShown(true);
      setLoading(false);
    }

    if (response.status === 500) {
      setCallbackType("error");
      setCallbackErrorList(["Erro interno no servidor. Contate o suporte."]);
      setCallbackMessage("Erro!");
      setCallbackShown(true);
      setCallbackDuration(3000);
      setLoading(false);
      return;
    }

    if (response.status === 200 || response.status === 201) {
      base64ToFile(
        "data:application/pdf;base64," + response.data.pdf,
        "nota_fiscal"
      );
    }
    setLoading(false);
  };

  const handleCloseModalFatura = () => {
    return setShowModalAlterarStatus(false);
  };

  const handleCloseModalRegistrarBaixa = () => {
    return setConfirmBaixaDialog(false);
  };

  const onUpdate = async (id) => {
    setLoading(true);

    if (!id) {
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Essa fatura não possui boleto disponível."]);
      setCallbackDuration(3000);
      setCallbackShown(true);
      setLoading(false);
      return;
    }

    console.log("id", id);

    const response = await atualizaBoleto(id);
    console.log(response);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setCallbackType("success");
      setCallbackMessage("Fatuta atualizada com sucesso.");
      setCallbackDuration(2000);
      setCallbackShown(true);
      setLoading(false);
      return;
    } else {
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Erro ao atualizar o boleto, tente novamente."]);
      setCallbackDuration(3000);
      setCallbackShown(true);
      setLoading(false);
    }
    setLoading(false);
  };

  let onBaixaFatura = async () => {
    setLoading(true);
    const response = await baixaFatura(idFatura);

    if (response.status === 401) {
      setLoading(false);
      setConfirmBaixaDialog(false);
      setOpenLoginExpirado(true);
      return;
    }
    if (response.status !== 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setConfirmBaixaDialog(false);
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      return;
    }
    if (response.status === 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage("Fatura dada baixa com sucesso!");
      setCallbackDuration(2000);
      setConfirmBaixaDialog(false);
      await updateTable();
      return;
    }

    handleRemoveCancel();
    setLoading(false);
  };

  let onDeleteFatura = async () => {
    setLoading(true);
    const response = await apagarFatura(idFatura);

    if (response.status === 401) {
      setLoading(false);
      setConfirmRemoveDialog(false);
      setOpenLoginExpirado(true);
      return;
    }
    if (response.status !== 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setConfirmRemoveDialog(false);
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      return;
    }
    if (response.status === 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage("Fatura apagada com sucesso!");
      setCallbackDuration(2000);
      setConfirmRemoveDialog(false);
      await updateTable();
      return;
    }

    handleRemoveCancel();
    setLoading(false);
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  const handleEstornoCancel = () => {
    setEstornoId(null);
    setConfirmEstornoDialog(false);
  };

  let handleSelectFaturaOption = async (event) => {
    const itemId = event.target.dataset.id;
    setVisible(false);
    setLoading(true);

    if (itemId === "batch") {
      return history.push("/fatura-lote", {
        isNew: false,
        isBatch: true,
        id_cliente: idEmpresa,
      });
    }

    if (itemId === "only") {
      return history.push("/nova-fatura", {
        isNew: true,
        isBatch: false,
        id_cliente: idEmpresa,
      });
    }

    setLoading(false);
  };

  const onEstorno = async () => {
    console.log({ estornoId });
    setLoading(true);
    const response = await estornarFatura(estornoId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setCallbackMessage(response.data.message || "Estornado com sucesso!");
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackShown(true);

      updateTable();
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
    setLoading(false);
    handleEstornoCancel();
  };

  const handleNovaFatura = useCallback(() => {
    return history.push("/nova-fatura", {
      isNew: true,
      id_cliente: idEmpresa,
    });
  }, []);

  let handleEmitirFatura = useCallback(() => {
    setVisible((prev) => !prev);
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      {perfilAcesso === "1" && (
        <>
          <div className={styles.nfHeaderButton}>
            <Button
              onClick={handleEmitirFatura}
              className="btn-primary icon-right"
              text="Emitir Fatura"
              icone="hamburger"
              iconeColor="#fff"
              iconeSize={20}
            />
            <div>
              <ul className={visible ? styles.visible : ""}>
                {faturaOptions.map((item, index) => (
                  <li
                    key={index}
                    data-id={item.id}
                    onMouseDown={handleSelectFaturaOption}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}

      <ConfirmDialog
        title="Baixa na fatura"
        description="Você tem certeza que deseja dar baixa na fatura?"
        cancelLabel="Cancelar"
        acceptLabel="Confirmar"
        // open={confirmBaixaDialog}
        onCancel={handleRemoveCancel}
        onAccept={onBaixaFatura}
      />
      <ModalAlterarStatus
        idFatura={idFatura}
        open={showModalAlterarStatus}
        onClose={handleCloseModalFatura}
        cbTable={updateTable}
      />

      <ModalRegistrarBaixa
        idFatura={idFatura}
        open={confirmBaixaDialog}
        showInfo={showInfoBaixa}
        onClose={handleCloseModalRegistrarBaixa}
        cbTable={updateTable}
      />
      <div className="container-tabela-responsiva">
        <TableComponent
          idName="fatura_id"
          dataList={listRequest !== undefined ? listRequest.data : []}
          tableConfig={defaultConfigTable}
          callbackCurrentPage={onPageChange}
          callbackOrderBy={onOrderBy}
          callbackPerPage={onPerPage}
          callbackSearchTerm={onSearchTerm}
          cbSearch={onSearch}
          cbFile={onFile}
          cbUpdate={onUpdate}
          cbMoreAction={onMoreAction}
          pageId="faturas"
        />
      </div>
      <CallbackMessage
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        type={callbackType}
        handleClose={handleClose}
      />
      <ConfirmDialog
        open={confirmEstornoDialog}
        onCancel={handleEstornoCancel}
        onAccept={onEstorno}
        description="Você tem certeza que deseja realizar o estorno?"
        title="CONFIRMAR ESTORNO"
        acceptLabel="Confirmar"
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default Faturas;
