import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useMediaQuery,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../ButtonComponent";
import DatePicker from "../DatePicker";
import OverlayLoading from "../OverlayLoading";
import Input from "../Input";

import "./styles.scss";
import CallbackMessage from "../CallbackMessage";

const ConfirmDialog = ({
  open,
  title,
  description,
  acceptLabel,
  cancelLabel,
  onAccept,
  onCancel,
  haveReason,
  haveDate,
  haveSecondDate,
  dateLabel,
  secondDateLabel,
  onClose,
  btnClass = "btn-danger",
  noCancel = false,
  cancelClass = "",
  hideOnMobile = false,
  warning = null
}) => {
  const mobileScreen = useMediaQuery("(max-width: 768px)");

  const [callbackErrorList, setCallbackErrorList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState(null);
  const [date, setDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);

  async function handleAccept() {
    if (haveSecondDate) {
      const fieldsErros = [];
      if (!date) {
        fieldsErros.push("O campo data de início é obrigatório");
      }
      if (!secondDate) {
        fieldsErros.push("O campo data final é obrigatório");
      }
      console.log({ fieldsErros });
      if (fieldsErros.length !== 0) {
        setCallbackErrorList(fieldsErros);
        return;
      }
      await onAccept(date, secondDate);
      setLoading(false);
      setDate(null);
      setSecondDate(null);
      return;
    }
    setLoading(true);

    if (haveReason) {
      await onAccept(reason);
      setLoading(false);
      return;
    }

    if (haveDate) {
      await onAccept(date);
      setLoading(false);
      return;
    }

    await onAccept();
    setLoading(false);
    console.log("here");
    setDate(null);
    setSecondDate(null);
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackErrorList([]);
    }
    if (reason === "timeout") {
      setCallbackErrorList([]);
    }
  }

  function handleCloseModal() {
    setDate(null);
    setSecondDate(null);
    if (onClose) {
      onClose();
      return;
    }
    onCancel();
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      className={`paper ${haveDate || haveSecondDate ? "with-date" : ""}`}
    >
      {loading && <OverlayLoading />}
      <div className="modal-title-area">
        <DialogTitle className="dialog-title-custom-width">
          <span className="titleConfirmDialog">{title}</span>
        </DialogTitle>
        {(!mobileScreen || !hideOnMobile) && (
          <IconButton onClick={handleCloseModal} size="medium">
            <CloseIcon className="iconConfirm" />
          </IconButton>
        )}
      </div>
      <DialogContent className="custom-dialog-content">
        <pre className="confirmDescription">{description}</pre>
        {haveReason && (
          <Input
            nome="justificativa"
            label="Motivo do cancelamento"
            tipo="text"
            required
            value={reason}
            handleInputChange={(event) => {
              setReason(event.target.value);
            }}
          />
        )}
        {haveDate && (
          <DatePicker
            label={dateLabel}
            initDate={date}
            handleChange={(date) => {
              setDate(date);
            }}
          />
        )}
        {warning ? warning : null}
        {haveDate || haveReason ? <div className="required-text">
          <span>* Campos obrigatórios</span>
        </div> : null}
        {haveSecondDate && (
          <Grid
            container
            spacing={2}
            className={description ? "container-form-dates" : ""}
          >
            <Grid item xs={12} md={6}>
              <DatePicker
                label={dateLabel}
                initDate={date || ""}
                handleChange={(date) => {
                  setDate(date);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                label={secondDateLabel}
                initDate={secondDate || ""}
                handleChange={(date) => {
                  setSecondDate(date);
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions className="buttonContent">
        <>
          {!noCancel && (
            <Button
              text={cancelLabel}
              className={`btn-secondary-strong btnSize ${cancelClass}`}
              onClick={() => {
                setDate(null);
                setSecondDate(null);
                onCancel();
              }}
            />
          )}
          <Button
            text={acceptLabel}
            className={`${btnClass} btnSize`}
            onClick={async () => handleAccept()}
          />
        </>
      </DialogActions>
      <CallbackMessage
        type="error"
        open={callbackErrorList?.length}
        errorList={callbackErrorList}
        duration={4000}
        message={"Erro!"}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
};

ConfirmDialog.defaultProps = {
  open: false,
  description: "Você tem certeza que deseja realizar a exclusão?",
  cancelLabel: "Cancelar",
  acceptLabel: "Excluir",
  title: "CONFIRMAR EXCLUSÃO",
};

export default ConfirmDialog;
