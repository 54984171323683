import Axios from './Axios';

export default {
  cadastraServico: async ({
    prefeitura,
    arquivo
  }) => {
    const formData = new FormData()
    formData.append('id_prefeitura', prefeitura)
    formData.append('arquivo', arquivo)

    const response = await Axios.post('/importar-servicos-prefeitura', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  listaServicos: async ({
    page = null,
    id_prefeitura = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    data_inicial = null,
    data_final = null
  }) => {
    console.log(perPage);
    console.log(data_inicial);
    console.log(data_final);
    const response = await Axios.post('/lista-importacoes-servicos-prefeitura', {
        page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_prefeitura,
        data_inicial,
        data_final
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  removerServico: async (id) => {
    const response = await Axios.get(`/remove-produto/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selecionaServico: async (id) => {
    const response = await Axios.get(`/seleciona-produto/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  consultaServico: async (id, altera_codigo = null) => {
    const response = await Axios.get(
      `/consulta-produto/${id}?altera_codigo=${altera_codigo}`
    )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  atualizaServico: async ({
    idCodigoServico,
    estado,
    prefeitura,
    arquivo
  }
  ) => {
    const response = await Axios.post(`/atualiza-produto/${idCodigoServico}`, {
      estado,
      prefeitura,
      arquivo
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
};
