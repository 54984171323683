import Axios from "./Axios";

export const documentacaoContabilService = {
  getDocumentacao: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null
  ) => {
    const result = await Axios.get("/lista-documento-contabil", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  deleteDocumentacao: async (id) => {
    const result = await Axios.get(`/remove-documento-contabil/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  consultaDocumentacao: async (id) => {
    const result = await Axios.get(`/consulta-documento-contabil/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  atualizaDocumento: async (
    id,
    titulo,
    id_categoria_documento_contabil,
    id_empresa,
    arquivo,
    observacoes,
    nome_arquivo
  ) => {
    const result = await Axios.post(`/atualiza-documento-contabil/${id}`, {
      titulo: titulo,
      id_categoria_documento_contabil: id_categoria_documento_contabil,
      id_empresa: id_empresa,
      arquivo: arquivo,
      observacoes,
      nome_arquivo
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  cadastraDocumento: async (
    titulo,
    id_categoria_documento_contabil,
    id_empresa,
    arquivo,
    observacoes,
    nome_arquivo
  ) => {
    const result = await Axios.post(`/cadastra-documento-contabil`, {
      titulo: titulo,
      id_categoria_documento_contabil: id_categoria_documento_contabil,
      id_empresa: id_empresa,
      arquivo: arquivo,
      observacoes,
      nome_arquivo
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  selectCategorias: async () => {
    const result = await Axios.get("/seleciona-documento-contabil")
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};
