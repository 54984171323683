import { Dialog, DialogTitle, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState, useEffect } from "react";
import validateFields from "../../../utils/validateFields";
import ButtonComponent from "../../ButtonComponent";
import CallbackMessage from "../../CallbackMessage";
import InputComponent from "../../Input";
import ModalLoginExpirado from "../../ModalLoginExpirado";
import OverlayLoading from "../../OverlayLoading";
import "./styles.scss";



function ModalPagamentoDinheiroPDV({
  open,
  onClose,
  onSave,
  totalValue,
}) {
  const [amount, setAmount] = useState(null);
  const [troco, setTroco] = useState(null);
  const [total, setTotal] = useState(null);

  const [cadastrarCliente, setCadastrarCliente] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  async function handleSubmit() {
    setErrors([]);

    let fields = [
      {
        label: "valor",
        value: amount,
      },
    ];
    
    const fieldsValidate = validateFields(fields);

    if (fieldsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(fieldsValidate);
      return;
    }

    if(Number(totalValue) <= 0) {
      setShowMessage(true);
      setErrors(["Você não pode pagar uma conta com valor igual a zero."]);
      return;
    }

    let numberConvert = Math.abs(Number(troco));
    
    let data = {
      amount: Number(amount),
      troco: numberConvert,
    }

    onSave(data, 'money');
    handleCloseModal();
  }

  useEffect(() => {
    setLoading(true);
    setTotal(totalValue);
    setLoading(false);
  }, [totalValue])

  const handleCloseModal = () => {
    setShowMessage(false);
    setAmount(null);
    onClose();
  }

  const handleClose = () => {
    setShowMessage(false);
    // setAmount(null);
    
    if (errors.length === 0) {
      onClose();
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>PAGAMENTO EM DINHEIRO</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                <InputComponent
                  nome="valor-pago"
                  label="Valor pago"
                  tipo="dinheiro"
                  required
                  value={amount}
                  handleInputChange={(event) => {
                    setAmount(event.value);
                  }}
                />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <InputComponent
                  nome="valor-total"
                  label="Valor total"
                  tipo="dinheiro"
                  value={total}
                  disabled
                  handleInputChange={(event) => {}}
                />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <InputComponent
                  nome="troco"
                  label="Troco"
                  tipo="dinheiro"
                  value={amount === null ? 0 : amount > total ? amount - total : 0}
                  disabled
                  handleInputChange={(event) => setTroco(event.value)}
                />
                </Grid>
                <Grid item xs={12}>
                <span className="required-text">* Campos obrigatórios</span>
                </Grid>

              <Grid container justify="flex-end" spacing={2} item>
              <Grid item>
               <ButtonComponent
                  text="Cancelar"
                  onClick={onClose}
                  className="primary-outline zero-margin btn-modal-outline"
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  text="Pagar"
                  onClick={handleSubmit}
                  className="btn-success zero-margin"
                />
              </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}

export default ModalPagamentoDinheiroPDV;