import Axios from './Axios';
const empresasService = {
  getEmpresas: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    status = null,
    dataInicio = null,
    dataFim = null
  ) => {
    const result = await Axios.get('/lista-empresa', {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        status,
        data_inicio: dataInicio,
        data_fim: dataFim,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  selectEmpresa: async () => {
    const idUsuario = window.localStorage.getItem('id_usuario');
    const select = await Axios.get('/seleciona-empresa', {
      params: {
        id_usuario: idUsuario,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return select;
  },
  consultaEmpresa: async (id) => {
    const response = await Axios.get(`/consulta-empresa/${id}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cadastraEmpresa: async (form) => {
    const response = await Axios.post('/cadastra-empresa', {
      cnpj: form.cnpj,
      razao_social: form.razaoSocial,
      nome_fantasia: form.nome_fantasia,
      id_cnae: form.cnae,
      id_natureza_juridica: form.naturezaJuridica,
      inscricao_estadual: form.inscricao_estadual,
      inscricao_municipal: form.inscricao_municipal,
      site: form.site,
      cep: form.cep,
      endereco: form.endereco,
      numero: form.numEnd,
      complemento: form.complemento,
      bairro: form.bairro,
      cidade: form.cidade,
      uf: form.estado,
      cnaeSec: form.cnaeSec,
      socios: form.socio,
      contato: form.contato,
      modulo_nfe: form.modulo_nfe,
      senha_govbr: form.senha_govbr,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  preCadastroEmpresa: async (form) => {
    const response = await Axios.post('/erp-pre-cadastro', {
      ...form
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  atualizaEmpresa: async (id, form) => {
    const update = await Axios.post(`/atualiza-empresa/${id}`, {
      cnpj: form.cnpj,
      razao_social: form.razaoSocial,
      nome_fantasia: form.nome_fantasia,
      id_cnae: form.cnae,
      id_natureza_juridica: form.naturezaJuridica,
      inscricao_estadual: form.inscricao_estadual,
      inscricao_municipal: form.inscricao_municipal,
      site: form.site,
      cep: form.cep,
      endereco: form.endereco,
      numero: form.numEnd,
      complemento: form.complemento,
      bairro: form.bairro,
      cidade: form.cidade,
      uf: form.estado,

      cnaeSec: form.cnaeSec,

      socios: form.socio,
      contato: form.contato,
      modulo_nfe: form.modulo_nfe,
      senha_govbr: form.senha_govbr,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return update;
  },
  removerEmpresa: async (id) => {
    const response = await Axios.get(`/remover-empresa/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  bloquearEmpresa: async (id) => {
    const response = await Axios.get(`/bloquear-empresa/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  bloqueioEmpresa: async (id_empresa, bloqueio) => {
    const response = await Axios.post(`/bloqueio-empresa`, {
      id_empresa: id_empresa,
      bloqueio: bloqueio,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  inativarEmpresa: async (id_empresa, bloqueio, data_inativacao) => {
    const response = await Axios.get(
      `/ativar-inativar-empresa/${id_empresa}?data_inativacao=${data_inativacao}`,
      {
        bloqueio: bloqueio,
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  ativarEmpresa: async (id_empresa, bloqueio) => {
    const response = await Axios.get(`/ativar-inativar-empresa/${id_empresa}`, {
      bloqueio: bloqueio,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cadastrarCertificado: async (
    id,
    data_validade_certificado_digital,
    senha_certificado_digital,
    certificado,
    nome_certificado
  ) => {
    const response = await Axios.post('/certificado-empresa', {
      id,
      data_validade_certificado_digital,
      senha_certificado_digital,
      certificado,
      nome_certificado,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  alterarStatusEmpresa: async (id_empresa, status, observacao) => {
    const response = await Axios.post('/alterar-status-empresa', {
      id_empresa,
      status,
      observacao,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  consultarCertificado: async (id) => {
    const response = await Axios.get(`/certificado-empresa/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  editarConfiguracao: async (id, discriminacao_servico, provisao, ferias) => {
    const response = await Axios.post(`/edita-configuracao/${id}`, {
      discriminacao_servico: discriminacao_servico,
      provisao: provisao,
      ferias: ferias,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  selecionaQualificacao: async () => {
    const response = await Axios.get('/seleciona-qualificacao')
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  consultaComplemento: async (idEmpresa) => {
    const response = await Axios.get(
      `/consulta-empresa-complemento/${idEmpresa}`
    )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selectSituacaoEmpresa: async () => {
    const response = Axios.get('/seleciona-situacao-empresa')
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  editarDadosFolha: async ({
    idEmpresa,
    dataPagamento,
    adiantamento,
    sindicato = null,
    cnpj = null
  }) => {
    const response = await Axios.post('/dp-dados-folha', {
      id_empresa: idEmpresa,
      data_pagamento: dataPagamento,
      adiantamento,
      sindicato,
      cnpj
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  bucarDadosFolha: async (idEmpresa) => {
    const response = Axios.get(`/consulta-dp-dados-folha/${idEmpresa}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
};

export default empresasService;
