import Axios from "./Axios";

const buscaCnpjService = {
  getCnpjReceita: async (cnpjValue = null) => {
    const result = await Axios.get("/busca-cnpj", {
      params: {
        cnpj: cnpjValue,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};

export default buscaCnpjService;
