import React, { useState } from "react";
import { Accordion } from "../../../../components/AccordionComponent";
import { Grid } from "@material-ui/core";
import { formataNumero } from "../../../../utils/functions";

export function AccordionDre({ dados, columns = [] }) {
  const [expanded, setExpanded] = useState(false);
  const [expandedCategoria, setExpandedCategoria] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeCategoria = (panel) => (event, isExpanded) => {
    setExpandedCategoria(isExpanded ? panel : false);
  };
  return (
    <>
      {dados.map((item, index) => {
        const values = columns.map((itemCol) => {
          const periodos = item.meses;

          return (
            <>
              <span>
                {formataNumero(Number(periodos[itemCol.id]["orcado"]))}
              </span>
              <span>
                {formataNumero(Number(periodos[itemCol.id]["realizado"]))}
              </span>
            </>
          );
        });
        item.descricao = item.nome;
        item.total = (
          <>
            <span>{formataNumero(item.saldo["orcado"])}</span>
            <span>{formataNumero(item.saldo["realizado"])}</span>
          </>
        );
        const categorias = item.categorias || [];
        item.descricaoRowspan = (
          <>
            <span>Orçado</span>
            <span>Realizado</span>
          </>
        );
        return (
          <div key={`summary_${index}`}>
            <Accordion.Root
              handleChange={handleChange}
              index={index}
              expanded={expanded}
            >
              <Accordion.Header
                expanded={expanded}
                index={index}
                item={item}
                columns={values}
                // showTipoCategoria
                rowSpan={true}
              />

              <Accordion.Body>
                {categorias.map((itemCategoria, indexCategoria) => {
                  const valuesCategoria = columns.map((itemCol) => {
                    const periodos = itemCategoria.meses;

                    return (
                      <div className="item-rowspan">
                        <span>
                          {formataNumero(
                            Number(periodos[itemCol.id]["orcado"])
                          )}
                        </span>
                        <span>
                          {formataNumero(
                            Number(periodos[itemCol.id]["realizado"])
                          )}
                        </span>
                      </div>
                    );
                  });
                  itemCategoria.total = (
                    <>
                      <span>
                        {formataNumero(itemCategoria.saldo["orcado"])}
                      </span>
                      <span>
                        {formataNumero(itemCategoria.saldo["realizado"])}
                      </span>
                    </>
                  );
                  itemCategoria.descricao = itemCategoria.nome;
                  const subcategorias = itemCategoria.subcategorias || [];
                  itemCategoria.descricaoRowspan = <></>;
                  return (
                    <Accordion.Root
                      handleChange={handleChangeCategoria}
                      index={indexCategoria}
                      expanded={expandedCategoria}
                      child={true}
                      key={`categoria_${indexCategoria}`}
                    >
                      <Accordion.Header
                        expanded={expandedCategoria}
                        index={indexCategoria}
                        item={itemCategoria}
                        columns={valuesCategoria}
                        // showTipoCategoria
                        rowSpan={true}
                      />

                      <Accordion.Body>
                        <Grid container direction="column">
                          {subcategorias.map(
                            (subcategoria, indexSubcategoria) => {
                              const valuesSubcategoria = columns.map(
                                (itemCol) => {
                                  const periodos = subcategoria.meses;
                                  return (
                                    <div className="item-rowspan">
                                      <span>
                                        {formataNumero(
                                          Number(periodos[itemCol.id]["orcado"])
                                        )}
                                      </span>
                                      <span>
                                        {formataNumero(
                                          Number(
                                            periodos[itemCol.id]["realizado"]
                                          )
                                        )}
                                      </span>
                                    </div>
                                  );
                                }
                              );
                              return (
                                <Grid
                                  item
                                  container
                                  direction="column"
                                  key={`subcategoria_${indexCategoria}_${indexSubcategoria}`}
                                >
                                  <Grid
                                    item
                                    className="table-details container-row-dre"
                                  >
                                    <Grid
                                      item
                                      container
                                      className="inner-font-title name-categoria title-categoria"
                                    >
                                      <div>{subcategoria.nome}</div>
                                    </Grid>
                                    <Grid
                                      item
                                      container
                                      alignItems="center"
                                      className="name-tipo-categoria"
                                    >
                                      {subcategoria.tipo_subcategoria}
                                    </Grid>
                                    {valuesSubcategoria.map(
                                      (itemSubcat, indexSubcat) => {
                                        return (
                                          <Grid
                                            item
                                            key={`subCat${indexCategoria}_${indexSubcategoria}_${indexSubcat}`}
                                            className="cell-table keep-number cell-info"
                                          >
                                            {itemSubcat}
                                          </Grid>
                                        );
                                      }
                                    )}
                                    <Grid
                                      item
                                      className="name-column total-column center-end"
                                    >
                                      <div className="item-rowspan">
                                        <span>
                                          {formataNumero(
                                            subcategoria.saldo["orcado"]
                                          )}
                                        </span>
                                        <span>
                                          {formataNumero(
                                            subcategoria.saldo["realizado"]
                                          )}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      </Accordion.Body>
                    </Accordion.Root>
                  );
                })}
              </Accordion.Body>
            </Accordion.Root>
            <div className="space-vertical" />
          </div>
        );
      })}
    </>
  );
}
