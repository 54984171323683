import React, { useEffect, useState } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ButtonComponent from "../ButtonComponent";
import OverlayLoading from "../OverlayLoading";
import TableComponent from "../TableComponent";
import CallbackMessage from "../CallbackMessage";
import conciliacaoService from "../../services/conciliacaoService";
import { formatDate } from "../../utils/dates";
import { numberToCurrency } from "../../utils/functions";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";

import "./styles.scss";

function ModalBuscarConciliacao({
  open = () => {},
  onClose = () => {},
  type = "",
  idEmpresa = "",
  idLancamento = "",
  onSubmit = () => {},
  transacao = null,
  usarIntegracao,
}) {
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listRequest, setListRequest] = useState([]);
  const [listCheckBox, setListCheckBox] = useState([]);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      {
        columnName:
          type === "recebimento" ? "Data de recebimento" : "Data de pagamento",
        dataRef: "data_referencia",
      },
      { columnName: "Categoria", dataRef: "nome_categoria" },
      { columnName: "Cliente/Fornecedor", dataRef: "nome" },
      { columnName: "Valor", dataRef: "valor" },
      {
        columnName: "diferenca",
        dataRef: "classe_linha_estilo_especial",
        hide: true,
      },
    ],
    display: {
      startCheckBox: true,
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  let updateTable = async () => {
    setLoading(true);
    let result = [];
    let list = [];
    let l = [];
    if (type === "recebimento") {
      await conciliacaoService
        .getListaRecebimento(
          idEmpresa,
          defaultConfigTable.currentPage,
          defaultConfigTable.orderBy,
          defaultConfigTable.orderByType,
          defaultConfigTable.perPage,
          defaultConfigTable.searchTerm,
          transacao.valorOriginal || null
        )
        .then((response) => {
          if (response.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          }
          result = response;
        });
    } else if (type === "pagamento") {
      await conciliacaoService
        .getListaPagamento(
          idEmpresa,
          defaultConfigTable.currentPage,
          defaultConfigTable.orderBy,
          defaultConfigTable.orderByType,
          defaultConfigTable.perPage,
          defaultConfigTable.searchTerm,
          transacao.valorOriginal || null
        )
        .then((response) => {
          if (response.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          }
          result = response;
        });
    }
    setLoading(false);
    if (result.status !== 200) return;
    if (result.data.hasOwnProperty("data")) list = result.data.data;
    else list = result.data;
    console.log(list);
    list.forEach((item, i) => {
      item.data_referencia = formatDate(item.data_referencia);
      item.id = item.id_lancamento;
      item.id = item.id_lancamento;
      item.valor = numberToCurrency(item.valor);
      item.classe_linha_estilo_especial =
        Number(item.diferenca) === 0 ? "linha-verde" : false;
      l.push(item);
    });
    if (result.data.hasOwnProperty("data")) {
      result.data.data = l;
      setListRequest(result.data);
    } else {
      result.data = l;
      setListRequest(result);
    }
  };

  let onClickSubmit = async () => {
    setLoading(true);
    await conciliacaoService
      .conciliarLote(idLancamento, listCheckBox, usarIntegracao)
      .then((response) => {
        if (response.status !== 200)
          if (response.data.error)
            setErrors(Object.values(response.data.error));
          else setErrors(["Ocorreu um erro"]);
        setLoading(false);
        setShowMessage(true);
        if (response.status === 200) onSubmit();
      });
  };

  function handleClose() {
    if (errors.length === 0) {
      onClose();
    }
    setShowMessage(false);
    setErrors([]);
  }

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onCheckBox = (value) => {
    setListCheckBox(value);
  };

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    async function init() {
      setLoading(true);
      await updateTable();
      setLoading(false);
    }
    init();
  }, [type]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      className="modal-buscar-lancamento"
    >
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justifyContent="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>BUSCAR LANÇAMENTOS</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{ marginRight: 16, color: "#EF4066", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content-buscar-conciliacao">
        <Grid item container direction="column">
          <Grid item>
            <TableComponent
              idName="id"
              dataList={listRequest !== undefined ? listRequest?.data : []}
              tableConfig={defaultConfigTable}
              callbackCurrentPage={onPageChange}
              callbackOrderBy={onOrderBy}
              callbackPerPage={onPerPage}
              callbackSearchTerm={onSearchTerm}
              cbCheckBox={onCheckBox}
            />
          </Grid>
          <Grid item container justifyContent="flex-end">
            <ButtonComponent
              text="Salvar e conciliar"
              className="btn-success btn-margin"
              onClick={onClickSubmit}
            />
          </Grid>
          <CallbackMessage
            open={showMessage}
            type={`${errors.length === 0 ? "success" : "error"}`}
            message={`${
              errors.length === 0
                ? "Conciliação realizada com sucesso!"
                : "Erro!"
            }`}
            errorList={errors}
            handleClose={handleClose}
            duration={errors.length === 0 ? 2000 : 6000}
          />
        </Grid>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}

export default ModalBuscarConciliacao;
