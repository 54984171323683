import React from "react";
import "./styles.css";

function ViewClickSign() {
  function Clicksign(o) {
    "use strict";
    var r,
      u,
      t = window.location.protocol + "//" + window.location.host,
      e = {},
      n = function (n) {
        var t;
        (e[((t = n), t.name || t)] || []).forEach(function (t) {
          t(n.data);
        });
      },
      a = function (t) {
        n(t.data);
      };
    return {
      endpoint: "https://app.clicksign.com",
      origin: t,
      mount: function (t) {
        var n = "/sign/" + o,
          e = "?embedded=true&origin=" + this.origin,
          i = this.endpoint + n + e;
        return (
          (u = document.getElementById(t)),
          (r = document.createElement("iframe")).setAttribute("src", i),
          r.setAttribute("style", "width: 100%; height: 100%;"),
          r.setAttribute("allow", "camera"),
          window.addEventListener("message", a),
          u.appendChild(r)
        );
      },
      unmount: function () {
        return (
          r &&
            (u.removeChild(r),
            (r = u = null),
            window.removeEventListener("message", n)),
          !0
        );
      },
      on: function (t, n) {
        return e[t] || (e[t] = []), e[t].push(n);
      },
      trigger: n,
    };
  }

  let widget;

  let run = () => {
    console.log("ViewClickSign run()");

    var request_signature_key = "d1ac099b-2f47-49c6-b317-57e48681e185";
    if (widget) {
      widget.unmount();
    }

    widget = new Clicksign(request_signature_key);

    console.log(widget);

    widget.endpoint = "https://sandbox.clicksign.com";
    widget.origin = "http://localhost:3000";
    widget.mount("container");

    widget.on("loaded", function (ev) {
      console.log("loaded!");
    });
    widget.on("signed", function (ev) {
      console.log("==========");
      console.log("signed!");
      console.log(ev);
      console.log("==========");
    });
    widget.on("resized", function (height) {
      console.log("resized!");
      document.getElementById("container").style.height = height + "px";
    });
  };

  return (
    <>
      <div className="session-container">
        <p>ViewClickSign</p>
        <br />
        <input type="button" value="Load" onClick={run} />
        <div id="container" style={{ height: "600px" }}></div>
      </div>
    </>
  );
}

export default ViewClickSign;
