import React, { useEffect, useState } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InputComponent from "../Input";
import Textarea from "../Textarea";
import DatePicker from "../DatePicker";
import SelectComponent from "../SelectComponent";
import ButtonComponent from "../ButtonComponent";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import InputFile from "../InputFile";
import CallbackMessage from "../CallbackMessage";
import validateFields from "../../utils/validateFields";
import transferenciaService from "../../services/transferenciaService";
import contasBancariasService from "../../services/contasBancariasService";

import "./styles.css";

function ModalTransferencia({
  open,
  onClose,
  idEmpresa,
  idContaOrigem,
  nomeContaOrigem,
  callback = () => {},
}) {
  const [dataVencimento, setDataVencimento] = useState("");
  const [valor, setValor] = useState("");
  const [descricao, setDescricao] = useState("");
  const [contaDestinoId, setCintaDestinoId] = useState("");
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState([]);
  const [listContaDestino, setListContaDestino] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  async function handleSubmit() {
    const fields = [
      {
        label: "datavencimento",
        value: dataVencimento,
      },
      {
        label: "valor",
        value: valor,
      },
      {
        label: "contaselect",
        value: contaDestinoId,
      },
      {
        label: "descricao",
        value: descricao,
      },
    ];

    const errorsValidate = validateFields(fields);
    if (errorsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(errorsValidate);
      return;
    }
    setLoading(true);
    await transferenciaService
      .cadastraTransferencia(
        idEmpresa,
        idContaOrigem,
        contaDestinoId,
        dataVencimento,
        Number(valor),
        descricao,
        file
      )
      .then((response) => {
        console.log(response);
        if (response.status !== 200)
          if (response.data.error)
            setErrors(Object.values(response.data.error));
          else setErrors(["Ocorreu um erro"]);
        else if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        setLoading(false);
        setShowMessage(true);
        if (response.status === 200 || response.status === 201) {
          callback();
        }
      });
  }

  function handleClose() {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
    setErrors([]);
  }

  async function loadContaDestino() {
    await contasBancariasService.selecionaConta(idEmpresa).then((response) => {
      console.log(response);
      if (response.status === 200) setListContaDestino(response.data);
      else if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
    });
  }

  useEffect(() => {
    async function init() {
      setLoading(true);
      await loadContaDestino();
      setLoading(false);
    }
    init();
  }, [idEmpresa]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>TRANSFERÊNCIAS ENTRE CONTAS</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <span className="label">Conta origem</span>
              <div className="input-read-only">{nomeContaOrigem}</div>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs>
                <DatePicker
                  label="Data Vencimento *"
                  handleChange={(value) => setDataVencimento(value)}
                />
              </Grid>
              <Grid item xs>
                <InputComponent
                  tipo="dinheiro"
                  label="Valor *"
                  handleInputChange={(event) => setValor(event.value)}
                />
              </Grid>
              <Grid item xs>
                <SelectComponent
                  list={listContaDestino}
                  label="Conta destino *"
                  styleType="form"
                  callback={(value) => setCintaDestinoId(value)}
                  title="Selecione uma conta"
                />
              </Grid>
            </Grid>
            <Grid item>
              <Textarea
                label="Descrição *"
                handleInputChange={(event) => setDescricao(event.target.value)}
              />
            </Grid>
            <Grid item>
              <InputFile title="Anexo" onChange={(value) => setFile(value)} />
            </Grid>
            <Grid container justify="flex-end" item>
              <ButtonComponent
                text="Salvar"
                onClick={handleSubmit}
                className="btn-success zero-margin"
              />
            </Grid>
          </Grid>
          <CallbackMessage
            open={showMessage}
            type={`${errors.length === 0 ? "success" : "error"}`}
            message={`${
              errors.length === 0
                ? "Transferência realizada com sucesso!"
                : "Erro!"
            }`}
            errorList={errors}
            handleClose={handleClose}
            duration={errors.length === 0 ? 2000 : 6000}
          />
        </form>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}

export default ModalTransferencia;
