import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { versaoService } from "../../services/versaoService";

export default function ModalNovaVersao() {
  const history = useHistory();
  const { marcarComoVisualizada } = versaoService;
  const [open, setOpen] = useState(
    window.localStorage.getItem("exibir_modal_versao") == "1" || false
  );

  const [versaoAtual, setVersaoAtual] = useState([]);

  const onClose = useCallback(
    async (props, reason) => {
      if (reason === "backdropClick" || reason === "escapeKeyDown") return;
      try {
        const idVersao = window.localStorage.getItem("id_versao");
        if (!isNaN(idVersao)) {
          await marcarComoVisualizada(Number(idVersao));
        }
        window.localStorage.setItem("exibir_modal_versao", 0);
        setOpen(false);
        if (props.redirect) {
          history.push("/novidades");
        }
      } catch (error) {
        console.log({ error });
      }
    },
    [setOpen]
  );

  useEffect(() => {
    const versao = window.localStorage.getItem("versao");
    if (versao?.split(".")?.length > 0) {
      setVersaoAtual(versao?.split("."));
    }
  }, []);

  return (
    <Dialog
      open={open}
      className="modalNovaVersao"
      onClose={onClose}
      disableEscapeKeyDown={true}
    >
      <DialogContent className="modal-content">
        <IconButton
          className="botaoFecharModal"
          onClick={onClose}
          size="medium"
        >
          <CloseIcon />
        </IconButton>
        <div className="containerModal">
          <div className="containerNumeroVersao">
            {versaoAtual.map((item, index) => (
              <div className="containerNumeroItemVersao" key={index.toString()}>
                {item}
              </div>
            ))}
          </div>
          <div className="containerTitle">NOVA VERSÃO DISPONÍVEL</div>
          <div className="containerSubtitle">
            Explore as novidades da <span className="contabil">contábil</span>
            <span className="hub">hub!</span>
          </div>
          <div className="containerBotao">
            <button type="button" onClick={() => onClose({ redirect: true })}>
              Acesse Aqui
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
