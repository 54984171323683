import React, { useEffect, useState, memo, useCallback } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory, useParams } from "react-router-dom";
import {
  onlyNumbers,
  isValidCnpj,
  // isValidCpf,
} from "../../../utils/strings.js";
import validateFields from "../../../utils/validateFields";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import SelectComponent from "../../../components/SelectComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import Input from "../../../components/Input";
import Switch from "../../../components/Switch";
import Icon from "../../../components/Icon";
import DatePicker from "../../../components/DatePicker";

import CallbackMessage from "../../../components/CallbackMessage";
import OverlayLoading from "../../../components/OverlayLoading";
import Stepper from "../../../components/Stepper";

import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";

import { Grid, Divider } from "@material-ui/core";

import empresasService from "../../../services/empresasService";
import buscaCnpjService from "../../../services/buscaCnpjService";
import buscaCepService from "../../../services/buscaCepService";
import contasBancariasService from "../../../services/contasBancariasService";

import selectServices from "../../../services/selectServices";

import "./styles.scss";
import viacepServices from "../../../services/viacepServices.js";

const CustomCheckbox = withStyles({
  root: {
    color: "#812990",
    marginTop: 10,
    width: 0,
    "&$checked": {
      color: "#812990",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function CadastroEmpresas() {
  const history = useHistory();
  const locationState = history.location.state;
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const params = useParams();
  
  let { id: idParams } = params;
  const [id, setId] = useState(idParams);

  const IntId = parseInt(id, 10);
  const isEdit = Number.isInteger(IntId);

  const [loadingInputCnpj, setLoadingInputCnpj] = useState(false);
  const [loadingInputCep, setLoadingInputCep] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [listCNAE, setListCNAE] = useState([]);
  const [listNatureza, setListNatureza] = useState([]);
  const [listQualificacao, setListQualificacao] = useState([]);
  const [listBancos, setListBancos] = useState([]);
  const [listTipoConta, setListTipoConta] = useState([]);
  const [listEstados, setListEstados] = useState([]);
  const [listOrgaoEmissor, setListOrgaoEmissor] = useState([]);

  // INICIO CAMPOS PARA O SERVICE
  const [naturezaJuridica, setNaturezaJuridica] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cnae, setCnae] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numEnd, setNumEnd] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nome_fantasia, setNome_fantasia] = useState("");
  const [site, setSite] = useState("");
  const [inscricao_municipal, setInscricao_municipal] = useState("");
  const [inscricao_estadual, setInscricao_estadual] = useState("");
  const [utilizaNfe, setUtilizaNfe] = useState(false);

  const [senhaPortalGov, setSenhaPortalGov] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  //Contato
  const objContato = {
    nome_social: "",
    nome: "",
    email: "",
    telefone: "",
    celular: "",
    departamento: "",
  };
  const [formContato, setFormContato] = useState([objContato]);
  const handleDuplicateContato = () =>
    setFormContato([...formContato, objContato]);
  const handleRemoveContato = (index) => {
    formContato.splice(index, 1);
    setFormContato([...formContato]);
  };

  //CNAESecundario
  const objCnae = {
    id: "",
  };
  const [formCnae, setformCnae] = useState([]);
  const handleDuplicateCnae = () => setformCnae([...formCnae, objCnae]);
  function handleRemoveCnae(index) {
    formCnae.splice(index, 1);
    setformCnae([...formCnae]);
  }

  // Socios
  const ObjSocio = {
    nome: "",
    data_nascimento: "",
    cpf: "",
    pis: "",
    email: "",
    cod_qualificacao: null,
    pro_labore: false,
    utiliza_endereco_empresa: 0,
    cep: "",
    endereco: "",
    bairro: "",
    numero: "",
    complemento: "",
    uf: "",
    cidade: "",
    rg: "",
    data_emissao_pis: '',
    orgao_emissor_identidade: '',
    data_emissao_identidade: '',
    id_uf_identidade: '',
    nro_ctps: '',
    serie_ctps: '',
    id_uf_ctps: '',
    data_emissao_ctps: '',
    id_tipo_conta: '',
    id_banco: '',
    banco_nome: '',
    agencia: '',
    conta: '',
    digito_conta: '',
    valor_pro_labore: '',
  };

  const [formSocio, setFormSocio] = useState([ObjSocio]);
  const handleDuplicateSocio = () => setFormSocio([...formSocio, ObjSocio]);
  const handleRemoveSocio = (index) => {
    formSocio.splice(index, 1);
    setFormSocio([...formSocio]);
  };

  // FIM CAMPOS PARA O SERVICE

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  useEffect(() => {
    setLoading(true);
    updateListNatureza();
    updateListCNAE();
    updateListQualificacao();
    loadBancos();
    loadEstados();
    loadTipoContaBancaria();
    loadOrgaoEmissor();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (isEdit) {
      setLoading(true);
      async function getRequest() {
        const response = await empresasService.consultaEmpresa(id);
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }else if(response.status === 400){
          setCallbackType("error");
          setCallbackMessage(response.data.msg || "Erro!");
          setCallbackErrorList([response.data.error] || ["Erro ao consultar empresa!"]);
          setLoading(false);
          setCallbackShown(true);
          setId(null);
          return;
        }

        const data = response.data;
        console.log("data :: ", data);
        if (data) {
          setCnae(data.id_cnae);
          setNaturezaJuridica(data.id_natureza_juridica);
          setCnpj(data.cnpj);
          setRazaoSocial(data.razao_social);
          setBairro(data.bairro);
          setCep(data.cep);
          setEndereco(data.endereco);
          setNumEnd(data.numero);
          setCidade(data.cidade);
          setEstado(data.uf);
          setComplemento(data.complemento);
          setNome_fantasia(data.nome_fantasia);
          setSite(data.site);
          setInscricao_municipal(data.inscricao_municipal);
          setInscricao_estadual(data.inscricao_estadual);
          setSenhaPortalGov(data.senha_govbr);
          setUtilizaNfe(data.modulo_nfe === 1);

          // setCod_indicador_ie(data.cod_indicador_ie);

          console.log(data.socio_empresas);

          const sociosData = data.socio_empresas.map((socio) => {
            return {
              ...socio,
              pro_labore: socio.pro_labore === 1,
              conta: socio.conta ? socio.conta.substring(0, socio.conta.length - 1) : null,
              digito_conta: socio.conta ? socio.conta.at(-1) : null
            };
          });

          console.log(sociosData);

          setFormSocio(sociosData);

          setFormContato(data.contato_empresas, ...formContato);

          let cnae2 = data.cnae_secundario_empresas.map((c) => ({ id: c }));
          setformCnae(cnae2, ...formCnae);
        }
        setLoading(false);
      }
      getRequest();
    }
  }, []);

  async function updateListCNAE() {
    const response = await selectServices.getCNAE();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const data = response.data;
    // const newData = data.map((item) => {
    //   const cod_nae = item.nome.substring(0, 7);
    //   return {
    //     ...item,
    //     cod_nae,
    //   };
    // });

    // console.log(newData);

    setListCNAE(data);
  }
  const updateListNatureza = async () => {
    const response = await selectServices.getNatureza();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListNatureza(response.data);
  };

  const updateListQualificacao = async () => {
    const response = await empresasService.selecionaQualificacao();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    setListQualificacao(response.data);
  };

  async function loadBancos() {
    try {
      const response = await contasBancariasService.getBancos();
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      const data = response.data;
      
      setListBancos(data);
    } catch (error) {
      setListBancos([]);
      setLoading(false);
    }
  }

  async function loadEstados() {
    try {
      const response = await viacepServices.selecionaEstado();
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      const data = response.data;
      
      setListEstados(data);
    } catch (error) {
      setListEstados([]);
      setLoading(false);
    }
  }

  const loadTipoContaBancaria = useCallback(async () => {
    await selectServices.selecionaTipoContaBancariaDp().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        if (
          typeof response.data === "object" &&
          Object.keys(response.data)?.length > 0
        ) {
          const arrayFormatted = Object.keys(response.data).map((key) => ({
            id: Number(key),
            nome: response.data[key],
          }));
          setListTipoConta(arrayFormatted);
        } else {
          setListTipoConta([]);
        }
      }
    });
  }, []);

  const loadOrgaoEmissor = useCallback(async () => {
    await selectServices.selecionaOrgaoEmissorDp().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        if (
          typeof response.data === "object" &&
          Object.keys(response.data)?.length > 0
        ) {
          const arrayFormatted = Object.keys(response.data).map((key) => ({
            id: Number(key),
            nome: response.data[key],
          }));
          setListOrgaoEmissor(arrayFormatted);
        } else {
          setListOrgaoEmissor([]);
        }
      }
    });
  }, []);

  const handleNextStep = () => {
    if (locationState?.details) {
      return setActiveStep(activeStep + 1);
    }
    if (activeStep === 0) {
      const stepZeroFields = [
        // {
        //   label: "cnpj",
        //   value: cnpj,
        // },
        {
          label: "razaosocial",
          value: razaoSocial,
        },
        {
          label: "cnaeprincipal",
          value: cnae,
        },
        {
          label: "naturezajuridica",
          value: naturezaJuridica,
        },
        {
          label: "inscricaoestadual",
          value: inscricao_estadual,
        },
        {
          label: "inscricaomunicipal",
          value: inscricao_municipal,
        },
      ];
      const stepZeroErrors = validateFields(stepZeroFields);

      if (stepZeroErrors.length !== 0) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(stepZeroErrors);
        return;
      }
    }

    if (activeStep === 1) {
      const stepOneFields = [
        {
          label: "cep",
          value: cep,
        },
        {
          label: "endereco",
          value: endereco,
        },
        {
          label: "bairro",
          value: bairro,
        },
        {
          label: "numero",
          value: numEnd,
        },
        {
          label: "estado",
          value: estado,
        },
        {
          label: "cidade",
          value: cidade,
        },
      ];
      const stepOneErrors = validateFields(stepOneFields);

      if (stepOneErrors.length !== 0) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(stepOneErrors);
        return;
      }
    }

    if (activeStep === 2) {
      let hasError = false;

      console.log(formSocio);

      formSocio.forEach((socio) => {
        let socioFields = [
          {
            label: "nome",
            value: socio.nome,
          },
          {
            label: "datanascimento",
            value: socio.data_nascimento,
          },
          {
            label: "cpf",
            value: socio.cpf,
          },
          {
            label: "email",
            value: socio.email,
          },
          {
            label: "qualificacao",
            value: socio.cod_qualificacao,
          },
        ];

        if(socio.utiliza_endereco_empresa === 0) {
          socioFields.push(
            {
              label: "cep",
              value: socio.cep,
            },
            {
              label: "endereco",
              value: socio.endereco,
            },
            {
              label: "bairro",
              value: socio.bairro,
            },
            {
              label: "numero",
              value: socio.numero,
            },
            {
              label: "estado",
              value: socio.uf,
            },
            {
              label: "cidade",
              value: socio.cidade,
            },
          )
        }

        if(socio.pro_labore === 1) {
          socioFields.push(
            {
              label: "orgaoidentidade",
              value: socio.orgao_emissor_identidade,
            },
            {
              label: "emissaoidentidade",
              value: socio.data_emissao_identidade,
            },
            {
              label: "ufidentidade",
              value: socio.id_uf_identidade,
            },
            {
              label: "ctps",
              value: socio.nro_ctps,
            },
            {
              label: "seriectps",
              value: socio.serie_ctps,
            },
            {
              label: "ufCtpsDp",
              value: socio.id_uf_ctps,
            },
            {
              label: "emissaoctps",
              value: socio.data_emissao_ctps,
            },
          )

          if (socio.conta) {
            socioFields.push(
              {
                label: "digito",
                value: socio.digito_conta,
              })
          }
        }

        const socioErrors = validateFields(socioFields);

        if (socioErrors.length !== 0) {
          hasError = true;
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackErrorList(socioErrors);
          return;
        }
      });

      if (hasError) return;
    }
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => setActiveStep(activeStep - 1);

  async function handleCnpjChange(_cnpj) {
    _cnpj = onlyNumbers(_cnpj);
    setCnpj(_cnpj);

    if (_cnpj.length === 14) {
      setCallbackShown(false);
      setLoadingInputCnpj(true);
      setCallbackErrorList([]);
      const response = await buscaCnpjService.getCnpjReceita(_cnpj);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoadingInputCnpj(false);
        return;
      }
      const data = response.data;
      setCallbackErrorList([]);
      if(response.status === 201) {
        if (data.status && data.status === "ERROR") {
          setCallbackErrorList(["CNPJ inválido"]);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setLoadingInputCnpj(false);
          setRazaoSocial("");
          setCep("");
          setCidade("");
          setEndereco("");
          setBairro("");
          setNumEnd("");
          setEstado("");
          setComplemento("");
          setNome_fantasia("");
          setCnae("");
          setNaturezaJuridica("");
          setFormSocio([ObjSocio]);
          setformCnae([objCnae]);
          return;
        }
        if (data) {
          if (data.situacao === "ATIVA") {
            let secCNAE = [];
            let socios = [];
            let rawCode = data.atividade_principal[0].code;
            rawCode = rawCode.replaceAll("-", "").replace(".", "");
            let rawNatureza = data.natureza_juridica.substring(0, 3);
            setRazaoSocial(data.nome);
            setCep(onlyNumbers(data.cep));
            setCidade(data.municipio);
            setEndereco(data.logradouro);
            setBairro(data.bairro);
            setNumEnd(data.numero);
            setEstado(data.uf);
            setComplemento(data.complemento);
            setNome_fantasia(data.fantasia);
            let indexCnae = listCNAE.findIndex(
              (element) => element.cod_cnae === rawCode
            );
            if (indexCnae > 0) {
              setCnae(listCNAE[indexCnae].id);
            } else setCnae("");
            for (let i = 0; i < data.atividades_secundarias.length; i++) {
              let item = data.atividades_secundarias[i];
              if (item.code !== "00.00-0-00") {
                let rawCode = item.code;
                rawCode = rawCode.replaceAll("-", "").replace(".", "");
                let indexCnae = listCNAE.findIndex(
                  (element) => element.cod_cnae === rawCode
                );
                if (indexCnae > 0) {
                  secCNAE.push({ id: listCNAE[indexCnae].id });
                }
              }
            }
            for (let i = 0; i < data.qsa.length; i++) {
              let item = data.qsa[i];
              let cod_qualificacao = Number(item.qual?.split("-")[0]);
              console.log("cod_qualificacao :: ", cod_qualificacao);
              socios.push({
                nome: item.nome,
                data_nascimento: "",
                cpf: "",
                pis: "",
                email: "",
                cod_qualificacao,
                pro_labore: null,
                utiliza_endereco_empresa: 0,
                cep: "",
                endereco: "",
                bairro: "",
                numero: "",
                complemento: "",
                uf: "",
                cidade: "",
                rg: "",
                data_emissao_pis: "",
                orgao_emissor_identidade: '',
                data_emissao_identidade: '',
                id_uf_identidade: '',
                nro_ctps: '',
                serie_ctps: '',
                id_uf_ctps: '',
                data_emissao_ctps: '',
                id_tipo_conta: '',
                id_banco: '',
                banco_nome: '',
                agencia: '',
                conta: '',
                digito_conta: '',
                valor_pro_labore: '',
              });
            }
            let indexNatureza = listNatureza.findIndex(
              (element) =>
                element.cod_natureza_juridica.cod_natureza_juridica ===
                rawNatureza
            );
            if (indexNatureza > 0) {
              setNaturezaJuridica(listNatureza[indexNatureza].id);
            } else setNaturezaJuridica("");
            if (socios.length > 0) setFormSocio(socios);
            else setFormSocio([ObjSocio]);
            setformCnae(secCNAE);
          }
        }
      } else if (response.status === 400 &&
        response.data.error.includes("Too Many Requests")) {
          setLoadingInputCnpj(false);
          setCallbackErrorList(["Ocorreu um excesso de consultas. Espere 1 minuto e tente novamente."]);
          setCallbackDuration(4000);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackShown(true);
      }
      setLoadingInputCnpj(false);
    }
    // else {
    //   alert("Cnpj inválido");
    //   setCnpj("");
    // }
  }

  async function handleCepChange(cep) {
    cep = onlyNumbers(cep);
    setCep(cep);
    
    if (cep.length === 8) {
      setLoadingInputCep(true);
      const response = await buscaCepService.getCep(cep);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoadingInputCep(false);
        return;
      }
      const data = response.data;
      if (data) {
        setCidade(data.localidade);
        setEstado(data.uf);
        setEndereco(data.logradouro);
        setBairro(data.bairro);
        setNumEnd("");
      }
      setLoadingInputCep(false);
    }
  }

  async function handleCepSocioChange(cep, index) {
    let list = [...formSocio];

    list[index].cep = cep;

    let newCepConsult = onlyNumbers(cep);

    if (newCepConsult.length === 8) {

      setLoadingInputCep(true);
      const response = await buscaCepService.getCep(newCepConsult);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoadingInputCep(false);
        return;
      }
      const data = response.data;
      if (data) {
        list[index].cidade = data.localidade;
        list[index].uf = data.uf;
        list[index].endereco = data.logradouro;
        list[index].bairro = data.bairro;
      }
      setLoadingInputCep(false);
    }

    setFormSocio(list);
  }

  // function handleChangeProlabore(event) {
  //   setProlabore(event.target.checked);
  // }

  function handleLinkEstadual() {
    let win = window.open("http://www.sintegra.gov.br/", "_blank");
    win.focus();
  }

  function handleLinkMunicipal() {
    let win = window.open(
      "https://ccm.prefeitura.sp.gov.br/login/contribuinte?tipo=F",
      "_blank"
    );
    win.focus();
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (activeStep === 3) {
      let hasError = false;
      formContato.forEach((contato) => {
        const contatoFields = [
          {
            label: "nomesocial",
            value: contato.nome_social,
          },
          {
            label: "nome",
            value: contato.nome,
          },
        ];
        const contatoErrors = validateFields(contatoFields);

        if (contatoErrors.length !== 0) {
          hasError = true;
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackErrorList(contatoErrors);
          return;
        }
      });

      if (hasError) return;
    }

    let success = false;
    let status = 0;
    let error = "";

    setLoading(true);
    if (isEdit) {
      console.log("getAtualFormData :: ", getAtualFormData);

      console.log("formSocio :: ", formSocio);
      const response = await empresasService.atualizaEmpresa(
        id,
        getAtualFormData
      );
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      console.log("atualizaEmpresa :: ", response);
      success = response.success;
      status = response.status;
      if (response.data.error) error = Object.values(response.data.error);
    } else {
      console.log("atualFormData :: ", getAtualFormData);
      avoidMultipleRequests();
      const response = await empresasService.cadastraEmpresa(getAtualFormData);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      console.log("cadastraEmpresa :: ", response);
      success = response.success;
      status = response.status;
      if (response.data.error) error = Object.values(response.data.error);
    }

    setLoading(false);

    if (success || status === 201) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage("Empresa salva com sucesso!");
      setCallbackDuration(2000);
    }

    if (error) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList([error]);
    }
  };
  const getAtualFormData = {
    cnpj: cnpj,
    cnae: cnae,
    razaoSocial: razaoSocial,
    nome_fantasia: nome_fantasia,

    endereco: endereco,
    numEnd: numEnd,
    complemento: complemento,
    bairro: bairro,
    cep: cep,
    cidade: cidade,
    estado: estado,

    site: site,

    naturezaJuridica: naturezaJuridica,
    inscricao_municipal: inscricao_municipal,
    inscricao_estadual: inscricao_estadual,

    modulo_nfe: utilizaNfe ? 1 : 0,
    senha_govbr: senhaPortalGov,

    contato: formContato, 
    socio: formSocio.map( item => ({
      ...item,
      conta: item.conta && item.digito_conta ? item.conta+item.digito_conta : item.conta ? item.conta : null,
    })),
    cnaeSec: formCnae,
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/empresas");
    }
  }
  // if (error) return <div>Error loading : {error.message}</div>
  // if (loading) return <OverlayLoading />;
  // if (!data.length) return <div>No data!!!</div>;

  const handleEstadoChange = (event) => {
    const { value, maxLength } = event.target;
    const estado = value.slice(0, maxLength);
    setEstado(estado.toUpperCase());
  };

  const handleClickStep = (newStep) => {
    if (locationState?.activeStepNavigation) {
      setActiveStep(newStep)
    }
  };

  const avoidMultipleRequests = () => {
    const button = document.getElementById("submitFinalButton");
    button.textContent = "Solicitado...";
    button.disabled = true;

    setTimeout(() => {
      button.textContent = "Tentar novamente";
      button.disabled = false;
    }, 10000);
  }

  return (
    <>
      <Grid container className="default-forms-container">
        <Grid item xs={2}>
          <Stepper
            useActiveStep
            activeStep={activeStep}
            arraySteps={[
              "Dados de Identificação",
              "Endereço",
              "Sócios",
              "Contato",
            ]}
            handleClickStep={handleClickStep}
            activeStepNavigation={locationState?.activeStepNavigation}
          />
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={8} style={{ marginTop: 20 }}>
              <form onSubmit={handleFormSubmit}>
                {/* step 0 documentos  */}
                {activeStep === 0 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Input
                        label="CNPJ"
                        tipo="cnpj"
                        name="cnpj"
                        value={cnpj}
                        loading={loadingInputCnpj}
                        handleInputChange={async (e) => {
                          await handleCnpjChange(e.target.value);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Razão Social"
                        name="razaoSocial"
                        value={razaoSocial}
                        required
                        handleInputChange={(e) => {
                          setRazaoSocial(e.target.value);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Nome Fantasia"
                        name="nome_fantasia"
                        value={nome_fantasia}
                        handleInputChange={(e) => {
                          setNome_fantasia(e.target.value);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectComponent
                        styleType="form"
                        label="CNAE Principal"
                        title="Selecione CNAE Principal"
                        list={listCNAE}
                        initialValue={
                          listCNAE.find((item) => item.id === cnae)
                            ? listCNAE.find((item) => item.id === cnae).nome
                            : ""
                        }
                        required
                        callback={(id) => setCnae(id)}
                        isDisabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {formCnae.map((c, index) => (
                        <React.Fragment key={index}>
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="space-between"
                            alignItems="flex-end"
                            // spacing={1}
                            style={{ marginBottom: 16 }}
                          >
                            <Grid item xs={locationState?.details ? 12 : 11}>
                              <SelectComponent
                                styleType="form"
                                name="cnae2c"
                                label="CNAE Secundário"
                                title="Selecione CNAE Secundario"
                                list={listCNAE}
                                initialValue={
                                  listCNAE.find((item) => item.id === c.id)
                                    ? listCNAE.find((item) => item.id === c.id)
                                        .nome
                                    : ""
                                }
                                callback={(id) => {
                                  let list = [...formCnae];
                                  list[index].id = id;
                                  setformCnae(list);
                                }}
                                isDisabled={locationState?.details}
                              />
                            </Grid>
                            <Grid item xs="auto">
                              {index >= 0 && !locationState?.details && (
                                <ButtonComponent
                                  text="-"
                                  className="secondary-outline btnAddRemove"
                                  onClick={() => handleRemoveCnae(index)}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                      {
                        !locationState?.details &&
                        <div className="directBtn">
                          <ButtonComponent
                            text="+ Adicionar"
                            onClick={() => handleDuplicateCnae()}
                            className="success-outline btnAddEmpresa"
                          />
                        </div>
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <SelectComponent
                        styleType="form"
                        label="Natureza juridica"
                        title="Selecione Natureza Juridica"
                        list={listNatureza}
                        initialValue={
                          listNatureza.find(
                            (item) => item.id === naturezaJuridica
                          )
                            ? listNatureza.find(
                                (item) => item.id === naturezaJuridica
                              ).nome
                            : ""
                        }
                        required
                        callback={(id) => setNaturezaJuridica(id)}
                        isDisabled={locationState?.details}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Input
                        label="Inscrição Municipal"
                        required
                        nome="inscricao_municipal"
                        value={inscricao_municipal || ""}
                        handleInputChange={(e) => {
                          setInscricao_municipal(e.target.value);
                        }}
                        labelSuffix={{
                          onClick: handleLinkMunicipal,
                          icon: "export",
                          title: "Clique aqui para consultar o número da Inscrição Municipal."
                         }}
                         disabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Inscrição Estadual"
                        labelSuffix={{
                           onClick: handleLinkEstadual,
                           title:"Clique aqui para consultar o número da Inscrição Estadual.",
                           icon: "export"
                        }}
                        required
                        name="inscricao_estadual"
                        handleInputChange={(e) => {
                          setInscricao_estadual(e.target.value);
                        }}
                        value={inscricao_estadual}
                        disabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Site"
                        nome="site"
                        value={site}
                        handleInputChange={(e) => {
                          setSite(e.target.value);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>
                    <Grid container spacing={2} justifyContent="space-between" style={{ padding: 8 }}>
                        
                        {/* <Grid container spacing={1} item xs={12} md={6} className="empresa-nfe">
                          <Grid item className="label">
                            Empresa utiliza NF-e? *
                          </Grid>
                          <div className="empresa-nfe-opcoes">
                            <Grid item className="label" style={{ marginRight: 12 }}>
                              Não
                            </Grid>
                            <Grid item>
                              <Switch
                                checked={utilizaNfe}
                                onClick={() => setUtilizaNfe(!utilizaNfe)}
                              />
                            </Grid>
                            <Grid item className="label">
                              Sim
                            </Grid>
                          </div>
                        </Grid> */}
                        <Grid item xs={12} md={12}>
                          <div className="password-input">
                            <Input
                              nome="password"
                              value={senhaPortalGov}
                              label="Senha portal Gov br"
                              tooltipType="infoButton"
                              labelSuffix={{
                                icon: "export",
                                title: "Portal Gov.br",
                                onClick: () => {
                                  window.open('https://sso.acesso.gov.br/login?client_id=contas.acesso.gov.br&authorization_id=176f186eaf', '_blank');
                                }
                              }}
                              tipo="text"
                              type={passwordVisible ? "text" : "password"}
                              handleInputChange={(event) => setSenhaPortalGov(event.target.value)}
                              disabled={locationState?.details}
                            />
                            <button
                              onClick={() => setPasswordVisible(prev => !prev)}
                              className="gov-password-icon"
                              type="button"
                            >
                              <div className="gov-password-icon-container">
                                {!passwordVisible ? (
                                  <Icon name="closed-eye" />
                                ) : (
                                  <Icon name="open-eye" />
                                )}
                              </div>
                            </button>
                          </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <span className="required-text">
                        * Campos obrigatórios
                      </span>
                    </Grid>
                  </Grid>
                )}
                {/* step 1 endereço */}
                {activeStep === 1 && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Input
                        label="CEP"
                        tipo="cep"
                        required
                        name="cep"
                        loading={loadingInputCep}
                        value={cep}
                        handleInputChange={(e) => {
                          handleCepChange(e.target.value);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                      <Input
                        label="Endereço"
                        name="endereco"
                        value={endereco}
                        required
                        handleInputChange={(e) => {
                          setEndereco(e.target.value);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Bairro"
                        name="bairro"
                        value={bairro}
                        required
                        handleInputChange={(e) => {
                          setBairro(e.target.value);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Número"
                        tipo="numero"
                        name="numEnd"
                        value={numEnd}
                        required
                        handleInputChange={(e) => {
                          setNumEnd(e.target.value);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Complemento"
                        name="complemento"
                        value={complemento}
                        handleInputChange={(e) => {
                          setComplemento(e.target.value);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Estado"
                        name="estado"
                        value={estado}
                        required
                        handleInputChange={handleEstadoChange}
                        maxLength={2}
                        disabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Cidade"
                        name="cidade"
                        value={cidade}
                        required
                        handleInputChange={(e) => {
                          setCidade(e.target.value);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <span className="required-text">
                        * Campos obrigatórios
                      </span>
                    </Grid>
                  </Grid>
                )}

                {/* step 2 socios */}
                {activeStep === 2 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {formSocio.map((c, index) => (
                        <React.Fragment key={index}>
                          <Grid container spacing={2}>
                            {index > 0 && (
                              <Grid item xs={12} style={{ marginTop: 20 }}>
                                <Divider />
                              </Grid>
                            )}
                            <Grid item xs={6}>
                              <Input
                                label="Nome"
                                name="nomesocioc"
                                value={c.nome}
                                required
                                handleInputChange={(e) => {
                                  let list = [...formSocio];
                                  list[index].nome = e.target.value;
                                  setFormSocio(list);
                                }}
                                disabled={locationState?.details}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Input
                                label="RG"
                                name="RG"
                                tipo="numero"
                                value={c.rg}
                                handleInputChange={(e) => {
                                  let list = [...formSocio];
                                  list[index].rg = e.target.value;
                                  setFormSocio(list);
                                }}
                                disabled={locationState?.details}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <DatePicker
                                noMask
                                label="Data Nascimento *"
                                initDate={c.data_nascimento}
                                handleChange={(date) => {
                                  let list = [...formSocio];
                                  list[index].data_nascimento = date;
                                  setFormSocio(list);
                                }}
                                readOnly={locationState?.details}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Input
                                label="CPF"
                                name="cpfc"
                                tipo="cpf"
                                value={c.cpf}
                                required
                                handleInputChange={(e) => {
                                  let list = [...formSocio];
                                  list[index].cpf = onlyNumbers(e.target.value);
                                  setFormSocio(list);
                                }}
                                disabled={locationState?.details}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Input
                                label="PIS"
                                name="pisc"
                                tipo="pis"
                                value={c.pis}
                                handleInputChange={(e) => {
                                  let list = [...formSocio];
                                  list[index].pis = e.target.value;
                                  setFormSocio(list);
                                }}
                                disabled={locationState?.details}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <SelectComponent
                                styleType="form"
                                label="Qualificação"
                                title="Selecione"
                                required
                                initialValue={
                                  listQualificacao.find(
                                    (item) => item.id === c.cod_qualificacao
                                  )?.nome || ""
                                }
                                list={listQualificacao}
                                callback={(id) => {
                                  let list = [...formSocio];
                                  list[index].cod_qualificacao = id;
                                  setFormSocio(list);
                                }}
                                isDisabled={locationState?.details}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Input
                                label="E-mail"
                                name="email"
                                required
                                value={c.email}
                                handleInputChange={(e) => {
                                  let list = [...formSocio];
                                  list[index].email = e.target.value;
                                  setFormSocio(list);
                                }}
                                disabled={locationState?.details}
                              />
                            </Grid>
                            <Grid 
                                container 
                                spacing={1} 
                                item 
                                xs={12} 
                                md={12} 
                                style={{ marginTop: 0 }}
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                            <Grid item className="container-input-switch">
                              Recebe pro-labore?
                              <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                                Não
                              </Grid>
                              <Grid item style={{ marginLeft: 12 }} >
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={Number(c.pro_labore) === 1}
                                      onClick={(event) => {
                                        let prolabore = !c.pro_labore;
                                        let list = [...formSocio];
                                        list[index].pro_labore = prolabore
                                          ? 1
                                          : 0;
                                        setFormSocio(list);
                                        console.log("list :: ", list);
                                        
                                      }}
                                      name="prolabore"
                                      disabled={locationState?.details}
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item style={{ marginLeft: -8 }} >
                                Sim
                              </Grid>
                            </Grid>
                            </Grid>
                            { c.pro_labore ? (
                                <>
                                <Grid item xs={6}>
                                  <DatePicker
                                    noMask
                                    label="Emissão do NIS/PIS/PASEP ou NIT"
                                    initDate={c.data_emissao_pis ? c.data_emissao_pis : ''}
                                    handleChange={(date) => {
                                      let list = [...formSocio];
                                      list[index].data_emissao_pis = date;
                                      setFormSocio(list);
                                    }}
                                    readOnly={locationState?.details}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <SelectComponent
                                    styleType="form"
                                    label="Órgão Emissor da Identidade"
                                    title="Selecione uma opção"
                                    list={listOrgaoEmissor}
                                    initialValue={
                                      listOrgaoEmissor.find((item) => item.id === c.orgao_emissor_identidade)
                                        ? listOrgaoEmissor.find((item) => item.id === c.orgao_emissor_identidade).nome
                                        : ""
                                    }
                                    required
                                    callback={(id) => {
                                      let list = [...formSocio];
                                      list[index].orgao_emissor_identidade = id;
                                      setFormSocio(list);
                                    }}
                                    isDisabled={locationState?.details}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <DatePicker
                                    noMask
                                    label="Emissão da Identidade*"
                                    initDate={c.data_emissao_identidade ? c.data_emissao_identidade : ''}
                                    handleChange={(date) => {
                                      let list = [...formSocio];
                                      list[index].data_emissao_identidade = date;
                                      setFormSocio(list);
                                    }}
                                    readOnly={locationState?.details}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <SelectComponent
                                    styleType="form"
                                    label="UF da Identidade"
                                    title="Selecione uma opção"
                                    list={listEstados}
                                    initialValue={
                                      listEstados.find((item) => item.id === c.id_uf_identidade)
                                        ? listEstados.find((item) => item.id === c.id_uf_identidade).nome
                                        : ""
                                    }
                                    required
                                    callback={(id) => {
                                      let list = [...formSocio];
                                      list[index].id_uf_identidade = id;
                                      setFormSocio(list);
                                    }}
                                    isDisabled={locationState?.details}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Input
                                    label="CTPS"
                                    required
                                    name="nro_ctps"
                                    value={c.nro_ctps}
                                    handleInputChange={(e) => {
                                      let list = [...formSocio];
                                      list[index].nro_ctps = e.target.value;
                                      setFormSocio(list);
                                    }}
                                    disabled={locationState?.details}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Input
                                    label="Série de CTPS"
                                    required
                                    name="serie_ctps"
                                    value={c.serie_ctps}
                                    handleInputChange={(e) => {
                                      let list = [...formSocio];
                                      list[index].serie_ctps = e.target.value;
                                      setFormSocio(list);
                                    }}
                                    disabled={locationState?.details}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <SelectComponent
                                    styleType="form"
                                    label="UF da CTPS"
                                    title="Selecione uma opção"
                                    list={listEstados}
                                    initialValue={
                                      listEstados.find((item) => item.id === c.id_uf_ctps)
                                        ? listEstados.find((item) => item.id === c.id_uf_ctps).nome
                                        : ""
                                    }
                                    required
                                    callback={(id) => {
                                      let list = [...formSocio];
                                      list[index].id_uf_ctps = id;
                                      setFormSocio(list);
                                    }}
                                    isDisabled={locationState?.details}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <DatePicker
                                    noMask
                                    label="Emissão da CTPS*"
                                    initDate={c.data_emissao_ctps ? c.data_emissao_ctps : ''}
                                    handleChange={(date) => {
                                      let list = [...formSocio];
                                      list[index].data_emissao_ctps = date;
                                      setFormSocio(list);
                                    }}
                                    readOnly={locationState?.details}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <SelectComponent
                                    styleType="form"
                                    label="Tipo da conta"
                                    title="Selecione uma opção"
                                    list={listTipoConta}
                                    initialValue={
                                      listTipoConta.find((item) => item.id === c.id_tipo_conta)
                                        ? listTipoConta.find((item) => item.id === c.id_tipo_conta).nome
                                        : ""
                                    }
                                    callback={(id) => {
                                      let list = [...formSocio];
                                      list[index].id_tipo_conta = id;
                                      setFormSocio(list);
                                    }}
                                    isDisabled={locationState?.details}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <SelectComponent
                                    styleType="form"
                                    label="Banco"
                                    title="Selecione uma opção"
                                    list={listBancos}
                                    initialValue={
                                      listBancos.find((item) => item.id === c.id_banco)
                                        ? listBancos.find((item) => item.id === c.id_banco).nome
                                        : ""
                                    }
                                    callback={(id) => {
                                      let list = [...formSocio];
                                      list[index].id_banco = id;
                                      setFormSocio(list);
                                    }}
                                    isDisabled={locationState?.details}
                                  />
                                </Grid>
                                {
                                  c.id_banco === 211 ? (
                                    <Grid item xs={12} md={6}>
                                      <Input
                                        label="Nome do banco"
                                        name="banco_nome"
                                        value={c.banco_nome}
                                        handleInputChange={async (e) => {
                                          let list = [...formSocio];
                                          list[index].banco_nome = e.target.value;
                                          setFormSocio(list);
                                        }}
                                        disabled={locationState?.details}
                                      />
                                    </Grid>
                                  ) : null
                                }
                                <Grid item xs={6}>
                                    <Input
                                      label="Agência"
                                      tipo="numero"
                                      name="agencia"
                                      value={c.agencia}
                                      handleInputChange={(e) => {
                                        let list = [...formSocio];
                                        list[index].agencia = e.target.value;
                                        setFormSocio(list);
                                      }}
                                      disabled={locationState?.details}
                                    />
                                </Grid>
                                <Grid container spacing={1} item xs={6} alignItems="flex-end">
                                  <Grid item xs={8}>
                                    <Input
                                      label="Conta"
                                      tipo="numero"
                                      name="conta"
                                      value={c.conta}
                                      handleInputChange={(e) => {
                                        let list = [...formSocio];
                                        list[index].conta = e.target.value;
                                        setFormSocio(list);
                                      }}
                                      disabled={locationState?.details}
                                    />
                                  </Grid>
                                  <Grid item xs={1} style={{margin: 'auto', marginBottom: 15, textAlign: 'center'}}>
                                  -
                                  </Grid>
                                  <Grid item xs={3} style={{marginTop: 'auto'}}>
                                    <Input
                                      label=""
                                      tipo="numero"
                                      name="digito_conta"
                                      value={c.digito_conta}
                                      handleInputChange={(e) => {
                                        let list = [...formSocio];
                                        list[index].digito_conta = e.target.value;
                                        setFormSocio(list);
                                      }}
                                      disabled={locationState?.details}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Input
                                    label="Valor do pró-labore"
                                    tipo="dinheiro"
                                    name="valor-pro-labore"
                                    value={c.valor_pro_labore}
                                    handleInputChange={(e) => {
                                      let list = [...formSocio];
                                      list[index].valor_pro_labore = e.value;
                                      setFormSocio(list);
                                    }}
                                    disabled={locationState?.details}
                                  />
                                </Grid>
                                </>
                              ) : null
                            }
                            <Grid 
                                container 
                                spacing={1} 
                                item 
                                xs={12} 
                                md={12} 
                                style={{ marginTop: 0 }}
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                            <Grid item className="container-input-switch">
                              Usar mesmo endereço da empresa?
                              
                              <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                                Não
                              </Grid>
                              <Grid item style={{ marginLeft: 12 }} >
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={c.utiliza_endereco_empresa === 1}
                                      onClick={(event) => {
                                        let utiliza_endereco_empresa = c.utiliza_endereco_empresa === 0;
                                        let list = [...formSocio];
                                        list[index].utiliza_endereco_empresa = utiliza_endereco_empresa
                                          ? 1
                                          : 0;
                                        setFormSocio(list);
                                      }}
                                      name="endereco-socio"
                                      disabled={locationState?.details}
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item style={{ marginLeft: -8 }} >
                                Sim
                              </Grid>
                            </Grid>
                            </Grid>
                            {!c.utiliza_endereco_empresa && (
                              <>
                            <Grid item xs={6}>
                              <Input
                                label="CEP"
                                tipo="cep"
                                required
                                name="cep"
                                loading={loadingInputCep}
                                value={c.cep}
                                handleInputChange={(e) => handleCepSocioChange(e.target.value, index)}
                                disabled={locationState?.details}
                                // handleInputChange={(e) => {
                                //   let list = [...formSocio];
                                //   list[index].cep = e.target.value;
                                //   setFormSocio(list);
                                // }}
                              />
                            </Grid>
                            <Grid item xs={6} />
                            <Grid item xs={6}>
                              <Input
                                label="Endereço"
                                name="endereco"
                                value={c.endereco}
                                required
                                handleInputChange={(e) => {
                                  let list = [...formSocio];
                                  list[index].endereco = e.target.value;
                                  setFormSocio(list);
                                }}
                                disabled={locationState?.details}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Input
                                label="Bairro"
                                name="bairro"
                                value={c.bairro}
                                required
                                handleInputChange={(e) => {
                                  let list = [...formSocio];
                                  list[index].bairro = e.target.value;
                                  setFormSocio(list);
                                }}
                                disabled={locationState?.details}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Input
                                label="Número"
                                tipo="numero"
                                name="numEnd"
                                value={c.numero}
                                required
                                handleInputChange={(e) => {
                                  let list = [...formSocio];
                                  list[index].numero = e.target.value;
                                  setFormSocio(list);
                                }}
                                disabled={locationState?.details}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Input
                                label="Complemento"
                                name="complemento"
                                value={c.complemento}
                                handleInputChange={(e) => {
                                  let list = [...formSocio];
                                  list[index].complemento = e.target.value;
                                  setFormSocio(list);
                                }}
                                disabled={locationState?.details}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Input
                                label="Estado"
                                name="estado"
                                value={c.uf}
                                required
                                handleInputChange={(e) => {
                                  let list = [...formSocio];
                                  list[index].uf = e.target.value;
                                  setFormSocio(list);
                                }}
                                disabled={locationState?.details}
                                maxLength={2}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Input
                                label="Cidade"
                                name="cidade"
                                value={c.cidade}
                                required
                                handleInputChange={(e) => {
                                  let list = [...formSocio];
                                  list[index].cidade = e.target.value;
                                  setFormSocio(list);
                                }}
                                disabled={locationState?.details}
                              />
                            </Grid>
                              </>
                            )}
                            
                            <Grid item xs={12}>
                              <div className="directBtn leftButton">
                                {index > 0 && !locationState?.details && (
                                  <ButtonComponent
                                    icon={AiOutlineUserDelete}
                                    text="Remover Sócio"
                                    className="secondary-outline btnAddAndRemove"
                                    onClick={() => handleRemoveSocio(index)}
                                  />
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                      { 
                        !locationState?.details &&
                        <Grid container justifyContent="flex-end">
                          <Grid item xs="auto">
                            <ButtonComponent
                              icon={AiOutlineUserAdd}
                              text="Adicionar Sócio"
                              onClick={() => handleDuplicateSocio()}
                              className="success-outline btnAddAndRemove"
                            />
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <span className="required-text">
                        * Campos obrigatórios
                      </span>
                    </Grid>
                  </Grid>
                )}
                {/* step 3 contatos */}
                {activeStep === 3 && (
                  <Grid container spacing={1} justifyContent="flex-end">
                    {formContato.map((c, index) => (
                      <React.Fragment key={index}>
                        <Grid container spacing={2}>
                          {index > 0 && (
                            <Grid item xs={12} style={{ marginTop: 20 }}>
                              <Divider />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Input
                              label="Como gostaria de ser chamado?"
                              required
                              name="nomesocialc"
                              value={c.nome_social}
                              handleInputChange={(e) => {
                                let list = [...formContato];
                                list[index].nome_social = e.target.value;
                                setFormContato(list);
                              }}
                              disabled={locationState?.details}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              label="Nome"
                              required
                              name="nomec"
                              value={c.nome}
                              handleInputChange={(e) => {
                                let list = [...formContato];
                                list[index].nome = e.target.value;
                                setFormContato(list);
                              }}
                              disabled={locationState?.details}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              label="E-mail"
                              tipo="email"
                              name="emailc"
                              value={c.email}
                              handleInputChange={(e) => {
                                let list = [...formContato];
                                list[index].email = e.target.value;
                                setFormContato(list);
                              }}
                              disabled={locationState?.details}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Input
                              label="Telefone"
                              tipo="telefone"
                              name="telefonec"
                              value={c.telefone}
                              handleInputChange={(e) => {
                                let list = [...formContato];
                                list[index].telefone = onlyNumbers(
                                  e.target.value
                                );
                                setFormContato(list);
                              }}
                              disabled={locationState?.details}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Input
                              label="Celular"
                              tipo="celular"
                              name="celularc"
                              value={c.celular}
                              handleInputChange={(e) => {
                                let list = [...formContato];
                                list[index].celular = onlyNumbers(
                                  e.target.value
                                );
                                setFormContato(list);
                              }}
                              disabled={locationState?.details}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              label="Qual o setor/departamento?"
                              name="departamentoc"
                              value={c.departamento}
                              handleInputChange={(e) => {
                                let list = [...formContato];
                                list[index].departamento = e.target.value;
                                setFormContato(list);
                              }}
                              disabled={locationState?.details}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <div className="directBtn leftButton">
                              {index > 0 && !locationState?.details && (
                                <>
                                  <ButtonComponent
                                    icon={AiOutlineUserDelete}
                                    text="Remover Contato"
                                    onClick={() => handleRemoveContato(index)}
                                    className="secondary-outline btnAddAndRemove"
                                  />
                                </>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}
                    <Grid item xs={12} style={{ marginTop: 16 }}>
                      <span className="required-text">
                        * Campos obrigatórios
                      </span>
                    </Grid>
                    {
                      !locationState?.details &&
                      <Grid item>
                        <ButtonComponent
                          icon={AiOutlineUserAdd}
                          text="Adicionar Contato"
                          onClick={() => handleDuplicateContato()}
                          className="success-outline btnAddAndRemove"
                        />
                      </Grid>
                    }
                  </Grid>
                )}

                {/* NAVIGATION BUTTONS */}
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{
                    marginTop: 40,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {activeStep > 0 && (
                    <>
                      <Grid item xs={3} style={{ marginRight: 10 }} className={locationState?.details && activeStep === 3 ? 'align-button-right' : ''}>
                        <ButtonComponent
                          text="Voltar"
                          className="btn-secondary btnSteps"
                          onClick={handleBack}
                        />
                      </Grid>
                    </>
                  )}
                  {activeStep <= 2 && (
                    <>
                      <Grid item {...(locationState?.edit ? {xs: 3} : {})} style={{ width: 120 }}>
                        <ButtonComponent
                          className="btn-success btnSteps"
                          text="Avançar"
                          onClick={handleNextStep}
                        />
                      </Grid>
                    </>
                  )}
                  
                  {activeStep <= 2 && locationState?.edit && (
                    <>
                      <Grid item style={{ width: 120 }}>
                        <ButtonComponent
                          className="btn-info btnSteps"
                          text="Salvar"
                          type="submit"
                        />
                      </Grid>
                    </>
                  )}
                  
                  {activeStep >= 3 && !locationState?.details && (
                    <>
                      <Grid item style={{width: 120}}>
                        <button id="submitFinalButton" type="submit" className="btn btn-info btnSteps"
                                >{locationState?.edit ? "Salvar" : "Finalizar"}</button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
        {loading && <OverlayLoading />}
        {callbackShown && (
          <CallbackMessage
            open={callbackShown}
            type={callbackType}
            handleClose={handleClose}
            message={callbackMessage}
            duration={callbackDuration}
            errorList={callbackErrorList}
          />
        )}
      </Grid>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default memo(CadastroEmpresas);
