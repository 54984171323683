import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TableComponent from "../TableComponent";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import CallbackMessage from "../CallbackMessage";
import notasFiscaisService from '../../services/notasFiscaisService';

import "./styles.scss";

function ModalItensNota({
  open,
  onClose,
  idNota,
}) {

  const { listaNotaEntradaItens } = notasFiscaisService;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [listRequest, setListRequest] = useState([]);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Código", dataRef: "codigo" },
      { columnName: "Descrição", dataRef: "descricao" },
      { columnName: "Quantidade de Itens", dataRef: "quantidade_tributavel" },
      { columnName: "CFOP", dataRef: "cfop" },
      { columnName: "CEST", dataRef: "cest" },
      { columnName: "ORDEM", dataRef: "ordem" },
      { columnName: "EAN", dataRef: "ean" },
      { columnName: "EAN Tributário", dataRef: "ean_tributario" },
      // { columnName: "Indicador Total", dataRef: "valor_total" },
      // { columnName: "Status", dataRef: "status" },
    ],
    display: {
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });


  let updateTable = useCallback(async () => {
    if(idNota) {
    window.scrollTo(0, 0);

    setLoading(true);

    const response = await listaNotaEntradaItens(
      idNota,
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
    );

    if (response.data) {
      if (response.data.hasOwnProperty("data")) {
        
        const { data } = response?.data;

        const newData = data?.map(item => ({
          ...item,
          id: item.id_nota_fiscal_produto_entrada_item,
        }))

        const newResult = {
          ...response.data,
          data: newData,
        }

        setListRequest(newResult);
      } else {
        const { data } = response?.data;

        const newData = data?.map(item => ({
          ...item,
          id: item.id_nota_fiscal_produto_entrada_item,
        }))

        const newResult = {
          ...response.data,
          data: newData,
        }

        setListRequest(newResult);
      }
    }
    setLoading(false);
    }
  }, [defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    idNota,
  ]);

  useEffect(() => {
    updateTable();
  }, [updateTable])

  useEffect(() => {
    const newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  let onPageChange = useCallback((newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onOrderBy = useCallback((newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onPerPage = useCallback((newPerPage) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onSearchTerm = useCallback((value) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }

  const handleCloseModal = async () => {
    setLoading(false);
    onClose();
  }

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={handleCloseModal} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>DETALHES - ITENS</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        {/* <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>

             <Grid item xs={12} className="modal-items">
                <ShowMore title="DADOS DO EMITENTE" listInfo={dataEmitente} /> 
              </Grid>

              <Grid item xs={12} className="modal-items">
                <ShowMore title="DADOS DO DESTINATÁRIO" listInfo={dataDestinatario} /> 
              </Grid>

              <Grid item xs={12} className="modal-items">
                <ShowMore title="VALORES TOTAIS" listInfo={dataValores} /> 
              </Grid>

              <Grid item xs={12} className="modal-items">
                <ShowMore title="OUTROS DADOS" listInfo={data} /> 
              </Grid>

            </Grid>
          </Grid>
        </form> */}

      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
      />
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalItensNota;
