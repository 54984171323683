import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";

function FaturaContainer({ children }) {
  const history = useHistory();


  return (
    <>
      <div className="fatura-container-default">
        <div className="fatura-container-default-component">{children}</div>
      </div>
    </>
  );
}

export default FaturaContainer;
