import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import Input from "../../../../components/Input";
import ModalLoginExpirado from "../../../../components/InputFile";
import ButtonComponent from "../../../../components/ButtonComponent";
import SelectComponent from "../../../../components/SelectComponent";
import OverlayLoading from "../../../../components/OverlayLoading";
import DatePicker from "../../../../components/DatePicker";
import CallbackMessage from "../../../../components/CallbackMessage";
import verifyFields from "../../../../utils/validateFields";
import { Grid } from "@material-ui/core";
import "./styles.scss";
import viacepServices from "../../../../services/viacepServices";
import selectServices from "../../../../services/selectServices";
import preAdmissaoService from "../../../../services/preAdmissaoService";

function CadastroDocumentos() {
  const history = useHistory();
  const params = useParams();
  const { idFuncionario } = params;
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cpf, setCpf] = useState();
  const [nis, setNis] = useState();
  const [emissaoNis, setEmissaoNis] = useState();
  const [identidade, setIdentidade] = useState();
  const [tipoIdentidade, setTipoIdentidade] = useState();
  const [orgaoEmissorIdentidade, setOrgaoEmissorIdentidade] = useState();
  const [emissaoIdentidade, setEmissaoIdentidade] = useState();
  const [ufIdentidade, setUfIdentidade] = useState();
  const [ctps, setCtps] = useState();
  const [serieCtps, setSerieCtps] = useState();
  const [ufCtps, setUfCtps] = useState();
  const [emissaoCtps, setEmissaoCtps] = useState();
  const [titulo, setTitulo] = useState();
  const [zona, setZona] = useState();
  const [secao, setSecao] = useState();
  const [cnh, setCnh] = useState();
  const [emissaoCnh, setEmissaoCnh] = useState();
  const [validadeCnh, setValidadeCnh] = useState();
  // eslint-disable-next-line
  const [categoriaCnh, setCategoriaCnh] = useState();
  const [orgaoCnh, setOrgaoCnh] = useState();
  const [ufCnh, setUfCnh] = useState();
  const [primeiraEmissaoCnh, setPrimeiraEmissaoCnh] = useState();
  // eslint-disable-next-line
  const [tipoCnh, setTipoCnh] = useState();
  const [listTipoCnh, setListTipoCnh] = useState([]);
  const [listTipoIdentidade, setListIdentidade] = useState([]);
  const [listEstados, setListEstados] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [errors, setErrors] = useState([]);
  const [tipoMaskIdentidade, setTipoMaskIdentidade] = useState("");

  async function loadEstados() {
    await viacepServices.selecionaEstado().then((response) => {
      if (response.status === 200)
        setListEstados(
          response.data.map((item) => {
            item.nome = item.uf;
            return item;
          })
        );
    });
  }

  const handleChangeTipoIdentidade = useCallback((id) => {    
    setTipoIdentidade(id);

    switch (id) {
      case 1:
        setTipoMaskIdentidade("rg");
        break;
      case 2:
        setTipoMaskIdentidade("");
        break;
      case 3:
        setTipoMaskIdentidade("rne");
        break;
      default:
        break;
    }
  }, [tipoMaskIdentidade, setTipoMaskIdentidade]);

  async function loadTipoIdentidade() {
    await selectServices.selecionaTipoIdentidade().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      } else if (response.status === 200) setListIdentidade(response.data);
    });
  }

  async function loadTipoCnh() {
    await selectServices.selecionaTipoCnh().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      } else if (response.status === 200) setListTipoCnh(response.data);
    });
  }

  const loadPreAdmissaoDocumentos = async () => {
    if(!idFuncionario) {
      return;
    }

    if (idFuncionario) {
      const response = await preAdmissaoService.consultaPreAdmissao(idFuncionario);
    
      console.log("response:::", response);

      if (response.status === 200) {        
        const data = response.data;
        
        setCpf(data?.cpf);
        setSerieCtps(data?.serie_ctps);
        setCtps(data?.nro_ctps);
        setUfCtps(data?.id_uf_ctps);
        setUfIdentidade(data?.id_uf_identidade);
        setTipoIdentidade(data?.id_tipo_identidade);
        setOrgaoEmissorIdentidade(data?.orgao_emissor_identidade);
        setIdentidade(data?.identidade);
        setEmissaoIdentidade(data?.data_emissao_identidade);
        setEmissaoCtps(data?.data_emissao_ctps);
        setEmissaoNis(data?.data_emissao_pis);
        data?.pis !== null && setNis(data?.pis);
        data?.id_categoria_cnh !== null && setTipoCnh(data?.id_categoria_cnh);
        data?.id_uf_cnh !== null && setUfCnh(data?.id_uf_cnh);
        data?.titulo_eleitor !== null && setTitulo(data?.titulo_eleitor);
        data?.zona_eleitoral !== null && setZona(data?.zona_eleitoral);
        data?.secao_eleitoral !== null && setSecao(data?.secao_eleitoral);
        data?.secao_eleitoral !== null && setSecao(data?.secao_eleitoral);
        data?.carteira_habilitacao !== null && setCnh(data?.carteira_habilitacao);
        data?.data_emissao_cnh !== null && setEmissaoCnh(data?.data_emissao_cnh);
        data?.data_validade_cnh !== null && setValidadeCnh(data?.data_validade_cnh);
        data?.orgao_emissor_cnh !== null && setOrgaoCnh(data?.orgao_emissor_cnh);
        data?.data_primeira_cnh !== null && setPrimeiraEmissaoCnh(data?.data_primeira_cnh);
      } else {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      }
    }
  };


  function handleClose() {
    setShowMessage(false);
    if (errors.length === 0) {
      history.push("/pre-admissao");
    }
  }

  function validateFields() {
    setErrors([]);

    const fields = [
      {
        label: "cpf",
        value: cpf,
      },
      {
        label: "tipoidentidade",
        value: tipoIdentidade,
      },
      {
        label: "identidade",
        value: identidade,
      },
      {
        label: "orgaoidentidade",
        value: orgaoEmissorIdentidade,
      },
      {
        label: "emissaoidentidade",
        value: emissaoIdentidade,
      },
      {
        label: "ufidentidade",
        value: ufIdentidade,
      },
      {
        label: "selectctps",
        value: ctps,
      },
      {
        label: "seriectps",
        value: serieCtps,
      },
      {
        label: "ufctps",
        value: ufCtps,
      },
      {
        label: "emissaoctps",
        value: emissaoCtps,
      },
    ];
    const fieldsErrors = verifyFields(fields);
    if (fieldsErrors.length > 0) {
      setErrors(fieldsErrors);
      setShowMessage(true);
    }
    return fieldsErrors.length === 0;
  }

  async function handleSubmit() {
    if (!validateFields()) return;
    setLoading(true);
    await preAdmissaoService
      .cadastraDocumento(
        idFuncionario,
        cpf,
        tipoIdentidade,
        identidade,
        orgaoEmissorIdentidade,
        emissaoIdentidade,
        ufIdentidade,
        ctps,
        serieCtps,
        ufCtps,
        emissaoCtps,
        nis,
        emissaoNis,
        titulo,
        zona,
        secao,
        cnh,
        emissaoCnh,
        validadeCnh,
        orgaoCnh,
        ufCnh,
        primeiraEmissaoCnh,
        tipoCnh
      )
      .then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }

        if (response.status !== 201 && response.status !== 200) {
          if (response.data.error)
            setErrors(Object.values(response.data.error));
          else setErrors(["Ocorreu um erro"]);
        }
        setLoading(false);
        setShowMessage(true);
      });
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadEstados();
      await loadTipoIdentidade();
      await loadTipoCnh();
      await loadPreAdmissaoDocumentos();
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <Grid
            container
            direction="column"
            className="form-cadastro-endereco"
            spacing={2}
          >
            <Grid item>
              <div className="title-cadastro-endereco">Documentos</div>
            </Grid>
            <Grid item container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="CPF"
                  tipo="cpf"
                  required
                  value={cpf}
                  handleInputChange={(e) => {
                    setCpf(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="NIS/PIS/PASEP ou NIT"
                  tipo="numero"
                  tipo="pis"
                  value={nis}
                  handleInputChange={(e) => {
                    setNis(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DatePicker
                  initDate={emissaoNis !== "" ? emissaoNis : null}
                  handleChange={(value) => setEmissaoNis(value)}
                  label="Emissão do NIS/PIS/PASEP ou NIT"
                  noMask
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={12} md={4}>
                <SelectComponent
                  styleType="form"
                  label="Tipo identidade"
                  title="Selecione uma opção"
                  initialValue={
                    listTipoIdentidade.find((item) => item.id === tipoIdentidade)
                      ? listTipoIdentidade.find((item) => item.id === tipoIdentidade).nome
                      : ""
                  }
                  list={listTipoIdentidade}
                  required
                  callback={(id) => handleChangeTipoIdentidade(id)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Identidade"
                  tipo="numero"
                  required
                  disabled={tipoIdentidade !== null ? false : true}
                  tipo={tipoMaskIdentidade}
                  value={identidade}
                  handleInputChange={(e) => {
                    setIdentidade(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Órgão Emissor da Identidade"
                  required
                  value={orgaoEmissorIdentidade}
                  handleInputChange={(e) => {
                    setOrgaoEmissorIdentidade(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={12} md={4}>
                <DatePicker
                  initDate={emissaoIdentidade !== "" ? emissaoIdentidade : null}
                  noMask
                  label="Emissão da Identidade *"
                  handleChange={(value) => setEmissaoIdentidade(value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <SelectComponent
                  styleType="form"
                  required
                  label="UF da identidade"
                  title="Selecione uma opção"
                  initialValue={
                    listEstados.find((item) => item.id === ufIdentidade)
                      ? listEstados.find((item) => item.id === ufIdentidade).nome
                      : ""
                  }
                  list={listEstados}
                  callback={(id) => setUfIdentidade(id)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="CTPS"
                  tipo="numero"
                  tipo="ctps"
                  required
                  value={ctps}
                  handleInputChange={(e) => {
                    setCtps(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Série da CTPS"
                  tipo="numero"
                  required
                  value={serieCtps}
                  handleInputChange={(e) => {
                    setSerieCtps(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <SelectComponent
                  styleType="form"
                  label="UF da CTPS"
                  title="Selecione uma opção"
                  initialValue={
                    listEstados.find((item) => item.id === ufCtps)
                      ? listEstados.find((item) => item.id === ufCtps).nome
                      : ""
                  }
                  list={listEstados}
                  required
                  callback={(id) => setUfCtps(id)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DatePicker
                  label="Emissão da CTPS *"
                  noMask
                  initDate={emissaoCtps !== "" ? emissaoCtps : null}
                  handleChange={(e) => {
                    setEmissaoCtps(e);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Título de Eleitor"
                  value={titulo}
                  handleInputChange={(e) => {
                    setTitulo(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Zona Eleitoral"
                  tipo="numero"
                  value={zona}
                  handleInputChange={(e) => {
                    setZona(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Seção Eleitoral"
                  tipo="numero"
                  value={secao}
                  handleInputChange={(e) => {
                    setSecao(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Carteira de Habilitação"
                  tipo="numero"
                  value={cnh}
                  handleInputChange={(e) => {
                    setCnh(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DatePicker
                  label="Data de emissão da CNH"
                  noMask
                  initDate={emissaoCnh !== "" ? emissaoCnh : null}
                  handleChange={(e) => {
                    setEmissaoCnh(e);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DatePicker
                  label="Validade CNH"
                  noMask
                  initDate={validadeCnh !== "" ? validadeCnh : null}
                  handleChange={(e) => {
                    setValidadeCnh(e);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={12} md={6}>
                <SelectComponent
                  styleType="form"
                  label="Categoria da CNH"
                  title="Selecione uma opção"
                  initialValue={
                    listTipoCnh.find((item) => item.id === tipoCnh)
                      ? listTipoCnh.find((item) => item.id === tipoCnh).nome
                      : ""
                  }
                  list={listTipoCnh}
                  callback={(id) => setTipoCnh(id)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Input
                  label="Órgão emissor da CNH"
                  value={orgaoCnh}
                  handleInputChange={(e) => {
                    setOrgaoCnh(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={12} md={6}>
                <SelectComponent
                  styleType="form"
                  label="UF da CNH"
                  title="Selecione uma opção"
                  initialValue={
                    listEstados.find((item) => item.id === ufCnh)
                      ? listEstados.find((item) => item.id === ufCnh).nome
                      : ""
                  }
                  list={listEstados}
                  callback={(id) => setUfCnh(id)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <DatePicker
                  label="Data primeira emissão da CNH"
                  noMask
                  initDate={primeiraEmissaoCnh !== "" ? primeiraEmissaoCnh : null}
                  handleChange={(e) => {
                    setPrimeiraEmissaoCnh(e);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <span className="required-text">* Campos obrigatórios</span>
            </Grid>
          </Grid>
          {openLoginExpirado && <ModalLoginExpirado open={openLoginExpirado} />}
        </span>
      </div>
      <Grid item container justify="flex-end">
        <ButtonComponent
          text="Salvar"
          className="btn-success zero-margin"
          onClick={handleSubmit}
        />
      </Grid>
      {openLoginExpirado && <ModalLoginExpirado open={openLoginExpirado} />}
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? "Documentos cadastrados com sucesso!" : "Erro!"
          }`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
    </>
  );
}

export default CadastroDocumentos;
