import React, { useState, useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import OverlayLoading from '../../../components/OverlayLoading';

const RedirectCertificado = () => {
  const history = useHistory();
  const location = useLocation();
  const { state } = location.state;
  
  useEffect(() => {
    console.log("state :: ", state);
  }, [state])

  if (!state) {
    return history.goBack();
  }
  
  return (
    <>
      <OverlayLoading />
      <Redirect to={{
        pathname: `/certificado/${state.id_empresa}`,
        state: { state }
      }} />
    </>
  )
}

export default RedirectCertificado;