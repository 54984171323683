import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/ButtonComponent";
import CallbackMessage from "../../../components/CallbackMessage";
import DatePicker from "../../../components/DatePicker";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import OverlayLoading from "../../../components/OverlayLoading";
import ModalCancelamento from "../../../components/PDVModals/ModalCancelamento";
import ModalDetalhesVenda from "../../../components/PDVModals/ModalDetalhesVenda";
import SelectComponent from "../../../components/SelectComponent";
import TableComponent from "../../../components/TableComponent";
import pdvService from "../../../services/pdvService";
import { numberToCurrency } from "../../../utils/functions";
import "./styles.scss";
import useCompany from "../../../hooks/useCompany";
import { useIsMount } from "../../../hooks/useIsMount";

const FilterComponentCustom = ({
  onApplyFilters = () => {},
  onCleanFilters = () => {},
  initDateFrom = null,
  initDateTo = null,
  initCompany = null,
  listEmpresas,
}) => {
  console.log({ initCompany });
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [company, setCompany] = useState(null);

  const onChangeDateFrom = (value) => {
    setDateFrom(value);
  };

  const onChangeDateTo = (value) => {
    setDateTo(value);
  };

  const handleApplyFilters = () => {
    onApplyFilters({ dateFrom, dateTo, company });
  };

  const handleCleanFilters = () => {
    onCleanFilters();
    setDateFrom("");
    setDateTo("");
    setCompany(null);
  };

  useEffect(() => {
    if (initDateFrom) setDateFrom(initDateFrom);
    if (initDateTo) setDateTo(initDateTo);
    if (initCompany) setCompany(initCompany);
  }, [initDateFrom, initDateTo, initCompany]);

  return (
    <div className="session-container filter-component-container chat">
      <Grid container spacing={2} alignItems="center" className="form-table">
        <Grid item xs={12} sm={12}>
          <SelectComponent
            styleType="form"
            label="Empresa"
            initialValue={
              listEmpresas.find((item) => item.id == company)?.nome || null
            }
            title="Selecione uma empresa"
            list={listEmpresas}
            callback={(id) => setCompany(id)}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="De"
            noMask
            handleChange={onChangeDateFrom}
            initDate={dateFrom}
          />
        </Grid>

        <Grid item xs={6}>
          <DatePicker
            noMask
            label="Até"
            handleChange={onChangeDateTo}
            initDate={dateTo}
          />
        </Grid>

        <div className="filter-button-area">
          <Button
            text="Aplicar"
            onClick={handleApplyFilters}
            className="btn-primary"
          />
          <Button
            text="Limpar"
            onClick={handleCleanFilters}
            className="outline-no-hover"
          />
        </div>
      </Grid>
    </div>
  );
};

export default function ListaPedidosPDV() {
  const history = useHistory();
  const { listaPedidos } = pdvService;

  const {
    companyList: listEmpresas,
    selectedCompany: empresa,
    setSelectedCompany: setEmpresa,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("listagem_vendas");
  const isMount = useIsMount();

  const [loading, setLoading] = useState(false);
  const [idVenda, setIdVenda] = useState();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  const [listRequest, setListRequest] = useState([]);
  const [dateDe, setDateDe] = useState("");
  const [dateAte, setDateAte] = useState("");
  const [paramsURL, setParamsURL] = useState(null);

  const [exportToXLS, setExportToXLS] = useState({
    url: "erp-exporta-pedidos",
    fileName: "vendas-pdv",
    fileType: "xlsx",
    params: paramsURL,
  });
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_pedido" },
      { columnName: "Quantidade de Itens", dataRef: "quantidade_itens" },
      { columnName: "Valor ", dataRef: "valor_total" },
      { columnName: "Número da Nota", dataRef: "numero_nf" },
      { columnName: "Status", dataRef: "status_pedido" },
    ],
    options: {
      delete: true,
      searchFile: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      filter: true,
      export: true,
      isOpenedFilter: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  const callbackShowMessage = useCallback(() => {
    setShowMessage(true);
  }, []);

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);

    if (empresa !== null) {
      const response = await listaPedidos(
        defaultConfigTable.currentPage,
        defaultConfigTable.orderBy,
        defaultConfigTable.orderByType,
        defaultConfigTable.perPage,
        defaultConfigTable.searchTerm,
        dateDe,
        dateAte,
        empresa
      );

      if (response.data) {
        if (response.data.hasOwnProperty("data")) {
          const { data } = response.data;

          const newData = data.map((item) => ({
            ...item,
            id: item.id_pedido,
            valor_total: numberToCurrency(item.valor_total),
          }));

          const newResult = {
            ...response.data,
            data: newData,
          };

          console.log(newResult);

          setListRequest(newResult);
        } else {
          const { data } = response;

          const newData = data.map((item) => ({
            ...item,
            id: item.id_pedido,
            valor_total: numberToCurrency(item.valor_total),
          }));

          const newResult = {
            ...response,
            data: newData,
          };

          console.log(newResult);

          setListRequest(newResult);
        }
      }
    }
    setLoading(false);
  }, [
    dateAte,
    dateDe,
    empresa,
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    listaPedidos,
  ]);

  let onPageChange = useCallback(
    (newPageObj) => {
      let newPage = newPageObj.page;
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.currentPage = newPage;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onOrderBy = useCallback(
    (newOrderBy) => {
      let orderBy = newOrderBy.orderBy;
      let orderByType = newOrderBy.orderByType;
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.orderBy = orderBy;
      newDefaultConfigTable.orderByType = orderByType;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onPerPage = useCallback(
    (newPerPage) => {
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.perPage = newPerPage;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onSearchTerm = useCallback(
    (value) => {
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.searchTerm = value ? value : null;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onClickFile = (id) => {
    setIdVenda(id);
    setOpenModalDetails(true);
  };

  let onClickEdit = (id) => {
    history.push(`/solicitacao-servico/${id}`);
  };

  let onClickDelete = (id) => {
    setIdVenda(id);
    setOpenConfirmDelete(true);
  };

  let onCancelDelete = () => {
    setOpenConfirmDelete(false);
  };

  let onCancelDetails = () => {
    setOpenModalDetails(false);
  };

  function handleClose() {
    setShowMessage(false);
  }

  const handleApplyFilters = useCallback(
    ({ dateFrom, dateTo, company, empresaStorage = null }) => {
      if (empresaStorage) {
        setEmpresa(empresaStorage);
        setParamsURL((prev) => ({
          ...prev,
          id_empresa: empresaStorage,
        }));
        return onPageChange({ page: 1 });
      }
      setDateDe(dateFrom);
      setDateAte(dateTo);
      setEmpresa(company);
      setParamsURL({
        id_empresa: company,
        data_de: dateFrom,
        data_ate: dateTo,
      });
      onPageChange({ page: 1 });
      setEmpresaPagina("listagem_vendas", company);
    },
    []
  );

  const handleCleanFilters = useCallback(() => {
    setDateDe(null);
    setDateAte(null);
    setEmpresa(null);
    setListRequest({ data: [] });
    setEmpresaPagina("listagem_vendas", null);
  }, []);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable, dateAte, dateDe]);

  useEffect(() => {
    setExportToXLS({
      ...exportToXLS,
      params: {
        ...exportToXLS.params,
        data_de: dateDe,
        data_ate: dateAte,
        id_empresa: empresa,
      },
    });
  }, [dateDe, dateAte, empresa]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("listagem_vendas");
    if (empresaStorage) {
      handleApplyFilters({ empresaStorage });
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onClickEdit}
        cbDelete={onClickDelete}
        cbLookFile={onClickFile}
        exportXLS={exportToXLS}
        filterOnly
        filterComponentCustom={
          <FilterComponentCustom
            onApplyFilters={handleApplyFilters}
            onCleanFilters={handleCleanFilters}
            initDateFrom={dateDe}
            initDateTo={dateAte}
            initCompany={empresa}
            listEmpresas={listEmpresas}
          />
        }
      />
      <ModalDetalhesVenda
        open={openModalDetails}
        onClose={onCancelDetails}
        idVenda={idVenda}
        onSave={() => {}}
      />
      <ModalCancelamento
        open={openConfirmDelete}
        onClose={onCancelDelete}
        idVenda={idVenda}
        onSave={callbackShowMessage}
      />
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${
          errors.length === 0 ? "Pedido cancelado com sucesso!" : "Erro!"
        }`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 3000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
