import { Grid } from "@material-ui/core";
import React from "react";

export function EmptyDre({ filtered = false }) {
  return (
    <div className="empty-dre">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item className="font-empty-title">
          Não há dados para carregar
        </Grid>
        {!filtered ? (
          <Grid item className="font-empty-subtitle">
            Selecione uma empresa e o período
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}
