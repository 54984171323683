import Axios from "./Axios";

const agendamentoFeriasService = {
  getAgendamentos: async ({
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null,
    status = null,
  }) => {
    const result = await Axios.get("/lista-dp-agendamento-ferias", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
        status,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  cadastrarAgendamentoFerias: async ({
    empresaDp,
    funcionario,
    periodoDe,
    periodoAte,
    pagarAdiantamento,
    anotacoes = null,
    pagarAbono = null,
    diasAbono = null,
    diasFerias = null,
  }) => {
    const response = await Axios.post("/cadastra-dp-agendamento-ferias", {
      id_empresa: empresaDp,
      id_funcionario: funcionario,
      data_inicio: periodoDe,
      data_fim: periodoAte,
      adiantar_decimo_terceiro: pagarAdiantamento,
      anotacoes,
      pagar_abono: pagarAbono,
      dias_abono: diasAbono,
      dias_ferias: diasFerias,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  listaFeriados: async (ano) => {
    const result = await Axios.get(`/lista-feriados-nacionais/${ano}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  consultaAgendamentoFerias: async (id) => {
    const response = await Axios.get(`/consulta-agendamento-ferias/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  confirmarAgendamento: async (id) => {
    const response = await Axios.post(`/confirma-agendamento-ferias/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  excluirAgendamento: async (id) => {
    const response = await Axios.delete(`/remove-agendamento-ferias/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);
    return response;
  },
};
export default agendamentoFeriasService;
