import React, { useEffect, useState, useCallback } from 'react'
import { Grid, Dialog, DialogTitle, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import InputComponent from '../Input'
import DatePicker from '../DatePicker'
import ButtonComponent from '../ButtonComponent'
import SelectComponent from '../SelectComponent'
import Switch from '../Switch'
import OverlayLoading from '../OverlayLoading'
import ModalLoginExpirado from '../ModalLoginExpirado'
import CallbackMessage from '../CallbackMessage'
import validateFields from '../../utils/validateFields'
import selectServices from '../../services/selectServices'
import faturasService from '../../services/faturasService'
import {
  formatDate,
  toDateDD_MM_YYYY,
  isValidDateMomentDD_MM_YYYY,
  fromStringDD_MM_YYYYtoDate
} from '../../utils/dates'
import { numberToCurrency } from '../../utils/functions'

import './styles.scss'

function ModalAlterarStatus({ open, onClose, idFatura, cbTable, dataModal }) {
  const [idEmpresa, setIdEmpresa] = useState(null)
  const [idCliente, setIdCliente] = useState(null)

  const [plano, setPlano] = useState(null)
  const [status, setStatus] = useState(null)
  const [dataVencimento, setDataVencimento] = useState(null)
  const [dataPagamento, setDataPagamento] = useState('')
  const [competencia, setCompetencia] = useState('')
  const [nsu, setNsu] = useState('')
  const [valor, setValor] = useState(null)
  const [formaPagamento, setFormaPagamento] = useState(null)
  const [emiteNota, setEmiteNota] = useState(false)

  const [listStatus, setListStatus] = useState([])
  const [listFormaPagamento, setListFormaPagamento] = useState([])

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false)

  useEffect(() => {
    setLoading(true)
    loadStatus()
    loadFormaPagamento()
    setLoading(false)
  }, [])

  async function handleSubmit() {
    setErrors([])

    let findStatus = listStatus.find((item) => item.id === status)

    let fields = []

    if (findStatus?.nome?.toUpperCase() === 'PAGO') {
      fields.push(
        {
          label: 'status',
          value: status
        },
        {
          label: 'datadepagamento',
          value: dataPagamento
        },
        {
          label: 'formadepagamento',
          value: formaPagamento
        }
      )
    } else {
      fields.push({
        label: 'status',
        value: status
      })
    }

    const fieldsValidate = validateFields(fields)

    if (fieldsValidate.length !== 0) {
      setShowMessage(true)
      setErrors(fieldsValidate)
      return
    }

    setLoading(true)

    const response = await faturasService.alterarFatura(
      idFatura,
      plano,
      idEmpresa,
      idCliente,
      dataPagamento,
      nsu,
      formaPagamento,
      status,
      competencia !== '' ? competencia : null,
      emiteNota ? 1 : 0
    )

    if (response.status === 401) {
      setOpenLoginExpirado(true)
      setLoading(false)
      return
    }
    if (response.status !== 201 && response.status !== 200) {
      if (response.data.error) {
        setLoading(false)
        setShowMessage(true)
        if (typeof response?.data?.error === 'string') {
          setErrors([response.data.error])
        } else {
          setErrors(Object.values(response.data.error))
        }
      } else setErrors(['Ocorreu um erro'])
    } else if (response.status === 200 || response.status === 201) {
      setErrors([])
      setSuccessMessage('Fatura atualizada com sucesso!')
    }

    setShowMessage(true)
    setLoading(false)
    cbTable()
  }

  const loadStatus = async () => {
    const response = await selectServices.selectStatusFatura()
    if (response.status === 401) {
      setOpenLoginExpirado(true)
      setLoading(false)
      return
    }
    const data = response.data

    setListStatus(data)
  }

  const loadFormaPagamento = async () => {
    const origemFatura = 3;
    const response = await selectServices.selecionaFormaPagamentoPorOrigem(origemFatura)
    if (response.status === 401) {
      setOpenLoginExpirado(true)
      setLoading(false)
      return
    }
    const data = response.data

    setListFormaPagamento(data)
  }

  const loadFields = useCallback(async () => {
    if (idFatura !== null) {
      const response = await faturasService.consultaFatura(idFatura)

      if (response.status === 401) {
        setOpenLoginExpirado(true)
        setLoading(false)
        return
      }

      if (response.status !== 201 && response.status !== 200) {
        if (response.data.error) {
          setLoading(false)
          setShowMessage(true)
          setErrors(Object.values(response.data.error))
        } else setErrors(['Ocorreu um erro'])
        setShowMessage(true)
      } else {
        const { data } = response

        setEmiteNota(data.emite_nota === 1)
        let findFormaPagamentoFatura = listFormaPagamento.find(
          (item) =>
            item.nome?.toUpperCase() === data.forma_pagamento?.toUpperCase()
        )
        let findStatusFatura = listStatus.find(
          (item) =>
            item.nome?.toUpperCase() === data.status_pagamento?.toUpperCase()
        )

        if (findStatusFatura) {
          setStatus(findStatusFatura.id)
        }

        if (findFormaPagamentoFatura) {
          setFormaPagamento(findFormaPagamentoFatura.id)
        }

        setIdEmpresa(data.id_empresa)
        setIdCliente(data.id_cliente)
        data?.data_vencimento
          ? setDataVencimento(formatDate(data.data_vencimento))
          : setDataVencimento('')
        data?.valor_total
          ? setValor(numberToCurrency(data.valor_total))
          : setValor(null)
        data?.competencia !== null
          ? setCompetencia(data?.competencia)
          : setCompetencia('')
        data?.nsu !== null ? setNsu(data?.nsu) : setNsu('')

        if (data.data_pagamento) {
          let formattedDateMoment = toDateDD_MM_YYYY(data.data_pagamento)

          let isValidDate = isValidDateMomentDD_MM_YYYY(formattedDateMoment)

          if (isValidDate) {
            let formatted = data.data_pagamento.substring(0, 10)
            setDataPagamento(formatted)
          }
        } else if (data.data_pagamento === null) {
          setDataPagamento('')
        }
      }
      setLoading(false)
    }
  }, [idFatura])

  const handleClose = () => {
    setShowMessage(false)
    if (errors.length === 0) {
      onClose()
      cbTable()
    }
  }

  const handleCloseModal = async () => {
    setLoading(false)
    onClose()
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await loadFields()
      setLoading(false)
    })()
  }, [loadFields])

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='md'
      onClose={handleCloseModal}
      dialog
    >
      {loading && <OverlayLoading />}
      <DialogTitle className='modal-container'>
        <Grid
          item
          container
          justify='space-between'
          className='modal-title-container modal-title'
        >
          <Grid item>
            <span>ALTERAR STATUS</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size='medium'
              style={{ marginRight: 16, color: '#053b59', padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className='content'>
        <form className='session-container-form'>
          <Grid container direction='column' spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4} className='modal-items'>
                <label>ID</label>
                <span>{idFatura}</span>
              </Grid>
              <Grid item xs={12} sm={12} md={4} className='modal-items'>
                <label>Data de Vencimento</label>
                <span>{dataVencimento}</span>
              </Grid>
              <Grid item xs={12} sm={12} md={4} className='modal-items'>
                <label>Valor</label>
                <span>{valor}</span>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div className='select-container'>
                  <SelectComponent
                    list={listStatus}
                    label='Status'
                    title='Selecione um status'
                    initialValue={
                      listStatus.find((item) => item.id === status)
                        ? listStatus.find((item) => item.id === status).nome
                        : ''
                    }
                    callback={(id) => setStatus(id)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DatePicker
                  label='Data de Pagamento'
                  initDate={dataPagamento}
                  handleChange={(date) => {
                    setDataPagamento(date)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div className='select-container'>
                  <SelectComponent
                    list={listFormaPagamento}
                    label='Forma de Pagamento'
                    title='Selecione uma forma de pagamento'
                    initialValue={
                      listFormaPagamento.find(
                        (item) => item.id === formaPagamento
                      )
                        ? listFormaPagamento.find(
                            (item) => item.id === formaPagamento
                          ).nome
                        : ''
                    }
                    callback={(id) => setFormaPagamento(id)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  nome='nsu'
                  label='NSU'
                  tipo='text'
                  value={nsu}
                  handleInputChange={(event) => {
                    setNsu(event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  nome='competencia'
                  label='Competência (mês/ano)'
                  tipo='mes-ano'
                  value={competencia}
                  title='__/____'
                  handleInputChange={(event) => {
                    setCompetencia(event.target.value)
                  }}
                />
              </Grid>
              <Grid
                container
                spacing={1}
                item
                xs={12}
                md={6}
                className='empresa-nfe'
              >
                <Grid item className='container-input-switch'>
                  Emite nota?
                  <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                    Não
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={emiteNota}
                      onClick={() => setEmiteNota(!emiteNota)}
                    />
                  </Grid>
                  <Grid item>Sim</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <span className='required-text'>* Campos obrigatórios</span>
              </Grid>
              <Grid container justify='flex-end' item>
                <ButtonComponent
                  text='Salvar'
                  onClick={handleSubmit}
                  className='btn-success zero-margin'
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? 'success' : 'error'}`}
        message={`${errors.length === 0 ? successMessage : 'Erro!'}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  )
}
export default ModalAlterarStatus
