import React, { useState, useEffect } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import InputComponent from "../../Input";
import ButtonComponent from "../../ButtonComponent";
import SelectComponent from "../../SelectComponent";
import CallbackMessage from "../../CallbackMessage";
import validateFields from "../../../utils/validateFields";

function ModalIcms51({ open, onClose, data = null, callback = () => {}, listBci = [],listCstCsosn = [] }) {

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackErrorList, setCallbackErrorList] = useState([]);

  const [field, setField] = useState({
    origem: null,
    cst: null,
    modalidade_determinacao_bcicms: null,
    percentual_reducao_bc: null,
    valor_base_calculo: null,
    aliquota_imposto: null,
    valor_icms_operacao: null,
    percentual_diferimento: null,
    valor_icms_diferido: null,
    valor_icms: null,
    valor_base_calculo_fundo_combate_pobreza: null,
    percentual_fundo_combate_pobreza: null,
    valor_fundo_combate_pobreza: null,
  });

  const handleClose = (event, reason) => {
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
  };

  const handleSubmit = () => {
    console.log(field);

    const fieldsToValidate = [
      {
        label: "origem",
        value: field.origem,
      },
      {
        label: "cst",
        value: field.cst,
      }
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      setCallbackErrorList(fieldsErrors);
      setCallbackShown(true);
      return;
    }
    callback(field)
  }

  useEffect(() => {
    let mounted = true;

    if (data) {
      if (mounted) {
        setField({
          origem: data.origem,
          cst: data.cst,
          modalidade_determinacao_bcicms: data.modalidade_determinacao_bcicms,
          percentual_reducao_bc: data.percentual_reducao_bc,
          valor_base_calculo: data.valor_base_calculo,
          aliquota_imposto: data.aliquota_imposto,
          valor_icms_operacao: data.valor_icms_operacao,
          percentual_diferimento: data.percentual_diferimento,
          valor_icms_diferido: data.valor_icms_diferido,
          valor_icms: data.valor_icms,
          valor_base_calculo_fundo_combate_pobreza: data.valor_base_calculo_fundo_combate_pobreza,
          percentual_fundo_combate_pobreza: data.percentual_fundo_combate_pobreza,
          valor_fundo_combate_pobreza: data.valor_fundo_combate_pobreza,
        })
      }
    }
    
    return () => mounted = false;
  }, [data])
  
  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose} >
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>ICMS - ICMS51</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={1}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Origem"
                  tipo="numero"
                  value={field.origem}
                  handleInputChange={event => setField({ ...field, origem: event.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <SelectComponent
                  required
                  styleType="form"
                  label="CST"
                  initialValue={listCstCsosn.find((item) => item.id === Number(field.cst) ? listCstCsosn.find((item) => item.id === Number(field.cst)) : null)}
                  callback={event => setField({ ...field, cst: event })}
                  list={listCstCsosn}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
               <SelectComponent
                  label="Modalidade determinação BC ICMS"
                  initialValue={
                    listBci.find((item) => item.id === Number(field.modalidade_determinacao_bcicms))?.nome
                  }
                  callback={id => {
                    setField({...field, modalidade_determinacao_bcicms: id});
                  }}
                  styleType="form"
                  list={listBci}                  
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  tipo="percentage"
                  label="Redução BC (%)"
                  value={field.percentual_reducao_bc && field.percentual_reducao_bc+"%"}
                  handleInputChange={event => {
                    setField({... field, percentual_reducao_bc: event.value});
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_base_calculo}
                  label="Valor da base de cálculo"
                  handleInputChange={event => {
                    setField({ ...field, valor_base_calculo: event.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Alíquota Imposto (%)"
                  tipo="percentage"
                  value={field.aliquota_imposto && field.aliquota_imposto+"%"}
                  handleInputChange={event => {
                    setField({ ...field, aliquota_imposto: event.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="ICMS Operação"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_icms_operacao}
                  handleInputChange={event => {
                    setField({ ...field, valor_icms_operacao: event.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Deferimento (%)"
                  tipo="percentage"
                  value={field.percentual_diferimento && field.percentual_diferimento + "%"}
                  handleInputChange={event => {
                    setField({ ...field, percentual_diferimento: event.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Valor do ICMS Deferido"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_icms_diferido}
                  handleInputChange={event => {
                    setField({ ...field, valor_icms_diferido: event.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Valor ICMS"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_icms}
                  handleInputChange={event => {
                    setField({ ...field, valor_icms: event.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Base cálculo fundo de combate à pobreza"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_base_calculo_fundo_combate_pobreza}
                  handleInputChange={event => {
                    setField({ ...field, valor_base_calculo_fundo_combate_pobreza: event.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Percentual fundo de combate à pobreza (%)"
                  tipo="percentage"
                  value={field.percentual_fundo_combate_pobreza && field.percentual_fundo_combate_pobreza +"%"}
                  handleInputChange={event => {
                    setField({ ...field, percentual_fundo_combate_pobreza: event.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Valor fundo de combate à pobreza"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_fundo_combate_pobreza}
                  handleInputChange={event => {
                    setField({ ...field, valor_fundo_combate_pobreza: event.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justify="flex-end" item>
              <ButtonComponent
                text="Salvar"
                onClick={() => handleSubmit()}
                className="btn-success zero-margin"
              />
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={callbackShown}
        errorList={callbackErrorList}
        duration={4000}
        message={'Erro!'}
        type={'error'}
        handleClose={handleClose}
      />
    </Dialog>
  );
}

export default ModalIcms51;
