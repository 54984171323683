import React, { useState, useEffect, useContext } from "react";

import Input from "../Input";
import InputCheck from "../InputCheck";
import Select from "../SelectComponent";
import DatePicker from "../DatePicker";
import moment from "moment";
import {
  DD_MM_YYYY,
  addMonthsMoment,
  isValidDateMomentYYYY_MM_DD,
  toDateDD_MM_YYYY,
  fromStringYYYY_MM_DDtoDate,
} from "../../utils/dates";

import classes from "./styles.module.scss";
import { Grid } from "@material-ui/core";
// exemplo de uso  :
// <Parcelamentos
// dataInicial={"2019-12-31"}
// valorTotal={123.12}
// qtdTotalParcelas={12}
// />

const Parcelamentos = (props) => {
  const {
    qtdTotalParcelas = 2,
    valorTotal = 0,
    dividirEntreParcelas = false,
    handleChange = () => {},
    showOptions = true,
  } = props;

  const [dateBase, setDateBase] = useState();
  const [qtdParcelas, setQtdParcelas] = useState(qtdTotalParcelas);
  const [divideValorTotal, setDivideValorTotal] =
    useState(dividirEntreParcelas);
  const listQtdParcelas = [];
  const dataInicial = moment().format("yyyy-MM-DD");

  for (let i = 2; i <= 60; i++) {
    listQtdParcelas.push({
      id: i,
      nome: i,
    });
  }
  const objDuplicata = {
    numero_parcela: 1,
    data_vencimento: dataInicial,
    valor: 0,
    descricao: "",
  };
  const [formDuplicatas, setFormDuplicatas] = useState([objDuplicata]);
  const handleQtdParcelasChange = (qtd) => {
    if (qtd) {
      setQtdParcelas(qtd);
    }
  };

  useEffect(() => {
    const dateValid = isValidDateMomentYYYY_MM_DD(dataInicial)
      ? fromStringYYYY_MM_DDtoDate(dataInicial)
      : null;
    setDateBase(dataInicial);
  }, [dataInicial, dateBase]);

  useEffect(() => {
    console.log(qtdParcelas, divideValorTotal, formDuplicatas);

    if (qtdParcelas !== 1) {
      let temp = [];

      let valorParcela = divideValorTotal
        ? Math.trunc((valorTotal / qtdParcelas) * 100) / 100
        : valorTotal;

      let valorResto = divideValorTotal
        ? +(valorTotal - valorParcela * qtdParcelas).toFixed(2)
        : 0;

      // console.log(valorParcela, valorResto, valorParcela * qtdParcelas);

      for (let i = 2; i <= qtdParcelas + 1; i++) {
        const nextDate = addMonthsMoment(dataInicial, i - 1);
        temp.push({
          numero_parcela: i - 1,
          data_vencimento: nextDate.format("yyyy-MM-DD"),
          valor:
            i === qtdParcelas + 1 ? valorParcela + valorResto : valorParcela,
          descricao: "",
        });
      }
      setFormDuplicatas(temp);
      handleChange(temp);
    }
  }, [divideValorTotal, qtdParcelas, valorTotal]);

  if (!dataInicial || dataInicial === "") return <>Data inicial inválida</>;

  return (
    <div
      className={
        classes.container +
        ` ${classes.chat}` +
        " aux-modal-cadastra-recebimento"
      }
    >
      <div>
        <h2 className={classes.fieldTitle}>Parcelas</h2>
        {showOptions ? (
          <>
            <div className={classes.inputCheckContainer}>
              <div className={classes.checkBoxContainer}>
                <InputCheck
                  checked={!divideValorTotal}
                  nome="nao_divide_valor_total"
                  id="nao_divide_valor_total"
                  tipo="radio"
                  onInputChange={() => setDivideValorTotal(!divideValorTotal)}
                />
              </div>
              <div className={classes.checkBoxTextContainer}>
                <span>As parcelas terão o mesmo valor do agendamento</span>
              </div>
            </div>
            <div className={classes.inputCheckContainer}>
              <div className={classes.checkBoxContainer}>
                <InputCheck
                  checked={divideValorTotal}
                  nome="divide_valor_total"
                  id="divide_valor_total"
                  tipo="radio"
                  onInputChange={() => setDivideValorTotal(!divideValorTotal)}
                />
              </div>
              <div className={classes.checkBoxTextContainer}>
                <span>
                  O valor do agendamento será dividido entre as parcelas
                </span>
              </div>
            </div>
          </>
        ) : null}
        <div className={classes.selectContainer}>
          <Select
            styleType="form"
            label="Número total de parcelas"
            title="Selecione a quantidade de parcelas"
            list={listQtdParcelas}
            initialValue={listQtdParcelas?.find(
              (item) => item.id === qtdParcelas
            )}
            callback={(e) => {
              handleQtdParcelasChange(e);
            }}
          />
        </div>

        {formDuplicatas.map((c, index) => (
          <div className={classes.parcelContainer} key={index}>
            <Grid item container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                className={classes.containerTituloParcela}
              >
                <h1>{c.numero_parcela}</h1>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DatePicker
                  label="Data de Vencimento *"
                  required
                  initDate={c.data_vencimento}
                  handleChange={(date) => {
                    let list = [...formDuplicatas];
                    list[index].data_vencimento = date;
                    handleChange(list);
                  }}
                ></DatePicker>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Input
                  label="Valor"
                  required
                  tipo="dinheiro"
                  value={c.valor ? c.valor : 0}
                  handleInputChange={(e) => {
                    let list = [...formDuplicatas];
                    list[index].valor = e.value;
                    handleChange(list);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Descrição"
                  type="text"
                  value={c.descricao}
                  handleInputChange={(e) => {
                    let list = [...formDuplicatas];
                    list[index].descricao = e.target.value;
                    handleChange(list);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Parcelamentos;
