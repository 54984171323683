import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useCallback,
} from "react";
import { FaArrowDown } from "react-icons/fa";

import TableComponent from "../../components/TableComponent";
import OverlayLoading from "../../components/OverlayLoading";
import TabsComponent from "../../components/TabsComponent";
import SelectComponent from "../../components/SelectComponent";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import GraficoBar from "../../components/GraficoBar";
import EmptyImg from "../../assets/img/empty-illustration.svg";
import DataImg from "../../assets/img/sem-dados-dashboard.svg";
import DetailImg from "../../assets/img/detail.svg";
import TrofeuImg from "../../assets/img/trofeu.svg";
import OvalImg from "../../assets/img/Oval.svg";

import Grafico from "../../components/Grafico";

import ObrigacoesService from "../../services/obrigacoesService";
import empresasService from "../../services/empresasService";
import graficoService from "../../services/graficoServices";

import cliente from "../../config/cliente";

import { formatDate } from "../../utils/dates";
import { numberToCurrency } from "../../utils/functions";

import trendingUp from "../../assets/img/trending-up.svg";
import eye from "../../assets/img/eye.svg";
import eyeBlind from "../../assets/img/eye-blind.svg";
import "./styles.scss";
import { CardNovidadesHome } from "../../components/CardNovidadesHome";
import { Grid } from "@material-ui/core";
import { dreOrcadoRealizadoService } from "../../services/dreOrcadoRealizadoService";

function Home() {
  const [client, setClient] = useState("");
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [loadingGrafico, setLoadingGrafico] = useState(false);
  const [tableProximosPagar, setTableProximosPagar] = useState([]);
  const [tableProximosPagarConfig, setTableProximosPagarConfig] = useState({
    columnArray: [
      { columnName: "Data de vencimento", dataRef: "data_vencimento" },
      { columnName: "Referência", dataRef: "titulo" },
      { columnName: "Valor", dataRef: "valor" },
    ],
    display: {
      search: false,
      itemsPerPage: false,
      totalResults: false,
      pagination: false,
    },
    pagination: false,
    totalPages: 1,
    dataListTotal: "0",
  });

  const [tableEmAberto, setTableEmAberto] = useState([]);
  const [tableEmAbertoConfig, setTableEmAbertoConfig] = useState({
    columnArray: [
      { columnName: "Data de vencimento", dataRef: "data_vencimento" },
      { columnName: "Referência", dataRef: "titulo" },
      { columnName: "Valor", dataRef: "valor" },
    ],
    display: {
      search: false,
      itemsPerPage: false,
      totalResults: false,
      pagination: false,
    },
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    enableOrder: false,
  });

  const [tableVencido, setTableVencido] = useState([]);
  const [tableVencidoConfig, setTableVencidoConfig] = useState({
    columnArray: [
      { columnName: "Data de vencimento", dataRef: "data_vencimento" },
      { columnName: "Referência", dataRef: "titulo" },
      { columnName: "Valor", dataRef: "valor" },
    ],
    display: {
      search: false,
      itemsPerPage: false,
      totalResults: false,
      pagination: false,
    },
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
  });

  const [selectEmpresa, setSelectEmpresa] = useState([]);
  const [periodo, setPeriodo] = useState(null);
  const [listPeriodo, setListPeriodo] = useState([]);
  const [selectConta, setSelectConta] = useState([]);
  const [saldoConta, setSaldoConta] = useState(0);
  const [receitasTotais, setReceitasTotais] = useState(0);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [checkObrigacoes, setCheckObrigacoes] = useState(false);

  const [listObrigacoes, setListObrigacoes] = useState([]);

  const [idEmpresa, setIdEmpresa] = useState("");

  const [showSaldo, setShowSaldo] = useState(false);

  const [requestParams, setRequestParams] = useState({
    columnName: "",
    order: 0,
    page: 1,
    seach: "",
    id_empresa: null,
  });

  const [dadosGraficos, setDadosGrafico] = useState({
    recebimento: { orcado: 0, realizado: 0 },
    pagamento: { orcado: 0, realizado: 0 },
  });
  const titleGrafico = ["Meses", "Receita", "Custo e Despesas"];
  const corGrafico = ["#92EBBC", "#CD3C3E"];

  const updateListObrigacoes = useCallback(async () => {
    if (!idEmpresa) {
      return;
    }
    if (client === "contabilhub") {
      await graficoService.listaObrigacoes(idEmpresa).then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          setCheckObrigacoes(true);
          return;
        }

        if (response.status === 200) {
          let list = response.data.data.map((item) => ({
            id: item.id_obrigacao,
            titulo: item.obrigacao,
            data_vencimento: formatDate(item.data_vencimento),
            valor: numberToCurrency(item.valor),
          }));
          setListObrigacoes(list);
          setCheckObrigacoes(false);
        }

        if (response?.data?.data?.length === 0) {
          setCheckObrigacoes(true);
        }
      });
    }
  }, [client, idEmpresa]);

  /* SELECT EMPRESA */
  const getSelectOption = (id) => {
    setIdEmpresa(id);
  };

  const getSelectConta = useCallback(
    (id) => {
      let contaSelecionada = selectConta.find((conta) => conta.id === id);

      if (contaSelecionada) {
        setSaldoConta(contaSelecionada.saldo);
      }
    },
    [selectConta]
  );

  let updateListEmpresas = async () => {
    await empresasService.selectEmpresa().then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        setSelectEmpresa(response.data);
        setIdEmpresa(response.data[0]?.id);
      }
    });
  };

  let updateListContas = async () => {
    await graficoService.selectSaldoConta(idEmpresa).then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        setSelectConta(response.data);
        setSaldoConta(response.data[0]?.saldo);
      }
    });
  };

  const loadPeriodo = useCallback(async () => {
    try {
      const response = await dreOrcadoRealizadoService.selecionaPeriodo();
      if (response?.status === 200) {
        setListPeriodo(response.data);
        setPeriodo(
          response.data?.find((item) => item.id === "30_dias")?.id || null
        );
      }
    } catch (error) {
      console.log({ error });
    }
  }, []);

  const atualizaGraficoDre = useCallback(async () => {
    try {
      setLoadingGrafico(true);
      const result = await dreOrcadoRealizadoService.getDreGraficoHome({
        id_empresa: idEmpresa,
        periodo,
      });
      if (result.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      const resultTemp = result.data;
      const dataGraficoTemp = {
        recebimento: {
          orcado: resultTemp?.recebimento?.orcado || 0,
          realizado: resultTemp?.recebimento?.realizado || 0,
        },
        pagamento: {
          orcado: resultTemp?.pagamento?.orcado || 0,
          realizado: resultTemp?.pagamento?.realizado || 0,
        },
      };
      if (!dataGraficoTemp.pagamento.orcado &&
        !dataGraficoTemp.pagamento.realizado &&
        !dataGraficoTemp.recebimento.orcado &&
        !dataGraficoTemp.recebimento.realizado) {
        setDataAvailable(false)
      }else{
        setDataAvailable(true)
      }
      setDadosGrafico(dataGraficoTemp);S

      setLoadingGrafico(false);
    } catch (error) {
      setLoadingGrafico(false);
    }
  }, [idEmpresa, periodo]);

  /* GRAFICO */
  useEffect(() => {
    if (idEmpresa && periodo) {
      atualizaGraficoDre();
    }
  }, [idEmpresa, periodo]);

  useEffect(() => {
    loadPeriodo();
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setClient(cliente.cliente);
    }

    return () => (mounted = false);
  }, []);

  useEffect(() => {
    let newDataListTotal = tableProximosPagarConfig;
    newDataListTotal.dataListTotal = tableProximosPagar?.total;
    newDataListTotal.totalPages = tableProximosPagar?.last_page;
    setTableProximosPagarConfig(newDataListTotal);
  }, [tableProximosPagar, tableProximosPagarConfig]);

  useEffect(() => {
    let newDataListTotal = tableVencidoConfig;
    newDataListTotal.dataListTotal = tableVencido?.total;
    newDataListTotal.totalPages = tableVencido?.last_page;
    setTableVencidoConfig(newDataListTotal);
  }, [tableVencido, tableVencidoConfig]);

  useEffect(() => {
    let newDataListTotal = tableEmAbertoConfig;
    newDataListTotal.dataListTotal = tableEmAberto?.total;
    newDataListTotal.totalPages = tableEmAberto?.last_page;
    setTableEmAbertoConfig(newDataListTotal);
  }, [tableEmAberto, tableEmAbertoConfig]);

  useEffect(() => {
    setLoadingGrafico(true);
    if (!idEmpresa) {
      updateListEmpresas().then((_) => setLoadingGrafico(false));
    }
    updateListContas();
    updateListObrigacoes();
  }, [idEmpresa]);

  useLayoutEffect(() => {
    const home = document.querySelector(".container-default-component");

    if (client === "36z") {
      home?.classList?.add("home-column-reverse");
    }

    return () => home?.classList?.remove("home-column-reverse");
  }, [client]);

  return (
    <>
      {client === "contabilhub" && (
        <>
          <div className="session-container">
            <div className="session-wrapper">
              <div>
                <div className="graphic-container">
                  <Grid container spacing={2} className="graphic-header">
                    <Grid item xs={12} md={6}>
                      <p style={{ lineHeight: "38px" }}>
                        DRE Orçado x Realizado
                      </p>
                    </Grid>
                    <Grid container item spacing={2} xs={12} md={6}>
                      <Grid item xs={12} md={6}>
                        <SelectComponent
                          styleType="form"
                          title="Selecione uma empresa"
                          list={selectEmpresa}
                          callback={(id) => getSelectOption(id)}
                          initialValue={selectEmpresa[0] || null}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SelectComponent
                          styleType="form"
                          title="Selecione um período"
                          list={listPeriodo}
                          callback={(id) => setPeriodo(id)}
                          initialValue={
                            listPeriodo?.find((item) => item.id === periodo)
                              ?.nome || null
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className="session-graphic">
                    {loading && <OverlayLoading />}
                    {dataAvailable ? (
                      <div className="graphic">
                        <GraficoBar
                          loading={loadingGrafico}
                          dados={dadosGraficos}
                        />
                      </div>
                    ) : (
                      <div className="empty-data">
                        <img src={DataImg} alt="Imagem" />
                        <span>Você ainda não possui dados cadastrados.</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="bottom-session">
                  <img src={TrofeuImg} alt="Trofeu" className="tropheu" />

                  <a
                    href="https://www.contabilhub.com.br/blog/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Saiba Mais
                  </a>

                  <div
                    className="info"
                    style={{ borderBottom: "none !important" }}
                  >
                    <h3>Tire o melhor proveito do seu Dash Board</h3>
                    <span>
                      No nosso blog você encontra conteúdos exclusivos que vão
                      fazer de você um usuário campeão. Conheça todas funções do
                      seu DashBoard.
                    </span>
                  </div>

                  <img
                    src={DetailImg}
                    alt="Imagem Detalhe"
                    className="image-detail"
                  />
                </div>
              </div>
              <div className="session-right-container">
                <CardNovidadesHome />
                <div className="account">
                  <div className="home__account-box-header">
                    <p>Saldo da Conta</p>
                    <SelectComponent
                      styleType="form"
                      title="Selecione uma conta"
                      list={selectConta}
                      callback={(id) => getSelectConta(id)}
                      initialValue={selectConta[0] || null}
                    />
                  </div>
                  <span>
                    <h4>Saldo</h4>
                    <span>
                      {showSaldo ? numberToCurrency(saldoConta) : "R$"}
                    </span>
                    <img
                      width="24px"
                      src={showSaldo ? eye : eyeBlind}
                      alt=""
                      onClick={() => setShowSaldo((prev) => !prev)}
                    />
                  </span>
                </div>
                <div className="list">
                  <p>Lista de Obrigações</p>
                  <div className="list-container">
                    {checkObrigacoes ? (
                      <div className="list-empty">
                        <img src={EmptyImg} alt="Imagem" />
                        <h3>Você ainda não possui obrigações cadastradas.</h3>
                      </div>
                    ) : (
                      listObrigacoes?.map((obrigacao, i) => (
                        <div key={i} className="list-item">
                          <img src={OvalImg} alt="Icone" />
                          <div>
                            <p>{obrigacao.titulo}</p>
                            <span>
                              Pagamento no valor de {obrigacao.valor} com
                              vencimento em {obrigacao.data_vencimento}
                            </span>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default Home;
