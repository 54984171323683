const opcoesDiasVencimentoBoleto = [
  {"id": 1,"nome": "1"},
  {"id": 5,"nome": "5"},
  {"id": 10,"nome": "10"},
  {"id": 12,"nome": "12"},
  {"id": 15,"nome": "15"},
  {"id": 20,"nome": "20"},
  {"id": 25,"nome": "25"},
]

export { opcoesDiasVencimentoBoleto }