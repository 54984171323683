import React, { useState, useEffect, useCallback } from "react";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Grid
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import ButtonComponent from "../../../../../components/ButtonComponent";
import OverlayLoading from "../../../../../components/OverlayLoading";
import ModalLoginExpirado from "../../../../../components/ModalLoginExpirado";
import CallbackMessage from "../../../../../components/CallbackMessage";
import Calendar from "../../../../../components/Calendar";

import corretoresService from "../../../../../services/corretoresService";

function ModalGestaoTurnos({ id, open, onClose }) {
  const history = useHistory();

  const { atualizaTurno } = corretoresService;

  const [listDays, setListDays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [turnos, setTurnos] = useState([]);
  
  async function handleSubmit() {
    setLoading(true);

    const response = await atualizaTurno(id, listDays);

    const { status } = response;

    if (status === 401) {
      setLoading(false);
      setOpenLoginExpirado(true);
      return;
    }
    
    if ((status === 200) || (status === 201)) {
      setShowMessage(true);
      onClose();
    }

    if (status === 400) {
      if (response.data.error) {
        const responseErrors = Object.values(response.data.error).flat(Infinity)
        
        console.log('responseErrors :: ', responseErrors);
        setErrors(responseErrors);
      } else {
        setErrors(['Ocorreu um erro ao atualizar.']);
      }
      setShowMessage(true);
    }
    
    setLoading(false);
  }

  function handleClose() {
    setShowMessage(false);
    if (errors.length === 0) {
      history.push("/corretores-cadastrados");
    }
  }

  
  const loadTurnos = useCallback(async () => {
    setCalendarLoading(true);
    const now = new Date();
    const actualMonth = now.getMonth() + 1;
    const actualYear = now.getFullYear();

    const response = await corretoresService.consultaTurnoCorretor(id, actualMonth, actualYear);

    if (response.status === 200) {
      const { data } = response;
      const newData = data.map(item => ({
        data: item.data_hora,
        turno: item.turno,
      }))
      
      setTurnos(newData);
    }

    setCalendarLoading(false);
  }, [id])

  const handleMonthChange = useCallback(async (date) => {
    setCalendarLoading(true);

    const response = await corretoresService.consultaTurnoCorretor(id, date?.month + 1, date?.year);

    if (response.status === 200) {
      const { data } = response;
      const newData = data.map(item => ({
        data: item.data_hora,
        turno: item.turno,
      }))
      
      setTurnos(newData);
    }
    
    setCalendarLoading(false);
  }, [id]);
  
  useEffect(() => {
    let mounted = true;
    
    if (mounted) {
      (async () => {
        await loadTurnos();
      })()
    }
    return () => mounted = false;
  }, [loadTurnos]);
  
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <div className="modal-title-area">
          <DialogTitle>
            <span className="titleGestaoTurno">GESTÃO DE TURNOS</span>
          </DialogTitle>
          <IconButton
            onClick={onClose}
            size="medium"
            style={{ marginRight: 16, padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <div className="gestao-turno-container">
            {loading && <OverlayLoading />}
            <Grid container direction="column">
              <Grid item>
                <Calendar callback={list => setListDays(list)} onMonthChange={handleMonthChange} data={turnos} loading={calendarLoading} />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justify="flex-start"
            direction="column"
            style={{ maxWidth: 234, paddingLeft: 24, paddingBottom: 24 }}
          >
            <Grid item>
              <span className="textLegenda">Legenda</span>
            </Grid>
            <Grid
              container
              item
              justify="space-between"
              style={{ marginTop: 10 }}
            >
              <span className="textTurnos">M - Manhã</span>
              <span className="textTurnos">T - Tarde</span>
              <span className="textTurnos">D - Diurno</span>
            </Grid>
          </Grid>
          <Grid container item justify="flex-end">
            <ButtonComponent
              text="Salvar"
              className="btn-saveGestao"
              onClick={handleSubmit}
            />
          </Grid>
        </DialogActions>
      </Dialog>

      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${
          errors.length === 0 ? "Turnos cadastrados com sucesso!" : "Erro!"
        }`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default ModalGestaoTurnos;
