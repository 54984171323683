const servicosPlano = [
  {"id_configuracao_plano": "1","configuracao": "Acesso a plataforma de gestão empresarial"},
  {"id_configuracao_plano": "2","configuracao": "Cadastros clientes"},
  {"id_configuracao_plano": "3","configuracao": "Cadastro fornecedores"},
  {"id_configuracao_plano": "4","configuracao": "Cadastro de serviços"},
  {"id_configuracao_plano": "5","configuracao": "Cadastro de produtos"},
  {"id_configuracao_plano": "6","configuracao": "Contas a pagar e receber"},
  {"id_configuracao_plano": "7","configuracao": "Controle de acessos por usuários"},
  {"id_configuracao_plano": "8","configuracao": "Notificações periódicas dos vencimentos"},
  {"id_configuracao_plano": "14","configuracao": "Modelos padrões de contratos e documentos empresariais"},
  {"id_configuracao_plano": "15","configuracao": "Relatórios para controle e gestão de Fluxo de Caixa, controle de pagamentos, recebimentos, extratos bancários, controle de estoque e demonstrativos de resultados."},
  {"id_configuracao_plano": "16","configuracao": "Elaboração e entrega das declarações contábeis e fiscais para atender ao Fisco e a Bancos."},
  {"id_configuracao_plano": "17","configuracao": "Apuração de impostos e taxas municipais, federais e estaduais."},
  {"id_configuracao_plano": "39","configuracao": "Fechamento 13º Salário"},
  {"id_configuracao_plano": "40","configuracao": "Elaboração e entrega das declarações contábeis e fiscais"},
  {"id_configuracao_plano": "18","configuracao": "Recálculo de impostos"},
  {"id_configuracao_plano": "19","configuracao": "Acompanhamentos de impostos pagos e controle de certidões negativas"},
  {"id_configuracao_plano": "20","configuracao": "Fechamento mensal financeiro com indicadores de resultados."},
  {"id_configuracao_plano": "21","configuracao": "Demonstrações Contábeis ponto a ponto"},
  {"id_configuracao_plano": "22","configuracao": "Análise Tributária da sua empresa"},
  {"id_configuracao_plano": "23","configuracao": "Diagnóstico do seu Negócio"},
  {"id_configuracao_plano": "24","configuracao": "Assessoria com especialistas de cada área Contábil, Fiscal, Tributária, BPO, DP, Financeiro, Legal."},
  {"id_configuracao_plano": "25","configuracao": "Mentoria para gestão da sua empresa nas áreas área Contábil, Fiscal, Tributária, BPO, DP, Financeiro, Legal."},
  {"id_configuracao_plano": "33","configuracao": "Cadastro no e-social"},
  {"id_configuracao_plano": "34","configuracao": "Registro do colaborador"},
  {"id_configuracao_plano": "35","configuracao": "Emissão do contrato"},
  {"id_configuracao_plano": "36","configuracao": "Fechamento mensal da folha"},
  {"id_configuracao_plano": "37","configuracao": "Emissão de guias"},
  {"id_configuracao_plano": "38","configuracao": "Controle de férias"},
  {"id_configuracao_plano": "27","configuracao": "Assistente virtual FAQ e BOT"},
  {"id_configuracao_plano": "28","configuracao": "Chat"},
  {"id_configuracao_plano": "29","configuracao": "WhatsApp"},
  {"id_configuracao_plano": "30","configuracao": "E-mail"},
  {"id_configuracao_plano": "31","configuracao": "Telefone"},
  {"id_configuracao_plano": "32","configuracao": "Canal direto com contador"},
]

export { servicosPlano }