import Axios from './Axios';

const perfilAcessoService = {
  cadastraPerfilAcesso: async (nome, padrao = 0) => {
    const response = await Axios.post(`/erp-cadastra-perfil-acesso`, {
      nome,
      padrao,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  atualizarPermissoes: async (
    id_perfil_acesso,
    // id_empresa,
    defaultPermissions = 1,
    permissoes
  ) => {
    const response = await Axios.post(`/erp-salvar-permissoes-perfil-acesso`, {
      id_perfil_acesso,
      // id_empresa,
      default: defaultPermissions,
      permissoes,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  atualizaPerfilAcesso: async (id_perfil_acesso, nome, padrao) => {
    const response = await Axios.post(
      `/erp-atualiza-perfil-acesso/${id_perfil_acesso}`,
      {
        nome,
        padrao,
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  listaPerfilAcesso: async (
    page = null,
    order_by = null,
    order_by_type = null,
    per_page = null,
    search_term = null
  ) => {
    const response = await Axios.get(`/erp-lista-perfil-acesso`, {
      params: {
        page,
        order_by,
        order_by_type,
        per_page,
        search_term,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  consultaPerfilAcesso: async (id) => {
    const response = await Axios.get(`/erp-consulta-perfil-acesso/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  listaPermissoesPerfilAcesso: async (
    id,
    page = null,
    order_by = null,
    order_by_type = null,
    per_page = null,
    search_term = null
  ) => {
    const response = await Axios.get(
      `/erp-lista-permissoes-perfil-acesso/${id}`,
      {
        params: {
          page,
          order_by,
          order_by_type,
          per_page,
          search_term,
        },
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  removePerfilAcesso: async (id) => {
    const response = await Axios.get(`/erp-remove-perfil-acesso/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selecionaPerfilAcesso: async () => {
    const response = await Axios.get(`/erp-seleciona-perfil-acesso`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};

export default perfilAcessoService;
