import React from "react";
import { Switch, Route as DefaultRoute } from "react-router-dom";
import Route from "./RouteWrapper";
import Container from "../components/Container";
import FaturaContainer from "../components/FaturaContainer";

//paginas de teste
import ViewButtons from "../tests/ViewButton";
import ViewConfirmDialog from "../tests/ViewConfirmDialog";
import ViewSelect from "../tests/ViewSelect";
import ViewInput from "../tests/ViewInput";
import ViewInputFile from "../tests/ViewInputFile";
import ViewStepper from "../tests/ViewStepper";
import ViewModalContas from "../tests/ViewModalContas";
import ViewPagamentoRecebimento from "../tests/ViewPagamentoRecebimento";
import ViewClickSign from "../tests/ViewClickSign";

// Sem rota definida
import PreAdmisssaoDadosContratuais from "../views/PreAdmissoes/CadastroDadosContratuais";
import PreAdmissaoListaDependentes from "../views/PreAdmissoes/Dependentes/Lista";
import PreAdmissaoListaValeTransporte from "../views/PreAdmissoes/ValeTransporte/Lista";
//

import Login from "../views/LoginPage";
import Home from "../views/Home";
import EmpresasLista from "../views/Empresas/Lista";
import EmpresasCadastro from "../views/Empresas/Cadastro";
import PreCadastroEmpresas from "../views/Empresas/PreCadastro";
import EmpresaCertificado from "../views/Empresas/EmissaoCertificado";
import ConfiguracoesEmpresas from "../views/ConfiguracoesEmpresas";
import CadastroClientes from "../views/CadastroClientes/Cadastro";
import ListaFornecedores from "../views/CadastroFornecedores/Lista";
import CadastroFornecedores from "../views/CadastroFornecedores/Cadastro";
import ListaTransportadoras from "../views/Transportadoras/Lista";
import CadastroUsuarios from "../views/CadastroUsuarios";
import EditarUsuario from "../views/CadastroUsuarios/Editar";
import PermissaoUsuario from "../views/CadastroUsuarios/Editar/PermissoesAcesso";
import UsuariosLista from "../views/CadastroUsuarios/Lista";
import ContasBancariasLista from "../views/CadastroContas/Lista";
import ContasBancariasCadastro from "../views/CadastroContas";
import ContasBancariasEditar from "../views/CadastroContas/Editar";
import ClientesLista from "../views/CadastroClientes/Lista";
import Emissao from "../views/Emissao";
import EmissaoListaNFS from "../views/Emissao/ListaNFS";
import EmissaoListaNFC from "../views/Emissao/ListaNFC";
import EmissaoListaNFE from "../views/Emissao/ListaNFE";
import AgendarRecebimento from "../views/AgendarRecebimento";
import AgendarPagamento from "../views/AgendarPagamento";
import ContasExtratos from "../views/ContasExtratos";
import CadastraPreAdmissao from "../views/PreAdmissoes/Cadastro";
import CadastraPreAdmissaoEnderecoContato from "../views/PreAdmissoes/Cadastro/EnderecoContato";
import CadastraPreAdmissaoDocumentos from "../views/PreAdmissoes/Cadastro/Documentos";
import CadastraPreAdmissaoDadosBancarios from "../views/PreAdmissoes/Cadastro/DadosBancarios";
import Ferias from "../views/Ferias";
import EventosFolha from "../views/EventosFolha";
import IntegracaoContabilFP from "../views/IntegracaoContabilFP";
import Certidoes from "../views/Certidoes";
import CadastrarCertidao from "../views/Certidoes/Cadastrar";
import EditarCertidao from "../views/Certidoes/Editar";
import DocumentacaoLegal from "../views/DocumentacaoLegal";
import CadastroDocumentacaoLegal from "../views/DocumentacaoLegal/Cadastrar";
import EditarDocumentacaoLegal from "../views/DocumentacaoLegal/Editar";
import DRE from "../views/DRE";
import ContasReceber from "../views/ContasReceber";
import ContasPagar from "../views/ContasPagar";
import ContasRecebidas from "../views/ContasRecebidas";
import ContasPagas from "../views/ContasPagas";
import LeadsChatbot from "../views/LeadsChatbot";
import CentroCustosLista from "../views/CentroCusto/Lista";
import CadastroCentroCusto from "../views/CentroCusto/Cadastro";
import EditarCentroCusto from "../views/CentroCusto/Editar";
import CategoriaPadrao from "../views/CategoriaPadrao";
import EditarCategoria from "../views/Categorias/Editar";
import EditarGrupo from "../views/Categorias/EditarGrupo";
import CadastroGrupo from "../views/Categorias/NovoGrupo";
import ConfiguracoesPlataforma from "../views/ConfiguracoesPlataforma";
import PerfilTributario from "../views/Empresas/PerfilTributario";
import PagamentosCadastrados from "../views/Pagamentos";
import CadastroPagamento from "../views/Pagamentos/Cadastro";
import RecebimentosCadastrados from "../views/Recebimentos";
import CadastroRecebimento from "../views/Recebimentos/Cadastro";
import ObrigacoesLista from "../views/Obrigacoes";
import CadastroObrigacoes from "../views/Obrigacoes/Cadastro";
import EditarObrigacoes from "../views/Obrigacoes/Editar";
import CadastroDocumentoContabil from "../views/DocumentoContabil/Cadastro";
import ListaDocumentoContabil from "../views/DocumentoContabil/Lista";
import EditarDocumentoContabil from "../views/DocumentoContabil/Editar";
import Faturas from "../views/Faturas";
import CadastroFatura from "../views/CadastroFatura";
import ConfiguracoesBoleto from "../views/ConfiguracoesBoleto";
import Conciliacao from "../views/Conciliacao";
import FaturaBoleto from "../views/FaturaBoleto";
import ContratoAceite from "../views/ContratoAceite";
import PrimeiraFatura from "../views/PrimeiraFatura";
import Parcelamentos from "../components/Parcelamentos";
import ServicoCadastro from "../views/Servicos/Cadastro";
import CadastrarPlano from "../views/PlanosCadastrados/Cadastro";
import AtualizarPlano from "../views/PlanosCadastrados/Editar";
import ListaPlanosCadastrados from "../views/PlanosCadastrados/Lista";
import EscolhePlanos from "../views/EscolhePlanos";
import RedirectCertificado from "../views/Empresas/RedirectCertificado";
import ListaServicos from "../views/Servicos/Lista";
import ListaPerfilAcesso from "../views/PerfilAcesso/Lista";
import PermissoesAcesso from "../views/PerfilAcesso/PermissoesAcesso";
import ListaSolicitacaoServico from "../views/SolicitacaoServico/Lista";
import NotasDeEntrada from "../views/NotasDeEntrada";
import EmitirNfce from "../views/Emissao/Cadastro/NFC-e";
import EmitirNfe from "../views/Emissao/Cadastro/NF-e";
import SolicitacaoServico from "../views/SolicitacaoServico";
import ExibirSolicitacao from "../views/SolicitacaoServico/Exibir";
import ConfirmacaoProduto from "../views/NotasDeEntrada/ConfirmacaoProduto";
import Checkout from "../views/CheckoutContratacao";
import AssinaContrato from "../views/AssinaContrato";
import VendaPDV from "../views/PDV/Venda";
import ListaPDV from "../views/PDV/Lista";
import ListaCaixaPDV from "../views/Caixa/Lista";

// específico 36z
import ListaCorretoresTurnos from "../views/ListaCorretoresTurnos";
import ListagemImoveis from "../views/ListagemImoveis";
import ListaCorretores from "../views/CadastroCorretores/Lista";
import CadastroCorretor from "../views/CadastroCorretores/Cadastro";
import ListaGerentes from "../views/CadastroGerentes/Lista";
import CadastroGerente from "../views/CadastroGerentes/Cadastro";
import RedefinirSenha from "../views/RedefinirSenha";

import ListaProdutos from "../views/Produtos/Lista";
import CadastroProdutos from "../views/CadastroProdutos";
import GestaoLeadsLista from "../views/GestaoLeads/Lista";
import AtualizaLead from "../views/GestaoLeads/Atualiza";
import ListaRelatorioAssinantes from "../views/RelatorioAssinantes";
import ListaRelatorioExtratoMovimentacaoFinanceira from "../views/RelatorioExtratoMovimentacaoFinanceira";
import DetalhesImovel from "../views/DetalhesImovel";
import CadastroTransportadoras from "../views/Transportadoras/Cadastro";
import ListaIntegracao from "../views/IntegracaoQuestor/Lista";
import CadastroIntegracao from "../views/IntegracaoQuestor/cadastro";
import ListaCodigosServicosPrefeitura from "../views/CodigosServicoPrefeitura/Lista";
import CadastroCodigosServico from "../views/CodigosServicoPrefeitura/Cadastro";
import FluxoCaixa from "../views/FluxoCaixa";
import { CadastroPreAdmissao } from "../views/DpPreAdmissao/Cadastro/empresa";
import { CadastroPreAdmissaoColaborador } from "../views/DpPreAdmissao/Cadastro/funcionario";
import { ListaPreAdmissoes } from "../views/DpPreAdmissao/Lista";
import { EdicaoPreAdmissao } from "../views/DpPreAdmissao/Cadastro/edicao";
import { ListaPreDemissoes } from "../views/DpPreDemissao/Lista";
import { CadastroPreDemissao } from "../views/DpPreDemissao/Cadastro";
import { ConfiguracoesEmailAutomatico } from "../views/ConfiguracoesEmailAutomatico";
import { ListaFuncionarios } from "../views/DpFuncionario/Lista";
import HubCountDashboard from "../views/HubCountDashboard";
import { ListaPrevisoesFerias } from "../views/DpPrevisaoFerias/Lista";
import { ListaAgendamentoFerias } from "../views/DpAgendamentoFerias/Lista";
import { CadastroAgendamentoFerias } from "../views/DpAgendamentoFerias/Cadastro";
import { ListaFolhaPagamento } from "../views/DpFolhaPagamento/Lista";
import { CadastroEventosFolha } from "../views/DpFolhaPagamento/Cadastro";
import { Orcamento } from "../views/Orcamento";
import { DREOrcamentario } from "../views/DREOrcamentario";
import { ListaCartoesPonto } from "../views/DpCartaoPonto/Lista";
import { CadastroCartaoPonto } from "../views/DpCartaoPonto/Cadastro";
import { ListaFormasPagamento } from "../views/FormasPagamento/Lista";
import { CadastroFormasPagamento } from "../views/FormasPagamento/Cadastro";
import { ListaVersoes } from "../views/Versoes/Lista";
import { EditarPerfil } from "../views/CadastroUsuarios/EditarPerfil";
import { ListaGrupos } from "../views/Grupos/Lista";
import { CadastroGrupoCategoria } from "../views/Grupos/Cadastro";
import { ListaCategorias } from "../views/CategoriasEmpresa/Lista";
import { CadastroCategorias } from "../views/CategoriasEmpresa/Cadastro";
import Subcategorias from "../views/Categorias";
import CadastroSubategorias from "../views/Categorias/Cadastro";
import { DREOrcadoRealizado } from "../views/DREOrcadoRealizado";
import NotasDeEntradaRelatorio from "../views/NotasDeEntradaRelatorio";
import NotasDeSaidaRelatorio from "../views/NotasDeSaidaRelatorio";
import PaginaNaoEncontrada from "../views/PaginaNaoEncontrada";

const Routes = ({ callbackAlert }) => (
  <Switch>
    <Route
      exact
      path="/add-parcel"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={Parcelamentos}
          objHeaderConfig={{
            title: "Adicionar Parcela",
          }}
        />
      )}
    />
    <Route
      path="/cadastro-produtos/:id?"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroProdutos}
          objHeaderConfig={{
            title: "Cadastro de Produtos",
            btnClass: "btn-primary ",
            url: "/produtos-cadastrados",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/solicitacao-servico/:id?"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={SolicitacaoServico}
          objHeaderConfig={{
            title: "Solicitação de Serviço",
            btnClass: "btn-primary ",
            url: "/lista-solicitacao-servico",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/visualizar-solicitacao/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ExibirSolicitacao}
          objHeaderConfig={{
            title: "Solicitação de Serviço",
            btnClass: "btn-primary ",
            url: "/lista-solicitacao-servico",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/teste-input-file"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ViewInputFile}
          objHeaderConfig={{
            title: "teste de input",
            btnClass: "btn-primary",
            url: "/teste-step",
            btnText: "+ Emitir Nota Fiscal",
          }}
        />
      )}
    />
    <Route
      exact
      path="/atualiza-lead/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={AtualizaLead}
          objHeaderConfig={{
            title: "Gestão de Leads",
          }}
        />
      )}
    />
    <Route
      exact
      path="/lista-gestao-leads"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={GestaoLeadsLista}
          objHeaderConfig={{
            title: "Gestão de Leads",
          }}
        />
      )}
    />
    <Route
      exact
      path="/corretores-cadastrados"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaCorretores}
          objHeaderConfig={{
            title: "Corretores Cadastrados",
            btnClass: "btn-primary",
            url: "/cadastra-corretor",
            btnText: "+ Novo Corretor",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastra-corretor"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroCorretor}
          objHeaderConfig={{
            title: "Cadastro de Corretor",
            btnClass: "btn-primary ",
            url: "/corretores-cadastrados",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-corretor/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroCorretor}
          objHeaderConfig={{
            title: "Editar Corretor",
            btnClass: "btn-primary ",
            url: "/corretores-cadastrados",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/gerentes-cadastrados"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaGerentes}
          objHeaderConfig={{
            title: "Gerentes Cadastrados",
            btnClass: "btn-primary",
            url: "/cadastrar-gerente",
            btnText: "+ Novo Gerente",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastrar-gerente"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroGerente}
          objHeaderConfig={{
            title: "Cadastrado de Gerente",
            btnClass: "btn-primary ",
            url: "/gerentes-cadastrados",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-gerente/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroGerente}
          objHeaderConfig={{
            title: "Editar Gerente",
            btnClass: "btn-primary ",
            url: "/gerentes-cadastrados",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/pre-admissao-dados-contratuais/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={PreAdmisssaoDadosContratuais}
          objHeaderConfig={{
            title: "Cadastro Pré-admissão",
          }}
        />
      )}
    />
    <Route
      exact
      path="/pre-admissao-dependentes/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={PreAdmissaoListaDependentes}
          objHeaderConfig={{
            title: "Cadastrar Pré-admissão",
          }}
        />
      )}
    />
    <Route
      exact
      path="/pre-admissao-vale-transporte/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={PreAdmissaoListaValeTransporte}
          objHeaderConfig={{
            title: "Cadastrar Pré-admissão",
          }}
        />
      )}
    />
    <Route
      exact
      path="/pre-admissao-endereco-contato/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastraPreAdmissaoEnderecoContato}
          objHeaderConfig={{
            title: "Cadastrar Pré-admissão",
          }}
        />
      )}
    />

    <Route
      exact
      path="/lista-imoveis"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListagemImoveis}
          objHeaderConfig={{
            title: "Listagem de Imóveis",
          }}
        />
      )}
    />
    <Route
      exact
      path="/mapa-turno-corretor"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaCorretoresTurnos}
          // objHeaderConfig={{
          //   title: ""
          // }}
        />
      )}
    />
    <Route
      exact
      path="/teste-modal-contas"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ViewModalContas}
          objHeaderConfig={{
            title: "Teste da modal de contas",
            btnClass: "btn-primary",
            url: "/teste-modal-contas",
            btnText: "+ Emitir Nota Fiscal",
          }}
        />
      )}
    />
    <Route
      exact
      path="/teste-extrato-recebimento-pagamento"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ViewPagamentoRecebimento}
          objHeaderConfig={{
            title: "Teste Recebimento/Pagamento Extrato",
          }}
        />
      )}
    />
    <Route
      exact
      path="/teste-input"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ViewInput}
          objHeaderConfig={{
            title: "teste de input",
            btnClass: "btn-primary",
            url: "/teste-step",
          }}
        />
      )}
    />
    <Route
      exact
      path="/teste-step"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ViewStepper}
          objHeaderConfig={{
            title: "teste de step",
            btnClass: "btn-primary",
            btnText: "+ Emitir Nota Fiscal",
          }}
        />
      )}
    />
    <Route
      exact
      path="/teste-botoes"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ViewButtons}
          objHeaderConfig={{
            title: "teste de botões",
            btnClass: "btn-primary",
            btnText: "+ Emitir Nota Fiscal",
          }}
        />
      )}
    />
    <Route
      exact
      path="/teste-confirmDialog"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ViewConfirmDialog}
          objHeaderConfig={{
            title: "teste de confirmDialog",
          }}
        />
      )}
    />
    <Route
      exact
      path="/teste-clicsign"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ViewClickSign}
          objHeaderConfig={{
            title: "teste de ClickSign",
            btnClass: "btn-primary",
            btnText: "+ Emitir Nota Fiscal",
          }}
        />
      )}
    />
    <Route
      exact
      path="/teste-select"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ViewSelect}
          objHeaderConfig={{
            title: "teste de select",
            btnClass: "btn-primary",
            btnText: "+ Emitir Nota Fiscal",
          }}
        />
      )}
    />
    <Route
      exact
      path="/"
      render={() => <Login callbackAlert={callbackAlert} />}
      isPrivate={false}
    />
    <Route exact path="/atualiza-senha" render={() => <RedefinirSenha />} />
    <Route
      exact
      isPrivate={false}
      path="/ver-fatura/:idFatura/:idBoleto?"
      render={() => (
        <FaturaContainer>
          <FaturaBoleto />
        </FaturaContainer>
      )}
    />
    <Route
      exact
      path="/home"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={Home}
          objHeaderConfig={{
            title: "cbhub",
            btnClass: "btn-primary",
            btnText: "Emitir Nota Fiscal",
            url: "/emissao/nfs",
          }}
        />
      )}
    />
    <Route
      exact
      path="/empresas"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EmpresasLista}
          objHeaderConfig={{
            title: "Empresas Cadastradas",
          }}
        />
      )}
    />
    <Route
      exact
      path="/empresas-cadastro"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EmpresasCadastro}
          objHeaderConfig={{
            title: "Cadastrar Empresa",
            btnClass: "btn-primary ",
            btnText: "Voltar",
            url: "/empresas",
          }}
        />
      )}
    />
    <Route
      exact
      path="/pre-cadastro"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={PreCadastroEmpresas}
          objHeaderConfig={{
            title: "Pré-cadastro",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-empresa/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EmpresasCadastro}
          objHeaderConfig={{
            title: "Editar Empresa",
            btnClass: "btn-primary ",
            btnText: "Voltar",
            url: "/empresas",
          }}
        />
      )}
    />
    <Route
      exact
      path="/detalhes-empresa/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EmpresasCadastro}
          objHeaderConfig={{
            title: "Editar Empresa",
            btnClass: "btn-primary ",
            btnText: "Voltar",
            url: "/empresas",
          }}
        />
      )}
    />
    <Route
      exact
      path="/subcategorias"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={Subcategorias}
          objHeaderConfig={{
            title: "Subcategorias",
          }}
        />
      )}
    />
    <Route
      exact
      path="/categoria-padrao"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CategoriaPadrao}
          objHeaderConfig={{
            title: "Categoria Padrão",
          }}
        />
      )}
    />
    <Route
      path="/categorias-cadastra-grupo/"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroGrupo}
          objHeaderConfig={{
            title: "Cadastrar Grupo",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/categorias",
          }}
        />
      )}
    />
    <Route
      path="/categorias-edita-grupo/:companyId/:groupId"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EditarGrupo}
          objHeaderConfig={{
            title: "Editar Grupo",
            btnClass: "btn-second-outline",
            btnText: "Voltar",
            url: "/categorias",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastro-subcategoria/:companyId/:categoryId"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroSubategorias}
          objHeaderConfig={{
            title: "Cadastro subcategoria",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/subcategorias",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-subcategoria/:companyId/:categoryId/:subCategoryId"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroSubategorias}
          objHeaderConfig={{
            title: "Editar subcategoria",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/subcategorias",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-categoria/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EditarCategoria}
          objHeaderConfig={{
            title: "Editar Categoria",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/categorias",
          }}
        />
      )}
    />
    <Route
      exact
      path="/certificado/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EmpresaCertificado}
          objHeaderConfig={{
            title: "Certificado",
          }}
        />
      )}
    />
    <Route
      exact
      path="/configuracoes-empresas/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ConfiguracoesEmpresas}
          objHeaderConfig={{
            title: "Configurações",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/empresas",
          }}
        />
      )}
    />
    <Route
      path="/centro-custo"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CentroCustosLista}
          objHeaderConfig={{
            title: "Centro de Custos",
            btnClass: "btn-primary",
            btnText: "+ Novo Centro de Custos",
            url: "/cadastro-centro-custo",
          }}
        />
      )}
    />
    <Route
      path="/cadastro-centro-custo"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroCentroCusto}
          objHeaderConfig={{
            title: "Centro de Custos",
            btnClass: "btn-primary ",
            btnText: "Voltar",
            url: "/centro-custo",
          }}
        />
      )}
    />
    <Route
      path="/editar-centro-custo/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EditarCentroCusto}
          objHeaderConfig={{
            title: "Editar Centro de Custos",
            btnClass: "btn-second-outline ",
            btnText: "Voltar",
            url: "/centro-custo",
          }}
        />
      )}
    />
    <Route
      exact
      path="/clientes-cadastrados"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ClientesLista}
          objHeaderConfig={{
            title: "Clientes Cadastrados",
            btnClass: "btn-primary",
            btnText: "+ Novo Cliente",
            url: "/cadastro-clientes",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastro-clientes"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroClientes}
          objHeaderConfig={{
            title: "Cadastro de Clientes",
            btnClass: "btn-primary ",
            btnText: "Voltar",
            url: "/clientes-cadastrados",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-cliente/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroClientes}
          objHeaderConfig={{
            title: "Editar Cliente",
            btnClass: "btn-primary ",
            btnText: "Voltar",
            url: "/clientes-cadastrados",
          }}
        />
      )}
    />
    <Route
      path="/lista-fornecedores"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaFornecedores}
          objHeaderConfig={{
            title: "Cadastro de Fornecedores",
            btnClass: "btn-primary",
            btnText: "+ Novo Fornecedor",
            url: "/cadastro-fornecedores",
          }}
        />
      )}
    />
    <Route
      path="/cadastro-fornecedores"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroFornecedores}
          objHeaderConfig={{
            title: "Cadastro de Fornecedores",
            btnClass: "btn-primary ",
            btnText: "Voltar",
            url: "/lista-fornecedores",
          }}
        />
      )}
    />
    <Route
      path="/edita-fornecedor/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroFornecedores}
          objHeaderConfig={{
            title: "Editar Fornecedor",
            btnClass: "btn-primary ",
            btnText: "Voltar",
            url: "/lista-fornecedores",
          }}
        />
      )}
    />
    <Route
      path="/cadastro-conta"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ContasBancariasLista}
          objHeaderConfig={{
            title: "Contas bancárias",
            btnClass: "btn-primary",
            btnText: "+ Nova conta bancária",
            url: "/cadastrar-conta",
          }}
        />
      )}
    />
    <Route
      path="/cadastrar-conta"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ContasBancariasCadastro}
          objHeaderConfig={{
            btnClass: "btn-primary",
            btnText: "Voltar",
            title: "Cadastrar Conta Bancária",
            url: "/cadastro-conta",
          }}
        />
      )}
    />
    <Route
      path="/editar-conta/:idConta"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ContasBancariasCadastro}
          objHeaderConfig={{
            btnClass: "btn-primary",
            btnText: "Voltar",
            title: "Editar conta",
            url: "/cadastro-conta",
          }}
        />
      )}
    />
    <Route
      exact
      path="/usuarios-cadastrados"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={UsuariosLista}
          objHeaderConfig={{
            title: "Usuários Cadastrados",
            btnClass: "btn-primary",
            btnText: "+ Novo Usuário",
            url: "/cadastro-usuarios",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastro-usuarios"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroUsuarios}
          objHeaderConfig={{
            title: "Cadastrar Usuário",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/usuarios-cadastrados",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-usuario/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EditarUsuario}
          objHeaderConfig={{
            title: "Editar Usuário",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/usuarios-cadastrados",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-perfil"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EditarPerfil}
          objHeaderConfig={{
            title: "Editar Perfil",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/",
          }}
        />
      )}
    />
    <Route
      exact
      path="/permissao-usuarios"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={PermissaoUsuario}
          objHeaderConfig={{
            title: "Permissões de Usuário",
            btnClass: "btn-hidden",
          }}
        />
      )}
    />
    <Route
      exact
      path="/emissao/nfs"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EmissaoListaNFS}
          objHeaderConfig={{
            title: "Notas Fiscais Emitidas - NFS-e",
          }}
        />
      )}
    />
    <Route
      exact
      path="/emissao/nfc"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EmissaoListaNFC}
          objHeaderConfig={{
            title: "Notas Fiscais Emitidas - NFC-e",
          }}
        />
      )}
    />
    <Route
      exact
      path="/emissao/nfe"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EmissaoListaNFE}
          objHeaderConfig={{
            title: "Notas Fiscais Emitidas - NF-e",
          }}
        />
      )}
    />
    <Route
      exact
      path="/emitir-nfse"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={Emissao}
          objHeaderConfig={{
            title: "Emitir Nota Fiscal - NFS-e",
          }}
        />
      )}
    />
    <Route
      exact
      path="/agendar-recebimento"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={AgendarRecebimento}
          objHeaderConfig={{
            title: "Agendar Recebimento",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/recebimentos-cadastrados",
          }}
        />
      )}
    />
    <Route
      exact
      path="/agendar-pagamento"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={AgendarPagamento}
          objHeaderConfig={{
            title: "Agendar Pagamento",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/pagamentos-cadastrados",
          }}
        />
      )}
    />
    <Route
      exact
      path="/contas-extratos"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ContasExtratos}
          objHeaderConfig={{
            title: "Contas e Extratos",
            btnClass: "btn-primary",
            btnText: "+ Cadastro Conta",
            url: "/cadastrar-conta",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastrar-pre-admissao"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastraPreAdmissao}
          objHeaderConfig={{
            title: "Cadastrar Pré-admissão",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/pre-admissao",
          }}
        />
      )}
    />
    <Route
      exact
      path="/pre-admissao/endereco-contato/:idFuncionario"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastraPreAdmissaoEnderecoContato}
          objHeaderConfig={{
            title: "Cadastrar Pré-admissão",
          }}
        />
      )}
    />
    <Route
      exact
      path="/pre-admissao/documentos/:idFuncionario"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastraPreAdmissaoDocumentos}
          objHeaderConfig={{
            title: "Cadastrar Pré-admissão",
          }}
        />
      )}
    />
    <Route
      exact
      path="/pre-admissao/dados-bancarios/:idFuncionario"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastraPreAdmissaoDadosBancarios}
          objHeaderConfig={{
            title: "Cadastrar Pré-admissão",
          }}
        />
      )}
    />
    <Route
      exact
      path="/pre-demissao"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaPreDemissoes}
          objHeaderConfig={{
            title: "Pré-demissões Cadastradas",
            btnClass: "btn-primary",
            btnText: "+ Nova Pré-demissão",
            url: "/cadastrar-pre-demissao",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastrar-pre-demissao"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroPreDemissao}
          objHeaderConfig={{
            title: "Cadastro de Pré-Demissão",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/pre-demissao",
          }}
        />
      )}
    />
    <Route
      exact
      path="/ferias"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={Ferias}
          objHeaderConfig={{
            title: "Férias",
            btnClass: "btn-primary",
          }}
        />
      )}
    />
    <Route
      exact
      path="/eventos-folha"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EventosFolha}
          objHeaderConfig={{
            title: "Eventos da Folha",
            btnClass: "btn-primary",
          }}
        />
      )}
    />
    <Route
      exact
      path="/integracao-contabil-fp"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={IntegracaoContabilFP}
          objHeaderConfig={{
            title: "Integração Contabil",
            btnClass: "btn-primary",
          }}
        />
      )}
    />
    <Route
      exact
      path="/obrigacoes-financeiras"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ObrigacoesLista}
          objHeaderConfig={{
            title: "Obrigações Cadastradas",
            btnClass: "btn-primary",
            btnText: "+ Nova Obrigação",
            url: "/cadastro-obrigacoes",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastro-obrigacoes"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroObrigacoes}
          objHeaderConfig={{
            title: "Cadastrar Obrigações",
            btnClass: "btn-primary ",
            url: "/obrigacoes-financeiras",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-obrigacoes/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EditarObrigacoes}
          objHeaderConfig={{
            title: "Editar Obrigações",
            btnClass: "btn-primary ",
            url: "/obrigacoes-financeiras",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/certidoes"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={Certidoes}
          objHeaderConfig={{
            title: "Certidões Cadastradas",
            btnClass: "btn-primary",
            btnText: "+ Nova Certidão",
            url: "/cadastrar-certidao",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastrar-certidao"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastrarCertidao}
          objHeaderConfig={{
            title: "Cadastrar Certidão",
            btnClass: "btn-primary ",
            url: "/certidoes",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-certidao/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EditarCertidao}
          objHeaderConfig={{
            title: "Editar Certidão",
            btnClass: "btn-primary ",
            url: "/certidoes",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/documentacao-legal"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={DocumentacaoLegal}
          objHeaderConfig={{
            title: "Documentação Legal",
            btnClass: "btn-primary",
            btnText: "+ Novo Documento",
            url: "/cadastrar-documento-legal",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastrar-documento-legal"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroDocumentacaoLegal}
          objHeaderConfig={{
            title: "Cadastrar Documento Legal",
            btnClass: "btn-primary ",
            url: "/documentacao-legal",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-documento-legal/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EditarDocumentacaoLegal}
          objHeaderConfig={{
            title: "Editar Documento Legal",
            btnClass: "btn-primary ",
            url: "/documentacao-legal",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/dre"
      render={(props) => (
        <Container {...props} callbackAlert={callbackAlert} component={DRE} objHeaderConfig={{
            title: "Demonstrativo de Resultado do Exercício - DRE",
        }} />
      )}
    />
    <Route
      exact
      path="/contas-receber"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ContasReceber}
          objHeaderConfig={{
              title: "Contas a Receber",
          }}
        />
      )}
    />
    <Route
      exact
      path="/contas-pagar"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ContasPagar}
          objHeaderConfig={{
              title: "Contas a Pagar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/contas-recebidas"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ContasRecebidas}
          objHeaderConfig={{
              title: "Contas Recebidas",
          }}
        />
      )}
    />
    <Route
      exact
      path="/contas-pagas"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ContasPagas}
          objHeaderConfig={{
              title: "Contas Pagas",
          }}
        />
      )}
    />
    <Route
      exact
      path="/pagamentos-cadastrados"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={PagamentosCadastrados}
          objHeaderConfig={{
            title: "Contas a pagar cadastradas",
            btnClass: "btn-primary",
            btnText: "+ Novo a pagar",
            url: "/cadastro-pagamentos",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastro-pagamentos"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroPagamento}
          objHeaderConfig={{
            title: "Cadastrar Pagamentos",
            btnClass: "btn-primary ",
            url: "/pagamentos-cadastrados",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/recebimentos-cadastrados"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={RecebimentosCadastrados}
          objHeaderConfig={{
            title: "Contas a receber cadastradas",
            btnClass: "btn-primary",
            btnText: "+ Novo a receber",
            url: "/cadastro-recebimentos",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastro-recebimentos"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroRecebimento}
          objHeaderConfig={{
            title: "Cadastrar Recebimentos",
            btnClass: "btn-primary ",
            url: "/recebimentos-cadastrados",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/leads-chatbot"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={LeadsChatbot}
          objHeaderConfig={{
              title: "Leads Chatbot",
          }}
        />
      )}
    />
    <Route
      exact
      path="/configuracoes-plataforma"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ConfiguracoesPlataforma}
          objHeaderConfig={{
            title: "Configurações Gerais",
            btnClass: "btn-hidden",
          }}
        />
      )}
    />
    <Route
      exact
      path="/configuracao-email-automatico"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ConfiguracoesEmailAutomatico}
          objHeaderConfig={{
            title: "E-mails Automáticos de Notificações",
            btnClass: "btn-hidden",
          }}
        />
      )}
    />
    <Route
      exact
      path="/perfil-tributario/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={PerfilTributario}
          objHeaderConfig={{
            title: "Perfil Tributário e Nota Fiscal",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastro-documento-contabil"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroDocumentoContabil}
          objHeaderConfig={{
            title: "Cadastrar Documento Contábil",
            btnText: "Voltar",
            btnClass: "btn-primary",
            url: "/documento-contabil",
          }}
        />
      )}
    />
    <Route
      exact
      path="/documento-contabil"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaDocumentoContabil}
          objHeaderConfig={{
            title: "Documento Contábil",
            btnClass: "btn-primary",
            btnText: "+ Novo Documento",
            url: "/cadastro-documento-contabil",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-documento-contabil/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EditarDocumentoContabil}
          objHeaderConfig={{
            title: "Editar Documento Contábil",
            btnText: "Voltar",
            btnClass: "btn-primary",
            url: "/documento-contabil",
          }}
        />
      )}
    />
    <Route
      exact
      path="/faturas/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={Faturas}
          objHeaderConfig={{
            title: "Faturas",
          }}
        />
      )}
    />
    <Route
      path="/nova-fatura"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroFatura}
          objHeaderConfig={{
            title: "Nova Fatura",
          }}
        />
      )}
    />
    <Route
      path="/fatura-lote"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroFatura}
          objHeaderConfig={{
            title: "Novas Faturas",
          }}
        />
      )}
    />
    <Route
      exact
      path="/configuracoes-boleto/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ConfiguracoesBoleto}
          objHeaderConfig={{
            title: "Configurações do Boleto",
          }}
        />
      )}
    />
    <Route
      exact
      path="/conciliacao/:idEmpresa/:idConta"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={Conciliacao}
          objHeaderConfig={{
            title: "Conciliação",
          }}
        />
      )}
    />
    <Route
      path="/fatura-boleto/:idFatura/:idBoleto?"
      // render={() => <FaturaBoleto />}
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={FaturaBoleto}
          objHeaderConfig={{
            title: "Fatura e Boleto",
          }}
        />
      )}
    />
    <Route
      exact
      path="/contrato"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ContratoAceite}
          objHeaderConfig={{
            title: "Home",
          }}
        />
      )}
    />
    <Route
      exact
      path="/fatura/:idFatura/:idBoleto?"
      component={PrimeiraFatura}
    />
    <Route
      exact
      path="/cadastrar-plano"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastrarPlano}
          objHeaderConfig={{
            title: "Cadastrar plano",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastrar-servico"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ServicoCadastro}
          objHeaderConfig={{
            title: "Cadastrar Serviço",
            btnText: "Voltar",
            btnClass: "btn-primary",
            url: "/servicos",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-servico/:id_servico"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ServicoCadastro}
          objHeaderConfig={{
            title: "Cadastrar Serviço",
            btnText: "Voltar",
            btnClass: "btn-primary",
            url: "/servicos",
          }}
        />
      )}
    />
    <Route
      exact
      path="/seleciona-plano"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EscolhePlanos}
          objHeaderConfig={{
            title: "Escolha de Planos",
          }}
        />
      )}
    />
    <Route
      exact
      path="/lista-planos"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaPlanosCadastrados}
          objHeaderConfig={{
            title: "Planos cadastrados",
            btnClass: "btn-primary",
            btnText: "+ Novo Plano",
            url: "/cadastrar-plano",
          }}
        />
      )}
    />
    <Route
      exact
      path="/atualizar-plano/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={AtualizarPlano}
          objHeaderConfig={{
            title: "Cadastrar plano",
          }}
        />
      )}
    />
    <Route
      exact
      path="/relatorio-assinantes"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaRelatorioAssinantes}
          objHeaderConfig={{
              title: "Relatório de Assinantes",
          }}
        />
      )}
    />
    <Route
      exact
      path="/relatorio-extrato-movimentacao-financeira"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaRelatorioExtratoMovimentacaoFinanceira}
          objHeaderConfig={{
              title: "Movimentação Financeira"
          }}
        />
      )}
    />
    <Route
      exact
      path="/produtos-cadastrados"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaProdutos}
          objHeaderConfig={{
            title: "Produtos cadastrados",
            btnClass: "btn-primary",
            btnText: "+ Novo Produto",
            url: "/cadastro-produtos",
          }}
        />
      )}
    />
    <Route
      exact
      path="/lista-solicitacao-servico"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaSolicitacaoServico}
          objHeaderConfig={{
            title: "Solicitação de Serviços",
            btnClass: "btn-primary",
            btnText: "+ Solicitar Serviço",
            url: "/solicitacao-servico",
          }}
        />
      )}
    />
    <Route
      exact
      path="/notas-entrada-nfe"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={NotasDeEntrada}
          objHeaderConfig={{
            title: "Notas de Entrada - NF-e",
          }}
        />
      )}
    />
    <Route
      exact
      path="/confirmacao-produto-nota/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ConfirmacaoProduto}
          objHeaderConfig={{
            title: "Confirmação de Produto",
            btnText: "Voltar",
            btnClass: "btn-primary",
            url: "/notas-entrada-nfe",
          }}
        />
      )}
    />
    <Route
      exact
      path="/emitir-nfce"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EmitirNfce}
          objHeaderConfig={{
            title: "Emitir Nota Fiscal - NFC-e",
          }}
        />
      )}
    />
    <Route
      exact
      path="/detalhes-imovel/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={DetalhesImovel}
          objHeaderConfig={{
            title: "Detalhes do Imóvel",
          }}
        />
      )}
    />
    <Route
      exact
      path="/lista-solicitacao-servico"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaSolicitacaoServico}
          objHeaderConfig={{
            title: "Serviços Solicitados",
            btnClass: "btn-primary",
            btnText: "+ Solicitar Serviço",
            url: "/solicitacao-servico",
          }}
        />
      )}
    />
    <Route
      exact
      path="/certificado-vencendo"
      isPrivate
      render={(props) => <RedirectCertificado />}
    />
    <Route
      exact
      path="/certificado-vencido"
      isPrivate
      render={(props) => <RedirectCertificado />}
    />
    <Route
      exact
      path="/servicos"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaServicos}
          objHeaderConfig={{
            title: "Serviços Cadastrados",
            btnClass: "btn-primary",
            btnText: "+ Novo Serviço",
            url: `/cadastrar-servico`,
          }}
        />
      )}
    />
    <Route
      exact
      path="/perfil-acesso"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaPerfilAcesso}
          objHeaderConfig={{
            title: "Perfil de Acesso",
          }}
        />
      )}
    />
    <Route
      exact
      path="/permissoes-acesso/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={PermissoesAcesso}
          objHeaderConfig={{
            title: "",
          }}
        />
      )}
    />
    <Route
      exact
      path="/produtos-cadastrados"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaProdutos}
          objHeaderConfig={{
            title: "Produtos cadastrados",
            btnClass: "btn-primary",
            btnText: "+ Novo Produto",
            url: "/cadastro-produtos",
          }}
        />
      )}
    />
    <Route
      exact
      path="/pdv-venda"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={VendaPDV}
          objHeaderConfig={{
            title: null,
          }}
        />
      )}
    />
    <Route
      exact
      path="/pdv-listagem"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaPDV}
          objHeaderConfig={{
            title: "Listagem de Vendas",
          }}
        />
      )}
    />
    <Route
      exact
      path="/pdv-listagem-caixa"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaCaixaPDV}
          objHeaderConfig={{
            title: "Listagem de Caixa",
          }}
        />
      )}
    />
    <Route
      exact
      path="/emitir-nfce"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EmitirNfce}
          objHeaderConfig={{
            title: "Emitir Nota Fiscal - NFC-e",
          }}
        />
      )}
    />
    <DefaultRoute path="/checkout" component={Checkout} />
    <DefaultRoute
      path="/assina-contrato/:id_assinatura_clicksign"
      component={AssinaContrato}
    />
    <Route
      exact
      path="/emitir-nfe"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EmitirNfe}
          objHeaderConfig={{
            title: "Emitir Nota Fiscal - NF-e",
          }}
        />
      )}
    />
    <Route
      path="/lista-transportadoras"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaTransportadoras}
          objHeaderConfig={{
            title: "Cadastro de Transportadoras",
            btnClass: "btn-primary",
            btnText: "+ Nova transportadora",
            url: "/cadastro-transportadora",
          }}
        />
      )}
    />
    <Route
      path="/cadastro-transportadora"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroTransportadoras}
          objHeaderConfig={{
            title: "Cadastro de Transportadora",
            btnClass: "btn-primary ",
            btnText: "Voltar",
            url: "/lista-transportadoras",
          }}
        />
      )}
    />
    <Route
      path="/editar-transportadora/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroTransportadoras}
          objHeaderConfig={{
            title: "Editar Transportadora",
            btnClass: "btn-primary ",
            btnText: "Voltar",
            url: "/lista-transportadoras",
          }}
        />
      )}
    />
    <Route
      exact
      path="/lista-integracoes"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaIntegracao}
          objHeaderConfig={{
            title: "Lista de Integrações",
            btnClass: "btn-primary",
            btnText: "+ Nova Integração",
            url: "/cadastrar-integracao",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastrar-integracao"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroIntegracao}
          objHeaderConfig={{
            title: "Integração",
          }}
        />
      )}
    />
    <Route
      exact
      path="/lista-prefeituras"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaCodigosServicosPrefeitura}
          objHeaderConfig={{
            title: "Códigos de Serviços por Prefeitura",
            btnClass: "btn-primary",
            btnText: "+ Novo upload",
            url: "/importar-arquivo",
          }}
          className="dashboard-semantix"
          exact
        />
      )}
    />
    <Route
      path="/lista-prefeituras"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaCodigosServicosPrefeitura}
          objHeaderConfig={{
            title: "Códigos de Serviços por Prefeitura",
            btnClass: "btn-primary",
            btnText: "+ Novo upload",
            url: "/importar-arquivo",
          }}
        />
      )}
    />
    <Route
      exact
      path="/importar-arquivo"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroCodigosServico}
          objHeaderConfig={{
            title: "Importar arquivo",
          }}
        />
      )}
    />
    <Route
      exact
      path="/importar-arquivo"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroCodigosServico}
          objHeaderConfig={{
            title: "Importar arquivo",
          }}
        />
      )}
    />

    {/* Pré-admissão */}

    <Route
      exact
      path="/pre-admissao"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaPreAdmissoes}
          objHeaderConfig={{
            title: "Pré-admissões Cadastradas",
            btnClass: "btn-primary",
            btnText: "+ Nova Pré-admissão",
            url: "/cadastro-pre-admissao",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastro-pre-admissao"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroPreAdmissao}
          objHeaderConfig={{
            title: "Cadastro de Pré-Admissão",
            btnClass: "btn-primary ",
            url: "/pre-admissao",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastro-pre-admissao-colaborador/:hash"
      isPrivate={false}
      render={(props) => (
        <Container
          {...props}
          publicPage={true}
          hideFooter={true}
          title="Cadastro de Pré-Admissão"
          callbackAlert={callbackAlert}
          component={CadastroPreAdmissaoColaborador}
          objHeaderConfig={{
            title: "Cadastro de Pré-Admissão",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-pre-admissao/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EdicaoPreAdmissao}
          objHeaderConfig={{
            title: "Editar Pré-admissão",
            btnClass: "btn-primary ",
            url: "/pre-admissao",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/fluxo-de-caixa"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={FluxoCaixa}
          objHeaderConfig={{
            title: "Fluxo de Caixa",
          }}
        />
      )}
    />
    <Route
      exact
      path="/hubcount"
      render={(props) => (
        <Container
          {...props}
          component={HubCountDashboard}
          iframePage
          objHeaderConfig={
            {
              // title: 'HubCount'
            }
          }
        />
      )}
    />

    <Route
      exact
      path="/editar-pre-demissao/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroPreDemissao}
          objHeaderConfig={{
            title: "Editar Pré-demissão",
            btnClass: "btn-primary ",
            url: "/pre-demissao",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/funcionarios"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaFuncionarios}
          objHeaderConfig={{
            title: "Funcionários Cadastrados",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-funcionario/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={EdicaoPreAdmissao}
          objHeaderConfig={{
            title: "Editar Funcionário",
            btnClass: "btn-primary ",
            url: "/funcionarios",
            btnText: "Voltar",
          }}
        />
      )}
    />
    <Route
      exact
      path="/previsao-ferias"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaPrevisoesFerias}
          objHeaderConfig={{
            title: "Previsão de férias",
          }}
        />
      )}
    />
    <Route
      exact
      path="/agendamento-de-ferias"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaAgendamentoFerias}
          objHeaderConfig={{
            title: "Agendamento de férias cadastradas",
            btnClass: "btn-primary",
            btnText: "+ Novo Agendamento",
            url: "/cadastro-agendamento-ferias",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastro-agendamento-ferias"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroAgendamentoFerias}
          objHeaderConfig={{
            title: "Cadastro de agendamento de férias",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/agendamento-de-ferias",
          }}
        />
      )}
    />
    <Route
      exact
      path="/consultar-agendamento/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroAgendamentoFerias}
          objHeaderConfig={{
            title: "Consultar agendamento de férias",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/agendamento-de-ferias",
          }}
        />
      )}
    />
    <Route
      exact
      path="/folhas-de-pagamento"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaFolhaPagamento}
          objHeaderConfig={{
            title: "Folha de pagamento",
            btnClass: "btn-primary",
            btnText: "+ Evento",
            url: "/cadastro-de-eventos-da-folha",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastro-de-eventos-da-folha"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroEventosFolha}
          objHeaderConfig={{
            title: "Eventos Folha de Pagamento",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/folhas-de-pagamento",
          }}
        />
      )}
    />
    <Route
      exact
      path="/orcamento"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={Orcamento}
          objHeaderConfig={{
            title: "Orçamento",
          }}
        />
      )}
    />
    <Route
      exact
      path="/dre-orcamentario"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={DREOrcamentario}
          objHeaderConfig={{
            title: "Demonstrativo de Resultado Orçamentário",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cartoes-ponto"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaCartoesPonto}
          objHeaderConfig={{
            title: "Pontos eletrônicos cadastrados",
            btnClass: "btn-primary",
            btnText: "+ Novo Ponto Eletrônico",
            url: "/cadastro-de-cartoes-ponto",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastro-de-cartoes-ponto"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroCartaoPonto}
          objHeaderConfig={{
            title: "Cadastro do Ponto Eletrônico",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/cartoes-ponto",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-cartoes-ponto/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroCartaoPonto}
          objHeaderConfig={{
            title: "Edição de Ponto Eletrônico",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/cartoes-ponto",
          }}
        />
      )}
    />
    <Route
      exact
      path="/lista-formas-pagamento"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaFormasPagamento}
          objHeaderConfig={{
            title: "Formas de pagamento Cadastradas",
            btnClass: "btn-primary",
            btnText: "+ Nova Forma",
            url: "/cadastro-forma-de-pagamento",
          }}
        />
      )}
    />
    <Route
      exact
      path="/cadastro-forma-de-pagamento"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroFormasPagamento}
          objHeaderConfig={{
            title: "Cadastrar forma de pagamento",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/lista-formas-pagamento",
          }}
        />
      )}
    />
    <Route
      exact
      path="/editar-forma-pagamento/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroFormasPagamento}
          objHeaderConfig={{
            title: "Editar forma de pagamento",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/lista-formas-pagamento",
          }}
        />
      )}
    />
    <Route
      exact
      path="/novidades"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaVersoes}
          objHeaderConfig={{
            title:
              "<span style='color: #812990'>Novidades</span> <span style='color: #EF4066'>contábilhub</span>",
          }}
        />
      )}
    />
    <Route
      exact
      path="/dre-orcado-realizado"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={DREOrcadoRealizado}
          objHeaderConfig={{
            title: "Demonstrativo de Resultado Orçado x Realizado",
          }}
        />
      )}
    />

    <Route
      exact
      path="/grupos"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaGrupos}
          objHeaderConfig={{
            title: "Grupos Cadastrados",
            btnClass: "btn-primary",
            btnText: "+ Novo Grupo",
            url: "/cadastro-grupo",
          }}
        />
      )}
    />

    <Route
      exact
      path="/cadastro-grupo"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroGrupoCategoria}
          objHeaderConfig={{
            title: "Cadastro grupo",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/grupos",
          }}
        />
      )}
    />

    <Route
      exact
      path="/editar-grupo/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroGrupoCategoria}
          objHeaderConfig={{
            title: "Editar grupo",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/grupos",
          }}
        />
      )}
    />

    <Route
      exact
      path="/categorias"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={ListaCategorias}
          objHeaderConfig={{
            title: "Categorias",
            btnClass: "btn-primary",
            btnText: "+ Nova categoria",
            url: "/cadastro-categoria",
          }}
        />
      )}
    />

    <Route
      exact
      path="/cadastro-categoria"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroCategorias}
          objHeaderConfig={{
            title: "Cadastro categoria",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/categorias",
          }}
        />
      )}
    />

    <Route
      exact
      path="/editar-categoria-empresa/:id"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroCategorias}
          objHeaderConfig={{
            title: "Editar categoria",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/categorias",
          }}
        />
      )}
    />

    <Route
      exact
      path="/editar-evento-da-folha/:idEventoFolha"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroEventosFolha}
          objHeaderConfig={{
            title: "Editar Eventos Folha de Pagamento",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/folhas-de-pagamento",
          }}
        />
      )}
    />

    <Route
      exact
      path="/consulta-evento-da-folha/:idEventoFolha"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={CadastroEventosFolha}
          objHeaderConfig={{
            title: "Consultar Eventos Folha de Pagamento",
            btnClass: "btn-primary",
            btnText: "Voltar",
            url: "/folhas-de-pagamento",
          }}
        />
      )}
    />

    <Route
      exact
      path="/notas-de-entrada"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={NotasDeEntradaRelatorio}
          objHeaderConfig={{
            title: "Notas de Entrada",
          }}
        />
      )}
    />
    <Route
      exact
      path="/notas-de-saida"
      render={(props) => (
        <Container
          {...props}
          callbackAlert={callbackAlert}
          component={NotasDeSaidaRelatorio}
          objHeaderConfig={{
            title: "Notas de Saída",
          }}
        />
      )}
    />

      <Route
          exact
          path="/notas-de-saida"
          render={(props) => (
              <Container
                  {...props}
                  callbackAlert={callbackAlert}
                  component={NotasDeSaidaRelatorio}
                  objHeaderConfig={{
                      title: "Notas de Saída",
                  }}
              />
          )}
      />

      <Route
          path="*"
          render={(props) => (
              <Container
                  {...props}
                  component={PaginaNaoEncontrada}
                  objHeaderConfig={{
                      title: "Página não encontrada",
                  }}
              />
          )}
      />


  </Switch>
);

export default Routes;
