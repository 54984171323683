import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Input from "../../../../components/Input";
import ModalLoginExpirado from "../../../../components/InputFile";
import ButtonComponent from "../../../../components/ButtonComponent";
import SelectComponent from "../../../../components/SelectComponent";
import OverlayLoading from "../../../../components/OverlayLoading";
import CallbackMessage from "../../../../components/CallbackMessage";
import buscaCepService from "../../../../services/buscaCepService";
import verifyFields from "../../../../utils/validateFields";
import { Grid } from "@material-ui/core";
import "./styles.scss";
import { onlyNumbers } from "../../../../utils/strings";
import preAdmissaoService from "../../../../services/preAdmissaoService";
import viacepServices from "../../../../services/viacepServices";

function CadastroEnderecoContato() {
  const history = useHistory();
  const params = useParams();
  const { idFuncionario } = params;
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [cep, setCep] = useState();
  const [rua, setRua] = useState();
  const [complemento, setComplemento] = useState();
  const [bairro, setBairro] = useState();
  const [numEnd, setNumEnd] = useState();
  const [cidade, setCidade] = useState();
  const [estado, setEstado] = useState();
  const [email, setEmail] = useState();
  const [telefone, setTelefone] = useState();
  const [celular, setCelular] = useState();
  const [showMessage, setShowMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const [listEstados, setListEstados] = useState([]);
  const [listCidades, setListCidades] = useState([]);

  async function loadEstados() {
    await viacepServices.selecionaEstado().then((response) => {
      if (response.status === 200) setListEstados(response.data);
    });
  }

  const loadPreAdmissaoEndereco = async () => {
    if(!idFuncionario) {
      return;
    }

    if (idFuncionario) {
      const response = await preAdmissaoService.consultaPreAdmissao(idFuncionario);

      console.log('preaddmissão:::', response.data);
      if (response.status === 200) {        
        const data = response.data;
        //data?.cep !== null && handleCepChange(data?.cep);

        setRua(data?.endereco);
        setBairro(data?.bairro);
        setCep(data?.cep);
        setNumEnd(data?.numero);
        setTelefone(data?.telefone);
        setCelular(data?.celular);
        setEmail(data?.email);
        setEstado(data?.id_uf);
        data?.complemento !== null && setComplemento(data?.complemento);

        await loadCidades(data?.id_uf).then((result) => {
          setCidade(
            result.find((item) => item.id === data.id_cidade)
              ? result.find((item) => item.id === data.id_cidade).id
              : null
          );
        });
      } else {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      }
    }
  };

  async function loadCidades(id) {
    setListCidades([]);
    const response = await viacepServices.selecionaCidade(id);
    if (response.status === 200) {
      setCidade();
      setListCidades(response.data);
      return response.data;
    }
  }
  async function handleCepChange(cep) {
    cep = onlyNumbers(cep);
    setCep(cep);

    if (cep.length === 8) {
      setLoadingCep(true);
      const response = await buscaCepService.getCep(cep);

      const data = response.data;

      if (!data.hasOwnProperty("erro")) {
        let estadoSelected = listEstados.find((item) => item.uf === data.uf);
        setEstado(estadoSelected ? estadoSelected.id : null);
        if (estadoSelected) {
          await loadCidades(estadoSelected.id).then((result) => {
            setCidade(
              result.find((item) => item.nome === data.localidade)
                ? result.find((item) => item.nome === data.localidade).id
                : null
            );
          });
        }
        setRua(data.logradouro);
        setBairro(data.bairro);
      }
      setLoadingCep(false);
    }
  }

  function handleClose() {
    setShowMessage(false);
    if (errors.length === 0) {
      history.push("/pre-admissao");
    }
  }

  function validateFields() {
    setErrors([]);
    const fields = [
      {
        label: "cep",
        value: cep,
      },
      {
        label: "rua",
        value: rua,
      },
      {
        label: "numero",
        value: numEnd,
      },
      {
        label: "bairro",
        value: bairro,
      },
      {
        label: "selectestado",
        value: estado,
      },
      {
        label: "selectcidade",
        value: cidade,
      },
      {
        label: "telefone",
        value: telefone,
      },
    ];
    const fieldsErrors = verifyFields(fields);
    if (fields.length > 0) {
      setErrors(fieldsErrors);
      setShowMessage(true);
    }
    return fieldsErrors.length === 0;
  }

  async function handleSubmit() {
    if (!validateFields()) return;
    setLoading(true);

    await preAdmissaoService
      .cadastraEndereco(
        idFuncionario,
        cep,
        rua,
        numEnd,
        bairro,
        estado,
        cidade,
        telefone,
        email,
        celular,
        complemento
      )
      .then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }

        if (response.status !== 201 && response.status !== 200) {
          if (response.data.error)
            setErrors(Object.values(response.data.error));
          else setErrors(["Ocorreu um erro"]);
        }
        setLoading(false);
        setShowMessage(true);
      });
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadEstados();
      await loadPreAdmissaoEndereco();
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <Grid
            container
            direction="column"
            className="form-cadastro-endereco"
            spacing={2}
          >
            <Grid item>
              <div className="title-cadastro-endereco">Endereço e Contato</div>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="CEP"
                  tipo="cep"
                  required
                  value={cep}
                  loading={loadingCep}
                  handleInputChange={(e) => {
                    handleCepChange(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Input
                  label="Rua"
                  value={rua}
                  required
                  handleInputChange={(e) => {
                    setRua(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Número"
                  tipo="numero"
                  value={numEnd}
                  required
                  handleInputChange={(e) => {
                    setNumEnd(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Complemento"
                  value={complemento}
                  handleInputChange={(e) => {
                    setComplemento(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input
                  label="Bairro"
                  required
                  value={bairro}
                  handleInputChange={(e) => {
                    setBairro(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <SelectComponent
                  styleType="form"
                  list={listEstados}
                  initialValue={
                    listEstados.find((item) => item.id === estado)
                      ? listEstados.find((item) => item.id === estado).nome
                      : ""
                  }
                  value={estado}
                  label="Estado"
                  required
                  callback={async (id) => {
                    setEstado(id);
                    setLoading(true);
                    await loadCidades(id);
                    setLoading(false);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <SelectComponent
                  styleType="form"
                  initialValue={
                    listCidades.find((item) => item.id === cidade)
                      ? listCidades.find((item) => item.id === cidade).nome
                      : ""
                  }
                  list={listCidades}
                  value={cidade}
                  label="Cidade"
                  required
                  callback={(event) => setCidade(event)}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Input
                label="E-mail"
                value={email}
                handleInputChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Input
                  label="Telefone"
                  tipo="telefone"
                  required
                  value={telefone}
                  handleInputChange={(e) => {
                    setTelefone(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Input
                  label="Celular"
                  tipo="celular"
                  value={celular}
                  handleInputChange={(e) => {
                    setCelular(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <span className="required-text">* Campos obrigatórios</span>
            </Grid>
          </Grid>
          {openLoginExpirado && <ModalLoginExpirado open={openLoginExpirado} />}
        </span>
      </div>
      <Grid item container justify="flex-end">
        <ButtonComponent
          text="Salvar"
          className="btn-success zero-margin"
          onClick={handleSubmit}
        />
      </Grid>
      {openLoginExpirado && <ModalLoginExpirado open={openLoginExpirado} />}
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0
            ? "Endereço e contato cadastrados com sucesso!"
            : "Erro!"
          }`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
    </>
  );
}

export default CadastroEnderecoContato;
