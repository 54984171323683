import Axios from "./Axios";

const funcionarioService = {
  getFuncionarios: async ({
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null,
    status = null,
  }) => {
    const result = await Axios.get("/lista-dp-funcionario", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
        status,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  confirmarDesligamentoFuncionario: async (id) => {
    const response = await Axios.post(`desliga-funcionario-dp/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  importarFuncionariosXlsx: async (body) => {
    const response = await Axios.post(`importar-funcionarios`, {
      ...body
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
};
export default funcionarioService;
