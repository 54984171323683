import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import SelectComponent from "../../../components/SelectComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import Input from "../../../components/Input";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

import empresasService from "../../../services/empresasService";
import categoriasCadastroService from "../../../services/categoriasCadastroService";

import "./styles.css";

function CadastrarGrupo() {
  const history = useHistory();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [listEmpresas, setListEmpresas] = useState();
  const [idEmpresaSelected, setIdEmpresaSelected] = useState();
  const [valueInput, setValueInput] = useState("");

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await updateListEmpresas();
      setLoading(false);
    })();
  }, []);

  let updateListEmpresas = async () => {
    let params = {
      page: null,
      orderBy: null,
      orderByType: null,
      perPage: 9999,
      searchTerm: null,
    };
    const response = await empresasService.getEmpresas(
      params.page,
      params.orderBy,
      params.orderByType,
      params.perPage,
      params.searchTerm
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const data = response.data;
    setListEmpresas(
      data.data.map((empresa) => ({
        id: empresa.id_empresa,
        nome: empresa.razao_social,
      }))
    );
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/categorias");
    }
  }

  async function handleClick() {
    if (!idEmpresaSelected && valueInput === "") {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList([
        "Selecione uma empresa.",
        'O campo "Nome do grupo" é obrigatório.',
      ]);
      return;
    }

    if (!idEmpresaSelected) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Selecione uma empresa."]);
      return;
    }

    if (valueInput === "") {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(['O campo "Nome do grupo" é obrigatório.']);
      return;
    }

    const response = await categoriasCadastroService.addGrupo(
      idEmpresaSelected,
      valueInput
    );

    if (response.status === 201 || response.status === 200) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage("Grupo cadastrado com sucesso!");
      setCallbackDuration(2000);
    } else if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
  }
  return (
    <>
      <div className="session-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          {callbackShown && (
            <CallbackMessage
              open={callbackShown}
              type={callbackType}
              handleClose={handleClose}
              message={callbackMessage}
              duration={callbackDuration}
              errorList={callbackErrorList}
            />
          )}
          <form className="session-container-form">
            <div className="input-area">
              <fieldset>
                <SelectComponent
                  styleType="form"
                  label="Empresa"
                  title="Selecione uma empresa"
                  list={listEmpresas}
                  required
                  callback={(element) => setIdEmpresaSelected(element)}
                />
              </fieldset>
              <fieldset className="input-children">
                <Input
                  title=""
                  label="Nome do grupo"
                  tipo="nome"
                  required
                  handleInputChange={(event) =>
                    setValueInput(event.target.value)
                  }
                />
              </fieldset>
              <div className="required-text">
                <span>* Campos obrigatórios</span>
              </div>
            </div>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <ButtonComponent
            text="Salvar"
            className="btn-success"
            onClick={handleClick}
          />
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastrarGrupo;
