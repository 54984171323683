import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import SelectComponent from "../../../components/SelectComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import Input from "../../../components/Input";
import DatePicker from "../../../components/DatePicker";
import InputFile from "../../../components/InputFile";
import Icon from "../../../components/Icon";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import { base64ToFile } from "../../../utils/functions";
import empresasService from "../../../services/empresasService";
import certidoesService from "../../../services/certidoesService";

import "./styles.css";

function EditarCertidao() {
  const history = useHistory();
  const params = useParams();

  const { id } = params;

  const { selectEmpresa } = empresasService;
  const {
    consultaCertidao,
    selecionaCertidao,
    selecionaAbrangencia,
    selecionaOrgaoEmissor,
    selecionaStatus,
    atualizaCertidao,
  } = certidoesService;

  const [listEmpresas, setListEmpresas] = useState([]);
  const [listCertidao, setListCertidao] = useState([]);
  const [listAbrangencia, setListAbrangencia] = useState([]);
  const [listOrgaoEmissor, setListOrgaoEmissor] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [certidao, setCertidao] = useState({});
  const [idEmpresaSelected, setIdEmpresaSelected] = useState();
  const [anexo, setAnexo] = useState();
  const [dataVencimento, setDataVencimento] = useState("");
  const [dataEmissao, setDataEmissao] = useState("");
  const [statusCertidao, setStatusCertidao] = useState();
  const [abrangencia, setAbrangencia] = useState();
  const [orgaoEmissor, setOrgaoEmissor] = useState();
  const [tipoCertidao, setTipoCertidao] = useState();

  const [initEmpresa, setInitEmpresa] = useState();
  const [initCertidao, setInitCertidao] = useState();
  const [initAbrangencia, setInitAbrangencia] = useState();
  const [initOrgaoEmissor, setInitOrgaoEmissor] = useState();
  const [initStatus, setInitStatus] = useState();

  const [loading, setLoading] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackErrors, setCallbackErrorList] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [duration, setCallbackDuration] = useState(6000);

  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await getCertidao(id);
      getCertidaoList();
      getAbrangenciaList();
      getOrgaoEmissorList();
      getStatusList();
      await updateListEmpresas();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async function () {
      const {
        arquivo,
        data_emissao,
        data_vencimento,
        id_abrangencia,
        id_empresa,
        id_orgao_emissor,
        id_status,
        id_tipo_certidao,
      } = certidao;

      if (arquivo !== "" && arquivo !== null) {
        setAnexo(arquivo);
      }
      if (data_emissao !== "" && data_emissao !== null) {
        setDataEmissao(data_emissao);
      }
      if (data_vencimento !== "" && data_vencimento !== null) {
        setDataVencimento(data_vencimento);
      }
      if (id_abrangencia !== null) {
        const selectedAbrangencia = listAbrangencia.find(
          (item) => item.id === id_abrangencia
        );
        if (selectedAbrangencia !== undefined) {
          setAbrangencia(selectedAbrangencia.id);
          setInitAbrangencia(selectedAbrangencia.nome);
        }
      }
      if (id_empresa !== null) {
        const selectedEmpresa = listEmpresas.find(
          (item) => item.id === id_empresa
        );
        if (selectedEmpresa !== undefined) {
          setIdEmpresaSelected(selectedEmpresa.id);
          setInitEmpresa(selectedEmpresa.nome);
        }
      }
      if (id_orgao_emissor !== null) {
        const selectedOrgaoEmissor = listOrgaoEmissor.find(
          (item) => item.id === id_orgao_emissor
        );
        if (selectedOrgaoEmissor !== undefined) {
          setOrgaoEmissor(selectedOrgaoEmissor.id);
          setInitOrgaoEmissor(selectedOrgaoEmissor.nome);
        }
      }
      if (id_status !== null) {
        const selectedStatus = listStatus.find((item) => item.id === id_status);
        if (selectedStatus !== undefined) {
          setStatusCertidao(selectedStatus.id);
          setInitStatus(selectedStatus.nome);
        }
      }
      if (id_tipo_certidao !== null) {
        const selectedTipoCertidao = listCertidao.find(
          (item) => item.id === id_tipo_certidao
        );
        if (selectedTipoCertidao !== undefined) {
          setTipoCertidao(selectedTipoCertidao.id);
          setInitCertidao(selectedTipoCertidao.nome);
        }
      }
    })();
  }, [
    certidao,
    listAbrangencia,
    listEmpresas,
    listOrgaoEmissor,
    listStatus,
    listCertidao,
  ]);

  let getCertidao = async (id) => {
    const response = await consultaCertidao(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setCertidao(response.data);
    console.log(response.data);
  };

  let getCertidaoList = async () => {
    const response = await selecionaCertidao();
    setListCertidao(response.data);
  };

  let getAbrangenciaList = async () => {
    const response = await selecionaAbrangencia();
    setListAbrangencia(response.data);
  };

  let getOrgaoEmissorList = async () => {
    const response = await selecionaOrgaoEmissor();
    setListOrgaoEmissor(response.data);
  };

  let getStatusList = async () => {
    const response = await selecionaStatus();
    setListStatus(response.data);
  };

  let updateListEmpresas = async () => {
    const response = await selectEmpresa();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListEmpresas(response.data);
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
      setShowMessage(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
      setShowMessage(false);
    }
    if (callbackType === "success") {
      history.push("/certidoes");
    }
  }

  async function handleErrors(errors) {
    const callbackErrors = errors.map((error) => {
      switch (error) {
        case "id_empresa":
          return "Nenhuma empresa selecionada.";

        case "id_tipo_certidao":
          return "Nenhum tipo de certidão selecionado.";

        case "id_orgao_emissor":
          return "Nenhum órgão emissor selecionado.";

        case "id_abrangencia":
          return "Abrangência não selecionada.";

        case "id_status":
          return "Status não selecionado.";

        case "data_emissao":
          return 'O campo "Data emissão" é obrigatório.';

        case "data_vencimento":
          return 'O campo "Data vencimento" é obrigatório.';

        case "arquivo":
          return 'O campo "Anexo" é obrigatório';

        default:
          break;
      }
    });

    setCallbackShown(true);
    setCallbackType("error");
    setCallbackMessage("Erro!");
    setCallbackErrorList(callbackErrors);
    return;
  }

  function formatDate(date, toFormat) {
    if (date !== null && date !== undefined && date !== "") {
      if (toFormat === "YYYY-MM-DD") {
        const splittedDate = date.split("/");
        const newDate = `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
        return newDate;
      }
      if (toFormat === "DD/MM/YYYY") {
        const splittedDate = date.split("-");
        const newDate = `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`;
        return newDate;
      }
    }
  }

  function handleSubmit() {
    if (!validateFields()) return;

    setLoading(true);
    (async () => {
      const response = await atualizaCertidao(
        id,
        idEmpresaSelected,
        anexo,
        abrangencia,
        orgaoEmissor,
        statusCertidao,
        tipoCertidao,
        dataEmissao,
        dataVencimento
      );
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status !== 200) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackDuration(3000);
        setCallbackMessage("Erro!");
        if (response.data.error)
          setCallbackErrorList(Object.values(response.data.error));
        else setCallbackErrorList(["Ocorreu um erro"]);
        return;
      } else {
        setCallbackShown(true);
        setCallbackType("success");
        setCallbackDuration(2000);
        setCallbackMessage("Certidão editada com sucesso.");
      }
      setLoading(false);
    })();
  }

  function verifyField(field, callback) {
    if (field === "" || field === null || field === undefined || field === []) {
      callback();
    }
  }

  function validateFields() {
    let list = [];
    verifyField(tipoCertidao, () =>
      list.push('O campo "Tipo de certidão" não foi selecionado.')
    );
    verifyField(orgaoEmissor, () =>
      list.push('O campo "Órgão Emissor" é obrigatório.')
    );
    verifyField(abrangencia, () =>
      list.push('O campo "Abrangência" não foi selecionado.')
    );
    verifyField(statusCertidao, () =>
      list.push('O campo "Status certidão" não foi selecionado.')
    );
    verifyField(dataEmissao, () =>
      list.push('O campo "Data emissão" é obrigatório.')
    );
    verifyField(idEmpresaSelected, () =>
      list.push('O campo "Empresa" não foi selecionado.')
    );
    verifyField(anexo, () => list.push('O campo "Anexo" é obrigatório.'));

    setErrors(list);
    if (list.length > 0) setShowMessage(true);
    return list.length === 0;
  }

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          {callbackShown && (
            <CallbackMessage
              type={callbackType}
              open={callbackShown}
              message={callbackMessage}
              errorList={callbackErrors}
              handleClose={handleClose}
              duration={duration}
            />
          )}
          <form className="session-container-form">
            <div className="cadastro-certidao-form-container">
              <fieldset className="cadastro-certidao-certidao-select">
                <SelectComponent
                  styleType="form"
                  label="Tipo de certidão"
                  title="Selecione um tipo"
                  list={listCertidao}
                  initialValue={initCertidao}
                  required
                  callback={(element) => setTipoCertidao(element)}
                />
              </fieldset>
              <fieldset className="cadastro-certidao-orgaoemissor-select">
                <SelectComponent
                  styleType="form"
                  label="Órgão emissor"
                  title="Selecione um órgão emissor"
                  required
                  list={listOrgaoEmissor}
                  initialValue={initOrgaoEmissor}
                  callback={(element) => setOrgaoEmissor(element)}
                />
              </fieldset>
              <fieldset className="cadastro-certidao-abrangencia-select">
                <SelectComponent
                  styleType="form"
                  label="Abrangência"
                  title="Selecione a abrangência"
                  required
                  list={listAbrangencia}
                  initialValue={initAbrangencia}
                  callback={(element) => setAbrangencia(element)}
                />
              </fieldset>
              <fieldset className="cadastro-certidao-status-select">
                <SelectComponent
                  styleType="form"
                  label="Status certidão"
                  title="Selecione o status"
                  required
                  list={listStatus}
                  initialValue={initStatus}
                  callback={(element) => setStatusCertidao(element)}
                />
              </fieldset>
              <fieldset className="cadastro-certidao-emissao-input">
                <DatePicker
                  label="Data emissão"
                  initDate={dataEmissao}
                  handleChange={(value) => setDataEmissao(value)}
                />
              </fieldset>
              <fieldset className="cadastro-certidao-vencimento-input">
                <DatePicker
                  label="Data vencimento"
                  initDate={dataVencimento}
                  handleChange={(value) => setDataVencimento(value)}
                />
              </fieldset>
              <fieldset className="cadastro-certidao-empresa-select">
                <SelectComponent
                  styleType="form"
                  label="Empresa"
                  title="Selecione uma empresa"
                  list={listEmpresas}
                  initialValue={initEmpresa}
                  required
                  callback={(element) => setIdEmpresaSelected(element)}
                />
              </fieldset>
              {anexo ? (
                <>
                  <fieldset className="cadastro-certidao-anexo-visualizar">
                    <div className="label">Anexo *</div>
                  </fieldset>
                  <div className="cadastro-certidao-anexo-botoes">
                    <ButtonComponent
                      className="primary-outline text-button"
                      type="button"
                      text="Visualizar arquivo"
                      icone="download"
                      iconeColor="#812990"
                      iconeSize="18"
                      onClick={() => base64ToFile(anexo, "anexo")}
                    />
                    <ButtonComponent
                      className="primary-outline icon-button"
                      type="button"
                      icone="trash"
                      iconeColor="#812990"
                      iconeSize="16"
                      onClick={() => setAnexo(null)}
                    />
                  </div>
                  <div />
                </>
              ) : (
                <fieldset className="cadastro-certidao-anexo-input">
                  <InputFile
                    title="Anexo *"
                    onChange={(file) => setAnexo(file)}
                  />
                </fieldset>
              )}
              <fieldset className="required-text">
                <span>* Campos obrigatórios</span>
              </fieldset>
            </div>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <ButtonComponent
            text="Salvar"
            type="button"
            className="btn-success"
            onClick={() => handleSubmit()}
          />
        </span>
      </div>
      {showMessage && (
        <CallbackMessage
          open={showMessage}
          type={`${errors.length === 0 ? "success" : "error"}`}
          message={`${
            errors.length === 0 ? "Certidão editada com sucesso!" : "Erro!"
          }`}
          errorList={errors}
          handleClose={handleClose}
          duration={errors.length === 0 ? 2000 : 6000}
        />
      )}
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default EditarCertidao;
