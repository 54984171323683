import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { onlyNumbers } from "../../../utils/strings.js";
import validateFields from "../../../utils/validateFields";

import SelectComponent from "../../../components/SelectComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import Input from "../../../components/Input";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import OverlayLoading from "../../../components/OverlayLoading";
import Stepper from "../../../components/Stepper";

import { withStyles } from "@material-ui/core/styles";
import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";

import { Grid, Switch, Divider } from "@material-ui/core";

import empresasService from "../../../services/empresasService";

import buscaCnpjService from "../../../services/buscaCnpjService";
import buscaCepService from "../../../services/buscaCepService";
import indicadorIEService from "../../../services/indicadorIEService";
import cadastroClientesService from "../../../services/cadastroClientesService";

import "./styles.scss";
import selectService from "../../../services/selectServices.js";
const { selecionaCategoriaPadrao } = selectService;
import useCompany from "../../../hooks/useCompany.js";
import recebimentosService from "../../../services/recebimentosService";

function CadastroClientes() {
  const history = useHistory();
  const {
    companyList: listEmpresas,
    selectedCompany: idEmpresaSelected,
    setSelectedCompany: setIdEmpresaSelected,
    getEmpresaPagina,
  } = useCompany("clientes");

  const [listIndicadorIe, setListIndicadorIe] = useState([]);
  const [listCategoriaPadrao, setListCategoriaPadrao] = useState([]);
  const [idEmpresaSelectedRS, setIdEmpresaSelectedRS] = useState();
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const params = useParams();
  let { id: idParams } = params;

  const [id, setId] = useState(idParams);

  const IntId = parseInt(id, 10);
  const isEdit = Number.isInteger(IntId);

  const { selectEmpresa } = empresasService;
  const { selecionaCategoriaRecebimento } = recebimentosService;
  const [loadingInputCnpj, setLoadingInputCnpj] = useState(false);
  const [loadingInputCep, setLoadingInputCep] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isPJ, setIsPJ] = useState(true);
  const [categoriaPadrao, setCategoriaPadrao] = useState(null);

  // INICIO CAMPOS PARA O SERVICE
  const [cnpj, setCnpj] = useState("");
  const [cpf, setCpf] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numEnd, setNumEnd] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nome_fantasia, setNome_fantasia] = useState("");
  const [site, setSite] = useState("");
  const [cod_indicador_ie, setCod_indicador_ie] = useState(null);
  const [inscricao_municipal, setInscricao_municipal] = useState("");
  const [inscricao_estadual, setInscricao_estadual] = useState("");
  const [observacao, setObservacao] = useState("");
  // contato
  const objContato = {
    nome: "",
    email: "",
    telefone: "",
    celular: "",
  };
  const [formContato, setFormContato] = useState([objContato]);
  const handleDuplicateContato = () =>
    setFormContato([...formContato, objContato]);
  const handleRemoveContato = (index) => {
    formContato.splice(index, 1);
    setFormContato([...formContato]);
  };
  // FIM CAMPOS PARA O SERVICE

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const CustomSwitch = withStyles({
    switchBase: {
      color: "#812990",
      "&$checked": {
        color: "#812990",
      },
      "&$checked + $track": {
        backgroundColor: "#812990",
      },
    },
    checked: {},
    track: {},
  })(Switch);


  const loadCategoriaPadrao = useCallback(async () => {
    const response = await selecionaCategoriaPadrao();
    if (response.status === 200) {
      const formattedData = response?.data?.map((item) => ({
        ...item,
        id: item?.id_categoria_padrao,
      }));
      setListCategoriaPadrao(formattedData);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selecionaCategoriaPadrao]);

  const updateListIndicadorIe = async () => {
    const response = await indicadorIEService.getIndicadorIe();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListIndicadorIe(response.data);
  };
  async function handleCnpjChange(_cnpj) {
    _cnpj = onlyNumbers(_cnpj);
    setCnpj(_cnpj);
    if (_cnpj.length === 14) {
      setCallbackShown(false);
      setCallbackErrorList([]);
      setLoadingInputCnpj(true);
      const response = await buscaCnpjService.getCnpjReceita(_cnpj);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoadingInputCnpj(false);
        return;
      }
      const data = response.data;
      if (response.status === 201) {
        if (data.status === "ERROR") {
          setCallbackErrorList(["CNPJ inválido"]);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setLoadingInputCnpj(false);
          setRazaoSocial("");
          setCep(onlyNumbers(""));
          setCidade("");
          setEndereco("");
          setBairro("");
          setNumEnd("");
          setEstado("");
          setComplemento("");
          setNome_fantasia("");
          return;
        }
        if (data) {
          if (data.situacao === "ATIVA") {
            setRazaoSocial(data.nome);
            setCep(onlyNumbers(data.cep));
            setCidade(data.municipio);
            setEndereco(data.logradouro);
            setBairro(data.bairro);
            setNumEnd(data.numero);
            setEstado(data.uf);
            setComplemento(data.complemento);
            setNome_fantasia(data.fantasia);
          }
        }
        setLoadingInputCnpj(false);
      } else if (response.status === 400) {
        console.log(response);
        if (response.data.error.includes("Too Many Requests")) {
          setLoadingInputCnpj(false);
          setLoading(false);
          setCallbackErrorList([
            "Ocorreu um excesso de consultas. Espere 1 minuto e tente novamente.",
          ]);
          setCallbackDuration(4000);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackShown(true);
        } else if (typeof response.data.error === "string") {
          setLoadingInputCnpj(false);
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackDuration(4000);
          setCallbackErrorList([response.data.error]);
        } else if (typeof response.data.error === "object") {
          setLoadingInputCnpj(false);
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackDuration(4000);
          setCallbackMessage("Erro!");
          setCallbackErrorList(
            Object.values(response?.data?.error).flat(Infinity)
          );
        }
      }
    }
    // else {
    //   alert("Cnpj inválido");
    //   setCnpj("");
    // }
  }
  function handleCpfChange(_cpf) {
    _cpf = onlyNumbers(_cpf);
    setCpf(_cpf);
  }

  async function handleCepChange(cep) {
    cep = onlyNumbers(cep);
    setCep(cep);

    if (cep.length === 8) {
      setLoadingInputCep(true);
      const response = await buscaCepService.getCep(cep);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoadingInputCep(false);
        return;
      }
      const data = response.data;

      if (data) {
        setCidade(data.localidade);
        setEstado(data.uf);
        setEndereco(data.logradouro);
        setBairro(data.bairro);
        setNumEnd("");
      }
      setLoadingInputCep(false);
    }
  }
  const handleNextStep = () => {
    let hasErrors = false;
    if (!isPJ) {
      if (activeStep === 0) {
        const stepZeroFields = [
          {
            label: "empresa",
            value: idEmpresaSelected,
          },
          {
            label: "cpf",
            value: cpf,
          },
          {
            label: "nome",
            value: razaoSocial,
          },
          {
            label: "categoriapadrao",
            value: categoriaPadrao,
          },
        ];
        const stepZeroErrors = validateFields(stepZeroFields);

        if (stepZeroErrors.length !== 0) {
          hasErrors = true;
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackErrorList(stepZeroErrors);
          return;
        }
      }
    }
    if (isPJ) {
      if (activeStep === 0) {
        const stepZeroFields = [
          {
            label: "empresa",
            value: idEmpresaSelected,
          },
          {
            label: "cnpj",
            value: cnpj,
          },
          {
            label: "razaosocial",
            value: razaoSocial,
          },
          {
            label: "indicadordeie",
            value: cod_indicador_ie,
          },
          {
            label: "inscricaoestadual",
            value: inscricao_estadual,
          },
          {
            label: "inscricaomunicipal",
            value: inscricao_municipal,
          },
          {
            label: "categoriapadrao",
            value: categoriaPadrao,
          },
        ];
        const stepZeroErrors = validateFields(stepZeroFields);

        if (stepZeroErrors.length !== 0) {
          hasErrors = true;
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackErrorList(stepZeroErrors);
          return;
        }
      }
    }

    if (hasErrors) return;

    setActiveStep(activeStep + 1);
  };
  const handleBack = () => setActiveStep(activeStep - 1);

  const getAtualFormData = {
    id_empresa: idEmpresaSelected,
    cnpj_cpf: isPJ ? "pj" : "pf",
    cnpj: cnpj,
    cpf: cpf,
    razaoSocial: razaoSocial,
    nome_fantasia: nome_fantasia,
    observacao: observacao,
    endereco: endereco,
    numEnd: numEnd,
    complemento: complemento,
    bairro: bairro,
    cep: cep,
    cidade: cidade,
    estado: estado,

    site: site,

    cod_indicador_ie: cod_indicador_ie,
    inscricao_municipal: inscricao_municipal,
    inscricao_estadual: inscricao_estadual,

    contato: formContato, //.length > 0 ? formContato.slice(1) : formContato,
    id_categoria_padrao: categoriaPadrao,
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    let hasErrors = false;
    if (activeStep === 2) {
      const stepTwoFields = [
        {
          label: "cep",
          value: cep,
        },
        {
          label: "endereco",
          value: endereco,
        },
        {
          label: "bairro",
          value: bairro,
        },
        {
          label: "numero",
          value: numEnd,
        },
        {
          label: "estado",
          value: estado,
        },
        {
          label: "cidade",
          value: cidade,
        },
      ];
      const stepTwoErrors = validateFields(stepTwoFields);

      if (stepTwoErrors.length !== 0) {
        hasErrors = true;
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(stepTwoErrors);
        return;
      }
    }

    if (hasErrors) return;

    let success = false;
    let status = 0;
    let error = "";

    console.log("getAtualFormData :: ", getAtualFormData);

    setLoading(true);
    if (isEdit) {
      const response = await cadastroClientesService.atualizaCliente(
        id,
        getAtualFormData
      );
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      success = response.success;
      status = response.status;
      if (response.data.error) error = Object.values(response.data.error);
    } else {
      const response = await cadastroClientesService.cadastrarCliente(
        getAtualFormData
      );
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      success = response.success;
      status = response.status;
      if (response.data.error) error = Object.values(response.data.error);
    }

    setLoading(false);

    if (success || status === 201) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage("Cliente salvo com sucesso!");
      setCallbackDuration(3000);
    }

    if (error) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackErrorList(error);
      setCallbackMessage("Erro!");
    }
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/clientes-cadastrados");
    }
  }

  useEffect(() => {
    (async function () {
      setLoading(true);
      await updateListIndicadorIe();
      await loadCategoriaPadrao();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (isEdit) {
      setLoading(true);
      async function getClienteRequest() {
        const response = await cadastroClientesService.consultaCliente(id);

        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        } else if (response.status === 400) {
          setCallbackType("error");
          setCallbackMessage(response.data.msg || "Erro!");
          setCallbackErrorList(
            [response.data.error] || ["Erro ao consultar cliente!"]
          );
          setLoading(false);
          setCallbackShown(true);
          setId(null);
          return;
        }

        const data = response.data;

        if (data) {
          if (data.cnpj_cpf.length === 14) {
            setCnpj(data.cnpj_cpf);
            setCpf("");
            setIsPJ(true);
          }

          if (data.cnpj_cpf.length === 11) {
            setCpf(data.cnpj_cpf);
            setCnpj("");
            setIsPJ(false);
          }

          setIdEmpresaSelected(data.id_empresa);
          const empresaGetRazaoSocial = await empresasService.consultaEmpresa(
            data.id_empresa
          );

          if (empresaGetRazaoSocial && empresaGetRazaoSocial.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          }
          setIdEmpresaSelectedRS(empresaGetRazaoSocial.data.razao_social);

          setRazaoSocial(data.nome_razao_social);
          setBairro(data.bairro);
          setCep(data.cep);
          setEndereco(data.endereco);
          setNumEnd(data.numero);
          setCidade(data.cidade);
          setEstado(data.uf);
          setCategoriaPadrao(data?.id_categoria_padrao);

          setComplemento(data.complemento);
          setNome_fantasia(data.nome_fantasia);
          setSite(data.site);
          setInscricao_municipal(data.inscricao_municipal);
          setInscricao_estadual(data.inscricao_estadual);

          setCod_indicador_ie(data.cod_indicador_ie);
          setObservacao(data?.observacao);
          setFormContato(data.contatos);
        }
        setLoading(false);
      }
      getClienteRequest();
    } else {
      const empresaStorage = getEmpresaPagina("clientes");
      if (empresaStorage) {
        setIdEmpresaSelected(empresaStorage);
      }
    }
  }, [isEdit, id]);

  if (loading) return <OverlayLoading />;

  return (
    <>
      <Grid container className="default-forms-container">
        <Grid item xs={12} md={2}>
          <Stepper
            activeStep={activeStep}
            arraySteps={[
              "DADOS DE IDENTIFICAÇÃO",
              "DADOS DE CONTATO",
              "ENDEREÇO",
            ]}
            useActiveStep={true}
          />
        </Grid>

        <Grid item xs={10}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={8} style={{ marginTop: 45 }}>
              <form onSubmit={handleFormSubmit} noValidate>
                {/* STEP0 PESSOA JURIDICA E PESSOA FISICA */}
                <Grid container spacing={2}>
                  {/* swicht pj-pf */}
                  {activeStep === 0 && (
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                      }}
                    >
                      <Grid item>
                        {!isEdit && (
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            justifyContent="center"
                            spacing={1}
                            style={{
                              backgroundColor: "#ECECEC",
                              width: 115,
                              height: 34,
                              padding: 0,
                              borderRadius: 5,
                              marginBottom: 10,
                              alignContent: "center",
                            }}
                          >
                            <Grid item>PF</Grid>
                            <div>
                              <CustomSwitch
                                size="small"
                                checked={isPJ}
                                onChange={() => setIsPJ(!isPJ)}
                              />
                            </div>
                            <Grid item>PJ</Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs="auto"
                        style={{
                          alignContent: "center",
                          marginTop: 3,
                        }}
                      >
                        <span className="text-tipo-cliente-for">
                          {isPJ ? "PESSOA JURÍDICA" : "PESSOA FÍSICA"}
                        </span>
                      </Grid>
                    </Grid>
                  )}

                  {/* EMPRESA */}
                  {activeStep === 0 && (
                    <Grid item xs={12}>
                      <SelectComponent
                        styleType="form"
                        label="Empresa"
                        title="Selecione uma empresa"
                        list={listEmpresas}
                        initialValue={
                          listEmpresas?.find(
                            (item) => item.id === idEmpresaSelected
                          )?.nome || null
                        }
                        required
                        callback={(id) => setIdEmpresaSelected(id)}
                      />
                    </Grid>
                  )}

                  {/* PJ */}
                  {activeStep === 0 && isPJ && (
                    <>
                      <Grid item xs={12}>
                        <Input
                          label="CNPJ"
                          tipo="cnpj"
                          required
                          nome="cnpj"
                          value={cnpj}
                          loading={loadingInputCnpj}
                          handleInputChange={(e) => {
                            handleCnpjChange(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          label="Razão Social"
                          nome="razaoSocial"
                          value={razaoSocial}
                          required
                          handleInputChange={(e) => {
                            setRazaoSocial(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          label="Nome Fantasia"
                          nome="nome_fantasia"
                          value={nome_fantasia}
                          handleInputChange={(e) => {
                            setNome_fantasia(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectComponent
                          styleType="form"
                          label="Indicador de IE"
                          title="Selecione"
                          list={listIndicadorIe}
                          initialValue={
                            listIndicadorIe[cod_indicador_ie]
                              ? listIndicadorIe[cod_indicador_ie - 1].nome
                              : cod_indicador_ie
                          }
                          required
                          callback={(id) => setCod_indicador_ie(id)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Inscrição Estadual"
                          required
                          nome="inscricao_estadual"
                          value={inscricao_estadual}
                          handleInputChange={(e) => {
                            setInscricao_estadual(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Inscrição Municipal"
                          required
                          nome="inscricao_municipal"
                          value={inscricao_municipal}
                          handleInputChange={(e) => {
                            setInscricao_municipal(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <SelectComponent
                          styleType="form"
                          label="Categoria"
                          title="Selecione uma categoria"
                          required
                          initialValue={
                            categoriaPadrao !== undefined
                              ? listCategoriaPadrao.find(
                                  (item) => item.id === categoriaPadrao
                                )?.nome
                              : ""
                          }
                          list={listCategoriaPadrao}
                          callback={(id) => {
                            setCategoriaPadrao(id);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          label="Web Site"
                          nome="site"
                          value={site}
                          handleInputChange={(e) => {
                            setSite(e.target.value);
                          }}
                        />
                      </Grid>

                      {/* <Grid item xs={12}>
                        <Textarea
                          label='Observação'
                          value={observacao}
                          handleInputChange={event => setObservacao(event.target.value)}
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <span className="required-text">
                          * Campos obrigatórios
                        </span>
                      </Grid>
                    </>
                  )}

                  {/* PF */}
                  {activeStep === 0 && !isPJ && (
                    <>
                      <Grid item xs={12}>
                        <Input
                          label="CPF"
                          nome="cpf"
                          tipo="cpf"
                          required
                          value={cpf}
                          handleInputChange={(e) => {
                            handleCpfChange(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          label="Nome"
                          nome="razaoSocialcpf"
                          value={razaoSocial}
                          required
                          handleInputChange={(e) => {
                            setRazaoSocial(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectComponent
                          styleType="form"
                          label="Categoria"
                          title="Selecione uma categoria"
                          required
                          initialValue={
                            categoriaPadrao !== undefined
                              ? listCategoriaPadrao.find(
                                  (item) => item.id === categoriaPadrao
                                )?.nome
                              : ""
                          }
                          list={listCategoriaPadrao}
                          callback={(id) => {
                            setCategoriaPadrao(id);
                          }}
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
                        <Textarea
                          label='Observação'
                          value={observacao}
                          handleInputChange={event => setObservacao(event.target.value)}
                        />
                      </Grid> */}
                    </>
                  )}
                </Grid>

                {/* STEP 1 CONTATO */}
                {activeStep === 1 && (
                  <>
                    {formContato.map((c, index) => (
                      <React.Fragment key={index}>
                        <Grid container spacing={2}>
                          {index > 0 && (
                            <Grid item xs={12} style={{ marginTop: 20 }}>
                              <Divider />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Input
                              label="Nome Contato"
                              nome="nomec"
                              value={c.nome}
                              handleInputChange={(e) => {
                                let list = [...formContato];
                                list[index].nome = e.target.value;
                                setFormContato(list);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              label="E-mail"
                              tipo="email"
                              nome="emailc"
                              value={c.email}
                              handleInputChange={(e) => {
                                let list = [...formContato];
                                list[index].email = e.target.value;
                                setFormContato(list);
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Input
                              label="Telefone"
                              tipo="telefone"
                              nome="telefonec"
                              value={c.telefone}
                              handleInputChange={(e) => {
                                let list = [...formContato];
                                list[index].telefone = onlyNumbers(
                                  e.target.value
                                );
                                setFormContato(list);
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Input
                              label="Celular"
                              tipo="celular"
                              nome="celularc"
                              value={c.celular}
                              handleInputChange={(e) => {
                                let list = [...formContato];
                                list[index].celular = onlyNumbers(
                                  e.target.value
                                );
                                setFormContato(list);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <div className="directBtn left">
                              {index > 0 && (
                                <>
                                  <ButtonComponent
                                    icon={AiOutlineUserDelete}
                                    text="Remover"
                                    onClick={() => handleRemoveContato(index)}
                                    className="secondary-outline"
                                  />
                                </>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}
                    <div className="directBtn">
                      <ButtonComponent
                        icon={AiOutlineUserAdd}
                        text="Adicionar"
                        onClick={() => handleDuplicateContato()}
                        className="success-outline"
                      />
                    </div>
                  </>
                )}

                {/* STEP 2 ENDEREÇO */}
                {activeStep === 2 && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Input
                          label="CEP"
                          tipo="cep"
                          required
                          nome="cep"
                          value={cep}
                          loading={loadingInputCep}
                          handleInputChange={(e) => {
                            handleCepChange(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Endereço"
                          nome="endereco"
                          value={endereco}
                          required
                          handleInputChange={(e) => {
                            setEndereco(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Bairro"
                          nome="bairro"
                          value={bairro}
                          required
                          handleInputChange={(e) => {
                            setBairro(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Número"
                          tipo="numero"
                          nome="numEnd"
                          value={numEnd}
                          required
                          handleInputChange={(e) => {
                            setNumEnd(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Complemento"
                          nome="complemento"
                          value={complemento}
                          handleInputChange={(e) => {
                            setComplemento(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Estado"
                          nome="estado"
                          value={estado}
                          required
                          handleInputChange={(e) => {
                            setEstado(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Cidade"
                          nome="cidade"
                          value={cidade}
                          required
                          handleInputChange={(e) => {
                            setCidade(e.target.value);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <span className="required-text">
                          * Campos obrigatórios
                        </span>
                      </Grid>
                    </Grid>
                  </>
                )}

                {/* NAVIGATION BUTTONS */}
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{
                    marginTop: 40,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {activeStep > 0 && (
                    <>
                      <Grid item style={{ marginRight: 10 }}>
                        <ButtonComponent
                          text="Voltar"
                          className="btn-secondary"
                          onClick={handleBack}
                        />
                      </Grid>
                    </>
                  )}
                  {activeStep <= 1 && (
                    <>
                      <Grid item>
                        <ButtonComponent
                          className="btn-success"
                          text="Avançar"
                          onClick={handleNextStep}
                        />
                      </Grid>
                    </>
                  )}
                  {activeStep >= 2 && (
                    <>
                      <Grid item>
                        <ButtonComponent
                          className="btn-success"
                          text="Finalizar"
                          type="submit"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
        {loading && <OverlayLoading />}
        {callbackShown && (
          <CallbackMessage
            open={callbackShown}
            type={callbackType}
            handleClose={handleClose}
            message={callbackMessage}
            duration={callbackDuration}
            errorList={callbackErrorList}
          />
        )}
      </Grid>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastroClientes;
