import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import classes from "./styles.module.scss";
import Icon from "../Icon";
import "./styles.scss";

function AutocompleteInput({
  label = "",
  required = false,
  data = [],
  initValue = [],
  getOptionLabel = () => {},
  handleSelectedValues = () => {},
  getOptionSelected = () => {},
  page,
  noOptionsText = null,
  placeholder = null,
}) {
  const headerInput = () => (
    <>
      <label className={classes.inputLabel}>
        {label}
        <span className={classes.requiredItem}>{required && "*"}</span>
      </label>
    </>
  );

  const [value, setValue] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    console.log({ initValue, data });
    if (initValue.length !== 0 && data.length !== 0) {
      let initData = [];

      initValue.map((initItem) => {
        data.filter((item) => {
          if (item.id === initItem.id) {
            initData.push(item);
          }
          return;
        });
      });
      setValue(initData);
    } else if (
      page === "usuarios" ||
      page === "dre_orcado_realizado" ||
      (page === "formas-pagamento" && initValue.length === 0)
    ) {
      setValue([]);
    }
  }, [initValue, data]);
  return (
    <>
      {headerInput()}
      <Autocomplete
        id="tags-outlined"
        popupIcon={<Icon name="arrow-down" />}
        multiple
        value={value}
        onChange={(event, newValue) => {
          handleSelectedValues(newValue);
          setValue(newValue);
        }}
        getOptionSelected={getOptionSelected}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={data}
        getOptionLabel={getOptionLabel}
        filterSelectedOptions
        noOptionsText={noOptionsText}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={
              value?.length === 0 && placeholder ? placeholder : undefined
            }
            className={classes.input}
          />
        )}
      />
    </>
  );
}

export default AutocompleteInput;
