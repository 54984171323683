import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import Table from "../../../components/TableComponent";
import { Grid } from "@material-ui/core";
import Select from "../../../components/SelectComponent";
import Button from "../../../components/ButtonComponent";
import "./styles.css";
import empresasServices from "../../../services/empresasService";
import integracoesService from "../../../services/integracoesService";

import { numberToCurrency } from "../../../utils/functions";
import DateTimePicker from "../../../components/DateTimePicker";
import validateFields from "../../../utils/validateFields";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

const CadastroIntegracao = () => {

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { listaIntegracoesDisponiveis, cadastrarIntegracao } = integracoesService;

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [empresaSelecionada, setEmpresaSelecionada] = useState("");
  const [listEmpresas, setListEmpresas] = useState([]);
  const { selectEmpresa } = empresasServices;

  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [checkedPermissoesList, setCheckedPermissoesList] = useState([]);
  const [permissoesList, setPermissoesList] = useState([]);

  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);

  const handleClose = (event, reason) => {
    console.log(reason);
    setCallbackShown(false);
    if (callbackType === 'success') {
      history.push('/lista-integracoes')
    }
  };

  const [listRequest, setListRequest] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id" },
      { columnName: "Grupo", dataRef: "nome_grupo" },
      { columnName: "Integração", dataRef: "nome" },
      { columnName: "Opções", dataRef: "checkbox_integracao" },
    ],
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      checkBoxOptions: true,
      detailTitle: true,
    },
    tableOptions: {
      noSearch: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  const optionsCheckBox = [
    {
      icon: "check-double",
      label: "Marcar todos",
      action: "marcar-todos"
    },
    {
      icon: "close",
      label: "Desmarcar todos",
      action: "desmarcar-todos"
    },
  ]

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await listaIntegracoesDisponiveis(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
    );
    console.log(result);

    if (result?.status === 200) {
      if (result?.data?.hasOwnProperty("data")) {
        const { data } = result;
        let newPermissions = [];
        console.log(data.data);
        const newData = data.data.map(item => {
          newPermissions.push({
            id_permissao: item.id,
            permissao: false
          })

          return item
        });

        const newResult = {
          ...data,
          data: newData,
        };
        setListRequest(newResult);
        console.log(newPermissions);
        setPermissoesList(newPermissions);

        const checkedPermissoes = newPermissions.map((item) => item.permissao);
        setCheckedPermissoesList(checkedPermissoes)
      } else {
        let newPermissions = [];
        const newData = result?.data?.map((item) => {
          newPermissions.push({
            id_permissao: item.id,
            permissao: false
          })

          return {
            ...item,
            valor_unitario_comercial: numberToCurrency(
              item.valor_unitario_comercial
            ),
            status: Number(item.bloqueado_inativo) === 1 ? "Desativado" : "Ativo",
          };
        });

        const newResult = {
          ...result,
          data: newData,
        };

        setListRequest(newResult);
        console.log(newPermissions);
        setPermissoesList(newPermissions);

        const checkedPermissoes = newPermissions.map((item) => item.permissao);
        setCheckedPermissoesList(checkedPermissoes)
      }

    } else if (result?.status === 500) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackMessage("Erro!");
      setCallbackDuration(2000);
      setCallbackType("error");
      setCallbackErrorList(["Houve um erro interno no servidor."]);
      return;
    }

    if (result?.status === 401) {
      setLoading(false);
      setOpenLoginExpirado(true);
      return;
    }

    if (result?.status === 400 || result?.result?.status) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackMessage("Erro!");
      setCallbackDuration(5000);
      setCallbackType("error");
      setCallbackErrorList(
        result?.data?.error ?
          Object.values(
            result?.data?.error || result?.result?.data?.error
          ).flat(Infinity)
          :
          (result?.data?.msg
            ?
            [result?.data?.msg] :
            ["Ocorreu algum erro inesperado, tente novamente."]
          )
      );
      return;
    }
    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
  ]);

  let onCheck = useCallback((checkBoxObj) => {
    (async () => {
      let { id, checked } = JSON.parse(checkBoxObj);
      let permissoes = permissoesList;

      let index = permissoes.findIndex(item => item?.id_permissao === id);

      permissoes[index].permissao = checked

      setPermissoesList(permissoes);

      const checkedPermissoes = permissoes.map((item) => item.permissao);

      setCheckedPermissoesList(checkedPermissoes);
    })();
  }, [setCheckedPermissoesList, permissoesList]);


  let onMoreAction = useCallback(async (action) => {
    console.log(action);

    switch (action.action) {
      case "marcar-todos": {
        let permissoes = permissoesList;

        const checkedPermissoes = permissoes.map((item) => ({
          ...item,
          permissao: true,
        }));

        const checkedPermissoesValues = checkedPermissoes.map((item) => item.permissao)

        setPermissoesList(checkedPermissoes);

        setCheckedPermissoesList(checkedPermissoesValues);
        break;
      }
      case "desmarcar-todos": {
        let permissoes = permissoesList;

        const updatePermissoes = permissoes.map((item) => ({
          ...item,
          permissao: false,
        }));

        setPermissoesList(updatePermissoes);

        const noCheckedPermissoes = updatePermissoes.map((item) => item.permissao);

        setCheckedPermissoesList(noCheckedPermissoes);
        break;
      }
      default:
        break;
    }
  }, [permissoesList, setCheckedPermissoesList, setDefaultConfigTable]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  useEffect(() => {
    console.log(listRequest);
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    loadEmpresas();
  }, []);

  const loadEmpresas = useCallback(async () => {
    const response = await selectEmpresa();
    if (response.status === 200) {
      setListEmpresas(response.data);
    } else if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }

  }, [selectEmpresa]);

  const onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onPerPage = (newPerPage) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onSearchTerm = (value) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const handleSelectEmpresa = async (id) => {
    console.log(id);
    setEmpresaSelecionada(id);
  };

  async function handleSubmit() {
    console.log(permissoesList);

    let idsIntegracoes = permissoesList?.filter(item => item.permissao)
    idsIntegracoes = idsIntegracoes?.map(item => item.id_permissao)

    const stepZeroFields = [
      {
        label: "empresa",
        value: empresaSelecionada
      },
      {
        label: "datainicio",
        value: dataInicio
      },
      {
        label: "datafim",
        value: dataFim,
      },
    ];
    const stepZeroErrors = validateFields(stepZeroFields);
    console.log(stepZeroErrors);
    if (!idsIntegracoes || idsIntegracoes?.length === 0) {
      stepZeroErrors?.push("Selecione um ou mais cadastros para continuar a integração");
    }

    if (stepZeroErrors.length !== 0) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(stepZeroErrors);
      return;
    }
    setLoading(true);

    // return;
    const response = await cadastrarIntegracao({
      idEmpresa: empresaSelecionada,
      idsIntegracoes,
      dataInicio,
      dataFim
    });
    console.log(response);
    if (response?.status === 200 || response?.status === 201) {
      setCallbackMessage(response.data?.msg);
      setCallbackDuration(2000);
      setCallbackType("success");
      setCallbackShown(true);
    } else if (response?.status === 500) {
      setCallbackMessage("Erro!");
      setCallbackErrorList([
        "Erro interno do servidor, por favor, contate o suporte.",
      ]);
      setCallbackType("error");
      setCallbackShown(true);
    } else if (response?.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    } else {
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response?.data?.error)
        setCallbackErrorList(Object.values(response?.data?.error));
      else setCallbackErrorList(["Houve um erro ao salvar as integrações."]);
      setCallbackShown(true);
    }

    setLoading(false);

    return;
  }

  return (
    <div className="container-cadastra-integracoes">
      {loading && <OverlayLoading />}
      <div className="session-container filter-component-container integracao-questor">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                    ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={handleSelectEmpresa}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker
              label="Integrar de *"
              handleChange={(value) => {
                console.log(value);
                setDataInicio(value)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker
              label="Integrar até *"
              handleChange={(value) => setDataFim(value)}
            />
          </Grid>
        </Grid>
      </div>
      <h1 className="header-bar-title-page custom-table-title title-outside-table">Cadastros a Integrar</h1>
      <Table
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        checkList={checkedPermissoesList}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbCheckBox={onCheck}
        cbMoreAction={onMoreAction}
        optionsCheckBox={optionsCheckBox}
      />
      <Grid container spacing={2} alignItems="center">
        <Grid xs={12} className="marginLeftAuto">
          <div className="filter-button-area align-rigth">
            <Button
              onClick={() => handleSubmit()}
              text="Salvar"
              className="btn-primary"
            />
          </div>
        </Grid>
      </Grid>
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </div>
  );
};

export default CadastroIntegracao;
