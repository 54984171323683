import { Dialog, DialogTitle, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import pdvService from '../../../services/pdvService';
import usuariosService from '../../../services/usuariosService';
import { numberToCurrency } from '../../../utils/functions';
import ButtonComponent from "../../ButtonComponent";
import CallbackMessage from "../../CallbackMessage";
import ModalLoginExpirado from "../../ModalLoginExpirado";
import OverlayLoading from "../../OverlayLoading";
import { dateToDateDD_MM_YYYYandHours } from '../../../utils/dates';
import "./styles.scss";

const mockConsulta = {
  id_caixa: 0,
  numero_caixa: 123,
  valor_total: 1500,
  abertura: '21/07/2021 - 15:07:35',
  fechamento: '21/07/2021 - 15:07:35',
  usuario: 'Nome do usuário',
  dinheiro: 0,
  cheque: 0,
  cartao_credito: 0,
  pix: 0,
  abertura_caixa: 0,
  sangria: 0,
  suprimento: 0,
  entrada: 0,
  saida: 0,
  total: 0,
};

function ModalDetalhesCaixa({
  open,
  onClose,
  onSave,
  idCaixa,
}) {
  const { consultaCaixa } = pdvService;
  const { consultaUsuario } = usuariosService;

  const [dadosCaixa, setDadosCaixa] = useState({});
  const [nomeUsuario, setNomeUsuario] = useState("");

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  useEffect(() => {
    if (idCaixa && open) {
      loadCaixa();
    }
  }, [idCaixa, open])

  const loadUsuario = async (id_usuario) => {
    const response = await consultaUsuario(id_usuario);
    console.log(response.data)

    if (response.status === 200) {
      setNomeUsuario(response.data.name)
    }
  }

  const loadCaixa = async () => {
    setLoading(true);
    const response = await consultaCaixa(idCaixa);

    console.log(response.data);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }

    if (response.status === 400) {
      setErrors(Object.values(response?.data?.error).flat(Infinity));
      setShowMessage(true);
    }

    if (response.status === 500) {
      setErrors(['Erro interno no servidor, contate o suporte.']);
      setShowMessage(true);
    }

    if (response.status === 200 || response.status === 201) {
      loadUsuario(response.data.id_usuario);
      setDadosCaixa(response?.data);
      console.log(response.data)
    }

    setLoading(false);
  };

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }

  const handleCloseModal = () => {
    setShowMessage(false);
    setDadosCaixa({});
    setNomeUsuario('');
    onClose();
  }


  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleCloseModal} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>DETALHES DO CAIXA</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
              <div className="top-info-details">
                <div className="wrapper-detail">
                  <Grid item xs={12} sm={12} md={6} className="modal-items-detail">
                    <div>
                      <label>Abertura</label>
                      <span>{dadosCaixa?.data_abertura}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className="modal-items-detail">
                    <div>
                      <label>Usuário</label>
                      {/* <span>{dadosPedido?.numero_nota}</span> */}
                      <span>{nomeUsuario}</span>
                    </div>
                  </Grid>
                </div>
                <div className="wrapper-detail">
                  <Grid item xs={12} sm={12} md={6} className="modal-items-detail">
                    <div>
                      <label>Fechamento</label>
                      <span>{dadosCaixa?.data_fechamento}</span>
                    </div>
                  </Grid>
                </div>
                {/* <div className="wrapper-detail">
                  <Grid item xs={12} sm={12} md={6} className="modal-items-detail">
                    <div>
                      <label>Fechamento do pedido</label>
                      <span>{dateToDateDD_MM_YYYYandHours(dadosPedido?.data_finalizacao_pedido)}</span>
                    </div>
                  </Grid>
                </div> */}
              </div>

              {/* <Grid item className="container-table-details"> */}
              {/* <Grid item className="container-table-details">
                <Grid item xs={12} sm={12} md={12}>
                  <table className="nota-nfce-table">
                    <thead>
                      <tr>
                        <th className="column-head-id">ID</th>
                        <th className="column-numero">Código</th>
                        <th className="column-descricao">Descrição</th>
                        <th className="column-numero">Qtde</th>
                        <th className="column-th-valor">Vl Unit</th>
                        <th className="column-th-valor">Desc</th>
                        <th className="column-th-valor">Vl Trib</th>
                        <th className="column-th-valor">Valor (R$)</th>
                      </tr>
                    </thead>
                    {dadosPedido?.itens?.length > 0
                      && (
                        <tbody>
                          {dadosPedido?.itens?.map((item, index) => (
                            <tr key={index}>
                              <td className="column-id">
                                <div>
                                  {item.id}
                                </div>
                              </td>
                              <td className="column-numero">{item?.codigo}</td>
                              <td className="column-descricao">{item?.descricao}</td>
                              <td className="column-quantidade">{item?.quantidade}</td>
                              <td className="column-td-valor">{numberToCurrency(item.valor_unitario)}</td>
                              <td className="column-td-valor">{numberToCurrency(item.valor_desconto)}</td>
                              <td className="column-td-valor">{numberToCurrency(item.valor_total_tributos)}</td>
                              <td className="column-td-valor">{numberToCurrency(item.valor_total)}</td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                  </table>
                  {dadosPedido?.itens?.length === 0 &&
                    <div className="table-sem-dados"> Nenhum produto adicionado</div>}
                </Grid>
                <Grid item container spacing={1} justify="flex-end">
                  <Grid item xs={12} sm={4} md={1} className="nota-nfce-total">
                    TOTAL
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className="nota-nfce-total">
                    {numberToCurrency(dadosPedido?.valor_total)}
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid item xs={12} sm={12} md={6} className="modal-items-detail">
                <div className="title-section">
                  <label>Formas de pagamento</label>
                  <span></span>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} className="info-itens-container border-bottom">
                <div className="info-item-payment">
                  <h3>Dinheiro</h3>
                  <span>{numberToCurrency(dadosCaixa?.total_dinheiro)}</span>
                </div>
                <div className="info-item-payment">
                  <h3>Cheque</h3>
                  <span>{numberToCurrency(dadosCaixa?.total_cheque)}</span>
                </div>
                <div className="info-item-payment">
                  <h3>Cartão</h3>
                  <span>{numberToCurrency(dadosCaixa?.total_cartao_credito)}</span>
                </div>
                <div className="info-item-payment">
                  <h3>PIX</h3>
                  <span>{numberToCurrency(dadosCaixa?.total_pix)}</span>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6} className="modal-items-detail">
                <div className="title-section">
                  <label>Movimentação do Caixa</label>
                  <span></span>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} className="info-itens-container border-bottom">
                <div className="info-item-payment">
                  <h3>Abertura de Caixa</h3>
                  <span>{numberToCurrency(dadosCaixa?.abertura_caixa)}</span>
                </div>
                <div className="info-item-payment">
                  <h3>Sangria</h3>
                  <span>{numberToCurrency(dadosCaixa?.total_sangria)}</span>
                </div>
                <div className="info-item-payment">
                  <h3>Suprimento</h3>
                  <span>{numberToCurrency(dadosCaixa?.total_suprimento)}</span>
                </div>
                <div className="info-item-payment">
                  <h3>Entrada</h3>
                  <span>{numberToCurrency(dadosCaixa?.total_entrada)}</span>
                </div>
                <div className="info-item-payment">
                  <h3>Saída</h3>
                  <span>{numberToCurrency(dadosCaixa?.total_saida)}</span>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} className="info-itens-container">
                <div className="info-item-payment total-value">
                  <h3>Total</h3>
                  <span>{numberToCurrency(dadosCaixa?.valor_final)}</span>
                </div>
              </Grid>

              {/* <Grid item container spacing={1} justify="flex-between">
                <Grid item xs={12} sm={4} md={1} className="nota-nfce-total">
                  TOTAL
                </Grid>
                <Grid item xs={12} sm={4} md={2} className="nota-nfce-total">
                  {numberToCurrency(dadosPedido?.total_pagamentos)}
                </Grid>
              </Grid> */}

              <Grid container justify="flex-end" item>
                <ButtonComponent
                  text="Voltar"
                  onClick={handleCloseModal}
                  className="btn-success zero-margin"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalDetalhesCaixa;
