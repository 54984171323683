import React, { useState, useEffect, useCallback, useRef } from "react";

import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import OverlayLoading from "../../components/OverlayLoading";

import validateFields from "../../utils/validateFields";
import CallbackMessage from "../../components/CallbackMessage";
import exportData from "../../services/exportData";
import { ModalEnviarEmail } from "../../components/ModalEnviarEmail";
import "./styles.scss";
import { Filter } from "../../components/FilterComponent";
import { Search } from "../../components/TableResponsiveComponent";

import contasBancariasService from "../../services/contasBancariasService";
import selectService from "../../services/selectServices";
import notasFiscaisService from "../../services/notasFiscaisService";

import useCompany from "../../hooks/useCompany";
import { AccordionDre } from "./componentes/accordion";
import { Grid, useMediaQuery } from "@material-ui/core";
import { EmptyDre } from "./componentes/emptyDre";
import { toDateDD_MM_YYYY } from "../../utils/dates";
import { numberToCurrency } from "../../utils/functions";
import useHorizontalScroll from "../../hooks/useHorizontalScroll";
import SelectField from "../../components/SelectComponent";
import { Pagination } from "@material-ui/lab";

const colunasNota = ["Data", "Conta", "Cliente/fornecedor", "Descrição", "Categoria", "Status", "Valor", "Saldo"];

const colunasProduto = [
  { columnName: "Data", dataRef: "data" },
  { columnName: "Conta", dataRef: "conta" },
  { columnName: "Cliente/fornecedor", dataRef: "cliente" },
  { columnName: "Descrição", dataRef: "descricao" },
  { columnName: "Categoria", dataRef: "categoria" },
  { columnName: "Status", dataRef: "status" },
  { columnName: "Valor", dataRef: "valor" },
  { columnName: "Saldo", dataRef: "saldo" }
];

const tableOptions = {
  noSearch: false,
  filter: true,
  more: true,
  moreOptions: [
    {
      icon: "export",
      label: "Exportar XLS",
      action: "exportar-xls",
    },
    {
      icon: "export",
      label: "Exportar PDF",
      action: "exportar-pdf",
    },
    {
      icon: "email",
      label: "Enviar e-mail",
      action: "enviar-e-mail",
    },
  ],
};

function filterComponent({
  listEmpresas,
  setEmpresaId,
  setDataInicio,
  dataInicio,
  setDataFim,
  dataFim,
  setContaId,
  categoriaId,
  setCategoriaId,
  listCategoria,
  statusId,
  setStatusId,
  listStatus,
  listConta = [],
  handleApplyFilters,
  handleCleanFilters,
  empresaId,
  contaId,
}) {
  return (
    <Filter.Root>
      <Filter.Select
        callback={setEmpresaId}
        label="Empresa"
        options={listEmpresas}
        xs={12}
        title="Selecione uma empresa"
        value={empresaId}
      />
      <Filter.Date
        handleChange={(value) => setDataInicio(value)}
        initDate={dataInicio}
        label="Período de *"
        xs={6}
      />
      <Filter.Date
        handleChange={(value) => setDataFim(value)}
        initDate={dataFim}
        label="Período até*"
        xs={6}
      />
      <Filter.Autocomplete
        callback={(values) => setContaId(values)}
        label="Conta"
        page="relatorio_movimentacao_financeira"
        options={listConta}
        xs={12}
        values={contaId}
        placeholder={
          categoriaId?.length > 0
              ? "Selecione uma ou mais contas"
              : "Seleção vazia"
        }
      />
      <Filter.Autocomplete
        label="Categoria"
        page="relatorio_movimentacao_financeira"
        options={listCategoria}
        callback={(values) => setCategoriaId(values)}
        xs={6}
        values={categoriaId}
        placeholder={
          categoriaId?.length > 0
              ? "Selecione uma ou mais categorias"
              : "Seleção vazia"
        }
      />
      <Filter.Autocomplete
        xs={6}
        callback={(values) => setStatusId(values)}
        label="Status"
        options={listStatus}
        page="relatorio_movimentacao_financeira"
        values={statusId}
        placeholder={
          statusId?.length > 0
              ? "Selecione um ou mais status"
              : "Seleção vazia"
        }
      />
      <Filter.Buttons>
        <Filter.Button
          text="Filtrar"
          className="btn-primary"
          onClick={handleApplyFilters}
        />
        <Filter.Button
          text="Limpar Filtros"
          className="default-outline clean-filters-button"
          onClick={handleCleanFilters}
        />
      </Filter.Buttons>
    </Filter.Root>
  );
}

function HeaderTable({
                       onTableMoreAction,
                       tableOptions,
                       onSearch,
                       searchTermRef,
                       ...rest
}) {
  return (
    <Search
      callback={(e) => onSearch(e)}
      tableOptions={tableOptions}
      searchTermRef={searchTermRef}
      filterComponent={filterComponent({ ...rest })}
      callbackMenu={onTableMoreAction}
    />
  );
}

function ListaRelatorioExtratoMovimentacaoFinanceira() {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const {
    companyList: listEmpresas,
    selectedCompany: empresaId,
    setSelectedCompany: setEmpresaId,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("relatorio_movimentacao_financeira");

  const tableRef = useRef(null);
  useHorizontalScroll(tableRef?.current);

  const [loading, setLoading] = useState(false);

  const [listConta, setListConta] = useState([]);
  const [contaId, setContaId] = useState([]);

  const [listCategoria, setListCategoria] = useState([]);
  const [categoriaId, setCategoriaId] = useState([]);

  const listStatus = [{id: 1, nome: 'Entrada'}, {id: 2, nome: 'Saída'}]
  const [statusId, setStatusId] = useState([]);

  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [listRequest, setListRequest] = useState([]);
  const [resumeData, setResumeData] = useState()

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [filterOptions, setFilterOptions] = useState({
    id_empresa: null,
    periodo_de: null,
    periodo_ate: null,
    conta: null,
    status: null,
    categoria: null,
    page: 1,
    per_page: 10
  });

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    nextPageUrl: null,
    prevPageUrl: null,
    perPage: 10
  });

  const [openModalEmail, setOpenModalEmail] = useState(false);

  const validationFields = [
    {
      label: "empresa",
      value: empresaId,
    },
    {
      label: "periodo_de_emissao_de",
      value: dataInicio,
    },
    {
      label: "periodo_de_emissao_ate",
      value: dataFim,
    },
  ];

  const exportToPDF = {
    url: "exportar-relatorio-movimentacao-financeira-pdf",
    fileName: "cbhub_relatorio_movimentacao_financeira",
    fileType: "pdf",
  };

  const exportToXLS = {
    url: "exportar-relatorio-movimentacao-financeira-excel",
    fileName: "cbhub_relatorio_movimentacao_financeira",
    fileType: "xlsx",
  };

  const updateTable = useCallback(async () => {
    // window.scrollTo(0, 0);
    setLoading(true);

    const response = await notasFiscaisService.listaMovimentacaoFinanceira({
      ...filterOptions,
    });

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status === 200) {
      const {data} = response

      setResumeData({
        ...data.resume,
        bancos: data.data.map(item => item.bank_name)
      })

      const listBank = data.data.map((item) => {
        let saldo = null

        if (item.data.length > 0) {
          saldo = item.data[item.data.length - 1].saldo
        }

        return ({
          descricao: item.bank_name,
          id: item.id_bank_account,
          total: !!saldo ? <p className={saldo > 0 ? 'total-positivo' : 'total-negativo'}>Saldo total: <span>{numberToCurrency(saldo)}</span></p> : <></>,
          data: item.data.map(bank => (
              {
                data: toDateDD_MM_YYYY(bank.data_referencia),
                conta: bank.nome_conta_bancaria,
                cliente: bank.nome_fornecedor ?? bank.nome_cliente,
                descricao: bank.descricao,
                categoria: bank.nome_categoria,
                status: bank.nome_tipo_categoria === 'ENTRADA' ? 'Recebido' : 'Pago',
                valor: <span
                    className={Number(bank.valor) > 0 ? 'table-positive' : 'table-negative'}>{numberToCurrency(bank.valor)}</span>,
                saldo: <span className={'table-saldo'}>{numberToCurrency(bank.saldo)}</span>
              }))
        })
      })

      setListRequest(listBank)

      setPagination(prev => ({
        ...prev,
        totalPages: data.paginate_info.last_page,
        totalItems: data.paginate_info.quantidade_registros
      }));
    }
    setLoading(false);
  }, [
    notasFiscaisService,
    filterOptions,
    setListRequest,
    setResumeData,
    setLoading,
    setOpenLoginExpirado,
  ]);

  const [perPageSelect, setPerPageSelect] = useState([
    { id: 10, nome: "10 por página" },
    { id: 25, nome: "25 por página" },
    { id: 50, nome: "50 por página" },
    { id: 100, nome: "100 por página" },
  ]);
  
  const handleChangePage = (event, newPage) => {
    setPagination(prevPagination => ({
      ...prevPagination,
      currentPage: newPage
    }));

    setFilterOptions(prevOptions => ({
      ...prevOptions,
      page: newPage
    }));
  };

  const handleChangePerPage = (selectedItem) => {
    setPagination(prevPagination => ({
        ...prevPagination,
        perPage: selectedItem.id
    }));

    const maxCurrentPage = Math.ceil(pagination.totalItems / selectedItem);

    const newPage = Math.min(pagination.currentPage, maxCurrentPage);

    setFilterOptions(prevOptions => ({
        ...prevOptions,
        page: newPage,
        per_page: selectedItem
    }));
  };

  async function loadAccountAndCategory(id) {
    if (!id) return

    Promise.all([
      contasBancariasService.selecionaConta(id),
      selectService.selecionaCategoria(id)
    ]).then((res) => {
      const setters = {
        0: setListConta,
        1: setListCategoria
      }

      res.forEach((response, index) => {
        if (response.status === 200) {
          let newRes = response

          if (newRes.response) newRes = newRes.response

          const {data} = newRes

          const setterFc = setters[index]

          setterFc(data)
        }
      })
    })
  }

  const onTableMoreAction = async ({ action }) => {
    const fields = [...validationFields];

    const validateFieldsErrors = validateFields(fields);

    if (validateFieldsErrors.length !== 0) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackDuration(3000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(validateFieldsErrors);
      setLoading(false);
      return;
    }

    onConfirmExport(action)
  };

  const onConfirmExport = async (tipo) => {
    if (tipo === "enviar-e-mail") {
      setOpenModalEmail(true);
      return;
    }
    let params = {};
    if (tipo === "exportar-xls") {
      params = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: {
          id_empresa: empresaId,
          periodo_de: dataInicio,
          periodo_ate: dataFim,
          conta: contaId,
          status: statusId,
          search_term: filterOptions?.search_term
        },
      };
    } else if (tipo === "exportar-pdf") {
      params = {
        url: exportToPDF.url,
        fileName: exportToPDF.fileName,
        fileType: exportToPDF.fileType,
        params: {
          id_empresa: empresaId,
          periodo_de: dataInicio,
          periodo_ate: dataFim,
          conta: contaId,
          status: statusId,
          search_term: filterOptions?.search_term
        },
      };
    }
    setLoading(true);
    const response = await exportData(params);

    if (response.status === 500) {
      setCallbackErrorList([
        "Erro interno no servidor. Por favor, contate o suporte",
      ]);
      setCallbackShown(true);
      setCallbackType("error");
    } else if (response.status !== 201 && response.status !== 200) {
      setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
      setCallbackShown(true);
      setCallbackType("error");
    }

    setLoading(false);
  };

  function handleClose() {
    setCallbackShown(false);
    setCallbackType("");
  }

  async function handleSendEmail(data = false) {
    if (!data) {
      setOpenModalEmail(false);
      return;
    }
    setLoading(true);
    const cco = data.cco?.split(";")?.map((item) => {
      return item?.trim();
    });
    const emails = data.para?.split(";")?.map((item) => {
      return item?.trim();
    });
    
    const response = await notasFiscaisService.enviarEmailMovimentacaoFinanceira({
      id_empresa: empresaId,
      periodo_de: dataInicio,
      periodo_ate: dataFim,
      conta: contaId,
      status: statusId,
      search_term: filterOptions?.search_term,
      cco,
      emails,
      assunto: data.assunto,
      mensagem: data.mensagem,
    });

    if (response?.status === 200) {
      setCallbackErrorList([]);
      setCallbackMessage([
        response?.data?.msg ? response?.data?.msg : "Relátorio enviado",
      ]);
      setCallbackShown(true);
      setCallbackType("success");
      setOpenModalEmail(false);
    }

    setLoading(false);
  }

  const [filtered, setFiltered] = useState(false)

  const handleApplyFilters = () => {
    const fields = [...validationFields];
    setEmpresaPagina("relatorio_movimentacao_financeira", empresaId);

    const validateFieldsErrors = validateFields(fields);

    if (validateFieldsErrors.length !== 0) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackDuration(3000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(validateFieldsErrors);
      setLoading(false);
      return;
    }

    setFiltered(true)
    setFilterOptions({
      id_empresa: empresaId,
      periodo_de: dataInicio,
      periodo_ate: dataFim,
      status: statusId,
      conta: contaId,
      categoria: categoriaId,
    });
  };

  const searchTermRef = useRef(null)

  const handleCleanFilters = async () => {
    setFiltered(false)
    setEmpresaId(null);
    setListRequest(null);
    setDataInicio("");
    setDataFim("");
    setContaId([]);
    setCategoriaId([]);
    setStatusId([]);
    setEmpresaPagina("relatorio_movimentacao_financeira", null);

    if (searchTermRef.current) {
      searchTermRef.current.value = '';
    }

    setFilterOptions({
      id_empresa: null,
      periodo_de: null,
      periodo_ate: null,
      conta: null,
      status: null,
      categoria: null,
      search_term: null
    });
  };

  const configuracaoTabela = {
    columnArray: colunasProduto,
    display: {
      totalResults: false,
    },
  };

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("relatorio_movimentacao_financeira");
    if (empresaStorage) {
      setEmpresaId(empresaStorage);
    }
  }, []);

  useEffect(() => {
    if (empresaId) {
      loadAccountAndCategory(empresaId);
    }
  }, [empresaId]);

  useEffect(() => {
    if (
      filterOptions.id_empresa &&
      filterOptions.periodo_de &&
      filterOptions.periodo_ate
    ) {
      updateTable();
    }
  }, [updateTable]);


  const onSearch = (value) => {
    setFilterOptions(prevOptions => ({
      ...prevOptions,
      search_term: value
    }));
  };

  return (
    <>
      {loading && <OverlayLoading />}
      <div className="table-no-header max-width-for-page height-auto">
        <HeaderTable
          listEmpresas={listEmpresas}
          listConta={listConta}
          setEmpresaId={setEmpresaId}
          setContaId={(values) => setContaId(values.map(item => item.id))}
          listCategoria={listCategoria}
          setCategoriaId={(values) => setCategoriaId(values.map(item => item.id))}
          categoriaId={categoriaId}
          listStatus={listStatus}
          setStatusId={(values) => setStatusId(values.map(item => item.id))}
          statusId={statusId}
          setDataInicio={setDataInicio}
          dataInicio={dataInicio}
          setDataFim={setDataFim}
          dataFim={dataFim}
          empresaId={empresaId}
          contaId={contaId}
          handleApplyFilters={handleApplyFilters}
          handleCleanFilters={handleCleanFilters}
          onTableMoreAction={onTableMoreAction}
          tableOptions={tableOptions}
          onSearch={onSearch}
          searchTermRef={searchTermRef}
        />
        {listRequest?.length ? (
            <>
              <div
                  className="session-container"
                  style={{
                    padding: '2rem'
                  }}
              >
                <div
                  className={'resumo-mov-container'}
                >
                  <div>
                    <h2 className={'resumo-mov-title'}>
                      Resumo das movimentações
                    </h2>
                    <p className={'data'}>{`${dataInicio} a ${dataFim}`}</p>
                    <p className={'small laranja bold'}>Contas:</p>
                    {
                      resumeData.bancos?.map((item, index) => (
                          <p className={'small'} key={index}>{item}</p>
                      ))
                    }
                  </div>
                  <div className={'details'}>
                    <div>
                      <p>Receitas Em Abertos</p>
                      <p>{numberToCurrency(resumeData?.receita_em_aberto)}</p>
                    </div>
                    <div>
                      <p>Receitas Realizadas</p>
                      <p>{numberToCurrency(resumeData?.receita_realizada)}</p>
                    </div>
                    <div>
                      <p>Despesas Em Abertos</p>
                      <p>{numberToCurrency(resumeData?.despesa_em_aberto)}</p>
                    </div>
                    <div>
                      <p>Despesas Realizadas</p>
                      <p>{numberToCurrency(resumeData?.despesa_realizada)}</p>
                    </div>
                    <div className={'border-before bigger'}>
                      <p>Totais em Aberto</p>
                      <p>{numberToCurrency(resumeData?.totais_em_aberto)}</p>
                    </div>
                    <div className={'bigger'}>
                      <p>Totais Realizados</p>
                      <p>{numberToCurrency(resumeData?.totais_realizados)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="session-container">
                <span className="session-container-header">
                  <Grid
                      container
                      direction="column"
                      className="container-relatorio-notas container-relatorio"
                      ref={tableRef}
                  >
                    <AccordionDre
                        columns={colunasNota}
                        dados={listRequest}
                        configuracaoTabela={configuracaoTabela}
                    />
                  </Grid>
                </span>
                {pagination &&
                    <div className="table-footer">
                      {!isMobile && (
                          <>
                            <div className="table-perpage-wrapper">
                              <SelectField
                                  initialValue={perPageSelect.find(option => option.id === pagination?.perPage) || perPageSelect[0]}
                                  list={perPageSelect}
                                  callback={handleChangePerPage}
                              />
                            </div>
                            <div className="table-total-data-lis-wrapper">
                      <span className="table-total-data-list">
                        {`Total: ${pagination?.totalItems} resultados`}
                      </span>
                            </div>
                          </>
                      )}
                      <div className="table-configuration">
                        <Pagination
                            count={pagination?.totalPages}
                            variant="outlined"
                            shape="rounded"
                            size="large"
                            page={pagination?.currentPage}
                            onChange={handleChangePage}
                        />
                      </div>
                    </div>
                }
              </div>
            </>
        ) : (
            <EmptyDre filtered={filtered}/>
        )}
      </div>
      <CallbackMessage
          type={callbackType}
          open={callbackShown}
          errorList={callbackErrorList}
          duration={callbackDuration}
          message={callbackMessage}
          handleClose={() => handleClose()}
      />
      <ModalEnviarEmail
          open={openModalEmail}
          onClose={handleSendEmail}
          setCallbackErrorList={setCallbackErrorList}
          callbackErrorList={callbackErrorList}
          callbackShown={callbackShown}
          setCallbackShown={setCallbackShown}
      />
      <ModalLoginExpirado open={openLoginExpirado}/>
    </>
  );
}

export default ListaRelatorioExtratoMovimentacaoFinanceira;
