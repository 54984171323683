import Axios from './Axios';

const pdvService = {
  abrirCaixa: async (id_empresa, valor_inicial, numero_caixa) => {
    const response = await Axios.post(`/erp-abre-caixa`, {
      id_empresa,
      valor_inicial,
      numero_caixa,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  sangriaCaixa: async (id_caixa, valor, motivo) => {
    const response = await Axios.post(`/erp-sangria-caixa`, {
      id_caixa,
      valor,
      motivo,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  suprimentoCaixa: async (id_caixa, valor, motivo) => {
    const response = await Axios.post(`/erp-suprimento-caixa`, {
      id_caixa,
      valor,
      motivo,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  consultaCaixa: async (id_caixa) => {
    const response = await Axios.get(`/erp-consulta-caixa/${id_caixa}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  consultaPedido: async (id_pedido) => {
    const response = await Axios.get(`/erp-consulta-pedido/${id_pedido}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  fecharCaixa: async (id_caixa) => {
    const response = await Axios.get(`/erp-fecha-caixa/${id_caixa}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  emitirNfcePedido: async (
    id_pedido,
    id_empresa,
    produtos,
    pagamento,
    identificador_cliente
  ) => {
    const response = await Axios.get(`/erp-emite-nfce-pedido/${id_pedido}`, {
      id_empresa,
      produtos,
      pagamento,
      identificador_cliente,
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  listaPedidos: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    de = null,
    ate = null,
    id_empresa = null
  ) => {
    const response = await Axios.get('/erp-lista-pedido', {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        data_de: de,
        data_ate: ate,
        id_empresa: id_empresa,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  cancelaPedido: async (id_pedido, motivo_cancelamento) => {
    const response = await Axios.post(`/erp-cancela-pedido-nota/${id_pedido}`, {
      motivo_cancelamento,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  listaCaixa: async (
    data_inicio = null,
    data_fim = null,
    id_empresa = null,
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
  ) => {
    const response = await Axios.get(`/erp-lista-caixa`, {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        data_inicio,
        data_fim,
        id_empresa,
      }
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      })

    return response;
  },
  editaCaixa: async ({id_caixa, data_abertura, data_fechamento, valor_inicial, numero_caixa}) => {
    const response = await Axios.post(`/erp-edita-caixa/${id_caixa}`,{
      data_abertura,
      data_fechamento,
      valor_inicial,
      numero_caixa
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      })

    return response;
  }
};

export default pdvService;
