import Axios from './Axios';

export default {
  selecionaPrefeitura: async (id) => {
    const response = await Axios.get(`/select-prefeituras-parametrizadas`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
};
