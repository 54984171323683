import { Grid } from "@material-ui/core";
import DtDatePicker from "../../DatePicker";

export function FilterDate({ label, handleChange, initDate, ...rest }) {
  return (
    <Grid item {...rest}>
      <DtDatePicker
        label={label}
        handleChange={handleChange}
        initDate={initDate}
      />
    </Grid>
  );
}
