import Axios from "./Axios";

const preDemissaoService = {
  getPreDemissoes: async ({
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null,
    status = null,
  }) => {
    const result = await Axios.get("/lista-dp-pre-demissao", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
        status,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  cadastrarPreDemissao: async ({
    empresaDp,
    funcionario,
    documentoDp,
    emissorDocumentoDp,
    data,
    motivo,
    eventos,
    previa
  }) => {
    const response = await Axios.post("/cadastro-dp-pre-demissao", {
      id_empresa: empresaDp,
      id_funcionario: funcionario,
      id_documento: documentoDp,
      id_emissor: emissorDocumentoDp,
      data,
      id_motivo: motivo,
      eventos,
      previa
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  consultaPreDemissao: async (id) => {
    const response = await Axios.get(`/consulta-dp-pre-demissao/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  removePreDemissao: async (id) => {
    const response = await Axios.post(`/remove-dp-pre-demissao/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  confirmarDesligamentoPreDemissao: async (id) => {
    const response = await Axios.post(`desliga-funcionario-dp/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  editarPreDemissao: async ({
    idPreDemissao,
    empresaDp,
    funcionario,
    documentoDp,
    emissorDocumentoDp,
    data,
    motivo,
    eventos,
    previa
  }) => {
    const response = await Axios.post(
      `/edita-dp-pre-demissao/${idPreDemissao}`,
      {
        id_empresa: empresaDp,
        id_funcionario: funcionario,
        id_documento: documentoDp,
        id_emissor: emissorDocumentoDp,
        data,
        id_motivo: motivo,
        eventos,
        previa
      }
    )
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  confirmarFinalizaLancamento: async (id) => {
    const response = await Axios.post(`finalizar-atendimento-dp-pre-demissao/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
};
export default preDemissaoService;
