import React from "react";
import { Grid } from "@material-ui/core";
import styles from "./styles.module.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import checkIcon from "../../../../../assets/img/check-circle.svg";
import Icon from "../../../../../components/Icon";

export function Aviso({ locationState, tipo, link, onCopy }) {
  return (
    <Grid container spacing={2} className={styles.containerAviso}>
      {tipo === "preenchimento-finalizado" && (
        <>
          <Grid item xs={12}>
            <div className={styles.textoAviso}>
              Link externo para preenchimento das informações de Dados Pessoais,
              Endereço e Contato, Documentos e Dependentes por parte do
              colaborador
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.labelLink}>Link</div>
            <div className={styles.containerCopyLink}>
              <div className={styles.containerLink}>
                <a href={link} target="_blank">
                  {link}
                </a>
              </div>
              <CopyToClipboard text={link} onCopy={onCopy}>
                <div className={styles.buttonWrapper}>
                  <button>
                    <Icon name="copy-icon" size={16} className="icon" /> Copiar
                    link
                  </button>
                </div>
              </CopyToClipboard>
            </div>
          </Grid>
        </>
      )}
      {tipo === "preenchimento-finalizado-funcionario" && (
        <>
          <Grid item xs={12}>
            <div
              className={`${styles.textoAviso} ${styles.containerAvisoImage}`}
            >
              <img src={checkIcon} alt="imagem check" />
              Seus dados foram enviados para o setor responsável da empresa.
              Obrigado!
            </div>
          </Grid>
        </>
      )}
      {tipo === "ja-preenchido" && (
        <>
          <Grid item xs={12}>
            <div
              className={`${styles.textoAviso} ${styles.containerAvisoImage}`}
            >
              <img src={checkIcon} alt="imagem check" />
              As informações já foram enviadas ao setor responsável da empresa.
            </div>
          </Grid>
        </>
      )}
      {tipo === "link-invalido" && (
        <>
          <Grid item xs={12}>
            <div className={styles.textoAviso}>
              O link acessado não é válido!
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
}
