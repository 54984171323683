import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core';

import Icon from '../../../../components/Icon';

import { numberToCurrency } from '../../../../utils/functions';

import styles from './styles.module.scss';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    backgroundColor: '#F8F8FB',
    marginTop: 15
  },
  tableCell: {
    color: '#EF4066 !important'
  }
});

export default function TableServices({ serviceList: data = [], onDelete, noDelete = false }) {
  const classes = useStyles();

  const [total, setTotal] = useState(numberToCurrency(0));

  useEffect(() => {
    let mounted = true;
    const total = numberToCurrency(data.reduce((totalPrice, service) => {
      return totalPrice + service.valorUnit * service.quantidade;
    }, 0));

    if (mounted) {
      setTotal(total);
    }

    return () => mounted = false;
  }, [data]);

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {!noDelete && <TableCell className={classes.tableCell} align='left'></TableCell>}
            <TableCell className={classes.tableCell} align='left'>
              Quantidade
            </TableCell>
            <TableCell className={classes.tableCell} align='left'>
              Descrição
            </TableCell>
            <TableCell className={classes.tableCell} align='right'>
              Valor (R$)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length !== 0 && data?.map(service => (
            <TableRow key={service.idSolicitacaoItem}>
              {!noDelete && (
                <TableCell className={classes.tableCell}>
                  <IconButton onClick={() => onDelete(service.idSolicitacaoItem)}>
                    <Icon name='remove' size={18} color='#EF4066' />
                  </IconButton>
                </TableCell>
              )}
              <TableCell className={classes.tableCell}>
                {service.quantidade}x
              </TableCell>
              <TableCell
                className={classes.tableCell}
                component='th'
                scope='row'
              >
                <div className={styles.descricaoField}>
                  <span>{service.descricao || service.nome_servico}</span>
                  {service.observacao && service.observacao !== "" && service.observacao !== undefined && (
                    <>
                      <span className={styles.observacoes}><strong>Observações: </strong>{service.observacao}</span>
                    </>
                  )}
                </div>
              </TableCell>
              <TableCell className={classes.tableCell} align='right'>
                {numberToCurrency(service.valorUnit)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow className={styles.row}>
            {!noDelete && <TableCell className={classes.tableCell} align='left'></TableCell>}
            <TableCell></TableCell>
            <TableCell className={styles.alignRight}>
              <span className={styles.total}>TOTAL</span>
            </TableCell>
            <TableCell className={styles.alignRight}>
              <span className={styles.value}>{total}</span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}
