import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import validateFields from "../../../../utils/validateFields";
import ModalLoginExpirado from "../../../../components/ModalLoginExpirado";
import ButtonComponent from "../../../../components/ButtonComponent";

import CallbackMessage from "../../../../components/CallbackMessage";
import OverlayLoading from "../../../../components/OverlayLoading";
import Stepper from "../../../../components/Stepper";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  useMediaQuery,
} from "@material-ui/core";

import { Aviso } from "../componentes/aviso";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import styles from "../styles.module.scss";
import { DadosPessoais } from "../componentes/dadosPessoais";
import { EnderecoContato } from "../componentes/enderecoContato";
import { Documentos } from "../componentes/documentos";
import { Dependentes } from "../componentes/dependentes";
import preAdmissaoService from "../../../../services/dpPreAdmissaoService";
import { DadosContratuais } from "../componentes/dadosContratuais";
import { Beneficios } from "../componentes/beneficios";
import { BeneficiosMobile } from "../componentes/beneficiosMobile";
import { onlyUnsignedNumbers } from "../../../../utils/strings";
import useCompany from "../../../../hooks/useCompany";

export function EdicaoPreAdmissao() {
  const isMobile = useMediaQuery("(max-width: 1100px)");

  const history = useHistory();
  const params = useParams();

  const { companyList: listEmpresas } = useCompany("pre_admissao");

  const { id: idProdutoParams } = params;
  const [id, setId] = useState(idProdutoParams);

  const availableSteps = [
    "DADOS CONTRATUAIS",
    "BENEFÍCIOS",
    "DADOS PESSOAIS",
    "ENDEREÇO E CONTATO",
    "DOCUMENTOS",
    "DEPENDENTES",
  ];

  const {
    cadastrarPreAdmissaoFuncionario,
    consultaPreAdmissao,
    editarPreAdmissao,
  } = preAdmissaoService;
  const locationState = history.location.state;
  const [loading, setLoading] = useState(false);
  const [idEmpresa, setIdEmpresa] = useState(null);
  const [hash, setHash] = useState(null);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [preAdmissaoDadosContratuais, setPreAdmissaoDadosContratuais] =
    useState({
      // DADOS CONTRATUAIS
      nomeFuncionario: null,
      dataAdmissao: null,
      diasExperiencia: null,
      dataExameAdmissional: null,
      optantePorAdiantamento: null,
      admissaoDuranteSD: null,
      departamento: null,
      salarioBase: null,
      terminoContrato: null,
      resultadoAtestadoOcupacional: null,
      tipoAdmissao: null,
      vinculoTrabalista: null,
      categoria: null,
      funcao: null,
      formaPagamento: null,
      horariosSemana: {
        segunda: null,
        entradaSegunda: null,
        inicioIntervaloSegunda: null,
        fimIntervaloSegunda: null,
        saidaSegunda: null,
        terca: null,
        entradaTerca: null,
        inicioIntervaloTerca: null,
        fimIntervaloTerca: null,
        saidaTerca: null,
        quarta: null,
        entradaQuarta: null,
        inicioIntervaloQuarta: null,
        fimIntervaloQuarta: null,
        saidaQuarta: null,
        quinta: null,
        entradaQuinta: null,
        inicioIntervaloQuinta: null,
        fimIntervaloQuinta: null,
        saidaQuinta: null,
        sexta: null,
        entradaSexta: null,
        inicioIntervaloSexta: null,
        fimIntervaloSexta: null,
        saidaSexta: null,
        sabado: null,
        entradaSabado: null,
        inicioIntervaloSabado: null,
        fimIntervaloSabado: null,
        saidaSabado: null,
        domingo: null,
        entradaDomingo: null,
        inicioIntervaloDomingo: null,
        fimIntervaloDomingo: null,
        saidaDomingo: null,
      },
    });

  const [preAdmissaoBeneficios, setPreAdmissaoBeneficios] = useState({
    valeTransporte: [
      {
        descricao: null,
        quantidadePassagens: null,
        valor: null,
        numeroBilhete: null,
      },
    ],
    valeAlimentacao: {
      valor: null,
      formaPagamento: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
    },
    valeRefeicao: {
      valor: null,
      formaPagamento: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
    },
    assistenciaMedica: {
      operadora: null,
      plano: null,
      valor: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
      dependentes: null,
      inclusoNoPlano: null,
      tipoDescontoDependentes: null,
      valorDescontadoDependentes: null,
    },
    assistenciaOdontologica: {
      operadora: null,
      plano: null,
      valor: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
      dependentes: null,
      inclusoNoPlano: null,
      tipoDescontoDependentes: null,
      valorDescontadoDependentes: null,
    },
    seguroVida: {
      descricao: null,
      seguradora: null,
      valor: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
    },
    bolsaAuxilio: {
      instituicao: null,
      curso: null,
      valor: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
    },
    outros: {
      descricao: null,
      valor: null,
      descontado: null,
      tipoDesconto: null,
      valorDescontado: null,
    },
  });

  const [preAdmissaoDadosPessoais, setPreAdmissaoDadosPessoais] = useState({
    // DADOS PESSOAIS
    imagem: null,
    imagemPreview: null,
    hasImagem: null,
    dataNascimento: null,
    estadoCivil: null,
    sexo: null,
    cor: null,
    escolaridade: null,
    nomeMae: null,
    nomePai: null,
    paisNascimento: null,
    nacionalidade: null,
    estadoNascimento: null,
    municipioNascimento: null,
    possuiDeficiencia: null,
    deficiencia: null,
    observacoes: null,
  });

  const [preAdmissaoEnderecoContato, setPreAdmissaoEnderecoContato] = useState({
    // ENDEREÇO E CONTATO
    cep: null,
    rua: null,
    numero: null,
    bairro: null,
    cidade: null,
    estado: null,
    pais: null,
    email: null,
    telefone: null,
    complemento: null,
    celular: null,
  });

  const [preAdmissaoDocumentos, setPreAdmissaoDocumentos] = useState({
    // DOCUMENTOS
    cpf: null,
    nisNit: null,
    tipoIdentidade: null,
    numeroDoc: null,
    emissorDoc: null,
    emissaoDoc: null,
    ufDoc: null,
    ctps: null,
    serieCtps: null,
    ufCtps: null,
    emissaoCtps: null,
    emissaoNisNit: null,
    tituloEleitor: null,
    zonaEleitoral: null,
    secaoEleitoral: null,
    cnh: null,
    emissaoCnh: null,
    validadeCnh: null,
    categoriaCnh: null,
    ufCnh: null,
    emissaoPrimeiraCnh: null,
    tipoConta: null,
    banco: null,
    bancoOutros: null,
    agencia: null,
    conta: null,
    digitoConta: null,
    documentos: [],
  });

  const [preAdmissaoDependentes, setPreAdmissaoDependentes] = useState({
    // ENDEREÇO E CONTATO
    possuiDependentes: null,
    dependentes: [
      {
        nome: null,
        cpf: null,
        dataNascimento: null,
        inicioDependencia: null,
        paisNascimento: null,
        nacionalidade: null,
        telefone: null,
        cep: null,
        rua: null,
        numero: null,
        bairro: null,
        cidade: null,
        estado: null,
        pais: null,
        parentesco: null,
        dataEntradaPais: null,
        possuiDeficiencia: null,
        inclusoPlanoSaude: null,
        inclusoPlanoOdontologico: null,
        deficiencia: null,
        mesmoEndereco: null,
        complemento: null,
        declaraDependente: null,
        salarioFamilia: null,
        certidaoNascimento: null,
        cadernetaVacinacao: null,
      },
    ],
  });

  const handleNextStep = () => {
    if (locationState?.details) {
      return setActiveStep(activeStep + 1);
    }
    console.log(preAdmissaoDadosContratuais.funcao);
    if (activeStep === 0) {
      const stepZeroFields = [
        {
          label: "empresaDp",
          value: idEmpresa,
        },
        {
          label: "nomeFuncionario",
          value: preAdmissaoDadosContratuais.nomeFuncionario,
        },
        {
          label: "dataAdmissao",
          value: preAdmissaoDadosContratuais.dataAdmissao,
        },
        {
          label: "diasExperiencia",
          value: preAdmissaoDadosContratuais.diasExperiencia,
        },
        {
          label: "departamento",
          value: preAdmissaoDadosContratuais.departamento,
        },
        {
          label: "salarioBase",
          value: preAdmissaoDadosContratuais.salarioBase,
        },
        {
          label: "resultadoAtestadoOcupacional",
          value: preAdmissaoDadosContratuais.resultadoAtestadoOcupacional,
        },
        {
          label: "tipoAdmissao",
          value: preAdmissaoDadosContratuais.tipoAdmissao,
        },
        {
          label: "vinculoTrabalista",
          value: preAdmissaoDadosContratuais.vinculoTrabalista,
        },
        { label: "categoria_dp", value: preAdmissaoDadosContratuais.categoria },
        { label: "funcao", value: preAdmissaoDadosContratuais.funcao },
        {
          label: "formaPagamento",
          value: preAdmissaoDadosContratuais.formaPagamento,
        },
      ];
      console.log(stepZeroFields);
      const stepZeroErrors = validateFields(stepZeroFields);
      if (stepZeroErrors.length !== 0) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(stepZeroErrors);
        return;
      }
    }

    if (activeStep === 1) {
      let hasError = false;
      preAdmissaoBeneficios?.valeTransporte.forEach((vale) => {
        let beneficiosFields = [
          {
            label: "descricao",
            value: vale.descricao,
          },
          {
            label: "quantidadePassagens",
            value: vale.quantidadePassagens,
          },
          {
            label: "valor",
            value: vale.valor,
          },
          {
            label: "numeroBilhete",
            value: vale.numeroBilhete,
          },
        ];
        console.log(beneficiosFields);
        const beneficiosErros = validateFields(beneficiosFields);
        console.log(beneficiosErros);
        if (beneficiosErros.length !== 0) {
          hasError = true;
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackErrorList(beneficiosErros);
          return;
        }
      });
      if (hasError) return;
    }

    if (activeStep === 2) {
      const stepDoisFields = [
        {
          label: "datanascimento",
          value: preAdmissaoDadosPessoais.dataNascimento,
        },
        {
          label: "estadocivil",
          value: preAdmissaoDadosPessoais.estadoCivil,
        },
        {
          label: "sexo",
          value: preAdmissaoDadosPessoais.sexo,
        },
        {
          label: "cor",
          value: preAdmissaoDadosPessoais.cor,
        },
        {
          label: "escolaridade",
          value: preAdmissaoDadosPessoais.escolaridade,
        },
        {
          label: "nomeMae",
          value: preAdmissaoDadosPessoais.nomeMae,
        },
        {
          label: "paisNascimento",
          value: preAdmissaoDadosPessoais.paisNascimento,
        },
        {
          label: "nacionalidadeDp",
          value: preAdmissaoDadosPessoais.nacionalidade,
        },
        {
          label: "estadoNascimento",
          value: preAdmissaoDadosPessoais.estadoNascimento,
        },
        {
          label: "municipioNascimento",
          value: preAdmissaoDadosPessoais.municipioNascimento,
        },
      ];
      const stepDoisErrors = validateFields(stepDoisFields);

      if (stepDoisErrors.length !== 0) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(stepDoisErrors);
        return;
      }
    }

    if (activeStep === 3) {
      const stepTresFields = [
        {
          label: "cep",
          value: preAdmissaoEnderecoContato.cep,
        },
        {
          label: "rua",
          value: preAdmissaoEnderecoContato.rua,
        },
        {
          label: "numero",
          value: preAdmissaoEnderecoContato.numero,
        },
        {
          label: "bairro",
          value: preAdmissaoEnderecoContato.bairro,
        },
        {
          label: "cidade",
          value: preAdmissaoEnderecoContato.cidade,
        },
        {
          label: "estado",
          value: preAdmissaoEnderecoContato.estado,
        },
        {
          label: "paisDp",
          value: preAdmissaoEnderecoContato.pais,
        },
        {
          label: "email",
          value: preAdmissaoEnderecoContato.email,
        },
        {
          label: "telefone",
          value: preAdmissaoEnderecoContato.telefone,
        },
      ];
      const stepTresErrors = validateFields(stepTresFields);

      if (stepTresErrors.length !== 0) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(stepTresErrors);
        return;
      }
    }

    if (activeStep === 4) {
      const stepQuatroFields = [
        {
          label: "cpf",
          value: preAdmissaoDocumentos.cpf,
        },
        {
          label: "nisNit",
          value: preAdmissaoDocumentos.nisNit,
        },
        {
          label: "tipoIdentidadeDp",
          value: preAdmissaoDocumentos.tipoIdentidade,
        },
        {
          label: "numeroDoc",
          value: preAdmissaoDocumentos.numeroDoc,
        },
        {
          label: "emissorDoc",
          value: preAdmissaoDocumentos.emissorDoc,
        },
        {
          label: "emissaoDoc",
          value: preAdmissaoDocumentos.emissaoDoc,
        },
        {
          label: "ufDoc",
          value: preAdmissaoDocumentos.ufDoc,
        },
        {
          label: "ctps",
          value: preAdmissaoDocumentos.ctps,
        },
        {
          label: "seriectps",
          value: preAdmissaoDocumentos.serieCtps,
        },
        {
          label: "ufCtpsDp",
          value: preAdmissaoDocumentos.ufCtps,
        },
        {
          label: "emissaoctps",
          value: preAdmissaoDocumentos.emissaoCtps,
        },
      ];

      const stepQuatroErrors = validateFields(stepQuatroFields);

      if (preAdmissaoDocumentos.documentos.length === 0) {
        stepQuatroErrors.push(
          'Você precisa adicionar no mínimo 1 documento no campo "Documentos"'
        );
      }
      if (stepQuatroErrors.length !== 0) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(stepQuatroErrors);
        return;
      }
    }

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    setActiveStep(activeStep - 1);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      if (activeStep === 5) {
        let hasError = false;

        if (preAdmissaoDependentes?.possuiDependentes === null) {
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackErrorList([
            'O Campo "Possui dependentes?" é obrigatório.',
          ]);
          return;
        }

        if (preAdmissaoDependentes?.possuiDependentes) {
          preAdmissaoDependentes?.dependentes.forEach((dependente) => {
            let dependentesFields = [
              {
                label: "nome",
                value: dependente.nome,
              },
              {
                label: "cpf",
                value: dependente.cpf,
              },
              {
                label: "datanascimento",
                value: dependente.dataNascimento,
              },
              {
                label: "inicioDependencia",
                value: dependente.inicioDependencia,
              },
              {
                label: "paisNascimento",
                value: dependente.paisNascimento,
              },
              {
                label: "nacionalidadeDp",
                value: dependente.nacionalidade,
              },
              {
                label: "telefone",
                value: dependente.telefone,
              },
            ];

            if (!dependente.mesmoEndereco) {
              dependentesFields = [
                ...dependentesFields,
                {
                  label: "cep",
                  value: dependente.cep,
                },
                {
                  label: "rua",
                  value: dependente.rua,
                },
                {
                  label: "numero",
                  value: dependente.numero,
                },
                {
                  label: "bairro",
                  value: dependente.bairro,
                },
                {
                  label: "cidade",
                  value: dependente.cidade,
                },
                {
                  label: "estado",
                  value: dependente.estado,
                },
                {
                  label: "paisDp",
                  value: dependente.pais,
                },
              ];
            }

            const dependentesErrors = validateFields(dependentesFields);
            if (dependentesErrors.length !== 0) {
              hasError = true;
              setCallbackShown(true);
              setCallbackType("error");
              setCallbackMessage("Erro!");
              setCallbackErrorList(dependentesErrors);
              return;
            }
          });
          if (hasError) return;
        }
      }

      setLoading(true);
      const formData = new FormData();

      formData.append("hash", hash);
      formData.append("id_empresa", idEmpresa);
      formData.append("nome", preAdmissaoDadosContratuais.nomeFuncionario);

      if (preAdmissaoDadosContratuais.dataAdmissao != null) {
        formData.append(
          "data_admissao",
          preAdmissaoDadosContratuais.dataAdmissao
        );
      }
      if (preAdmissaoDadosContratuais.diasExperiencia != null) {
        formData.append(
          "dias_contrato_experiencia",
          preAdmissaoDadosContratuais.diasExperiencia
        );
      }
      if (preAdmissaoDadosContratuais.resultadoAtestadoOcupacional != null) {
        formData.append(
          "apto_trabalhar",
          preAdmissaoDadosContratuais.resultadoAtestadoOcupacional ? "1" : "0"
        );
      }
      if (preAdmissaoDadosContratuais.dataExameAdmissional != null) {
        formData.append(
          "data_exame_admissional",
          preAdmissaoDadosContratuais.dataExameAdmissional
        );
      }
      if (preAdmissaoDadosContratuais.tipoAdmissao != null) {
        formData.append(
          "id_tipo_admissao",
          preAdmissaoDadosContratuais.tipoAdmissao
        );
      }
      if (preAdmissaoDadosContratuais.optantePorAdiantamento != null) {
        formData.append(
          "optante_por_adiantamento",
          preAdmissaoDadosContratuais.optantePorAdiantamento ? 1 : 0
        );
      }
      if (preAdmissaoDadosContratuais.admissaoDuranteSD ? "1" : "0" != null) {
        formData.append(
          "admissao_seguro_acao_fiscal",
          preAdmissaoDadosContratuais.admissaoDuranteSD ? "1" : "0"
        );
      }
      if (preAdmissaoDadosContratuais.vinculoTrabalista != null) {
        formData.append(
          "id_vinculo_trabalhista",
          preAdmissaoDadosContratuais.vinculoTrabalista
        );
      }
      if (preAdmissaoDadosContratuais.categoria != null) {
        formData.append(
          "id_categoria_funcionario",
          preAdmissaoDadosContratuais.categoria
        );
      }
      if (preAdmissaoDadosContratuais.departamento != null) {
        formData.append(
          "id_departamento_funcionario",
          preAdmissaoDadosContratuais.departamento
        );
      }
      if (preAdmissaoDadosContratuais.funcao != null) {
        formData.append("funcao", preAdmissaoDadosContratuais.funcao);
      }
      if (preAdmissaoDadosContratuais.salarioBase != null) {
        formData.append(
          "salario_base",
          preAdmissaoDadosContratuais.salarioBase
        );
      }
      if (preAdmissaoDadosContratuais.formaPagamento) {
        formData.append(
          "id_forma_pagamento_salario",
          preAdmissaoDadosContratuais.formaPagamento
        );
      }
      if (preAdmissaoDadosContratuais.terminoContrato) {
        formData.append(
          "data_termino_contrato",
          preAdmissaoDadosContratuais.terminoContrato
        );
      }
      if (preAdmissaoBeneficios.valeAlimentacao.valor != null) {
        formData.append(
          "va_valor",
          preAdmissaoBeneficios.valeAlimentacao.valor
        );
      }
      if (preAdmissaoBeneficios.valeAlimentacao.formaPagamento != null) {
        formData.append(
          "va_id_forma_pagamento",
          preAdmissaoBeneficios.valeAlimentacao.formaPagamento
        );
      }
      if (preAdmissaoBeneficios.valeAlimentacao.descontado != null) {
        formData.append(
          "va_descontado",
          preAdmissaoBeneficios.valeAlimentacao.descontado ? 1 : 0
        );
      }
      if (preAdmissaoBeneficios.valeAlimentacao.tipoDesconto != null) {
        formData.append(
          "va_tipo_desconto",
          preAdmissaoBeneficios.valeAlimentacao.tipoDesconto
        );
      }
      if (preAdmissaoBeneficios.valeAlimentacao.valorDescontado != null) {
        formData.append(
          "va_valor_descontado",
          preAdmissaoBeneficios.valeAlimentacao.valorDescontado
        );
      }
      if (preAdmissaoBeneficios.valeRefeicao.valor) {
        formData.append("vr_valor", preAdmissaoBeneficios.valeRefeicao.valor);
      }
      if (preAdmissaoBeneficios.valeRefeicao.formaPagamento != null) {
        formData.append(
          "vr_id_forma_pagamento",
          preAdmissaoBeneficios.valeRefeicao.formaPagamento
        );
      }
      if (preAdmissaoBeneficios.valeRefeicao.descontado != null) {
        formData.append(
          "vr_descontado",
          preAdmissaoBeneficios.valeRefeicao.descontado ? 1 : 0
        );
      }
      if (preAdmissaoBeneficios.valeRefeicao.tipoDesconto != null) {
        formData.append(
          "vr_tipo_desconto",
          preAdmissaoBeneficios.valeRefeicao.tipoDesconto
        );
      }
      if (preAdmissaoBeneficios.valeRefeicao.valorDescontado != null) {
        formData.append(
          "vr_valor_descontado",
          preAdmissaoBeneficios.valeRefeicao.valorDescontado
        );
      }
      if (preAdmissaoBeneficios.assistenciaMedica.operadora != null) {
        formData.append(
          "ass_medica_operadora",
          preAdmissaoBeneficios.assistenciaMedica.operadora
        );
      }
      if (preAdmissaoBeneficios.assistenciaMedica.plano != null) {
        formData.append(
          "ass_medica_plano",
          preAdmissaoBeneficios.assistenciaMedica.plano
        );
      }
      if (preAdmissaoBeneficios.assistenciaMedica.valor != null) {
        formData.append(
          "ass_medica_valor",
          preAdmissaoBeneficios.assistenciaMedica.valor
        );
      }
      if (
        preAdmissaoBeneficios.assistenciaMedica.descontado ? "1" : "0" != null
      ) {
        formData.append(
          "ass_medica_descontado",
          preAdmissaoBeneficios.assistenciaMedica.descontado ? "1" : "0"
        );
      }
      if (preAdmissaoBeneficios.assistenciaMedica.tipoDesconto != null) {
        formData.append(
          "ass_medica_tipo_desconto",
          preAdmissaoBeneficios.assistenciaMedica.tipoDesconto
        );
      }
      if (preAdmissaoBeneficios.assistenciaMedica.valorDescontado != null) {
        formData.append(
          "ass_medica_valor_descontado",
          preAdmissaoBeneficios.assistenciaMedica.valorDescontado
        );
      }
      if (
        preAdmissaoBeneficios.assistenciaMedica.dependentes ? "1" : "0" != null
      ) {
        formData.append(
          "ass_medica_dependentes",
          preAdmissaoBeneficios.assistenciaMedica.dependentes ? "1" : "0"
        );
      }
      if (
        preAdmissaoBeneficios.assistenciaMedica.inclusoNoPlano
          ? "1"
          : "0" != null
      ) {
        formData.append(
          "ass_medica_dependente_incluso_no_plano",
          preAdmissaoBeneficios.assistenciaMedica.inclusoNoPlano ? "1" : "0"
        );
      }
      if (
        preAdmissaoBeneficios.assistenciaMedica.valorDescontadoDependentes !=
        null
      ) {
        formData.append(
          "ass_medica_dependente_descontado",
          preAdmissaoBeneficios.assistenciaMedica.valorDescontadoDependentes
        );
      }
      if (
        preAdmissaoBeneficios.assistenciaMedica.tipoDescontoDependentes != null
      ) {
        formData.append(
          "ass_medica_dependente_tipo_desconto",
          preAdmissaoBeneficios.assistenciaMedica.tipoDescontoDependentes
        );
      }
      if (preAdmissaoBeneficios.assistenciaOdontologica.operadora != null) {
        formData.append(
          "ass_odonto_operadora",
          preAdmissaoBeneficios.assistenciaOdontologica.operadora
        );
      }
      if (preAdmissaoBeneficios.assistenciaOdontologica.plano != null) {
        formData.append(
          "ass_odonto_plano",
          preAdmissaoBeneficios.assistenciaOdontologica.plano
        );
      }
      if (preAdmissaoBeneficios.assistenciaOdontologica.valor != null) {
        formData.append(
          "ass_odonto_valor",
          preAdmissaoBeneficios.assistenciaOdontologica.valor
        );
      }
      if (
        preAdmissaoBeneficios.assistenciaOdontologica.descontado
          ? "1"
          : "0" != null
      ) {
        formData.append(
          "ass_odonto_descontado",
          preAdmissaoBeneficios.assistenciaOdontologica.descontado ? "1" : "0"
        );
      }
      if (preAdmissaoBeneficios.assistenciaOdontologica.tipoDesconto != null) {
        formData.append(
          "ass_odonto_tipo_desconto",
          preAdmissaoBeneficios.assistenciaOdontologica.tipoDesconto
        );
      }
      if (
        preAdmissaoBeneficios.assistenciaOdontologica.valorDescontado != null
      ) {
        formData.append(
          "ass_odonto_valor_descontado",
          preAdmissaoBeneficios.assistenciaOdontologica.valorDescontado
        );
      }
      if (
        preAdmissaoBeneficios.assistenciaOdontologica.dependentes
          ? "1"
          : "0" != null
      ) {
        formData.append(
          "ass_odonto_dependentes",
          preAdmissaoBeneficios.assistenciaOdontologica.dependentes ? "1" : "0"
        );
      }
      if (
        preAdmissaoBeneficios.assistenciaOdontologica.inclusoNoPlano
          ? "1"
          : "0" != null
      ) {
        formData.append(
          "ass_odonto_dependente_incluso_no_plano",
          preAdmissaoBeneficios.assistenciaOdontologica.inclusoNoPlano
            ? "1"
            : "0"
        );
      }
      if (
        preAdmissaoBeneficios.assistenciaOdontologica.tipoDescontoDependentes !=
        null
      ) {
        formData.append(
          "ass_odonto_dependente_tipo_desconto",
          preAdmissaoBeneficios.assistenciaOdontologica.tipoDescontoDependentes
        );
      }
      if (
        preAdmissaoBeneficios.assistenciaOdontologica
          .valorDescontadoDependentes != null
      ) {
        formData.append(
          "ass_odonto_dependente_valor_descontado",
          preAdmissaoBeneficios.assistenciaOdontologica
            .valorDescontadoDependentes
        );
      }
      if (preAdmissaoBeneficios.seguroVida.descricao != null) {
        formData.append(
          "seguro_vida_descricao",
          preAdmissaoBeneficios.seguroVida.descricao
        );
      }
      if (preAdmissaoBeneficios.seguroVida.seguradora != null) {
        formData.append(
          "seguro_vida_seguradora",
          preAdmissaoBeneficios.seguroVida.seguradora
        );
      }
      if (preAdmissaoBeneficios.seguroVida.valor != null) {
        formData.append(
          "seguro_vida_valor",
          preAdmissaoBeneficios.seguroVida.valor
        );
      }
      if (preAdmissaoBeneficios.seguroVida.descontado != null) {
        formData.append(
          "seguro_vida_descontado",
          preAdmissaoBeneficios.seguroVida.descontado ? 1 : 0
        );
      }
      if (preAdmissaoBeneficios.seguroVida.tipoDesconto != null) {
        formData.append(
          "seguro_vida_tipo_desconto",
          preAdmissaoBeneficios.seguroVida.tipoDesconto
        );
      }
      if (preAdmissaoBeneficios.seguroVida.valorDescontado != null) {
        formData.append(
          "seguro_vida_valor_descontado",
          preAdmissaoBeneficios.seguroVida.valorDescontado
        );
      }
      if (preAdmissaoBeneficios.bolsaAuxilio.instituicao != null) {
        formData.append(
          "bolsa_auxilio_instituicao",
          preAdmissaoBeneficios.bolsaAuxilio.instituicao
        );
      }
      if (preAdmissaoBeneficios.bolsaAuxilio.curso != null) {
        formData.append(
          "bolsa_auxilio_curso",
          preAdmissaoBeneficios.bolsaAuxilio.curso
        );
      }
      if (preAdmissaoBeneficios.bolsaAuxilio.valor != null) {
        formData.append(
          "bolsa_auxilio_valor",
          preAdmissaoBeneficios.bolsaAuxilio.valor
        );
      }
      if (preAdmissaoBeneficios.bolsaAuxilio.descontado ? "1" : "0" != null) {
        formData.append(
          "bolsa_auxilio_descontado",
          preAdmissaoBeneficios.bolsaAuxilio.descontado ? "1" : "0"
        );
      }
      if (preAdmissaoBeneficios.bolsaAuxilio.tipoDesconto != null) {
        formData.append(
          "bolsa_auxilio_tipo_desconto",
          preAdmissaoBeneficios.bolsaAuxilio.tipoDesconto
        );
      }
      if (preAdmissaoBeneficios.bolsaAuxilio.valorDescontado != null) {
        formData.append(
          "bolsa_auxilio_valor_descontado",
          preAdmissaoBeneficios.bolsaAuxilio.valorDescontado
        );
      }
      if (preAdmissaoBeneficios.outros.descricao != null) {
        formData.append(
          "outros_descricao",
          preAdmissaoBeneficios.outros.descricao
        );
      }
      if (preAdmissaoBeneficios.outros.valor != null) {
        formData.append("outros_valor", preAdmissaoBeneficios.outros.valor);
      }
      if (preAdmissaoBeneficios.outros.descontado != null) {
        formData.append(
          "outros_descontado",
          preAdmissaoBeneficios.outros.descontado ? 1 : 0
        );
      }
      if (preAdmissaoBeneficios.outros.tipoDesconto != null) {
        formData.append(
          "outros_tipo_desconto",
          preAdmissaoBeneficios.outros.tipoDesconto
        );
      }
      if (preAdmissaoBeneficios.outros.valorDescontado != null) {
        formData.append(
          "outros_valor_descontado",
          preAdmissaoBeneficios.outros.valorDescontado
        );
      }

      if (preAdmissaoDadosPessoais.imagem != null)
        formData.append("imagem", preAdmissaoDadosPessoais.imagem);
      if (preAdmissaoDadosPessoais.dataNascimento != null)
        formData.append(
          "data_nascimento",
          preAdmissaoDadosPessoais.dataNascimento
        );
      if (preAdmissaoDadosPessoais.estadoCivil != null)
        formData.append(
          "id_estado_civil",
          preAdmissaoDadosPessoais.estadoCivil
        );
      if (preAdmissaoDadosPessoais.sexo != null)
        formData.append("id_sexo", preAdmissaoDadosPessoais.sexo);
      if (preAdmissaoDadosPessoais.cor != null)
        formData.append("id_cor", preAdmissaoDadosPessoais.cor);
      if (preAdmissaoDadosPessoais.escolaridade != null)
        formData.append(
          "id_grau_instrucao",
          preAdmissaoDadosPessoais.escolaridade
        );
      if (preAdmissaoDadosPessoais.nomeMae != null)
        formData.append("nome_mae", preAdmissaoDadosPessoais.nomeMae);
      if (preAdmissaoDadosPessoais.nomePai != null)
        formData.append("nome_pai", preAdmissaoDadosPessoais.nomePai);
      if (preAdmissaoDadosPessoais.paisNascimento != null)
        formData.append(
          "id_pais_nascimento",
          preAdmissaoDadosPessoais.paisNascimento
        );
      if (preAdmissaoDadosPessoais.nacionalidade != null)
        formData.append(
          "id_nacionalidade",
          preAdmissaoDadosPessoais.nacionalidade
        );
      if (preAdmissaoDadosPessoais.estadoNascimento != null)
        formData.append(
          "id_uf_nascimento",
          preAdmissaoDadosPessoais.estadoNascimento
        );
      if (preAdmissaoDadosPessoais.municipioNascimento != null)
        formData.append(
          "id_municipio_nascimento",
          preAdmissaoDadosPessoais.municipioNascimento
        );
      if (preAdmissaoDadosPessoais.possuiDeficiencia != null)
        formData.append(
          "possui_deficiencia",
          preAdmissaoDadosPessoais.possuiDeficiencia ? "1" : "0"
        );
      if (preAdmissaoDadosPessoais.deficiencia != null)
        formData.append(
          "id_tipo_deficiencia",
          preAdmissaoDadosPessoais.deficiencia
        );
      if (preAdmissaoDadosPessoais.observacoes != null)
        formData.append("observacao", preAdmissaoDadosPessoais.observacoes);
      if (preAdmissaoEnderecoContato.cep != null)
        formData.append("cep", preAdmissaoEnderecoContato.cep);
      if (preAdmissaoEnderecoContato.rua != null)
        formData.append("endereco", preAdmissaoEnderecoContato.rua);
      if (preAdmissaoEnderecoContato.numero != null)
        formData.append("numero", preAdmissaoEnderecoContato.numero);
      if (preAdmissaoEnderecoContato.complemento != null)
        formData.append("complemento", preAdmissaoEnderecoContato.complemento);
      if (preAdmissaoEnderecoContato.bairro != null)
        formData.append("bairro", preAdmissaoEnderecoContato.bairro);
      if (preAdmissaoEnderecoContato.cidade != null)
        formData.append("id_cidade", preAdmissaoEnderecoContato.cidade);
      if (preAdmissaoEnderecoContato.estado != null)
        formData.append("id_uf", preAdmissaoEnderecoContato.estado);
      if (preAdmissaoEnderecoContato.pais != null)
        formData.append("id_pais", preAdmissaoEnderecoContato.pais);
      if (preAdmissaoEnderecoContato.email != null)
        formData.append("email", preAdmissaoEnderecoContato.email);
      if (preAdmissaoEnderecoContato.telefone != null)
        formData.append("telefone", preAdmissaoEnderecoContato.telefone);
      if (preAdmissaoEnderecoContato.celular != null)
        formData.append("celular", preAdmissaoEnderecoContato.celular);
      if (preAdmissaoDocumentos.cpf != null)
        formData.append("cpf", onlyUnsignedNumbers(preAdmissaoDocumentos.cpf));
      if (preAdmissaoDocumentos.nisNit != null)
        formData.append("pis", preAdmissaoDocumentos.nisNit);
      if (preAdmissaoDocumentos.emissaoNisNit != null)
        formData.append(
          "data_emissao_pis",
          preAdmissaoDocumentos.emissaoNisNit
        );
      if (preAdmissaoDocumentos.tipoIdentidade != null)
        formData.append(
          "id_tipo_identidade",
          preAdmissaoDocumentos.tipoIdentidade
        );
      if (preAdmissaoDocumentos.numeroDoc != null)
        formData.append("identidade", preAdmissaoDocumentos.numeroDoc);
      if (preAdmissaoDocumentos.emissorDoc != null)
        formData.append(
          "orgao_emissor_identidade",
          preAdmissaoDocumentos.emissorDoc
        );
      if (preAdmissaoDocumentos.emissaoDoc != null)
        formData.append(
          "data_emissao_identidade",
          preAdmissaoDocumentos.emissaoDoc
        );
      if (preAdmissaoDocumentos.ufDoc != null)
        formData.append("id_uf_identidade", preAdmissaoDocumentos.ufDoc);
      if (preAdmissaoDocumentos.ctps != null)
        formData.append("nro_ctps", preAdmissaoDocumentos.ctps);
      if (preAdmissaoDocumentos.serieCtps != null)
        formData.append("serie_ctps", preAdmissaoDocumentos.serieCtps);
      if (preAdmissaoDocumentos.ufCtps != null)
        formData.append("id_uf_ctps", preAdmissaoDocumentos.ufCtps);
      if (preAdmissaoDocumentos.emissaoCtps != null)
        formData.append("data_emissao_ctps", preAdmissaoDocumentos.emissaoCtps);
      if (preAdmissaoDocumentos.tituloEleitor != null)
        formData.append("titulo_eleitor", preAdmissaoDocumentos.tituloEleitor);
      if (preAdmissaoDocumentos.zonaEleitoral != null)
        formData.append("zona_eleitoral", preAdmissaoDocumentos.zonaEleitoral);
      if (preAdmissaoDocumentos.secaoEleitoral != null)
        formData.append(
          "secao_eleitoral",
          preAdmissaoDocumentos.secaoEleitoral
        );
      if (preAdmissaoDocumentos.cnh != null)
        formData.append("carteira_habilitacao", preAdmissaoDocumentos.cnh);
      if (preAdmissaoDocumentos.emissaoCnh != null)
        formData.append("data_emissao_cnh", preAdmissaoDocumentos.emissaoCnh);
      if (preAdmissaoDocumentos.validadeCnh != null)
        formData.append("data_validade_cnh", preAdmissaoDocumentos.validadeCnh);
      if (preAdmissaoDocumentos.categoriaCnh != null)
        formData.append("id_categoria_cnh", preAdmissaoDocumentos.categoriaCnh);
      if (preAdmissaoDocumentos.ufCnh != null)
        formData.append("id_uf_cnh", preAdmissaoDocumentos.ufCnh);
      if (preAdmissaoDocumentos.emissaoPrimeiraCnh != null)
        formData.append(
          "data_primeira_cnh",
          preAdmissaoDocumentos.emissaoPrimeiraCnh
        );
      if (preAdmissaoDocumentos.tipoConta != null)
        formData.append("id_tipo_conta_banco", preAdmissaoDocumentos.tipoConta);
      if (preAdmissaoDocumentos.banco != null)
        formData.append("id_banco", preAdmissaoDocumentos.banco);
      if (preAdmissaoDocumentos.bancoOutros != null)
        formData.append("banco_nome", preAdmissaoDocumentos.bancoOutros);
      if (preAdmissaoDocumentos.agencia != null)
        formData.append("agencia", preAdmissaoDocumentos.agencia);
      if (preAdmissaoDocumentos.conta != null)
        formData.append("conta", preAdmissaoDocumentos.conta);
      if (preAdmissaoDocumentos.digitoConta != null)
        formData.append("digito", preAdmissaoDocumentos.digitoConta);
      if (preAdmissaoDependentes.possuiDependentes != null)
        formData.append("dependente", preAdmissaoDependentes.possuiDependentes);

      preAdmissaoDocumentos.documentos?.forEach((item, index) => {
        if (item.id) {
          formData.append(`documentos[${index}][id]`, item.id);
        }
        formData.append(`documentos[${index}][nome]`, item.nome);
        formData.append(`documentos[${index}][arquivo]`, item.arquivo);
      });

      preAdmissaoBeneficios.valeTransporte?.forEach((item, index) => {
        formData.append(`vt[${index}][descricao]`, item.descricao);
        formData.append(`vt[${index}][valor]`, item.valor);
        formData.append(`vt[${index}][numero_bilhete]`, item.numeroBilhete);
        formData.append(
          `vt[${index}][qtd_passagens_dia]`,
          item.quantidadePassagens
        );
      });

      formData.append(
        "horario[0][trabalha]",
        preAdmissaoDadosContratuais.horariosSemana.segunda ? 1 : 0
      );
      formData.append(
        "horario[0][entrada]",
        preAdmissaoDadosContratuais.horariosSemana.entradaSegunda
      );
      formData.append(
        "horario[0][inicio_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.inicioIntervaloSegunda
      );
      formData.append(
        "horario[0][final_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.fimIntervaloSegunda
      );
      formData.append(
        "horario[0][saida]",
        preAdmissaoDadosContratuais.horariosSemana.saidaSegunda
      );

      formData.append(
        "horario[1][trabalha]",
        preAdmissaoDadosContratuais.horariosSemana.terca ? 1 : 0
      );
      formData.append(
        "horario[1][entrada]",
        preAdmissaoDadosContratuais.horariosSemana.entradaTerca
      );
      formData.append(
        "horario[1][inicio_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.inicioIntervaloTerca
      );
      formData.append(
        "horario[1][final_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.fimIntervaloTerca
      );
      formData.append(
        "horario[1][saida]",
        preAdmissaoDadosContratuais.horariosSemana.saidaTerca
      );

      formData.append(
        "horario[2][trabalha]",
        preAdmissaoDadosContratuais.horariosSemana.quarta ? 1 : 0
      );
      formData.append(
        "horario[2][entrada]",
        preAdmissaoDadosContratuais.horariosSemana.entradaQuarta
      );
      formData.append(
        "horario[2][inicio_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.inicioIntervaloQuarta
      );
      formData.append(
        "horario[2][final_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.fimIntervaloQuarta
      );
      formData.append(
        "horario[2][saida]",
        preAdmissaoDadosContratuais.horariosSemana.saidaQuarta
      );

      formData.append(
        "horario[3][trabalha]",
        preAdmissaoDadosContratuais.horariosSemana.quinta ? 1 : 0
      );
      formData.append(
        "horario[3][entrada]",
        preAdmissaoDadosContratuais.horariosSemana.entradaQuinta
      );
      formData.append(
        "horario[3][inicio_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.inicioIntervaloQuinta
      );
      formData.append(
        "horario[3][final_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.fimIntervaloQuinta
      );
      formData.append(
        "horario[3][saida]",
        preAdmissaoDadosContratuais.horariosSemana.saidaQuinta
      );

      formData.append(
        "horario[4][trabalha]",
        preAdmissaoDadosContratuais.horariosSemana.sexta ? 1 : 0
      );
      formData.append(
        "horario[4][entrada]",
        preAdmissaoDadosContratuais.horariosSemana.entradaSexta
      );
      formData.append(
        "horario[4][inicio_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.inicioIntervaloSexta
      );
      formData.append(
        "horario[4][final_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.fimIntervaloSexta
      );
      formData.append(
        "horario[4][saida]",
        preAdmissaoDadosContratuais.horariosSemana.saidaSexta
      );

      formData.append(
        "horario[5][trabalha]",
        preAdmissaoDadosContratuais.horariosSemana.sabado ? 1 : 0
      );
      formData.append(
        "horario[5][entrada]",
        preAdmissaoDadosContratuais.horariosSemana.entradaSabado
      );
      formData.append(
        "horario[5][inicio_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.inicioIntervaloSabado
      );
      formData.append(
        "horario[5][final_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.fimIntervaloSabado
      );
      formData.append(
        "horario[5][saida]",
        preAdmissaoDadosContratuais.horariosSemana.saidaSabado
      );

      formData.append(
        "horario[6][trabalha]",
        preAdmissaoDadosContratuais.horariosSemana.domingo ? 1 : 0
      );
      formData.append(
        "horario[6][entrada]",
        preAdmissaoDadosContratuais.horariosSemana.entradaDomingo
      );
      formData.append(
        "horario[6][inicio_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.inicioIntervaloDomingo
      );
      formData.append(
        "horario[6][final_intervalo]",
        preAdmissaoDadosContratuais.horariosSemana.fimIntervaloDomingo
      );
      formData.append(
        "horario[6][saida]",
        preAdmissaoDadosContratuais.horariosSemana.saidaDomingo
      );

      if (preAdmissaoDependentes.possuiDependentes) {
        preAdmissaoDependentes.dependentes?.forEach((item, index) => {
          formData.append(`dependentes[${index}][nome]`, item.nome);
          formData.append(
            `dependentes[${index}][cpf]`,
            onlyUnsignedNumbers(item.cpf)
          );
          formData.append(
            `dependentes[${index}][id_parentesco]`,
            item.parentesco
          );
          formData.append(
            `dependentes[${index}][data_nascimento]`,
            item.dataNascimento
          );
          formData.append(
            `dependentes[${index}][data_inicio_dependencia]`,
            item.inicioDependencia
          );
          formData.append(
            `dependentes[${index}][id_pais_nascimento]`,
            item.paisNascimento
          );
          formData.append(
            `dependentes[${index}][id_nacionalidade]`,
            item.nacionalidade
          );
          formData.append(
            `dependentes[${index}][data_entrada_pais]`,
            item.dataEntradaPais
          );
          formData.append(`dependentes[${index}][telefone]`, item.telefone);
          formData.append(
            `dependentes[${index}][possui_deficiencia]`,
            item.possuiDeficiencia ? 1 : 0
          );
          formData.append(
            `dependentes[${index}][incluso_no_plano_de_saude]`,
            item.inclusoPlanoSaude ? 1 : 0
          );
          formData.append(
            `dependentes[${index}][incluso_no_plano_odontologico]`,
            item.inclusoPlanoOdontologico ? 1 : 0
          );
          formData.append(
            `dependentes[${index}][id_tipo_deficiencia]`,
            item.deficiencia
          );
          formData.append(
            `dependentes[${index}][mesmo_endereco_funcionario]`,
            item.mesmoEndereco ? 1 : 0
          );
          if (!item.mesmoEndereco) {
            formData.append(`dependentes[${index}][cep]`, item.cep);
            formData.append(`dependentes[${index}][rua]`, item.rua);
            formData.append(`dependentes[${index}][numero]`, item.numero);
            formData.append(
              `dependentes[${index}][complemento]`,
              item.complemento
            );
            formData.append(`dependentes[${index}][bairro]`, item.bairro);
            formData.append(`dependentes[${index}][id_cidade]`, item.cidade);
            formData.append(`dependentes[${index}][id_estado]`, item.estado);
            formData.append(`dependentes[${index}][id_pais]`, item.pais);
          }
          formData.append(
            `dependentes[${index}][declara_no_irpf]`,
            item.declaraDependente ? 1 : 0
          );
          formData.append(
            `dependentes[${index}][possui_salario_familia]`,
            item.salarioFamilia ? 1 : 0
          );
          if (item.certidaoNascimento)
            formData.append(
              `dependentes[${index}][certidao_nascimento]`,
              item.certidaoNascimento
            );
          if (item.cadernetaVacinacao)
            formData.append(
              `dependentes[${index}][caderneta_vacinacao]`,
              item.cadernetaVacinacao
            );
          formData.append(
            `dependentes[${index}][certidao_nascimento_base64]`,
            item.certidaoNascimentoBase64
          );
          formData.append(
            `dependentes[${index}][caderneta_vacinacao_base64]`,
            item.cadernetaVacinacaoBase64
          );
        });
      }

      const response = await editarPreAdmissao(id, formData);
      console.log(response);
      setLoading(false);
      if (response?.data?.success) {
        setCallbackShown(true);
        setCallbackType("success");
        setCallbackMessage(
          response?.data?.message || "Informações atualizadas!"
        );
        setCallbackDuration(2000);
      } else if (typeof response?.data?.error === "string") {
        setLoading(false);
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage(response?.data?.msg || "Erro!");
        setCallbackDuration(4000);
        setCallbackErrorList([response?.data?.error]);
      } else if (typeof response?.data?.error === "object") {
        setLoading(false);
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackDuration(4000);
        setCallbackMessage(response?.data?.msg || "Erro!");
        setCallbackErrorList(
          Object.values(response?.data?.error).flat(Infinity)
        );
      } else {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(["Erro ao cadastrar pré-admissão"]);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      if (locationState?.from) {
        history.push(locationState?.from);
      } else {
        history.push("/pre-admissao");
      }
    }
  }

  const handleClickStep = (newStep) => {
    if (locationState?.activeStepNavigation) {
      setActiveStep(newStep);
    }
  };

  async function loadPreadmissao() {
    try {
      const response = await consultaPreAdmissao(id);
      if (response.status === 400) {
        setCallbackType("error");
        setCallbackMessage(
          response.data.msg || response.data.message || "Erro!"
        );
        setCallbackErrorList(
          [response.data.error] || ["Erro ao consultar Pré admissão!"]
        );
        setLoading(false);
        setCallbackShown(true);
        setId(null);
        return;
      }
      console.log(response);
      if (response?.data?.success && response?.data?.funcionario) {
        const { funcionario } = response.data;
        console.log(funcionario?.apto_trabalhar || "nullsddsd");
        setIdEmpresa(funcionario?.id_empresa);
        setHash(funcionario?.hash);

        const documentos = funcionario?.documentos?.map((item) => {
          return {
            id: item.id,
            arquivo: item.documento,
            nome: item.nome_doc,
          };
        });

        setPreAdmissaoDadosContratuais({
          // DADOS CONTRATUAIS
          nomeFuncionario: funcionario?.nome != null ? funcionario?.nome : null,
          dataAdmissao:
            funcionario?.data_admissao != null
              ? funcionario?.data_admissao
              : null,
          diasExperiencia:
            funcionario?.dias_contrato_experiencia != null
              ? funcionario?.dias_contrato_experiencia
              : null,
          dataExameAdmissional:
            funcionario?.data_exame_admissional != null
              ? funcionario?.data_exame_admissional
              : null,
          optantePorAdiantamento:
            funcionario?.optante_por_adiantamento != null
              ? funcionario?.optante_por_adiantamento
              : null,
          admissaoDuranteSD:
            funcionario?.admissao_seguro_acao_fiscal != null
              ? funcionario?.admissao_seguro_acao_fiscal
              : null,
          departamento:
            funcionario?.id_departamento_funcionario != null
              ? funcionario?.id_departamento_funcionario
              : null,
          salarioBase:
            funcionario?.salario_base != null
              ? funcionario?.salario_base
              : null,
          terminoContrato:
            funcionario?.data_termino_contrato != null
              ? funcionario?.data_termino_contrato
              : null,
          resultadoAtestadoOcupacional:
            funcionario?.apto_trabalhar != null
              ? funcionario?.apto_trabalhar
              : null,
          tipoAdmissao:
            funcionario?.id_tipo_admissao != null
              ? funcionario?.id_tipo_admissao
              : null,
          vinculoTrabalista:
            funcionario?.id_vinculo_trabalhista != null
              ? funcionario?.id_vinculo_trabalhista
              : null,
          categoria:
            funcionario?.id_categoria_funcionario != null
              ? funcionario?.id_categoria_funcionario
              : null,
          funcao: funcionario?.funcao != null ? funcionario?.funcao : null,
          formaPagamento:
            funcionario?.id_forma_pagamento_salario != null
              ? funcionario?.id_forma_pagamento_salario
              : null,
          horariosSemana: {
            segunda:
              funcionario?.horarios &&
              funcionario?.horarios[0]?.trabalha != null
                ? funcionario?.horarios[0]?.trabalha
                : null,
            entradaSegunda:
              funcionario?.horarios && funcionario?.horarios[0]?.entrada != null
                ? funcionario?.horarios[0]?.entrada?.substring(0, 5)
                : null,
            inicioIntervaloSegunda:
              funcionario?.horarios &&
              funcionario?.horarios[0]?.inicio_intervalo != null
                ? funcionario?.horarios[0]?.inicio_intervalo?.substring(0, 5)
                : null,
            fimIntervaloSegunda:
              funcionario?.horarios &&
              funcionario?.horarios[0]?.final_intervalo != null
                ? funcionario?.horarios[0]?.final_intervalo?.substring(0, 5)
                : null,
            saidaSegunda:
              funcionario?.horarios && funcionario?.horarios[0]?.saida != null
                ? funcionario?.horarios[0]?.saida?.substring(0, 5)
                : null,
            terca:
              funcionario?.horarios &&
              funcionario?.horarios[1]?.trabalha != null
                ? funcionario?.horarios[1]?.trabalha
                : null,
            entradaTerca:
              funcionario?.horarios && funcionario?.horarios[1]?.entrada != null
                ? funcionario?.horarios[1]?.entrada?.substring(0, 5)
                : null,
            inicioIntervaloTerca:
              funcionario?.horarios &&
              funcionario?.horarios[1]?.inicio_intervalo != null
                ? funcionario?.horarios[1]?.inicio_intervalo?.substring(0, 5)
                : null,
            fimIntervaloTerca:
              funcionario?.horarios &&
              funcionario?.horarios[1]?.final_intervalo != null
                ? funcionario?.horarios[1]?.final_intervalo?.substring(0, 5)
                : null,
            saidaTerca:
              funcionario?.horarios && funcionario?.horarios[1]?.saida != null
                ? funcionario?.horarios[1]?.saida?.substring(0, 5)
                : null,
            quarta:
              funcionario?.horarios &&
              funcionario?.horarios[2]?.trabalha != null
                ? funcionario?.horarios[2]?.trabalha
                : null,
            entradaQuarta:
              funcionario?.horarios && funcionario?.horarios[2]?.entrada != null
                ? funcionario?.horarios[2]?.entrada?.substring(0, 5)
                : null,
            inicioIntervaloQuarta:
              funcionario?.horarios &&
              funcionario?.horarios[2]?.inicio_intervalo != null
                ? funcionario?.horarios[2]?.inicio_intervalo?.substring(0, 5)
                : null,
            fimIntervaloQuarta:
              funcionario?.horarios &&
              funcionario?.horarios[2]?.final_intervalo != null
                ? funcionario?.horarios[2]?.final_intervalo?.substring(0, 5)
                : null,
            saidaQuarta:
              funcionario?.horarios && funcionario?.horarios[2]?.saida != null
                ? funcionario?.horarios[2]?.saida?.substring(0, 5)
                : null,
            quinta:
              funcionario?.horarios &&
              funcionario?.horarios[3]?.trabalha != null
                ? funcionario?.horarios[3]?.trabalha
                : null,
            entradaQuinta:
              funcionario?.horarios && funcionario?.horarios[3]?.entrada != null
                ? funcionario?.horarios[3]?.entrada?.substring(0, 5)
                : null,
            inicioIntervaloQuinta:
              funcionario?.horarios &&
              funcionario?.horarios[3]?.inicio_intervalo != null
                ? funcionario?.horarios[3]?.inicio_intervalo?.substring(0, 5)
                : null,
            fimIntervaloQuinta:
              funcionario?.horarios &&
              funcionario?.horarios[3]?.final_intervalo != null
                ? funcionario?.horarios[3]?.final_intervalo?.substring(0, 5)
                : null,
            saidaQuinta:
              funcionario?.horarios && funcionario?.horarios[3]?.saida != null
                ? funcionario?.horarios[3]?.saida?.substring(0, 5)
                : null,
            sexta:
              funcionario?.horarios &&
              funcionario?.horarios[4]?.trabalha != null
                ? funcionario?.horarios[4]?.trabalha
                : null,
            entradaSexta:
              funcionario?.horarios && funcionario?.horarios[4]?.entrada != null
                ? funcionario?.horarios[4]?.entrada?.substring(0, 5)
                : null,
            inicioIntervaloSexta:
              funcionario?.horarios &&
              funcionario?.horarios[4]?.inicio_intervalo != null
                ? funcionario?.horarios[4]?.inicio_intervalo?.substring(0, 5)
                : null,
            fimIntervaloSexta:
              funcionario?.horarios &&
              funcionario?.horarios[4]?.final_intervalo != null
                ? funcionario?.horarios[4]?.final_intervalo?.substring(0, 5)
                : null,
            saidaSexta:
              funcionario?.horarios && funcionario?.horarios[4]?.saida != null
                ? funcionario?.horarios[4]?.saida?.substring(0, 5)
                : null,
            sabado:
              funcionario?.horarios &&
              funcionario?.horarios[5]?.trabalha != null
                ? funcionario?.horarios[5]?.trabalha
                : null,
            entradaSabado:
              funcionario?.horarios && funcionario?.horarios[5]?.entrada != null
                ? funcionario?.horarios[5]?.entrada?.substring(0, 5)
                : null,
            inicioIntervaloSabado:
              funcionario?.horarios &&
              funcionario?.horarios[5]?.inicio_intervalo != null
                ? funcionario?.horarios[5]?.inicio_intervalo?.substring(0, 5)
                : null,
            fimIntervaloSabado:
              funcionario?.horarios &&
              funcionario?.horarios[5]?.final_intervalo != null
                ? funcionario?.horarios[5]?.final_intervalo?.substring(0, 5)
                : null,
            saidaSabado:
              funcionario?.horarios && funcionario?.horarios[5]?.saida != null
                ? funcionario?.horarios[5]?.saida?.substring(0, 5)
                : null,
            domingo:
              funcionario?.horarios &&
              funcionario?.horarios[6]?.trabalha != null
                ? funcionario?.horarios[6]?.trabalha
                : null,
            entradaDomingo:
              funcionario?.horarios && funcionario?.horarios[6]?.entrada != null
                ? funcionario?.horarios[6]?.entrada?.substring(0, 5)
                : null,
            inicioIntervaloDomingo:
              funcionario?.horarios &&
              funcionario?.horarios[6]?.inicio_intervalo != null
                ? funcionario?.horarios[6]?.inicio_intervalo?.substring(0, 5)
                : null,
            fimIntervaloDomingo:
              funcionario?.horarios &&
              funcionario?.horarios[6]?.final_intervalo != null
                ? funcionario?.horarios[6]?.final_intervalo?.substring(0, 5)
                : null,
            saidaDomingo:
              funcionario?.horarios && funcionario?.horarios[6]?.saida
                ? funcionario?.horarios[6]?.saida?.substring(0, 5)
                : null,
          },
        });

        let vt = [
          {
            descricao: null,
            quantidadePassagens: null,
            valor: null,
            numeroBilhete: null,
          },
        ];
        if (funcionario?.vt?.length > 0) {
          vt = funcionario?.vt?.map((item) => ({
            descricao: item.descricao != null ? item.descricao : null,
            quantidadePassagens:
              item.qtd_passagens_dia != null ? item.qtd_passagens_dia : null,
            valor: item.valor != null ? item.valor : null,
            numeroBilhete:
              item.numero_bilhete != null ? item.numero_bilhete : null,
          }));
        }
        console.log(vt);
        setPreAdmissaoBeneficios({
          valeTransporte: vt,
          valeAlimentacao: {
            valor:
              funcionario?.beneficios?.va_valor != null
                ? funcionario?.beneficios?.va_valor
                : null,
            formaPagamento:
              funcionario?.beneficios?.va_id_forma_pagamento != null
                ? funcionario?.beneficios?.va_id_forma_pagamento
                : null,
            descontado: funcionario?.beneficios?.va_descontado
              ? funcionario?.beneficios?.va_descontado
              : null,
            tipoDesconto:
              funcionario?.beneficios?.va_tipo_desconto != null
                ? funcionario?.beneficios?.va_tipo_desconto
                : null,
            valorDescontado:
              funcionario?.beneficios?.va_valor_descontado != null
                ? funcionario?.beneficios?.va_valor_descontado
                : null,
          },
          valeRefeicao: {
            valor:
              funcionario?.beneficios?.vr_valor != null
                ? funcionario?.beneficios?.vr_valor
                : null,
            formaPagamento:
              funcionario?.beneficios?.vr_id_forma_pagamento != null
                ? funcionario?.beneficios?.vr_id_forma_pagamento
                : null,
            descontado: funcionario?.beneficios?.vr_descontado
              ? funcionario?.beneficios?.vr_descontado
              : null,
            tipoDesconto:
              funcionario?.beneficios?.vr_tipo_desconto != null
                ? funcionario?.beneficios?.vr_tipo_desconto
                : null,
            valorDescontado:
              funcionario?.beneficios?.vr_valor_descontado != null
                ? funcionario?.beneficios?.vr_valor_descontado
                : null,
          },
          assistenciaMedica: {
            operadora:
              funcionario?.beneficios?.ass_medica_operadora != null
                ? funcionario?.beneficios?.ass_medica_operadora
                : null,
            plano:
              funcionario?.beneficios?.ass_medica_plano != null
                ? funcionario?.beneficios?.ass_medica_plano
                : null,
            valor:
              funcionario?.beneficios?.ass_medica_valor != null
                ? funcionario?.beneficios?.ass_medica_valor
                : null,
            descontado:
              funcionario?.beneficios?.ass_medica_descontado != null
                ? funcionario?.beneficios?.ass_medica_descontado
                : null,
            tipoDesconto:
              funcionario?.beneficios?.ass_medica_tipo_desconto != null
                ? funcionario?.beneficios?.ass_medica_tipo_desconto
                : null,
            valorDescontado:
              funcionario?.beneficios?.ass_medica_valor_descontado != null
                ? funcionario?.beneficios?.ass_medica_valor_descontado
                : null,
            dependentes:
              funcionario?.beneficios?.ass_medica_dependentes != null
                ? funcionario?.beneficios?.ass_medica_dependentes
                : null,
            inclusoNoPlano:
              funcionario?.beneficios?.ass_medica_dependente_incluso_no_plano !=
              null
                ? funcionario?.beneficios
                    ?.ass_medica_dependente_incluso_no_plano
                : null,
            tipoDescontoDependentes:
              funcionario?.beneficios?.ass_medica_dependente_tipo_desconto !=
              null
                ? funcionario?.beneficios?.ass_medica_dependente_tipo_desconto
                : null,
            valorDescontadoDependentes:
              funcionario?.beneficios?.ass_medica_dependente_valor_descontado !=
              null
                ? funcionario?.beneficios
                    ?.ass_medica_dependente_valor_descontado
                : null,
          },
          assistenciaOdontologica: {
            operadora:
              funcionario?.beneficios?.ass_odonto_operadora != null
                ? funcionario?.beneficios?.ass_odonto_operadora
                : null,
            plano:
              funcionario?.beneficios?.ass_odonto_plano != null
                ? funcionario?.beneficios?.ass_odonto_plano
                : null,
            valor:
              funcionario?.beneficios?.ass_odonto_valor != null
                ? funcionario?.beneficios?.ass_odonto_valor
                : null,
            descontado:
              funcionario?.beneficios?.ass_odonto_descontado != null
                ? funcionario?.beneficios?.ass_odonto_descontado
                : null,
            tipoDesconto:
              funcionario?.beneficios?.ass_odonto_tipo_desconto != null
                ? funcionario?.beneficios?.ass_odonto_tipo_desconto
                : null,
            valorDescontado:
              funcionario?.beneficios?.ass_odonto_valor_descontado != null
                ? funcionario?.beneficios?.ass_odonto_valor_descontado
                : null,
            dependentes:
              funcionario?.beneficios?.ass_odonto_dependentes != null
                ? funcionario?.beneficios?.ass_odonto_dependentes
                : null,
            inclusoNoPlano:
              funcionario?.beneficios?.ass_odonto_dependente_incluso_no_plano !=
              null
                ? funcionario?.beneficios
                    ?.ass_odonto_dependente_incluso_no_plano
                : null,
            tipoDescontoDependentes:
              funcionario?.beneficios?.ass_odonto_dependente_tipo_desconto !=
              null
                ? funcionario?.beneficios?.ass_odonto_dependente_tipo_desconto
                : null,
            valorDescontadoDependentes:
              funcionario?.beneficios?.ass_odonto_dependente_valor_descontado !=
              null
                ? funcionario?.beneficios
                    ?.ass_odonto_dependente_valor_descontado
                : null,
          },
          seguroVida: {
            descricao:
              funcionario?.beneficios?.seguro_vida_descricao != null
                ? funcionario?.beneficios?.seguro_vida_descricao
                : null,
            seguradora:
              funcionario?.beneficios?.seguro_vida_seguradora != null
                ? funcionario?.beneficios?.seguro_vida_seguradora
                : null,
            valor:
              funcionario?.beneficios?.seguro_vida_valor != null
                ? funcionario?.beneficios?.seguro_vida_valor
                : null,
            descontado:
              funcionario?.beneficios?.seguro_vida_descontado != null
                ? funcionario?.beneficios?.seguro_vida_descontado
                : null,
            tipoDesconto:
              funcionario?.beneficios?.seguro_vida_tipo_desconto != null
                ? funcionario?.beneficios?.seguro_vida_tipo_desconto
                : null,
            valorDescontado:
              funcionario?.beneficios?.seguro_vida_valor_descontado != null
                ? funcionario?.beneficios?.seguro_vida_valor_descontado
                : null,
          },
          bolsaAuxilio: {
            instituicao:
              funcionario?.beneficios?.bolsa_auxilio_instituicao != null
                ? funcionario?.beneficios?.bolsa_auxilio_instituicao
                : null,
            curso:
              funcionario?.beneficios?.bolsa_auxilio_curso != null
                ? funcionario?.beneficios?.bolsa_auxilio_curso
                : null,
            valor:
              funcionario?.beneficios?.bolsa_auxilio_valor != null
                ? funcionario?.beneficios?.bolsa_auxilio_valor
                : null,
            descontado:
              funcionario?.beneficios?.bolsa_auxilio_descontado != null
                ? funcionario?.beneficios?.bolsa_auxilio_descontado
                : null,
            tipoDesconto:
              funcionario?.beneficios?.bolsa_auxilio_tipo_desconto != null
                ? funcionario?.beneficios?.bolsa_auxilio_tipo_desconto
                : null,
            valorDescontado:
              funcionario?.beneficios?.bolsa_auxilio_valor_descontado != null
                ? funcionario?.beneficios?.bolsa_auxilio_valor_descontado
                : null,
          },
          outros: {
            descricao:
              funcionario?.beneficios?.outros_descricao != null
                ? funcionario?.beneficios?.outros_descricao
                : null,
            valor:
              funcionario?.beneficios?.outros_valor != null
                ? funcionario?.beneficios?.outros_valor
                : null,
            descontado:
              funcionario?.beneficios?.outros_descontado != null
                ? funcionario?.beneficios?.outros_descontado
                : null,
            tipoDesconto:
              funcionario?.beneficios?.outros_tipo_desconto != null
                ? funcionario?.beneficios?.outros_tipo_desconto
                : null,
            valorDescontado:
              funcionario?.beneficios?.outros_valor_descontado != null
                ? funcionario?.beneficios?.outros_valor_descontado
                : null,
          },
        });

        setPreAdmissaoDadosPessoais({
          hasImagem: funcionario?.foto != null ? funcionario?.foto : null,
          imagemPreview:
            funcionario?.foto != null
              ? "data:image/png;base64," + funcionario?.foto
              : null,
          dataNascimento:
            funcionario?.data_nascimento != null
              ? funcionario?.data_nascimento
              : null,
          estadoCivil:
            funcionario?.id_estado_civil != null
              ? funcionario?.id_estado_civil
              : null,
          sexo: funcionario?.id_sexo != null ? funcionario?.id_sexo : null,
          cor: funcionario?.id_cor != null ? funcionario?.id_cor : null,
          escolaridade:
            funcionario?.id_grau_instrucao != null
              ? funcionario?.id_grau_instrucao
              : null,
          nomeMae: funcionario?.nome_mae != null ? funcionario?.nome_mae : null,
          nomePai: funcionario?.nome_pai != null ? funcionario?.nome_pai : null,
          paisNascimento:
            funcionario?.id_pais_nascimento != null
              ? funcionario?.id_pais_nascimento
              : null,
          nacionalidade:
            funcionario?.id_nacionalidade != null
              ? funcionario?.id_nacionalidade
              : null,
          estadoNascimento:
            funcionario?.id_uf_nascimento != null
              ? funcionario?.id_uf_nascimento
              : null,
          municipioNascimento:
            funcionario?.id_municipio_nascimento != null
              ? funcionario?.id_municipio_nascimento
              : null,
          possuiDeficiencia:
            funcionario?.possui_deficiencia != null
              ? funcionario?.possui_deficiencia
              : null,
          deficiencia:
            funcionario?.id_tipo_deficiencia != null
              ? funcionario?.id_tipo_deficiencia
              : null,
          observacoes:
            funcionario?.observacao != null ? funcionario?.observacao : null,
        });

        setPreAdmissaoEnderecoContato({
          cep: funcionario?.cep != null ? funcionario?.cep : null,
          rua: funcionario?.endereco != null ? funcionario?.endereco : null,
          numero: funcionario?.numero != null ? funcionario?.numero : null,
          bairro: funcionario?.bairro != null ? funcionario?.bairro : null,
          cidade:
            funcionario?.id_cidade != null ? funcionario?.id_cidade : null,
          estado: funcionario?.id_uf != null ? funcionario?.id_uf : null,
          pais: funcionario?.id_pais != null ? funcionario?.id_pais : null,
          email: funcionario?.email != null ? funcionario?.email : null,
          telefone:
            funcionario?.telefone != null ? funcionario?.telefone : null,
          complemento:
            funcionario?.complemento != null ? funcionario?.complemento : null,
          celular: funcionario?.celular != null ? funcionario?.celular : null,
        });

        setPreAdmissaoDocumentos({
          cpf: funcionario?.cpf != null ? funcionario?.cpf : null,
          nisNit: funcionario?.pis != null ? funcionario?.pis : null,
          tipoIdentidade:
            funcionario?.id_tipo_identidade != null
              ? funcionario?.id_tipo_identidade
              : null,
          numeroDoc:
            funcionario?.identidade != null ? funcionario?.identidade : null,
          emissorDoc:
            funcionario?.orgao_emissor_identidade != null
              ? funcionario?.orgao_emissor_identidade
              : null,
          emissaoDoc:
            funcionario?.data_emissao_identidade != null
              ? funcionario?.data_emissao_identidade
              : null,
          ufDoc:
            funcionario?.id_uf_identidade != null
              ? funcionario?.id_uf_identidade
              : null,
          ctps: funcionario?.nro_ctps != null ? funcionario?.nro_ctps : null,
          serieCtps:
            funcionario?.serie_ctps != null ? funcionario?.serie_ctps : null,
          ufCtps:
            funcionario?.id_uf_ctps != null ? funcionario?.id_uf_ctps : null,
          emissaoCtps:
            funcionario?.data_emissao_ctps != null
              ? funcionario?.data_emissao_ctps
              : null,
          emissaoNisNit:
            funcionario?.data_emissao_pis != null
              ? funcionario?.data_emissao_pis
              : null,
          tituloEleitor:
            funcionario?.titulo_eleitor != null
              ? funcionario?.titulo_eleitor
              : null,
          zonaEleitoral:
            funcionario?.zona_eleitoral != null
              ? funcionario?.zona_eleitoral
              : null,
          secaoEleitoral:
            funcionario?.secao_eleitoral != null
              ? funcionario?.secao_eleitoral
              : null,
          cnh:
            funcionario?.carteira_habilitacao != null
              ? funcionario?.carteira_habilitacao
              : null,
          emissaoCnh:
            funcionario?.data_emissao_cnh != null
              ? funcionario?.data_emissao_cnh
              : null,
          validadeCnh:
            funcionario?.data_validade_cnh != null
              ? funcionario?.data_validade_cnh
              : null,
          categoriaCnh:
            funcionario?.id_categoria_cnh != null
              ? funcionario?.id_categoria_cnh
              : null,
          ufCnh: funcionario?.id_uf_cnh != null ? funcionario?.id_uf_cnh : null,
          emissaoPrimeiraCnh:
            funcionario?.data_primeira_cnh != null
              ? funcionario?.data_primeira_cnh
              : null,
          tipoConta:
            funcionario?.id_tipo_conta_banco != null
              ? funcionario?.id_tipo_conta_banco
              : null,
          banco: funcionario?.id_banco != null ? funcionario?.id_banco : null,
          bancoOutros:
            funcionario?.banco_nome != null ? funcionario?.banco_nome : null,
          agencia: funcionario?.agencia != null ? funcionario?.agencia : null,
          conta: funcionario?.conta != null ? funcionario?.conta : null,
          digitoConta: funcionario?.digito != null ? funcionario?.digito : null,
          documentos: documentos ? documentos : [],
        });

        let dependentes = [];
        if (funcionario?.dependentes?.length) {
          dependentes = funcionario?.dependentes.map((item) => ({
            nome: item.nome != null ? item.nome : null,
            cpf: item.cpf != null ? item.cpf : null,
            dataNascimento:
              item.data_nascimento != null ? item.data_nascimento : null,
            inicioDependencia:
              item.data_inicio_dependencia != null
                ? item.data_inicio_dependencia
                : null,
            paisNascimento:
              item.id_pais_nascimento != null ? item.id_pais_nascimento : null,
            nacionalidade:
              item.id_nacionalidade != null ? item.id_nacionalidade : null,
            telefone: item.telefone != null ? item.telefone : null,
            cep: item.cep != null ? item.cep : null,
            rua: item.rua != null ? item.rua : null,
            numero: item.numero != null ? item.numero : null,
            bairro: item.bairro != null ? item.bairro : null,
            cidade: item.id_cidade != null ? item.id_cidade : null,
            estado: item.id_estado != null ? item.id_estado : null,
            pais: item.id_pais != null ? item.id_pais : null,
            parentesco: item.id_parentesco != null ? item.id_parentesco : null,
            dataEntradaPais:
              item.data_entrada_pais != null ? item.data_entrada_pais : null,
            possuiDeficiencia: item.id_tipo_deficiencia ? 1 : 0,
            deficiencia:
              item.id_tipo_deficiencia != null
                ? item.id_tipo_deficiencia
                : null,
            mesmoEndereco: item.id_estado != null ? item.id_estado : null,
            complemento: item.id_pais != null ? item.id_pais : null,
            inclusoPlanoSaude:
              item.incluso_no_plano_de_saude != null
                ? item.incluso_no_plano_de_saude
                : null,
            inclusoPlanoOdontologico:
              item.incluso_no_plano_odontologico != null
                ? item.incluso_no_plano_odontologico
                : null,
            declaraDependente:
              item.declara_no_irpf != null ? item.declara_no_irpf : null,
            salarioFamilia:
              item.possui_salario_familia != null
                ? item.possui_salario_familia
                : null,
            certidaoNascimentoBase64:
              item.certidao_nascimento != null
                ? item.certidao_nascimento
                : null,
            cadernetaVacinacaoBase64:
              item.caderneta_vacinacao != null
                ? item.caderneta_vacinacao
                : null,
          }));
        }
        console.log(dependentes);
        setPreAdmissaoDependentes({
          possuiDependentes: dependentes?.length ? 1 : 0,
          dependentes: dependentes?.length
            ? dependentes
            : [
                {
                  nome: null,
                  cpf: null,
                  dataNascimento: null,
                  inicioDependencia: null,
                  paisNascimento: null,
                  nacionalidade: null,
                  telefone: null,
                  cep: null,
                  rua: null,
                  numero: null,
                  bairro: null,
                  cidade: null,
                  estado: null,
                  pais: null,
                  parentesco: null,
                  dataEntradaPais: null,
                  possuiDeficiencia: null,
                  deficiencia: null,
                  mesmoEndereco: null,
                  complemento: null,
                  declaraDependente: null,
                  salarioFamilia: null,
                  certidaoNascimento: null,
                  cadernetaVacinacao: null,
                },
              ],
        });
      } else if (typeof response?.data?.error === "string") {
        setLoading(false);
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage(response?.data?.msg || "Erro!");
        setCallbackDuration(4000);
        setCallbackErrorList([response?.data?.error]);
      } else if (typeof response?.data?.error === "object") {
        setLoading(false);
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackDuration(4000);
        setCallbackMessage(response?.data?.msg || "Erro!");
        setCallbackErrorList(
          Object.values(response?.data?.error).flat(Infinity)
        );
      } else {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(["Erro ao consultar a pré-admissão"]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleStepNavigation = (step) => {
    if (locationState?.activeStepNavigation) {
      setActiveStep(step);
    }
  };

  useEffect(() => {
    loadPreadmissao();
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <Grid container className={isMobile ? "" : "default-forms-container"}>
        <>
          {!isMobile && (
            <Grid item xs={2}>
              <Stepper
                useActiveStep
                activeStep={activeStep}
                arraySteps={availableSteps}
                handleClickStep={handleClickStep}
                activeStepNavigation={locationState?.activeStepNavigation}
              />
            </Grid>
          )}
          <Grid item xs={isMobile ? 12 : 10}>
            <Grid
              container
              spacing={2}
              justifyContent={isMobile ? "flex-start" : "center"}
            >
              <Grid
                item
                xs={isMobile ? 12 : 8}
                className={isMobile ? styles.containerFormMobile : ""}
                style={isMobile ? {} : { marginTop: 20 }}
              >
                <form onSubmit={handleFormSubmit}>
                  {isMobile && (
                    <>
                      <Accordion
                        expanded={activeStep === 0}
                        onChange={() => handleStepNavigation(0)}
                        className={styles.accordionStyles}
                      >
                        <AccordionSummary>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            xs={12}
                            className={styles.accordionSummary}
                          >
                            <Grid item className="font-title">
                              <span className="text-pleft">
                                1. DADOS CONTRATUAIS
                              </span>
                            </Grid>
                            <Grid item>
                              {activeStep === 0 ? (
                                <ExpandLess className="font-darkBlue" />
                              ) : (
                                <ExpandMore className="font-orange" />
                              )}
                            </Grid>
                          </Grid>
                        </AccordionSummary>

                        <AccordionDetails
                          className={styles.accordionDetails}
                          style={{ background: "white" }}
                        >
                          <DadosContratuais
                            idEmpresa={idEmpresa}
                            setIdEmpresa={setIdEmpresa}
                            locationState={locationState}
                            dados={preAdmissaoDadosContratuais}
                            setDados={setPreAdmissaoDadosContratuais}
                            listEmpresa={listEmpresas}
                          />
                          <Grid
                            container
                            xs={12}
                            style={{ marginTop: 10 }}
                            justifyContent="flex-end"
                          >
                            <ButtonComponent
                              className={`btn-success ${styles.btnSteps} w-full`}
                              text="Avançar"
                              onClick={handleNextStep}
                            />
                          </Grid>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={activeStep === 1}
                        onChange={() => handleStepNavigation(1)}
                        className={`${styles.accordionStyles} no-boder-top`}
                      >
                        <AccordionSummary>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            xs={12}
                            className={styles.accordionSummary}
                          >
                            <Grid item className="font-title">
                              <span className="text-pleft">2. BENEFÍCIOS</span>
                            </Grid>
                            <Grid item>
                              {activeStep === 1 ? (
                                <ExpandLess className="font-darkBlue" />
                              ) : (
                                <ExpandMore className="font-orange" />
                              )}
                            </Grid>
                          </Grid>
                        </AccordionSummary>

                        <AccordionDetails
                          className={styles.accordionDetails}
                          style={{ background: "white" }}
                        >
                          <BeneficiosMobile
                            locationState={locationState}
                            dados={preAdmissaoBeneficios}
                            setDados={setPreAdmissaoBeneficios}
                          />
                          <Grid
                            container
                            spacing={2}
                            xs={12}
                            style={{ marginTop: 10 }}
                            justifyContent="flex-end"
                          >
                            <Grid item xs={6}>
                              <ButtonComponent
                                text="Voltar"
                                className={`btn-secondary ${styles.btnOutline} ${styles.btnSteps} w-full`}
                                onClick={handleBack}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <ButtonComponent
                                className={`btn-success ${styles.btnSteps} w-full`}
                                text="Avançar"
                                onClick={handleNextStep}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={activeStep === 2}
                        onChange={() => handleStepNavigation(2)}
                        className={styles.accordionStyles}
                      >
                        <AccordionSummary>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            xs={12}
                            className={styles.accordionSummary}
                          >
                            <Grid item className="font-title">
                              <span className="text-pleft">
                                3. DADOS PESSOAIS
                              </span>
                            </Grid>
                            <Grid item>
                              {activeStep === 2 ? (
                                <ExpandLess className="font-darkBlue" />
                              ) : (
                                <ExpandMore className="font-orange" />
                              )}
                            </Grid>
                          </Grid>
                        </AccordionSummary>

                        <AccordionDetails
                          className={styles.accordionDetails}
                          style={{ background: "white" }}
                        >
                          <DadosPessoais
                            locationState={locationState}
                            dados={preAdmissaoDadosPessoais}
                            setDados={setPreAdmissaoDadosPessoais}
                            setLoading={setLoading}
                          />
                          <Grid
                            container
                            spacing={2}
                            xs={12}
                            style={{ marginTop: 10 }}
                            justifyContent="flex-end"
                          >
                            <Grid item xs={6}>
                              <ButtonComponent
                                text="Voltar"
                                className={`btn-secondary ${styles.btnOutline} ${styles.btnSteps} w-full`}
                                onClick={handleBack}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <ButtonComponent
                                className={`btn-success ${styles.btnSteps} w-full`}
                                text="Avançar"
                                onClick={handleNextStep}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={activeStep === 3}
                        onChange={() => handleStepNavigation(3)}
                        className={styles.accordionStyles}
                      >
                        <AccordionSummary>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            xs={12}
                            className={styles.accordionSummary}
                          >
                            <Grid item className="font-title">
                              <span className="text-pleft">
                                4. ENDEREÇO E CONTATO
                              </span>
                            </Grid>
                            <Grid item>
                              {activeStep === 3 ? (
                                <ExpandLess className="font-darkBlue" />
                              ) : (
                                <ExpandMore className="font-orange" />
                              )}
                            </Grid>
                          </Grid>
                        </AccordionSummary>

                        <AccordionDetails
                          className={styles.accordionDetails}
                          style={{ background: "white" }}
                        >
                          <EnderecoContato
                            locationState={locationState}
                            dados={preAdmissaoEnderecoContato}
                            setDados={setPreAdmissaoEnderecoContato}
                            setLoading={setLoading}
                          />
                          <Grid
                            container
                            spacing={2}
                            xs={12}
                            style={{ marginTop: 10 }}
                            justifyContent="flex-end"
                          >
                            <Grid item xs={6}>
                              <ButtonComponent
                                text="Voltar"
                                className={`btn-secondary ${styles.btnOutline} ${styles.btnSteps} w-full`}
                                onClick={handleBack}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <ButtonComponent
                                className={`btn-success ${styles.btnSteps} w-full`}
                                text="Avançar"
                                onClick={handleNextStep}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={activeStep === 4}
                        onChange={() => handleStepNavigation(4)}
                        className={styles.accordionStyles}
                      >
                        <AccordionSummary>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            xs={12}
                            className={styles.accordionSummary}
                          >
                            <Grid item className="font-title">
                              <span className="text-pleft">5. DOCUMENTOS</span>
                            </Grid>
                            <Grid item>
                              {activeStep === 4 ? (
                                <ExpandLess className="font-darkBlue" />
                              ) : (
                                <ExpandMore className="font-orange" />
                              )}
                            </Grid>
                          </Grid>
                        </AccordionSummary>

                        <AccordionDetails
                          className={styles.accordionDetails}
                          style={{ background: "white" }}
                        >
                          <Documentos
                            locationState={locationState}
                            dados={preAdmissaoDocumentos}
                            setDados={setPreAdmissaoDocumentos}
                            setLoading={setLoading}
                            edit={id}
                          />
                          <Grid
                            container
                            spacing={2}
                            xs={12}
                            style={{ marginTop: 10 }}
                            justifyContent="flex-end"
                          >
                            <Grid item xs={6}>
                              <ButtonComponent
                                text="Voltar"
                                className={`btn-secondary ${styles.btnOutline} ${styles.btnSteps} w-full`}
                                onClick={handleBack}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <ButtonComponent
                                className={`btn-success ${styles.btnSteps} w-full`}
                                text="Avançar"
                                onClick={handleNextStep}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={activeStep === 5}
                        onChange={() => handleStepNavigation(5)}
                        className={styles.accordionStyles}
                      >
                        <AccordionSummary>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            xs={12}
                            className={styles.accordionSummary}
                          >
                            <Grid item className="font-title">
                              <span className="text-pleft">6. DEPENDENTES</span>
                            </Grid>
                            <Grid item>
                              {activeStep === 5 ? (
                                <ExpandLess className="font-darkBlue" />
                              ) : (
                                <ExpandMore className="font-orange" />
                              )}
                            </Grid>
                          </Grid>
                        </AccordionSummary>

                        <AccordionDetails
                          className={styles.accordionDetails}
                          style={{ background: "white" }}
                        >
                          <Dependentes
                            locationState={locationState}
                            dados={preAdmissaoDependentes}
                            setDados={setPreAdmissaoDependentes}
                            setLoading={setLoading}
                          />
                          <Grid
                            container
                            spacing={2}
                            xs={12}
                            style={{ marginTop: 10 }}
                            justifyContent="flex-end"
                          >
                            <Grid item xs={!locationState?.details ? 6 : 12}>
                              <ButtonComponent
                                text="Voltar"
                                className={`btn-secondary ${styles.btnOutline} ${styles.btnSteps} w-full`}
                                onClick={handleBack}
                              />
                            </Grid>
                            {!locationState?.details && (
                              <Grid item xs={6}>
                                <ButtonComponent
                                  className={`btn-success ${styles.btnSteps} w-full`}
                                  text="Enviar"
                                  type="submit"
                                />
                              </Grid>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )}
                  {!isMobile && (
                    <>
                      {activeStep === 0 && (
                        <DadosContratuais
                          idEmpresa={idEmpresa}
                          setIdEmpresa={setIdEmpresa}
                          locationState={locationState}
                          dados={preAdmissaoDadosContratuais}
                          setDados={setPreAdmissaoDadosContratuais}
                          listEmpresa={listEmpresas}
                        />
                      )}
                      {activeStep === 1 && (
                        <Beneficios
                          locationState={locationState}
                          dados={preAdmissaoBeneficios}
                          setDados={setPreAdmissaoBeneficios}
                        />
                      )}
                      {activeStep === 2 && (
                        <DadosPessoais
                          locationState={locationState}
                          dados={preAdmissaoDadosPessoais}
                          setDados={setPreAdmissaoDadosPessoais}
                          setLoading={setLoading}
                        />
                      )}
                      {activeStep === 3 && (
                        <EnderecoContato
                          locationState={locationState}
                          dados={preAdmissaoEnderecoContato}
                          setDados={setPreAdmissaoEnderecoContato}
                          setLoading={setLoading}
                        />
                      )}
                      {activeStep === 4 && (
                        <Documentos
                          locationState={locationState}
                          dados={preAdmissaoDocumentos}
                          setDados={setPreAdmissaoDocumentos}
                          setLoading={setLoading}
                          edit={id}
                        />
                      )}
                      {activeStep === 5 && (
                        <Dependentes
                          locationState={locationState}
                          dados={preAdmissaoDependentes}
                          setDados={setPreAdmissaoDependentes}
                          setLoading={setLoading}
                        />
                      )}
                      <Grid
                        container
                        justifyContent="flex-end"
                        style={{
                          marginTop: 40,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {activeStep > 0 ? (
                          <>
                            <Grid
                              item
                              xs={3}
                              style={{ marginRight: 10 }}
                              className={
                                locationState?.details && activeStep === 3
                                  ? "align-button-right"
                                  : ""
                              }
                            >
                              <ButtonComponent
                                text="Voltar"
                                className="btn-secondary btnSteps"
                                onClick={handleBack}
                              />
                            </Grid>
                          </>
                        ) : null}
                        {activeStep < 5 ? (
                          <>
                            <Grid
                              item
                              {...(locationState?.edit ? { xs: 3 } : {})}
                              style={{ width: 120 }}
                            >
                              <ButtonComponent
                                className="btn-success btnSteps"
                                text="Avançar"
                                onClick={handleNextStep}
                              />
                            </Grid>
                          </>
                        ) : null}

                        {activeStep === 5 && !locationState?.details ? (
                          <>
                            <Grid item style={{ width: 120 }}>
                              <ButtonComponent
                                className="btn-info btnSteps"
                                text="Salvar"
                                type="submit"
                              />
                            </Grid>
                          </>
                        ) : null}
                      </Grid>
                    </>
                  )}
                </form>
              </Grid>
            </Grid>
          </Grid>
        </>
        {loading && <OverlayLoading />}
        {callbackShown && (
          <CallbackMessage
            open={callbackShown}
            type={callbackType}
            handleClose={handleClose}
            message={callbackMessage}
            duration={callbackDuration}
            errorList={callbackErrorList}
          />
        )}
      </Grid>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
