import React, { useState, useEffect, useCallback } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import classes from "./styles.module.scss";
import Icon from "../Icon";
import "./styles.scss";

function AutocompleteInput({
  label = "",
  required = false,
  data = [],
  initialValue = [],
  getOptionLabel = () => { },
  handleSelectedValues = () => { },
  getOptionSelected = () => { },
  id = "tags-outlined",
  placeholder = "",
  multiple = true,
  customFilterOptions = false,
  loading = false
}) {
  const headerInput = () => (
    <>
      <label className={classes.inputLabel}>
        {label}
        <span className={classes.requiredItem}>{required && "*"}</span>
      </label>
    </>
  );

  const [value, setValue] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const getFilteredOptions = useCallback((value) => {
    if (value?.length > 0) {
      const newFilteredOptions = data.filter(item => item.id?.startsWith(value));
      console.log(newFilteredOptions);
      return setFilteredOptions(newFilteredOptions);
    }
    setFilteredOptions([]);
  }, [inputValue, data])

  useEffect(() => {
    if (initialValue.length !== 0 && data.length !== 0 && !customFilterOptions) {

      let initData = [];

      const newFilteredOptions = data.filter(item => item.nome?.startsWith(initialValue));
      newFilteredOptions.map((initItem) => {
        if (initialValue === initItem.nome) {
          initData.push(initItem);
        }
        return false;
      })
      setValue(initData[0]);
    } else if(customFilterOptions){
      console.log(initialValue);
      let initData = [];

      data.map((initItem) => {
        if (initialValue === initItem.nome) {
          initData.push(initItem);
        }
        return false;
      })
      setValue(initData[0]);
    }
  }, [initialValue, data]);

  return (
    <>
      {headerInput()}
      <Autocomplete
        id={id}
        popupIcon={<Icon name="arrow-down" />}
        multiple={multiple}
        value={value}
        classes={{ paper: classes.paperRoot }}
        onChange={(event, newValue) => {
          handleSelectedValues(newValue?.id);
          setValue(newValue);
        }}
        getOptionSelected={getOptionSelected}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          if (customFilterOptions) {
            customFilterOptions(newInputValue)
          }else{
            getFilteredOptions(newInputValue);
          }
          setInputValue(newInputValue);
        }}
        noOptionsText={inputValue.length > 0 ? "Nenhuma opção encontrada" : "Digite para iniciar a busca"}
        options={customFilterOptions ? data : filteredOptions}
        getOptionLabel={getOptionLabel}
        filterSelectedOptions
        closeIcon={false}
        loading={loading}
        loadingText="Buscando dados..."
        renderInput={(params) => (
          <TextField {...params} className={classes.input} placeholder={placeholder} />
        )}
      />
    </>
  );
}

export default AutocompleteInput;
