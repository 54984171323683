import { useEffect, useRef } from "react";

export default function useHorizontalScroll(element) {
  const isDragging = useRef(false);
  const startClientX = useRef();
  const startScrollLeft = useRef();

  const startDragging = (e) => {
    isDragging.current = true;
    startClientX.current = e.clientX;
    startScrollLeft.current = element.scrollLeft;
  };

  const stopDragging = () => {
    isDragging.current = false;
  };

  const move = (e) => {
    e.preventDefault();
    if (!isDragging.current) {
      return;
    }

    const clientX = e.clientX;
    const deltaX = clientX - startClientX.current;
    element.scrollLeft = startScrollLeft.current - deltaX;
  };

  useEffect(() => {
    if (!element) {
      return;
    }

    // Add event listeners
    element.addEventListener("mousedown", startDragging, false);
    element.addEventListener("mousemove", move, false);
    element.addEventListener("mouseup", stopDragging, false);
    element.addEventListener("mouseleave", stopDragging, false);

    // Remove event listeners on cleanup
    return () => {
      element.removeEventListener("mousedown", startDragging);
      element.removeEventListener("mousemove", move);
      element.removeEventListener("mouseup", stopDragging);
      element.removeEventListener("mouseleave", stopDragging);
    };
  }, [element]);
}
