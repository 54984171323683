import React from 'react';
import { Switch } from '@material-ui/core';
import './styles.scss';

export default function SwitchComponent({
  checked,
  onClick,
  isBoolean = true,
  disabled = false
}) {
  return (
    <div className="switch-container">
      <Switch
        checked={checked}
        onChange={onClick}
        onClick={onClick}
        disabled={disabled}
        data-testid="switchComponent"
        classes={{
          root: 'switch-root',
          thumb: isBoolean ? 'switch-thumb' : 'not-bool-switch-thumb',
          track: isBoolean ? 'switch-track' : 'not-bool-switch-track',
          switchBase: 'switch-switchBase',
          checked: 'switch-checked',
        }}
      />
    </div>
  );
}
