import Axios from "./Axios";

const transferenciaService = {
  cadastraTransferencia: async (
    id_empresa,
    id_conta_origem,
    id_conta_destino,
    data_vencimento,
    valor,
    descricao,
    arquivo
  ) => {
    const response = await Axios.post(`/cadastra-transferencia`, {
      id_empresa: id_empresa,
      id_conta_origem: id_conta_origem,
      id_conta_destino: id_conta_destino,
      data_vencimento: data_vencimento,
      valor: valor,
      descricao: descricao,
      arquivo: arquivo,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};

export default transferenciaService;
