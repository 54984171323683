import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import Input from "../../components/Input";
import Button from "../../components/ButtonComponent";
import AutocompleteInput from "../../components/AutocompleteInput";
import OverlayLoading from "../../components/OverlayLoading";
import CallbackMessage from "../../components/CallbackMessage";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import ConfirmDialog from "../../components/ConfirmDialog";
import Select from "../../components/SelectComponent";

import usuariosService from "../../services/usuariosService";
import perfilAcessoService from "../../services/perfilAcessoService";

import "./styles.css";
import useCompany from "../../hooks/useCompany";

function CadastroUsuarios() {
  const history = useHistory();
  const { companyList: empresas, getEmpresaPagina } = useCompany("usuarios");

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const { addUsuario } = usuariosService;
  const { selecionaPerfilAcesso } = perfilAcessoService;
  const [initEmpresas, setInitEmpresas] = useState([]);

  const [perfilAcessoList, setPerfilAcessoList] = useState([]);

  const [perfil, setPerfil] = useState("");
  const [openModalPermissao, setOpenModalPermissao] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [selectedEmpresas, setSelectedEmpresas] = useState([]);
  const [active, setActive] = useState(1);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [loading, setLoading] = useState(false);

  const loadPerfil = useCallback(async () => {
    const response = await selecionaPerfilAcesso();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setPerfilAcessoList(response.data);
  }, [selecionaPerfilAcesso]);

  function handleSelectedValues(values) {
    console.log({ values });
    const empresasIds = values.map((item) => {
      return item.id;
    });

    setSelectedEmpresas(empresasIds);
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/usuarios-cadastrados");
    }
  }

  async function validateLimiteQtd() {
    setLoading(true);
    let success = true;
    for (let i = 0; i < selectedEmpresas.length; i++) {
      const response = await usuariosService.limiteUsuario(selectedEmpresas[i]);
      if (response.status === 401) {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      } else if (response.status === 201) {
        if (response.data.limite_excedido) {
          setLoading(false);
          setOpenModalPermissao(true);
          success = false;
          break;
        }
      }
    }
    return success;
  }

  async function handleUserSubmit() {
    if (!validateFields()) return;

    if (!(await validateLimiteQtd())) return;

    const response = await addUsuario(
      nome,
      email,
      cpf,
      selectedEmpresas,
      perfil,
      active
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status !== 201) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      setLoading(false);
      return;
    }
    if (response.status === 201) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Usuário cadastrado com sucesso");
    }
  }

  function verifyField(field, callback) {
    if (field === "" || field === null || field === undefined) {
      callback();
    }
  }

  function validateFields() {
    let list = [];
    verifyField(nome, () => list.push('O campo "Nome" é obrigatório.'));
    verifyField(email, () => list.push('O campo "E-mail" é obrigatório.'));
    verifyField(cpf, () => list.push('O campo "Cpf" é obrigatório.'));
    verifyField(perfil, () =>
      list.push('O campo "Perfil de Acesso" é obrigatório.')
    );
    if (selectedEmpresas.length === 0) {
      list.push('O campo "Empresa" deve ser selecionado.');
    }

    setCallbackErrorList(list);
    if (list.length > 0) {
      setCallbackShown(true);
      setCallbackDuration(2000);
      setCallbackType("error");
    }
    return list.length === 0;
  }

  useEffect(() => {
    (async function () {
      setLoading(true);
      await loadPerfil();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("usuarios");
    if (empresaStorage) {
      console.log({ empresaStorage });
      setInitEmpresas([{ id: empresaStorage }]);
      handleSelectedValues([{ id: empresaStorage }]);
    }
  }, []);

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <form className="form form-create-user">
            <CallbackMessage
              open={callbackShown}
              duration={callbackDuration}
              errorList={callbackErrorList}
              handleClose={handleClose}
              message={callbackMessage}
              type={callbackType}
            />
            <div className="name-input">
              <Input
                nome="nome"
                label="Nome"
                tipo="text"
                required={true}
                handleInputChange={(event) => setNome(event.target.value)}
              />
            </div>
            <div className="email-input">
              <Input
                nome="email"
                label="E-mail"
                tipo="text"
                required={true}
                handleInputChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="cpf-input">
              <Input
                nome="cpf"
                label="CPF"
                tipo="cpf"
                required={true}
                handleInputChange={(event) => setCpf(event.target.value)}
              />
            </div>
            <div className="company-input">
              <AutocompleteInput
                label="Empresa"
                data={empresas}
                initValue={initEmpresas}
                handleSelectedValues={(values) => handleSelectedValues(values)}
                key={(option) => option.id}
                getOptionLabel={(option) => option.nome}
                required
              />
            </div>
            <div className="profile-input">
              <Select
                list={perfilAcessoList}
                label="Perfil de Acesso"
                title="Selecione um perfil"
                required
                styleType="form"
                initialValue={
                  perfilAcessoList.find((item) => item.id === perfil)
                    ? perfilAcessoList.find((item) => item.id === perfil).nome
                    : ""
                }
                callback={(id) => setPerfil(id)}
              />
              <div className="hint-form space-vertical">
                <span className="required-text ">* Campos obrigatórios</span>
              </div>
            </div>

            <div className="checkbox-input">
              <div className="container-checkbox-input-ativo">
                <input
                  id="showAllCategories"
                  type="checkbox"
                  name="showAllCategories"
                  checked={active}
                  onChange={(event) => setActive(active ? 0 : 1)}
                  autocomplete="off"
                />
                <label
                  className="label-input-checkbox"
                  htmlFor="showAllCategories"
                >
                  Ativo
                </label>
              </div>
            </div>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            type="button"
            text="Salvar"
            className="btn-success"
            onClick={handleUserSubmit}
          />
        </span>
      </div>
      <ConfirmDialog
        acceptLabel="Fechar"
        noCancel
        btnClass="btn-secondary-strong"
        description={`Seu plano não permite criar novos usuários.\n\nEm caso de dúvidas, entre em contato com o suporte.`}
        open={openModalPermissao}
        title="LIMITE DO PLANO EXCEDIDO"
        onAccept={() => history.push("/usuarios-cadastrados")}
        onClose={() => { }}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastroUsuarios;
