import axios from "axios";

const getToken = () => window.localStorage.getItem("session");

const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_LARAVEL_URL}`,
  headers: { Accept: "application/json" },
});

Axios.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

Axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    const { response } = error;
    const { data } = response;
    if(response.status === 401 && data.permission === false && data.session === true) {  
      window.sessionStorage.setItem("acesso_negado", "true");
    } 
    return Promise.reject(error);
  }
);

export default Axios;
