import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../Icon";
import { Menu, MenuItem } from "@material-ui/core";
import { NavbarContext } from "../../contexts/NavbarContext";
import ModalAbrir from "../../components/ModalCadastroComplementar/Abrir";
import ModalMigrar from "../../components/ModalCadastroComplementar/Migrar";

import "./styles.scss";

function NavbarNotification({ showBadge = true }) {
  const history = useHistory();
  const {
    listNotification,
    setChaveSelected,
  } = useContext(NavbarContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModalAbrir, setOpenModalAbrir] = useState(false);
  const [openModalMigrar, setOpenModalMigrar] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionModal = acao => {
    switch (acao) {
      case "cadastro-completo-abrir":
        setOpenModalAbrir(true);
        break;
      case "cadastro-completo-migrar":
        setOpenModalMigrar(true);
        break;

      default:
        return;
    }
  }

  const handleClickItem = (item) => {
    setChaveSelected(item.chave);
    
    switch (item.tipo) {
      case "modal":
        handleActionModal(item.acao);
        break;
      case "link-interno":
        history.push("/" + item.acao, {state: item});
        break;
      case "link-externo":
        let win = window.open(item.acao, '_blank');
        win.focus();
        break;
          
      default:
        return;
    }

    setAnchorEl(null);
  }

  const handleCloseAbrir = () => {
    setOpenModalAbrir(false);
  }

  const handleCloseMigrar = () => {
    setOpenModalMigrar(false);
  }

  const paperProps = {
    style: {
      width: 220,
    },
  }

  if (!showBadge) return <></>

  return (
    <div className={listNotification.length > 0 ? '' : 'no-notifications'}>
      {listNotification.length > 0 ? (
        <>
          <div className="container-notification" onClick={handleClick}>
            <div className="avatar-icon-wrapper has-notifications-wrapper">
              <Icon name="notification" color="#fff" size="14" />
            </div>
            <div className="text-notification">
              {`Você possui ${listNotification.length} 
              ${listNotification.length > 1 ? "notificações" : "notificação"}`}
            </div>
          </div>
          <Menu
            className="notification-menu-container"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={paperProps}
          >
            {listNotification.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => handleClickItem(item, index)}
                style={{ whiteSpace: "unset" }}
              >
                <div className="notification-item">
                  <div className="avatar-icon-wrapper avatar-item has-notifications-wrapper">
                    <Icon name="notification" color="#fff" size="14" />
                  </div>
                  <span className="text-notification-item">{item.valor}</span>
                </div>
              </MenuItem>
            ))}
          </Menu>
          {openModalAbrir && (
            <ModalAbrir open={openModalAbrir} onClose={handleCloseAbrir} />
          )}
          {openModalMigrar && (
            <ModalMigrar open={openModalMigrar} onClose={handleCloseMigrar} />
          )}
        </>
      ) : (
        <>
          <div className="container-notification" onClick={handleClick}>
            <div className="avatar-icon-wrapper no-notifications-wrapper">
              <Icon name="notification" color="#fff" size="14" />
            </div>
          </div>
          <Menu
            className="notification-menu-container"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={paperProps}
          >
            <MenuItem onClick={handleClickItem} style={{ whiteSpace: "unset" }}>
              <div className="notification-item">
                <div className="avatar-icon-wrapper avatar-item no-notifications-wrapper">
                  <Icon name="notification" color="#fff" size="14" />
                </div>
                <span className="text-notification-item no-notification-text">
                  Nenhuma notificação.
                </span>
              </div>
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
}

export default NavbarNotification;
