import Axios from "./Axios";

const extratosService = {
  getExtratos: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    idContaBancaria,
    dataReferenciaInicial,
    dataReferenciaFinal
  ) => {
    const response = await Axios.get("/lista-extrato", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_conta_bancaria: idContaBancaria,
        data_referencia_inicial: dataReferenciaInicial,
        data_referencia_final: dataReferenciaFinal,
      },
    })
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  getSaldoContas: async (id) => {
    const response = await Axios.get(`/lista-saldo-conta/${id}`)
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  getConsultaConta: async (id) => {
    const response = await Axios.get(`/consulta-conta/${id}`)
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
};

export default extratosService;
