import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { sub } from "date-fns";
import { Grid } from "@material-ui/core";

import validateFields from "../../../utils/validateFields";

import TableComponent from "../../../components/TableComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import Select from "../../../components/SelectComponent";
import Input from "../../../components/Input";
import DatePicker from "../../../components/DatePicker";
import Button from "../../../components/ButtonComponent";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalDetalhesNotaFiscal from "../../../components/ModalDetalhesNotaFiscal";

import solicitacaoServicoServices from "../../../services/solicitacaoServicoServices";
import notasFiscaisService from "../../../services/notasFiscaisService";

import { numberToCurrency, base64ToFile } from "../../../utils/functions";
import { formatDate, toDateYYYY_MM_DD } from "../../../utils/dates";

import loadPermissoes from "../../../contexts/RoutesContext";

import classes from "./styles.module.scss";
import "./styles.scss";
import useCompany from "../../../hooks/useCompany";

export default function EmissaoListaNFE() {
  const history = useHistory();
  const { listaSolicitacoes } = solicitacaoServicoServices;
  const {
    listaNotasFiscais,
    downloadPDFNfe,
    downloadXMLNfe,
    selecionaStatusNotaEntrada,
    cancelaNotaFiscal,
  } = notasFiscaisService;
  const {
    companyList: listEmpresas,
    selectedCompany: empresa,
    setSelectedCompany: setEmpresa,
    setEmpresaPagina,
  } = useCompany("nfe");

  const [loading, setLoading] = useState(false);
  const [idNotaFiscal, setIdNotaFiscal] = useState(null);

  const [openModalDetalhesNota, setOpenModalDetalhesNota] = useState(false);
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [dateDe, setDateDe] = useState(null);
  const [dateAte, setDateAte] = useState(null);
  const [chaveAcesso, setChaveAcesso] = useState(null);
  const [numeroInicial, setNumeroInicial] = useState(null);
  const [numeroFinal, setNumeroFinal] = useState(null);
  const [status, setStatus] = useState(null);
  const [serieInicial, setSerieInicial] = useState(null);
  const [serieFinal, setSerieFinal] = useState(null);

  const [listStatus, setListStatus] = useState([]);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const permissions = loadPermissoes();

  const [nfOptions, setNfOptions] = useState([]);
  const [visible, setVisible] = useState(false);

  const [listRequest, setListRequest] = useState([]);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Data emissão", dataRef: "data_emissao" },
      { columnName: "Empresa", dataRef: "razao_social" },
      { columnName: "Número", dataRef: "numero" },
      { columnName: "Série", dataRef: "serie" },
      { columnName: "Total", dataRef: "valor_total" },
    ],
    options: {
      searchFile: true,
      more: [
        {
          icon: "nf-e",
          label: "Download XML",
          action: "download-xml",
        },
        {
          icon: "nf-e",
          label: "Download PDF",
          action: "download-pdf",
        },
        {
          icon: "cancel",
          label: "Cancelar NF-e",
          action: "cancelar-nota",
        },
        {
          icon: "search",
          label: "Busca SEFAZ",
          action: "busca-sefaz",
        },
        {
          icon: "copy-icon",
          label: "Copiar NF",
          action: "copiar-nf",
        },
      ],
    },
    display: {
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      filter: true,
      filterOnly: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  let onMoreAction = async (action) => {
    console.log(action);

    switch (action.action) {
      case "download-xml": {
        setLoading(true);

        await onDownloadFile("xml", action.id);

        setLoading(false);
        break;
      }
      case "download-pdf": {
        setLoading(true);

        await onDownloadFile("pdf", action.id);

        setLoading(false);
        break;
      }
      case "cancelar-nota": {
        setLoading(true);

        setIdNotaFiscal(action.id);
        setOpenConfirmCancel(true);

        setLoading(false);
        break;
      }
      case "busca-sefaz": {
        setLoading(true);

        await onBuscaSefaz(action.id);

        setLoading(false);
        break;
      }
      case "copiar-nf": {
        console.log("Copiar NF");
        console.log(action.id);

        const data = await notasFiscaisService.consultaNfe(action.id);

        notasFiscaisService
          .dadosNfe(action.id)
          .then((response) => {
            if (response.status === 200) {
              console.log("Dados nota fiscal NFE");
              console.log(response.data);
              history.push("/emitir-nfe", response.data[0]);
            }
          })
          .catch(() => {
            console.log("Error");
          });
        break;
      }
      default:
        break;
    }
  };

  const onBuscaSefaz = async (id) => {
    console.log(id);
    const response = await notasFiscaisService.consultaNfeSefaz(id);
    console.log(response);
    console.log(response.status);
    console.log(
      response?.data?.message ? [response.data.message] : ["Nota Atualizada"]
    );
    if (response.status === 200) {
      setCallbackType("success");
      setCallbackDuration(4000);
      setCallbackMessage(
        response?.data?.message ? response.data.message : "Sucesso!"
      );
      setCallbackShown(true);
      updateTable();
    }
    if (response.status === 400) {
      setCallbackType("error");
      setCallbackDuration(4000);
      setCallbackMessage("Erro!");
      setCallbackErrorList(
        response?.data?.error?.length > 0
          ? response.data.error
          : ["Ocorreu um erro ao consultar a nota"]
      );
      setCallbackShown(true);
    }

    if (response.status === 500) {
      setCallbackType("error");
      setCallbackDuration(5000);
      setCallbackMessage("Erro!");
      setCallbackErrorList([
        "Erro interno do servidor. Por favor, contate o suporte.",
      ]);
      setCallbackShown(true);
    }

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  };

  const loadStatus = useCallback(async () => {
    const response = await selecionaStatusNotaEntrada();

    if (response.status === 200) setListStatus(response.data);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await loadStatus();
      setLoading(false);
    })();
  }, []);

  function listaStatus(list = []) {
    return list.map((item) => {
      let labelColor = "";

      switch (item.descricao_status_sefaz) {
        case "Autorizado o uso da NF-e":
          labelColor = "#8ac64b";
          break;

        case "NF-e já está cancelada na base de dados da SEFAZ":
          labelColor = "#bdbdbd";
          break;

        case "Cancelamento para NF-e já cancelada":
          labelColor = "#bdbdbd";
          break;

        case "Cancelamento de NF-e homologado":
          labelColor = "#bdbdbd";
          break;

        case null:
          labelColor = "transparent";
          break;

        default:
          labelColor = "#b00b00";
          break;
      }

      return (
        <div className="status-label" style={{ backgroundColor: labelColor }}>
          {item.descricao_status_sefaz}
        </div>
      );
    });
  }

  useEffect(() => {
    if (!dateDe) {
      let year = new Date().getFullYear();
      let initDateYear = new Date(year, 0, 1);
      const dateInitBeforeSetFormat = toDateYYYY_MM_DD(initDateYear);
      setDateDe(dateInitBeforeSetFormat);
    }

    if (!dateAte) {
      const dateInitSet = new Date();
      const dateInitSetFormat = toDateYYYY_MM_DD(dateInitSet);
      setDateAte(dateInitSetFormat);
    }
  }, [dateDe, dateAte]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  let updateTable = useCallback(async () => {
    // window.scrollTo(0, 0);
    setLoading(true);

    const response = await listaNotasFiscais(
      empresa,
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      dateDe,
      dateAte,
      chaveAcesso,
      numeroInicial,
      numeroFinal,
      status,
      serieInicial,
      serieFinal
    );

    if (response.data) {
      if (response.data.hasOwnProperty("data")) {
        response.data.data.map((item) => console.log("Status: ", item.status));

        const { data } = response.data;

        const newData = data.map((item) => ({
          ...item,
          data_emissao: formatDate(item.data_hora_emissao.substring(0, 10)),
          id: item.id_nota_fiscal_eletronica,
          valor_total: numberToCurrency(item.valor_total),
        }));

        const newResult = {
          ...response.data,
          data: newData,
        };
        setListRequest(newResult);
      } else {
        const { data } = response;

        const newData = data.map((item) => ({
          ...item,
          data_emissao: formatDate(item.data_hora_emissao.substring(0, 10)),
          id: item.id_nota_fiscal_produto,
          valor_total: numberToCurrency(item.valor_total),
        }));

        const newResult = {
          ...response,
          data: newData,
        };

        setListRequest(newResult);
      }
    }
    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    listaSolicitacoes,
    dateAte,
    dateDe,
    empresa,
    chaveAcesso,
    numeroInicial,
    numeroFinal,
    serieInicial,
    serieFinal,
    status,
  ]);

  let onPageChange = useCallback(
    (newPageObj) => {
      let newPage = newPageObj.page;
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.currentPage = newPage;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onOrderBy = useCallback(
    (newOrderBy) => {
      let orderBy = newOrderBy.orderBy;
      let orderByType = newOrderBy.orderByType;
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.orderBy = orderBy;
      newDefaultConfigTable.orderByType = orderByType;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onPerPage = useCallback(
    (newPerPage) => {
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.perPage = newPerPage;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onSearchTerm = useCallback(
    (value) => {
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.searchTerm = value ? value : null;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let handleSelectNfOption = async (event) => {
    const itemId = event.target.dataset.id;
    setVisible(false);
    setLoading(true);

    if (itemId === "nfs") {
      return history.push("/emitir-nfse");
    }

    if (itemId === "nfc") {
      return history.push("/emitir-nfce");
    }

    if (itemId === "nfe") {
      return history.push("/emitir-nfe");
    }

    setLoading(false);
  };

  let loadNfOptions = useCallback(() => {
    let list = [];
    let nfs = permissions.find((item) => item.path === "/emissao/nfs");
    let nfc = permissions.find((item) => item.path === "/emissao/nfc");
    let nf = permissions.find((item) => item.path === "/emissao/nfe");
    if (nfs && nfs.accessGranted)
      list.push({
        id: "nfs",
        name: "NFS-e",
      });
    if (nfc && nfc.accessGranted)
      list.push({
        id: "nfc",
        name: "NFC-e",
      });
    if (nf && nf.accessGranted)
      list.push({
        id: "nfe",
        name: "NF-e",
      });

    setNfOptions(list);
  }, [setNfOptions]);

  let handleEmitirNota = useCallback(() => {
    setVisible((prev) => !prev);
  }, []);

  useEffect(() => {
    loadNfOptions();
  }, [loadNfOptions]);

  let handleCancelNotaFiscal = async (reason) => {
    setLoading(true);

    let fieldsToValidate = [
      {
        label: "motivo",
        value: reason,
      },
    ];

    let fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      setOpenConfirmCancel(false);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      setCallbackErrorList(fieldsErrors);
      setCallbackShown(true);
      setLoading(false);
      return;
    }

    const response = await cancelaNotaFiscal(idNotaFiscal, reason);

    if (response.status === 200) {
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Nota fiscal cancelada com sucesso!");
      setCallbackShown(true);
      updateTable();
    }

    if (response.status === 500) {
      setCallbackType("error");
      setCallbackDuration(2000);
      setCallbackMessage("Erro!");
      setCallbackErrorList([
        "Erro interno do servidor. Por favor, contate o suporte.",
      ]);
      setCallbackShown(true);
    }

    if (response.status !== 200 && response.status !== 500) {
      const { data } = response;
      console.log("datadata", data);

      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(data?.error);
    }

    setOpenConfirmCancel(false);
    setLoading(false);
  };

  function handleClose() {
    setCallbackShown(false);
  }

  function handleCloseModal() {
    setOpenModalDetalhesNota(false);
  }

  const handleApplyFilters = useCallback(() => {
    setLoading(true);

    let fields = [
      {
        label: "empresa",
        value: empresa,
      },
    ];

    const validateFieldsErrors = validateFields(fields);

    if (validateFieldsErrors.length !== 0) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackDuration(3000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(validateFieldsErrors);
      return;
    }

    setLoading(false);
    updateTable();
    // setParamsURL({ id_empresa: 150, data_de: dateFrom, data_ate: dateTo });
    setEmpresaPagina("nfe", empresa);
  }, [updateTable]);

  function onLookFile(id) {
    setIdNotaFiscal(id);
    setOpenModalDetalhesNota(true);
  }

  const onDownloadFile = async (type, id) => {
    setLoading(true);

    let response;

    if (type === "xml") {
      response = await downloadXMLNfe(id);
    } else if (type === "pdf") {
      response = await downloadPDFNfe(id);
    }

    const responseRequest = response?.response;

    if (responseRequest?.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (responseRequest?.status === 400) {
      setCallbackShown(true);
      setCallbackDuration(3000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(responseRequest?.data?.error);
      setLoading(false);
      return;
    }

    if (responseRequest?.status === 500) {
      setCallbackType("error");
      setCallbackErrorList(["Erro interno no servidor. Contate o suporte."]);
      setCallbackMessage("Erro!");
      setCallbackShown(true);
      setCallbackDuration(3000);
      setLoading(false);
      return;
    }

    if (response?.status === 200 || response?.status === 201) {
      if (type === "xml") {
        base64ToFile(
          "data:application/xml;base64," + response.data.xml,
          "nota_fiscal"
        );
      } else if (type === "pdf") {
        base64ToFile(
          "data:application/pdf;base64," + response.data.pdf,
          "nota_fiscal"
        );
      }
    }
    setLoading(false);
  };

  const handleCleanFilters = useCallback(() => {
    setLoading(true);

    setDateDe(null);
    setDateAte(null);
    setChaveAcesso(null);
    setEmpresa(null);
    setNumeroFinal(null);
    setNumeroInicial(null);
    setSerieFinal(null);
    setSerieInicial(null);
    setStatus(null);

    setListRequest((prev) => ({
      ...prev,
      data: [],
    }));
    setLoading(false);
    setEmpresaPagina("nfe", null);
  }, []);

  function filterComponent() {
    return (
      <div className="session-container filter-component-container custom-filter-container">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12} sm={12} md={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                listEmpresas.find((item) => item.id === empresa)
                  ? listEmpresas.find((item) => item.id === empresa).nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={async (e) => {
                setEmpresa(e);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <DatePicker
              label="Emissão de"
              noMask
              initDate={dateDe}
              handleChange={(date) => setDateDe(date)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <DatePicker
              noMask
              label="Emissão até"
              initDate={dateAte}
              handleChange={(date) => setDateAte(date)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Input
              nome="chave"
              label="Chave de Acesso"
              tipo="text"
              value={chaveAcesso ? chaveAcesso : ""}
              handleInputChange={(event) => {
                setChaveAcesso(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Input
              nome="numeroinicial"
              label="Número inicial"
              tipo="text"
              value={numeroInicial ? numeroInicial : ""}
              handleInputChange={(event) => {
                setNumeroInicial(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Input
              nome="numerofinal"
              label="Número final"
              tipo="text"
              value={numeroFinal ? numeroFinal : ""}
              handleInputChange={(event) => {
                setNumeroFinal(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Select
              styleType="form"
              label="Status"
              initialValue={
                listStatus.find((item) => item.id === status)
                  ? listStatus.find((item) => item.id === status).nome
                  : ""
              }
              title="Selecione uma opção"
              list={listStatus}
              callback={(e) => {
                setStatus(e);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Input
              nome="serieinicial"
              label="Série inicial"
              tipo="text"
              value={serieInicial ? serieInicial : ""}
              handleInputChange={(event) => {
                setSerieInicial(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Input
              nome="seriefinal"
              label="Série final"
              tipo="text"
              value={serieFinal ? serieFinal : ""}
              handleInputChange={(event) => {
                setSerieFinal(event.target.value);
              }}
            />
          </Grid>

          <div className="filter-button-area">
            <Button
              onClick={handleApplyFilters}
              text="Filtrar"
              className="btn-primary"
            />
            <Button
              onClick={handleCleanFilters}
              text="Limpar Filtros"
              className="default-outline clean-filters-button"
            />
          </div>
        </Grid>
      </div>
    );
  }

  return (
    <>
      <div className={classes.nfHeaderButton}>
        <Button
          onClick={handleEmitirNota}
          className="btn-primary icon-right"
          text="Emitir Nota Fiscal"
          icone="hamburger"
          iconeColor="#fff"
          iconeSize={20}
        />
        <div className={classes.nfOptionsContainer}>
          <ul className={visible ? classes.visible : ""}>
            {nfOptions.map((item, index) => (
              <li
                key={index}
                data-id={item.id}
                onMouseDown={handleSelectNfOption}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbMoreAction={onMoreAction}
        cbLookFile={onLookFile}
        filterComponentCustom={filterComponent()}
        customColumns={[
          {
            columnHead: <span>Status</span>,
            columnData: listaStatus(listRequest?.data),
          },
        ]}
      />
      <ModalDetalhesNotaFiscal
        open={openModalDetalhesNota}
        onClose={handleCloseModal}
        idNota={idNotaFiscal}
      />
      <ConfirmDialog
        open={openConfirmCancel}
        title="CANCELAR NOTA"
        description="Você tem certeza que deseja cancelar essa nota fiscal eletrônica?"
        cancelLabel="Cancelar"
        acceptLabel="Confirmar"
        onCancel={() => setOpenConfirmCancel(false)}
        onClose={() => setOpenConfirmCancel(false)}
        onAccept={handleCancelNotaFiscal}
        haveReason
      />
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        handleClose={handleClose}
        message={callbackMessage}
        duration={callbackDuration}
        errorList={callbackErrorList}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
