import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import TableComponent from "../../../components/TableComponent";
import ConfirmDialog from "../../../components/ConfirmDialog";
import CallbackMessage from "../../../components/CallbackMessage";
import OverlayLoading from "../../../components/OverlayLoading";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import ModalAlterarSenha from "../../../components/ModalAlterarSenha";
import usuariosService from "../../../services/usuariosService";

import { Grid } from "@material-ui/core";
import Select from "../../../components/SelectComponent";
import Button from "../../../components/ButtonComponent";

import "./styles.css";
import validateFields from "../../../utils/validateFields";
import perfilAcessoService from "../../../services/perfilAcessoService";
import exportData from "../../../services/exportData";
import { useIsMount } from "../../../hooks/useIsMount";
import useCompany from "../../../hooks/useCompany";

const statusList = [
  { id: 1, nome: "Ativo" },
  { id: 0, nome: "Inativo" },
];

const exportToPDF = {
  url: "exportar-usuarios-pdf",
  fileName: "cbhub_relatorio_usuarios",
  fileType: "pdf",
};

const exportToXLS = {
  url: "exportar-usuarios-xls",
  fileName: "cbhub_relatorio_usuarios",
  fileType: "xlsx",
};

function UsuariosLista() {
  const history = useHistory();
  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("usuarios");
  const isMount = useIsMount();

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [listRequest, setListRequest] = useState([]);
  const [loading, setLoading] = useState(false);

  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [idUsuarioChangePassword, setIdUsuarioChangePassword] = useState(false);

  const [statusSelecionado, setStatusSelecionado] = useState(null);
  const [perfilAcessoSelecionado, setPerfilAcessoSelecionado] = useState(null);
  const [listPerfilAcesso, setListPerfilAcesso] = useState([]);
  const { selecionaPerfilAcesso } = perfilAcessoService;

  const perfilAcesso = window.localStorage.getItem("perfil_acesso");

  const isEmpresaPlataforma =
    perfilAcesso && (perfilAcesso === "1" || perfilAcesso === "2");

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id" },
      { columnName: "Nome", dataRef: "name" },
      { columnName: "E-mail", dataRef: "email" },
      { columnName: "Perfil de Acesso", dataRef: "perfil_acesso_nome" },
      { columnName: "Status", dataRef: "status" },
    ],
    options: isEmpresaPlataforma
      ? {
          edit: true,
          changePassword: true,
        }
      : {
          edit: true,
        },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      filter: false,
      statusLabels: true,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-xls",
        },
        {
          icon: "export",
          label: "Exportar PDF",
          action: "exportar-pdf",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    id_empresa: null,
    status: null,
    perfil_acesso: null,
  });

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);

    const result = await usuariosService.getUsuarios(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.id_empresa,
      defaultConfigTable.status,
      defaultConfigTable.perfil_acesso
    );

    console.log(result);
    setLoading(false);
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.data.hasOwnProperty("data")) {
      setListRequest(result.data);
    } else {
      setListRequest(result);
    }
    console.log(result.data);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
    defaultConfigTable.status,
    defaultConfigTable.perfil_acesso,
  ]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") setShowMessage(false);

    if (reason === "timeout") setShowMessage(false);

    setShowMessage(false);
  };

  const loadPerfilAcesso = useCallback(async () => {
    const response = await selecionaPerfilAcesso();
    if (response.status === 200) {
      setListPerfilAcesso(response.data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selecionaPerfilAcesso]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onEdit = (id) => {
    history.push(`/editar-usuario/${id}`);
  };

  let handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  let handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  let onDelete = async () => {
    setLoading(true);
    const response = await usuariosService.removeUsuario(deleteId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response?.status === 200 || response?.status === 201) {
      setShowMessage(true);
      setSuccessMessage("Usuário deletado com sucesso.");
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
    }

    setLoading(false);
    handleRemoveCancel();
  };

  let onMoreAction = (action) => {
    console.log(action);
  };

  let onChangePassword = (action) => {
    console.log(action);
    setIdUsuarioChangePassword(action);
    setShowModalChangePassword(true);
  };

  const submitChangePassword = async () => {
    setLoading(true);

    const fieldsToValidate = [
      {
        label: "password",
        value: password,
      },
      {
        label: "rePassword",
        value: rePassword,
      },
    ];
    const fieldsErrors = validateFields(fieldsToValidate);

    console.log(fieldsErrors);
    if (fieldsErrors.length !== 0) {
      setErrors(fieldsErrors);
      setShowMessage(true);
      setLoading(false);
      return;
    }
    const response = await usuariosService.alterarSenha(
      idUsuarioChangePassword,
      password
    );

    if (response.status === 201 || response.status === 200) {
      setShowModalChangePassword(false);
      setIdUsuarioChangePassword(false);
      setErrors([]);
      setSuccessMessage("Senha alterada");
      setShowMessage(true);
      setLoading(false);
    } else if (response.status === 500) {
      setErrors(["Erro interno no servidor. Por favor, contate o suporte"]);
      setShowMessage(true);
      setLoading(false);
      return;
    } else if (response?.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    } else {
      setLoading(false);
      setShowMessage(true);
      setErrors(response?.data?.error);
      return;
    }
  };
  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      return setDefaultConfigTable({
        ...defaultConfigTable,
        currentPage: 1,
        id_empresa: empresaStorage,
      });
    }
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_empresa: empresaSelecionada,
      perfil_acesso: perfilAcessoSelecionado,
      status: statusSelecionado,
    });
    setEmpresaPagina("usuarios", empresaSelecionada);
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setStatusSelecionado(null);
    setPerfilAcessoSelecionado(null);
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
      perfil_acesso: null,
      status: null,
    });
    setEmpresaPagina("usuarios", null);
  };

  const onTableMoreAction = async ({ action }) => {
    console.log(action);

    let params = {};
    if (action === "exportar-xls") {
      params = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: {
          search_term: defaultConfigTable.searchTerm,
          id_empresa: defaultConfigTable.id_empresa,
          status: defaultConfigTable.status,
          perfil_acesso: defaultConfigTable.perfil_acesso,
        },
      };
    } else if (action === "exportar-pdf") {
      params = {
        url: exportToPDF.url,
        fileName: exportToPDF.fileName,
        fileType: exportToPDF.fileType,
        params: {
          search_term: defaultConfigTable.searchTerm,
          id_empresa: defaultConfigTable.id_empresa,
          status: defaultConfigTable.status,
          perfil_acesso: defaultConfigTable.perfil_acesso,
        },
      };
    }

    setLoading(true);
    const response = await exportData(params);
    console.log(response);

    if (response.status === 500) {
      setCallbackErrorList([
        "Erro interno no servidor. Por favor, contate o suporte",
      ]);
      setCallbackShown(true);
    } else if (response.status !== 201 && response.status !== 200) {
      setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
      setCallbackShown(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadPerfilAcesso();
  }, []);

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("usuarios");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                      ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={(id) => setEmpresaSelecionada(id)}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              styleType="form"
              label="Perfil de acesso"
              required
              initialValue={
                perfilAcessoSelecionado !== undefined
                  ? listPerfilAcesso.find(
                      (item) => item.id === perfilAcessoSelecionado
                    )?.nome
                  : ""
              }
              title="Selecione um perfil"
              list={listPerfilAcesso}
              callback={(id) => setPerfilAcessoSelecionado(id)}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              styleType="form"
              label="Status"
              required
              initialValue={
                !statusSelecionado
                  ? statusList.find((item) => item.id === statusSelecionado)
                      ?.nome
                  : ""
              }
              title="Selecione um status"
              list={statusList}
              callback={(id) => setStatusSelecionado(id)}
            />
          </Grid>
          <Grid className="marginLeftAuto">
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onEdit}
        cbDelete={handleRemoveDialog}
        cbMoreAction={onMoreAction}
        cbChangePassword={onChangePassword}
        filterComponent={filterComponent()}
        cbTableMoreAction={onTableMoreAction}
      />
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalAlterarSenha
        show={showModalChangePassword}
        toggle={setShowModalChangePassword}
        idUsuarioChangePassword={idUsuarioChangePassword}
        setLoading={setLoading}
      />
    </>
  );
}

export default UsuariosLista;
