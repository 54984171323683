import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Input from "../../../components/Input";
import ModalLoginExpirado from "../../../components/InputFile";
import Button from "../../../components/ButtonComponent";
import SelectComponent from "../../../components/SelectComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import DatePicker from "../../../components/DatePicker";

import selectServices from "../../../services/selectServices";
import preAdmissaoService from "../../../services/preAdmissaoService";
import { Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./styles.scss";
import SwitchComponent from "../../../components/Switch";

const CustomCheckbox = withStyles({
  root: {
    color: "#E8EBF1",
    width: 0,
    marginTop: 10,
    borderRadius: 4,
    marginRight: 0,
    "&$checked": {
      color: "#812990 !important",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function PreAdmissaoDadosContratuais() {
  const history = useHistory();
  const { id: idPreAdmissao } = useParams();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [diasContratoExp, setDiasContratoExp] = useState(null);
  const [dataAdmissao, setDataAdmissao] = useState("");
  const [dataValidadeOcupacional, setDataValidadeOcupacional] = useState("");
  const [departamento, setDepartamento] = useState(null);
  const [horario, setHorario] = useState(null);

  const [atestadoOcupacional, setAtestadoOcupacional] = useState(null);
  const [tipoAdmissao, setTipoAdmissao] = useState(null);
  const [vinculoTrabalhista, setVinculoTrabalhista] = useState(null);
  const [categoria, setCategoria] = useState(null);
  const [funcao, setFuncao] = useState(null);
  const [salarioBase, setSalarioBase] = useState("");
  const [proventosDescontos, setProventosDescontos] = useState("");
  const [formaPagamento, setFormaPagamento] = useState(null);
  const [terminoContrato, setTerminoContrato] = useState("");
  const [listaTipoAdmissao, setListaTipoAdmissao] = useState([]);
  const [listaAtestadoOcupacional, setListaAtestadoOcupacional] = useState([]);
  const [listaDepartamento, setListaDepartamento] = useState([]);
  const [listaHorario, setListaHorario] = useState([]);
  const [listaVinculo, setListaVinculo] = useState([]);
  const [listaCategoria, setListaCategoria] = useState([]);
  const [listaFormaPagamento, setListaFormaPagamento] = useState([]);
  const [listaFuncao, setListaFuncao] = useState([]);
  const [checked, setChecked] = useState(false);
  const [firstJob, setFirstJob] = useState(true);

  const [loading, setLoading] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const loadPreAdmissao = async () => {
    if(!idPreAdmissao) {
      return;
    }

    if (idPreAdmissao) {
      const response = await preAdmissaoService.consultaPreAdmissao(idPreAdmissao);

      if (response.status === 200) {        
        const data = response.data;

        setDataAdmissao(data.data_admissao);
        setDataValidadeOcupacional(data.data_validade_atestado);
        setTipoAdmissao(data.id_tipo_admissao);
        setDepartamento(data.id_departamento_funcionario);
        setVinculoTrabalhista(data.id_vinculo_trabalhista);
        setDiasContratoExp(data.dias_contrato_experiencia);
        setFormaPagamento(data.id_forma_pagamento_salario);
        setFuncao(data.id_funcao_funcionario);
        setCategoria(data.id_categoria_funcionario);
        setHorario(data.id_tipo_horario);
        data.id_atestado_admissional !== null && setAtestadoOcupacional(data.id_atestado_admissional);
        data.proventos_descontos !== null && setProventosDescontos(data.proventos_descontos);
        data.primeiro_emprego !== null && setFirstJob(data.primeiro_emprego === 1 ? true : false);
        data.salario_base !== null && setSalarioBase(data.salario_base);
        data.data_termino_contrato !== null && setTerminoContrato(data.data_termino_contrato);
        data.admissao_seguro_acao_fiscal !== null && setChecked(data.admissao_seguro_acao_fiscal === 1 ? true : false);

      } else {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      }
    }
  };

  async function loadSelects() {
    setLoading(true);
    const responseAtestado = await selectServices.selecionaAtestadoOcupacional();
    const responseTipoAdmissao = await selectServices.selecionaTipoAdmissao();
    const responseVinculo = await selectServices.selecionaVinculoTrabalhista();
    const responseCategoria = await selectServices.selecionaCategoriaFuncionario();
    const responseFuncao = await selectServices.selecionaFuncaoFuncionario();
    const responseForma = await selectServices.selecionaFormaPagamento();
    const responseDepartamento = await selectServices.selecionaDepartamento();
    const responseHorario = await selectServices.selecionaTipoHorario();

    if (
      responseAtestado.status === 401 ||
      responseTipoAdmissao.status === 401 ||
      responseVinculo.status === 401 ||
      responseCategoria.status === 401 ||
      responseForma.status === 401 ||
      responseFuncao.status === 401 ||
      responseDepartamento.status === 401 ||
      responseHorario.status === 401
    ) {
      setLoading(false);
      setOpenLoginExpirado(true);
      return;
    }
    if (responseAtestado.status === 200)
      setListaAtestadoOcupacional(responseAtestado.data);
    if (responseTipoAdmissao.status === 200)
      setListaTipoAdmissao(responseTipoAdmissao.data);
    if (responseVinculo.status === 200) setListaVinculo(responseVinculo.data);
    if (responseCategoria.status === 200)
      setListaCategoria(responseCategoria.data);
    if (responseForma.status === 200)
      setListaFormaPagamento(responseForma.data);
    if (responseFuncao.status === 200) setListaFuncao(responseFuncao.data);
    if (responseHorario.status === 200) setListaHorario(responseHorario.data);
    if (responseDepartamento.status === 200) setListaDepartamento(responseDepartamento.data);
    setLoading(false);
  }

  useEffect(() => {
    (async function () {
      await loadSelects();
    })();

    loadPreAdmissao();
  }, []);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/pre-admissao");
    }
  }

  async function handleSubmit() {
    if (!validateFields()) return;
    setLoading(true);
    const response = await preAdmissaoService.cadastraDadosContratuais(
      idPreAdmissao,
      diasContratoExp,
      atestadoOcupacional,
      tipoAdmissao,
      firstJob ? 1 : 0,
      vinculoTrabalhista,
      categoria,
      departamento,
      funcao,
      horario,
      salarioBase,
      formaPagamento,
      terminoContrato,
      dataAdmissao,
      tipoAdmissao,
      dataValidadeOcupacional,
      checked ? 1 : 0,
      proventosDescontos
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    } else if (response.status !== 201 && response.status !== 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      setCallbackDuration(2000);
      return;
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage("Dados Contratuais salvos com sucesso!");
      setCallbackDuration(2000);
      return;
    }
  }

  function verifyField(field, callback) {
    if (field === "" || field === null || field === undefined) {
      callback();
    }
  }

  function validateFields() {
    let list = [];
    verifyField(dataAdmissao, () =>
      list.push('O campo "Data de Admissão" é obrigatório.')
    );
    verifyField(diasContratoExp, () =>
      list.push('O campo "Dias do contrato de experiência" é obrigatório.')
    );
    verifyField(tipoAdmissao, () =>
      list.push("Nenhum tipo de admissão selecionado.")
    );
    verifyField(vinculoTrabalhista, () =>
      list.push("Nenhum vínculo trabalhista selecionado.")
    );
    verifyField(categoria, () => list.push("Nenhuma categoria selecionada."));
    verifyField(departamento, () =>
      list.push('O campo "Departamento" é obrigatório.')
    );
    verifyField(horario, () => list.push('O campo "Horário" é obrigatório.'));
    verifyField(funcao, () => list.push("Nenhuma função selecionada."));
    verifyField(salarioBase, () =>
      list.push('O campo "Salário base" é obrigatório.')
    );
    verifyField(proventosDescontos, () =>
      list.push('O campo "Proventos e descontos" é obrigatório.')
    );
    verifyField(formaPagamento, () =>
      list.push("Nenhuma forma de pagamento selecionada.")
    );
    setCallbackErrorList(list);
    if (list.length > 0) {
      setCallbackType("error");
      setCallbackShown(true);
      setCallbackDuration(2000);
    }
    return list.length === 0;
  }

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <CallbackMessage
            open={callbackShown}
            duration={callbackDuration}
            errorList={callbackErrorList}
            handleClose={handleClose}
            message={callbackMessage}
            type={callbackType}
          />
          <Grid container>
            <form className="cadastrar-contratuais-form">
              <h3>Dados contratuais</h3>
              <Grid container item xs={12} spacing={2}>
                <Grid item className="gridXs4Force">
                  <DatePicker
                    initDate={dataAdmissao ? dataAdmissao : null}
                    label="Data de Admissão *"
                    handleChange={(value) => setDataAdmissao(value)}
                  />
                </Grid>
                <Grid item className="gridXs4Force">
                  <Input
                    required
                    nome="diasContratoExp"
                    label="Dias do contrato de experiência"
                    tipo="numero"
                    type="text"
                    value={diasContratoExp}
                    handleInputChange={(event) =>
                      setDiasContratoExp(event.target.value)
                    }
                  />
                </Grid>
                <Grid item className="gridXs4Force">
                  <SelectComponent
                    styleType="form"
                    label="Resultado do atestado ocupacional"
                    title="Selecione uma opção"
                    list={listaAtestadoOcupacional}
                    initialValue={listaAtestadoOcupacional.find(item => item.id === atestadoOcupacional) ? 
                      listaAtestadoOcupacional.find(item => item.id === atestadoOcupacional).nome : ''}
                    callback={(id) => setAtestadoOcupacional(id)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    initDate={dataValidadeOcupacional ? dataValidadeOcupacional : null}
                    label="Validade do atestado ocupacional"
                    handleChange={(value) => setDataValidadeOcupacional(value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Tipo de Admissão"
                    required
                    title="Selecione uma opção"
                    list={listaTipoAdmissao}
                    initialValue={listaTipoAdmissao.find(item => item.id === tipoAdmissao) ? 
                      listaTipoAdmissao.find(item => item.id === tipoAdmissao).nome : ''}
                    callback={(id) => setTipoAdmissao(id)}
                  />
                </Grid>
                <Grid container className="empregoWrapper">
                  <Grid item style={{ marginRight: 10 }}>
                    <span className="textSpan">Primeiro emprego?</span>
                  </Grid>
                  <Grid item style={{ margin: "0 8px" }}>
                    <p>Não</p>
                  </Grid>
                  <Grid item>
                    <SwitchComponent
                      checked={firstJob}
                      onClick={() => setFirstJob(!firstJob)}
                    />
                  </Grid>
                  <Grid item>
                    <p className="textChecked">Sim</p>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10, marginLeft: 4 }}>
                  <span className="textSpan">
                    Informação para o CAGED referente à Portaria nº. 1.129/2014
                  </span>
                </Grid>
                <Grid container className="checkboxWrapper">
                  <Grid item xs="auto">
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          size="small"
                          checked={checked}
                          onChange={() => {
                            setChecked(!checked);
                          }}
                          name="checked"
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <span className="textSpan">
                      Admissão durante percepção/requerimento do Seguro
                      Desemprego ou decorrida de ação fiscal?
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ marginTop: 12 }}>
                  <SelectComponent
                    required
                    styleType="form"
                    label="Vínculo Trabalhista"
                    title="Selecione uma opção"
                    initialValue={listaVinculo.find(item => item.id === vinculoTrabalhista) ? 
                      listaVinculo.find(item => item.id === vinculoTrabalhista).nome : ''}
                    list={listaVinculo}
                    callback={(id) => setVinculoTrabalhista(id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectComponent
                    required
                    styleType="form"
                    label="Categoria"
                    title="Selecione uma opção"
                    initialValue={listaCategoria.find(item => item.id === categoria) ? 
                      listaCategoria.find(item => item.id === categoria).nome : ''}
                    list={listaCategoria}
                    callback={(id) => setCategoria(id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectComponent
                    required
                    styleType="form"
                    label="Departamento"
                    title="Selecione uma opção"
                    initialValue={listaDepartamento.find(item => item.id == departamento) ? 
                      listaDepartamento.find(item => item.id == departamento).nome : ''}
                    list={listaDepartamento}
                    callback={(id) => setDepartamento(id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectComponent
                    required
                    styleType="form"
                    label="Função"
                    title="Selecione uma opção"
                    list={listaFuncao}
                    initialValue={listaFuncao.find(item => item.id === funcao) ? 
                      listaFuncao.find(item => item.id === funcao).nome : ''}
                    callback={(id) => setFuncao(id)}
                  />
                </Grid>
                <Grid item xs={12}>
                <SelectComponent
                    required
                    styleType="form"
                    label="Horário"
                    title="Selecione uma opção"
                    list={listaHorario}
                    initialValue={listaHorario.find(item => item.id == horario) ? 
                      listaHorario.find(item => item.id == horario).nome : ''}
                    callback={(id) => setHorario(id)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    required
                    nome="salarioBase"
                    label="Salário Base"
                    tipo="dinheiro"
                    title="R$"
                    value={salarioBase}
                    handleInputChange={(event) => setSalarioBase(event.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    nome="proventosDescontos"
                    label="Proventos e descontos"
                    required
                    tipo="dinheiro"
                    title="R$"
                    value={proventosDescontos}
                    handleInputChange={(event) =>
                      setProventosDescontos(event.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectComponent
                    required
                    styleType="form"
                    label="Forma Pagamento"
                    title="Selecione uma opção"
                    list={listaFormaPagamento}
                    initialValue={listaFormaPagamento.find(item => item.id === formaPagamento) ? 
                      listaFormaPagamento.find(item => item.id === formaPagamento).nome : ''}
                    callback={(id) => setFormaPagamento(id)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    initDate={terminoContrato ? terminoContrato : null}
                    label="Término do contrato"
                    handleChange={(value) => setTerminoContrato(value)}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            text="Salvar"
            className="btn-success"
            onClick={handleSubmit}
          />
        </span>
      </div>
      {openLoginExpirado && <ModalLoginExpirado open={openLoginExpirado} />}
    </>
  );
}

export default PreAdmissaoDadosContratuais;
