import Axios from "./Axios";

const loginService = {
  checkLogin: async ({ email, password }) => {
    // window.localStorage.removeItem("session");
    const result = await Axios.post(
      "/login",
      {
        email: email,
        password: password,
      },
      { headers: { Accept: "application/json", Authorization: null } }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return result;
  },
  sendEmail: async (email) => {
    const result = await Axios.post("/recupera-senha", {
      email: email,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return result;
  },
  atualizaSenha: async (token, email, password) => {
    const result = await Axios.post("/atualiza-senha", {
      token,
      email,
      password,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return result;
  },
  getLoginHubCount: async (idEmpresa) => {
    const result = await Axios.post("/login-hub-count", {
      id_empresa: idEmpresa
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return result;
  },
};

export default loginService;
