import Axios from "./Axios";

const boletoService = {
  consultaBoleto: async (idBoleto) => {
    const response = await Axios.get(`/consulta-boleto/${idBoleto}`)
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  cadastraConfiguracoes: async (
    id_empresa = null,
    instrucao = null,
    vencimento_plano = null
  ) => {
    const response = await Axios.post("/cadastra-boleto-configuracoes", {
      id_empresa,
      instrucao,
      vencimento_plano
    })
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  consultaConfiguracoes: async (id) => {
    const response = await Axios.get(`/consulta-boleto-configuracoes/${id}`)
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  atualizaBoleto: async (id) => {
    const response = await Axios.post(`/atualiza-boleto/${id}`)
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
};

export default boletoService;
