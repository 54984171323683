import React from "react";
import Icon from "../Icon";
import "./styles.scss";

function ButtonComponent({
  type = "button",
  text = "",
  className = "",
  onClick = () => { },
  icon = "",
  disabled = false,
  icone = "",
  iconeColor = "",
  iconeSize = "",
  ...rest
}) {
  return (
    <>
      <button
        type={type}
        className={`btn ${className}`}
        onClick={() => onClick()}
        disabled={disabled}
        {...rest}
      >
        {icon && (
          <span className="button-icon">{icon({ fontSize: "15px" })}</span>
        )}
        {icone && (
          <span className="button-icone">
            <Icon name={icone} size={iconeSize} color={iconeColor} />
          </span>
        )}
        {text}
      </button>
    </>
  );
}

export default ButtonComponent;
