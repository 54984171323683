import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TableComponent from "../TableComponent";
import OverlayLoading from "../OverlayLoading";
import Button from "../ButtonComponent";
import ModalLoginExpirado from "../ModalLoginExpirado";
import CallbackMessage from "../CallbackMessage";
import InputComponent from "../Input";
import produtosServices from '../../services/produtosServices';

import { numberToCurrency } from '../../utils/functions'
import classes from "../../components/Input/styles.module.scss";

import "./styles.scss";
import { ConvertMoney } from "../../utils/strings";

function ModalBuscarProduto({
  open,
  onClose,
  onConfirm,
  idNota,
  valorCusto=0
}) {

  const { listaProdutos } = produtosServices;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [checkedProdutosList, setCheckedProdutosList] = useState([]);
  const [checkedProduct, setCheckedProduct] = useState([]);

  const [listRequest, setListRequest] = useState([]);
  const [quantidade, setQuantidade] = useState(null);
  const [valorCustoUnitario, setValorCustoUnitario] = useState(0);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Código", dataRef: "codigo_produto" },
      { columnName: "Nome", dataRef: "descricao" },
      { columnName: "Tipo de produto", dataRef: "tipo_produto" },
      { columnName: "Valor", dataRef: "valor_unitario_tributario" },
    ],
    display: {
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      startCheckBoxProduct: true,
      submit: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });


  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);

    setLoading(true);

    const response = await listaProdutos(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
    );

    if (response.data) {
      if (response.data.hasOwnProperty("data")) {
        const { data } = response.data;

        const newData = data.map(item => ({
          ...item,
          id: item.id_produto,
          valor_unitario_tributario: numberToCurrency(item.valor_unitario_tributario),
        }))

        const newResult = {
          ...response.data,
          data: newData
        }

        setCheckedProdutosList(newData);
        setListRequest(newResult);
      } else {
        const { data } = response.data;

        const newData = data.map(item => ({
          ...item,
          id: item.id_produto,
          valor_unitario_tributario: numberToCurrency(item.valor_unitario_tributario),
        }))

        const newResult = {
          ...response.data,
          data: newData
        }

        setCheckedProdutosList(newData);
        setListRequest(newResult);
      }
    }
    setLoading(false);
  }, [defaultConfigTable.currentPage,
  defaultConfigTable.orderBy,
  defaultConfigTable.orderByType,
  defaultConfigTable.perPage,
  defaultConfigTable.searchTerm,
  ]);

  useEffect(() => {
    updateTable();
  }, [updateTable])

  let onPageChange = useCallback((newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onOrderBy = useCallback((newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onPerPage = useCallback((newPerPage) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onSearchTerm = useCallback((value) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }

  function listaStatus(list = []) {
    return list.map((item) => (
      item.bloqueado_inativo === 1 ? (
        <div className="card-table-danger" key={item.id_empresa}>Desativado</div>
      ) : (
        <div className="card-table-success" key={item.id_empresa}>Ativo</div>
      )
    ));
  }

  const handleConfirm = useCallback(() => {
    if (checkedProduct?.length > 1) {
      setShowMessage(true);
      setErrors(['Você não pode selecionar mais de um produto.'])
      return;
    }

    if(!quantidade || quantidade <= 0) {
      setShowMessage(true);
      return setErrors(['O campo "Quantidade de entrada no estoque" é obrigatório'])
    }

    let idProduct = checkedProduct[0];

    let product = listRequest?.data?.find(item => item.id === idProduct);

    onConfirm(product, quantidade, valorCustoUnitario);
    setValorCustoUnitario(0);
    setQuantidade(null);
  }, [checkedProduct, quantidade, valorCustoUnitario]);

  let onCheck = useCallback((checkList) => {
    (async () => {
      console.log('checkBoxObj', checkList);

      let cbList;
      cbList = checkList;

      setCheckedProduct(cbList);
    })();
  }, []);

  useEffect(() => {
    const newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect( () => {
    setValorCustoUnitario(quantidade > 0 ? valorCusto/quantidade : 0)
  }, [quantidade, valorCusto])

  const handleCloseModal = async () => {
    setLoading(false);
    onClose();
    setQuantidade(null);
    setValorCustoUnitario(0);
  }

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={handleCloseModal} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>BUSCAR EXISTENTE</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <TableComponent
          idName="id"
          dataList={listRequest !== undefined ? listRequest?.data : []}
          tableConfig={defaultConfigTable}
          checkList={checkedProdutosList}
          callbackCurrentPage={onPageChange}
          callbackOrderBy={onOrderBy}
          callbackPerPage={onPerPage}
          callbackSearchTerm={onSearchTerm}
          cbCheckBox={onCheck}
          customColumns={[
            {
              columnHead: <span>Status</span>,
              columnData: listaStatus(listRequest?.data),
            },
          ]}
        />
        <Grid item container spacing={2} className="container-grid-form">
          <Grid item xs={12} sm={12} md={6}>
            <InputComponent
              nome="valor"
              label="Quantidade de entrada no estoque"
              required
              tipo="decimal"
              value={quantidade}
              handleInputChange={(event) => {
                setQuantidade(event.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.inputLabel}>
              Valor de custo *
            </div>
            <div className="disabled-field">{ConvertMoney(!isNaN(valorCustoUnitario) ? valorCustoUnitario : 0)}</div>
          </Grid>
        </Grid>
        <div className="buttons-submit-footer">
          <Button
            text="Confirmar"
            onClick={handleConfirm}
            className="btn-success zero-margin"
          />
        </div>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalBuscarProduto;
