import React, { useState, useEffect, useContext } from "react";
import SelectComponent from "../../../components/SelectComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import Input from "../../../components/Input";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import centroCustoService from "../../../services/centroCustosService";
import { useHistory } from "react-router-dom";
import "./styles.css";
import useCompany from "../../../hooks/useCompany";

function CadastroCentroCusto() {
  const history = useHistory();
  const { companyList: listEmpresas, getEmpresaPagina } =
    useCompany("centro_custo");

  const [idEmpresaSelected, setIdEmpresaSelected] = useState();
  const [valueInput, setValueInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackErrors, setCallbackErrors] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [duration, setDuration] = useState(6000);
  const [errorObj, setErrorObj] = useState();

  function getSelectOption(id) {
    console.log({ id });
    setIdEmpresaSelected(id);
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success" && errorObj === undefined) {
      history.push("/centro-custo");
    }
  }

  async function handleClick() {
    try {
      setErrorObj(undefined);
      if (idEmpresaSelected === undefined && valueInput === "") {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackErrors([
          "Nenhuma empresa selecionada.",
          "O campo 'Nome do centro de custo' é obrigatório.",
        ]);
        setCallbackMessage("Erro!");
      } else if (idEmpresaSelected === undefined) {
        setCallbackShown(true);
        setCallbackType("success");
        setCallbackErrors(["Nenhuma empresa selecionada."]);
        setCallbackMessage("Erro!");
      } else if (valueInput === "") {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackErrors(["O campo 'Nome do centro de custo' é obrigatório."]);
        setCallbackMessage("Erro!");
      } else {
        setLoading(true);
        const response = await centroCustoService.addCentroCusto(
          idEmpresaSelected,
          valueInput
        );
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        } else if (response.status === 200 || response.status === 201) {
          setCallbackShown(true);
          setCallbackType("success");
          setCallbackMessage("Dados cadastrados com sucesso!");
          setDuration(2000);
        } else {
          setCallbackShown(true);
          setCallbackType("error");
          if (response.data.error)
            setCallbackErrors(Object.values(response.data.error));
          else setCallbackErrors(["Ocorreu um erro"]);
          setCallbackMessage("Erro!");
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  function handleInputChange(event) {
    const text = event.target.value;
    setValueInput(text);
  }

  useEffect(() => {
    (async function () {
      const empresaStorage = getEmpresaPagina("centro_custo");
      if (empresaStorage) {
        getSelectOption(empresaStorage);
      }
    })();
  }, []);

  return (
    <>
      <div className="session-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          {callbackShown && (
            <CallbackMessage
              errorObject={errorObj}
              type={callbackType}
              open={callbackShown}
              message={callbackMessage}
              errorList={callbackErrors}
              handleClose={handleClose}
              duration={duration}
            />
          )}
          <form className="session-container-form">
            <div className="input-area">
              <fieldset>
                <SelectComponent
                  styleType="form"
                  label="Empresa"
                  title="Selecione uma empresa"
                  list={listEmpresas}
                  initialValue={
                    listEmpresas?.find((item) => item.id === idEmpresaSelected)
                      ?.nome || null
                  }
                  required
                  callback={getSelectOption}
                />
              </fieldset>
              <fieldset className="input-children">
                <Input
                  title=""
                  label="Nome do centro de custo"
                  tipo="nome"
                  required
                  handleInputChange={(event) => handleInputChange(event)}
                />
              </fieldset>
              <div className="required-text">
                <span>* Campos obrigatórios</span>
              </div>
            </div>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <ButtonComponent
            text="Salvar"
            className="btn-success"
            onClick={handleClick}
          />
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastroCentroCusto;
