import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import Table from "../../../components/TableComponent";
import RemoveDialog from "../../../components/ConfirmDialog";
import ModalEstoque from "../../../components/ModalEstoque";
import { Grid } from "@material-ui/core";
import Select from "../../../components/SelectComponent";
import Button from "../../../components/ButtonComponent";
import "./styles.css";
import produtosService from "../../../services/produtosServices";

import { base64ToFile, numberToCurrency } from "../../../utils/functions";
import { useIsMount } from "../../../hooks/useIsMount";
import useCompany from "../../../hooks/useCompany";

const ListaProdutos = () => {
  const history = useHistory();

  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("produtos");
  const isMount = useIsMount();

  const [loading, setLoading] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [productId, setProductId] = useState(null);
  const [openModalInventory, setOpenModalInventory] = useState(false);

  const handleClose = (event, reason) => {
    console.log(reason);
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  };

  const [listRequest, setListRequest] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_produto" },
      { columnName: "Código", dataRef: "codigo_produto" },
      { columnName: "Descrição", dataRef: "descricao" },
      { columnName: "Tipo de Produto", dataRef: "tipo_produto" },
      { columnName: "Unid de Medida", dataRef: "unidade_medida_comercial" },
      { columnName: "Valor", dataRef: "valor_unitario_comercial" },
      { columnName: "Status", dataRef: "status" },
    ],
    options: {
      inventory: true,
      edit: true,
      delete: true,
      copy: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      statusLabels: true,
      filter: false,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-xls",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    id_empresa: null,
  });

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await produtosService.listaProdutos(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.id_empresa
    );

    if (result?.data.hasOwnProperty("data")) {
      const { data } = result;

      const newData = data.data.map((item) => {
        return {
          ...item,
          valor_unitario_comercial: numberToCurrency(
            item.valor_unitario_comercial
          ),
          status: Number(item.bloqueado_inativo) === 1 ? "Desativado" : "Ativo",
        };
      });

      const newResult = {
        ...data,
        data: newData,
      };
      setListRequest(newResult);
    } else {
      const newData = result.data.map((item) => {
        return {
          ...item,
          valor_unitario_comercial: numberToCurrency(
            item.valor_unitario_comercial
          ),
          status: Number(item.bloqueado_inativo) === 1 ? "Desativado" : "Ativo",
        };
      });

      const newResult = {
        ...result,
        data: newData,
      };

      setListRequest(newResult);
    }
    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
  ]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onEdit = (id) => {
    history.push(`/cadastro-produtos/${id}`, {
      isEdit: true,
    });
  };

  let onCopy = (id) => {
    history.push(`/cadastro-produtos`, {
      id,
      isCopy: true,
    });
  };

  let onInventory = (id) => {
    setProductId(id);
    setOpenModalInventory(true);
  };

  let handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  let handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  let onDelete = async () => {
    setLoading(true);

    const response = await produtosService.removerProduto(deleteId);

    if (response.status === 200) {
      setLoading(false);
      setCallbackErrorList([]);
      setCallbackMessage("Removido com sucesso!");
      setCallbackDuration(2000);
      setCallbackType("success");
      setCallbackShown(true);
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
      handleRemoveCancel();
      return;
    }

    if (response.status === 500) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackMessage("Erro!");
      setCallbackDuration(2000);
      setCallbackType("error");
      setCallbackErrorList(["Houve um erro interno no servidor."]);
      handleRemoveCancel();
      return;
    }

    if (response.status === 401) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackMessage("Erro!");
      setCallbackDuration(2000);
      setCallbackType("error");
      setCallbackErrorList(["Ocorreu algum erro inesperado, tente novamente."]);
      handleRemoveCancel();
      return;
    }

    if (response?.status === 400 || response?.response?.status) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackMessage("Erro!");
      setCallbackDuration(2000);
      setCallbackType("error");
      setCallbackErrorList(
        Object.values(
          response?.data?.error || response?.response?.data?.error
        ).flat(Infinity)
      );
      handleRemoveCancel();
      return;
    }

    setLoading(false);
    handleRemoveCancel();
  };

  let onCancelInventory = () => {
    setOpenModalInventory(false);
  };

  const handleSelectEmpresa = async (id) => {
    console.log(id);
    setEmpresaSelecionada(id);
  };

  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      return setDefaultConfigTable({
        ...defaultConfigTable,
        currentPage: 1,
        id_empresa: empresaStorage,
      });
    }
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_empresa: empresaSelecionada,
    });
    setEmpresaPagina("produtos", empresaSelecionada);
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
    });
    setEmpresaPagina("produtos", null);
  };

  const onTableMoreAction = async (action) => {
    if (!defaultConfigTable?.id_empresa) {
      setCallbackMessage("Erro!");
      setCallbackType("error");
      setCallbackErrorList([
        "É necessário selecionar uma empresa antes de exportar.",
      ]);
      setCallbackShown(true);
      return;
    }
    setLoading(true);
    const response = await produtosService.exportarRelatorio(
      defaultConfigTable?.id_empresa ? defaultConfigTable?.id_empresa : null
    );
    setLoading(false);
    if (response.status === 200) {
      base64ToFile(response.data.arquivo, "cbhub_relatorio_produtos", "xlsx");
    } else if (response.status === 500 || response?.response?.status === 500) {
      setCallbackErrorList([
        "Erro interno no servidor. Por favor, contate o suporte",
      ]);
      setCallbackType("error");
      setCallbackShown(true);
    } else if (response?.status === 400 || response?.response?.status === 400) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackMessage("Erro!");
      setCallbackDuration(5000);
      setCallbackType("error");
      setCallbackErrorList(
        Object.values(
          response?.data?.error || response?.response?.data?.error
        ).flat(Infinity)
      );
      return;
    } else {
      setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
      setCallbackType("error");
      setCallbackShown(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("produtos");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                      ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={handleSelectEmpresa}
            />
          </Grid>
          <Grid className="marginLeftAuto">
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <>
      {loading && <OverlayLoading />}
      <Table
        idName="id_produto"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onEdit}
        cbCopy={onCopy}
        cbDelete={handleRemoveDialog}
        cbInventory={onInventory}
        filterComponent={filterComponent()}
        cbTableMoreAction={onTableMoreAction}
      />
      <ModalEstoque
        open={openModalInventory}
        onClose={onCancelInventory}
        idProduto={productId}
        onSave={() => {}}
      />
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={"error"}
      />
      <RemoveDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />
    </>
  );
};

export default ListaProdutos;
