import Axios from "./Axios";

const fluxoCaixaService = {
  getFluxoCaixa: async ({ id_empresa, mes, ano, all }) => {
    const response = await Axios.get("/lista-fluxo-caixa", {
      params: {
        id_empresa,
        ano,
        mes,
        all
      },
    })
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  enviarEmailRelatorio: async ({
    assunto = null,
    mensagem = null,
    emails = [],
    cco = [],
    id_empresa = null,
    mes = null,
    ano = null,
    all = null,
  }) => {
    const response = await Axios.get('/exportar-relatorio-fluxo-caixa-email',{
      params: {
        id_empresa,
        ano,
        mes,
        all,
        assunto,
        mensagem,
        emails,
        cco,
      }
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  }
};

export default fluxoCaixaService;
