import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import PermissoesUsuario from "./PermissoesAcesso";

import Input from "../../../components/Input";
import Button from "../../../components/ButtonComponent";
import AutocompleteInput from "../../../components/AutocompleteInput";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import Select from "../../../components/SelectComponent";

import empresasService from "../../../services/empresasService";
import usuariosService from "../../../services/usuariosService";
import perfilAcessoService from "../../../services/perfilAcessoService";

import "./styles.css";

//falta deixar responsivo
function EditarUsuario() {
  const history = useHistory();
  const routeParams = useParams();

  const { selectEmpresa } = empresasService;
  const { atualizaUsuario, consultaUsuario } = usuariosService;
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const { selecionaPerfilAcesso } = perfilAcessoService;

  const [empresas, setEmpresas] = useState([]);
  const [perfilAcessoList, setPerfilAcessoList] = useState([]);

  const [perfil, setPerfil] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [selectedEmpresas, setSelectedEmpresas] = useState([]);
  const [initEmpresas, setInitEmpresas] = useState([]);
  const [active, setActive] = useState(1);

  const [permissoesToAdd, setPermissoesToAdd] = useState([]);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [loading, setLoading] = useState(false);
  const [empresasLoading, setEmpresasLoading] = useState(false);

  let { id: userIdParams } = routeParams;
  const [userId, setUserId] = useState(userIdParams);

  useEffect(() => {
    (async function loadEmpresas() {
      setEmpresasLoading(true);
      const response = await selectEmpresa();
      if (response?.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      setEmpresas(response.data);
      setEmpresasLoading(false);
    })();

    (async function loadPerfilAcesso() {
      setEmpresasLoading(true);
      const response = await selecionaPerfilAcesso();
      if (response?.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      setPerfilAcessoList(response.data);
      setEmpresasLoading(false);
    })();

    (async function loadUser() {
      if (!userId) return;
      setLoading(true);
      const response = await consultaUsuario(userId);
      if (response?.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      } else if (response.status === 400) {
        setCallbackType("error");
        setCallbackMessage(
          response.data.msg || response.data.message || "Erro!"
        );
        setCallbackErrorList(
          [response.data.error] || ["Erro ao consultar usuário!"]
        );
        setLoading(false);
        setCallbackShown(true);
        setUserId(null);
        return;
      }
      const { data } = response;

      const initialEmpresasIds = data.empresas.map((item) => {
        return {
          id: item,
        };
      });

      setPerfil(data.perfil_acesso);
      setNome(data.name);
      setEmail(data.email);
      setCpf(data.cpf);
      setInitEmpresas(initialEmpresasIds);
      setSelectedEmpresas(data.empresas);
      setActive(data.active);
      setLoading(false);
    })();
  }, [consultaUsuario, selectEmpresa, userId]);

  function handleSelectedValues(values) {
    const empresasIds = values.map((item) => {
      return item.id;
    });

    setSelectedEmpresas(empresasIds);
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/usuarios-cadastrados");
    }
  }

  function verifyField(field, callback) {
    if (field === "" || field === null || field === undefined) {
      callback();
    }
  }
  let cbOnSave = useCallback(
    (permissoes) => {
      (async () => {
        console.log(permissoes);
        let newPermissoes = permissoes?.map((item) => ({
          id: item?.id_permissao,
          checked: item?.permissao,
        }));

        setPermissoesToAdd(newPermissoes);
      })();
    },
    [permissoesToAdd]
  );

  function validateFields() {
    let list = [];
    verifyField(nome, () => list.push('O campo "Nome" é obrigatório.'));
    verifyField(email, () => list.push('O campo "E-mail" é obrigatório.'));
    verifyField(cpf, () => list.push('O campo "Cpf" é obrigatório.'));
    if (selectedEmpresas.length === 0) {
      list.push('O campo "Empresa" deve ser selecionado.');
    }

    setCallbackErrorList(list);
    if (list.length > 0) {
      setCallbackShown(true);
      setCallbackDuration(2000);
      setCallbackType("error");
    }
    return list.length === 0;
  }

  async function handleUserSubmit() {
    if (!validateFields()) return;
    setLoading(true);

    // const { id } = routeParams;

    let permissoesList = permissoesToAdd;

    let uniqueIds = [...new Set(permissoesList)];

    let addPermissions = uniqueIds
      .map((item) => {
        if (item.checked === true) {
          return item.id;
        }
        return undefined;
      })
      .filter((item) => item !== undefined);

    const response = await atualizaUsuario(
      userId,
      nome,
      email,
      cpf,
      selectedEmpresas,
      perfil,
      active,
      addPermissions
    );

    if (response?.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status !== 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      return;
    }
    if (response?.status === 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Usuário editado com sucesso!");
    }
  }

  const handleChangePerfilAcesso = (id) => {
    setPerfil(id);
    if (![1, 2].includes(id)) {
      setInitEmpresas([]);
      handleSelectedValues([]);
    }
  };

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {(loading || empresasLoading) && <OverlayLoading />}
          <CallbackMessage
            open={callbackShown}
            duration={callbackDuration}
            errorList={callbackErrorList}
            handleClose={handleClose}
            message={callbackMessage}
            type={callbackType}
          />
          <form className="form form-edit-user">
            <div className="name-input">
              <Input
                nome="nome"
                label="Nome"
                tipo="text"
                required={true}
                value={nome}
                handleInputChange={(event) => setNome(event.target.value)}
              />
            </div>
            <div className="email-input">
              <Input
                nome="email"
                label="E-mail"
                tipo="text"
                required={true}
                value={email}
                handleInputChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="cpf-input">
              <Input
                nome="cpf"
                label="CPF"
                tipo="cpf"
                required={true}
                value={cpf}
                handleInputChange={(event) => setCpf(event.target.value)}
              />
            </div>
            <div className="company-input">
              <AutocompleteInput
                label="Empresa"
                data={empresas}
                initValue={initEmpresas}
                handleSelectedValues={(values) => handleSelectedValues(values)}
                key={(option) => option.id}
                getOptionLabel={(option) => option.nome}
                getOptionSelected={(option, value) => option.id === value.id}
                required
                page="usuarios"
              />
            </div>
            <div className="profile-input">
              <Select
                list={perfilAcessoList}
                label="Perfil de Acesso"
                title="Selecione um perfil"
                styleType="form"
                initialValue={
                  perfilAcessoList.find((item) => item.id === perfil)
                    ? perfilAcessoList.find((item) => item.id === perfil).nome
                    : ""
                }
                callback={(id) => handleChangePerfilAcesso(id)}
              />
            </div>
            <div className="checkbox-input">
              <div className="container-checkbox-input-ativo">
                <input
                  id="showAllCategories"
                  type="checkbox"
                  name="showAllCategories"
                  checked={active}
                  onChange={(event) => setActive(active ? 0 : 1)}
                  autocomplete="off"
                />
                <label
                  className="label-input-checkbox"
                  htmlFor="showAllCategories"
                >
                  Ativo
                </label>
              </div>
            </div>
            <div className="hint-form">
              <span className="required-text ">* Campos obrigatórios</span>
            </div>
          </form>
        </span>
      </div>
      <h2 className="header-bar-title-page editar-usuario-permissoes-title">
        Permissões de Acesso
      </h2>
      <PermissoesUsuario onSave={cbOnSave} userId={userId} />
      <div className="button-area editar-usuario-button-area">
        <span>
          <Button
            type="button"
            text="Salvar"
            className="btn-success"
            onClick={handleUserSubmit}
          />
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default EditarUsuario;
