import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "../../components/SelectComponent";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import Button from "../../components/ButtonComponent";
import OverlayLoading from "../../components/OverlayLoading";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import ConfirmDialog from "../../components/ConfirmDialog";
import CallbackMessage from "../../components/CallbackMessage";
import DatePicker from "../../components/DatePicker";
import empresasService from "../../services/empresasService";
import clientesService from "../../services/clientesService";
import viacepServices from "../../services/viacepServices";
import servicosService from "../../services/servicosService";
import notasFiscaisService from "../../services/notasFiscaisService";

import "./styles.scss";
import recebimentosService from "../../services/recebimentosService";
import useCompany from "../../hooks/useCompany";

function Emissao() {
  const history = useHistory();

  const {
    companyList: prestadorDeServicoList,
    selectedCompany: prestadorDeServico,
    setSelectedCompany: setPrestadorDeServico,
    getEmpresaPagina,
  } = useCompany("nfse");

  const { consultaEmpresa } = empresasService;
  const { selecionaCliente, consultaCliente } = clientesService;
  const { selecionaEstado, selecionaCidade } = viacepServices;
  const { selecionaServico } = servicosService;
  const { cadastrarNota, calcularImposto, controleNota } = notasFiscaisService;
  const { selecionaCategoriaRecebimento } = recebimentosService;

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [openModalAccept, setOpenModalAccept] = useState(false);

  const [clientesList, setClientesList] = useState([]);
  const [CPFRazaoSocial, setCPFRazaoSocial] = useState();
  const [estadosList, setEstadosList] = useState([]);
  const [estado, setEstado] = useState();
  const [cidadesList, setCidadesList] = useState([]);
  const [cidade, setCidade] = useState();
  const [codigoList, setCodigoList] = useState([]);
  const [codigo, setCodigo] = useState();
  const [ISSRetido, setISSRetido] = useState();
  const [discriminacao, setDiscriminacao] = useState();
  const [outrasInformacoes, setOutrasInformacoes] = useState();

  const [listCategorias, setListCategorias] = useState([]);
  const [idCategoria, setIdCategoria] = useState(null);

  const [dataEmissao, setDataEmissao] = useState();
  const [valorTotalNF, setValorTotalNF] = useState(null);
  const [valorTotalDeducoes, setValorTotalDeducoes] = useState(null);
  const [descIncondicionados, setDescIncondicionados] = useState(null);
  const [ISS, setISS] = useState(null);
  const [COFINS, setCOFINS] = useState(null);
  const [CSLL, setCSLL] = useState(null);
  const [PIS, setPIS] = useState(null);
  const [IRPJ, setIRPJ] = useState(null);
  const [INSS, setINSS] = useState(null);
  const [outros, setOutros] = useState(null);
  const [pfPj, setPfPj] = useState("");

  const [cidadeValue, setCidadeValue] = useState("");

  const [loading, setLoading] = useState(false);
  const [fieldsLoading, setFieldsLoading] = useState(false);
  const [clientesLoading, setClientesLoading] = useState(false);
  const [servicosLoading, setServicosLoading] = useState(false);
  const [cidadesLoading, setCidadesLoading] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackErrors, setCallbackErrors] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [duration, setDuration] = useState(6000);

  const [dataCopy, setDataCopy] = useState({});
  const [initialValueClient, setInitialValueClient] = useState(null);
  const [initialValueEstado, setInitialValueEstado] = useState(null);
  const [initialServico, setInitialServico] = useState(null);

  const issOptions = [
    {
      id: 1,
      nome: "SIM",
    },
    {
      id: 0,
      nome: "NÃO",
    },
  ];

  const handleCalcularImposto = useCallback(async () => {
    if (codigo === undefined || ISSRetido === undefined) return;
    setFieldsLoading(true);

    const response = await calcularImposto(
      prestadorDeServico,
      valorTotalNF,
      codigo,
      ISSRetido,
      outros || 0.0,
      pfPj
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const { data } = response;

    if (response.status === 200) {
      setISS(data.valor_iss);
      setCOFINS(data.valor_cofins);
      setCSLL(data.valor_csll);
      setPIS(data.valor_pis);
      setIRPJ(data.valor_irpj);
      setINSS(data.valor_inss);
      setValorTotalDeducoes(data.valor_total_deducoes);
    }

    setFieldsLoading(false);
  }, [
    ISSRetido,
    calcularImposto,
    codigo,
    outros,
    pfPj,
    prestadorDeServico,
    valorTotalNF,
  ]);

  const handleIssRetido = useCallback((id) => {
    setISSRetido(id);
    handleCalcularImposto();
  }, []);

  const handleSelectUF = useCallback(
    (id) => {
      setEstado(id);
      setCidadeValue("");
      loadCidades(id);
    },
    [estadosList]
  );

  async function loadEstados() {
    const response = await selecionaEstado();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setEstadosList(response.data);
  }

  async function loadClientes(id) {
    setClientesLoading(true);
    const response = await selecionaCliente(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    console.log("loadClientes :: ", response);
    setClientesList(response.data);
    setClientesLoading(false);
  }

  async function loadCidades(id) {
    setCidadesLoading(true);
    const response = await selecionaCidade(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return [];
    }
    setCidadesList([]);
    setCidade(null);
    setCidadeValue("");
    setCidadesList(response.data);
    setCidadesLoading(false);
    return response.data;
  }

  async function loadServicos(id) {
    setServicosLoading(true);
    const response = await selecionaServico(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setCodigoList(response.data);
    setServicosLoading(false);
  }

  async function loadEmpresaInfo(id) {
    const response = await consultaEmpresa(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    console.log({ uf: response?.data?.uf });
    console.log({ estadosList });
    if (response?.data?.uf) {
      const idEstadoTemp = estadosList?.find(
        (item) => item?.uf?.toLowerCase() === response?.data?.uf?.toLowerCase()
      );
      console.log({ idEstadoTemp });
      if (idEstadoTemp) {
        setInitialValueEstado(idEstadoTemp);
        setEstado(idEstadoTemp?.id);
        setCidadeValue("");
        const cidadesListTemp = await loadCidades(idEstadoTemp.id);
        if (response?.data?.cidade) {
          const cidadeTemp = cidadesListTemp?.find(
            (item) =>
              item?.nome
                ?.toLowerCase()
                ?.normalize("NFD")
                ?.replace(/[\u0300-\u036f]/g, "") ===
              response?.data?.cidade
                ?.toLowerCase()
                ?.normalize("NFD")
                ?.replace(/[\u0300-\u036f]/g, "")
          );
          setCidadeValue(cidadeTemp);
          setCidade(cidadeTemp?.id);
        }
      }
    }
  }

  async function loadCategorias(id) {
    let response = await selecionaCategoriaRecebimento(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) setListCategorias(response.data);
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.goBack();
    }
  }
  async function validateLimiteQtd(id) {
    const response = await controleNota(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      return;
    } else if (response.status === 201 || response.status === 200) {
      if (response.data.limite_excedido) {
        setOpenModalAccept(true);
      }
    }
  }

  async function handleClickPrestador(id) {
    await validateLimiteQtd(id);
    setPrestadorDeServico(id);
    loadClientes(id);
    loadServicos(id);
    loadEmpresaInfo(id);
    loadCategorias(id);
  }

  async function handleSelectCliente(id) {
    const response = await consultaCliente(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const { data } = response;

    const cpfCnpj = data.cnpj_cpf;
    console.log(data);
    if (cpfCnpj.length === 14) {
      setPfPj("pj");
    } else if (cpfCnpj.length === 11) {
      setPfPj("pf");
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrors(["Tomador de serviço sem dados de CPF/CNPJ"]);
      setDuration(3000);
      return;
    }

    setCPFRazaoSocial(id);
  }

  const handleServicoChange = (id) => {
    setCodigo(id);
    let iss = codigoList.find((item) => id === item.id);
    console.log("ISS :: ", iss);
    if (iss.iss_retido) {
      setISSRetido(issOptions[0].id);
    } else {
      setISSRetido(issOptions[1].id);
    }

    return handleCalcularImposto();
  };

  async function handleSubmit() {
    if (!validateFields()) return;

    setLoading(true);
    await cadastrarNota(
      prestadorDeServico,
      CPFRazaoSocial,
      valorTotalNF,
      estado,
      cidade,
      codigo,
      ISSRetido,
      discriminacao,
      ISS,
      COFINS,
      CSLL,
      PIS,
      IRPJ,
      INSS,
      outros || "0.00",
      valorTotalDeducoes,
      descIncondicionados || "0.00",
      outrasInformacoes,
      idCategoria
    ).then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 201) {
        setCallbackShown(true);
        setCallbackType("success");
        setDuration(3000);
        setCallbackMessage("Nota fiscal emitida com sucesso!");
        setLoading(false);
      } else {
        setCallbackShown(true);
        setCallbackType("error");
        setDuration(3000);
        setCallbackMessage("Erro!");
        if (response.data.error)
          setCallbackErrors([Object.values(response.data.error)]);
        else setCallbackErrors(["Ocorreu um erro"]);
        setLoading(false);
        return;
      }
    });
  }
  function verifyField(field, callback) {
    if (field === "" || field === null || field === undefined) {
      callback();
    }
  }

  function validateFields() {
    let listErrors = [];
    verifyField(prestadorDeServico, () =>
      listErrors.push('O campo "Prestador de Serviço" não foi selecionado.')
    );
    verifyField(CPFRazaoSocial, () =>
      listErrors.push('O campo "CPF/CNPJ ou Razão Social" não foi selecionado.')
    );
    verifyField(dataEmissao, () =>
      listErrors.push('O campo "Data de Emissão" é obrigatório.')
    );
    verifyField(estado, () =>
      listErrors.push('O campo "Estado" é obrigatório.')
    );
    verifyField(cidade, () =>
      listErrors.push('O campo "Cidade" é obrigatório.')
    );
    verifyField(codigo, () =>
      listErrors.push('O campo "Serviço Cadastrado" não foi selecionado.')
    );
    verifyField(ISSRetido, () =>
      listErrors.push('O campo "ISS Retido" não foi selecionado.')
    );
    verifyField(discriminacao, () =>
      listErrors.push('O campo "Discriminação do Serviço" é obrigatório.')
    );
    verifyField(valorTotalNF, () =>
      listErrors.push('O campo "Valor Total Nota Fiscal" é obrigatório.')
    );
    verifyField(idCategoria, () =>
      listErrors.push('O campo "Categoria" é obrigatório.')
    );
    if (listErrors.length > 0) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrors(listErrors);
      setDuration(3000);
    }
    return listErrors.length === 0;
  }

  useEffect(() => {
    (async function () {
      setLoading(true);
      if (estadosList?.length === 0) {
        await loadEstados();
      } else {
        const empresaStorage = getEmpresaPagina("nfse");
        if (empresaStorage) {
          handleClickPrestador(empresaStorage);
        }
      }

      setLoading(false);
    })();
  }, [estadosList]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleCalcularImposto();
    }, 800);

    return () => clearTimeout(delayDebounceFn);
  }, [valorTotalNF, outros, handleCalcularImposto]);

  useEffect(() => {
    const dataNFSE = history.location.state;

    if (dataNFSE) {
      setDataCopy(dataNFSE);
      setDiscriminacao(dataNFSE.discriminacao_servico);
      setValorTotalNF(dataNFSE.valor_total_nota);
      setValorTotalDeducoes(dataNFSE.valor_total_deducoes);
      setDescIncondicionados(dataNFSE.descontos_incondicionados);
      setINSS(dataNFSE.valor_iss_retido);
      setCOFINS(dataNFSE.valor_cofins);
      setCSLL(dataNFSE.valor_csll);
      setPIS(dataNFSE.valor_pis);
      setIRPJ(dataNFSE.valor_irpj);
      setINSS(dataNFSE.valor_inss);
      setOutros(dataNFSE.valor_outros);
      setOutrasInformacoes(dataNFSE.outras_informacoes);
    }

    if (prestadorDeServicoList.length > 0 && dataNFSE) {
      const prestadorFound = prestadorDeServicoList.find(
        (prestador) => prestador.id === dataNFSE.id_empresa
      );
      if (prestadorFound) {
        handleClickPrestador(prestadorFound.id);
      }
    }
  }, [prestadorDeServicoList]);

  useEffect(() => {
    if (clientesList.length > 0 && dataCopy) {
      const clientFound = clientesList.find(
        (client) => client.id === dataCopy.id_cliente
      );
      if (clientFound) {
        setInitialValueClient(clientFound);
        handleSelectCliente(clientFound.id);
      }
    }
  }, [clientesList, dataCopy]);

  useEffect(() => {
    if (estadosList.length > 0 && dataCopy) {
      const ufFound = estadosList.find(
        (estado) => estado.id === dataCopy.id_uf
      );
      if (ufFound) {
        console.log("estado");
        console.log(ufFound);
        setInitialValueEstado(ufFound);
        handleSelectUF(ufFound.id);
      }
    }
  }, [estadosList, dataCopy]);

  useEffect(() => {
    if (cidadesList.length > 0 && dataCopy) {
      const cityFound = cidadesList.find(
        (city) => city.id === dataCopy.id_cidade
      );
      if (cityFound) {
        console.log("cidade");
        console.log(cityFound);
        setCidadeValue(cityFound);
        setCidade(cityFound.id);
      }
    }
  }, [cidadesList, dataCopy]);

  useEffect(() => {
    if (issOptions.length > 0 && dataCopy) {
      const issFound = issOptions.find((iss) => iss.id === dataCopy.iss_retido);
      if (issFound) {
        console.log("ISS");
        console.log(issFound);
        handleIssRetido(issFound.id);
      }
    }
  }, [dataCopy]);

  useEffect(() => {
    if (codigoList.length > 0 && dataCopy) {
      console.log("lista de serviços");
      console.log(codigoList);
      const servicoFound = codigoList.find(
        (servico) => servico.id === dataCopy.id_servico_empresa
      );
      if (servicoFound) {
        console.log("serviço");
        console.log(servicoFound);
        setInitialServico(servicoFound);
        handleServicoChange(servicoFound.id);
        handleCalcularImposto();
      }
    }
  }, [codigoList, dataCopy]);

  return (
    <>
      <div className="session-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <form className="emitir-nf-form-container">
            <fieldset>
              <h1 className="emitir-nf-form-title">1. Prestador de Serviço</h1>
              <div className="emitir-nf-form-1-section-container">
                <div className="emitir-nf-form-1-section-select">
                  <Select
                    styleType="form"
                    label="Prestador de Serviço"
                    required
                    list={prestadorDeServicoList}
                    title="Selecione"
                    callback={handleClickPrestador}
                    initialValue={
                      prestadorDeServicoList?.find(
                        (item) => item.id === prestadorDeServico
                      )?.nome || null
                    }
                  />
                </div>
                <div className="emitir-nf-form-1-section-input">
                  <DatePicker
                    label="Data de Emissão *"
                    handleChange={(event) => setDataEmissao(event)}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="emitir-nf-form-2-section-fieldset">
              <h1 className="emitir-nf-form-title">2. Tomador de Serviço</h1>
              <div className="emitir-nf-form-2-section-container">
                <div className="emitir-nf-form-2-section-select">
                  <Select
                    styleType="form"
                    label="CPF/CNPJ ou Razão social"
                    list={clientesList}
                    loading={clientesLoading}
                    required
                    title="Selecione"
                    callback={(id) => handleSelectCliente(id)}
                    initialValue={initialValueClient}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <h1 className="emitir-nf-form-title">3. Serviço Prestado</h1>
              <h4 className="emitir-nf-form-subtitle">
                Localidade de prestação do serviço *
              </h4>
              <div className="emitir-nf-form-3-section-container">
                <div className="emitir-nf-form-3-section-estado-select">
                  <Select
                    styleType="form"
                    label="Estado"
                    required
                    list={estadosList}
                    title="Selecione"
                    callback={(id) => handleSelectUF(id)}
                    initialValue={initialValueEstado}
                  />
                </div>
                <div className="emitir-nf-form-3-section-cidade-select">
                  <Select
                    styleType="form"
                    label="Cidade"
                    required
                    list={cidadesList}
                    loading={cidadesLoading}
                    title="Selecione"
                    initialValue={cidadeValue}
                    callback={(id) => setCidade(id)}
                  />
                </div>
                <div className="emitir-nf-form-3-section-codigo-select">
                  <Select
                    styleType="form"
                    label="Serviço Cadastrado"
                    required
                    list={codigoList}
                    search={false}
                    loading={servicosLoading}
                    title="Selecione"
                    initialValue={initialServico}
                    callback={(id) => handleServicoChange(id)}
                  />
                </div>
                <div className="emitir-nf-form-3-section-servico-textarea">
                  <Textarea
                    handleInputChange={(event) =>
                      setDiscriminacao(event.target.value)
                    }
                    label="Discriminação do Serviço *"
                    // inputValue={discriminacao}
                    value={discriminacao}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <h1 className="emitir-nf-form-title">4. Valores e Tributos</h1>
              <h4 className="emitir-nf-form-subtitle">
                Valores da Nota Fiscal
              </h4>
              <div className="emitir-nf-form-4-section-container">
                <div className="emitir-nf-form-4-section-nf-input">
                  <Input
                    tipo="dinheiro"
                    label="Valor Total da NF (R$) *"
                    handleInputChange={(event) => {
                      setValorTotalNF(event.value);
                    }}
                    value={valorTotalNF}
                  />
                </div>
                <div className="emitir-nf-form-4-section-deducoes-input">
                  <Input
                    tipo="dinheiro"
                    title={valorTotalDeducoes === null ? "0,00" : null}
                    label="Valor Total Deduções (R$)"
                    loading={fieldsLoading}
                    value={valorTotalDeducoes}
                    readOnly
                  />
                </div>
                <div className="emitir-nf-form-4-section-desc-input">
                  <Input
                    tipo="dinheiro"
                    label="Desc. incondicionados (R$)"
                    handleInputChange={(event) =>
                      setDescIncondicionados(event.value)
                    }
                    value={descIncondicionados}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <h1 className="emitir-nf-form-title">5. Tributos</h1>
              <h4 className="emitir-nf-form-subtitle">Retenção de Tributos</h4>
              <div className="emitir-nf-form-5-section-container">
                <div className="emitir-nf-form-5-section-iss-input">
                  <Input
                    tipo="dinheiro"
                    title={ISS === null ? "0,00" : null}
                    label="ISS Retido (R$)"
                    value={ISS}
                    readOnly
                  />
                </div>
                <div className="emitir-nf-form-5-section-cofins-input">
                  <Input
                    tipo="dinheiro"
                    title={COFINS === null ? "0,00" : null}
                    label="COFINS (R$)"
                    value={COFINS}
                    readOnly
                  />
                </div>
                <div className="emitir-nf-form-5-section-csll-input">
                  <Input
                    tipo="dinheiro"
                    title={CSLL === null ? "0,00" : null}
                    label="CSLL (R$)"
                    value={CSLL}
                    readOnly
                  />
                </div>
                <div className="emitir-nf-form-5-section-pis-input">
                  <Input
                    tipo="dinheiro"
                    title={PIS === null ? "0,00" : null}
                    label="PIS (R$)"
                    value={PIS}
                    readOnly
                  />
                </div>
                <div className="emitir-nf-form-5-section-irpj-input">
                  <Input
                    tipo="dinheiro"
                    title={IRPJ === null ? "0,00" : null}
                    label="IRPJ (R$)"
                    value={IRPJ}
                    readOnly
                  />
                </div>
                <div className="emitir-nf-form-5-section-inss-input">
                  <Input
                    tipo="dinheiro"
                    title={INSS === null ? "0,00" : null}
                    label="INSS (R$)"
                    value={INSS}
                    readOnly
                  />
                </div>
                <div className="emitir-nf-form-5-section-outros-input">
                  <Input
                    tipo="dinheiro"
                    label="OUTROS (R$)"
                    handleInputChange={(event) => setOutros(event.value)}
                    value={outros}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <h1 className="emitir-nf-form-title">6. Dados Adicionais</h1>

              <div className="emitir-nf-form-3-section-categoria-select">
                <Select
                  styleType="form"
                  label="Categoria"
                  required
                  list={listCategorias}
                  title="Selecione uma categoria"
                  callback={(id) => setIdCategoria(id)}
                />
              </div>
              <div className="emitir-nf-form-3-section-servico-textarea">
                <Textarea
                  handleInputChange={(event) =>
                    setOutrasInformacoes(event.target.value)
                  }
                  label="Outras informações"
                  value={outrasInformacoes}
                />
              </div>
            </fieldset>
            <div className="required-text">
              <span>* Campos obrigatórios</span>
            </div>
            <div className="emitir-nf-form-submit-button">
              <Button
                type="button"
                onClick={() => handleSubmit()}
                className="flat-default"
                text="GERAR NOTA FISCAL"
              />
            </div>
          </form>
        </span>
      </div>
      <ConfirmDialog
        acceptLabel="Seguir"
        btnClass="btn-success-strong"
        cancelLabel="Cancelar"
        description={`Você excedeu o limite de notas do seu pacote contratado, você deseja fazer upgrade no plano?\n\nCaso decida continuar com a emissão, a mesma será cobrada como adicional conforme seu contrato.`}
        open={openModalAccept}
        title="LIMITE DO PLANO EXCEDIDO"
        onCancel={() => history.goBack()}
        onClose={() => {}}
        onAccept={() => setOpenModalAccept(false)}
      />
      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        message={callbackMessage}
        errorList={callbackErrors}
        handleClose={handleClose}
        duration={duration}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default Emissao;
