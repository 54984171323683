export function base64ToFile(base64, fileName, customExtension = null) {
  const extension = base64.substring(
    base64.indexOf("/") + 1,
    base64.indexOf(";base64")
  );
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var byteCharacters = atob(base64);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray]);
    window.navigator.msSaveOrOpenBlob(
      blob,
      fileName + `.${customExtension || extension}`
    );
    return;
  }
  const linkSource = base64;
  const link = document.createElement("a");
  link.href = linkSource;
  link.download = fileName + `.${customExtension || extension}`;
  document.body.appendChild(link);
  link.click();
  link.remove();

  window.addEventListener(
    "focus",
    function () {
      URL.revokeObjectURL(link.href);
    },
    { once: true }
  );
}

export function currencyToNumber(valor) {
  return Number(
    valor.substring(3, valor.length).replace(".", "").replace(",", ".")
  );
}

export function numberToCurrency(valor) {
  if (valor == null) valor = 0;
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(valor);
}

export function numberToNegativeCurrency(valor) {
  valor = valor.toString();
  if (valor.includes("-")) valor = valor.substring(1);

  valor = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(valor);

  let itemValor = valor.split(/(\s+)/);
  valor = itemValor[0] + " -" + itemValor[2];

  return valor;
}

export function formataNumero(numero) {
  return Intl.NumberFormat("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(!isNaN(numero) ? numero : 0);
}
