import Axios from "./Axios";

const buscaCepService = {
  getCep: async (cepValue = null) => {
    const result = await Axios.get("/busca-cep", {
      params: {
        cep: cepValue,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};

export default buscaCepService;
