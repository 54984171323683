import React, { useState, useEffect, useCallback, useRef } from "react";
import { debounce } from "throttle-debounce";
import ReactTooltip from "react-tooltip";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import { ClickAwayListener, Collapse, IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import SelectField from "../SelectComponent";
import Button from "../ButtonComponent";
import Icon from "../Icon";
import InputCheck from "../InputCheck";
import nfeIcon from "../../assets/img/nf-e.svg";
import searchIcon from "../../assets/img/search.svg";
import nfeIcon2 from "../../assets/img/nf-e2.svg";
import iconSearch from "../../assets/img/search.svg";
import iconAceite from "../../assets/img/aceitar.png";
import iconDelete from "../../assets/img/delete.svg";
import editIcon from "../../assets/img/Vectorpencil-icon.svg";
import updateIcon from "../../assets/img/update.svg";
import PreviewIcon from "../../assets/img/preview.svg";
import deleteIcon from "../../assets/img/Vectortrash.svg";
import fileDownloadIcon from "../../assets/img/download-file.svg";

import exportData from "../../services/exportData";

import colors from "../../assets/styles/_colors.scss";
import { cpfCnpjMask, onlyUnsignedNumbers } from "../../utils/strings";
import "./styles.scss";
import { TooltipComponent } from "../Tooltip";

const defaultConfigTable = {
  columnArray: [],
  pagination: true,
  totalPages: 1,
  dataListTotal: 0,
  perPage: 10,
};

export function MoreOptions({
  options,
  lineId,
  callback,
  handleMouseDown,
  displayHeader = false,
  showOptionalLabel = false,
  elementData = null,
}) {
  let onClick = (e) => {
    let data;
    if (displayHeader) {
      data = {
        action: e.target.dataset.action,
      };
    } else
      data = {
        id: lineId,
        action: e.target.dataset.action,
      };
    callback(data);
  };
  return (
    <>
      {displayHeader && (
        <div className="modal-options-header">
          <ul className="modal-options-list-header">
            {options.map((option, index) => {
              if (
                option.action === "estorno" &&
                elementData?.status_pagamento !== "BAIXADA"
              ) {
                return null;
              }
              return (
                <React.Fragment key={index}>
                  <li
                    className="modal-options-list-item"
                    data-action={option.action}
                    onMouseDown={(e) => {
                      handleMouseDown();
                      onClick(e);
                    }}
                  >
                    <span className="modal-options-list-item-icon">
                      <Icon name={option.icon} color="#0a2346" />
                    </span>
                    <span className="modal-options-list-item-label">
                      {showOptionalLabel && option.labelOpcional
                        ? option.labelOpcional
                        : option.label}
                    </span>
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      )}
      {!displayHeader && (
        <div className="modal-options">
          <ul className="modal-options-list">
            {options.map((option, index) => {
              if (
                option.action === "estorno" &&
                elementData?.status_pagamento !== "BAIXADA"
              ) {
                return null;
              }
              return (
                <React.Fragment key={index}>
                  <li
                    className="modal-options-list-item"
                    data-action={option.action}
                    onMouseDown={(e) => {
                      handleMouseDown();
                      onClick(e);
                    }}
                  >
                    <span className="modal-options-list-item-icon">
                      <Icon name={option.icon} />
                    </span>
                    <span className="modal-options-list-item-label">
                      {showOptionalLabel && option.labelOpcional
                        ? option.labelOpcional
                        : option.label}
                    </span>
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}

export function Search({
  callback,
  tableOptions,
  filterComponent,
  callbackMenu,
  customFilterTitle,
  customFilter,
  filterOpen,
  onClickFilter,
  searchTermRef,
}) {
  const [showFilter, setShowFilter] = useState(true);
  const [moreOptionsDisplay, setMoreOptionsDisplay] = useState(false);
  const debounceChange = debounce(750, (value) => {
    callback(value);
  });

  document.documentElement.onclick = function (event) {
    if (event.target !== moreOptionsDisplay) {
      setMoreOptionsDisplay(false);
    }
  };

  let onChange = (e) => {
    debounceChange(e.target.value);
  };

  return (
    <div className="search-container">
      <Grid item container>
        {customFilterTitle && (
          <Grid item container alignItems="flex-end">
            <h1 className="custom-filter-title">{customFilterTitle}</h1>
          </Grid>
        )}
        <Grid
          className="search-input-grid-container"
          item
          xs
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={1}
        >
          {tableOptions && tableOptions.noSearch ? (
            <Grid item></Grid>
          ) : (
            <Grid item className="search-input-input-container">
              <div className="search-input">
                <Icon name="search" className="search-icon" />
                <input
                  onChange={onChange}
                  placeholder="Pesquisar"
                  ref={searchTermRef}
                />
              </div>
            </Grid>
          )}
          {tableOptions && tableOptions.filter && (
            <Grid item className="filter-icon">
              <div
                className="icon-wrapper"
                onClick={() => setShowFilter(!showFilter)}
              >
                <Icon name="filter" color="#EF4066" size={18} />
              </div>
            </Grid>
          )}
          {tableOptions && tableOptions.customFilter && (
            <Grid item className="filter-icon">
              <div className="icon-wrapper" onClick={onClickFilter}>
                <Icon name="filter" color={colors.colorPrimary} size={18} />
              </div>
            </Grid>
          )}
          {tableOptions && tableOptions.more && (
            <Grid item className=" filter-icon moreoptionsdisplay-wrapper">
              <div
                className="icon-wrapper"
                onClick={() => setMoreOptionsDisplay((prev) => !prev)}
              >
                <Icon
                  name="hamburger"
                  size={18}
                  color="#EF4066"
                  className="icon"
                />
              </div>
              {moreOptionsDisplay && (
                <MoreOptions
                  handleMouseDown={() => setMoreOptionsDisplay(false)}
                  options={tableOptions.moreOptions}
                  callback={callbackMenu}
                  displayHeader
                />
              )}
            </Grid>
          )}
          {/* {tableOptions && !tableOptions.more && tableOptions.filter && (
            <div style={{ width: 40 }} />
          )} */}
        </Grid>
      </Grid>
      {showFilter && filterComponent}
      {filterOpen && customFilter}
    </div>
  );
}

export function TableHeadItem({
  columnName = "",
  dataRef,
  callbackOrderBy,
  enableOrder,
  currency = false,
  hasMoreOptions,
  cbMoreAction,
  optionsCheckBox,
}) {
  const [stateOrder, setStateOrder] = useState(0);
  const [moreOptions, setMoreOptions] = useState(false);

  let newCallbackOrderBy = (data) => {
    let orderByType = null;
    if (data.order === 1) {
      orderByType = "desc";
    } else if (data.order === 2) {
      orderByType = "asc";
    }
    let orderByObj = {
      orderBy: data.columnName ? data.columnName : null,
      orderByType: orderByType,
    };
    callbackOrderBy(orderByObj);
  };

  let onMoreOptionsCb = (action) => {
    cbMoreAction(action);
    setMoreOptions(false);
  };

  function handleStateOrder() {
    if (stateOrder + 1 >= 3) {
      newCallbackOrderBy({ columnName: "", order: 0 });
      setStateOrder(0);
    } else {
      newCallbackOrderBy({ columnName: dataRef, order: stateOrder + 1 });
      setStateOrder(stateOrder + 1);
    }
  }

  function handleCbMoreOptions() {}

  return (
    <>
      <div
        className="table-head-item-container"
        onClick={
          hasMoreOptions
            ? handleCbMoreOptions
            : enableOrder && !hasMoreOptions
            ? handleStateOrder
            : null
        }
      >
        <div
          className={
            currency ? "table-head-item-title-center" : "table-head-item-title"
          }
        >
          {columnName}
        </div>
        {hasMoreOptions ? (
          <div
            className="icon-wrapper icon-menu"
            onClick={(e) => setMoreOptions((prev) => !prev)}
          >
            <Icon
              name="arrow-down"
              size={15}
              color="#0a2346"
              className="icon"
            />
            {moreOptions ? (
              <MoreOptions
                handleMouseDown={() => setMoreOptions(true)}
                options={optionsCheckBox}
                callback={onMoreOptionsCb}
              />
            ) : null}
          </div>
        ) : (
          enableOrder && (
            <div className="order-icons">
              <div
                className={`arrowDown ${
                  stateOrder === 1 && "arrowDown-active"
                }`}
              ></div>
              <div
                className={`arrowUp ${stateOrder === 2 && "arrowUp-active"}`}
              ></div>
            </div>
          )
        )}
      </div>
    </>
  );
}

function converteData(value) {
  value = value.split("T")[0];
  value = value.split("-");
  value = value[2] + "/" + value[1] + "/" + value[0];
  return value;
}

function converteValor(value) {
  if (value != "") {
    value = value.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }
  return value;
}

function StatusLabel({ children, status }) {
  let labelColor = "";
  let showTooltip = false;
  switch (status) {
    case "Nota Fiscal Rejeitada": {
      labelColor = "status-label status-label-rejeitada";
      break;
    }
    case "Nota Fiscal Gerada": {
      labelColor = "status-label status-label-gerada";
      break;
    }
    case "Cancelamento em processamento": {
      labelColor = "status-label status-label-cancelamento-em-processo";
      break;
    }
    case "Nota Fiscal Cancelada": {
      labelColor = "status-label status-label-cancelada";
      break;
    }
    case "ENTRADA": {
      labelColor = "status-label status-label-entrada";
      break;
    }
    case "SAÍDA": {
      labelColor = "status-label status-label-saida";
      break;
    }
    case "AGUARDANDO": {
      labelColor = "status-label status-label-aguardando";
      break;
    }
    case "PROPOSTA ATIVA": {
      labelColor = "status-label status-label-ativa";
      break;
    }
    case "EM ANDAMENTO": {
      labelColor = "status-label status-label-andamento";
      break;
    }
    case "FINALIZADO": {
      labelColor = "status-label status-label-finalizado";
      break;
    }
    case "NÃO DISTRIBUIDO": {
      labelColor = "status-label status-label-nao-distribuido";
      break;
    }
    case "Ativo": {
      labelColor = "status-label status-label-entrada";
      break;
    }
    case "Desativado": {
      labelColor = "status-label status-label-saida";
      break;
    }
    case "NÃO LOCALIZADO": {
      labelColor = "status-label status-label-nao-localizado";
      break;
    }
    case "ERRO INTEGRAÇÃO VISTA": {
      labelColor = "status-label status-label-erro-integracao";
      break;
    }
    case "Solicitação de Ajustes": {
      labelColor = "status-label-ceu status-label-ceu-solicitacao-ajustes";
      break;
    }
    case "Aprovada": {
      labelColor = "status-label-ceu status-label-ceu-aprovado";
      break;
    }
    case "Aprovado": {
      labelColor = "status-label-ceu status-label-ceu-aprovado";
      break;
    }
    case "Contratada": {
      labelColor = "status-label-ceu status-label-ceu-contratado";
      break;
    }
    case "Contratado": {
      labelColor = "status-label-ceu status-label-ceu-contratado";
      break;
    }
    case "Reprovada": {
      labelColor = "status-label-ceu status-label-ceu-reprovado";
      break;
    }
    case "Reprovado": {
      labelColor = "status-label-ceu status-label-ceu-reprovado";
      break;
    }
    case "Em Análise": {
      labelColor = "status-label-ceu status-label-ceu-em-analise";
      break;
    }
    case "Em andamento": {
      labelColor = "status-label status-label-em-andamento";
      break;
    }
    case "Concluído": {
      labelColor = "status-label status-label-concluido";
      break;
    }
    case "Concluído.": {
      labelColor = "status-label status-label-concluido-erro";
      showTooltip = true;
      break;
    }
    case "Erro": {
      labelColor = "status-label status-label-erro";
      break;
    }
    case "Pendente": {
      labelColor = "status-label status-label-pendente";
      break;
    }
    case "Aberto": {
      labelColor = "status-label status-label-inativo";
      break;
    }
    case "Em aberto": {
      labelColor = "status-label status-label-inativo";
      break;
    }
    case "Aguardando": {
      labelColor = "status-label status-label-inativo";
      break;
    }
    case "Agendado": {
      labelColor = "status-label status-label-concluido";
      showTooltip = true;
      break;
    }
    case "Desligado": {
      labelColor = "status-label status-label-inativo";
      break;
    }
    case "Sem previsão": {
      labelColor = "status-label status-label-inativo-previsao";
      break;
    }
    case "Aguardando previsão": {
      labelColor = "status-label status-label-aguardando-previsao";
      break;
    }
    case "Previsão finalizada": {
      labelColor = "status-label status-label-finalizada";
      break;
    }
    case "Fechado": {
      labelColor = "status-label status-label-concluido";
      break;
    }
    case "Inativo": {
      labelColor = "status-label status-label-inativo";
      break;
    }

    default: {
      labelColor = "status-label status-label-default";
      break;
    }
  }

  return (
    <div
      data-tip
      data-for={showTooltip ? "show-label" : ""}
      className={`${labelColor}`}
    >
      {children}
      {null}
    </div>
  );
}

function MultiLabel({ children, status }) {
  let labelColor = "status-label status-label-default";

  return (
    <div data-tip className={`${labelColor}`}>
      {children}
    </div>
  );
}

function Header({
  tableConfig,
  customColumns,
  callbackOrderBy,
  optionsCheckBox,
  cbMoreAction,
  listIndexCurrency,
  enableOrder,
}) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return isMobile ? (
    <TableRow>
      {tableConfig.columnArray
        .filter((item) => !item.customColum)
        .filter((item) => !item.hide)
        .slice(0, 2)
        .map((element, index) => (
          <TableCell key={index}>
            <TableHeadItem
              columnName={element.columnName}
              dataRef={element.dataRef}
              callbackOrderBy={callbackOrderBy}
              enableOrder={
                element.dataRef === "permissao" ||
                element.dataRef === "checkbox_integracao" ||
                element.dataRef === "abono"
                  ? false
                  : enableOrder
              }
              currency={listIndexCurrency.includes(index)}
              hasMoreOptions={
                (element.dataRef === "permissao" ||
                  element.dataRef === "checkbox_integracao") &&
                tableConfig?.display?.checkBoxOptions
                  ? true
                  : false
              }
              cbMoreAction={cbMoreAction}
              optionsCheckBox={optionsCheckBox}
            />
          </TableCell>
        ))}
      <TableCell />
    </TableRow>
  ) : (
    <TableRow>
      {tableConfig.display && tableConfig.display.startCheckBox && (
        <TableCell />
      )}
      {tableConfig.display && tableConfig.display.startCheckBoxProduct && (
        <TableCell />
      )}
      {tableConfig.columnArray
        .filter((item) => !item.customColum)
        .filter((item) => !item.hide)
        .map((element, index) => (
          <TableCell key={index}>
            <TableHeadItem
              columnName={element.columnName}
              dataRef={element.dataRef}
              callbackOrderBy={callbackOrderBy}
              enableOrder={
                element.dataRef === "permissao" ||
                element.dataRef === "checkbox_integracao" ||
                element.dataRef === "abono"
                  ? false
                  : enableOrder
              }
              currency={listIndexCurrency.includes(index)}
              hasMoreOptions={
                (element.dataRef === "permissao" ||
                  element.dataRef === "checkbox_integracao") &&
                tableConfig?.display?.checkBoxOptions
                  ? true
                  : false
              }
              cbMoreAction={cbMoreAction}
              optionsCheckBox={optionsCheckBox}
            />
          </TableCell>
        ))}
      {customColumns &&
        customColumns.map((item, index) => (
          <TableCell key={index}>{item.columnHead}</TableCell>
        ))}
      {tableConfig.options && (
        <>
          {tableConfig.options && tableConfig.options.detailTitle ? (
            <TableCell>
              <div className="table-head-item-container">
                <div
                  className={
                    tableConfig.options.ceu
                      ? "table-head-item-title-ceu"
                      : "table-head-item-title"
                  }
                >
                  Detalhes
                </div>
              </div>
            </TableCell>
          ) : (
            <TableCell>
              <div className="table-head-item-container">
                <div
                  className={
                    tableConfig.options.ceu
                      ? "table-head-item-title-ceu"
                      : "table-head-item-title"
                  }
                >
                  {tableConfig.display?.optionsColumnName
                    ? tableConfig.display?.optionsColumnName
                    : "Opções"}
                </div>
              </div>
            </TableCell>
          )}
        </>
      )}
      {!tableConfig.options && null}
      {tableConfig.customOptions ? (
        <TableCell>
          <div className="table-head-item-container">
            <div
              className={
                tableConfig?.options?.ceu
                  ? "table-head-item-title-ceu"
                  : "table-head-item-title"
              }
            >
              {tableConfig.customOptions.title}
            </div>
          </div>
        </TableCell>
      ) : null}
    </TableRow>
  );
}

function Row({
  elementData,
  index,
  tableConfig,
  idName,
  listCheckBox,
  onClickCheckBox,
  onClickCheckBoxProduct,
  objFilteredAttr,
  onClickCheckBoxPermissoes,
  listIndexCurrency,
  customColumns,
  onClickFile,
  onClickFaturaBoletoSearch,
  onClickUpdate,
  onClickClip,
  onClickInventory,
  onClickEdit,
  onClickChangePassword,
  onClickDelete,
  onClickApprove,
  onClickPermissions,
  onClickCopy,
  onClickSearchPropCeu,
  onClickSearchPropCeuAnexo,
  onClickAceitaAnexo,
  onViewProp,
  onClickDeleteProposta,
  onClickDeleteAnexo,
  onClickDownload,
  pageId,
  onClickMoney,
  onClickSearch,
  onClickInfo,
  onClickPencil,
  onClickLookFile,
  onClickDownloadFile,
  setMoreDetailsId,
  setMoreDetails,
  onMoreOptionsCb,
  moreDetailsId,
  setMoreActionsId,
  setMoreActions,
  moreActionsId,
  setMoreOptionsId,
  isCbCompany,
  onClickMore,
  setMoreOptions,
  moreOptionsId,
  moreOptions,
  showOptionalLabel,
  onClickAdd,
  hideAdminOptions,
  onClickRemove,
  onClickConfirma,
  onClickFinaliza,
  onClickUpload,
  onClickDownloadDp,
  onClickSchedule,
  onClickDetails,
  onClickClosePayroll,
  editingId,
  setEditingId,
  onClickSave,
  onClickSwitch,
  onClickHistory,
  onClickEstorno,
  onClickFinalizaLancamento,
}) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [open, setOpen] = React.useState(false);

  return isMobile ? (
    <React.Fragment>
      <TableRow
        key={index}
        className={`table-row ${open ? "header-collapse-open" : ""}`}
      >
        {objFilteredAttr
          .filter((item) => item.dataRef !== "customValue")
          .filter((item) => !item.hide)
          .slice(0, 2)
          .map((elementAttr, ObjFilteredAttrIndex) => {
            const status = elementData[elementAttr.dataRef];
            return (
              <React.Fragment key={ObjFilteredAttrIndex}>
                {(tableConfig.display.statusLabels &&
                  elementAttr.dataRef === "status") ||
                (tableConfig.display.statusLabels &&
                  elementAttr.dataRef === "status_documento") ||
                elementAttr.dataRef === "tipo" ? (
                  <TableCell>
                    <StatusLabel status={status}>
                      <span>{status}</span>
                    </StatusLabel>
                  </TableCell>
                ) : elementAttr.dataRef === "created_at" ? (
                  <TableCell>
                    <span className="text_ceu">
                      {status ? converteData(status) : null}
                    </span>
                  </TableCell>
                ) : elementAttr.dataRef === "valor_total" ? (
                  <TableCell>
                    <span className="text_ceu">{converteValor(status)}</span>
                  </TableCell>
                ) : elementAttr.dataRef === "permissao" ||
                  elementAttr.dataRef === "checkbox_integracao" ? (
                  <TableCell>
                    <div className="icon-wrapper" data-id={elementData[idName]}>
                      <InputCheck
                        className="icon checkbox"
                        nome={elementData[idName]}
                        checkedValue={listCheckBox[index + (page - 1) * 10]}
                        id={elementData[idName]}
                        onInputChange={(event) =>
                          onClickCheckBoxPermissoes(
                            event,
                            elementData[idName],
                            index + (page - 1) * 10
                          )
                        }
                      />
                    </div>
                  </TableCell>
                ) : elementAttr.dataRef === "status_conciliacao" ? (
                  <TableCell>
                    <div data-id={elementData[idName]}>
                      <div
                        className={`status-conciliacao ${
                          elementData[elementAttr.dataRef] === "pago"
                            ? "pago"
                            : "em-aberto"
                        }`}
                      >
                        {elementData[elementAttr.dataRef]}
                      </div>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell
                    className={
                      listIndexCurrency.includes(ObjFilteredAttrIndex)
                        ? "cell-currency"
                        : "ceu-aux"
                    }
                  >
                    {elementData[elementAttr.dataRef]}
                  </TableCell>
                )}
              </React.Fragment>
            );
          })}
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableCell
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan="3"
        className={!open ? "table-cell-empty" : ""}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div>
            {objFilteredAttr
              .filter((item) => item.dataRef !== "customValue")
              .filter((item) => !item.hide)
              .slice(2)
              .map((elementAttr, ObjFilteredAttrIndex) => {
                const status = elementData[elementAttr.dataRef];
                return (
                  <div className="div-responsive-collapse">
                    <div className="collapse-row-title">
                      {elementAttr.dataName}
                    </div>
                    <div className="collapse-row-value">
                      {(tableConfig.display.statusLabels &&
                        elementAttr.dataRef === "status") ||
                      (tableConfig.display.statusLabels &&
                        elementAttr.dataRef === "status_documento") ||
                      elementAttr.dataRef === "tipo" ? (
                        <StatusLabel status={status}>
                          <span>{status}</span>
                        </StatusLabel>
                      ) : tableConfig.display.multiLabels &&
                        elementAttr.dataRef === "multi_label" ? (
                        status ? (
                          <TableCell>
                            <div className="multi-label">
                              {status?.map((item) => (
                                <MultiLabel status={item}>
                                  <span>{item}</span>
                                </MultiLabel>
                              ))}
                            </div>
                          </TableCell>
                        ) : null
                      ) : (
                        elementData[elementAttr.dataRef]
                      )}
                    </div>
                  </div>
                );
              })}
            {customColumns &&
              customColumns.map((customItem, customIndex) => (
                <div className="div-responsive-collapse">
                  <div className="collapse-row-title">
                    {customItem.columnHead}
                  </div>
                  <div className="collapse-row-value">
                    {customItem.editable ? (
                      <TableCell key={customIndex}>
                        <customItem.columnData
                          data={elementData}
                          editingId={editingId}
                          setEditingId={setEditingId}
                        />
                      </TableCell>
                    ) : (
                      <TableCell key={customIndex}>
                        {customItem.columnData[index]}
                      </TableCell>
                    )}
                  </div>
                </div>
              ))}
            {tableConfig.options && (
              <div className="div-responsive-collapse">
                <div className="collapse-row-title">
                  {tableConfig?.options?.detailTitle ? "Detalhes" : "Opções"}
                </div>
                <div
                  className={`options-wrapper ${
                    tableConfig?.display?.alignLeft ? "align-column-left" : ""
                  }`}
                >
                  {tableConfig.options && tableConfig.options.clip ? (
                    <TooltipComponent label={tableConfig.text?.clip}>
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        onClick={onClickClip}
                      >
                        <Icon name="paper-clip" className="icon" />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.inventory ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickInventory}
                    >
                      <Icon name="barcode" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options &&
                  tableConfig.options.edit &&
                  !elementData["hideEdit"] &&
                  (pageId !== "lista-pre-admissao" ||
                    elementData["status"] !== "Concluído") &&
                  (pageId !== "lista-pre-demissao" ||
                    elementData["status"] !== "Concluído") &&
                  (pageId !== "lista-cartoes-ponto" ||
                    elementData["status"] !== "Fechado") &&
                  (pageId !== "lista-eventos-folha" ||
                    elementData["status"] !== "Fechado") ? (
                    <TooltipComponent label={tableConfig.text?.edit}>
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        onClick={onClickEdit}
                      >
                        <Icon name="edit" className="icon" />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.changePassword ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickChangePassword}
                    >
                      <Icon name="lockPassword" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.edit2 ? (
                    <TooltipComponent label={tableConfig.text?.edit}>
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        onClick={onClickEdit}
                      >
                        <img alt="" src={editIcon} className="icon" />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.delete ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickDelete}
                    >
                      <Icon name="delete" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.switch ? (
                    <TooltipComponent label={tableConfig.text?.switch}>
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        onClick={onClickSwitch}
                      >
                        <Icon
                          name={
                            elementData["switch_value"]
                              ? "switch-on"
                              : "switch-off"
                          }
                          className="icon"
                        />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.add ? (
                    <TooltipComponent
                      label={
                        pageId === "lista-pre-admissao" &&
                        elementData["status"] === "Concluído"
                          ? tableConfig.text?.addFill
                          : tableConfig.text?.addOutline
                      }
                    >
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        onClick={onClickAdd}
                      >
                        <Icon
                          name="add-person"
                          color="#27ae60"
                          fill={
                            pageId === "lista-pre-admissao" &&
                            elementData["status"] === "Concluído"
                          }
                          className="icon"
                        />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.history ? (
                    <TooltipComponent label={tableConfig.text?.history}>
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        onClick={onClickHistory}
                      >
                        <Icon name="historico" className="icon" />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.history ? (
                    <TooltipComponent label={tableConfig.text?.history}>
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        onClick={onClickHistory}
                      >
                        <Icon name="historico" className="icon" />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.approve ? (
                    <TooltipComponent
                      label={
                        pageId === "lista-cartoes-ponto" &&
                        elementData["status"] === "Fechado"
                          ? tableConfig.text?.approveInactive
                          : tableConfig.text?.approveActive
                      }
                    >
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        onClick={onClickApprove}
                      >
                        <Icon
                          name="check-square"
                          className="icon"
                          color={
                            pageId === "lista-cartoes-ponto" &&
                            elementData["status"] === "Fechado"
                              ? "#27ae60"
                              : null
                          }
                        />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.permissions ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickPermissions}
                    >
                      <Icon name="check-circle" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.copy ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickCopy}
                    >
                      <Icon name="copy-table" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.searchPropCeu ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={() =>
                        onClickSearchPropCeu(elementData.id_ceu_proposta)
                      }
                    >
                      <img
                        alt=""
                        src={iconSearch}
                        className="icon-ceu-search"
                      />
                    </div>
                  ) : null}
                  {tableConfig.options &&
                  tableConfig.options.searchPropCeuAnexo ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={() =>
                        onClickSearchPropCeuAnexo(elementData["arquivo"])
                      }
                    >
                      <img
                        alt=""
                        src={iconSearch}
                        className="icon-ceu-search"
                      />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.viewPropAnexo ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={() =>
                        onClickAceitaAnexo(elementData["id_pessoa_documento"])
                      }
                    >
                      <img
                        alt=""
                        src={iconAceite}
                        className="icon-ceu-search"
                      />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.viewProp ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onViewProp}
                    >
                      <Icon name="paper-clip" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.deleteProp ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={() =>
                        onClickDeleteProposta(elementData.id_ceu_proposta)
                      }
                    >
                      <img alt="" src={iconDelete} className="icon-ceu-trash" />
                    </div>
                  ) : null}
                  {tableConfig.options &&
                  tableConfig.options.deletePropAnexo ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={() =>
                        onClickDeleteAnexo(elementData.id_pessoa_documento)
                      }
                    >
                      <img alt="" src={iconDelete} className="icon-ceu-trash" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.delete2 ? (
                    <TooltipComponent label={tableConfig.text?.delete}>
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        onClick={onClickDelete}
                      >
                        <img alt="" src={deleteIcon} className="icon" />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.download ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickDownload}
                    >
                      <Icon name="download" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.nfe ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickFile}
                    >
                      <img alt="" src={nfeIcon} className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.nfe2 ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickFile}
                    >
                      <img alt="" src={nfeIcon2} className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.file ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickFile}
                    >
                      <Icon name="file" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.file2 ? (
                    <TooltipComponent label={tableConfig.text?.file}>
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        onClick={onClickDownload}
                      >
                        <img alt="" src={fileDownloadIcon} className="icon" />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.checkBox ? (
                    <div className="icon-wrapper" data-id={elementData[idName]}>
                      <InputCheck
                        className="icon checkbox"
                        nome={elementData[idName]}
                        checkedValue={listCheckBox[index + (page - 1) * 10]}
                        id={elementData[idName]}
                        onInputChange={() => onClickCheckBoxPermissoes(index)}
                      />
                    </div>
                  ) : null}
                  {tableConfig.options &&
                  tableConfig.options.money &&
                  ((pageId !== "lista-pagamentos" &&
                    pageId !== "lista-recebimentos") ||
                    ((pageId === "lista-pagamentos" ||
                      pageId === "lista-recebimentos") &&
                      !elementData["data_pagamento"])) ? (
                    <TooltipComponent label={tableConfig.text?.money}>
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        data-id2={elementData.id2}
                        onClick={onClickMoney}
                      >
                        <Icon name="money" className="icon" />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options &&
                  tableConfig.options.estorno &&
                  ((pageId !== "lista-pagamentos" &&
                    pageId !== "lista-recebimentos" &&
                    pageId !== "faturas") ||
                    ((pageId === "lista-pagamentos" ||
                      pageId === "lista-recebimentos") &&
                      elementData["data_pagamento"]) ||
                    (pageId === "faturas" &&
                      elementData["status_pagamento"] === "BAIXADA")) ? (
                    <TooltipComponent label={tableConfig.text?.estorno}>
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        data-id2={elementData.id2}
                        onClick={onClickEstorno}
                      >
                        <Icon name="estorno" className="icon" />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.search ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickSearch}
                    >
                      <Icon name="search" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.search2 ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      data-boleto_id={elementData.boleto_id || null}
                      onClick={onClickFaturaBoletoSearch}
                    >
                      <img alt="" src={searchIcon} className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options &&
                  tableConfig.options.update &&
                  elementData.status_pagamento !== "PAGO" ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData.boleto_id}
                      onClick={onClickUpdate}
                    >
                      <img alt="" src={updateIcon} className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.info ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickInfo}
                    >
                      <Icon name="info" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.pencil ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickPencil}
                    >
                      <Icon name="pencil" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.trash ? (
                    pageId === "lista-pre-admissao" &&
                    hideAdminOptions ? null : (
                      <TooltipComponent label={tableConfig.text?.trash}>
                        <div
                          className="icon-wrapper"
                          data-id={elementData[idName]}
                          onClick={onClickDelete}
                        >
                          <Icon name="trash" className="icon" />
                        </div>
                      </TooltipComponent>
                    )
                  ) : null}
                  {tableConfig.options &&
                  tableConfig.options.remove &&
                  !elementData["hideRemove"] &&
                  (pageId !== "lista-funcionario" ||
                    elementData["status"] !== "Desligado") ? (
                    <TooltipComponent
                      label={
                        (pageId === "lista-pre-demissao" &&
                          elementData["status"] === "Concluído") ||
                        (pageId === "lista-pre-demissao" &&
                          elementData["status"] === 1)
                          ? tableConfig.text?.removeFill
                          : tableConfig.text?.removeOutline
                      }
                    >
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        onClick={onClickRemove}
                      >
                        <Icon
                          name="remove-person"
                          color="#27ae60"
                          fill={
                            (pageId === "lista-pre-demissao" &&
                              elementData["status"] === "Concluído") ||
                            (pageId === "lista-pre-demissao" &&
                              elementData["status"] === 1)
                          }
                          className="icon"
                        />
                      </div>
                    </TooltipComponent>
                  ) : null}

                  {tableConfig.options &&
                  tableConfig.options.finaliza &&
                  !elementData["hideFinaliza"] ? (
                    <TooltipComponent label={tableConfig.text?.finaliza}>
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        onClick={onClickFinalizaLancamento}
                      >
                        <Icon
                          size={12}
                          name="finaliza-lancamento"
                          className="icon"
                        />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.magnifier ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickLookFile}
                    >
                      <Icon name="search" size={24} color="#EFDD59" />
                    </div>
                  ) : null}
                  {tableConfig.options &&
                  tableConfig.options.searchFile &&
                  (pageId !== "lista-eventos-folha" ||
                    elementData["status"] === "Fechado") ? (
                    <TooltipComponent label={tableConfig.text?.searchFile}>
                      <div
                        className="icon-wrapper"
                        data-id={elementData[idName]}
                        onClick={onClickLookFile}
                      >
                        <Icon name="file-search" className="icon" />
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.downloadFile ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickDownloadFile}
                    >
                      <Icon name="file-download" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.documentView ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickLookFile}
                    >
                      <img alt="" src={PreviewIcon} />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.confirm ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickConfirma}
                    >
                      <Icon name="confirma" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.finalize ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickFinaliza}
                    >
                      <Icon name="confirma-check" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.download_dp ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickDownloadDp}
                    >
                      <Icon name="download-dp" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.schedule ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickSchedule}
                    >
                      <Icon name="agendar" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.details ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickDetails}
                    >
                      <Icon name="detalhes" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.closePayroll ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickClosePayroll}
                    >
                      <Icon
                        fill={
                          pageId === "lista-eventos-folha" &&
                          elementData["status"] === "Fechado"
                        }
                        name="fechar-folha"
                        className="icon"
                      />
                    </div>
                  ) : null}
                  {tableConfig.options && tableConfig.options.upload_dp ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickUpload}
                    >
                      <Icon name="upload-dp" className="icon" />
                    </div>
                  ) : null}
                  {tableConfig.options &&
                  tableConfig.options.moreDetails &&
                  tableConfig.options.moreDetails.length > 0 ? (
                    <div
                      className="icon-wrapper icon-menu"
                      onClick={(e) => {
                        e.stopPropagation();
                        setMoreDetailsId(elementData[idName]);
                        setMoreDetails((prev) => !prev);
                      }}
                    >
                      <Icon
                        name="find-file"
                        size={15}
                        color="#0a2346"
                        className="icon"
                      />
                      {moreDetailsId === elementData[idName] && moreDetails ? (
                        <MoreOptions
                          handleMouseDown={() => setMoreDetails(true)}
                          key={elementData[idName]}
                          options={tableConfig.options.moreDetails}
                          callback={onMoreOptionsCb}
                          lineId={moreDetailsId}
                        />
                      ) : null}
                    </div>
                  ) : null}
                  {tableConfig.options &&
                  tableConfig.options.moreActions &&
                  tableConfig.options.moreActions.length > 0 ? (
                    <div
                      className="icon-wrapper icon-menu"
                      onClick={(e) => {
                        e.stopPropagation();
                        setMoreActionsId(elementData[idName]);
                        setMoreActions((prev) => !prev);
                      }}
                    >
                      <Icon
                        name="megaphone"
                        size={15}
                        color="#0a2346"
                        className="icon"
                      />
                      {moreActionsId === elementData[idName] && moreActions ? (
                        <MoreOptions
                          handleMouseDown={() => setMoreActions(true)}
                          key={elementData[idName]}
                          options={tableConfig.options.moreActions}
                          callback={onMoreOptionsCb}
                          lineId={moreActionsId}
                        />
                      ) : null}
                    </div>
                  ) : null}
                  {tableConfig.options &&
                  tableConfig.options.more &&
                  tableConfig.options.more.length > 0 ? (
                    <TooltipComponent label={tableConfig.text?.more}>
                      <div
                        className="icon-wrapper icon-menu"
                        onClick={(e) => {
                          e.stopPropagation();
                          isCbCompany
                            ? onClickMore(elementData[idName])
                            : setMoreOptions((prev) => !prev);
                          setMoreOptionsId(elementData[idName]);
                        }}
                      >
                        <Icon
                          name="hamburger"
                          size={15}
                          color="#0a2346"
                          className="icon"
                        />
                        {moreOptionsId === elementData[idName] &&
                        moreOptions ? (
                          <MoreOptions
                            handleMouseDown={() => setMoreOptions(true)}
                            key={elementData[idName]}
                            options={tableConfig.options.more}
                            callback={onMoreOptionsCb}
                            lineId={moreOptionsId}
                            showOptionalLabel={showOptionalLabel}
                            elementData={elementData}
                          />
                        ) : null}
                      </div>
                    </TooltipComponent>
                  ) : null}
                  {tableConfig.options && tableConfig.options.save ? (
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickSave}
                    >
                      <Icon name="save" className="icon" />
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </Collapse>
      </TableCell>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <TableRow key={index} className="table-row">
        {tableConfig.display && tableConfig.display.startCheckBox ? (
          <TableCell>
            <div className="icon-wrapper" data-id={elementData[idName]}>
              <InputCheck
                className="icon checkbox"
                nome={elementData[idName]}
                checkedValue={listCheckBox[index]}
                id={elementData[idName]}
                onInputChange={() => onClickCheckBox(index)}
              />
            </div>
          </TableCell>
        ) : null}
        {tableConfig.display && tableConfig.display.startCheckBoxProduct ? (
          <TableCell>
            <div className="icon-wrapper" data-id={elementData[idName]}>
              <InputCheck
                className="icon checkbox"
                nome={elementData[idName]}
                checkedValue={listCheckBox[index]}
                id={elementData[idName]}
                onInputChange={() => onClickCheckBoxProduct(index)}
              />
            </div>
          </TableCell>
        ) : null}
        {objFilteredAttr
          .filter((item) => item.dataRef !== "customValue")
          .filter((item) => !item.hide)
          .map((elementAttr, ObjFilteredAttrIndex) => {
            const status = elementData[elementAttr.dataRef];
            return (
              <React.Fragment key={ObjFilteredAttrIndex}>
                {(tableConfig.display.statusLabels &&
                  elementAttr.dataRef === "status") ||
                (tableConfig.display.statusLabels &&
                  elementAttr.dataRef === "status_documento") ||
                elementAttr.dataRef === "tipo" ? (
                  <TableCell>
                    <StatusLabel status={status}>
                      <span>{status}</span>
                    </StatusLabel>
                  </TableCell>
                ) : tableConfig.display.multiLabels &&
                  elementAttr.dataRef === "multi_label" ? (
                  status ? (
                    <TableCell>
                      <div className="multi-label">
                        {status?.map((item) => (
                          <MultiLabel status={item}>
                            <span>{item}</span>
                          </MultiLabel>
                        ))}
                      </div>
                    </TableCell>
                  ) : null
                ) : elementAttr.dataRef === "created_at" ? (
                  <TableCell>
                    <span className="text_ceu">
                      {status ? converteData(status) : null}
                    </span>
                  </TableCell>
                ) : elementAttr.dataRef === "valor_total" ? (
                  <TableCell>
                    <span className="text_ceu">{converteValor(status)}</span>
                  </TableCell>
                ) : elementAttr.dataRef === "permissao" ||
                  elementAttr.dataRef === "checkbox_integracao" ? (
                  <TableCell>
                    <div className="icon-wrapper" data-id={elementData[idName]}>
                      <InputCheck
                        className="icon checkbox"
                        nome={elementData[idName]}
                        checkedValue={listCheckBox[index + (page - 1) * 10]}
                        id={elementData[idName]}
                        onInputChange={(event) =>
                          onClickCheckBoxPermissoes(
                            event,
                            elementData[idName],
                            index + (page - 1) * 10
                          )
                        }
                      />
                    </div>
                  </TableCell>
                ) : elementAttr.dataRef === "status_conciliacao" ? (
                  <TableCell>
                    <div data-id={elementData[idName]}>
                      <div
                        className={`status-conciliacao ${
                          elementData[elementAttr.dataRef] === "pago"
                            ? "pago"
                            : "em-aberto"
                        }`}
                      >
                        {elementData[elementAttr.dataRef]}
                      </div>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell
                    className={
                      listIndexCurrency.includes(ObjFilteredAttrIndex)
                        ? "cell-currency"
                        : "ceu-aux"
                    }
                  >
                    {elementData[elementAttr.dataRef]}
                  </TableCell>
                )}
              </React.Fragment>
            );
          })}
        {customColumns &&
          customColumns.map((customItem, customIndex) =>
            customItem.editable ? (
              <TableCell key={customIndex}>
                <customItem.columnData
                  data={elementData}
                  editingId={editingId}
                  setEditingId={setEditingId}
                />
              </TableCell>
            ) : (
              <TableCell key={customIndex}>
                {customItem.columnData[index]}
              </TableCell>
            )
          )}
        {tableConfig.customOptions && (
          <TableCell>
            <div className="options-wrapper">
              {tableConfig.customOptions && tableConfig.customOptions.nfe ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickFile}
                >
                  <img alt="" src={nfeIcon2} className="icon" />
                </div>
              ) : null}
              {tableConfig.customOptions && tableConfig.customOptions.search ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  data-boleto_id={elementData.boleto_id || null}
                  onClick={onClickFaturaBoletoSearch}
                >
                  <img alt="" src={searchIcon} className="icon" />
                </div>
              ) : null}
              {tableConfig.customOptions &&
              tableConfig.customOptions.update &&
              elementData.status_pagamento !== "PAGO" ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData.boleto_id}
                  onClick={onClickUpdate}
                >
                  <img alt="" src={updateIcon} className="icon" />
                </div>
              ) : null}
            </div>
          </TableCell>
        )}
        {tableConfig.options && (
          <TableCell>
            <div
              className={`options-wrapper ${
                tableConfig?.display?.alignLeft ? "align-column-left" : ""
              }`}
            >
              {tableConfig.options && tableConfig.options.clip ? (
                <TooltipComponent label={tableConfig.text?.clip}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickClip}
                  >
                    <Icon name="paper-clip" className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.inventory ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickInventory}
                >
                  <Icon name="barcode" className="icon" />
                </div>
              ) : null}
              {tableConfig.options &&
              tableConfig.options.edit &&
              !elementData["hideEdit"] &&
              (pageId !== "lista-pre-admissao" ||
                elementData["status"] !== "Concluído") &&
              (pageId !== "lista-cartoes-ponto" ||
                elementData["status"] !== "Fechado") &&
              (pageId !== "lista-pre-demissao" ||
                elementData["status"] !== "Concluído") &&
              (pageId !== "lista-eventos-folha" ||
                elementData["status"] !== "Fechado") ? (
                <TooltipComponent label={tableConfig.text?.edit}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickEdit}
                  >
                    <Icon name="edit" className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.changePassword ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickChangePassword}
                >
                  <Icon name="lockPassword" className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.edit2 ? (
                <TooltipComponent label={tableConfig.text?.edit}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickEdit}
                  >
                    <img alt="" src={editIcon} className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.delete ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickDelete}
                >
                  <Icon name="delete" className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.switch ? (
                <TooltipComponent label={tableConfig.text?.switch}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickSwitch}
                  >
                    <Icon
                      name={
                        elementData["switch_value"] ? "switch-on" : "switch-off"
                      }
                      className="icon"
                    />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.add ? (
                <TooltipComponent
                  label={
                    pageId === "lista-pre-admissao" &&
                    elementData["status"] === "Concluído"
                      ? tableConfig.text?.addFill
                      : tableConfig.text?.addOutline
                  }
                >
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickAdd}
                  >
                    <Icon
                      name="add-person"
                      color="#27ae60"
                      fill={
                        pageId === "lista-pre-admissao" &&
                        elementData["status"] === "Concluído"
                      }
                      className="icon"
                    />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.history ? (
                <TooltipComponent label={tableConfig.text?.history}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickHistory}
                  >
                    <Icon name="historico" className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.approve ? (
                <TooltipComponent
                  label={
                    pageId === "lista-cartoes-ponto" &&
                    elementData["status"] === "Fechado"
                      ? tableConfig.text?.approveInactive
                      : tableConfig.text?.approveActive
                  }
                >
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickApprove}
                  >
                    <Icon
                      name="check-square"
                      className="icon"
                      color={
                        pageId === "lista-cartoes-ponto" &&
                        elementData["status"] === "Fechado"
                          ? "#27ae60"
                          : null
                      }
                    />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.permissions ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickPermissions}
                >
                  <Icon name="check-circle" className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.copy ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickCopy}
                >
                  <Icon name="copy-table" className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.searchPropCeu ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={() =>
                    onClickSearchPropCeu(elementData.id_ceu_proposta)
                  }
                >
                  <img alt="" src={iconSearch} className="icon-ceu-search" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.searchPropCeuAnexo ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={() =>
                    onClickSearchPropCeuAnexo(elementData["arquivo"])
                  }
                >
                  <img alt="" src={iconSearch} className="icon-ceu-search" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.viewPropAnexo ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={() =>
                    onClickAceitaAnexo(elementData["id_pessoa_documento"])
                  }
                >
                  <img alt="" src={iconAceite} className="icon-ceu-search" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.viewProp ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onViewProp}
                >
                  <Icon name="paper-clip" className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.deleteProp ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={() =>
                    onClickDeleteProposta(elementData.id_ceu_proposta)
                  }
                >
                  <img alt="" src={iconDelete} className="icon-ceu-trash" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.deletePropAnexo ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={() =>
                    onClickDeleteAnexo(elementData.id_pessoa_documento)
                  }
                >
                  <img alt="" src={iconDelete} className="icon-ceu-trash" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.delete2 ? (
                <TooltipComponent label={tableConfig.text?.delete}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickDelete}
                  >
                    <img alt="" src={deleteIcon} className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.download ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickDownload}
                >
                  <Icon name="download" className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.nfe ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickFile}
                >
                  <img alt="" src={nfeIcon} className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.nfe2 ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickFile}
                >
                  <img alt="" src={nfeIcon2} className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.file ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickFile}
                >
                  <Icon name="file" className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.file2 ? (
                <TooltipComponent label={tableConfig.text?.file}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickDownload}
                  >
                    <img alt="" src={fileDownloadIcon} className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.checkBox ? (
                <div className="icon-wrapper" data-id={elementData[idName]}>
                  <InputCheck
                    className="icon checkbox"
                    nome={elementData[idName]}
                    checkedValue={listCheckBox[index + (page - 1) * 10]}
                    id={elementData[idName]}
                    onInputChange={() => onClickCheckBoxPermissoes(index)}
                  />
                </div>
              ) : null}
              {tableConfig.options &&
              tableConfig.options.money &&
              ((pageId !== "lista-pagamentos" &&
                pageId !== "lista-recebimentos") ||
                ((pageId === "lista-pagamentos" ||
                  pageId === "lista-recebimentos") &&
                  !elementData["data_pagamento"])) ? (
                <TooltipComponent label={tableConfig.text?.money}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    data-id2={elementData.id2}
                    onClick={onClickMoney}
                  >
                    <Icon name="money" className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options &&
              tableConfig.options.estorno &&
              ((pageId !== "lista-pagamentos" &&
                pageId !== "lista-recebimentos" &&
                pageId !== "faturas") ||
                ((pageId === "lista-pagamentos" ||
                  pageId === "lista-recebimentos") &&
                  elementData["data_pagamento"]) ||
                (pageId === "faturas" &&
                  elementData["status_pagamento"] === "BAIXADA")) ? (
                <TooltipComponent label={tableConfig.text?.estorno}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    data-id2={elementData.id2}
                    onClick={onClickEstorno}
                  >
                    <Icon name="estorno" className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.search ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickSearch}
                >
                  <Icon name="search" className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.search2 ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  data-boleto_id={elementData.boleto_id || null}
                  onClick={onClickFaturaBoletoSearch}
                >
                  <img alt="" src={searchIcon} className="icon" />
                </div>
              ) : null}
              {tableConfig.options &&
              tableConfig.options.update &&
              elementData.status_pagamento !== "PAGO" ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData.boleto_id}
                  onClick={onClickUpdate}
                >
                  <img alt="" src={updateIcon} className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.info ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickInfo}
                >
                  <Icon name="info" className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.pencil ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickPencil}
                >
                  <Icon name="pencil" className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.trash ? (
                pageId === "lista-pre-admissao" && hideAdminOptions ? null : (
                  <TooltipComponent label={tableConfig.text?.trash}>
                    <div
                      className="icon-wrapper"
                      data-id={elementData[idName]}
                      onClick={onClickDelete}
                    >
                      <Icon name="trash" className="icon" />
                    </div>
                  </TooltipComponent>
                )
              ) : null}
              {tableConfig.options &&
              tableConfig.options.remove &&
              !elementData["hideRemove"] &&
              (pageId !== "lista-funcionario" ||
                elementData["status"] !== "Desligado") ? (
                <TooltipComponent
                  label={
                    (pageId === "lista-pre-demissao" &&
                      elementData["status"] === "Concluído") ||
                    (pageId === "lista-pre-demissao" &&
                      elementData["status"] === 1)
                      ? tableConfig.text?.removeFill
                      : tableConfig.text?.removeOutline
                  }
                >
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickRemove}
                  >
                    <Icon
                      name="remove-person"
                      color="#27ae60"
                      fill={
                        (pageId === "lista-pre-demissao" &&
                          elementData["status"] === "Concluído") ||
                        (pageId === "lista-pre-demissao" &&
                          elementData["status"] === 1)
                      }
                      className="icon"
                    />
                  </div>
                </TooltipComponent>
              ) : null}

              {tableConfig.options &&
              tableConfig.options.finaliza &&
              !elementData["hideFinaliza"] ? (
                <TooltipComponent label={tableConfig.text?.finaliza}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickFinalizaLancamento}
                  >
                    <Icon
                      size={12}
                      name="finaliza-lancamento"
                      className="icon"
                    />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.magnifier ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickLookFile}
                >
                  <Icon name="search" size={24} color="#EFDD59" />
                </div>
              ) : null}
              {tableConfig.options &&
              tableConfig.options.searchFile &&
              (pageId !== "lista-eventos-folha" ||
                elementData["status"] === "Fechado") ? (
                <TooltipComponent label={tableConfig.text?.searchFile}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickLookFile}
                  >
                    <Icon name="file-search" className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.downloadFile ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickDownloadFile}
                >
                  <Icon name="file-download" className="icon" />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.documentView ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickLookFile}
                >
                  <img alt="" src={PreviewIcon} />
                </div>
              ) : null}
              {tableConfig.options && tableConfig.options.confirm ? (
                <TooltipComponent label={tableConfig.text?.confirm}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickConfirma}
                  >
                    <Icon name="confirma" className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.finalize ? (
                <TooltipComponent label={tableConfig.text?.finalize}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickFinaliza}
                  >
                    <Icon name="confirma-check" className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.schedule ? (
                <TooltipComponent label={tableConfig.text?.schedule}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickSchedule}
                  >
                    <Icon name="agendar" className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.details ? (
                <TooltipComponent label={tableConfig.text?.details}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickDetails}
                  >
                    <Icon name="detalhes" className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.closePayroll ? (
                <TooltipComponent
                  label={
                    pageId === "lista-eventos-folha" &&
                    elementData["status"] === "Fechado"
                      ? tableConfig.text?.closePayrollClosed
                      : tableConfig.text?.closePayrollOpened
                  }
                >
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickClosePayroll}
                  >
                    <Icon
                      name="fechar-folha"
                      fill={
                        pageId === "lista-eventos-folha" &&
                        elementData["status"] === "Fechado"
                      }
                      className="icon"
                    />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.download_dp ? (
                <TooltipComponent label={tableConfig.text?.download_dp}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickDownloadDp}
                  >
                    <Icon name="download-dp" className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.upload_dp ? (
                <TooltipComponent label={tableConfig.text?.upload_dp}>
                  <div
                    className="icon-wrapper"
                    data-id={elementData[idName]}
                    onClick={onClickUpload}
                  >
                    <Icon name="upload-dp" className="icon" />
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options &&
              tableConfig.options.moreDetails &&
              tableConfig.options.moreDetails.length > 0 ? (
                <div
                  className="icon-wrapper icon-menu"
                  onClick={(e) => {
                    e.stopPropagation();
                    setMoreDetailsId(elementData[idName]);
                    setMoreDetails((prev) => !prev);
                  }}
                >
                  <Icon
                    name="find-file"
                    size={15}
                    color="#0a2346"
                    className="icon"
                  />
                  {moreDetailsId === elementData[idName] && moreDetails ? (
                    <MoreOptions
                      handleMouseDown={() => setMoreDetails(true)}
                      key={elementData[idName]}
                      options={tableConfig.options.moreDetails}
                      callback={onMoreOptionsCb}
                      lineId={moreDetailsId}
                    />
                  ) : null}
                </div>
              ) : null}
              {tableConfig.options &&
              tableConfig.options.moreActions &&
              tableConfig.options.moreActions.length > 0 ? (
                <div
                  className="icon-wrapper icon-menu"
                  onClick={(e) => {
                    e.stopPropagation();
                    setMoreActionsId(elementData[idName]);
                    setMoreActions((prev) => !prev);
                  }}
                >
                  <Icon
                    name="megaphone"
                    size={15}
                    color="#0a2346"
                    className="icon"
                  />
                  {moreActionsId === elementData[idName] && moreActions ? (
                    <MoreOptions
                      handleMouseDown={() => setMoreActions(true)}
                      key={elementData[idName]}
                      options={tableConfig.options.moreActions}
                      callback={onMoreOptionsCb}
                      lineId={moreActionsId}
                    />
                  ) : null}
                </div>
              ) : null}
              {tableConfig.options &&
              tableConfig.options.more &&
              tableConfig.options.more.length > 0 ? (
                <TooltipComponent label={tableConfig.text?.more}>
                  <div
                    className="icon-wrapper icon-menu"
                    onClick={(e) => {
                      e.stopPropagation();
                      isCbCompany
                        ? onClickMore(elementData[idName])
                        : setMoreOptions((prev) => !prev);
                      setMoreOptionsId(elementData[idName]);
                    }}
                  >
                    <Icon
                      name="hamburger"
                      size={15}
                      color="#0a2346"
                      className="icon"
                    />
                    {moreOptionsId === elementData[idName] && moreOptions ? (
                      <MoreOptions
                        handleMouseDown={() => setMoreOptions(true)}
                        key={elementData[idName]}
                        options={tableConfig.options.more}
                        callback={onMoreOptionsCb}
                        lineId={moreOptionsId}
                        showOptionalLabel={showOptionalLabel}
                        elementData={elementData}
                      />
                    ) : null}
                  </div>
                </TooltipComponent>
              ) : null}
              {tableConfig.options && tableConfig.options.save ? (
                <div
                  className="icon-wrapper"
                  data-id={elementData[idName]}
                  onClick={onClickSave}
                >
                  <Icon name="save" className="icon" />
                </div>
              ) : null}
            </div>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
}

function TableComponent({
  idName,
  dataList,
  checkList = [],
  tableConfig = defaultConfigTable,
  filterComponent,
  filterComponentCustom,
  customFilter,
  onClickFilter = () => {},
  filterOpen = false,
  callbackOrderBy,
  callbackCurrentPage,
  callbackPerPage,
  callbackSearchTerm,
  cbInventory,
  cbPermissions,
  cbEdit,
  cbChangePassword,
  cbCopy,
  cbFile,
  cbAceitaProp,
  cbDelete,
  cbDeleteProp,
  cbRecusaProp,
  cbDownload,
  cbMoreAction,
  cbCheckBox,
  cbAttachFile,
  cbMoney,
  cbLookFile,
  cbClip,
  cbPencil,
  cbDownloadFile,
  cbSearch,
  cbSearchProp,
  onViewProp,
  cbUpdate,
  cbInfo,
  cbClickMore,
  cbApprove,
  cbAdd,
  cbRemove,
  isCbCompany,
  optionsCheckBox,
  enableOrder = true,
  customColumns = null,
  withOutTitleHeader = false,
  cbTableMoreAction,
  exportPDF = null,
  exportXLS = null,
  pageId,
  hideAdminOptions,
  cbConfirma,
  cbFinaliza,
  cbUpload,
  cbDownloadDp,
  cbSchedule,
  cbDetails,
  cbClosePayroll,
  editingId,
  setEditingId,
  cbSave,
  cbSwitch,
  cbHistory,
  cbEstorno,
  cbClickFinalizaLancamento,
}) {
  const [listData, setListData] = useState(dataList);
  const [filteredDataList, setFilteredDataList] = useState([]);
  const [objFilteredAttr, setObjFilteredAttr] = useState([]);
  const [page, setPage] = React.useState(1);
  const [moreOptions, setMoreOptions] = useState(false);
  const [moreActions, setMoreActions] = useState(false);
  const [moreDetails, setMoreDetails] = useState(false);
  const [moreOptionsId, setMoreOptionsId] = useState(null);
  const [moreDetailsId, setMoreDetailsId] = useState(null);
  const [moreActionsId, setMoreActionsId] = useState(null);
  const [listCheckBox, setListCheckBox] = useState([]);
  const [listCheckedId, setListCheckedId] = useState([]);
  const [listIndexCurrency, setListIndexCurrency] = useState([]);
  const [isOpenFilter, setIsOpenFilter] = useState(
    tableConfig.display.filterOnly
      ? true
      : tableConfig.display.isOpenedFilter
      ? true
      : false
  );
  const [orderBy, setorderBy] = useState({
    field: "",
    type: "",
  });
  const searchTermRef = useRef(null);

  const mobileScreen = useMediaQuery("(max-width: 768px)");

  const [orderByExport, setorderByExport] = useState(false);

  useEffect(() => {
    if (checkList.length !== 0) setListCheckBox(checkList);
    return;
  }, [checkList]);

  document.documentElement.onclick = function (event) {
    if (
      event.target !== moreOptions ||
      event.target !== moreActions ||
      event.target !== moreDetails
    ) {
      setMoreOptions(false);
      setMoreActions(false);
      setMoreDetails(false);
    }
  };

  useEffect(() => {
    setListData(dataList);
    if (tableConfig.display && tableConfig.display.startCheckBox) {
      if (dataList) setListCheckBox(new Array(dataList.length).fill(false));
    }
    if (tableConfig.display && tableConfig.display.startCheckBoxProduct) {
      if (dataList) setListCheckBox(new Array(dataList.length).fill(false));
    }
  }, [dataList, tableConfig.display]);

  useEffect(() => {
    setPage(tableConfig.currentPage);
  }, [tableConfig.currentPage]);

  const handleChangePage = (event, value) => {
    setPage(value);
    callbackCurrentPage({ page: value });
  };

  const perPageSelect = [
    { id: 10, nome: "10 por página" },
    { id: 25, nome: "25 por página" },
    { id: 50, nome: "50 por página" },
    { id: 100, nome: "100 por página" },
  ];

  let findIndexCurrency = useCallback(() => {
    let array = [];
    tableConfig.columnArray.forEach((item, index) => {
      switch (item.columnName.toUpperCase()) {
        case "SALDO":
          array.push(index);
          break;
        case "VALOR":
          array.push(index);
          break;
        case "VALOR RECEBIMENTO":
          array.push(index);
          break;
        case "VALOR PAGAMENTO":
          array.push(index);
          break;
        case "VALOR LOCAÇÃO":
          array.push(index);
          break;
        case "VALOR VENDA":
          array.push(index);
          break;
        case "VALOR IPTU":
          array.push(index);
          break;
        default:
          return;
      }
    });
    setListIndexCurrency(array);
  }, [tableConfig.columnArray]);

  useEffect(() => {
    let objFilteredAttrTemp = tableConfig.columnArray.map((element) => {
      return {
        dataRef: element.dataRef,
        dataName: element.columnName,
        hide: !!element.hide,
      };
    });
    setObjFilteredAttr(objFilteredAttrTemp);
    findIndexCurrency();
  }, [tableConfig, findIndexCurrency]);

  useEffect(() => {
    setorderBy({ field: tableConfig.orderBy, type: tableConfig.orderByType });

    if (listData) {
      let array = listData.map((element) => {
        let tempObj = {};
        objFilteredAttr.forEach((elementF) => {
          if (element.id) tempObj["id"] = element.id;
          if (element.cnpj)
            tempObj["cnpj"] = cpfCnpjMask(onlyUnsignedNumbers(element.cnpj))
              ? cpfCnpjMask(onlyUnsignedNumbers(element.cnpj))
              : element.cnpj;
          if (element.cpf)
            tempObj["cpf"] = cpfCnpjMask(onlyUnsignedNumbers(element.cpf))
              ? cpfCnpjMask(onlyUnsignedNumbers(element.cpf))
              : element.cpf;
          if (element.cnpj_cpf)
            tempObj["cnpj_cpf"] = cpfCnpjMask(
              onlyUnsignedNumbers(element.cnpj_cpf)
            )
              ? cpfCnpjMask(onlyUnsignedNumbers(element.cnpj_cpf))
              : element.cnpj_cpf;
          if (element.id2) tempObj["id2"] = element.id2;
          if (element.boleto_id) tempObj["boleto_id"] = element.boleto_id;
          if (element.id_lead_zap) tempObj["id_lead_zap"] = element.id_lead_zap;
          if (element.id_servico) tempObj["id_servico"] = element.id_servico;
          if (element.arquivo) tempObj["arquivo"] = element.arquivo;
          if (element.id_pessoa_documento)
            tempObj["id_pessoa_documento"] = element.id_pessoa_documento;

          tempObj[elementF.dataRef] = element[elementF.dataRef];
        });
        return tempObj;
      });
      setFilteredDataList(array);
    }
  }, [listData, objFilteredAttr]);

  let perPageSelectCb = (value) => {
    callbackPerPage(value);
  };

  let searchTermCb = (value) => {
    callbackSearchTerm(value);
  };

  let onClickEdit = (e) => {
    cbEdit(e.target.dataset.id);
  };

  let onClickChangePassword = (e) => {
    cbChangePassword(e.target.dataset.id);
  };

  let onClickInventory = (e) => {
    cbInventory(e.target.dataset.id);
  };

  let onClickCopy = (e) => {
    cbCopy(e.target.dataset.id);
  };

  function onClickDeleteProposta(params) {
    cbDeleteProp(params);
  }

  let onClickMore = (id) => {
    cbClickMore(id);
    setMoreOptions((prev) => !prev);
  };

  function onClickDeleteAnexo(params) {
    cbRecusaProp(params);
  }
  function onClickAceitaAnexo(params) {
    cbAceitaProp(params);
  }
  function onClickSearchPropCeu(params) {
    cbSearchProp(params);
  }
  function onClickSearchPropCeuAnexo(params) {
    const type = params.split(";")[0].split("/")[1];
    var a = document.createElement("a");
    a.href = params;
    a.download = "ceu-doc." + type;
    a.click(); //Downloaded file
  }

  let onClickDelete = (e) => {
    cbDelete(e.target.dataset.id);
  };

  let onClickApprove = (e) => {
    cbApprove(e.target.dataset.id);
  };

  let onClickAdd = (e) => {
    cbAdd(e.target.dataset.id);
  };

  let onClickRemove = (e) => {
    cbRemove(e.target.dataset.id);
  };

  let onClickPermissions = (e) => {
    cbPermissions(e.target.dataset.id);
  };

  let onClickCheckBox = (index) => {
    let checkList = listCheckBox;
    let ids = listCheckedId;
    let newValue = !checkList[index];
    checkList[index] = newValue;
    if (newValue) {
      ids.push(filteredDataList[index][idName]);
    } else {
      let index = ids.indexOf(
        (item) => item === filteredDataList[index][idName]
      );
      ids.splice(index, 1);
    }

    setListCheckedId(ids);
    setListCheckBox(checkList);
    cbCheckBox(ids);
  };

  let onClickCheckBoxProduct = (index) => {
    let checkList = listCheckBox;

    let ids = listCheckedId;

    let newValue = !checkList[index];

    checkList[index] = newValue;

    if (newValue) {
      ids.push(filteredDataList[index][idName]);
    } else {
      let index = ids.indexOf(
        (item) => item === filteredDataList[index][idName]
      );
      ids.splice(index, 1);
    }

    setListCheckedId(ids);
    setListCheckBox(checkList);
    cbCheckBox(ids);
  };

  let onClickCheckBoxPermissoes = useCallback(
    (event, id, index) => {
      let checkList = listCheckBox;
      let checked = event.target.checked;
      let cbObj = {};

      checkList[index] = checked;
      cbObj = {
        id,
        checked,
      };

      let responseCbObj = JSON.stringify(cbObj);

      setListCheckBox(checkList);
      cbCheckBox(responseCbObj);
    },
    [listCheckBox, cbCheckBox]
  );

  let onClickDownload = (e) => {
    cbDownload(e.target.dataset.id);
  };

  let onClickFile = (e) => {
    cbFile(e.target.dataset.id);
  };

  let onClickSearch = (e) => {
    cbSearch(e.target.dataset.id);
  };

  let onClickLookFile = (e) => {
    cbLookFile(e.target.dataset.id);
  };

  let onMoreOptionsCb = (action) => {
    cbMoreAction(action);
    setMoreOptions(false);
    setMoreDetails(false);
    setMoreActions(false);
  };

  let onMoreOptionsCbHeader = (action) => {
    cbTableMoreAction(action);
  };

  let onClickClip = (e) => {
    cbClip(e.target.dataset.id);
  };

  let onClickMoney = (e) => {
    let cbObj = {
      id: e.target.dataset.id,
      id2: e.target.dataset.id2,
    };

    let responseCbObj = JSON.stringify(cbObj);

    cbMoney(responseCbObj);
  };

  let onClickPencil = (e) => {
    cbPencil(e.target.dataset.id);
  };

  let onClickDownloadFile = (e) => {
    cbDownloadFile(e.target.dataset.id);
  };

  let onClickUpdate = (e) => {
    cbUpdate(e.target.dataset.id);
  };

  let onClickInfo = (e) => {
    cbInfo(e.target.dataset.id);
  };

  let onClickFaturaBoletoSearch = (e) => {
    cbSearch({
      fatura_id: e.target.dataset.id,
      boleto_id: e.target.dataset.boleto_id,
    });
  };

  const onClickConfirma = (e) => {
    cbConfirma(e.target.dataset.id);
  };

  const onClickFinaliza = (e) => {
    cbFinaliza(e.target.dataset.id);
  };

  const onClickUpload = (e) => {
    cbUpload(e.target.dataset.id);
  };

  const onClickDownloadDp = (e) => {
    cbDownloadDp(e.target.dataset.id);
  };

  const onClickSchedule = (e) => {
    cbSchedule(e.target.dataset.id);
  };

  const onClickDetails = (e) => {
    cbDetails(e.target.dataset.id);
  };

  const onClickClosePayroll = (e) => {
    cbClosePayroll(e.target.dataset.id);
  };

  const onClickSave = (e) => {
    cbSave(e.target.dataset.id);
  };

  const onClickSwitch = (e) => {
    cbSwitch(e.target.dataset.id);
  };

  const onClickHistory = (e) => {
    cbHistory(e.target.dataset.id);
  };

  const onClickEstorno = (e) => {
    cbEstorno(e.target.dataset.id);
  };

  const onClickFinalizaLancamento = (e) => {
    cbClickFinalizaLancamento(e.target.dataset.id);
  };

  const FilterList = () => {
    return (
      <div className="container-filter-list">
        <Icon name="filter" />
      </div>
    );
  };

  const ExportList = ({ exportXLS = null, exportPDF = null }) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleClick = useCallback((event) => {
      setMenuIsOpen(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
      setMenuIsOpen(false);
    }, []);

    const handleExport = useCallback(async (dataFile) => {
      let search_term = searchTermRef.current?.value
        ? searchTermRef.current.value
        : "";
      let aux = {
        url: dataFile.url,
        fileName: dataFile.fileName,
        fileType: dataFile.fileType,
        params: {
          order_by: orderBy.field,
          order_by_type: orderBy.type,
          ...dataFile.params,
        },
      };
      const response = await exportData(aux);
      setMenuIsOpen(false);
    }, []);

    return (
      <>
        <div
          className="container-export-list"
          onClick={handleClick}
          aria-controls="menu-export"
        >
          <Icon name="hamburger" />
        </div>
        <Menu
          id="menu-export"
          anchorEl={menuIsOpen}
          keepMounted
          open={!!menuIsOpen}
          onClose={handleClose}
          className="menu-export"
        >
          {exportPDF === null ? (
            ""
          ) : (
            <MenuItem
              onClick={() => handleExport(exportPDF)}
              className="menu-list-export"
            >
              <div>
                <Icon name="export" />
                <p>Exportar PDF</p>
              </div>
            </MenuItem>
          )}
          {exportXLS === null ? (
            ""
          ) : (
            <MenuItem
              onClick={() => handleExport(exportXLS)}
              className="menu-list-export"
            >
              <div>
                <Icon name="export" />
                <p>Exportar XLS</p>
              </div>
            </MenuItem>
          )}
        </Menu>
      </>
    );
  };

  const handleToggleFilter = useCallback(() => {
    setIsOpenFilter(!isOpenFilter);
  }, [tableConfig.display.filter, isOpenFilter]);

  return (
    <ClickAwayListener onClickAway={() => setMoreOptions(false)}>
      <Paper elevation={0} onClick={() => setMoreOptions(false)}>
        <div
          className={`config-display ${
            tableConfig.display.filterOnly && "filter-container"
          }`}
        >
          {tableConfig.display.search ? (
            <Search
              callback={searchTermCb}
              tableOptions={tableConfig.tableOptions}
              filterComponent={filterComponent}
              callbackMenu={onMoreOptionsCbHeader}
              customFilterTitle={tableConfig.display.customFilterTitle}
              customFilter={customFilter}
              filterOpen={filterOpen}
              onClickFilter={onClickFilter}
              searchTermRef={searchTermRef}
            />
          ) : null}
          <div
            onClick={handleToggleFilter}
            className={
              tableConfig.display.filterOnly ? "filter-display-only" : ""
            }
          >
            {tableConfig.display.filter && <FilterList />}
          </div>
          {tableConfig.display.export && (
            <ExportList exportPDF={exportPDF} exportXLS={exportXLS} />
          )}
        </div>
        {isOpenFilter && filterComponentCustom}
        <TableContainer
          className={
            tableConfig.display.selfContainer
              ? "MuiTableContainer-selfContainer"
              : ""
          }
        >
          {tableConfig.display.customTableTitle && (
            <h1 className="custom-table-title">
              {tableConfig.display.customTableTitle}
            </h1>
          )}
          <Table aria-label="Table">
            <TableHead>
              <Header
                customColumns={customColumns}
                tableConfig={tableConfig}
                callbackOrderBy={callbackOrderBy}
                optionsCheckBox={optionsCheckBox}
                cbMoreAction={cbMoreAction}
                listIndexCurrency={listIndexCurrency}
                enableOrder={enableOrder}
              />
            </TableHead>
            <TableBody>
              {filteredDataList?.length !== 0 ? (
                <React.Fragment>
                  {filteredDataList?.map((elementData, index) => {
                    const showOptionalLabel =
                      elementData["status_pagamento"] === "BAIXADA" &&
                      idName === "fatura_id"
                        ? true
                        : false;
                    return (
                      <Row
                        tableConfig={tableConfig}
                        index={index}
                        idName={idName}
                        listCheckBox={listCheckBox}
                        onClickCheckBox={onClickCheckBox}
                        onClickCheckBoxProduct={onClickCheckBoxProduct}
                        objFilteredAttr={objFilteredAttr}
                        onClickCheckBoxPermissoes={onClickCheckBoxPermissoes}
                        listIndexCurrency={listIndexCurrency}
                        customColumns={customColumns}
                        onClickFile={onClickFile}
                        onClickFaturaBoletoSearch={onClickFaturaBoletoSearch}
                        onClickUpdate={onClickUpdate}
                        onClickClip={onClickClip}
                        onClickInventory={onClickInventory}
                        onClickEdit={onClickEdit}
                        onClickChangePassword={onClickChangePassword}
                        onClickDelete={onClickDelete}
                        onClickApprove={onClickApprove}
                        onClickPermissions={onClickPermissions}
                        onClickCopy={onClickCopy}
                        onClickSearchPropCeu={onClickSearchPropCeu}
                        onClickSearchPropCeuAnexo={onClickSearchPropCeuAnexo}
                        onClickAceitaAnexo={onClickAceitaAnexo}
                        onViewProp={onViewProp}
                        onClickDeleteProposta={onClickDeleteProposta}
                        onClickDeleteAnexo={onClickDeleteAnexo}
                        onClickDownload={onClickDownload}
                        pageId={pageId}
                        onClickMoney={onClickMoney}
                        onClickSearch={onClickSearch}
                        onClickInfo={onClickInfo}
                        onClickPencil={onClickPencil}
                        onClickLookFile={onClickLookFile}
                        onClickDownloadFile={onClickDownloadFile}
                        setMoreDetailsId={setMoreDetailsId}
                        setMoreDetails={setMoreDetails}
                        onMoreOptionsCb={onMoreOptionsCb}
                        moreDetailsId={moreDetailsId}
                        setMoreActionsId={setMoreActionsId}
                        setMoreActions={setMoreActions}
                        moreActionsId={moreActionsId}
                        setMoreOptionsId={setMoreOptionsId}
                        isCbCompany={isCbCompany}
                        onClickMore={onClickMore}
                        setMoreOptions={setMoreOptions}
                        moreOptionsId={moreOptionsId}
                        moreOptions={moreOptions}
                        showOptionalLabel={showOptionalLabel}
                        elementData={elementData}
                        onClickAdd={onClickAdd}
                        hideAdminOptions={hideAdminOptions}
                        onClickRemove={onClickRemove}
                        onClickConfirma={onClickConfirma}
                        onClickFinaliza={onClickFinaliza}
                        onClickUpload={onClickUpload}
                        onClickDownloadDp={onClickDownloadDp}
                        onClickSchedule={onClickSchedule}
                        onClickDetails={onClickDetails}
                        onClickClosePayroll={onClickClosePayroll}
                        editingId={editingId}
                        setEditingId={setEditingId}
                        onClickSave={onClickSave}
                        onClickSwitch={onClickSwitch}
                        onClickHistory={onClickHistory}
                        onClickEstorno={onClickEstorno}
                        onClickFinalizaLancamento={onClickFinalizaLancamento}
                      />
                    );
                  })}
                </React.Fragment>
              ) : (
                filteredDataList?.length === 0 &&
                tableConfig.columnArray.length !== 0 && (
                  <React.Fragment>
                    <TableRow className="table-row">
                      <TableCell colSpan={objFilteredAttr.length}>
                        Nenhum item encontrado
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )
              )}
            </TableBody>
          </Table>
          {filteredDataList?.length > 0 &&
            tableConfig.columnArray.length !== 0 && (
              <>
                <div className="table-footer">
                  {tableConfig.display.itemsPerPage &&
                  tableConfig.dataListTotal &&
                  !mobileScreen ? (
                    <div className="table-perpage-wrapper">
                      <SelectField
                        initialValue={
                          typeof perPageSelect.find(
                            (item) => item.id === tableConfig?.perPage
                          ) !== "undefined"
                            ? perPageSelect.find(
                                (item) => item.id === tableConfig?.perPage
                              )
                            : perPageSelect[0]
                        }
                        list={perPageSelect}
                        callback={perPageSelectCb}
                      />
                    </div>
                  ) : null}
                  {tableConfig.display.totalResults && !mobileScreen ? (
                    <div className="table-total-data-lis-wrapper">
                      <span className="table-total-data-list">
                        {`Total: ${
                          tableConfig.dataListTotal
                            ? tableConfig.dataListTotal
                            : filteredDataList?.length
                        } resultados`}
                      </span>
                    </div>
                  ) : null}
                  {tableConfig.display.pagination ? (
                    <div className="table-configuration">
                      <Pagination
                        count={tableConfig.totalPages}
                        variant="outlined"
                        shape="rounded"
                        size="large"
                        page={page}
                        onChange={handleChangePage}
                        siblingCount={mobileScreen ? 0 : 1}
                        boundaryCount={1}
                      />
                    </div>
                  ) : null}
                </div>
              </>
            )}
        </TableContainer>
      </Paper>
    </ClickAwayListener>
  );
}

export default TableComponent;
