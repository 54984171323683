import React, { useState, useEffect } from "react";
import TextareaAutosize from 'react-textarea-autosize';

import classes from "./styles.module.scss";

function Textarea({
  title = "",
  label = "",
  nome = "",
  onInputChange = () => {},
  required = false,
  handleInputChange = () => { },
  initialValue = "",
  ...rest
}) {
  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue) {
      setInputValue(initialValue);
      onInputChange(initialValue);
    }
  }, [initialValue])

  function handleOnInputWrap(event) {
    const text = event.target.value;
    setInputValue(text);
    onInputChange(inputValue);
  }

  const headerInput = () => (
    <React.Fragment>
      <label className={classes.inputLabel}>
        {label}
        <span className={classes.requiredItem}>{required && "*"}</span>
        <span
          className={`${classes.inputHint} ${
            inputValue !== "" ? classes.inputHintHide : ""
          } `}
        >
          {title}
        </span>
      </label>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {headerInput()}
      <div className={classes.inputContainer}>
        <div className={classes.inputContainer}>
          <textarea
            required={required}
            className={classes.input}
            onChange={(event) => {
              handleOnInputWrap(event);
              handleInputChange(event);
            }}
            name={nome}
            {...rest}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Textarea;
