import Axios from "./Axios";

const indicadorIEService = {
  getIndicadorIe: async () => {
    const result = await Axios.get("/seleciona-indicador-ie", {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};

export default indicadorIEService;
