import React, { Component } from "react";
import Barcode from "react-barcode";

import BBLogo from "../../../assets/img/bancodobrasil.png";

import classes from "./styles.module.css";

class Boleto extends Component {
  render() {
    const {
      linhaDigitavel = "",
      dataVencimento = "",
      beneficiario = "",
      agenciaCodigo = "",
      dataDocumento = "",
      numeroDocumento = "",
      especieDoc = "",
      aceite = "",
      dataProcessamento = "",
      nossoNumero = "",
      usoDoBanco = "",
      carteira = "",
      especie = "",
      quantidade = "",
      valor = "",
      valorDocumento = "",
      instrucao = "",
      desconto = "",
      juros = "",
      valorCobrado = "",
      nomePagador = "",
      enderecoPagador = "",
      codBarras = "",
      cpfCnpjBeneficiario = "",
      enderecoBeneficiario = "",
      valorPago = "",
    } = this.props.boleto;
    const { cnpjCpfPagador = "" } = this.props;

    return (
      <div>
        <span style={{ float: "right", fontSize: 10 }}>Recibo do Pagador</span>
        <table
          border="0"
          cellPadding={5}
          cellSpacing={0}
          className={classes.table}
          style={{ marginBottom: 50 }}
        >
          <tbody>
            <tr>
              <td
                colSpan={1}
                style={{ borderBottom: "1px solid #000" }}
                valign="middle"
                width="19%"
              >
                <img src={BBLogo} style={{ float: "left", height: 30 }} />
              </td>
              <td colSpan={1} className={classes.td} width="8%">
                001-9
              </td>
              <td colSpan={10}>
                <span
                  style={{
                    float: "right",
                    fontSize: 13,
                    fontWeight: "bold",
                    letterSpacing: 1,
                  }}
                >
                  {linhaDigitavel}
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={12} className={classes.tdEight}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Nome do pagador/CPF/CNPJ/Endereço
                </span>
                {nomePagador} - CPF/CNPJ: {(cnpjCpfPagador)}
                <br />
                {enderecoPagador}
              </td>
            </tr>
            <tr>
              <td colSpan={12} className={classes.tdBottom}>
                <p style={{ margin: 0, textAlign: "left" }}>&nbsp;</p>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Sacador/Avalista
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={2.4} className={classes.tdEight}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Nosso número
                </span>
                <p style={{ margin: 0, textAlign: "left" }}>{nossoNumero}</p>
              </td>
              <td colSpan={2.4} className={classes.tdEight}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Nr. Documento
                </span>
                <p style={{ margin: 0, textAlign: "left" }}>{numeroDocumento}</p>
              </td>
              <td colSpan={2.4} className={classes.tdEight}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Data de Vencimento
                </span>
                <p style={{ margin: 0, textAlign: "left" }}>{dataVencimento}</p>
              </td>
              <td colSpan={2.4} className={classes.tdEight}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Valor do Documento
                </span>
                <p style={{ margin: 0, textAlign: "left" }}>{valorDocumento}</p>
              </td>
              <td colSpan={2.4} className={classes.tdEight}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  (=) Valor Pago
                </span>
                <p style={{ margin: 0, textAlign: "left" }}>{valorPago}</p>
              </td>
            </tr>
            <tr>
              <td
                colSpan={12}
                className={classes.tdNine}
                style={{ fontSize: 9, height: 100, width: "100%" }}
                valign="top"
              >
                <p style={{ marginTop: -3 }}>
                  Nome do Beneficiário/CPF/CNPJ/Endereço
                </p>

                <p style={{ margin: 0, fontWeight: "bold" }}>
                  {beneficiario} - CPF/CNPJ: {cpfCnpjBeneficiario}
                  <br />
                  {enderecoBeneficiario}
                </p>
              </td>
            </tr>
            <tr className={classes.lastRowRecibo}>
              <td colSpan={8} style={{ height: 70 }} valign="top">
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Agência/Código do Beneficiário
                </span>
                <p style={{ margin: 0, fontWeight: "bold" }}>{agenciaCodigo}</p>
              </td>
              <td colSpan={4} valign="top">
                <span
                  style={{
                    fontSize: 10,
                    display: "block",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Autenticação Mecânica
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          border="0"
          cellPadding={5}
          cellSpacing={0}
          className={classes.table}
        >
          <tbody>
            <tr>
              <td
                colSpan={1}
                style={{ borderBottom: "1px solid #000" }}
                valign="middle"
                width="19%"
              >
                <img src={BBLogo} style={{ float: "left", height: 30 }} />
              </td>
              <td colSpan={1} className={classes.td} width="8%">
                001-9
              </td>
              <td colSpan={10}>
                <span
                  style={{
                    float: "right",
                    fontSize: 13,
                    fontWeight: "bold",
                    letterSpacing: 1,
                  }}
                >
                  {linhaDigitavel}
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={9} className={classes.tdOne}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Local de Pagamento
                </span>
                <p style={{ margin: 0, fontWeight: "bold" }}>
                  Pagável em qualquer banco.
                </p>
              </td>
              <td colSpan={3} className={classes.tdTwo} width="29%">
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Vencimento
                </span>
                <p
                  style={{ margin: 0, fontWeight: "bold", textAlign: "center" }}
                >
                  {dataVencimento}
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={9} className={classes.tdNine}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Nome do Beneficiário/CPF/CNPJ
                </span>
                <p style={{ margin: 0, fontWeight: "bold" }}>
                  {beneficiario} - CPF/CNPJ: {cpfCnpjBeneficiario}
                  <br />
                  {enderecoBeneficiario}
                </p>
              </td>
              <td colSpan={3} className={classes.tdAgencia}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Agência/Código do Beneficiário
                </span>
                <p style={{ margin: 0, fontWeight: "bold", textAlign: "center" }}>{agenciaCodigo}</p>
              </td>
            </tr>

            <tr>
              <td colSpan={2} className={classes.tdFive}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Data do Documento
                </span>
                <p style={{ margin: 0, textAlign: "center" }}>
                  {dataDocumento}
                </p>
              </td>
              <td colSpan={2} className={classes.tdSix}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Nr. Documento
                </span>
                <p style={{ margin: 0, textAlign: "center" }}>
                  {numeroDocumento}
                </p>
              </td>
              <td colSpan={1} className={classes.tdSeven}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Espécie DOC
                </span>
                <p style={{ margin: 0, textAlign: "center" }}>{especieDoc}</p>
              </td>
              <td colSpan={2} className={classes.tdOne}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Aceite
                </span>
                <p style={{ margin: 0, textAlign: "center" }}>{aceite}</p>
              </td>
              <td colSpan={2} className={classes.tdOne}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Data de Processamento
                </span>
                <p style={{ margin: 0, textAlign: "center" }}>
                  {dataProcessamento}
                </p>
              </td>
              <td colSpan={3} className={classes.tdEight}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Nosso Número
                </span>
                <p style={{ margin: 0, textAlign: "center" }}>{nossoNumero}</p>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className={classes.tdOne}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Uso do banco
                </span>
                <p style={{ margin: 0 }}>{usoDoBanco}</p>
              </td>
              <td colSpan={2} className={classes.tdOne}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Carteira
                </span>
                <p style={{ margin: 0, textAlign: "center" }}>{carteira}</p>
              </td>
              <td colSpan={1} className={classes.tdOne}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Espécie
                </span>
                <p style={{ margin: 0, textAlign: "center" }}>{especie}</p>
              </td>
              <td colSpan={2} className={classes.tdOne}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Quantidade
                </span>
                <p style={{ margin: 0, textAlign: "center" }}>{quantidade}</p>
              </td>
              <td colSpan={2} className={classes.tdOne}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  (x) Valor
                </span>
                <p
                  style={{ margin: 0, textAlign: "center", fontWeight: "bold" }}
                >
                  {valor}
                </p>
              </td>
              <td colSpan={3} className={classes.tdFour}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  (=) Valor do Documento
                </span>
                <p
                  style={{ margin: 0, textAlign: "center", fontWeight: "bold" }}
                >
                  {valorDocumento}
                </p>
              </td>
            </tr>
            <tr>
              <td
                colSpan={9}
                rowSpan={3}
                className={classes.tdOne}
                style={{ fontSize: 9 }}
                valign="top"
              >
                <p style={{ marginTop: -3 }}>
                  Informações de responsabilidade do beneficiário
                </p>

                <p style={{ marginTop: 5 }}>{instrucao}</p>
              </td>
              <td colSpan={3} className={classes.tdFour}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  (-) Desconto/Abatimento
                </span>
                <p
                  style={{ margin: 0, textAlign: "right", fontWeight: "bold" }}
                >
                  {desconto}
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={3} className={classes.tdFour}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  (+) Juros / Multa
                </span>
                <p
                  style={{ margin: 0, textAlign: "right", fontWeight: "bold" }}
                >
                  {juros}
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={3} className={classes.tdFour}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  (=) Valor Cobrado
                </span>
                <p
                  style={{ margin: 0, textAlign: "right", fontWeight: "bold" }}
                >
                  {valorCobrado}
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={12} className={classes.tdNine}>
                <span style={{ fontSize: 10, display: "block", width: "100%" }}>
                  Nome do Pagador/CPF/CNPJ/Endereço
                </span>
                <p style={{ margin: 0, fontWeight: "bold" }}>
                  {nomePagador} - CPF/CNPJ: {(cnpjCpfPagador)}
                  <br />
                  {enderecoPagador}
                </p>
              </td>
            </tr>
            <tr>
              <td
                colSpan={12}
                style={{ verticalAlign: "top", border: 0, padding: 10 }}
              >
                <div className={classes.barcodeWrapper}>
                  <Barcode
                    value={codBarras}
                    format="ITF"
                    width={1}
                    height="69"
                    displayValue={false}
                  />
                </div>
                <span className={classes.span}>
                  Autenticação Mecânica - Ficha de Compensação
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Boleto;
