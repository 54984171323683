import React, { useState, useMemo, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import Switch from "../../components/Switch";
import Select from "../../components/SelectComponent";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import InputFile from "../../components/InputFile";
import Button from "../../components/ButtonComponent";
import OverlayLoading from "../../components/OverlayLoading";
import CallbackMessage from "../../components/CallbackMessage";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import ModalIssqn from "../../components/ModalIssqn";
import ModalIcms00 from "../../components/ModalIcms/ICMS00";
import ModalIcms10 from "../../components/ModalIcms/ICMS10";
import ModalIcms20 from "../../components/ModalIcms/ICMS20";
import ModalIcms30 from "../../components/ModalIcms/ICMS30";
import ModalIcms404150 from "../../components/ModalIcms/ICMS40_41_50";
import ModalIcms51 from "../../components/ModalIcms/ICMS51";
import ModalIcms60 from "../../components/ModalIcms/ICMS60";
import ModalIcms70 from "../../components/ModalIcms/ICMS70";
import ModalIcms90 from "../../components/ModalIcms/ICMS90";
import ModalIcmssn101 from "../../components/ModalIcms/ICMSSN101";
import ModalIcmssn102 from "../../components/ModalIcms/ICMSSN102";
import ModalIcmssn201 from "../../components/ModalIcms/ICMSSN201";
import ModalIcmssn202 from "../../components/ModalIcms/ICMSSN202";
import ModalIcmssn500 from "../../components/ModalIcms/ICMSSN500";
import ModalIcmssn900 from "../../components/ModalIcms/ICMSSN900";

import produtosServices from "../../services/produtosServices";
import selectServices from "../../services/selectServices";

import { base64ToFile } from "../../utils/functions";
import validateFields from "../../utils/validateFields";

import trashIcon from "../../assets/img/trash.svg";

import classes from "./styles.module.scss";
import { useEffect } from "react";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import { onlyUnsignedNumbers } from "../../utils/strings";

const eanValidLength = [8, 12, 13, 14];
import useCompany from "../../hooks/useCompany";

const CadastroProdutos = () => {
  const history = useHistory();
  const params = useParams();
  const {
    companyList: listEmpresas,
    selectedCompany: empresa,
    setSelectedCompany: setEmpresa,
    getEmpresaPagina,
  } = useCompany("produtos");

  const { state } = useLocation();

  const { id: idProdutoParams } = params;

  const [idProduto, setIdProduto] = useState(idProdutoParams);

  const {
    cadastraProduto,
    selectTipo,
    selectTipoEspecifico,
    selectCfop,
    selectMedidaTributaria,
    selectMedidaComercial,
    selectOrigemMaterial,
    consultaProduto,
    atualizaProduto,
    selectPisConfins,
  } = produtosServices;
  const {
    selecionaIcms,
    selecionaDeterminacaoBci,
    selectCstCsosn,
    selecionaNcm,
    selecionaCest,
    selecionaCategoriaPadrao,
  } = selectServices;

  const [thumbnail, setThumbnail] = useState(null);
  const [blocked, setBlocked] = useState(false);
  const [codigo, setCodigo] = useState("");
  const [tipoProduto, setTipoProduto] = useState(null);
  const [tipoEspecifico, setTipoEspecifico] = useState(null);
  const [categoriaPadrao, setCategoriaPadrao] = useState(null);
  const [descricaoProduto, setDescricaoProduto] = useState("");
  const [ncm, setNcm] = useState("");
  const [cest, setCest] = useState("");
  const [descricaoNcm, setDescricaoNcm] = useState("");
  const [ean, setEan] = useState("");
  const [eanTributario, setEanTributario] = useState("");
  const [pis, setPis] = useState(null);
  const [aliquotaPis, setAliquotaPis] = useState(null);
  const [cstPis, setCstPis] = useState(null);
  const [cofins, setCofins] = useState(null);
  const [aliquotaCofins, setAliquotaCofins] = useState(null);
  const [cstCofins, setCstCofins] = useState(null);
  const [codigoBeneficioFiscal, setCodigoBeneficioFiscal] = useState("");
  const [unidadeMedidaComercial, setUnidadeMedidaComercial] = useState(null);
  const [unidadeMedidaComercialDescricao, setUnidadeMedidaComercialDescricao] =
    useState(null);
  const [valorUnitarioComercial, setValorUnitarioComercial] = useState("");
  const [unidadeMedidaTributaria, setUnidadeMedidaTributaria] = useState(null);
  const [
    unidadeMedidaTributariaDescricao,
    setUnidadeMedidaTributariaDescricao,
  ] = useState(null);
  const [valorUnitarioTributario, setValorUnitarioTributario] = useState("");
  const [pesoLiquido, setPesoLiquido] = useState("");
  const [pesoBruto, setPesoBruto] = useState("");
  const [origemMaterial, setOrigemMaterial] = useState(null);
  const [percentualImpostos, setPercentualImpostos] = useState("");
  const [extensaoIpi, setExtensaoIpi] = useState("");
  const [valorTotalTributos, setValorTotalTributos] = useState("");
  const [valorCusto, setValorCusto] = useState("");
  const [icms, setIcms] = useState(null);
  const [utilizaIssqn, setUtilizaIssqn] = useState(false);
  const [objIcms, setObjIcms] = useState(null);
  const [objIssqn, setObjIssqn] = useState(null);
  const [nfe, setNfe] = useState(null);
  const [file, setFile] = useState(null);
  const [listTipo, setListTipo] = useState([]);
  const [listTipoEspecifico, setListTipoEspecifico] = useState([]);
  const [listCategoriaPadrao, setListCategoriaPadrao] = useState([]);
  const [listMedidaComercial, setListMedidaComercial] = useState([]);
  const [listMedidaTributaria, setListMedidaTributaria] = useState([]);
  const [listOrigemMaterial, setListOrigemMaterial] = useState([]);
  const [listNcm, setListNcm] = useState([]);
  const [listCest, setListCest] = useState([]);
  const [listNfe, setListNfe] = useState([]);
  const [listIcms, setListIcms] = useState([]);
  const [listBci, setListBci] = useState([]);
  const [listCstCsosn, setListCstCsosn] = useState([]);
  const [listPisConfins, setListPisConfins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [utilizaTabela, setUtilizaTabela] = useState(false);
  const [impostos, setImpostos] = useState({});
  const [openModalIssqn, setOpenModalIssqn] = useState(false);
  const [openModalIcms00, setOpenModalIcms00] = useState(false);
  const [openModalIcms10, setOpenModalIcms10] = useState(false);
  const [openModalIcms20, setOpenModalIcms20] = useState(false);
  const [openModalIcms30, setOpenModalIcms30] = useState(false);
  const [openModalIcms404150, setOpenModalIcms404150] = useState(false);
  const [openModalIcms51, setOpenModalIcms51] = useState(false);
  const [openModalIcms60, setOpenModalIcms60] = useState(false);
  const [openModalIcms70, setOpenModalIcms70] = useState(false);
  const [openModalIcms90, setOpenModalIcms90] = useState(false);
  const [openModalIcmssn101, setOpenModalIcmssn101] = useState(false);
  const [openModalIcmssn102, setOpenModalIcmssn102] = useState(false);
  const [openModalIcmssn201, setOpenModalIcmssn201] = useState(false);
  const [openModalIcmssn202, setOpenModalIcmssn202] = useState(false);
  const [openModalIcmssn500, setOpenModalIcmssn500] = useState(false);
  const [openModalIcmssn900, setOpenModalIcmssn900] = useState(false);
  const [estoque, setEstoque] = useState(0);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackType, setCallbackType] = useState("");
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [callbackErrorList, setCallbackErrorList] = useState([]);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [loadedImage, setLoadedImage] = useState();

  const loadNcm = useCallback(
    async (ncmTemp) => {
      const response = await selecionaNcm();
      if (response.status === 200) {
        if (ncmTemp) {
          console.log({ ncmTemp });
          console.log({ response: response.data });
          const ncmEdit = response.data?.find(
            (item) => onlyUnsignedNumbers(item.id) === ncmTemp
          )?.id;
          console.log(ncmEdit);
          setNcm(ncmEdit);
        }
        setListNcm(response.data);
      }
      if (response.status === 401) {
        setOpenLoginExpirado(true);
      }
    },
    [selecionaNcm]
  );

  const loadCest = useCallback(async () => {
    setLoading(true);
    const response = await selecionaCest(ncm);
    setLoading(false);
    console.log(response);
    if (response?.status === 200 && response?.data?.length > 0) {
      const formattedCest = response?.data?.map((a) => {
        return {
          ...a,
          id: a.cest,
          nome: `${a.cest} - ${a.descricao}`,
        };
      });
      setListCest(formattedCest);
    } else {
      setListCest([]);
    }
    if (response?.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [ncm, selecionaCest]);

  const loadTipo = useCallback(async () => {
    const response = await selectTipo();
    if (response.status === 200) {
      setListTipo(response.data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selectTipo]);

  const loadTipoEspecifico = useCallback(async () => {
    const response = await selectTipoEspecifico();
    if (response.status === 200) {
      setListTipoEspecifico(response.data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selectTipoEspecifico]);

  const loadCategoriaPadrao = useCallback(async () => {
    const response = await selecionaCategoriaPadrao();
    if (response.status === 200) {
      const formattedData = response?.data?.map((item) => ({
        ...item,
        id: item?.id_categoria_padrao,
      }));
      setListCategoriaPadrao(formattedData);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selecionaCategoriaPadrao]);

  const loadUnidadeComercial = useCallback(async () => {
    const response = await selectMedidaComercial();
    if (response.status === 200) {
      setListMedidaComercial(response.data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selectMedidaComercial]);

  const loadUnidadeTributaria = useCallback(async () => {
    const response = await selectMedidaTributaria();
    if (response.status === 200) {
      setListMedidaTributaria(response.data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selectMedidaTributaria]);

  const loadOrigemMaterial = useCallback(async () => {
    const response = await selectOrigemMaterial();
    if (response.status === 200) {
      setListOrigemMaterial(response.data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selectOrigemMaterial]);

  const loadCfop = useCallback(async () => {
    const response = await selectCfop();
    if (response.status === 200) {
      setListNfe(response.data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selectCfop]);

  const loadIcms = useCallback(async () => {
    const response = await selecionaIcms();
    if (response.status === 200) {
      setListIcms(response.data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selecionaIcms]);

  const loadListBci = useCallback(async () => {
    const response = await selecionaDeterminacaoBci();
    if (response.status === 401) {
      setLoading(false);
      setOpenLoginExpirado(true);
      return;
    } else if (response.status === 200) setListBci(response.data);
  }, [selecionaDeterminacaoBci]);

  const loadlistCstCsosn = async (id) => {
    const response = await selectCstCsosn(id);
    if (response.status === 200) setListCstCsosn(response.data);
  };

  const loadProductInfo = useCallback(
    async (id) => {
      // console.log("idPRodutooooooooooooooooooooo", idProduto);

      if (idProduto) {
        const response = await consultaProduto(id);
        if (response.status === 200) {
          const data = response.data;
          setBlocked(data?.bloqueado_inativo === 0 ? false : true);
          setCest(data?.cest);
          setNfe(data?.cfop);
          setCodigoBeneficioFiscal(data?.codigo_beneficio_fiscal);
          setCodigo(data?.codigo_produto);
          setDescricaoProduto(data?.descricao);
          setDescricaoNcm(data?.descricao_ncm);
          setEan(data?.ean);
          setEanTributario(data?.ean_tributado);
          setExtensaoIpi(data?.extensao_ipi);
          setUtilizaTabela(data?.extensao_ipi !== "" ? true : false);
          setUtilizaIssqn(data?.impostos.issqn === "Sim" ? 1 : 0);
          setEmpresa(data?.id_empresa);
          setFile(data?.imagem_produto);
          loadNcm(data?.ncm);
          setOrigemMaterial(data?.origem_material);
          setPercentualImpostos(`${data?.percentual_imposto}%`);
          setPesoBruto(data?.peso_bruto);
          setPesoLiquido(data?.peso_liquido);
          setTipoEspecifico(data?.tipo_especifico_produto);
          setCategoriaPadrao(data?.id_categoria_padrao);
          setTipoProduto(data?.tipo_produto);
          setUnidadeMedidaComercial(data?.unidade_medida_comercial);
          setValorCusto(data?.valor_de_custo ? data?.valor_de_custo : "");
          setUnidadeMedidaTributaria(data?.unidade_medida_tributario);
          setValorTotalTributos(data?.valor_total_tributos);
          setValorUnitarioComercial(data?.valor_unitario_comercial);
          setValorUnitarioTributario(data?.valor_unitario_tributario);
          setIcms(data?.impostos?.id_icms);
          setLoadedImage(data?.imagem_produto);
          setImpostos(data?.impostos);
          setPis(data?.impostos?.valor_base_calculo_pis);
          setAliquotaPis(data?.impostos?.aliquota_pis);
          setCstPis(data?.impostos?.cst_pis ? data?.impostos?.cst_pis : null);
          setCofins(data?.impostos?.valor_base_calculo_cofins);
          setAliquotaCofins(data?.impostos?.aliquota_cofins);
          setCstCofins(
            data?.impostos?.cst_cofins ? data?.impostos?.cst_cofins : null
          );
          setEstoque(data?.estoque ? data?.estoque : null);

          const objImpostos = {
            ...data.impostos,
            issqn: data?.impostos?.issqn === 1 ? 1 : 0,
          };
          data.impostos.issqn === 1
            ? setObjIssqn(objImpostos)
            : setObjIcms(objImpostos);
        }
        if (response?.response?.status === 400) {
          setCallbackType("error");
          setCallbackMessage(
            response.response.data.msg ||
              response.response.data.message ||
              "Erro!"
          );
          setCallbackErrorList(
            [response.response.data.error] || ["Erro ao consultar produto!"]
          );
          setLoading(false);
          setCallbackShown(true);
          setIdProduto(null);
          return;
        }
      } else {
        loadNcm();
      }

      if (state) {
        const { id: idCopy, isCopy } = state;

        const IntId = parseInt(idCopy, 10);

        if (IntId && isCopy) {
          const response = await consultaProduto(IntId);

          if (response.status === 200) {
            const data = response.data;
            setBlocked(data?.bloqueado_inativo === 0 ? false : true);
            setCest(data?.cest);
            setNfe(data?.cfop);
            setCodigoBeneficioFiscal(data?.codigo_beneficio_fiscal);
            setCodigo(data?.codigo_produto);
            setDescricaoProduto(data?.descricao);
            setDescricaoNcm(data?.descricao_ncm);
            setEan(data?.ean);
            setEanTributario(data?.ean_tributado);
            setExtensaoIpi(data?.extensao_ipi);
            setUtilizaTabela(data?.extensao_ipi !== "" ? true : false);
            setUtilizaIssqn(data?.impostos.issqn === "Sim" ? 1 : 0);
            setEmpresa(data?.id_empresa);
            setFile(data?.imagem_produto);
            setNcm(data?.ncm);
            setOrigemMaterial(data?.origem_material);
            setPercentualImpostos(`${data?.percentual_imposto}%`);
            setPesoBruto(data?.peso_bruto);
            setPesoLiquido(data?.peso_liquido);
            setCategoriaPadrao(data?.id_categoria_padrao);
            setTipoEspecifico(data?.tipo_especifico_produto);
            setTipoProduto(data?.tipo_produto);
            setUnidadeMedidaComercial(data?.unidade_medida_comercial);
            setValorCusto(data?.valor_de_custo ? data?.valor_de_custo : "");
            setUnidadeMedidaTributaria(data?.unidade_medida_tributario);
            setValorTotalTributos(data?.valor_total_tributos);
            setValorUnitarioComercial(data?.valor_unitario_comercial);
            setValorUnitarioTributario(data?.valor_unitario_tributario);
            setIcms(data?.impostos?.id_icms);
            setLoadedImage(data?.imagem_produto);
            setImpostos(data?.impostos);
            setEstoque(data?.estoque);

            const objImpostos = {
              ...data.impostos,
              issqn: data?.impostos?.issqn === 1 ? 1 : 0,
            };
            data.impostos.issqn === 1
              ? setObjIssqn(objImpostos)
              : setObjIcms(objImpostos);
            console.log(objImpostos);
          }
        }
      }
    },
    [state, idProduto, setOrigemMaterial]
  );

  const loadPisConfins = useCallback(async () => {
    const response = await selectPisConfins();
    if (response.status === 200) {
      setListPisConfins(response?.data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selectPisConfins]);

  const preview = useMemo(() => {
    return thumbnail ? URL.createObjectURL(thumbnail) : null;
  }, [thumbnail]);

  const handleSwitch = useCallback(() => {
    setBlocked((prev) => !prev);
  }, []);

  const handleSwitchTabela = useCallback(() => {
    setUtilizaTabela((prev) => !prev);
    setExtensaoIpi("");
  }, []);

  const handleChange = useCallback((value, setterFc) => {
    setterFc(value);
  }, []);

  const handleChangeEmpresa = useCallback(
    (id) => {
      setEmpresa(id);
      if (callbackErrorList.includes("Selecione uma empresa antes.")) {
        setOpenModalIssqn(true);
        setCallbackErrorList([]);
      }
    },
    [callbackErrorList]
  );

  const handleCadastrarParametros = async () => {
    if (utilizaIssqn) handleChangeIssqn();
    else await handleChangeIcms();
  };

  const handleChangeIssqn = useCallback(() => {
    setIcms();
    if (empresa === null) {
      setCallbackMessage("Erro!");
      setCallbackDuration(4000);
      setCallbackType("error");
      setCallbackErrorList(["Selecione uma empresa antes."]);
      setCallbackShown(true);
    } else setOpenModalIssqn(true);
  }, [utilizaIssqn, empresa]);

  const handleChangeIcms = async () => {
    setLoading(true);
    await loadlistCstCsosn(icms);
    setLoading(false);
    switch (icms) {
      case 0:
        setOpenModalIcms00(true);
        break;
      case 1:
        setOpenModalIcms10(true);
        break;
      case 2:
        setOpenModalIcms20(true);
        break;
      case 3:
        setOpenModalIcms30(true);
        break;
      case 4:
        setOpenModalIcms404150(true);
        break;
      case 5:
        setOpenModalIcms51(true);
        break;
      case 6:
        setOpenModalIcms60(true);
        break;
      case 7:
        setOpenModalIcms70(true);
        break;
      case 8:
        setOpenModalIcms90(true);
        break;
      case 9:
        setOpenModalIcmssn101(true);
        break;
      case 10:
        setOpenModalIcmssn102(true);
        break;
      case 11:
        setOpenModalIcmssn201(true);
        break;
      case 12:
        setOpenModalIcmssn202(true);
        break;
      case 13:
        setOpenModalIcmssn500(true);
        break;
      case 14:
        setOpenModalIcmssn900(true);
        break;
      default:
        return;
    }
  };

  const callbackIssqn = useCallback((value) => {
    setObjIssqn(value);
    setObjIcms(null);
    setOpenModalIssqn(false);
  }, []);

  const callbackIcms = useCallback((value, onCloseIcms) => {
    setObjIcms(value);
    setObjIssqn(null);
    onCloseIcms();
  }, []);

  const handleDownloadFile = useCallback(() => {
    setLoading(true);
    if (file !== null && thumbnail !== null) {
      const downloadFile = base64ToFile(file, thumbnail.name);
      setLoading(false);
      return downloadFile;
    } else {
      setCallbackType("error");
      setCallbackErrorList([
        "Houve um erro ao tentar fazer o download do arquivo. Tente novamente mais tarde.",
      ]);
      setCallbackDuration(2000);
      setCallbackShown(true);
    }
    setLoading(false);
  }, [file, thumbnail]);

  const handleRemoveFile = useCallback(() => {
    setThumbnail(null);
    setFile(null);
    setLoadedImage(null);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/produtos-cadastrados");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    let hasErrors = false;
    const fieldsToValidate = [
      {
        label: "empresa",
        value: empresa,
      },
      {
        label: "codigo",
        value: codigo,
      },
      {
        label: "tipodoproduto",
        value: tipoProduto,
      },
      {
        label: "tipoespecifico",
        value: tipoEspecifico,
      },
      {
        label: "categoriapadrao",
        value: categoriaPadrao,
      },
      {
        label: "descricaodoprodutoouservico",
        value: descricaoProduto,
      },
      {
        label: "unidadedemedidacomercial",
        value: unidadeMedidaComercial,
      },
      {
        label: "unidadedemedidatributaria",
        value: unidadeMedidaTributaria,
      },
      {
        label: "origemdomaterial",
        value: origemMaterial,
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (!eanValidLength.includes(ean.length)) {
      fieldsErrors.push(
        "Informe um código EAN válido (8, 12, 13 ou 14 digitos)"
      );
    }

    if (!eanValidLength.includes(eanTributario.length)) {
      fieldsErrors.push(
        "Informe um código EAN Tributário válido (8, 12, 13 ou 14 digitos)"
      );
    }

    if (fieldsErrors.length !== 0) {
      hasErrors = true;
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(fieldsErrors);
      setCallbackShown(true);
      setLoading(false);
    }

    if (hasErrors) return;

    if (idProduto) {
      // console.log("opaaaa cheguei no atualiza!!!");
      const response = await atualizaProduto(
        idProduto,
        empresa,
        codigo,
        tipoProduto,
        tipoEspecifico,
        descricaoProduto,
        ncm,
        valorCusto,
        unidadeMedidaComercial,
        unidadeMedidaTributaria,
        origemMaterial,
        descricaoNcm,
        cest,
        ean,
        eanTributario,
        codigoBeneficioFiscal,
        valorUnitarioComercial,
        valorUnitarioTributario,
        pesoLiquido,
        pesoBruto,
        percentualImpostos.slice(0, -1),
        extensaoIpi,
        valorTotalTributos,
        nfe,
        blocked ? 1 : 0,
        file,
        utilizaIssqn ? 1 : 0,
        icms,
        origemMaterial,
        objIcms,
        objIssqn,
        categoriaPadrao,
        pis,
        aliquotaPis?.replace("%", ""),
        cstPis,
        cofins,
        aliquotaCofins?.replace("%", ""),
        cstCofins,
        estoque,
        unidadeMedidaComercialDescricao,
        unidadeMedidaTributariaDescricao
      );
      if (response.status === 200) {
        setCallbackMessage(response.data?.msg);
        setCallbackDuration(2000);
        setCallbackType("success");
        setCallbackShown(true);
      } else if (response.status === 500) {
        setCallbackMessage("Erro!");
        setCallbackErrorList([
          "Erro interno do servidor, por favor, contate o suporte.",
        ]);
        setCallbackType("error");
        setCallbackShown(true);
      } else if (response.status === 401) {
        setOpenLoginExpirado(true);
        return;
      } else {
        setCallbackType("error");
        setCallbackMessage("Erro!");
        if (response.response.data.error)
          setCallbackErrorList(Object.values(response.response?.data?.error));
        else setCallbackErrorList(["Houve um erro ao atualizar o produto."]);
        setCallbackShown(true);
      }

      setLoading(false);

      return;
    }

    if (state?.isCopy) {
      let newObjIcms = {};

      if (
        objIcms?.id_produto !== null &&
        objIcms?.id_produto !== undefined &&
        objIcms?.id_produto
      ) {
        const { id_produto, ...newObjectIcmsFormatted } = objIcms;

        newObjIcms = newObjectIcmsFormatted;
      }

      const response = await cadastraProduto(
        empresa,
        codigo,
        tipoProduto,
        tipoEspecifico,
        descricaoProduto,
        ncm,
        valorCusto,
        unidadeMedidaComercial,
        unidadeMedidaTributaria,
        origemMaterial,
        descricaoNcm,
        cest,
        ean,
        eanTributario,
        codigoBeneficioFiscal,
        valorUnitarioComercial,
        valorUnitarioTributario,
        pesoLiquido,
        pesoBruto,
        percentualImpostos,
        extensaoIpi,
        valorTotalTributos,
        nfe,
        blocked ? 1 : 0,
        file !== "" ? file : null,
        utilizaIssqn ? 1 : 0,
        icms,
        newObjIcms,
        objIssqn,
        categoriaPadrao,
        pis,
        aliquotaPis,
        cstPis,
        cofins,
        aliquotaCofins,
        cstCofins,
        null,
        estoque,
        unidadeMedidaComercialDescricao,
        unidadeMedidaTributariaDescricao
      );

      setLoading(false);

      if (response.status === 200 || response.status === 201) {
        setCallbackMessage("Produto cadastrado!");
        setCallbackDuration(2000);
        setCallbackType("success");
        setCallbackShown(true);
        return;
      }
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        return;
      } else {
        setCallbackMessage(response.data.message);
        setCallbackType("error");
        setCallbackErrorList(Object.values(response.data.error));
        setCallbackShown(true);
        return;
      }
    }

    const response = await cadastraProduto(
      empresa,
      codigo,
      tipoProduto,
      tipoEspecifico,
      descricaoProduto,
      ncm,
      valorCusto,
      unidadeMedidaComercial,
      unidadeMedidaTributaria,
      origemMaterial,
      descricaoNcm,
      cest,
      ean,
      eanTributario,
      codigoBeneficioFiscal,
      valorUnitarioComercial,
      valorUnitarioTributario,
      pesoLiquido,
      pesoBruto,
      percentualImpostos,
      extensaoIpi,
      valorTotalTributos,
      nfe,
      blocked ? 1 : 0,
      file,
      utilizaIssqn ? 1 : 0,
      icms,
      objIcms,
      objIssqn,
      categoriaPadrao,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      estoque,
      unidadeMedidaComercialDescricao,
      unidadeMedidaTributariaDescricao
    );

    setLoading(false);

    if (response.status === 200 || response.status === 201) {
      setCallbackMessage("Produto cadastrado!");
      setCallbackDuration(2000);
      setCallbackType("success");
      setCallbackShown(true);
      return;
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      return;
    } else {
      setCallbackMessage(response.data.message);
      setCallbackType("error");
      setCallbackErrorList(Object.values(response.data.error));
      setCallbackShown(true);
      return;
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([
        loadTipo(),
        loadTipoEspecifico(),
        loadCategoriaPadrao(),
        loadUnidadeComercial(),
        loadUnidadeTributaria(),
        loadOrigemMaterial(),
        loadCfop(),
        loadIcms(),
        loadListBci(),
        loadProductInfo(idProduto),
        loadPisConfins(),
      ]);
      setLoading(false);
    })();
    //eslint-disable-next-line
  }, [
    loadTipoEspecifico,
    loadCategoriaPadrao,
    loadUnidadeComercial,
    loadListBci,
    loadUnidadeTributaria,
    loadCfop,
    loadOrigemMaterial,
    loadTipo,
    loadIcms,
    loadNcm,
  ]);

  useEffect(() => {
    if (ncm) {
      loadCest();
    }
  }, [ncm]);

  useEffect(() => {
    if (
      listMedidaComercial?.length > 0 &&
      idProduto &&
      !unidadeMedidaComercialDescricao &&
      unidadeMedidaComercial
    ) {
      const unidadeMedidaComercialDescricaoAux = listMedidaComercial?.find(
        (item) => item.id === unidadeMedidaComercial
      )?.nome;
      setUnidadeMedidaComercialDescricao(unidadeMedidaComercialDescricaoAux);
    }
  }, [listMedidaComercial, unidadeMedidaComercial]);

  useEffect(() => {
    if (
      listMedidaTributaria?.length > 0 &&
      idProduto &&
      !unidadeMedidaTributariaDescricao &&
      unidadeMedidaTributaria
    ) {
      const unidadeMedidaTributariaDescricaoAux = listMedidaTributaria?.find(
        (item) => item.id === unidadeMedidaTributaria
      )?.nome;
      setUnidadeMedidaTributariaDescricao(unidadeMedidaTributariaDescricaoAux);
    }
  }, [listMedidaTributaria, unidadeMedidaTributaria]);

  useEffect(() => {
    if (!idProdutoParams) {
      const empresaStorage = getEmpresaPagina("produtos");
      if (empresaStorage) {
        setEmpresa(empresaStorage);
      }
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <div className="session-container user-register-container">
        <span className="session-container-header">
          <form className={classes.formContainer}>
            <section className={classes.sectionOne}>
              <div className={classes.sectionOneHeader}>
                <h1 className={classes.sectionTitle}>1. Dados do Produto</h1>
                <div className={classes.switchArea}>
                  <span className={classes.switchLabel}>
                    Bloqueado / Inativo?
                  </span>
                  <div className={classes.switchContainer}>
                    <span className={blocked ? classes.false : classes.true}>
                      Não
                    </span>
                    <Switch checked={blocked} onClick={handleSwitch} />
                    <span className={blocked ? classes.true : classes.false}>
                      Sim
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.sectionOneFields}>
                <div className={classes.empresaSelectContainer}>
                  <Select
                    styleType="form"
                    label="Empresa"
                    title="Selecione uma empresa"
                    initialValue={
                      empresa !== undefined
                        ? listEmpresas.find((item) => item.id === empresa)?.nome
                        : ""
                    }
                    required
                    list={listEmpresas}
                    callback={handleChangeEmpresa}
                  />
                </div>
                <div className={classes.codigoInputContainer}>
                  <Input
                    label="Código"
                    title={codigo !== "" ? "" : "Código do produto"}
                    required
                    value={codigo}
                    handleInputChange={(event) =>
                      handleChange(event.target.value, setCodigo)
                    }
                  />
                </div>
                <div className={classes.tipoProdutoInputContainer}>
                  <Select
                    styleType="form"
                    label="Tipo de Material"
                    title="Selecione uma opção"
                    initialValue={
                      tipoProduto !== undefined
                        ? listTipo.find((item) => item.id === tipoProduto)?.nome
                        : ""
                    }
                    required
                    list={listTipo}
                    callback={(id) => handleChange(id, setTipoProduto)}
                  />
                </div>
                <div className={classes.tipoEspecificoInputContainer}>
                  <Select
                    styleType="form"
                    label="Tipo de Material Específico"
                    title="Selecione uma opção"
                    required
                    initialValue={
                      tipoEspecifico !== undefined
                        ? listTipoEspecifico.find(
                            (item) => item.id === tipoEspecifico
                          )?.nome
                        : ""
                    }
                    list={listTipoEspecifico}
                    callback={(id) => handleChange(id, setTipoEspecifico)}
                  />
                </div>
                <div className={classes.descricaoInputContainer}>
                  <Input
                    label="Descrição do Produto"
                    title={descricaoProduto !== "" ? "" : "Detalhes do produto"}
                    required
                    value={descricaoProduto}
                    handleInputChange={(event) =>
                      handleChange(event.target.value, setDescricaoProduto)
                    }
                  />
                </div>
                <div className={classes.categoriaInputContainer}>
                  <Select
                    styleType="form"
                    label="Categoria do Produto"
                    title="Selecione uma categoria"
                    required
                    initialValue={
                      categoriaPadrao !== undefined
                        ? listCategoriaPadrao.find(
                            (item) => item.id === categoriaPadrao
                          )?.nome
                        : ""
                    }
                    list={listCategoriaPadrao}
                    callback={(id) => handleChange(id, setCategoriaPadrao)}
                  />
                </div>
                <div className={classes.ncmInputContainer}>
                  <AutocompleteSelect
                    data={listNcm}
                    styleType="form"
                    label="NCM"
                    placeholder="Selecione uma opção"
                    required
                    getOptionLabel={(option) => option.nome}
                    key={(option) => option.id}
                    initialValue={
                      ncm !== undefined
                        ? listNcm.find((item) => item.id === ncm)?.nome
                        : ""
                    }
                    handleSelectedValues={(values) =>
                      handleChange(values, setNcm)
                    }
                    multiple={false}
                  />
                </div>

                <div className={classes.cestInputContainer}>
                  <Select
                    styleType="form"
                    label="CEST"
                    title="Selecione uma opção"
                    required
                    initialValue={
                      cest !== undefined
                        ? listCest.find((item) => item.id === cest)?.nome
                        : ""
                    }
                    list={listCest}
                    callback={(id) => handleChange(id, setCest)}
                  />
                  {/* <Input
                    label="CEST"
                    tipo="cest"
                    value={cest}
                    title={
                      cest !== ""
                        ? ""
                        : "Código Especificador da Substituição Tributária"
                    }
                    handleInputChange={(event) =>
                      handleChange(event.target.value, setCest)
                    }
                  /> */}
                </div>
                <div className={classes.descricaoNcmInputContainer}>
                  <Textarea
                    label="Descrição do NCM"
                    value={descricaoNcm}
                    handleInputChange={(event) =>
                      handleChange(event.target.value, setDescricaoNcm)
                    }
                  />
                </div>
                <div className={classes.eanInputContainer}>
                  <Input
                    label="EAN"
                    required
                    title={ean !== "" ? "" : "Código de barras"}
                    value={ean}
                    handleInputChange={(event) =>
                      handleChange(event.target.value, setEan)
                    }
                  />
                </div>
                <div className={classes.eanTributarioInputContainer}>
                  <Input
                    label="EAN Tributário"
                    required
                    title={
                      eanTributario !== "" ? "" : "Código de barras tributário"
                    }
                    value={eanTributario}
                    handleInputChange={(event) =>
                      handleChange(event.target.value, setEanTributario)
                    }
                  />
                </div>
                <div className={classes.codigoBeneficioFiscalInputContainer}>
                  <Input
                    label="Código de Benefício Fiscal"
                    title={
                      codigoBeneficioFiscal !== ""
                        ? ""
                        : "Código utilizado pela UF"
                    }
                    value={codigoBeneficioFiscal}
                    handleInputChange={(event) =>
                      handleChange(event.target.value, setCodigoBeneficioFiscal)
                    }
                  />
                </div>
              </div>
            </section>

            <section className={classes.sectionTwo}>
              <h1 className={classes.sectionTitle}>2. Valores e Tributos</h1>

              <div
                className={classes.valorTotalTributosContainer}
                style={{ marginBottom: "18px" }}
              >
                <Input
                  label="Valor de Custo"
                  tipo="dinheiro"
                  value={valorCusto}
                  handleInputChange={(event) =>
                    handleChange(event.value, setValorCusto)
                  }
                />
              </div>

              <div className={classes.sectionTwoFields}>
                <div className={classes.unidadeMedidaComercialContainer}>
                  <Select
                    list={listMedidaComercial}
                    styleType="form"
                    label="Unidade de medida comercial"
                    title="Selecione uma opção"
                    initialValue={
                      unidadeMedidaComercial !== undefined
                        ? listMedidaComercial.find(
                            (item) => item.id === unidadeMedidaComercial
                          )?.nome
                        : ""
                    }
                    required
                    callback={(id) => {
                      handleChange(id, setUnidadeMedidaComercial);
                      const idAux = listMedidaComercial?.find(
                        (item) => item.id === id
                      )?.nome;
                      handleChange(idAux, setUnidadeMedidaComercialDescricao);
                    }}
                  />
                </div>
                <div className={classes.valorUnitarioComercialContainer}>
                  <Input
                    label="Valor unitário comercial"
                    tipo="dinheiro"
                    title={valorUnitarioComercial !== "" ? "" : "R$"}
                    value={valorUnitarioComercial}
                    handleInputChange={(event) =>
                      handleChange(event.value, setValorUnitarioComercial)
                    }
                  />
                </div>
                <div className={classes.unidadeMedidaTributariaComercial}>
                  <Select
                    list={listMedidaTributaria}
                    styleType="form"
                    label="Unidade de medida tributária"
                    title="Selecione uma opção"
                    required
                    initialValue={
                      unidadeMedidaTributaria !== undefined
                        ? listMedidaTributaria.find(
                            (item) => item.id === unidadeMedidaTributaria
                          )?.nome
                        : ""
                    }
                    callback={(id) => {
                      handleChange(id, setUnidadeMedidaTributaria);
                      const idAux = listMedidaTributaria?.find(
                        (item) => item.id === id
                      )?.nome;
                      handleChange(idAux, setUnidadeMedidaTributariaDescricao);
                    }}
                  />
                </div>
                <div className={classes.valorUnitarioTributarioContainer}>
                  <Input
                    label="Valor unitário tributário"
                    tipo="dinheiro"
                    title={valorUnitarioTributario !== "" ? "" : "R$"}
                    value={valorUnitarioTributario}
                    handleInputChange={(event) =>
                      handleChange(event.value, setValorUnitarioTributario)
                    }
                  />
                </div>
                <div className={classes.pesoLiquidoContainer}>
                  <Input
                    label="Peso líquido"
                    tipo="decimal"
                    value={pesoLiquido}
                    handleInputChange={(event) =>
                      handleChange(event.target.value, setPesoLiquido)
                    }
                  />
                </div>
                <div className={classes.pesoBrutoContainer}>
                  <Input
                    label="Peso bruto"
                    tipo="decimal"
                    value={pesoBruto}
                    handleInputChange={(event) =>
                      handleChange(event.target.value, setPesoBruto)
                    }
                  />
                </div>
                <div className={classes.origemMaterialContainer}>
                  <Select
                    list={listOrigemMaterial}
                    styleType="form"
                    label="Origem do Material"
                    title="Selecione uma opção"
                    initialValue={
                      listOrigemMaterial.find(
                        (item) => item.id === origemMaterial
                      )
                        ? listOrigemMaterial.find(
                            (item) => item.id === origemMaterial
                          )?.nome
                        : ""
                    }
                    required
                    callback={(id) => handleChange(id, setOrigemMaterial)}
                  />
                </div>
                <div className={classes.percentualImpostosContainer}>
                  <Input
                    label="Percentual de impostos (%)"
                    tipo="percentage"
                    value={percentualImpostos}
                    handleInputChange={(event) =>
                      handleChange(event.target.value, setPercentualImpostos)
                    }
                  />
                </div>
                <div className={classes.valorTotalTributosContainer}>
                  <Input
                    label="Valor Total dos Tributos"
                    tipo="dinheiro"
                    value={valorTotalTributos}
                    handleInputChange={(event) =>
                      handleChange(event.value, setValorTotalTributos)
                    }
                  />
                </div>
                <div className={classes.utilizaTabelaContainer}>
                  <div className="label">Utiliza tabela IBPT?</div>
                  <div className={classes.switchTabela}>
                    <div className={classes.switchTabelaLabel}>Não</div>
                    <Switch
                      checked={utilizaTabela}
                      onClick={handleSwitchTabela}
                    />
                    <div className={classes.switchTabelaLabel}>Sim</div>
                  </div>
                </div>
                <div className={classes.extensaoTabelaContainer}>
                  {utilizaTabela && (
                    <Input
                      label="Extensão da Tabela IPI"
                      title="Impostos sobre Produtos Industrializados"
                      value={extensaoIpi}
                      handleInputChange={(event) =>
                        handleChange(event.target.value, setExtensaoIpi)
                      }
                      labelSuffix={{
                        icon: "info",
                        title:
                          "Informar a extensão do código NCM para produtos nos " +
                          "quais possuem alíquota de IPI diferenciada. " +
                          'Exemplo: "01", consulte o suporte Contabilhub caso esteja com dúvidas.',
                        onClick: () => {},
                      }}
                    />
                  )}
                </div>
                <div className={classes.issqnContainer}>
                  <div className="label">ISSQN ou ICMS?</div>
                  <div className={classes.switchTabela}>
                    <div className={classes.switchTabelaLabel}>ISSQN</div>
                    <Switch
                      isBoolean={false}
                      checked={!utilizaIssqn}
                      onClick={() => setUtilizaIssqn((prev) => !prev)}
                    />
                    <div className={classes.switchTabelaLabel}>ICMS</div>
                  </div>
                </div>
                <div className={classes.paramsIcmsContainer}>
                  {!utilizaIssqn && (
                    <Select
                      list={listIcms}
                      styleType="form"
                      label="Definir parâmetros de ICMS"
                      required
                      initialValue={
                        icms !== undefined
                          ? listIcms.find((item) => item.id === icms)?.nome
                          : ""
                      }
                      title="Selecione um tipo de ICMS"
                      callback={(id) => setIcms(id)}
                    />
                  )}
                  <Button
                    text="Cadastrar parâmetros"
                    className="secondary-outline"
                    onClick={async () => await handleCadastrarParametros()}
                  />
                </div>
              </div>

              <div className={classes.sectionThreeFields}>
                {/* PIS */}
                <div className={classes.cst}>
                  <Select
                    list={listPisConfins}
                    styleType="form"
                    label="CST PIS"
                    title="Selecione uma opção"
                    initialValue={
                      unidadeMedidaComercial !== undefined
                        ? listPisConfins.find((item) => item.id === cstPis)
                            ?.nome
                        : ""
                    }
                    callback={(id) => handleChange(id, setCstPis)}
                  />
                </div>

                <div className={classes.pis}>
                  <Input
                    label="Base PIS"
                    tipo="decimal"
                    value={pis}
                    handleInputChange={(event) => {
                      console.log(event.value);
                      console.log(event.target.value);
                      handleChange(event.target.value, setPis);
                    }}
                  />
                </div>

                <div className={classes.aliquotaPis}>
                  <Input
                    label="Aliquota PIS"
                    value={aliquotaPis}
                    tipo="percentage"
                    handleInputChange={(event) =>
                      handleChange(event.target.value, setAliquotaPis)
                    }
                  />
                </div>

                {/* COFINS */}
                <div className={classes.cstCofins}>
                  <Select
                    list={listPisConfins}
                    styleType="form"
                    label="CST COFINS"
                    title="Selecione uma opção"
                    initialValue={
                      unidadeMedidaComercial !== undefined
                        ? listPisConfins.find((item) => item.id === cstCofins)
                            ?.nome
                        : ""
                    }
                    callback={(id) => handleChange(id, setCstCofins)}
                  />
                </div>

                <div className={classes.cofins}>
                  <Input
                    label="Base COFINS"
                    tipo="decimal"
                    value={cofins}
                    handleInputChange={(event) =>
                      handleChange(event.target.value, setCofins)
                    }
                  />
                </div>

                <div className={classes.aliquotaCofins}>
                  <Input
                    label="Aliquota COFINS"
                    value={aliquotaCofins}
                    tipo="percentage"
                    handleInputChange={(event) =>
                      handleChange(event.target.value, setAliquotaCofins)
                    }
                  />
                </div>
              </div>
            </section>

            <section className={classes.sectionThree}>
              <h1 className={classes.sectionTitle}>
                3. CFOP PADRÃO PARA SUAS EMISSÕES
              </h1>
              <h3 className={classes.sectionSubtitle}>
                Código Fiscal de Operações e Prestações das entradas e saídas de
                mercadorias
              </h3>
              <div>
                <Select
                  list={listNfe}
                  styleType="form"
                  label="NF-e"
                  title="Selecione uma opção"
                  initialValue={
                    nfe !== undefined
                      ? listNfe.find((item) => item.id === nfe)?.nome
                      : ""
                  }
                  callback={(id) => handleChange(id, setNfe)}
                />
              </div>
            </section>

            <section className={classes.sectionFour}>
              <h1 className={classes.sectionTitle}>4. Estoque do produto</h1>
              <div className={classes.sectionFourFields}>
                <Input
                  label="Atualize o estoque do produto"
                  value={estoque}
                  handleInputChange={(event) => setEstoque(event.target.value)}
                />
              </div>
            </section>

            <section className={classes.sectionFour}>
              <h1 className={classes.sectionTitle}>5. Imagem</h1>
              <div className={classes.sectionFourFields}>
                {!thumbnail && !loadedImage ? (
                  <InputFile
                    title="Foto"
                    cbFileObj={(file) => handleChange(file, setThumbnail)}
                    onChange={(file) => setFile(file)}
                  />
                ) : (
                  <div className={classes.imgArea}>
                    <div className={classes.imageContainer}>
                      <img src={loadedImage || preview} alt="Produto" />
                    </div>
                    <div className={classes.buttonArea}>
                      <Button
                        icone="download-thin"
                        className={`outline-no-hover ${classes.downloadImageButton}`}
                        text="Download da imagem"
                        onClick={handleDownloadFile}
                      />
                      <button
                        type="button"
                        className={classes.removeImageButton}
                        onClick={handleRemoveFile}
                      >
                        <img src={trashIcon} alt="Remover" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            type="button"
            text="Salvar"
            className="btn-success"
            onClick={handleSubmit}
          />
        </span>
      </div>
      <CallbackMessage
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        type={callbackType}
        handleClose={handleClose}
      />
      {openModalIssqn && (
        <ModalIssqn
          open={openModalIssqn}
          idEmpresa={empresa}
          callback={callbackIssqn}
          onClose={() => setOpenModalIssqn(false)}
          data={objIssqn || impostos}
        />
      )}
      {openModalIcms00 && (
        <ModalIcms00
          open={openModalIcms00}
          listBci={listBci}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcms00(false))
          }
          onClose={() => setOpenModalIcms00(false)}
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcms10 && (
        <ModalIcms10
          open={openModalIcms10}
          listBci={listBci}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcms10(false))
          }
          onClose={() => setOpenModalIcms10(false)}
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcms20 && (
        <ModalIcms20
          open={openModalIcms20}
          listBci={listBci}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcms20(false))
          }
          onClose={() => setOpenModalIcms20(false)}
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcms30 && (
        <ModalIcms30
          open={openModalIcms30}
          listBci={listBci}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcms30(false))
          }
          onClose={() => setOpenModalIcms30(false)}
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcms404150 && (
        <ModalIcms404150
          open={openModalIcms404150}
          onClose={() => setOpenModalIcms404150(false)}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcms404150(false))
          }
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcms51 && (
        <ModalIcms51
          open={openModalIcms51}
          onClose={() => setOpenModalIcms51(false)}
          listBci={listBci}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcms51(false))
          }
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcms60 && (
        <ModalIcms60
          open={openModalIcms60}
          onClose={() => setOpenModalIcms60(false)}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcms60(false))
          }
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcms70 && (
        <ModalIcms70
          open={openModalIcms70}
          onClose={() => setOpenModalIcms70(false)}
          listBci={listBci}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcms70(false))
          }
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcms90 && (
        <ModalIcms90
          open={openModalIcms90}
          onClose={() => setOpenModalIcms90(false)}
          listBci={listBci}
          listBcimsst={listBci}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcms90(false))
          }
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcmssn101 && (
        <ModalIcmssn101
          open={openModalIcmssn101}
          onClose={() => setOpenModalIcmssn101(false)}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcmssn101(false))
          }
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcmssn102 && (
        <ModalIcmssn102
          open={openModalIcmssn102}
          onClose={() => setOpenModalIcmssn102(false)}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcmssn102(false))
          }
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcmssn201 && (
        <ModalIcmssn201
          open={openModalIcmssn201}
          onClose={() => setOpenModalIcmssn201(false)}
          listBci={listBci}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcmssn201(false))
          }
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcmssn202 && (
        <ModalIcmssn202
          open={openModalIcmssn202}
          onClose={() => setOpenModalIcmssn202(false)}
          listBci={listBci}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcmssn202(false))
          }
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcmssn500 && (
        <ModalIcmssn500
          open={openModalIcmssn500}
          onClose={() => setOpenModalIcmssn500(false)}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcmssn500(false))
          }
          data={objIcms || impostos}
          listCstCsosn={listCstCsosn}
        />
      )}
      {openModalIcmssn900 && (
        <ModalIcmssn900
          open={openModalIcmssn900}
          onClose={() => setOpenModalIcmssn900(false)}
          callback={(value) =>
            callbackIcms(value, () => setOpenModalIcmssn900(false))
          }
          data={objIcms || impostos}
          listBci={listBci}
          listCstCsosn={listCstCsosn}
        />
      )}
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
};

export default CadastroProdutos;
