import React, { useState } from "react";
import { Accordion } from "../../../../components/AccordionComponent";
import { Grid } from "@material-ui/core";
import { formataNumero } from "../../../../utils/functions";

export function AccordionDreMobile({ dados, columns = [] }) {
  const [expanded, setExpanded] = useState(false);
  const [expandedCategoria, setExpandedCategoria] = useState(false);
  const [expandedSubcategoria, setExpandedSubcategoria] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeCategoria = (panel) => (event, isExpanded) => {
    setExpandedCategoria(isExpanded ? panel : false);
  };

  const handleChangeSubcategoria = (panel) => (event, isExpanded) => {
    setExpandedSubcategoria(isExpanded ? panel : false);
  };

  return (
    <>
      {dados.map((item, index) => {
        const values = columns.map((itemCol) => {
          const periodos = item.periodos;

          return formataNumero(periodos[itemCol.id]);
        });
        item.descricao = item.nome;
        item.total = formataNumero(item.saldo);
        const categorias = item.categorias || [];

        return (
          <div key={`summary_${index}`}>
            <Accordion.Root
              handleChange={handleChange}
              index={index}
              expanded={expanded}
            >
              <Accordion.Header
                expanded={expanded}
                index={index}
                item={item}
                columns={values}
                mobile
                showTipoCategoria
              />

              <Accordion.Body>
                {categorias.map((itemCategoria, indexCategoria) => {
                  const valuesCategoria = columns.map((itemCol) => {
                    const periodos = itemCategoria.periodos;

                    return formataNumero(periodos[itemCol.id]);
                  });
                  itemCategoria.descricao = itemCategoria.nome;
                  itemCategoria.total = formataNumero(itemCategoria.saldo);
                  const subcategorias = itemCategoria.subcategorias || [];

                  return (
                    <Accordion.Root
                      handleChange={handleChangeCategoria}
                      index={indexCategoria}
                      expanded={expandedCategoria}
                      child={true}
                      key={`categoria_${indexCategoria}`}
                    >
                      <Accordion.Header
                        expanded={expandedCategoria}
                        index={indexCategoria}
                        item={itemCategoria}
                        columns={valuesCategoria}
                        mobile
                        showTipoCategoria
                      />

                      <Accordion.Body>
                        {subcategorias.map((itemSubcat, indexSubcat) => {
                          itemSubcat.descricao = itemSubcat.nome;

                          return (
                            <Accordion.Root
                              handleChange={handleChangeSubcategoria}
                              index={indexSubcat}
                              expanded={expandedSubcategoria}
                              borderChild={true}
                              child={true}
                              key={`subcategoria_${indexSubcat}`}
                            >
                              <Accordion.Header
                                expanded={expandedSubcategoria}
                                index={indexSubcat}
                                item={itemSubcat}
                                mobile
                                showTipoCategoria
                              />

                              <Accordion.Body>
                                <Grid container>
                                  <Grid
                                    item
                                    container
                                    xs={12}
                                    style={{ paddingLeft: 8 }}
                                    className="container-mobile-header"
                                  >
                                    <Grid
                                      item
                                      xs={4}
                                      className="inner-font-title"
                                      style={{ padding: 0 }}
                                    >
                                      <div>Tipo</div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      className="inner-font-title"
                                      style={{ padding: 0 }}
                                    >
                                      <div style={{ textAlign: "center" }}>
                                        Período
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      style={{ padding: 0 }}
                                      className="inner-font-title right"
                                    >
                                      <div style={{ textAlign: "center" }}>
                                        Valor
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    xs={12}
                                    spacing={1}
                                    style={{ padding: 16 }}
                                  >
                                    {columns.map((itemCol, indexCol) => {
                                      const periodos = itemSubcat.periodos;

                                      return (
                                        <Grid
                                          item
                                          container
                                          xs={12}
                                          key={`col_sub_${indexCol}`}
                                          style={{ padding: 0 }}
                                        >
                                          <Grid
                                            item
                                            xs={4}
                                            className="inner-cell-table-fluxo"
                                            style={{ padding: "0 !important" }}
                                          >
                                            <div>
                                              {itemSubcat.tipo_subcategoria}
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={4}
                                            className="inner-cell-table-fluxo"
                                            style={{ padding: "0 !important" }}
                                          >
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              {itemCol.nome}
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={4}
                                            className="inner-cell-table-fluxo right"
                                            style={{ padding: "0 !important" }}
                                          >
                                            <div style={{ textAlign: "right" }}>
                                              {formataNumero(
                                                periodos[itemCol.id]
                                              )}
                                            </div>
                                          </Grid>
                                        </Grid>
                                      );
                                    })}
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    spacing={3}
                                    className="container-total-categoria"
                                  >
                                    <Grid
                                      item
                                      xs={5}
                                      className="inner-font-title"
                                    ></Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      className="inner-font-title total-title"
                                    >
                                      <div>Total</div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      justifyContent="flex-end"
                                      className="inner-cell-table-fluxo right font-title-total-mobile"
                                    >
                                      {formataNumero(itemSubcat.saldo)}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Accordion.Body>
                            </Accordion.Root>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Root>
                  );
                })}
              </Accordion.Body>
            </Accordion.Root>
            <div className="space-vertical" />
          </div>
        );
      })}
    </>
  );
}
