import React from "react";
import { AccordionSummary, Grid } from "@material-ui/core";
import { ArrowForwardIos, ExpandMore } from "@material-ui/icons";

export function AccordionHeader({
  expanded,
  index,
  item,
  columns = [],
  mobile = false,
  showTipoCategoria = false,
  rowSpan = false,
  numbers = true,
  marginRight = null,
  widthFirstColumn = null,
  customDescriptionClass = "name-categoria",
  customTotalClass = "",
  customContainerClass = "",
  activeOpen = true,
  hideTotalOnOpen = true
}) {
  return (
    <AccordionSummary>
      <Grid
        className={`container-row ${customContainerClass}`}
        style={{
          paddingRight: mobile ? 0 : 24,
        }}
      >
        <Grid item className="">
          <div className="accordion-title">
            {activeOpen ? (
              <>
                {expanded === `panel${index}` ? (
                  <ExpandMore className="font-orange" />
                ) : (
                  <ArrowForwardIos
                    className="font-orange"
                    style={{ fontSize: 15, padding: 4 }}
                  />
                )}
                <span className={`font-title ${customDescriptionClass}`}>
                  {item.descricao}
                </span>
              </>
            ) : null}
          </div>
        </Grid>
        {!mobile && showTipoCategoria ? (
          <Grid
            item
            container
            alignItems="center"
            className="name-tipo-categoria"
          ></Grid>
        ) : null}

        {!mobile && rowSpan ? (
          <Grid
            item
            container
            alignItems="center"
            className="name-tipo-categoria"
          >
            {item.descricaoRowspan}
          </Grid>
        ) : null}
        {!mobile
          ? columns.map((itemColumn, indexColumn) => {
              return (
                <Grid
                  item
                  key={indexColumn}
                  className={`cell-table ${numbers ? "keep-number" : ""} ${
                    rowSpan ? "item-rowspan" : ""
                  }`}
                  style={{
                    ...(marginRight ? { marginRight } : {}),
                    ...(widthFirstColumn && indexColumn === 0
                      ? { width: widthFirstColumn }
                      : {}),
                  }}
                >
                  {itemColumn}
                </Grid>
              );
            })
          : null}
        {!mobile ? (
          <Grid
            item
            style={(hideTotalOnOpen && expanded) ?{
              display: 'none'
            } : {}}
            className={`name-column total-column right ${
              rowSpan ? "item-rowspan" : ""
            } ${customTotalClass}`}
          >
            {item.total}
          </Grid>
        ) : null}
      </Grid>
    </AccordionSummary>
  );
}
