import Axios from "./Axios";

const gerentesService = {
  consultaGerentes: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null
  ) => {
    const response = await Axios.get("/lista-gerente", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm
      }
    })
      .then(({ ...response }) => {
        console.log(response);
        return response;
      })
      .catch(({ response }) => {
        console.log(response);

        return response;
      });

    return response;
  },
  consultaGerente: async id => {
    const response = await Axios.get(`/consulta-gerente/${id}`)
      .then(({ ...response }) => {
        console.log(response);
        return response;
      })
      .catch(({ response }) => {
        console.log(response);

        return response;
      });

    return response;
  },
  cadastraGerente: async form => {
    const response = await Axios.post("/cadastra-gerente", form)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  atualizaGerente: async (id, form) => {
    const response = await Axios.post(`/atualiza-gerente/${id}`, form)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  selecionaGerente: async () => {
    const response = await Axios.get(`/seleciona-gerente`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  removeGerente: async id => {
    const response = await Axios.get(`/remover-gerente/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  }
};

export default gerentesService;
