export default () => ({
  dayWrapper: {
    position: "relative"
  },
  day: {
    width: 36,
    height: 36,
    fontSize: 14,
    margin: "0 2px",
    color: "inherit"
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: "1px solid #000000",
    borderRadius: "50%"
  },
  nonCurrentMonthDay: {
    color: "#676767"
  },
  highlightNonCurrentMonthDay: {
    color: "#676767"
  },
  highlight: {
    background: "#4f4f4f",
    color: "#fff"
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%"
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%"
  },
  monthWrapper: {
    '& .MuiInputBase-root	': {
      borderBottom: "none !important",
      border: "none !important"
    },
    '& .MuiInput-input': {
      borderBottom: "none !important",
    }
  }
});
