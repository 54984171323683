import Axios from "./Axios";

const contasReceber = {
  getContas: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    data_inicial,
    data_final,
    id_empresa
  ) => {
    const result = await Axios.get("/lista-conta-a-receber", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        data_inicial,
        data_final,
        id_empresa,
      },
    })
      .then(({ ...response }) => {
        console.log(response);
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  exportarRelatorio: async (
    data_inicial = null,
    data_final = null,
    id_empresa = null
  ) => {
    const response = await Axios.get("/exportar-contas-a-receber", {
      params: {
        data_inicial,
        data_final,
        id_empresa,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  enviarEmailRelatorio: async ({
    assunto = null,
    mensagem = null,
    emails = [],
    cco = [],
    id_empresa = null,
    data_inicial = null,
    data_final = null,
  }) => {
    const response = await Axios.get("/exportar-contas-a-receber-email", {
      params: {
        id_empresa,
        data_inicial,
        data_final,
        assunto,
        mensagem,
        emails,
        cco,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
};

export default contasReceber;
