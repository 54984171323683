import { useEffect, useState, useContext } from "react";
import { NavbarContext } from "../contexts/NavbarContext";

const SmallScreen = () => {
  const { setShowNavbar } = useContext(NavbarContext);
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setShowNavbar(false);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
    //eslint-disable-next-line
  }, []);

  return (width <= 1100);
}

export default SmallScreen;