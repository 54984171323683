import Axios from "./Axios";

export const dreOrcadoRealizadoService = {
  getDre: async ({ id_empresa, ano, categorias, meses }) => {
    const response = await Axios.get("/lista-dre-orcado-realizado", {
      params: {
        id_empresa,
        ano,
        categorias,
        meses,
      },
    })
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  getDreGraficoHome: async ({ id_empresa, periodo }) => {
    const response = await Axios.get("/lista-dre-orcado-realizado-grafico", {
      params: {
        id_empresa,
        periodo,
      },
    })
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  selecionaPeriodo: async () => {
    const response = await Axios.get(
      "/seleciona-periodo-dre-orcado-realizado-grafico"
    )
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  enviarEmailRelatorio: async ({
    assunto = null,
    mensagem = null,
    emails = [],
    cco = [],
    id_empresa = null,
    meses = null,
    ano = null,
    categorias = null,
  }) => {
    const response = await Axios.get(
      "/exportar-relatorio-dre-orcado-realizado-email",
      {
        params: {
          id_empresa,
          ano,
          meses,
          categorias,
          assunto,
          mensagem,
          emails,
          cco,
        },
      }
    )
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
};
