import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./styles.scss";
import useAuth from "../../hooks/useAuth";
import usuariosService from "../../services/usuariosService";

export default function ModalAvisoInstabilidade() {
  const { authenticated } = useAuth();
  const { consultaUsuarioLogado } = usuariosService;
  const [open, setOpen] = useState(false);

  const onClose = useCallback(
    async (props, reason) => {
      if (reason === "backdropClick" || reason === "escapeKeyDown") return;
      window.localStorage.setItem("nao_exibir_modal_instabilidade_v2", 1);
      setOpen(false);
    },
    [setOpen]
  );

  async function exibeModal() {
    const response = await consultaUsuarioLogado();
    if (response?.status !== 401) {
      const currentDate = new Date();
      const localStorageValue = window.localStorage.getItem(
        "nao_exibir_modal_instabilidade_v2"
      );
      const isDateInRange =
        currentDate >= new Date("2024-02-23") &&
        currentDate <= new Date("2024-02-25");

      const isLocalStorageNotEqualToOne = localStorageValue !== "1";
      const result = isDateInRange && isLocalStorageNotEqualToOne;
      setOpen(result);
    }
  }

  useEffect(() => {
    exibeModal();
  }, []);

  return (
    <Dialog
      open={open}
      className="modalInstabilidade"
      onClose={onClose}
      disableEscapeKeyDown={true}
    >
      <DialogContent className="modal-content">
        <IconButton
          className="botaoFecharModal"
          onClick={onClose}
          size="medium"
        >
          <CloseIcon />
        </IconButton>
        <div className="containerModal">
          <div className="containerTitle">AVISO DE INSTABILIDADE</div>
          <div className="containerSubtitle">
            <p>Prezado cliente,</p>

            <p>
              Gostaríamos de informar que a Plataforma Hub estará passando por
              uma breve manutenção programada 23/02/2024 das 19:00 às 11:00 am
              do dia 24/02/2024. Durante esse período, algumas funcionalidades
              podem ficar temporariamente indisponíveis.
            </p>

            <p>
              A manutenção é necessária para garantir que a plataforma esteja
              sempre atualizada e funcionando da melhor maneira possível,
              proporcionando a você uma experiência mais eficiente e segura.
            </p>

            <p>
              Pedimos desculpas por qualquer inconveniente que isso possa causar
              e agradecemos a sua compreensão. Caso você tenha alguma dúvida ou
              precise de assistência adicional, nossa equipe de suporte estará à
              disposição para ajudá-lo.
            </p>

            <p>
              Agradecemos a sua confiança em nossa Plataforma Hub e estamos
              empenhados em continuar oferecendo um serviço de qualidade.
            </p>

            <p>Atenciosamente,</p>

            <p>Contábil Hub.</p>
          </div>
          <div className="containerBotao">
            <button type="button" onClick={() => onClose()}>
              Continuar
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
