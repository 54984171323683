import Axios from './Axios';

const graficoService = {
  resultadOperacional: async (idEmpresa) => {
    const result = await Axios.get(
      `/resultado-operacional?empresa=${idEmpresa}`
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  selectSaldoConta: async (id_empresa) => {
    const result = await Axios.get('/seleciona-saldo-conta', {
      params: {
        id_empresa
      }
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  listaObrigacoes: async (id_empresa) => {
    const result = await Axios.get('/lista-obrigacao-home', {
      params: {
        id_empresa
      }
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};

export default graficoService;
