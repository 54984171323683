import Axios from "./Axios";

export const versaoService = {
  marcarComoVisualizada: async (idVersao) => {
    const response = await Axios.post(`visualizar-versao/${idVersao}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  consultaVersao: async (idVersao) => {
    const response = await Axios.get(`/consulta-versao/${idVersao}`)
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  listaVersoes: async () => {
    const response = await Axios.get(`/lista-versao`)
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
};
