import React, { Children, useState, useReducer, useEffect } from "react";
import { ArrowBackIos } from "@material-ui/icons";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "./styles.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";

function MyCalendar({ callback = () => {}, onMonthChange = () => {}, data, loading }) {
  // const [turno, setTurno] = useState("");
  moment.locale("pt");
  //eslint-disable-next-line
  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  const [listDays, setListDays] = useState([]);
  const localizer = momentLocalizer(moment);
  const formats = {
    weekdayFormat: (date, culture, localizer) => {
      let name = localizer.format(date, "dddd", culture).replace("-feira", "");
      return name[0].toUpperCase() + name.substring(1, name.length);
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted)
      if (data)
        setListDays([...listDays, ...data]);

    return () => mounted = false
  }, [data])

  const changeTurnoAtual = value => {
    let newTurno, turnoAtual = "";

    let list = listDays;

    let index = listDays.findIndex(item =>
      moment(value).isSame(item.data, "day")
    );
    
    if (index !== -1) turnoAtual = list[index].turno;
    
    if (turnoAtual === "") newTurno = "M";
    else if (turnoAtual === "M") newTurno = "T";
    else if (turnoAtual === "T") newTurno = "D";
    else if (turnoAtual === "D") newTurno = "";

    if (index > -1) {
      list[index] = { data: value, turno: newTurno }
    } else {
      list.push({ data: value, turno: newTurno })
    };

    setListDays(list);
    if (list.length !== 0) {
      let callbackList = list.map(item => {
        item.data = moment(item.data).format("YYYY-MM-DD");
        return item;
      });
      callback(callbackList);
    }
  };

  const backgroundPainter = value => {
    let index = listDays.findIndex(item =>
      moment(value).isSame(item.data, "day")
    );
    if (index === -1) return "transparent";

    let turnoAtual = listDays[index].turno;

    if (turnoAtual === "M") return "linear-gradient(to bottom right, #5F5F5F 49%, transparent 50%)";
    if (turnoAtual === "T") return "linear-gradient(to top left, #5F5F5F 49%, transparent 50%)";
    if (turnoAtual === "D") return "#5F5F5F";
  };

  const getTurnoAtual = value => {
    let index = listDays.findIndex(item =>
      moment(value).isSame(item.data, "day")
    );
    if (index === -1) return "";

    let turnoAtual = listDays[index].turno;

    return turnoAtual;
  };

  const ColoredDateCellWrapper = ({ children, value }) => {
    value = moment(value);
    let painter = backgroundPainter(value);
    let atualTurno = getTurnoAtual(value);
    let event = true;

    if (painter === "inset(0 0 0 0)") event = false;

    const style = {
      position: "relative",
      zIndex: 11,
      background: painter,
      flex: "1 1",
      margin: "0px 2.5px 2.5px 5px",
      cursor: "pointer"
    };

    const element = React.cloneElement(Children.only(children), {
      style: {
        backgroundColor: event ? "#5F5F5F" : "#F3F3F3",
        border: "none"
      }
    });
    return (
      <div
        style={style}
        onClick={() => {
          changeTurnoAtual(value);
          forceUpdate();
        }}
      >
        {atualTurno === "M" && (
          <div className="wrapperManha">
            <span>{atualTurno}</span>
          </div>
        )}
        {atualTurno === "T" && (
          <div className="wrapperTarde">
            <span>{atualTurno}</span>
          </div>
        )}
        {atualTurno === "D" && (
          <div className="wrapperDia">
            <span>{atualTurno}</span>
          </div>
        )}
        {element}
      </div>
    );
  };

  const CustomToolbar = toolbar => {
    const date = moment(toolbar.date);
    
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate("prev");
      
      onMonthChange({
        month: toolbar.date.getMonth(),
        year: toolbar.date.getFullYear(),
      });
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate("next");
      
      onMonthChange({
        month: toolbar.date.getMonth(),
        year: toolbar.date.getFullYear(),
      });   
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate("current");
    };

    return (
      <div className="calendar-toolbar">
        <div className="icon-wrapper" onClick={goToBack}>
          <ArrowBackIos style={{ fontWeight: 200, color: loading ? "#c2c2c2" : 'inherit' }} />
        </div>
        <div className="calendar-toolbar-title" onClick={goToCurrent}>
          {date.format("MMMM").toUpperCase() + " DE " + date.format("YYYY")}
        </div>
        <div className="icon-wrapper" onClick={goToNext}>
          <ArrowBackIos
            style={{ fontWeight: 200, transform: "rotate(180deg)", color: loading ? "#c2c2c2" : 'inherit' }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="calendar-wrapper">
      <Calendar
        events={[]}
        startAccessor="start"
        endAccessor="end"
        formats={formats}
        views={["month"]}
        localizer={localizer}
        components={{
          dateCellWrapper: ColoredDateCellWrapper,
          toolbar: CustomToolbar
        }}
      />
    </div>
  );
}

export default MyCalendar;
