import Axios from './Axios';

export default {
  cadastraProduto: async (
    id_empresa,
    codigo_produto,
    tipo_produto,
    tipo_especifico_produto,
    descricao,
    ncm,
    valor_de_custo,
    unidade_medida_comercial,
    unidade_medida_tributario,
    origem_material,
    descricao_ncm,
    cest,
    ean,
    ean_tributado,
    codigo_beneficio_fiscal,
    valor_unitario_comercial,
    valor_unitario_tributario,
    peso_liquido,
    peso_bruto,
    percentual_imposto,
    extensao_ipi,
    valor_total_tributos,
    cfop,
    bloqueado_inativo,
    imagem_produto,
    issqn,
    icms,
    objIcms,
    objIssqn,
    id_categoria_padrao,
    valor_base_calculo_pis,
    aliquota_pis,
    cst_pis,
    valor_base_calculo_cofins,
    aliquota_cofins,
    cst_cofins,
    csosn = null,
    estoque,
    medida_comercial,
    medida_tributario
  ) => {
    const response = await Axios.post('/cadastra-produto', {
      id_empresa,
      codigo_produto,
      tipo_produto,
      tipo_especifico_produto,
      descricao,
      ncm,
      unidade_medida_comercial,
      unidade_medida_tributario,
      origem_material,
      descricao_ncm,
      cest,
      ean,
      ean_tributado,
      codigo_beneficio_fiscal,
      valor_unitario_comercial,
      valor_unitario_tributario,
      peso_liquido,
      peso_bruto,
      percentual_imposto,
      extensao_ipi,
      valor_total_tributos,
      cfop,
      bloqueado_inativo,
      imagem_produto,
      issqn,
      icms,
      ...objIcms,
      ...objIssqn,
      valor_base_calculo_pis,
      aliquota_pis,
      cst_pis,
      valor_base_calculo_cofins,
      aliquota_cofins,
      cst_cofins,
      valor_de_custo,
      csosn,
      id_categoria_padrao,
      estoque,
      medida_tributario,
      medida_comercial
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selectTipo: async () => {
    const response = Axios.get('/tipo-material-produto')
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selectTipoEspecifico: async () => {
    const response = Axios.get('/tipo-material-especifico-produto')
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selectMedidaComercial: async () => {
    const response = Axios.get('/unidade-medida-produto')
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selectMedidaTributaria: async () => {
    const response = Axios.get('/unidade-medida-tributaria-produto')
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selectOrigemMaterial: async () => {
    const response = Axios.get('/origem-material-produto')
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  selectCfop: async () => {
    const response = Axios.get('/cfop-produto')
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  listaProdutos: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null
  ) => {
    const response = await Axios.get('/lista-produto', {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  removerProduto: async (id) => {
    const response = await Axios.get(`/remove-produto/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selecionaProduto: async (id) => {
    const response = await Axios.get(`/seleciona-produto/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  consultaProduto: async (id, altera_codigo = null) => {
    const response = await Axios.get(
      `/consulta-produto/${id}?altera_codigo=${altera_codigo}`
    )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  atualizaProduto: async (
    idProduto,
    id_empresa,
    codigo_produto,
    tipo_produto,
    tipo_especifico_produto,
    descricao,
    ncm,
    valor_de_custo,
    unidade_medida_comercial,
    unidade_medida_tributario,
    origem_material,
    descricao_ncm,
    cest,
    ean,
    ean_tributado,
    codigo_beneficio_fiscal,
    valor_unitario_comercial,
    valor_unitario_tributario,
    peso_liquido,
    peso_bruto,
    percentual_imposto,
    extensao_ipi,
    valor_total_tributos,
    cfop,
    bloqueado_inativo,
    imagem_produto,
    issqn,
    icms,
    origem,
    objIcms,
    objIssqn,
    id_categoria_padrao,
    valor_base_calculo_pis,
    aliquota_pis,
    cst_pis,
    valor_base_calculo_cofins,
    aliquota_cofins,
    cst_cofins,
    estoque,
    medida_comercial,
    medida_tributario
  ) => {
    const response = await Axios.post(`/atualiza-produto/${idProduto}`, {
      id_empresa,
      codigo_produto,
      tipo_produto,
      tipo_especifico_produto,
      descricao,
      ncm,
      unidade_medida_comercial,
      unidade_medida_tributario,
      origem_material,
      descricao_ncm,
      cest,
      ean,
      ean_tributado,
      codigo_beneficio_fiscal,
      valor_unitario_comercial,
      valor_unitario_tributario,
      peso_liquido,
      peso_bruto,
      percentual_imposto,
      extensao_ipi,
      valor_total_tributos,
      cfop,
      bloqueado_inativo,
      imagem_produto,
      icms,
      origem,
      ...objIcms,
      ...objIssqn,
      issqn,
      valor_base_calculo_pis,
      aliquota_pis,
      cst_pis,
      valor_base_calculo_cofins,
      aliquota_cofins,
      cst_cofins,
      valor_de_custo,
      id_categoria_padrao,
      estoque,
      medida_comercial,
      medida_tributario
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  sincronizarPedido: async (
    id_caixa,
    id_pedido,
    id_empresa,
    produtos,
    pagamento,
    identificador_cliente,
    observacao
  ) => {
    const response = await Axios.post(`/erp-sincroniza-pedido`, {
      id_caixa,
      id_pedido,
      id_empresa,
      produtos,
      pagamento,
      identificador_cliente,
      observacao
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  finalizarPedido: async (
    id_pedido,
    id_empresa,
    produtos,
    pagamento,
    identificador_cliente
  ) => {
    const response = await Axios.get(`/erp-finaliza-venda/${id_pedido}`, {
      id_empresa,
      produtos,
      pagamento,
      identificador_cliente,
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  consultaEstoque: async (id_produto) => {
    const response = await Axios.get(`/consulta-estoque-produto/${id_produto}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  definirEstoque: async (id_produto, estoque_minimo, estoque_maximo) => {
    const response = await Axios.post(`/define-limite-estoque-produto`, {
      id_produto: id_produto,
      estoque_minimo: estoque_minimo,
      estoque_maximo: estoque_maximo,
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selectPisConfins: async () => {
    const response = Axios.get('/seleciona-cst-pis-cofins')
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  exportarRelatorio: async (
    id_empresa = null,
  ) => {
    const response = await Axios.get(`/exportar-produto`, {
      params: {
        id_empresa
      }
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  }
};
