import React, { useCallback, useContext, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { FaRegBuilding } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import OverlayLoading from "../../components/OverlayLoading";

import notasFiscaisService from "../../services/notasFiscaisService";
import loginService from "../../services/loginService";

import "./styles.scss";
import { NavbarContext } from "../../contexts/NavbarContext";
import SmallScreen from "../../utils/smallScreen";
import { ModalSelecionaEmpresaHubcount } from "../ModalSelecionaEmpresaHubcount";
import CallbackMessage from "../CallbackMessage";
import empresasService from "../../services/empresasService";

function NavbarItem({ item, vertical }) {
  const { verificaModuloNf } = notasFiscaisService;
  const { setShowNavbar } = useContext(NavbarContext);
  const isSmallScreen = SmallScreen();
  const history = useHistory();
  const { selectEmpresa } = empresasService;

  const [showFolder, setShowFolder] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openModalHubcount, setOpenModalHubcount] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errors, setErrors] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState("");

  const buscaQuantidadeEmpresas = useCallback(async () => {
    setLoading(true);
    const response = await selectEmpresa();
    setLoading(false);

    if (response.status === 200) {
      return response.data?.filter((item) => item.nome !== "")?.length;
    }
    return 0;
  }, [selectEmpresa]);

  const childSpace = () => {
    let result = "";

    if (item.degree === 1) {
      result = "46px";
    } else if (item.degree > 1) {
      result = `${46 + 11.5 * item.degree}px`;
    }

    return result;
  };

  const handleShowFolder = () => {
    setShowFolder(!showFolder);
  };

  const handleShowNavbar = () => {
    if (isSmallScreen) setShowNavbar(false);
  };

  const LinkItem = () => {
    const linkItemClassNameFunc = () => {
      let result = "navbar-item navbar-item-hover";

      if (vertical) {
        if (item.degree === 0) {
          result += ` navbar-item-vertical-parent`;
        } else {
          result += ` navbar-item-vertical-children`;
        }
      } else {
        result += ` navbar-item-horizontal`;
      }

      return result;
    };

    const handleNfeCheck = async (link) => {
      setLoading(true);

      const response = await verificaModuloNf();

      if (response.data) {
        window.open(link, "_blank");
      }

      if (!response.data) {
        const chatButton = document.querySelector("#hi-chat-widget");
        chatButton.click();
      }

      setLoading(false);
    };

    async function loadDashboard(idEmpresa) {
      try {
        setLoading(true);
        setOpenModalHubcount(false);
        const response = await loginService.getLoginHubCount(idEmpresa);
        console.log(response);
        if (response?.data?.error) {
          setShowMessage(true);
          setCallbackMessage(response?.data?.error?.message || "Erro!");
          setErrors(
            response?.data?.error?.details
              ? [response?.data?.error?.details]
              : ["Erro ao gerar ao gerar o link da dashboard"]
          );
        } else if (response?.data?.result) {
          setShowMessage(true);
          setCallbackMessage("Você será direcionado para a dashboard!");
          setErrors([]);
          history.push(`/hubcount?${response?.data?.result}`);
        } else {
          setShowMessage(true);
          setCallbackMessage(response?.data?.error?.message || "Erro!");
          setErrors(
            response?.data?.error?.details
              ? [response?.data?.error?.details]
              : ["Erro ao gerar ao gerar o link da dashboard"]
          );
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }

    const handleOpenModalHubcount = () => {
      setOpenModalHubcount(true);
    };

    const handleCloseModalHubcount = () => {
      setOpenModalHubcount(false);
    };

    const switchCustomAction = async (action) => {
      switch (action) {
        case "dashboard":
          const numeroEmpresas = await buscaQuantidadeEmpresas();
          console.log({ numeroEmpresas });
          if (!numeroEmpresas || numeroEmpresas === 1) {
            const empresaUsuario =
              window.localStorage.getItem("empresa_usuario");

            loadDashboard(empresaUsuario);
          } else {
            handleOpenModalHubcount();
          }
          break;

        default:
          break;
      }
    };

    const handleClose = (event, reason) => {
      console.log(reason);
      if (reason === "clickaway") {
        setShowMessage(false);
      }
      if (reason === "timeout") {
        setShowMessage(false);
      }
    };

    return (
      <>
        {loading && <OverlayLoading />}
        <CallbackMessage
          open={showMessage}
          type={`${errors.length === 0 ? "success" : "error"}`}
          message={callbackMessage}
          errorList={errors}
          handleClose={handleClose}
          duration={errors.length === 0 ? 2000 : 6000}
        />
        {item.id === 200 ? (
          <button
            onClick={() => handleNfeCheck(item.link)}
            className="navbar-link-container navbar-link"
          >
            <span
              style={{ paddingLeft: childSpace() }}
              className={linkItemClassNameFunc()}
            >
              <span className="navbar-item-wrap">
                {item.degree === 0 ? (
                  <React.Fragment>
                    {item.icon ? (
                      <span className="navbar-item-icon">
                        {item.icon}
                        <p>{item.title}</p>
                      </span>
                    ) : (
                      <span className="navbar-item-icon">
                        <FaRegBuilding />
                        <p>{item.title}</p>
                      </span>
                    )}
                  </React.Fragment>
                ) : (
                  <span className="navbar-item-icon">
                    <p>{item.title}</p>
                  </span>
                )}
              </span>
            </span>
          </button>
        ) : item.customAction ? (
          <>
            <a
              onClick={() => switchCustomAction(item.customAction)}
              className="navbar-link-container navbar-link"
            >
              <span
                style={{ paddingLeft: childSpace() }}
                className={linkItemClassNameFunc()}
              >
                <span className="navbar-item-wrap">
                  {item.degree === 0 ? (
                    <React.Fragment>
                      {item.icon ? (
                        <span className="navbar-item-icon">
                          {item.icon}
                          <p>{item.title}</p>
                        </span>
                      ) : (
                        <span className="navbar-item-icon">
                          <FaRegBuilding />
                          <p>{item.title}</p>
                        </span>
                      )}
                    </React.Fragment>
                  ) : (
                    <span className="navbar-item-icon">
                      <p>{item.title}</p>
                    </span>
                  )}
                </span>
              </span>
            </a>
            {openModalHubcount && (
              <ModalSelecionaEmpresaHubcount
                onClose={handleCloseModalHubcount}
                onConfirm={loadDashboard}
                open={openModalHubcount}
              />
            )}
          </>
        ) : (
          <Link
            to={item.link}
            className="navbar-link-container navbar-link"
            onClick={handleShowNavbar}
          >
            <span
              style={{ paddingLeft: childSpace() }}
              className={linkItemClassNameFunc()}
            >
              <span className="navbar-item-wrap">
                {item.degree === 0 ? (
                  <React.Fragment>
                    {item.icon ? (
                      <span className="navbar-item-icon">
                        {item.icon}
                        <p>{item.title}</p>
                      </span>
                    ) : (
                      <span className="navbar-item-icon">
                        <FaRegBuilding />
                        <p>{item.title}</p>
                      </span>
                    )}
                  </React.Fragment>
                ) : (
                  <span className="navbar-item-icon">
                    <p>{item.title}</p>
                  </span>
                )}
              </span>
            </span>
          </Link>
        )}
      </>
    );
  };

  const FolderItem = () => {
    const Folder = () => {
      const folderClassNameFunc = () => {
        let result = "navbar-item navbar-item-hover";

        if (vertical) {
          result += " navbar-item-folder-vertical";
          if (item.degree === 0) {
            result += " navbar-item-vertical-parent";
          } else {
            result +=
              " navbar-item-vertical-children navbar-item-vertical-children-folder";
          }
        } else {
          result += " navbar-item-folder-horizontal";
        }

        return result;
      };

      return (
        <span
          onClick={handleShowFolder}
          style={{ paddingLeft: childSpace() }}
          className={`${folderClassNameFunc()} ${
            showFolder ? "navbar-item-open-mark" : ""
          }`}
        >
          {item.degree === 0 ? (
            <React.Fragment>
              {item.icon ? (
                <span className="navbar-item-icon">
                  {item.icon}
                  <p>{item.title}</p>
                </span>
              ) : (
                <span className="navbar-item-icon">
                  <FaRegBuilding />
                  <p>{item.title}</p>
                </span>
              )}
            </React.Fragment>
          ) : (
            <span className="navbar-item-icon">
              <p>{item.title}</p>
            </span>
          )}
          <span
            className={`${
              vertical
                ? "navbar-item-icon-folder-vertical"
                : "navbar-item-icon-folder-horizontal"
            }`}
          >
            <FiChevronDown
              className={`navbar-collapse-item ${
                !showFolder ? "navbar-close-folder" : "navbar-open-folder"
              }`}
            />
          </span>
        </span>
      );
    };

    const Children = () => (
      <ul
        className={`${
          !showFolder ? "navbar-hide-folder" : "navbar-show-folder"
        }`}
      >
        {item.children.map((element, index) => (
          <li className="navbar-item" key={index}>
            <NavbarItem item={element} vertical={vertical} />
          </li>
        ))}
      </ul>
    );

    return (
      <div
        className={`${
          vertical
            ? "navbar-list navbar-list-vertical"
            : "navbar-list navbar-item-horizontal navbar-item-horizontal-border"
        }`}
      >
        <Folder />
        <Children />
      </div>
    );
  };

  return <>{item.link ? <LinkItem /> : <FolderItem />}</>;
}

export default NavbarItem;
