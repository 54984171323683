import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore, ExpandLess, InfoOutlined } from "@material-ui/icons";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";

import Icon from "../../components/Icon";
import TableComponent, {
  Search,
} from "../../components/TableResponsiveComponent";
import OverlayLoading from "../../components/OverlayLoading";
import CallbackMessage from "../../components/CallbackMessage";
import ConfirmDialog from "../../components/ConfirmDialog";

import { orcamentoServices } from "../../services/orcamentoServices";

import "./styles.css";
import styles from "./styles.module.scss";
import SelectComponent from "../../components/SelectComponent";
import classes from "./styles.module.scss";
import Button from "../../components/ButtonComponent";
import selectService from "../../services/selectServices";
import Input from "../../components/Input";
import { numberToCurrency } from "../../utils/functions";
import useCompany from "../../hooks/useCompany";
import { InfoComponent } from "../../components/InfoComponent";

function TitleCompany({ company }) {
  return (
    <Grid container direction="column" className="body">
      <Grid item container alignItems="center" justifyContent="space-between">
        <Grid item xs sm md={7}>
          <span className="font-title">{company}</span>
        </Grid>
      </Grid>
      <Grid item>
        <InfoComponent
          text={
            <span className={classes.warningTitle}>
              Para adicionar ou editar alguma categoria, você deve fazer através
              das Categorias, acessando o menu ”Categorias” ou{" "}
              <a href="/categorias">
                <span>clique aqui</span>
              </a>
              .
            </span>
          }
          icon={<InfoOutlined fontSize="small" className="info-icon" />}
        />
        <div className="space-large-vertical" />
      </Grid>
    </Grid>
  );
}

function AccordionCategory({
  groupName,
  groupId,
  companyId,
  categories,
  updateCategories = () => {},
  anoSelecionado,
  setLoading,
  componentEditingId,
  setComponentEditingId,
}) {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  const editingValue = useRef(null);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [confirmRemoveCategoriaDialog, setConfirmRemoveCategoriaDialog] =
    useState(false);
  const [confirmRemoveGrupoDialog, setConfirmRemoveGrupoDialog] =
    useState(false);

  const [deleteCategoriaId, setDeleteCategoriaId] = useState(null);
  const [removeGrupoId, setRemoveGrupoId] = useState(null);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const defaultConfigTable = {
    columnArray: [
      { columnName: "Categoria", dataRef: "nome" },
      { columnName: "Valor", dataRef: "customValue", customColum: true },
    ],
    options: {
      save: true,
    },
    display: {
      statusLabels: true,
      search: false,
      itemsPerPage: false,
      totalResults: false,
      pagination: false,
    },
    currentPage: 1,
    pagination: false,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function handleDeleteGroup() {
    const response = await categoriasServices.removerGrupo(removeGrupoId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      return;
    }

    if (response.status === 200) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage("Grupo excluído com sucesso!");
      setCallbackDuration(2000);
      setTimeout(() => {
        updateCategories();
      }, 1500);
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
    handleRemoveCancel();
  }

  function handleClose(event, reason) {
    // if (reason === "clickaway") {
    //   setCallbackShown(false);
    // }
    // if (reason === "timeout") {
    //   setCallbackShown(false);
    // }
    setCallbackShown(false);
  }

  function handleEditarCategoria(id) {
    history.push(`/editar-categoria/${id}`);
  }

  function handleRemoveCategoriaDialog(id) {
    setConfirmRemoveCategoriaDialog(true);
    setDeleteCategoriaId(id);
  }

  function handleRemoveCancel() {
    setConfirmRemoveCategoriaDialog(false);
    setConfirmRemoveGrupoDialog(false);
  }

  async function handleDeletarCategoria() {
    await categoriasServices
      .removerCategoria(deleteCategoriaId)
      .then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          return;
        }
        setCallbackErrorList([]);
        if (response.status === 200) {
          setCallbackType("success");
          setCallbackMessage("Categoria excluída com sucesso!");
          setCallbackDuration(4000);
          setCallbackShown(true);
          setTimeout(() => {
            updateCategories();
          }, 1500);
        } else {
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackErrorList([
            "Houve um erro ao tentar excluir categoria. Por favor, tente novamente.",
          ]);
        }
      });
    handleRemoveCancel();
  }

  function listTipo(categories) {
    return categories.map((item, index) =>
      item.tipo === "ENTRADA" ? (
        <div className="card-entrada" key={index}>
          ENTRADA
        </div>
      ) : (
        <div className="card-saida" key={index}>
          SAIDA
        </div>
      )
    );
  }

  const handleSubmitNewValue = async () => {
    try {
      if (!componentEditingId) {
        return;
      }
      setLoading(true);
      const response = await orcamentoServices.atualizaValorCategoria({
        id_categoria: componentEditingId,
        valor: editingValue.current,
        ano: anoSelecionado,
      });
      setLoading(true);

      if (response.status === 401) {
        setOpenLoginExpirado(true);
        return;
      }
      setCallbackErrorList([]);
      if (response.status === 200) {
        setCallbackType("success");
        setCallbackMessage(response?.data?.msg || "Valor atualizado");
        setCallbackDuration(4000);
        setCallbackShown(true);
        updateCategories();
      } else {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        if (response.data.error)
          setCallbackErrorList(Object.values(response.data.error));
        else
          setCallbackErrorList([
            "Houve um erro ao tentar atualizar o valor. Por favor, tente novamente.",
          ]);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const editableCell = useCallback(
    ({ data, editingId, setEditingId }) => {
      const handleDoubleClick = () => {
        setEditingId(data.id);
      };

      const [touched, setTouched] = useState(false);

      const handleTouchEnd = () => {
        if (touched) {
          setEditingId(data.id);
        }
        setTouched(false);
      };

      useEffect(() => {
        if (editingId) {
          editingValue.current = data.customValue;
        }
      }, [editingId]);

      return componentEditingId === data.id ? (
        <div className={styles.containerInputEdit}>
          <Input
            tipo="dinheiro"
            label=""
            defaultValue={
              editingValue.current ? editingValue.current : data.customValue
            }
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                handleSubmitNewValue(event);
              }
            }}
            handleInputChange={(event) => {
              editingValue.current = event.floatValue;
            }}
          />
        </div>
      ) : (
        <div
          className={styles.containerEditableValue}
          onTouchStart={() => setTouched(true)}
          onTouchEnd={handleTouchEnd}
          onDoubleClick={handleDoubleClick}
        >
          {numberToCurrency(data.customValue)}
        </div>
      );
    },
    [editingValue.current, componentEditingId]
  );

  return (
    <>
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
        key={groupId + "1"}
      />
      <Accordion
        key={groupId}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{
          background: "#f8f8fb",
          boxShadow: "none",
        }}
      >
        <AccordionSummary>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item container xs className="accordion-title">
              <Grid item>
                {expanded === "panel1" ? (
                  <ExpandMore className="font-orange accordion-arrow" />
                ) : (
                  <ExpandLess className="font-darkBlue accordion-arrow" />
                )}
              </Grid>
              <Grid item className="font-title">
                {groupName}
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails style={{ background: "white" }}>
          <TableComponent
            idName="id"
            dataList={categories}
            tableConfig={defaultConfigTable}
            cbEdit={(id) => handleEditarCategoria(id)}
            cbDelete={(id) => handleRemoveCategoriaDialog(id)}
            cbSave={handleSubmitNewValue}
            enableOrder={false}
            editingId={componentEditingId}
            setEditingId={setComponentEditingId}
            customColumns={[
              {
                columnHead: <span>Tipo</span>,
                columnData: listTipo(categories),
              },
              {
                columnHead: <span>Valor média mês</span>,
                columnData: editableCell,
                editable: true,
              },
            ]}
          />
        </AccordionDetails>
      </Accordion>
      <ConfirmDialog
        open={confirmRemoveCategoriaDialog}
        onCancel={handleRemoveCancel}
        onAccept={handleDeletarCategoria}
      />
      <ConfirmDialog
        open={confirmRemoveGrupoDialog}
        onCancel={handleRemoveCancel}
        onAccept={handleDeleteGroup}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export function Orcamento() {
  const { selecionaAnoOrcamento } = selectService;

  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    setEmpresaPagina,
  } = useCompany("orcamento");

  const [listRequest, setListRequest] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [listAnos, setListAnos] = useState([]);
  const [anoSelecionado, setAnoSelecionado] = useState(null);
  const [componentEditingId, setComponentEditingId] = useState(false);

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);

  const tableOptions = {
    noSearch: true,
    filter: true,
  };

  const fetchCategory = useCallback(async () => {
    try {
      if (!empresaSelecionada || !anoSelecionado) {
        let errors = [];
        if (!anoSelecionado) {
          errors.push("Selecione um ano na lista.");
        }
        if (!empresaSelecionada) {
          errors.push("Selecione uma empresa na lista.");
        }
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(errors);
        setCallbackDuration(4000);
        return;
      }
      setLoading(true);
      await orcamentoServices
        .getDre({ idEmpresa: empresaSelecionada, ano: anoSelecionado })
        .then((response) => {
          if (response.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          }
          if (response.status === 200) {
            setListRequest(response.data);
            setComponentEditingId(null);
          }
          setLoading(false);
        });
    } catch (error) {
      console.log({ error });
      setLoading(false);
    }
  }, [empresaSelecionada, anoSelecionado]);

  const loadAnosOrcamento = useCallback(async () => {
    const response = await selecionaAnoOrcamento();
    if (response.status === 200) {
      setListAnos(response.data);
    }
  }, [selecionaAnoOrcamento]);

  const handleApplyFilters = () => {
    setEmpresaPagina("orcamento", empresaSelecionada);
    fetchCategory();
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setAnoSelecionado(null);
    setListRequest(null);
    setEmpresaPagina("orcamento", null);
  };

  function handleClose() {
    setCallbackShown(false);
    setCallbackType("");
  }

  function filterComponent() {
    return (
      <div className="session-container container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <SelectComponent
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                      ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={(value) => {
                setEmpresaSelecionada(value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectComponent
              styleType="form"
              label="Ano"
              required
              initialValue={
                anoSelecionado !== undefined
                  ? listAnos.find((item) => item.id === anoSelecionado)?.nome
                  : ""
              }
              title="Selecione um ano"
              list={listAnos}
              callback={(value) => {
                setAnoSelecionado(value);
              }}
            />
          </Grid>
          <Grid className={classes.marginLeftAuto}>
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  useEffect(() => {
    loadAnosOrcamento();
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <Search
        callback={() => {}}
        tableOptions={tableOptions}
        filterComponent={filterComponent()}
      />
      {listRequest && (
        <div className="session-container">
          <TitleCompany
            company={listRequest?.razao_social}
            nameCompany={`${listRequest?.id_empresa} - ${listRequest?.razao_social}`}
          />

          {listRequest?.grupos?.map((newItem, newIndex) => {
            const { categorias } = newItem;

            const newCategories = categorias.map((item) => {
              return {
                ...item,
                id: item.id_categoria,
                customValue: item.valor,
              };
            });
            return (
              <>
                <AccordionCategory
                  key={newIndex}
                  groupName={newItem.nome}
                  categories={newCategories}
                  groupId={newItem.id_grupo}
                  updateCategories={fetchCategory}
                  anoSelecionado={anoSelecionado}
                  setLoading={setLoading}
                  componentEditingId={componentEditingId}
                  setComponentEditingId={setComponentEditingId}
                />
                <div className="space-vertical" />
              </>
            );
          })}
          <div className="space-vertical-xl" />
        </div>
      )}

      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={() => handleClose()}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
