import React from "react";
import "./styles.css";

function AgendarRecebimento() {
  return (
    <>
      <div className="session-container">
        <span className="session-container-header"></span>
      </div>
    </>
  );
}

export default AgendarRecebimento;
