import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom';

import TableComponent from '../../../components/TableComponent';
import OverlayLoading from '../../../components/OverlayLoading';
import ModalLoginExpirado from '../../../components/ModalLoginExpirado';
// import ConfirmDialog from '../../../components/ConfirmDialog';

import planosServices from '../../../services/planosServices';

import { numberToCurrency } from '../../../utils/functions';

export default function Lista() {
  const history = useHistory();

  const { listaPlanos } = planosServices;

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  // const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);

  const [listRequest, setListRequest] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_plano" },
      { columnName: "Nome do plano", dataRef: "nome_plano" },
      { columnName: "Período", dataRef: "quantidades_meses" },
      { columnName: "Status", dataRef: "status" },
      { columnName: "Valor do plano", dataRef: "valor_plano" },
    ],
    options: {
      edit: true,
      // delete: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = {...defaultConfigTable};
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await listaPlanos(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm
    );

    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.data.hasOwnProperty("data")) {

      const newData = result.data.data.map(item => {
        return {
          ...item,
          quantidades_meses: `${item.quantidades_meses} meses`,
          valor_plano: numberToCurrency(item.valor_plano),
        }
      });

      const newResult = {
        ...result.data,
        data: newData,
      }

      setListRequest(newResult);
    } else {

      const newData = result.data.map(item => {
        return {
          ...item,
          quantidades_meses: `${item.quantidades_meses} meses`,
          valor_plano: numberToCurrency(item.valor_plano),
        }
      });

      const newResult = {
        ...result,
        data: newData,
      }

      setListRequest(newResult);
    }
    setLoading(false);
  }, [
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      listaPlanos
    ]);


  useEffect(() => {
    updateTable();
  }, [updateTable]);

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = {...defaultConfigTable};
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = {...defaultConfigTable};
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onEdit = async (id) => {
    history.push(`/atualizar-plano/${id}`)
  };

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id_plano"
        dataList={listRequest?.data ? listRequest.data : listRequest}
        tableConfig={defaultConfigTable}
        cbEdit={onEdit}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackCurrentPage={onPageChange}
        callbackSearchTerm={onSearchTerm}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  )
}