import Axios from "./Axios";

const leadChatbotService = {
  getLista: async (   page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
  ) => {
    const response = await Axios.get("/lista-lead-chatbot", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
      }
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
}

export default leadChatbotService;
