import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import "./styles.css";
import Input from "../../../components/Input";
import SelectComponent from "../../../components/SelectComponent";
import empresasService from "../../../services/empresasService";
import { documentacaoContabilService } from "../../../services/documentoContabilService";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import FileInput from "../../../components/InputFile";
import ButtonComponent from "../../../components/ButtonComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import { base64ToFile } from "../../../utils/functions";
import "./styles.css";
import Textarea from "../../../components/Textarea";

function CadastroDocumentoContabil() {
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [file, setFile] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [idEmpresa, setIdEmpresa] = useState("");
  const [listEmpresas, setListEmpresas] = useState();
  const [listCategorias, setListCategorias] = useState([]);
  const [observacoes, setObservacoes] = useState(null);
  const [categoriaId, setCategoriaId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const history = useHistory();
  const params = useParams();

  let { id: idParams } = params;

  const [id, setId] = useState(idParams);

  let updateListEmpresas = async () => {
    await empresasService.selectEmpresa().then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response) setListEmpresas(response.data);
    });
  };

  async function loadFields() {
    const response = await documentacaoContabilService.consultaDocumentacao(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    } else if (response.status === 400) {
      setErrors([response.data.error] || ["Erro ao consultar documento!"]);
      setLoading(false);
      setShowMessage(true);
      setId(null);
      return;
    }
    let data = response.data;
    setTitulo(data.titulo);
    setCategoriaId(data.id_categoria_documento_contabil);
    setIdEmpresa(data.id_empresa);
    setObservacoes(data.observacoes);
    if (data.arquivo !== null) setFile(data.arquivo);
  }

  function validateFields() {
    let list = [];
    if (titulo === "") list.push("O campo Título deve ser preenchido.");
    if (idEmpresa === "") list.push("A Empresa deve ser selecionada.");

    if (list.length > 0) setShowMessage(true);

    setErrors(list);

    return list.length === 0;
  }

  function handleClose(event, reason) {
    setShowMessage(false);
    if (errors.length === 0) {
      history.push("/documento-contabil");
    }
  }

  async function handleSubmit() {
    if (!validateFields()) return;
    setLoading(true);
    await documentacaoContabilService
      .atualizaDocumento(
        id,
        titulo,
        categoriaId,
        idEmpresa,
        file,
        observacoes,
        fileName
      )
      .then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        if (response.status != 200)
          if (response.data.error)
            setErrors(Object.values(response.data.error));
          else setErrors(["Ocorreu um erro"]);
        setShowMessage(true);
        setLoading(false);
      });
  }

  async function loadCategorias() {
    const response = await documentacaoContabilService.selectCategorias();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListCategorias(response.data);
  }

  useEffect(() => {
    async function load() {
      setLoading(true);
      await updateListEmpresas();
      await loadFields();
      await loadCategorias();
      setLoading(false);
    }
    load();
  }, []);

  return (
    <>
      <div>
        <form className="session-container-form  documento-contabil-container">
          <span className="session-container-header ">
            {loading && <OverlayLoading />}
            <div className="p-horizontal">
              <Grid item container direction="column" spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs sm md={8}>
                    <Input
                      required
                      label="Título do Documento"
                      value={titulo}
                      handleInputChange={(event) =>
                        setTitulo(event.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs sm md={4}>
                    <SelectComponent
                      styleType="form"
                      label="Categoria"
                      title="Selecione uma categoria"
                      list={listCategorias}
                      initialValue={
                        listCategorias.find((item) => item.id === categoriaId)
                          ? listCategorias.find(
                              (item) => item.id === categoriaId
                            ).nome
                          : ""
                      }
                      callback={(id) => setCategoriaId(id)}
                      search={false}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <SelectComponent
                    styleType="form"
                    label="Empresa"
                    title="Selecione uma empresa"
                    list={listEmpresas}
                    required
                    initialValue={
                      listEmpresas &&
                      listEmpresas.find((item) => item.id === idEmpresa)
                        ? listEmpresas.find((item) => item.id === idEmpresa)
                            .nome
                        : ""
                    }
                    callback={(id) => setIdEmpresa(id)}
                  />
                </Grid>
                <Grid item>
                  <Textarea
                    label="Observações"
                    minRows={5}
                    value={observacoes}
                    onChange={(e) => setObservacoes(e.target.value)}
                    maxLength={300}
                  />
                </Grid>
                {file === "" || file === null ? (
                  <Grid item>
                    <FileInput
                      title="Anexo *"
                      onChange={(value) => {
                        setFile(value);
                        setFileName(valueFileName);
                      }}
                    />
                  </Grid>
                ) : (
                  <>
                    <span className="label text-label">Anexo</span>
                    <Grid item container>
                      <Grid item>
                        <ButtonComponent
                          className="primary-outline text-button"
                          text="Visualizar arquivo"
                          icone="download"
                          iconeSize="18"
                          iconeColor="#812990"
                          onClick={() => {
                            base64ToFile(file, "anexo");
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <ButtonComponent
                          className="primary-outline icon-button"
                          icone="trash"
                          iconeColor="#812990"
                          iconeSize="16"
                          onClick={() => {
                            setFile("");
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid item>
                  <span className="required-text">* Campos obrigatórios</span>
                </Grid>
              </Grid>
            </div>

            <CallbackMessage
              open={showMessage}
              type={`${errors.length === 0 ? "success" : "error"}`}
              message={`${
                errors.length === 0
                  ? "Documento Contábil editado com sucesso!"
                  : "Erro!"
              }`}
              errorList={errors}
              handleClose={handleClose}
              duration={errors.length === 0 ? 2000 : 6000}
            />
          </span>
        </form>
        <Grid
          item
          container
          justifyContent="flex-end"
          className="documento-contabil-submit"
        >
          <ButtonComponent
            text="Salvar"
            className="btn-success zero-margin"
            onClick={handleSubmit}
          />
        </Grid>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastroDocumentoContabil;
