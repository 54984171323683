import Axios from './Axios';

const solicitacaoServicoServices = {
  selecionaServicoPlano: async () => {
    const response = await Axios.get('/seleciona-servico-plano')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  selecionaStatusSolicitacao: async () => {
    const response = await Axios.get('/seleciona-status-solicitacao')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  solicitarServico: async (id_empresa, servicos, permite_parcelamento, numero_maximo_parcelas, data_vencimento) => {
    const response = await Axios.post('/solicita-servico', {
      id_empresa,
      servicos,
      permite_parcelamento,
      numero_maximo_parcelas,
      data_vencimento
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  atualizarSolicitacaoServico: async (id, status_solicitacao, itens, permite_parcelamento, numero_maximo_parcelas, data_vencimento) => {
    const response = await Axios.post(`/atualiza-solicitacao-servico/${id}`, {
      status_solicitacao,
      itens,
      permite_parcelamento,
      numero_maximo_parcelas,
      data_vencimento
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  consultarSolicitacaoServico: async (id) => {
    const response = await Axios.get(`/consulta-solicitacao-servico/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  listaSolicitacoes: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    // 1 = sim; 0 = não
    // lista_admin = 1,
    de = null,
    ate = null,
    id_empresa = null,
  ) => {
    const response = await Axios.get('/lista-solicitacao-servico', {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        data_de: de,
        data_ate: ate,
        id_empresa,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  consultaSolicitacao: async (id) => {
    const response = await Axios.get(`/consulta-solicitacao-servico/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  cancelaSolicitacaoServico: async (id) => {
    const response = await Axios.get(`/cancela-solicitacao-servico/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
};

export default solicitacaoServicoServices;
