import React from "react";
import "./styles.scss";

export const InputNativo = ({
  label,
  type,
  placeholder,
  onChange,
  error,
  ...rest
}) => {
  return (
    <fieldset className="input-nativo">
      {label ? <span className="label">{label}</span> : null}
      <input
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        {...rest}
      />
      {error ? <span className="validation-error">{error}</span> : null}
    </fieldset>
  );
};
