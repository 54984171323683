import React, { useState } from "react";
import { Checkbox, Dialog, DialogTitle, FormControlLabel, Grid, IconButton, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import creditCardIcon from "../../../assets/img/credit-card-icon.svg";
import selectServices from '../../../services/selectServices';
import validateFields from "../../../utils/validateFields";
import ButtonComponent from "../../ButtonComponent";
import SwitchComponent from '../../Switch';
import CallbackMessage from "../../CallbackMessage";
import InputComponent from "../../Input";
import ModalLoginExpirado from "../../ModalLoginExpirado";
import OverlayLoading from "../../OverlayLoading";
import SelectComponent from "../../SelectComponent";
import "./styles.scss";





function ModalPagamentoCartaoPDV({
  open,
  onClose,
  onSave,
  totalValue,
}) {

  const CustomCheckbox = withStyles({
    root: {
      color: "#E8EBF1",
      marginTop: 10,
      width: 0,
      borderRadius: 4,
      "&$checked": {
        color: "#812990"
      }
    },
    checked: {}
  })(props => <Checkbox color="default" {...props} />);

  const { selecionaFormaPagamentoStatus } = selectServices;
  const [amount, setAmount] = useState(null);
  const [nsu, setNsu] = useState(null);
  const [bandeiraCartao, setBandeiraCartao] = useState(null);
  const [formaPagamento, setFormaPagamento] = useState(null);
  const [qtdParcelas, setQtdParcelas] = useState(2);


  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [showParcela, setShowParcela] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  async function handleSubmit() {
    setErrors([]);

    let fields = [
      {
        label: "valor",
        value: amount,
      },
      {
        label: "nsu",
        value: nsu,
      },
      {
        label: "formadepagamento",
        value: formaPagamento,
      },
      {
        label: "bandeira",
        value: bandeiraCartao,
      },
      {
        label: "qdtParcelas",
        value: qtdParcelas,
      },
    ];

    const fieldsValidate = validateFields(fields);

    if (fieldsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(fieldsValidate);
      return;
    }

    if (Number(totalValue) <= 0) {
      setShowMessage(true);
      setErrors(["Você não pode pagar uma conta com valor igual a zero."]);
      return;
    }

    if (Number(amount) > totalValue) {
      setShowMessage(true);
      setErrors(["Você não pode pagar mais que o valor total."]);
      return;
    }


    let data = {}

    if (showParcela && formaPagamento === 2) {
      data = {
        amount: Number(amount),
        nsu: nsu,
        formaPagamento: formaPagamento,
        bandeira: bandeiraCartao,
        qtdParcelas: qtdParcelas,
      }
    } else {
      data = {
        amount: Number(amount),
        nsu: nsu,
        formaPagamento: formaPagamento,
        bandeira: bandeiraCartao,
      }
    }

    // console.log("onSave data modal::")
    // console.log(data)

    onSave(data, 'card');
    handleCloseModal();
  }

  const handleCloseModal = () => {
    setShowMessage(false);
    setAmount(null);
    setNsu(null);
    setBandeiraCartao(null);
    setFormaPagamento(null);
    onClose();
  }

  const handleClose = () => {
    setShowMessage(false);

    if (errors.length === 0) {
      onClose();
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => { }} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>PAGAMENTO COM CARTÃO</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
              {/* <Grid
                      item
                      container
                      // className={classes.creditCardChoice}
                      justify="space-between"
                      xs={12}

                    > */}
              <Grid
                item
                container
                className="wrapper-payment-section"
                xs={12}
                sm={12}
                md={12}
                justify="space-between"
                spacing={1}
              >
                <Grid item>
                  <div
                    className="payment-card-container"
                  >
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          checked={formaPagamento === 3}
                          onChange={() => formaPagamento !== null ? setFormaPagamento(null) : setFormaPagamento(3)}
                          name="debit"
                        />
                      }
                    />
                    <img width={30} src={creditCardIcon} alt="" />
                    <span className="label-payment-section">
                      Débito
                    </span>
                  </div>
                </Grid>
                <Grid item>
                  <div
                    className="payment-card-container"
                  >
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          checked={formaPagamento === 2}
                          onChange={() => formaPagamento !== null ? setFormaPagamento(null) : setFormaPagamento(2)}
                          name="credit"
                        />
                      }
                    />
                    <img width={30} src={creditCardIcon} alt="" />
                    <span className="label-payment-section">
                      Crédito
                    </span>
                  </div>
                </Grid>
              </Grid>
              {/* <Grid
                        item
                        container
                        xs={12}
                        sm={3}
                        md={3}
                        justify="center"
                      >
                        <Grid item>
                          <div className={classes.brandsWrapper}>
                            <img src={cardFlags} alt="" />
                          </div>
                        </Grid>
                      </Grid> */}
              {/* </Grid> */}
              <Grid item xs={12} sm={12} md={12}>
                <InputComponent
                  nome="valor-pago"
                  label="Valor do Pagamento"
                  tipo="dinheiro"
                  value={amount}
                  required
                  handleInputChange={(event) => {
                    setAmount(event.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <InputComponent
                  nome="nsu"
                  label="Nsu da Transação"
                  tipo="text"
                  required
                  value={nsu}
                  handleInputChange={(event) => setNsu(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <SelectComponent
                  styleType="form"
                  required
                  list={
                    [{
                      id: 'Mastercard',
                      nome: 'Mastercard'
                    },
                    {
                      id: 'Visa',
                      nome: 'Visa'
                    }
                    ]}
                  label="Informe a bandeira do cartão"
                  title="Selecione uma opção"
                  callback={(id) => setBandeiraCartao(id)}
                />
              </Grid>

              {formaPagamento === 2 && (
                <>
                  <Grid
                    item
                    container
                    alignItems="center"
                    style={{ height: 60 }}
                  >
                    <Grid item>
                      <SwitchComponent
                        checked={showParcela}
                        onClick={() => setShowParcela(!showParcela)}
                      />
                    </Grid>


                    <Grid item>
                      <span className="title">Parcelado</span>
                    </Grid>
                  </Grid>

                  {showParcela && (
                    <Grid item xs sm md={6}>
                      <InputComponent
                        tipo="valor"
                        nome="qdtParcelas"
                        required
                        label="Quantidade de Parcelas"
                        value={qtdParcelas}
                        handleInputChange={(event) => setQtdParcelas(event.target.value)}
                      />
                    </Grid>
                  )}
                </>
              )}

              <Grid item xs={12}>
                <span className="required-text">* Campos obrigatórios</span>
              </Grid>

              <Grid container justify="flex-end" spacing={2} item>
                <Grid item>
                  <ButtonComponent
                    text="Cancelar"
                    onClick={onClose}
                    className="primary-outline zero-margin btn-modal-outline"
                  />
                </Grid>
                <Grid item>
                  <ButtonComponent
                    text="Pagar"
                    onClick={handleSubmit}
                    className="btn-success zero-margin"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}

export default ModalPagamentoCartaoPDV;