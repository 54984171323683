import Axios from "./Axios";

export const documentacaoLegalService = {
  getDocumentacao: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null
  ) => {
    const result = await Axios.get("/lista-documento-legal", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  deleteDocumentacao: async (id) => {
    const result = await Axios.get(`/remove-documento-legal/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  consultaDocumentacao: async (id) => {
    const result = await Axios.get(`/consulta-documento-legal/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  atualizaDocumentoLegal: async (
    id,
    titulo,
    id_categoria_documento_legal,
    id_empresa,
    arquivo
  ) => {
    const result = await Axios.post(`/atualiza-documento-legal/${id}`, {
      titulo: titulo,
      id_categoria_documento_legal: id_categoria_documento_legal,
      id_empresa: id_empresa,
      arquivo: arquivo,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  selectCategorias: async () => {
    const result = await Axios.get("/seleciona-documento-legal")
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  cadastrarDocumentoLegal: async (
    titulo,
    id_categoria_documento_legal,
    id_empresa,
    arquivo,
    descricao = "",
  ) => {
    const response = await Axios.post("/cadastra-documento-legal",
      {
        titulo,
        id_categoria_documento_legal,
        id_empresa,
        arquivo,
        descricao,
      })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
};
