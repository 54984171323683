import parseJSON from "../utils/parseJSON";

function loadPermissoes() {
  let token = localStorage.getItem("session");
  let stringifiedPermissions = localStorage.getItem("permissoes");

  if (!token) return [];
  if (!stringifiedPermissions) return [];
  if (stringifiedPermissions === "undefined") return [];

  const storagedPermissions = parseJSON(stringifiedPermissions);

  if (!storagedPermissions) return [];

  let accessGrantedRoutes = storagedPermissions
    .map((item) => {
      const { chave, valor } = item;
      let path = "";
      switch (true) {
        case chave === "empresas": {
          path = "/empresas";
          break;
        }
        case chave === "empresas-categorias": {
          path = "/categorias-old";
          break;
        }
        case chave === "empresas-categorias-personalizadas": {
          path = "/categorias";
          break;
        }
        case chave === "empresas-grupos": {
          path = "/grupos";
          break;
        }
        case chave === "empresas-centro-custos": {
          path = "/centro-custo";
          break;
        }
        case chave === "empresas-contas-bancarias": {
          path = "/cadastro-conta";
          break;
        }
        case chave === "cadastros-clientes": {
          path = "/clientes-cadastrados";
          break;
        }
        case chave === "cadastros-fornecedores": {
          path = "/lista-fornecedores";
          break;
        }
        case chave === "cadastros-transportadora": {
          path = "/lista-transportadoras";
          break;
        }
        case chave === "cadastros-usuarios": {
          path = "/usuarios-cadastrados";
          break;
        }
        case chave === "emissao-nf": {
          path = "/emissao";
          break;
        }
        case chave === "financeiro-agendar-pagamento": {
          path = "/pagamentos-cadastrados";
          break;
        }
        case chave === "financeiro-agendar-recebimento": {
          path = "/recebimentos-cadastrados";
          break;
        }
        case chave === "financeiro-contas-extratos": {
          path = "/contas-extratos";
          break;
        }
        case chave === "meu-contador-obrigacoes-financeiras": {
          path = "/obrigacoes-financeiras";
          break;
        }
        case chave === "meu-contador-certidoes": {
          path = "/certidoes";
          break;
        }
        case chave === "meu-contador-documentacao-legal": {
          path = "/documentacao-legal";
          break;
        }
        case chave === "relatorios-dre": {
          path = "/dre";
          break;
        }
        case chave === "relatorios-contas-receber": {
          path = "/contas-receber";
          break;
        }
        case chave === "relatorios-contas-pagar": {
          path = "/contas-pagar";
          break;
        }
        case chave === "relatorios-contas-recebidas": {
          path = "/contas-recebidas";
          break;
        }
        case chave === "relatorios-contas-pagas": {
          path = "/contas-pagas";
          break;
        }
        case chave === "empresas-categoria-padrao": {
          path = "/categoria-padrao";
          break;
        }
        case chave === "relatorios-leads-chatbot": {
          path = "/leads-chatbot";
          break;
        }
        case chave === "modulo-dp-funcionario": {
          path = "/funcionarios";
          break;
        }
        case chave === "modulo-dp-pre-admissao": {
          path = "/pre-admissao";
          break;
        }
        case chave === "modulo-dp-pre-demissao": {
          path = "/pre-demissao";
          break;
        }
        case chave === "configuracao-plataforma": {
          path = "/configuracoes-plataforma";
          break;
        }
        case chave === "ferias": {
          path = "/ferias";
          break;
        }
        case chave === "eventos-da-folha": {
          path = "/eventos-folha";
          break;
        }
        case chave === "menu-lead-ai": {
          path = "/lista-gestao-leads";
          break;
        }
        case chave === "emissao-nf-s": {
          path = "/emissao/nfs";
          break;
        }
        case chave === "emissao-nf-c": {
          path = "/emissao/nfc";
          break;
        }
        case chave === "emissao-nf-e": {
          path = "/emissao/nfe";
          break;
        }
        case chave === "cadastros-produto": {
          path = "/produtos-cadastrados";
          break;
        }
        case chave === "meu-contador-servicos": {
          path = "/lista-solicitacao-servicos";
          break;
        }
        case chave === "meu-contador-planos": {
          path = "/lista-planos";
          break;
        }
        case chave === "modulo-dp-previsao-ferias": {
          path = "/previsao-ferias";
          break;
        }
        case chave === "modulo-dp-agendamento-ferias": {
          path = "/agendamento-de-ferias";
          break;
        }
        case chave === "modulo-dp-eventos-folha": {
          path = "/folhas-de-pagamento";
          break;
        }
        case chave === "financeiro-orcamentos": {
          path = "/orcamento";
          break;
        }
        case chave === "modulo-dp-cartao-ponto": {
          path = "/cartoes-ponto";
          break;
        }
        case chave === "cadastros-formas-de-pagamento": {
          path = "/lista-formas-pagamento";
          break;
        }
        case chave === "relatorios-dre-orcado-realizado": {
          path = "/dre-orcado-realizado";
          break;
        }
        case chave === "relatorios-notas-de-entrada": {  
          path = "/notas-de-entrada"
          break;
        }
        case chave === "relatorios-notas-de-saida": {  
          path = "/notas-de-saida"
          break;
        }
        default:
          break;
      }

      return {
        path,
        accessGranted: valor === 1 ? true : false,
      };
    })
    .filter((item) => item !== undefined)
    .filter((item) => item.path !== "");

  return accessGrantedRoutes;
}

export default loadPermissoes;
