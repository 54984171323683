import React, { useState } from "react";
import { Accordion } from "../../../../components/AccordionComponent";
import { Grid } from "@material-ui/core";
import TableComponent from "../../../../components/TableComponent";


const FinanceAccordionHeader = ({item, dadosProdutos, configuracaoTabela, index}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
      <div>
        <Accordion.Root
            handleChange={handleChange}
            index={index}
            expanded={expanded}
        >
          <Accordion.Header
              expanded={expanded}
              index={index}
              item={item}
              columns={[]}
              numbers={false}
              marginRight={32}
              widthFirstColumn={154}
              customDescriptionClass=""
              customTotalClass={'rel-extrato-mf-total'}
              customContainerClass={'rel-extrato-mf-container'}
              hideTotalOnOpen={true}
          />

          <Accordion.Body>
            <Grid
                container
                direction="column"
                className="container-tabela-responsiva rel-extrato-mf-hide-table-footer"
            >
              <TableComponent
                  idName="id_item"
                  dataList={dadosProdutos}
                  tableConfig={configuracaoTabela}
                  enableOrder={false}
              />
              {item.total}
            </Grid>
          </Accordion.Body>
        </Accordion.Root>
        <div className="space-vertical"/>
      </div>
  )
}

export function AccordionDre({dados, columns = [], configuracaoTabela, onOrderBy}) {

  return (
      <>
        {dados.map((item, index) => {
          const dadosProdutos = item?.data

          return (
              <FinanceAccordionHeader dadosProdutos={dadosProdutos} configuracaoTabela={configuracaoTabela} onOrderBy={onOrderBy} item={item} index={index} key={index}/>
        );
      })}
    </>
  );
}
