import { Dialog, DialogTitle, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import pdvService from '../../../services/pdvService';
import validateFields from "../../../utils/validateFields";
import ButtonComponent from "../../ButtonComponent";
import CallbackMessage from "../../CallbackMessage";
import ModalLoginExpirado from "../../ModalLoginExpirado";
import OverlayLoading from "../../OverlayLoading";
import Textarea from "../../Textarea";
import "./styles.scss";


function ModalCancelamento({
  open,
  onClose,
  onSave,
  idVenda,
}) {
  const { cancelaPedido } = pdvService;

  const [motivo, setMotivo] = useState(null);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  async function handleSubmit() {
    setLoading(true);
    setErrors([]);

    let fields = [
      {
        label: "motivo",
        value: motivo,
      },
    ];
    
    const fieldsValidate = validateFields(fields);

    if (fieldsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(fieldsValidate);
      setLoading(false);
      return;
    }

    const response = await cancelaPedido(idVenda, motivo);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }

    if (response.status === 400) {
      setErrors(Object.values(response?.data?.error).flat(Infinity));
      setShowMessage(true);
      setLoading(false);
      return;
    }

    if (response.status === 500) {
      setErrors(['Erro interno no servidor, contate o suporte.']);
      setShowMessage(true);
      setLoading(false);
      return;
    }
    
    if(response.status === 200 || response.status === 201) {
      handleCloseModal();
      onSave();
    }

    handleCloseModal();
    setLoading(false);
  }

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }

  const handleCloseModal = () => {
    setShowMessage(false);
    setMotivo(null);
    onClose();
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>CANCELAR</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <p className="info-text">Você esta cancelando uma venda, por favor informe abaixo o motivo.</p>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Textarea
                    label='Motivo *'
                    value={motivo}
                    handleInputChange={event => setMotivo(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                <span className="required-text">* Campos obrigatórios</span>
                </Grid>

              <Grid container justify="flex-end" item>
                <ButtonComponent
                  text="Salvar"
                  onClick={handleSubmit}
                  className="btn-success zero-margin"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalCancelamento;
