import React, { useState, useCallback, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import OverlayLoading from "../../../components/OverlayLoading";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import TableComponent from "../../../components/TableComponent";
import { documentacaoContabilService } from "../../../services/documentoContabilService";
import { base64ToFile } from "../../../utils/functions";
import CallbackMessage from "../../../components/CallbackMessage";

import Select from "../../../components/SelectComponent";
import Button from "../../../components/ButtonComponent";
import { useIsMount } from "../../../hooks/useIsMount";
import useCompany from "../../../hooks/useCompany";

function DocumentoContabilLista() {
  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("documento_contabil");
  const isMount = useIsMount();

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [listRequest, setListRequest] = useState([]);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Data Cadastro", dataRef: "data_hora" },
      { columnName: "Empresa", dataRef: "razao_social" },
      { columnName: "Título", dataRef: "titulo" },
      { columnName: "Categoria", dataRef: "nome" },
    ],
    options: {
      edit: true,
      delete: true,
      download: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    tableOptions: {
      filter: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    id_empresa: null,
  });

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    console.log("there");
    const result = await documentacaoContabilService.getDocumentacao(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.id_empresa
    );
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.status === 200) {
      let list = [];
      let l = [];
      if (result.data.hasOwnProperty("data")) list = result.data.data;
      else list = result.data;

      list.forEach((item) => {
        item.id = item.id_contabil;
        item.data_hora = new Date(item.data_hora).toLocaleDateString("pt-br");
        l.push(item);
      });

      if (result.data.hasOwnProperty("data")) {
        result.data.data = l;
        setListRequest(result.data);
      } else {
        result.data = l;
        setListRequest(result);
      }
    }

    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
  ]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onEdit = (id) => {
    history.push(`editar-documento-contabil/${id}`);
  };

  let handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  let handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  let onDelete = async () => {
    setLoading(true);
    const response = await documentacaoContabilService.deleteDocumentacao(
      deleteId
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    console.log(response);

    if (response.status === 200) {
      setShowMessage(true);
      updateTable();
    } else {
      setShowMessage(true);
      if (response.data.error) setErrors(Object.values(response.data.error));
      else setErrors(["Ocorreu um erro"]);
    }
    setLoading(false);
    handleRemoveCancel();
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setShowMessage(false);
    }
    if (reason === "timeout") {
      setShowMessage(false);
    }
  }

  let onDownload = async (id) => {
    await documentacaoContabilService
      .consultaDocumentacao(id)
      .then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        if (response.status === 200 && response.data.arquivo) {
          base64ToFile(response.data.arquivo, "anexo");
        }
      });
  };

  const handleSelectEmpresa = async (id) => {
    console.log(id);
    setEmpresaSelecionada(id);
  };

  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      return setDefaultConfigTable({
        ...defaultConfigTable,
        id_empresa: empresaStorage,
      });
    }
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: empresaSelecionada,
    });
    setEmpresaPagina("documento_contabil", empresaSelecionada);
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
    });
    setEmpresaPagina("documento_contabil", null);
  };

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                      ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={handleSelectEmpresa}
            />
          </Grid>
          <Grid className="marginLeftAuto">
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("documento_contabil");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onEdit}
        cbDelete={handleRemoveDialog}
        cbDownload={onDownload}
        filterComponent={filterComponent()}
      />
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${
          errors.length === 0
            ? "Documento Contábil excluído com sucesso!"
            : "Erro!"
        }`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default DocumentoContabilLista;
