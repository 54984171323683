import { AccordionSummary, FormControlLabel, Grid } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React from "react";
import Icon from "../../../../../../components/Icon";

export const AccordionHeader = ({
  companyId,
  expanded,
  name,
  handleNewCategoryNavigation,
  categoryId,
  handleEditGroupNavigation,
  handleAtualizaStatusCategoriaGrupoDialog,
  groupStatus,
}) => {
  return (
    <AccordionSummary>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item container xs className="accordion-title">
          <Grid item>
            {expanded === "panel1" ? (
              <ExpandMore className="font-orange accordion-arrow" />
            ) : (
              <ExpandLess className="font-darkBlue accordion-arrow" />
            )}
          </Grid>
          <Grid item className="font-title">
            {name}
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            aria-label="buttons"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <>
                <div
                  className="icon-wrapper"
                  onClick={() =>
                    handleNewCategoryNavigation(companyId, categoryId)
                  }
                >
                  <Icon name="add" className="icon" color="#812990" size={16} />
                </div>
                <div
                  className="icon-wrapper"
                  onClick={() => handleEditGroupNavigation(categoryId)}
                >
                  <Icon name="edit" className="icon" />
                </div>
                <div
                  className="icon-wrapper"
                  onClick={() =>
                    handleAtualizaStatusCategoriaGrupoDialog(categoryId)
                  }
                >
                  {groupStatus ? (
                    <Icon name="switch-on" className="icon" />
                  ) : (
                    <Icon name="switch-off" className="icon" />
                  )}
                </div>
              </>
            }
          />
        </Grid>
      </Grid>
    </AccordionSummary>
  );
};
