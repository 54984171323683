import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Input from '../../../components/Input';
import Select from '../../../components/SelectComponent';
import Button from '../../../components/ButtonComponent';
import ModalLoginExpirado from '../../../components/ModalLoginExpirado';
import OverlayLoading from '../../../components/OverlayLoading';
import CallbackMessage from '../../../components/CallbackMessage';

import selectServices from '../../../services/selectServices';
import planosServices from '../../../services/planosServices';

import generateAmountArray from '../../../utils/generateAmountArray';
import validateFields from '../../../utils/validateFields';

import styles from './styles.module.scss';

export default function CadastrarPlano() {
  const history = useHistory();
  const params = useParams();

  const { id } = params;

  const { selecionaPeriodoPlano, selecionaStatusPlano, selecionaServico } = selectServices;
  const { atualizaPlano, consultaPlano } = planosServices;

  let objService = {
    ilimitado: 0,
    id_servico: null,
    quantidade: null,
  }

  const [planName, setPlanName] = useState("");
  const [tableValue, setTableValue] = useState("");
  const [planValue, setPlanValue] = useState("");
  const [planTime, setPlanTime] = useState(null);
  const [planStatus, setPlanStatus] = useState(null);
  const [planServices, setPlanServices] = useState([objService]);

  const [amountArray, setAmountArray] = useState([]);
  const [periodoList, setPeriodoList] = useState([]);
  const [statusPlanoList, setStatusPlanoList] = useState([]);
  const [servicosList, setServicosList] = useState([]);

  const [openModalLoginExpirado, setOpenModalLoginExpirado] = useState(false);
  const [loading, setLoading] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackType, setCallbackType] = useState("");
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [callbackErrorList, setCallbackErrorList] = useState([]);

  const handleDuplicate = () => {
    setPlanServices([...planServices, objService]);
  };

  const handleRemove = useCallback((index) => {
    let list = planServices;
    const newData = list.filter((item, pos) => {
      if (index !== pos) {
        return item;
      }

      return false;
    });

    console.log("planServices :: ", planServices);
    console.log("newData :: ", newData);

    setPlanServices(newData);

  }, [planServices]);

  const handleInputChange = useCallback((value, setterFc) => {
    return setterFc(value);
  }, [])

  const loadPlano = useCallback(async () => {
    const response = await consultaPlano(id);

    if (response.status === 200) {
      const { data } = response;

      setPlanName(data.nome_plano);
      setTableValue(data.valor_tabela);
      setPlanValue(data.valor_plano);
      setPlanTime(data.quantidades_meses);
      setPlanStatus(data.status);
      setPlanServices(data.servicos)
    }

    console.log("consultaPlano :: ", response);
  }, [consultaPlano]);

  const loadPeriodoPlano = useCallback(async () => {
    const response = await selecionaPeriodoPlano();

    console.log(response);

    if (response.status === 401) {
      return setOpenModalLoginExpirado(true);
    }

    return setPeriodoList(response.data);
  }, [selecionaPeriodoPlano])

  const loadStatusPlano = useCallback(async () => {
    const response = await selecionaStatusPlano();

    if (response.status === 401) {
      return setOpenModalLoginExpirado(true);
    }

    return setStatusPlanoList(response.data);
  }, [selecionaStatusPlano])

  const loadServicos = useCallback(async () => {
    const response = await selecionaServico();

    if (response.status === 401) {
      return setOpenModalLoginExpirado(true);
    }

    return setServicosList(response.data);
  }, [selecionaServico])

  useEffect(() => {
    (async () => {
      setLoading(true);
      loadPeriodoPlano();
      loadServicos();
      loadStatusPlano();
      await loadPlano();
      setLoading(false);
    })()
  }, [loadPeriodoPlano, loadServicos, loadStatusPlano, loadPlano])

  useEffect(() => {
    let amtArr = generateAmountArray();
    setAmountArray(amtArr);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      setCallbackShown(false);
    }

    if (reason === 'timeout') {
      setCallbackShown(false);
    }

    if (callbackType === 'success') {
      history.push('/lista-planos');
    }
  }

  const handleSubmit = async () => {
    setLoading(true);

    let hasErrors = false;
    let fieldsToValidate = [
      {
        label: "nomedoplano",
        value: planName,
      },
      {
        label: "valortabela",
        value: tableValue,
      },
      {
        label: "valorplano",
        value: planValue,
      },
      {
        label: "periodo",
        value: planTime,
      },
      {
        label: "status",
        value: planStatus,
      },
    ];

    planServices.forEach(plan => {
      fieldsToValidate.push(
        {
          label: 'servico',
          value: plan.id_servico,
        },
        {
          label: 'quantidadeselect',
          value: plan.quantidade,
        }
      )
    })

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      hasErrors = true;
      setLoading(false);
      setCallbackType("error");
      setCallbackErrorList(fieldsErrors);
      setCallbackMessage("Erro!");
      setCallbackShown(true);
    }

    if (hasErrors) return;

    const response = await atualizaPlano(
      id,
      planName,
      planValue,
      tableValue,
      planTime,
      planStatus,
      planServices
    )

    setLoading(false);

    if (response.status === 401) {
      return setOpenModalLoginExpirado(true);
    }

    if (response.status === 500) {
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Erro interno do servidor. Por favor contate o suporte."]);
      setCallbackShown(true);
      setCallbackDuration(3000);
    }

    if (response.status === 200 || response.status === 201) {
      setCallbackType("success");
      setCallbackMessage("Atualizado!");
      setCallbackDuration(2000);
      setCallbackShown(true);
    } else {
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Ocorreu um erro ao cadastrar o plano."]);
      setCallbackShown(true);
      setCallbackDuration(3000);
    }
  }

  return (
    <>
      {loading && <OverlayLoading />}
      <div className="session-container user-register-container">
        <span className="session-container-header">
          <form className={styles.formContainer}>
            <section className={`${styles.gridLayout} ${styles.formSectionOne}`}>
              <div className={styles.planNameInputContainer}>
                <Input
                  label="Nome do plano"
                  required
                  value={planName}
                  handleInputChange={
                    (event) => handleInputChange(event.target.value, setPlanName)
                  }
                />
              </div>
              <div className={styles.tableValueInputContainer}>
                <Input
                  label="Valor Tabela"
                  title="R$"
                  tipo="dinheiro"
                  required
                  value={tableValue}
                  handleInputChange={
                    (event) => handleInputChange(event.value, setTableValue)
                  }
                />
              </div>
              <div className={styles.planValueInputContainer}>
                <Input
                  label="Valor Plano"
                  title="R$"
                  tipo="dinheiro"
                  required
                  value={planValue}
                  handleInputChange={
                    (event) => handleInputChange(event.value, setPlanValue)
                  }
                />
              </div>
              <div className={styles.planTimeInputContainer}>
                <Select
                  list={periodoList}
                  styleType="form"
                  label="Período"
                  initialValue={periodoList.find(item => item.id === planTime) ? periodoList.find(item => item.id === planTime).nome : ""}
                  required
                  callback={(id) => handleInputChange(id, setPlanTime)}
                />
              </div>
              <div className={styles.planStatusInputContainer}>
                <Select
                  list={statusPlanoList}
                  styleType="form"
                  label="Status"
                  initialValue={statusPlanoList.find(item => item.id === planStatus) ? statusPlanoList.find(item => item.id === planStatus).nome : ""}
                  required
                  callback={(id) => handleInputChange(id, setPlanStatus)}
                />
              </div>
            </section>
            <h1 className={styles.sectionTitle}>Serviços contemplados no plano</h1>
            {planServices.map((service, index) => (
              <React.Fragment key={index}>
                <div className={`${styles.gridLayout} ${styles.formSectionTwo}`}>
                  <div className={styles.servicesInputContainer}>
                    <Select
                      list={servicosList}
                      styleType="form"
                      label="Serviço"
                      title="Selecione um serviço"
                      required
                      initialValue={servicosList.find(item => Number(item.id) === Number(planServices[index].id_servico)) ? servicosList.find(item => Number(item.id) === Number(planServices[index].id_servico)).nome : ""}
                      callback={(id) => {
                        let list = [...planServices];
                        list[index].id_servico = id;
                        setPlanServices(list);
                      }}
                    />
                  </div>
                  <div className={styles.quantityInputContainer}>
                    <Select
                      list={amountArray}
                      styleType="form"
                      label="Quantidade"
                      required
                      title="Selecione"
                      initialValue={amountArray.find(item => item.id === planServices[index].quantidade) ? amountArray.find(item => item.id === planServices[index].quantidade).nome : ""}
                      callback={(id) => {
                        let list = [...planServices];
                        if (id === 0) {
                          list[index].quantidade = 1;
                          list[index].ilimitado = 1;
                          return setPlanServices(list);
                        }
                        list[index].quantidade = id;
                        return setPlanServices(list);
                      }}
                    />
                  </div>
                  {index === (planServices.length - 1) ? (
                    <div className={styles.addButton}>
                      <Button text="+ Adicionar" className="success-outline" onClick={handleDuplicate} />
                    </div>
                  ) : (
                      <div className={styles.addButton}>
                        <Button text="- Remover" className="secondary-outline" onClick={() => handleRemove(index)} />
                      </div>
                    )}
                </div>
              </React.Fragment>
            ))}
            <div className="hint-form space-vertical">
              <span className="required-text ">* Campos obrigatórios</span>
            </div>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            type="button"
            text="Salvar"
            className="btn-success"
            onClick={handleSubmit}
          />
        </span>
      </div>
      <ModalLoginExpirado open={openModalLoginExpirado} />
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        type={callbackType}
        errorList={callbackErrorList}
        message={callbackMessage}
        handleClose={handleClose}
      />
    </>
  );
}