import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import ButtonComponent from "../../../components/ButtonComponent";
import Input from "../../../components/Input";
import CallbackMessage from "../../../components/CallbackMessage";
import OverlayLoading from "../../../components/OverlayLoading";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

import empresasService from "../../../services/empresasService";
import categoriasCadastroService from "../../../services/categoriasCadastroService";

import "./styles.css";

function EditarGrupo() {
  const history = useHistory();
  const params = useParams();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [idSelectedEmpresa, setIdSelectedEmpresa] = useState();
  const [valueInput, setValueInput] = useState("");
  const [idGrupo, setIdGrupo] = useState();

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function getEmpresa() {
      setLoading(true);
      const { companyId, groupId } = params;
      const initialEmpresa = await empresasService.consultaEmpresa(companyId);
      if (initialEmpresa && initialEmpresa.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      const initialGrupo = await categoriasCadastroService.consultaGrupo(
        groupId
      );

      const { data } = initialEmpresa;

      setIdSelectedEmpresa(companyId);
      setSelectedEmpresa(data.razao_social);

      setIdGrupo(groupId);
      setValueInput(initialGrupo.data.nome);
      setLoading(false);
    })();
  }, []);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/categorias");
    }
  }

  async function handleClick() {
    if (valueInput === "" || valueInput === null || valueInput === undefined) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(['O campo "Nome do grupo" é obrigatório.']);
      return;
    }

    const response = await categoriasCadastroService.atualizaGrupo(
      idGrupo,
      idSelectedEmpresa,
      valueInput
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200 || response.status === 201) {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage("Grupo atualizado com sucesso!");
      setCallbackDuration(2000);
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
  }

  return (
    <>
      <div className="session-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          {callbackShown && (
            <CallbackMessage
              open={callbackShown}
              type={callbackType}
              handleClose={handleClose}
              message={callbackMessage}
              duration={callbackDuration}
              errorList={callbackErrorList}
            />
          )}
          <form className="session-container-form">
            <div className="input-area">
              <fieldset>
                <Input
                  label="Empresa"
                  value={selectedEmpresa}
                  required
                  readOnly
                />
              </fieldset>
              <fieldset className="input-children">
                <Input
                  title=""
                  label="Nome do grupo"
                  tipo="text"
                  required
                  value={valueInput}
                  handleInputChange={(event) =>
                    setValueInput(event.target.value)
                  }
                />
              </fieldset>
              <div className="required-text">
                <span>* Campos obrigatórios</span>
              </div>
            </div>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <ButtonComponent
            text="Salvar"
            className="btn-success"
            onClick={handleClick}
          />
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default EditarGrupo;
