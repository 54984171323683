import React, { useState, useEffect, useCallback, useRef } from "react";

import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import OverlayLoading from "../../components/OverlayLoading";

import validateFields from "../../utils/validateFields";
import CallbackMessage from "../../components/CallbackMessage";
import exportData from "../../services/exportData";
import { ModalEnviarEmail } from "../../components/ModalEnviarEmail";
import "./styles.scss";
import { Filter } from "../../components/FilterComponent";
import { Search } from "../../components/TableResponsiveComponent";
import useCompany from "../../hooks/useCompany";
import { AccordionDre } from "./componentes/accordion";
import { Grid, useMediaQuery } from "@material-ui/core";
import notasFiscaisService from "../../services/notasFiscaisService";
import { EmptyDre } from "./componentes/emptyDre";
import { toDateDD_MM_YYYY } from "../../utils/dates";
import { numberToCurrency } from "../../utils/functions";
import ConfirmDialog from "../../components/ConfirmDialog";
import clientesService from "../../services/clientesService";
import ReactTooltip from "react-tooltip";
import useHorizontalScroll from "../../hooks/useHorizontalScroll";
import SelectField from "../../components/SelectComponent";
import { Pagination } from "@material-ui/lab";

const listTipos = [
  { id: "produto", nome: "Produto" },
  { id: "servico", nome: "Serviço" },
];

const colunasNotaTipoProduto = [
  { id: "cliente", nome: "Cliente" },
  { id: "emissao", nome: "Emissão" },
  { id: "uf", nome: "UF" },
  { id: "numero", nome: "Número" },
  { id: "serie", nome: "Série" },
  { id: "quantidade_itens", nome: "Qtd. Itens" },
  { id: "base_icms", nome: "Base cálc. ICMS" },
  { id: "valor_icms", nome: "Valor ICMS" },
  { id: "base_icmsst", nome: "Base cálc. ICMS ST" },
  { id: "valor_icmsst", nome: "Valor ICMS ST" },
  { id: "valor_produtos", nome: "Valor total dos produtos" },
  { id: "valor_frete", nome: "Valor do Frete" },
  { id: "valor_seguro", nome: "Valor Seguro" },
  { id: "valor_ipi", nome: "Valor IPI" },
  { id: "desconto", nome: "Desconto" },
  { id: "outras_despesas", nome: "Outras desp. acessórias" },
  { id: "valor_total", nome: "Valor total da nota" },
];

const colunasNotaTipoServico = [
  { id: "cliente", nome: "Cliente" },
  { id: "emissao", nome: "Emissão" },
  { id: "uf", nome: "UF" },
  { id: "numero", nome: "Número" },
  { id: "cod_servico", nome: "Código serviço" },
  { id: "descricao_split", nome: "Descrição serviço" },
  { id: "valor_servico", nome: "Valor serviço" },
  { id: "valor_deducoes", nome: "Valor deduções" },
  { id: "valor_pis", nome: "Valor PIS" },
  { id: "valor_cofins", nome: "Valor COFINS" },
  { id: "valor_inss", nome: "Valor INSS" },
  { id: "valor_irpj", nome: "Valor IR" },
  { id: "valor_csll", nome: "Valor CSLL" },
  { id: "base_calculo", nome: "Base cálc." },
  { id: "valor_iss", nome: "Valor ISS" },
  { id: "credito", nome: "Crédito" },
  { id: "valor_total", nome: "Valor total da nota" },
];

const colunasProduto = [
  { columnName: "Item", dataRef: "ordem" },
  { columnName: "Código", dataRef: "codigo" },
  { columnName: "Descrição do produto/serviço", dataRef: "descricao" },
  { columnName: "NCM/sh", dataRef: "ncm" },
  { columnName: "CST", dataRef: "cst" },
  { columnName: "CFOP", dataRef: "cfop" },
  { columnName: "QTD", dataRef: "quantidade" },
  { columnName: "V. UNIT", dataRef: "valor_unitario_tributario" },
  { columnName: "DESCONTO", dataRef: "valor_desconto" },
  { columnName: "V. TOTAL", dataRef: "valor_total_bruto" },
  { columnName: "BC ICMS", dataRef: "bc_icms" },
  { columnName: "BC ICMS ST", dataRef: "bc_icmsst" },
  { columnName: "V. ICMS", dataRef: "valor_icms" },
  { columnName: "V. ICMS ST", dataRef: "valor_icmsst" },
  { columnName: "V. IPI", dataRef: "valor_ipi" },
  { columnName: "ALÍQ. ICMS", dataRef: "aliquota_icms" },
  { columnName: "ALÍQ. IPI", dataRef: "aliquota_ipi" },
];

const tableOptions = {
  noSearch: true,
  filter: true,
  more: true,
  moreOptions: [
    {
      icon: "export",
      label: "Exportar XLS",
      action: "exportar-xls",
    },
    {
      icon: "export",
      label: "Exportar PDF",
      action: "exportar-pdf",
    },
    {
      icon: "email",
      label: "Enviar e-mail",
      action: "enviar-e-mail",
    },
  ],
};

function filterComponent({
  listEmpresas,
  setEmpresaId,
  setDataInicio,
  dataInicio,
  setDataFim,
  dataFim,
  setClienteId,
  listCliente = [],
  handleApplyFilters,
  handleCleanFilters,
  empresaId,
  clienteId,
  filtroTipoAtivo,
  setFiltroTipoAtivo,
}) {
  return (
    <Filter.Root>
      <Filter.Select
        callback={setEmpresaId}
        label="Empresa"
        options={listEmpresas}
        xs={12}
        title="Selecione uma empresa"
        value={empresaId}
      />
      <Filter.Date
        handleChange={(value) => setDataInicio(value)}
        initDate={dataInicio}
        label="Período de emissão de*"
        xs={6}
      />
      <Filter.Date
        handleChange={(value) => setDataFim(value)}
        initDate={dataFim}
        label="Período de emissão até*"
        xs={6}
      />
      <Filter.Select
        callback={setClienteId}
        label="Cliente"
        options={listCliente}
        xs={12}
        title="Selecione um cliente"
        required={false}
        value={clienteId}
      />
      <Filter.Select
        callback={setFiltroTipoAtivo}
        label="Tipo"
        options={listTipos}
        xs={12}
        title="Selecione um tipo"
        value={filtroTipoAtivo}
      />
      <Filter.Buttons>
        <Filter.Button
          text="Filtrar"
          className="btn-primary"
          onClick={handleApplyFilters}
        />
        <Filter.Button
          text="Limpar Filtros"
          className="default-outline clean-filters-button"
          onClick={handleCleanFilters}
        />
      </Filter.Buttons>
    </Filter.Root>
  );
}

function HeaderTable({ onTableMoreAction, tableOptions, ...rest }) {
  return (
    <Search
      callback={() => {}}
      tableOptions={tableOptions}
      filterComponent={filterComponent({ ...rest })}
      callbackMenu={onTableMoreAction}
    />
  );
}

function NotasDeSaidaRelatorio() {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const {
    companyList: listEmpresas,
    selectedCompany: empresaId,
    setSelectedCompany: setEmpresaId,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("relatorio_nota_saida");

  const tableRef = useRef(null);
  useHorizontalScroll(tableRef?.current);

  const [loading, setLoading] = useState(false);
  const [listCliente, setListCliente] = useState([]);
  const [clienteId, setClienteId] = useState(null);
  const [filtered, setFiltered] = useState(false);
  const [filtroTipoAtivo, setFiltroTipoAtivo] = useState(null);

  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [listRequest, setListRequest] = useState([]);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);

  const [tipoExportacao, setTipoExportacao] = useState(null);
  const [exportarDetalhamento, setExportarDetalhamento] = useState(null);

  const [filterOptions, setFilterOptions] = useState({
    id_empresa: null,
    periodo_emissao_de: null,
    periodo_emissao_ate: null,
    tipo: null,
    id_cliente: null,
    page: 1,
    per_page: 10
  });

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    nextPageUrl: null,
    prevPageUrl: null,
    perPage: 10
  });

  const [openModalEmail, setOpenModalEmail] = useState(false);

  const validationFields = [
    {
      label: "empresa",
      value: empresaId,
    },
    {
      label: "periodo_de_emissao_de",
      value: dataInicio,
    },
    {
      label: "periodo_de_emissao_ate",
      value: dataFim,
    },
    {
      label: "tipo",
      value: filtroTipoAtivo,
    },
  ];

  const exportToPDF = {
    url: "exportar-relatorio-nfe-pdf",
    fileName: "cbhub_relatorio_notas_de_saida",
    fileType: "pdf",
  };

  const exportToXLS = {
    url: "exportar-relatorio-nfe-excel",
    fileName: "cbhub_relatorio_notas_de_saida",
    fileType: "xlsx",
  };

  const formataDescricao = (descricao, cliente, idNota) => {
    let novaDescricao = null;
    if (descricao && cliente) {
      novaDescricao = descricao?.substring(0, cliente.length);
    } else if (descricao) {
      novaDescricao = descricao?.substring(0, 30);
    }
    return (
      <div
        data-tip
        data-for={`show-label-${idNota}`}
        className="tootip-relatorio-nota"
      >
        <span>{novaDescricao}</span>
        <ReactTooltip id={`show-label-${idNota}`} type="success">
          <span>{descricao}</span>
        </ReactTooltip>
      </div>
    );
  };

  const updateTable = useCallback(async () => {
    // window.scrollTo(0, 0);
    setLoading(true);

    const response = await notasFiscaisService.listaRelatorioNotaSaida({
      ...filterOptions,
    });
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      const data = response.data.data.map((item) => {
        return {
          ...item,
          descricao_split: formataDescricao(
            item.descricao,
            item.cliente,
            item.id_nota_fiscal_eletronica
          ),
          emissao: toDateDD_MM_YYYY(item.emissao),
          total_produtos: numberToCurrency(item.total_produtos),
          valor_bc: numberToCurrency(item.valor_bc),
          valor_bcst: numberToCurrency(item.valor_bcst),
          valor_frete: numberToCurrency(item.valor_frete),
          valor_icms: numberToCurrency(item.valor_icms),
          valor_icmsst: numberToCurrency(item.valor_icmsst),
          valor_ipi: numberToCurrency(item.valor_ipi),
          valor_seguro: numberToCurrency(item.valor_seguro),
          valor_total: numberToCurrency(item.valor_total),
          desconto: numberToCurrency(item.desconto),
        };
      });
      setListRequest(data);

      const pagination = {
        currentPage: response.data.current_page,
        totalPages: response.data.last_page,
        totalItems: response.data.total,
        nextPageUrl: response.data.next_page_url,
        prevPageUrl: response.data.prev_page_url,
        perPage: response.data.per_page
      };

      setPagination(pagination);
    } else {
    }
    setLoading(false);
  }, [
    notasFiscaisService,
    filterOptions,
    setListRequest,
    setLoading,
    setOpenLoginExpirado,
  ]);

  const [perPageSelect, setPerPageSelect] = useState([
    { id: 10, nome: "10 por página" },
    { id: 25, nome: "25 por página" },
    { id: 50, nome: "50 por página" },
    { id: 100, nome: "100 por página" },
  ]);
  
  const handleChangePage = (event, newPage) => {
    setPagination(prevPagination => ({
      ...prevPagination,
      currentPage: newPage
    }));

    setFilterOptions(prevOptions => ({
      ...prevOptions,
      page: newPage
    }));
  };

  const handleChangePerPage = (selectedItem) => {
    setPagination(prevPagination => ({
        ...prevPagination,
        perPage: selectedItem.id
    }));

    const maxCurrentPage = Math.ceil(pagination.totalItems / selectedItem);

    const newPage = Math.min(pagination.currentPage, maxCurrentPage);

    setFilterOptions(prevOptions => ({
        ...prevOptions,
        page: newPage,
        per_page: selectedItem
    }));
  };

  async function loadClientes(id) {
    let response = await clientesService.selecionaCliente(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) setListCliente(response.data);
  }

  const onTableMoreAction = async ({ action }) => {
    console.log(action);

    const fields = [...validationFields];

    const validateFieldsErrors = validateFields(fields);

    if (validateFieldsErrors.length !== 0) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackDuration(3000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(validateFieldsErrors);
      setLoading(false);
      return;
    }
    if (filtroTipoAtivo === "servico") {
      return onConfirmExport({ detalhamento: false, action });
    }
    setTipoExportacao(action);
    setConfirmRemoveDialog(true);
  };

  const onConfirmExport = async ({ detalhamento, action = null }) => {
    console.log(detalhamento);
    setExportarDetalhamento(detalhamento);
    if ((action || tipoExportacao) === "enviar-e-mail") {
      console.log("enviar-e-mail");
      setOpenModalEmail(true);
      return;
    }
    let params = {};
    if ((action || tipoExportacao) === "exportar-xls") {
      params = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: {
          id_empresa: empresaId,
          periodo_emissao_de: dataInicio,
          periodo_emissao_ate: dataFim,
          tipo: filtroTipoAtivo,
          id_cliente: clienteId,
          incluir_detalhamento: detalhamento ? 1 : 0,
        },
      };
    } else if ((action || tipoExportacao) === "exportar-pdf") {
      params = {
        url: exportToPDF.url,
        fileName: exportToPDF.fileName,
        fileType: exportToPDF.fileType,
        params: {
          id_empresa: empresaId,
          periodo_emissao_de: dataInicio,
          periodo_emissao_ate: dataFim,
          tipo: filtroTipoAtivo,
          id_cliente: clienteId,
          incluir_detalhamento: detalhamento ? 1 : 0,
        },
      };
    }
    setLoading(true);
    const response = await exportData(params);

    if (response.status === 500) {
      setCallbackErrorList([
        "Erro interno no servidor. Por favor, contate o suporte",
      ]);
      setCallbackShown(true);
      setCallbackType("error");
    } else if (response.status !== 201 && response.status !== 200) {
      setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
      setCallbackShown(true);
      setCallbackType("error");
    }

    handleCloseConfirm();
    setLoading(false);
  };

  function handleClose() {
    setCallbackShown(false);
    setCallbackType("");
  }

  async function handleSendEmail(data = false) {
    console.log(data);
    if (!data) {
      setOpenModalEmail(false);
      return;
    }
    setLoading(true);
    const cco = data.cco?.split(";")?.map((item) => {
      return item?.trim();
    });
    const emails = data.para?.split(";")?.map((item) => {
      return item?.trim();
    });
    const response = await notasFiscaisService.enviarEmailRelatorioNotaSaida({
      id_empresa: empresaId,
      periodo_emissao_de: dataInicio,
      periodo_emissao_ate: dataFim,
      tipo: filtroTipoAtivo,
      id_cliente: clienteId,
      incluir_detalhamento: exportarDetalhamento ? 1 : 0,
      cco,
      emails,
      assunto: data.assunto,
      mensagem: data.mensagem,
    });

    if (response?.status === 200) {
      setCallbackErrorList([]);
      setCallbackMessage([
        response?.data?.msg ? response?.data?.msg : "Relátorio enviado",
      ]);
      setCallbackShown(true);
      setCallbackType("success");
      setOpenModalEmail(false);
      handleCloseConfirm();
    }

    setLoading(false);
  }

  const handleApplyFilters = () => {
    const fields = [...validationFields];
    setEmpresaPagina("relatorio_nota_saida", empresaId);

    const validateFieldsErrors = validateFields(fields);

    if (validateFieldsErrors.length !== 0) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackDuration(3000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(validateFieldsErrors);
      setLoading(false);
      return;
    }
    setFilterOptions({
      id_empresa: empresaId,
      periodo_emissao_de: dataInicio,
      periodo_emissao_ate: dataFim,
      tipo: filtroTipoAtivo,
      id_cliente: clienteId,
    });
    setFiltered(true);
  };

  const handleCleanFilters = async () => {
    setEmpresaId(null);
    setListRequest(null);
    setDataInicio("");
    setDataFim("");
    setClienteId(null);
    setFiltroTipoAtivo(null);
    setEmpresaPagina("relatorio_nota_saida", null);

    setFilterOptions({
      id_empresa: null,
      periodo_emissao_de: null,
      periodo_emissao_ate: null,
      tipo: filtroTipoAtivo,
      id_cliente: null,
    });
    setFiltered(false);
  };

  const colunasHeader = () =>
    filtroTipoAtivo
      ? filtroTipoAtivo === "produto"
        ? colunasNotaTipoProduto
        : colunasNotaTipoServico
      : [];

  const configuracaoTabela = {
    columnArray: colunasProduto,
    display: {
      totalResults: true,
    },
  };

  const handleCloseConfirm = () => {
    setTipoExportacao(null);
    setConfirmRemoveDialog(false);
    setExportarDetalhamento(false);
  };

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("relatorio_nota_saida");
    if (empresaStorage) {
      setEmpresaId(empresaStorage);
    }
  }, []);

  useEffect(() => {
    if (empresaId) {
      loadClientes(empresaId);
    }
  }, [empresaId]);

  useEffect(() => {
    if (
      filterOptions.id_empresa &&
      filterOptions.periodo_emissao_de &&
      filterOptions.periodo_emissao_ate
    ) {
      updateTable();
    }
  }, [updateTable]);

  return (
    <>
      {loading && <OverlayLoading />}
      <div className="table-no-header max-width-for-page height-auto">
        <HeaderTable
          listEmpresas={listEmpresas}
          listCliente={listCliente}
          setEmpresaId={setEmpresaId}
          setClienteId={setClienteId}
          setDataInicio={setDataInicio}
          dataInicio={dataInicio}
          setDataFim={setDataFim}
          dataFim={dataFim}
          empresaId={empresaId}
          clienteId={clienteId}
          filtroTipoAtivo={filtroTipoAtivo}
          setFiltroTipoAtivo={setFiltroTipoAtivo}
          handleApplyFilters={handleApplyFilters}
          handleCleanFilters={handleCleanFilters}
          onTableMoreAction={onTableMoreAction}
          tableOptions={tableOptions}
        />
        {listRequest?.length ? (
          <div className="session-container">
            <span className="session-container-header">
              <Grid
                container
                direction="column"
                className="container-relatorio-notas container-relatorio"
                ref={tableRef}
              >
                {!isMobile && (
                  <>
                    <Grid
                      item
                      spacing={3}
                      className="container-row-relatorio-notas"
                    >
                      {colunasHeader().map((item, index) => (
                        <Grid item key={index} className="name-column">
                          {item?.nome}
                        </Grid>
                      ))}
                    </Grid>
                    <div style={{ height: 40 }} />
                  </>
                )}

                <Grid item xs sm md>
                  <AccordionDre
                    columns={colunasHeader()}
                    dados={listRequest}
                    configuracaoTabela={configuracaoTabela}
                    tipoAtivo={filterOptions.tipo}
                  />
                </Grid>
              </Grid>
            </span>
            {pagination && 
              <div className="table-footer">
                {!isMobile && (
                  <>
                    <div className="table-perpage-wrapper">
                      <SelectField
                        initialValue={perPageSelect.find(option => option.id === pagination?.perPage) || perPageSelect[0]}
                        list={perPageSelect}
                        callback={handleChangePerPage}
                      />
                    </div>
                    <div className="table-total-data-lis-wrapper">
                      <span className="table-total-data-list">
                        {`Total: ${pagination?.totalItems} resultados`}
                      </span>
                    </div>
                  </>
                )}
                <div className="table-configuration"> 
                  <Pagination
                    count={pagination?.totalPages}
                    variant="outlined"
                    shape="rounded"
                    size="large"
                    page={pagination?.currentPage}
                    onChange={handleChangePage}
                  />
                </div>
              </div>
            }
          </div>
        ) : (
          <EmptyDre filtered={filtered} />
        )}
      </div>
      <ConfirmDialog
        title="Detalhamento dos itens"
        description="Exportar o arquivo com o detalhamento dos itens das notas?"
        open={confirmRemoveDialog}
        onCancel={() => onConfirmExport({ detalhamento: true })}
        onAccept={() => onConfirmExport({ detalhamento: false })}
        onClose={handleCloseConfirm}
        cancelLabel="Com detalhamento"
        acceptLabel="Sem detalhamento"
      />
      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={() => handleClose()}
      />
      <ModalEnviarEmail
        open={openModalEmail}
        onClose={handleSendEmail}
        setCallbackErrorList={setCallbackErrorList}
        callbackErrorList={callbackErrorList}
        callbackShown={callbackShown}
        setCallbackShown={setCallbackShown}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default NotasDeSaidaRelatorio;
