import Axios from "./Axios";

export const dreOrcamentarioService = {
  getDreOrcamentario: async ({ id_empresa, ano, all }) => {
    const response = await Axios.get("/lista-dre-orcamentario", {
      params: {
        id_empresa,
        ano,
        all: all ? 1 : null,
      },
    })
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  enviarEmailRelatorio: async ({
    assunto = null,
    mensagem = null,
    emails = [],
    cco = [],
    id_empresa = null,
    mes = null,
    ano = null,
    all = null,
  }) => {
    const response = await Axios.get(
      "/exportar-relatorio-dre-orcamentario-email",
      {
        params: {
          id_empresa,
          ano,
          all,
          assunto,
          mensagem,
          emails,
          cco,
        },
      }
    )
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
};
