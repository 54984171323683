import React, { useCallback, useEffect, useState } from "react";
import { Divider, Grid } from "@material-ui/core";
import styles from "./styles.module.scss";
import Input from "../../../../../components/Input";
import SelectComponent from "../../../../../components/SelectComponent";
import selectService from "../../../../../services/selectServices";
import ButtonComponent from "../../../../../components/ButtonComponent";

const simNaoOpcoes = [
  {
    id: 1,
    nome: "Sim",
  },
  {
    id: 0,
    nome: "Não",
  },
];

export function Beneficios({ locationState, dados, setDados }) {
  const { selecionaFormaPagamentoDp, selecionaTipoDescontoDp } = selectService;

  const [listFormaPagamentoDp, setListFormaPagamentoDp] = useState([]);
  const [listTipoDescontoDp, setListTipoDescontoDp] = useState([]);

  const loadFormaPagamentoDp = useCallback(async () => {
    await selecionaFormaPagamentoDp().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        if (
          typeof response.data === "object" &&
          Object.keys(response.data)?.length > 0
        ) {
          const arrayFormatted = Object.keys(response.data).map((key) => ({
            id: key,
            nome: response.data[key],
          }));
          setListFormaPagamentoDp(arrayFormatted);
        } else {
          setListFormaPagamentoDp([]);
        }
      }
    });
  }, []);

  const loadTipoDescontoDp = useCallback(async () => {
    await selecionaTipoDescontoDp().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        if (
          typeof response.data === "object" &&
          Object.keys(response.data)?.length > 0
        ) {
          const arrayFormatted = Object.keys(response.data).map((key) => ({
            id: key,
            nome: response.data[key],
          }));
          setListTipoDescontoDp(arrayFormatted);
        } else {
          setListTipoDescontoDp([]);
        }
      }
    });
  }, []);

  function handleChangeDados(field, value, index = -1) {
    console.log(field, value, index);
    let novosDados = { ...dados };
    if (index >= 0) {
      novosDados.valeTransporte[index][field] = value;
      setDados(novosDados);
    } else {
      if (field?.split(".")?.length === 2) {
        const fieldSplit = field.split(".");
        novosDados[fieldSplit[0]][fieldSplit[1]] = value;
        setDados(novosDados);
      } else {
        novosDados[field] = value;
        setDados(novosDados);
      }
    }
  }

  function handleAddValeTransporte() {
    let dadosValeTransporte = [...dados?.valeTransporte];
    dadosValeTransporte.push({
      descricao: null,
      quantidadePassagens: null,
      valor: null,
      numeroBilhete: null,
    });

    handleChangeDados("valeTransporte", dadosValeTransporte);
  }

  function handleRemoveValeTransporte(index) {
    let dadosValeTransporte = [...dados?.valeTransporte];
    dadosValeTransporte = dadosValeTransporte.filter((_, i) => i !== index);

    handleChangeDados("valeTransporte", dadosValeTransporte);
  }

  useEffect(() => {
    loadFormaPagamentoDp();
    loadTipoDescontoDp();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid container>
        <div className={`label ${styles.subSession} ${styles.mlSubSession}`}>
          Vale Transporte
        </div>
      </Grid>
      <Grid container spacing={2}>
        {dados?.valeTransporte?.map((item, index) => {
          return (
            <Grid container key={index} spacing={2}>
              {index > 0 && (
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <Divider />
                </Grid>
              )}
              <Grid item xs={12}>
                <Input
                  label="Descrição"
                  name="descricao"
                  required
                  value={item?.descricao}
                  handleInputChange={async (e) => {
                    handleChangeDados("descricao", e.target.value, index);
                  }}
                  disabled={locationState?.details}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Quantidade de passagens por dia"
                  name="quantidadePassagens"
                  required
                  value={item?.quantidadePassagens}
                  handleInputChange={async (e) => {
                    handleChangeDados(
                      "quantidadePassagens",
                      e.target.value,
                      index
                    );
                  }}
                  disabled={locationState?.details}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Valor"
                  name="valor"
                  tipo="dinheiro"
                  placeholder="R$"
                  required
                  value={item?.valor}
                  handleInputChange={async (e) => {
                    handleChangeDados("valor", e.value, index);
                  }}
                  disabled={locationState?.details}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Número do Bilhete"
                  name="numeroBilhete"
                  required
                  value={item?.numeroBilhete}
                  handleInputChange={async (e) => {
                    handleChangeDados("numeroBilhete", e.target.value, index);
                  }}
                  disabled={locationState?.details}
                />
              </Grid>
              {index > 0 && !locationState?.details && (
                <Grid xs={12}>
                  <ButtonComponent
                    text="Remover Vale Transporte"
                    className={`secondary-outline ${styles.btnAddAndRemove}`}
                    onClick={() => handleRemoveValeTransporte(index)}
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
        {!locationState?.details ? <div className={styles.containerButton}>
          <ButtonComponent
            text="+ Adicionar Vale Transporte"
            onClick={() => handleAddValeTransporte()}
            className={`success-outline ${styles.btnAdd}`}
          />
        </div> : null}
      </Grid>

      <Grid container>
        <div className={`label ${styles.subSession}`}>Vale Alimentação</div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor"
            name="valor"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.valeAlimentacao?.valor}
            handleInputChange={async (e) => {
              handleChangeDados("valeAlimentacao.valor", e.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Forma de pagamento"
            title="Selecione uma opção"
            list={listFormaPagamentoDp}
            initialValue={
              listFormaPagamentoDp.find(
                (item) =>
                  Number(item.id) ===
                  Number(dados?.valeAlimentacao?.formaPagamento)
              )
                ? listFormaPagamentoDp.find(
                    (item) =>
                      Number(item.id) ===
                      Number(dados?.valeAlimentacao?.formaPagamento)
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("valeAlimentacao.formaPagamento", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Descontado"
            title="Selecione uma opção"
            list={simNaoOpcoes}
            initialValue={
              simNaoOpcoes.find(
                (item) => item.id === dados?.valeAlimentacao?.descontado
              )
                ? simNaoOpcoes.find(
                    (item) => item.id === dados?.valeAlimentacao?.descontado
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("valeAlimentacao.descontado", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Tipo de desconto"
            title="Selecione uma opção"
            list={listTipoDescontoDp}
            initialValue={
              listTipoDescontoDp.find(
                (item) =>
                  Number(item.id) ===
                  Number(dados?.valeAlimentacao?.tipoDesconto)
              )
                ? listTipoDescontoDp.find(
                    (item) =>
                      Number(item.id) ===
                      Number(dados?.valeAlimentacao?.tipoDesconto)
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("valeAlimentacao.tipoDesconto", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            label="Valor descontado"
            name="valorDescontado"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.valeAlimentacao?.valorDescontado}
            handleInputChange={async (e) => {
              handleChangeDados("valeAlimentacao.valorDescontado", e.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
      </Grid>

      <Grid container>
        <div className={`label ${styles.subSession}`}>Vale Refeição</div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor"
            name="valor"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.valeRefeicao?.valor}
            handleInputChange={async (e) => {
              handleChangeDados("valeRefeicao.valor", e.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Forma de pagamento"
            title="Selecione uma opção"
            list={listFormaPagamentoDp}
            initialValue={
              listFormaPagamentoDp.find(
                (item) =>
                  Number(item.id) ===
                  Number(dados?.valeRefeicao?.formaPagamento)
              )
                ? listFormaPagamentoDp.find(
                    (item) =>
                      Number(item.id) ===
                      Number(dados?.valeRefeicao?.formaPagamento)
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("valeRefeicao.formaPagamento", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Descontado"
            title="Selecione uma opção"
            list={simNaoOpcoes}
            initialValue={
              simNaoOpcoes.find(
                (item) => item.id === dados?.valeRefeicao?.descontado
              )
                ? simNaoOpcoes.find(
                    (item) => item.id === dados?.valeRefeicao?.descontado
                  ).nome
                : ""
            }
            callback={(id) => handleChangeDados("valeRefeicao.descontado", id)}
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Tipo de desconto"
            title="Selecione uma opção"
            list={listTipoDescontoDp}
            initialValue={
              listTipoDescontoDp.find(
                (item) =>
                  Number(item.id) === Number(dados?.valeRefeicao?.tipoDesconto)
              )
                ? listTipoDescontoDp.find(
                    (item) =>
                      Number(item.id) ===
                      Number(dados?.valeRefeicao?.tipoDesconto)
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("valeRefeicao.tipoDesconto", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            label="Valor descontado"
            name="valorDescontado"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.valeRefeicao?.valorDescontado}
            handleInputChange={async (e) => {
              handleChangeDados("valeRefeicao.valorDescontado", e.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
      </Grid>

      <Grid container>
        <div className={`label ${styles.subSession}`}>Assistência Médica</div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Input
            label="Operadora"
            name="operadora"
            value={dados?.assistenciaMedica?.operadora}
            handleInputChange={async (e) => {
              handleChangeDados("assistenciaMedica.operadora", e.target.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Plano"
            name="plano"
            value={dados?.assistenciaMedica?.plano}
            handleInputChange={async (e) => {
              handleChangeDados("assistenciaMedica.plano", e.target.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor"
            name="valor"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.assistenciaMedica?.valor}
            handleInputChange={async (e) => {
              handleChangeDados("assistenciaMedica.valor", e.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Descontado"
            title="Selecione uma opção"
            list={simNaoOpcoes}
            initialValue={
              simNaoOpcoes.find(
                (item) => item.id === dados?.assistenciaMedica?.descontado
              )
                ? simNaoOpcoes.find(
                    (item) => item.id === dados?.assistenciaMedica?.descontado
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("assistenciaMedica.descontado", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Tipo de desconto"
            title="Selecione uma opção"
            list={listTipoDescontoDp}
            initialValue={
              listTipoDescontoDp.find(
                (item) =>
                  Number(item.id) ===
                  Number(dados?.assistenciaMedica?.tipoDesconto)
              )
                ? listTipoDescontoDp.find(
                    (item) =>
                      Number(item.id) ===
                      Number(dados?.assistenciaMedica?.tipoDesconto)
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("assistenciaMedica.tipoDesconto", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor descontado"
            name="valorDescontado"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.assistenciaMedica?.valorDescontado}
            handleInputChange={async (e) => {
              handleChangeDados("assistenciaMedica.valorDescontado", e.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Dependentes"
            title="Selecione uma opção"
            list={simNaoOpcoes}
            initialValue={
              simNaoOpcoes.find(
                (item) => item.id === dados?.assistenciaMedica?.dependentes
              )
                ? simNaoOpcoes.find(
                    (item) => item.id === dados?.assistenciaMedica?.dependentes
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("assistenciaMedica.dependentes", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Incluso no plano"
            title="Selecione uma opção"
            list={simNaoOpcoes}
            initialValue={
              simNaoOpcoes.find(
                (item) => item.id === dados?.assistenciaMedica?.inclusoNoPlano
              )
                ? simNaoOpcoes.find(
                    (item) =>
                      item.id === dados?.assistenciaMedica?.inclusoNoPlano
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("assistenciaMedica.inclusoNoPlano", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Tipo de desconto"
            title="Selecione uma opção"
            list={listTipoDescontoDp}
            initialValue={
              listTipoDescontoDp.find(
                (item) =>
                  Number(item.id) ===
                  Number(dados?.assistenciaMedica?.tipoDescontoDependentes)
              )
                ? listTipoDescontoDp.find(
                    (item) =>
                      Number(item.id) ===
                      Number(dados?.assistenciaMedica?.tipoDescontoDependentes)
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("assistenciaMedica.tipoDescontoDependentes", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor descontado"
            name="valorDescontadoDependentes"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.assistenciaMedica?.valorDescontadoDependentes}
            handleInputChange={async (e) => {
              handleChangeDados(
                "assistenciaMedica.valorDescontadoDependentes",
                e.value
              );
            }}
            disabled={locationState?.details}
          />
        </Grid>
      </Grid>

      <Grid container>
        <div className={`label ${styles.subSession}`}>
          Assistência Odontologica
        </div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Input
            label="Operadora"
            name="operadora"
            value={dados?.assistenciaOdontologica?.operadora}
            handleInputChange={async (e) => {
              handleChangeDados(
                "assistenciaOdontologica.operadora",
                e.target.value
              );
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Plano"
            name="plano"
            value={dados?.assistenciaOdontologica?.plano}
            handleInputChange={async (e) => {
              handleChangeDados(
                "assistenciaOdontologica.plano",
                e.target.value
              );
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor"
            name="valor"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.assistenciaOdontologica?.valor}
            handleInputChange={async (e) => {
              handleChangeDados("assistenciaOdontologica.valor", e.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Descontado"
            title="Selecione uma opção"
            list={simNaoOpcoes}
            initialValue={
              simNaoOpcoes.find(
                (item) => item.id === dados?.assistenciaOdontologica?.descontado
              )
                ? simNaoOpcoes.find(
                    (item) =>
                      item.id === dados?.assistenciaOdontologica?.descontado
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("assistenciaOdontologica.descontado", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Tipo de desconto"
            title="Selecione uma opção"
            list={listTipoDescontoDp}
            initialValue={
              listTipoDescontoDp.find(
                (item) =>
                  Number(item.id) ===
                  Number(dados?.assistenciaOdontologica?.tipoDesconto)
              )
                ? listTipoDescontoDp.find(
                    (item) =>
                      Number(item.id) ===
                      Number(dados?.assistenciaOdontologica?.tipoDesconto)
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("assistenciaOdontologica.tipoDesconto", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor descontado"
            name="valorDescontado"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.assistenciaOdontologica?.valorDescontado}
            handleInputChange={async (e) => {
              handleChangeDados(
                "assistenciaOdontologica.valorDescontado",
                e.value
              );
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Dependentes"
            title="Selecione uma opção"
            list={simNaoOpcoes}
            initialValue={
              simNaoOpcoes.find(
                (item) =>
                  item.id === dados?.assistenciaOdontologica?.dependentes
              )
                ? simNaoOpcoes.find(
                    (item) =>
                      item.id === dados?.assistenciaOdontologica?.dependentes
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("assistenciaOdontologica.dependentes", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Incluso no plano"
            title="Selecione uma opção"
            list={simNaoOpcoes}
            initialValue={
              simNaoOpcoes.find(
                (item) =>
                  item.id === dados?.assistenciaOdontologica?.inclusoNoPlano
              )
                ? simNaoOpcoes.find(
                    (item) =>
                      item.id === dados?.assistenciaOdontologica?.inclusoNoPlano
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("assistenciaOdontologica.inclusoNoPlano", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Tipo de desconto"
            title="Selecione uma opção"
            list={listTipoDescontoDp}
            initialValue={
              listTipoDescontoDp.find(
                (item) =>
                  item.id ===
                  Number(
                    dados?.assistenciaOdontologica?.tipoDescontoDependentes
                  )
              )
                ? listTipoDescontoDp.find(
                    (item) =>
                      item.id ===
                      Number(
                        dados?.assistenciaOdontologica?.tipoDescontoDependentes
                      )
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados(
                "assistenciaOdontologica.tipoDescontoDependentes",
                id
              )
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor descontado"
            name="valorDescontadoDependentes"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.assistenciaOdontologica?.valorDescontadoDependentes}
            handleInputChange={async (e) => {
              handleChangeDados(
                "assistenciaOdontologica.valorDescontadoDependentes",
                e.value
              );
            }}
            disabled={locationState?.details}
          />
        </Grid>
      </Grid>

      <Grid container>
        <div className={`label ${styles.subSession}`}>Seguro de Vida</div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            label="Descrição"
            name="descricao"
            value={dados?.seguroVida?.descricao}
            handleInputChange={async (e) => {
              handleChangeDados("seguroVida.descricao", e.target.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Seguradora"
            name="seguradora"
            value={dados?.seguroVida?.seguradora}
            handleInputChange={async (e) => {
              handleChangeDados("seguroVida.seguradora", e.target.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor"
            name="valor"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.seguroVida?.valor}
            handleInputChange={async (e) => {
              handleChangeDados("seguroVida.valor", e.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Descontado"
            title="Selecione uma opção"
            list={simNaoOpcoes}
            initialValue={
              simNaoOpcoes.find(
                (item) => item.id === dados?.seguroVida?.descontado
              )
                ? simNaoOpcoes.find(
                    (item) => item.id === dados?.seguroVida?.descontado
                  ).nome
                : ""
            }
            callback={(id) => handleChangeDados("seguroVida.descontado", id)}
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Tipo de desconto"
            title="Selecione uma opção"
            list={listTipoDescontoDp}
            initialValue={
              listTipoDescontoDp.find(
                (item) =>
                  Number(item.id) === Number(dados?.seguroVida?.tipoDesconto)
              )
                ? listTipoDescontoDp.find(
                    (item) =>
                      Number(item.id) ===
                      Number(dados?.seguroVida?.tipoDesconto)
                  ).nome
                : ""
            }
            callback={(id) => handleChangeDados("seguroVida.tipoDesconto", id)}
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor descontado"
            name="valorDescontado"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.seguroVida?.valorDescontado}
            handleInputChange={async (e) => {
              handleChangeDados("seguroVida.valorDescontado", e.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
      </Grid>

      <Grid container>
        <div className={`label ${styles.subSession}`}>Bolsa Auxílio</div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Input
            label="Instituição"
            name="instituicao"
            value={dados?.bolsaAuxilio?.instituicao}
            handleInputChange={async (e) => {
              handleChangeDados("bolsaAuxilio.instituicao", e.target.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Curso"
            name="curso"
            value={dados?.bolsaAuxilio?.curso}
            handleInputChange={async (e) => {
              handleChangeDados("bolsaAuxilio.curso", e.target.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor"
            name="valor"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.bolsaAuxilio?.valor}
            handleInputChange={async (e) => {
              handleChangeDados("bolsaAuxilio.valor", e.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Descontado"
            title="Selecione uma opção"
            list={simNaoOpcoes}
            initialValue={
              simNaoOpcoes.find(
                (item) => item.id === dados?.bolsaAuxilio?.descontado
              )
                ? simNaoOpcoes.find(
                    (item) => item.id === dados?.bolsaAuxilio?.descontado
                  ).nome
                : ""
            }
            callback={(id) => handleChangeDados("bolsaAuxilio.descontado", id)}
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Tipo de desconto"
            title="Selecione uma opção"
            list={listTipoDescontoDp}
            initialValue={
              listTipoDescontoDp.find(
                (item) =>
                  Number(item.id) === Number(dados?.bolsaAuxilio?.tipoDesconto)
              )
                ? listTipoDescontoDp.find(
                    (item) =>
                      Number(item.id) ===
                      Number(dados?.bolsaAuxilio?.tipoDesconto)
                  ).nome
                : ""
            }
            callback={(id) =>
              handleChangeDados("bolsaAuxilio.tipoDesconto", id)
            }
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor descontado"
            name="valorDescontado"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.bolsaAuxilio?.valorDescontado}
            handleInputChange={async (e) => {
              handleChangeDados("bolsaAuxilio.valorDescontado", e.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
      </Grid>

      <Grid container>
        <div className={`label ${styles.subSession}`}>Outros</div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            label="Descrição"
            name="descricao"
            value={dados?.outros?.descricao}
            handleInputChange={async (e) => {
              handleChangeDados("outros.descricao", e.target.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor"
            name="valor"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.outros?.valor}
            handleInputChange={async (e) => {
              handleChangeDados("outros.valor", e.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Descontado"
            title="Selecione uma opção"
            list={simNaoOpcoes}
            initialValue={
              simNaoOpcoes.find((item) => item.id === dados?.outros?.descontado)
                ? simNaoOpcoes.find(
                    (item) => item.id === dados?.outros?.descontado
                  ).nome
                : ""
            }
            callback={(id) => handleChangeDados("outros.descontado", id)}
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectComponent
            styleType="form"
            label="Tipo de desconto"
            title="Selecione uma opção"
            list={listTipoDescontoDp}
            initialValue={
              listTipoDescontoDp.find(
                (item) =>
                  Number(item.id) === Number(dados?.outros?.tipoDesconto)
              )
                ? listTipoDescontoDp.find(
                    (item) =>
                      Number(item.id) === Number(dados?.outros?.tipoDesconto)
                  ).nome
                : ""
            }
            callback={(id) => handleChangeDados("outros.tipoDesconto", id)}
            isDisabled={locationState?.details}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Valor descontado"
            name="valorDescontado"
            tipo="dinheiro"
            placeholder="R$"
            value={dados?.outros?.valorDescontado}
            handleInputChange={async (e) => {
              handleChangeDados("outros.valorDescontado", e.value);
            }}
            disabled={locationState?.details}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <span className="required-text">* Campos obrigatórios</span>
      </Grid>
    </Grid>
  );
}
