import { useState } from "react";

import parseJSON from '../utils/parseJSON'
import notificationService from "../services/notificationService";

export default function useNavbar() {
  const notification = parseJSON(window.localStorage.getItem('notificacoes'));

  const [listNotification, setListNotification] = useState(notification || []);
  const [chaveSelected, setChaveSelected] = useState("");
  const [showNavbar, setSN] = useState(window.innerWidth >= 1100);

  const setShowNavbar = (value) => {
    if (window.innerWidth >= 1100) setSN(true);
    else setSN(value);
  }

  async function fetchNotification() {
    const response = await notificationService.getLista();
    if (response.status === 200) {
      window.localStorage.setItem("notificacoes", JSON.stringify(response.data));
      setListNotification(response.data);
    };
    return response.data || [];
  }

  async function readNotification() {
    if (chaveSelected !== "") {
      const response = await notificationService.lerNotificacao(chaveSelected);
      setChaveSelected("");
      if (response.status === 200) {
        await fetchNotification();
      }
    }
  }

  return {
    listNotification, setListNotification, fetchNotification,
    readNotification, chaveSelected, setChaveSelected,
    showNavbar, setShowNavbar
  }
}
