import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { AuthContext } from "../../contexts/AuthContext";

import Loader from "../../components/Loader";
import CallbackMessage from "../../components/CallbackMessage";
import OverlayLoading from "../../components/OverlayLoading";

import loginService from "../../services/loginService";

import BGImage from "../../assets/img/BG_login.jpg";

import "./styles.scss";
import { InputNativo } from "../../components/InputNativo";
import usuariosService from "../../services/usuariosService";

const LoginButton = ({ handleLogin, requestLoader }) => {
  return (
    <button className="login-button" type="submit" onClick={handleLogin}>
      {!requestLoader ? "LOGIN" : <Loader />}
    </button>
  );
};

const ForgotPasswordButton = ({ handleForgetPassword }) => {
  return (
    <button
      className="login-button forgot-password-button"
      onClick={handleForgetPassword}
    >
      RECUPERAR SENHA
    </button>
  );
};

const UpdatePasswordButton = ({ handleLogin, requestLoader }) => {
  return (
    <button className="login-button" type="submit" onClick={handleLogin}>
      {!requestLoader ? "SALVAR" : <Loader />}
    </button>
  );
};

const ReactiveUserButton = ({ onClick, requestLoader }) => {
  return (
    <button className="login-button" type="submit" onClick={onClick}>
      {!requestLoader ? "REATIVAR CONTA" : <Loader />}
    </button>
  );
};

function LoginPage({ callbackAlert }) {
  const isMobile = useMediaQuery("(max-width:590px)");
  const {
    handleLogin,
    requestLoader,
    isTempPassword,
    setIsTempPassword,
    shouldUpdatePassword,
    setShouldUpdatePassword,
    inactiveDueInactivity,
  } = useContext(AuthContext);
  const history = useHistory();

  const [textHeader, setTextHeader] = useState("Acesso a plataforma");
  const [userData, setUserData] = useState(null);
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loadingForgot, setLoadingForgot] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorsList, setErrorsList] = useState([]);
  const nps = document.getElementById("yv-nps-frame");
  const [errors, setErrors] = useState(null);
  const [verticalAlignCallback, setVerticalAlignCallback] = useState("bottom");

  function handleClose(event, reason) {
    setShowMessage(false);
  }

  async function handleForgetPassword(event) {
    setErrorsList([]);
    event.preventDefault();
    if (emailInput === "") {
      setErrorsList(['O campo "E-mail" é obrigatório.']);
      setShowMessage(true);
      return;
    }
    setLoadingForgot(true);
    const response = await loginService.sendEmail(emailInput);
    if (response.status === 200) {
      setErrorsList([]);
    } else {
      if (response.data.error) {
        setErrorsList(Object.values(response.data.error));
        setErrors(response.data.error);
      } else {
        setErrorsList(["Ocorreu um erro"]);
      }
    }
    setShowMessage(true);
    setLoadingForgot(false);
  }

  async function handleUpdatePassword(event) {
    setErrorsList([]);
    event.preventDefault();
    
    const errorsList = [];
    
    if (!passwordInput) {
      errorsList.push(
        `O campo ${
          shouldUpdatePassword ? "Nova senha" : "Senha"
        } é obrigatório.`
      );
    }
    
    if (!confirmPasswordInput) {
      errorsList.push(
        `O campo ${
          shouldUpdatePassword
            ? "Confirme sua nova senha"
            : "Confirme sua senha"
        } é obrigatório.`
      );
    }
    
    if (passwordInput !== confirmPasswordInput) {
      errorsList.push("As senhas não são iguais.");
    }
    
    if (passwordInput.length < 6) {
      errorsList.push("A senha deve ter pelo menos 6 caracteres");
    }
    
    if (!/[A-Z]/.test(passwordInput)) {
      errorsList.push("A senha deve conter pelo menos uma letra maiúscula");
    }
    
    if (!/\d/.test(passwordInput)) {
      errorsList.push("A senha deve conter pelo menos um número");
    }
    
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(passwordInput)) {
      errorsList.push("A senha deve conter pelo menos um caractere especial");
    }
    
    if (errorsList.length > 0) {
      setErrorsList(errorsList);
      setShowMessage(true);
      return;
    }
    

    setLoadingForgot(true);
    const response = await usuariosService.alterarSenha(
      userData.userId,
      passwordInput,
      userData.token
    );
    setLoadingForgot(false);

    if (response.status !== 200 && response.status !== 201) {
      if (response.data.error) {
        setErrorsList(Object.values(response.data.error));
        setErrors(response.data.error);
      } else {
        setErrorsList(["Ocorreu um erro."]);
      }
    } else {
      await submitLogin();
    }
  }

  const validatePassword = (value) => {
    let message = "";
  
    if (value.length < 6) {
      message = "A senha deve ter pelo menos 6 caracteres.";
    } else if (!/[A-Z]/.test(value)) {
      message = "A senha deve conter pelo menos uma letra maiúscula.";
    } else if (!/\d/.test(value)) {
      message = "A senha deve conter pelo menos um número.";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      message = "A senha deve conter pelo menos um caractere especial.";
    }
  
    setPasswordMessage(message);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPasswordInput(newPassword);
    validatePassword(newPassword);
  };

  async function submitLogin() {
    setErrors(null);
    const response = await handleLogin(
      { email: emailInput, password: passwordInput },
      callbackAlert
    );
    console.log({ response });
    if (response?.token) {
      setUserData({ token: response?.token, userId: response.userId });
    } else if (response?.inactive) {
      setUserData({ userId: response.userId });
    }
  }

  async function requestReactiveUser() {
    setLoadingForgot(true);
    const response = await usuariosService.solicitaReativacao(userData?.userId);

    setLoadingForgot(false);
    if (response.status !== 200 && response.status !== 201) {
      setVerticalAlignCallback("bottom");

      if (response.data.error) {
        setErrorsList(Object.values(response.data.error));
        setErrors(response.data.error);
      } else {
        setErrorsList(["Ocorreu um erro."]);
      }
    } else {
      setVerticalAlignCallback("top");
      setErrorsList([]);
    }
    setShowMessage(true);
  }

  async function handleSetForgotPassword() {
    setErrors(null);
    if (isTempPassword) {
      setIsTempPassword(false);
      setTextHeader("Acesso a plataforma");
    } else if (shouldUpdatePassword) {
      setShouldUpdatePassword(false);
      setTextHeader("Acesso a plataforma");
    } else {
      if (forgotPassword) {
        setTextHeader("Acesso a plataforma");
      } else {
        setTextHeader("Esqueci minha senha");
      }
      setForgotPassword((prev) => !prev);
    }
  }

  function leftBottomFormText() {
    if (isTempPassword || shouldUpdatePassword) {
      return "Fazer login em outra conta";
    } else if (!forgotPassword) {
      return "Esqueceu a senha?";
    } else {
      return "Já tenho a senha.";
    }
  }

  useEffect(() => {
    if (nps) nps.remove();
  }, [nps]);

  useEffect(() => {
    if (window.localStorage.getItem("session")) {
      history.push("/home");
    }
  }, [history]);

  useEffect(() => {
    if (emailInput.length > 0) {
      document.getElementById("email")?.classList?.add("spaceInput");
    } else {
      document.getElementById("email")?.classList?.remove("spaceInput");
    }
  }, [emailInput]);

  useEffect(() => {
    if (passwordInput.length > 0) {
      document.getElementById("password")?.classList?.add("spaceInput");
    } else {
      document.getElementById("password")?.classList?.remove("spaceInput");
    }
  }, [passwordInput]);

  useEffect(() => {
    if (inactiveDueInactivity) {
      setTextHeader(
        "<span class='span-header'>Usuário desativado por inatividade <strong>100 dias.</strong></span>"
      );
    } else if (isTempPassword) {
      setTextHeader(
        "Seja bem-vindo a nossa plataforma, para sua segurança defina sua nova senha"
      );
    } else if (shouldUpdatePassword) {
      setTextHeader("Renovação de senha periódica");
    }
  }, [isTempPassword, shouldUpdatePassword, inactiveDueInactivity]);

  return (
    <div className="login-container">
      {isMobile && <div className="login-img-mobile" />}
      <div
        className="login-background"
        style={{ backgroundImage: `url(${BGImage})` }}
      />
      <div className="login-form-container">
        {loadingForgot && <OverlayLoading />}

        {!isMobile && <div className="login-img-logo" />}

        {inactiveDueInactivity ? (
          <form
            className="login-form form-inactivity"
            onSubmit={(e) => e.preventDefault()}
          >
            <p
              className="login-description-page"
              dangerouslySetInnerHTML={{ __html: textHeader }}
            />
            <ReactiveUserButton onClick={requestReactiveUser} />
          </form>
        ) : null}
        {!inactiveDueInactivity ? (
          <form className="login-form" onSubmit={(e) => e.preventDefault()}>
            <p className="login-description-page">{textHeader}</p>
            {!isTempPassword && !shouldUpdatePassword ? (
              <InputNativo
                label="E-mail"
                type="email"
                placeholder="seu e-mail"
                onChange={(event) => setEmailInput(event.target.value)}
                error={errors?.email}
                id="email"
              />
            ) : null}
            {!forgotPassword ? (
              <>
                <InputNativo
                  label={shouldUpdatePassword ? "Nova senha" : "Senha"}
                  type="password"
                  placeholder="Insira sua senha"
                  onChange={handlePasswordChange}
                  error={errors?.senha || errors?.nova_senha}
                  id="password"
                  message={isTempPassword ? passwordMessage : ""}
                />
                {isTempPassword && passwordMessage && <div className="password-message">{passwordMessage}</div>}
              </>
            ) : null}

            {isTempPassword || shouldUpdatePassword ? (
              <InputNativo
                label={
                  shouldUpdatePassword
                    ? "Confirme sua nova senha"
                    : "Confirme sua senha"
                }
                type="password"
                placeholder="Insira a mesma senha"
                onChange={(event) =>
                  setConfirmPasswordInput(event.target.value)
                }
              />
            ) : null}

            <div className={`container-form-footer`}>
              <p
                className={`forgot-password`}
                onClick={handleSetForgotPassword}
              >
                {leftBottomFormText()}
              </p>
              {!isTempPassword && !shouldUpdatePassword && !forgotPassword ? (
                <LoginButton
                  requestLoader={requestLoader}
                  handleLogin={submitLogin}
                />
              ) : null}
              {forgotPassword ? (
                <ForgotPasswordButton
                  handleForgetPassword={handleForgetPassword}
                />
              ) : null}

              {isTempPassword || shouldUpdatePassword ? (
                <UpdatePasswordButton handleLogin={handleUpdatePassword} />
              ) : null}
            </div>
          </form>
        ) : null}

        {showMessage && (
          <CallbackMessage
            open={showMessage}
            type={`${errorsList.length === 0 ? "success" : "error"}`}
            message={`${
              errorsList.length === 0
                ? inactiveDueInactivity
                  ? "Sua solicitação foi enviada ao setor responsável."
                  : "O E-mail de recuperação de senha foi enviado com sucesso."
                : "Erro!"
            }`}
            errorList={errorsList}
            handleClose={handleClose}
            duration={
              errorsList.length === 0 && !inactiveDueInactivity ? 2000 : 60000
            }
            vertical={verticalAlignCallback}
          />
        )}
      </div>
    </div>
  );
}

export default LoginPage;
