import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import InputComponent from "../Input";
import SelectComponent from "../SelectComponent";
import ButtonComponent from "../ButtonComponent";
import Switch from "../Switch";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import DatePicker from "../DatePicker";
import CallbackMessage from "../CallbackMessage";
import verifyFields from "../../utils/validateFields";
import preAdmissaoService from "../../services/preAdmissaoService";
import selectServices from "../../services/selectServices";
import buscaCepService from "../../services/buscaCepService";
import viacepServices from "../../services/viacepServices";
import { onlyNumbers } from "../../utils/strings";
import "./styles.scss";

function ModalNovoDependente({ open, onClose, idFuncionario, idDependente }) {
  const [nome, setNome] = useState();
  const [cpf, setCpf] = useState();
  const [telefone, setTelefone] = useState();
  const [parentesco, setParentesco] = useState();
  const [dataNasc, setDataNasc] = useState();
  const [inicioDependencia, setInicioDependencia] = useState();
  const [pais, setPais] = useState();
  const [nacionalidade, setNacionalidade] = useState();
  const [deficiente, setDeficiente] = useState(false);
  const [dataEntrada, setDataEntrada] = useState();
  const [mesmoEndereco, setMesmoEndereco] = useState(true);
  const [cep, setCep] = useState();
  const [endereco, setEndereco] = useState();
  const [bairro, setBairro] = useState();
  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState();
  const [estado, setEstado] = useState();
  const [cidade, setCidade] = useState();
  const [listParentesco, setListParentesco] = useState([]);
  const [listPaises, setListPaises] = useState([]);
  const [listCidades, setListCidades] = useState([]);
  const [listEstados, setListEstados] = useState([]);
  const [listNacionalidade, setListNacionalidade] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const history = useHistory();
  const [loadingCep, setLoadingCep] = useState(false);

  const loadFields = useCallback(async () => {
    await preAdmissaoService
      .consultaDependente(idDependente)
      .then((response) => {
        if (response.status === 401) {
          setLoading(false);
          setOpenLoginExpirado(true);
          return;
        } else if (response.status === 200) {
          let data = response.data;
          setCpf(data.cpf);
          setNome(data.nome);
          setParentesco(data.id_parentesco);
          setDataNasc(data.data_nascimento);
          setInicioDependencia(data.data_inicio_dependencia);
          setPais(data.id_pais_nascimento);
          setNacionalidade(data.id_nacionalidade);
          setDataEntrada(data.data_entrada_pais);
          setTelefone(data.telefone);
          setDeficiente(data.deficiente === 1 ? true : false);
          setMesmoEndereco(data.endereco_funcionario === 1 ? true : false);
          if (data.endereco_funcionario === 0) {
            setCep(data.cep);
            setEndereco(data.endereco);
            setNumero(data.numero);
            setComplemento(data.complemento);
            setBairro(data.bairro);
            setEstado(data.id_uf);
    
               loadCidades(data.id_uf).then( (result) => {
                 console.log('result:::', result);
                setCidade(
                  result.find((item) => item.id === data?.id_cidade)
                    ? result.find((item) => item.id === data?.id_cidade).id
                    : null
                );
              });
            
          }
        }
      });
  }, [idDependente])

  async function loadSelects() {
    const responseParentesco = await selectServices.selecionaParentesco();
    const responsePais = await selectServices.selecionaPais();
    const responseNacionalidade = await selectServices.selecionaNacionalidade();

    if (
      responseNacionalidade.status === 401 ||
      responsePais.status === 401 ||
      responseNacionalidade.status === 401
    ) {
      setLoading(false);
      setOpenLoginExpirado(true);
      return;
    }
    if (responseParentesco.status === 200)
      setListParentesco(responseParentesco.data);
    if (responsePais.status === 200) setListPaises(responsePais.data);
    if (responseNacionalidade.status === 200)
      setListNacionalidade(responseNacionalidade.data);
  }

  async function loadEstados() {
    await viacepServices.selecionaEstado().then((response) => {
      if (response.status === 200) setListEstados(response.data);
    });
  }

  async function loadCidades(id) {
    setListCidades([]);
    const response = await viacepServices.selecionaCidade(id);
    if (response.status === 200) {
      setCidade();
      setListCidades(response.data);
      return response.data;
    }
  }

  function handleChangeMesmoEndereco() {
    let newValue = !mesmoEndereco;
    setMesmoEndereco(newValue);
    if (newValue) {
      setCep();
      setEndereco();
      setNumero();
      setComplemento();
      setBairro();
      setCidade();
      setEstado();
    }
  }

  async function handleCepChange(cep) {
    cep = onlyNumbers(cep);
    setCep(cep);
    if (cep.length === 8) {
      setLoadingCep(true);
      const response = await buscaCepService.getCep(cep);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoadingCep(false);
        return;
      }
      const data = response.data;
      if (!data.hasOwnProperty("erro")) {
        let estadoSelected = listEstados.find((item) => item.uf === data.uf);
        setEstado(estadoSelected ? estadoSelected.id : null);
        if (estadoSelected) {
          await loadCidades(estadoSelected.id).then((result) => {
            console.log("cidade", result, data.localidade);
            setCidade(
              result.find((item) => item.nome === data.localidade)
                ? result.find((item) => item.nome === data.localidade).id
                : null
            );
          });
        }
        setEndereco(data.logradouro);
        setBairro(data.bairro);
        setNumero();
      }
      setLoadingCep(false);
    }
  }

  function validateFields() {
    setErrors([]);
    let fields = [
      {
        label: "nome",
        value: nome,
      },
      {
        label: "cpf",
        value: cpf,
      },
      {
        label: "telefone",
        value: telefone,
      },
      {
        label: "parentesco",
        value: parentesco,
      },
      {
        label: "datadenascimento",
        value: dataNasc,
      },
      {
        label: "iniciodadependencia",
        value: inicioDependencia,
      },
      {
        label: "paisdenascimento",
        value: pais,
      },
      {
        label: "nacionalidade",
        value: nacionalidade,
      },
    ];

    if (!mesmoEndereco) {
      let l = [
        {
          label: "cep",
          value: cep,
        },
        {
          label: "endereco",
          value: endereco,
        },
        {
          label: "bairro",
          value: bairro,
        },
        {
          label: "numero",
          value: numero,
        },
        {
          label: "estado",
          value: estado,
        },
        {
          label: "cidade",
          value: cidade,
        },
      ];
      fields = fields.concat(l);
    }
    const errorsValidate = verifyFields(fields);

    if (errorsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(errorsValidate);
    }
    return errorsValidate.length === 0;
  }

  function handleClose() {
    setShowMessage(false);
    if (errors.length === 0) {
      history.push(`/pre-admissao-dependentes/${idFuncionario}`);
      onClose();
    }
  }

  async function handleSubmit() {
    if (!validateFields()) return;
    setLoading(true);
    await preAdmissaoService
      .cadastraNovoDependente(
        nome,
        onlyNumbers(cpf),
        telefone,
        parentesco,
        dataNasc,
        inicioDependencia,
        pais,
        nacionalidade,
        deficiente ? 1 : 0,
        mesmoEndereco ? 1 : 0,
        idFuncionario,
        cep,
        endereco,
        bairro,
        numero,
        estado,
        cidade,
        complemento,
        dataEntrada,
        idDependente
      )
      .then((response) => {
        setLoading(false);
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          return;
        } else if (response.status === 200) {
          setSuccessMessage("Dependente atualizado com sucesso.");
          setShowMessage(true);
          return;
        } else if (response.status === 201) {
          setSuccessMessage("Dependente cadastrado com sucesso.");
          setShowMessage(true);
          return;
        }
        if (response.data.error) setErrors(Object.values(response.data.error));
        else setErrors(["Ocorreu um erro"]);

        setShowMessage(true);
      });
  }

  function cadastroEndereco() {
    return (
      <Grid item container direction="column" spacing={2}>
        <Grid item container>
          <Grid item xs sm md={6}>
            <InputComponent
              value={cep}
              label="CEP"
              required
              loading={loadingCep}
              handleInputChange={(event) => handleCepChange(event.target.value)}
              tipo="cep"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs sm md={6}>
            <InputComponent
              value={endereco}
              label="Endereço"
              required
              handleInputChange={(event) => setEndereco(event.target.value)}
            />
          </Grid>
          <Grid item xs sm md={6}>
            <InputComponent
              value={bairro}
              label="Bairro"
              required
              handleInputChange={(event) => setBairro(event.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs sm md={6}>
            <InputComponent
              value={numero}
              label="Número"
              tipo="numero"
              required
              handleInputChange={(event) => setNumero(event.target.value)}
            />
          </Grid>
          <Grid item xs sm md={6}>
            <InputComponent
              value={complemento}
              label="Complemento"
              handleInputChange={(event) => setComplemento(event.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs sm md={6}>
            <SelectComponent
              styleType="form"
              list={listEstados}
              initialValue={
                listEstados.find((item) => item.id === estado)
                  ? listEstados.find((item) => item.id === estado).nome
                  : ""
              }
              value={estado}
              label="Estado"
              required
              callback={async (id) => {
                setEstado(id);
                setLoading(true);
                await loadCidades(id);
                setLoading(false);
              }}
            />
          </Grid>
          <Grid item xs sm md={6}>
            <SelectComponent
              styleType="form"
              initialValue={
                listCidades.find((item) => item.id === cidade)
                  ? listCidades.find((item) => item.id === cidade).nome
                  : ""
              }
              list={listCidades}
              value={cidade}
              label="Cidade"
              required
              callback={(event) => setCidade(event)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <span className="required-text">* Obrigatório</span>
        </Grid>
      </Grid>
    );
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadSelects();
      await loadEstados();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (idDependente) {
      (async () => await loadFields())();
    }
  }, [idDependente, loadFields]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>NOVO DEPENDENTE</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content novo-dependente">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item xs sm md container>
              <InputComponent
                value={nome}
                label="Nome"
                required
                handleInputChange={(event) => setNome(event.target.value)}
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  value={cpf}
                  tipo="cpf"
                  label="CPF"
                  required
                  handleInputChange={(event) => setCpf(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  value={telefone}
                  tipo="celular"
                  label="Telefone *"
                  handleInputChange={(event) => setTelefone(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <SelectComponent
                  initialValue={
                    listParentesco.find((item) => item.id === parentesco)
                      ? listParentesco.find((item) => item.id === parentesco)
                        .nome
                      : ""
                  }
                  label="Parentesco"
                  styleType="form"
                  list={listParentesco}
                  required
                  callback={(id) => setParentesco(id)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DatePicker
                  initDate={dataNasc}
                  label="Data de nascimento *"
                  noMask
                  handleChange={(value) => setDataNasc(value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DatePicker
                  initDate={inicioDependencia}
                  label="Início da dependência *"
                  noMask
                  handleChange={(value) => setInicioDependencia(value)}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <SelectComponent
                  label="País de nascimento"
                  initialValue={
                    listPaises.find((item) => item.id === pais)
                      ? listPaises.find((item) => item.id === pais).nome
                      : ""
                  }
                  styleType="form"
                  list={listPaises}
                  required
                  callback={(id) => setPais(id)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <SelectComponent
                  label="Nacionalidade"
                  initialValue={
                    listNacionalidade.find((item) => item.id === nacionalidade)
                      ? listNacionalidade.find(
                        (item) => item.id === nacionalidade
                      ).nome
                      : ""
                  }
                  styleType="form"
                  list={listNacionalidade}
                  required
                  callback={(id) => setNacionalidade(id)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DatePicker
                  initDate={dataEntrada}
                  label="Data de entrada no país"
                  noMask
                  handleChange={(value) => setDataEntrada(value)}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={1} alignItems="center">
              <Grid item className="label">
                Deficiente?
              </Grid>
              <Grid item className="dependente-font-decline">
                Não
              </Grid>
              <Grid item>
                <Switch
                  checked={deficiente}
                  onClick={() => setDeficiente(!deficiente)}
                />
              </Grid>
              <Grid item className="dependente-font-accept">
                Sim
              </Grid>
            </Grid>
            <Grid item container spacing={1} alignItems="center">
              <Grid item className="label">
                Mesmo endereço do funcionário?
              </Grid>
              <Grid item className="dependente-font-decline">
                Não
              </Grid>
              <Grid item>
                <Switch
                  style={{ marginRight: 0 }}
                  checked={mesmoEndereco}
                  onClick={handleChangeMesmoEndereco}
                />
              </Grid>
              <Grid item className="dependente-font-accept">
                Sim
              </Grid>
            </Grid>
            {!mesmoEndereco && cadastroEndereco()}
            <Grid container justify="flex-end" item>
              <ButtonComponent
                text="Salvar"
                onClick={handleSubmit}
                className="btn-success zero-margin"
              />
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalNovoDependente;
