import React, { useState, useCallback, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import Input from "../../../components/Input";
import SelectComponent from "../../../components/SelectComponent";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import empresasService from "../../../services/empresasService";
import { documentacaoContabilService } from "../../../services/documentoContabilService";

import FileInput from "../../../components/InputFile";
import ButtonComponent from "../../../components/ButtonComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import Textarea from "../../../components/Textarea";
import useCompany from "../../../hooks/useCompany";

function CadastroDocumentoContabil() {
  const {
    companyList: listEmpresas,
    selectedCompany: idEmpresa,
    setSelectedCompany: setIdEmpresa,
    getEmpresaPagina,
  } = useCompany("documento_contabil");

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [observacoes, setObservacoes] = useState(null);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [listCategorias, setListCategorias] = useState([]);
  const [categoriaId, setCategoriaId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const history = useHistory();

  async function loadCategorias() {
    setLoading(true);
    const response = await documentacaoContabilService.selectCategorias();
    setLoading(false);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListCategorias(response.data);
  }

  function validateFields() {
    let list = [];
    if (titulo === "")
      list.push('O campo "Título do Documento" é obrigatório.');

    if (categoriaId === "") list.push("Nenhuma categoria selecionada.");

    if (idEmpresa === "") list.push("Nenhuma empresa selecionada");

    if (file === "" || file === null) {
      list.push("Nenhum anexo selecionado.");
    }

    if (list.length > 0) setShowMessage(true);

    setErrors(list);

    return list.length === 0;
  }

  function handleClose(event, reason) {
    setShowMessage(false);
    if (errors.length === 0) {
      history.push("/documento-contabil");
    }
  }

  async function handleSubmit() {
    try {
      if (!validateFields()) return;
      setLoading(true);
      await documentacaoContabilService
        .cadastraDocumento(
          titulo,
          categoriaId,
          idEmpresa,
          file,
          observacoes,
          fileName
        )
        .then((response) => {
          if (response.status === 401) {
            setOpenLoginExpirado(true);
            setLoading(false);
            return;
          }
          if (response.status != 200)
            if (response.data.error)
              setErrors(Object.values(response.data.error));
            else setErrors(["Ocorreu um erro"]);
          setShowMessage(true);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setErrors(["Ocorreu um erro ao cadastrar"]);
    }
  }

  useEffect(() => {
    loadCategorias();
    const empresaStorage = getEmpresaPagina("documento_contabil");
    if (empresaStorage) {
      setIdEmpresa(empresaStorage);
    }
  }, []);

  return (
    <>
      <div className="container-cadastrar-documento-contabil">
        <form className="session-container-form  documento-contabil-container">
          <span className="session-container-header ">
            {loading && <OverlayLoading />}
            <div className="p-horizontal">
              <Grid item container direction="column" spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs sm md={8}>
                    <Input
                      required
                      label="Título do Documento"
                      value={titulo}
                      handleInputChange={(event) =>
                        setTitulo(event.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs sm md={4}>
                    <SelectComponent
                      required
                      styleType="form"
                      label="Categoria"
                      title="Selecione uma categoria"
                      list={listCategorias}
                      callback={(id) => setCategoriaId(id)}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <SelectComponent
                    styleType="form"
                    label="Empresa"
                    title="Selecione uma empresa"
                    list={listEmpresas}
                    required
                    initialValue={
                      listEmpresas?.find((item) => item.id === idEmpresa)
                        ?.nome || null
                    }
                    callback={(id) => setIdEmpresa(id)}
                  />
                </Grid>
                <Grid item>
                  <Textarea
                    label="Observações"
                    minRows={5}
                    value={observacoes}
                    onChange={(e) => setObservacoes(e.target.value)}
                    maxLength={300}
                  />
                </Grid>
                <Grid item>
                  <FileInput
                    title="Anexo *"
                    onChange={(value, valueFileName) => {
                      setFile(value);
                      setFileName(valueFileName);
                    }}
                  />
                </Grid>
                <Grid item>
                  <span className="required-text">* Campos obrigatórios</span>
                </Grid>
              </Grid>
            </div>
            <CallbackMessage
              open={showMessage}
              type={`${errors.length === 0 ? "success" : "error"}`}
              message={`${
                errors.length === 0
                  ? "Cadastro realizado com sucesso!"
                  : "Erro!"
              }`}
              errorList={errors}
              handleClose={handleClose}
              duration={errors.length === 0 ? 2000 : 6000}
            />
          </span>
        </form>
        <Grid item container justifyContent="flex-end">
          <ButtonComponent
            text="Salvar"
            className="btn-success zero-margin"
            onClick={handleSubmit}
          />
        </Grid>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastroDocumentoContabil;
