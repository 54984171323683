import React, { useState, useEffect } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import InputComponent from "../../Input";
import ButtonComponent from "../../ButtonComponent";
import SelectComponent from "../../SelectComponent";
import CallbackMessage from "../../CallbackMessage";
import validateFields from "../../../utils/validateFields";

function ModalIcmssn202({ open, onClose, data = null, callback = () => {}, listBci = [], listCstCsosn = []  }) {
  
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackErrorList, setCallbackErrorList] = useState([]);

  const [field, setField] = useState({
    origem: null,
    csosn: null,
    modalidade_determinacao_bcicmsst: null,
    valor_bcicmsst: null,
    aliquota_imposto_icmsst: null,
    valor_icmsst: null,
    percentual_margem_valor_adicionado_icmsst: null,
    percentual_reducao_bcicmsst: null
  });
 
  const handleClose = (event, reason) => {
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
  };

  const handleSubmit = () => {
    console.log(field);

    const fieldsToValidate = [
      {
        label: "origem",
        value: field.origem,
      },
      {
        label: "csosn",
        value: field.csosn,
      },
      {
        label: "modalidade_determinacao_bcicmsst",
        value: field.modalidade_determinacao_bcicmsst,
      },
      {
        label: "valor_bcicmsst",
        value: field.valor_bcicmsst,
      },
      {
        label: "aliquota_imposto_icmsst",
        value: field.aliquota_imposto_icmsst,
      },
      {
        label: "valor_icmsst",
        value: field.valor_icmsst,
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      setCallbackErrorList(fieldsErrors);
      setCallbackShown(true);
      return;
    }
    callback(field)
  }

  useEffect(() => {
    let mounted = true;

    if (data) {
      if (mounted) {
        setField({
          origem: data.origem,
          csosn: data.csosn,
          modalidade_determinacao_bcicmsst: data.modalidade_determinacao_bcicmsst,
          valor_bcicmsst: data.valor_bcicmsst,
          aliquota_imposto_icmsst: data.aliquota_imposto_icmsst,
          valor_icmsst: data.valor_icmsst,
          percentual_margem_valor_adicionado_icmsst: data.percentual_margem_valor_adicionado_icmsst,
          percentual_reducao_bcicmsst: data.percentual_reducao_bcicmsst
        })
      }
    }
    
    return () => mounted = false;
  }, [data])
  
  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose} 
      className="modal-icms" >
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>ICMSSN - ICMSSN202</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={1}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Origem"
                  tipo="numero"
                  value={field.origem}
                  handleInputChange={event => setField({ ...field, origem: event.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <SelectComponent
                  required
                  styleType="form"
                  label="CSOSN"
                  initialValue={listCstCsosn.find((item) => item.id === Number(field.csosn) ? listCstCsosn.find((item) => item.id === Number(field.csosn)) : null)}
                  callback={event => setField({ ...field, csosn: event })}
                  list={listCstCsosn}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
               <SelectComponent
                  required
                  label="Modalidade determinação BC ICMS ST"
                  initialValue={field.modalidade_determinacao_bcicmsst !== undefined ? listBci.find(item => item.id === field.modalidade_determinacao_bcicmsst) : ''}
                  callback={id => setField({ ...field, modalidade_determinacao_bcicmsst: id })}
                  styleType="form"
                  list={listBci}                  
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  tipo="dinheiro"
                  title="R$"
                  label="Valor da base de cálculo ICMS ST"
                  value={field.valor_bcicmsst}
                  handleInputChange={event => setField({ ...field, valor_bcicmsst: event.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  tipo="percentage"
                  label="Alíquota do imposto do ICMS ST %"
                  value={field.aliquota_imposto_icmsst && field.aliquota_imposto_icmsst+"%"}
                  handleInputChange={event => setField({ ...field, aliquota_imposto_icmsst: event.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Valor do ICMS ST"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_icmsst}
                  handleInputChange={event => setField({ ...field, valor_icmsst: event.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Margem de valor adicionado ICMS ST (%)"
                  tipo="percentage"
                  value={field.percentual_margem_valor_adicionado_icmsst && field.percentual_margem_valor_adicionado_icmsst+"%"}
                  handleInputChange={event => setField({ ...field, percentual_margem_valor_adicionado_icmsst: event.value })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Redução de BC do ICMS ST (%)"
                  tipo="percentage"
                  value={field.percentual_reducao_bcicmsst && field.percentual_reducao_bcicmsst+"%"}
                  handleInputChange={event => setField({ ...field, percentual_reducao_bcicmsst: event.value })}
                />
              </Grid>
            </Grid>
            <Grid container justify="flex-end" item>
              <ButtonComponent
                text="Salvar"
                onClick={() => handleSubmit()}
                className="btn-success zero-margin"
              />
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={callbackShown}
        errorList={callbackErrorList}
        duration={4000}
        message={'Erro!'}
        type={'error'}
        handleClose={handleClose}
      />
    </Dialog>
  );
}

export default ModalIcmssn202;
