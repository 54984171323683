import React, { useEffect, useState, useContext, useCallback, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import CallbackMessage from "../../components/CallbackMessage";
import OverlayLoading from "../../components/OverlayLoading";
import Select from '../../components/SelectComponent';
import Button from "../../components/ButtonComponent";
import Switch from "../../components/Switch";


import { NavbarContext } from '../../contexts/NavbarContext';

import TermosDeUso from "../../docs/Termos_e_Condicoes_de_Uso_da_Plataforma_Contabil_Hub_04_2022.pdf";
import PoliticasDePrivacidade from "../../docs/POLITICA_DE_PRIVACIDADE_04_2022.pdf";

import contratoServices from "../../services/contratoServices";
import usoService from "../../services/usoService";
import Clicksign from "../../services/clicksign";
import planosServices from '../../services/planosServices';
import usuarioServices from '../../services/usuariosService';

import clickSignUrl from '../../utils/clicksign';
import { startYvnps } from "../../utils/yvnps";

import anchorLink from "../../assets/img/anchor-link.svg";
import contract from "../../assets/img/contract1.svg";
import clickSign from "../../assets/img/clickSign.png";


import "./styles.css";
import classes from "./styles.module.scss";

const ContratoAceite = () => {
  const { fetchNotification } = useContext(NavbarContext);
  const CustomCheckbox = withStyles({
    root: {
      color: "#E8EBF1",
      width: 0,
      marginRight: 18,
      borderRadius: 4,
      "&$checked": {
        color: "#812990 !important",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const history = useHistory();
  const location = useLocation();
  const descricao_plano_especifico = location?.state?.descricao_plano_especifico

  const {
    assinaContrato,
    atualizarTermos,
    assinaContratoClicksign,
    geraContrato,
  } = contratoServices;
  const { consultaUsoLogin, verificaUso } = usoService;
  const { selecionaPlano, assinaPlano } = planosServices;
  const { consultaPermissoes } = usuarioServices;

  const [loading, setLoading] = useState(false);

  const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox] = useState(false);
  const [cookiesCheckbox, setCookiesCheckbox] = useState(false);
  const [useTermsCheckbox, setUseTermsCheckbox] = useState(false);
  const [signedTerms, setSignedTerms] = useState(false);
  const [showClickSignDocument, setShowClickSignDocument] = useState(false);

  const [base64Contrato, setBase64Contrato] = useState("");

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);

  const [showSignContract, setShowSignContract] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [typePlan, setTypePlan] = useState(false); 

  const plan = window.localStorage.getItem('plano');

  const [selectedPlan, setSelectedPlan] = useState(plan ? Number(plan) : null);
  const [disablePlanButton, setDisablePlanButton] = useState(false);
  const [hasSignature, setHasSignature] = useState(true);
  
  const [contractName, setContractName] = useState(null);
  const [contractDocument, setContractDocument] = useState(null);
  
  const nps = document.getElementById("yv-nps-frame");

  const perfilAcesso = window.localStorage.getItem('perfil_acesso');



  useEffect(() => { 
    if(nps) nps.remove();
   
  }, [nps]);

  useEffect(() => {  
    return () => startYvnps();
  }, [location]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      await verificaUso();

      const response = await consultaUsoLogin();

      if (response.status === 200) {
        if (response?.data?.contrato_assinado) {
          setHasSignature(true);
        }else{
          setHasSignature(false);
        }
        if (response?.data?.assinatura_paga === 0) {
          history.replace(
            `/fatura/${response?.data.primeira_fatura_da_empresa}/${response?.data.primeiro_boleto_da_fatura}`
          );
        }

        setContractName(response.data.nome);
        setContractDocument(response.data.documento);

        setCookiesCheckbox(response?.data?.cookies === 1 || false);
        setPrivacyPolicyCheckbox(
          response?.data?.politica_privacidade === 1 || false
        );
        setUseTermsCheckbox(response?.data?.termo_uso === 1 || false);

        if (
          response?.data?.cookies === 1 &&
          response?.data?.politica_privacidade === 1 &&
          response?.data?.termo_uso === 1
        ) {
          setSignedTerms(true);
          const buttonArea = document.querySelector("#buttonArea");
          buttonArea?.classList?.add("btnHidden");
        }
      }else{
        hasSignature(false);
      }  

      setLoading(false);
    })();
  }, [consultaUsoLogin, history, verificaUso, selectedPlan, typePlan]);



  const handlePdfOpen = (pdf) => {
    if (pdf === "termosdeuso") {
      const pdf = TermosDeUso;
      window.open(pdf, "_blank");
    }
    if (pdf === "politicasdeprivacidade") {
      const pdf = PoliticasDePrivacidade;
      window.open(pdf, "_blank");
    }

    return false;
  };

  const handleAcceptTerms = async () => {
    if (privacyPolicyCheckbox && cookiesCheckbox & useTermsCheckbox) {
      const response = await atualizarTermos();
      if (response.status === 200) {
        setSignedTerms(true);
        const buttonArea = document.querySelector("#buttonArea");
        buttonArea?.classList?.add("btnHidden");

        setCallbackType("success");
        setCallbackDuration(2000);
        setCallbackMessage("Sucesso!");
        setCallbackShown(true);
        if (hasSignature) {
          window.localStorage.setItem("status_uso", "1");
          setTimeout(() => {
            fetchNotification();
            history.push("/home");
          }, 2000);
        }
      } else {
        setCallbackType("error");
        setCallbackMessage("Erro!");
        if (response.data.error)
          setCallbackErrorList(Object.values(response.data.error));
        else setCallbackErrorList(["Ocorreu um erro"]);
        setCallbackShown(true);
      }
    }
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  let widget;

  let run = (idAssinatura) => {
    setShowClickSignDocument(true);

    if (widget) {
      widget.unmount();
    }

    widget = new Clicksign(idAssinatura);

    widget.endpoint = clickSignUrl.clicksignUrl;
    widget.origin = window.location.origin;
    widget.mount("container");

    widget.on("loaded", function (ev) {
      const buttonArea = document.querySelector("#buttonArea");
      buttonArea?.classList?.add("btnHidden");
      const iframeArea = document.querySelector("#container");
      iframeArea?.classList?.add("iframeFullHeight");
    });
    widget.on("signed", async function (ev) {

      setLoading(true);
      await assinaContrato();

      const getPermissoes = await consultaPermissoes(perfilAcesso);
        
      if (getPermissoes.status === 200) {
        const stringfiedPermissions = JSON.stringify(getPermissoes.data);
        
        window.localStorage.setItem('permissoes', stringfiedPermissions);
      }
      
      window.localStorage.setItem("status_uso", "1");
      setLoading(false);

      setCallbackType("success");
      setCallbackDuration(3000);
      setCallbackMessage("Sucesso");
      setCallbackShown(true);
      setTimeout(() => {
        fetchNotification();
        history.push("/home");
      }, 3000);

    });
    widget.on("resized", function (height) {
      document.getElementById("container").style.height = height + "px";
    });
  };

  const handleContract = async () => {
    setLoading(true);

    await assinaContratoClicksign(
      `data:application/pdf;base64,${base64Contrato}`
    );
    const responseUso = await consultaUsoLogin();

    run(responseUso?.data?.id_assinatura_clicksign);
    setLoading(false);
  };


  const handleChooseTypePlan = useCallback(() => {
    setTypePlan(!typePlan);
  }, [typePlan, setTypePlan]);

  const handleSelectPlan = useCallback((id) => {
    setSelectedPlan(id);
  }, [])

  const handleChoosePlan = useCallback(async () => {
    setLoading(true);

    if (disablePlanButton || !selectedPlan) {
      setLoading(false);
      return;
    }

    if(selectedPlan == null) {
      selectedPlan = window.localStorage.getItem("plano");
    }
    
    const response = await assinaPlano(selectedPlan, typePlan);

    if ((response.status === 200) || (response.status === 201)) {
      let selectedPlanLabel = planList.find(item => item.id === selectedPlan);
      
      if (response.data.msg) {
        setCallbackMessage(response.data.msg);
      } else if (selectedPlanLabel !== undefined) {
        
        setCallbackMessage(`Definido para o plano ${selectedPlanLabel.nome}`);
      } else {
        setCallbackMessage('Plano definido.')
      }      

      console.log('contractName :: ', contractName);
      console.log('contractDocument :: ', contractDocument);

      const responseGerarContrato = await geraContrato(
        contractName,
        contractDocument,
        selectedPlan,
      );

      setBase64Contrato(responseGerarContrato?.data?.contrato);
      
      setDisablePlanButton(true);
      setCallbackType('success');
      setCallbackDuration(3000);
      setCallbackShown(true);
      
      if (!response.data.exibir_contrato) {
        const getPermissoes = await consultaPermissoes(perfilAcesso);
        
        if (getPermissoes.status === 200) {
          const stringfiedPermissions = JSON.stringify(getPermissoes.data);
          
          window.localStorage.setItem('permissoes', stringfiedPermissions);
        }
        
        window.localStorage.setItem("status_uso", "1");
        setTimeout(() => {
          fetchNotification();
          history.push("/home");
        }, 3000);
      }

      if (response.data.exibir_contrato) {
        setShowSignContract(true);
      }
    } else {
      setCallbackType('error');
      setCallbackErrorList(['Ocorreu um erro ao selecionar o plano.']);
      setCallbackDuration(3000);
      setCallbackMessage('Erro!');
      setCallbackShown(true);
    }

    setLoading(false);
  }, [assinaPlano, fetchNotification, history, typePlan, selectedPlan, disablePlanButton, 
      consultaPermissoes, contractDocument, contractName, geraContrato, perfilAcesso, planList]);

  useEffect(() => {
    (async () => {
      setLoadingSelect(true);

      const response = await selecionaPlano();
      
      if (response.status === 200) {
        setPlanList(response.data);
      }
      
      setLoadingSelect(false);
    })()
  }, [selecionaPlano])
  
  return (
    <>
      {loading && <OverlayLoading />}
      <CallbackMessage
        type={callbackType}
        duration={callbackDuration}
        errorList={callbackErrorList}
        message={callbackMessage}
        open={callbackShown}
        handleClose={handleClose}
      />
      <div className="session-container">
        <span className={`session-container-header ${classes.container}`}>
          <div className={classes.titleContainer}>
            <h2 className={classes.title}>Política de Privacidade e Cookies</h2>
            <span className={`${classes.subtitle} ${classes.span}`}>
              Para iniciar o uso da plataforma solicitamos o aceite em nossa
              política de privacidade e uso de cookies.
            </span>
          </div>
          <div className={classes.selectsContainer}>
            <div className={classes.individualSelectContainer}>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    disabled={signedTerms}
                    checked={privacyPolicyCheckbox}
                    onChange={() => setPrivacyPolicyCheckbox((prev) => !prev)}
                    name="privacyPolicy"
                  />
                }
              />
              <span className={classes.span}>
                * Estou de acordo com a{" "}
                <div
                  className={classes.pdfAnchor}
                  onClick={() => handlePdfOpen("politicasdeprivacidade")}
                >
                  Política de Privacidade.
                </div>
                <img alt="" style={{ marginLeft: 3 }} src={anchorLink} />
              </span>
            </div>
            <div className={classes.individualSelectContainer}>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    disabled={signedTerms}
                    checked={cookiesCheckbox}
                    onChange={() => setCookiesCheckbox((prev) => !prev)}
                    name="cookies"
                  />
                }
              />

              <span className={classes.span}>
                * Este site busca utilizar cookies para melhorar o site e sua
                experiência. Ao continuar navegando no site você concorda em
                aceitar nosso uso de cookies.
              </span>
            </div>
            <div className={classes.individualSelectContainer}>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    disabled={signedTerms}
                    checked={useTermsCheckbox}
                    onChange={() => setUseTermsCheckbox((prev) => !prev)}
                    name="useTerms"
                  />
                }
              />

              <span className={classes.span}>
                * Estou de acordo com os{" "}
                <div
                  className={classes.pdfAnchor}
                  onClick={() => handlePdfOpen("termosdeuso")}
                >
                  Termos de Uso.
                </div>
                <img alt="" style={{ marginLeft: 3 }} src={anchorLink} />
              </span>
            </div>
            <span className={classes.requiredFieldsHint}>
              * Campos obrigatórios
            </span>
          </div>

          <div id="buttonArea" className={classes.buttonArea}>
            <Button
              disabled={
                !privacyPolicyCheckbox || !cookiesCheckbox || !useTermsCheckbox
              }
              className={classes.customButton}
              text="Aceitar termos"
              onClick={handleAcceptTerms}
            />
          </div>
        </span>
      </div>
      {!hasSignature && <div className="session-container">
          <span
            className={`session-container-header ${classes.secondaryContainer}`}
          >
            {descricao_plano_especifico ? (
              <>
                <div
                className={`${classes.contratoHeaderContainer} ${classes.halfWidth}`}
              >
                <h2 className={classes.title}>O melhor plano para você</h2>
              </div>
              <div
                className={`${classes.contratoHeaderContainer} ${classes.halfWidth} ${classes.switchContainer}`}
              >
                <span className={`${classes.subTitlePlan}`}>
                  Plano escolhido:
                </span>

                <div className={classes.especificPlanDescription} dangerouslySetInnerHTML={{__html: descricao_plano_especifico.replaceAll("\n", "<br>")}}>
                </div>
              </div>
              </>
            ) : (
              <>
              <div
                className={`${classes.contratoHeaderContainer} ${classes.halfWidth}`}
              >
                <h2 className={classes.title}>Escolha o melhor plano para você</h2>
              </div>
              <div className={classes.planSelectSection}>
                <div className={classes.planSelectContainer}>
                  <Select 
                    styleType="form" 
                    label="Escolha o plano" 
                    loading={loadingSelect} 
                    list={planList} 
                    callback={(id) => handleSelectPlan(id)}
                    initialValue={
                      planList.find(item => item.id === selectedPlan) !== undefined ?
                      planList.find(item => item.id === selectedPlan) :
                      null
                    }
                  />
                </div>
                <a href="https://www.contabilhub.com.br/planos/" rel="noopener noreferrer" target="_blank" className={classes.planDetailsText}>CONHEÇA DETALHES DESTE PLANO</a>
              </div>
              </>
            )}

            <div
              className={`${classes.contratoHeaderContainer} ${classes.halfWidth} ${classes.switchContainer}`}
            >

            <span className={`${classes.subTitlePlan}`}>
              Forma de contratação
            </span>
            <div className={`${classes.switchPlan}`}>
              <span className={`${classes.typePlan}`}>Anual</span> <Switch checked={!typePlan}  onClick={handleChooseTypePlan} /> <span className={`${classes.typePlan}`}>Mensal</span>
            </div>

            </div>
            <div className={classes.choosePlanButtonWrapper}>
              <Button text="QUERO ESTE" className={`btn-success ${disablePlanButton || !selectedPlan ? classes.buttonDisabled : ''}`} onClick={handleChoosePlan} />
            </div>
          </span>
      </div>}
      {showSignContract && (
        <div className="session-container">
          <span
            className={`session-container-header ${classes.secondaryContainer}`}
          >
            <div
              className={`${classes.contratoHeaderContainer} ${classes.halfWidth}`}
            >
              <h2 className={classes.title}>Assinatura do Contrato</h2>
              <span
                style={{ marginBottom: 40, marginTop: 5, display: "block" }}
                className={`${classes.subtitle} ${classes.span}`}
              >
                Para você ter acesso completo a plataforma é necessário realizar a
                assinatura digital do seu contrato.
              </span>
              <span className={`${classes.contratoInfo} ${classes.span}`}>
                Para realizar a assinatura você deve clicar no botão ”Assinar” e
                logo abaixo irá aparecer o módulo de assinatura com instruções e
                um formulário que deve ser preenchido. <br /> Se ficar com
                dúvidas, entre em contato com nosso suporte.
              </span>
            </div>
            {!showClickSignDocument && (
              <div className={classes.contratoActionsContainer}>
                <div className={classes.contractIconWrapper}>
                  <img alt="" src={contract} />
                </div>
                <div className={classes.contractButtonsContainer}>
                  <Button
                    className={classes.customFilled}
                    text="Visualizar e assinar"
                    onClick={handleContract}
                    disabled={!signedTerms}
                  />
                </div>
              </div>
            )}
            <div className={classes.clickSignInfoWrapper}>
              <span className={classes.span}>Parceiro: </span>
              <img alt="" src={clickSign} />
            </div>
            <div
              id="container"
              className={classes.clickSignContractWrapper}
            ></div>
          </span>
        </div>
      )}
    </>
  );
};

export default ContratoAceite;
