import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import SelectComponent from "../../../components/SelectComponent";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import ButtonComponent from "../../../components/ButtonComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";

import validateFields from "../../../utils/validateFields";

import "./styles.scss";
import SmallScreen from "../../../utils/smallScreen";
import selectService from "../../../services/selectServices";
import { formaPagamentoServices } from "../../../services/formaPagamentoServices";
import Input from "../../../components/Input";
import AutocompleteInput from "../../../components/AutocompleteInput";
import InputCheck from "../../../components/InputCheckLeft";

export function CadastroFormasPagamento() {
  const isSmallScreen = SmallScreen();
  const params = useParams();
  const { id } = params;

  const [descricao, setDescricao] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [ativo, setAtivo] = useState(true);
  const [origem, setOrigem] = useState([]);
  const [initOrigem, setInitOrigem] = useState([]);
  const [listaOrigem, setListaOrigem] = useState([]);
  const [listaTipo, setListaTipo] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState("");
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const { selecionaOrigemFomaPagamento, selectFormaPagamento } = selectService;
  const {
    cadastraFormaPagamento,
    consultaFomaPagamento,
    atualizaFormaPagamento,
  } = formaPagamentoServices;

  const history = useHistory();

  async function loadOrigens() {
    const response = await selecionaOrigemFomaPagamento();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setListaOrigem(response.data);
    }
  }

  async function loadTipo() {
    const response = await selectFormaPagamento();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setListaTipo(response.data);
    }
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/lista-formas-pagamento");
    }
  }

  async function handleSubmit() {
    setLoading(true);
    let hasErrors = false;

    const fieldsToValidate = [
      {
        label: "descricao",
        value: descricao,
      },
      {
        label: "tipo",
        value: tipo,
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);
    if (origem?.length === 0) {
      fieldsErrors.push('O campo "Origem" é obrigatório');
    }

    if (fieldsErrors.length !== 0) {
      hasErrors = true;
      setLoading(false);
      setCallbackType("error");
      setCallbackErrorList(fieldsErrors);
      setCallbackMessage("Erro!");
      setCallbackShown(true);
    }

    if (hasErrors) return;

    let result = null;

    if (id) {
      result = await atualizaFormaPagamento({
        id,
        ativo,
        descricao,
        origem,
        tipo,
      });
    } else {
      result = await cadastraFormaPagamento({
        ativo,
        descricao,
        origem,
        tipo,
      });
    }
    console.log(result);
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.status !== 200 && result.status !== 201) {
      setLoading(false);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackShown(true);
      console.log(typeof result.data.error);
      if (typeof result.data.error === "string") {
        if (result.data.msg && typeof result.data.msg === "string") {
          setCallbackErrorList([result.data.msg]);
        } else {
          setCallbackErrorList([result.data.error]);
        }
      } else {
        setCallbackErrorList(Object.values(result.data.error));
      }
      return;
    }
    setLoading(false);
    setCallbackShown(true);
    setCallbackMessage(
      result?.data?.msg ? result.data.msg : "Cadastrado com sucesso"
    );
    setCallbackType("success");
    setCallbackDuration(2000);
  }

  function handleSelectedValues(values) {
    console.log({ values });
    const origensIds = values.map((item) => {
      return item.id;
    });

    setOrigem(origensIds);
  }

  async function loadFormaPagamento(id) {
    try {
      setLoading(true);
      const response = await consultaFomaPagamento(id);
      setLoading(false);
      console.log({ response });
      if (response?.status === 200 && response?.data?.success) {
        const { data } = response.data;
        setAtivo(data.ativo ? 1 : 0);
        setDescricao(data.descricao);
        setTipo(Number(data.tipo));

        const initialOrigemIds = data.origem.map((item) => {
          return {
            id: item.id_origem_forma_pagamento,
          };
        });
        setInitOrigem(initialOrigemIds);
        setOrigem(initialOrigemIds.map((item) => item.id));
        console.log({ data });
      }
    } catch (error) {
      console.log({ error });
      setLoading(false);
    }
  }

  useEffect(() => {
    loadOrigens();
    loadTipo();
    if (id) {
      loadFormaPagamento(id);
    }
  }, [id]);

  return (
    <>
      <form className="session-container-form">
        <div className="session-container">
          <span className="session-container-header">
            {loading && <OverlayLoading />}
            <div className={`${isSmallScreen ? "" : "p-horizontal"}`}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Descrição"
                    name="Descrição"
                    value={descricao}
                    required
                    handleInputChange={async (e) => {
                      setDescricao(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Tipo"
                    title="Selecione uma opção"
                    list={listaTipo}
                    required
                    initialValue={
                      listaTipo.find((item) => item.id === tipo)
                        ? listaTipo.find((item) => item.id === tipo)
                        : ""
                    }
                    callback={(id) => setTipo(id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteInput
                    label="Origem"
                    data={listaOrigem}
                    initValue={initOrigem}
                    handleSelectedValues={(values) =>
                      handleSelectedValues(values)
                    }
                    key={(option) => option.id}
                    getOptionLabel={(option) => option.nome}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    required
                    noOptionsText="Nenhuma opção disponível"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCheck
                    checked={ativo}
                    nome="ativo"
                    id="ativo"
                    title="Ativo"
                    onInputChange={() => setAtivo(!ativo)}
                  />
                </Grid>
                <Grid item>
                  <span className="required-text">* Campos obrigatórios</span>
                </Grid>
              </Grid>
              <div style={{ height: 40 }} />
            </div>
          </span>
        </div>
        <div style={{ height: 40 }} />
        <Grid item container justifyContent="flex-end">
          <ButtonComponent
            text="Salvar"
            className="btn-success zero-margin"
            onClick={handleSubmit}
          />
        </Grid>
      </form>
      {callbackShown && (
        <CallbackMessage
          open={callbackShown}
          type={callbackType}
          handleClose={handleClose}
          message={callbackMessage}
          duration={callbackDuration}
          errorList={callbackErrorList}
        />
      )}
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
