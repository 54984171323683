import React, { useState, useEffect, useCallback } from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import ButtonComponent from "../ButtonComponent";
import Input from "../Input";
import SelectComponent from "../SelectComponent";
import DatePicker from "../DatePicker";
import CallbackMessage from "../CallbackMessage";

import validateFields from "../../utils/validateFields";

import { TitleArea, Title, ColsTable, ColsCell } from "./styled.js";

function ModalRegistraRecebePaga({
  tipoRecebimentoPagamento,
  tipoRegistrarCancelar,
  data,
  valor,
  conta,
  listaContas,
  initConta = "",
  onClose,
  open,
  multaJuros, 
  abatimentosDescontos,
  ...rest
}) {
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [valorInput, setValorInput] = useState(null);

  const recebimento = "Recebimento";
  const pagamento = "Pagamento";
  const isCancelar = tipoRegistrarCancelar.toLowerCase().trim() === "cancelar";
  const isRecebimento =
    tipoRecebimentoPagamento.toLowerCase().trim() === "recebimento";
  const [returnObject, setReturnObject] = useState({
    tipoRecebimentoPagamento: tipoRecebimentoPagamento,
    tipoRegistrarCancelar: tipoRegistrarCancelar,
    data: null,
    valor: null,
    valorInicial: null,
    conta: "",
    multaJuros: null,
    abatimentosDescontos: null
  });

  const initObject = useCallback(
    (date, value, account, multaJuros, abatimentosDescontos) => {
      setReturnObject({
        ...returnObject,
        data: date,
        valor: value,
        valorInicial: value,
        conta: account,
        multaJuros: multaJuros,
        abatimentosDescontos: abatimentosDescontos
      });
    },
    [setReturnObject]
  );

  useEffect(() => {
    if (isCancelar) {
      initObject(data, valor, conta, multaJuros, abatimentosDescontos);
    } else {
      initObject(null, null, "", null, null);
    }
  }, [tipoRegistrarCancelar, conta, data, isCancelar, valor, multaJuros, abatimentosDescontos]);

  const handleClose = () => {
    let hasErrors = false;

    console.log("returnObject :: ", returnObject);

    if (returnObject.tipoRecebimentoPagamento === "Pagamento") {
      const fieldsToValidate = [
        {
          label: "datadepagamento",
          value: returnObject.data,
        },
        {
          label: "valor",
          value:
            returnObject.valor === 0 || returnObject.valor === "0.00"
              ? null
              : returnObject.valor,
        },
        {
          label: "contaselect",
          value: returnObject.conta,
        },
      ];

      const fieldsError = validateFields(fieldsToValidate);

      if (!isCancelar && fieldsError.length !== 0) {
        hasErrors = true;
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(fieldsError);
        return;
      }
    } else if (returnObject.tipoRecebimentoPagamento === "Recebimento") {
      const fieldsToValidate = [
        {
          label: "dataderecebimento",
          value: returnObject.data,
        },
        {
          label: "valor",
          value:
            returnObject.valor === 0 || returnObject.valor === "0.00"
              ? null
              : returnObject.valor,
        },
        {
          label: "contaselect",
          value: returnObject.conta,
        },
      ];

      const fieldsError = validateFields(fieldsToValidate);

      if (!isCancelar && fieldsError.length !== 0) {
        hasErrors = true;
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackErrorList(fieldsError);
        return;
      }
    }

    if (hasErrors) return;
    onClose(returnObject);

    setReturnObject({
      tipoRecebimentoPagamento: tipoRecebimentoPagamento,
      tipoRegistrarCancelar: tipoRegistrarCancelar,
      data: null,
      valor: null,
      conta: "",
    });
  };

  const handleCancelClose = () => {
    setReturnObject({
      tipoRecebimentoPagamento: tipoRecebimentoPagamento,
      tipoRegistrarCancelar: tipoRegistrarCancelar,
      data: null,
      valor: null,
      conta: "",
    });
    onClose();
  };

  function handleCBClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  return (
    <Dialog
      className="modal-main"
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCancelClose}
    >
      <TitleArea>
        <DialogTitle>
          <Title>
            {isCancelar
              ? "Cancelar ".toUpperCase()
              : "Registrar ".toUpperCase()}{" "}
            {isRecebimento
              ? recebimento.toUpperCase()
              : pagamento.toUpperCase()}
          </Title>
        </DialogTitle>
        <IconButton
          onClick={handleCancelClose}
          size="medium"
          style={{ marginRight: 16 }}
        >
          <CloseIcon />
        </IconButton>
      </TitleArea>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <DatePicker
              readOnly={isCancelar}
              label={
                "Data de " + (isRecebimento ? recebimento : pagamento) + " *"
              }
              value={returnObject.data}
              handleChange={(date) => {
                console.log(date);
                setReturnObject({
                  ...returnObject,
                  data: date,
                });
                returnObject.data = date;
              }}
            ></DatePicker>
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              readOnly={isCancelar}
              label="Valor"
              title={isCancelar ? null : "R$"}
              name="valorrecpag"
              tipo="dinheiro"
              value={isCancelar ? valor : returnObject.valor}
              required
              handleInputChange={(e, info) => {
                setReturnObject({
                  ...returnObject,
                  valor: isCancelar ? valor : e.value,
                  valorInicial: isCancelar ? valor : ( info?.event ? e.value : returnObject.valorInicial),
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {!isCancelar ? (
              <SelectComponent
                styleType="form"
                label="Conta"
                required
                title="Selecione uma conta"
                list={listaContas}
                callback={(id) => {
                  setReturnObject({
                    ...returnObject,
                    conta: id,
                  });
                }}
              />
            ) : (
              <Input
                readOnly
                label="Conta"
                icon="arrow-down"
                value={`${initConta} ${conta}`}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Input
              readOnly={isCancelar}
              label="Acréscimo Multa/Juros"
              title="R$"
              tipo="dinheiro"
              placeholder="R$"
              value={returnObject.multaJuros}
              handleInputChange={(e) => {
                setReturnObject({
                  ...returnObject,
                  multaJuros: e.value,
                  valor: isCancelar ? 
                    returnObject.multaJuros : 
                    (
                      Number(returnObject?.valorInicial)
                      + Number(e.value ? e.value : 0)
                      - Number(returnObject?.abatimentosDescontos ? returnObject?.abatimentosDescontos : 0)
                    )
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Input
              readOnly={isCancelar}
              label="Concessão de Abatimentos/Descontos"
              title=""
              tipo="dinheiro"
              placeholder="R$"
              value={returnObject.abatimentosDescontos}
              handleInputChange={(e) => {
                console.log(e);
                setReturnObject({
                  ...returnObject,
                  abatimentosDescontos: e.value,
                  valor: isCancelar ? 
                    returnObject.abatimentosDescontos : 
                    (
                      Number(returnObject?.valorInicial)
                      + Number(returnObject?.multaJuros ? returnObject?.multaJuros : 0)
                      - Number(e.value ? e.value : 0)
                    )
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ marginBottom: 30 }}>
        <ButtonComponent
          className={isCancelar ? "btn-danger" : "btn-success"}
          text={
            isCancelar
              ? "Cancelar " + (isRecebimento ? recebimento : pagamento)
              : "Salvar"
          }
          onClick={handleClose}
        />
      </DialogActions>
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        handleClose={handleCBClose}
        message={callbackMessage}
        duration={callbackDuration}
        errorList={callbackErrorList}
      />
    </Dialog>
  );
}

export default ModalRegistraRecebePaga;
