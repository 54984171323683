import React, { useState } from "react";
import { Accordion } from "../../../../components/AccordionComponent";
import { Grid } from "@material-ui/core";

export function AccordionDre({ dados }) {
  const [expanded, setExpanded] = useState(false);
  const [expandedCategoria, setExpandedCategoria] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeCategoria = (panel) => (event, isExpanded) => {
    setExpandedCategoria(isExpanded ? panel : false);
  };

  return (
    <>
      {dados.map((item, index) => {
        let values = [
          item.jan,
          item.fev,
          item.mar,
          item.abr,
          item.mai,
          item.jun,
          item.jul,
          item.ago,
          item.set,
          item.out,
          item.nov,
          item.dez,
          // item.total
        ];
        item.descricao = item.nome_grupo;
        const categorias = item.categorias || [];

        return (
          <div key={`summary_${index}`}>
            <Accordion.Root
              handleChange={handleChange}
              index={index}
              expanded={expanded}
            >
              <Accordion.Header
                expanded={expanded}
                index={index}
                item={item}
                columns={values}
              />

              <Accordion.Body>
                {categorias.map((itemCategoria, indexCategoria) => {
                  itemCategoria.descricao = itemCategoria.nome_categoria;
                  const subcategorias = itemCategoria.subcategorias;
                  let valuesCategoria = [
                    item.jan,
                    item.fev,
                    item.mar,
                    item.abr,
                    item.mai,
                    item.jun,
                    item.jul,
                    item.ago,
                    item.set,
                    item.out,
                    item.nov,
                    item.dez,
                  ];

                  return (
                    <Accordion.Root
                      handleChange={handleChangeCategoria}
                      index={indexCategoria}
                      expanded={expandedCategoria}
                      child={true}
                      key={`categoria_${indexCategoria}`}
                    >
                      <Accordion.Header
                        expanded={expandedCategoria}
                        index={indexCategoria}
                        item={itemCategoria}
                        columns={valuesCategoria}
                      />

                      <Accordion.Body>
                        <Grid container direction="column">
                          {subcategorias.map(
                            (subcategoria, indexSubcategoria) => {
                              let valuesSubcategoria = [
                                subcategoria.Janeiro,
                                subcategoria.Fevereiro,
                                subcategoria.Marco,
                                subcategoria.Abril,
                                subcategoria.Maio,
                                subcategoria.Junho,
                                subcategoria.Julho,
                                subcategoria.Agosto,
                                subcategoria.Setembro,
                                subcategoria.Outubro,
                                subcategoria.Novembro,
                                subcategoria.Dezembro,
                                // subcategoria.total
                              ];
                              return (
                                <Grid
                                  item
                                  container
                                  direction="column"
                                  key={`subcategoria_${indexCategoria}_${indexSubcategoria}`}
                                >
                                  <Grid
                                    item
                                    className="table-details container-row-dre"
                                  >
                                    <Grid
                                      item
                                      container
                                      className="inner-font-title name-categoria title-categoria"
                                    >
                                      <div>{subcategoria.subcategoria}</div>
                                    </Grid>
                                    {valuesSubcategoria.map(
                                      (itemSubcat, indexSubcat) => {
                                        if (itemSubcat) {
                                          itemSubcat = itemSubcat.replace(
                                            ".",
                                            ";"
                                          );
                                          itemSubcat = itemSubcat.replace(
                                            ",",
                                            "."
                                          );
                                          itemSubcat = itemSubcat.replace(
                                            ";",
                                            ","
                                          );
                                        }
                                        return (
                                          <Grid
                                            item
                                            key={`subCat${indexCategoria}_${indexSubcategoria}_${indexSubcat}`}
                                            className="cell-table keep-number cell-info"
                                          >
                                            {itemSubcat}
                                          </Grid>
                                        );
                                      }
                                    )}
                                    <Grid
                                      item
                                      className="name-column total-column center-end"
                                    >
                                      {subcategoria.total
                                        .replace(".", ";")
                                        .replace(",", ".")
                                        .replace(";", ",")}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      </Accordion.Body>
                    </Accordion.Root>
                  );
                })}
              </Accordion.Body>
            </Accordion.Root>
            <div className="space-vertical" />
          </div>
        );
      })}
    </>
  );
}
