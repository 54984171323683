import { Grid } from "@material-ui/core";
import React from "react";
import "./styles.scss";
import ImagePreview from "../../assets/img/user.svg";

export const ImagemPerfil = ({
  src,
  alt,
  showButton,
  textButton,
  fileFormat = "",
  handleImageChange,
  idButton = 0,
  disabled,
  ...props
}) => {
  const onChange = (event) => {
    let file = event.target.files[0];

    if (!file) return;

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      handleImageChange({ base64: reader.result });
    };
  };

  return (
    <Grid
      item
      container
      className="container-imagem-perfil"
      justifyContent="center"
      {...props}
    >
      <div className="imagem-perfil">
        <img src={src || ImagePreview} alt={alt} />
      </div>
      {showButton ? (
        <>
          <label htmlFor={`input-file-${idButton}`} className="btn btn-success">
            {textButton}
          </label>
          <input
            id={`input-file-${idButton}`}
            style={{ display: "none" }}
            type="file"
            accept={fileFormat}
            onChange={onChange}
            disabled={disabled}
          />
        </>
      ) : null}
    </Grid>
  );
};
