import Axios from './Axios';

const cadastroFornecedorService = {
  cadastrarFornecedor: async (form) => {
    const add = await Axios.post('/cadastra-fornecedor', {
      observacao: form.observacao,
      identificacao_escolhapfpj: form.cnpj_cpf,
      identificacao_id_empresa: form.id_empresa,

      identificacao_pf_cpf: form.cpf,
      identificacao_pj_cnpj: form.cnpj,

      identificacao_pj_razao_social: form.razaoSocial,
      identificacao_pf_nome: form.razaoSocial,
      identificacao_pj_nome_fantasia: form.nome_fantasia,

      endereco_rua: form.endereco,
      endereco_numero: form.numEnd,
      endereco_complemento: form.complemento,
      endereco_bairro: form.bairro,
      endereco_cep: form.cep,
      endereco_cidade: form.cidade,
      endereco_estado: form.estado,

      identificacao_pj_site: form.site,
      identificacao_pj_indicador_ie: form.cod_indicador_ie,
      identificacao_pj_inscricao_municipal: form.inscricao_municipal,
      identificacao_pj_inscricao_estadual: form.inscricao_estadual,
      contato: form.contato,
      id_categoria_padrao: form.id_categoria_padrao
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return add;
  },
  consultaFornecedor: async (id = null) => {
    const result = await Axios.get(`/consulta-fornecedor/${id}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  atualizaFornecedor: async (id, form) => {
    const update = await Axios.post(`/atualiza-fornecedor/${id}`, {
      // 31/08/2020 - por jonas gedrat depois de cadastrado nao pode mais alterar cnpj-cpf
      observacao: form.observacao,
      identificacao_escolhapfpj: form.cnpj_cpf,
      identificacao_id_empresa: form.id_empresa,

      identificacao_pf_cpf: form.cpf,
      identificacao_pj_cnpj: form.cnpj,

      identificacao_pj_razao_social: form.razaoSocial,
      identificacao_pf_nome: form.razaoSocial,
      identificacao_pj_nome_fantasia: form.nome_fantasia,

      endereco_rua: form.endereco,
      endereco_numero: form.numEnd,
      endereco_complemento: form.complemento,
      endereco_bairro: form.bairro,
      endereco_cep: form.cep,
      endereco_cidade: form.cidade,
      endereco_estado: form.estado,

      identificacao_pj_site: form.site,
      identificacao_pj_indicador_ie: form.cod_indicador_ie,
      identificacao_pj_inscricao_municipal: form.inscricao_municipal,
      identificacao_pj_inscricao_estadual: form.inscricao_estadual,
      contato: form.contato,
      id_categoria_padrao: form.id_categoria_padrao
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return update;
  },
  removeFornecedor: async (id = null) => {
    const result = await Axios.get(`/remover-fornecedor/${id}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};

export default cadastroFornecedorService;
