import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { parseISO, format } from "date-fns";

import TableComponent from "../../../components/TableComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Button from "../../../components/ButtonComponent";

import { Grid } from "@material-ui/core";
import Select from "../../../components/SelectComponent";

import notasFiscaisService from "../../../services/notasFiscaisService";
import { base64ToFile, numberToCurrency } from "../../../utils/functions";

import classes from "./styles.module.scss";
import loadPermissoes from "../../../contexts/RoutesContext";
import ModalCadastroRecebimento from "../../../components/ModalCadastroRecebimento";
import DatePicker from "../../../components/DatePicker";
import Input from "../../../components/Input";
import useCompany from "../../../hooks/useCompany";

function EmissaoLista() {
  const history = useHistory();

  const {
    getNotasFiscais,
    consultaPDFInvoisys,
    consultaXMLInvoisys,
    cancelarNotaInvoisys,
    exportarRelatorio,
  } = notasFiscaisService;

  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    setEmpresaPagina,
  } = useCompany("nfse");

  const [listRequest, setListRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModalCancelar, setOpenModalCancelar] = useState(false);
  const [nfeId, setNfeId] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [nfOptions, setNfOptions] = useState([]);
  const [notaData, setNotaData] = useState({});
  const permissions = loadPermissoes();
  const [periodoEmissaoInicio, setPeriodoEmissaoInicio] = useState(null);
  const [periodoEmissaoFim, setPeriodoEmissaoFim] = useState(null);

  const [numeroInicial, setNumeroInicial] = useState(null);
  const [numeroFinal, setNumeroFinal] = useState(null);
  const [status, setStatus] = useState(null);
  const [serieInicial, setSerieInicial] = useState(null);
  const [serieFinal, setSerieFinal] = useState(null);
  const [lote, setLote] = useState("");
  const [codigoVerificacao, setCodigoVerificacao] = useState(null);

  const [listStatus, setListStatus] = useState([]);

  const { selecionaStatusNotaServico } = notasFiscaisService;

  const [visible, setVisible] = useState(false);
  const [showModalCadastroRecebimento, setShowModalCadastroRecebimento] =
    useState(false);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Nº/Série/Lote", dataRef: "num_serie_lote" },
      { columnName: "Data de Emissão", dataRef: "data_emissao" },
      { columnName: "Empresa", dataRef: "razao_social" },
      { columnName: "Código de verificação", dataRef: "cod_verificacao" },
      { columnName: "Valor", dataRef: "valor_total_nota" },
      { columnName: "Status", dataRef: "status" },
    ],
    options: {
      more: [
        { icon: "file", label: "Download XML", action: "abrir-nfe" },
        { icon: "cancel", label: "Cancelar NFS-e", action: "cancelar-nfe" },
        {
          icon: "search",
          label: "Busca SEFAZ",
          action: "busca-sefaz",
        },
        {
          icon: "copy-icon",
          label: "Copiar NF",
          action: "copiar-nf",
        },
        {
          icon: "shopping-cart-check",
          label: "Agendar recebimento",
          action: "agendar-recebimento",
        },
      ],
      nfe: true,
    },
    display: {
      statusLabels: true,
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      filter: false,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-xls",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: "data_emissao",
    orderByType: "desc",
    perPage: null,
    searchTerm: null,
    id_empresa: null,
    data_emissao_inicio: null,
    data_emissao_fim: null,
    numero_inicial: null,
    numero_final: null,
    status: null,
    serie_inicial: null,
    serie_final: null,
    lote: null,
    cod_verificacao: null,
  });

  let updateTable = useCallback(async () => {
    setLoading(true);
    window.scrollTo(0, 0);

    const response = await getNotasFiscais(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.id_empresa,
      defaultConfigTable.data_emissao_inicio,
      defaultConfigTable.data_emissao_fim,
      defaultConfigTable.numero_inicial,
      defaultConfigTable.numero_final,
      defaultConfigTable.status,
      defaultConfigTable.serie_inicial,
      defaultConfigTable.serie_final,
      defaultConfigTable.lote,
      defaultConfigTable.cod_verificacao
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const { data } = response;

    let dataArray = [];
    if (data !== undefined && data.data !== undefined) {
      dataArray = data.data.map((item) => {
        const formattedDate = format(
          parseISO(item.data_emissao),
          "dd/MM/yyyy HH:mm:ss"
        );

        const num_serie_lote = `${item.numero}-${item.serie}-${item.lote}`;

        return {
          ...item,
          data_emissao: formattedDate,
          valor_total_nota: numberToCurrency(item.valor_total_nota),
          num_serie_lote,
          id: item.id_nota_fiscal,
        };
      });

      const newListRequest = {
        ...data,
        data: dataArray,
      };

      console.log(newListRequest);

      setListRequest(newListRequest);
    } else {
      dataArray = data.map((item) => {
        const formattedDate = format(
          parseISO(item.data_emissao),
          "dd/MM/yyyy HH:mm:ss"
        );

        const num_serie_lote = `${item.numero}-${item.serie}-${item.lote}`;

        return {
          ...item,
          data_emissao: formattedDate,
          valor_total_nota: numberToCurrency(item.valor_total_nota),
          num_serie_lote,
          id: item.id_nota_fiscal,
        };
      });

      const newListRequest = {
        ...data,
        data: dataArray,
      };

      console.log(newListRequest);

      setListRequest(newListRequest);
    }

    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
    defaultConfigTable.data_emissao_inicio,
    defaultConfigTable.data_emissao_fim,
    defaultConfigTable.numero_inicial,
    defaultConfigTable.numero_final,
    defaultConfigTable.status,
    defaultConfigTable.serie_inicial,
    defaultConfigTable.serie_final,
    defaultConfigTable.lote,
    defaultConfigTable.cod_verificacao,
    getNotasFiscais,
  ]);

  let loadNfOptions = useCallback(() => {
    let list = [];
    let nfs = permissions.find((item) => item.path === "/emissao/nfs");
    let nfc = permissions.find((item) => item.path === "/emissao/nfc");
    let nf = permissions.find((item) => item.path === "/emissao/nfe");
    if (nfs && nfs.accessGranted)
      list.push({
        id: "nfs",
        name: "NFS-e",
      });
    if (nfc && nfc.accessGranted)
      list.push({
        id: "nfc",
        name: "NFC-e",
      });
    if (nf && nf.accessGranted)
      list.push({
        id: "nfe",
        name: "NF-e",
      });

    setNfOptions(list);
  }, [setNfOptions]);

  const loadStatus = useCallback(async () => {
    const response = await selecionaStatusNotaServico();

    if (response.status === 200) setListStatus(response.data);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  }, []);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    if (orderBy === "num_serie_lote") orderBy = "numero";
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }
  async function cancelarNfe() {
    const response = await cancelarNotaInvoisys(nfeId);
    setLoading(true);
    console.log(response);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status === 201 || response.status === 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Nota fiscal cancelada.");
      updateTable();
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response.data.error) {
        setCallbackErrorList(
          typeof response.data.error === "string"
            ? [response.data.error]
            : Object.values(response.data.error)
        );
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
    }
    setOpenModalCancelar(false);
  }

  let onMoreAction = async (action) => {
    setLoading(true);
    switch (action.action) {
      case "abrir-nfe": {
        const response = await consultaXMLInvoisys(Number(action.id));
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        if (response.status === 500) {
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackDuration(3000);
          setCallbackMessage("Erro!");
          setCallbackErrorList([
            "Erro interno no servidor. Contate o suporte.",
          ]);
          return;
        } else {
          base64ToFile(
            "data:application/xml;base64," + response.data.xml,
            "nota_fiscal"
          );
        }

        break;
      }
      case "cancelar-nfe": {
        setOpenModalCancelar(true);
        setNfeId(Number(action.id));

        break;
      }
      case "busca-sefaz": {
        setLoading(true);

        await onBuscaSefaz(action.id);

        setLoading(false);
        break;
      }
      case "copiar-nf": {
        console.log("Copiar NF");
        console.log(action.id);

        // const response =  {}
        notasFiscaisService
          .dadosNfseSefaz(action.id)
          .then((response) => {
            if (response.status === 200) {
              console.log("Dados nota fiscal NFSE");
              console.log(response.data);
              history.push("/emitir-nfse", response.data[0]);
            }
          })
          .catch(() => {
            console.log("Error");
          });
        break;
      }
      case "agendar-recebimento": {
        // console.log(action.id)
        const nota = listRequest.data.find((nota) => nota.id === action.id);
        // console.log(nota)
        setNotaData(nota);
        setShowModalCadastroRecebimento(true);
        break;
      }
      default:
        break;
    }
    setLoading(false);
  };

  const onBuscaSefaz = async (id) => {
    const response = await notasFiscaisService.consultaNfseSefaz(id);

    if (response.status === 200) {
      setCallbackType("success");
      setCallbackDuration(4000);
      setCallbackMessage(
        response?.data?.message ? response.data.message : "Sucesso!"
      );
      setCallbackShown(true);
      updateTable();
    }
    if (response.status === 400) {
      setCallbackType("error");
      setCallbackDuration(4000);
      setCallbackMessage("Erro!");
      setCallbackErrorList(
        response?.data?.error?.length > 0
          ? response.data.error
          : ["Ocorreu um erro ao consultar a nota"]
      );
      setCallbackShown(true);
    }

    if (response.status === 500) {
      setCallbackType("error");
      setCallbackDuration(5000);
      setCallbackMessage("Erro!");
      setCallbackErrorList([
        "Erro interno do servidor. Por favor, contate o suporte.",
      ]);
      setCallbackShown(true);
    }

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  };

  let onFile = async (id) => {
    setLoading(true);
    const response = await consultaPDFInvoisys(Number(id));
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 500) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Erro interno no servidor. Contate o suporte."]);
      return;
    }

    base64ToFile(
      "data:application/pdf;base64," + response.data.pdf,
      "nota_fiscal"
    );
    setLoading(false);
  };

  let handleEmitirNota = useCallback(() => {
    setVisible((prev) => !prev);
  }, []);

  let handleSelectNfOption = async (event) => {
    const itemId = event.target.dataset.id;
    setVisible(false);
    setLoading(true);

    if (itemId === "nfs") {
      return history.push("/emitir-nfse");
    }

    if (itemId === "nfc") {
      return history.push("/emitir-nfce");
    }

    if (itemId === "nfe") {
      return history.push("/emitir-nfe");
    }

    setLoading(false);
  };

  const handleSelectEmpresa = async (id) => {
    console.log(id);
    setEmpresaSelecionada(id);
  };

  const handleApplyFilters = () => {
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_empresa: empresaSelecionada,
      data_emissao_inicio: periodoEmissaoInicio,
      data_emissao_fim: periodoEmissaoFim || null,
      numero_inicial: numeroInicial || null,
      numero_final: numeroFinal || null,
      status: status || null,
      serie_inicial: serieInicial || null,
      serie_final: serieFinal || null,
      lote: lote || null,
      cod_verificacao: codigoVerificacao || null,
    });
    setEmpresaPagina("nfse", empresaSelecionada);
  };

  function handleCloseModal() {
    setShowModalCadastroRecebimento(false);
  }

  const cbOnSuccess = useCallback((status) => {
    console.log(status);
    if (status) {
    }
  }, []);

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setNumeroInicial(null);
    setNumeroFinal(null);
    setStatus(null);
    setSerieInicial(null);
    setSerieFinal(null);
    setLote(null);
    setCodigoVerificacao(null);
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
      data_emissao_inicio: null,
      data_emissao_fim: null,
      numero_inicial: null,
      numero_final: null,
      status: null,
      serie_inicial: null,
      serie_final: null,
      lote: null,
      cod_verificacao: null,
    });
    setEmpresaPagina("nfse", null);
  };

  const onTableMoreAction = async (action) => {
    console.log(action);

    if (!defaultConfigTable?.id_empresa) {
      setCallbackMessage("Erro!");
      setCallbackType("error");
      setCallbackErrorList([
        "É necessário selecionar uma empresa antes de exportar.",
      ]);
      setCallbackShown(true);
      return;
    }
    setLoading(true);
    const response = await exportarRelatorio(
      defaultConfigTable?.id_empresa ? defaultConfigTable?.id_empresa : null,
      defaultConfigTable?.data_emissao_inicio
        ? defaultConfigTable?.data_emissao_inicio
        : null,
      defaultConfigTable?.data_emissao_fim
        ? defaultConfigTable?.data_emissao_fim
        : null,
      defaultConfigTable?.numero_inicial || null,
      defaultConfigTable?.numero_final || null,
      defaultConfigTable?.status || null,
      defaultConfigTable?.serie_inicial || null,
      defaultConfigTable?.serie_final || null,
      defaultConfigTable?.lote || null,
      defaultConfigTable?.cod_verificacao || null
    );
    console.log(response);
    if (response.status === 200) {
      base64ToFile(response.data.arquivo, "cbhub_relatorio_nfse", "xlsx");
    } else if (response?.status === 500 || response?.response?.status === 500) {
      setCallbackErrorList([
        "Erro interno no servidor. Por favor, contate o suporte",
      ]);
      setCallbackShown(true);
    } else if (response?.status === 400 || response?.response?.status === 400) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackMessage("Erro!");
      setCallbackDuration(5000);
      setCallbackType("error");
      setCallbackErrorList(
        Object.values(
          response?.data?.error || response?.response?.data?.error
        ).flat(Infinity)
      );
      return;
    } else {
      setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
      setCallbackShown(true);
      setCallbackType("error");
    }
    setLoading(false);
  };

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                      ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={handleSelectEmpresa}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              label="Período de emissão de:"
              handleChange={(value) => setPeriodoEmissaoInicio(value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              label="Período de emissão até:"
              handleChange={(value) => setPeriodoEmissaoFim(value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Select
              styleType="form"
              label="Status"
              initialValue={
                listStatus.find((item) => item.id === status)
                  ? listStatus.find((item) => item.id === status).nome
                  : ""
              }
              title="Selecione uma opção"
              list={listStatus}
              callback={(e) => {
                setStatus(e);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input
              nome="numeroinicial"
              label="Número inicial"
              tipo="text"
              value={numeroInicial || ""}
              handleInputChange={(event) => {
                setNumeroInicial(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input
              nome="numerofinal"
              label="Número final"
              tipo="text"
              value={numeroFinal || ""}
              handleInputChange={(event) => {
                setNumeroFinal(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input
              nome="lote"
              label="Lote"
              tipo="text"
              value={lote || ""}
              handleInputChange={(event) => {
                setLote(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input
              nome="serieinicial"
              label="Série inicial"
              tipo="text"
              value={serieInicial || ""}
              handleInputChange={(event) => {
                setSerieInicial(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input
              nome="seriefinal"
              label="Série final"
              tipo="text"
              value={serieFinal || ""}
              handleInputChange={(event) => {
                setSerieFinal(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Input
              nome="cod_verificacao"
              label="Código de verificação"
              tipo="text"
              value={codigoVerificacao || ""}
              handleInputChange={(event) => {
                setCodigoVerificacao(event.target.value);
              }}
            />
          </Grid>
          <Grid className={classes.marginLeftAuto}>
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  useEffect(() => {
    loadNfOptions();
  }, [loadNfOptions]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    loadStatus();
  }, []);

  return (
    <>
      <div className={classes.nfHeaderButton}>
        <Button
          onClick={handleEmitirNota}
          className="btn-primary icon-right"
          text="Emitir Nota Fiscal"
          icone="hamburger"
          iconeColor="#fff"
          iconeSize={20}
        />
        <div className={classes.nfOptionsContainer}>
          <ul className={visible ? classes.visible : ""}>
            {nfOptions.map((item, index) => (
              <li
                key={index}
                data-id={item.id}
                onMouseDown={handleSelectNfOption}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {loading && <OverlayLoading />}
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbFile={onFile}
        cbMoreAction={onMoreAction}
        filterComponent={filterComponent()}
        cbTableMoreAction={onTableMoreAction}
      />
      <embed src="" type="application/pdf" width="100%" height="100%" />
      <ConfirmDialog
        acceptLabel="Sim"
        cancelLabel="Sair"
        btnClass="btn-success-strong"
        description="Você tem certeza que deseja cancelar essa NF-e?"
        open={openModalCancelar}
        title="CONFIRMAR CANCELAMENTO"
        onAccept={cancelarNfe}
        onCancel={() => {
          setOpenModalCancelar(false);
        }}
      />
      <ModalCadastroRecebimento
        open={showModalCadastroRecebimento}
        onClose={handleCloseModal}
        cbSuccess={cbOnSuccess}
        notaData={notaData}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default EmissaoLista;
