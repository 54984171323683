import Axios from "./Axios";

const previsaoFeriasService = {
  getPrevisoes: async ({
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null,
    status = null,
  }) => {
    const result = await Axios.get("/lista-dp-previsao-ferias", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
        status,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  confirmarPrevisaoFerias: async (id) => {
    const response = await Axios.post(`confirma-previsao-ferias/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  finalizaPrevisaoFerias: async (id) => {
    const response = await Axios.post(`finaliza-previsao-ferias/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
};
export default previsaoFeriasService;
