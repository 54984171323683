import React, { useEffect, useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import classes from "./styles.module.css";

function CallbackMessage({
  open,
  handleClose = () => {},
  type = "",
  message = "",
  errorList = [],
  duration = 6000,
  vertical = "bottom",
}) {
  return (
    <div id="alertBox" className={classes.container}>
      <Snackbar
        anchorOrigin={{ vertical: vertical, horizontal: "right" }}
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
      >
        <Alert severity={type}>
          {type === "success" ? (
            <span className={classes.message}>{message}</span>
          ) : null}
          {type === "error" ? (
            <>
              <AlertTitle>{message}</AlertTitle>
              <ul className={classes.errorList}>
                {errorList &&
                  errorList.length !== 0 &&
                  errorList.map((item, index) => <li key={index}>{item}</li>)}
              </ul>
            </>
          ) : null}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default CallbackMessage;
