
import React, { useState, useEffect } from 'react';
import ButtonComponent from '../ButtonComponent';
import './styles.scss';

function Stepper({
  arraySteps = [],
  arrayContent = [],
  handleSubmit = () => {},
  useActiveStep = false,
  activeStep,
  handleClickStep = () => {},
  activeStepNavigation = false
}) {
  const [currentStep, setCurrentStep] = useState();
  const [previousStep, setPreviousStep] = useState(0);

  useEffect(() => {
    setCurrentStep(0);
  }, []);

  useEffect(() => {
    if (currentStep !== previousStep) {
      setPreviousStep(currentStep);
      const currentID = `#div${currentStep}`;
      const previousID = `#div${previousStep}`;
      const searchCurrentDIV = document.querySelector(currentID);
      const searchPreviousDIV = document.querySelector(previousID);

      if (searchCurrentDIV) {
        searchCurrentDIV?.classList?.remove('hide-content');
      }
      if (searchPreviousDIV) {
        searchPreviousDIV?.classList?.add('hide-content');
      }
    }
  }, [currentStep, previousStep]);

  const callbackNextSteps = (direction = false) => {
    if (direction) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const callbackDisableButton = value => {
    if (document.querySelector(`#stepProximo`).disabled) {
      document.querySelector(`#stepProximo`).disabled = false;
      document.querySelector(`#stepEnviar`).disabled = true;
    } else {
      document.querySelector(`#stepProximo`).disabled = true;
      document.querySelector(`#stepEnviar`).disabled = false;
    }
  };

  //Este if (assim como o activeStep e o useActiveStep) são apenas para o uso do CadastroFornecedores (que eu tinha adaptado o stepper)
  //Em breve vou padronizar-lo também.
  if (useActiveStep === true) {
    return (
      <React.Fragment>
        <div className="steps">
          <div className="stepIndice">
            {arraySteps.map((element, index) => (
              <a
                key={index}
                value={index}
                className={`${activeStep === index ? 'active' : ''} ${activeStepNavigation ? 'showCursorPointer' : 'showCursorDefault'}`}
                onClick={() => handleClickStep(index)}
              >
                <span>{index + 1}.</span>
                {element}
              </a>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="steps">
        <div className="stepIndice">
          {arraySteps.map((element, index) => (
            <a
              key={index}
              value={index}
              className={`${currentStep === index ? 'active' : ''}`}
            >
              <span>{index + 1}.</span>
              {element}
            </a>
          ))}
        </div>
        <div className="stepCorpo">
          {arrayContent.length === 0 ? (
            <>
              {arraySteps.map((element, index) => (
                <div
                  key={index}
                  id={`div${index}`}
                  className="hide-content m-30"
                >
                  Nenhum conteudo encontrado.
                  {index}
                </div>
              ))}
            </>
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                {arrayContent.map((element, index) => (
                  <>
                    <div
                      key={index}
                      id={`div${index}`}
                      className="hide-content m-30"
                    >
                      {element(callbackDisableButton)}
                    </div>
                    <div className="stepBtn">
                      <div className="stepVolta">
                        <ButtonComponent
                          text="Voltar"
                          className="btn-secondary"
                          onClick={() => callbackNextSteps()}
                          disabled={currentStep === 0}
                          id="stepAnterior"
                        />
                      </div>
                      <div className="stepAvanca">
                        {currentStep !== 0 && (
                          <>
                            {currentStep === arraySteps.length - 1 && (
                              <ButtonComponent
                                text="Salvar"
                                type="submit"
                                className="btn-success"
                                disabled={!currentStep}
                                id="stepEnviar"
                              />
                            )}
                          </>
                        )}
                        <ButtonComponent
                          text="Avançar"
                          className="btn-info"
                          onClick={() => callbackNextSteps(true)}
                          disabled={currentStep === arraySteps.length - 1}
                          id="stepProximo"
                        />
                      </div>
                    </div>
                  </>
                ))}
              </form>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Stepper;
