import React, { useState, useEffect } from "react";
import moment from "moment";

import {
  DialogTitle,
  Dialog,
  DialogContent,
  IconButton,
  Grid,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import ModalLoginExpirado from "../ModalLoginExpirado";

import ButtonComponent from "../ButtonComponent";
import InputComponent from "../Input";
import InputFile from "../InputFile";
import DatePicker from "../DatePicker";
import SelectComponent from "../SelectComponent";
import CallbackMessage from "../CallbackMessage";
import OverlayLoading from "../OverlayLoading";

import pagamentosService from "../../services/pagamentosService";
import centroCustosService from "../../services/centroCustosService";
import fornecedoresService from "../../services/fornecedoresService";
import recebimentosService from "../../services/recebimentosService";
import clientesService from "../../services/clientesService";

//Falta listar os clientes da empresa;

import "./styles.scss";

function ModalRegistraRecebePaga({
  isPagamento = false,
  isRecebimento = false,
  onClose,
  open,
  callback = () => {},
  empresaId = null,
  contaId = null,
}) {
  const [listCentroCusto, setListCentroCusto] = useState([]);
  const [listClientes, setListClientes] = useState([]);
  const [listFornecedores, setListFornecedores] = useState([]);
  const [listCategorias, setListCategorias] = useState([]);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const currentDate = moment().format("YYYY-MM-DD");
  const [descricao, setDescricao] = useState("");
  const [vencimento, setVencimento] = useState("");
  const [idCentro, setCentroId] = useState(null);
  const [categoriaId, setCategoriaId] = useState(null);
  const [clienteId, setClienteId] = useState(null);
  const [fornecedorId, setFornecedorId] = useState(null);
  const [value, setValue] = useState("");
  const [file, setFile] = useState(null);

  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState([]);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [duration, setDuration] = useState(6000);

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (empresaId) {
        if (isRecebimento) {
          await recebimentosService
            .selecionaCategoriaRecebimento(empresaId)
            .then((response) => {
              if (response.status === 401) {
                setOpenLoginExpirado(true);
                setLoading(false);
                return;
              }
              setListCategorias(response.data);
            });
        } else {
          await pagamentosService
            .selecionaCategoriaPagamento(empresaId)
            .then((response) => {
              if (response.status === 401) {
                setOpenLoginExpirado(true);
                setLoading(false);
                return;
              }
              setListCategorias(response.data);
            });
        }
        await centroCustosService
          .selecionaCentroCusto(empresaId)
          .then((response) => {
            if (response.status === 401) {
              setOpenLoginExpirado(true);
              setLoading(false);
              return;
            }
            setListCentroCusto(response.data);
          });

        if (isRecebimento) {
          await clientesService.selecionaCliente(empresaId).then((response) => {
            if (response.status === 401) {
              setOpenLoginExpirado(true);
              setLoading(false);
              return;
            }
            setListClientes(response.data);
          });
        }

        if (isPagamento) {
          await fornecedoresService
            .selecionaFornecedores(empresaId)
            .then((response) => {
              if (response.status === 401) {
                setOpenLoginExpirado(true);
                setLoading(false);
                return;
              }
              setListFornecedores(response.data);
            });
        }
      }
      setLoading(false);
    })();
    setVencimento(currentDate);
  }, [empresaId]);

  const formRecebimento = {
    dataVencimento: vencimento,
    valor: value,
    clienteId: clienteId,
    centroCustoId: idCentro,
    categoriaId: categoriaId,
    anexo: file,
    descricao: descricao,
  };

  const formPagamento = {
    dataVencimento: vencimento,
    valor: value,
    fornecedorId: fornecedorId,
    centroCustoId: idCentro,
    categoriaId: categoriaId,
    anexo: file,
    descricao: descricao,
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;
    setLoading(true);
    if (isPagamento) {
      const response = await pagamentosService.cadastraPagamentoExtrato(
        empresaId,
        file,
        descricao,
        categoriaId,
        vencimento,
        value,
        fornecedorId,
        idCentro,
        contaId
      );
      console.log(response);
      console.log(response.status);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        setCallbackShown(true);
        setCallbackType("success");
        setCallbackMessage("Pagamento registrado com sucesso!");
        setDuration(3000);
      } else {
        setLoading(false);
        setCallbackShown(true);
        setCallbackType("error");
        setDuration(3000);
        setCallbackMessage("Erro!");
        if (response.data.error) setErrors(Object.values(response.data.error));
        else setErrors(["Ocorreu um erro"]);
      }
    }
    if (isRecebimento) {
      setLoading(true);
      const response = await recebimentosService.cadastraRecebimentoExtrato(
        empresaId,
        file,
        descricao,
        categoriaId,
        vencimento,
        value,
        clienteId,
        idCentro,
        contaId
      );
      console.log(response);
      console.log(response.status);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        setCallbackShown(true);
        setCallbackType("success");
        setCallbackMessage("Recebimento registrado com sucesso!");
        setDuration(3000);
      } else {
        setLoading(false);
        setCallbackShown(true);
        setCallbackType("error");
        setDuration(3000);
        setCallbackMessage("Erro!");
        if (response.data.error) setErrors(Object.values(response.data.error));
        else setErrors(["Ocorreu um erro"]);
      }
    }
  };

  const handleCancelClose = () => {
    onClose();
  };

  function validateFields() {
    let list = [];
    if (vencimento === "") list.push("O campo Vencimento deve ser preenchido.");
    if (value === "") list.push("O campo Valor deve ser preenchido.");
    if (isPagamento && fornecedorId === null)
      list.push("O campo Fornecedor deve ser selecionado.");
    if (isRecebimento && clienteId === null)
      list.push("O campo Cliente deve ser selecionado.");
    if (categoriaId === null)
      list.push("O campo Categoria deve ser selecionado.");
    if (descricao === "") list.push("O campo Descrição deve ser preenchido.");

    setErrors(list);
    setCallbackType("error");
    setCallbackMessage("Erro!");
    if (list.length > 0) setCallbackShown(true);
    return list.length === 0;
  }

  function handleCloseMessage(event, reason) {
    if (isPagamento) callback(formPagamento);
    else callback(formRecebimento);
    setCallbackShown(false);
    if (errors.length === 0) {
      onClose();
    }
    setErrors([]);
  }

  return (
    <Dialog open={open} onClose={handleCancelClose} maxWidth="md">
      <div className="modal-title-area">
        <DialogTitle>
          <span className="modal-title">
            NOVO {isRecebimento ? "RECEBIMENTO" : "PAGAMENTO"}
          </span>
        </DialogTitle>
        <IconButton
          onClick={onClose}
          size="medium"
          style={{ marginRight: 16, color: "#EF4066", padding: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs sm md={4}>
            <DatePicker
              label="Data de Vencimento"
              required
              value={vencimento}
              handleChange={(date) => {
                setVencimento(date);
              }}
            ></DatePicker>
          </Grid>
          <Grid item xs sm md={4}>
            <InputComponent
              tipo="dinheiro"
              required
              label="Valor"
              handleInputChange={(event) => setValue(event.value)}
            />
          </Grid>
          <Grid item xs sm md={4}>
            <SelectComponent
              styleType="form"
              label="Centro de Custo"
              title="Selecione um centro de custo"
              list={listCentroCusto}
              callback={(id) => setCentroId(id)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            {isRecebimento && (
              <SelectComponent
                styleType="form"
                label="Cliente"
                required
                title="Selecione um cliente"
                list={listClientes}
                callback={(id) => setClienteId(id)}
              />
            )}
            {isPagamento && (
              <SelectComponent
                styleType="form"
                label="Fornecedor"
                required
                title="Selecione um fornecedor"
                list={listFornecedores}
                callback={(id) => setFornecedorId(id)}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <SelectComponent
              styleType="form"
              label="Categoria"
              required
              title="Selecione uma categoria"
              list={listCategorias}
              callback={(id) => setCategoriaId(id)}
            />
          </Grid>
          <Grid item xs={12}>
            <span className="label">Descrição *</span>
            <textarea
              placeholder="Defina uma descrição"
              style={{ resize: "none" }}
              className="large-input"
              value={descricao}
              onChange={(event) => setDescricao(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputFile title="Anexo" onChange={(value) => setFile(value)} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify="flex-end"
          spacing={2}
          style={{ marginTop: 8, marginBottom: 8 }}
        >
          <Grid item>
            <ButtonComponent
              className="btn-success"
              text="Salvar"
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {loading && <OverlayLoading />}

      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        message={callbackMessage}
        errorList={errors}
        handleClose={handleCloseMessage}
        duration={duration}
      />

      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}

export default ModalRegistraRecebePaga;
