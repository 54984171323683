import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import TableComponent from "../../components/TableComponent";
import ConfirmDialog from "../../components/ConfirmDialog";
import OverlayLoading from "../../components/OverlayLoading";
import CallbackMessage from "../../components/CallbackMessage";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";

import { base64ToFile } from "../../utils/functions";
import { formatDate } from "../../utils/dates";

import { Grid } from "@material-ui/core";
import Select from "../../components/SelectComponent";
import Button from "../../components/ButtonComponent";

import certidoesService from "../../services/certidoesService";

import "./styles.css";
import { useIsMount } from "../../hooks/useIsMount";
import useCompany from "../../hooks/useCompany";

function Certidoes() {
  const history = useHistory();

  const { listarCertidoes } = certidoesService;

  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("certidoes");
  const isMount = useIsMount();

  const [loading, setLoading] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [listRequest, setListRequest] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Empresa", dataRef: "razao_social" },
      { columnName: "Abrangência", dataRef: "abrangencia" },
      { columnName: "Status", dataRef: "status" },
      { columnName: "Data emissão", dataRef: "data_emissao" },
      { columnName: "Tipo", dataRef: "tipo_certidao" },
      { columnName: "Órgão emissor", dataRef: "orgao_emissor" },
      { columnName: "Data vencimento", dataRef: "data_vencimento" },
    ],
    options: {
      pencil: true,
      trash: true,
      downloadFile: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    tableOptions: {
      filter: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    id_empresa: null,
  });

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const response = await listarCertidoes(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.id_empresa
    );

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    const responseData = response.data;

    if (responseData.hasOwnProperty("data")) {
      const { data } = responseData;
      const newData = data.map((item) => {
        return {
          ...item,
          id: item.id_certidao,
          data_emissao: formatDate(item.data_emissao),
          data_vencimento: formatDate(item.data_vencimento),
        };
      });
      const newResult = {
        ...responseData,
        data: newData,
      };
      setListRequest(newResult);
    } else {
      const newData = responseData.map((item) => {
        return {
          ...item,
          id: item.id_certidao,
          data_emissao: formatDate(item.data_emissao),
          data_vencimento: formatDate(item.data_vencimento),
        };
      });
      const newResult = {
        ...responseData,
        data: newData,
      };
      setListRequest(newResult);
    }
    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
    listarCertidoes,
  ]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onClickPencil = (id) => {
    history.push(`/editar-certidao/${id}`);
  };

  let handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  let handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  let onDelete = async () => {
    setLoading(true);
    const response = await certidoesService.removeCertidao(deleteId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200 || response.status === 201) {
      setCallbackMessage("Certidão excluída com sucesso!");
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackShown(true);

      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.currentPage = 1;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    } else {
      setCallbackMessage("Erro!");
      setCallbackType("error");
      setCallbackDuration(6000);
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      setCallbackShown(true);
    }
    setLoading(false);
    handleRemoveCancel();
  };

  let onClickDownloadFile = async (id) => {
    setLoading(true);
    const response = await certidoesService.consultaCertidao(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const file = response.data.arquivo;
    if (!file) {
      setCallbackMessage("Erro!");
      setCallbackType("error");
      setCallbackDuration(6000);
      setCallbackErrorList([
        "Arquivo não encontrado, ou não enviado pelo usuário.",
      ]);
      setCallbackShown(true);
    } else {
      const downloadFile = base64ToFile(file, "anexo");
      console.log("downloadFile :: ", downloadFile);
      setLoading(false);
      return downloadFile;
    }
    setLoading(false);
  };

  function handleClose(event, reason) {
    if (callbackErrorList.length === 0) updateTable();
    setCallbackShown(false);
  }

  const handleSelectEmpresa = async (id) => {
    console.log(id);
    setEmpresaSelecionada(id);
  };

  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      return setDefaultConfigTable({
        ...defaultConfigTable,
        id_empresa: empresaStorage,
      });
    }
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: empresaSelecionada,
    });
    setEmpresaPagina("certidoes", empresaSelecionada);
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
    });
    setEmpresaPagina("certidoes", null);
  };

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                      ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={handleSelectEmpresa}
            />
          </Grid>
          <Grid className="marginLeftAuto">
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("certidoes");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}

      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbPencil={onClickPencil}
        cbDelete={handleRemoveDialog}
        cbDownloadFile={onClickDownloadFile}
        filterComponent={filterComponent()}
      />
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        duration={callbackDuration}
        errorList={callbackErrorList}
        message={callbackMessage}
        handleClose={handleClose}
      />
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default Certidoes;
