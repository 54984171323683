import React, { useState, useEffect, useCallback } from "react";
import { Grid, useMediaQuery } from "@material-ui/core";

import { Search } from "../../components/TableResponsiveComponent";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import OverlayLoading from "../../components/OverlayLoading";

import empresasService from "../../services/empresasService";
import selectServices from "../../services/selectServices";

import "./styles.scss";
import CallbackMessage from "../../components/CallbackMessage";
import validateFields from "../../utils/validateFields";
import exportData from "../../services/exportData";
import { ModalEnviarEmail } from "../../components/ModalEnviarEmail";
import { dreOrcadoRealizadoService } from "../../services/dreOrcadoRealizadoService";
import { Filter } from "../../components/FilterComponent";
import { AccordionDre } from "./componentes/accordion";
import { AccordionDreMobile } from "./componentes/accordionMobile";
import useCompany from "../../hooks/useCompany";

function filterComponent({
  empresaId,
  categorias,
  meses,
  ano,
  listEmpresas,
  listCategorias,
  listMeses,
  listAnos,
  handleCategoriasSelecionadas,
  handleMesesSelecionadas,
  setAno,
  handleApplyFilters,
  handleCleanFilters,
  handleChangeEmpresa,
}) {
  return (
    <Filter.Root>
      <Filter.Select
        xs={12}
        callback={handleChangeEmpresa}
        label="Empresa"
        options={listEmpresas}
        value={empresaId}
        title="Selecione uma empresa"
      />
      <Filter.Autocomplete
        xs={12}
        callback={(values) => handleCategoriasSelecionadas(values)}
        label="Categoria"
        options={listCategorias}
        page="dre_orcado_realizado"
        values={categorias}
        placeholder={
          listCategorias?.length > 0
            ? "Selecione uma ou mais categorias"
            : "Seleção vazia"
        }
      />
      <Filter.Autocomplete
        xs={12}
        md={6}
        callback={(values) => handleMesesSelecionadas(values)}
        label="Mês"
        options={listMeses}
        page="dre_orcado_realizado"
        values={meses}
        placeholder={
          listMeses?.length > 0
            ? "Selecione uma ou mais meses"
            : "Seleção vazia"
        }
      />
      <Filter.Select
        xs={12}
        md={6}
        callback={(id) => {
          setAno(id);
        }}
        label="Ano"
        title="Selecione o ano"
        options={listAnos}
        value={ano}
      />
      <Filter.Buttons>
        <Filter.Button
          text="Filtrar"
          onClick={handleApplyFilters}
          className="btn-primary w-full fluxo-caixa"
        />

        <Filter.Button
          text="Limpar Filtros"
          onClick={handleCleanFilters}
          className="default-outline clean-filters-button"
        />
      </Filter.Buttons>
    </Filter.Root>
  );
}

function HeaderTable({ tableOptions, onTableMoreAction, ...rest }) {
  return (
    <Search
      callback={() => {}}
      tableOptions={tableOptions}
      filterComponent={filterComponent({ ...rest })}
      callbackMenu={onTableMoreAction}
    />
  );
}

export function DREOrcadoRealizado() {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const {
    companyList: listEmpresas,
    selectedCompany: empresaId,
    setSelectedCompany: setEmpresaId,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("dre_orcado_realizado");

  const { getDre, enviarEmailRelatorio } = dreOrcadoRealizadoService;

  const [loading, setLoading] = useState(false);
  const [listMeses, setListMeses] = useState([]);
  const [mesesDisponiveis, setMesesDisponiveis] = useState([]);
  const [listCategorias, setListCategorias] = useState([]);
  const [ano, setAno] = useState(null);
  const [meses, setMeses] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [listRequest, setListRequest] = useState();
  const [listAnos, setListAnos] = useState([]);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);

  const [openModalEmail, setOpenModalEmail] = useState(false);

  const [filterOptions, setFilterOptions] = useState({
    id_empresa: null,
    ano: null,
    categorias: null,
    meses: null,
  });

  const tableOptions = {
    noSearch: true,
    filter: true,
    more: true,
    moreOptions: [
      {
        icon: "export",
        label: "Exportar XLS",
        action: "exportar-xls",
      },
      {
        icon: "export",
        label: "Exportar PDF",
        action: "exportar-pdf",
      },
      {
        icon: "email",
        label: "Enviar e-mail",
        action: "enviar-e-mail",
      },
    ],
  };

  const exportToPDF = {
    url: "exportar-relatorio-dre-orcado-realizado-pdf",
    fileName: "cbhub_relatorio_dre_orcado_x_realizado",
    fileType: "pdf",
  };

  const exportToXLS = {
    url: "exportar-relatorio-dre-orcado-realizado-xls",
    fileName: "cbhub_relatorio_dre_orcado_x_realizado",
    fileType: "xlsx",
  };

  async function loadMeses() {
    const response = await selectServices.selecionaMeses();
    if (response.status === 200) {
      const data = Object.keys(response.data).map((key) => ({
        id: key,
        nome: response.data[key],
      }));
      setListMeses(data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  }

  async function loadAnos() {
    const response = await selectServices.selecionaAnoOrcamento();

    if (response.status === 200) {
      setListAnos(response.data);
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  }

  async function loadCategorias(id_empresa) {
    try {
      const response = await selectServices.selecionaCategoria(id_empresa);
      if (response.status === 200) {
        setListCategorias(response.data);
      }
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
      }
    } catch (error) {
      setListCategorias([]);
    }
  }

  const updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);

    const response = await getDre({
      ...filterOptions,
    });
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    // setListRequest(mockResponse)
    if (response.status === 200) {
      setMesesDisponiveis(
        filterOptions.meses
          ? listMeses.filter(
              (item) => filterOptions.meses?.indexOf(item.id) >= 0
            )
          : listMeses
      );
      const values = response.data.map((item) => {
        return {
          ...item,
          saldo: item.total,
          categorias: item.categorias
            ? item.categorias?.map((itemCat) => ({
                ...itemCat,
                saldo: itemCat.total,
                subcategorias: itemCat.subcategorias
                  ? itemCat.subcategorias?.map((itemSubCat) => ({
                      ...itemSubCat,
                      saldo: itemSubCat.total,
                    }))
                  : [],
              }))
            : [],
        };
      });
      setListRequest(values);
    } else {
    }
    setLoading(false);
  }, [
    getDre,
    filterOptions,
    setListRequest,
    setLoading,
    setOpenLoginExpirado,
    listMeses,
  ]);

  let onTableMoreAction = async ({ action }) => {
    let fields = [
      {
        label: "empresa",
        value: filterOptions.id_empresa,
      },
      {
        label: "ano",
        value: filterOptions.ano,
      },
    ];

    const validateFieldsErrors = validateFields(fields);

    if (validateFieldsErrors.length !== 0) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackDuration(3000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(validateFieldsErrors);
      setLoading(false);
      return;
    }

    let params = {};
    if (action === "enviar-e-mail") {
      setOpenModalEmail(true);
      return;
    } else if (action === "exportar-xls") {
      params = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: { ...filterOptions, all: filterOptions.all ? 1 : null },
      };
    } else if (action === "exportar-pdf") {
      params = {
        url: exportToPDF.url,
        fileName: exportToPDF.fileName,
        fileType: exportToPDF.fileType,
        params: { ...filterOptions, all: filterOptions.all ? 1 : null },
      };
    }

    setLoading(true);
    const response = await exportData(params);

    if (response.status === 500) {
      setCallbackErrorList([
        "Erro interno no servidor. Por favor, contate o suporte",
      ]);
      setCallbackShown(true);
      setCallbackType("error");
    } else if (response.status !== 201 && response.status !== 200) {
      setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
      setCallbackShown(true);
      setCallbackType("error");
    }

    setLoading(false);
  };

  function handleClose() {
    setCallbackShown(false);
    setCallbackType("");
  }

  async function handleSendEmail(data = false) {
    if (!data) {
      setOpenModalEmail(false);
      return;
    }
    setLoading(true);
    const cco = data.cco?.split(";")?.map((item) => {
      return item?.trim();
    });
    const emails = data.para?.split(";")?.map((item) => {
      return item?.trim();
    });
    const response = await enviarEmailRelatorio({
      ...filterOptions,
      assunto: data.assunto,
      cco,
      emails,
      mensagem: data.mensagem,
    });
    if (response?.status === 200) {
      setCallbackErrorList([]);
      setCallbackMessage([
        response?.data?.msg ? response?.data?.msg : "Relátorio enviado",
      ]);
      setCallbackShown(true);
      setCallbackType("success");
      setOpenModalEmail(false);
    } else if (response.status === 400) {
      setCallbackErrorList(Object.values(response?.data?.error).flat(Infinity));
      setCallbackDuration(3000);
      setCallbackMessage("Erro");
      setCallbackShown(true);
    } else {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Ocorreu um erro"]);
    }

    setLoading(false);
  }

  function EmptyDre() {
    return (
      <div className="empty-dre">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item className="font-empty-title">
            Não há dados para carregar
          </Grid>
          <Grid item className="font-empty-subtitle">
            Selecione uma empresa
          </Grid>
        </Grid>
      </div>
    );
  }

  const handleCleanFilters = async () => {
    setEmpresaId(null);
    setAno(null);
    setListRequest(null);
    setMeses([]);
    setCategorias([]);
    setListCategorias([]);
    setEmpresaPagina("dre_orcado_realizado", null);
  };

  const handleApplyFilters = () => {
    let fields = [
      {
        label: "empresa",
        value: empresaId,
      },
      {
        label: "ano",
        value: ano,
      },
    ];
    setEmpresaPagina("dre_orcado_realizado", empresaId);

    const validateFieldsErrors = validateFields(fields);

    if (validateFieldsErrors.length !== 0) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackDuration(3000);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(validateFieldsErrors);
      setLoading(false);
      return;
    }
    setFilterOptions({
      id_empresa: empresaId,
      ano: ano,
      categorias: categorias?.length > 0 ? categorias : null,
      meses: meses?.length > 0 ? meses : null,
    });
  };

  function handleCategoriasSelecionadas(values) {
    const idsCategorias = values.map((item) => {
      return item.id;
    });
    setCategorias(idsCategorias);
  }

  function handleMesesSelecionadas(values) {
    const idsMeses = values.map((item) => {
      return item.id;
    });
    setMeses(idsMeses);
  }

  function handleChangeEmpresa(id) {
    setEmpresaId(id);
  }

  useEffect(() => {
    (async function () {
      setLoading(true);
      loadMeses();
      loadAnos();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (empresaId && ano) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("dre_orcado_realizado");
    if (empresaStorage) {
      handleChangeEmpresa(empresaStorage);
    }
  }, []);

  useEffect(() => {
    if (empresaId) {
      loadCategorias(empresaId);
    }
  }, [empresaId]);

  return (
    <>
      {loading && <OverlayLoading />}
      <div className="table-no-header relatorio-dre-orcado-realizado">
        <HeaderTable
          empresaId={empresaId}
          categorias={categorias}
          meses={meses}
          ano={ano}
          listEmpresas={listEmpresas}
          listCategorias={listCategorias}
          listMeses={listMeses}
          listAnos={listAnos}
          handleCategoriasSelecionadas={handleCategoriasSelecionadas}
          handleMesesSelecionadas={handleMesesSelecionadas}
          setAno={setAno}
          handleApplyFilters={handleApplyFilters}
          handleCleanFilters={handleCleanFilters}
          tableOptions={tableOptions}
          onTableMoreAction={onTableMoreAction}
          handleChangeEmpresa={handleChangeEmpresa}
        />
        {listRequest ? (
          <div className="session-container">
            <span className="session-container-header">
              <Grid
                container
                direction="column"
                className="container-dre-orcado-realizado"
              >
                {!isMobile && (
                  <>
                    <Grid item className="container-legenda-tabela">
                      <div>
                        <span className="orcado-legenda"></span>
                        <span>Orçado</span>
                      </div>
                      <div>
                        <span className="realizado-legenda"></span>
                        <span>Realizado</span>
                      </div>
                    </Grid>
                    <Grid item className="container-row-dre-orcado-realizado">
                      <Grid
                        item
                        className="name-categoria header title-categoria"
                      >
                        Categoria
                      </Grid>
                      <Grid
                        item
                        className="name-tipo-categoria name-tipo-categoria-header"
                      >
                        Tipo de categoria
                      </Grid>
                      {mesesDisponiveis.map((item, index) => (
                        <Grid item key={index} className="name-column">
                          {item?.nome?.substring(0, 3) || ""}
                        </Grid>
                      ))}
                      <Grid
                        item
                        className="name-column total-column total-column-header"
                      >
                        Total
                      </Grid>
                    </Grid>
                    <div style={{ height: 40 }} />
                  </>
                )}
                <Grid item xs sm md>
                  {isMobile ? (
                    <AccordionDreMobile
                      columns={listMeses}
                      dados={listRequest}
                    />
                  ) : (
                    <AccordionDre
                      columns={mesesDisponiveis}
                      dados={listRequest}
                    />
                  )}
                </Grid>
              </Grid>
            </span>
          </div>
        ) : (
          <EmptyDre />
        )}
        <CallbackMessage
          type={callbackType}
          open={callbackShown}
          errorList={callbackErrorList}
          duration={callbackDuration}
          message={callbackMessage}
          handleClose={() => handleClose()}
        />
        <ModalEnviarEmail
          open={openModalEmail}
          onClose={handleSendEmail}
          setCallbackErrorList={setCallbackErrorList}
          callbackErrorList={callbackErrorList}
          callbackShown={callbackShown}
          setCallbackShown={setCallbackShown}
        />
        <ModalLoginExpirado open={openLoginExpirado} />
      </div>
    </>
  );
}
