import React, { useContext } from "react";
import Navbar from "../Navbar";
import UserNavbar from "../UserNavbar";
import Logo from "../../assets/img/logo001.svg";
import "./styles.scss";
import { NavbarContext } from "../../contexts/NavbarContext";
import SmallScreen from "../../utils/smallScreen";
import Close from "@material-ui/icons/Close";
import { Link } from "react-router-dom";

function Sidebar({ arrayMenu }) {
  const { showNavbar, setShowNavbar } = useContext(NavbarContext);
  const isSmallScreen = SmallScreen();

  const userStatus = window.localStorage.getItem("status_uso");

  const handleClickLogo = () => {
    if (isSmallScreen) setShowNavbar(false);
  };

  return (
    showNavbar && (
      <aside className="header-sidebar">
        {isSmallScreen && (
          <div className="header-logo-mobile">
            <Link
              to={userStatus === "0" ? "/contrato" : "/home"}
              onClick={handleClickLogo}
            >
              <img src={Logo} alt="logo" />
            </Link>
            <div
              className="header-close-wrapper"
              onClick={() => setShowNavbar(false)}
            >
              <Close className="header-close-icon" />
            </div>
          </div>
        )}
        <UserNavbar
          setShowNavbar={setShowNavbar}
          isSmallScreen={isSmallScreen}
        />
        <Navbar arrayMenu={arrayMenu} vertical={true} />
      </aside>
    )
  );
}

export default Sidebar;
