import Axios from "./Axios";

const categoriasCadastroService = {
  addGrupo: async (idEmpresa, nome) => {
    const add = await Axios.post("/configuracao-cadastra-grupo", {
      id_empresa: idEmpresa,
      nome,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return add;
  },
  consultaGrupo: async (id) => {
    const response = await Axios.get(`/configuracao-consulta-grupo/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  atualizaGrupo: async (idGrupo, idEmpresa, nome) => {
    const response = await Axios.post("/configuracao-atualiza-grupo", {
      id_grupo: idGrupo,
      id_empresa: idEmpresa,
      nome,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
};

export default categoriasCadastroService;
