import React, { useState, useCallback, useEffect } from "react";
import Input from "../Input";
import InputFile from "../InputFile";
import ButtonComponent from "../ButtonComponent";
import TableComponent from "../TableComponent";
import { Grid } from "@material-ui/core";
import "./styles.css";

function Anexos({
  listFiles = [],
  cbDelete = () => {},
  cbDownload = () => {},
  cbAnexar = () => {},
  cbSubmit = () => {},
}) {
  const [list, setList] = useState(listFiles);
  const [file, setFile] = useState(null);
  const [identificacao, setIdentificacao] = useState("");
  const defaultConfigTable = {
    columnArray: [
      { columnName: "ID", dataRef: "id" },
      { columnName: "Identificação", dataRef: "identificacao" },
    ],
    options: {
      download: true,
      delete: true,
    },
    display: {
      search: false,
      itemsPerPage: false,
      totalResults: false,
      pagination: false,
    },
    currentPage: 1,
    pagination: false,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  };

  function handleSubmit(event) {
    event.preventDefault();
  }

  function handleFileChange(event) {
    setFile(event);
  }

  function onDelete(id) {
    let l = [...list];
    let index = l.findIndex((item) => item.id === id);
    l.splice(index, 1);
    setList(l);
    cbDelete(index);
  }
  function onDownload(id) {
    let l = [...list];
    let index = l.findIndex((item) => item.id === id);
    cbDownload(l[index].file);
  }

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    console.log(newOrderBy);
    let l = [...list];
    if (orderBy === "id") l.sort((a, b) => a - b);
    else if (orderBy === "identificacao") l.sort();

    if (orderByType === "desc") l.reverse();

    setList(l);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="anexos-container">
        <Grid container direction="column">
          <Grid item container alignItems="flex-end" spacing={2}>
            <Grid item xs={2}>
              <Input
                handleInputChange={(event) =>
                  setIdentificacao(event.target.value)
                }
                label="Identificação"
                value={identificacao}
              />
            </Grid>
            <Grid item xs={8}>
              <span className="label">Arquivo</span>
              <InputFile onChange={handleFileChange} />
            </Grid>
            <Grid item xs={2}>
              <ButtonComponent
                className="btn-info"
                text="Anexar"
                onClick={() => {
                  let l = [...list];
                  l.push({
                    id: `${l.length + 1}`,
                    identificacao: identificacao,
                    file: file,
                  });
                  console.log(list);
                  setList(l);
                }}
              />
            </Grid>
          </Grid>
          <div style={{ height: 50 }} />
          <Grid item>
            <TableComponent
              idName="idAnexos"
              dataList={list}
              enableOrder={true}
              cbDelete={onDelete}
              cbDownload={onDownload}
              callbackOrderBy={onOrderBy}
              tableConfig={defaultConfigTable}
            />
          </Grid>
        </Grid>
        <div style={{ height: 30 }} />
        <Grid item container justify="flex-end">
          <ButtonComponent
            text="Continuar"
            className="btn-success zero-margin"
            onClick={() => {
              cbSubmit(list);
            }}
          />
        </Grid>
      </form>
    </>
  );
}

export default Anexos;
