import Axios from "./Axios";

const notasFiscaisService = {
  getNotasFiscais: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null,
    data_inicial_emissao = null,
    data_final_emissao = null,
    numero_inicial = null,
    numero_final = null,
    status = null,
    serie_inicial = null,
    serie_final = null,
    rps = null,
    cod_verificacao = null
  ) => {
    const result = await Axios.get("/lista-nota", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
        data_inicial_emissao,
        data_final_emissao,
        numero_inicial,
        numero_final,
        status,
        serie_inicial,
        serie_final,
        rps,
        cod_verificacao,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  cadastrarNota: async (
    id_empresa,
    id_cliente,
    valor_total_nota,
    id_uf,
    id_cidade,
    id_servico_empresa,
    iss_retido,
    discriminacao_servico,
    valor_iss_retido,
    valor_cofins,
    valor_csll,
    valor_pis,
    valor_irpj,
    valor_inss,
    valor_outros,
    valor_total_deducoes,
    descontos_incondicionados,
    outras_informacoes,
    id_categoria
  ) => {
    console.log({
      id_empresa,
      id_cliente,
      valor_total_nota,
      id_uf,
      id_cidade,
      id_servico_empresa,
      iss_retido,
      discriminacao_servico,
      valor_iss_retido,
      valor_cofins,
      valor_csll,
      valor_pis,
      valor_irpj,
      valor_inss,
      valor_outros,
      valor_total_deducoes,
      descontos_incondicionados,
      outras_informacoes,
    });
    const response = await Axios.post("/cadastrar-nota", {
      id_empresa,
      id_cliente,
      valor_total_nota,
      id_uf,
      id_cidade,
      id_servico_empresa,
      iss_retido,
      discriminacao_servico,
      valor_iss_retido,
      valor_cofins,
      valor_csll,
      valor_pis,
      valor_irpj,
      valor_inss,
      valor_outros,
      valor_total_deducoes,
      descontos_incondicionados,
      outras_informacoes,
      id_categoria,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  calcularImposto: async (
    id_empresa,
    valor_total_nota,
    id_servico_empresa,
    iss_retido,
    valor_outros,
    pjpf
  ) => {
    const response = await Axios.post("/impostos-nota-fiscal", {
      id_empresa,
      valor_total_nota,
      id_servico_empresa,
      iss_retido,
      valor_outros,
      pjpf,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  consultaPDFInvoisys: async (id) => {
    const response = await Axios.post(`/consulta-pdf-nota-invoisys/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  consultaXMLInvoisys: async (id) => {
    const response = await Axios.post(`/consulta-xml-nota-invoisys/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cancelarNotaInvoisys: async (id) => {
    const response = await Axios.post("/cancelar-nota-invoisys", { id })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  controleNota: async (idEmpresa) => {
    const response = await Axios.get(`/controle-nota-emitida/${idEmpresa}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  verificaModuloNf: async () => {
    const response = await Axios.get("/verificar-modulo-nfe-empresa")
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  listaNotaProduto: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null
  ) => {
    const response = await Axios.get("/lista-nota-produto", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  listaNotaEntradaItens: async (
    id_nota_fiscal = null,
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null
  ) => {
    const response = await Axios.get(
      `/lista-nota-entrada-itens/${id_nota_fiscal}`,
      {
        params: {
          page: page,
          order_by: orderBy,
          order_by_type: orderByType,
          per_page: perPage,
          search_term: searchTerm,
        },
      }
    )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  listaNotasFiscais: async (
    id_empresa = null,
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    emissao_de = null,
    emissao_ate = null,
    chave_acesso = null,
    numero_inicial = null,
    numero_final = null,
    status = null,
    serie_inicial = null,
    serie_final = null
  ) => {
    const response = await Axios.get("/lista-nfe", {
      params: {
        id_empresa: id_empresa,
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        emissao_de,
        emissao_ate,
        chave_acesso,
        numero_inicial,
        numero_final,
        status,
        serie_inicial,
        serie_final,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  listaNotaEntrada: async (
    id_empresa = null,
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    emissao_de = null,
    emissao_ate = null,
    emitente = null,
    chave_acesso = null,
    numero_inicial = null,
    numero_final = null,
    status = null,
    serie_inicial = null,
    serie_final = null,
    uf = null
  ) => {
    const response = await Axios.get("/lista-nota-entrada", {
      params: {
        id_empresa: id_empresa,
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        emissao_de,
        emissao_ate,
        emitente,
        chave_acesso,
        numero_inicial,
        numero_final,
        status,
        serie_inicial,
        serie_final,
        uf,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  cadastraNotaProduto: async (
    id_empresa,
    natureza_da_operacao,
    tipo_da_operacao,
    destino_da_operacao,
    tipo_impressao_danfe,
    finalidade_emissao,
    indicador_venda_presencial,
    modalidade_frete,
    valor_troco,
    valor_total,
    produtos,
    pagamentos
  ) => {
    const response = await Axios.post("/cadastra-nota-produto", {
      id_empresa,
      natureza_da_operacao,
      tipo_da_operacao,
      destino_da_operacao,
      tipo_impressao_danfe,
      finalidade_emissao,
      indicador_consumidor_final: indicador_venda_presencial,
      indicador_venda_presencial,
      modalidade_frete,
      valor_troco,
      valor_total,
      produtos,
      pagamentos,
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  cadastraNota: async (
    id_empresa,
    id_cliente,
    natureza_da_operacao,
    tipo_da_operacao,
    destino_da_operacao,
    tipo_impressao_danfe,
    finalidade_emissao,
    indicador_venda_presencial,
    modalidade_frete,
    valor_troco,
    valor_total,
    valor_desconto,
    produtos,
    pagamentos,
    outras_informacoes,
    id_transportadora,
    placa_veiculo,
    uf_veiculo,
    registro_nacional_transporte_carga_veiculo,
    placa_reboque,
    uf_reboque,
    registro_nacional_transporte_carga_reboque,
    identificacao_vagao_reboque,
    identificacao_balsa_reboque,
    quantidade_vol,
    esp_vol,
    marca_vol,
    numeracao_vol,
    peso_liquido_vol,
    peso_bruto_vol,
    numeracao_lacre_vol,
    valor_servico_transporte,
    bc_retencao_icms_transporte,
    aliquota_retencao_transporte,
    valor_icms_retido_transporte,
    cfop_transporte,
    cod_mun_fato_gerador_icms_transporte,
    adicionar_reboque,
    id_categoria,
    data_vencimento,
    parcelas
  ) => {
    const response = await Axios.post("/cadastra-nfe", {
      id_empresa,
      id_cliente,
      natureza_da_operacao,
      tipo_da_operacao,
      destino_da_operacao,
      tipo_impressao_danfe,
      finalidade_emissao,
      indicador_consumidor_final: indicador_venda_presencial,
      indicador_venda_presencial,
      modalidade_frete,
      valor_troco,
      valor_total,
      valor_desconto,
      produtos,
      pagamentos,
      outras_informacoes,
      id_transportadora,
      placa_veiculo,
      uf_veiculo,
      registro_nacional_transporte_carga_veiculo,
      placa_reboque,
      uf_reboque,
      registro_nacional_transporte_carga_reboque,
      identificacao_vagao_reboque,
      identificacao_balsa_reboque,
      quantidade_vol,
      esp_vol,
      marca_vol,
      numeracao_vol,
      peso_liquido_vol,
      peso_bruto_vol,
      numeracao_lacre_vol,
      valor_servico_transporte,
      bc_retencao_icms_transporte,
      aliquota_retencao_transporte,
      valor_icms_retido_transporte,
      cfop_transporte,
      cod_mun_fato_gerador_icms_transporte,
      adicionar_reboque,
      id_categoria,
      data_vencimento,
      parcelas,
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  exportarPdfNfce: async (id) => {
    const response = await Axios.get(`/consulta-pdf-nfce/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  exportarXmlNfce: async (id) => {
    const response = await Axios.get(`/consulta-xml-nfce/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  cancelarNotaProduto: async (id, justificativa) => {
    const response = await Axios.post(`/cancela-nota-produto/${id}`, {
      justificativa,
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  downloadXMLNotaEntrada: async (id) => {
    const response = await Axios.get(`/baixar-xml-nota-entrada/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);
    return response;
  },
  downloadPDFNotaEntrada: async (id) => {
    const response = await Axios.get(`/baixar-pdf-nota-entrada/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);
    return response;
  },
  downloadXMLNfe: async (id) => {
    const response = await Axios.get(`/consulta-xml-nfe/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  downloadPDFNfe: async (id) => {
    const response = await Axios.get(`/consulta-pdf-nfe/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  selecionaStatusNotaEntrada: async () => {
    const response = await Axios.get(`/seleciona-status-nota-entrada`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  selecionaStatusNotaServico: async () => {
    const response = await Axios.get(`/seleciona-status-nota-servico`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  consultaNotaEntrada: async (id) => {
    const response = await Axios.get(`/consulta-nota-entrada/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  consultaNfe: async (id) => {
    const response = await Axios.get(`/consulta-nfe/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  cienciaNotaEntrada: async (id) => {
    const response = await Axios.get(`/manifestar-ciencia-nota-entrada/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  confirmacaoNotaEntrada: async (id) => {
    const response = await Axios.get(
      `/manifestar-confirmacao-nota-entrada/${id}`
    )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  desconhecimentoNotaEntrada: async (id) => {
    const response = await Axios.get(
      `/manifestar-desconhecimento-nota-entrada/${id}`
    )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  naoRealizacaoNotaEntrada: async (id) => {
    const response = await Axios.get(
      `/manifestar-nao-realizacao-nota-entrada/${id}`
    )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  cancelaNotaFiscal: async (id, justificativa) => {
    const response = await Axios.post(`/cancela-nfe/${id}`, {
      justificativa: justificativa,
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  importarProdutoNotaEntrada: async (
    id_produto,
    id_nota_fiscal_produto_entrada_item,
    quantidade,
    valor_de_custo
  ) => {
    const response = await Axios.post(`/importar-produto-nota-entrada`, {
      id_produto,
      id_nota_fiscal_produto_entrada_item,
      quantidade,
      valor_de_custo,
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  desabilitaItemNotaEntrada: async (id) => {
    const response = await Axios.get(`/desabilita-item-nota-entrada/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  consultaNfeSefaz: async (id) => {
    const response = await Axios.get(`/consulta-nfe-sefaz/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);
    console.log(response);
    return response;
  },
  consultaNfseSefaz: async (id) => {
    const response = await Axios.get(`/consulta-nfse-sefaz/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);
    console.log(response);
    return response;
  },
  consultaNfceSefaz: async (id) => {
    const response = await Axios.get(`/consulta-nfce-sefaz/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);
    console.log(response);
    return response;
  },
  dadosNfseSefaz: async (id) => {
    const response = await Axios.get(`/dados-nfse-sefaz/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);
    console.log(response);
    return response;
  },
  dadosNfe: async (id) => {
    const response = await Axios.get(`/dados-nfe/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);
    console.log(response);
    return response;
  },
  dadosNfceSefaz: async (id) => {
    const response = await Axios.get(`/dados-nfce-sefaz/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);
    console.log(response);
    return response;
  },
  exportarRelatorio: async (
    id_empresa = null,
    data_inicial_emissao = null,
    data_final_emissao = null,
    numero_inicial = null,
    numero_final = null,
    status = null,
    serie_inicial = null,
    serie_final = null,
    lote = null,
    cod_verificacao = null
  ) => {
    const response = await Axios.get(`/lista-nota-exportar`, {
      params: {
        id_empresa,
        data_inicial_emissao,
        data_final_emissao,
        numero_inicial,
        numero_final,
        status,
        serie_inicial,
        serie_final,
        lote,
        cod_verificacao,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    return response;
  },
  listaRelatorioNotaEntrada: async (params) => {
    const response = await Axios.get("/lista-relatorio-nota-entrada", {
      params: params,
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  enviarEmailRelatorioNotaEntrada: async ({
    assunto = null,
    mensagem = null,
    emails = [],
    cco = [],
    id_empresa = null,
    periodo_emissao_de = null,
    periodo_emissao_ate = null,
    tipo = null,
    id_fornecedor = null,
    incluir_detalhamento = null,
  }) => {
    const response = await Axios.get("/exportar-relatorio-nota-entrada-email", {
      params: {
        assunto,
        mensagem,
        emails,
        cco,
        id_empresa,
        periodo_emissao_de,
        periodo_emissao_ate,
        tipo,
        id_fornecedor,
        incluir_detalhamento,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  listaRelatorioNotaSaida: async (params) => {
    const response = await Axios.get("/lista-relatorio-nfe", {
      params: params,
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  listaRelatorioNotaEntrada: async (params) => {
    const response = await Axios.get("/lista-relatorio-nota-entrada", {
      params: params,
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  enviarEmailRelatorioNotaEntrada: async ({
    assunto = null,
    mensagem = null,
    emails = [],
    cco = [],
    id_empresa = null,
    periodo_emissao_de = null,
    periodo_emissao_ate = null,
    tipo = null,
    id_fornecedor = null,
    incluir_detalhamento = null,
  }) => {
    const response = await Axios.get("/exportar-relatorio-nota-entrada-email", {
      params: {
        assunto,
        mensagem,
        emails,
        cco,
        id_empresa,
        periodo_emissao_de,
        periodo_emissao_ate,
        tipo,
        id_fornecedor,
        incluir_detalhamento,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  enviarEmailMovimentacaoFinanceira: async (params) => {
    const response = await Axios.get("/exportar-relatorio-movimentacao-financeira-email", {params})
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  listaRelatorioNotaSaida: async (params) => {
    const response = await Axios.get("/lista-relatorio-nfe", {
      params: params,
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  enviarEmailRelatorioNotaSaida: async ({
    assunto = null,
    mensagem = null,
    emails = [],
    cco = [],
    id_empresa = null,
    periodo_emissao_de = null,
    periodo_emissao_ate = null,
    tipo = null,
    id_cliente = null,
    incluir_detalhamento = null,
  }) => {
    const response = await Axios.get("/exportar-relatorio-nfe-email", {
      params: {
        assunto,
        mensagem,
        emails,
        cco,
        id_empresa,
        periodo_emissao_de,
        periodo_emissao_ate,
        tipo,
        id_cliente,
        incluir_detalhamento,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  listaMovimentacaoFinanceira: async (params) => {
    const response = await Axios.get("/lista-movimentacao-financeira", {
      params: params,
    })
        .then(({ ...response }) => response)
        .catch(({ ...response }) => response);

    return response;
  }
};

export default notasFiscaisService;
