export default function parseJSON(json) {
  let parsed = null

  try {
    parsed = JSON.parse(json)
  } catch(e) {
    return null
  }

  return parsed
}