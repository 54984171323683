import Axios from "./Axios";

const gruposCategoriaService = {
  listGrupos: async ({
    idEmpresa,
    orderBy = null,
    orderByType = null,
    page = null,
    perPage = null,
  }) => {
    const response = await Axios.get(`/lista-grupo`, {
      params: {
        id_empresa: idEmpresa,
        order_by: orderBy,
        order_by_type: orderByType,
        page,
        per_page: perPage,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  cadastrarGrupo: async ({ idEmpresa, nome, codigo, status }) => {
    const response = await Axios.post(`/cadastra-grupo-empresa`, {
      id_empresa: idEmpresa,
      nome,
      codigo,
      status,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  consultaGrupo: async (id) => {
    const response = await Axios.get(`/consulta-grupo-empresa/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  atualizarGrupo: async ({ id, idEmpresa, nome, codigo, status }) => {
    const response = await Axios.put(`/atualiza-grupo-empresa/${id}`, {
      id_empresa: idEmpresa,
      nome,
      codigo,
      status,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  atualizarStatusGrupo: async (id) => {
    const response = await Axios.put(`/atualiza-status-grupo-empresa/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  importarGrupo: async ({ arquivo, id_empresa }) => {
    const response = await Axios.post(`/importar-grupos`, {
      arquivo,
      id_empresa,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  consultaSugestaoCodigoGrupo: async (id) => {
    const response = await Axios.get(`/seleciona-sugestao-codigo-grupo/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
};

export default gruposCategoriaService;
