import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from "react-input-mask";
import "moment/locale/pt-br";
import "./styles.css";
import { ClickAwayListener } from "@material-ui/core";
import ptBR from "date-fns/locale/pt-BR";
import { format } from "date-fns";
import { toDateYYYY_MM_DD, formatDate, unformatDate } from "../../utils/dates";
import Icon from "../../components/Icon";

registerLocale("pt-BR", ptBR);

function DateTimePicker({
  label = "",
  handleChange = () => {},
  initDate = null, // yyyy-mm-dd
  noMask = false,
  readOnly,
}) {
  const [startDate, setStartDate] = useState("");
  const [open, setOpen] = useState(false);
  const [inputChange, setInputChange] = useState(false);
  const today = new Date();
  const [defaultValue, setDefaultValue] = useState("");
  const [zIndex, setZIndex] = useState(0);
  useEffect(() => {
    const today = new Date();

    if (initDate === '') {
      setStartDate('')
      setDefaultValue('');
      return;
    }
  }, [initDate]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setZIndex(0);
      }}
    >
      <>
        <div className="label">{label}</div>
        <div className="calendar-container" style={{ zIndex: zIndex }}>
          {/* <DatePicker
            placeholderText="dd/mm/aaaa"
            disabled={readOnly}
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              setDefaultValue(format(date, "dd/MM/yyyy"));
              if (inputChange) setInputChange(false);
              handleChange(format(date, "yyyy-MM-dd"));
            }}
            onCalendarOpen={() => setZIndex(99999999)}
            onClickOutside={() => {
              if (zIndex === 99999999) setZIndex(0);
            }}
            onCalendarClose={() => {
              if (zIndex === 99999999) setZIndex(0);
            }}
            customInput={
              <InputMask
                readOnly={readOnly}
                value={defaultValue}
                type="text"
                mask="99/99/9999"
                className="input-field"
                onChange={(event) => handleChange(event.target.valueAsDate)}
                onKeyDown={() => {
                  if (!inputChange) setInputChange(true);
                }}
              />
            }
            dateFormat="dd/MM/yyyy"
            locale="pt-BR"
          /> */}
          <DatePicker
            className="date-time-picker"
            placeholderText="dd/mm/aaaa - hh:mm"
            disabled={readOnly}
            selected={startDate}
            onChange={(date) => {
              console.log(date);
              setStartDate(date)
              if (inputChange) setInputChange(false);
              handleChange(format(date, "yyyy-MM-dd HH:mm"));
            }}
            onCalendarOpen={() => setZIndex(99999999)}
            onClickOutside={() => {
              if (zIndex === 99999999) setZIndex(0);
            }}
            onCalendarClose={() => {
              if (zIndex === 99999999) setZIndex(0);
            }}
            showTimeSelect
            timeFormat="HH:mm"
            dateFormat="dd/MM/yyyy - HH:mm:SS"
            locale="pt-BR"
            timeCaption="Horário"
            timeIntervals={15}
            customInput={
              <InputMask
                readOnly={readOnly}
                // value={defaultValue}
                type="text"
                mask="99/99/9999 - 99:99"
                className="input-field"
                onChange={(event) => {
                  console.log(event);
                }}
                onKeyDown={() => {
                  if (!inputChange) setInputChange(true);
                }}
              />
            }
          />
          <Icon name="calendar-outline" color="#EF4066" size={20} />
        </div>
      </>
    </ClickAwayListener>
  );
}

export default DateTimePicker;
