import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { numberToCurrency } from "../../utils/functions";
import Icon from "../../components/Icon";
import OverlayLoading from "../../components/OverlayLoading";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";

import imovelServices from "../../services/imoveisService";
import "./styles.scss";

function DetalhesImovel() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [codigoImovel, setCodigoImovel] = useState(null);
  const [tituloSite, setTituloSite] = useState(null)
  const [cep, setCep] = useState(null);
  const [rua, setRua] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [bairro, setBairro] = useState(null);
  const [numero, setNumero] = useState(null);
  const [complemento, setComplemento] = useState(null);
  const [descricao, setDescricao] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [dormitorios, setDormitorios] = useState(null);
  const [vagas, setVagas] = useState(null);
  const [banheiros, setBanheiros] = useState(null);
  const [suites, setSuites] = useState(null);
  const [uf, setUf] = useState(null);
  const [condominio, setCondominio] = useState(null);
  const [iptu, setIptu] = useState(null);
  const [valorLocacao, setValorLocacao] = useState(null);
  const [valorVenda, setValorVenda] = useState(null);
  const [fotos, setFotos] = useState([]);
  const [caracteristicasAdicionais, setCaracteristicasAdicionais] = useState([]);

  const loadImovel = useCallback(async () => {  
    const response = await imovelServices.consultaDetalhesImovel(id);
    if(response === 401) {  
      setLoading(false);
      setOpenLoginExpirado(true);
      return;
    } else if (response.status === 200) {  
      const { data } = response;
      setBairro(data.bairro);
      setCidade(data.cidade);
      setDormitorios(data.dormitorios);
      setVagas(data.vagas);
      setRua(data.endereco);
      setSuites(data.suites);
      setUf(data.uf);
      setCondominio(data.valor_condominio);
      setIptu(data.valor_iptu);
      setValorLocacao(data.valor_locacao);
      setValorVenda(data.valor_venda);
      setFotos([data.foto_destaque_pequena]);
      setCodigoImovel(data.referencia);
      setBanheiros(data.banheiro);
      setNumero(data.numero);
      setComplemento(data.complemento);
      setLatitude(data.latitude);
      setLongitude(data.longitude);
      setDescricao(data.descricao);
      setCep(data.cep);
      setLongitude(data.longitude);
      setLatitude(data.latitude);
      setTituloSite(data.titulo_site)
      setCaracteristicasAdicionais(data.caracteristicas);
    }
  }, []);

  useEffect(() => {  
    (async () => {  
      setLoading(true);
      await loadImovel();
      setLoading(false);
    })();
  }, [loadImovel])


  return (
    <>
      <div className="session-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <form className="detalhes-imovel-form-container">
            <div className="detalhes-imovel-form-title">
              Código do imóvel: {codigoImovel}
            </div>
            {tituloSite && (
              <div className="detalhes-imovel-form-subtitle">
                {tituloSite}
              </div>
            )}
            <Grid item container justify="space-between" spacing={10}>
              <Grid item xs={12} sm={12} md={8}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="detalhes-imovel-title-divider">
                      Localização
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className="detalhes-imovel-text"
                  >
                    {`${cep ? `Cep ${cep}`: ""} ${rua? `- Rua ${rua}` : ""} ${numero ? `${numero}` : ""}
                      ${complemento ? `- ${complemento}` : ""} ${cidade ? `- ${cidade}` : ""} ${uf ? `- ${uf} -` : ""}
                    `}
                    {latitude && longitude &&  
                     <a href={`https://maps.google.com/?q=${latitude},${longitude}`} 
                      className="detalhes-imovel-link" target="_blank">
                        ver no mapa
                     </a>
                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="detalhes-imovel-title-divider">
                      Descrição
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className="detalhes-imovel-text"
                  >
                    {descricao}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="detalhes-imovel-title-divider">Fotos</div>
                  </Grid>
                  <Grid item container xs={12} sm={12} md={12}>
                   {fotos.map((item, index) => (  
                      <Grid item xs={3} sm={3} md={3} key={index}>
                      <img
                        className="detalhes-imovel-image"
                        src={item}
                      />
                    </Grid>
                   ))}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="detalhes-imovel-title-divider">
                      Informações
                    </div>
                  </Grid>
                  <Grid item container xs={12} sm={12} md={12} spacing={1}>
                    {dormitorios && dormitorios !== undefined &&
                    <Grid item container xs={12} sm={4} md={4}>
                      <div className="detalhes-imovel-info">
                        <Icon name="bedroom" color="#4B4B4B" />
                        <div>{`${dormitorios} ${Number(dormitorios) > 1 ? "quartos" : "quarto"}`}</div>
                      </div>
                    </Grid>}
                    {vagas && vagas !== undefined && 
                    <Grid item container xs={12} sm={4} md={4}>
                      <div className="detalhes-imovel-info">
                        <Icon name="garage" color="#4B4B4B" />
                        <div>{`${vagas} ${Number(vagas) > 1 ? "vagas" : "vaga"}`}</div>
                      </div>
                    </Grid>}
                    {banheiros && banheiros !== undefined  &&
                    <Grid item container xs={12} sm={4} md={4}>
                      <div className="detalhes-imovel-info">
                        <Icon name="bathroom" color="#4B4B4B" />
                        <div>{`${banheiros} ${Number(banheiros) > 1 ? "banheiros" : "banheiro"}`}</div>
                      </div>
                    </Grid>}
                  </Grid>
                  {caracteristicasAdicionais?.length !== 0 && (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="detalhes-imovel-title-divider">
                          Características adicionais
                        </div>
                      </Grid>
                      <Grid item container xs={12} sm={12} md={12} spacing={1}>
                        {caracteristicasAdicionais.map(item => (
                          <Grid item container xs={12} sm={4} md={4}>
                            <div className="detalhes-imovel-info">
                              <Icon name="check-square" color="#000" size="22" />
                              <div>{item.descricao}</div>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Grid item xs={12} sm={12} md={12}>
                  <div className="detalhes-imovel-title-divider">
                    Dados da locação
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  container
                  justify="space-between"
                >
                  <div className="detalhes-imovel-text-strong">
                    Valor de Venda
                  </div>
                  <div className="detalhes-imovel-text">{numberToCurrency(valorVenda)}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  container
                  justify="space-between"
                >
                  <div className="detalhes-imovel-text-strong">
                    Valor de Locação
                  </div>
                  <div className="detalhes-imovel-text">{numberToCurrency(valorLocacao)}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  container
                  justify="space-between"
                >
                  <div className="detalhes-imovel-text-strong">
                    IPTU
                  </div>
                  <div className="detalhes-imovel-text">{numberToCurrency(iptu)}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  container
                  justify="space-between"
                  style={{
                    borderBottom: '0.5px #dfdfe2 solid'
                  }}
                >
                  <div className="detalhes-imovel-text-strong">
                    Condomínio
                  </div>
                  <div className="detalhes-imovel-text">{numberToCurrency(condominio)}</div>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default DetalhesImovel;
