import React from "react";
import { DialogTitle, Dialog, DialogContent, Grid } from "@material-ui/core";
import ButtonComponent from "../ButtonComponent";
import ModalAcessoNegado from "../ModalAcessoNegado";
import { useHistory } from "react-router-dom";
import "./styles.css";

export default function ModalLoginExpirado({ open }) {
  const history = useHistory();
  const openAcessoNegado = window.sessionStorage.getItem("acesso_negado") === "true";

   return (
    openAcessoNegado && open
    ? <ModalAcessoNegado open={open} /> 
    : ( 
        <Dialog open={open}>
          <div className="modal-title-area">
            <DialogTitle>
              <span className="modal-title">ACESSO EXPIRADO</span>
            </DialogTitle>
          </div>
          <DialogContent className="modal-content">
            <Grid container direction="column" spacing={4} className="modal-items">
              <Grid item xs={12}>
                É necessário fazer o Login novamente. Clique no botão abaixo e
                efetue o login.
              </Grid>
              <Grid item xs container justify="flex-end">
                <ButtonComponent
                  className="btn-success margin-zero"
                  text="Login"
                  onClick={() => {
                    localStorage.removeItem("session");
                    history.push("/");
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
    )
  );
}
