import React, { useState } from "react";
import "./styles.scss";
import Icon from '../Icon'

function ShowMore({ title, listInfo }) {
  const [show, setShow] = useState(false);

  function handleShowMore() {
    setShow(prev => !prev);
  }

  return (
    <>
    <div onClick={handleShowMore} className="show-container-header">
      <span>{title.toUpperCase()}</span>

      {show ? <Icon name="minus" /> : <Icon name="add" />}
    </div>
    {show && (
      <div className="box-container-info" >
        {listInfo?.map(info => (
          <div className="show-info-container">
            <span>{info?.title}</span>
            <p>{info?.description}</p>
          </div>
        ))}
      </div>
    )}
    </>
  );
}

export default ShowMore;
