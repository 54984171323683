import React, { useState } from "react";
import ButtonComponent from "../../components/ButtonComponent";
import ModalContas from "../../components/ModalContas";
import ModalRegistraRecebePaga from "../../components/ModalRegistraRecebePaga";
import ModalTransferencia from "../../components/ModalTransferencia";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import "./styles.css";

function ViewModalContas() {
  const [openModalPagamentos, setOpenModalPagamentos] = useState(false);
  const [openModalRecebimentos, setOpenModalRecebimentos] = useState(false);
  const [openModalPagSemConta, setOpenModalPagSemConta] = useState(false);
  const [openModalRecebSemConta, setOpenModalRecebSemContas] = useState(false);
  const [openModalTransferencia, setOpenModalTransferencia] = useState(false);
  const [openModalRecebe, setOpenModalRecebe] = useState(false);
  const [openModalPaga, setOpenModalPaga] = useState(false);
  const [retornoRecebePaga, setRetornoRecebePaga] = useState({
    isRecebimento: true,
    isCancelar: false,
    data: null,
    valor: 0.0,
    conta: "",
  });

  const dataMockModalPagamento = {
    nomeEmpresa: "DELTA TRANSPORTES LTDA",
    nomeFornecedor: "ALPHA IMÓVEIS LTDA",
    dataPrev: "06/07/2020",
    categoria: "Prestação de serviços mensais",
    valorPrev: "R$ 2.500,00",
    conta: "BRADESCO S.A (4343-1)",
    dataRealizada: "06/08/2020",
    valorRealizado: "R$ 2.500,00",
  };
  const dataMockModalPagSemConta = {
    nomeEmpresa: "DELTA TRANSPORTES LTDA",
    nomeFornecedor: "ALPHA IMÓVEIS LTDA",
    dataPrev: "06/07/2020",
    categoria: "Prestação de serviços mensais",
    valorPrev: "R$ 2.500,00",
  };
  const dataMockModalRecebimento = {
    nomeEmpresa: "DELTA TRANSPORTES LTDA",
    nomeCliente: "ALPHA IMÓVEIS LTDA",
    dataPrev: "06/07/2020",
    categoria: "Prestação de serviços mensais",
    valorPrev: "R$ 2.500,00",
    conta: "BRADESCO S.A (4343-1)",
    dataRealizada: "06/08/2020",
    valorRealizado: "R$ 2.500,00",
  };
  const dataMockModalRecebSemConta = {
    nomeEmpresa: "DELTA TRANSPORTES LTDA",
    nomeCliente: "ALPHA IMÓVEIS LTDA",
    dataPrev: "06/07/2020",
    categoria: "Prestação de serviços mensais",
    valorPrev: "R$ 2.500,00",
  };
  function handleOpenModalPagamentos() {
    setOpenModalPagamentos(true);
  }
  function handleOpenModalRecebimentos() {
    setOpenModalRecebimentos(true);
  }
  function handleOpenModalPagamentosSemConta() {
    setOpenModalPagSemConta(true);
  }
  function handleOpenModalRecebSemConta() {
    setOpenModalRecebSemContas(true);
  }
  function handleClose() {
    setOpenModalPagamentos(false);
    setOpenModalRecebimentos(false);
    setOpenModalPagSemConta(false);
    setOpenModalRecebSemContas(false);
    setOpenModalPaga(false);
    setOpenModalRecebe(false);
  }
  function handleVerFatura() {
    console.log("ver fatura");
  }
  return (
    <>
      <div className="session-container">
        <div className="session-container-header">
          <div className="teste-modal-container">
            <ButtonComponent
              text="Abrir Modal de PAGAMENTOS"
              className="btn-primary"
              onClick={handleOpenModalPagamentos}
            />
            <ButtonComponent
              text="Abrir Modal de RECEBIMENTOS"
              className="btn-primary"
              onClick={handleOpenModalRecebimentos}
            />
            <ButtonComponent
              text="Abrir Modal de PAGAMENTOS SEM CONTA"
              className="btn-dark"
              onClick={handleOpenModalPagamentosSemConta}
            />
            <ButtonComponent
              text="Abrir Modal de RECEBIMENTOS SEM CONTA"
              className="btn-dark"
              onClick={handleOpenModalRecebSemConta}
            />
            <ButtonComponent
              text="Abrir REGISTRO de RECEBIMENTO"
              className="btn-dark"
              onClick={() => setOpenModalRecebe(true)}
            />
            <ButtonComponent
              text="Abrir CANCELAMENTO de PAGAMENTO"
              className="btn-dark"
              onClick={() => setOpenModalPaga(true)}
            />
            <ButtonComponent
              text="Abrir TRANSFERENCIA de CONTA"
              className="btn-dark"
              onClick={() => setOpenModalTransferencia(true)}
            />
          </div>

          <div>
            <ModalContas
              type="pagamento"
              dataModal={dataMockModalPagamento}
              open={openModalPagamentos}
              onClose={handleClose}
            />
            <ModalContas
              type="recebimento"
              cbVerFatura={handleVerFatura}
              dataModal={dataMockModalRecebimento}
              open={openModalRecebimentos}
              onClose={handleClose}
            />
            <ModalContas
              type="recebimento"
              cbVerFatura={handleVerFatura}
              dataModal={dataMockModalRecebSemConta}
              open={openModalRecebSemConta}
              onClose={handleClose}
            />
            <ModalContas
              type="pagamento"
              dataModal={dataMockModalPagSemConta}
              open={openModalPagSemConta}
              onClose={handleClose}
            />
            <ModalLoginExpirado />
          </div>

          <div>
            <ModalRegistraRecebePaga
              open={openModalRecebe}
              listaContas={[
                { id: "1", nome: "conta1" },
                { id: "2", nome: "conta2" },
                { id: "3", nome: "conta3" },
              ]}
              tipoRecebimentoPagamento="recebimento"
              tipoRegistrarCancelar="registrar"
              onClose={(value) => {
                setRetornoRecebePaga(value);
                handleClose();
              }}
            />
            <ModalRegistraRecebePaga
              open={openModalPaga}
              conta="33"
              data="21/12/1999"
              valor="987687.56"
              listaContas={[
                { id: "1", nome: "conta1" },
                { id: "2", nome: "conta2" },
                { id: "33", nome: "conta33" },
              ]}
              tipoRecebimentoPagamento="pagamento"
              tipoRegistrarCancelar="cancelar"
              onClose={(value) => {
                setRetornoRecebePaga(value);
                handleClose();
              }}
            />
            <ModalTransferencia
              open={openModalTransferencia}
              onClose={() => setOpenModalTransferencia(false)}
            />
          </div>
        </div>

        <h4>Retorno</h4>
        <ul>
          <li>
            tipoRecebimentoPagamento:{" "}
            {retornoRecebePaga.tipoRecebimentoPagamento}
          </li>
          <li>
            tipoRegistrarCancelar: {retornoRecebePaga.tipoRegistrarCancelar}
          </li>
          <li>data: {retornoRecebePaga.data}</li>
          <li>valor: {retornoRecebePaga.valor}</li>
          <li>conta: {retornoRecebePaga.conta}</li>
        </ul>
      </div>
    </>
  );
}

export default ViewModalContas;
