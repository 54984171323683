import React, { useCallback, useEffect, useState } from "react";
import {
  Divider,
  FormControlLabel,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import Input from "../../../../../components/Input";
import buscaCepService from "../../../../../services/buscaCepService";
import { onlyUnsignedNumbers } from "../../../../../utils/strings";
import SelectComponent from "../../../../../components/SelectComponent";
import SwitchComponent from "../../../../../components/Switch";
import selectService from "../../../../../services/selectServices";
import DatePicker from "../../../../../components/DatePicker";
import styles from "./styles.module.scss";
import InputFile from "../../../../../components/InputFile";
import ButtonComponent from "../../../../../components/ButtonComponent";

const simNaoOpcoes = [
  {
    id: 1,
    nome: "Sim",
  },
  {
    id: 0,
    nome: "Não",
  },
];

export function Dependentes({ locationState, dados, setDados, setLoading }) {
  const isMobile = useMediaQuery("(max-width: 1100px)");

  const { getCep } = buscaCepService;
  const {
    selecionaParentesco,
    selecionaPais,
    selecionaTipoDeficiencia,
    selecionaNacionalidade,
  } = selectService;

  const [listParentesco, setListParentesco] = useState([]);
  const [listPais, setListPais] = useState([]);
  const [listNacionalidade, setListNacionalidade] = useState([]);
  const [listTipoDeficiencia, setListTipoDeficiencia] = useState([]);

  const loadParentesco = useCallback(async () => {
    await selecionaParentesco().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListParentesco(response.data);
    });
  }, []);

  const loadPais = useCallback(async () => {
    await selecionaPais().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListPais(response.data);
    });
  }, []);

  const loadNacionalidade = useCallback(async () => {
    await selecionaNacionalidade().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListNacionalidade(response.data);
    });
  }, []);

  const loadTipoDeficiencia = useCallback(async () => {
    await selecionaTipoDeficiencia().then((response) => {
      if (response.status === 401) {
        setLoading(false);
        return;
      }
      if (response.status === 200) setListTipoDeficiencia(response.data);
    });
  }, []);

  function handleChangeDados(field, value, index = -1) {
    let novosDados = { ...dados };
    if (index >= 0) {
      let novosDadosDependentes = novosDados.dependentes;
      if (field === "cep" && value.length === 8) {
        handleChangeCep(value, index);
      }
      novosDadosDependentes = novosDadosDependentes?.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            [field]: value,
          };
        } else {
          return item;
        }
      });
      novosDados["dependentes"] = novosDadosDependentes;
    } else {
      novosDados[field] = value;
      console.log(novosDados);
    }
    setDados(novosDados);
  }

  async function handleChangeCep(cep, index) {
    try {
      setLoading(true);
      const response = await getCep(cep);
      setLoading(false);
      if (response.status === 401) {
        return;
      }
      const data = response.data;
      console.log(data);
      if (!data.hasOwnProperty("erro")) {
        let novosDados = { ...dados };
        let novosDadosDependentes = novosDados.dependentes;
        novosDadosDependentes = novosDadosDependentes?.map((item, i) => {
          if (index === i) {
            if (!data.hasOwnProperty("erro") && data?.cep !== "0") {
              return {
                ...item,
                cep: data?.cep,
                rua: data?.logradouro,
                complemento: data?.complemento,
                bairro: data?.bairro,
                cidade: data?.localidade,
                estado: data?.uf,
              };
            }
            return item;
          } else {
            return item;
          }
        });
        novosDados = {
          ...novosDados,
          dependentes: novosDadosDependentes,
        };
        setDados(novosDados);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  function handleAddDependente() {
    let novosDados = { ...dados };
    let novosDadosDependentes = novosDados.dependentes;
    novosDadosDependentes.push({
      nome: null,
      cpf: null,
      parentesco: null,
      dataNascimento: null,
      inicioDependencia: null,
      paisNascimento: null,
      nacionalidade: null,
      dataEntradaPais: null,
      telefone: null,
      possuiDeficiencia: null,
      deficiencia: null,
      mesmoEndereco: null,
      cep: null,
      rua: null,
      numero: null,
      complemento: null,
      bairro: null,
      cidade: null,
      estado: null,
      pais: null,
      declaraDependente: null,
      salarioFamilia: null,
      certidaoNascimento: null,
      cadernetaVacinacao: null,
    });
    novosDados.dependentes = novosDadosDependentes;

    setDados(novosDados);
  }

  function handleRemoveDependente(index) {
    let novosDados = { ...dados };
    let novosDadosDependentes = novosDados?.dependentes
      ? novosDados.dependentes?.filter((_, i) => index !== i)
      : [];

    novosDados.dependentes = novosDadosDependentes;

    setDados(novosDados);
  }

  useEffect(() => {
    loadParentesco();
    loadPais();
    loadNacionalidade();
    loadTipoDeficiencia();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <SelectComponent
          styleType="form"
          label="Possui dependentes?"
          required
          title="Selecione uma opção"
          list={simNaoOpcoes}
          initialValue={
            simNaoOpcoes?.find((item) => item.id === dados?.possuiDependentes)
              ? simNaoOpcoes?.find(
                  (item) => item.id === dados?.possuiDependentes
                ).nome
              : ""
          }
          callback={(id) => {
            handleChangeDados("possuiDependentes", id);
          }}
          isDisabled={locationState?.details}
        />
      </Grid>
      {dados?.possuiDependentes ? (
        <>
          {dados?.dependentes?.map((item, index) => {
            return (
              <Grid container item spacing={2} key={index}>
                <Grid item xs={12} style={{ marginTop: 20, marginBottom: 10 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Nome"
                    name="nome"
                    tipo="nome"
                    required
                    value={item?.nome}
                    handleInputChange={async (e) => {
                      handleChangeDados("nome", e.target.value, index);
                    }}
                    disabled={locationState?.details}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="CPF"
                    name="cpf"
                    tipo="cpf"
                    required
                    value={item?.cpf}
                    handleInputChange={async (e) => {
                      handleChangeDados(
                        "cpf",
                        onlyUnsignedNumbers(e.target.value),
                        index
                      );
                    }}
                    disabled={locationState?.details}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Parentesco"
                    title="Selecione uma opção"
                    list={listParentesco}
                    initialValue={
                      listParentesco?.find(
                        (itemFind) => itemFind.id === item?.parentesco
                      )
                        ? listParentesco?.find(
                            (itemFind) => itemFind.id === item?.parentesco
                          ).nome
                        : ""
                    }
                    callback={(id) => {
                      handleChangeDados("parentesco", id, index);
                    }}
                    isDisabled={locationState?.details}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Data de Nascimento*"
                    name="dataNascimento"
                    initDate={item?.dataNascimento ? item?.dataNascimento : ""}
                    handleChange={(date) => {
                      handleChangeDados("dataNascimento", date, index);
                    }}
                    value={item?.dataNascimento}
                    readOnly={locationState?.details}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Início da Dependência*"
                    name="inicioDependencia"
                    initDate={
                      item?.inicioDependencia ? item?.inicioDependencia : ""
                    }
                    handleChange={(date) => {
                      handleChangeDados("inicioDependencia", date, index);
                    }}
                    value={item?.inicioDependencia}
                    readOnly={locationState?.details}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="País de Nascimento"
                    title="Selecione uma opção"
                    list={listPais}
                    required
                    initialValue={
                      listPais?.find(
                        (itemFind) => itemFind.id === item?.paisNascimento
                      )
                        ? listPais?.find(
                            (itemFind) => itemFind.id === item?.paisNascimento
                          ).nome
                        : ""
                    }
                    callback={(id) => {
                      handleChangeDados("paisNascimento", id, index);
                    }}
                    isDisabled={locationState?.details}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Nacionalidade"
                    title="Selecione uma opção"
                    list={listNacionalidade}
                    required
                    initialValue={
                      listNacionalidade?.find(
                        (itemFind) => itemFind.id === item?.nacionalidade
                      )
                        ? listNacionalidade?.find(
                            (itemFind) => itemFind.id === item?.nacionalidade
                          ).nome
                        : ""
                    }
                    callback={(id) => {
                      handleChangeDados("nacionalidade", id, index);
                    }}
                    isDisabled={locationState?.details}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Data de entrada no País"
                    name="dataEntradaPais"
                    initDate={
                      item?.dataEntradaPais ? item?.dataEntradaPais : ""
                    }
                    handleChange={(date) => {
                      handleChangeDados("dataEntradaPais", date, index);
                    }}
                    value={item?.dataEntradaPais}
                    readOnly={locationState?.details}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Input
                    label="Telefone"
                    name="telefone"
                    required
                    tipo="telefone"
                    value={item?.telefone}
                    handleInputChange={async (e) => {
                      handleChangeDados("telefone", e.target.value, index);
                    }}
                    disabled={locationState?.details}
                  />
                </Grid>

                <Grid item className="container-input-switch" xs={12}>
                  Possui deficiência?
                  <Grid
                    item
                    style={{
                      marginLeft: isMobile ? "auto" : 12,
                      marginRight: 12,
                    }}
                  >
                    Não
                  </Grid>
                  <Grid item style={{ marginLeft: 12 }}>
                    <FormControlLabel
                      className={styles.controlLabel}
                      style={{ marginBottom: 0 }}
                      control={
                        <SwitchComponent
                          checked={item?.possuiDeficiencia}
                          onClick={(event) => {
                            console.log(item?.possuiDeficiencia);
                            handleChangeDados(
                              "possuiDeficiencia",
                              !item.possuiDeficiencia,
                              index
                            );
                          }}
                          name="possuiDeficiencia"
                          disabled={locationState?.details}
                        />
                      }
                    />
                  </Grid>
                  <Grid item style={{ marginLeft: -8 }}>
                    Sim
                  </Grid>
                </Grid>

                {item?.possuiDeficiencia ? (
                  <Grid item xs={12} md={6}>
                    <SelectComponent
                      styleType="form"
                      label="Qual deficiência?"
                      title="Selecione uma opção"
                      list={listTipoDeficiencia}
                      initialValue={
                        listTipoDeficiencia.find(
                          (itemFind) => itemFind.id === item?.deficiencia
                        )
                          ? listTipoDeficiencia.find(
                              (itemFind) => itemFind.id === item?.deficiencia
                            ).nome
                          : ""
                      }
                      callback={(id) =>
                        handleChangeDados("deficiencia", id, index)
                      }
                      isDisabled={locationState?.details}
                    />
                  </Grid>
                ) : null}

                <Grid item className="container-input-switch" xs={12}>
                  Incluso no plano de saúde?
                  <Grid
                    item
                    style={{
                      marginLeft: isMobile ? "auto" : 12,
                      marginRight: 12,
                    }}
                  >
                    Não
                  </Grid>
                  <Grid item style={{ marginLeft: 12 }}>
                    <FormControlLabel
                      className={styles.controlLabel}
                      style={{ marginBottom: 0 }}
                      control={
                        <SwitchComponent
                          checked={item?.inclusoPlanoSaude}
                          onClick={(event) => {
                            console.log(item?.inclusoPlanoSaude);
                            handleChangeDados(
                              "inclusoPlanoSaude",
                              !item.inclusoPlanoSaude,
                              index
                            );
                          }}
                          name="inclusoPlanoSaude"
                          disabled={locationState?.details}
                        />
                      }
                    />
                  </Grid>
                  <Grid item style={{ marginLeft: -8 }}>
                    Sim
                  </Grid>
                </Grid>

                <Grid item className="container-input-switch" xs={12}>
                  Incluso no plano odontológico?
                  <Grid
                    item
                    style={{
                      marginLeft: isMobile ? "auto" : 12,
                      marginRight: 12,
                    }}
                  >
                    Não
                  </Grid>
                  <Grid item style={{ marginLeft: 12 }}>
                    <FormControlLabel
                      className={styles.controlLabel}
                      style={{ marginBottom: 0 }}
                      control={
                        <SwitchComponent
                          checked={item?.inclusoPlanoOdontologico}
                          onClick={(event) => {
                            console.log(item?.inclusoPlanoOdontologico);
                            handleChangeDados(
                              "inclusoPlanoOdontologico",
                              !item.inclusoPlanoOdontologico,
                              index
                            );
                          }}
                          name="inclusoPlanoOdontologico"
                          disabled={locationState?.details}
                        />
                      }
                    />
                  </Grid>
                  <Grid item style={{ marginLeft: -8 }}>
                    Sim
                  </Grid>
                </Grid>

                <Grid item className="container-input-switch" xs={12}>
                  Mesmo endereço do funcionário?
                  <Grid
                    item
                    style={{
                      marginLeft: isMobile ? "auto" : 12,
                      marginRight: 12,
                    }}
                  >
                    Não
                  </Grid>
                  <Grid item style={{ marginLeft: 12 }}>
                    <FormControlLabel
                      className={styles.controlLabel}
                      style={{ marginBottom: 0 }}
                      control={
                        <SwitchComponent
                          checked={item?.mesmoEndereco}
                          onClick={(event) => {
                            console.log(item?.mesmoEndereco);
                            handleChangeDados(
                              "mesmoEndereco",
                              !item.mesmoEndereco,
                              index
                            );
                          }}
                          name="mesmoEndereco"
                          disabled={locationState?.details}
                        />
                      }
                    />
                  </Grid>
                  <Grid item style={{ marginLeft: -8 }}>
                    Sim
                  </Grid>
                </Grid>

                {!item?.mesmoEndereco && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Input
                        label="CEP"
                        name="cep"
                        tipo="cep"
                        required
                        value={item?.cep}
                        handleInputChange={async (e) => {
                          handleChangeDados(
                            "cep",
                            onlyUnsignedNumbers(e.target.value),
                            index
                          );
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Input
                        label="Rua"
                        name="rua"
                        required
                        value={item?.rua}
                        handleInputChange={async (e) => {
                          handleChangeDados("rua", e.target.value, index);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Input
                        label="Número"
                        name="numero"
                        required
                        value={item?.numero}
                        handleInputChange={async (e) => {
                          handleChangeDados("numero", e.target.value, index);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Input
                        label="Complemento"
                        name="complemento"
                        value={item?.complemento}
                        handleInputChange={async (e) => {
                          handleChangeDados(
                            "complemento",
                            e.target.value,
                            index
                          );
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Input
                        label="Bairro"
                        name="bairro"
                        required
                        value={item?.bairro}
                        handleInputChange={async (e) => {
                          handleChangeDados("bairro", e.target.value, index);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Input
                        label="Cidade"
                        name="cidade"
                        required
                        value={item?.cidade}
                        handleInputChange={async (e) => {
                          handleChangeDados("cidade", e.target.value, index);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Input
                        label="Estado"
                        name="estado"
                        required
                        value={item?.estado}
                        handleInputChange={async (e) => {
                          handleChangeDados("estado", e.target.value, index);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Input
                        label="País"
                        name="pais"
                        required
                        value={item?.pais}
                        handleInputChange={async (e) => {
                          handleChangeDados("pais", e.target.value, index);
                        }}
                        disabled={locationState?.details}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Declara esse dependente no IRPF"
                    title="Selecione uma opção"
                    list={simNaoOpcoes}
                    initialValue={
                      simNaoOpcoes?.find(
                        (itemFind) => itemFind.id === item?.declaraDependente
                      )
                        ? simNaoOpcoes?.find(
                            (itemFind) =>
                              itemFind.id === item?.declaraDependente
                          ).nome
                        : ""
                    }
                    callback={(id) => {
                      handleChangeDados("declaraDependente", id, index);
                    }}
                    isDisabled={locationState?.details}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Possui Salário Família?"
                    title="Selecione uma opção"
                    list={simNaoOpcoes}
                    initialValue={
                      simNaoOpcoes?.find(
                        (itemFind) => itemFind.id === item?.salarioFamilia
                      )
                        ? simNaoOpcoes?.find(
                            (itemFind) => itemFind.id === item?.salarioFamilia
                          ).nome
                        : ""
                    }
                    callback={(id) => {
                      handleChangeDados("salarioFamilia", id, index);
                    }}
                    isDisabled={locationState?.details}
                  />
                </Grid>

                {item.salarioFamilia ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <InputFile
                        hasFile={
                          item.certidaoNascimentoBase64
                            ? "Arquivo selecionado"
                            : false
                        }
                        id={`0${index}`}
                        title="Certidão de nascimento"
                        onChange={(_, __, file) =>
                          handleChangeDados("certidaoNascimento", file, index)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputFile
                        hasFile={
                          item.cadernetaVacinacaoBase64
                            ? "Arquivo selecionado"
                            : false
                        }
                        id={`1${index}`}
                        title="Caderneta de vacinação"
                        onChange={(_, __, file) =>
                          handleChangeDados("cadernetaVacinacao", file, index)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} className={`${styles.containerAviso}`}>
                      Se não anexar os documentos, o Departamento Pessoal não
                      libera o Salário Família
                    </Grid>
                  </>
                ) : null}
                {index > 0 && !locationState?.details && (
                  <Grid xs={12}>
                    <ButtonComponent
                      text="Remover Dependente"
                      className={`secondary-outline ${styles.btnAddAndRemove}`}
                      onClick={() => handleRemoveDependente(index)}
                    />
                  </Grid>
                )}
              </Grid>
            );
          })}
          {!locationState?.details ? (
            <div className={styles.containerButton}>
              <ButtonComponent
                text="+ Adicionar dependente"
                onClick={() => handleAddDependente()}
                className={`success-outline ${styles.btnAdd}`}
              />
            </div>
          ) : null}
        </>
      ) : null}

      <Grid item xs={12}>
        <span className="required-text">* Campos obrigatórios</span>
      </Grid>
    </Grid>
  );
}
