import Axios from './Axios';

export default {
  formaPagamento: async () => {
    const response = await Axios.get('/enum-indicador-forma-pagamento')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    
    return response;
  },
  indicadorPresenca: async () => {
    const response = await Axios.get('/enum-indicador-presenca')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    
    return response;
  },
  localDestinoOperacao: async () => {
    const response = await Axios.get('/enum-local-destino-operacao')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    
    return response;
  },
  meioPagamento: async () => {
    const response = await Axios.get('/enum-meio-pagamento')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response;
  },
  modalidadeFrete: async () => {
    const response = await Axios.get('/enum-modalidade-frete')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    
    return response;
  },
  naturezaOperacao: async () => {
    const response = await Axios.get('/enum-natureza-operacao')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    
    return response;
  },
  tipoNF: async () => {
    const response = await Axios.get('/enum-tipo-nf')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    
    return response;
  },
  tipoImpressaoDanfe: async () => {
    const response = await Axios.get('/enum-tipo-impressao-danfe')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    
    return response;
  },
  finalidadeEmissao: async () => {
    const response = await Axios.get('/enum-finalidade-emissao')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    
    return response;
  }
}