import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import SelectComponent from "../../../components/SelectComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import DatePicker from "../../../components/DatePicker";
import InputFile from "../../../components/InputFile";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

import certidoesService from "../../../services/certidoesService";

import "./styles.css";
import useCompany from "../../../hooks/useCompany";

function CadastrarCertidao() {
  const history = useHistory();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const {
    selecionaCertidao,
    selecionaAbrangencia,
    selecionaOrgaoEmissor,
    selecionaStatus,
    cadastraCertidao,
  } = certidoesService;

  const {
    companyList: listEmpresas,
    selectedCompany: idEmpresaSelected,
    setSelectedCompany: setIdEmpresaSelected,
    getEmpresaPagina,
  } = useCompany("certidoes");

  const [listCertidao, setListCertidao] = useState([]);
  const [listAbrangencia, setListAbrangencia] = useState([]);
  const [listOrgaoEmissor, setListOrgaoEmissor] = useState([]);
  const [listStatus, setListStatus] = useState([]);

  const [anexo, setAnexo] = useState();
  const [dataVencimento, setDataVencimento] = useState("");
  const [dataEmissao, setDataEmissao] = useState("");
  const [statusCertidao, setStatusCertidao] = useState();
  const [abrangencia, setAbrangencia] = useState();
  const [orgaoEmissor, setOrgaoEmissor] = useState();
  const [tipoCertidao, setTipoCertidao] = useState();

  const [loading, setLoading] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackErrors, setCallbackErrorList] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [duration, setCallbackDuration] = useState(6000);

  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  let getCertidaoList = async () => {
    const response = await selecionaCertidao();
    setListCertidao(response.data);
  };

  let getAbrangenciaList = async () => {
    const response = await selecionaAbrangencia();
    setListAbrangencia(response.data);
  };

  let getOrgaoEmissorList = async () => {
    const response = await selecionaOrgaoEmissor();
    setListOrgaoEmissor(response.data);
  };

  let getStatusList = async () => {
    const response = await selecionaStatus();
    setListStatus(response.data);
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
      setShowMessage(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
      setShowMessage(false);
    }
  }

  function verifyField(field, callback) {
    if (field === "" || field === null || field === undefined) {
      callback();
    }
  }

  function validateFields() {
    let list = [];
    verifyField(tipoCertidao, () =>
      list.push('O campo "Tipo de certidão" não foi selecionado.')
    );
    verifyField(orgaoEmissor, () =>
      list.push('O campo "Órgão Emissor" é obrigatório.')
    );
    verifyField(abrangencia, () =>
      list.push('O campo "Abrangência" não foi selecionado.')
    );
    verifyField(statusCertidao, () =>
      list.push('O campo "Status certidão" não foi selecionado.')
    );
    verifyField(dataEmissao, () =>
      list.push('O campo "Data emissão" é obrigatório.')
    );
    verifyField(idEmpresaSelected, () =>
      list.push('O campo "Empresa" não foi selecionado.')
    );
    verifyField(anexo, () => list.push('O campo "Anexo" é obrigatório.'));

    setErrors(list);
    if (list.length > 0) setShowMessage(true);
    return list.length === 0;
  }

  function handleSubmit() {
    if (!validateFields()) return;

    setLoading(true);
    (async () => {
      const response = await cadastraCertidao(
        idEmpresaSelected,
        anexo,
        abrangencia,
        orgaoEmissor,
        statusCertidao,
        tipoCertidao,
        dataEmissao,
        dataVencimento
      );
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      console.log(response.status);
      if (response.status === 200) {
        setCallbackType("success");
        setCallbackShown(true);
        setCallbackMessage("Certidão cadastrada com sucesso!");
        setCallbackDuration(2000);
        history.push("/certidoes");
      } else {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackDuration(3000);
        setCallbackMessage("Erro!");
        if (response.data.error)
          setCallbackErrorList(Object.values(response.data.error));
        else setCallbackErrorList(["Ocorreu um erro"]);
        return;
      }
      setLoading(false);
    })();
  }

  useEffect(() => {
    (async function () {
      setLoading(true);
      await Promise.all([
        getCertidaoList(),
        getAbrangenciaList(),
        getOrgaoEmissorList(),
        getStatusList(),
      ]);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("certidoes");
    if (empresaStorage) {
      setIdEmpresaSelected(empresaStorage);
    }
  }, []);

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          {callbackShown && (
            <CallbackMessage
              type={callbackType}
              open={callbackShown}
              message={callbackMessage}
              errorList={callbackErrors}
              handleClose={handleClose}
              duration={duration}
            />
          )}
          <form className="session-container-form">
            <div className="cadastro-certidao-form-container">
              <fieldset className="cadastro-certidao-certidao-select">
                <SelectComponent
                  styleType="form"
                  label="Tipo de certidão"
                  title="Selecione um tipo"
                  list={listCertidao}
                  required
                  callback={(element) => setTipoCertidao(element)}
                />
              </fieldset>
              <fieldset className="cadastro-certidao-orgaoemissor-select">
                <SelectComponent
                  styleType="form"
                  label="Órgão emissor"
                  title="Selecione um órgão emissor"
                  required
                  list={listOrgaoEmissor}
                  callback={(element) => setOrgaoEmissor(element)}
                />
              </fieldset>
              <fieldset className="cadastro-certidao-abrangencia-select">
                <SelectComponent
                  styleType="form"
                  label="Abrangência"
                  title="Selecione a abrangência"
                  required
                  list={listAbrangencia}
                  callback={(element) => setAbrangencia(element)}
                />
              </fieldset>
              <fieldset className="cadastro-certidao-status-select">
                <SelectComponent
                  styleType="form"
                  label="Status certidão"
                  title="Selecione o status"
                  required
                  list={listStatus}
                  callback={(element) => setStatusCertidao(element)}
                />
              </fieldset>
              <fieldset className="cadastro-certidao-emissao-input">
                <DatePicker
                  label="Data emissão *"
                  handleChange={(value) => setDataEmissao(value)}
                />
              </fieldset>
              <fieldset className="cadastro-certidao-vencimento-input">
                <DatePicker
                  label="Data vencimento *"
                  handleChange={(value) => setDataVencimento(value)}
                />
              </fieldset>
              <fieldset className="cadastro-certidao-empresa-select">
                <SelectComponent
                  styleType="form"
                  label="Empresa"
                  title="Selecione uma empresa"
                  list={listEmpresas}
                  required
                  initialValue={
                    listEmpresas?.find((item) => item.id === idEmpresaSelected)
                      ?.nome || null
                  }
                  callback={(element) => setIdEmpresaSelected(element)}
                />
              </fieldset>
              <fieldset className="cadastro-certidao-anexo-input">
                <InputFile title="Anexo*" onChange={(file) => setAnexo(file)} />
              </fieldset>
              <div className="required-text">
                <span>* Campos obrigatórios</span>
              </div>
            </div>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <ButtonComponent
            text="Salvar"
            type="button"
            className="btn-success"
            onClick={handleSubmit}
          />
        </span>
      </div>
      {showMessage && (
        <CallbackMessage
          open={showMessage}
          type={`${errors.length === 0 ? "success" : "error"}`}
          message={`${
            errors.length === 0 ? "Certidão cadastrada com sucesso!" : "Erro!"
          }`}
          errorList={errors}
          handleClose={handleClose}
          duration={errors.length === 0 ? 2000 : 6000}
        />
      )}
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastrarCertidao;
