import React from "react";
import "./styles.css";
import {
  FiEdit,
  FiSettings,
  FiSearch,
  FiXSquare,
  FiPlus,
  FiPaperclip,
  FiAlertTriangle,
  FiSave,
  FiFilter,
  FiMenu,
  FiCheck,
  FiDollarSign,
  FiMinus,
  FiCheckCircle,
  FiUserPlus,
  FiUserMinus,
} from "react-icons/fi";
import {
  FaSignature,
  FaUserEdit,
  FaRegCalendar,
  FaRegMoneyBillAlt,
  FaSignOutAlt,
  FaBath,
  FaUserPlus,
  FaUserMinus,
} from "react-icons/fa";
import { GoInfo } from "react-icons/go";
import {
  AiOutlineFileText,
  AiOutlineFileProtect,
  AiOutlineFileSearch,
  AiOutlineCalendar,
  AiOutlineCloseCircle,
  AiOutlineCheckSquare,
  AiOutlineFileUnknown,
  AiOutlineStar,
  AiOutlineMail,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import {
  BsTrash,
  BsPencil,
  BsFileEarmarkArrowDown,
  BsDownload,
  BsChevronDown,
  BsChevronUp,
  BsPlus,
  BsArrowLeftRight,
  BsCheckAll,
  BsEyeSlash,
  BsCheckBox,
} from "react-icons/bs";
import {
  RiMoneyDollarCircleLine,
  RiHandCoinLine,
  RiDeleteBinLine,
  RiShareBoxFill,
} from "react-icons/ri";
import { CgArrowsH, CgRemoveR, CgClipboard } from "react-icons/cg";
import { IoMdNotificationsOutline, IoMdCopy } from "react-icons/io";
import { MdPets, MdContentCopy, MdLockOutline } from "react-icons/md";
import { GiBarbecue } from "react-icons/gi";
import {
  BiBed,
  BiLockAlt,
  BiBlock,
  BiBarcodeReader,
  BiTime,
} from "react-icons/bi";

import plus from "../../assets/img/plus.svg";
import minus from "../../assets/img/minus.svg";
import repeat from "../../assets/img/repeat.svg";
import doublearrows from "../../assets/img/doublearrows.svg";
import fileSearch from "../../assets/img/file-search.svg";
import copy from "../../assets/img/copy.svg";
import printer from "../../assets/img/printer.svg";
import download from "../../assets/img/download01.svg";
import printerBlue from "../../assets/img/printerblue.svg";
import openEye from "../../assets/img/open-eye.svg";
import closedEye from "../../assets/img/closed-eye.svg";
import downloadBlue from "../../assets/img/download-thin-blue.svg";
import garage from "../../assets/img/garage.svg";
import airConditioner from "../../assets/img/air-conditioner.svg";
import clipboard from "../../assets/img/clipboard.svg";
import updateIcon from "../../assets/img/update.svg";
import megaphoneIcon from "../../assets/img/megaphone.svg";
import nfeIcon from "../../assets/img/nf-e.svg";
import ShoppingCartCheck from "../../assets/img/shopping-cart-check.svg";
// import UserPlus from "../../assets/img/user-plus.svg";
import fileDownloadIcon from "../../assets/img/download-file.svg";
import iconConfirma from "../../assets/img/icon-confirma.svg";
import iconConfirmaCheck from "../../assets/img/icon-confirma-check.svg";
import iconDownload from "../../assets/img/icon-download.svg";
import iconUpload from "../../assets/img/icon-upload.svg";
import iconDetails from "../../assets/img/icon-detalhes.svg";
import iconSchedule from "../../assets/img/icon-agendar.svg";
import iconFolha from "../../assets/img/icon-folha.svg";
import iconFecharFolha from "../../assets/img/icon-fecha-folha.svg";
import iconFolhaFechada from "../../assets/img/icon-folha-fechada.svg";
import iconInformation from "../../assets/img/informacao.svg";
import iconSwitchOn from "../../assets/img/icon-switch-on.svg";
import iconSwitchOff from "../../assets/img/icon-switch-off.svg";
import iconHistorico from "../../assets/img/icon-historico.svg";
import iconFecharPonto from "../../assets/img/icon-fecha-ponto.svg";
import iconAdicionar from "../../assets/img/icon-adicionar.svg";
import iconDeletar from "../../assets/img/icon-deletar.svg";
import iconEstorno from "../../assets/img/icon-estorno.svg";
import iconFinaliza from "../../assets/img/icon-finaliza.svg";

function Icon({ name, size, color, fill = false }) {
  return (
    <div className="icon">
      {name === "delete" ? <RiDeleteBinLine /> : null}
      {name === "hamburger" ? <FiMenu fill={color} size={size} /> : null}
      {name === "edit" ? <FiEdit /> : null}
      {name === "edit-user" ? <FaUserEdit /> : null}
      {name === "signature" ? <FaSignature /> : null}
      {name === "info" ? <GoInfo /> : null}
      {name === "info-large" ? <RiMoneyDollarCircleLine /> : null}
      {name === "arrow-down" ? <BsChevronDown /> : null}
      {name === "settings" ? <FiSettings /> : null}
      {name === "certificate" ? <AiOutlineFileProtect /> : null}
      {name === "download" ? <BsDownload size={size} color={color} /> : null}
      {name === "calendar" ? <FaRegCalendar size={size} color={color} /> : null}
      {name === "calendar-outline" ? (
        <AiOutlineCalendar size={size} color={color} />
      ) : null}
      {name === "search" ? <FiSearch size={size} color={color} /> : null}
      {name === "file" ? <AiOutlineFileText size={size} color={color} /> : null}
      {name === "cancel" ? <FiXSquare size={size} color={color} /> : null}
      {name === "plus" ? <BsPlus size={size} color={color} /> : null}
      {name === "close" ? (
        <AiOutlineCloseCircle size={size} color={color} />
      ) : null}
      {name === "alert" ? <FiAlertTriangle size={size} color={color} /> : null}
      {name === "add" ? <FiPlus size={size} color={color} /> : null}
      {name === "minus" ? <FiMinus size={size} color={color} /> : null}
      {name === "money-receive" ? (
        <RiHandCoinLine size={size} color={color} />
      ) : null}
      {name === "save" ? <FiSave size={size} color={color} /> : null}
      {name === "filter" ? <FiFilter size={size} color={color} /> : null}
      {name === "money" ? (
        <FaRegMoneyBillAlt size={size} color={color} />
      ) : null}
      {name === "file-search" ? (
        <AiOutlineFileSearch size={size} color={color} />
      ) : null}
      {name === "trash" ? <BsTrash size={size} color={color} /> : null}
      {name === "paper-clip" ? <FiPaperclip size={size} color={color} /> : null}
      {name === "preview" ? <FiPaperclip size={size} color={color} /> : null}
      {name === "pencil" ? <BsPencil size={size} color={color} /> : null}
      {name === "file-download" ? (
        <BsFileEarmarkArrowDown size={size} color={color} />
      ) : null}
      {name === "notification" ? (
        <IoMdNotificationsOutline size={size} color={color} />
      ) : null}
      {name === "arrow-track" ? <CgArrowsH size={size} color={color} /> : null}
      {name === "export" ? <RiShareBoxFill size={size} color={color} /> : null}
      {name === "bedroom" ? <BiBed size={size} color={color} /> : null}
      {name === "bathroom" ? <FaBath size={size} color={color} /> : null}
      {name === "pet" ? <MdPets size={size} color={color} /> : null}
      {name === "barbecue" ? <GiBarbecue size={size} color={color} /> : null}
      {name === "arrow-up" ? <BsChevronUp /> : null}
      {name === "payment" ? <img alt="" src={minus} /> : null}
      {name === "find-file" ? <img alt="" src={fileSearch} /> : null}
      {name === "receivement" ? <img alt="" src={plus} /> : null}
      {name === "repeat" ? <img alt="" src={repeat} /> : null}
      {name === "double-arrows" ? <img alt="" src={doublearrows} /> : null}
      {name === "copy" ? <img alt="" src={copy} /> : null}
      {name === "printer" ? <img alt="" src={printer} /> : null}
      {name === "download-thin" ? <img alt="" src={download} /> : null}
      {name === "printer-blue" ? <img alt="" src={printerBlue} /> : null}
      {name === "open-eye" ? <img alt="" src={openEye} /> : null}
      {name === "closed-eye" ? <img alt="" src={closedEye} /> : null}
      {name === "download-thin-blue" ? <img alt="" src={downloadBlue} /> : null}
      {name === "garage" ? <img alt="" src={garage} /> : null}
      {name === "air-conditioner" ? (
        <img alt="" src={airConditioner} width={22} />
      ) : null}
      {name === "megaphone" ? <img alt="" src={megaphoneIcon} /> : null}

      {name === "remove" ? <CgRemoveR size={size} color={color} /> : null}
      {name === "logout" ? <FaSignOutAlt size={size} color={color} /> : null}
      {name === "clipboard" && <img alt="" src={clipboard} />}
      {name === "check" ? <FiCheck size={size} color={color} /> : null}
      {name === "check-square" ? (
        <AiOutlineCheckSquare size={size} fill={color} />
      ) : null}
      {name === "check-circle" ? (
        <FiCheckCircle size={size} color={color} />
      ) : null}
      {name === "check-double" ? (
        <BsCheckAll size={size} color={color} />
      ) : null}
      {name === "dollar" ? <FiDollarSign size={size} color={color} /> : null}
      {name === "copy-table" ? <IoMdCopy size={size} color={color} /> : null}
      {name === "update-icon" ? <img alt="" src={updateIcon} /> : null}
      {name === "copy-icon" ? (
        <MdContentCopy size={size} color={color} />
      ) : null}
      {name === "arrows" ? (
        <BsArrowLeftRight size={size} color={color} />
      ) : null}
      {name === "lock" ? <BiLockAlt size={size} color={color} /> : null}
      {name === "unknow-file" ? (
        <AiOutlineFileUnknown size={size} color={color} />
      ) : null}
      {name === "block" ? <BiBlock size={size} color={color} /> : null}
      {name === "clipboard-2" ? (
        <CgClipboard size={size} color={color} />
      ) : null}
      {name === "star" ? <AiOutlineStar size={size} color={color} /> : null}
      {name === "nf-e" ? <img alt="" src={nfeIcon} /> : null}
      {name === "eye-slash" ? <BsEyeSlash size={size} color={color} /> : null}
      {name === "barcode" ? (
        <BiBarcodeReader size={size} color={color} />
      ) : null}
      {name === "lockPassword" ? (
        <MdLockOutline size={size} color={color} />
      ) : null}
      {name === "shopping-cart-check" ? (
        <img alt="" src={ShoppingCartCheck} />
      ) : null}
      {name === "email" ? <AiOutlineMail size={size} color={color} /> : null}
      {name === "time" ? <BiTime size={size} color={color} /> : null}
      {name === "add-person" ? (
        fill ? (
          <FaUserPlus size={size} color={color} fill={color} />
        ) : (
          <FiUserPlus size={size} color={color} />
        )
      ) : null}
      {name === "remove-person" ? (
        fill ? (
          <FaUserMinus size={size} color={color} fill={color} />
        ) : (
          <FiUserMinus size={size} color={color} />
        )
      ) : null}
      {name === "plus-circle" ? (
        <AiOutlinePlusCircle size={size} color={color} />
      ) : null}
      {name === "download-2" ? (
        <img alt="" width={size} src={fileDownloadIcon} />
      ) : null}
      {name === "confirma" ? (
        <img alt="" width={size} src={iconConfirma} />
      ) : null}
      {name === "confirma-check" ? (
        <img alt="" width={size} src={iconConfirmaCheck} />
      ) : null}
      {name === "download-dp" ? (
        <img alt="" width={size} src={iconDownload} />
      ) : null}
      {name === "upload-dp" ? (
        <img alt="" width={size} src={iconUpload} />
      ) : null}
      {name === "detalhes" ? (
        <img alt="" width={size} src={iconDetails} />
      ) : null}
      {name === "agendar" ? (
        <img alt="" width={size} src={iconSchedule} />
      ) : null}
      {name === "folha" ? <img alt="" width={size} src={iconFolha} /> : null}
      {name === "fechar-folha" ? (
        fill ? (
          <img alt="" width={size} src={iconFolhaFechada} />
        ) : (
          <img alt="" width={size} src={iconFecharFolha} />
        )
      ) : null}
      {name === "info-circle" ? (
        <img alt="" width={size} src={iconInformation} />
      ) : null}
      {name === "switch-on" ? (
        <img alt="" width={size} src={iconSwitchOn} />
      ) : null}
      {name === "switch-off" ? (
        <img alt="" width={size} src={iconSwitchOff} />
      ) : null}
      {name === "add-square" ? (
        <img alt="" width={size} src={iconAdicionar} />
      ) : null}
      {name === "remover" ? (
        <img alt="" width={size} src={iconDeletar} />
      ) : null}

      {name === "historico" ? (
        <img alt="" width={size} src={iconHistorico} />
      ) : null}
      {name === "fechar-ponto" ? (
        <img alt="" width={size} src={iconFecharPonto} />
      ) : null}
      {name === "estorno" ? (
        <img alt="" width={size} src={iconEstorno} />
      ) : null}
      {name === "finaliza-lancamento" ? (
        <img alt="" width={size} src={iconFinaliza} />
      ) : null}
    </div>
  );
}

export default Icon;
