import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../ButtonComponent";

import "./styles.scss";
import InputFile from "../InputFile";
import CallbackMessage from "../CallbackMessage";
import empresasService from "../../services/empresasService";
import SelectComponent from "../SelectComponent";
import { InfoComponent } from "../InfoComponent";
import { InfoOutlined } from "@material-ui/icons";

export const ModalImportarXlsx = ({
  open,
  title,
  description,
  acceptLabel,
  onAccept,
  onClose,
  btnClass = "btn-primary",
  inputFileLabel = "Anexar XLS *",
  warning = "Para fazer o download do arquivo padrão para preenchimento <span>clique aqui</span>",
  callbackErrorListModal = [],
  callbackShownModal = false,
  setCallbackShown = () => {},
  arquivoPadrao = null,
}) => {
  const [listEmpresas, setListEmpresas] = useState([]);
  const { selectEmpresa } = empresasService;

  const [empresa, setEmpresa] = useState(null);
  const [file, setFile] = useState(null);

  const loadEmpresas = useCallback(async () => {
    const response = await selectEmpresa();
    if (response.status === 200) {
      setListEmpresas(response.data?.filter((item) => item.nome !== ""));
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }
  }, [selectEmpresa]);

  async function handleAccept() {
    await onAccept({ arquivo: file, idEmpresa: empresa });
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  function handleCloseModal() {
    setEmpresa(null);
    onClose();
  }

  useEffect(() => {
    loadEmpresas();
    setEmpresa(null);
  }, [open]);

  return (
    <Dialog open={open} onClose={handleCloseModal} className="paper">
      <div className="modal-title-area">
        <DialogTitle>
          <span className="titleConfirmDialog">{title}</span>
        </DialogTitle>
        <IconButton onClick={handleCloseModal} size="medium">
          <CloseIcon className="iconConfirm" />
        </IconButton>
      </div>
      <DialogContent className="custom-dialog-content">
        {description && <pre className="confirmDescription">{description}</pre>}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectComponent
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresa !== undefined
                  ? listEmpresas.find((item) => item.id === empresa)?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={(id) => setEmpresa(id)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputFile
              id={1}
              title={inputFileLabel}
              fileFormat=".xls,.xlsx"
              onChange={(base64) => {
                setFile(base64);
              }}
            />
          </Grid>

          {warning && arquivoPadrao && (
            <InfoComponent
              text={
                <a
                  href={arquivoPadrao}
                  download
                  dangerouslySetInnerHTML={{ __html: warning }}
                />
              }
              icon={<InfoOutlined fontSize="small" className="info-icon" />}
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions className="buttonContent">
        <Button
          text={acceptLabel}
          className={`${btnClass} btnSize`}
          onClick={async () => handleAccept()}
        />
      </DialogActions>
      <CallbackMessage
        type={"error"}
        open={callbackShownModal}
        errorList={callbackErrorListModal}
        duration={4000}
        message={"Erro!"}
        handleClose={handleClose}
      />
    </Dialog>
  );
};
