import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../components/ButtonComponent";
import CallbackMessage from "../../../components/CallbackMessage";
import DatePicker from "../../../components/DatePicker";
import ModalCaixa from "../../../components/ModalCaixa";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import OverlayLoading from "../../../components/OverlayLoading";
import ModalCancelamento from "../../../components/PDVModals/ModalCancelamento";
import ModalDetalhesCaixa from "../../../components/PDVModals/ModalDetalhesCaixa";
import SelectComponent from "../../../components/SelectComponent";
import TableComponent from "../../../components/TableComponent";
import pdvService from "../../../services/pdvService";
import {
  isodateToDateDD_MM_YYYYandHours,
  isodateToDateYYYY_MM_DDandHours,
  toDateYYYY_MM_DD,
} from "../../../utils/dates";
import "./styles.scss";
import { useIsMount } from "../../../hooks/useIsMount";
import useCompany from "../../../hooks/useCompany";

const FilterComponentCustom = ({
  onApplyFilters = () => {},
  onCleanFilters = () => {},
  initDateFrom = null,
  initDateTo = null,
  initCompany = null,
  listEmpresas,
}) => {
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (initDateFrom) setDateFrom(initDateFrom);
    if (initDateTo) setDateTo(initDateTo);
    if (initCompany) setCompany(initCompany);
  }, [initDateFrom, initDateTo, initCompany]);

  const onChangeDateFrom = (value) => {
    setDateFrom(value);
  };

  const onChangeDateTo = (value) => {
    setDateTo(value);
  };

  const handleApplyFilters = () => {
    onApplyFilters({ dateFrom, dateTo, company });
  };

  const handleCleanFilters = () => {
    onCleanFilters();
    setDateFrom(null);
    setCompany(null);
  };

  return (
    <div className="session-container filter-component-container chat">
      <Grid container spacing={2} alignItems="center" className="form-table">
        <Grid item xs={12} sm={12}>
          <SelectComponent
            styleType="form"
            label="Empresa"
            initialValue={
              company !== null
                ? listEmpresas.find((item) => item.id == company)?.nome
                : ""
            }
            title="Selecione uma empresa"
            list={listEmpresas}
            callback={(id) => setCompany(id)}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="De"
            noMask
            handleChange={onChangeDateFrom}
            initDate={dateFrom}
          />
        </Grid>

        <Grid item xs={6}>
          <DatePicker
            noMask
            label="Até"
            handleChange={onChangeDateTo}
            initDate={dateTo}
          />
        </Grid>

        <div className="filter-button-area">
          <Button
            text="Aplicar"
            onClick={handleApplyFilters}
            className="btn-primary"
          />
          <Button
            text="Limpar"
            onClick={handleCleanFilters}
            className="outline-no-hover"
          />
        </div>
      </Grid>
    </div>
  );
};

export default function ListaCaixaPDV() {
  const { listaCaixa } = pdvService;

  const {
    companyList: listEmpresas,
    selectedCompany: empresa,
    setSelectedCompany: setEmpresa,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("listagem_caixas");
  const isMount = useIsMount();

  const [loading, setLoading] = useState(false);
  const [idCaixa, setIdCaixa] = useState();
  const [infoCaixa, setInfoCaixa] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [openModalCaixa, setOpenModalCaixa] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  const [listRequest, setListRequest] = useState([]);
  const [dateDe, setDateDe] = useState(null);
  const [dateAte, setDateAte] = useState(null);
  const [paramsURL, setParamsURL] = useState(null);

  const [exportToXLS, setExportToXLS] = useState({
    url: "erp-exporta-pedidos",
    fileName: "vendas-pdv",
    fileType: "xlsx",
    params: paramsURL,
  });

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_caixa" },
      { columnName: "Número do caixa", dataRef: "numero_caixa" },
      { columnName: "Data de abertura", dataRef: "data_abertura" },
      { columnName: "Valor ", dataRef: "valor_final" },
    ],
    options: {
      delete: false,
      edit: true,
      searchFile: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      filter: true,
      export: true,
      isOpenedFilter: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  const callbackShowMessage = useCallback(() => {
    setShowMessage(true);
  }, []);

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);

    console.log("dataDe:");
    console.log(dateDe);

    if (empresa !== null) {
      const response = await listaCaixa(
        dateDe,
        dateAte,
        empresa,
        defaultConfigTable.currentPage,
        defaultConfigTable.orderBy,
        defaultConfigTable.orderByType,
        defaultConfigTable.perPage,
        defaultConfigTable.searchTerm
      );

      if (response.data) {
        if (response.data.hasOwnProperty("data")) {
          const { data } = response.data;
          console.log(data);
          const newData = data.map((item) => ({
            ...item,
            data_criacao_inicial: isodateToDateYYYY_MM_DDandHours(
              item.data_criacao
            ),
            data_abertura: isodateToDateDD_MM_YYYYandHours(item.data_criacao),
            data_fechamento_inicial: item?.data_fechamento
              ? item.data_fechamento
              : false,
            data_fechamento: item?.data_fechamento
              ? item.data_fechamento
              : false,
          }));

          const newResult = {
            ...response.data,
            data: newData,
          };

          setListRequest(newResult);
        } else {
          const { data } = response;

          // const newData = data.map(item => ({
          //   ...item,
          //   id: item.id_pedido,
          //   valor_total: numberToCurrency(item.valor_total)
          // }))

          const newResult = {
            ...response,
            data,
          };

          // const newResult = data;

          console.log(newResult);

          setListRequest(newResult);
        }
      }
    }
    setLoading(false);
  }, [
    dateAte,
    dateDe,
    empresa,
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    listaCaixa,
  ]);

  let onPageChange = useCallback(
    (newPageObj) => {
      let newPage = newPageObj.page;
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.currentPage = newPage;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onOrderBy = useCallback(
    (newOrderBy) => {
      let orderBy = newOrderBy.orderBy;
      let orderByType = newOrderBy.orderByType;
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.orderBy = orderBy;
      newDefaultConfigTable.orderByType = orderByType;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onPerPage = useCallback(
    (newPerPage) => {
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.perPage = newPerPage;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onSearchTerm = useCallback(
    (value) => {
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.searchTerm = value ? value : null;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onClickFile = (id) => {
    setIdCaixa(id);
    setOpenModalDetails(true);
  };

  let onClickEdit = (id) => {
    const caixa = listRequest?.data?.find(
      (item) => item.id_caixa === Number(id)
    );
    setInfoCaixa({
      ...caixa,
    });
    setOpenModalCaixa(true);
  };

  let onClickDelete = (id) => {
    setIdCaixa(id);
    setOpenConfirmDelete(true);
  };

  let onCancelDelete = () => {
    setOpenConfirmDelete(false);
  };

  let onCancelDetails = () => {
    setOpenModalDetails(false);
  };

  function handleClose() {
    setShowMessage(false);
  }

  const handleApplyFilters = useCallback(
    ({ dateFrom, dateTo, company, empresaStorage = null }) => {
      if (empresaStorage) {
        setEmpresa(empresaStorage);
        setParamsURL((prev) => ({ ...prev, id_empresa: empresaStorage }));
        return onPageChange({ page: 1 });
      }
      setDateDe(dateFrom);
      setDateAte(dateTo);
      setEmpresa(company);
      setParamsURL({
        id_empresa: company,
        data_de: dateFrom,
        data_ate: dateTo,
      });
      onPageChange({ page: 1 });
      setEmpresaPagina("listagem_caixas", company);
    },
    []
  );

  const handleCleanFilters = useCallback(() => {
    setDateDe(null);
    setDateAte(null);
    setEmpresa(null);
    setListRequest({ data: [] });
    setEmpresaPagina("listagem_caixas", null);
  }, []);

  function handleCloseModalCaixa() {
    setInfoCaixa(null);
    setOpenModalCaixa(false);
    updateTable();
  }

  useEffect(() => {
    setExportToXLS({
      ...exportToXLS,
      params: {
        ...exportToXLS.params,
        data_de: dateDe,
        data_ate: dateAte,
        id_empresa: empresa,
      },
    });
  }, [dateDe, dateAte, empresa]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    if (dateDe === null) {
      let year = new Date().getFullYear();
      let initDateYear = new Date(year, 0, 1);
      const dateInitBeforeSetFormat = toDateYYYY_MM_DD(initDateYear);

      setDateDe(dateInitBeforeSetFormat);
    }

    if (dateAte === null) {
      const dateInitSet = new Date();
      const dateInitSetFormat = toDateYYYY_MM_DD(dateInitSet);

      setDateAte(dateInitSetFormat);
    }
  }, [dateDe, dateAte]);

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable, dateAte, dateDe, empresa]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("listagem_caixas");
    if (empresaStorage) {
      handleApplyFilters({ empresaStorage });
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id_caixa"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onClickEdit}
        cbDelete={onClickDelete}
        cbLookFile={onClickFile}
        exportXLS={exportToXLS}
        filterOnly
        filterComponentCustom={
          <FilterComponentCustom
            onApplyFilters={handleApplyFilters}
            onCleanFilters={handleCleanFilters}
            initDateFrom={dateDe}
            initDateTo={dateAte}
            initCompany={empresa}
            listEmpresas={listEmpresas}
          />
        }
      />
      <ModalCaixa
        infoCaixa={infoCaixa}
        open={openModalCaixa}
        onClose={handleCloseModalCaixa}
      />
      <ModalDetalhesCaixa
        open={openModalDetails}
        onClose={onCancelDetails}
        idCaixa={idCaixa}
        onSave={() => {}}
      />
      <ModalCancelamento
        open={openConfirmDelete}
        onClose={onCancelDelete}
        idCaixa={idCaixa}
        onSave={callbackShowMessage}
      />
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${
          errors.length === 0 ? "Pedido cancelado com sucesso!" : "Erro!"
        }`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 3000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
