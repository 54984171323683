import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Input from "../../../components/Input";
import Button from "../../../components/ButtonComponent";
import Select from "../../../components/SelectComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

import empresasService from "../../../services/empresasService";
import contasBancariasService from "../../../services/contasBancariasService.js";

import "./styles.css";

function EditarConta() {
  const history = useHistory();
  const routeParams = useParams();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const { selectEmpresa, consultaEmpresa } = empresasService;
  const {
    atualizaContaBancaria,
    getBancos,
    consultaContaBancaria,
  } = contasBancariasService;

  const [id, setId] = useState(routeParams?.id);
  const [empresas, setEmpresas] = useState([]);
  const [bancos, setBancos] = useState([]);

  const [empresa, setEmpresa] = useState(null);
  const [banco, setBanco] = useState(null);
  const [agencia, setAgencia] = useState("");
  const [contaCorrente, setContaCorrente] = useState("");
  const [digito, setDigito] = useState("");
  const [saldo, setSaldo] = useState("");
  const [contaContabil, setContaContabil] = useState("");
  const [nomeConta, setNomeConta] = useState("");

  const [initialEmpresaValue, setInitialEmpresaValue] = useState("");

  const [loading, setLoading] = useState(true);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await loadConta();
      await loadEmpresas();
      setLoading(false);
    })();
  }, []);

  let params = {
    page: null,
    orderBy: null,
    orderByType: null,
    perPage: 9999,
    searchTerm: null,
  };

  async function loadConta() {
    // const { id } = routeParams;
    const response = await consultaContaBancaria(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }else if(response.status === 400){
      setCallbackType("error");
      setCallbackMessage(response.data.msg || (response.data.message || "Erro!"));
      setCallbackErrorList([response.data.error] || ["Erro ao consultar conta!"]);
      setLoading(false);
      setCallbackShown(true);
      setId(null);
      return;
    }
    const { data } = response;

    const initialEmpresa = await consultaEmpresa(data.id_empresa);
    if (initialEmpresa && initialEmpresa.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const bancosArray = await getBancos();
    if (bancosArray && bancosArray.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const { data: bancosData } = bancosArray;

    if (bancosData && bancosData.length !== 0) {
      setBancos(bancosData);

      const initialBanco = bancosData.find((item) => item.id === data.id_banco);

      setNomeConta(initialBanco.nome);
      setBanco(initialBanco.id);
    }

    setEmpresa(data.id_empresa);
    setAgencia(data.agencia || "");
    setContaCorrente(data.conta_corrente || "");
    setContaContabil(data.conta_contabil || "");
    setDigito(data.digito || "");
    setSaldo(data.saldo_inicial || "");
    setInitialEmpresaValue(initialEmpresa.data.razao_social);
  }

  async function loadEmpresas() {
    const response = await selectEmpresa();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setEmpresas(response.data);
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/cadastro-conta");
    }
  }

  async function handleSelectBanco(id) {
    setBanco(id);
    const nomeBanco = await bancos.find((item) => item.id === id);
    setNomeConta(nomeBanco.nome);
  }

  async function handleAccountSubmit() {
    if (!validateFields()) return;

    // const { id } = routeParams;

    const response = await atualizaContaBancaria(
      id,
      banco,
      agencia,
      contaCorrente,
      digito,
      contaContabil,
      empresa,
      Number(saldo),
      nomeConta
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status !== 201) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(6000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      return;
    } else {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Conta editada com sucesso.");
    }
  }

  function verifyField(field, callback) {
    if (field === "") {
      callback();
    }
  }

  function validateFields() {
    let list = [];
    verifyField(empresa, () => list.push("A Empresa deve ser selecionada."));
    verifyField(banco, () => list.push("O Banco deve ser selecionado."));
    setErrors(list);
    if (list.length > 0) setShowMessage(true);
    return list.length === 0;
  }

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <form className="account-register-form">
            <div className="account-register-form-empresa-input">
              <Select
                styleType="form"
                nome="empresa"
                title="Selecione uma empresa"
                label="Empresa"
                tipo="text"
                required="true"
                list={empresas}
                initialValue={initialEmpresaValue}
                callback={(element) => setEmpresa(element)}
              />
            </div>
            <div className="account-register-form-banco-input">
              <Select
                styleType="form"
                nome="banco"
                label="Banco"
                title="Selecione uma instituição bancária"
                tipo="text"
                list={bancos}
                initialValue={nomeConta}
                required="true"
                callback={(id) => handleSelectBanco(id)}
              />
            </div>
            <div className="account-register-form-agencia-input">
              <Input
                nome="agencia"
                label="Agência"
                tipo="text"
                value={agencia}
                handleInputChange={(event) => setAgencia(event.target.value)}
              />
            </div>
            <div className="account-register-form-contaCorrente-input">
              <Input
                nome="contaCorrente"
                label="Conta Corrente"
                tipo="text"
                type="text"
                value={contaCorrente}
                handleInputChange={(event) =>
                  setContaCorrente(event.target.value)
                }
              />
            </div>
            <div className="account-register-form-digito-input">
              <Input
                nome="digito"
                label="Dígito"
                tipo="text"
                type="text"
                value={digito}
                handleInputChange={(event) => setDigito(event.target.value)}
              />
            </div>
            <div className="account-register-form-saldoInicial-input">
              <Input
                nome="saldoInicial"
                label="Saldo Inicial"
                title={saldo === "" ? "R$" : null}
                tipo="dinheiro"
                type="text"
                value={saldo}
                handleInputChange={(event) => setSaldo(event.value)}
                disabled={true}
              />
            </div>
            <div className="account-register-form-contaContabil-input">
              <Input
                nome="contaContabil"
                label="Conta Contábil"
                tipo="text"
                type="text"
                value={contaContabil}
                handleInputChange={(event) =>
                  setContaContabil(event.target.value)
                }
              />
            </div>
            {callbackShown && (
              <CallbackMessage
                open={callbackShown}
                duration={callbackDuration}
                errorList={callbackErrorList}
                handleClose={handleClose}
                message={callbackMessage}
                type={callbackType}
              />
            )}
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            type="button"
            text="Salvar"
            className="btn-success"
            onClick={handleAccountSubmit}
          />
        </span>
      </div>
      {showMessage && (
        <CallbackMessage
          open={showMessage}
          type={`${errors.length === 0 ? "success" : "error"}`}
          message={`${
            errors.length === 0 ? "Conta editada com sucesso!" : "Erro!"
          }`}
          errorList={errors}
          handleClose={handleClose}
          duration={errors.length === 0 ? 2000 : 6000}
        />
      )}
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default EditarConta;
