import { format } from "date-fns/esm";
import React, { useState, useEffect } from "react";
import TableComponent from "../../components/TableComponent";
import OverlayLoading from "../../components/OverlayLoading";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import leadChatbotService from "../../services/leadChatbotService";
import { formatDate } from "../../utils/dates";
import "./styles.css";

function LeadsChatbot() {
  const [listRequest, setListRequest] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_lead_chatbot" },
      { columnName: "Data/hora", dataRef: "data_hora" },
      { columnName: "Nome", dataRef: "nome" },
      { columnName: "E-mail", dataRef: "email" },
      { columnName: "Telefone", dataRef: "telefone" },
    ],
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
    },
    tableOptions: {
      filter: false,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    updateTable();
  }, []);

  async function updateTable() {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await leadChatbotService.getLista(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm
    );
    console.log(result);
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.status === 200) {
      let list = [];
      let l = [];
      if (result.data.hasOwnProperty("data")) list = result.data.data;
      else list = result.data;

      list.forEach((item) => {
        let date = item.data_hora.split(/(\s+)/);
        item.data_hora = `${formatDate(date[0])} ${date[2]}`;
        l.push(item);
      });

      if (result.data.hasOwnProperty("data")) {
        result.data.data = l;
        setListRequest(result.data);
      } else {
        result.data = l;
        setListRequest(result);
      }
    }

    setLoading(false);
  }

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onMoreAction = (event) => {
    if (event.action === "exportar-xls") {
      console.log("EXPORTAR XLS");
    }
    if (event.action === "exportar-csv") {
      console.log("EXPORTAR CSV");
    }
  };

  return (
    <>
      <div className="table-no-header">
        {loading && <OverlayLoading />}
        <TableComponent
          idName="id"
          dataList={listRequest !== undefined ? listRequest.data : []}
          tableConfig={defaultConfigTable}
          callbackCurrentPage={onPageChange}
          callbackOrderBy={onOrderBy}
          callbackPerPage={onPerPage}
          callbackSearchTerm={onSearchTerm}
          cbTableMoreAction={onMoreAction}
          // withOutTitleHeader
        />
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default LeadsChatbot;
