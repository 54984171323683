import React, { createContext } from "react";

import useAuth from "../hooks/useAuth";

const AuthContext = createContext();

function AuthProvider({ children }) {
  const {
    authenticated,
    handleLogin,
    requestLoader,
    isAdmin,
    isTempPassword,
    setIsTempPassword,
    shouldUpdatePassword,
    setShouldUpdatePassword,
    inactiveDueInactivity,
    avatar,
    setAvatar,
  } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        handleLogin,
        requestLoader,
        isAdmin,
        isTempPassword,
        setIsTempPassword,
        shouldUpdatePassword,
        setShouldUpdatePassword,
        inactiveDueInactivity,
        avatar,
        setAvatar,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
