import React, { useEffect, useState, useCallback } from "react";

import empresasService from "../../services/empresasService";
import TableComponent, {
  TableHeadItem,
} from "../../components/TableResponsiveComponent";
import ConfirmDialog from "../../components/ConfirmDialog";
import OverlayLoading from "../../components/OverlayLoading";
import CallbackMessage from "../../components/CallbackMessage";
import DatePicker from "../../components/DatePicker";
import { useHistory } from "react-router-dom";
import PagamentoRecebimentoExtrato from "../../components/PagamentoRecebimentoExtrato";
import ModalTransferencia from "../../components/ModalTransferencia";
import Select from "../../components/SelectComponent";
import { Grid } from "@material-ui/core";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import extratosService from "../../services/extratosService";
import pagamentosService from "../../services/pagamentosService";
import recebimentosService from "../../services/recebimentosService";
import { format } from "date-fns";
import {
  numberToCurrency,
  numberToNegativeCurrency,
  base64ToFile,
} from "../../utils/functions";
import { formatDate } from "../../utils/dates";

import "./styles.css";
import conciliacaoService from "../../services/conciliacaoService";
import useCompany from "../../hooks/useCompany";

function filterComponent({ setDataInicio, setDataFim }) {
  return (
    <div className="session-container filter-component-container chat">
      <Grid container spacing={2} alignItems="center" className="form-table">
        <Grid item xs={6}>
          <DatePicker
            label="De *"
            handleChange={(value) => setDataInicio(value)}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="Até *"
            handleChange={(value) => setDataFim(value)}
          />
        </Grid>
      </Grid>
    </div>
  );
}

function ContasExtratos() {
  const { getSaldoContas } = extratosService;

  const {
    companyList: listEmpresas,
    selectedCompany: empresaId,
    setSelectedCompany: setEmpresaId,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("contas_bancarias");

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [listRequest, setListRequest] = useState();
  const [loading, setLoading] = useState(false);
  const [listContas, setListContas] = useState([]);
  const [contaId, setContaId] = useState();
  const [nomeConta, setNomeConta] = useState("");
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [errors, setErrors] = useState([]);
  const [openModalTransferencia, setOpenModalTransferencia] = useState(false);
  const [openModalPagamentos, setOpenModalPagamentos] = useState(false);
  const [openModalRecebimentos, setOpenModalRecebimentos] = useState(false);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const history = useHistory();

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Data", dataRef: "data_referencia" },
      { columnName: "Nome", dataRef: "nome" },
      { columnName: "Descrição", dataRef: "descricao" },
      { columnName: "Categoria", dataRef: "nome_categoria" },
      { columnName: "Centro de Custo", dataRef: "nome_centro_custo" },
    ],
    options: {
      clip: true,
      estorno: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      customTableTitle: "Extrato Conciliado",
      selfContainer: true,
    },
    text: {
      clip: "Download",
      estorno: "Desfazer conciliação",
    },
    tableOptions: {
      more: true,
      filter: true,
      moreOptions: [
        { icon: "payment", label: "Novo pagamento", action: "novo-pagamento" },
        {
          icon: "receivement",
          label: "Novo recebimento",
          action: "novo-recebimento",
        },
        {
          icon: "repeat",
          label: "Transferência entre as contas",
          action: "transferencia-entre-contas",
        },
        {
          icon: "double-arrows",
          label: "Conciliar extratos",
          action: "conciliar-extratos",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  const updateTable = useCallback(async () => {
    let list = [];
    let l = [];
    setLoading(true);
    const result = await extratosService.getExtratos(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      contaId,
      dataInicio,
      dataFim
    );
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.status === 200) {
      if (result.data.hasOwnProperty("data")) {
        list = result.data.data.extrato;
      } else list = result.data.extrato;

      if (list !== undefined && !!list.length) {
        list.forEach((item, index) => {
          if (item.saldo.toString().includes("-"))
            item.saldo = numberToNegativeCurrency(item.saldo);
          else item.saldo = numberToCurrency(item.saldo);

          if (item.valor.toString().includes("-")) {
            item.valor = numberToNegativeCurrency(item.valor);
          } else {
            item.valor = numberToCurrency(item.valor);
          }

          item.data_referencia = formatDate(item.data_referencia);
          item.id = item.id_lancamento;
          l.push(item);
        });
        if (result.data.hasOwnProperty("extrato")) {
          result.data.data = {};
        }
        result.data.data.extrato = l;
        setListRequest(result.data);
      } else {
        setListRequest({
          data: {
            extrato: l,
          },
        });
      }
      setLoading(false);
    }
  }, [contaId, dataFim, dataInicio, defaultConfigTable]);

  const onPageChange = (newPageObj) => {
    const newPage = newPageObj.page;
    const newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onOrderBy = (newOrderBy) => {
    const orderBy = newOrderBy.orderBy;
    const orderByType = newOrderBy.orderByType;
    const newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onPerPage = (newPerPage) => {
    const newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onSearchTerm = (value) => {
    const newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onTableMoreAction = ({ action }) => {
    if (empresaId === undefined || contaId === undefined) {
      setShowMessage(true);
      setErrors(["Selecione uma empresa e uma conta antes de continuar"]);
      return;
    }
    if (action === "novo-recebimento") {
      handleOpenModalRecebimentos();
    }
    if (action === "novo-pagamento") {
      handleOpenModalPagamentos();
    }
    if (action === "conciliar-extratos") {
      history.push(`conciliacao/${empresaId}/${contaId}`);
    }
    if (action === "transferencia-entre-contas") handleOpenModalTransferencia();
  };

  const onClickClip = async (id) => {
    id = Number(id);
    if (!listRequest || !listRequest.data) return null;
    setLoading(true);
    let item = listRequest.data.extrato.find((item) => item.id === id);
    if (item.id_tipo_categoria === 1) {
      await recebimentosService.consultaRecebimento(id).then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        if (response.status === 200) {
          if (response.data.arquivo) {
            base64ToFile(response.data.arquivo);
          } else {
            setShowMessage(true);
            setErrors(["Arquivo não encontrado, ou não enviado pelo usuário."]);
            return;
          }
        } else {
          setShowMessage(true);
          setErrors(Object.values(response.data.error));
          return;
        }
      });
    } else {
      await pagamentosService.consultaPagamento(id).then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        if (response.status === 200) {
          if (response.data.arquivo) {
            base64ToFile(response.data.arquivo);
          } else {
            setShowMessage(true);
            setErrors(["Arquivo não encontrado, ou não enviado pelo usuário."]);
            return;
          }
        } else {
          setShowMessage(true);
          setErrors(Object.values(response.data.error));
          return;
        }
      });
    }
    setLoading(false);
  };

  const handleRemoveDialog = useCallback((id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  }, []);

  const handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  const onDelete = async () => {
    if (!listRequest || !listRequest.data) return null;
    setLoading(true);
    const response = await conciliacaoService.estornarConciliacao(deleteId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setShowMessage(true);
      setErrorMessage(response?.data?.message || "Conciliação removida");
      setErrors([]);
    } else {
      setShowMessage(true);
      if (response.data.error) setErrors(Object.values(response.data.error));
      else setErrors(["Ocorreu um erro"]);
    }
    setLoading(false);
    handleRemoveCancel();
    await updateTable();
    await loadContas(empresaId);
  };

  const handleOpenModalPagamentos = () => {
    setOpenModalPagamentos(true);
  };
  const handleOpenModalRecebimentos = () => {
    setOpenModalRecebimentos(true);
  };
  const handleOpenModalTransferencia = () => {
    setOpenModalTransferencia(true);
  };
  const handleClose = () => {
    setOpenModalPagamentos(false);
    setOpenModalRecebimentos(false);
    setOpenModalTransferencia(false);
  };

  const cbForm = async () => {
    await updateTable();
    await loadContas(empresaId);
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
    setErrors([]);
  };

  function CardConta({ conta, cardStyle, cbSelect = () => {} }) {
    const {
      nome,
      agencia,
      conta_contabil,
      conta_corrente,
      valorConciliar,
      saldo,
    } = conta;
    return (
      <div
        onClick={() => {
          cbSelect(conta.id_conta_bancaria);
          setNomeConta(nome);
        }}
        className={`card-conta-container ${cardStyle}`}
      >
        <div className="card-conta-info-container">
          <h4 className="card-conta-title">{nome}</h4>
          <div className="card-conta-info-wrapper">
            <div className="card-conta-info-agencia">
              <span className="card-conta-info-title info-text">
                Agência: {agencia}
              </span>
            </div>
            <div className="card-conta-info-conta-corrente">
              <span className="card-conta-info-title info-text">
                Conta corrente: {conta_corrente}
              </span>
            </div>
          </div>
        </div>
        <div className="card-conta-saldo-container">
          <h4 className="card-conta-saldo">Saldo: {saldo}</h4>
        </div>
      </div>
    );
  }

  async function loadContas(id) {
    setLoading(true);
    await getSaldoContas(id).then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        let list = [];
        if (response.data.length === 0) {
          setLoading(false);
          setErrors(["Não há contas cadastradas nessa empresa."]);
          setShowMessage(true);
          return;
        }
        if (response.data[0].id_conta_bancaria !== null) {
          response.data.forEach((item) => {
            if (item.saldo.toString().includes("-"))
              item.saldo = numberToNegativeCurrency(item.saldo);
            else item.saldo = numberToCurrency(item.saldo);
            list.push(item);
          });
        }
        setListContas(list);
        setLoading(false);
      }
    });
  }

  const handleSelectEmpresa = async (id) => {
    setListContas([]);
    setEmpresaId(id);
    await loadContas(id);
    setEmpresaPagina("contas_bancarias", id);
  };

  const handleSelectConta = (id) => {
    setContaId(id);
  };

  function columnSaldo() {
    return (
      <TableHeadItem
        callbackOrderBy={onOrderBy}
        dataRef="saldo"
        currency
        columnName="Saldo"
      />
    );
  }

  function columnValor() {
    return (
      <TableHeadItem
        callbackOrderBy={onOrderBy}
        dataRef="valor"
        enableOrder
        currency
        columnName="Valor"
      />
    );
  }

  function listSaldo() {
    if (!listRequest || !listRequest.data) return null;
    return listRequest.data.extrato.map((item, index) => (
      <div className="cell-valor" key={index}>
        {item.saldo}
      </div>
    ));
  }

  function listValor() {
    if (!listRequest || !listRequest.data) return null;
    return listRequest.data.extrato.map((item, index) =>
      item.valor.includes("-") ? (
        <div className="font-vermelho cell-valor" key={index}>
          {item.valor}
        </div>
      ) : (
        <div className="font-verde cell-valor" key={index}>
          {item.valor}
        </div>
      )
    );
  }

  useEffect(() => {
    (async () => {
      let today = new Date();
      let unformattedToday = format(today, "yyyy-MM-dd");
      setDataInicio(unformattedToday);
      setDataFim(unformattedToday);
      setLoading(true);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (contaId !== undefined) {
      (async () => {
        setLoading(true);
        await updateTable();
        setLoading(false);
      })();
    }
  }, [dataInicio, dataFim, contaId]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    if (contaId) {
      (async () => {
        setLoading(true);
        await updateTable();
        setLoading(false);
      })();
    }
  }, [dataInicio, dataFim, contaId, updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("contas_bancarias");
    if (empresaStorage) {
      handleSelectEmpresa(empresaStorage);
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}
      <div
        className={`session-container container-conta ${
          listContas.length === 0 ? "container-conta-empty" : ""
        }`}
      >
        <Grid
          container
          spacing={2}
          className="form-table"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              title="Selecione uma empresa"
              list={listEmpresas}
              initialValue={
                listEmpresas?.find((item) => item.id === empresaId)?.nome ||
                null
              }
              callback={handleSelectEmpresa}
            />
          </Grid>
          {listContas && listContas.length !== 0 && (
            <Grid item xs={12}>
              <span className="container-contas-title">Contas</span>
              <div className="container-contas-cards-wrapper">
                {listContas.map((conta, index) => (
                  <React.Fragment key={index}>
                    <CardConta
                      cardStyle={
                        contaId !== conta.id_conta_bancaria
                          ? "dark-style"
                          : "light-style"
                      }
                      conta={conta}
                      key={index}
                      cbSelect={handleSelectConta}
                    />
                  </React.Fragment>
                ))}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest.data.extrato : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbTableMoreAction={onTableMoreAction}
        cbClip={onClickClip}
        cbEstorno={handleRemoveDialog}
        filterComponent={filterComponent({ setDataInicio, setDataFim })}
        customColumns={[
          {
            columnHead: columnValor(),
            columnData: listValor(),
          },
          {
            columnHead: columnSaldo(),
            columnData: listSaldo(),
          },
        ]}
      />
      {openModalPagamentos && (
        <PagamentoRecebimentoExtrato
          isPagamento
          empresaId={empresaId}
          contaId={contaId}
          open={openModalPagamentos}
          onClose={handleClose}
          callback={cbForm}
        />
      )}
      {openModalRecebimentos && (
        <PagamentoRecebimentoExtrato
          isRecebimento
          contaId={contaId}
          empresaId={empresaId}
          open={openModalRecebimentos}
          onClose={handleClose}
          callback={cbForm}
        />
      )}
      {openModalTransferencia && (
        <ModalTransferencia
          onClose={handleClose}
          open={openModalTransferencia}
          idContaOrigem={contaId}
          idEmpresa={empresaId}
          nomeContaOrigem={nomeConta}
          callback={cbForm}
        />
      )}
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
        title="CONFIRMAR"
        description="Você tem certeza que deseja desfazer a conciliação?"
        acceptLabel="Desfazer"
      />
      <CallbackMessage
        type={errors.length === 0 ? "success" : "error"}
        open={showMessage}
        message={errors.length === 0 ? errorMessage : "Erro!"}
        errorList={errors}
        handleClose={handleCloseMessage}
        duration={4000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default ContasExtratos;
