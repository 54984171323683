import parseJSON from './parseJSON'

export function startYvnps() {  
 if(process.env.REACT_APP_YVNPS) {  
  
  let user = parseJSON(window.localStorage.getItem("user"));

  if(user) {  
    window.yvnps = [];
    window.yvnps.push({account: "352173e9-229a-4869-b277-16633c7fc503"});

    window.yvnps.push({userid: `${window.localStorage.getItem("id_usuario")}`});
    window.yvnps.push({name: `${user.name}`});
    window.yvnps.push({email:`${user.email}`});
    var a = document.createElement("script");
    a.type = "text/javascript";
    a.async = !0;
    a.id = "_yvnps";
    a.src = "https://yvnps.s3.amazonaws.com/public/scripts/v2/yvapi.js";
    var b = document.getElementsByTagName("script")[0];
    b.parentNode.insertBefore(a, b);
  }
 }
}