import Axios from './Axios';

const transportadoraService = {
  listaTransportadoras: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null
  ) => {
    const result = await Axios.get('/lista-transportadora', {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  cadastrarTransportadora: async (form) => {
    const add = await Axios.post('/cadastra-transportadora', {
      observacao: form.observacao,
      identificacao_escolhapfpj: form.cnpj_cpf,
      identificacao_id_empresa: form.id_empresa,

      identificacao_pf_cpf: form.cpf,
      identificacao_pj_cnpj: form.cnpj,

      identificacao_pj_razao_social: form.razaoSocial,
      identificacao_pf_nome: form.razaoSocial,
      identificacao_pj_nome_fantasia: form.nome_fantasia,

      endereco_rua: form.endereco,
      endereco_numero: form.numEnd,
      endereco_complemento: form.complemento,
      endereco_bairro: form.bairro,
      endereco_cep: form.cep,
      endereco_cidade: form.cidade,
      endereco_estado: form.estado,

      identificacao_pj_site: form.site,
      identificacao_pj_indicador_ie: form.cod_indicador_ie,
      identificacao_pj_inscricao_municipal: form.inscricao_municipal,
      identificacao_pj_inscricao_estadual: form.inscricao_estadual,
      contato: form.contato,
      id_categoria_padrao: form.id_categoria_padrao,
      identificacao_pj_suframa: form.identificacao_pj_suframa
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return add;
  },
  consultaTransportadora: async (id = null) => {
    const result = await Axios.get(`/consulta-transportadora/${id}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  atualizaTransportadora: async (id, form) => {
    const update = await Axios.post(`/atualiza-transportadora/${id}`, {
      observacao: form.observacao,
      identificacao_escolhapfpj: form.cnpj_cpf,
      identificacao_id_empresa: form.id_empresa,

      identificacao_pf_cpf: form.cpf,
      identificacao_pj_cnpj: form.cnpj,

      identificacao_pj_razao_social: form.razaoSocial,
      identificacao_pf_nome: form.razaoSocial,
      identificacao_pj_nome_fantasia: form.nome_fantasia,

      endereco_rua: form.endereco,
      endereco_numero: form.numEnd,
      endereco_complemento: form.complemento,
      endereco_bairro: form.bairro,
      endereco_cep: form.cep,
      endereco_cidade: form.cidade,
      endereco_estado: form.estado,

      identificacao_pj_site: form.site,
      identificacao_pj_indicador_ie: form.cod_indicador_ie,
      identificacao_pj_inscricao_municipal: form.inscricao_municipal,
      identificacao_pj_inscricao_estadual: form.inscricao_estadual,
      contato: form.contato,
      id_categoria_padrao: form.id_categoria_padrao,
      identificacao_pj_suframa: form.identificacao_pj_suframa
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return update;
  },
  removeTransportadora: async (id = null) => {
    const result = await Axios.get(`/remover-transportadora/${id}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};

export default transportadoraService;
