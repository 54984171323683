import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import Input from "../../../../../components/Input";
import buscaCepService from "../../../../../services/buscaCepService";
import { onlyUnsignedNumbers } from "../../../../../utils/strings";

const simNaoOpcoes = [
  {
    id: 1,
    nome: "Sim",
  },
  {
    id: 0,
    nome: "Não",
  },
];

export function EnderecoContato({
  locationState,
  dados,
  setDados,
  setLoading,
}) {
  const { getCep } = buscaCepService;

  function handleChangeDados(field, value) {
    console.log(value);
    console.log(value.length);
    if (field === "cep" && onlyUnsignedNumbers(value).length === 8) {
      handleChangeCep(onlyUnsignedNumbers(value));
    }
    let novosDados = { ...dados };
    novosDados[field] = value;
    console.log(novosDados);
    setDados(novosDados);
  }

  async function handleChangeCep(cep) {
    try {
      setLoading(true);
      const response = await getCep(cep);
      setLoading(false);
      if (response.status === 401) {
        return;
      }
      const data = response.data;
      console.log(data);
      if (!data.hasOwnProperty("erro") && data?.cep !== '0') {
        let novosDados = { ...dados };
        novosDados = {
          ...novosDados,
          cep: data?.cep,
          rua: data?.logradouro,
          complemento: data?.complemento,
          bairro: data?.bairro,
          cidade: data?.localidade,
          estado: data?.uf,
        };
        setDados(novosDados);
      }
    } catch (error) {
      setLoading(false);
    }
  }
  useEffect(() => {}, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Input
          label="CEP"
          name="cep"
          tipo="cep"
          required
          value={dados?.cep}
          handleInputChange={async (e) => {
            handleChangeDados("cep", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          label="Rua"
          name="rua"
          required
          value={dados?.rua}
          handleInputChange={async (e) => {
            handleChangeDados("rua", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="Número"
          name="numero"
          required
          value={dados?.numero}
          handleInputChange={async (e) => {
            handleChangeDados("numero", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="Complemento"
          name="complemento"
          value={dados?.complemento}
          handleInputChange={async (e) => {
            handleChangeDados("complemento", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="Bairro"
          name="bairro"
          required
          value={dados?.bairro}
          handleInputChange={async (e) => {
            handleChangeDados("bairro", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="Cidade"
          name="cidade"
          required
          value={dados?.cidade}
          handleInputChange={async (e) => {
            handleChangeDados("cidade", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="Estado"
          name="estado"
          required
          value={dados?.estado}
          handleInputChange={async (e) => {
            handleChangeDados("estado", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="País"
          name="pais"
          required
          value={dados?.pais}
          handleInputChange={async (e) => {
            handleChangeDados("pais", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          label="E-mail"
          name="email"
          required
          value={dados?.email}
          handleInputChange={async (e) => {
            handleChangeDados("email", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="Telefone"
          name="telefone"
          required
          tipo="telefone"
          value={dados?.telefone}
          handleInputChange={async (e) => {
            handleChangeDados("telefone", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          label="Celular"
          name="celular"
          tipo="celular"
          value={dados?.celular}
          handleInputChange={async (e) => {
            handleChangeDados("celular", e.target.value);
          }}
          disabled={locationState?.details}
        />
      </Grid>

      <Grid item xs={12}>
        <span className="required-text">* Campos obrigatórios</span>
      </Grid>
    </Grid>
  );
}
