import React, { useCallback, useEffect, useState } from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ButtonComponent from "../ButtonComponent";
import NumberFormat from "react-number-format";
import InputComponent from "../Input";
import SelectComponent from "../SelectComponent";
import DatePicker from "../DatePicker";

import empresasService from "../../services/empresasService";
import fornecedoresService from "../../services/fornecedoresService";
import recebimentosServices from "../../services/recebimentosService";

import "./styles.scss";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import { currencyToNumber } from "../../utils/functions";
import pagamentosService from "../../services/pagamentosService";
import CallbackMessage from "../CallbackMessage";
import clientesService from "../../services/clientesService";
import { onlyUnsignedNumbers } from "../../utils/strings";

function ModalContas(props) {
  const recebimento = "Recebimento";
  const pagamento = "Pagamento";
  const {
    tipoRecebimentoPagamento = recebimento,
    dataModal = {},
    cbVerFatura = () => {},
    open = false,
    onClose = () => {},
  } = props;

  const isRecebimento =
    tipoRecebimentoPagamento.toLowerCase().trim() === "recebimento";

  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(1500);

  const [valor, setValor] = useState("");
  const [centroId, setCentroId] = useState("");
  const [empresaId, setEmpresaId] = useState("");
  const [dataAgendamento, setDataAgendamento] = useState(null);
  const [dataEmissao, setDataEmissao] = useState(null);
  const [dataRecebimento, setDataRecebimento] = useState(null);

  const [listEmpresas, setListEmpresas] = useState([]);
  const [clienteId, setClienteId] = useState("");
  const [categoriaId, setCategoriaId] = useState("");
  const [codigoBarras, setCodigoBarras] = useState(null);
  const [descricao, setDescricao] = useState("");
  const [listClientes, setListClientes] = useState([]);
  const [listCategorias, setListCategorias] = useState([]);

  const { selecionaFornecedores } = fornecedoresService;
  const { selectEmpresa } = empresasService;
  const { selecionaCategoriaRecebimento, atualizaRecebimento } =
    recebimentosServices;
  const { atualizaPagamento, selecionaCategoriaPagamento } = pagamentosService;
  const { selecionaCliente } = clientesService;

  const loadEmpresas = useCallback(async () => {
    const response = await selectEmpresa();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return [];
    }
    if (response.status === 200) {
      setListEmpresas(response.data);
      return response.data;
    }
    return [];
  }, [selectEmpresa]);

  const loadFornecedores = useCallback(
    async (id) => {
      let response;
      if (isRecebimento) {
        response = await selecionaCliente(id);
      } else {
        response = await selecionaFornecedores(id);
      }

      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return [];
      }
      if (response.status === 200) {
        setListClientes(response.data);
        return response.data;
      }
      return [];
    },
    [selecionaFornecedores]
  );

  const loadCategorias = useCallback(
    async (id) => {
      let response;
      if (isRecebimento) {
        response = await selecionaCategoriaRecebimento(id);
      } else {
        response = await selecionaCategoriaPagamento(id);
      }
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return [];
      }
      if (response.status === 200) {
        setListCategorias(response.data);
        return response.data;
      }
      return [];
    },
    [selecionaCategoriaRecebimento, selecionaCategoriaPagamento]
  );

  useEffect(() => {
    async function loadInitialInfo() {
      const listaEmpresas = await loadEmpresas();
      console.log(listaEmpresas);
      if (listaEmpresas?.length > 0) {
        const idEmpresaTemp = listaEmpresas?.find(
          (item) => item.nome === dataModal?.nomeEmpresa
        )?.id;
        setEmpresaId(idEmpresaTemp ? idEmpresaTemp : null);
        const listaCategorias = await loadCategorias(idEmpresaTemp);
        if (listaCategorias?.length > 0) {
          const idCategoriaTemp = listaCategorias?.find(
            (item) => item.nome === dataModal?.categoria
          )?.id;
          console.log(idCategoriaTemp);
          setCategoriaId(idCategoriaTemp ? idCategoriaTemp : null);
        }
        const listaFornecedores = await loadFornecedores(idEmpresaTemp);
        console.log(listaFornecedores);
        if (listaFornecedores?.length > 0) {
          let idFornecedorTemp;
          if (isRecebimento) {
            idFornecedorTemp = listaFornecedores?.find(
              (item) => item.nome === dataModal?.nomeCliente
            )?.id;
          } else {
            idFornecedorTemp = listaFornecedores?.find(
              (item) => item.nome === dataModal?.nomeFornecedor
            )?.id;
          }
          console.log(idFornecedorTemp);
          setClienteId(idFornecedorTemp ? idFornecedorTemp : null);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    loadInitialInfo();
    setValor(Number(dataModal?.valorPrev));
    console.log(dataModal?.dataPrev);
    setDataAgendamento(dataModal?.dataPrev ? dataModal?.dataPrev : null);
    setDataEmissao(
      dataModal?.dataEmissaoPrev ? dataModal?.dataEmissaoPrev : null
    );
    setDescricao(dataModal?.descricao ? dataModal?.descricao : "");
    setDataRecebimento(
      dataModal?.dataRealizada ? dataModal?.dataRealizada : null
    );
    setCodigoBarras(dataModal?.codigoBarras ? dataModal?.codigoBarras : null);
  }, [dataModal]);

  async function handleSubmit() {
    setLoading(true);
    let response;
    if (isRecebimento) {
      response = await atualizaRecebimento({
        idRecebimento: dataModal?.idRecebimento,
        idEmpresa: empresaId,
        dataVencimento: dataAgendamento,
        idFornecedor: clienteId,
        idCategoria: categoriaId,
        descricao,
        valor: valor,
        dataRecebimento: dataRecebimento,
        dataEmissao: dataEmissao,
        codigoBarras,
      });
    } else {
      response = await atualizaPagamento({
        idPagamento: dataModal?.idPagamento,
        idEmpresa: empresaId,
        dataVencimento: dataAgendamento,
        idFornecedor: clienteId,
        idCategoria: categoriaId,
        descricao,
        valor: valor,
        dataRecebimento: dataRecebimento,
        dataEmissao: dataEmissao,
        codigoBarras,
      });
    }

    if (response.status === 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        `${isRecebimento ? "Recebimento" : "Pagamento"} salvo com sucesso!`
      );
      setCallbackDuration(1000);
      return;
    }
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    } else {
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackMessage(["Ocorreu um erro"]);
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
    }
  }

  function handleClose(event, reason) {
    setCallbackShown(false);
    if (callbackErrorList.length === 0) {
      handleCloseModal(true);
    }
    setCallbackErrorList([]);
  }

  const handleCloseModal = async (success = false) => {
    onClose(success);
  };

  return (
    <Dialog open={open} onClose={() => handleCloseModal()}>
      {loading && <OverlayLoading />}
      <div className="modal-title-area">
        <DialogTitle>
          <span className="modal-title">
            {isRecebimento
              ? recebimento.toUpperCase()
              : pagamento.toUpperCase()}
          </span>
        </DialogTitle>
        <IconButton
          onClick={() => handleCloseModal()}
          size="medium"
          style={{ marginRight: 16, color: "#EF4066", padding: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="modal-content">
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} md={6}>
            <SelectComponent
              styleType="form"
              label="Empresa"
              title="Selecione uma empresa"
              list={listEmpresas}
              initialValue={
                listEmpresas.find((item) => item.id === empresaId)
                  ? listEmpresas.find((item) => item.id === empresaId).nome
                  : ""
              }
              required
              callback={async (id) => {
                setEmpresaId(id);
                setLoading(true);
                await loadCategorias(id);
                await loadFornecedores(id);
                setLoading(false);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectComponent
              styleType="form"
              label={isRecebimento ? "Cliente" : "Fornecedor"}
              required
              title={`Selecione um ${isRecebimento ? "Cliente" : "Fornecedor"}`}
              list={listClientes}
              callback={(id) => setClienteId(id)}
              initialValue={
                listClientes.find((item) => item.id === clienteId)
                  ? listClientes.find((item) => item.id === clienteId).nome
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="Data de emissão"
              required
              value={dataEmissao}
              initDate={dataModal?.dataEmissaoPrev || ""}
              handleChange={(date) => {
                setDataEmissao(date);
              }}
            ></DatePicker>
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="Data de agendamento"
              required
              value={dataAgendamento}
              initDate={dataModal?.dataPrev}
              handleChange={(date) => {
                setDataAgendamento(date);
              }}
            ></DatePicker>
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectComponent
              styleType="form"
              label="Categoria"
              required
              title="Selecione uma categoria"
              list={listCategorias}
              callback={(id) => setCategoriaId(id)}
              initialValue={
                listCategorias.find((item) => item.id === categoriaId)
                  ? listCategorias.find((item) => item.id === categoriaId).nome
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputComponent
              tipo="dinheiro"
              required
              label="Valor"
              value={valor}
              handleInputChange={(event) => setValor(event.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputComponent
              label="Código de barras"
              value={codigoBarras}
              tipo={
                codigoBarras?.length > 47 ? "boleto-concessionaria" : "boleto"
              }
              handleInputChange={(event) => {
                setCodigoBarras(onlyUnsignedNumbers(event.target.value));
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <InputComponent
              required
              label="Descrição"
              value={descricao}
              handleInputChange={(event) => setDescricao(event.target.value)}
            />
          </Grid>
          {dataModal.conta && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item className="modal-items">
                <label>Conta</label>
                <span>{dataModal.conta}</span>
              </Grid>
              <Grid item className="modal-items items-right">
                <label>
                  {isRecebimento
                    ? "Valor do recebimento"
                    : "Valor do pagamento"}
                </label>
                <span>{dataModal.valorRealizado}</span>
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  label={`Data do ${
                    isRecebimento ? "recebimento" : "pagamento"
                  }`}
                  required
                  value={dataRecebimento}
                  initDate={dataModal?.dataRealizada}
                  handleChange={(date) => {
                    setDataRecebimento(date);
                  }}
                ></DatePicker>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <ButtonComponent
              text="Salvar"
              className="btn-primary button-flex-end"
              onClick={() => handleSubmit(0)}
            />
          </Grid>
        </Grid>
        {isRecebimento &&
          dataModal.faturaId !== null &&
          dataModal.boletoId !== null && (
            <div className="modal-verFatura">
              <Grid>
                <ButtonComponent
                  text="Veja a sua fatura"
                  className="btn-verFatura"
                  onClick={() =>
                    cbVerFatura(dataModal.faturaId, dataModal.boletoId)
                  }
                />
              </Grid>
            </div>
          )}
      </DialogContent>
      {callbackShown && (
        <CallbackMessage
          open={callbackShown}
          type={callbackType}
          handleClose={handleClose}
          message={callbackMessage}
          duration={callbackDuration}
          errorList={callbackErrorList}
        />
      )}
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}

export default ModalContas;
