import React from 'react';

function PaginaNaoEncontrada() {
    return (
        <div>
            <h1 style={{ marginBottom: '0.5em' }}>Erro 404</h1>
            <p>A página que você procura não foi encontrada.</p>
        </div>
    );
}

export default PaginaNaoEncontrada;