import { Grid } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Button from "../../../components/ButtonComponent";
import CallbackMessage from "../../../components/CallbackMessage";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import OverlayLoading from "../../../components/OverlayLoading";
import Select from "../../../components/SelectComponent";
import TableComponent from "../../../components/TableResponsiveComponent";
import preAdmissaoService from "../../../services/dpPreAdmissaoService";
import exportData from "../../../services/exportData";
import { useHistory } from "react-router-dom";
import { ModalUploadDocumento } from "../../../components/ModalUploadDocumento";
import { ModalListaDocumento } from "../../../components/ModalListaDocumento";
import { AuthContext } from "../../../contexts/AuthContext";
import { useIsMount } from "../../../hooks/useIsMount";
import useCompany from "../../../hooks/useCompany";

const statusList = [
  { id: 0, nome: "Em aberto" },
  { id: 1, nome: "Concluído" },
];

const exportToPDF = {
  url: "exportar-relatorio-dp-pre-admissao-pdf",
  fileName: "cbhub_relatorio_pre_admissao",
  fileType: "pdf",
};

const exportToXLS = {
  url: "exportar-relatorio-dp-pre-admissao-xls",
  fileName: "cbhub_relatorio_pre_admissao",
  fileType: "xlsx",
};

function filterComponent({
  empresaSelecionada,
  statusSelecionado,
  setEmpresaSelecionada,
  setStatusSelecionado,
  handleApplyFilters,
  handleCleanFilters,
  listEmpresas,
}) {
  return (
    <div className="session-container filter-component-container chat">
      <Grid container spacing={2} alignItems="center" className="form-table">
        <Grid item xs={12}>
          <Select
            styleType="form"
            label="Empresa"
            required
            initialValue={
              empresaSelecionada !== undefined
                ? listEmpresas.find((item) => item.id === empresaSelecionada)
                    ?.nome
                : ""
            }
            title="Selecione uma empresa"
            list={listEmpresas}
            callback={(id) => setEmpresaSelecionada(id)}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            styleType="form"
            label="Status"
            // required
            initialValue={
              statusSelecionado !== undefined
                ? statusList.find((item) => item.id === statusSelecionado)?.nome
                : ""
            }
            title="Selecione um status"
            list={statusList}
            callback={(id) => setStatusSelecionado(id)}
          />
        </Grid>
        <Grid className="marginLeftAuto">
          <div className="filter-button-area align-rigth">
            <Button
              onClick={handleApplyFilters}
              text="Filtrar"
              className="btn-primary"
            />
            <Button
              onClick={handleCleanFilters}
              text="Limpar Filtros"
              className="default-outline clean-filters-button"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export function ListaPreAdmissoes() {
  const history = useHistory();
  const { isAdmin } = useContext(AuthContext);

  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("pre_admissao");
  const isMount = useIsMount();

  const {
    getPreAdmissoes,
    removePreAdmissao,
    confirmarContratacaoPreAdmissao,
    uploadArquivo,
  } = preAdmissaoService;

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [loading, setLoading] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(4000);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_funcionario" },
      { columnName: "Empresa", dataRef: "nome_empresa" },
      { columnName: "CPF do  Funcionário", dataRef: "cpf" },
      { columnName: "Nome", dataRef: "nome" },
      { columnName: "Data cadastro", dataRef: "created_at" },
      { columnName: "Status", dataRef: "status" },
    ],
    options: {
      edit: true,
      trash: isAdmin,
      add: isAdmin,
      more: [
        { icon: "plus-circle", label: "Anexar documentos", action: "anexar" },
        { icon: "download", label: "Documentos anexados", action: "anexados" },
        { icon: "file-search", label: "Link externo", action: "link" },
      ],
    },
    text: {
      edit: "Editar",
      addOutline: "Confirmar contratação",
      addFill: "Contratado",
      trash: "Excluir",
      more: "Link externo, anexar ou visualizar documentos",
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      statusLabels: true,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-xls",
        },
        {
          icon: "export",
          label: "Exportar PDF",
          action: "exportar-pdf",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: "id_funcionario",
    orderByType: "desc",
    perPage: null,
    searchTerm: null,
    id_empresa: null,
    status: null,
  });

  const [listRequest, setListRequest] = useState([]);

  const [statusSelecionado, setStatusSelecionado] = useState(null);

  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [confirmContratacaoDialog, setConfirmContratacaoDialog] =
    useState(false);
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [showModalListaUploads, setShowModalListaUploads] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await getPreAdmissoes({
      page: defaultConfigTable.currentPage,
      orderBy: defaultConfigTable.orderBy,
      orderByType: defaultConfigTable.orderByType,
      perPage: defaultConfigTable.perPage,
      searchTerm: defaultConfigTable.searchTerm,
      id_empresa: defaultConfigTable.id_empresa,
      efetivado: defaultConfigTable.status,
    });
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const resultData = result.data;

    if (resultData.hasOwnProperty("data")) {
      const { data } = resultData;
      const newData = data.map((item) => {
        return {
          ...item,
          status: item.status_pre_admissao,
        };
      });
      const newResult = {
        ...resultData,
        data: newData,
      };
      setListRequest(newResult);
    } else {
      const newData = resultData.map((item) => {
        return {
          ...item,
          status: item.status_pre_admissao,
        };
      });
      const newResult = {
        ...resultData,
        data: newData,
      };
      setListRequest(newResult);
    }

    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
    defaultConfigTable.status,
  ]);

  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      return setDefaultConfigTable({
        ...defaultConfigTable,
        currentPage: 1,
        id_empresa: empresaStorage,
      });
    }
    setDefaultConfigTable({
      ...defaultConfigTable,
      currentPage: 1,
      id_empresa: empresaSelecionada,
      status: statusSelecionado,
    });
    setEmpresaPagina("pre_admissao", empresaSelecionada);
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setStatusSelecionado(null);
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
      status: null,
    });
    setEmpresaPagina("pre_admissao", null);
  };

  const onTableMoreAction = async ({ action }) => {
    console.log(action);

    let params = {};
    if (action === "exportar-xls") {
      params = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: {
          search_term: defaultConfigTable.searchTerm,
          id_empresa: defaultConfigTable.id_empresa,
          efetivado: defaultConfigTable.status,
        },
      };
    } else if (action === "exportar-pdf") {
      params = {
        url: exportToPDF.url,
        fileName: exportToPDF.fileName,
        fileType: exportToPDF.fileType,
        params: {
          search_term: defaultConfigTable.searchTerm,
          id_empresa: defaultConfigTable.id_empresa,
          efetivado: defaultConfigTable.status,
        },
      };
    }

    setLoading(true);
    const response = await exportData(params);
    console.log(response);

    if (response.status === 500) {
      setCallbackErrorList([
        "Erro interno no servidor. Por favor, contate o suporte",
      ]);
      setCallbackShown(true);
      setCallbackType("error");
    } else if (response.status !== 201 && response.status !== 200) {
      setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
      setCallbackShown(true);
      setCallbackType("error");
    }

    setLoading(false);
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  const handleRemoveDialog = (id) => {
    setCurrentId(id);
    setConfirmRemoveDialog(true);
  };

  const handleRemoveCancel = () => {
    setCurrentId(null);
    setConfirmRemoveDialog(false);
  };

  const handleContratacaoDialog = (id) => {
    setCurrentId(id);
    setConfirmContratacaoDialog(true);
  };

  const handleContratacaoCancel = () => {
    setCurrentId(null);
    setConfirmContratacaoDialog(false);
  };

  const handleModalUpload = (id) => {
    setCurrentId(id);
    setShowModalUpload(true);
  };

  const handleModalUploadCancel = () => {
    setCurrentId(null);
    setShowModalUpload(false);
  };

  const handleModalListaUploads = (id) => {
    setCurrentId(id);
    setShowModalListaUploads(true);
  };

  const handleModalListaUploadsCancel = () => {
    setCurrentId(null);
    setShowModalListaUploads(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const response = await removePreAdmissao(currentId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 201) {
      setLoading(false);
      handleRemoveCancel();
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        response?.data?.mensagem || "Pré-admissão excluída com sucesso!"
      );
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
      return;
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage(response?.data?.msg || "Erro!");
      console.log(response.data);
      if (response?.data?.error && Array.isArray(response?.data?.error)) {
        let errorTemp = [];
        response?.data?.error?.map((item) => {
          errorTemp.push(...Object.values(item));
        });
        setCallbackErrorList(errorTemp);
      } else if (typeof response.data.error === "string") {
        setCallbackErrorList([response.data.error]);
      } else if (
        response?.data?.error &&
        !Array.isArray(response?.data?.error)
      ) {
        setCallbackErrorList(Object.values(response.data.error));
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
      handleRemoveCancel();
    }
    handleRemoveCancel();
  };

  const onContratacao = async () => {
    setLoading(true);
    const response = await confirmarContratacaoPreAdmissao(currentId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 201) {
      setLoading(false);
      handleContratacaoCancel();
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        response?.data?.mensagem || "Funcionário contratado com sucesso!"
      );
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
      return;
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage(response?.data?.msg || "Erro!");

      if (response?.data?.error && Array.isArray(response?.data?.error)) {
        let errorTemp = [];
        response?.data?.error?.map((item) => {
          errorTemp.push(...Object.values(item));
        });
        setCallbackErrorList(errorTemp);
      } else if (typeof response.data.error === "string") {
        setCallbackErrorList([response.data.error]);
      } else if (
        response?.data?.error &&
        !Array.isArray(response?.data?.error)
      ) {
        setCallbackErrorList(Object.values(response.data.error));
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
      handleContratacaoCancel();
    }
    handleContratacaoCancel();
  };

  const onUpload = async (files = []) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("id_funcionario", currentId);
    formData.append("tipo_doc", "pre_admissao");

    files?.forEach((item, index) => {
      formData.append(`documentos[${index}][nome]`, item.nome);
      formData.append(`documentos[${index}][arquivo]`, item.arquivo);
    });

    const response = await uploadArquivo(formData);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) {
      setLoading(false);
      handleModalUploadCancel();
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackMessage(
        response?.data?.mensagem || "Upload realizado com sucesso!"
      );
      let newDefaultConfigTable = { ...defaultConfigTable };
      if (
        newDefaultConfigTable.currentPage > 1 &&
        listRequest?.data?.length === 1
      ) {
        newDefaultConfigTable.currentPage =
          newDefaultConfigTable.currentPage - 1;
        setDefaultConfigTable(newDefaultConfigTable);
      } else {
        updateTable();
      }
      return;
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage(response?.data?.msg || "Erro!");
      console.log(response?.data);
      if (response?.data?.error && Array.isArray(response?.data?.error)) {
        let errorTemp = [];
        response?.data?.error?.map((item) => {
          errorTemp.push(...Object.values(item));
        });
        setCallbackErrorList(errorTemp);
      } else if (typeof response.data.error === "string") {
        setCallbackErrorList([response.data.error]);
      } else if (
        response?.data?.error &&
        !Array.isArray(response?.data?.error)
      ) {
        setCallbackErrorList(Object.values(response.data.error));
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
      handleModalUploadCancel();
    }
    handleModalUploadCancel();
  };

  const onEdit = (id) => {
    history.push(`/editar-pre-admissao/${id}`);
  };

  let onMoreAction = async (action) => {
    console.log(action);

    switch (action.action) {
      case "anexar":
        handleModalUpload(action.id);
        break;
      case "anexados":
        handleModalListaUploads(action.id);
        break;
      case "link":
        const link = listRequest.data?.find(
          (item) => item.id_funcionario === action.id
        )?.link_externo;
        if (link) {
          window.open(link, "_blank");
        }
        break;
      default:
        break;
    }
  };

  const onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onOrderBy = (newOrderBy) => {
    let orderBy =
      newOrderBy.orderBy === "status" ? "efetivado" : newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("pre_admissao");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  return (
    <>
      {loading && <OverlayLoading />}

      <TableComponent
        idName="id_funcionario"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onEdit}
        cbDelete={handleRemoveDialog}
        cbTableMoreAction={onTableMoreAction}
        cbAdd={handleContratacaoDialog}
        cbMoreAction={onMoreAction}
        filterComponent={filterComponent({
          empresaSelecionada,
          setEmpresaSelecionada,
          handleApplyFilters,
          handleCleanFilters,
          setStatusSelecionado,
          statusSelecionado,
          listEmpresas,
        })}
        pageId="lista-pre-admissao"
      />

      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
        btnClass="btn-success"
        cancelClass="default-outline"
      />

      <ConfirmDialog
        open={confirmContratacaoDialog}
        onCancel={handleContratacaoCancel}
        onAccept={onContratacao}
        btnClass="btn-success"
        cancelClass="default-outline"
        acceptLabel={"Confirmar"}
        description="Você tem certeza que deseja confirmar a contratação?"
        title="CONFIRMAR CONTRATAÇÃO"
      />

      <ModalUploadDocumento
        open={showModalUpload}
        onCancel={handleModalUploadCancel}
        onAccept={onUpload}
        btnClass="btn-success"
        cancelClass="default-outline"
        acceptLabel={"Salvar"}
        description=""
        title="ANEXAR DOCUMENTOS"
      />

      <ModalListaDocumento
        id={currentId}
        open={showModalListaUploads}
        description=""
        title="DOCUMENTOS ANEXADOS"
        onClose={handleModalListaUploadsCancel}
        tipo="pre_admissao"
      />

      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={handleClose}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}
