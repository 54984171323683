import moment from 'moment';

export const DD_MM_YYYY = 'DD/MM/YYYY';
export const YYYY_MM_DD = 'YYYY-MM-DD';

export const dd_MM_yyyy = 'dd/MM/yyyy';
export const yyyy_MM_dd = 'yyyy-MM-DD';
export const yyyy_MM_dd_HH_mm = 'yyyy-MM-DD HH:mm:ss';

export const DD_MM_YYYY_HOURS = 'DD/MM/YYYY - HH:mm:ss';
export const DD_MM_YYYY_HH_mm_ss = 'DD/MM/YYYY HH:mm:ss';

export const toDateYYYY_MM_DD = (value) => {
  return moment(value, DD_MM_YYYY).format(YYYY_MM_DD);
};
export const toDateDD_MM_YYYY = (value) => {
  return moment(value, YYYY_MM_DD).format(DD_MM_YYYY);
};
export const toDateDD_MM_YYYYandHours = (value) => {
  return moment(value, YYYY_MM_DD).format(DD_MM_YYYY_HOURS);
};
export const dateToDateDD_MM_YYYYandHours = (value) => {
  return moment(value, 'YYYY-MM-DD HH:mm:ss').format(DD_MM_YYYY_HOURS);
};
export const isodateToDateDD_MM_YYYYandHours = (value) => {
  return moment(value).utc(-3).format(DD_MM_YYYY_HOURS);
};
export const fromStringYYYY_MM_DDtoDate = (value) => {
  return moment(value, YYYY_MM_DD);
};
export const fromStringDD_MM_YYYYtoDate = (value) => {
  return moment(value, DD_MM_YYYY);
};

export const toDateYYYY_MM_dd_HH_mm = (value) => {
  return moment(value).format(yyyy_MM_dd_HH_mm);
};

export const toDateDD_MM_YYYY_HH_mm = (value) => {
  return moment(value, yyyy_MM_dd_HH_mm).format(DD_MM_YYYY_HH_mm_ss);
};

export const isodateToDateYYYY_MM_DDandHours = (value) => {
  return moment(value).utc(-3).format(yyyy_MM_dd);
};

export const formatDate = (date) => {
  if (date !== null && date !== undefined && date !== '') {
    const splittedDate = date.split('-');
    const newDate = `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`;
    return newDate;
  }
};

export const unformatDate = (date) => {
  if (date !== null && date !== undefined && date !== '') {
    const splittedDate = date.split('/');
    const newDate = `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
    return newDate;
  }
};

export const isValidDateMomentDD_MM_YYYY = (date) => {
  return moment(date, DD_MM_YYYY, true).isValid();
};
export const isValidDateMomentYYYY_MM_DD = (date) => {
  return moment(date, YYYY_MM_DD, true).isValid();
};

export const formatDateInObjectToYYYY_MM_DD = (_object, fieldNameString) => {
  return _object.map((item) => {
    const temp = Object.assign({}, item);
    temp[fieldNameString] = toDateYYYY_MM_DD(temp[fieldNameString]);
    return temp;
  });
};
export const formatDateInObjectToDD_MM_YYYY = (_object, fieldNameString) => {
  return _object.map((item) => {
    const temp = Object.assign({}, item);
    temp[fieldNameString] = toDateDD_MM_YYYY(temp[fieldNameString]);
    return temp;
  });
};

export const addMonthsMoment = (date, monthsInt) => {
  const mDate = moment(date);
  if (mDate.isValid()) {
    var fm = moment(mDate).add(monthsInt, 'M');
    date = fm;
  }
  return date;
};
