import Axios from "./Axios";
const notificationService = {
  getLista: async (id_empresa) => {
    const result = await Axios.get("/lista-notificacao", {
      body: {
        id_empresa
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  lerNotificacao: async (chave) => {
    const result = await Axios.get(`/ler-notificacao/${chave}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  }
}

export default notificationService;