import React, { useState } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";

import Alert from "./components/Alert";
import Routes from "./routes/routes";
import { AuthProvider } from "./contexts/AuthContext";
import { NavbarProvider } from "./contexts/NavbarContext";

import "./assets/css/global.scss";
import { CompanyProvider } from "./contexts/CompanyContext";

if (ptBR && ptBR.options) {
  ptBR.options.weekStartsOn = 1;
}

function App() {
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [autoHide, setAutoHide] = useState(2000);
  const [showAlert, setShowAlert] = useState(false);

  function callbackAlert(status, message, autoHide = 2000) {
    setStatus(status);
    setMessage(message);
    setAutoHide(autoHide);
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, autoHide);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <div className="App">
        {showAlert && (
          <Alert
            status={status}
            message={message}
            autoHideDuration={autoHide}
          />
        )}
        <NavbarProvider>
          <AuthProvider>
            <CompanyProvider>
              <Routes callbackAlert={callbackAlert} />
            </CompanyProvider>
          </AuthProvider>
        </NavbarProvider>
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default App;
