import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import "./styles.scss";
import { currencyToNumber } from "../../../utils/functions";
import { unformatDate } from "../../../utils/dates";
import InputComponent from "../../../components/Input";
import SwitchComponent from "../../../components/Switch";
import Parcelamentos from "../../../components/Parcelamentos";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import DatePicker from "../../../components/DatePicker";
import SelectComponent from "../../../components/SelectComponent";
import empresasService from "../../../services/empresasService";
import centroCustosService from "../../../services/centroCustosService";
import pagamentosServices from "../../../services/pagamentosService";
import fornecedoresService from "../../../services/fornecedoresService";
import FileInput from "../../../components/InputFile";
import ButtonComponent from "../../../components/ButtonComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";

import "./styles.scss";
import SmallScreen from "../../../utils/smallScreen";
import useCompany from "../../../hooks/useCompany";
import { onlyUnsignedNumbers } from "../../../utils/strings";

function CadastroPagamentos() {
  const isSmallScreen = SmallScreen();

  const {
    companyList: listEmpresas,
    selectedCompany: empresaId,
    setSelectedCompany: setEmpresaId,
    getEmpresaPagina,
  } = useCompany("pagamentos");

  const [file, setFile] = useState(null);
  const [vencimento, setVencimento] = useState("");
  const [emissao, setEmissao] = useState("");
  const [valor, setValor] = useState("");
  const [codigoBarras, setCodigoBarras] = useState(null);
  const [numeroNota, setNumeroNota] = useState("");
  const [centroId, setCentroId] = useState("");
  const [listCentroCusto, setListCentroCusto] = useState([]);
  const [listFornecedores, setListFornecedores] = useState([]);
  const [fornecedorId, setFornecedorId] = useState("");
  const [categoriaId, setCategoriaId] = useState("");
  const [listCategorias, setListCategorias] = useState([]);
  const [descricao, setDescricao] = useState("");
  const [parcelamentos, setParcelamentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showParcela, setShowParcela] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errors, setErrors] = useState([]);
  const history = useHistory();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  async function loadCentroCustos(id) {
    let response = await centroCustosService.selecionaCentroCusto(id);
    if (response.status === 200) setListCentroCusto(response.data);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
  }

  async function loadCategorias(id) {
    let response = await pagamentosServices.selecionaCategoriaPagamento(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) setListCategorias(response.data);
  }

  async function loadFornecedores(id) {
    let response = await fornecedoresService.selecionaFornecedores(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) setListFornecedores(response.data);
  }

  function validateFields() {
    let list = [];
    if (empresaId === "") list.push('O campo "Empresa" deve ser selecionada.');
    if (vencimento === "")
      list.push('O campo "Vencimento" deve ser preenchido.');
    if (valor === "") list.push('O campo "Valor" deve ser preenchido.');
    if (fornecedorId === "")
      list.push('O campo "Fornecedor" deve ser selecionado.');
    if (categoriaId === "")
      list.push('O campo "Categoria" deve ser selecionado.');
    if (descricao === "") list.push('O campo "Descrição" deve ser preenchido.');

    if (list.length > 0) setShowMessage(true);

    setErrors(list);

    return list.length === 0;
  }

  function handleClose(event, reason) {
    setShowMessage(false);
    if (errors.length === 0) {
      history.push("/pagamentos-cadastrados");
    }
  }

  async function handleSubmit() {
    if (!validateFields()) return;
    let p = parcelamentos.map((item, index) => {
      item.numero = parcelamentos.length;
      item.valor = Number(item.valor);
      return item;
    });
    window.scrollTo(0, 0);
    setLoading(true);
    await pagamentosServices
      .cadastraPagamento(
        empresaId,
        file,
        descricao,
        categoriaId,
        vencimento,
        Number(valor),
        fornecedorId,
        p,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        numeroNota,
        emissao || null,
        codigoBarras
      )
      .then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        setErrors([]);
        if (response.status !== 200)
          if (response.data.error)
            setErrors(Object.values(response.data.error));
          else setErrors(["Ocorreu um erro"]);
        console.log("response", response.data);
        setLoading(false);
        setShowMessage(true);
      });
  }

  async function handleChangeEmpresa(id) {
    setEmpresaId(id);
    setLoading(true);
    await loadCentroCustos(id);
    await loadCategorias(id);
    await loadFornecedores(id);
    setLoading(false);
  }

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("pagamentos");
    if (empresaStorage) {
      handleChangeEmpresa(empresaStorage);
    }
  }, []);

  return (
    <>
      <div className="session-container">
        <span className="session-container-header">
          <form className="session-container-form">
            {loading && <OverlayLoading />}
            <div className={`${isSmallScreen ? "" : "p-horizontal"}`}>
              <Grid item container direction="column" spacing={2}>
                <Grid item>
                  <SelectComponent
                    styleType="form"
                    label="Empresa"
                    title="Selecione uma empresa"
                    list={listEmpresas}
                    required
                    initialValue={
                      listEmpresas?.find((item) => item.id === empresaId)
                        ?.nome || null
                    }
                    callback={async (id) => {
                      handleChangeEmpresa(id);
                    }}
                  />
                </Grid>
                <Grid item>
                  <SelectComponent
                    styleType="form"
                    label="Fornecedor"
                    required
                    title="Selecione um Fornecedor"
                    list={listFornecedores}
                    callback={(id) => setFornecedorId(id)}
                  />
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs sm md={6}>
                    <SelectComponent
                      styleType="form"
                      label="Categoria"
                      required
                      title="Selecione uma categoria"
                      list={listCategorias}
                      callback={(id) => setCategoriaId(id)}
                    />
                  </Grid>
                  <Grid item xs sm md={6}>
                    <SelectComponent
                      styleType="form"
                      label="Centro de Custo"
                      title="Selecione uma opção"
                      list={listCentroCusto}
                      callback={(id) => setCentroId(id)}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <DatePicker
                      label="Data de Emissão"
                      value={emissao}
                      initDate={""}
                      handleChange={(date) => {
                        setEmissao(date);
                      }}
                    ></DatePicker>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <DatePicker
                      label="Data de Vencimento *"
                      required
                      value={vencimento}
                      handleChange={(date) => {
                        setVencimento(date);
                      }}
                    ></DatePicker>
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputComponent
                      tipo="dinheiro"
                      required
                      label="Valor"
                      value={valor}
                      handleInputChange={(event) => {
                        setValor(event.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputComponent
                      label="Número da nota"
                      value={numeroNota}
                      handleInputChange={(event) => {
                        setNumeroNota(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <InputComponent
                      label="Código de barras"
                      value={codigoBarras}
                      tipo={
                        codigoBarras?.length > 47
                          ? "boleto-concessionaria"
                          : "boleto"
                      }
                      handleInputChange={(event) => {
                        setCodigoBarras(
                          onlyUnsignedNumbers(event.target.value)
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <span className="label">Descrição *</span>
                  <textarea
                    className="large-input"
                    value={descricao}
                    onChange={(event) => setDescricao(event.target.value)}
                  />
                </Grid>
                <Grid item>
                  <FileInput
                    title="Anexo"
                    onChange={(value) => setFile(value)}
                  />
                </Grid>
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  style={{ height: 60 }}
                >
                  <Grid item>
                    <SwitchComponent
                      checked={showParcela}
                      onClick={() => setShowParcela(!showParcela)}
                    />
                  </Grid>
                  <Grid item>
                    <span className="title">Adicionar parcelas</span>
                  </Grid>
                </Grid>
                {showParcela && (
                  <Grid item>
                    <Parcelamentos
                      qtdTotalParcelas={2}
                      valorTotal={valor}
                      handleChange={(list) => {
                        console.log("list", list);
                        setParcelamentos(list);
                      }}
                    />
                  </Grid>
                )}
                <Grid item>
                  <span className="required-text">* Campos obrigatórios</span>
                </Grid>
              </Grid>
              <div style={{ height: 40 }} />
            </div>
            <CallbackMessage
              open={showMessage}
              type={`${errors.length === 0 ? "success" : "error"}`}
              message={`${
                errors.length === 0
                  ? "Pagamento cadastrado com sucesso!"
                  : "Erro!"
              }`}
              errorList={errors}
              handleClose={handleClose}
              duration={errors.length === 0 ? 2000 : 6000}
            />
          </form>
        </span>
      </div>
      <div style={{ height: 30 }} />
      <Grid item container justify="flex-end" spacing={4}>
        <Grid item>
          <ButtonComponent
            text="Salvar"
            className="btn-success"
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
      <div style={{ height: 30 }} />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastroPagamentos;
