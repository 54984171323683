import { Grid } from "@material-ui/core";
import SelectComponent from "../../SelectComponent";
import AutocompleteInput from "../../AutocompleteInput";

export function FilterAutocomplete({
  label,
  values,
  options,
  callback,
  placeholder = "Selecione uma empresa",
  page,
  ...rest
}) {
  return (
    <Grid item {...rest}>
      <AutocompleteInput
        label={label}
        placeholder={placeholder}
        data={options}
        initValue={values.map((item) => ({ id: item }))}
        handleSelectedValues={(values) => callback(values)}
        key={(option) => option.id}
        getOptionLabel={(option) => option.nome}
        getOptionSelected={(option, value) => option.id === value.id}
        page={page}
      />
    </Grid>
  );
}
