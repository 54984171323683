import React, { useState, useEffect, useCallback } from "react";

import TableComponent from "../../../../components/TableComponent";
import OverlayLoading from "../../../../components/OverlayLoading";

import permissoesService from "../../../../services/permissoesService";

import "./styles.css";

function PermissaoUsuario({ onSave = () => {}, userId }) {
  const { listarPermissoes } = permissoesService;

  const [loading, setLoading] = useState(false);
  const [permissoesList, setPermissoesList] = useState([]);

  const [listRequest, setListRequest] = useState([]);
  const [checkedPermissoesList, setCheckedPermissoesList] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_permissao" },
      { columnName: "Permissão", dataRef: "nome" },
      { columnName: "Opções", dataRef: "permissao" },
    ],
    display: {
      search: true,
      itemsPerPage: false,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      checkBoxOptions: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: 999,
    searchTerm: null,
  });

  let updateTable = useCallback(async () => {
    setLoading(true);
    const response = await listarPermissoes(
      userId,
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm
    );
    if (response.status !== 200 && response.status !== 201) {
      setLoading(false);
      return;
    }
    const responseData = response.data;

    if (responseData.hasOwnProperty("data")) {
      const { data } = responseData;
      console.log("permissoes :: ", response);
      const newData = data.map((item) => {
        return {
          ...item,
          permissao: item.possui_acesso === 1 ? true : false,
        };
      });
      const newResult = {
        ...responseData,
        data: newData,
      };

      console.log("newData :: ", newData);

      setListRequest(newResult);
    } else {
      const newData = responseData.map((item) => {
        return {
          ...item,
          permissao: item.possui_acesso === 1 ? true : false,
        };
      });
      const newResult = {
        ...responseData,
        data: newData,
      };
      setListRequest(newResult);
    }
    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    listarPermissoes,
    userId,
  ]);

  const loadPermissoesList = useCallback(async () => {
    const response = await listarPermissoes(userId, 1, null, null, 999, null);

    const permissoes = response?.data?.data?.map((item) => {
      return {
        id_permissao: item.id_permissao,
        permissao: item.possui_acesso === 1 ? true : false,
      };
    });

    setPermissoesList(permissoes);

    const checkedPermissoes = permissoes.map((item) => item.permissao);

    setCheckedPermissoesList(checkedPermissoes);
    initialCheck(permissoes);
  }, [listarPermissoes, userId]);

  useEffect(() => {
    updateTable();
    loadPermissoesList();
  }, [userId, loadPermissoesList, updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  let onOrderBy = useCallback(
    (newOrderBy) => {
      let orderBy = newOrderBy.orderBy;
      let orderByType = newOrderBy.orderByType;
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.orderBy = orderBy;
      newDefaultConfigTable.orderByType = orderByType;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onPageChange = useCallback(
    (newPageObj) => {
      let newPage = newPageObj.page;
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.currentPage = newPage;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onSearchTerm = useCallback(
    (value) => {
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.searchTerm = value ? value : null;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let onPerPage = useCallback(
    (newPerPage) => {
      let newDefaultConfigTable = defaultConfigTable;
      newDefaultConfigTable.perPage = newPerPage;
      setDefaultConfigTable(newDefaultConfigTable);
      updateTable();
    },
    [defaultConfigTable, updateTable]
  );

  let initialCheck = useCallback(
    (permissoesListTemp) => {
      (async () => {
        onSave(permissoesListTemp);
      })();
    },
    [setCheckedPermissoesList, permissoesList]
  );

  let onCheck = useCallback(
    (checkBoxObj) => {
      (async () => {
        let { id, checked } = JSON.parse(checkBoxObj);

        let permissoes = permissoesList;

        let index = permissoes.findIndex((item) => item?.id_permissao === id);

        permissoes[index].permissao = checked;

        setPermissoesList(permissoes);

        const checkedPermissoes = permissoes.map((item) => item.permissao);

        onSave(permissoes);

        setCheckedPermissoesList(checkedPermissoes);
      })();
    },
    [setCheckedPermissoesList, permissoesList]
  );

  let onMoreAction = useCallback(
    async (action) => {
      console.log(action);

      switch (action.action) {
        case "marcar-todos": {
          let permissoes = permissoesList;

          const checkedPermissoes = permissoes.map((item) => ({
            ...item,
            permissao: true,
          }));

          onSave(checkedPermissoes);

          const checkedPermissoesValues = checkedPermissoes.map(
            (item) => item.permissao
          );

          setPermissoesList(checkedPermissoes);

          setCheckedPermissoesList(checkedPermissoesValues);
          break;
        }
        case "desmarcar-todos": {
          let permissoes = permissoesList;

          const updatePermissoes = permissoes.map((item) => ({
            ...item,
            permissao: false,
          }));

          onSave(updatePermissoes);

          setPermissoesList(updatePermissoes);

          const noCheckedPermissoes = updatePermissoes.map(
            (item) => item.permissao
          );

          setCheckedPermissoesList(noCheckedPermissoes);
          break;
        }
        default:
          break;
      }
    },
    [permissoesList, setCheckedPermissoesList, setDefaultConfigTable]
  );

  const optionsCheckBox = [
    {
      icon: "check-double",
      label: "Marcar todos",
      action: "marcar-todos",
    },
    {
      icon: "close",
      label: "Desmarcar todos",
      action: "desmarcar-todos",
    },
  ];

  return (
    <>
      <TableComponent
        idName="id_permissao"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        checkList={checkedPermissoesList}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbCheckBox={onCheck}
        cbMoreAction={onMoreAction}
        optionsCheckBox={optionsCheckBox}
      />
      {loading && <OverlayLoading />}
    </>
  );
}

export default PermissaoUsuario;
