import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ButtonComponent from "../ButtonComponent";
import SelectComponent from "../SelectComponent";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import CallbackMessage from "../CallbackMessage";
import empresasService from "../../services/empresasService";

import "./styles.scss";

export function ModalSelecionaEmpresaHubcount({ open, onClose, onConfirm }) {
  const [empresa, setEmpresa] = useState(null);

  const [listEmpresas, setListEmpresas] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const loadEmpresas = async () => {
    const response = await empresasService.selectEmpresa();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const data = response.data;

    setListEmpresas(data);
  };

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  };

  const handleCloseModal = async () => {
    setLoading(false);
    onClose();
  };

  const handleSubmit = () => {
    onConfirm(empresa);
  };

  useEffect(() => {
    setLoading(true);
    loadEmpresas();
    setLoading(false);
  }, []);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={handleCloseModal}
      dialog
    >
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justifyContent="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>Selecione a empresa</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content content-modal-hubcount">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <div className="select-container">
                  <SelectComponent
                    styleType="form"
                    list={listEmpresas}
                    label="Empresa"
                    title="Selecione uma opção"
                    required
                    callback={(id) => setEmpresa(id)}
                  />
                </div>
              </Grid>
              <Grid container justifyContent="flex-end" item>
                <ButtonComponent
                  text="Confirmar"
                  onClick={handleSubmit}
                  className="btn-success zero-margin"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
