import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";

import Input from "../../../components/Input";
import ModalLoginExpirado from "../../../components/InputFile";
import Button from "../../../components/ButtonComponent";
import SelectComponent from "../../../components/SelectComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";

import iconHistory from "./img/icon-history.png";

import corretoresService from "../../../services/corretoresService";
import gestaoLeadsService from "../../../services/gestaoLeadsService";

import { Grid, Switch, withStyles } from "@material-ui/core";

import colors from "../../../assets/styles/_colors.scss";

import "./styles.scss";

function AtualizaLead() {
  const history = useHistory();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const { selecionaCorretor } = corretoresService;
  const { consultaLead, cadastraInteracao, atualizaLead } = gestaoLeadsService;

  const CustomSwitch = withStyles({
    switchBase: {
      color: colors.colorSecondary,
      "&$checked": {
        color: colors.colorSecondary
      },
      "&$checked + $track": {
        backgroundColor: colors.colorSecondary
      }
    },
    checked: {},
    track: {}
  })(Switch);

  const params = useParams();
  const { id } = params;

  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");

  const [portalOrigem, setPortalOrigem] = useState("");

  const [idCorretor, setIdCorretor] = useState("");
  const [idImovel, setIdImovel] = useState("");

  const [codImovel, setCodImovel] = useState("");
  const [nomeImovel, setNomeImovel] = useState("");

  const [propostaAtiva, setPropostaAtiva] = useState(false);
  const [finalizaInteracao, setFinalizaInteracao] = useState(false);

  const [interacoes, setInteracoes] = useState([]);

  const [mensagem, setMensagem] = useState("");

  const [loading, setLoading] = useState(false);

  const [listaCorretores, setListaCorretores] = useState([]);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const getLead = useCallback(async () => {
    const { data, status } = await consultaLead(id);
    if (status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (status === 200) {
      setName(data.nome_lead);
      setEmail(data.email);
      setTelefone(data.telefone);
      setCodImovel(data.codigo_imovel);
      setNomeImovel(data.nome_imove_site);
      setPortalOrigem(data.lead_origin);
      if (data.historicos) {
        if (data.historicos.length >= 10) {
          setInteracoes(data.historicos.slice(data.historicos.length - 10, data.historicos.length));
        } else {
          setInteracoes(data.historicos);
        }
      }
      setIdImovel(data.id_imovel);
      setPropostaAtiva(data.proposta_ativa === 1 ? true : false);
      setFinalizaInteracao(data.finalizado === 1 ? true : false);
      if (data.id_corretor) {
        setIdCorretor(data.id_corretor);
      }
    }
  }, [consultaLead, id]);

  const getListaCorretores = useCallback(async () => {
    const { data, status } = await selecionaCorretor();
    if (status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    const mockCorretores = [
      { id: 1, nome: "Corretor 1" },
      { id: 2, nome: "Corretor 2" }
    ];
    setListaCorretores(data.length > 0 ? data : mockCorretores);
  }, [selecionaCorretor]);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await getLead();
      await getListaCorretores();
      setLoading(false);
    })();
  }, [getLead, getListaCorretores]);

  const formData = {
    nome: name,
    telefone,
    email,
    lead_origin: portalOrigem,
    client_listing_id: codImovel,
    id_corretor: idCorretor,
    proposta_ativa: propostaAtiva ? 1 : 0,
    finalizado: finalizaInteracao ? 1 : 0
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  async function handleSubmit() {
    if (!validateFields()) return;
    setLoading(true);
    if (callbackErrorList.length === 0) {
      const response = await atualizaLead(id, formData);
      if (response.status === 401) {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      }
      if (response.status !== 200) {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        if (response.data.error)
          setCallbackErrorList(Object.values(response.data.error).flat(Infinity));
        else setCallbackErrorList(["Ocorreu um erro"]);
        setLoading(false);
        setCallbackDuration(2000);
        return;
      } else {
        setLoading(false);
        setCallbackType("success");
        setCallbackShown(true);
        setCallbackMessage("Lead atualizado com sucesso.");
        setCallbackDuration(2000);
        setTimeout(() => {
          history.push("/lista-gestao-leads");
        }, 2000);
        return;
      }
    }
    setLoading(false);
  }

  function verifyField(field, callback) {
    if (field === "" || field === null || field === undefined) {
      callback();
    }
  }

  function validateFields() {
    let list = [];
    verifyField(name, () => list.push('O campo "Nome do Lead" é obrigatório'));
    verifyField(portalOrigem, () =>
      list.push('O campo "Portal de origem" é obrigatório')
    );
    verifyField(idCorretor, () =>
      list.push('O campo "Corretor" é obrigatório')
    );
    setCallbackErrorList(list);
    if (list.length > 0) {
      setCallbackType("error");
      setCallbackShown(true);
      setCallbackDuration(2000);
    }
    return list.length === 0;
  }

  async function handleSubmitInteracao() {
    setLoading(true);
    if (mensagem === "") {
      setLoading(false)
      setCallbackType("error");
      setCallbackShown(true);
      setCallbackDuration(2000);
      setCallbackErrorList(["O campo mensagem é obrigatório"]);
      return;
    } else {
      const response = await cadastraInteracao(id, mensagem, name);
      const { status } = response;
      if (response.status === 401) {
        setLoading(false);
        setOpenLoginExpirado(true);
        return;
      }
      if (status === 200 || status === 201) {
        setCallbackType("success");
        setCallbackShown(true);
        setCallbackMessage("Interação cadastrada com sucesso.");
        setCallbackDuration(2000);
        getLead();
      }
    }
    setLoading(false)
  }

  return (
    <>
      <div className="session-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <form className="cadastrar-preAdmissao-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  underline
                  required
                  label="Nome do Lead"
                  nome="nome"
                  value={name || ""}
                  handleInputChange={e => {
                    setName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  underline
                  label="E-mail"
                  nome="email"
                  tipo="email"
                  value={email || ""}
                  handleInputChange={e => {
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  underline
                  label="Telefone"
                  nome="telefone"
                  tipo="telefone"
                  value={telefone || ""}
                  handleInputChange={e => {
                    setTelefone(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  underline
                  label="Portal de origem"
                  required
                  value={portalOrigem || ""}
                  handleInputChange={e => {
                    setPortalOrigem(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  underline
                  label="Código do imóvel"
                  nome="cod_imovel"
                  value={codImovel || ""}
                  handleInputChange={e => {
                    setCodImovel(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  underline
                  label="Nome do imóvel no site"
                  nome="nome_imovel"
                  value={nomeImovel || ""}
                  handleInputChange={e => {
                    setNomeImovel(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectComponent
                  selectUnderline
                  styleType="form"
                  label="Corretor"
                  title="Selecione uma opção"
                  initialValue={
                    listaCorretores.find(item => item.id === idCorretor)
                      ? listaCorretores.find(item => item.id === idCorretor)
                          .nome
                      : ""
                  }
                  list={listaCorretores}
                  required
                  callback={id => setIdCorretor(id)}
                />
              </Grid>
              <Grid
                container
                justify="flex-start"
                className="proposta-container"
              >
                <Grid item>
                  <span>Proposta ativa?</span>
                </Grid>
                <Grid item>
                  <div className="switch-wrapper">
                    <CustomSwitch
                      size="small"
                      checked={propostaAtiva}
                      onClick={() => setPropostaAtiva(!propostaAtiva)}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            text="Salvar"
            disabled={loading}
            className="btn-success"
            onClick={handleSubmit}
          />
        </span>
      </div>
      <div className="session-container">
        <span className="session-container-header">
          <Grid container spacing={2} className="interacaoContainer">
            {!loading && (
              <Grid container className="titleHistoricoWrapper">
                <Grid item className="wrapperCenter">
                  <div style={{ marginRight: 10 }}>
                    <img
                      src={iconHistory}
                      alt="icon"
                      style={{ padding: 0, width: 25, height: 25 }}
                    />
                  </div>
                  <div>
                    <span>Histórico de interações</span>
                  </div>
                </Grid>
                <Grid item className="wrapperCenter">
                  <span>Finalizar interação?</span>
                  <div className="switch-wrapper">
                    <CustomSwitch
                      size="small"
                      checked={finalizaInteracao}
                      onClick={() => setFinalizaInteracao(!finalizaInteracao)}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            {interacoes &&
              interacoes.map(interacao => (
                <div
                  className="interacoesWrapper interacoes"
                  key={interacao.Codigo}
                >
                  <Grid
                    container
                    justify="flex-start"
                    style={{
                      flexDirection: "column",
                      padding: "20px 25px"
                    }}
                  >
                    <Grid container justify="space-between">
                      <div>
                        <Grid item>
                          <span className="interacoes-title">
                            Data da interação:
                          </span>
                        </Grid>
                        <Grid item>
                          <span className="interacoes-mensagem">
                            {moment(interacao.Data).format("L")}
                            {" - "}
                            {interacao.Hora}
                          </span>
                        </Grid>
                      </div>
                      {interacao.Por && (
                        <div style={{ paddingRight: 10 }}>
                          <Grid item>
                            <span className="interacoes-title">Por:</span>
                          </Grid>
                          <Grid item>
                            <span className="interacoes-mensagem">
                              {interacao.Por}
                            </span>
                          </Grid>
                        </div>
                      )}
                    </Grid>
                    <Grid item style={{ marginTop: 20 }}>
                      <span className="interacoes-title">Assunto:</span>
                    </Grid>
                    <Grid item>
                      <span className="interacoes-mensagem">
                        {interacao.Assunto}
                      </span>
                    </Grid>
                    {interacao.Texto && (
                      <>
                        <Grid item style={{ marginTop: 20 }}>
                          <span className="interacoes-title">Mensagem:</span>
                        </Grid>
                        <Grid item>
                          <span className="interacoes-mensagem">
                            {interacao.Texto}
                          </span>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </div>
              ))}
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ marginBottom: "1rem", marginTop: "1rem" }}
              >
                <span className="interacoes-title">Adicionar interação *</span>
                <textarea
                  className="large-input textarea-mensagem"
                  value={mensagem || ""}
                  placeholder="Insira aqui a mensagem que deseja enviar"
                  onChange={event => setMensagem(event.target.value)}
                />
              </Grid>
              <Grid container justify="flex-end" style={{ paddingBottom: 20 }}>
                <Grid item>
                  <Button
                    text="Enviar"
                    disabled={loading}
                    className="btn-success"
                    onClick={handleSubmitInteracao}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </span>
      </div>

      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />
      {openLoginExpirado && <ModalLoginExpirado open={openLoginExpirado} />}
    </>
  );
}

export default AtualizaLead;
