import React, { useEffect } from "react";
import { useState } from "react";
import Icon from "../../../../components/Icon";
import { numberToCurrency } from "../../../../utils/functions";
import uniqBy from "lodash.uniqby";

import "./styles.scss";

export default function CardPlano({ data, cbButton }) {

  const [filteredList, setFilteredList] = useState(uniqBy(data.servicos, 'id_servico'));
  const [showAll, setShowAll] = useState(false);


  useEffect(() => {  
    if (showAll) {
      setFilteredList(uniqBy(data.servicos, 'id_servico'));
    } else {
      const destaqueData = data.servicos.filter((item) => item.destaque === 1);
      setFilteredList(uniqBy(destaqueData, 'id_servico'));
    }
  }, [showAll])
  
  return (
    <div className="card-escolhe-plano-root">
      <div className="card-escolhe-plano">
        <div className="card-escolhe-plano-header">
          <div className="card-escolhe-plano-titleWrapper">
            <span className="escolhe-plano-title">{data.nome_plano}</span>
            <span className="escolhe-plano-description">
              {data.sub_titulo}
            </span>
          </div>
          <div className="escolhe-plano-valorWrapper">
            <div className="escolhe-plano-cifrao">
              <span>R$</span>
            </div>
            <span className="escolhe-plano-valorText">
              {numberToCurrency(data.valor_plano).replace("R$", "")}
            </span>
            <span className="escolhe-plano-mes">/ Mês</span>
          </div>
          <div className="escolhe-plano-buttonWrapper">
            <button onClick={() => cbButton(data.id_plano)}>QUERO ESTE</button>
          </div>
        </div>
        <hr />
        <div className="escolhe-plano-funcionalidades">
          {filteredList?.map(({ nome_servico }, index) => (
            <div key={index}>
              <div className="escolhe-plano-icone">
                <Icon name="check" color="#812990" />
              </div>
              <div className="escolhe-plano-funcionalidade">{nome_servico.replace("  ", "")}</div>
            </div>
          ))}
        </div>
        {filteredList.length >= 6 && (
          <div className="escolhe-plano-ver-todos" onClick={() => setShowAll(!showAll)}>
            <div>
              Todos os serviços
            </div>
            <Icon name={showAll ? "arrow-up" : "arrow-down"} />
          </div>
        )}
      </div>
    </div>
  );
}
