import Axios from "./Axios";

const folhaPagamentoService = {
  getEventosFolha: async ({
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null,
    status = null,
  }) => {
    const result = await Axios.get("/lista-dp-eventos-folha", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
        status,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  cadastrarEventoFolha: async (formData) => {
    const response = await Axios.post("/cadastra-dp-evento-folha", formData)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  atualizarEventoFolha: async (id, formData) => {
    const response = await Axios.post(`/atualiza-dp-evento-folha/${id}`, formData)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  listaFeriados: async (ano) => {
    const result = await Axios.get(`/lista-feriados-nacionais/${ano}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  consultaAgendamentoFerias: async (id) => {
    const response = await Axios.get(`/consulta-agendamento-ferias/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  confirmarFechamento: async (id) => {
    const response = await Axios.post(`/dp-fecha-folha/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  confirmarFechamentoPeriodo: async ({ dataInicio, dataFim, idEmpresa }) => {
    const response = await Axios.post(`/dp-fecha-folha`, {
      id_empresa: idEmpresa,
      data_inicio: dataInicio,
      data_fim: dataFim,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  consultaEventoFolha: async (id) => {
    const response = await Axios.get(`/consulta-dp-evento-folha/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
};
export default folhaPagamentoService;
