import Axios from './Axios';

const cadastroClientesService = {
  cadastrarCliente: async (form) => {
    const add = await Axios.post('/cadastra-cliente', {
      id_empresa: form.id_empresa,
      escolhapfpj: form.cnpj_cpf,
      observacao: form.observacao,

      'cliente-cnpj': form.cnpj,
      'cliente-cpf': form.cpf,
      'cliente-razao-social': form.razaoSocial,
      'cliente-nome-fantasia': form.nome_fantasia,
      'cliente-nome': form.razaoSocial,

      'cliente-rua': form.endereco,
      'cliente-numero': form.numEnd,
      'cliente-complemento': form.complemento,
      'cliente-bairro': form.bairro,
      'cliente-cep': form.cep,
      'cliente-cidade': form.cidade,
      'cliente-estado': form.estado,
      'cliente-site': form.site,

      'cliente-indicador-ie': form.cod_indicador_ie,
      'clienteuni-insc-mcipal': form.inscricao_municipal,
      'cliente-insc-estadual': form.inscricao_estadual,

      contato: form.contato,
      id_categoria_padrao: form.id_categoria_padrao
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return add;
  },
  consultaCliente: async (id) => {
    const result = await Axios.get(`/consulta-cliente/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return result;
  },
  atualizaCliente: async (id, form) => {
    const update = await Axios.post(`/atualiza-cliente/${id}`, {
      id_empresa: form.id_empresa,
      escolhapfpj: form.cnpj_cpf,
      observacao: form.observacao,

      'cliente-cnpj': form.cnpj,
      'cliente-razao-social': form.razaoSocial,
      'cliente-nome-fantasia': form.nome_fantasia,
      'cliente-nome': form.razaoSocial,
      'cliente-cpf': form.cpf,

      'cliente-rua': form.endereco,
      'cliente-numero': form.numEnd,
      'cliente-complemento': form.complemento,
      'cliente-bairro': form.bairro,
      'cliente-cep': form.cep,
      'cliente-cidade': form.cidade,
      'cliente-estado': form.estado,
      'cliente-site': form.site,

      'cliente-indicador-ie': form.cod_indicador_ie,
      'clienteuni-insc-mcipal': form.inscricao_municipal,
      'cliente-insc-estadual': form.inscricao_estadual,

      contato: form.contato,
      id_categoria_padrao: form.id_categoria_padrao
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return update;
  },
};

export default cadastroClientesService;
