import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import TableComponent from '../../../components/TableComponent';
import OverlayLoading from '../../../components/OverlayLoading';
import Button from '../../../components/ButtonComponent';
import ConfirmDialog from '../../../components/ConfirmDialog';
import ModalLoginExpirado from '../../../components/ModalLoginExpirado';
import CallbackMessage from '../../../components/CallbackMessage';
import ModalNovoPerfil from '../../../components/ModalPerfilAcesso/Novo';
import ModalEditarPerfil from '../../../components/ModalPerfilAcesso/Editar';


import perfilAcessoService from '../../../services/perfilAcessoService';

import classes from './styles.module.scss'

import { numberToCurrency } from '../../../utils/functions';

export default function ListaPerfilAcesso() {
  const history = useHistory();
  // const { listaServicoPlano, listaServico } = servicosService;
  const { listaPerfilAcesso, removePerfilAcesso } = perfilAcessoService;

  const [loading, setLoading] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const [idEdit, setIdEdit] = useState();
  const [errors, setErrors] = useState([]);

  const [listRequest, setListRequest] = useState([]);
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id" },
      { columnName: "Perfil de Acesso", dataRef: "nome" },
    ],
    options: {
      edit: true,
      delete: true,
      permissions: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);

    const response = await listaPerfilAcesso(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
    );

    if (response.data.hasOwnProperty("data")) {
      const { data } = response;
      const formattedData = {
        ...response.data,
        data: data.data.map((item) => ({
          ...item,
          id: item?.id_perfil_acesso,
        }))
      };
      setListRequest(formattedData);
    } else {
      const { data } = response;
      const formattedData = {
        ...response.data,
        data: data.data.map((item) => ({
          ...item,
          id: item?.id_perfil_acesso,
        }))
      };
      setListRequest(formattedData);
    }

    // console.log("SolicitacaoServicoLista :: updateTable :: response :: ", response);

    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    listaPerfilAcesso
  ]);


  useEffect(() => {
    updateTable();
  }, [updateTable]);

  let onPageChange = useCallback((newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onOrderBy = useCallback((newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onPerPage = useCallback((newPerPage) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onSearchTerm = useCallback((value) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onClickFile = (id) => {
    history.push(`/teste-visualizar-solicitacao/${id}`);
  };

  let onEdit = useCallback((id) => {
    console.log('id??????', id);
    setIdEdit(id)
    setOpenModalEdit(true);
  }, [setIdEdit])

  let onPermissions = (id) => {
    let perfil = listRequest?.data.find(item => item?.id_perfil_acesso == id);
    
    history.push(`/permissoes-acesso/${id}`, {
      perfilName: perfil?.nome,
    });
  };

  let onClickDelete = (id) => {
    setIdDelete(id);
    setOpenConfirmDelete(true);
  }

  let onCancelDelete = () => {  
    setOpenConfirmDelete(false);
  }

  let handleOpenModalNew = () => {  
    setOpenModalNew(true);
  }

  let handleCloseModal = () => {
    setOpenModalNew(false);
    setOpenModalEdit(false);
  }

  let onDelete = async () => {  
    setLoading(true);
    const response = await removePerfilAcesso(idDelete);

    if (response.status === 200 || response.status === 201) {
      setOpenConfirmDelete(false);
      await updateTable();
      setLoading(false);
      setShowMessage(true);
      setErrors([]);
    } else {
      if(response.status === 401) {  
        setOpenLoginExpirado(false);
        setLoading(false);
        return;
      }
      if (response.data.error)
        setErrors(Object.values(response.data.error));
      else setErrors(["Ocorreu um erro"]);
      setShowMessage(true);
      setLoading(false);
    }
  }

  function handleClose() {
    setShowMessage(false);
  }

  return (
    <>
      {loading && <OverlayLoading />}
        <div className={classes.headerButton}>
          <Button
            onClick={handleOpenModalNew}
            className="btn-primary"
            text="+ Novo Perfil"
          />
        </div>
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        cbEdit={(id) => onEdit(id)}
        cbDelete={onClickDelete}
        callbackSearchTerm={onSearchTerm}
        cbLookFile={onClickFile}
        cbPermissions={onPermissions}
      />
      <ConfirmDialog
       open = {openConfirmDelete}
       title = "CONFIRMAR EXCLUSÃO"
       description = "Você tem certeza que deseja excluir o perfil de acesso?"
       cancelLabel = "Voltar"
       acceptLabel = "Excluir"
       onAccept={onDelete}
       onCancel={onCancelDelete}
       onClose={onCancelDelete}
      />
      <ModalNovoPerfil
        open={openModalNew}
        onClose={handleCloseModal}
        cbTable={updateTable}
      />
      <ModalEditarPerfil
        open={openModalEdit}
        onClose={handleCloseModal}
        cbTable={updateTable}
        idEdit={idEdit}
      />
      <CallbackMessage
          open={showMessage}
          type={`${errors.length === 0 ? "success" : "error"}`}
          message={`${errors.length === 0
            ? "Perfil de acesso excluído com sucesso!"
            : "Erro!"
            }`}
          errorList={errors}
          handleClose={handleClose}
          duration={errors.length === 0 ? 3000 : 6000}
      />
       <ModalLoginExpirado open={openLoginExpirado} />
    </>
  )
}