import { Grid } from "@material-ui/core";
import QRCode from 'qrcode.react';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BiMinus } from 'react-icons/bi';
import { BsPlus, BsTrash } from 'react-icons/bs';
import { FiMenu } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { isNumber } from "lodash";
import IconNfce from "../../../assets/img/nfc-e.svg";
import Button from "../../../components/ButtonComponent";
import CallbackMessage from '../../../components/CallbackMessage';
import Input from "../../../components/Input";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import OverlayLoading from "../../../components/OverlayLoading";
import ModalAbrirCaixa from '../../../components/PDVModals/ModalAbrirCaixa';
import ModalCadastroPDV from '../../../components/PDVModals/ModalCadastroPDV';
import ModalFecharCaixa from '../../../components/PDVModals/ModalFecharCaixa';
import ModalPagamentoCartaoPDV from '../../../components/PDVModals/ModalPagamentoCartaoPDV';
import ModalPagamentoDinheiroPDV from '../../../components/PDVModals/ModalPagamentoDinheiroPDV';
import ModalSangria from '../../../components/PDVModals/ModalSangria';
import ModalSuprimento from '../../../components/PDVModals/ModalSuprimento';
import SelectList from "../../../components/SelectList";
import { MoreOptions } from '../../../components/TableComponent';
import { usePDVContext } from '../../../contexts/PDVContext';
import empresasService from "../../../services/empresasService";
import notasFiscaisServices from '../../../services/notasFiscaisService';
import pdvService from '../../../services/pdvService';
import produtosServices from '../../../services/produtosServices';
import selectServices from "../../../services/selectServices";
import { currencyToNumber, numberToCurrency } from "../../../utils/functions";
import { cpfCnpjMask } from '../../../utils/strings';
import validateFields from '../../../utils/validateFields';
import "./styles.scss";

function VendaPDV() {
  const history = useHistory();

  var moreOptionsList = [
    {
      icon: "",
      label: "Sangria",
      action: "sangria"
    },
    {
      icon: "",
      label: "Suprimento",
      action: "suprimento"
    },
    {
      icon: "",
      label: "Fechar Caixa",
      action: "fechar-caixa"
    },
  ]

  const { state, onChange, onFinish, onResetWithBoxOpen } = usePDVContext();

  const { selecionaProduto, sincronizarPedido, finalizarPedido } = produtosServices;
  const { consultaEmpresa } = empresasService;
  const { emitirNfcePedido } = pdvService;

  const user = JSON.parse(window.localStorage.getItem("user"));

  const [danfeCompanyInfo, setDanfeCompanyInfo] = useState(null);

  const [openMoreOptions, setOpenMoreOptions] = useState(false);

  const [step, setStep] = useState(1);
  const [identificadorCliente, setIdentificadorCliente] = useState(null);
  const [codigoPdv, setCodigoPdv] = useState(null);
  const [valorAbertura, setValorAbertura] = useState(null);
  const [dataAbertura, setDataAbertura] = useState(null);
  const [idCaixa, setIdCaixa] = useState(null);
  const [openModalPaymentMoney, setOpenModalPaymentMoney] = useState(false);
  const [openModalPaymentCard, setOpenModalPaymentCard] = useState(false);
  const [openModalSangria, setOpenModalSangria] = useState(false);
  const [openModalSuprimento, setOpenModalSuprimento] = useState(false);
  const [openModalFecharCaixa, setOpenModalFecharCaixa] = useState(false);
  const [dadosNfce, setDadosNfce] = useState({});
  const [paymentsProducts, setPaymentsProducts] = useState([]);
  const [idPedido, setIdPedido] = useState(null);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [loading, setLoading] = useState();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [openCadastro, setOpenCadastro] = useState(false);
  const [produto, setProduto] = useState();
  const [somaQuantidade, setSomaQuantidade] = useState(0.0);
  const [somaDesconto, setSomaDesconto] = useState(0.0);
  const [somaTotal, setSomaTotal] = useState(0.0);
  const [somaTotalPagar, setSomaTotalPagar] = useState(0.0);
  const [totalPago, setTotalPago] = useState(0.0);
  const [formProduto, setFormProduto] = useState([]);
  const [observacao, setObservacao] = useState("");
  
  const [listProduto, setListProduto] = useState([]);

  const cbSaveIdentifierClient = (identifier) => {
    if(identifier) {
      setIdentificadorCliente(identifier)
    }
    
    setStep(step => step + 1);

    onChange('identificador', {
      pass: true,
      identifier: identifier ? identifier : null,
    })
  };

  const cbSabeOpenBoxControl = async (data) => {
    setLoading(true);
    if(data) {
      setCodigoPdv(data?.codigoPdv);
      setValorAbertura(data?.valorAbertura);
      setIdCaixa(data?.idCaixa);
      setDataAbertura(data?.dataAbertura)
    }

    setStep(step => step + 1);
    onChange('caixa', {
      pass: true,
      codigoPdv: data?.codigoPdv,
      valorAbertura: data?.valorAbertura,
      idCaixa: data?.idCaixa,
      dataAbertura: data?.dataAbertura,
    })

    setLoading(false);
  };

  useEffect(() => { 
    if(state?.caixa?.pass) {
      setCodigoPdv(state?.caixa?.codigoPdv);
      setValorAbertura(state?.caixa?.valorAbertura);
      setIdCaixa(state?.caixa?.idCaixa);
      setDataAbertura(state?.caixa?.dataAbertura);
      setStep(2);
    }
    if(state?.identificador?.pass) {
      setIdentificadorCliente(state?.identificador?.identifier);
      setStep(3);
    }
    if(state?.produtos?.pass) {
      setFormProduto(state?.produtos?.formProduto);
      setStep(4);
    }
    if(state?.predanfe?.pass) {
      setDanfeCompanyInfo(state?.predanfe?.danfeCompanyInfo);
      setStep(5);
    }
    if(state?.produtos?.formProduto) {
      setFormProduto(state?.produtos?.formProduto);
    }
    if(state?.produtos?.pagamentos) {
      setPaymentsProducts(state?.produtos?.pagamentos);
    }
    if(state?.produtos?.totalPago) {
      setTotalPago(state?.produtos?.totalPago);
    }
    if(state?.produtos?.idPedido) {
      setIdPedido(state?.produtos?.idPedido);
    }
    if(state?.predanfe?.danfeCompanyInfo) {
      setDanfeCompanyInfo(state?.predanfe?.danfeCompanyInfo);
    }
    if(state?.danfe?.dadosNfce) {
      setDadosNfce(state?.danfe?.dadosNfce);
    }
  }, [state])

  const loadProdutos = useCallback(async () => {
    const companyId = parseInt(window.localStorage.getItem("empresa_usuario"), 10);

    if(companyId) {
      const response = await selecionaProduto(companyId);

      if (response.status === 200) {
        const { data } = response;
  
        if (data.length === 0) {
          setCallbackMessage('Erro!');
          setCallbackErrorList(['Nenhum produto encontrado para a empresa selecionada.']);
          setCallbackShown(true);
          return;
        }
        const productsList = data.map(item => ({
          image: "https://www.orientalheritageagra.com/wp-content/uploads/2019/03/product-png-image-59158.png",
          description: item.descricao,
          code: item.codigo_produto,
          type: item.tipo_produto,
          value: numberToCurrency(item.valor_unitario_comercial),
          valorTributario: numberToCurrency(item.valor_unitario_tributario),
          id: item.id_produto,
          estoqueMinimo: !item.estoque_minimo && item.estoque_minimo !== 0 ? false : item.estoque_minimo,
          estoque: item.estoque,
          ean: item?.ean
        }))
  
        setListProduto(productsList);
      }
    }

  }, [selecionaProduto])

  const loadDanfeCompanyInfo = useCallback(async (id) => {
    const response = await consultaEmpresa(id);

    if (response.status === 200) {
      const { data } = response;

      setDanfeCompanyInfo({
        cpfCnpj: cpfCnpjMask(data.cnpj),
        razaoSocial: data.razao_social,
        endereco: data.endereco,
        numero: data.numero,
        bairro: data.bairro,
        cidade: data.cidade,
        uf: data.uf,
      });

      onChange('predanfe', {
        pass: false,
        danfeCompanyInfo: {
          cpfCnpj: cpfCnpjMask(data.cnpj),
          razaoSocial: data.razao_social,
          endereco: data.endereco,
          numero: data.numero,
          bairro: data.bairro,
          cidade: data.cidade,
          uf: data.uf,
        }
      })
    }
  }, [consultaEmpresa]);

  useEffect(() => {
    setLoading(true);
    loadProdutos();
    setLoading(false);
  }, []);


  const handleAdicionarProduto = (produtoSelected, fields) => {
    setOpenCadastro(false);

    setFormProduto([...formProduto, { ...produtoSelected, ...fields }])

    onChange('produtos', {
      pass: false,
      formProduto: [...formProduto, { ...produtoSelected, ...fields }],
    })
  }

  const callbackHandlePayment = async (data, type) => {
    setLoading(true);
    const companyId = parseInt(window.localStorage.getItem("empresa_usuario"), 10);

    let produtos = formProduto.map(item => ({
      id_produto: item?.id,
      quantidade: item?.quantidade,
      valor_unitario: currencyToNumber(item?.valorTributario),
      valor_desconto: Number(item?.valorDesconto),
    }))

    if(type === 'money') {
      let pagamentos = paymentsProducts;

      pagamentos.push({
        forma_pagamento: 1,
        valor_pago: data?.amount,
        valor_troco: data?.troco,
      })

      setPaymentsProducts(pagamentos);

      const response = await sincronizarPedido(idCaixa, idPedido, companyId, produtos, pagamentos, identificadorCliente, observacao)
    
      if(response.status === 200 || response.status === 201) {
        setCallbackErrorList([]);
        setCallbackMessage(response.data?.message);
        setCallbackShown(true)
        setCallbackDuration(2000);
      }

      if (response.status === 401) {
        setOpenLoginExpirado(true);
      }

      if (response.status === 400) {
        setCallbackErrorList(Object.values(response?.data?.error).flat(Infinity));
        setCallbackMessage('Erro');
        setCallbackShown(true);
      }

      if (response.status === 500) {
        setCallbackErrorList(['Erro interno no servidor, contate o suporte.']);
        setCallbackMessage('Erro!');
        setCallbackShown(true);
      }

      if(response.data?.pago === 1) {
        await loadDanfeCompanyInfo(companyId);
        setStep(step => step + 1);
        onChange('produtos', {
          pass: true,
          idPedido: response?.data?.id,
          formProduto: formProduto,
          pagamentos: pagamentos,
          })
      }

      if(response.data?.pago === 0) {
        setIdPedido(response?.data?.id);
        setTotalPago(response?.data?.valor_total_pagamentos);
        setSomaTotalPagar(response?.data?.valor_total_pedido - response?.data?.valor_total_pagamentos);

        onChange('produtos', {
          pass: false,
          idPedido: response?.data?.id,
          formProduto: formProduto,
          totalPago: response?.data?.valor_total_pagamentos,
          pagamentos: pagamentos,
        });
      }
      
      setLoading(false);
    }

    if (type === 'card') {
      // console.table('HandlePayment')
      // console.table({ type, data })
      let pagamentos = paymentsProducts;

      pagamentos.push({
        forma_pagamento: data?.formaPagamento,
        valor_pago: data?.amount,
        nsu: data?.nsu,
        bandeira: data?.bandeira,
        quantidade_parcelas: data?.qtdParcelas,
      })

      setPaymentsProducts(pagamentos);

      const response = await sincronizarPedido(idCaixa, idPedido, companyId, produtos, pagamentos, identificadorCliente)
    
      if(response.status === 200 || response.status === 201) {
        setCallbackErrorList([]);
        setCallbackMessage(response.data?.message);
        setCallbackShown(true)
        setCallbackDuration(2000);
      }

      if (response.status === 401) {
        setOpenLoginExpirado(true);
      }

      if (response.status === 400) {
        setCallbackErrorList(Object.values(response?.data?.error).flat(Infinity));
        setCallbackMessage('Erro');
        setCallbackShown(true);
      }

      if (response.status === 500) {
        setCallbackErrorList(['Erro interno no servidor, contate o suporte.']);
        setCallbackMessage('Erro!');
        setCallbackShown(true);
      }

      if(response.data?.pago === 1) {
        await loadDanfeCompanyInfo(companyId);
        setStep(step => step + 1);
        onChange('produtos', {
          pass: true,
          idPedido: response?.data?.id,
          formProduto: formProduto,
          totalPago: response?.data?.valor_total_pagamentos,
          pagamentos: pagamentos,
          })
      }

      if(response.data?.pago === 0) {
        setIdPedido(response?.data?.id);
        setTotalPago(response?.data?.valor_total_pagamentos);
        setSomaTotalPagar(response?.data?.valor_total_pedido - response?.data?.valor_total_pagamentos);

        onChange('produtos', {
          pass: false,
          idPedido: response?.data?.id,
          formProduto: formProduto,
          totalPago: response?.data?.valor_total_pagamentos,
          pagamentos: pagamentos,
        });
      }
      
      setLoading(false);
    }

    setLoading(false);
  }

  const handleEmitirDanfe = async () => {
    setLoading(true);
    const companyId = parseInt(window.localStorage.getItem("empresa_usuario"), 10);

    let produtos = formProduto.map(item => ({
      id_produto: item?.id,
      quantidade: item?.quantidade,
      valor_unitario: currencyToNumber(item?.valorTributario),
      valor_desconto: item?.valorDesconto,
    }))

      const response = await emitirNfcePedido(idPedido, companyId, produtos, paymentsProducts, identificadorCliente)

      if(response.status === 200 || response.status === 201) {
        setCallbackErrorList([]);
        setDadosNfce(response?.data?.dados);
        setStep(step => step + 1);
        setCallbackMessage(response?.data?.message);
        setCallbackShown(true)
        setCallbackDuration(2000);

        onChange('predanfe', {
          pass: true,
          danfeCompanyInfo: danfeCompanyInfo
        })

        onChange('danfe', {
          pass: false,
          dadosNfce: response?.data?.dados
        })
      }

      if (response?.status === 401 || response?.response?.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }

      if (response?.status === 400 || response?.response?.status === 400) {
        setCallbackErrorList(Object.values(response?.data?.error || response?.response?.data?.error).flat(Infinity))
        setCallbackMessage('Erro');
        setCallbackShown(true);
        setLoading(false);
        return;
      }

      if (response?.status === 500 || response?.response?.status === 500) {
        setCallbackErrorList(['Erro interno no servidor, contate o suporte.']);
        setCallbackMessage('Erro!');
        setCallbackShown(true);
        setLoading(false);

        return;
      }

    setLoading(false);
  };

  const callbackShowMessage = useCallback((type) => {
    if(type === 'sangria') {
      setCallbackErrorList([]);
      setCallbackMessage("Sangria feita com sucesso!");
      setCallbackShown(true)
      setCallbackDuration(2000);
    }

    if(type === 'suprimento') {
      setCallbackErrorList([]);
      setCallbackMessage("Suprimento feito com sucesso!");
      setCallbackShown(true)
      setCallbackDuration(2000);
    }

    if(type === 'fechamento') {
      setCallbackErrorList([]);
      setCallbackMessage("Fechamento de caixa efetuado com sucesso!");
      setCallbackShown(true)
      setCallbackDuration(2000);
      setStep(1);
      onFinish();
    }
  }, [])

  const calculaSoma = useCallback(() => {
    let quantidade = 0.0
    let desconto = 0.0;
    let total = 0.0;

    let list = formProduto;

    for (let i = 0;i < list.length;i++) {
      quantidade += Number(list[i].quantidade || 0.0);
      total += Number(list[i].valorTotal || 0.0);
      desconto += Number(list[i].totalDesconto || 0.0);
    }

    setSomaQuantidade(quantidade);
    setSomaDesconto(desconto);
    setSomaTotal(total);
    setSomaTotalPagar(total - desconto);
  }, [formProduto])

  function NotaNfce() {

    calculaSoma();

    return (
      <Grid
        container
        direction="column"
        className="nota-nfce-container"
        spacing={1}
      >
        <Grid item container>
          <Grid item xs={6} sm={8} md={8}>
            <pre className="nota-nfce-info">
              {danfeCompanyInfo && (
                <>
                  {`CPF/CPNJ: ${danfeCompanyInfo.cpfCnpj} ${danfeCompanyInfo.razaoSocial}
                  ${danfeCompanyInfo.endereco}, ${danfeCompanyInfo.numero}, ${danfeCompanyInfo.bairro}, ${danfeCompanyInfo.cidade} - ${danfeCompanyInfo.uf}\n
                  Documento Auxiliar da Nota Fiscal de Consumidor Eletrônica`}
                </>
              )}
            </pre>
          </Grid>
          <Grid item xs={6} sm={4} md={4} className="nota-nfce-icon-wrapper">
            <img className="nota-nfce-icon" src={IconNfce} alt="icone-nota-nfce" />
          </Grid>
        </Grid>
        <Grid item>
          <table className="nota-nfce-table">
            <thead>
              <tr>
                <th className="column-head-id">ID</th>
                <th className="column-numero">Código</th>
                <th className="column-descricao">Descrição</th>
                <th className="column-numero">Qtde</th>
                <th className="column-th-valor">Vl Unit</th>
                <th className="column-th-valor">Desc</th>
                <th className="column-th-valor">Vl Trib</th>
                <th className="column-th-valor">Valor (R$)</th>
              </tr>
            </thead>
            {formProduto.length > 0
              && (
                <tbody>
                  {formProduto.map((item, index) => (
                    <tr key={index}>
                      <td className="column-id">
                        <div>
                          {item.id}
                        </div>
                      </td>
                      <td className="column-numero">{item.code}</td>
                      <td className="column-descricao">{item.description}</td>
                      <td className="column-quantidade">{item.quantidade}</td>
                      <td className="column-td-valor">{numberToCurrency(item.valorUnitario)}</td>
                      <td className="column-td-valor">{numberToCurrency(item.valorDesconto)}</td>
                      <td className="column-td-valor">{numberToCurrency(item.valorTributo)}</td>
                      <td className="column-td-valor">{numberToCurrency(item.valorTotal)}</td>
                    </tr>
                  ))}
                </tbody>
              )}
          </table>
          {formProduto.length === 0 &&
            <div className="table-sem-dados"> Nenhum produto adicionado</div>}
        </Grid>
        <Grid item container spacing={1} justify="flex-end">
          <Grid item xs={12} sm={4} md={1} className="nota-nfce-total">
            TOTAL
          </Grid>
          <Grid item xs={12} sm={4} md={2} className="nota-nfce-total">
            {numberToCurrency(somaTotal)}
          </Grid>
        </Grid>
        <Grid
          item
          container
          className="nota-nfce-info-total-container"
          justify="flex-end"
        >
          <Grid item xs={12} sm={4} md={4} className="nota-nfce-info-total-label"
          >
            Quantidade Total de Itens:
          </Grid>
          <Grid item xs={12} sm={2} md={3} className="nota-nfce-info-total-valor"
          >
            {somaQuantidade}
          </Grid>
        </Grid>
        <Grid item container className="nota-nfce-info-total-container" justify="flex-end">
          <Grid item xs={12} sm={4} md={4} className="nota-nfce-info-total-label">
            Valor total:
          </Grid>
          <Grid item xs={12} sm={2} md={3} className="nota-nfce-info-total-valor">
            {numberToCurrency(somaTotal)}
          </Grid>
        </Grid>
        <Grid item container className="nota-nfce-info-total-container" justify="flex-end" >
          <Grid item xs={12} sm={4} md={4} className="nota-nfce-info-total-label" >
            Desconto:
          </Grid>
          <Grid item xs={12} sm={2} md={3} className="nota-nfce-info-desconto">
            {numberToCurrency(somaDesconto)}
          </Grid>
        </Grid>
        <Grid item container className="nota-nfce-info-total-container" justify="flex-end" >
          <Grid itemxs={12} sm={4} md={4} className="nota-nfce-info-soma-label">
            Valor Total a Pagar:
          </Grid>
          <Grid item xs={12} sm={2} md={3} className="nota-nfce-info-soma-valor">
            {numberToCurrency(somaTotalPagar)}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function DanfeNfce() {

    calculaSoma();

    return (
      <Grid
        container
        direction="column"
        className="nota-danfe-container"
        spacing={1}
      >
        <Grid item>
          <table className="nota-nfce-table">
            <thead>
              <tr>
                <th className="column-head-id">ID</th>
                <th className="column-numero">Código</th>
                <th className="column-descricao">Descrição</th>
                <th className="column-numero">Qtde</th>
                <th className="column-th-valor">Vl Unit</th>
                <th className="column-th-valor">Desc</th>
                <th className="column-th-valor">Vl Trib</th>
                <th className="column-th-valor">Valor (R$)</th>
              </tr>
            </thead>
            {formProduto.length > 0
              && (
                <tbody>
                  {formProduto.map((item, index) => (
                    <tr key={index}>
                      <td className="column-id">
                        <div>
                          <span>{item.id}</span>
                        </div>
                      </td>
                      <td className="column-numero">{item.code}</td>
                      <td className="column-descricao">{item.description}</td>
                      <td className="column-quantidade">{item.quantidade}</td>
                      <td className="column-td-valor">{numberToCurrency(item.valorUnitario)}</td>
                      <td className="column-td-valor">{numberToCurrency(item.valorDesconto)}</td>
                      <td className="column-td-valor">{numberToCurrency(item.valorTributo)}</td>
                      <td className="column-td-valor">{numberToCurrency(item.valorTotal)}</td>
                    </tr>
                  ))}
                </tbody>
              )}
          </table>
          {formProduto.length === 0 &&
            <div className="table-sem-dados"> Nenhum produto adicionado</div>}
        </Grid>
        <Grid item container spacing={1} justify="flex-end">
          <Grid item xs={12} sm={4} md={1} className="nota-nfce-total">
            TOTAL
          </Grid>
          <Grid item xs={12} sm={4} md={2} className="nota-nfce-total">
            {numberToCurrency(somaTotal)}
          </Grid>
        </Grid>
      
      </Grid>
    );
  }

  function handleCloseModalPayment() {
    setOpenModalPaymentMoney(false);
    setOpenModalPaymentCard(false);
  }

  function handleCloseModal() {
    setOpenModalSangria(false);
    setOpenModalSuprimento(false);
    setOpenModalFecharCaixa(false);
  }

  function cbMoreOptions(action) {

    switch (action.action) {
      case "sangria": {
        setLoading(true);

        setOpenModalSangria(true);

        setLoading(false);
        break;
      }
      case "suprimento": {
        setLoading(true);

        setOpenModalSuprimento(true);


        setLoading(false);
        break;
      }
      case "fechar-caixa": {
        setLoading(true);

        setOpenModalFecharCaixa(true);
        
        setLoading(false);
        break;
      }
      default:
        break;
    }
  }

  function ProdutoCadastro() {
    let produtoSelected = listProduto.find(item => item.id === produto);

    const [callbackAddProdutoShown, setCallbackAddProdutoShown] = useState(false);
    const [callbackAddProdutoMessage, setCallbackAddProdutoMessage] = useState("");
    const [callbackAddProdutoErrorList, setCallbackAddProdutoErrorList] = useState([]);

    useEffect( () => {
      if (isNumber(produtoSelected?.estoque) && isNumber(produtoSelected?.estoqueMinimo) && produtoSelected?.estoque <= produtoSelected?.estoqueMinimo){
        setCallbackAddProdutoMessage('Atenção!');
        setCallbackAddProdutoErrorList(['Estoque mínimo do produto atingido!']);
        setCallbackAddProdutoShown(true);
      }
    }, [produtoSelected, produto])

    const [quantidade, setQuantidade] = useState(1);
    const [valorDesconto, setValorDesconto] = useState(0);

    let valorTributo = currencyToNumber(produtoSelected.valorTributario);
    let valorUnitario = currencyToNumber(produtoSelected.value);

    const valorTotal = useMemo(() => {
      let total = 0;

      total = (valorTributo * quantidade);

      return total;
    }, [valorTributo, quantidade, valorDesconto]);

    const handleCloseAddProduto = (event, reason) => {
      if (reason === 'timeout') {
        setCallbackAddProdutoShown(false);
      }
    }

    return (
      <>
        <Grid item container direction="column" spacing={2}>
          <Grid
            item
            container
            direction="column"
            className="emitir-nfce-cadastro-container"
            spacing={2}
          >
            <Grid item className="emitir-nfce-form-title">
              {`Item: #${formProduto.length + 1}`}
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                <Input
                  readOnly
                  title={produtoSelected.description}
                  label="Descrição do Produto ou Serviço"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input readOnly title={produtoSelected.code} label="Código" />
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <div>
                    <div className="label">Imagem</div>
                    <img
                      src={produtoSelected.image}
                      alt="imagem-produto"
                      className="emitir-nfce-cadastro-imagem"
                    />
                  </div>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={6} spacing={1}>
                  <Grid item container justify="flex-end">
                    <Grid item xs={12} sm={12} md={8} className="emitir-nfce-field-desconto">
                      <Input
                        label="Desconto"
                        tipo="dinheiro"
                        handleInputChange={event => setValorDesconto(event.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="flex-end">
                    <Grid item xs={12} sm={12} md={8} className="emitir-nfce-field-total">
                      <Input
                        label="Total"
                        tipo="dinheiro"
                        readOnly
                        value={valorTotal}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justify="flex-end">
          <Button text="+ Adicionar" className="success-outline zero-margin"
            onClick={() => {
              if(valorDesconto > valorTotal) {
                setCallbackShown(true);
                setCallbackMessage("Erro!");
                setCallbackErrorList(["Você não pode dar um desconto maior que o valor do produto."]);
                return;  
              };

              handleAdicionarProduto(produtoSelected, {
              valorDesconto: valorDesconto,
              valorTotal: valorTotal,
              valorTributo: valorTributo,
              valorUnitario: valorUnitario,
              quantidade: quantidade,
              totalDesconto: valorDesconto * quantidade,
            })
            }}
          />
        </Grid>
        <CallbackMessage
          key={1}
          open={callbackAddProdutoShown}
          type={callbackAddProdutoErrorList.length !== 0 ? 'error' : 'success'}
          duration={6000}
          errorList={callbackAddProdutoErrorList}
          message={callbackAddProdutoMessage}
          handleClose={handleCloseAddProduto}
        />
      </>
    );
  }

  function ListProducts() {

    useEffect(() => {
      calculaSoma();
    }, [formProduto])

    return (
      <div className="list-products-container">
      {formProduto.length === 0 ? (
        <h3 className="empty-span">Ainda não tem produtos adicionados.</h3>
      ) : (
        <ul className="list-products-wrapper">
        {formProduto.map((item, index) => (
          <li className="list-product-item">
            <span>{item.description}</span>
            <div className="product-info-container">
              <div className="quantity-options">
                <button
                type="button" 
                onClick={() => {
                   let list = [...formProduto];

                   if(paymentsProducts.length > 0) {
                      setCallbackShown(true);
                      setCallbackMessage("Erro!");
                      setCallbackErrorList(["Você não pode deletar um produto com pagamento em andamento."]);
                      return;  
                    };
                   if(list[index].quantidade > 1) {
                    list[index].quantidade = list[index].quantidade - 1;
                    list[index].valorTotal -= list[index].valorTributo;
                    list[index].totalDesconto -= list[index].valorDesconto;
                    setFormProduto(list);
                    onChange('produtos', {
                      pass: false,
                      formProduto: list,
                    })
                    calculaSoma();
                   }
                 }}>
                  <BiMinus size={20} />
                </button>
                <p>{item.quantidade}</p>
                <button
                type="button" 
                onClick={() => {
                   let list = [...formProduto];

                   if(paymentsProducts.length > 0) {
                    setCallbackShown(true);
                    setCallbackMessage("Erro!");
                    setCallbackErrorList(["Você não pode adicionar um produto com pagamento em andamento."]);
                    return;  
                  };

                   list[index].quantidade = list[index].quantidade + 1;
                   list[index].valorTotal = list[index].valorTributo * list[index].quantidade;
                   list[index].totalDesconto = list[index].valorDesconto * list[index].quantidade;
                   setFormProduto(list);
                   onChange('produtos', {
                    pass: false,
                    formProduto: list,
                    })
                   calculaSoma();
                 }}>
                  <BsPlus size={20} />
                </button>
              </div>
              <div className="info-product">
                <p>{numberToCurrency(item.valorTotal)}</p>
              <button   
              type="button" 
              onClick={() => {
                 let list = [...formProduto];

                 if(paymentsProducts.length > 0) {
                  setCallbackShown(true);
                  setCallbackMessage("Erro!");
                  setCallbackErrorList(["Você não pode deletar um produto com pagamento em andamento."]);
                  return;  
                };

                 list.splice(index, 1);
                 setFormProduto(list);
                 onChange('produtos', {
                  pass: false,
                  formProduto: list,
                  })
                 calculaSoma();
               }}>
                <BsTrash />
              </button>
              </div>
            </div>
  
          </li>
        ))}
        </ul>
      )}
      </div>
      );
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      setCallbackShown(false);
    }

    if (reason === 'timeout') {
      setCallbackShown(false);
    }
  }

  const handleFinish = async () => {
    setLoading(true);
    const companyId = parseInt(window.localStorage.getItem("empresa_usuario"), 10);

    let produtos = formProduto.map(item => ({
      id_produto: item?.id,
      quantidade: item?.quantidade,
      valor_unitario: currencyToNumber(item?.valorTributario),
      valor_desconto: item?.valorDesconto,
    }))

      const response = await finalizarPedido(idPedido, companyId, produtos, paymentsProducts, identificadorCliente)

      if(response.status === 200 || response.status === 201) {
        if(state?.caixa?.pass) {
          setCallbackErrorList([]);
          setCallbackMessage("Venda finalizada com sucesso!");
          setCallbackShown(true)
          setCallbackDuration(2000);
    
          setTimeout(() => {
            onResetWithBoxOpen();
            setIdPedido(null);
            setFormProduto([]);
            setPaymentsProducts([]);
            setTotalPago(0.0);
            setStep(2);
          }, 2000)
        } else {
          setCallbackErrorList([]);
          setCallbackMessage("Venda finalizada com sucesso!");
          setCallbackShown(true)
          setCallbackDuration(2000);
    
          setTimeout(() => {
            onFinish();
            setIdPedido(null);
            setFormProduto([]);
            setPaymentsProducts([]);
            setTotalPago(0.0);
            setStep(1);
          }, 2000)
        }
      }

      if (response?.status === 401 || response?.response?.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }

      if (response?.status === 400 || response?.response?.status === 400) {
        setCallbackErrorList(Object.values(response?.data?.error || response?.response?.data?.error).flat(Infinity))
        setCallbackMessage('Erro');
        setCallbackShown(true);
        setLoading(false);
        return;
      }

      if (response?.status === 500 || response?.response?.status === 500) {
        setCallbackErrorList(['Erro interno no servidor, contate o suporte.']);
        setCallbackMessage('Erro!');
        setCallbackShown(true);
        setLoading(false);

        return;
      }

    setLoading(false);
  };

  return (
    <>
      {loading && <OverlayLoading />}
      {/* <div className="header-menu">
        <span>hamb</span>
      </div> */}
      <Grid container className="pdv-wrapper">
          <Grid item>
            <h3 className="title-pdv">PDV</h3>
          </Grid>
          <Grid item className="pdv-menu-wrapper">
            {(step === 1 || step === 2 || step === 3) ? (
              <>
              <div
                className="icon-wrapper-pdv"
                onClick={() => setOpenMoreOptions(prev => !prev)}
              >
                <FiMenu
                  size={30}
                  color="#EF4066"
                  className="icon-pdv"
                />
              </div>
              {openMoreOptions && (
                <MoreOptions
                  handleMouseDown={() => setOpenMoreOptions(false)}
                  options={moreOptionsList}
                  callback={cbMoreOptions}
                  lineId={idCaixa}
                  // displayHeader
                />
              )}
              </>
            ) : null}
            </Grid>
          </Grid>
      {(step === 1 || step === 2 || step === 3) && (
        <div className="session-container">
        <span className="session-container-header">
          <form className="emitir-nfce-form-container">
            <Grid container  spacing={3}>
            <Grid item container spacing={2} className="top-info-pdv">
             <Grid item xs={12} sm={12} md={4} className="modal-items">
                <label>Abertura</label>
                <span>{dataAbertura}</span>
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="modal-items">
                <label>Usuário</label>
                <span>{user?.name}</span>
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="modal-items">
                <label>PDV</label>
                <span>{codigoPdv}</span>
              </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <div className="emitir-nfce-form-title">Produtos</div>
                <SelectList
                  label="Pesquise o produto aqui:"
                  list={listProduto}
                  title="Selecione"
                  callback={id => {
                    setProduto(id);
                    setOpenCadastro(true);
                  }}
                  hint="Produtos"
                  pdvProducts
                />

                {openCadastro && (
                  <ProdutoCadastro />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <div className="checkout-container">
                  <h1>Checkout</h1>

                  <ListProducts />
                  <Grid container  spacing={3}>
                    <Grid item xs={12}>
                      <Input
                        label="Observação"
                        title=""
                        value={observacao}
                        handleInputChange={(event) =>
                          setObservacao(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <h1 className="payment-section">Pagamento</h1>

                  <div className="values-products-container">
                    <div className="values-container">
                    <h3>Subtotal</h3>
                    <h3>{numberToCurrency(somaTotal)}</h3>
                    </div>
                    <div className="values-container discount">
                    <h3>Desconto</h3>
                    <h3>{numberToCurrency(somaDesconto)}</h3>
                    </div>

                    <div className="values-container payment-value">
                      <h3>Valor Pago</h3>
                      <h3>{numberToCurrency(totalPago)}</h3>
                    </div>
                    {paymentsProducts.map((item) => (
                    <div className="values-container sub-payment-value">
                    {item.forma_pagamento === 1 && <h3>Dinheiro</h3>}
                    {item.forma_pagamento === 2 && <h3>Crédito</h3>}
                    {item.forma_pagamento === 3 && <h3>Débito</h3>}
                    <h3>{item.valor_pago > 0 ? numberToCurrency(item.valor_pago) : ''}</h3>
                    </div>
                    ))}
                  </div>
                  <div className="total-products-container">
                    <div className="total-values-container">
                    <h3>Total</h3>
                    <h3>{numberToCurrency(somaTotalPagar - totalPago)}</h3>
                    </div>
                  </div>

                  <div className="values-container">
                    <Button 
                    className="btn-info" 
                    text="Pagar com Dinheiro" 
                    onClick={() => setOpenModalPaymentMoney(true)} />
                    <Button 
                    className="btn-success" 
                    text="Pagar com Cartão" 
                    onClick={() => setOpenModalPaymentCard(true)} />

                  </div>

                </div>
              </Grid>
            </Grid>
          </form>
        </span>
      </div>
      )}
      
      {step === 4 && (
      <div className="session-container">
        <span className="session-container-header">
          <form className="emitir-nfce-form-container">
            <div className="emitir-nfce-section-title">Pré-Danfe</div>

            <NotaNfce />

            <Grid item container alignItems="flex-end" className="buttons-pre-danfe"  spacing={3}>
              <div className="buttons-container-pre-danfe">
              <Grid item>
                <Button 
                    className="btn-success" 
                    text="Emitir DANFE" 
                    onClick={handleEmitirDanfe} />
              </Grid>
              <Grid item>
                <Button 
                    className="btn-info" 
                    text="Finalizar Venda" 
                    onClick={() => handleFinish()} />
              </Grid>
              </div>
            </Grid>
          </form>
        </span>
      </div>
      )}

    {step === 5 && (
      <div className="session-container">
        <span className="session-container-header">
          <form className="emitir-nfce-form-container">
            <div className="emitir-danfe-section-title">DANFE</div>
            <Grid item xs={12} sm={12} md={12} className="info-danfe-wrapper">
              <pre className="nota-nfce-info">
                {danfeCompanyInfo && (
                  <>
                    {`CPNJ: ${dadosNfce?.cnpj_emitente} ${dadosNfce?.razao_social_emitente}
                    ${dadosNfce?.endereco_emitente}, ${dadosNfce?.numero_emitente}, ${dadosNfce?.bairro_emitente}, ${dadosNfce?.cidade_emitente} - ${dadosNfce?.uf_emitente}\n
                    Documento Auxiliar da Nota Fiscal de Consumidor Eletrônica`}
                  </>
                )}
              </pre>
            </Grid>
            <DanfeNfce />

            <div className="nota-danfe-info-bottom">
                <div className="qr-code-wrapper"><QRCode size={250} value={dadosNfce?.qrcode_nfce} /> </div>
                <section>
                  <div className="info-line">
                    <h3>CONSUMIDOR (CPF/CNPJ) -</h3>
                    <span>{dadosNfce?.identificador_cliente ? dadosNfce?.identificador_cliente : 'SEM IDENTIFICAÇÃO'}</span>
                  </div>
                  <div className="info-line">
                    <h3>NFC-e</h3>
                    <span> n {dadosNfce?.numero_nfce}</span>
                    <h3 className="margin-left-info">Série</h3>
                    <span>{dadosNfce?.serie_nfce}</span>
                    <h3 className="margin-left-info">{dadosNfce?.data_emissao_nfce}</h3>
                  </div>
                  <div className="info-line-access">
                    <h3>Consulte pela Chave de Acesso em {dadosNfce?.url_consulta_nfce}</h3>
                    <span>{dadosNfce?.chave}</span>
                  </div>
                  <div className="info-line">
                    <h3>Protocolo de Autorização:</h3>
                    <span>{dadosNfce?.protocolo_autorizacao_nfce}</span>
                  </div>
                  <div className="info-line">
                    <h3>Data de Autorização:</h3>
                    <span>{dadosNfce?.data_autorizacao_nfce}</span>
                  </div>
                </section>
            </div>

            <Grid item container alignItems="flex-end" className="buttons-pre-danfe"  spacing={3}>
              <div className="buttons-container-pre-danfe">
              <Grid item>
                <Button 
                    className="btn-info" 
                    text="Finalizar Venda" 
                    onClick={() => handleFinish()} />
              </Grid>
              </div>
            </Grid>

          </form>
        </span>
      </div>
      )}

      <ModalLoginExpirado open={openLoginExpirado} />

      <ModalPagamentoCartaoPDV
         open={openModalPaymentCard}
         onClose={handleCloseModalPayment}
         totalValue={somaTotalPagar - totalPago}
         onSave={callbackHandlePayment}
      />
      <ModalPagamentoDinheiroPDV
         open={openModalPaymentMoney}
         onClose={handleCloseModalPayment}
         totalValue={somaTotalPagar - totalPago}
         onSave={callbackHandlePayment}
      />
      <ModalFecharCaixa
         open={openModalFecharCaixa}
         onClose={handleCloseModal}
         idCaixa={idCaixa}
         onSave={callbackShowMessage}
      />
      <ModalSangria
         open={openModalSangria}
         onClose={handleCloseModal}
         idCaixa={idCaixa}
         onSave={callbackShowMessage}
      />
      <ModalSuprimento
         open={openModalSuprimento}
         onClose={handleCloseModal}
         idCaixa={idCaixa}
         onSave={callbackShowMessage}
      />
      <ModalAbrirCaixa
         open={step === 1}
         onClose={() => {}}
         onSave={cbSabeOpenBoxControl}
      />
      <ModalCadastroPDV
         open={step === 2}
         onClose={() => {}}
         onSave={cbSaveIdentifierClient}
      />
      <CallbackMessage
        key={1}
        open={callbackShown}
        type={callbackErrorList.length !== 0 ? 'error' : 'success'}
        duration={callbackDuration}
        errorList={callbackErrorList}
        message={callbackMessage}
        handleClose={handleClose}
      />
    </>
  );
}

export default VendaPDV;
