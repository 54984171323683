import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import TableComponent from "../../../components/TableComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Button from "../../../components/ButtonComponent";

import { Grid } from "@material-ui/core";
import Select from "../../../components/SelectComponent";

import notasFiscaisService from "../../../services/notasFiscaisService";
import { base64ToFile, numberToCurrency } from "../../../utils/functions";

import classes from "./styles.module.scss";
import loadPermissoes from "../../../contexts/RoutesContext";
import useCompany from "../../../hooks/useCompany";
import { useIsMount } from "../../../hooks/useIsMount";

function EmissaoLista() {
  const history = useHistory();

  const {
    getNotasFiscais,
    exportarPdfNfce,
    exportarXmlNfce,
    cancelarNotaProduto,
    listaNotaProduto,
  } = notasFiscaisService;

  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("nfce");

  const isMount = useIsMount();

  const [listRequest, setListRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModalCancelar, setOpenModalCancelar] = useState(false);
  const [nfeId, setNfeId] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [nfOptions, setNfOptions] = useState([]);
  const permissions = loadPermissoes();

  const [visible, setVisible] = useState(false);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Nº/Serie", dataRef: "n_serie" },
      { columnName: "Data de Emissão", dataRef: "data_hora_emissao" },
      { columnName: "Empresa", dataRef: "razao_social" },
      { columnName: "Valor", dataRef: "valor_total" },
    ],
    options: {
      more: [
        {
          icon: "nf-e",
          label: "Download XML",
          action: "abrir-nfe",
        },
        {
          icon: "cancel",
          label: "Cancelar NF-e",
          action: "cancelar-nfe",
        },
        {
          icon: "search",
          label: "Busca SEFAZ",
          action: "busca-sefaz",
        },
        {
          icon: "copy-icon",
          label: "Copiar NF",
          action: "copiar-nf",
        },
      ],
      nfe: true,
    },
    display: {
      statusLabels: true,
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      filter: false,
    },
    tableOptions: {
      filter: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: "data_hora_emissao",
    orderByType: "desc",
    perPage: null,
    searchTerm: null,
    id_empresa: null,
  });

  let updateTable = useCallback(async () => {
    setLoading(true);
    window.scrollTo(0, 0);

    const response = await listaNotaProduto(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.id_empresa
    );

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    const { data } = response;

    let dataArray = [];
    if (data !== undefined && data.data !== undefined) {
      dataArray = data.data.map((item) => {
        const n_serie = `${item.numero_nf}-${item.serie}`;

        return {
          ...item,
          n_serie,
          valor_total: numberToCurrency(item.valor_total),
          id: item.id_nota_fiscal_produto,
        };
      });

      const newListRequest = {
        ...data,
        data: dataArray,
      };

      setListRequest(newListRequest);
    } else {
      dataArray = data.map((item) => {
        const n_serie = `${item.numero_nf}-${item.serie}`;

        return {
          ...item,
          n_serie,
          valor_total: numberToCurrency(item.valor_total),
          id: item.id_nota_fiscal_produto,
        };
      });

      const newListRequest = {
        ...data,
        data: dataArray,
      };

      setListRequest(newListRequest);
    }

    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
    getNotasFiscais,
  ]);

  let loadNfOptions = useCallback(() => {
    let list = [];
    let nfs = permissions.find((item) => item.path === "/emissao/nfs");
    let nfc = permissions.find((item) => item.path === "/emissao/nfc");
    let nf = permissions.find((item) => item.path === "/emissao/nfe");
    if (nfs && nfs.accessGranted) list.push({ id: "nfs", name: "NFS-e" });
    if (nfc && nfc.accessGranted) list.push({ id: "nfc", name: "NFC-e" });
    if (nf && nf.accessGranted) list.push({ id: "nfe", name: "NF-e" });

    setNfOptions(list);
  }, [setNfOptions]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    if (orderBy === "n_serie") orderBy = "numero_nf";
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  // let onEdit = (id) => {
  //   console.log(`Edit: ${id}`);
  // };

  // let onDelete = (id) => {
  //   console.log(`Delete: ${id}`);
  // };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }
  async function cancelarNfe() {
    let response = await cancelarNotaProduto(nfeId);
    setLoading(true);
    console.log(response);

    if (response.hasOwnProperty("response")) {
      response = response.response;
    }

    if (response.status === 401) {
      setOpenModalCancelar(false);
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status === 400) {
      setLoading(false);
      setCallbackType("error");
      setCallbackMessage(response.data?.msg ? response.data?.msg : "Erro");
      setCallbackErrorList(
        response.data?.error
          ? Object.values(response.data?.error).flat(Infinity)
          : "Houve um erro ao cancelar a nota."
      );
      setCallbackShown(true);
    }

    if (response.status === 201 || response.status === 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Nota fiscal cancelada.");
      updateTable();
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
    }
    setOpenModalCancelar(false);
  }

  const onBuscaSefaz = async (id) => {
    console.log(id);
    const response = await notasFiscaisService.consultaNfceSefaz(id);

    if (response.status === 200) {
      setCallbackType("success");
      setCallbackDuration(4000);
      setCallbackMessage(
        response?.data?.message ? response.data.message : "Sucesso!"
      );
      setCallbackShown(true);
      updateTable();
    }
    if (response.status === 400) {
      setCallbackType("error");
      setCallbackDuration(4000);
      setCallbackMessage("Erro!");
      setCallbackErrorList(
        response?.data?.error?.length > 0
          ? response.data.error
          : ["Ocorreu um erro ao consultar a nota"]
      );
      setCallbackShown(true);
    }

    if (response.status === 500) {
      setCallbackType("error");
      setCallbackDuration(5000);
      setCallbackMessage("Erro!");
      setCallbackErrorList([
        "Erro interno do servidor. Por favor, contate o suporte.",
      ]);
      setCallbackShown(true);
    }

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
    }
  };

  let onMoreAction = async (action) => {
    setLoading(true);
    switch (action.action) {
      case "abrir-nfe": {
        let response = await exportarXmlNfce(Number(action.id));

        if (response.hasOwnProperty("response")) {
          response = response.response;
        }

        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }

        if (response.status === 500) {
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackDuration(3000);
          setCallbackMessage("Erro!");
          setCallbackErrorList([
            "Erro interno no servidor. Contate o suporte.",
          ]);
          return;
        }

        if (response.status === 400) {
          setLoading(false);
          setCallbackType("error");
          setCallbackDuration(3000);
          setCallbackMessage("Erro");
          setCallbackErrorList(
            response.data?.msg
              ? [response.data?.msg]
              : ["Houve um erro ao exportar o XML da nota."]
          );
          setCallbackShown(true);
          return;
        }

        base64ToFile(
          "data:application/xml;base64," + response.data.xml,
          "nota_fiscal"
        );
        setLoading(false);

        // else {
        //   setCallbackMessage(response.data.message);
        //   if (typeof response.data.error === "object") {
        //     setLoading(false);
        //     handleErrors(Object.keys(response.data.error));
        //     return;
        //   }
        //   if (typeof response.data.error === "string") {
        //     setLoading(false);
        //     setCallbackShown(true);
        //     setCallbackType("error");
        //     setCallbackErrorList([response.data.error]);
        //   }
        // }

        break;
      }
      case "cancelar-nfe": {
        setOpenModalCancelar(true);
        setNfeId(Number(action.id));
        setLoading(false);
        break;
      }
      case "busca-sefaz": {
        setLoading(true);

        await onBuscaSefaz(action.id);

        setLoading(false);
        break;
      }
      case "copiar-nf": {
        console.log(action.id);
        setLoading(true);
        notasFiscaisService
          .dadosNfceSefaz(action.id)
          .then((response) => {
            if (response.status === 200) {
              console.log("Dados nota fiscal NFCE");
              console.log(response.data);

              return history.push("/emitir-nfce", {
                nfcsCopiada: response?.data[0],
              });
            }
          })
          .catch(() => {
            console.log("Error");
          })
          .finally(() => {
            setLoading(false);
          });
        break;
      }
      default:
        break;
    }
    // setLoading(false);
  };

  let onFile = async (id) => {
    setLoading(true);
    let response = await exportarPdfNfce(Number(id));

    if (response.hasOwnProperty("response")) {
      response = response.response;
    }

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 500) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Erro interno no servidor. Contate o suporte."]);
      return;
    }

    if (response.status === 400) {
      setLoading(false);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro");
      setCallbackErrorList(
        response.data?.msg
          ? [response.data?.msg]
          : ["Houve um erro ao exportar o PDF da nota."]
      );
      setCallbackShown(true);
      return;
    }

    base64ToFile(
      "data:application/pdf;base64," + response.data.pdf,
      "nota_fiscal"
    );
    setLoading(false);
  };

  let handleEmitirNota = useCallback(() => {
    setVisible((prev) => !prev);
  }, []);

  let handleSelectNfOption = async (event) => {
    const itemId = event.target.dataset.id;
    setVisible(false);
    setLoading(true);

    if (itemId === "nfs") {
      return history.push("/emitir-nfse");
    }

    if (itemId === "nfc") {
      return history.push("/emitir-nfce");
    }

    if (itemId === "nfe") {
      return history.push("/emitir-nfe");
    }

    setLoading(false);
  };

  function listaStatus(list = []) {
    return list.map((item) => {
      let labelColor = "";

      switch (item.descricao_status_SEFAZ) {
        case "Autorizado o uso da NF-e":
          labelColor = "#8ac64b";
          break;

        case "NF-e já está cancelada na base de dados da SEFAZ":
          labelColor = "#bdbdbd";
          break;

        case "Cancelamento para NF-e já cancelada":
          labelColor = "#bdbdbd";
          break;

        case "Cancelamento de NF-e homologado":
          labelColor = "#bdbdbd";
          break;

        case null:
          labelColor = "transparent";
          break;

        default:
          labelColor = "#b00b00";
          break;
      }

      return (
        <div
          className={classes.statusLabel}
          style={{ backgroundColor: labelColor }}
        >
          {item.descricao_status_SEFAZ}
        </div>
      );
    });
  }

  const handleSelectEmpresa = async (id) => {
    console.log(id);
    setEmpresaSelecionada(id);
  };

  const handleApplyFilters = (empresaStorage = null) => {
    if (empresaStorage) {
      return setDefaultConfigTable({
        ...defaultConfigTable,
        id_empresa: empresaStorage,
      });
    }
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: empresaSelecionada,
    });
    setEmpresaPagina("nfce", empresaSelecionada);
  };

  const handleCleanFilters = async () => {
    setEmpresaSelecionada(null);
    setDefaultConfigTable({
      ...defaultConfigTable,
      id_empresa: null,
    });
    setEmpresaPagina("nfce", null);
  };

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12}>
            <Select
              styleType="form"
              label="Empresa"
              required
              initialValue={
                empresaSelecionada !== undefined
                  ? listEmpresas.find((item) => item.id === empresaSelecionada)
                      ?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={handleSelectEmpresa}
            />
          </Grid>
          <Grid className={classes.marginLeftAuto}>
            <div className="filter-button-area align-rigth">
              <Button
                onClick={handleApplyFilters}
                text="Filtrar"
                className="btn-primary"
              />
              <Button
                onClick={handleCleanFilters}
                text="Limpar Filtros"
                className="default-outline clean-filters-button"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  useEffect(() => {
    loadNfOptions();
  }, [loadNfOptions]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("nfce");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  return (
    <>
      <div className={classes.nfHeaderButton}>
        <Button
          onClick={handleEmitirNota}
          className="btn-primary icon-right"
          text="Emitir Nota Fiscal"
          icone="hamburger"
          iconeColor="#fff"
          iconeSize={20}
        />
        <div className={classes.nfOptionsContainer}>
          <ul className={visible ? classes.visible : ""}>
            {nfOptions.map((item, index) => (
              <li
                key={index}
                data-id={item.id}
                onMouseDown={handleSelectNfOption}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {loading && <OverlayLoading />}
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbFile={onFile}
        cbMoreAction={onMoreAction}
        filterComponent={filterComponent()}
        customColumns={[
          {
            columnHead: <span>Status</span>,
            columnData: listaStatus(listRequest?.data),
          },
        ]}
      />
      <embed src="" type="application/pdf" width="100%" height="100%" />
      <ConfirmDialog
        acceptLabel="Sim"
        cancelLabel="Sair"
        btnClass="btn-success-strong"
        description="Você tem certeza que deseja cancelar essa NF-e?"
        open={openModalCancelar}
        title="CONFIRMAR CANCELAMENTO"
        onAccept={cancelarNfe}
        onCancel={() => {
          setOpenModalCancelar(false);
        }}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default EmissaoLista;
