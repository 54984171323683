import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import usuariosService from "../../../services/usuariosService";
import validateFields from "../../../utils/validateFields";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import Input from "../../../components/Input";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import ButtonComponent from "../../../components/ButtonComponent";
import "./styles.scss";
import { Grid } from "@material-ui/core";
import SelectComponent from "../../../components/SelectComponent";
import { ImagemPerfil } from "../../../components/ImagemPerfil";
import { AuthContext } from "../../../contexts/AuthContext";

export const EditarPerfil = () => {
  const history = useHistory();
  const { setAvatar } = useContext(AuthContext)
  const {
    atualizaUsuarioLogado,
    consultaUsuarioLogadoEdicao,
    selecionaPeriodoParaRenovacaoSenha,
  } = usuariosService;
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [imagem, setImagem] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [diasParaRenovacaoSenha, setDiasParaRenovacaoSenha] = useState(null);

  const [listPeriodosRenovacaoSenha, setListPeriodosRenovacaoSenha] = useState(
    []
  );

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [loading, setLoading] = useState(false);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/");
    }
  }

  async function handleUserSubmit() {
    const fieldsToValidate = [
      {
        label: "nome",
        value: nome,
      },
      {
        label: "email",
        value: email,
      },
      {
        label: "diasParaRenovacaoSenha",
        value: diasParaRenovacaoSenha,
      },
      {
        label: "senha",
        value: password,
      },
      {
        label: "confirmarsenha",
        value: confirmPassword,
      },
    ];
    const fieldsErrors = validateFields(fieldsToValidate);

    if (password !== confirmPassword) {
      fieldsErrors.push("As senhas não são iguais");
    }

    if (password.length < 6) {
      fieldsErrors.push("A senha deve ter pelo menos 6 caracteres");
    }
  
    if (!/[A-Z]/.test(password)) {
      fieldsErrors.push("A senha deve conter pelo menos uma letra maiúscula");
    }
  
    if (!/\d/.test(password)) {
      fieldsErrors.push("A senha deve conter pelo menos um número");
    }
  
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      fieldsErrors.push("A senha deve conter pelo menos um caractere especial");
    }

    if (fieldsErrors.length !== 0) {
      setCallbackErrorList(fieldsErrors);
      setCallbackShown(true);
      setCallbackType("error");
      setLoading(false);
      return;
    }

    setLoading(true);

    const response = await atualizaUsuarioLogado({
      password,
      email,
      cpf,
      diasParaRenovacaoSenha,
      imagem,
      name: nome,
    });

    if (response?.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status !== 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      return;
    }
    if (response?.status === 200) {
      setAvatar(imagem)
      window.localStorage.setItem("avatar", imagem);

      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Usuário editado com sucesso!");
    }
  }

  const validatePassword = (value) => {
    let message = "";
  
    if (value.length < 6) {
      message = "A senha deve ter pelo menos 6 caracteres.";
    } else if (!/[A-Z]/.test(value)) {
      message = "A senha deve conter pelo menos uma letra maiúscula.";
    } else if (!/\d/.test(value)) {
      message = "A senha deve conter pelo menos um número.";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      message = "A senha deve conter pelo menos um caractere especial.";
    }
  
    setPasswordMessage(message);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  async function loadUser() {
    setLoading(true);
    const response = await consultaUsuarioLogadoEdicao();
    if (response?.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    } else if (response.status === 400) {
      setCallbackType("error");
      setCallbackMessage(response.data.msg || response.data.message || "Erro!");
      setCallbackErrorList(
        [response.data.error] || ["Erro ao consultar usuário!"]
      );
      setLoading(false);
      setCallbackShown(true);
      return;
    }
    const { data } = response;

    setNome(data.name);
    setEmail(data.email);
    setCpf(data.cpf);
    setImagem(data.avatar || null);
    setDiasParaRenovacaoSenha(data.dias_para_renovacao_senha);
    setLoading(false);
  }

  async function loadPeriodosRecuperacaoSenha() {
    const response = await selecionaPeriodoParaRenovacaoSenha();
    if (response?.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListPeriodosRenovacaoSenha(response.data);
  }

  useEffect(() => {
    loadUser();
    loadPeriodosRecuperacaoSenha();
  }, []);

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <CallbackMessage
            open={callbackShown}
            duration={callbackDuration}
            errorList={callbackErrorList}
            handleClose={handleClose}
            message={callbackMessage}
            type={callbackType}
          />
          <form className="form form-edit-profile">
            <Grid container spacing={2}>
              <ImagemPerfil
                xs={12}
                md={3}
                src={imagem}
                alt="Imagem perfil"
                showButton
                textButton="Editar foto"
                fileFormat=".jpeg,.jpg,.png,.svg"
                handleImageChange={({ base64 }) => setImagem(base64)}
              />

              <Grid item container spacing={2} xs={12} md={9}>
                <Grid item xs={12} md={6}>
                  <Input
                    nome="nome"
                    label="Nome"
                    tipo="text"
                    required={true}
                    value={nome}
                    handleInputChange={(event) => setNome(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    nome="email"
                    label="E-mail"
                    tipo="text"
                    required={true}
                    value={email}
                    handleInputChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    nome="cpf"
                    label="CPF"
                    tipo="cpf"
                    required={true}
                    value={cpf}
                    handleInputChange={(event) => setCpf(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectComponent
                    list={listPeriodosRenovacaoSenha}
                    label="Renovação de senha"
                    title="Selecione um período"
                    styleType="form"
                    required
                    initialValue={
                      listPeriodosRenovacaoSenha.find(
                        (item) => item.id === diasParaRenovacaoSenha
                      )?.nome || null
                    }
                    callback={(id) => setDiasParaRenovacaoSenha(id)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    nome="password"
                    label="Senha"
                    tipo="text"
                    type="password"
                    required={true}
                    handleInputChange={handlePasswordChange}
                    error={passwordMessage !== ""}
                    message={passwordMessage}
                  />
                  {passwordMessage && <div className="password-message">{passwordMessage}</div>}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    nome="confirmPassword"
                    label="Confirmar senha"
                    tipo="text"
                    type="password"
                    required={true}
                    handleInputChange={(event) =>
                      setConfirmPassword(event.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </span>
      </div>
      <div className="button-area editar-usuario-button-area">
        <span>
          <ButtonComponent
            type="button"
            text="Salvar"
            className="btn-success"
            onClick={handleUserSubmit}
          />
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
};
