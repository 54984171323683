import React, { useState } from "react";
import { FiInstagram, FiFacebook, FiYoutube, FiLinkedin } from "react-icons/fi";
import "./styles.css";

function NavbarFooter() {
  const [versao, serVersao] = useState(
    window.localStorage.getItem("versao") || ""
  );

  const handleIconClick = (socialNetwork) => {
    switch (socialNetwork) {
      case "instagram":
        return window.open(
          process.env.REACT_APP_CONTABILHUB_INSTAGRAM,
          "_blank"
        );

      case "facebook":
        return window.open(
          process.env.REACT_APP_CONTABILHUB_FACEBOOK,
          "_blank"
        );

      case "youtube":
        return window.open(process.env.REACT_APP_CONTABILHUB_YOUTUBE, "_blank");

      case "linkedin":
        return window.open(
          process.env.REACT_APP_CONTABILHUB_LINKEDIN,
          "_blank"
        );

      default:
        break;
    }
  };

  return (
    <footer className="navbar-footer">
      <div className="navbar-footer-social-network">
        <FiInstagram onClick={() => handleIconClick("instagram")} />
        <FiFacebook onClick={() => handleIconClick("facebook")} />
        <FiYoutube onClick={() => handleIconClick("youtube")} />
        <FiLinkedin onClick={() => handleIconClick("linkedin")} />
      </div>
      <p className="navbar-footer-description">
        Acessível e Fácil como deve ser!
      </p>
      <p className="navbar-footer-description">Versão {versao}</p>
    </footer>
  );
}

export default NavbarFooter;
