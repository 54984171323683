import React, { useState } from "react";
import Input from "../../components/Input";
import DatePicker from "../../components/DatePicker";
import "./styles.css";

function ViewInput() {
  const [valueInput, setValueInput] = useState("");
  const [dateValue, setDateValue] = useState("");

  return (
    <div className="session-container">
      <span className="session-container-header">
        <form className="session-container-form">
          <span>
            <span>
              Value do input: <br />
              <input value={valueInput} readOnly />
            </span>
          </span>
          <fieldset>
            <Input
              title="CPF"
              label="Cpf"
              tipo="cpf"
              required="true"
              onInputChange={(e) => setValueInput(e.target.value)}
            />
            <Input
              title="CNPJ"
              label="Cnpj"
              tipo="cnpj"
              required="false"
              onInputChange={(e) => setValueInput(e.target.value)}
            />
            <Input
              title="Data"
              label="Data"
              tipo="data"
              required="false"
              onInputChange={(e) => setValueInput(e.target.value)}
            />

            <br />
            <br />

            <DatePicker
              label="Escolha a data"
              value={dateValue}
              handleChange={(date) => {
                // console.log("aqui", date);
                setDateValue(date);
              }}
            ></DatePicker>

            <br />
            <span>Retorno do DatePicker: {dateValue}</span>
            <br />
            <br />
            <br />

            <Input
              title="Celular"
              label="Celular"
              tipo="celular"
              required="true"
              onInputChange={(e) => setValueInput(e.target.value)}
            />
            <Input
              title="Telefone"
              label="Telefone"
              tipo="telefone"
              required="false"
              onInputChange={(e) => setValueInput(e.target.value)}
            />
            <Input
              title="CEP"
              label="Cep"
              tipo="cep"
              required="true"
              onInputChange={(e) => setValueInput(e.target.value)}
            />
            <Input
              title="Fulano de tal"
              label="Nome"
              tipo="nome"
              required="true"
              onInputChange={(e) => setValueInput(e.target.value)}
            />
            <Input
              title="E-mail"
              label="e-mail"
              tipo="email"
              type="email"
              onInputChange={(e) => setValueInput(e.target.value)}
              required="true"
            />
            <Input
              title="Numero"
              label="Numero"
              tipo="numero"
              type="number"
              onInputChange={(e) => setValueInput(e.target.value)}
              required="false"
            />
            <Input
              title="Senha"
              label="Senha"
              tipo="senha"
              type="password"
              onInputChange={(e) => setValueInput(e.target.value)}
              required="false"
            />
            <Input
              title=""
              label="Semana"
              tipo="Semana"
              type="week"
              required="false"
            />

            <Input
              title=""
              label="Mês"
              tipo="Mês"
              type="month"
              required="false"
            />
            <Input
              title="Anexo"
              label="Anexo"
              tipo="Anexo"
              type="file"
              required="false"
            />
          </fieldset>
        </form>
      </span>
    </div>
  );
}

export default ViewInput;
