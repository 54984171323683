import { Dialog, DialogTitle, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import pdvService from '../../../services/pdvService';
import { toDateDD_MM_YYYYandHours } from '../../../utils/dates';
import validateFields from "../../../utils/validateFields";
import ButtonComponent from "../../ButtonComponent";
import CallbackMessage from "../../CallbackMessage";
import InputComponent from "../../Input";
import CheckBox from "../../InputCheck";
import ModalLoginExpirado from "../../ModalLoginExpirado";
import OverlayLoading from "../../OverlayLoading";
import "./styles.scss";



function ModalSangria({
  open,
  onClose,
  onSave,
  idCaixa,
}) {
  const { sangriaCaixa } = pdvService;

  const [registrar, setRegistrar] = useState(false);
  const [dataAbertura, setDataAbertura] = useState(null);
  const [motivo, setMotivo] = useState(null);
  const [valorSangria, setValorSangria] = useState(null);

  useEffect(() => { 
    if(dataAbertura === null) {
      setDataAbertura(toDateDD_MM_YYYYandHours(new Date()));
    }
  }, [dataAbertura])


  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  async function handleSubmit() {
    setErrors([]);

    let fields = [
      {
        label: "valor",
        value: valorSangria,
      },
      {
        label: "motivo",
        value: motivo,
      },
    ];
    
    const fieldsValidate = validateFields(fields);

    if (fieldsValidate.length !== 0) {
      setShowMessage(true);
      setErrors(fieldsValidate);
      return;
    }

    const response = await sangriaCaixa(idCaixa, valorSangria, motivo);

    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }

    if (response.status === 400) {
      setErrors(Object.values(response?.data?.error).flat(Infinity));
      setShowMessage(true);
    }

    if (response.status === 500) {
      setErrors(['Erro interno no servidor, contate o suporte.']);
      setShowMessage(true);
    }
    
    if(response.status === 200 || response.status === 201) {
      handleCloseModal();
      onSave('sangria');
    }

    setLoading(false);
  }

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }

  const handleCloseModal = () => {
    setShowMessage(false);
    setValorSangria(null);
    setMotivo(null);
    setDataAbertura(null);
    onClose();
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>SANGRIA</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
                <Grid item xs={12} sm={12} md={7}>
                <InputComponent
                  nome="sangria"
                  label="Valor"
                  title="R$"
                  tipo="dinheiro"
                  value={valorSangria}
                  handleInputChange={(event) => {
                    setValorSangria(event.value);
                  }}
                  required
                />
                </Grid>
                <Grid item xs={12} sm={12} md={5} className="modal-items">
                  <div>
                  <label>Data e hora</label>
                  <span>{dataAbertura}</span>
                  </div>
               </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <InputComponent
                  nome="motivo"
                  label="Motivo"
                  title="Digite o motivo"
                  tipo="text"
                  required
                  value={motivo}
                  handleInputChange={(event) => {
                    setMotivo(event.target.value);
                  }}
                  required
                />
                </Grid>

                {/* <Grid item xs={12} sm={12} md={12}>
                  <div className="input-checkbox-finances">
                    <div>
                  <CheckBox
                    className="icon checkbox"
                    nome="registrar"
                    checkedValue={registrar}
                    id="registrar"
                    onInputChange={() => setRegistrar(!registrar)}
                  />
                  </div>
                  <span>Registrar saída no Financeiro</span>
                  </div>
                </Grid> */}

                <Grid item xs={12}>
                <span className="required-text">* Campos obrigatórios</span>
                </Grid>

              <Grid container justify="flex-end" item>
                <ButtonComponent
                  text="Salvar"
                  onClick={handleSubmit}
                  className="btn-success zero-margin"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalSangria;
