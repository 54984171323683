import Axios from "./Axios";

const categoriaEmpresaService = {
  listCategorias: async ({
    idEmpresa,
    orderBy = null,
    orderByType = null,
    page = null,
    perPage = null,
  }) => {
    const response = await Axios.get(`/lista-categoria-empresa`, {
      params: {
        id_empresa: idEmpresa,
        order_by: orderBy,
        order_by_type: orderByType,
        page,
        per_page: perPage,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  cadastrarCategoria: async ({ idEmpresa, nome, idGrupo, status }) => {
    const response = await Axios.post(`/cadastra-categoria-empresa`, {
      id_empresa: idEmpresa,
      nome,
      id_grupo: idGrupo,
      status,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  consultaCategoria: async (id) => {
    const response = await Axios.get(`/consulta-categoria-empresa/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  atualizarCategoria: async ({ id, idEmpresa, nome, idGrupo, status }) => {
    const response = await Axios.put(`/atualiza-categoria-empresa/${id}`, {
      id_empresa: idEmpresa,
      nome,
      id_grupo: idGrupo,
      status,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  atualizarStatusCategoria: async (id) => {
    const response = await Axios.put(`/atualiza-status-categoria-empresa/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  importarCategoria: async ({ arquivo, id_empresa }) => {
    const response = await Axios.post(`/importar-categorias`, {
      arquivo,
      id_empresa,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};

export default categoriaEmpresaService;
