import { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import loginService from "../services/loginService";
import usoService from "../services/usoService";
import { NavbarContext } from "../contexts/NavbarContext";

import cliente from "../config/cliente";

const perfilAcessoAdmin = [1, 2];
export default function useAuth() {
  const avatarStorage = window.localStorage.getItem("avatar");
  const history = useHistory();
  const { search } = useLocation();
  const [authenticated, setAuthenticated] = useState(
    window.localStorage.getItem("session")
  );
  const [isTempPassword, setIsTempPassword] = useState(false);
  const [shouldUpdatePassword, setShouldUpdatePassword] = useState(false);
  const [avatar, setAvatar] = useState(
    avatarStorage && avatarStorage !== "null" ? avatarStorage : null
  );
  const [inactiveDueInactivity, setInactiveDueInactivity] = useState(false);
  const [requestLoader, setRequestLoader] = useState(false);
  const [isAdmin, setIsAdmin] = useState(
    perfilAcessoAdmin.includes(
      Number(window.localStorage.getItem("perfil_acesso"))
    )
  );
  const { fetchNotification, setShowNavbar } = useContext(NavbarContext);

  useEffect(() => {
    (async () => {
      const token = window.localStorage.getItem("session");

      if (token) {
        setAuthenticated(true);
      }
    })();
  }, []);

  async function handleSession(dataSession) {
    window.localStorage.setItem(
      "permissoes",
      JSON.stringify(dataSession.lista_permissoes)
    );
    window.localStorage.setItem("session", dataSession.token.token);
    window.localStorage.setItem("id_usuario", dataSession.id_usuario);
    window.localStorage.setItem("perfil_acesso", dataSession.perfil_acesso);
    window.localStorage.setItem("empresa_usuario", dataSession.empresa_usuario);
    window.localStorage.setItem("plano", dataSession.plano);
    window.localStorage.setItem(
      "id_tipo_pessoa",
      dataSession?.pessoa?.id_tipo_pessoa
    );
    window.localStorage.setItem(
      "codigo_promocional",
      dataSession?.codigo_promocional
    );
    setIsAdmin(perfilAcessoAdmin.includes(Number(dataSession.perfil_acesso)));
  }

  async function handleLogin(userInput, callbackAlert) {
    const { email, password } = userInput;

    if (!requestLoader) {
      setRequestLoader(true);
      const resultLogin = await loginService.checkLogin({
        email,
        password,
      });

      if (resultLogin) {
        setRequestLoader(false);
        if (resultLogin.status === 200) {
          if (
            resultLogin?.data?.success === false &&
            resultLogin?.data?.message
          ) {
            if (resultLogin.data?.desativado_por_inatividade) {
              setInactiveDueInactivity(true);
            } else {
              setInactiveDueInactivity(false);
            }
            callbackAlert("error", resultLogin?.data?.message, 6000);
            return { inactive: true, userId: resultLogin.data?.id_usuario };
          }
          console.log({ data: resultLogin.data });
          if (cliente?.cliente === "contabilhub") {
            if (resultLogin.data?.bloqueio === 1) {
              window.localStorage.setItem("status_uso", 2);

              window.localStorage.setItem(
                "invoice_id",
                resultLogin.data?.id_fatura
              );

              callbackAlert(
                "error",
                "Ops! Houve algum problema no seu acesso... 🙁 Mas fica tranquilo, contate nosso suporte que resolvemos isso rapidinho pra você ! 😊",
                6000
              );

              return;
            }

            if (resultLogin.data?.inativo === 1) {
              window.localStorage.setItem("status_uso", 2);

              window.localStorage.setItem(
                "invoice_id",
                resultLogin.data?.id_fatura
              );

              callbackAlert(
                "error",
                "Ops! Houve algum problema no seu acesso... 🙁 Mas fica tranquilo, contate nosso suporte que resolvemos isso rapidinho pra você ! 😊",
                6000
              );

              return;
            }
            setAvatar(resultLogin.data?.imagem);
            window.localStorage.setItem("avatar", resultLogin.data?.imagem);

            if (resultLogin.data?.senha_temporaria) {
              setIsTempPassword(true);
              callbackAlert("success", "Bem-vindo", 2000);
              return {
                userId: resultLogin.data?.id_usuario,
                token: resultLogin.data?.token.token,
              };
            } else {
              setIsTempPassword(false);
            }
            if (resultLogin.data?.atualizar_senha) {
              setShouldUpdatePassword(true);
              callbackAlert("success", "Bem-vindo", 2000);
              return {
                userId: resultLogin.data?.id_usuario,
                token: resultLogin.data?.token.token,
              };
            } else {
              setShouldUpdatePassword(false);
            }

            if (
              resultLogin.data?.bloqueio === 0 &&
              resultLogin.data?.id_fatura !== null
            ) {
              window.localStorage.setItem("status_uso", 1);
              window.localStorage.setItem(
                "versao",
                resultLogin.data?.nome_versao || ""
              );
              window.localStorage.setItem(
                "exibir_modal_versao",
                resultLogin.data?.exibir_modal_versao || ""
              );
              window.localStorage.setItem(
                "id_versao",
                resultLogin.data?.id_versao || null
              );
              window.localStorage.setItem(
                "invoice_id",
                resultLogin.data?.id_fatura
              );
              callbackAlert("success", "Bem-vindo", 2000);
            }
          } else {
            if (resultLogin.data?.bloqueio === 1) {
              window.localStorage.setItem("status_uso", 2);
              window.localStorage.setItem(
                "invoice_id",
                resultLogin.data?.id_fatura
              );
              callbackAlert("success", "Bem-vindo", 2000);

              return setTimeout(() => {
                history.push(`/checkout/fatura/${resultLogin.data?.id_fatura}`);
              }, 1200);
            }

            if (
              resultLogin.data?.bloqueio === 0 &&
              resultLogin.data?.id_fatura !== null
            ) {
              window.localStorage.setItem("status_uso", 1);
              window.localStorage.setItem(
                "invoice_id",
                resultLogin.data?.id_fatura
              );
              callbackAlert("success", "Bem-vindo", 2000);

              return setTimeout(() => {
                history.push(`/checkout/fatura/${resultLogin.data?.id_fatura}`);
              }, 1200);
            }
          }

          setAuthenticated(true);
          await handleSession(resultLogin.data);

          const responseUso = await usoService.verificaUso();
          if (responseUso.status === 200) {
            const { data } = responseUso;
            await fetchNotification();

            if (data["Não habilitado"] === 0) {
              window.localStorage.setItem("status_uso", 0);
              callbackAlert("success", "Bem-vindo", 2000);

              if (resultLogin.data?.descricao_plano_especifico) {
                return setTimeout(() => {
                  history.replace({
                    pathname: "/contrato",
                    state: {
                      descricao_plano_especifico:
                        resultLogin.data?.descricao_plano_especifico,
                    },
                  });
                }, 1200);
              }

              return setTimeout(() => {
                history.replace("/contrato");
              }, 1200);
            } else {
              setShowNavbar(false);
              window.localStorage.setItem("status_uso", 1);
              callbackAlert("success", "Bem-vindo", 2000);
              setTimeout(() => {
                if (search.startsWith("?pag=")) {
                  if (search.startsWith("?pag=faturas")) {
                    history.replace(
                      `/faturas/${resultLogin.data.empresa_usuario}`
                    );
                  } else {
                    history.replace("/" + search.split("pag=")[1]);
                  }
                } else {
                  history.push("/home");
                }
              }, 1200);
            }
          } else if (responseUso.status === 500) {
            callbackAlert(
              "error",
              "Erro na comunicação com o servidor. Contate o suporte"
            );
            return;
          }
        } else if (resultLogin.status === 401) {
          // callbackAlert("error", resultLogin.data.msg);
          callbackAlert("error", "Login ou senha inválido");
        } else if (resultLogin.status === 500) {
          callbackAlert(
            "error",
            "Erro na comunicação com o servidor. Contate o suporte"
          );
        } else {
          callbackAlert("error", "Ocorreu um erro. Verifique sua senha");
        }
      } else {
        setRequestLoader(false);
        callbackAlert(
          "error",
          "Erro na comunicação com o servidor. Contate o suporte"
        );
      }
    }
  }

  return {
    authenticated,
    handleLogin,
    requestLoader,
    isAdmin,
    isTempPassword,
    setIsTempPassword,
    shouldUpdatePassword,
    setShouldUpdatePassword,
    inactiveDueInactivity,
    avatar,
    setAvatar,
  };
}
