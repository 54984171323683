import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import SelectComponent from '../../../components/SelectComponent';
import ModalLoginExpirado from '../../../components/ModalLoginExpirado';
import FileInput from '../../../components/InputFile';
import ButtonComponent from '../../../components/ButtonComponent';
import OverlayLoading from '../../../components/OverlayLoading';
import CallbackMessage from '../../../components/CallbackMessage';

import validateFields from '../../../utils/validateFields';

import codigoServicosService from '../../../services/codigosServicoService';
import prefeiturasService from '../../../services/prefeiturasService';
import viacepServices from "../../../services/viacepServices";

import './styles.css';
import SmallScreen from '../../../utils/smallScreen';

function CadastroCodigosServico() {
  const isSmallScreen = SmallScreen();

  const [file, setFile] = useState(null);
  const [estado, setEstado] = useState('');
  const [prefeitura, setPrefeitura] = useState('');
  const [listEstados, setListEstados] = useState([]);
  const [listPrefeituras, setListPrefeituras] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState('');
  const [callbackMessage, setCallbackMessage] = useState('');
  const [callbackErrorList, setCallbackErrorList] = useState('');
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const { cadastraServico } = codigoServicosService;
  const { selecionaPrefeitura } = prefeiturasService;
  const { selecionaEstado } = viacepServices;

  const history = useHistory();

  async function loadPrefeituras(id) {
    const response = await viacepServices.selecionaCidade(id)
    if (response.status === 401) {
      setOpenLoginExpirado(true)
      setLoading(false)
      return
    }
    if (response.status === 200) {
      setListPrefeituras(response.data)
    }
  }

  async function loadEstados() {
    const response = await selecionaEstado();
    if (response.status === 401) {
      setOpenLoginExpirado(true)
      setLoading(false)
      return
    }
    if (response.status === 200) {
      setListEstados(response.data);
    }
  }

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      setCallbackShown(false);
    }
    if (reason === 'timeout') {
      setCallbackShown(false);
    }
    if (callbackType === 'success') {
      history.push('/lista-prefeituras');
    }
  }

  async function handleSubmit() {
    console.log('submit');
    setLoading(true);
    let hasErrors = false;

    const fieldsToValidate = [
      {
        label: 'estado',
        value: estado
      },
      {
        label: 'prefeitura',
        value: prefeitura
      },
      {
        label: 'anexo',
        value: file
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      hasErrors = true;
      setLoading(false);
      setCallbackType('error');
      setCallbackErrorList(fieldsErrors);
      setCallbackMessage('Erro!');
      setCallbackShown(true);
    }

    if (hasErrors) return;

    const result = await cadastraServico({
      prefeitura,
      arquivo: file
    }
    );
    console.log(result);
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.status !== 200 && result.status !== 201) {
      setLoading(false);
      setCallbackType('error');
      setCallbackMessage('Erro!');
      setCallbackShown(true);
      console.log(typeof result.data.error);
      if (typeof result.data.error === 'string') {
        if (result.data.msg && typeof result.data.msg === 'string') {
          setCallbackErrorList([result.data.msg]);
        } else{
          setCallbackErrorList([result.data.error]);
        }
      } else {
        setCallbackErrorList(Object.values(result.data.error));
      }
      return;
    }
    setLoading(false);
    setCallbackShown(true);
    setCallbackMessage(result?.data?.message ? result.data.message : 'Importado com sucesso');
    setCallbackType('success');
    setCallbackDuration(2000);
  }

  function handleChangeUf(id){
    setEstado(id)
    loadPrefeituras(id)
  }
  
  useEffect( () => {
    loadEstados();
  },[])

  return (
    <>
      <form className='session-container-form'>
        <div className='session-container'>
          <span className='session-container-header'>
            {loading && <OverlayLoading />}
            <div className={`${isSmallScreen ? '' : 'p-horizontal'}`}>
              <Grid item container direction='column' spacing={2}>
                <Grid item>
                  <SelectComponent
                    styleType='form'
                    label='Estado'
                    title='Selecione o estado'
                    list={listEstados}
                    required
                    callback={id => handleChangeUf(id)}
                  />
                </Grid>
                <Grid item>
                  <SelectComponent
                    styleType='form'
                    label='Prefeitura'
                    title='Selecione uma prefeitura'
                    list={listPrefeituras}
                    required
                    callback={id => setPrefeitura(id)}
                  />
                </Grid>
                <Grid item>
                  <FileInput
                    title='Anexo'
                    onChange={(base64, name, file) => {
                      console.log(file);
                      setFile(file)
                    }}
                  />
                </Grid>
                <Grid item>
                  <span className='required-text'>* Campos obrigatórios</span>
                </Grid>
              </Grid>
              <div style={{ height: 40 }} />
            </div>
          </span>
        </div>
      </form>
      <div style={{ height: 40 }} />
      <Grid item container justify='flex-end'>
        <ButtonComponent
          text='Salvar'
          className='btn-success zero-margin'
          onClick={handleSubmit}
        />
      </Grid>
      {callbackShown && (
        <CallbackMessage
          open={callbackShown}
          type={callbackType}
          handleClose={handleClose}
          message={callbackMessage}
          duration={callbackDuration}
          errorList={callbackErrorList}
        />
      )}
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastroCodigosServico;
