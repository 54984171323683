import React, { useState, useEffect, useCallback, useContext } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import InputComponent from "../../Input";
import ButtonComponent from "../../ButtonComponent";
import SelectComponent from "../../SelectComponent";
import InputFile from "../../InputFile";
import OverlayLoading from "../../OverlayLoading";
import CallbackMessage from "../../CallbackMessage";
import Icon from '../../Icon';
import DatePicker from "../../DatePicker";
import blackFridayServices from "../../../services/blackFridayServices";
import selectServices from "../../../services/selectServices";
import buscaCepService from "../../../services/buscaCepService";

import { onlyNumbers } from "../../../utils/strings";
import { NavbarContext } from "../../../contexts/NavbarContext";
import validateFields from "../../../utils/validateFields";
import { base64ToFile } from '../../../utils/functions';

import "./styles.scss";

function ModalAbrir({ open, onClose, data, companyId }) {
  const [isEdit, setIsEdit] = useState(false)

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [listTipo, setListTipo] = useState([]);
  const [listArea, setListArea] = useState([]);
  const id = companyId || window.localStorage.getItem("empresa_usuario");
  const { readNotification } = useContext(NavbarContext);
  const [field, setField] = useState({
    bairro: null,
    cep: null,
    cidade: null,
    codigo_simples: null,
    complemento: null,
    comprovante_endereco: null,
    data_nascimento: null,
    documento_identificador: null,
    endereco: null,
    endereco_responsavel: null,
    id_area_atuacao: null,
    id_socio: null,
    id_tipo_empresa: null,
    numero: null,
    numero_declaracao: null,
    senha_govbr: null,
    tipo_cadastro: 1,
    titulo_eleitor: null,
    uf: null,
  });

  useEffect(() => {
    if (data) {
      setField(data);
      setIsEdit(true);
    }
  }, [data])

  const loadListTipo = useCallback(async () => {
    const response = await selectServices.selecionaTipoEmpresa();
    if (response.status === 200) setListTipo(response.data);
  }, []);

  const loadListArea = useCallback(async () => {
    const response = await selectServices.selecionaGrupoAtuacao();
    if (response.status === 200) setListArea(response.data);
  }, []);

  function verifyFields() {
    let list = [];
    let validator = [
      {
        label: "tipoempresa",
        value: field.id_tipo_empresa
      },
      {
        label: "areaatuacao",
        value: field.id_area_atuacao
      },
      {
        label: "cep",
        value: field.cep
      },
      {
        label: "endereco",
        value: field.endereco
      },
      {
        label: "bairro",
        value: field.bairro
      },
      {
        label: "numero",
        value: field.numero
      },
      {
        label: "estado",
        value: field.uf
      },
      {
        label: "cidade",
        value: field.cidade
      },
      {
        label: "comprovanteendereco",
        value: field.comprovante_endereco
      },
      {
        label: "rgcpf",
        value: field.documento_identificador
      },
      {
        label: "comprovanteresponsavel",
        value: field.endereco_responsavel
      }
    ];

    list = validateFields(validator);

    if (list.length !== 0) {
      setShowMessage(true);
      setErrors(list);
    }
    return list.length === 0;
  }

  const handleGovBrPassVisibility = () => {
    setIsPasswordVisible(prev => !prev);
  };

  async function handleSubmit() {
    if (!verifyFields()) return;
    setLoading(true);
    const response = await blackFridayServices.cadastraComplemento(id, field);

    if (response.status === 200 || response.status === 201) {
      setLoading(false);
      setShowMessage(true);
      setErrors([]);
    } else {
      if (response.data.error)
        setErrors(Object.values(response.data.error));
      else setErrors(["Ocorreu um erro"]);
      setShowMessage(true);
      setLoading(false);
    }
  }

  function handleClose() {
    setShowMessage(false);
    if (errors.length === 0) {
      readNotification();
      onClose();
    }
  }

  useEffect(() => {
    let mounted = true;
    (async () => {
      if (!mounted) return;

      let obj = field;
      let cep = onlyNumbers(obj?.cep);

      if (cep === undefined) return;

      if (cep.length === 8) {
        setLoadingCep(true);
        const response = await buscaCepService.getCep(cep);
        if (response.status === 401) {
          setLoadingCep(false);
          return;
        }
        const data = response.data;
        if (!data.hasOwnProperty("erro")) {
          obj.uf = data.uf;
          obj.cidade = data.localidade;
          obj.endereco = data.logradouro;
          obj.bairro = data.bairro;
          obj.numero = obj.numero || "";
          obj.complemento = obj.complemento || "";
        }
        setField(obj);
        setLoadingCep(false);
      }
    })()
    return () => mounted = false;
  }, [field.cep])

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadListTipo();
      await loadListArea();
      setLoading(false);
    })();
  }, [loadListTipo, loadListArea]);

  const handleLink = useCallback(() => {
    window.open('https://sso.acesso.gov.br/login?client_id=contas.acesso.gov.br&authorization_id=176f186eaf', '_blank');
  }, []);
  
  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>CADASTRO COMPLEMENTAR</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <h1 className="cadastro-first-title">1. Sobre a empresa</h1>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <SelectComponent
                  styleType="form"
                  label="Qual tipo de empresa?"
                  required
                  list={listTipo}
                  initialValue={listTipo.find(item => item.id === field.id_tipo_empresa) ? listTipo.find(item => item.id === field.id_tipo_empresa).nome : ''}
                  callback={(id) => {
                    let obj = field;
                    obj.id_tipo_empresa = id;
                    setField(obj);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <SelectComponent
                  styleType="form"
                  label="Qual a sua área de atuação?"
                  required
                  list={listArea}
                  initialValue={listArea.find(item => item.id === field.id_area_atuacao) ? listArea.find(item => item.id === field.id_area_atuacao).nome : ''}
                  callback={(id) => {
                    let obj = field;
                    obj.id_area_atuacao = id;
                    setField(obj);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent 
                  value={field.senha_govbr}
                  label="Senha portal Gov.br"
                  tooltipType="infoButton"
                  labelSuffix={{
                    icon: "export",
                    title: "Portal Gov.br",
                    onClick: handleLink,
                  }}
                  type={isPasswordVisible ? "text" : "password"}
                  handleInputChange={event => {
                    let obj = { ...field };
                    obj.senha_govbr = event.target.value;
                    setField(obj);
                  }}
                />
                <button
                onClick={handleGovBrPassVisibility}
                className="gov-password-icon"
                type="button"
              >
                <div className="gov-password-icon-container">
                  {isPasswordVisible ? (
                    <Icon name="open-eye" />
                  ) : (
                      <Icon name="closed-eye" />
                    )}
                </div>
              </button>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  value={field.codigo_simples}
                  label="Código do Simples"
                  handleInputChange={(event) => {
                    let obj = { ...field };
                    obj.codigo_simples = event.target.value;
                    setField(obj);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <h1 className="cadastro-form-title">2. Endereço</h1>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <InputComponent
                loading={loadingCep}
                label="CEP"
                value={field.cep}
                required
                tipo="cep"
                handleInputChange={(event) => {
                  let obj = { ...field };
                  obj.cep = event.target.value;
                  setField(obj);
                }}
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  value={field.endereco}
                  label="Endereço"
                  required
                  handleInputChange={(event) => {
                    let obj = { ...field };
                    obj.endereco = event.target.value;
                    setField(obj);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  value={field.bairro}
                  label="Bairro"
                  required
                  handleInputChange={(event) => {
                    let obj = { ...field };
                    obj.bairro = event.target.value;
                    setField(obj);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  value={field.numero}
                  label="Numero"
                  tipo="numero"
                  required
                  handleInputChange={(event) => {
                    let obj = { ...field };
                    obj.numero = event.target.value;
                    setField(obj);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  value={field.complemento}
                  label="Complemento"
                  handleInputChange={(event) => {
                    let obj = { ...field };
                    obj.complemento = event.target.value;
                    setField(obj);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  value={field.uf}
                  label="Estado"
                  required
                  handleInputChange={(event) => {
                    let obj = { ...field };
                    obj.uf = event.target.value;
                    setField(obj);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <InputComponent
                  value={field.cidade}
                  label="Cidade"
                  required
                  handleInputChange={(event) => {
                    let obj = { ...field };
                    obj.cidade = event.target.value;
                    setField(obj);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {isEdit && field.comprovante_endereco !== null ? (
                <>
                  <span className="view-file-button-label">Comprovante de endereço *</span>
                  <ButtonComponent
                    className="default-outline view-file-button-component"
                    text="Visualizar"
                    icone="download-thin"
                    onClick={() => base64ToFile(field.comprovante_endereco, 'comprovante_endereco')}
                  />
                </>
              ) : (
                  <InputFile
                    id={1}
                    title="Comprovante de endereço *"
                    onChange={(file) => {
                      let obj = field;
                      obj.comprovante_endereco = file;
                      setField(obj);
                    }}
                    labelSuffix={{
                      icon: "info",
                      title: "Envie o comprovante da propriedade do endereço, contrato de aluguel ou cópia do último IPTU."
                    }}
                  />
                )}
            </Grid>
            <Grid item>
              <h1 className="cadastro-form-title">3. Responsável pela empresa</h1>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {isEdit && field.documento_identificador !== null ? (
                <>
                  <span className="view-file-button-label">Anexe RG e CPF ou CNH *</span>
                  <ButtonComponent
                    className="default-outline view-file-button-component"
                    text="Visualizar"
                    icone="download-thin"
                    onClick={() => base64ToFile(field.documento_identificador, 'documento_identificador')}
                  />
                </>
              ) : (
                  <InputFile
                    id={2}
                    title="Anexe RG e CPF ou CNH *"
                    onChange={(file) => {
                      let obj = field;
                      obj.documento_identificador = file;
                      setField(obj);
                    }}
                    labelSuffix={{
                      icon: "info",
                      title: "Documentos do responsável da empresa."
                    }}
                  />
                )}
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Número da declaração"
                  tipo="numero"
                  value={field.numero_declaracao}
                  handleInputChange={(event) => {
                    let obj = { ...field };
                    obj.numero_declaracao = event.target.value;
                    setField(obj);
                  }}
                  labelSuffix={{
                    icon: "info",
                    title: "Agora precisamos do número do recibo da entrega da sua última declaração de Imposto de Renda, caso nunca tenha declarado preciso do número do seu título de eleitor com secção e zona eleitoral."
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Título de eleitor"
                  tipo="numero"
                  value={field.titulo_eleitor}
                  handleInputChange={(event) => {
                    let obj = { ...field };
                    obj.titulo_eleitor = event.target.value;
                    setField(obj);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
              <div className="datepicker">
              <DatePicker
                  initDate={field.data_nascimento}
                  label="Data de nascimento"
                  readOnly={!field?.id_socio}
                  noMask
                  handleChange={(value) => {
                    let obj = { ...field };
                    obj.data_nascimento = value;
                    setField(obj);
                  }}
                />
                </div>
              </Grid>

            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {isEdit && field.endereco_responsavel !== null ? (
                <>
                  <span className="view-file-button-label">Comprovante de residência dos responsáveis pela empresa *</span>
                  <ButtonComponent
                    className="default-outline view-file-button-component"
                    text="Visualizar"
                    icone="download-thin"
                    onClick={() => base64ToFile(field.endereco_responsavel, 'comprovante_residencia_responsaveis')}
                  />
                </>
              ) : (
                  <InputFile
                    id={3}
                    title="Comprovante de residência dos responsáveis pela empresa *"
                    onChange={(file) => {
                      let obj = field;
                      obj.endereco_responsavel = file;
                      setField(obj);
                    }}
                    labelSuffix={{
                      icon: "info",
                      title: "Precisamos de um comprovante de endereço atualizado."
                    }}
                  />
                )}

            </Grid>
            <Grid item>
              <span className="required-text">
                * Campos obrigatórios
              </span>
            </Grid>
            <Grid item container justify="flex-end">
              <ButtonComponent text="Salvar" onClick={handleSubmit} className="btn-success zero-margin" />
            </Grid>
          </Grid>
        </form>
        <CallbackMessage
          open={showMessage}
          type={`${errors.length === 0 ? "success" : "error"}`}
          message={`${errors.length === 0
            ? "Cadastro complementado com sucesso!"
            : "Erro!"
            }`}
          errorList={errors}
          handleClose={handleClose}
          duration={errors.length === 0 ? 4000 : 6000}
        />
      </div>
    </Dialog>
  );
}

export default ModalAbrir;