import Axios from "./Axios";

const viacepServices = {
  selecionaEstado: async () => {
    const response = await Axios.get("/seleciona-estado", {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selecionaCidade: async (estadoId) => {
    const response = await Axios.get(`/seleciona-cidade/${estadoId}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};

export default viacepServices;
