import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import Input from "../../../components/Input";
import DatePicker from "../../../components/DatePicker";
import InputFile from "../../../components/InputFile";
import Button from "../../../components/ButtonComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import Icon from "../../../components/Icon";

import validateFields from "../../../utils/validateFields";

import empresasService from "../../../services/empresasService";

import "./styles.scss";
import { NavbarContext } from "../../../contexts/NavbarContext";

function CadastroCategorias() {
  const params = useParams();
  const history = useHistory();
  const { readNotification } = useContext(NavbarContext);
  const {
    consultaEmpresa,
    cadastrarCertificado,
    consultarCertificado,
  } = empresasService;
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [empresa, setEmpresa] = useState("");
  const [file, setFile] = useState();
  const [senha, setSenha] = useState("");
  const [data, setData] = useState("");
  const [fileName, setFileName] = useState("");

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { id } = params;
    (async function getCertificado() {
      setLoading(true);
      const response = await consultarCertificado(id);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 400) {
        setCallbackType("error");
        setCallbackMessage("Erro!");
        setCallbackShown(true);
        setCallbackDuration(6000);
        if (response.data.error)
          setCallbackErrorList([Object.values(response.data.error)]);
        else setCallbackErrorList(["Ocorreu um erro"]);
      }

      const { Certificado } = response.data;

      if (Certificado) {
        if (Certificado.data_validade_certificado_digital !== null) {
          setData(Certificado.data_validade_certificado_digital);
        }
        if (Certificado.arquivo !== null) {
          setFile(Certificado.arquivo);
          if (Certificado.nome_certificado !== null) 
            setFileName(Certificado.nome_certificado)
          else if (Certificado.local_certificado_digital !== null) 
            setFileName(Certificado.local_certificado_digital);
        }
        if (Certificado.senha_certificado_digital !== null) {
          setSenha(Certificado.senha_certificado_digital);
        }
      }
      return;
    })();

    (async function getEmpresa() {
      const response = await consultaEmpresa(id);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response) {
        const { data } = response;
        setEmpresa(data.razao_social);
        setLoading(false);
      }
    })();
  }, []);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  if (callbackType === "success") {
      readNotification();
      history.push("/empresas");
    }
  }
  
  function handleFileChange(file, name) {
    setFile(file);
    setFileName(name);
  }

  function handleSenhaChange(event) {
    const { value } = event.target;
    setSenha(value);
  }

  function downloadCertificado(certificado) {
    if (!certificado || certificado === "") {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(["Certificado não encontrado."]);
      return;
    }
    const linkSource = `data:application/x-pkcs12;base64,${certificado}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  async function handleSubmit() {
    const { id } = params;
    let hasErrors = false;

    const fieldsToValidate = [
      {
        label: "empresa",
        value: empresa,
      },
      {
        label: "certificado",
        value: file,
      },
      {
        label: "senha",
        value: senha,
      },
      {
        label: "vencimento",
        value: data,
      },
    ];

    const fieldsError = validateFields(fieldsToValidate);
    if (fieldsError.length !== 0) {
      hasErrors = true;
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(fieldsError);
      return;
    }

    if (hasErrors) return;

    setLoading(true);
    const response = await cadastrarCertificado(
      Number(id), data, senha, file.split(';base64,')[1], fileName
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setLoading(false);
    if (response.status !== 200) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(6000);
      setCallbackMessage(response?.data?.msg || 'Erro');
      if (response.data.error)
        setCallbackErrorList([Object.values(response.data.error)]);
      else setCallbackErrorList(["Erro ao emitir certificado"]);
      return;
    } else {
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Certificado cadastrado com sucesso!");
      return;
    }
  }

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          {callbackShown && (
            <CallbackMessage
              open={callbackShown}
              duration={callbackDuration}
              errorList={callbackErrorList}
              handleClose={handleClose}
              message={callbackMessage}
              type={callbackType}
            />
          )}
          <form className="certificado-register-form">
            <div className="certificado-register-form-empresa-input">
              <Input
                nome="empresa"
                label="Empresa"
                tipo="text"
                readOnly
                required={true}
                value={empresa}
                className="certificado-register-form-empresa-input-fill"
              />
            </div>
            <div className="certificado-register-form-file-input">
              <InputFile
                title="Certificado *"
                onChange={(file, name) => handleFileChange(file, name)}
                fileName={fileName}
              />
            </div>
            <div className="certificado-register-form-senha-input">
              <div className="password-input">
                <Input
                  nome="senha"
                  value={senha}
                  label="Senha"
                  tipo="text"
                  required
                  title={senha !== "" ? null : "******"}
                  type={isPasswordVisible ? "text" : "password"}
                  handleInputChange={(event) => handleSenhaChange(event)}
                />
                <button
                  onClick={() => setIsPasswordVisible(prev => !prev)}
                  className="gov-password-icon"
                  type="button"
                >
                  <div className="gov-password-icon-container">
                    {!isPasswordVisible ? (
                      <Icon name="closed-eye" />
                    ) : (
                      <Icon name="open-eye" />
                    )}
                  </div>
                </button>
              </div>
            </div>
            <div className="certificado-register-form-data-input">
              <DatePicker
                label="Vencimento *"
                initDate={data}
                handleChange={(event) => {
                  setData(event);
                }}
              />
            </div>
            <div className="certificado-register-form-download">
              <Button
                className="primary-outline"
                type="button"
                text="Download do Certificado"
                icone="download"
                iconeColor="#812990"
                iconeSize="20"
                onClick={() => downloadCertificado(file)}
              />
            </div>
            <div className="required-text">
              <span>* Campos obrigatórios</span>
            </div>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            type="button"
            text="Emitir Certificado"
            className="btn-success"
            onClick={handleSubmit}
          />
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CadastroCategorias;
