import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, useHistory } from 'react-router-dom';

import TableComponent from "../../../components/TableComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import Button from "../../../components/ButtonComponent";

import ModalLoginExpirado from '../../../components/ModalLoginExpirado';
import perfilAcessoService from "../../../services/perfilAcessoService";
import CallbackMessage from '../../../components/CallbackMessage';


import "./styles.scss";

function PermissaoUsuario() {
  const { listaPermissoesPerfilAcesso, atualizarPermissoes } = perfilAcessoService;

  const history = useHistory();
  const { id } = useParams();
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [permissoesToAdd, setPermissoesToAdd] = useState([]);

  const [listRequest, setListRequest] = useState([]);
  const [checkedPermissoesList, setCheckedPermissoesList] = useState([]);
  const [permissoesList, setPermissoesList] = useState([]);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_permissao" },
      { columnName: "Permissão", dataRef: "nome" },
      { columnName: "Opções", dataRef: "permissao" },
    ],
    display: {
      search: true,
      itemsPerPage: false,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      detailTitle: true,
      checkBoxOptions: true,
      customTableTitle: "Permissões de Acesso",
      customFilterTitle: `Alterar Permissões - ${state?.perfilName}`,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: 999,
    searchTerm: null,
  });

  let updateTable = useCallback(async () => {
    setLoading(true);
    
    const response = await listaPermissoesPerfilAcesso(
      id,
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm
    );
    const responseData = response.data;

    if (responseData.hasOwnProperty("data")) {
      const { data } = responseData;
      const newData = data.map((item) => {
        return {
          ...item,
          permissao: item.possui_acesso === 1 ? true : false,
        };
      });
      const newResult = {
        ...responseData,
        data: newData,
      };

      setListRequest(newResult);
    } else {
      const newData = responseData.map((item) => {
        return {
          ...item,
          permissao: item.possui_acesso === 1 ? true : false,
        };
      });
      const newResult = {
        ...responseData,
        data: newData,
      };
      setListRequest(newResult);
    }
    setLoading(false);
  }, [defaultConfigTable.currentPage,
  defaultConfigTable.orderBy,
  defaultConfigTable.orderByType,
  defaultConfigTable.perPage,
  defaultConfigTable.searchTerm,
  listaPermissoesPerfilAcesso,
    id]);

  const loadPermissoesList = useCallback(async () => {
    const response = await listaPermissoesPerfilAcesso(id, 1, null, null, 999, null);

    const permissoes = response?.data?.data?.map((item) => {
      return {
        id_permissao: item.id_permissao,
        permissao: item.possui_acesso === 1 ? true : false,
      };
    });

    setPermissoesList(permissoes);

    const checkedPermissoes = permissoes.map((item) => item.permissao);

    setCheckedPermissoesList(checkedPermissoes);
  }, [listaPermissoesPerfilAcesso, id]);

  useEffect(() => {
    updateTable();
    loadPermissoesList();
  }, [id, loadPermissoesList, updateTable]);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  let onOrderBy = useCallback((newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onPageChange = useCallback((newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onSearchTerm = useCallback((value) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onPerPage = useCallback((newPerPage) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onCheck = useCallback((checkBoxObj) => {
    (async () => {
      let { id, checked } = JSON.parse(checkBoxObj);
      let permissoes = permissoesList;

      let index = permissoes.findIndex(item => item?.id_permissao === id);

      permissoes[index].permissao = checked

      setPermissoesList(permissoes);
  
      const checkedPermissoes = permissoes.map((item) => item.permissao);

      setCheckedPermissoesList(checkedPermissoes);
    })();
  }, [setCheckedPermissoesList, permissoesList]);


  async function handleSubmit() {
    setLoading(true);

    let permissoesTemp = permissoesList;

    let uniqueIds = [...new Set(permissoesTemp)];

    let permissoes = uniqueIds
      .map((item) => {
        if (item.permissao === true) {
          return item.id_permissao;
        }
        return undefined;
      })
      .filter((item) => item !== undefined);


    const response = await atualizarPermissoes(id, 1, permissoes);

    if (
      response?.status === 401
    ) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    if (response.status !== 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackDuration(3000);
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      return;
    }

    if (response?.status === 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage("Permissões editadas com sucesso!");
      setTimeout(() => {
        history.push('/perfil-acesso');
      }, 2000);
      return;
    }

    setLoading(false);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      setCallbackShown(false);
    }

    if (reason === 'timeout') {
      setCallbackShown(false);
    }
  }


  let onMoreAction = useCallback(async (action) => {
    console.log(action);

    switch (action.action) {
      case "marcar-todos": {
        let permissoes = permissoesList;

        const checkedPermissoes = permissoes.map((item) => ({
          ...item,
          permissao: true,
        }));

        const checkedPermissoesValues = checkedPermissoes.map((item) => item.permissao)

        setPermissoesList(checkedPermissoes);

        setCheckedPermissoesList(checkedPermissoesValues);
        break;
      }
      case "desmarcar-todos": {
        let permissoes = permissoesList;

        const updatePermissoes = permissoes.map((item) => ({
          ...item,
          permissao: false,
        }));

        setPermissoesList(updatePermissoes);

        const noCheckedPermissoes = updatePermissoes.map((item) => item.permissao);
  
        setCheckedPermissoesList(noCheckedPermissoes);
        break;
      }
      default:
        break;
    }
  }, [permissoesList, setCheckedPermissoesList, setDefaultConfigTable]);


  const optionsCheckBox = [
    {
      icon: "check-double",
      label: "Marcar todos",
      action: "marcar-todos"
    },
    {
      icon: "close",
      label: "Desmarcar todos",
      action: "desmarcar-todos"
    },
  ]


  return (
    <>
      <TableComponent
        idName="id_permissao"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        checkList={checkedPermissoesList}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbCheckBox={onCheck}
        cbMoreAction={onMoreAction}
        optionsCheckBox={optionsCheckBox}
        noPagination
      />
      <div className="button-area editar-usuario-button-area">
        <span>
          <Button
            type="button"
            text="Salvar"
            className="btn-success"
            onClick={handleSubmit}
          />
        </span>
      </div>
      {loading && <OverlayLoading />}
      <ModalLoginExpirado open={openLoginExpirado} />
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        handleClose={handleClose}
        message={callbackMessage}
        duration={callbackDuration}
        errorList={callbackErrorList}
      />
    </>
  );
}

export default PermissaoUsuario;
