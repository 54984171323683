import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import "./styles.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

function ContainerAlert({ 
  status = "",
  message,
  autoHideDuration = 2000 
}) {
  const [open, setOpen] = useState(true);
  function closeAlert() {
    setOpen(false);
  }

  return (
    <div className="snackbarRoot">
      <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={autoHideDuration}
      onClose={closeAlert}
      open={open}
      >
        <Alert severity={status}>{message}</Alert>
     </Snackbar>
    </div>
  );
}

export default ContainerAlert;
