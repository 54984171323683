import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import TableComponent from "../../../components/TableComponent";
import ConfirmDialog from "../../../components/ConfirmDialog";
import OverlayLoading from "../../../components/OverlayLoading";
import CallbackMessage from "../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

import corretoresService from "../../../services/corretoresService";

import DefinirGerenteTimeModal from "./components/DefinirGerenteTimeModal";
import ModalGestaoTurno from "./components/ModalGestaoTurnos";

function CorretoresLista() {
  const history = useHistory();
  const [listRequest, setListRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [openModalGerenteTime, setOpenModalGerenteTime] = useState(false);
  const [openModalGestaoTurno, setOpenModalGestaoTurno] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [idCorretor, setIdCorretor] = useState(null);
  const [idGerente, setIdGerente] = useState(null);
  const [idTimeCorretor, setIdTimeCorretor] = useState(null);

  const { consultaCorretores, removeCorretor } = corretoresService;

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(2000);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_corretor" },
      { columnName: "CNPJ/CPF", dataRef: "cnpj_cpf" },
      { columnName: "Razão Social/Nome", dataRef: "nome_razao_social" },
      { columnName: "Gerente", dataRef: "nome_gerente" },
      { columnName: "Time", dataRef: "time_corretor" }
    ],
    options: {
      edit: true,
      delete: true,
      more: [
        {
          icon: "calendar",
          label: "Gestão de Turnos",
          action: "gestao-turnos"
        },
        { icon: "settings", label: "Gerente e Time", action: "gerente-time" }
      ]
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null
  });

  const updateTable = useCallback(async () => {
    setLoading(true);
    const response = await consultaCorretores(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm
    );
    setLoading(false);
    if (response.data.hasOwnProperty("data")) {
      setListRequest(response.data);
    } else {
      setListRequest(response);
    }
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    consultaCorretores
  ]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  useEffect(() => {
    const newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  const onPageChange = newPageObj => {
    const newPage = newPageObj.page;
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onOrderBy = newOrderBy => {
    const orderBy = newOrderBy.orderBy;
    const orderByType = newOrderBy.orderByType;
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onPerPage = newPerPage => {
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onSearchTerm = value => {
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onEdit = id => {
    history.push(`/editar-corretor/${id}`);
  };

  const handleRemoveDialog = id => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  const handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  const onDelete = async () => {
    const response = await removeCorretor(deleteId);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status !== 200) {
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackShown(true);
      if (response.data.error) {
        setCallbackErrorList([response.data.msg]);
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
      setLoading(false);
      handleRemoveCancel();
    }
    if (response.status === 200) {
      setCallbackType("success");
      setCallbackMessage("Corretor removido com sucesso!");
      setCallbackDuration(3000);
      setCallbackShown(true);
      setLoading(false);
      handleRemoveCancel();
      await updateTable();
      return;
    }
    handleRemoveCancel();
  };

  const handleMoreOption = ({ action, id }) => {
    if (action === "gerente-time") {
      setOpenModalGerenteTime(true);
      setIdCorretor(id);
      const listaCorretores = listRequest.data;
      const corretor = listaCorretores.find(item => item.id_corretor === id);
      setIdGerente(corretor.id_gerente);
      setIdTimeCorretor(corretor.id_time_corretor);
    }

    if (action === "gestao-turnos") {
      setIdGerente(id);
      setOpenModalGestaoTurno(true);
    }
  };

  const handleCloseModalGerenteTime = async () => {
    setOpenModalGerenteTime(false);
    setIdGerente(null);
    setIdTimeCorretor(null);
    await updateTable();
  };

  return (
    <>
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id_corretor"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onEdit}
        cbDelete={handleRemoveDialog}
        cbMoreAction={handleMoreOption}
      />
      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={() => setCallbackShown(false)}
      />
      <ConfirmDialog
        open={confirmRemoveDialog}
        onAccept={onDelete}
        onCancel={handleRemoveCancel}
        description="Ao remover este corretor ele será desvinculado de todos os imóveis e leads. Deseja realizar a exclusão?"
      />
      <DefinirGerenteTimeModal
        open={openModalGerenteTime}
        onClose={handleCloseModalGerenteTime}
        idCorretor={idCorretor}
        currentIdGerente={idGerente}
        currentIdTimeCorretor={idTimeCorretor}
      />
      {openModalGestaoTurno && <ModalGestaoTurno
        id={idGerente}
        open={openModalGestaoTurno}
        onClose={() => setOpenModalGestaoTurno(false)}
      />}
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default CorretoresLista;
