import React, { useEffect, useState, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";

import TableComponent from "../../../components/TableComponent";
import OverlayLoading from "../../../components/OverlayLoading";
import SelectComponent from "../../../components/SelectComponent";
import DatePicker from "../../../components/DatePicker";
import Button from "../../../components/ButtonComponent";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import CallbackMessage from "../../../components/CallbackMessage";

import solicitacaoServicoServices from "../../../services/solicitacaoServicoServices";

import { numberToCurrency } from "../../../utils/functions";
import { formatDate, toDateYYYY_MM_DD } from "../../../utils/dates";

import "./styles.scss";
import exportData from "../../../services/exportData";
import { AuthContext } from "../../../contexts/AuthContext";
import { useIsMount } from "../../../hooks/useIsMount";
import useCompany from "../../../hooks/useCompany";

export default function ListaSolicitacaoServico() {
  const history = useHistory();
  const { listaSolicitacoes, cancelaSolicitacaoServico } =
    solicitacaoServicoServices;

  const { isAdmin } = useContext(AuthContext);

  const {
    companyList: listEmpresas,
    selectedCompany: empresa,
    setSelectedCompany: setEmpresa,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("solicitacoes_servicos");
  const isMount = useIsMount();

  const [loading, setLoading] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  const [listRequest, setListRequest] = useState([]);
  const [dateDe, setDateDe] = useState(null);
  const [dateAte, setDateAte] = useState(null);
  const [paramsURL, setParamsURL] = useState(null);
  const [exportToPDF, setExportToPDF] = useState({
    url: "exportar-solicitacao-servico-pdf",
    fileName: "solicitação_serviço",
    fileType: "pdf",
    params: { id_empresa: 150 },
  });
  const [exportToXLS, setExportToXLS] = useState({
    url: "exportar-solicitacao-servico-excel",
    fileName: "solicitação_serviço",
    fileType: "xlsx",
    params: { id_empresa: 150 },
  });
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "ID", dataRef: "id_solicitacao_servico" },
      { columnName: "Razão Social", dataRef: "razao_social" },
      { columnName: "Data Solicitação", dataRef: "data_solicitacao" },
      { columnName: "Valor", dataRef: "valor_total" },
      { columnName: "Status", dataRef: "status_solicitacao" },
    ],
    options: {
      searchFile: true,
      delete: isAdmin,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-xls",
        },
        {
          icon: "export",
          label: "Exportar PDF",
          action: "exportar-pdf",
        },
      ],
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    data_de: null,
    data_ate: null,
    empresa: null,
  });

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    if (defaultConfigTable.data_de && defaultConfigTable.data_ate) {
      const response = await listaSolicitacoes(
        defaultConfigTable.currentPage,
        defaultConfigTable.orderBy,
        defaultConfigTable.orderByType,
        defaultConfigTable.perPage,
        defaultConfigTable.searchTerm,
        defaultConfigTable.data_de,
        defaultConfigTable.data_ate,
        defaultConfigTable.empresa
      );

      if (response.data) {
        if (response.data.hasOwnProperty("data")) {
          const { data } = response.data;

          const newData = data.map((item) => ({
            ...item,
            data_solicitacao: item.data_solicitacao
              ? formatDate(item.data_solicitacao.substring(0, 10)) +
                item.data_solicitacao.substring(10)
              : "",
            id: item.id_solicitacao_servico,
            valor_total: numberToCurrency(item.valor_total),
          }));

          const newResult = {
            ...response.data,
            data: newData,
          };

          setListRequest(newResult);
        } else {
          const { data } = response;

          const newData = data.map((item) => ({
            ...item,
            data_solicitacao: item.data_solicitacao
              ? formatDate(item.data_solicitacao.substring(0, 10)) +
                item.data_solicitacao.substring(10)
              : "",
            id: item.id_solicitacao_servico,
            valor_total: numberToCurrency(item.valor_total),
          }));

          const newResult = {
            ...response,
            data: newData,
          };

          console.log(newResult);

          setListRequest(newResult);
        }
      }
    }
    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    listaSolicitacoes,
    defaultConfigTable.data_de,
    defaultConfigTable.data_ate,
    defaultConfigTable.empresa,
  ]);

  let onPageChange = useCallback(
    (newPageObj) => {
      let newPage = newPageObj.page;
      let newDefaultConfigTable = { ...defaultConfigTable };
      newDefaultConfigTable.currentPage = newPage;
      setDefaultConfigTable(newDefaultConfigTable);
    },
    [defaultConfigTable, updateTable]
  );

  let onOrderBy = useCallback(
    (newOrderBy) => {
      let orderBy = newOrderBy.orderBy;
      let orderByType = newOrderBy.orderByType;
      let newDefaultConfigTable = { ...defaultConfigTable };
      newDefaultConfigTable.orderBy = orderBy;
      newDefaultConfigTable.orderByType = orderByType;
      setDefaultConfigTable(newDefaultConfigTable);
    },
    [defaultConfigTable, updateTable]
  );

  let onPerPage = useCallback(
    (newPerPage) => {
      let newDefaultConfigTable = { ...defaultConfigTable };
      newDefaultConfigTable.perPage = newPerPage;
      newDefaultConfigTable.currentPage = 1;
      setDefaultConfigTable(newDefaultConfigTable);
    },
    [defaultConfigTable, updateTable]
  );

  let onSearchTerm = useCallback(
    (value) => {
      let newDefaultConfigTable = { ...defaultConfigTable };
      newDefaultConfigTable.searchTerm = value ? value : null;
      newDefaultConfigTable.currentPage = 1;
      setDefaultConfigTable(newDefaultConfigTable);
    },
    [defaultConfigTable, updateTable]
  );

  let onClickFile = (id) => {
    console.log("idloook:::", id);
    history.push(`/visualizar-solicitacao/${id}`);
  };

  let onClickEdit = (id) => {
    history.push(`/solicitacao-servico/${id}`);
  };

  let onClickDelete = (id) => {
    setIdDelete(id);
    setOpenConfirmDelete(true);
  };

  let onCancelDelete = () => {
    setOpenConfirmDelete(false);
  };

  function handleClose() {
    setShowMessage(false);
  }

  let onDelete = async () => {
    setLoading(true);

    const response = await cancelaSolicitacaoServico(idDelete);

    if (response.status === 200 || response.status === 201) {
      setOpenConfirmDelete(false);
      await updateTable();
      setLoading(false);
      setShowMessage(true);
      setErrors([]);
    } else {
      if (response.status === 401) {
        setOpenLoginExpirado(false);
        setLoading(false);
        return;
      }
      if (response.data.error) setErrors(Object.values(response.data.error));
      else setErrors(["Ocorreu um erro"]);
      setShowMessage(true);
      setLoading(false);
    }
  };

  const handleApplyFilters = useCallback(() => {
    setParamsURL({ id_empresa: empresa, data_de: dateDe, data_ate: dateAte });
    setDefaultConfigTable({
      ...defaultConfigTable,
      data_de: dateDe,
      data_ate: dateAte,
      empresa: empresa,
      currentPage: 1,
    });
    setEmpresaPagina("solicitacoes_servicos", empresa);
  }, [empresa, dateDe, dateAte]);

  const handleCleanFilters = useCallback(() => {
    setDateDe(null);
    setDateAte(null);
    setEmpresa(null);
    setDefaultConfigTable({
      ...defaultConfigTable,
      empresa: null,
      currentPage: 1,
    });
    setEmpresaPagina("solicitacoes_servicos", null);
  }, []);

  let onTableMoreAction = async ({ action }) => {
    let params = {};
    if (action === "exportar-xls") {
      params = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: {
          data_de: defaultConfigTable.data_de,
          data_ate: defaultConfigTable.data_ate,
          id_empresa: defaultConfigTable.empresa,
        },
      };
    } else if (action === "exportar-pdf") {
      params = {
        url: exportToPDF.url,
        fileName: exportToPDF.fileName,
        fileType: exportToPDF.fileType,
        params: {
          data_de: defaultConfigTable.data_de,
          data_ate: defaultConfigTable.data_ate,
          id_empresa: defaultConfigTable.empresa,
        },
      };
    }

    setLoading(true);
    const response = await exportData(params);
    console.log(response);

    if (response.status === 500) {
      setErrors(["Erro interno no servidor. Por favor, contate o suporte"]);
      setShowMessage(true);
    } else if (response.status !== 201 && response.status !== 200) {
      setErrors(["Ocorreu um erro ao exportar o relatório."]);
      setShowMessage(true);
    }

    setLoading(false);
  };

  function filterComponent() {
    return (
      <div className="session-container filter-component-container chat">
        <Grid container spacing={2} alignItems="center" className="form-table">
          <Grid item xs={12} sm={12}>
            <SelectComponent
              styleType="form"
              label="Empresa"
              initialValue={
                empresa !== null
                  ? listEmpresas.find((item) => item.id == empresa)?.nome
                  : ""
              }
              title="Selecione uma empresa"
              list={listEmpresas}
              callback={(id) => setEmpresa(id)}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="De"
              noMask
              handleChange={(date) => setDateDe(date)}
              initDate={dateDe}
            />
          </Grid>

          <Grid item xs={6}>
            <DatePicker
              noMask
              label="Até"
              handleChange={(date) => setDateAte(date)}
              initDate={dateAte}
            />
          </Grid>

          <div className="filter-button-area">
            <Button
              text="Aplicar"
              onClick={handleApplyFilters}
              className="btn-primary"
            />
            <Button
              text="Limpar"
              onClick={handleCleanFilters}
              className="btn-primary"
            />
          </div>
        </Grid>
      </div>
    );
  }

  useEffect(() => {
    let update = {};
    if (dateDe === null) {
      let year = new Date().getFullYear();
      let initDateYear = new Date(year, 0, 1);
      const dateInitBeforeSetFormat = toDateYYYY_MM_DD(initDateYear);

      setDateDe(dateInitBeforeSetFormat);
      update.dataDe = dateInitBeforeSetFormat;
    }

    if (dateAte === null) {
      const dateInitSet = new Date();
      const dateInitSetFormat = toDateYYYY_MM_DD(dateInitSet);

      setDateAte(dateInitSetFormat);
      update.dataAte = dateInitSetFormat;
    }

    const empresaStorage = getEmpresaPagina("solicitacoes_servicos");

    if (update?.dataDe || update?.dataAte) {
      let newDefaultConfigTable = { ...defaultConfigTable };
      setDefaultConfigTable({
        ...newDefaultConfigTable,
        data_de: update?.dataDe
          ? update?.dataDe
          : newDefaultConfigTable.data_de,
        data_ate: update?.dataAte
          ? update?.dataAte
          : newDefaultConfigTable.data_ate,
        empresa: empresaStorage || null,
      });
    }
  }, []);

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  useEffect(() => {
    if (!isMount) {
      updateTable();
    }
  }, [updateTable]);

  return (
    <div className="container-tabela-responsiva">
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onClickEdit}
        cbDelete={onClickDelete}
        cbLookFile={onClickFile}
        filterComponent={filterComponent()}
        cbTableMoreAction={onTableMoreAction}
      />
      <ConfirmDialog
        open={openConfirmDelete}
        title="CONFIRMAR CANCELAMENTO"
        description="Você tem certeza que deseja realizar o cancelamento da solicitação e da fatura?"
        cancelLabel="Voltar"
        acceptLabel="Cancelar"
        onAccept={onDelete}
        onCancel={onCancelDelete}
        onClose={onCancelDelete}
      />
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${
          errors.length === 0 ? "Serviço cancelado com sucesso!" : "Erro!"
        }`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 3000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </div>
  );
}
