import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../Header";
import { Menu } from "@material-ui/icons";
import loadMenu from "../../mocks/menu.js";
import smallScreen from "../../utils/smallScreen";
import Logo from "../../assets/img/logo001.svg";
import WhatsIcon from "../../assets/img/whats-icon.png";
import { NavbarContext } from "../../contexts/NavbarContext";
import { Link } from "react-router-dom";
import "./styles.scss";
import ModalNovaVersao from "../ModalNovaVersao";
import ModalAvisoInstabilidade from "../ModalAvisoInstabilidade/index.jsx";

function Container(props) {
  const [menuCompany, setMenuCompany] = useState([]);
  const history = useHistory();
  const isSmallScreen = smallScreen();
  const statusUso = window.localStorage.getItem("status_uso");
  const { showNavbar, setShowNavbar } = useContext(NavbarContext);
  const userStatus = window.localStorage.getItem("status_uso");

  useEffect(() => {
    if (history.location.pathname === "/contrato" && statusUso === "0") {
      return;
    }

    let menu = loadMenu();

    if (statusUso === "1") setMenuCompany(menu);
  }, [history.location.pathname, statusUso]);

  return (
    <>
      <div
        className={
          isSmallScreen
            ? "container-default-small"
            : props.secondaryContainer
            ? "container-default secondary-container"
            : props.publicPage
            ? "container-public-page"
            : "container-default"
        }
      >
        {isSmallScreen && !showNavbar && !props.publicPage && (
          <div className="header-mobile">
            <Link to={userStatus === "0" ? "/contrato" : "/home"}>
              <img src={Logo} alt="logo" className="img-menu-mobile" />
            </Link>
            <div
              className="icon-wrapper icon-menu-mobile"
              onClick={() => setShowNavbar(!showNavbar)}
            >
              <Menu />
            </div>
          </div>
        )}
        {!props.publicPage && (
          <Header
            arrayMenu={menuCompany}
            secondaryContainer={props.secondaryContainer || false}
            vertical={true}
            objHeaderConfig={props.objHeaderConfig}
            showNavbar={showNavbar}
            showToolbar={!props.iframePage}
          />
        )}
        {props.publicPage && (
          <div>
            <div className="container-footer-img">
              <div className="footer-img" />
            </div>
            <div className="title-public-page">{props.title}</div>
          </div>
        )}
        <div
          className={`${
            props.iframePage
              ? "container-iframe"
              : "container-default-component"
          } ${props?.className ? props.className : ""}`}
        >
          <props.component />
          {props.hideFooter ? null : (
            <div className="container-footer-img">
              <div className="footer-img" />
            </div>
          )}
        </div>
        {/* {!props.publicPage && (
          <a
            href="https://api.whatsapp.com/send/?phone=5511974496445&amp;text="
            target="_blank"
            className="whatsapp-menu-button btn"
          >
            <img src={WhatsIcon} />
            Ajuda
          </a>
        )} */}
        <ModalNovaVersao open={true} />
        <ModalAvisoInstabilidade />
      </div>
    </>
  );
}

export default Container;
