import { Grid } from "@material-ui/core";
import SelectComponent from "../../SelectComponent";

export function FilterSelect({
  label,
  value,
  options,
  callback,
  title = "Selecione uma empresa",
  required = true,
  disabled = false,
  ...rest
}) {
  return (
    <Grid item {...rest}>
      <SelectComponent
        styleType="form"
        label={label}
        required={required}
        initialValue={
          value !== undefined
            ? options.find((item) => item.id === value)?.nome
            : ""
        }
        title={title}
        list={options}
        callback={callback}
        isDisabled={disabled}
      />
    </Grid>
  );
}
