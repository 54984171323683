import React, { useState } from "react";
import { Accordion } from "../../../../components/AccordionComponent";
import { Grid } from "@material-ui/core";
import { formataNumero, numberToCurrency } from "../../../../utils/functions";
import TableComponent from "../../../../components/TableComponent";

export function AccordionDre({ dados, columns = [], configuracaoTabela }) {
  const [expanded, setExpanded] = useState(false);
  console.log({ configuracaoTabela });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  console.log({ dados, columns });
  return (
    <>
      {dados.map((item, index) => {
        const values = columns.map((itemCol) => {
          return item[itemCol.id];
        });
        const dadosProdutos = item?.nota_fiscal_produto_entrada_itens
          ? item.nota_fiscal_produto_entrada_itens.map((itemProd) => ({
              ...itemProd,
              valor_unitario_tributario: formataNumero(
                itemProd.valor_unitario_tributario
              ),
              valor_desconto: formataNumero(itemProd.valor_desconto),
              valor_total_bruto: formataNumero(itemProd.valor_total_bruto),
              bc_icms: formataNumero(itemProd.bc_icms),
              bc_icmsst: formataNumero(itemProd.bc_icmsst),
              valor_icms: formataNumero(itemProd.valor_icms),
              valor_icmsst: formataNumero(itemProd.valor_icmsst),
              valor_ipi: formataNumero(itemProd.valor_ipi),
              aliquota_icms: formataNumero(itemProd.aliquota_icms),
              aliquota_ipi: formataNumero(itemProd.aliquota_ipi),
            }))
          : [];

        return (
          <div key={`summary_${index}`}>
            <Accordion.Root
              handleChange={handleChange}
              index={index}
              expanded={expanded}
            >
              <Accordion.Header
                expanded={expanded}
                index={index}
                item={item}
                columns={values}
                numbers={false}
                marginRight={32}
                widthFirstColumn={154}
                customDescriptionClass=""
              />

              <Accordion.Body>
                <Grid
                  container
                  direction="column"
                  className="container-tabela-responsiva"
                >
                  <TableComponent
                    idName="id_item"
                    dataList={dadosProdutos}
                    tableConfig={configuracaoTabela}
                    enableOrder={false}
                  />
                </Grid>
              </Accordion.Body>
            </Accordion.Root>
            <div className="space-vertical" />
          </div>
        );
      })}
    </>
  );
}
