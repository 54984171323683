import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../ButtonComponent";
import Input from "../Input";

import "./styles.scss";
import CallbackMessage from "../CallbackMessage";
import InputFile from "../InputMultiFiles";

export const ModalUploadDocumento = ({
  open,
  title,
  description,
  acceptLabel,
  cancelLabel,
  onAccept,
  onCancel,
  onClose,
  btnClass = "btn-danger",
  noCancel = false,
  cancelClass = "",
}) => {
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackErrorList, setCallbackErrorList] = useState([]);

  const [files, setFiles] = useState([]);

  async function handleAccept() {
    let errorList = [];
    if (!files.length) {
      errorList.push("É necessário selecionar no mínimo um arquivo");
    }
    if (errorList.length > 0) {
      setCallbackErrorList(errorList);
      setCallbackShown(true);
      return;
    }
    await onAccept(files);
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
  }

  function handleCloseModal() {
    if (onClose) {
      onClose();
    } else {
      onCancel();
    }
  }

  return (
    <Dialog open={open} onClose={handleCloseModal} className="paper">
      <div className="modal-title-area">
        <DialogTitle>
          <span className="titleConfirmDialog">{title}</span>
        </DialogTitle>
        <IconButton onClick={handleCloseModal} size="medium">
          <CloseIcon className="iconConfirm" />
        </IconButton>
      </div>
      <DialogContent className="custom-dialog-content">
        {description && <pre className="confirmDescription">{description}</pre>}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputFile
              id={1}
              title="Anexos*"
              fileFormat=".pdf,.doc,.docx,.xls,.xlsx,.jpeg,.jpg,.png"
              onChange={(files) => {
                console.log({ files });
                setFiles(files);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="buttonContent">
        <>
          {!noCancel && (
            <Button
              text={cancelLabel}
              className={`btn-secondary-strong btnSize ${cancelClass}`}
              onClick={() => {
                onCancel();
              }}
            />
          )}
          <Button
            text={acceptLabel}
            className={`${btnClass} btnSize`}
            onClick={async () => handleAccept()}
          />
        </>
      </DialogActions>
      <CallbackMessage
        type={"error"}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={4000}
        message={"Erro!"}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

ModalUploadDocumento.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
};

ModalUploadDocumento.defaultProps = {
  open: false,
  description: "Você tem certeza que deseja realizar a exclusão?",
  cancelLabel: "Cancelar",
  acceptLabel: "Excluir",
  title: "CONFIRMAR EXCLUSÃO",
};
