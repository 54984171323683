import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";
import ButtonComponent from "../../../components/ButtonComponent";

import CallbackMessage from "../../../components/CallbackMessage";
import OverlayLoading from "../../../components/OverlayLoading";

import { Grid, useMediaQuery } from "@material-ui/core";

import SelectComponent from "../../../components/SelectComponent";
import selectService from "../../../services/selectServices";
import DatePicker from "../../../components/DatePicker";
import Input from "../../../components/Input";
import validateFields from "../../../utils/validateFields";
import styles from "./styles.module.scss";
import folhaPagamentoService from "../../../services/dpFolhaPagamentoService";
import InputTime from "../../../components/InputTime";
import InputFile from "../../../components/InputMultiFiles";
import { ModalListaDocumento } from "../../../components/ModalListaDocumento";
import useCompany from "../../../hooks/useCompany";

export function CadastroEventosFolha() {
  const isMobile = useMediaQuery("(max-width: 1100px)");

  const {
    companyList: listEmpresa,
    selectedCompany: idEmpresa,
    setSelectedCompany: setIdEmpresa,
    getEmpresaPagina,
  } = useCompany("folha_pagamento");

  const history = useHistory();
  const locationState = history.location.state;

  const {
    selecionaFuncionariosDp,
    selecionaEventoFolhaDp,
    selecionaTipoMovimentoDp,
  } = selectService;
  const { cadastrarEventoFolha, atualizarEventoFolha, consultaEventoFolha } =
    folhaPagamentoService;

  const [listFuncionarios, setListFuncionarios] = useState([]);
  const [listTipoMovimento, setListTipoMovimento] = useState([]);
  const [listEvento, setListEvento] = useState([]);

  const [loading, setLoading] = useState(false);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const params = useParams();
  const { idEventoFolha } = params;

  const [idFuncionario, setIdFuncionario] = useState(null);
  const [idFuncionarioInicial, setIdFuncionarioInicial] = useState(null);

  const [tipoMovimento, setTipoMovimento] = useState(null);
  const [evento, setEvento] = useState(null);
  const [documento, setDocumento] = useState(null);
  const [periodoDe, setPeriodoDe] = useState(null);
  const [periodoAte, setPeriodoAte] = useState(null);
  const [quantidade, setQuantidade] = useState(null);
  const [observacoes, setObservacoes] = useState(null);
  const [quantidadeDocumentos, setQuantidadeDocumentos] = useState(null);
  const [mascaraQuantidade, setMascaraQuantidade] = useState("");

  // FIM CAMPOS PARA O SERVICE

  const [isLoadingFuncionarios, setIsLoadingFuncionarios] = useState(false);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState();
  const [callbackMessage, setCallbackMessage] = useState();
  const [callbackErrorList, setCallbackErrorList] = useState();
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [showModalListaUploads, setShowModalListaUploads] = useState(false);

  const loadEventos = async () => {
    await selecionaEventoFolhaDp().then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        setListEvento(response.data);
      }

      if (idEventoFolha) {
        loadEventoFolha(response?.data || []);
      }
    });
  };

  const loadTipoMovimento = async () => {
    await selecionaTipoMovimentoDp().then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) setListTipoMovimento(response.data);
    });
  };

  const loadFuncionarios = async (empresa) => {
    setIsLoadingFuncionarios(true);
    await selecionaFuncionariosDp(empresa)
      .then((response) => {
        setIsLoadingFuncionarios(false);
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          setLoading(false);
          return;
        }
        if (response.status === 200) setListFuncionarios(response.data);
      })
      .catch(() => {
        setIsLoadingFuncionarios(false);
      });
  };

  const loadEventoFolha = async (eventos = []) => {
    try {
      const response = await consultaEventoFolha(idEventoFolha);
      console.log({ response });
      if (response.status === 200) {
        const data = response.data;
        console.log({ data });
        setIdEmpresa(data.id_empresa);
        setIdFuncionario(data.id_funcionario);
        setIdFuncionarioInicial(data.id_funcionario);
        setTipoMovimento(data.id_tipo_movimento_folha);
        setEvento(data.id_evento_folha);
        setPeriodoDe(data.data_inicio);
        setPeriodoAte(data.data_fim);
        setQuantidade(data.quantidade);
        setObservacoes(data.observacoes);
        setQuantidadeDocumentos(data.quantidade_documentos);
        loadFuncionarios(data.id_empresa);
        const mascara = eventos.find(
          (item) => item.id === data?.id_evento_folha
        );
        if (mascara?.tipo === "valor") {
          mascara.tipo = "dinheiro";
        } else if (mascara?.tipo === "") {
          mascara.tipo = "texto";
        }
        setMascaraQuantidade(mascara.tipo);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleChangeEmpresa = (id) => {
    setIdEmpresa(id);
    loadFuncionarios(id);
  };

  function handleClose(event, reason) {
    console.log("there");
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/folhas-de-pagamento");
    }
  }

  async function handleSubmit() {
    const fields = [
      {
        label: "empresaDp",
        value: idEmpresa,
      },
      {
        label: "funcionario",
        value: idFuncionario,
      },
      {
        label: "periodo_de",
        value: periodoDe,
      },
      {
        label: "periodo_ate",
        value: periodoAte,
      },
      {
        label: "id_tipo_movimento_folha",
        value: tipoMovimento,
      },
      {
        label: "id_evento_folha",
        value: evento,
      },
      {
        label: "quantidade",
        value: quantidade,
      },
    ];

    const fieldsErros = validateFields(fields);

    if (fieldsErros.length !== 0) {
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      setCallbackErrorList(fieldsErros);
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("id_empresa", idEmpresa);
    formData.append("id_funcionario", idFuncionario);
    formData.append("id_tipo_movimento_folha", tipoMovimento);
    formData.append("data_inicio", periodoDe);
    formData.append("data_fim", periodoAte);
    formData.append("id_evento_folha", evento);
    formData.append("quantidade", quantidade);
    console.log({ documento });
    if (observacoes) formData.append("observacoes", observacoes);
    if (documento?.length) {
      documento?.forEach((item, index) => {
        formData.append(`documentos[${index}][nome]`, item.nome);
        formData.append(`documentos[${index}][arquivo]`, item.arquivo);
      });
    }

    let response = null;

    if (idEventoFolha) {
      response = await atualizarEventoFolha(idEventoFolha, formData);
    } else {
      response = await cadastrarEventoFolha(formData);
    }

    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 201 || response.status === 200) {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("success");
      setCallbackDuration(2000);
      setCallbackMessage(
        response?.data?.message || `Evento da folha cadastrado com sucesso!`
      );
      return;
    } else {
      setLoading(false);
      setCallbackShown(true);
      setCallbackType("error");
      setCallbackMessage(response?.data?.msg || "Erro!");

      if (response?.data?.error && Array.isArray(response?.data?.error)) {
        let errorTemp = [];
        response?.data?.error?.map((item) => {
          errorTemp.push(...Object.values(item));
        });
        setCallbackErrorList(errorTemp);
      } else if (typeof response.data.error === "string") {
        setCallbackErrorList([response.data.error]);
      } else if (
        response?.data?.error &&
        !Array.isArray(response?.data?.error)
      ) {
        setCallbackErrorList(Object.values(response.data.error));
      } else {
        setCallbackErrorList(["Ocorreu um erro"]);
      }
    }
  }

  const handleModalListaUploadsCancel = () => {
    setShowModalListaUploads(false);
  };

  useEffect(() => {
    loadEventos();
    loadTipoMovimento();
  }, []);

  useEffect(() => {
    if (!idEventoFolha) {
      const empresaStorage = getEmpresaPagina("folha_pagamento");
      if (empresaStorage) {
        handleChangeEmpresa(empresaStorage);
      }
    }
  }, []);

  return (
    <div className={styles.mtDp}>
      <div className={`session-container ${styles.sessionContainer}`}>
        <span className="session-container-header">
          <form className="session-container-form">
            {loading && <OverlayLoading />}
            <div className={`${isMobile ? "" : "p-horizontal"}`}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Empresa"
                    title="Selecione uma opção"
                    list={listEmpresa}
                    initialValue={
                      listEmpresa.find((item) => item.id === idEmpresa)
                        ? listEmpresa.find((item) => item.id === idEmpresa).nome
                        : ""
                    }
                    required
                    callback={(id) => handleChangeEmpresa(id)}
                    isDisabled={locationState?.details}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectComponent
                    styleType="form"
                    label="Funcionário"
                    title="Selecione uma opção"
                    list={listFuncionarios}
                    loading={isLoadingFuncionarios}
                    initialValue={
                      listFuncionarios.find((item) => item.id === idFuncionario)
                        ? listFuncionarios.find(
                            (item) => item.id === idFuncionario
                          ).nome
                        : ""
                    }
                    required
                    callback={(id) => setIdFuncionario(id)}
                    isDisabled={locationState?.details}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectComponent
                    styleType="form"
                    label="Tipo de movimento"
                    title="Selecione um tipo"
                    list={listTipoMovimento}
                    initialValue={
                      listTipoMovimento.find(
                        (item) => item.id === tipoMovimento
                      )
                        ? listTipoMovimento.find(
                            (item) => item.id === tipoMovimento
                          ).nome
                        : ""
                    }
                    required
                    callback={(id) => setTipoMovimento(id)}
                    isDisabled={locationState?.details}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Período de:*"
                    name="periodoDe"
                    initDate={periodoDe ? periodoDe : ""}
                    handleChange={(date) => setPeriodoDe(date)}
                    value={periodoDe}
                    required
                    readOnly={locationState?.details}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Período até:*"
                    name="periodoAte"
                    initDate={periodoAte ? periodoAte : ""}
                    handleChange={(date) => setPeriodoAte(date)}
                    value={periodoAte}
                    required
                    readOnly={locationState?.details}
                  />
                </Grid>

                <Grid item xs={12} md={6} className={styles.mtAuto}>
                  <SelectComponent
                    styleType="form"
                    label="Evento"
                    title="Selecione um evento"
                    list={listEvento}
                    initialValue={
                      listEvento.find((item) => item.id === evento)
                        ? listEvento.find((item) => item.id === evento).nome
                        : ""
                    }
                    required
                    callback={(id) => {
                      setEvento(id);
                      const mascara = listEvento.find((item) => item.id === id);
                      console.log({ mascara });
                      if (mascara?.tipo === "valor") {
                        mascara.tipo = "dinheiro";
                      } else if (mascara?.tipo === "") {
                        mascara.tipo = "texto";
                      }
                      setMascaraQuantidade(mascara.tipo);
                    }}
                    isDisabled={locationState?.details}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  {mascaraQuantidade === "hora" ? (
                    <div className={`label`}>
                      Quantidade *
                      <InputTime
                        tipo="hhmm"
                        value={quantidade}
                        disabled={locationState?.details}
                        handleInputChange={(e) => setQuantidade(e.target.value)}
                      />
                    </div>
                  ) : (
                    <Input
                      label="Quantidade"
                      name="quantidade"
                      tipo={mascaraQuantidade}
                      value={quantidade}
                      required
                      handleInputChange={async (e) => {
                        if (mascaraQuantidade === "dinheiro") {
                          setQuantidade(e.value);
                        } else {
                          setQuantidade(e.target.value);
                        }
                      }}
                      disabled={locationState?.details || !mascaraQuantidade}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label="Observações"
                    name="observacoes"
                    value={observacoes}
                    handleInputChange={async (e) => {
                      setObservacoes(e.target.value);
                    }}
                    disabled={locationState?.details}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputFile
                    hasFile={
                      quantidadeDocumentos
                        ? `${quantidadeDocumentos} ${
                            quantidadeDocumentos > 1
                              ? "arquivos adicionados"
                              : "arquivo adicionado"
                          }`
                        : false
                    }
                    title="Documentos"
                    onChange={(files) => {
                      setDocumento(files);
                    }}
                    disabled={locationState?.details}
                  />
                  {idEventoFolha ? (
                    <div className={styles.containerButtonArquivos}>
                      <ButtonComponent
                        icone="paper-clip"
                        className="btn-info"
                        text="Arquivos Anexados"
                        onClick={() => setShowModalListaUploads(true)}
                      />
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </div>
          </form>
        </span>
      </div>
      {!locationState?.details && (
        <Grid item container justifyContent="flex-end" spacing={4}>
          <Grid item {...(isMobile ? { xs: 12 } : {})}>
            <ButtonComponent
              text="Salvar"
              className={`btn-success w-full ${styles.btnSalvar}`}
              onClick={() => handleSubmit()}
            />
          </Grid>
        </Grid>
      )}
      {loading && <OverlayLoading />}
      <CallbackMessage
        open={callbackShown}
        type={callbackType}
        handleClose={handleClose}
        message={callbackMessage}
        duration={callbackDuration}
        errorList={callbackErrorList}
      />
      {idEventoFolha ? (
        <ModalListaDocumento
          id={idFuncionarioInicial}
          open={showModalListaUploads}
          description=""
          title="DOCUMENTOS ANEXADOS"
          onClose={handleModalListaUploadsCancel}
          tipo="evento_folha"
          idEventoFolha={idEventoFolha}
        />
      ) : null}
      <ModalLoginExpirado open={openLoginExpirado} />
    </div>
  );
}
