import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TableComponent from "../TableComponent";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import CallbackMessage from "../CallbackMessage";
import integracoesService from "../../services/integracoesService";

import "./styles.scss";
import { opcoesStatusIntegracao } from "../../enums/opcoesStatusIntegracao";

function ModalHistoricoIntegracao({
  open,
  onClose,
  idIntegracao,
  nomeEmpresa = ''
}) {
  const { listaLogIntegracao } = integracoesService;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [listRequest, setListRequest] = useState([]);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Id", dataRef: "id" },
      { columnName: "Grupo", dataRef: "nome_grupo" },
      { columnName: "Integração", dataRef: "nome_integracao" },
      { columnName: "Status", dataRef: "status" },
      { columnName: "Erros", dataRef: "error_log", hide: true }
    ],
    display: {
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
      statusLabels: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
  });

  let updateTable = useCallback(async () => {
    console.log(idIntegracao);
    if (idIntegracao) {
      window.scrollTo(0, 0);

      setLoading(true);

      const response = await listaLogIntegracao(idIntegracao);

      console.log(response);
      if (response.data) {
        if (response.data.hasOwnProperty("data")) {

          const { data } = response?.data;

          const newData = data?.map(item => ({
            ...item,
            status: opcoesStatusIntegracao.find( a => a.id === item.status) ? opcoesStatusIntegracao.find( a => a.id === item.status).nome : 'Erro',
            error_log: item.log
          }))

          const newResult = {
            ...response.data,
            data: newData,
          }
          console.log(newResult);
          setListRequest(newResult);
        } else {
          const data = response?.data;
          console.log(data);
          const newData = data?.map(item => ({
            ...item,
            status: opcoesStatusIntegracao.find( a => a.id === item.status) ? opcoesStatusIntegracao.find( a => a.id === item.status).nome : 'Erro',
            error_log: item.log
          }))

          const newResult = {
            data: newData,
          }
          console.log(newResult);

          setListRequest(newResult);
        }
      }
      setLoading(false);
    }
  }, [defaultConfigTable.currentPage,
  defaultConfigTable.orderBy,
  defaultConfigTable.orderByType,
  defaultConfigTable.perPage,
  defaultConfigTable.searchTerm,
    idIntegracao,
  ]);

  useEffect(() => {
    updateTable();
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    idIntegracao
  ])

  useEffect(() => {
    setDefaultConfigTable({
      ...defaultConfigTable,
      display: {
        ...defaultConfigTable.display,
        customTableTitle: nomeEmpresa
      }
    });
  }, [nomeEmpresa])

  useEffect(() => {
    const newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  let onPageChange = useCallback((newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onOrderBy = useCallback((newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onPerPage = useCallback((newPerPage) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  let onSearchTerm = useCallback((value) => {
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.searchTerm = value ? value : null;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  }, [defaultConfigTable, updateTable]);

  const handleClose = () => {
    setShowMessage(false);
  }

  const handleCloseModal = async () => {
    setLoading(false);
    onClose();
  }

  const onCopy = useCallback(() => {
    setSuccessMessage('A mensagem de erro foi copiada!')
    setShowMessage(true)
  }, [])

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleCloseModal} dialog className="dialog-modal-integracao">
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>Histórico da Integração</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="container-tabela-responsiva">

        <TableComponent
          idName="id"
          dataList={listRequest !== undefined ? listRequest?.data : []}
          tableConfig={defaultConfigTable}
          callbackCurrentPage={onPageChange}
          callbackOrderBy={onOrderBy}
          callbackPerPage={onPerPage}
          callbackSearchTerm={onSearchTerm}
          cbCopyError={onCopy}
        />
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 4000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}

export default ModalHistoricoIntegracao;