import { Grid } from "@material-ui/core";
import React from "react";

export function FilterRoot({ children }) {
  return (
    <div className="session-container container chat">
      <Grid container spacing={2} alignItems="center" className="form-table">
        {children}
      </Grid>
    </div>
  );
}
