import React, { useState, useEffect } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import InputComponent from "../../Input";
import ButtonComponent from "../../ButtonComponent";
import SelectComponent from "../../SelectComponent";
import CallbackMessage from "../../CallbackMessage";
import validateFields from "../../../utils/validateFields";
import "../styles.scss"; 

function ModalIcmssn900({
  open,
  onClose,
  data = null,
  callback = () => {},
  listBci = [],
  listCstCsosn = [],
}) {
  console.log('listCstCsosn -> ', listCstCsosn);
  console.log('data -> ', data);
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackErrorList, setCallbackErrorList] = useState([]);

  const [field, setField] = useState({
    origem: null,
    csosn: null,
    modalidade_determinacao_bcicms: null,
    valor_base_calculo: null,
    aliquota_imposto: null,
    valor_icms: null,
    modalidade_determinacao_bcicmsst: null,
    valor_base_calculo_st: null,
    aliquota_imposto_icmsst: null,
    valor_icmsst: null,
    aliquota_aplicavel_calculo_credito: null,
    valor_credito_icmssn: null,
    percentual_reducao_bc: null,
    percentual_margem_valor_adicionado_icmsst: null,
    percentual_reducao_bcicmsst: null,
  });

  const handleClose = (event, reason) => {
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
  };

  const handleSubmit = () => {
    console.log(field);

    const fieldsToValidate = [
      {
        label: "origem",
        value: field.origem,
      },
      {
        label: "csosn",
        value: field.csosn,
      },
      {
        label: "modalidade_determinacao_bcicmsst",
        value: field.modalidade_determinacao_bcicms,
      },
      {
        label: "vBC",
        value: field.valor_base_calculo,
      },
      {
        label: "aliquota_imposto",
        value: field.aliquota_imposto,
      },
      {
        label: "valor_icms",
        value: field.valor_icms,
      },
      {
        label: "modalidade_determinacao_bcicmsst",
        value: field.modalidade_determinacao_bcicmsst,
      },
      {
        label: "valor_bc_icms_st",
        value: field.valor_base_calculo_st,
      },
      {
        label: "pICMSST",
        value: field.aliquota_imposto_icmsst,
      },
      {
        label: "vICMSST",
        value: field.valor_icmsst,
      },
      {
        label: "aliquota_aplicavel_calculo_credito",
        value: field.aliquota_aplicavel_calculo_credito,
      },
      {
        label: "vCredICMSSN",
        value: field.valor_credito_icmssn,
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      setCallbackErrorList(fieldsErrors);
      setCallbackShown(true);
      return;
    }
    callback(field);
  };

  useEffect(() => {
    let mounted = true;

    if (data) {
      if (mounted) {
        setField({
          origem: data.origem,
          csosn: data.csosn,
          modalidade_determinacao_bcicms: data.modalidade_determinacao_bcicms,
          valor_base_calculo: data.valor_base_calculo,
          percentual_reducao_bc: data.percentual_reducao_bc,
          aliquota_imposto: data.aliquota_imposto,
          valor_icms: data.valor_icms,
          modalidade_determinacao_bcicmsst: data.modalidade_determinacao_bcicmsst,
          percentual_margem_valor_adicionado_icmsst: data.percentual_margem_valor_adicionado_icmsst,
          percentual_reducao_bcicmsst: data.percentual_reducao_bcicmsst,
          valor_base_calculo_st: data.valor_base_calculo_st,
          aliquota_imposto_icmsst: data.aliquota_imposto_icmsst,
          valor_icmsst: data.valor_icmsst,
          aliquota_aplicavel_calculo_credito: data.aliquota_aplicavel_calculo_credito,
          valor_credito_icmssn: data.valor_credito_icmssn,
        });
      }
    }

    return () => (mounted = false);
  }, [data]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      className="modal-icms"
    >
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>ICMSSN - ICMSSN900</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <div className="session-container-form icms-modal-expand">
          {" "}
          <Grid container direction="column" spacing={1}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Origem"
                  tipo="numero"
                  value={field.origem}
                  handleInputChange={(event) =>
                    setField({ ...field, origem: event.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <SelectComponent
                  required
                  styleType="form"
                  label="CSOSN"
                  initialValue={listCstCsosn.find((item) =>
                    item.id === Number(field.csosn)
                      ? listCstCsosn.find(
                          (item) => item.id === Number(field.csosn)
                        )
                      : null
                  )}
                  callback={(event) => setField({ ...field, csosn: event })}
                  list={listCstCsosn}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <SelectComponent
                  required
                  label="Modalidade determinação BC ICMS"
                  initialValue={
                    field.modalidade_determinacao_bcicms !== undefined
                      ? listBci.find((item) => item.id === field.modalidade_determinacao_bcicms)?.nome
                      : ""
                  }
                  callback={(id) => setField({ ...field, modalidade_determinacao_bcicms: id })}
                  styleType="form"
                  list={listBci}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Valor da BC ICMS"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_base_calculo}
                  handleInputChange={(event) =>
                    setField({ ...field, valor_base_calculo: event.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  tipo="percentage"
                  label="Redução BC (%)"
                  value={field.percentual_reducao_bc && field.percentual_reducao_bc + "%"}
                  handleInputChange={(event) =>
                    setField({ ...field, percentual_reducao_bc: event.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Alíquota do Imposto (%)"
                  tipo="percentage"
                  value={field.aliquota_imposto && field.aliquota_imposto + "%"}
                  handleInputChange={(event) =>
                    setField({ ...field, aliquota_imposto: event.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Valor do ICMS"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_icms}
                  handleInputChange={(event) =>
                    setField({ ...field, valor_icms: event.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <SelectComponent
                  required
                  label="Modalidade determinação BC ICMS ST"
                  initialValue={
                    field.modalidade_determinacao_bcicmsst !== undefined
                      ? listBci.find((item) => item.id === field.modalidade_determinacao_bcicmsst)
                      : ""
                  }
                  callback={(id) => setField({ ...field, modalidade_determinacao_bcicmsst: id })}
                  styleType="form"
                  list={listBci}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Margem valor adicionado ICMS ST(%)"
                  tipo="percentage"
                  value={field.percentual_margem_valor_adicionado_icmsst && field.percentual_margem_valor_adicionado_icmsst + "%"}
                  handleInputChange={(event) =>
                    setField({ ...field, percentual_margem_valor_adicionado_icmsst: event.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  label="Redução BC ICMS ST (%)"
                  tipo="percentage"
                  value={field.percentual_reducao_bcicmsst && field.percentual_reducao_bcicmsst + "%"}
                  handleInputChange={(event) =>
                    setField({ ...field, percentual_reducao_bcicmsst: event.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  label="Valor da BC ICMS ST"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_base_calculo_st}
                  handleInputChange={(event) =>
                    setField({ ...field, valor_base_calculo_st: event.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputComponent
                  required
                  tipo="percentage"
                  label="Alíquota do imposto do ICMS ST (%)"
                  value={field.aliquota_imposto_icmsst && field.aliquota_imposto_icmsst + "%"}
                  handleInputChange={(event) =>
                    setField({ ...field, aliquota_imposto_icmsst: event.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="container-row-height">
                <InputComponent
                  required
                  label="Valor do ICMS ST"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_icmsst}
                  handleInputChange={(event) =>
                    setField({ ...field, valor_icmsst: event.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="container-row-height">
                <InputComponent
                  required
                  label="Alíquota aplicável de cálculo do crédito (%)"
                  tipo="percentage"
                  value={field.aliquota_aplicavel_calculo_credito && field.aliquota_aplicavel_calculo_credito + "%"}
                  handleInputChange={(event) =>
                    setField({ ...field, aliquota_aplicavel_calculo_credito: event.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="container-row-height">
                <InputComponent
                  required
                  label="Valor crédito do ICMS que pode ser aproveitado"
                  tipo="dinheiro"
                  title="R$"
                  value={field.valor_credito_icmssn}
                  handleInputChange={(event) =>
                    setField({ ...field, valor_credito_icmssn: event.value })
                  }
                />
              </Grid>
            </Grid>
            <Grid container justify="flex-end" item>
              <ButtonComponent
                text="Salvar"
                onClick={() => handleSubmit()}
                className="btn-success zero-margin"
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <CallbackMessage
        open={callbackShown}
        errorList={callbackErrorList}
        duration={4000}
        message={"Erro!"}
        type={"error"}
        handleClose={handleClose}
      />
    </Dialog>
  );
}

export default ModalIcmssn900;
