import ButtonComponent from "../../ButtonComponent";

export function FilterButton({text, onClick, className}) {
  return (
    <ButtonComponent
      onClick={onClick}
      text={text}
      className={className}
    />
  );
}
