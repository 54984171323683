import Axios from './Axios';

const perfilTributarioService = {
  getTributario: async () => {
    const result = await Axios.get('/seleciona-tributario', {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  getTributarioEspecial: async () => {
    const result = await Axios.get('/seleciona-tributario-especial', {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  sendServico: async (
    cod_servico,
    cod_servico_municipio,
    nome,
    iss,
    cod_tipo_tributacao_servico,
    id_empresa
  ) => {
    const result = await Axios.post('/cadastra-servico', {
      cod_servico: cod_servico,
      cod_servico_municipio: cod_servico_municipio,
      nome: nome,
      iss: iss,
      cod_tipo_tributacao_servico: cod_tipo_tributacao_servico,
      id_empresa: id_empresa,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return result;
  },
  sendPerfilTributario: async (
    id_empresa,
    cod_optante_simples,
    cod_regime_tributario,
    cod_regime_tributario_especial,
    usuario_prefeitura,
    senha_prefeitura,
    pis,
    cofins,
    csll,
    irpj,
    inss,
    numero_ultima_nf,
    numero_lote_nf,
    serie_nf,
    logo_nf,
    numero_ultima_nfce,
    token_csc_nfce,
    token_csc_hml_nfce,
    serie_nfce,
    id_token_nfce,
    id_token_hml_nfce,
    chave_autorizacao_nfse,
    modulo_nfe,
    modulo_nfse,
    modulo_nfce,
    grupo_transporte_reboque,
    grupo_volume_carga_lacres,
    retencao_icms_transporte,
    id_qualificacao
  ) => {
    const result = await Axios.post('/cadastra-perfil-tributario', {
      id_empresa,
      cod_optante_simples,
      cod_regime_tributario,
      cod_regime_tributario_especial,
      usuario_prefeitura,
      senha_prefeitura,
      pis,
      cofins,
      csll,
      irpj,
      inss,
      numero_ultima_nf,
      numero_lote_nf,
      serie_nf,
      logo_nf,
      numero_ultima_nfce,
      token_csc_nfce,
      token_csc_hml_nfce,
      serie_nfce,
      id_token_nfce,
      id_token_hml_nfce,
      chave_autorizacao_nfse,
      modulo_nfe,
      modulo_nfse,
      modulo_nfce,
      grupo_transporte_reboque,
      grupo_volume_carga_lacres,
      retencao_icms_transporte,
      id_qualificacao
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return result;
  },
  atualizaPerfilTributario: async (
    id_empresa,
    cod_optante_simples,
    cod_regime_tributario,
    cod_regime_tributario_especial,
    usuario_prefeitura,
    senha_prefeitura,
    pis,
    cofins,
    csll,
    irpj,
    inss,
    numero_ultima_nf,
    numero_lote_nf,
    serie_nf,
    logo_nf,
    numero_ultima_nfce,
    token_csc_nfce,
    token_csc_hml_nfce,
    serie_nfce,
    id_token_nfce,
    id_token_hml_nfce,
    chave_autorizacao_nfse,
    modulo_nfe,
    modulo_nfse,
    modulo_nfce,
    grupo_transporte_reboque,
    grupo_volume_carga_lacres,
    retencao_icms_transporte,
    id_qualificacao
  ) => {
    const result = await Axios.post(
      `/atualiza-perfil-tributario/${id_empresa}`,
      {
        id_empresa: id_empresa,
        cod_optante_simples: cod_optante_simples,
        cod_regime_tributario: cod_regime_tributario,
        cod_regime_tributario_especial: cod_regime_tributario_especial,
        usuario_prefeitura: usuario_prefeitura,
        senha_prefeitura: senha_prefeitura,
        pis: pis,
        cofins: cofins,
        csll: csll,
        irpj: irpj,
        inss: inss,
        numero_ultima_nf: numero_ultima_nf,
        numero_lote_nf: numero_lote_nf,
        serie_nf: serie_nf,
        logo_nf: logo_nf,
        numero_ultima_nfce,
        token_csc_nfce,
        token_csc_hml_nfce,
        serie_nfce,
        id_token_nfce,
        id_token_hml_nfce,
        chave_autorizacao_nfse,
        modulo_nfe,
        modulo_nfse,
        modulo_nfce,
        grupo_transporte_reboque,
        grupo_volume_carga_lacres,
        retencao_icms_transporte,
        id_qualificacao
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return result;
  },
  consultaPerfil: async (id) => {
    const result = await Axios.get(`/consulta-perfil-tributario/${id}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return result;
  },
  getConfiguracaoRegime: async (id) => {
    const result = await Axios.get(`/seleciona-configuracao-regime/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};

export default perfilTributarioService;
