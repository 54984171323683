import React, { useState, useEffect } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useParams, useHistory } from "react-router-dom";
import Input from "../../components/Input";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import Textarea from "../../components/Textarea";
import Button from "../../components/ButtonComponent";
import CallbackMessage from "../../components/CallbackMessage";
import "./styles.css";

import empresasService from "../../services/empresasService";

function ConfiguracoesEmpresas() {
  const [descricaoServico, setDescricaoServico] = useState("");
  const [provisao, setProvisao] = useState("");
  const [ferias, setFerias] = useState("");
  const [errorsList, setErrorsList] = useState([]);
  const [showMessage, setShowMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const params = useParams();
  const history = useHistory();

  const validateField = () => {
    let list = [];
    if (descricaoServico === "")
      list.push("O campo Descrição deve ser preenchido.");
    if (provisao === "") list.push("O campo Provisão deve ser preenchido.");
    if (ferias === "") list.push("O campo Férias deve ser preenchido.");

    if (list.length > 0) {
      setErrorsList(list);
      setShowMessage(true);
    }

    return list.length === 0;
  };

  const handleEditSubmit = async () => {
    if (!validateField()) return;
    console.log(params.id, descricaoServico, provisao, ferias);
    await empresasService
      .editarConfiguracao(
        params.id,
        descricaoServico,
        Number(provisao),
        Number(ferias)
      )
      .then((response) => {
        if (response.status === 401) {
          setOpenLoginExpirado(true);
          return;
        }
        if (response.status === 200) {
          setShowMessage(true);
        } else if (response.status !== 200) {
          if (response.data.error)
            setErrorsList(Object.values(response.data.error));
          else setErrorsList(["Ocorreu um erro"]);
          setShowMessage(true);
        }
      });
  };

  function handleClose(event, reason) {
    setShowMessage(false);
    if (errorsList.length === 0) {
      history.push("/empresas");
    }
    setErrorsList([]);
  }

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          <form className="company-config-form">
            <div className="company-config-form-descricao-input">
              <Textarea
                nome="descricao"
                label="Descrição de serviços padrão"
                handleInputChange={(event) =>
                  setDescricaoServico(event.target.value)
                }
              />
            </div>
            <div className="company-config-form-provisao-input">
              <Input
                nome="provisao"
                label="Provisão"
                tipo="number"
                handleInputChange={(event) => {
                  setProvisao(event.target.value);
                }}
              />
            </div>
            <div className="company-config-form-ferias-input">
              <Input
                nome="ferias"
                label="Férias"
                tipo="number"
                handleInputChange={(event) => {
                  setFerias(event.target.value);
                }}
              />
            </div>
            <span className="required-text">* Campos obrigatórios</span>
            {showMessage && (
              <CallbackMessage
                open={showMessage}
                type={`${errorsList.length === 0 ? "success" : "error"}`}
                message={`${
                  errorsList.length === 0
                    ? "Configuração atualizada com sucesso!"
                    : "Erro!"
                }`}
                errorList={errorsList}
                handleClose={handleClose}
                duration={errorsList.length === 0 ? 2000 : 6000}
              />
            )}
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            text="Salvar"
            type="button"
            className="btn-success"
            onClick={handleEditSubmit}
          />
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default ConfiguracoesEmpresas;
