import Axios from "./Axios";

const categoriasServices = {
  getCategorias: async (idEmpresa) => {
    const result = await Axios.get(
      `/configuracao-lista-grupo-categoria/${idEmpresa}`,
      {}
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  getCategoriaPadrao: async () => {
    const response = await Axios.get("/lista-categoria-padrao", {})
      .then(({ ...response }) => {
        console.log("axios", response);
        return response;
      })
      .catch(({ response }) => {
        console.log("axios", response);

        return response;
      });
    console.log("axios", response);

    return response;
  },
  consultarCategoria: async (id) => {
    const response = await Axios.get(
      `/configuracao-consulta-categoria/${id}`,
      {}
    )
      .then(({ ...response }) => {
        console.log(response);
        return response;
      })
      .catch(({ response }) => {
        console.log(response);

        return response;
      });

    return response;
  },
  getGrupoCategorias: async () => {
    const response = await Axios.get("/configuracao-lista-grupo-categoria", {})
      .then(({ ...response }) => {
        console.log(response);

        return response;
      })
      .catch(({ response }) => {
        console.log(response);

        return response;
      });
    return response;
  },
  cadastraCategoria: async ({
    idEmpresa,
    idCategoria,
    nome,
    idTipoCategoria,
    contaIntegracao = null,
    status,
  }) => {
    const response = await Axios.post("/cadastra-categoria", {
      id_empresa: idEmpresa,
      id_categoria_pai: idCategoria,
      nome,
      conta_integracao: contaIntegracao,
      id_tipo_categoria: idTipoCategoria,
      status,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  atualizaCategoria: async ({
    id,
    idEmpresa,
    idCategoria,
    nome,
    idTipoCategoria,
    contaIntegracao = null,
    status,
  }) => {
    const response = await Axios.post(
      `/configuracao-atualiza-categoria/${id}`,
      {
        id_empresa: idEmpresa,
        id_categoria_pai: idCategoria,
        nome,
        conta_integracao: contaIntegracao,
        id_tipo_categoria: idTipoCategoria,
        status,
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  atualizaCategoriaPadrao: async (id_categoria_padrao, conta) => {
    const response = await Axios.post(
      `atualiza-categoria-padrao/${id_categoria_padrao}`,
      {
        conta_contabil: conta,
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  removerGrupo: async (groupId) => {
    const response = await Axios.get(`/configuracao-remove-grupo/${groupId}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  removerCategoria: async (id) => {
    const response = await Axios.post(`/remove-categoria/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  atualizarStatusSubcategoria: async (id) => {
    const response = await Axios.post(`/atualiza-status-categoria/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  atualizaStatusGrupo: async (id) => {
    const response = await Axios.post(`/atualiza-status-grupo/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  importarSubcategoria: async ({ arquivo, id_empresa }) => {
    const response = await Axios.post(`/importar-subcategorias`, {
      arquivo,
      id_empresa,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};

export default categoriasServices;
