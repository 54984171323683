import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import SelectList from "../../../../components/SelectList";
import Select from "../../../../components/SelectComponent";
import Input from "../../../../components/Input";
import Button from "../../../../components/ButtonComponent";
import OverlayLoading from "../../../../components/OverlayLoading";
import ModalLoginExpirado from "../../../../components/ModalLoginExpirado";
import CallbackMessage from "../../../../components/CallbackMessage";

import empresasService from "../../../../services/empresasService";
import selectServices from "../../../../services/selectServices";
import produtosServices from "../../../../services/produtosServices";
import notasFiscaisServices from "../../../../services/notasFiscaisService";
import enumsServices from "../../../../services/enumsServices";
import clientesService from "../../../../services/clientesService";

import {
  numberToCurrency,
  currencyToNumber,
} from "../../../../utils/functions";
import { cpfCnpjMask } from "../../../../utils/strings";
import validateFields from "../../../../utils/validateFields";

import IconNfe from "../../../../assets/img/icon-nf-e.svg";
import "./styles.scss";
import Textarea from "../../../../components/Textarea";
import { isNumber } from "lodash";
import perfilTributarioService from "../../../../services/perfilTributarioService";
import viacepServices from "../../../../services/viacepServices";
import SwitchComponent from "../../../../components/Switch";
import recebimentosService from "../../../../services/recebimentosService";
import DatePicker from "../../../../components/DatePicker";
import Parcelamentos from "../../../../components/Parcelamentos";
import useCompany from "../../../../hooks/useCompany";

function FormaPagamentoNFe({
  listFormaPagamento,
  listMeioPagamento,
  handleAdicionarPagamento,
  openPagamentoValores,
  formPagamento,
  handleRemoverPagamento,
  setFormPagamento,
}) {
  const [valorPagamentoAux, setValorPagamentoAux] = useState(null);
  const [meioPagamentoAux, setMeioPagamentoAux] = useState(null);
  const [formaPagamentoAux, setFormaPagamentoAux] = useState(null);

  async function handleAddPagamento() {
    let status = await handleAdicionarPagamento(
      formaPagamentoAux,
      meioPagamentoAux,
      valorPagamentoAux
    );
    if (status) {
      setValorPagamentoAux("");
      setMeioPagamentoAux("");
      setFormaPagamentoAux("");
    }
  }

  return (
    <>
      <div className="emitir-nfce-form-title">Dados de pagamento</div>
      <Grid container direction="column" spacing={3}>
        <Grid item container alignItems="flex-end" spacing={1}>
          <Grid item container spacing={1} xs={11} sm={11} md={11}>
            <Grid item xs={12} sm={12} md={4}>
              <Select
                key={0}
                label="Forma de pagamento"
                styleType="form"
                required
                list={listFormaPagamento}
                // initialValue={formaPagamentoAux}
                initialValue={
                  listFormaPagamento.find(
                    (item) => item.id === formaPagamentoAux
                  )
                    ? listFormaPagamento.find(
                        (item) => item.id === formaPagamentoAux
                      ).nome
                    : ""
                }
                callback={(id) => {
                  // let list = formPagamento.filter( a => true);

                  // list[list.length - 1].formaPagamento = id;
                  // setFormPagamento(list);
                  setFormaPagamentoAux(id);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Select
                key={1}
                label="Meio de pagamento"
                styleType="form"
                required
                list={listMeioPagamento}
                // initialValue={formPagamento[formPagamento.length - 1].meioPagamento ? formPagamento[formPagamento.length - 1].meioPagamento : ''}
                initialValue={
                  listMeioPagamento.find((item) => item.id === meioPagamentoAux)
                    ? listMeioPagamento.find(
                        (item) => item.id === meioPagamentoAux
                      )?.nome
                    : ""
                }
                callback={(id) => {
                  setMeioPagamentoAux(id);
                  // let list = formPagamento.filter( a => true);
                  // list[list.length - 1].meioPagamento = id;
                  // setFormPagamento(list);
                }}
              />
            </Grid>
            <Grid item xs={11} sm={12} md={4}>
              <Input
                label="Valor"
                required
                tipo="dinheiro"
                // value={formPagamento[formPagamento.length - 1].valor ? formPagamento[formPagamento.length - 1].valor : ''}
                value={valorPagamentoAux}
                handleInputChange={(event) => {
                  // let list = formPagamento.filter( a => true);
                  // list[list.length - 1].valor = event.value;
                  // // setValorPagamentoAux(event.value);
                  // setFormPagamento(list);
                  setValorPagamentoAux(event.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Button
              className="action-outline emitir-nfce-btn-adicionar"
              text="+"
              onClick={() => {
                handleAddPagamento();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {openPagamentoValores && (
        <div className="emitir-nfce-pagamento">
          <Grid item container direction="column" spacing={1}>
            <Grid container item spacing={2} xs={11} sm={11} md={11}>
              <Grid item className="emitir-nfce-pagamento-label" xs sm md>
                Forma de pagamento
              </Grid>
              <Grid item className="emitir-nfce-pagamento-label" xs sm md>
                Meio de pagamento
              </Grid>
              <Grid item className="emitir-nfce-pagamento-label" xs sm md>
                Valor
              </Grid>
            </Grid>
            {formPagamento.map((item, index) => (
              <>
                {formPagamento[index].formaPagamento !== null &&
                  formPagamento[index].meioPagamento !== null &&
                  formPagamento[index].valor !== null && (
                    <Grid
                      item
                      direction="column"
                      xs={12}
                      sm={12}
                      md={12}
                      key={index}
                      className="emitir-nfce-pagamento-row"
                    >
                      <Grid
                        item
                        className="emitir-nfce-pagamento-divider"
                        xs={11}
                        sm={11}
                        md={11}
                      />
                      <Grid container item spacing={1} xs={12} sm={12} md={12}>
                        <Grid
                          item
                          className="emitir-nfce-pagamento-text"
                          xs
                          sm
                          md
                        >
                          {
                            listFormaPagamento.find(
                              (item) =>
                                item.id ===
                                Number(formPagamento[index].formaPagamento)
                            )?.nome
                          }
                        </Grid>
                        <Grid
                          item
                          className="emitir-nfce-pagamento-text"
                          xs
                          sm
                          md
                        >
                          {
                            listMeioPagamento.find(
                              (item) =>
                                item.id ===
                                Number(formPagamento[index].meioPagamento)
                            )?.nome
                          }
                        </Grid>
                        <Grid
                          item
                          className="emitir-nfce-pagamento-valor"
                          xs
                          sm
                          md
                        >
                          {numberToCurrency(Number(formPagamento[index].valor))}
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                          <Button
                            className="action-outline emitir-nfce-btn-adicionar"
                            text="-"
                            onClick={() => handleRemoverPagamento(index)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
              </>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
}

function EmitirNfce() {
  const history = useHistory();

  const {
    companyList: listEmpresa,
    selectedCompany: empresa,
    setSelectedCompany: setEmpresa,
    getEmpresaPagina,
  } = useCompany("nfe");

  const { selecionaProduto } = produtosServices;
  const { consultaEmpresa } = empresasService;
  const { cadastraNota } = notasFiscaisServices;
  const { selecionaEstado } = viacepServices;
  const { consultaPerfil } = perfilTributarioService;
  const { selecionaCategoriaRecebimento } = recebimentosService;

  const {
    finalidadeEmissao: getFinalidadeEmissao,
    indicadorPresenca,
    modalidadeFrete: getModalidadeFrete,
    naturezaOperacao: getNaturezaOperacao,
  } = enumsServices;
  const { selecionaCliente } = clientesService;
  const { selecionaTransportadora } = selectServices;

  const [danfeCompanyInfo, setDanfeCompanyInfo] = useState(null);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [loading, setLoading] = useState();
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [openCadastro, setOpenCadastro] = useState(false);
  const [openPagamentoValores, setOpenPagamentoValores] = useState(false);
  const [produto, setProduto] = useState();
  const [cliente, setCliente] = useState(null);
  const [somaQuantidade, setSomaQuantidade] = useState(0.0);
  const [somaDesconto, setSomaDesconto] = useState(0.0);
  const [somaTotal, setSomaTotal] = useState(0.0);
  const [somaTotalPagar, setSomaTotalPagar] = useState(0.0);
  const [formProduto, setFormProduto] = useState([]);

  const [listFinalidadeEmissao, setListFinalidadeEmissao] = useState([]);
  const [listIndicadorVendaPresencial, setListIndicadorVendaPresencial] =
    useState([]);
  const [listModalidadeFrete, setListModalidadeFrete] = useState([]);
  const [listTransportadora, setListTransportadora] = useState([]);
  const [listNaturezaOperacao, setListNaturezaOperacao] = useState([]);
  const [listClientes, setListClientes] = useState([]);

  const [listFormaPagamento, setListFormaPagamento] = useState([]);
  const [listMeioPagamento, setListMeioPagamento] = useState([]);
  const [listProduto, setListProduto] = useState([]);
  const objPagamento = {
    valor: null,
    formaPagamento: null,
    meioPagamento: null,
  };

  const [formPagamento, setFormPagamento] = useState([]);

  const [naturezaOperacao, setNaturezaOperacao] = useState(null);
  const [tipoOperacao, setTipoOperacao] = useState(null);
  const [destinoOperacao, setDestinoOperacao] = useState(null);
  const [tipoImpressaoDanfe, setTipoImpressaoDanfe] = useState(null);
  const [finalidadeEmissao, setFinalidadeEmissao] = useState(null);
  const [indicadorVendaPresencial, setIndicadorVendaPresencial] =
    useState(null);
  const [modalidadeFrete, setModalidadeFrete] = useState(null);
  const [transportadora, setTransportadora] = useState(null);
  const [outrasInformacoes, setOutrasInformacoes] = useState();

  const [dataCopy, setDataCopy] = useState(null);
  const [initialValueClient, setInitialValueClient] = useState(null);
  const [initialValueNaturezaOperacao, setInitialValueNaturezaOperacao] =
    useState(null);
  const [initialValueFinalidadeEmissao, setInitialValueFinalidadeEmissao] =
    useState(null);
  const [
    initialValueIndicadorVendaPresencial,
    setInitialValueIndicadorVendaPresencial,
  ] = useState(null);
  const [initialValueModalidadeFrete, setInitialValueModalidadeFrete] =
    useState(null);

  const [showGrupoTransporteReboque, setShowGrupoTransporteReboque] =
    useState(false);
  const [showReboque, setShowReboque] = useState(false);
  const [showGrupoVolumeCargaLacres, setShowGrupoVolumeCargaLacres] =
    useState(false);
  const [showRetencaoIcmsTransporte, setShowRetencaoIcmsTransporte] =
    useState(false);

  //Veículos de transporte e reboque
  const [placaVeiculoTransporte, setPlacaVeiculoTransporte] = useState(null);
  const [siglaUfTransporte, setSiglaUfTransporte] = useState(null);
  const [registroNacionalTransporte, setRegistroNacionalTransporte] =
    useState(null);
  const [placaVeiculoReboque, setPlacaVeiculoReboque] = useState(null);
  const [siglaUfReboque, setSiglaUfReboque] = useState(null);
  const [registroNacionalReboque, setRegistroNacionalReboque] = useState(null);
  const [identificacaoVagao, setIdentificacaoVagao] = useState(null);
  const [identificacaoBalsa, setIdentificacaoBalsa] = useState(null);

  const [listaEstados, setListaEstados] = useState([]);

  // Volume de carga e lacres
  const [quantidadeVolumesTransportados, setquantidadeVolumesTransportados] =
    useState(null);
  const [especieVolumesTransportados, setEspecieVolumesTransportados] =
    useState(null);
  const [marcaVolumesTransportados, setMarcaVolumesTransportados] =
    useState(null);
  const [numeracaoVolumesTransportados, setNumeracaoVolumesTransportados] =
    useState(null);
  const [pesoLiquido, setPesoLiquido] = useState(null);
  const [pesoBruto, setPesoBruto] = useState(null);
  const [numeroDosLacres, setNumeroDosLacres] = useState(null);

  // Retenção de ICMS Transporte
  const [valorServicoIcms, setValorServicoIcms] = useState(null);
  const [bcRetencaoIcms, setBcRetencaoIcms] = useState(null);
  const [aliquotaRetencaoIcms, setAliquotaRetencaoIcms] = useState(null);
  const [valorIcmsRetido, setValorIcmsRetido] = useState(null);
  const [cfopIcms, setCfopIcms] = useState(null);
  const [codigoMunicipioIcms, setCodigoMunicipioIcms] = useState(null);
  const [listCategorias, setListCategorias] = useState([]);
  const [idCategoria, setIdCategoria] = useState(null);
  const [dataVencimento, setDataVencimento] = useState(null);
  const [showParcela, setShowParcela] = useState(false);
  const [parcelamentos, setParcelamentos] = useState([]);
  const [valorTotalPagamentos, setValorTotalPagamentos] = useState(null);

  useEffect(() => {
    console.log({ formPagamento });
    if (formPagamento?.length > 0) {
      const totalPagamentoT = formPagamento.reduce((acc, cur) => {
        return acc + Number(cur.valor);
      }, 0);

      setValorTotalPagamentos(totalPagamentoT);
      console.log({ totalPagamentoT });
    }
  }, [formPagamento]);
  const handleSelectProduct = useCallback((id) => {
    setProduto(id);
    setOpenCadastro(true);
  }, []);

  const loadEnumFinalidadeEmissao = useCallback(async () => {
    const responseFinalidadeEmissao = await getFinalidadeEmissao();

    if (responseFinalidadeEmissao.status === 200) {
      setListFinalidadeEmissao(responseFinalidadeEmissao.data);
    }
  }, [getFinalidadeEmissao, setListFinalidadeEmissao]);

  const loadEnumIndicadorVendaPresenvial = useCallback(async () => {
    const responseIndicadorPresenca = await indicadorPresenca();

    if (responseIndicadorPresenca.status === 200) {
      setListIndicadorVendaPresencial(responseIndicadorPresenca.data);
    }
  }, [indicadorPresenca, setListIndicadorVendaPresencial]);

  const loadEnumModalidadeFrete = useCallback(async () => {
    const responseModalidadeFrete = await getModalidadeFrete();

    if (responseModalidadeFrete.status === 200) {
      setListModalidadeFrete(responseModalidadeFrete.data);
    }
  }, [getModalidadeFrete, setListModalidadeFrete]);

  const loadEnumNaturezaOperacao = useCallback(async () => {
    const responseNaturezaOperacao = await getNaturezaOperacao();

    if (responseNaturezaOperacao.status === 200) {
      setListNaturezaOperacao(responseNaturezaOperacao.data);
    }
  }, [getNaturezaOperacao, setListNaturezaOperacao]);

  const loadTransportadoras = useCallback(
    async (idEmpresa) => {
      const responseTransportadoras = await selecionaTransportadora(idEmpresa);

      if (responseTransportadoras.status === 200) {
        setListTransportadora(responseTransportadoras.data);
      }
    },
    [selecionaTransportadora, setListTransportadora]
  );

  const loadProdutos = useCallback(
    async (id) => {
      const response = await selecionaProduto(id);

      if (response.status === 200) {
        const { data } = response;

        if (data.length === 0) {
          setCallbackMessage("Erro!");
          setCallbackErrorList([
            "Nenhum produto encontrado para a empresa selecionada.",
          ]);
          setCallbackShown(true);
          return;
        }

        const productsList = data.map((item) => ({
          image:
            "https://www.orientalheritageagra.com/wp-content/uploads/2019/03/product-png-image-59158.png",
          description: item.descricao,
          code: item.codigo_produto,
          type: item.tipo_produto,
          value: numberToCurrency(item.valor_unitario_comercial),
          valorTributario: item.valor_unitario_tributario,
          ean: item.ean,
          id: item.id_produto,
          estoqueMinimo:
            !item.estoque_minimo && item.estoque_minimo !== 0
              ? false
              : item.estoque_minimo,
          estoque: item.estoque,
        }));

        setListProduto(productsList);
      }
    },
    [selecionaProduto]
  );

  const loadListFormaPagamento = useCallback(async () => {
    const origemNF = 1;
    const response = await selectServices.selecionaFormaPagamentoPorOrigem(
      origemNF
    );
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListFormaPagamento(response.data);
  }, []);

  const loadDanfeCompanyInfo = useCallback(
    async (id) => {
      const response = await consultaEmpresa(id);

      if (response.status === 200) {
        const { data } = response;

        setDanfeCompanyInfo({
          cpfCnpj: cpfCnpjMask(data.cnpj),
          razaoSocial: data.razao_social,
          endereco: data.endereco,
          numero: data.numero,
          bairro: data.bairro,
          cidade: data.cidade,
          uf: data.uf,
        });
      }
    },
    [consultaEmpresa]
  );

  async function loadCategorias(id) {
    let response = await selecionaCategoriaRecebimento(id);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (response.status === 200) setListCategorias(response.data);
  }

  const handleChangeEmpresa = useCallback(
    async (id) => {
      setLoading(true);

      setEmpresa(id);
      loadCategorias(id);
      loadTransportadoras(id);
      await loadProdutos(id);
      await loadDanfeCompanyInfo(id);
      const responsePerfilTributario = await consultaPerfil(id);
      if (responsePerfilTributario?.status === 200) {
        const data = responsePerfilTributario.data;
        setShowGrupoTransporteReboque(
          data?.grupo_transporte_reboque ? true : false
        );
        setShowGrupoVolumeCargaLacres(
          data?.grupo_volume_carga_lacres ? true : false
        );
        setShowRetencaoIcmsTransporte(
          data?.retencao_icms_transporte ? true : false
        );
        if (data?.grupo_transporte_reboque) {
          const responseEstados = await selecionaEstado(id);
          if (responseEstados?.status === 200) {
            setListaEstados(responseEstados.data);
          }
        }
      }

      const responseListCliente = await selecionaCliente(id);

      if (responseListCliente.status === 200) {
        const { data } = responseListCliente;

        setListClientes(data);
      }

      setLoading(false);
    },
    [loadDanfeCompanyInfo, loadProdutos, selecionaCliente, loadCategorias]
  );

  const loadListMeioPagamento = useCallback(async () => {
    const response = await selectServices.selectMeioPagamento();
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    setListMeioPagamento(response.data);
  }, []);

  const handleAdicionarProduto = (produtoSelected, fields) => {
    setOpenCadastro(false);
    setFormProduto([...formProduto, { ...produtoSelected, ...fields }]);
  };

  const handleSubmit = async () => {
    setLoading(true);

    let errors = [];

    let fieldsToValidate = [
      {
        label: "empresa",
        value: empresa,
      },
      {
        label: "cliente",
        value: cliente,
      },
      {
        label: "naturezaoperacao",
        value: naturezaOperacao,
      },
      {
        label: "finalidade",
        value: finalidadeEmissao,
      },
      {
        label: "indicador",
        value: indicadorVendaPresencial,
      },
      {
        label: "tipofrete",
        value: modalidadeFrete,
      },
      {
        label: "categoria",
        value: idCategoria,
      },
      {
        label: "datavencimento",
        value: dataVencimento,
      },
    ];

    let fieldsErrors = validateFields(fieldsToValidate);

    fieldsErrors.forEach((item) => errors.push(item));

    if (formProduto.length === 0) {
      errors.push("Nenhum produto selecionado.");
    }

    if (errors.length !== 0) {
      setCallbackErrorList(errors);
      setCallbackMessage("Erro!");
      setCallbackShown(true);
      setLoading(false);
      return;
    }

    const products = formProduto.map((item) => ({
      id_produto: item.id,
      quantidade: Number(item.quantidade),
      valor: item.valorUnitario,
    }));

    const pagamentos = [...formPagamento];

    const payments = pagamentos.map((item) => ({
      id_forma_pagamento: item.formaPagamento,
      meio_pagamento: String(item.meioPagamento),
      valor_pago: Number(item.valor),
    }));

    const totalPaid = payments.reduce((acumulattor, actualValue) => {
      return acumulattor + actualValue.valor_pago;
    }, 0);

    const valorTroco =
      totalPaid > somaTotalPagar ? totalPaid - somaTotalPagar : 0;

    const siglaUfReboqueTemp = listaEstados?.find(
      (item) => item.id === siglaUfReboque
    )?.uf;

    let parcelas = parcelamentos.map((item, index) => {
      item.numero = String(index + 1).padStart(3, "0");
      item.valor = Number(item.valor);
      return item;
    });

    let response = await cadastraNota(
      empresa,
      cliente,
      naturezaOperacao,
      tipoOperacao,
      destinoOperacao,
      tipoImpressaoDanfe,
      finalidadeEmissao,
      indicadorVendaPresencial,
      modalidadeFrete,
      valorTroco,
      somaTotalPagar,
      somaDesconto,
      products,
      payments,
      outrasInformacoes,
      transportadora,
      placaVeiculoTransporte,
      siglaUfTransporte,
      registroNacionalTransporte,
      placaVeiculoReboque,
      siglaUfReboqueTemp,
      registroNacionalReboque,
      identificacaoVagao,
      identificacaoBalsa,
      quantidadeVolumesTransportados,
      especieVolumesTransportados,
      marcaVolumesTransportados,
      numeracaoVolumesTransportados,
      pesoLiquido,
      pesoBruto,
      numeroDosLacres,
      valorServicoIcms,
      bcRetencaoIcms,
      aliquotaRetencaoIcms,
      valorIcmsRetido,
      cfopIcms,
      codigoMunicipioIcms,
      showReboque ? 1 : 0,
      idCategoria,
      dataVencimento,
      parcelas
    );

    if (response.hasOwnProperty("response")) {
      response = response.response;
    }

    if (response.status === 200 || response.status === 201) {
      setCallbackMessage(response.data?.msg || "Nota emitida com sucesso!");
      setCallbackShown(true);
      setCallbackDuration(2000);
      setTimeout(() => history.push("/emissao/nfe"), 250);
    }

    if (response.status === 401) {
      setOpenLoginExpirado(true);
    }

    if (response.status === 400) {
      setCallbackErrorList(
        [response?.data?.msg] ||
          Object.values(response?.data?.error).flat(Infinity)
      );
      setCallbackMessage("Erro");
      setCallbackShown(true);
    }

    if (response.status === 500) {
      setCallbackErrorList(["Erro interno no servidor, contate o suporte."]);
      setCallbackMessage("Erro!");
      setCallbackShown(true);
    }
    setLoading(false);
  };

  function calculaSoma() {
    let quantidade = 0.0;
    let desconto = 0.0;
    let total = 0.0;

    for (let i = 0; i < formProduto.length; i++) {
      quantidade += Number(formProduto[i].quantidade || 0.0);
      total += Number(formProduto[i].valorTotal || 0.0);
      desconto += Number(formProduto[i].valorDesconto || 0.0);
    }

    setSomaQuantidade(quantidade);
    setSomaDesconto(desconto);
    setSomaTotal(total + desconto);
    setSomaTotalPagar(total);
  }

  function NotaNfce() {
    calculaSoma();
    return (
      <Grid
        container
        direction="column"
        className="nota-nfce-container"
        spacing={1}
      >
        <Grid item container>
          <Grid item xs={6} sm={8} md={8}>
            <pre className="nota-nfce-info">
              {danfeCompanyInfo && (
                <>
                  {`CPF/CPNJ: ${danfeCompanyInfo.cpfCnpj} ${danfeCompanyInfo.razaoSocial}
                  ${danfeCompanyInfo.endereco}, ${danfeCompanyInfo.numero}, ${danfeCompanyInfo.bairro}, ${danfeCompanyInfo.cidade} - ${danfeCompanyInfo.uf}\n
                  Nota Fiscal Eletrônica`}
                </>
              )}
            </pre>
          </Grid>
          <Grid item xs={6} sm={4} md={4} className="nota-nfce-icon-wrapper">
            <img
              className="nota-nfce-icon"
              src={IconNfe}
              alt="icone-nota-nfce"
            />
          </Grid>
        </Grid>
        <Grid item>
          <table className="nota-nfce-table">
            <thead>
              <tr>
                <th className="column-head-id">ID</th>
                <th className="column-numero">Código</th>
                <th className="column-descricao">Descrição</th>
                <th className="column-th-ean">Ean</th>
                <th className="column-qtd">Qtde</th>
                <th className="column-th-valor">Vl Unit</th>
                <th className="column-th-valor">Desc</th>
                <th className="column-th-valor">Vl Trib</th>
                <th className="column-th-valor">Valor (R$)</th>
              </tr>
            </thead>
            {formProduto.length > 0 && (
              <tbody>
                {formProduto.map((item, index) => (
                  <tr key={index}>
                    <td className="column-id">
                      <div>
                        <Button
                          className="action-outline"
                          text="-"
                          onClick={() => {
                            let list = [...formProduto];
                            list.splice(index, 1);
                            setFormProduto(list);
                            calculaSoma();
                          }}
                        />
                        <span>{item.id}</span>
                      </div>
                    </td>
                    <td className="column-numero">{item.code}</td>
                    <td className="column-descricao">{item.description}</td>
                    <td className="column-td-ean">{item.ean}</td>
                    <td className="column-quantidade">{item.quantidade}</td>
                    <td className="column-td-valor">
                      {numberToCurrency(item.valorUnitario)}
                    </td>
                    <td className="column-td-valor">
                      {numberToCurrency(item.valorDesconto)}
                    </td>
                    <td className="column-td-valor">
                      {numberToCurrency(item.valorTributo)}
                    </td>
                    <td className="column-td-valor">
                      {numberToCurrency(item.valorTotal)}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {formProduto.length === 0 && (
            <div className="table-sem-dados"> Nenhum produto adicionado</div>
          )}
        </Grid>
        <Grid item container spacing={1} justify="flex-end">
          <Grid item xs={12} sm={4} md={1} className="nota-nfce-total">
            TOTAL
          </Grid>
          <Grid item xs={12} sm={4} md={2} className="nota-nfce-total">
            {numberToCurrency(somaTotal)}
          </Grid>
        </Grid>
        <Grid
          item
          container
          className="nota-nfce-info-total-container"
          justify="flex-end"
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            className="nota-nfce-info-total-label"
          >
            Quantidade Total de Itens:
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={3}
            className="nota-nfce-info-total-valor"
          >
            {somaQuantidade}
          </Grid>
        </Grid>
        <Grid
          item
          container
          className="nota-nfce-info-total-container"
          justify="flex-end"
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            className="nota-nfce-info-total-label"
          >
            Valor total:
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={3}
            className="nota-nfce-info-total-valor"
          >
            {numberToCurrency(somaTotal)}
          </Grid>
        </Grid>
        <Grid
          item
          container
          className="nota-nfce-info-total-container"
          justify="flex-end"
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            className="nota-nfce-info-total-label"
          >
            Desconto:
          </Grid>
          <Grid item xs={12} sm={2} md={3} className="nota-nfce-info-desconto">
            {numberToCurrency(somaDesconto)}
          </Grid>
        </Grid>
        <Grid
          item
          container
          className="nota-nfce-info-total-container"
          justify="flex-end"
        >
          <Grid itemxs={12} sm={4} md={4} className="nota-nfce-info-soma-label">
            Valor Total a Pagar:
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={3}
            className="nota-nfce-info-soma-valor"
          >
            {numberToCurrency(somaTotalPagar)}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function ProdutoCadastro() {
    let produtoSelected = listProduto.find((item) => item.id === produto);

    const [callbackAddProdutoShown, setCallbackAddProdutoShown] =
      useState(false);
    const [callbackAddProdutoMessage, setCallbackAddProdutoMessage] =
      useState("");
    const [callbackAddProdutoErrorList, setCallbackAddProdutoErrorList] =
      useState([]);

    useEffect(() => {
      if (
        isNumber(produtoSelected?.estoque) &&
        isNumber(produtoSelected?.estoqueMinimo) &&
        produtoSelected?.estoque <= produtoSelected?.estoqueMinimo
      ) {
        setCallbackAddProdutoMessage("Atenção!");
        setCallbackAddProdutoErrorList(["Estoque mínimo do produto atingido!"]);
        setCallbackAddProdutoShown(true);
      }
    }, [produtoSelected]);
    const [quantidade, setQuantidade] = useState(1);
    const [valorDesconto, setValorDesconto] = useState(0);

    let valorTributo = produtoSelected?.valorTributario;
    let valorUnitario = currencyToNumber(produtoSelected?.value);

    const valorTotal = useMemo(() => {
      let total = 0;

      total = valorUnitario * quantidade - valorDesconto;

      return total;
    }, [valorUnitario, quantidade, valorDesconto]);

    const handleValorDesconto = (value) => {
      if (Number(value) > Number(valorTotal)) {
        setCallbackAddProdutoMessage("Erro!");
        setCallbackAddProdutoErrorList([
          "O desconto não pode ser maior que o valor total.",
        ]);
        setCallbackAddProdutoShown(true);
        return;
      }
      setValorDesconto(value);
    };

    const handleCloseAddProduto = (event, reason) => {
      if (reason === "timeout") {
        setCallbackAddProdutoShown(false);
      }
    };

    return (
      <>
        <Grid item container direction="column" spacing={2}>
          <Grid
            item
            container
            direction="column"
            className="emitir-nfce-cadastro-container"
            spacing={2}
          >
            <Grid item className="emitir-nfce-form-title">
              {`Item: #${formProduto.length + 1}`}
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                <Input
                  readOnly
                  title={produtoSelected.description}
                  label="Descrição do Produto ou Serviço"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Input readOnly title={produtoSelected.code} label="Código" />
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <div>
                    <div className="label">Imagem</div>
                    <img
                      src={produtoSelected.image}
                      alt="imagem-produto"
                      className="emitir-nfce-cadastro-imagem"
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  xs={12}
                  sm={12}
                  md={6}
                  spacing={1}
                >
                  <Grid item container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      className="emitir-nfce-field-quantidade"
                    >
                      <Input
                        label="Quantidade"
                        tipo="decimal"
                        value={quantidade}
                        handleInputChange={(event) =>
                          setQuantidade(event.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Input
                        label="Valor unitário (R$)"
                        tipo="dinheiro"
                        readOnly
                        value={valorUnitario}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="flex-end">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      className="emitir-nfce-field-desconto"
                    >
                      <Input
                        label="Desconto"
                        tipo="dinheiro"
                        value={valorDesconto ? valorDesconto : ""}
                        handleInputChange={(event) =>
                          handleValorDesconto(event.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container justify="flex-end">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      className="emitir-nfce-field-total"
                    >
                      <Input
                        label="Total"
                        tipo="dinheiro"
                        readOnly
                        value={valorTotal}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justify="flex-end">
            <Button
              text="+ Adicionar"
              className="success-outline zero-margin"
              onClick={() =>
                handleAdicionarProduto(produtoSelected, {
                  valorDesconto: valorDesconto,
                  valorTotal: valorTotal,
                  valorTributo: valorTributo,
                  valorUnitario: valorUnitario,
                  quantidade: quantidade,
                })
              }
            />
          </Grid>
        </Grid>
        <CallbackMessage
          key={1}
          open={callbackAddProdutoShown}
          type={callbackAddProdutoErrorList.length !== 0 ? "error" : "success"}
          duration={6000}
          errorList={callbackAddProdutoErrorList}
          message={callbackAddProdutoMessage}
          handleClose={handleCloseAddProduto}
        />
      </>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }

    if (reason === "timeout") {
      setCallbackShown(false);
    }
  };

  const [pagCadastroCallbackShown, setPagCadastroCallbackShown] =
    useState(false);
  const [pagCadastroCallbackMessage, setPagCadastroCallbackMessage] =
    useState("");
  const [pagCadastroCallbackErrorList, setPagCadastroCallbackErrorList] =
    useState([]);
  const [pagCadastroCallbackDuration] = useState(6000);

  const pagCadastroHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      setPagCadastroCallbackShown(false);
    }

    if (reason === "timeout") {
      setPagCadastroCallbackShown(false);
    }

    if (pagCadastroCallbackErrorList.length === 0) {
      history.goBack();
    }
  };

  const handleAdicionarPagamento = (forma, meio, valor) => {
    let list = [...formPagamento];
    let lastField = list[list.length - 1];
    const fieldsToValidate = [
      {
        label: "formadepagamento",
        value: forma === "" ? null : forma,
      },
      {
        label: "meiodepagamento",
        value: meio === "" ? null : meio,
      },
      {
        label: "valor",
        value: valor === "" ? null : valor,
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      // setPagCadastroCallbackErrorList(fieldsErrors);
      // setPagCadastroCallbackMessage('Erro!');
      // setPagCadastroCallbackShown(true);
      return false;
    } else {
      if (!openPagamentoValores) setOpenPagamentoValores(true);
      list.push({ formaPagamento: forma, meioPagamento: meio, valor: valor });
      setFormPagamento(list);
      return true;
    }
  };

  const handleRemoverPagamento = (index) => {
    let list = [...formPagamento];
    let newList = list.filter((a, i) => index !== i);
    if (newList.length === 0) {
      setOpenPagamentoValores(false);
    }
    setFormPagamento(newList);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([
        loadListFormaPagamento(),
        loadListMeioPagamento(),
        loadEnumFinalidadeEmissao(),
        loadEnumIndicadorVendaPresenvial(),
        loadEnumModalidadeFrete(),
        loadEnumNaturezaOperacao(),
      ]);
      setLoading(false);
    })();
  }, [loadListFormaPagamento, loadListMeioPagamento]);

  useEffect(() => {
    const dataNFE = history.location.state;

    history.location.state = null;

    if (dataNFE) {
      setDataCopy(dataNFE);
      setOutrasInformacoes(dataNFE.outras_informacoes);
    }
  }, []);

  useEffect(() => {
    if (listEmpresa.length > 0 && dataCopy) {
      const empresaFound = listEmpresa.find(
        (empresa) => empresa.id === dataCopy?.id_empresa
      );
      if (empresaFound) {
        handleChangeEmpresa(empresaFound?.id);
      }
    }
  }, [listEmpresa, dataCopy]);

  useEffect(() => {
    if (listClientes.length > 0 && dataCopy) {
      const clientFound = listClientes.find(
        (client) => client.id === dataCopy?.id_cliente
      );
      if (clientFound) {
        setInitialValueClient(clientFound);
        setCliente(clientFound.id);
      }
    }
  }, [listClientes, dataCopy]);

  useEffect(() => {
    if (listProduto.length > 0 && dataCopy) {
      dataCopy.itens.map((item) => {
        const produtoFound = listProduto.find(
          (produto) => produto.id === item.id_produto
        );
        if (produtoFound) {
          handleAdicionarProduto(produtoFound, {
            valorDesconto: item.desconto,
            valorTotal: item.total_item,
            valorTributo: item.valor_total_tributos,
            valorUnitario: item.valor_unitario_comercial,
            quantidade: item.quantidade,
          });
          // handleSelectProduct(produtoFound.id)
        }
      });
    }
  }, [listProduto, dataCopy]);

  useEffect(() => {
    if (listNaturezaOperacao.length > 0 && dataCopy) {
      const naturezaFound = listNaturezaOperacao.find(
        (natureza) => natureza.id === dataCopy?.natureza_da_operacao
      );
      setInitialValueNaturezaOperacao(naturezaFound);
      setNaturezaOperacao(naturezaFound.id);
    }
  }, [listNaturezaOperacao]);

  useEffect(() => {
    if (listFinalidadeEmissao.length > 0 && dataCopy) {
      const finalidadeFound = listFinalidadeEmissao.find(
        (finalidade) => finalidade.id === dataCopy?.finalidade_emissao
      );
      setInitialValueFinalidadeEmissao(finalidadeFound);
      setFinalidadeEmissao(finalidadeFound.id);
    }
  }, [listFinalidadeEmissao]);

  useEffect(() => {
    if (listIndicadorVendaPresencial.length > 0 && dataCopy) {
      const indicadorVendaFound = listIndicadorVendaPresencial.find(
        (indicador) => indicador.id === dataCopy?.indicador_venda_presencial
      );
      setInitialValueIndicadorVendaPresencial(indicadorVendaFound);
      setIndicadorVendaPresencial(indicadorVendaFound.id);
    }
  }, [listIndicadorVendaPresencial]);

  useEffect(() => {
    if (listModalidadeFrete.length > 0 && dataCopy) {
      const modalidadeFreteFound = listModalidadeFrete.find(
        (frete) => frete.id === dataCopy?.modalidade_frete
      );
      setInitialValueModalidadeFrete(modalidadeFreteFound);
      setModalidadeFrete(modalidadeFreteFound.id);
    }
  }, [listModalidadeFrete]);

  useEffect(() => {
    if (
      listMeioPagamento.length > 0 &&
      listFormaPagamento.length > 0 &&
      dataCopy
    ) {
      const result = dataCopy?.pagamentos?.map((pagamento) => {
        handleAdicionarPagamento(
          pagamento.id_forma_pagamento,
          pagamento.meio_pagamento,
          pagamento.valor_pago
        );
      });
    }
  }, [listMeioPagamento, listFormaPagamento]);

  useEffect(() => {
    console.log({ formPagamento });
    if (formPagamento?.length > 0) {
      const totalPagamentoT = formPagamento.reduce((acc, cur) => {
        return acc + Number(cur.valor);
      }, 0);

      setValorTotalPagamentos(totalPagamentoT);
      console.log({ totalPagamentoT });
    }
  }, [formPagamento]);

  useEffect(() => {
    if (!dataCopy) {
      const empresaStorage = getEmpresaPagina("nfe");
      if (empresaStorage) {
        handleChangeEmpresa(empresaStorage);
      }
    }
  }, [dataCopy]);

  return (
    <>
      <div className="session-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <form className="emitir-nfe-form-container">
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <div className="container-select-bottom">
                  <Select
                    styleType="form"
                    label="Empresa"
                    required
                    list={listEmpresa}
                    title="Selecione uma empresa"
                    callback={handleChangeEmpresa}
                    initialValue={
                      listEmpresa?.find((item) => item.id === empresa)?.nome ||
                      null
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Select
                  styleType="form"
                  label="Cliente"
                  required
                  list={listClientes}
                  title="Selecione um cliente"
                  callback={(id) => setCliente(id)}
                  initialValue={initialValueClient}
                />
              </Grid>
              <Grid item container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="container-select-bottom">
                    <Select
                      styleType="form"
                      label="Categoria"
                      required
                      list={listCategorias}
                      title="Selecione uma categoria"
                      callback={(id) => setIdCategoria(id)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DatePicker
                    label="Data de vencimento*"
                    initDate={dataVencimento || ""}
                    handleChange={(date) => {
                      setDataVencimento(date);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className="emitir-nfce-form-title">Adicionar produtos</div>
                <SelectList
                  label="Localize um produto e serviço"
                  required
                  list={listProduto}
                  title="Selecione"
                  callback={(id) => handleSelectProduct(id)}
                  hint="Produtos"
                />
              </Grid>
              {openCadastro && (
                <Grid item xs={12} sm={12} md={12}>
                  <ProdutoCadastro />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12}>
                <div className="container-select-bottom">
                  <Select
                    styleType="form"
                    label="Natureza da operação"
                    required
                    list={listNaturezaOperacao}
                    title="Selecione uma natureza"
                    callback={(id) => setNaturezaOperacao(id)}
                    initialValue={initialValueNaturezaOperacao}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className="container-select-bottom">
                  <Select
                    styleType="form"
                    label="Finalidade"
                    required
                    list={listFinalidadeEmissao}
                    title="Selecione uma finalidade"
                    callback={(id) => setFinalidadeEmissao(id)}
                    initialValue={initialValueFinalidadeEmissao}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className="container-select-bottom">
                  <Select
                    styleType="form"
                    label="Indicador"
                    required
                    list={listIndicadorVendaPresencial}
                    title="Selecione um indicador"
                    callback={(id) => setIndicadorVendaPresencial(id)}
                    initialValue={initialValueIndicadorVendaPresencial}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className="container-select-bottom">
                  <Select
                    styleType="form"
                    label="Tipo de frete"
                    required
                    list={listModalidadeFrete}
                    title="Selecione um tipo de frete"
                    callback={(id) => setModalidadeFrete(id)}
                    initialValue={initialValueModalidadeFrete}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className="container-select-bottom">
                  <Select
                    styleType="form"
                    label="Transportador"
                    required
                    list={listTransportadora}
                    title="Selecione um Transportador"
                    callback={(id) => setTransportadora(id)}
                    value={
                      listTransportadora?.find(
                        (item) => item.id === transportadora
                      )
                        ? listTransportadora?.find(
                            (item) => item.id === transportadora
                          )?.nome
                        : ""
                    }
                  />
                </div>
              </Grid>

              {showGrupoTransporteReboque && (
                <Grid container spacing={3}>
                  <Grid item xs={12} className="container-grupos">
                    <div className="emitir-nfce-form-title">
                      Veículo de transporte
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} className="margin-top-auto">
                    <div className="container-select-bottom">
                      <Input
                        label="Placa do Veículo"
                        value={placaVeiculoTransporte}
                        handleInputChange={(event) => {
                          setPlacaVeiculoTransporte(event.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} className="margin-top-auto">
                    <div className="container-select-bottom">
                      <Select
                        styleType="form"
                        label="Sigla da UF"
                        list={listaEstados}
                        title="Sigla da UF"
                        callback={(id) => setSiglaUfTransporte(id)}
                        value={
                          listaEstados?.find(
                            (item) => item.id === siglaUfTransporte
                          )
                            ? listaEstados?.find(
                                (item) => item.id === siglaUfTransporte
                              )?.nome
                            : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className="container-select-bottom">
                      <Input
                        label="Registro Nacional de Transportador de Carga"
                        value={registroNacionalTransporte}
                        handleInputChange={(event) => {
                          setRegistroNacionalTransporte(event.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item className="container-input-switch page-nfe">
                    Adicionar reboque?
                    <Grid item style={{ marginLeft: 12, marginRight: 12 }}>
                      Não
                    </Grid>
                    <Grid item>
                      <SwitchComponent
                        checked={showReboque}
                        onClick={() => setShowReboque(!showReboque)}
                      />
                    </Grid>
                    <Grid item>Sim</Grid>
                  </Grid>
                  {showReboque && (
                    <>
                      <Grid item xs={12} className="container-grupos">
                        <div className="emitir-nfce-form-title no-margin-top">
                          Reboque
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className="container-select-bottom">
                          <Input
                            label="Placa do Veículo"
                            value={placaVeiculoReboque}
                            handleInputChange={(event) => {
                              setPlacaVeiculoReboque(event.target.value);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className="container-select-bottom">
                          <Select
                            styleType="form"
                            label="Sigla da UF"
                            list={listaEstados}
                            title="Sigla da UF"
                            callback={(id) => setSiglaUfReboque(id)}
                            value={
                              listaEstados?.find(
                                (item) => item.id === siglaUfReboque
                              )
                                ? listaEstados?.find(
                                    (item) => item.id === siglaUfReboque
                                  )?.nome
                                : ""
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="container-select-bottom">
                          <Input
                            label="Registro nacional de transportador de carga"
                            value={registroNacionalReboque}
                            handleInputChange={(event) => {
                              setRegistroNacionalReboque(event.target.value);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="container-select-bottom">
                          <Input
                            label="Identificação do vagão"
                            value={identificacaoVagao}
                            handleInputChange={(event) => {
                              setIdentificacaoVagao(event.target.value);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="container-select-bottom">
                          <Input
                            label="Identificação da balsa"
                            value={identificacaoBalsa}
                            handleInputChange={(event) => {
                              setIdentificacaoBalsa(event.target.value);
                            }}
                          />
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
              {showGrupoVolumeCargaLacres && (
                <Grid container spacing={3}>
                  <Grid item xs={12} className="container-grupos">
                    <div className="emitir-nfce-form-title">
                      Volume de carga
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className="container-select-bottom">
                      <Input
                        label="Quantidade de volumes transportados"
                        tipo="numero"
                        value={quantidadeVolumesTransportados}
                        handleInputChange={(event) => {
                          setquantidadeVolumesTransportados(event.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className="container-select-bottom">
                      <Input
                        label="Espécie dos volumes transportados"
                        value={especieVolumesTransportados}
                        handleInputChange={(event) => {
                          setEspecieVolumesTransportados(event.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className="container-select-bottom">
                      <Input
                        label="Marca dos volumes transportados"
                        value={marcaVolumesTransportados}
                        handleInputChange={(event) => {
                          setMarcaVolumesTransportados(event.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className="container-select-bottom">
                      <Input
                        label="Numeração dos volumes transportados"
                        value={numeracaoVolumesTransportados}
                        handleInputChange={(event) => {
                          setNumeracaoVolumesTransportados(event.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className="container-select-bottom">
                      <Input
                        label="Peso líquido (em kg)"
                        tipo="decimal"
                        value={pesoLiquido}
                        handleInputChange={(event) => {
                          setPesoLiquido(event.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className="container-select-bottom">
                      <Input
                        label="Peso Bruto (em kg)"
                        tipo="decimal"
                        value={pesoBruto}
                        handleInputChange={(event) => {
                          setPesoBruto(event.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} className="container-grupos">
                    <div className="emitir-nfce-form-title">Lacres</div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="container-select-bottom">
                      <Input
                        label="Números dos Lacres"
                        value={numeroDosLacres}
                        handleInputChange={(event) => {
                          setNumeroDosLacres(event.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
              {showRetencaoIcmsTransporte && (
                <Grid container spacing={3}>
                  <Grid item xs={12} className="container-grupos">
                    <div className="emitir-nfce-form-title">
                      Retenção de ICMS Transporte
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className="container-select-bottom">
                      <Input
                        label="Valor do Serviço"
                        tipo="dinheiro"
                        value={valorServicoIcms}
                        handleInputChange={(event) => {
                          setValorServicoIcms(event.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className="container-select-bottom">
                      <Input
                        label="BC da Retenção do ICMS"
                        value={bcRetencaoIcms}
                        handleInputChange={(event) => {
                          setBcRetencaoIcms(event.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className="container-select-bottom">
                      <Input
                        label="Alíquota da Retenção"
                        value={aliquotaRetencaoIcms}
                        handleInputChange={(event) => {
                          setAliquotaRetencaoIcms(event.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="container-select-bottom">
                      <Input
                        label="Valor do ICMS Retido"
                        value={valorIcmsRetido}
                        handleInputChange={(event) => {
                          setValorIcmsRetido(event.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="container-select-bottom">
                      <Input
                        label="CFOP"
                        value={cfopIcms}
                        handleInputChange={(event) => {
                          setCfopIcms(event.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="container-select-bottom">
                      <Input
                        label="Código do munícipio de ocorrência do fato gerador do ICMS do transporte"
                        value={codigoMunicipioIcms}
                        handleInputChange={(event) => {
                          setCodigoMunicipioIcms(event.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
              <div className="emitir-nfce-pagamento-container">
                <FormaPagamentoNFe
                  listFormaPagamento={listFormaPagamento}
                  listMeioPagamento={listMeioPagamento}
                  handleAdicionarPagamento={handleAdicionarPagamento}
                  openPagamentoValores={openPagamentoValores}
                  formPagamento={formPagamento}
                  handleRemoverPagamento={handleRemoverPagamento}
                  setFormPagamento={setFormPagamento}
                />

                <Grid
                  item
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ height: 60 }}
                >
                  <Grid item>
                    <SwitchComponent
                      checked={showParcela}
                      onClick={() => {
                        setShowParcela(!showParcela);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <span className="title">Adicionar parcelas</span>
                  </Grid>
                </Grid>

                {showParcela && (
                  <Grid item>
                    <Parcelamentos
                      qtdTotalParcelas={2}
                      valorTotal={valorTotalPagamentos}
                      dividirEntreParcelas={true}
                      showOptions={false}
                      handleChange={(list) => {
                        setParcelamentos(list);
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12}>
                  <div className="emitir-nfce-form-title">Dados Adicionais</div>
                  <div className="container-select-bottom">
                    <Textarea
                      handleInputChange={(event) =>
                        setOutrasInformacoes(event.target.value)
                      }
                      label="Outras informações"
                      value={outrasInformacoes}
                    />
                  </div>
                </Grid>
                <div className="emitir-nfce-submit">
                  <Button
                    className="btn-primary zero-margin"
                    text="GERAR NOTA FISCAL"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </Grid>
          </form>
        </span>
      </div>
      <div className="session-container">
        <span className="session-container-header">
          <form className="emitir-nfce-form-container">
            <div className="emitir-nfce-section-title">Pré-Danfe</div>
            <NotaNfce />
          </form>
        </span>
      </div>

      <ModalLoginExpirado open={openLoginExpirado} />
      <CallbackMessage
        key={1}
        open={callbackShown}
        type={callbackErrorList.length !== 0 ? "error" : "success"}
        duration={callbackDuration}
        errorList={callbackErrorList}
        message={callbackMessage}
        handleClose={handleClose}
      />
    </>
  );
}

export default EmitirNfce;
