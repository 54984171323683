import Axios from "./Axios";

const contratoServices = {
  assinaContrato: async () => {
    const response = await Axios.post("/assina-contrato")
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  assinaContratoClicksign: async (arquivo1) => {
    const response = await Axios.post(`/assina-contrato-clicksign`, {
      arquivo1,
    })
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  atualizarTermos: async () => {
    const response = await Axios.post("/atualizar-termo-privacidade")
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  atualizaContrato: async (id, data_fim, data_inicio, id_plano,
    assinado, status) => {
    let data_encerramento = data_fim;
    let id_contrato_plano = id_plano
    const response = await Axios.post(`/atualiza-contrato/${id}`, {
      data_fim,
      data_encerramento,
      data_inicio,
      id_plano,
      id_contrato_plano,
      assinado,
      status
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  consultaContrato: async (id) => {
    const response = await Axios.get(`/consulta-contrato/${id}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  cadastraLimiteExtra: async (id_contrato, id_plano, id_servico, quantidade, ilimitado) => {
    const response = await Axios.post("/cadastra-limite-extra-contrato", {
      id_contrato, id_plano, id_servico, quantidade, ilimitado
    })
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
  geraContrato: async (nome, documento, id_plano) => {
    const response = await Axios.post("/gera-contrato", {
      nome,
      documento,
      id_plano,
    })
      .then(({ ...response }) => response)
      .catch((response) => response);

    return response;
  },
};

export default contratoServices;
