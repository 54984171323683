import Axios from './Axios';

const servicosService = {
  selecionaServico: async (id) => {
    const response = await Axios.get(`/seleciona-codigo-servico/${id}`, {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cadastraServico: async (servico) => {
    const response = await Axios.post(`/cadastra-multiplo-servico`, {
      servico,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  atualizaServicos: async (servico) => {
    const response = await Axios.post('/atualiza-multiplo-servico', { servico })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  listaServico: async (
    // id_empresa = null,
    page = null,
    order_by = null,
    order_by_type = null,
    per_page = null,
    search_term = null,
    status = null,
    id_empresa = null,
  ) => {
    const response = await Axios.get(`/lista-servico`, {
      params: {
        // id_empresa,
        page,
        order_by,
        order_by_type,
        per_page,
        search_term,
        status,
        id_empresa,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selecionaTipoTributacao: async () => {
    const response = await Axios.get('/seleciona-tipo-tributacao-servico')
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  removeServico: async (idServico) => {
    const response = await Axios.get(`/remove-servico/${idServico}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  solicitaServico: async (data) => {
    const response = await Axios.post('/solicita-servico', data)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cadastraServico: async (
    nome,
    valor,
    id_tipo_servico,
    status,
    id_empresa,
    titulo_amigavel,
    destaque,
    cod_servico,
    cod_servico_municipio,
    iss,
    cod_tipo_tributacao_servico,
    id_categoria_padrao,
    iss_retido,
    reter_irrf,
    reter_inss,
    reter_cofins,
    reter_pis,
    reter_csll,
    reter_iss
  ) => {
    const response = await Axios.post(`/cadastra-servico`, {
      nome,
      valor,
      id_tipo_servico,
      status,
      id_empresa,
      titulo_amigavel,
      destaque,
      cod_servico,
      cod_servico_municipio,
      iss,
      cod_tipo_tributacao_servico,
      id_categoria_padrao,
      iss_retido,
      reter_irrf,
      reter_inss,
      reter_cofins,
      reter_pis,
      reter_csll,
      reter_iss
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },

  atualizaServico: async (
    id_servico_empresa,
    nome,
    valor,
    id_tipo_servico,
    status,
    id_empresa,
    titulo_amigavel,
    destaque,
    cod_servico,
    cod_servico_municipio,
    iss,
    cod_tipo_tributacao_servico,
    id_categoria_padrao,
    iss_retido,
    reter_irrf,
    reter_inss,
    reter_cofins,
    reter_pis,
    reter_csll,
    reter_iss
  ) => {
    const response = await Axios.post(
      `/atualiza-servico/${id_servico_empresa}`,
      {
        id_servico_empresa,
        nome,
        valor,
        id_tipo_servico,
        status,
        id_empresa,
        titulo_amigavel,
        destaque,
        cod_servico,
        cod_servico_municipio,
        iss,
        cod_tipo_tributacao_servico,
        id_categoria_padrao,
        iss_retido,
        reter_irrf,
        reter_inss,
        reter_cofins,
        reter_pis,
        reter_csll,
        reter_iss
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cadastraServicoPlano: async (
    nome_servico,
    valor,
    id_tipo_servico,
    status,
    id_servico_empresa,
    titulo_amigavel,
    destaque
  ) => {
    const response = await Axios.post(`/cadastra-servico-plano`, {
      nome_servico,
      valor,
      id_tipo_servico,
      status,
      id_servico_empresa,
      titulo_amigavel,
      destaque,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  consultaServico: async (id) => {
    const response = await Axios.get(`/consulta-servico/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },

  consultaServicoPlano: async (id) => {
    const response = await Axios.get(`/consulta-plano-servico/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  atualizaServicoPlano: async (
    id,
    nome_servico,
    valor,
    id_tipo_servico,
    status,
    id_servico_empresa,
    titulo_amigavel,
    destaque
  ) => {
    const response = await Axios.post(`/atualiza-servico-plano/${id}`, {
      nome_servico,
      valor,
      id_tipo_servico,
      status,
      id_servico_empresa,
      titulo_amigavel,
      destaque,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  listaServicoPlano: async (
    id_empresa = null,
    page = null,
    order_by = null,
    order_by_type = null,
    per_page = null,
    search_term = null
  ) => {
    const response = await Axios.get('/lista-servico-plano', {
      params: {
        id_empresa,
        page,
        order_by,
        order_by_type,
        per_page,
        search_term,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  inativaServico: async (idServico) => {
    const response = await Axios.get(`/inativa-servico/${idServico}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selecionaStatusServico: async () => {
    const response = await Axios.get(`/seleciona-status-servico`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};

export default servicosService;
