import Axios from "./Axios";

export const formaPagamentoServices = {
  cadastraFormaPagamento: async ({
    descricao = null,
    tipo = null,
    ativo = null,
    origem = null,
  }) => {
    const response = await Axios.post("/cadastra-forma-pagamento", {
      descricao,
      tipo,
      ativo,
      origem,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  atualizaFormaPagamento: async ({
    id = null,
    descricao = null,
    tipo = null,
    ativo = null,
    origem = null,
  }) => {
    const response = await Axios.post(`/atualiza-forma-pagamento/${id}`, {
      descricao,
      tipo,
      ativo,
      origem,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  listaFormasPagamento: async ({
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    origem = [],
  }) => {
    const response = await Axios.get("/lista-forma-pagamento", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        origem: origem,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  consultaFomaPagamento: async (id) => {
    const response = await Axios.get(`/consulta-forma-pagamento/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;
  },
  removeFormaPagamento: async (id) => {
    const response = await Axios.delete(`/remover-forma-pagamento/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};
