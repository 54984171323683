import React, { useState, useEffect, useCallback } from "react";

import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import TableComponent from "../../../../components/TableComponent";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import OverlayLoading from "../../../../components/OverlayLoading";
import CallbackMessage from "../../../../components/CallbackMessage";
import ModalLoginExpirado from "../../../../components/ModalLoginExpirado";
import ButtonComponent from "../../../../components/ButtonComponent";
import ModalNovoDependente from "../../../../components/ModalNovoDependente";
import preAdmissaoService from "../../../../services/preAdmissaoService";

import "./styles.scss";

function ListaDependentes() {
  const [listRequest, setListRequest] = useState([]);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [openCadastraDependente, setOpenCadastraDependente] = useState(false);
  const [idDependente, setIdDependente] = useState();
  const { id } = useParams();
  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Nome", dataRef: "nome" },
      { columnName: "Parentensco", dataRef: "parentesco" },
    ],
    options: {
      edit: true,
      delete: true,
    },
    display: {
      search: false,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
  });

  const updateTable = useCallback(async () => {
    setLoading(true);
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await preAdmissaoService.getDependentes(
      id,
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage
    );
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    } else if (result.status === 200) {
      result.data.data = result.data.data.map((item, index) => {
        item.id = item.id_dependente_funcionario;
        return item;
      });
      setListRequest(result.data);
    }

    setLoading(false);
  }, [id,
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  useEffect(() => {
    const newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  const onPageChange = (newPageObj) => {
    const newPage = newPageObj.page;
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onOrderBy = (newOrderBy) => {
    const orderBy = newOrderBy.orderBy;
    const orderByType = newOrderBy.orderByType;
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onPerPage = (newPerPage) => {
    const newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.perPage = newPerPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  const onEdit = (id) => {
    setIdDependente(id);
    setOpenCadastraDependente(true);
  };

  const handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  const handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  const onDelete = async () => {
    await preAdmissaoService.removeDependente(deleteId).then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      } else if (response.status === 200) {
        setErrors([]);
      } else {
        if (response.data.error) setErrors(Object.values(response.data.error));
        else setErrors(["Ocorreu um erro"]);
      }
      setShowMessage(true);
    });
    handleRemoveCancel();
  };

  const handleClose = (event, reason) => {
    setShowMessage(false);
    if (errors.length === 0) {
      updateTable();
    }
    setErrors([]);
  };

  const handleCloseDialog = async () => {
    setOpenCadastraDependente(false);
    setIdDependente();
    await updateTable();
    
  };

  return (
    <>
      {loading && <OverlayLoading />}
      <Grid container className="dependentes-container">
        <Grid container className="dependentes-wrapper">
          <Grid item>
            <h3 className="title-dependentes">Dependentes</h3>
          </Grid>
          <Grid item>
            <ButtonComponent
              className="btn-primary"
              text="+ Novo dependente"
              onClick={() => {
                setIdDependente();
                setOpenCadastraDependente(true);
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <TableComponent
            idName="id"
            dataList={listRequest !== undefined ? listRequest?.data : []}
            tableConfig={defaultConfigTable}
            callbackCurrentPage={onPageChange}
            callbackOrderBy={onOrderBy}
            callbackPerPage={onPerPage}
            cbEdit={onEdit}
            cbDelete={handleRemoveDialog}
          />
        </Grid>
      </Grid>

      <CallbackMessage
        duration={4000}
        open={showMessage}
        errorList={errors}
        message={
          errors.length === 0 ? "Dependente excluído com sucesso." : "Erro"
        }
        type={errors.length === 0 ? "success" : "error"}
        handleClose={handleClose}
      />
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
      {openCadastraDependente && (
        <ModalNovoDependente
          open={openCadastraDependente}
          onClose={handleCloseDialog}
          idFuncionario={id}
          idDependente={idDependente}
        />
      )}
    </>
  );
}

export default ListaDependentes;
