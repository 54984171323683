import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import TableComponent from "../../../components/TableComponent";
import ConfirmDialog from "../../../components/ConfirmDialog";
import OverlayLoading from "../../../components/OverlayLoading";
import ModalLoginExpirado from "../../../components/ModalLoginExpirado";

import centroCustosService from "../../../services/centroCustosService";
import "./styles.css";
import CallbackMessage from "../../../components/CallbackMessage";
import exportFileService from "../../../services/exportFile";
import { Grid } from "@material-ui/core";
import SelectComponent from "../../../components/SelectComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import useCompany from "../../../hooks/useCompany";

function filterComponent({
  empresaSelecionada,
  handleSelectEmpresa,
  handleApplyFilters,
  handleCleanFilters,
  listEmpresas,
}) {
  return (
    <div className="session-container container chat">
      <Grid container spacing={2} alignItems="center" className="form-table">
        <Grid item xs={12}>
          <SelectComponent
            styleType="form"
            label="Empresa"
            required
            initialValue={
              empresaSelecionada !== undefined
                ? listEmpresas.find((item) => item.id === empresaSelecionada)
                    ?.nome
                : ""
            }
            title="Selecione uma empresa"
            list={listEmpresas}
            callback={handleSelectEmpresa}
          />
        </Grid>
        <Grid className="ml-auto centro-custo">
          <div className="filter-button-area align-rigth">
            <ButtonComponent
              onClick={handleApplyFilters}
              text="Filtrar"
              className="btn-primary"
            />
            <ButtonComponent
              onClick={handleCleanFilters}
              text="Limpar Filtros"
              className="default-outline clean-filters-button"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function CentroCustosLista() {
  const history = useHistory();

  const { exportFile } = exportFileService;

  const {
    companyList: listEmpresas,
    selectedCompany: empresaSelecionada,
    setSelectedCompany: setEmpresaSelecionada,
    getEmpresaPagina,
    setEmpresaPagina,
  } = useCompany("centro_custo");

  const isFirstRender = useRef(true);

  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const [listRequest, setListRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);

  const [callbackType, setCallbackType] = useState("");
  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  const [defaultConfigTable, setDefaultConfigTable] = useState({
    columnArray: [
      { columnName: "Empresa", dataRef: "razao_social" },
      { columnName: "Centro de Custo", dataRef: "nome" },
    ],
    options: {
      edit: true,
      delete: true,
    },
    display: {
      search: true,
      itemsPerPage: true,
      totalResults: true,
      pagination: true,
      selfContainer: true,
    },
    tableOptions: {
      filter: true,
      more: true,
      moreOptions: [
        {
          icon: "export",
          label: "Exportar XLS",
          action: "exportar-xls",
        },
        {
          icon: "export",
          label: "Exportar PDF",
          action: "exportar-pdf",
        },
      ],
    },
    currentPage: 1,
    pagination: true,
    totalPages: 1,
    dataListTotal: "0",
    orderBy: null,
    orderByType: null,
    perPage: null,
    searchTerm: null,
    id_empresa: null,
  });

  const exportToPDF = {
    url: "exportar-centro-custo-pdf",
    fileName: "cbhub_relatorio_centro_custos",
    fileType: "pdf",
  };

  const exportToXLS = {
    url: "exportar-centro-custo-excel",
    fileName: "cbhub_relatorio_centro_custos",
    fileType: "xlsx",
  };

  useEffect(() => {
    let newDataListTotal = defaultConfigTable;
    newDataListTotal.dataListTotal = listRequest?.total;
    newDataListTotal.totalPages = listRequest?.last_page;
  }, [listRequest, defaultConfigTable]);

  let onPageChange = (newPageObj) => {
    let newPage = newPageObj.page;
    let newDefaultConfigTable = defaultConfigTable;
    newDefaultConfigTable.currentPage = newPage;
    setDefaultConfigTable(newDefaultConfigTable);
    updateTable();
  };

  let updateTable = useCallback(async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const result = await centroCustosService.getCentroCustos(
      defaultConfigTable.currentPage,
      defaultConfigTable.orderBy,
      defaultConfigTable.orderByType,
      defaultConfigTable.perPage,
      defaultConfigTable.searchTerm,
      defaultConfigTable.id_empresa
    );
    if (result.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }
    if (result.data.hasOwnProperty("data")) {
      setListRequest(result.data);
    } else {
      setListRequest(result);
    }

    setLoading(false);
  }, [
    defaultConfigTable.currentPage,
    defaultConfigTable.orderBy,
    defaultConfigTable.orderByType,
    defaultConfigTable.perPage,
    defaultConfigTable.searchTerm,
    defaultConfigTable.id_empresa,
  ]);

  let onOrderBy = (newOrderBy) => {
    let orderBy = newOrderBy.orderBy;
    let orderByType = newOrderBy.orderByType;
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.orderBy = orderBy;
    newDefaultConfigTable.orderByType = orderByType;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onPerPage = (newPerPage) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.perPage = newPerPage;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onSearchTerm = (value) => {
    let newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.searchTerm = value ? value : null;
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  let onEdit = (id) => {
    try {
      history.push(`/editar-centro-custo/${id}`);
    } catch (err) {
      console.log(err);
    }
  };

  let handleRemoveDialog = (id) => {
    setDeleteId(id);
    setConfirmRemoveDialog(true);
  };

  let handleRemoveCancel = () => {
    setConfirmRemoveDialog(false);
  };

  let onDelete = async () => {
    setLoading(true);
    await centroCustosService.removeCentroCusto(deleteId).then((response) => {
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response.status === 200) {
        setCallbackShown(true);
        setCallbackType("success");
        setCallbackMessage(
          response?.data?.msg
            ? response.data.msg
            : "Centro de custo excluído com sucesso!"
        );
        setCallbackDuration(2000);
        let newDefaultConfigTable = { ...defaultConfigTable };
        if (
          newDefaultConfigTable.currentPage > 1 &&
          listRequest?.data?.length === 1
        ) {
          newDefaultConfigTable.currentPage =
            newDefaultConfigTable.currentPage - 1;
          setDefaultConfigTable(newDefaultConfigTable);
        } else {
          updateTable();
        }
      } else {
        setCallbackShown(true);
        setCallbackType("error");
        setCallbackMessage("Erro!");
        if (response.data.error)
          setCallbackErrorList(Object.values(response.data.error));
        else setCallbackErrorList(["Ocorreu um erro"]);
      }
    });
    handleRemoveCancel();
    setLoading(false);
  };

  let onMoreAction = (action) => {
    console.log(action);
  };

  let onTableMoreAction = async ({ action }) => {
    console.log(action);

    setLoading(true);

    let params = {};
    if (action === "exportar-xls") {
      params = {
        url: exportToXLS.url,
        fileName: exportToXLS.fileName,
        fileType: exportToXLS.fileType,
        params: {
          search_term: defaultConfigTable.searchTerm,
          id_empresa: defaultConfigTable.id_empresa,
        },
      };
    } else if (action === "exportar-pdf") {
      params = {
        url: exportToPDF.url,
        fileName: exportToPDF.fileName,
        fileType: exportToPDF.fileType,
        params: {
          search_term: defaultConfigTable.searchTerm,
          id_empresa: defaultConfigTable.id_empresa,
        },
      };
    }
    try {
      const response = await exportFile(params);
      console.log(response);

      if (response.status === 200 || response.status === 201) {
        setCallbackErrorList([]);
        setCallbackShown(true);
        setCallbackMessage("Sucesso!");
        setCallbackType("success");
      } else if (response?.status === 500) {
        setCallbackErrorList([
          "Erro interno no servidor. Por favor, contate o suporte",
        ]);
        setCallbackShown(true);
        setCallbackType("error");
      } else if (response?.status === 400) {
        console.log(response);
        if (
          typeof response?.data?.error === "string" &&
          response?.data?.error?.includes("Too Many Requests")
        ) {
          setLoading(false);
          setCallbackErrorList([
            "Ocorreu um excesso de consultas. Espere 1 minuto e tente novamente.",
          ]);
          setCallbackDuration(4000);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackShown(true);
        } else if (typeof response?.data?.error === "string") {
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackMessage("Erro!");
          setCallbackDuration(4000);
          setCallbackErrorList([response?.data?.error]);
        } else if (typeof response?.data?.error === "object") {
          setLoading(false);
          setCallbackShown(true);
          setCallbackType("error");
          setCallbackDuration(4000);
          setCallbackMessage("Erro!");
          setCallbackErrorList(
            Object.values(response?.data?.error).flat(Infinity)
          );
        }
      } else {
        setCallbackErrorList(["Ocorreu um erro ao exportar o relatório."]);
        setCallbackShown(true);
        setCallbackType("error");
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  function handleClose() {
    setCallbackShown(false);
    setCallbackType("");
  }

  const handleSelectEmpresa = async (id) => {
    setEmpresaSelecionada(id);
  };

  const handleApplyFilters = (empresaStorage = null) => {
    const newDefaultConfigTable = { ...defaultConfigTable };
    console.log({ empresaStorage, empresaSelecionada });
    if (empresaStorage) {
      newDefaultConfigTable.id_empresa = empresaStorage;
    } else {
      newDefaultConfigTable.id_empresa = empresaSelecionada;
      setEmpresaPagina("centro_custo", empresaSelecionada);
    }
    newDefaultConfigTable.currentPage = 1;
    setDefaultConfigTable(newDefaultConfigTable);
  };

  const handleCleanFilters = async () => {
    const newDefaultConfigTable = { ...defaultConfigTable };
    newDefaultConfigTable.id_empresa = null;
    newDefaultConfigTable.currentPage = 1;
    setEmpresaSelecionada(null);
    setDefaultConfigTable(newDefaultConfigTable);
    setEmpresaPagina("centro_custo", null);
  };

  useEffect(() => {
    if (!isFirstRender.current) {
      updateTable();
    } else {
      isFirstRender.current = false;
    }
  }, [updateTable, defaultConfigTable]);

  useEffect(() => {
    const empresaStorage = getEmpresaPagina("centro_custo");
    if (empresaStorage) {
      handleApplyFilters(empresaStorage);
    } else {
      updateTable();
    }
  }, []);

  return (
    <div className="container-tabela-responsiva">
      {loading && <OverlayLoading />}
      <TableComponent
        idName="id"
        dataList={listRequest !== undefined ? listRequest?.data : []}
        tableConfig={defaultConfigTable}
        callbackCurrentPage={onPageChange}
        callbackOrderBy={onOrderBy}
        callbackPerPage={onPerPage}
        callbackSearchTerm={onSearchTerm}
        cbEdit={onEdit}
        cbDelete={handleRemoveDialog}
        cbMoreAction={onMoreAction}
        cbTableMoreAction={onTableMoreAction}
        filterComponent={filterComponent({
          listEmpresas,
          empresaSelecionada,
          handleSelectEmpresa,
          handleApplyFilters,
          handleCleanFilters,
        })}
      />
      <ConfirmDialog
        open={confirmRemoveDialog}
        onCancel={handleRemoveCancel}
        onAccept={onDelete}
      />

      <CallbackMessage
        type={callbackType}
        open={callbackShown}
        errorList={callbackErrorList}
        duration={callbackDuration}
        message={callbackMessage}
        handleClose={() => handleClose()}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </div>
  );
}

export default CentroCustosLista;
