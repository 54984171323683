import React, { useState } from "react";
import { Accordion } from "../../../../components/AccordionComponent";
import { Grid } from "@material-ui/core";

export function AccordionDreMobile({ dados }) {
  const [expanded, setExpanded] = useState(false);
  const [expandedCategoria, setExpandedCategoria] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeCategoria = (panel) => (event, isExpanded) => {
    setExpandedCategoria(isExpanded ? panel : false);
  };

  return (
    <>
      {dados.map((item, index) => {
        let values = [
          item.jan,
          item.fev,
          item.mar,
          item.abr,
          item.mai,
          item.jun,
          item.jul,
          item.ago,
          item.set,
          item.out,
          item.nov,
          item.dez,
          // item.total
        ];
        item.descricao = item.nome_grupo;
        const categorias = item.categorias || [];

        return (
          <div key={`summary_${index}`}>
            <Accordion.Root
              handleChange={handleChange}
              index={index}
              expanded={expanded}
            >
              <Accordion.Header
                expanded={expanded}
                index={index}
                item={item}
                columns={values}
                mobile
              />

              <Accordion.Body>
                {categorias.map((itemCategoria, indexCategoria) => {
                  itemCategoria.descricao = itemCategoria.nome_categoria;
                  const subcategorias = itemCategoria.subcategorias;
                  let valuesCategoria = [
                    item.jan,
                    item.fev,
                    item.mar,
                    item.abr,
                    item.mai,
                    item.jun,
                    item.jul,
                    item.ago,
                    item.set,
                    item.out,
                    item.nov,
                    item.dez,
                  ];

                  return (
                    <Accordion.Root
                      handleChange={handleChangeCategoria}
                      index={indexCategoria}
                      expanded={expandedCategoria}
                      child={true}
                      key={`categoria_${indexCategoria}`}
                    >
                      <Accordion.Header
                        expanded={expandedCategoria}
                        index={indexCategoria}
                        item={itemCategoria}
                        columns={valuesCategoria}
                        mobile
                      />

                      <Accordion.Body>
                        <Grid container>
                          <Grid item container justifyContent="space-between">
                            <Grid item xs={5} className="inner-font-title">
                              <div>Subcategoria</div>
                            </Grid>
                            <Grid item xs={4} className="inner-font-title">
                              <div>Período</div>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              className="inner-font-title right"
                            >
                              <div>Valor</div>
                            </Grid>
                          </Grid>

                          {subcategorias.map(
                            (subcategoria, indexCategoria) => {
                              let valuesSubcategoria = [
                                { mes: "Janeiro", value: subcategoria.Janeiro },
                                {
                                  mes: "Fevereiro",
                                  value: subcategoria.Fevereiro,
                                },
                                { mes: "Marco", value: subcategoria.Marco },
                                { mes: "Abril", value: subcategoria.Abril },
                                { mes: "Maio", value: subcategoria.Maio },
                                { mes: "Junho", value: subcategoria.Junho },
                                { mes: "Julho", value: subcategoria.Julho },
                                { mes: "Agosto", value: subcategoria.Agosto },
                                {
                                  mes: "Setembro",
                                  value: subcategoria.Setembro,
                                },
                                { mes: "Outubro", value: subcategoria.Outubro },
                                {
                                  mes: "Novembro",
                                  value: subcategoria.Novembro,
                                },
                                {
                                  mes: "Dezembro",
                                  value: subcategoria.Dezembro,
                                },
                                // {mes: 'total', value: subcategoria.total},
                              ];
                              return (
                                <Grid
                                  item
                                  container
                                  justifyContent="space-between"
                                  spacing={1}
                                  className="container-mobile-dados"
                                >
                                  {valuesSubcategoria.map(
                                    (itemSubcat, indexSubcat) => {
                                      let value = itemSubcat?.value;
                                      if (value) {
                                        value = value.replace(".", ";");
                                        value = value.replace(",", ".");
                                        value = value.replace(";", ",");
                                      }
                                      return (
                                        <>
                                          <Grid
                                            item
                                            xs={5}
                                            className="inner-cell-table"
                                          >
                                            <div>
                                              {subcategoria.subcategoria}
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            key={indexSubcat}
                                            xs={4}
                                            className="inner-cell-table"
                                          >
                                            {itemSubcat?.mes}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={3}
                                            key={index}
                                            className="inner-cell-table right"
                                          >
                                            {value}
                                          </Grid>
                                        </>
                                      );
                                    }
                                  )}
                                </Grid>
                              );
                            }
                          )}
                          <Grid
                            item
                            container
                            justifyContent="space-between"
                            spacing={2}
                            className="container-total-categoria"
                          >
                            <Grid
                              item
                              xs={5}
                              className="inner-font-title"
                            ></Grid>
                            <Grid
                              item
                              xs={4}
                              className="inner-font-title total-title"
                            >
                              <div>Total</div>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              className="inner-font-title total-value"
                            >
                              <div>{itemCategoria.total}</div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Accordion.Body>
                    </Accordion.Root>
                  );
                })}
              </Accordion.Body>
            </Accordion.Root>
            <div className="space-vertical" />
          </div>
        );
      })}
    </>
  );
}
