import Axios from './Axios';

const preAdmissaoService = {
  cadastraDocumento: async (
    id_funcionario,
    cpf,
    id_tipo_identidade,
    identidade,
    orgao_emissor_identidade,
    data_emissao_identidade,
    id_uf_identidade,
    nro_ctps,
    serie_ctps,
    id_uf_ctps,
    data_emissao_ctps,
    pis,
    data_emissao_pis,
    titulo_eleitor,
    zona_eleitoral,
    secao_eleitoral,
    carteira_habilitacao,
    data_emissao_cnh,
    data_validade_cnh,
    orgao_emissor_cnh,
    id_uf_cnh,
    data_primeira_cnh,
    id_categoria_cnh
  ) => {
    const response = await Axios.post('/cadastra-documento-admissao', {
      id_funcionario,
      cpf,
      id_tipo_identidade,
      identidade,
      orgao_emissor_identidade,
      data_emissao_identidade,
      id_uf_identidade,
      nro_ctps,
      serie_ctps,
      id_uf_ctps,
      data_emissao_ctps,
      pis,
      data_emissao_pis,
      titulo_eleitor,
      zona_eleitoral,
      secao_eleitoral,
      carteira_habilitacao,
      data_emissao_cnh,
      data_validade_cnh,
      orgao_emissor_cnh,
      id_uf_cnh,
      data_primeira_cnh,
      id_categoria_cnh,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },

  cadastraEndereco: async (
    id_funcionario,
    cep,
    endereco,
    numero,
    bairro,
    id_uf,
    id_cidade,
    telefone,
    email,
    celular,
    complemento
  ) => {
    const response = await Axios.post('/cadastra-endereco-contato-admissao', {
      id_funcionario,
      cep,
      endereco,
      numero,
      bairro,
      id_uf,
      id_cidade,
      telefone,
      email,
      celular,
      complemento,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },

  cadastraDadosBancarios: async (
    id_funcionario,
    id_tipo_conta_banco,
    id_banco,
    agencia,
    conta,
    digito
  ) => {
    const response = await Axios.post('/cadastra-dado-bancario-admissao', {
      id_funcionario,
      id_tipo_conta_banco,
      id_banco,
      agencia,
      conta,
      digito,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  cadastrarAdmissao: async (
    id_empresa,
    nome,
    data_nascimento,
    id_sexo,
    id_estado_civil,
    id_cor,
    id_grau_instrucao,
    id_pais_nascimento,
    id_naturalidade,
    id_uf_nascimento,
    id_municipio_nascimento,
    descricao,
    id_nacionalidade,
    foto,
    nome_mae,
    nome_pai,
    possui_deficiencia,
    id_tipo_deficiencia,
    id_funcionario
  ) => {
    const response = await Axios.post('/cadastra-admissao', {
      id_empresa,
      nome,
      data_nascimento,
      id_sexo,
      id_estado_civil,
      id_cor,
      id_grau_instrucao,
      id_pais_nascimento,
      id_naturalidade,
      id_uf_nascimento,
      id_municipio_nascimento,
      descricao,
      id_nacionalidade,
      foto,
      nome_mae,
      nome_pai,
      possui_deficiencia,
      id_tipo_deficiencia,
      id_funcionario,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  atualizarAdmissao: async (
    id_empresa,
    nome,
    data_nascimento,
    id_sexo,
    id_estado_civil,
    id_cor,
    id_grau_instrucao,
    id_pais_nascimento,
    id_naturalidade,
    id_uf_nascimento,
    id_municipio_nascimento,
    descricao,
    id_nacionalidade,
    foto,
    nome_mae,
    nome_pai,
    possui_deficiencia,
    id_tipo_deficiencia,
    id_funcionario
  ) => {
    const response = await Axios.post(`/edita-pre-admissao/${id_funcionario}`, {
      id_empresa,
      nome,
      data_nascimento,
      id_sexo,
      id_estado_civil,
      id_cor,
      id_grau_instrucao,
      id_pais_nascimento,
      id_naturalidade,
      id_uf_nascimento,
      id_municipio_nascimento,
      descricao,
      id_nacionalidade,
      foto,
      nome_mae,
      nome_pai,
      possui_deficiencia,
      id_tipo_deficiencia,
      id_funcionario,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  getDependentes: async (
    id,
    page = null,
    order_by = null,
    order_by_type = null,
    per_page = null
  ) => {
    const response = await Axios.get(`/lista-dependente/${id}`, {
      params: {
        page,
        order_by,
        order_by_type,
        per_page,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },

  cadastraNovoDependente: async (
    nome,
    cpf,
    telefone,
    id_parentesco,
    data_nascimento,
    data_inicio_dependencia,
    id_pais_nascimento,
    id_nacionalidade,
    deficiente,
    endereco_funcionario,
    id_funcionario,
    cep,
    endereco,
    bairro,
    numero,
    id_uf,
    id_cidade,
    complemento,
    data_entrada_pais,
    id_dependente_funcionario
  ) => {
    const response = await Axios.post('/cadastra-dependente-admissao', {
      nome,
      cpf,
      telefone,
      id_parentesco,
      data_nascimento,
      data_inicio_dependencia,
      id_pais_nascimento,
      id_nacionalidade,
      deficiente,
      endereco_funcionario,
      id_funcionario,
      cep,
      endereco,
      bairro,
      numero,
      id_uf,
      id_cidade,
      complemento,
      data_entrada_pais,
      id_dependente_funcionario,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },

  consultaDependente: async (idDependente) => {
    const response = await Axios.get(`/consulta-dependente/${idDependente}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  removeDependente: async (idDependente) => {
    const response = await Axios.get(`/remove-dependente/${idDependente}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  cadastraDadosContratuais: async (
    id_funcionario,
    dias_contrato_experiencia,
    id_atestado_admissional,
    id_tipo_admissao,
    primeiro_emprego,
    id_vinculo_trabalhista,
    id_categoria_funcionario,
    id_departamento_funcionario,
    id_funcao_funcionario,
    id_tipo_horario,
    salario_base,
    id_forma_pagamento_salario,
    data_termino_contrato,
    data_admissao,
    apto_trabalhar,
    data_validade_atestado,
    admissao_seguro_acao_fiscal,
    proventos_descontos
  ) => {
    const response = Axios.post('/cadastra-dado-contratual-admissao', {
      id_funcionario,
      dias_contrato_experiencia,
      id_atestado_admissional,
      id_tipo_admissao,
      primeiro_emprego,
      id_vinculo_trabalhista,
      id_categoria_funcionario,
      id_departamento_funcionario,
      id_funcao_funcionario,
      id_tipo_horario,
      salario_base,
      id_forma_pagamento_salario,
      data_termino_contrato,
      data_admissao,
      apto_trabalhar,
      data_validade_atestado,
      admissao_seguro_acao_fiscal,
      proventos_descontos,
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);
    return response;
  },
  getPreAdmissoes: async (
    page = null,
    order_by = null,
    order_by_type = null,
    per_page = null,
    search_term = null
  ) => {
    const response = await Axios.get('lista-pre-admissao', {
      params: {
        page,
        order_by,
        order_by_type,
        per_page,
        search_term,
      },
    })
      .then(({ ...response }) => response)
      .catch(({ response }) => response);
    return response;
  },
  consultaPreAdmissao: async (idPreAdmissao) => {
    const response = await Axios.get(`/consulta-pre-admissao/${idPreAdmissao}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
  removePreAdmissao: async (idPreAdmissao) => {
    const response = await Axios.get(`/remove-admissao/${idPreAdmissao}`)
      .then(({ ...response }) => response)
      .catch(({ response }) => response);

    return response;
  },
};

export default preAdmissaoService;
