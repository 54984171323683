import React, { useEffect, useState, useCallback } from "react";
import { Grid, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import OverlayLoading from "../OverlayLoading";
import ModalLoginExpirado from "../ModalLoginExpirado";
import CallbackMessage from "../CallbackMessage";
import notasFiscaisService from '../../services/notasFiscaisService';
import ShowMore from '../ShowMore';
import { formatDate } from '../../utils/dates'
import { numberToCurrency } from '../../utils/functions';


import "./styles.scss";

function ModalDetalhesNota({
  open,
  onClose,
  idNota,
}) {

  const { consultaNotaEntrada } = notasFiscaisService;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);

  const [dataEmitente, setDataEmitente] = useState([]);
  const [dataDestinatario, setDataDestinatario] = useState([]);
  const [dataValores, setDataValores] = useState([]);
  const [data, setData] = useState([]);

  const loadFields = useCallback(async () => {
    if (idNota) {
      setLoading(true);

      const response = await consultaNotaEntrada(idNota);

      if(response.status === 200 || response.status === 201) {
        setDataEmitente([
          {
            title: "Razão Social: ",
            description: response?.data?.emitente_razao_social
          },
          {
            title: "CNPJ: ",
            description: response?.data?.emitente_cnpj
          },
        ])
        setDataDestinatario([
          {
            title: "Razão Social:",
            description: response?.data?.destinatario_razao_social
          },
          {
            title: "CNPJ:",
            description: response?.data?.destinatario_cnpj
          },
          {
            title: "Id do destinatário:",
            description: response?.data?.destinatario_id_empresa
          },
        ])
        setDataValores([
          {
            title: "Valor Frete:",
            description: numberToCurrency(response?.data?.valor_frete)
          },
          {
            title: "Valor Total:",
            description: numberToCurrency(response?.data?.valor_total)
          },
        ]);

        setData([
          {
            title: "Data de Emissão:",
            description: formatDate(response?.data?.data_hora_emissao.substring(0, 10))
          },
          {
            title: "Chave:",
            description: response?.data?.chave
          },
          {
            title: "Número:",
            description: response?.data?.numero
          },
          {
            title: "Série:",
            description: response?.data?.serie
          },
        ])

        setLoading(false);
      }
    }
  }, [idNota])

  const handleClose = () => {
    setShowMessage(false);
    if (errors.length === 0) {
      onClose();
    }
  }

  const handleCloseModal = async () => {
    setLoading(false);
    onClose();
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadFields();
      setLoading(false);
    })();
  }, [loadFields]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleCloseModal} dialog>
      {loading && <OverlayLoading />}
      <DialogTitle className="modal-container">
        <Grid
          item
          container
          justify="space-between"
          className="modal-title-container modal-title"
        >
          <Grid item>
            <span>DETALHES - DADOS DA NOTA FISCAL</span>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleCloseModal}
              size="medium"
              style={{ marginRight: 16, color: "#053b59", padding: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="content">
        <form className="session-container-form">
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>

             <Grid item xs={12} className="modal-items-nota">
                <ShowMore title="DADOS DO EMITENTE" listInfo={dataEmitente} /> 
              </Grid>

              <Grid item xs={12} className="modal-items-nota">
                <ShowMore title="DADOS DO DESTINATÁRIO" listInfo={dataDestinatario} /> 
              </Grid>

              <Grid item xs={12} className="modal-items-nota">
                <ShowMore title="VALORES TOTAIS" listInfo={dataValores} /> 
              </Grid>

              <Grid item xs={12} className="modal-items-nota">
                <ShowMore title="OUTROS DADOS" listInfo={data} /> 
              </Grid>

            </Grid>
          </Grid>
        </form>
      </div>
      <CallbackMessage
        open={showMessage}
        type={`${errors.length === 0 ? "success" : "error"}`}
        message={`${errors.length === 0 ? successMessage : "Erro!"}`}
        errorList={errors}
        handleClose={handleClose}
        duration={errors.length === 0 ? 2000 : 6000}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </Dialog>
  );
}
export default ModalDetalhesNota;
