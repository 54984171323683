import React from "react";
import { Accordion } from "@material-ui/core";
import "./styles.scss";

export function AccordionRoot({
  children,
  expanded,
  index,
  handleChange,
  child = false,
  borderChild = false
}) {
  return (
    <Accordion
      key={index}
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
      className={`accordion-component ${child ? "accordion-child" : ""} ${borderChild ? "border-child" : ""}`}
    >
      {children}
    </Accordion>
  );
}
