import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Input from "../../components/Input";
import Button from "../../components/ButtonComponent";
import OverlayLoading from "../../components/OverlayLoading";
import CallbackMessage from "../../components/CallbackMessage";
import ModalLoginExpirado from "../../components/ModalLoginExpirado";
import SwitchComponent from "../../components/Switch";

import BBLogo from "../../assets/img/bancodobrasil.png";

import { onlyNumbers } from "../../utils/strings";
import validateFields from "../../utils/validateFields";

import boletoService from "../../services/boletoService";

import "./styles.css";
import SelectComponent from "../../components/SelectComponent";
import { opcoesDiasVencimentoBoleto } from "../../enums/opcoesDiaVencimentoBoleto";
import { Grid } from "@material-ui/core";

function ConfiguracoesBoleto() {
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false);
  const params = useParams();
  const history = useHistory();
  const { id } = params;
  const { cadastraConfiguracoes, consultaConfiguracoes } = boletoService;

  const [descricao, setDescricao] = useState("");
  const [diaVencimentoPlano, setDiaVencimentoPlano] = useState(null);
  const [activeBB, setActiveBB] = useState(false);

  const [loading, setLoading] = useState(false);

  const [callbackShown, setCallbackShown] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [callbackMessage, setCallbackMessage] = useState("");
  const [callbackErrorList, setCallbackErrorList] = useState([]);
  const [callbackDuration, setCallbackDuration] = useState(6000);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await consultaConfiguracoes(id);
      if (response.status === 401) {
        setOpenLoginExpirado(true);
        setLoading(false);
        return;
      }
      if (response?.data?.length !== 0) {
        setDescricao(response?.data[0]?.Instrucao);
        setDiaVencimentoPlano(response?.data[0]?.vencimento_plano);
      }

      console.log(response);
      setLoading(false);
    })();
  }, []);

  const toggleChecked = () => {
    setActiveBB((prev) => !prev);
    console.log(activeBB);
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      setCallbackShown(false);
    }
    if (reason === "timeout") {
      setCallbackShown(false);
    }
    if (callbackType === "success") {
      history.push("/empresas");
    }
  }

  async function handleSubmit() {
    setLoading(true);

    let hasErrors = false;

    const fieldsToValidate = [
      {
        label: "dia_vencimento_plano",
        value: diaVencimentoPlano,
      },
    ];

    const fieldsErrors = validateFields(fieldsToValidate);

    if (fieldsErrors.length !== 0) {
      hasErrors = true;
      setCallbackErrorList(fieldsErrors);
      setCallbackMessage("Erro!");
      setCallbackType("error");
      setCallbackShown(true);
    }

    if (hasErrors) {
      setLoading(false);
      return;
    }

    const response = await cadastraConfiguracoes(id, descricao, diaVencimentoPlano);
    if (response.status === 401) {
      setOpenLoginExpirado(true);
      setLoading(false);
      return;
    }

    console.log(response);

    if (response.status === 200 || response.status === 201) {
      setLoading(false);
      setCallbackType("success");
      setCallbackMessage("Boleto configurado com sucesso.");
      setCallbackDuration(2000);
      setCallbackShown(true);
      return;
    } else {
      setLoading(false);
      setCallbackType("error");
      setCallbackMessage("Erro!");
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error));
      else setCallbackErrorList(["Ocorreu um erro"]);
      setCallbackDuration(3000);
      setCallbackShown(true);
      return;
    }
  }

  return (
    <>
      <div className="session-container user-register-container">
        <span className="session-container-header">
          {loading && <OverlayLoading />}
          <CallbackMessage
            open={callbackShown}
            duration={callbackDuration}
            errorList={callbackErrorList}
            handleClose={handleClose}
            message={callbackMessage}
            type={callbackType}
          />
          <form className="configuracoes-boleto-form">
            {/* <div className="switch-boletos-config">
              < checked={activeBB} onClick={toggleChecked} />
              <span>Ativar Banco do Brasil</span>
            </div>
            <div className="bb-logo-container">
              <img src={BBLogo} alt="Banco do Brasil" />
            </div> */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <SelectComponent
                  styleType="form"
                  label="Dia de vencimento"
                  required
                  list={opcoesDiasVencimentoBoleto}
                  initialValue={opcoesDiasVencimentoBoleto.find(item => item.id === diaVencimentoPlano) ? opcoesDiasVencimentoBoleto.find(item => item.id === diaVencimentoPlano).nome : ''}
                  callback={(id) => { setDiaVencimentoPlano(id) }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Input
                  label="Texto descritivo"
                  title={descricao !== "" ? null : "Defina o texto"}
                  value={descricao}
                  handleInputChange={(e) => setDescricao(e.target.value)}
                />
              </Grid>
            </Grid>
          </form>
        </span>
      </div>
      <div className="button-area">
        <span>
          <Button
            type="button"
            text="Salvar"
            className="btn-success"
            onClick={handleSubmit}
          />
        </span>
      </div>
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  );
}

export default ConfiguracoesBoleto;
