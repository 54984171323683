import { Grid } from "@material-ui/core";
import "./styles.scss";

export const InfoComponent = ({ text, icon }) => {
  return (
    <Grid
      item
      container
      alignItems="center"
      className="container-info"
      spacing={1}
    >
      {icon ? <Grid item>{icon}</Grid> : null}
      <Grid item>
        <div className="text-info-component">{text}</div>
      </Grid>
    </Grid>
  );
};
